<template>
    <span>
        <div class="hudie2_0">
            <div class="hudie2_1">
                <img class="hudiefei_1" :src="myfly_0">
                <img class="hudiefei_2" :src="myfly_1">
            </div>
            <div class="hudie2_2">
                <img class="hudiefei_1" :src="myfly_0">
                <img class="hudiefei_2" :src="myfly_1">
            </div>
        </div>
        <div class="hudie2_3">
            <img class="hudiefei_1" :src="myfly_0">
            <img class="hudiefei_2" :src="myfly_1">
        </div>
        <div class="hudie2_4">
            <img class="hudiefei_1" :src="myfly_0">
            <img class="hudiefei_2" :src="myfly_1">
        </div>
    </span>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

    export default {
        name: 'hudie2',
        extends: BaseElem,
        computed: {
            myfly_0 () {
                return this.comp_config[0] || "http://resali.lititutu.cn/icons/tuku/3CE311A7BC8A1781744AF30EE00E14B2.png";
            },
            myfly_1 () {
                return this.comp_config[1] || "http://resali.lititutu.cn/icons/tuku/32D5CD81DCF0902440C0F612A61E6753.png";
            },
        },
        data () {
            return {}
        }
    }
</script>

<style>
    .hudie2_0 {
        position:absolute;
        bottom: 65px;
        left: 560px;
        width: 100px;
        height: 100px;
        z-index: 6;
    }
    .hudie2_1 {
        position: absolute;
        top: 30px;
        left: 20px;
        width: 30px;
        height: 30px;
        animation: hudie2_1 3s linear infinite;
        z-index: 6;
    }
    @keyframes hudie2_1 {
        0% {
            -webkit-transform: rotate(40deg) translate(0px,0px) scale(1);
        }
        20% {
            -webkit-transform: rotate(40deg) translate(-110px,20px) scale(1);
        }
        40% {
            -webkit-transform: rotate(40deg) translate(-140px,25px) scale(1);
        }
        60% {
            -webkit-transform: rotate(20deg) translate(-180px,80px) scale(1);
        }
        60.01% {
            -webkit-transform: rotate(20deg) translate(-180px,80px) scale(-1);
        }
        75% {
            -webkit-transform: rotate(-10deg) translate(-180px,40px) scale(-1);
        }
        100% {
            -webkit-transform: rotate(-60deg) translate(-60px,-10px) scale(-1);
        }
    }
    .hudie2_2 {
        position: absolute;
        top: 30px;
        left: 20px;
        width: 30px;
        height: 30px;
        -webkit-animation: hudie2_1 3s linear 0.5s infinite;
    }
    .hudie2_3 {
        position: absolute;
        bottom: 85px;
        left: 530px;
        width: 30px;
        height: 30px;
        -webkit-animation: hudie2_3 2.5s linear infinite;
        z-index: 6;
    } 
    @keyframes hudie2_3 {
        0% {
            -webkit-transform: translate(0px,0px) rotate(20deg);
        }
        33% {
            -webkit-transform: translate(-150px,-60px) rotate(40deg);
        }
        67% {
            -webkit-transform: translate(-400px,-190px) rotate(40deg);
        }
        100% {
            -webkit-transform: translate(-600px,-240px) rotate(20deg);
        }
    }
    .hudie2_4 {
        position: absolute;
        bottom: 75px;
        left: 500px;
        width: 30px;
        height: 30px;
        -webkit-animation: hudie2_4 4.7s;
        z-index: 6;
    }
    @keyframes hudie2_4 {
        0% {
            -webkit-transform: translate(0px,0px) rotate(20deg);
        }
        33.33% {
            -webkit-transform: translate(-166px,26px) rotate(20deg);
        }
        50% {
            -webkit-transform: translate(-166px,26px) rotate(20deg);
        }
        50.1% {
            -webkit-transform: translate(-166px,26px) rotate(130deg);
        }
        100% {
            -webkit-transform: translate(175px,-155px) rotate(130deg);
        }
    }
    .hudiefei_1 {
        position: absolute;
        width: 33px;
        height: auto;
        -webkit-animation: hudiefei_1 0.2s linear infinite;
    }
    .hudiefei_2 {
        position: absolute;
        width: 33px;
        height: auto;
        top: 9px;
        left: -6px;
        -webkit-animation: hudiefei_2 0.2s linear infinite;
    }
    @keyframes hudiefei_1 {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        51% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes hudiefei_2 {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        51% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
</style>