import { render, staticRenderFns } from "./yuanluo.vue?vue&type=template&id=ca48c8a2&"
import script from "./yuanluo.vue?vue&type=script&lang=js&"
export * from "./yuanluo.vue?vue&type=script&lang=js&"
import style0 from "./yuanluo.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports