<template>
	<div id="bganim2">
        <div class="w-ftest1 w-xuehua"></div>
        <div class="w-ftest2 w-xuehua"></div>
        <img src="http://litiresource.xcgogo.site/user/5980171/images/b92d10d8f1192fe5a18e10f5454a7845.png" class="w-ftest">
        <div class="w-ftest3 w-xuehua"></div>
        <div class="w-ftest4 w-xuehua"></div>
        <div class="w-ftest5 w-xuehua"></div>
        <img src="http://litiresource.xcgogo.site/user/5980171/images/eba05d68e9579c89c8923d090281363e.png" class="w-ftest6">
        <div class="w-ftest7 w-xuehua"></div>
        <div class="w-ftest8 w-xuehua"></div>
        <img class="w-xuehua1" src="http://litiresource.xcgogo.site/user/5980171/images/b92d10d8f1192fe5a18e10f5454a7845.png">
        <img class="w-xuehua2" src="http://litiresource.xcgogo.site/user/5980171/images/eba05d68e9579c89c8923d090281363e.png">
        <img class="w-xuehua3" src="http://litiresource.xcgogo.site/user/5980171/images/d188c310600164d4448ec72d06e207de.png">
        <img class="w-xuehua4" src="http://litiresource.xcgogo.site/user/5980171/images/52619fa6b9d38784ffd1febc173b30d0.png">
        <img class="w-xuehua5" src="http://litiresource.xcgogo.site/user/5980171/images/b92d10d8f1192fe5a18e10f5454a7845.png">
        <div id="elems">
            <img data-itemid="3" class="w-xueren w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/bc7fae064d7356f8eb897684d69c0a5b.png">
            <img data-itemid="11" class="w-xueren w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/bc7fae064d7356f8eb897684d69c0a5b.png">
            <img data-itemid="17" class="w-xueren w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/bc7fae064d7356f8eb897684d69c0a5b.png">
            <img data-itemid="3" class="w-xueren w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/bc7fae064d7356f8eb897684d69c0a5b.png">
            <img data-itemid="22" class="w-milu w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png">
            <img data-itemid="10" class="w-milu w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png">
            <img data-itemid="15" class="w-milu w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png">
            <img data-itemid="20" class="w-milu w-ele" src="http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png">
        </div>
    </div>
</template>

<script>
	/* eslint-disable */

	export default {
		name: 'xingfuxuege',
		data () {
			return {}
		}
	}
</script>

<style>
    .w-ftest {
        position: absolute;
        left: -300px;
        top: 400px;
        animation: w-ftest 12s 1s linear infinite;
        opacity: 1;
        transform: scale(.2);
    }

    @keyframes w-ftest {
        0% {
            transform: translate3d(0, 0, 0) rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale(.2);
            opacity: 1;
        }
        100% {
            transform: translate3d(700px, 250px, 400px) rotateX(400deg) rotateZ(600deg) rotateY(400deg) scale(.5);
        }
    }

    .w-ftest1 {
        left: -100px;
        top: 200px;
        position: absolute;
        animation: w-flower 8s 1s linear infinite;
        opacity: 0;
    }

    .w-ftest2 {
        left: 100px;
        top: 300px;
        position: absolute;
        animation: w-flower 8s 3s linear infinite;
        opacity: 0;
    }

    .w-ftest3 {
        left: 100px;
        top: 300px;
        position: absolute;
        animation: w-flower1 8s 3s linear infinite;
        opacity: 0;
    }

    .w-ftest4 {
        left: 100px;
        top: 300px;
        position: absolute;
        animation: w-flower1 8s 3s linear infinite;
        opacity: 0;
    }

    .w-ftest5 {
        left: 100px;
        top: 300px;
        position: absolute;
        animation: w-flower1 8s 3s linear infinite;
        opacity: 0;
    }

    .w-ftest6 {
        left: 100px;
        top: 300px;
        position: absolute;
        animation: w-xuezhuan2 8s 1s linear infinite;
        opacity: 0;
    }

    .w-ftest7 {
        left: 0px;
        top: 0px;
        position: absolute;
        animation: w-divw-xuehua 8s linear infinite;
        opacity: 0;
    }

    .w-ftest8 {
        left: -50px;
        top: 50px;
        position: absolute;
        animation: w-divw-xuehua8 8s 1.5s linear infinite;
    }

    @keyframes w-divw-xuehua8 {
        0% {
            transform: translate3d(0, 0px, 0px) scale(.4) rotate(0deg);
            opacity: .3;
        }
        100% {
            transform: translate3d(350px, 600px, 450px) scale(.2) rotate(1800deg);
            opacity: 1;
        }
    }

    @keyframes w-divw-xuehua {
        0% {
            transform: translate3d(0, 0px, 0px) scale(.4) rotate(0deg);
            opacity: .3;
        }
        100% {
            transform: translate3d(390px, 680px, 450px) scale(.2) rotate(1800deg);
            opacity: 1;
        }
    }

    @keyframes w-flower {
        0% {
            transform: translate3d(0, 100px, 0px);
            opacity: .3;
        }
        100% {
            transform: translate3d(600px, 700px, 200px);
            opacity: 1;
        }
    }

    @keyframes w-xuezhuan {
        0% {
            transform: translate3d(0, 100px, -200px) rotateY(0deg) rotateX(0deg);
            opacity: .3;
        }
        100% {
            transform: translate3d(600px, 700px, 300px) rotateY(200deg) rotateX(200deg);
            opacity: 1;
        }
    }

    @keyframes w-xuezhuan2 {
        0% {
            transform: translate3d(0, -300px, -200px) rotateY(0deg) rotateX(0deg);
            opacity: .3;
        }
        100% {
            transform: translate3d(-200px, 400px, 200px) rotateY(200deg) rotateX(200deg);
            opacity: 1;
        }
    }

    @keyframes w-flower1 {
        0% {
            transform: translate3d(0, -300px, 0px) scale(.3) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
            opacity: .3;
        }
        100% {
            transform: translate3d(-200px, 400px, 400px) scale(.3) rotateY(300deg) rotateX(400deg) rotateZ(200deg);
            opacity: 1;
        }
    }



    .w-xuehua {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
    }

    .w-xuehua1 {
        position: absolute;
        left: -320px;
        top: -120px;
        animation: w-xuehua1 10s linear infinite both;
    }

    @keyframes w-xuehua1 {
        0% {
            opacity: 0;
            transform: translateY(0px) rotate(0deg) scale(.5);
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(600px) rotate(-400deg) scale(.5);
        }
    }

    .w-xuehua2 {
        position: absolute;
        left: -130px;
        top: 0px;
        animation: w-xuehua2 12s linear infinite both;
        opacity: 0;
    }

    @keyframes w-xuehua2 {
        0% {
            opacity: 0;
            transform: translateY(0px) rotate(0deg) scale(.3);
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(600px) rotate(-90deg) scale(.3);
        }
    }

    .w-xuehua3 {
        position: absolute;
        left: 90px;
        top: -50px;
        animation: w-xuehua2 8s linear infinite both;
        opacity: 0;
    }

    .w-xuehua4 {
        position: absolute;
        left: 0px;
        top: -100px;
        animation: w-xuehua1 7s 1s linear infinite both;
        opacity: 0;
    }

    .w-xuehua5 {
        position: absolute;
        left: -240px;
        top: -100px;
        animation: w-new5 14s linear infinite both;
    }

    @keyframes w-new5 {
        0% {
            transform: translate3d(-100px, -200px, -200px) rotateY(0deg) rotateX(0deg) rotate(0deg);
            opacity: .3;
        }
        50% {
            transform: translate3d(300px, 700px, 100px) rotateY(200deg) rotateX(200deg) rotate(120deg);
            opacity: 1;
        }
        100% {
            transform: translate3d(700px, 900px, 400px) rotateY(400deg) rotateX(400deg) rotate(240deg);
            opacity: 1;
        }
    }


    .w-xueren {
        position: absolute;
        transform: scale(.7);
        bottom: 100px;
        left: 100px;
    }

    .w-milu {
        position: absolute;
        transform: scale(.5);
        bottom: 100px;
        left: 170px;
    }
</style>