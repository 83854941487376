<template>
    <div>
        <template v-if="mode.length == 3">
            <div class="quanjing5">
                <div class="xd_ani" style="opacity: 1;">
					<div class="xd_box">
						<div class="item1 item item-tree">
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
						<div class="item4 item item-tree">
							<!-- <img class="midimg" :src="mode[2]"> -->
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
						<div class="item7 item item-tree">
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
						<div class="item10 item item-tree">
							<!-- <img class="midimg" :src="mode[2]"> -->
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
						<div class="item13 item item-tree">
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
						<div class="item16 item item-tree">
							<!-- <img class="midimg" :src="mode[2]"> -->
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
						<div class="item19 item item-tree">
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
						<div class="item22 item item-tree">
							<!-- <img class="midimg" :src="mode[2]"> -->
							<img class="item_bot" :src="mode[1]" />
							<img class="item_top" :src="mode[1]" />
						</div>
					</div>
				</div>
            </div>
            <div class="quanjing5">
                <div class="xd_ani" style="opacity: 1;">
					<div class="xd_box">
						<div class="item1 item item-userphoto">
							<div class="tupian" :style="getStyle" @click="wxPreview"></div>
						</div>
						<div class="item4 item item-userphoto">
							<img class="midimg" :src="mode[2]">
						</div>
						<div class="item7 item item-userphoto">
							<div class="tupian" :style="getStyle" @click="wxPreview"></div>
						</div>
						<div class="item10 item item-userphoto">
						</div>
						<div class="item13 item item-userphoto">
							<div class="tupian" :style="getStyle" @click="wxPreview"></div>
						</div>
						<div class="item16 item item-userphoto">
							<img class="midimg" :src="mode[2]">
						</div>
						<div class="item19 item item-userphoto">
							<div class="tupian" :style="getStyle" @click="wxPreview"></div>
						</div>
						<div class="item22 item item-userphoto">
						</div>
					</div>
				</div>
            </div>
        </template>
        <template v-else-if="mode.length == 1">
            <div class="quanjing5">
                <div class="xd_ani" style="opacity: 1;">
                    <div class="xd_box">
                        <div class="item1 item">
                            <div class="tupian" :style="getStyle" @click="wxPreview"></div>
                        </div>
                        <div class="item4 item">
                            
                        </div>
                        <div class="item7 item">
                            <div class="tupian" :style="getStyle" @click="wxPreview"></div>
                        </div>
                        <div class="item10 item">
                        </div>
                        <div class="item13 item">
                            <div class="tupian" :style="getStyle" @click="wxPreview"></div>
                        </div>
                        <div class="item16 item">
                        </div>
                        <div class="item19 item">
                            <div class="tupian" :style="getStyle" @click="wxPreview"></div>
                        </div>
                        <div class="item22 item">
                        </div>
                    </div>
                    <gif :mode="mode[1]"></gif>
                </div>	        		
            </div>
        </template>
    </div>
</template>


<script>
	export default {
		name: 'xuandong2',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		computed: {
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [468, 530], 'both');
					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				} else {
					return {
						width: '368px',
						height: '530px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.xd_ani {
		transform: translate3d(0, -278.5px, -472px);
		-webkit-backface-visibility: hidden;
	}
	.xd_box {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.xd_ani .item {
		position:absolute;
		top:0;
		width: @bw * 0.718;
		height: @bw * 1.451;
		transform-origin: 100% 50%;
		pointer-events: none;
	}
	.xd_ani .item-userphoto {
		// -webkit-backface-visibility: hidden;
	}
	.xd_ani, .xd_ani .xd_box, .xd_ani .item {
		position:absolute;
		top:3%;
		left:0;
		width:100%;
		height:@bw * 2.6;
		-webkit-transform-style:preserve-3d;
		transform-style:preserve-3d;
	}
	.xd_ani .item .midimg {
		height:92%;
		width:100%;
	}
	.xd_ani .item .tupian,.xd_ani .item .tupianbg {
		position:absolute;
		/*bottom:@bw * 0.542;*/
		left:@bw * 0.188;
		// width:@bw * 0.668;
		// height:@bw * 1.061;
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		font-size:@bw * 0.139;
		color:#ffffff;
		text-align:center;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
	}
	.xd_ani .item .tupian {
		left:-@bw * 0.139;
		top: 0;
		bottom: 0;
		margin: auto 0;
		// width:@bw * 0.736;
		transform: rotateY(-90deg) translateZ(60px);
	}
	.xd_ani .item1,.xd_ani .item7,.xd_ani .item13,.xd_ani .item19 {
		pointer-events:auto;
	}
	.xd_ani .item1 {
		transform:translateX(-@bw * 0.521) rotateY(0deg);
	}
	.xd_ani .item2 {
		transform:translateX(-@bw * 0.521) rotateY(15deg);
	}
	.xd_ani .item3 {
		transform:translateX(-@bw * 0.521) rotateY(30deg);
	}
	.xd_ani .item4 {
		transform:translateX(-@bw * 0.521) rotateY(45deg);
	}
	.xd_ani .item5 {
		transform:translateX(-@bw * 0.521) rotateY(60deg);
	}
	.xd_ani .item6 {
		transform:translateX(-@bw * 0.521) rotateY(75deg);
	}
	.xd_ani .item7 {
		transform:translateX(-@bw * 0.521) rotateY(90deg);
	}
	.xd_ani .item8 {
		transform:translateX(-@bw * 0.521) rotateY(105deg);
	}
	.xd_ani .item9 {
		transform:translateX(-@bw * 0.521) rotateY(120deg);
	}
	.xd_ani .item10 {
		transform:translateX(-@bw * 0.521) rotateY(135deg);
	}
	.xd_ani .item11 {
		transform:translateX(-@bw * 0.521) rotateY(150deg);
	}
	.xd_ani .item12 {
		transform:translateX(-@bw * 0.521) rotateY(165deg);
	}
	.xd_ani .item13 {
		transform:translateX(-@bw * 0.521) rotateY(180deg);
	}
	.xd_ani .item14 {
		transform:translateX(-@bw * 0.521) rotateY(195deg);
	}
	.xd_ani .item15 {
		transform:translateX(-@bw * 0.521) rotateY(210deg);
	}
	.xd_ani .item16 {
		transform:translateX(-@bw * 0.521) rotateY(225deg);
	}
	.xd_ani .item17 {
		transform:translateX(-@bw * 0.521) rotateY(240deg);
	}
	.xd_ani .item18 {
		transform:translateX(-@bw * 0.521) rotateY(255deg);
	}
	.xd_ani .item19 {
		transform:translateX(-@bw * 0.521) rotateY(270deg);
	}
	.xd_ani .item20 {
		transform:translateX(-@bw * 0.521) rotateY(285deg);
	}
	.xd_ani .item21 {
		transform:translateX(-@bw * 0.521) rotateY(300deg);
	}
	.xd_ani .item22 {
		transform:translateX(-@bw * 0.521) rotateY(315deg);
	}
	.xd_ani .item23 {
		transform:translateX(-@bw * 0.521) rotateY(330deg);
	}
	.xd_ani .item24 {
		transform:translateX(-@bw * 0.521) rotateY(345deg);
	}
	.gu {
		position:absolute;
		transform:scale(.7);
		bottom:-@bw * 0.028;
		left:@bw * 0.028;
	}
	.jinqi {
		position:absolute;
		transform:scale(.5,.6);
		top:@bw * 0.181;
		left:@bw * 0.069;
	}
	@keyframes rotatebg {
		0% {
			transform: rotateY(0deg);
		}
		// 50% {
		// 	transform: rotateY(180deg);
		// }
		100% {
			transform: rotateY(360deg);
		}
	}
	.xd_box {
		animation:rotatebg 9s 0s linear infinite;
	}
	.wawa {
		position:absolute;
		transform:scale(.8);
		bottom:@bw * 0.056;
		left:@bw * 0.139;
	}
	.zhongguojie {
		position:absolute;
		transform:scale(.5,.6);
		top:@bw * 0.236;
		left:@bw * 0.167;
	}
	.quanjing5 {
		position: absolute;
		height: 100%;
		width: 100%;
		-webkit-perspective: @bw * 0.653;
		perspective: @bw * 0.653;
	}
	#bganim {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
	}
	#elems,#bgimg,#midimg {
		display:none;
		pointer-events:none;
	}
	.item_bot {
		position:absolute;
		width:100%;
		height:250px;
		transform:rotateX(90deg);
		left:0;
		bottom:0;
		// background-position:center;
		// background-repeat:no-repeat;
		// background-size:100% auto;
		-webkit-backface-visibility: hidden;
	}
	.item_top {
		position:absolute;
		width:100%;
		height:250px;
		transform:rotateX(-90deg) rotateY(12deg) translate3d(0, -46.5px, 0);
		// transform:rotateX(-90deg) rotateY(12deg) translateY(-@bw * 0.093);
		left:0;
		top:0;
		// background-position:center;
		// background-repeat:no-repeat;
		// background-size:cover;
		-webkit-backface-visibility: hidden;
	}
</style>
