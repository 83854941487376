<template>
    <div id="app">
        <router-view :key="key"/>
    </div>
</template>

<script>
	import C from '@/config'

	export default {
		data () {
			return {}
		},
		components: {},
		created () {},
		mounted () {
			// vue无法加载相关逻辑
			vueAble = true;
			if (document.getElementById('liti-error')) {
				document.getElementById('liti-error').style.display = 'none';
			}
		},
		computed: {
			key () {
				return this.$route.name ? this.$route.name + new Date() : this.$route + new Date();
			},
		},
		methods: {}
	}
	
</script>

<style lang="less">
	* {
		font-family: -apple-system-font,Helvetica Neue,sans-serif;
	    // font-family: 'Hei';
	    // font-family: 'Hei', SimHei, STHeiti, 黑体;
	}
	body {
		margin: 0;
		padding: 0;
		background-color: rgb(243, 243, 243);
		// background-color: #ededed;
		// background: rgb(39, 40, 44);
	}
	#app {
	    // top: 0; left: 0; right: 0; bottom: 0;
	    // overflow: hidden;
	}
	.po {
		position: absolute;
	}
	.poc {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.iphonex {
		padding-bottom: 30px;
	}
	.page {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.circle {
		border-radius: 50%;
	}
	.oh {
		overflow: hidden;
	}
	.bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.bg_photo {
		background-position: center 15%!important;
	}
	.none {
		display: none!important;
	}
	.preserve3d {
		-webkit-transform-style: preserve-3d;
	}
	.perspective {
	    -webkit-perspective: 500px;
	}
	.pointer {
		pointer-events: none;
	}
	.flex_r {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:after,
		&:before {
			content: '';
			display: block;	
		}
	}
	.origin_0_0 {
		transform-origin: 0 0;
	}
	
	.container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .re-container {
		display: flex;
        flex-direction: row;
        justify-content: space-between;
		align-items: center;
    }

    .container>span,.container>img,.re-container>span,.re-container>img{
            display: block;
    }


	/* 突出特效 */
	.tada {
		animation: tada 1s both;
	}
	@keyframes tada {
	    0% {
	        transform: scaleX(1)
	    }

	    10%,20% {
	        transform: scale3d(.9,.9,.9) rotate(-3deg)
	    }

	    30%,50%,70%,90% {
	        transform: scale3d(1.1,1.1,1.1) rotate(3deg)
	    }

	    40%,60%,80% {
	        transform: scale3d(1.1,1.1,1.1) rotate(-3deg)
	    }

	    to {
	        transform: scaleX(1)
	    }
	}

	/* 渐隐特效 */
	.fadeOut {
		animation: fadeOut .3s ease-out both;
	}
	.fadeIn {
		animation: fadeIn .3s ease-out both;
	}
	@keyframes fadeOut {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	/* 树枝摇晃特效 */
	@keyframes shuzhi {
		0% {
		    transform: rotateZ(-2.5deg);
		}
		100% {
		    transform: rotateZ(2.5deg);
		}
	}

	/* 缓缓放大 */
	@keyframes mscale {
    	from {
    		transform: none;
    	}
    	to {
    		transform: scale(1.1);
    	}
	}
	
	/* 缓动效果 */
	.float-x {
        animation: float_x 2.8s ease-in-out infinite alternate;
    }
    @keyframes float_x {
        0% {
            transform: translateX(7px);
        }
        100% {
            transform: translateX(-7px);
        }
    }
    .float-y {
        animation: float_y 3s ease-in-out infinite alternate;
    }
    @keyframes float_y {
        0% {
            transform: translateY(-7px);
        }
        100% {
            transform: translateY(7px);
        }
    }

	// 树枝晃动
	.shuZhiHuang {
		animation: shuZhiHuang 2s ease-in-out infinite alternate;
	}
	@keyframes shuZhiHuang {
		0% {
			transform: rotate(-1.5deg);
		}
		100% {
			transform: rotate(1.5deg);
		}
	}

	// 上下晃动
	.upDownHuang {
		animation: upDownHuang 2s ease-in-out infinite alternate both;
	}
	@keyframes upDownHuang {
		0% {
		    transform: translateY(-5px);
		}
		100% {
		    transform: translateY(5px);
		}
	}

	// 挥手动画
	.shakeHand {
		transform-origin: 95% 85%;
		animation: shakeHand 2s ease-in-out infinite alternate;
	}
	@keyframes shakeHand {
		0% {
		    transform: rotate(0);
		}
		100% {
		    transform: rotate(12deg);
		}
	}

	// 转圈
	@keyframes zhuan {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    // 从右到左横向移动
	@keyframes movex_right_to_left {
		0% {
		    transform: translateX(500px);
		}
		100% {
		    transform: translateX(-500px);
		}
	}

	// 音乐歌词
	#music-lrc {
		color: white;
		bottom: 100px;
		position: absolute;
		text-align: center;
		width: 420px;
		font-size: 28px;
		left: 50%;
		margin-left: -210px;
		line-height: 40px;
		// text-shadow: #fff 1px 0 0, #fff 0 1px 0, #fff -1px 0 0, #fff 0 -1px 0;
		// color: #dc2727;
		font-weight: bold;
		display: none;
		z-index: 8;
	    letter-spacing: 3px;

		padding: 10px 8px;
        box-sizing: border-box;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, 0.65);
	}

	// 高斯模糊背景图
	.blurImage {
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    background-position: center;
	    background-size: cover;
	    filter: blur(@bw * 0.028);
	}

	/* 修改vconsole样式 */
	#__vconsole {
		font-size: 28px!important;
	}

	// 以下是历史遗留css开始
	/*不同位置隐藏再出现的动画*/
	.yinshenshan {
		animation: yinshenshan linear 16s infinite;
	}
	@keyframes yinshenshan {
		0% {
		    opacity: 1;
		    top: 306px;
		    left: 1px;
		}
		25% {
		    opacity: 1;
		    top: 306px;
		    left: 1px;
		}
		29% {
		    opacity: 0;
		    top: 306px;
		    left: 1px;
		}
		30% {
		    opacity: 0;
		    top: 306px;
		    left: 1px;
		}
		31% {
		    opacity: 0;
		    top: 130px;
		    left: 410px;
		}
		35% {
		    opacity: 1;
		    top: 130px;
		    left: 410px;
		}
		60% {
		    opacity: 1;
		    top: 130px;
		    left: 410px;
		}
		64% {
		    opacity: 0;
		    top: 130px;
		    left: 410px;
		}
		65% {
		    opacity: 0;
		    top: 480px;
		    left: 410px;
		}
		69% {
		    opacity: 1;
		    top: 480px;
		    left: 410px;
		}
		91% {
		    opacity: 1;
		    top: 480px;
		    left: 410px;
		}
		95% {
		    opacity: 0;
		    top: 480px;
		    left: 410px;
		}
		96% {
		    opacity: 0;
		    top: 306px;
		    left: 1px;
		}
		100% {
		    opacity: 1;
		    top: 306px;
		    left: 1px;
		}
	}
	.tiaodong {
		animation: 4.5s linear 0s infinite normal none running tiaodong;
	}
	@keyframes tiaodong {
		0% {
		    transform: translate(0px,0px) rotate(0deg) scale(1);
		}
		12.5% {
		    transform: translate(-30px,5px) rotate(6deg) scale(.92);
		}
		25% {
		    transform: translate(-60px,20px) rotate(0deg) scale(1);
		}
		37.5% {
		    transform: translate(-30px,35px) rotate(-6deg) scale(.92);
		}
		50% {
		    transform: translate(0px,40px) rotate(0deg) scale(1);
		}
		62.5% {
		    transform: translate(30px,35px) rotate(6deg) scale(.92);
		}
		75% {
		    transform: translate(60px,20px) rotate(0deg) scale(1);
		}
		87.5% {
		    transform: translate(30px,5px) rotate(-6deg) scale(.92);
		}
		100% {
		    transform: translate(0px,0px) rotate(0deg) scale(1);
		}
	}
	.lefttoright {
		animation-name: lefttoright;
	}
	@keyframes lefttoright {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(880px);
		}
	}
	.righttoleft {
		animation-name: righttoleft;
	}
	@keyframes righttoleft {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-880px);
		}
	}
	.righttoleft_1 {
		animation-name: righttoleft_1;
	}
	@keyframes righttoleft_1 {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-1589px);
		}
	}

	.flower1 {
	    animation: flower1 2s infinite alternate ease-in-out both;
	}
	@keyframes flower1 {
		0% {
		    transform: rotateZ(-5deg);
		}
		100% {
		    transform: rotateZ(4deg);
		}
	}
	
	.flower2 {
	    animation: flower2 1.9s 0.4s infinite alternate ease-in-out both;
	}
	@keyframes flower2 {
		0% {
		    transform: rotateZ(2deg);
		}
		100% {
		    transform: rotateZ(-5deg);
		}
	}
	.flower3 {
	    animation: flower3 2.5s 0.5s infinite alternate ease-in-out both
	}
	@keyframes flower3 {
		0% {
    		transform: rotateZ(-6deg);
		}
		100% {
		    transform: rotateZ(4deg);
		}
	}
	.flower4 {
	    animation: flower4 2.1s 0.7s infinite alternate ease-in-out both;
	}
	@keyframes flower4 {
		0% {
		    transform: rotateZ(-4deg);
		}
		100% {
		    transform: rotateZ(5deg);
		}
	}
	.flower5 {
	    animation: flower5 2.5s 0.1s infinite alternate ease-in-out both;
	}
	@keyframes flower5 {
		0% {
		    transform: rotateZ(-2deg);
		}
		100% {
		    transform: rotateZ(8deg);
		}
	}

	.scaleHuaAnima2 {
	    opacity: 0;
	    animation: scaleHua 3s linear 0.1s infinite alternate;
	}
	.scaleHuaAnima3 {
	    opacity: 0;
		animation: scaleHua 2.6s linear 0.5s infinite alternate;
	}
	.scaleHuaAnima4 {
	    opacity: 0;
	    animation: scaleHua 3.6s linear .8s infinite alternate;
	}
	.scaleHuaAnima5 {
	    opacity: 0;
	    animation: scaleHua 2.3s linear .6s infinite alternate;
	}
	.scaleHuaAnima6 {
	    opacity: 0;
	    animation: scaleHua 2.3s linear 3s infinite alternate;
	}
	.scaleHuaAnima7 {
	    opacity: 0;
	    animation: scaleHua 2s linear 3s infinite alternate;
	}
	.scaleHuaAnima0 {
	    opacity: 0;
	    animation: scaleHua 2.5s linear 3s infinite alternate;
	}
	.scaleHuaAnima1 {
	    opacity: 0;
	    animation: scaleHua 2.7s linear 3s infinite alternate;
	}
	@keyframes scaleHua {
		0% {
		    transform: scale(0.3);
		    opacity: 0;
		}
		5% {
		    opacity: 0.7;
		}
		100% {
		    transform: scale(1);
		    opacity: 1;
		}
	}

	@keyframes feng_chui {
		0% {
            transform: rotateZ(0deg);
        }
        100% {
            transform: rotateZ(10deg);
        }
	}

	@keyframes feng_chui_1 {
		0% {
            transform: rotateZ(0deg);
        }
        100% {
            transform: rotateZ(-10deg);
        }
	}

	@keyframes feng_chui_2 {
		0% {
            transform: rotateZ(-2deg);
        }
        100% {
            transform: rotateZ(4deg);
        }
	}

	@keyframes feng_chui_3 {
		0% {
            transform: rotateZ(-3deg);
        }
        100% {
            transform: rotateZ(3deg);
        }
	}

	@keyframes feng_chui_4 {
		0% {
            transform: rotateZ(3deg);
        }
        100% {
            transform: rotateZ(-3deg);
        }
	}

	@keyframes cloud1 {
		0% {
		    transform: translateX(0) translateZ(5px);
		}
		71.875% {
		    transform: translateX(690px) translateZ(5px);
		}
		71.876% {
		    transform: translateX(-270px) translateZ(5px);
		}
		100% {
		    transform: translateX(0) translateZ(5px);
		}
	}

	@keyframes cloud2 {
		0% {
		    transform: translateX(0) translateZ(6px);
		}
		21.569% {
		    transform: translateX(220px) translateZ(6px);
		}
		21.57% {
		    transform: translateX(-800px) translateZ(6px);
		}
		100% {
		    transform: translateX(0) translateZ(6px);
		}
	}

	@keyframes cloud3 {
		0% {
		    transform: translateX(0) rotateY(180deg) translateZ(7px);
		}
		35.514% {
		    transform: translateX(380px) rotateY(180deg) translateZ(7px);
		}
		35.515% {
		    transform: translateX(-690px) rotateY(180deg) translateZ(7px);
		}
		100% {
		    transform: translateX(0) rotateY(180deg) translateZ(7px);
		}
	}

	@keyframes cloud4 {
		0% {
		    transform: translateX(0) rotateY(180deg) translateZ(6px);
		}
		84.112% {
		    transform: translateX(900px) rotateY(180deg) translateZ(6px);
		}
		84.113% {
		    transform: translateX(-170px) rotateY(180deg) translateZ(6px);
		}
		100% {
		    transform: translateX(0) rotateY(180deg) translateZ(6px);
		}
	}

	@keyframes cloud5 {
		0% {
			transform: translateX(0) translateZ(5px);
		}
		72.816% {
			transform: translateX(750px) translateZ(5px);
		}
		72.817% {
			transform: translateX(-280px) translateZ(5px);
		}
		100% {
			transform: translateX(0) translateZ(5px);
		}
	}

	@keyframes cloud6 {
		0% {
			transform: translateX(0) rotateY(180deg) rotateZ(-10deg) translateZ(4px);
		}
		18.367% {
			transform: translateX(180px) rotateY(180deg) rotateZ(-10deg) translateZ(4px);
		}
		18.368% {
			transform: translateX(-800px) rotateY(180deg) rotateZ(-10deg) translateZ(4px);
		}
		100% {
			transform: translateX(0) rotateY(180deg) rotateZ(-10deg) translateZ(4px);
		}
	}

	@keyframes cloud7 {
		0% {
			transform: translateX(0) rotateZ(-10deg) translateZ(3px);
		}
		48.276% {
			transform: translateX(420px) rotateZ(-10deg) translateZ(3px);
		}
		48.277% {
			transform: translateX(-450px) rotateZ(-10deg) translateZ(3px);
		}
		100% {
			transform: translateX(0) rotateZ(-10deg) translateZ(3px);
		}
	}

	@keyframes cloud8 {
		0% {
		    transform: translateX(0) rotateZ(-10deg) translateZ(5px);
		}
		100% {
		    transform: translateX(860px) rotateZ(-10deg) translateZ(5px);
		}
	}

	@keyframes lotus_leaf_1 {
		0% {
		    transform: translate(-6px,6px);
		}
		100% {
		    transform: translate(6px,-6px);
		}
	}

	@keyframes lotus_leaf_2 {
		0% {
		    transform: translate(2px,-3px);
		}
		100% {
		    transform: translate(-2px,13px);
		}
	}

	@keyframes lotus_leaf_3 {
		0% {
		    transform: translate(-8px,-6px);
		}
		100% {
		    transform: translate(6px,16px);
		}
	}

	@keyframes lotus_leaf_4 {
		0% {
		    transform: translate(5px,0px);
		}
		100% {
		    transform: translate(-5px,-30px);
		}
	}

	@keyframes lotus_leaf_5 {
		0% {
		    transform: translate(-10px,18px);
		}
		100% {
		    transform: translate(10px,0px);
		}
	}
	// 以上是历史遗留css结束

	/* 适配ipX的底边栏 */
	.ipX_padding_bottom {
		padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
		padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
	}
	.ipX_bottom {
		bottom: constant(safe-area-inset-bottom)!important; /*兼容 IOS<11.2*/
		bottom: env(safe-area-inset-bottom)!important; /*兼容 IOS>11.2*/
	}
	.ipX_height_less {
		height: calc(100% - constant(safe-area-inset-bottom))!important; /*兼容 IOS<11.2*/
		height: calc(100% - env(safe-area-inset-bottom))!important; /*兼容 IOS>11.2*/
	}
</style>
