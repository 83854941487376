class stTaro {
    constructor (util) {
        this.$util = util;
        
        // 加载弹窗单例对象
        this.loadingEvent = null;

        // 消息中心
        this.eventCenter = {
            on (eventName, cb) {
                // console.log("1 on > ", eventName, cb);
                let line = util.$global.get("h5EventCenterLine", {});
                let eventline = line[eventName] || [];
                eventline.push(cb);
                line[eventName] = eventline;
                util.$global.set("h5EventCenterLine", line);
                // console.log("2 on > ", line);
            },
            trigger (eventName, arg) {
                let line = util.$global.get("h5EventCenterLine", {});
                let eventline = line[eventName] || [];
                for (let i=0; i<eventline.length; i++) {
                    eventline[i](); 
                }
                // console.log("trigger > ", eventName, arg, eventline);
            },
            off (eventName, cb) {
                // console.log("off > ", eventName, cb)
            }
        }
    }
}

export default {
    stTaro
}