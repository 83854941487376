<template>
	<div id="now_page" class="now_page">
	    <div class="container2">
	        <div class="div_1 rect userimg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="div_2 rect topImg" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
	        <div class="div_3 rect userimg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="div_4 rect botImg" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	        <div class="div_5 rect userimg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="div_6 rect userimg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	    </div>
	    <div class="box">
	        <div class="containLit">
	            <div class="haicao6 po ">
	                <div class="page po fanzhuan">
	                    <div class="page scale">
	                        <img class="other_donghua other_donghua_06 po" src="http://resali.lititutu.cn/scenes/haidishijie/dd000e881817a971292cd85f50f09144.png"></div>
	                </div>
	            </div>
	            <div class="haicao5 po">
	                <div class="page po fanzhuan">
	                    <div class="page scale">
	                        <img src="http://resali.lititutu.cn/scenes/haidishijie/4c5b0ea7a330d77acd3b159224aa76d2.png" class="other_donghua other_donghua_05 po"></div>
	                </div>
	            </div>
	            <!-- <div class="haicao7 po ">
	                <div class="page po fanzhuan">
	                    <div class="page scale">
	                        <img src="http://resali.lititutu.cn/scenes/haidishijie/4c5b0ea7a330d77acd3b159224aa76d2.png" class="other_donghua other_donghua_02 po"></div>
	                </div>
	            </div> -->
	            <div class="shanhu2 po ">
	                <div class="page po fanzhuan">
	                    <div class="page scale">
	                        <img src="http://resali.lititutu.cn/scenes/haidishijie/cabf24c930780a054b7371aeab83dfb7.png" class=" po"></div>
	                </div>
	            </div>
	            <div class="shanhu3 po ">
	                <div class="page po fanzhuan">
	                    <div class="page scale">
	                        <img src="http://resali.lititutu.cn/scenes/haidishijie/cabf24c930780a054b7371aeab83dfb7.png" class="po"></div>
	                </div>
	            </div>
	            <div class="shanhu4 po ">
	                <div class="page po fanzhuan">
	                    <div class="page scale">
	                        <img src="http://resali.lititutu.cn/scenes/haidishijie/cabf24c930780a054b7371aeab83dfb7.png" class="po"></div>
	                </div>
	            </div>
	        </div>
	        <div class="float_container">
	            <img class="fish fish2" src="http://resali.lititutu.cn/scenes/haidishijie/ee1b247cc47331d5d21cd63dadaf73d5.png">
	            <img class="fish yellow_fish" src="http://resali.lititutu.cn/scenes/haidishijie/0a1210441ea7439c3093349a5e8bda42.png">
	            <img class=" right_shuimu" src="http://resali.lititutu.cn/scenes/haidishijie/2b1b2ffb0d2ab587091ed915d3e1dcb1.png">
	            <img class=" left_shuimu" src="http://resali.lititutu.cn/scenes/haidishijie/f5e23b13636dd5b6f8ed4efb53770c28.png">
	            <img class=" top_shuimu" src="http://resali.lititutu.cn/scenes/haidishijie/e044d9e0b74209dd1c406e40fecf97bd.png">
	            <div id="snow_box" class="snow_box">
	                <div class="snow1 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/48495d5949ddb4ad16246cb16726c50f.png"></div>
	                <div class="snow2 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/0761b92f77299267e014d3238b48db44.png"></div>
	                <div class="snow3 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/49909d81d26ed3a0010555fcdc19f419.png"></div>
	                <div class="snow4 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/b7da5800cd728702122e50f27e15ae46.png"></div>
	                <div class="snow5 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/657023cccd8ed1eb1a51f20a74964793.png"></div>
	                <div class="snow6 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/59bf4c6a3df826919d129fa963449afb.png"></div>
	                <div class="snow7 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/29bf86f6701a121c90e13fcc42215e1a.png"></div>
	                <div class="snow8 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/6beecbc3f602ed73b2ef4e11c25f0d04.png"></div>
	                <div class="snow9 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/49909d81d26ed3a0010555fcdc19f419.png"></div>
	                <div class="snow10 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/e0739b4336541bec5438f20519ad7f92.png"></div>
	                <div class="snow11 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/657023cccd8ed1eb1a51f20a74964793.png"></div>
	                <div class="snow12 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/a000093ac41eb26bb162f7fee681a607.png"></div>
	                <div class="snow13 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/49909d81d26ed3a0010555fcdc19f419.png"></div>
	                <div class="snow14 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/6beecbc3f602ed73b2ef4e11c25f0d04.png"></div>
	                <div class="snow15 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/49909d81d26ed3a0010555fcdc19f419.png"></div>
	                <div class="snow16 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/b7da5800cd728702122e50f27e15ae46.png"></div>
	                <div class="snow17 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/2ae58807773afcfc5726592b6d1a5bbb.png"></div>
	                <div class="snow18 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/a000093ac41eb26bb162f7fee681a607.png"></div>
	                <div class="snow19 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/2ae58807773afcfc5726592b6d1a5bbb.png"></div>
	                <div class="snow20 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/59bf4c6a3df826919d129fa963449afb.png"></div>
	                <div class="snow21 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/59bf4c6a3df826919d129fa963449afb.png"></div>
	                <div class="snow22 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/b7da5800cd728702122e50f27e15ae46.png"></div>
	                <div class="snow23 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/9f2a956e196a4a90be697f2769fdfd71.png"></div>
	                <div class="snow24 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/071f912ace30daae1f6b70ce26b43097.png"></div>
	                <div class="snow25 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/9f2a956e196a4a90be697f2769fdfd71.png"></div>
	                <div class="snow26 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/a000093ac41eb26bb162f7fee681a607.png"></div>
	                <div class="snow27 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/59bf4c6a3df826919d129fa963449afb.png"></div>
	                <div class="snow28 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/a000093ac41eb26bb162f7fee681a607.png"></div>
	                <div class="snow29 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/48495d5949ddb4ad16246cb16726c50f.png"></div>
	                <div class="snow30 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/6beecbc3f602ed73b2ef4e11c25f0d04.png"></div>
	                <div class="snow31 snow">
	                    <img class="snow_363" src="http://resali.lititutu.cn/scenes/haidishijie/48495d5949ddb4ad16246cb16726c50f.png"></div>
	            </div>
	        </div>
	        <div class="big_fish_container">
	            <img class="fish big_fish po" src="http://resali.lititutu.cn/scenes/haidishijie/ea86dd511b71bae4915ea52d6760770c.png"></div>
	        <div class="group_fish2">
	            <div class="fish-s">
	                <img class="fish3" src="http://resali.lititutu.cn/scenes/haidishijie/4ad5a087d441b003494910a1d91bb3aa.png"></div>
	            <div class="fish-s1">
	                <img class="fish3" src="http://resali.lititutu.cn/scenes/haidishijie/4ad5a087d441b003494910a1d91bb3aa.png"></div>
	            <div class="fish-s2">
	                <img class="fish3" src="http://resali.lititutu.cn/scenes/haidishijie/4ad5a087d441b003494910a1d91bb3aa.png"></div>
	            <div class="fish-s3">
	                <img class="fish3" src="http://resali.lititutu.cn/scenes/haidishijie/4ad5a087d441b003494910a1d91bb3aa.png"></div>
	            <div class="fish-s4">
	                <img class="fish3" src="http://resali.lititutu.cn/scenes/haidishijie/4ad5a087d441b003494910a1d91bb3aa.png"></div>
	            <div class="fish-s5">
	                <img class="fish3" src="http://resali.lititutu.cn/scenes/haidishijie/4ad5a087d441b003494910a1d91bb3aa.png"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'sea',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	#now_page {
	    height: 100%;
	    width: 100%;
	    overflow: hidden;
	    position: relative;
	    -webkit-perspective:@bw * 0.951;
	    -moz-perspective:@bw * 0.951;
	    perspective:@bw * 0.951;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    user-select: none;
	}
	.container2, .containLit {
	    position: absolute;
	    width:@bw * 1.0;
	    height:@bw * 1.0;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    margin: auto;
	    transform-style: preserve-3d;
	    transform: rotateX(0deg) rotateY(0deg) translateZ(@bw * 0.0);
	    animation: sea_xuanzhuan 10s infinite linear both;
	}
	@keyframes sea_xuanzhuan {
		0% {
		    transform: rotateY(0deg);
		}
		100% {
		    transform: rotateY(360deg);
		}
	}
	.container2 .rect {
	    position: absolute;
	    width:@bw * 1.0;
	    height:@bw * 1.0;
	    top: 0;
	    left: 0;
	    overflow: hidden;
	    background-size: cover;
	    background-position: center;
	}
	.div_1 {
	    transform: rotateY(180deg) translateZ(-@bw * 0.5);
	}
	.div_2 {
	    transform: rotateX(90deg) translateZ(@bw * 0.5);
	}
	.div_3 {
	    transform: rotateX(0deg) translateZ(-@bw * 0.5);
	}
	.div_4 {
	    transform: rotateX(270deg) translateZ(@bw * 0.5);
	    background-repeat: no-repeat;
	    overflow: hidden;
	}
	.div_5 {
	    transform: rotateY(90deg) translateZ(-@bw * 0.5);
	}
	.div_6 {
	    transform: rotateY(270deg) translateZ(-@bw * 0.5);
	}
	.haicao6 {
	    width:@bw * 0.392;
	    height:@bw * 0.639;
	    bottom: -@bw * 0.165;
	    right:@bw * 0.031;
	    -webkit-transform-style: preserve-3d;
	    -webkit-transform: translate3d(@bw * 0.0,@bw * 0.0, -@bw * 0.276);
	}
	.po {
	    position: absolute;
	}
	.fanzhuan {
	    animation: sea_zhuan 10s infinite linear;
	}
	@keyframes sea_zhuan {
		0% {
		    transform: rotateY(0);
		}
		100% {
		    transform: rotateY(-360deg);
		}
	}
	.page {
	    width: 100%;
	    height: 100%;
	}
	.haicao6 .scale {
	    transform: scale(0.5);
	}
	.other_donghua_06 {
	    position: absolute;
	    width:@bw * 0.392;
	    height:@bw * 0.639;
	    transform-origin:@bw * 0.172, @bw * 0.668;
	    animation: other_donghua_05 1s infinite ease-in-out alternate;
	}
	.other_donghua {
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    margin: auto;
	}
	@keyframes other_donghua_05 {
		0% {
		    transform: rotateZ(1deg);
		}
		50% {
		    transform: rotateZ(-1deg);
		}
		100% {
		    transform: rotateZ(1deg);
		}
	}
	.haicao5 {
	    width:@bw * 0.343;
	    height:@bw * 0.311;
	    bottom: -@bw * 0.083;
	    right: 0;
	    -webkit-transform-style: preserve-3d;
	    -webkit-transform: translate3d(@bw * 0.0,@bw * 0.0, -@bw * 0.217);
	}
	.other_donghua_05 {
	    position: absolute;
	    width:@bw * 0.392;
	    height:@bw * 0.639;
	    transform-origin:@bw * 0.312@bw * 0.668;
	    animation: other_donghua_05 1s infinite ease-in-out alternate;
	}
	.haicao7 {
	    width:@bw * 0.533;
	    height:@bw * 0.575;
	    bottom: -@bw * 0.131;
	    left: -@bw * 0.094;
	    -webkit-transform-style: preserve-3d;
	    -webkit-transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.389) rotateY(0deg);
	}
	.other_donghua_02 {
	    position: absolute;
	    width:@bw * 0.392;
	    height:@bw * 0.639;
	    transform-origin:@bw * 0.312@bw * 0.668;
	    animation: other_donghua_02 1s infinite ease-in-out alternate;
	}
	@keyframes other_donghua_02 {
		0% {
		    transform: rotateZ(2deg);
		}
		50% {
		    transform: rotateZ(-2deg);
		}
		100% {
		    transform: rotateZ(2deg);
		}
	}
	.shanhu2 {
	    width:@bw * 0.533;
	    height:@bw * 0.575;
	    bottom: -@bw * 0.24;
	    left: -@bw * 0.094;
	    -webkit-transform-style: preserve-3d;
	    -webkit-transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.25) rotateY(0deg);
	}
	.shanhu3 {
	    width:@bw * 0.533;
	    height:@bw * 0.575;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    margin: auto;
	    margin-bottom: -@bw * 0.238;
	    -webkit-transform-style: preserve-3d;
	    -webkit-transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateY(0deg);
	}
	.shanhu4 {
	    width:@bw * 0.392;
	    height:@bw * 0.639;
	    bottom: -@bw * 0.304;
	    right:@bw * 0.031;
	    -webkit-transform-style: preserve-3d;
	    -webkit-transform: translate3d(-@bw * 0.694, @bw * 0.0, -@bw * 0.276);
	}
	.float_container {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	}
	.float_container img, .float_container div {
	    position: absolute;
	}
	.fish2 {
	    position: absolute;
	    top: 55%;
	    left: -@bw * 0.111;
	    animation: fish2 7s ease-out infinite;
	}
	@keyframes fish2 {
		0% {
		    transform: rotate(0deg) translate(-@bw * 0.139,@bw * 0.278);
		}
		80% {
		    transform: rotate(-10deg) translate(@bw * 1.0,@bw * 0.0);
		}
		100% {
		    transform: rotate(-10deg) translate(@bw * 1.181,@bw * 0.0);
		}
	}
	.yellow_fish {
	    position: absolute;
	    bottom: 25%;
	    left:@bw * 0.472;
	    animation: yellow_fish 5.5s ease-in-out infinite;
	}
	@keyframes yellow_fish {
		0% {
		    left: -@bw * 0.208;
		    top:@bw * 0.694;
		}
		40% {
		    left:@bw * 0.139;
		    top:@bw * 0.764;
		}
		60% {
		    left:@bw * 0.556;
		    top:@bw * 0.833;
		}
		100% {
		    left:@bw * 1.042;
		    top:@bw * 1.111;
		}
	}
	.right_shuimu {
	    position: absolute;
	    bottom: 5%;
	    left:@bw * 0.472;
	    animation: right_shuimu 8.5s linear infinite;
	}
	@keyframes right_shuimu {
		0% {
		    bottom: -@bw * 0.069;
		    left:@bw * 0.139;
		}
		100% {
		    bottom:@bw * 1.736;
		    left:@bw * 1.25;
		}
	}
	.left_shuimu {
	    position: absolute;
	    bottom: 5%;
	    left:@bw * 0.472;
	    animation: left_shuimu 10s -9s linear infinite;
	}
	@keyframes left_shuimu {
		0% {
		    bottom:@bw * 0.069;
		    left:@bw * 1.25;
		}
		50% {
		    bottom:@bw * 0.556;
		    left:@bw * 0.972;
		}
		100% {
		    bottom:@bw * 1.736;
		    left:@bw * 0.139;
		}
	}
	.top_shuimu {
	    position: absolute;
	    bottom: 5%;
	    left:@bw * 0.472;
	    animation: top_shuimu 10s linear infinite;
	}
	@keyframes top_shuimu {
		0% {
		    bottom:@bw * 0.069;
		}
		60% {
		    bottom:@bw * 1.853;
		}
		100% {
		    bottom:@bw * 1.853;
		}
	}
	
	.snow_box {
	    position: absolute;
	    width: 100%;
	    bottom: 0;
	}
	.snow1 {
		top:@bw * 0.044;
		animation:snow_fall1 9.8s linear 4s infinite normal none running;
		left:@bw * 0.632
	}
	.snow2 {
		top:@bw * 0.062;
		animation:snow_fall2 8.8s linear 2s infinite normal none running;
		left:@bw * 0.547
	}
	.snow3 {
		top:@bw * 0.039;
		animation:snow_fall2 5.7s linear -2s infinite normal none running;
		left:@bw * 0.228
	}
	.snow4 {
		top:@bw * 0.035;
		animation:snow_fall1 5.2s linear 1s infinite normal none running;
		left:@bw * 0.189
	}
	.snow5 {
		top:@bw * 0.047;
		animation:snow_fall1 6.6s linear 3s infinite normal none running;
		left:@bw * 0.647
	}
	.snow6 {
		top:@bw * 0.057;
		animation:snow_fall1 7.9s linear 4s infinite normal none running;
		left:@bw * 0.617
	}
	.snow7 {
		top:@bw * 0.064;
		animation:snow_fall2 6.8s linear 3s infinite normal none running;
		left:@bw * 0.376
	}
	.snow8 {
		top:@bw * 0.065;
		animation:snow_fall2 8.2s linear 2s infinite normal none running;
		left:@bw * 0.699
	}
	.snow9 {
		top:@bw * 0.06;
		animation:snow_fall1 9.3s linear 2s infinite normal none running;
		left:@bw * 0.412
	}
	.snow10 {
		top:@bw * 0.025;
		animation:snow_fall1 8.5s linear -3s infinite normal none running;
		left:@bw * 0.124
	}
	.snow11 {
		top:@bw * 0.047;
		animation:snow_fall1 5.2s linear 2s infinite normal none running;
		left:@bw * 0.017
	}
	.snow12 {
		top:@bw * 0.015;
		animation:snow_fall2 8.5s linear 1s infinite normal none running;
		left:@bw * 0.197
	}
	.snow13 {
		top:@bw * 0.056;
		animation:snow_fall2 9.1s linear 3s infinite normal none running;
		left:@bw * 0.844
	}
	.snow14 {
		top:@bw * 0.054;
		animation:snow_fall1 9.1s linear 4s infinite normal none running;
		left:@bw * 0.449
	}
	.snow15 {
		top:@bw * 0.031;
		animation:snow_fall1 6.3s linear 4s infinite normal none running;
		left:@bw * 0.64
	}
	.snow16 {
		top:@bw * 0.018;
		animation:snow_fall2 9.7s linear 2s infinite normal none running;
		left:@bw * 0.683
	}
	.snow17 {
		top:@bw * 0.062;
		animation:snow_fall2 9.3s linear 3s infinite normal none running;
		left:@bw * 0.639
	}
	.snow18 {
		top:@bw * 0.022;
		animation:snow_fall1 9.7s linear 3s infinite normal none running;
		left:@bw * 0.65
	}
	.snow19 {
		top:@bw * 0.061;
		animation:snow_fall2 5.8s linear 4s infinite normal none running;
		left:@bw * 0.207
	}
	.snow20 {
		top:@bw * 0.017;
		animation:snow_fall1 9.4s linear 2s infinite normal none running;
		left:@bw * 0.84
	}
	.snow21 {
		top:@bw * 0.046;
		animation:snow_fall2 9.3s linear -3s infinite normal none running;
		left:@bw * 0.278
	}
	.snow22 {
		top:@bw * 0.047;
		animation:snow_fall1 8.3s linear 4s infinite normal none running;
		left:@bw * 0.099
	}
	.snow23 {
		top:@bw * 0.047;
		animation:snow_fall1 7.5s linear -1s infinite normal none running;
		left:@bw * 0.776
	}
	.snow24 {
		top:@bw * 0.04;
		animation:snow_fall2 8.1s linear 4s infinite normal none running;
		left:@bw * 0.168
	}
	.snow25 {
		top:@bw * 0.015;
		animation:snow_fall2 8.1s linear -1s infinite normal none running;
		left:@bw * 0.14
	}
	.snow26 {
		top:@bw * 0.064;
		animation:snow_fall1 8.2s linear -4s infinite normal none running;
		left:@bw * 0.782
	}
	.snow27 {
		top:@bw * 0.042;
		animation:snow_fall2 8.3s linear 4s infinite normal none running;
		left:@bw * 0.518
	}
	.snow28 {
		top:@bw * 0.014;
		animation:snow_fall1 7.6s linear 3s infinite normal none running;
		left:@bw * 0.112
	}
	.snow29 {
		top:@bw * 0.047;
		animation:snow_fall1 9.4s linear -3s infinite normal none running;
		left:@bw * 0.207
	}
	.snow30 {
		top:@bw * 0.022;
		animation:snow_fall1 7.8s linear 1s infinite normal none running;
		left:@bw * 0.204
	}
	.snow31 {
		top:@bw * 0.019;
		animation:snow_fall2 7.4s linear 2s infinite normal none running;
		left:@bw * 0.854
	}
	@keyframes snow_fall1 {
		0% {
		    -webkit-transform: translate(@bw * 0.0, @bw * 0.0) scale(1.2);
		    opacity: 1;
		}
		80% {
		    -webkit-transform: translate(@bw * 0.222, -@bw * 0.972) scale(1.2);
		    opacity: 1;
		}
		100% {
		    -webkit-transform: translate(@bw * 0.278, -@bw * 1.389) scale(1.2);
		    opacity: 0;
		}
	}
	@keyframes snow_fall2 {
		0% {
		    -webkit-transform: translate(@bw * 0.0, @bw * 0.0) scale(1.2);
		    opacity: 1;
		}
		80% {
		    -webkit-transform: translate(-@bw * 0.111, -@bw * 1.389) scale(1.2);
		    opacity: 1;
		}
		100% {
		    -webkit-transform: translate(-@bw * 0.139, -@bw * 1.736) scale(1.2);
		    opacity: 0;
		}
	}
	#now_page {
		height:100%;
		width:100%;
		overflow:hidden;
		position:relative;
		-webkit-perspective:@bw * 0.951;
		-moz-perspective:@bw * 0.951;
		perspective:@bw * 0.951;
		-webkit-user-select:none;
		-moz-user-select:none;
		user-select:none
	}
	.wrap {
		position:absolute;
		width:100%;
		height:100%;
		transform:translateZ(@bw * 0.417)
	}
	.container2 .rect {
		position:absolute;
		width:@bw * 1.0;
		height:@bw * 1.0;
		top:0;
		left:0;
		overflow:hidden;
		background-size:cover
	}
	.container2 .rect .bg {
		position:absolute;
		top:0;
		left:0;
		width:100%
	}
	.top_bg {
		height:110%
	}
	.div_1,.div_3,.div_5,.div_6 {
		-webkit-backface-visibility:hidden
	}
	.div_1 {
		transform:rotateY(180deg) translateZ(-@bw * 0.5)
	}
	.div_2 {
		transform:rotateX(90deg) translateZ(@bw * 0.5)
	}
	.div_3 {
		transform:rotateX(0deg) translateZ(-@bw * 0.5)
	}
	.div_4 {
		transform:rotateX(270deg) translateZ(@bw * 0.5);
		background-repeat:no-repeat;
		overflow:hidden
	}
	.div_5 {
		transform:rotateY(90deg) translateZ(-@bw * 0.5)
	}
	.div_6 {
		transform:rotateY(270deg) translateZ(-@bw * 0.5)
	}
	@keyframes sea_xuanzhuan {
		from {
		transform:rotateY(0deg)
	}
	to {
		transform:rotateY(360deg)
	}
	}@keyframes sea_zhuan {
		from {
		transform:rotateY(0)
	}
	to {
		transform:rotateY(-360deg)
	}
	}.float_container img,.float_container div {
		position:absolute
	}
	.float_container {
		position:absolute;
		width:100%;
		height:100%;
		overflow:hidden
	}
	.bubblealbum {
		position:absolute
	}
	.bubblealbum.pic3 {
		left:5%;
		background:url('https://pronews.oss-cn-shenzhen.aliyuncs.com/photo/xuyuanchi/paopao10.png') no-repeat;
		background-size:100% 100%;
		animation:bubblealbum3 8s 4s linear infinite
	}
	@-webkit-keyframes bubblealbum3 {
		0% {
			transform:rotate(0deg) translate(@bw * 0.028, -@bw * 0.139) scale(0)
		}
		5% {
			transform:rotate(0deg) translate(@bw * 0.028, -@bw * 0.139) scale(.9)
		}
		20% {
			transform:rotate(8deg) translate(@bw * 0.028, -@bw * 0.417) scale(.9)
		}
		40% {
			transform:rotate(-10deg) translate(@bw * 0.028, -@bw * 0.556) scale(.9)
		}
		60% {
			transform:rotate(2deg) translate(@bw * 0.028, -@bw * 0.833) scale(.9)
		}
		80% {
			transform:rotate(0deg) translate(@bw * 0.028, -@bw * 1.111) scale(.9)
		}
		100% {
			transform:rotate(5deg) translate(@bw * 0.028, -@bw * 1.639) scale(.9)
		}
	}
	.bubblealbum.pic4 {
		left:30%;
		background:url('https://pronews.oss-cn-shenzhen.aliyuncs.com/photo/xuyuanchi/paopao10.png') no-repeat;
		background-size:100% 100%;
		animation:bubblealbum4 8s 8s linear infinite
	}

	.big_fish {
	    position: absolute;
	    bottom: 21%;
	    left:@bw * 0.472;
	    animation: big_fish 10s ease-in infinite;
	}
	@keyframes big_fish {
		0% {
		    transform: rotate(0deg) translate(-@bw * 0.833,@bw * 0.101);
		}
		20% {
		    transform: rotateY(-10deg) translate(-@bw * 0.111,@bw * 0.314);
		}
		25% {
		    transform: rotateY(-10deg) translate(-@bw * 0.111,@bw * 0.314);
		}
		60% {
		    transform: rotateY(-10deg) translate(@bw * 2.057,-@bw * 0.111);
		}
		100% {
		    transform: rotateY(-10deg) translate(@bw * 2.057,-@bw * 0.111);
		}
	}
	.big_fish_container {
	    position: absolute;
	    top: -@bw * 0.012;
	    left: -@bw * 0.146;
	    width: 130%;
	    height: 100%;
	    z-index: -1;
	    transform: translateZ(@bw * 0.0) scale(.6);
	}
</style>



