<template>
	<div class="poc">

		<!-- wing -->
		<div class="wing po" v-if="photo['wing'] ">
			<div class="wing-left po">
				<img class="po" src="http://resali.lititutu.cn/icons/other/wing.png">
			</div>
			<img class="wing-right po" src="http://resali.lititutu.cn/icons/other/wing.png">
		</div>

		<!-- photo -->
		<template v-if="photo['photo_mode'] && photo['photo_mode']['mode'] == 'frame'">

			<div v-for="(elems, index) in photo['photo_mode']['elems']" v-if="frameShowAble(index)">
				
				<template v-if="$util.typeOf(elems) == 'object'">
					<elem :mode="elems" :currentImage="currentImage"></elem>
				</template>
				<template v-else-if="$util.typeOf(elems) == 'array'">
					<elem v-for="(elem, index) in elems" :mode="elem" :currentImage="currentImage"></elem>
				</template>

			</div>
		</template>
		<template v-else>
			<img class="poc simple-img simple-border" :src="currentImage" :class="borderClass" :style="borderStyle">
		</template>

		<!-- elems & gif -->
		<template v-if="fillMode == 'contain'">
			<template v-for="mode in elems">
				<elem :mode="mode"></elem>
			</template>

			<template v-for="mode in gifs">
				<gif :mode="mode"></gif>
			</template>
		</template>

	</div>
</template>

<script>
	export default {
		name: 'SimpleImg',
		props: ['photo', 'line', 'fillMode', 'elems', 'gifs'],
		data () {
			return {}
		},
		components: {},
		computed: {
			currentImage () {
				return this.line['photo']['src'];
			},
			borderType () {
				if (this.photo['border'] && this.photo['border']['im']) {
					return 'image';
				} else {
					return 'css';
				}
			},
			borderClass () {
				let _class = {};

				// 图片倒影
				if (this.photo['reflect']) {
					_class['simple-reflect'] = true;
				}

				return _class;
			},
			borderStyle () {
				let _style = {};

				if (!this.photo['border']) {
					return _style;
				}

				if (this.photo['border']['width']) {
					_style['borderWidth'] = this.photo['border']['width'] + 'px';
				} else {
					_style['borderWidth'] = '0';
				}

				_style['borderWidth'] = this.photo['border']['width'] ? this.photo['border']['width'] + 'px' : '0';

				_style['borderColor'] = this.photo['border']['color'] ? this.photo['border']['color'] : 'white';

				if (this.fillMode == 'contain') {
					_style['borderRadius'] = this.photo['border']['radius'] ? this.photo['border']['radius'] : '0';
				}

				if (this.borderType == 'image') {
					_style['borderImageSource'] = 'url(' + this.photo['border']['im'] + ')';
			        _style['borderImageSlice'] = this.photo['border']['imageSlice'];
			        _style['borderImageWidth'] = this.photo['border']['imageWidth'];
			        _style['borderImageOutset'] = this.photo['border']['imageOutset'];
			        _style['borderImageRepeat'] = this.photo['border']['imageRepeat'];
				}

				return _style;
			}
		},
		methods: {
			frameShowAble (idx) {
				return this.line['idx'] % this.photo['photo_mode']['elems'].length == idx;
			}
		}
	}
</script>

<style scoped>
	/*.simple-img {
		background-color: #eee;
	}*/
	.wing {
		width: 980px;
		height: 827px;
		top: 45%;
		left: 50%;
		transform: translate(-50%,-50%);
		perspective: 600px;
	}
	.wing-left {
		width: 544px;
		height: 900px;
		transform-origin: 100% 50%;
		animation: wing_left 1.8s ease-in-out infinite alternate;
	}
	@keyframes wing_left {
		0% {
		    transform: rotateY(30deg);
		}
		100% {
		    transform: rotateY(-25deg);
		}
	}
	.wing-left>img {
		width: 100%;
		height: 100%;
		transform: rotateY(180deg);
	}
	.wing-right {
		width: 544px;
		height: 900px;
	    right: 0;
	    transform-origin: 0% 50%;
	    animation: wing_right 1.8s ease-in-out infinite alternate;
	}
	@keyframes wing_right {
		0% {
		    transform: rotateY(-30deg);
		}
		100% {
		    transform: rotateY(25deg);
		}
	}
	.border-image {
		z-index: 2;
	}
	.simple-border {
		border-style: solid;
	}
	.simple-reflect {
		-webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(255,255,255,0.3) 0%, transparent 40%, transparent 100%);
	}
	.simple-reflect:before {
		content:"";
		background: linear-gradient(top, white, white 30%, rgba(255,255,255,0.9) 65%, rgba(255,255,255,0.7)) 0px 0px;
		/*百分比为调倒影的透明度*/
		transform: scaleY(-1);
		padding: 1px 0px;
		background-origin: padding-box, content-box;
		background-clip: padding-box, content-box;
		position:absolute;
		height:340px;
		width: 340px;
		top: 340px;
		left:0px;
	}
</style>