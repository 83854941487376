<template>
	<div class="dong10-page">
		<div class="dong10-1 blurImage" :style="{backgroundImage: imageStyle['backgroundImage']}" @click="wxPreview"></div>
		<div class="dong10" :style="imageStyle" @click="wxPreview"></div>
	</div>
</template>

<script>
	export default {
		name: 'dong10',
		props: ['mode', 'currentImage'],
		data () {
			return {
				imageStyle: {
					top: '50%',
					left: '0px',
					width: '0px',
					height: '0px',
					marginTop: '0px',
					backgroundImage: 'url()'
				}
			}
		},
		watch: {
			currentImage () {
				this.analyseImage();
			}
		},
		mounted () {
			this.analyseImage();
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			},
			analyseImage () {
				let twidth = this.$env.bw,
					theight = this.$env.bw * 1.25;
				this.imageStyle['width'] = twidth + 'px';
				this.imageStyle['height'] = theight.toString() + 'px';
				this.imageStyle['marginTop'] = -(theight/2) + 'px';
				this.imageStyle['backgroundImage'] = 'url('+ this.currentImage['im'] +')';
				// let im = new Image(),
				// 	_this = this;
				// im.onload = function() {
				// 	if (this.height/this.width > 1.25) {
				// 		let twidth = _this.$env.bw,
				// 			theight = twidth*this.height/this.width;
				// 		_this.imageStyle['width'] = twidth + 'px';
				// 		_this.imageStyle['height'] = theight + 'px';
				// 		_this.imageStyle['marginTop'] = -(theight/2) + 'px';
				// 	} else {
				// 		let theight = _this.$env.bw*1.25;
				// 		_this.imageStyle['width'] = theight*this.width/this.height + 'px';
				// 		_this.imageStyle['height'] = theight + 'px';
				// 		_this.imageStyle['marginTop'] = -(theight/2) + 'px';
				// 	}
				// 	_this.imageStyle['backgroundImage'] = 'url('+_this.currentImage['im']+')';
				// }
				// im.src = this.currentImage['im'];
			}
		}
	}
</script>

<style scoped>
	.dong10-1 {
		position: absolute;
		animation: 10s linear 0s 1 normal both running div21h-in;
	}
	@keyframes div21h-in {
		0% {
		    transform: scale(2);
		    opacity: 0.8;
		}
		20% {
		    transform: scale(2.04);
		    opacity: 1;
		}
		80% {
		    transform: scale(2.16);
		    opacity: 1;
		}
		100% {
		    transform: scale(2.2);
		    opacity: 0.8;
		}
	}
	.dong10 {
		position: absolute;
		background-position: center center;
	    background-size: contain;
	    background-repeat: no-repeat;
		animation: 10s linear 0s 1 normal both running div2-in;
	}
	@keyframes div2-in {
		0% {
		    transform: translate(65px,0px) scale(1.2);
		    opacity: 0;
		}
		20% {
		    transform: translate(45px,0px) scale(1.2);
		    opacity: 1;
		}
		80% {
		    transform: translate(-45px,0px) scale(1.2);
		    opacity: 1;
		}
		100% {
		    transform: translate(-65px,0px) scale(1.2);
		    opacity: 0;
		}
	}
</style>