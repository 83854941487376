<template>
    <div class="full-bg poc">
        <!-- elem -->
        <elem :mode="getElemBg" v-if="isGifShow"></elem>

        <!-- 静态图 -->
        <div class="bg poc" :style="getStaticBg"></div>
    </div>    
</template>

<script>
    export default {
        name: 'FullBg',
        props: ['mode', 'currentScene'],
        data () {
            return {
                isGifShow: true
            }
        },
        computed: {
            currentMode () {
                let mode = {};
                if (this.mode['mode'][this.currentScene]) {
                    mode = this.mode['mode'][this.currentScene];
                }
                return mode;
            },
            getStaticBg () {
                let static_bg = {};
                if (this.$util.typeOf(this.currentMode[0]) == 'string') {
                    static_bg = {
                        backgroundImage: 'url('+ this.currentMode[0] +')'
                    }
                }
                return static_bg;
            },
            getElemBg () {
                let gif_bg = {};
                if (this.$util.typeOf(this.currentMode[0]) == 'object') {
                    gif_bg = this.currentMode[0];
                }
                this.isGifShow = false;
                this.$nextTick(() => {
                    this.isGifShow = true;
                })
                return gif_bg;
            }
        }
    }
</script>

<style>
    
</style>