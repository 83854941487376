<template>
    <div class="page">
        <div class="luoxuan-con">
            <div class="luoxuan-box" v-if="canIShow">
                <div
                    v-for="(idx, index) in 20" 
                    :key="'luoxuan-item_'+index"
                    class="luoxuan-item" 
                    :class="'luoxuan-item_'+index"
                    :style="getLuoxuanItemStyle">
                    <div class="page bg bg_photo" :style="getLuoxuanStyles"></div>
                </div>
            </div>
            <elem v-if="comp['elems']" :canIShow="canIShow" :mode="comp['elems'][0]" :currentPhoto="currentPhoto"></elem>
            <elem v-else-if="comp_config[0]" :canIShow="canIShow" :mode="comp_config[0]" :currentPhoto="currentPhoto"></elem>
        </div>
    </div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: 'Luoxuan',
        extends: BaseElem,
        data () {
            return {}
        },
        computed: {
            getLuoxuanStyles () {
                let s = this.getStyles;
                s['backgroundImage'] = 'url('+ this.currentPhoto['im'] +')';
                return s;
            },
            getLuoxuanItemStyle () {
                let s = {};
                s['border'] = "3px solid " + (this.comp_config[1] || "#fa50b9");
                return s;
            }
        }
    }
</script>

<style>
    .luoxuan-con {
        position: absolute;
        /* top: 34.7px; */
        /* left: 148px; */
        width: 100%;
        height: 100%;
        perspective: 694px;
        transform-style: preserve-3d;
    }
    .luoxuan-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 208px;
        height: 208px;
        top: 20%;
        left: 50%;
        margin-left: -104px;
        /* margin-top: -104px; */
        /* margin: 104px auto; */
        transform-style: preserve-3d;
        animation: luoxuan_rotate 10s reverse linear infinite;
    }
    @keyframes luoxuan_rotate {
        0% {
            transform: rotateY(0);
        }
        100% {
            transform: rotateY(360deg);
        }
    }
    .luoxuan-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        /* border: 3px solid #fa50b9; */
    }
    .luoxuan-item_0 {
        transform: rotateY(-324deg) skewY(13deg) translateY(-236px) translateZ(208px);
    }
    .luoxuan-item_1 {
        transform: rotateY(-270deg) skewY(13deg) translateY(-187.5px) translateZ(208px);
    }
    .luoxuan-item_2 {
        transform: rotateY(-216deg) skewY(13deg) translateY(-139px) translateZ(208px);
    }
    .luoxuan-item_3 {
        transform: rotateY(-162deg) skewY(13deg) translateY(-90px) translateZ(208px);
    }
    .luoxuan-item_4 {
        transform: rotateY(-108deg) skewY(13deg) translateY(-42px) translateZ(208px);
    }
    .luoxuan-item_5 {
        transform: rotateY(-54deg) skewY(13deg) translateY(7px) translateZ(208px);
    }
    .luoxuan-item_6 {
        transform: rotateY(0deg) skewY(13deg) translateY(56px) translateZ(208px);
    }
    .luoxuan-item_7 {
        transform: rotateY(54deg) skewY(13deg) translateY(104px) translateZ(208px);
    }
    .luoxuan-item_8 {
        transform: rotateY(108deg) skewY(13deg) translateY(153px) translateZ(208px);
    }
    .luoxuan-item_9 {
        transform: rotateY(162deg) skewY(13deg) translateY(201px) translateZ(208px);
    }
    .luoxuan-item_10 {
        transform: rotateY(216deg) skewY(13deg) translateY(250px) translateZ(208px);
    }
    .luoxuan-item_11 {
        transform: rotateY(270deg) skewY(13deg) translateY(299px) translateZ(208px);
    }
    .luoxuan-item_12 {
        transform: rotateY(324deg) skewY(13deg) translateY(347px) translateZ(208px);
    }
    .luoxuan-item_13 {
        transform: rotateY(378deg) skewY(13deg) translateY(396px) translateZ(208px);
    }
    .luoxuan-item_14 {
        transform: rotateY(432deg) skewY(13deg) translateY(444px) translateZ(208px);
    }
    .luoxuan-item_15 {
        transform: rotateY(486deg) skewY(13deg) translateY(493px) translateZ(208px);
    }
    .luoxuan-item_16 {
        transform: rotateY(540deg) skewY(13deg) translateY(542px) translateZ(208px);
    }
    .luoxuan-item_17 {
        transform: rotateY(594deg) skewY(13deg) translateY(590px) translateZ(208px);
    }
    .luoxuan-item_18 {
        transform: rotateY(648deg) skewY(13deg) translateY(639px) translateZ(208px);
    }
    .luoxuan-item_19 {
        transform: rotateY(702deg) skewY(13deg) translateY(688px) translateZ(208px);
    }
    .luoxuan-item_20 {
        transform: rotateY(756deg) skewY(13deg) translateY(736px) translateZ(208px);
    }
</style>