<template>
	<div class="mine">
		<div class="mine-hd container" :style="{height: $store.state.tips.collection?'170px':'125px'}">
			<!-- <CollectionTips :currentPage="currentPage"></CollectionTips> -->
			<div class="mine-hd__bg cover-image-container container">
				<img
					class="cover-image"
					mode="aspectFill"
					:src="covers[(current_cover_idx + 1) % covers.length]" />
				<template v-for="(item, index) in covers">
					<img
						v-if="index == current_cover_idx"
						:key="item"
						class="cover-image-animate"
						:src="item" />
				</template>
			</div>
			<div class="mine-hd__mask"></div>
			<div class="re-container" style="width: 100%;">
				<div class="mine-item" style="width: 30%;">
					<img class="mine-hd__headimg" :src="headimgurl" alt="." v-show="headimgurl" @click="refetchUserInfo">
				</div>
				<div class="mine-item" style="width: 40%;">
					<div class="mine-hd__timearea">
						<span class="mine-hd__week">{{ week }}</span>
						<br>
						<span class="mine-hd__date">{{ date }}</span>
					</div>
				</div>
				<div class="mine-item" style="width: 30%;">
					<div class="mine-report" @click="toReport">
						<i class="fa fa-commenting"></i> 问题反馈
					</div>
				</div>
			</div>
		</div>

		<div class="mine-p">
			<img class="mine-p_icon" src="http://litiresource.xcgogo.site/user/6403275/images/ef4fc67c5526bc369f54a6e1db6e820d.png">
			<span class="mine-p_text">我的相册</span>
			<div class="mine-bin" @click="onBinClick">
				<img class="mine-bin_icon" src="http://resali.lititutu.cn/icons/app/del.png">
				<span class="mine-bin_text">回收站</span>
				<st-open-tag
					originid="gh_37a75119d615"
					path="subpackages/recycle/recycle"
					html='<div style="width: 200px; height: 30px;"></div>'>
				</st-open-tag>
				
				<!-- <div v-if="toWxaAble" class="poc" style="z-index: 1;">
					<wx-open-launch-weapp username="gh_37a75119d615" path="subpackages/recycle/recycle.html">
						<script type="text/wxtag-template"><style>.recycle_bin { width: 200px; height: 30px; top: 0; left: 0; }</sty;e>
<div class="recycle_bin"></div></script>
					</wx-open-launch-weapp>
				</div>
				<div v-else class="poc" style="z-index: 1;" @click="onBinClick"></div> -->
			</div>
		</div>
		<div class="mine-bd">
			<template v-for="(item, index) in mybook.items">
				<div
					v-if="item.create"
					:key="'mine-bd_item_'+index"
					class="text-decoration-container re-container">
					<div class="text-decoration"></div>
					<span class='text-container'>{{item.create}}</span>
				</div>
				<BookCell :key="'mine-bd_cell_'+index" :book="item" :index="index" :headimgurl="headimgurl" @onBookEventHandle="onBookEventHandle"></BookCell>
				<!-- <span class="book-info-text">来自{{wxa_appids.indexOf(item.value.appid) > -1 ? '小程序' : '公众号'}}：{{item.mpname}}</span> -->
			</template>
			<div class="loading">
                <div class="l-preload" v-show="mineLoadStatus == 0">
                    ·下拉加载更多·
                </div>
                <div class="l-loading" v-show="mineLoadStatus == 1">
                    <img src="http://resali.lititutu.cn/icons/app/loading2.gif">
                </div>
                <div class="l-loaded" v-show="mineLoadStatus == 2">
                    <span>没有更多了</span>
                </div>
            </div>
		</div>
		<div class="mine-ft"></div>
		<MineEmpty v-show="isBookEmpty"></MineEmpty>

		<div class="login-modal_contain" v-show="isLoginShow">
			<div class="login-modal">
				<img @click="onLoginClose" class="login-close" src="http://resali.lititutu.cn/icons/app/close.png">
				<div class="login-btns">
					<div @click="onLoginConfirm" class="login-confirm">
						立即登录
					</div>
					<div @click="onLoginCancel" class="login-cancel">
						不再提醒
					</div>
				</div>
			</div>
		</div>

		<BookMenu ref="book_menu" @onBookEventHandle="onBookEventHandle"></BookMenu>

		<PromoScan v-show="isPromoScanShow" 
		:promoFingerImage="promoFingerImage" @onPromoScanHide="onPromoScanHide" :promoQrcode="promoQrcode" promoFrom="downphoto"></PromoScan>

	</div>	
</template>

<script>
	import U from '@/util'

	import BookCell from './BookCell.vue'
	import BookMenu from './BookMenu.vue'
	import MineEmpty from './MineEmpty.vue'
	import PromoItem from '@/components/promo/PromoItem.vue'
	import PromoScan from '@/components/promo/PromoScan.vue'
	import CollectionTips from '@/views/tips/CollectionTips.vue'
	import StOpenTag from '@/components/st_comp/StOpenTag'
	
	let COVER_TIMER,
		LOADING = false;

	export default {
		name: 'Mine',
		props: ['currentPage'],
		watch: {
			currentPage: {
				handler () {
					if (this.currentPage == 'mybook') {
						if (this.mybook.items.length == 0) {

							// 登录逻辑
							this.$util.rget('get_author', {
								appid: this.$route.query._source,
								openid: this.$route.query.openid
							}).then( authorid => {
								
								this.buildItem();

								// 记录pv
								// this.$o_util.log('mybook');
								// this.$mta.click('pv_mybook');
								
								// byhz 22.12.5 停止做登陆判断
								// byhz 22.12.7 恢复登录判断
								this.login(this.$route.query._source, this.$route.query.openid, authorid).then(() => {
									
									this.buildItem();

									// 记录pv
									this.$o_util.log('mybook');
									this.$mta.click('pv_mybook');
								});
							});

							// 获取用户头像
							this.fetchUserInfo(false);
						};

						// 微信分享设置
						this.$wx.share({
							title: '邀请你制作甜豆相册',
							link: window.location.protocol + '//' + window.location.host + '/vliti/login?target=mybook',
							imgUrl: 'http://resali.lititutu.cn/tuku/prod/e3124a15b00bb83e2ec4ff42e0a2f60e.jpg',
							success: () => {},
							from: 'mine'
						});
					};
				},
				immediate: true
			},
			headimgurl () {
				this.refetchUserInfo();
			}
		},
		data () {
			return {
				wxa_appids: ['wx2fb7317f6bc5b387', 'wxcd236d6f280a1644', 'wxdae27e027cadf4c6', 'wxa52d8e4c620f0e4e', 'wxfd8adde97c295a6d', 'wx5f5d3f265242e546'],

				isPhotoDownShow: false,
				headimgurl: false,

				user_info: {},

				mineLoadStatus: 0,
				isBookEmpty: false,
				isLoginShow: false,

				isQrcodeShow: true,
				binQrcode: '',

				current_cover_idx: -1,
				covers: [],
				book_times: [],

				isPromoScanShow: false,
				promoFingerImage: '',
				promoQrcode: '',
				mybook: {
					items: [],
					pagenum: 0,
				}
			}
		},
		components: { BookCell, BookMenu, MineEmpty, PromoItem, PromoScan, CollectionTips, StOpenTag },
		computed: {
			date: function() {
				var date = new Date();
				var year = date.getFullYear(),
					month = date.getMonth() + 1,
					sdate = date.getDate();
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (sdate >= 0 && sdate <= 9) {
					sdate = "0" + sdate;
				}
				return year + "年" + month + "月" + sdate + "日";
			},
			week: function() {
				var date = new Date();
				var weekday = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"];
				return weekday[date.getDay()];
			},
		},
		mounted () {
			// 初始化第一次数据请求
			this.__scroll();
		},
		created () {
			this.$util.webpChecker();
		},
		methods: {
			login (appid, openid, authorid) {
				return new Promise((resolve, reject) => {
					// 当环境为微信内h5页面时登陆
					if (this.$o_util.isWeiXin() && this.$env.web != 'wxa') {
						loginManager.run(appid, openid, authorid, state => {
							if (!state) {
								// 若登录未完成，则不进行后续逻辑
								reject();
							} else {
								resolve();
							}
						})
					} else {
						resolve();
					}
				})
			},
			onBookEventHandle (book, index, btn_type){
				if (btn_type == 'more'){
					this.$refs.book_menu.display(book, index);	
				} else if(btn_type == 'share'){
					// 分享相册
					this.onShare(book['key'], book['value'], book['version']);
				} else if(btn_type == "del"){
					// 删除相册
					this.onBookRemove(index, book);
				} else if(btn_type == "extract"){
					// 提取照片
					this.onPromoScanShow(book['key']);
				} else if(btn_type == "download") {
					// 下载相册
					this.onDownloadClick(book['key']);
				} else {
					// 打开相册
					this.onBookJump(book['key'], book['value'], '', book['version']);
				}
			},
			onBookRemove (index, item) {
				var that = this;
				this.$dialog.confirm({
					title: '注意',
					message: '<img style="position: relative;width: 100%;height: 100%;" src="http://litiresource.xcgogo.site/user/5980171/images/fcd9a81513f948c576c3ee8beeed4340.jpg" />',
				})
				.then(() => {
					if (item['version'] == '2') {
						// 新版删除 
						that.$util.rget('/vliti/delbook', {
							token: item['token'],
							key: item['key'],
						})
						.then(() => {
							// this.$store.commit('delMybookItems', {'index': index});
							that.mybook.items.splice(index, 1)
							that.$toast.text({
								message: '删除成功',
								duration: 1
							})
						})
						.catch(() => {
							this.$util.rpost('/api/logerror', {
								'errormsg': JSON.stringify({
									'msg': 'delbook fail',
									'app': 'vliti',
									'lineNo': '',
									'columnNo': '',
									'other': {
										'log_array': '1',
										'data': {
											'url': window.location.href,
											'key': item['key']
										}
									}
								}),
								'useragent': 'useragent'
							}).then(() => {})
							this.$toast.text({
								message: '删除失败，请稍后再试',
								duration: 2
							})
						})
					} else {
						that.$util.rget('delbook', {
							token: item['token'],
							key: item['key']
						})
						.then(() => {
							// this.$store.commit('delMybookItems', {'index': index});
							that.mybook.items.splice(index, 1)
							this.$toast.text({
								message: '删除成功',
								duration: 1
							})
						})
					}
					this.$mta.click('mybook_btn_delete', {'confirm': 'true'});
				}, () => {
					console.log('取消');
					this.$mta.click('mybook_btn_delete', {'cancel': 'true'});
				})
			},
			bookNotFind () {
				window.location.href = "/liti/mybookweb";
			},
			toReport () {
				window.location.href = "/public/apps_report?__app__=liti&redirect_url="+encodeURIComponent(window.location.href)+'&__appid__='+this.$route.query._source+'&openid='+this.$route.query.openid;
			},
			refetchUserInfo () {

				let _this = this;

				let imageSrc = this.headimgurl;

				if (!imageSrc) {
					this.fetchUserInfo(true);
				} else {
					let im = new Image();
					im.onload = function() {
						if (120 == this.width && 120 == this.height) {
							_this.fetchUserInfo(true);
						}
					}
					im.onerror = function() {}
					im.src = imageSrc;

				}
			},
			fetchUserInfo (isRefetch) {
				return new Promise((resolve, reject) => {
					let url = 'get_user_info'

					if (isRefetch) {
						url = 'https://alifun.litiskr.cn/api/get_user_info';
					}

					this.$util.rget(url, {
						appid: this.$route.query._source,
						openid: this.$route.query.openid,
						app: 'vliti'
					})
					.then((response) => {
						if (response['headimgurl']) {
							response['headimgurl'] = response['headimgurl'].replace('thirdwx.qlogo.cn', 'wxhead.lititutu.cn');
						}
						if (response['errcode']) {
							reject();
						} else {
							this.headimgurl = response['headimgurl'];
							resolve();
						}
					})
				})
			},
			buildItem () {
				if (LOADING) {
					return new Promise(( resolve, reject ) => {resolve()}) ;
				}
				LOADING = true;
				this.mineLoadStatus = 1;
				let _this = this,
					covers = [];
				return new Promise(( resolve, reject ) => {
					_this.$util.rget('get_books', {
						'source': _this.$route.query._source,
						'openid': _this.$route.query.openid,
						'pagenum': _this.mybook.pagenum
					})
					.then((response) => {
						// 若未关联unionid，则补足
							// ||'LOGIN_CANCEL' == response['union_status'] 
						if (('NO_UNIONID' == response['union_status'] 
							|| null == response['union_status']) && _this.$route.query.is_manager != 1 && _this.mybook.pagenum == 0) {
							// window.location.href = "/vliti/unionidfetcher?_source="+_this.$route.query._source+"&openid="+_this.$route.query.openid;
							_this.isLoginShow = true;
						}

						if (response['books'].length == 0 && _this.mybook.pagenum == 0) {
							_this.mineLoadStatus = 3;
							_this.isBookEmpty = true;
							resolve();
							return;
						}

						if (response['books'].length == 0) {
							_this.mineLoadStatus = 2;
							LOADING = false;
							resolve();
							return;
						}

						let books = [];
						for(let i=0,len=response['books'].length; i<len; i++) {
							
							let book = response['books'][i];
							// 处理appid、openid为空的情况
							if (!book['value']['appid'] || !book['value']['openid'] || book['key'] == 'dcd360845c239eadf861188e69a13473') {
								this.$util.rget('fit_appid_openid_to_value', {
									__appid__: _this.$route.query._source,
									__openid__: _this.$route.query.openid,
									key: book['key']
								})
								.then((res) => {
									let token = res.token;
								})
							}

							// 处理相册封面
							if(book.cover) {
								if ('string' == _this.$util.typeOf(book.cover)) {
									book.cover = {
										'url': _this.$util.im(book.cover, 175),
									}
									if(_this.mybook.pagenum == 0){
										covers.push( book.cover.url );
									}
								} else {
									book.cover['url'] = _this.$util.im(book.cover, 175);
									if(_this.mybook.pagenum == 0){
										covers.push( _this.$util.im(book.cover, 500) );
									}

								}
							}

							// 处理相册时间
							if (_this.book_times.indexOf(book.create) > -1) {
								book.create = null;
							} else {
								_this.book_times.push(book.create);
							}

							// 若页面中已存在相同的key，则跳过 2021.12.13 弥补并发导致的redis中缓存多余key
							if (this.__bookExist(book)) {
								continue;
							}

							// response['books'][i] = book;
							books.push(book);
						}

						// 开始轮播封面图
						if(covers && _this.mybook.pagenum == 0){
							_this.buildCovers(covers)
						}

						_this.mybook.pagenum = response['pagenum'];
						// _this.$store.commit('setMybookPagenum', {'pagenum': response['pagenum']});
						_this.$set(_this.mybook, 'items', _this.mybook.items.concat(books))
						// _this.$store.commit('setMybookItems', {'items': _this.mybook.items.concat(books)})
						
						_this.mineLoadStatus = 0;
						
						LOADING = false;

						resolve();

						// 若第一次请求books长度小于5个，则直接发起第二页请求，避免当只有一个book导致的无法继续触发页面滚动的bug
						if (books.length < 5 && _this.mybook.pagenum == 1) {
							_this.buildItem();
						}

					})
					.catch((err) => {
						LOADING = false;
						console.error(err);
					})
				})
			},
			onShare (key, value, version) {
				let appid = value['appid'],
					type = value['type'];
				this.$dialog.alert({
					title: '提示',
					message: '进入相册后，点击右上角即可分享',
				})
				.then(() => {
					this.__onBookOpen(value, key, '', version);
				});
				this.$mta.click('mybook_btn_share');
			},
			onPromoScanShow (key) {
				// let node = e.target;

				this.promoFingerImage = 'http://litiresource.xcgogo.site/user/5980171/images/76432c925159c3bedec19d7cbeb1c94c.gif';

				this.$toast.loading({
					message: '加载中...',
					duration: 'infinite'
				})

				let _this = this;

				_this.$o_util.getWxaQrcode(key).then((qrcode) => {
					_this.isPromoScanShow = true;
					_this.promoQrcode = qrcode;
					_this.$toast.clear();
				})

				this.$mta.click('mybook_btn_downphoto');
			},
			onPromoScanHide () {
				this.isPromoScanShow = false;
			},
			onDownloadClick (key) {
				this.$mta.click("btn_to_downloadbook");

				this.$toast.loading({
					message: '加载中...',
					duration: 'infinite'
				});

				this.$o_util.getWxaQrcode(key, 'pages/savePage/savePage').then((qrcode) => {
					this.$toast.clear();

					this.$toast.qrcode({
						isMask: true,
						bgSrc: 'http://resali.lititutu.cn/icons/app/download_bg.png',
						qrcodeSrc: qrcode
					})
				});
			},
			onBookJump (key, value, jumptype, version) {
				
				this.__onBookOpen(value, key, jumptype, version);
			},
			__onBookOpen (value, key, jumptype, version) {
				let appid = value['appid'];
				let host = window.location.host;

				if (version && version.toString() == '2') {
					if (jumptype == 'editimage') {
						this.$mta.click('mybook_btn_addpic');
						window.location.href = 'http://'+host+"/vliti/main/"+(this.$store.state.appConfig.editor_path)+"?key="+key+"&_source="+appid+"&action=editimage&pfrom=mybook&vscene=2005";
					} else {
						this.$mta.click('mybook_btn_open');
						window.location.href = 'http://'+host+"/vliti/main/"+(this.$store.state.appConfig.editor_path)+"?key="+key+"&_source="+appid+"&pfrom=mybook&vscene=2005";
					}
				} else {
					var url = "/litiv/main?key=" + key + "&_source=" + appid + "&pfrom=mybook&vscene=2005";
					if (jumptype == 'share') {
						url = "http://" + host + "/litiv/share?key=" + key + "&_source=" + appid + "&st=2&pfrom=mybook&vscene=2005";
					} else if (jumptype == 'editimage') {
						url = "http://" + host + "/litiv/main?key=" + key + "&_source=" + appid + "&action=editimage&pfrom=mybook&vscene=2005";
					}
					window.location.href = url;
				}
			},
			__scroll () {
				var that = this;
				window.addEventListener('scroll', () => {
					if (this.currentPage != 'mybook') {
						return;
					}
					let bottomOfWindow = that.__getScrollHeight() - that.__getWindowHeight() - that.__getDocumentTop() < 200;

					if (bottomOfWindow && that.mineLoadStatus < 2) {
						that.buildItem().then(() => {})
					}
				}, true);
			},
            __getDocumentTop () {
                var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
				console.log("scrollTop==>",scrollTop)
                return scrollTop;
            },
            __getScrollHeight () {
                var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
				console.log("scrollHeight==>",scrollHeight)
                return scrollHeight;
            },
            __getWindowHeight () {
                var windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
				console.log("windowHeight==>",windowHeight)
                return windowHeight;
            },
			__bookExist (book) {
				for (let i=0; i<this.mybook.items.length; i++) {
					if (this.mybook.items[i]['key'] == book.key) {
						return true;
					}
				}
				return false;
			},
            onLoginConfirm () {
            	window.location.href = "/vliti/unionidfetcher?_source="+this.$route.query._source+"&openid="+this.$route.query.openid;
            },
            onLoginClose () {
            	this.isLoginShow = false;
            },
            onLoginCancel () {
            	this.$util.rget('setuserdata', {
            		'appid': this.$route.query['_source'],
            		'openid': this.$route.query['openid'],
            		'k': 'login_status',
            		'v': 2 // 0未登录/1已登录/2拒绝登录
            	});
            	this.isLoginShow = false;
            	this.$toast.text({
            		message: '不再提醒',
            		duration: 2
            	})
            },
            onBinClick () {
            	this.$mta.click('mybook_bin');

            	this.$o_util.getWxaQrcode('default', 'subpackages/recycle/recycle').then((qrcode) => {
	            	this.$toast.qrcode({
	            		isMask: true,
	            		bgSrc: 'http://resali.lititutu.cn/icons/app/qrcode_bin_bg.png',
	            		qrcodeSrc: qrcode
	            	})
            	});
            },
            onBinClose () {
            	// this.isQrcodeShow = false;
			},
			buildCovers: function(covers){
				if(!covers || covers.length < 1){
					return;
				}
				let _this = this;
				this.covers = covers;
				this.current_cover_idx = 0;
				COVER_TIMER = setInterval(() => {
					let current_cover_idx = _this.current_cover_idx + 1;
					current_cover_idx = current_cover_idx % covers.length;
					_this.current_cover_idx = current_cover_idx;
				}, 8000)
			},
		}
	}
</script>

<style scoped lang="less">
	.login-modal_contain {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0; left: 0;
		background-color: rgba(0,0,0,.3);
		z-index: 999999;
	}
	.login-modal {
		position: absolute;
		width: 320px;
		height: 352px;
		top: 50%;
		left: 50%;
		margin-top: -176px;
		margin-left: -160px;
		background-position: 0 0;
		background-size: 100% 100%;
		background-image: url(http://resali.lititutu.cn/icons/app/login_icon_1.png);
	}
	.login-btns {
		position: absolute;
		width: 100%;
		height: 100px;
		// background-color: red;
		left: 0;
		bottom: 0;
	}
	.login-confirm {
		position: absolute;
		width:261px;
		height:45px;
		left: 50%;
		margin-left: -130.5px;
		background:linear-gradient(180deg,rgba(252,99,38,1) 0%,rgba(255,54,114,1) 100%);
		box-shadow:2px 2px 10px rgba(254,108,142,1);
		opacity:1;
		border-radius:5px;
		color: white;
		font-weight: bold;
		font-family: 'Heiti';
		text-align: center;
		line-height: 45px;
		font-size: 20px;
	}
	.login-cancel {
		position: absolute;
		width: 100%;
		text-align: center;
		font-family: 'Heiti';
		color: #FE376F;
		bottom: 12px;
		font-size: 18px;
	}
	.login-close {
		position: absolute;
		width: 18px;
		height: 18px;
		right: 18px;
		top: 80px;
		z-index: 9;
	}

	.mine-tabbar {
		position: fixed;
		width: 100%;
		height:@bw * 0.167;
		bottom: 0;
		background-color: #fff;
		border-top:@bw * 0.001 solid #ccc;
	}
	.mine-make {
		position: absolute;
		width:@bw * 0.944;
		height:@bw * 0.125;
		top: 50%;
		left: 50%;
		margin-left: -@bw * 0.472;
		margin-top: -@bw * 0.062;
		background-color: #ff4200;
		color: #fff;
		font-weight: 400;
		font-size:@bw * 0.05;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius:@bw * 0.007;
	}
	.mine-make>img {
		margin-right:@bw * 0.022;
	}

	@-webkit-keyframes tipshide {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	.mine {
		position: relative;
		width: 100%;
		min-height: 1080px;
		padding-bottom:@bw * 0.167;
		// background: linear-gradient(to top right, #ff9761, #E72047);
		/*position: absolute;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		-webkit-overflow-scrolling: touch;*/
	}
	.mine-bd {
		margin-top:@bw * 0.014;
		margin-bottom:@bw * 0.042;
	}
	.mine-hd {
		position: relative;
		width: 100%;
		margin-bottom:@bw * 0.014;
		font-size: 0;
	}
	.mine-hd__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
	}
	.mine-hd__mask {
	    position: absolute;
	    width: 100%; height: 100%;
	    background-color: rgba(0, 0, 0, .3);
	    z-index: 2;
	}
	.mine-hd__headimg {
		position: absolute;
		z-index: 3;
		width:@bw * 0.167;
		height:@bw * 0.167;
		border-radius: 50%;
		border: solid@bw * 0.011 white;
		left: 50%;
		top: 50%;
		margin-left: -@bw * 0.083;
		margin-top: -@bw * 0.083;
	}
	.mine-hd__timearea {
		position: absolute;
		z-index: 3;
		color: white;
		top: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
	.mine-hd__timearea>span {
		text-shadow:@bw * 0.003@bw * 0.003@bw * 0.003 black;
	}
	.mine-hd__week {
		font-size:@bw * 0.078;
	}
	.mine-hd__date {
		font-size:@bw * 0.044;
	}
	.book_not_find {
		background-color: #ececec;
		text-align: center;
		padding:@bw * 0.028 0;
		margin: 0@bw * 0.039;
		font-size:@bw * 0.042;
		border-radius:@bw * 0.007;
	}
	.mine-report {
		position: absolute;
		z-index: 99;
		color: white;
		font-size:@bw * 0.033;
		bottom:@bw * 0.011;
		right:@bw * 0.022;
		text-shadow:@bw * 0.003@bw * 0.003@bw * 0.003 black;
	}
	.mine-item {
		position: relative;
		// display: inline-block;
		height: 125px;
	}
	.mine-follow {
		position: absolute;
		top:@bw * 0.039;
		z-index: 9;
		font-size:@bw * 0.047;
		background-color: white;
		padding:@bw * 0.014@bw * 0.028;
		border-radius:@bw * 0.019;
		border: solid@bw * 0.003 #ef5000;
		color: #ef5000;
		right:@bw * 0.022;
		display: none;
	}
	.l-loaded, .l-preload {
        color: #bdbdbd;
	    font-size:@bw * 0.039;
	    font-weight: 700;
	    letter-spacing:@bw * 0.003;
	    text-align: center;
        margin: 40px 0 30px;
	}
	.l-loading>img {
	    position: relative;
	    width:@bw * 0.078;
	    height:@bw * 0.078;
	    margin: 0 auto;
	    display: block;
	}

	
	.mine-p {
		height:@bw * 0.097;
		display: flex;
		align-items: center;
	}
	.mine-p_icon {
		position: relative;
		width:@bw * 0.062;
		height:@bw * 0.062;
		margin-left:@bw * 0.028;
	}
	.mine-p_text {
		color: #ed5100;
		margin-left:@bw * 0.014;
		font-family: 'Heiti';
		font-size:@bw * 0.044;
		font-weight: bold;
		// text-shadow: 0px 0px 6px #ed5100;
	}
	.mine-bin {
		position: absolute;
		width: 200px;
		height: 30px;
		line-height: 30px;
		right: 10px;
		display: flex;
		align-items: center;
	}
	.mine-bin_icon {
		position: absolute;
		width: 25px;
		right: 70px;
	}
	.mine-bin_text {
		position: absolute;
		right: 6px;
		font-size: 20px;
		color: #484848;
		top: 1px;
	}

	// 轮播封面图
	.cover-image-container {
		background-color: #EE8D2B;
		overflow: hidden;
		background-image: url("http://resali.lititutu.cn/tuku/prod/05b53e62f844b6408c1e7c8a5ca739d7.jpg");
		background-repeat: no-repeat;
		background-size: cover;
	}

	.cover-image{
		transform: scale(1.4);
	}

	.cover-image, .cover-image-animate{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		margin: auto;
	}

	.cover-image-animate{
		animation-name: coverFade;
		animation-duration: 8s;
		animation-timing-function: linear;
		animation-duration: normal;
		opacity: 0;
	}

	@keyframes coverFade {
		0% {
			opacity: 1;
			transform: scale(1.4);
		}
		90% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			transform: scale(1);
		}
	}

	.text-decoration-container {
		justify-content: flex-start;
		width: 500px;
	}

	.text-decoration {
		background: linear-gradient(to top right, #ff9761, #e72047);
		width: 8px;
		height: 27px;
		margin: 0 5px 0 21px;
	}

	.text-container {
		max-width: 457px;
		margin: 5px 0rpx;
		padding: 0 0 0 5px;
		letter-spacing: 1px;
		font-size: 20px;
		flex-grow: 1;
	}
	.book-info-text{
		color: #9e9e9e;
		font-size: 16px;
		width: 457px;
		text-align: right;
		margin: 0 auto 16px;
		display: block;
		font-weight: bold;
	}

</style>




