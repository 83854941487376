<template>
    <div class="chuxian_con">
        <div class="userbox3">
            <div class="box1">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto2"></div>
                <div class="photoborder1" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            </div>
            <div class="box2">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(-36deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(-36deg);"></div>
            </div>
            <div class="box22">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(-36deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(-36deg);"></div>
            </div>
            <div class="box222">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(-36deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(-36deg);"></div>
            </div>
            <div class="box3">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(36deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(36deg);"></div>
            </div>
            <div class="box33">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(36deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(36deg);"></div>
            </div>
            <div class="box333">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(36deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(36deg);"></div>
            </div>
            <div class="box4">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(108deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(108deg);"></div>
            </div>
            <div class="box44">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(108deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(108deg);"></div>
            </div>
            <div class="box444">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(108deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(108deg);"></div>
            </div>
            <div class="box5">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(180deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(180deg);"></div>
            </div>
            <div class="box55">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(180deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(180deg);"></div>
            </div>
            <div class="box555">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(180deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(180deg);"></div>
            </div>
            <div class="box6">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(252deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(252deg);"></div>
            </div>
            <div class="box66">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(252deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(252deg);"></div>
            </div>
            <div class="box666">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="chuxian-userphoto userphoto22" style="transform: rotate(252deg);"></div>
                <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="photoborder2" style="transform: rotate(252deg);"></div>
            </div>
        </div>
        <div class="paopao" style="background-image: url(http://resali.lititutu.cn/scenes/zaoshangyukuai/zaoshangyukuai_chuxian_shan.gif)"></div>
    </div>
</template>

<script>
    export default {
        name: 'chuxian',
        props: ['mode', 'currentImage'],
        data () {
            return {}
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage)
            }
        }
    }
</script>

<style scoped>
    .chuxian_con{
        width: 100vw;
        height: 100vh;
        position: relative;
        -webkit-transform-style: preserve-3d; 
        transform-style: preserve-3d;
    }
    .userbox3{
        position: absolute;
        top: 0vh;
        left: 0vw;
        width: 100vw;
        height: 100vh;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
    }
    .box1{position: absolute; top: 28vh; left: 10vw; width: 70vw; height: 75vw; z-index: 1;}
    .box2{position: absolute; top: 28vh; left: 25vw; width: 30vw; height: 30vw; animation: chuxian1 3s ease-in-out infinite;}
    .box22{position: absolute; top: 28vh; left: 25vw; width: 30vw; height: 30vw; animation: chuxian1 3s 1s ease-in-out infinite;}
    .box222{position: absolute; top: 28vh; left: 25vw; width: 30vw; height: 30vw; animation: chuxian1 3s 2s ease-in-out infinite;}
    .box3{position: absolute; top: 28vh; left: 29vw; width: 30vw; height: 30vw; animation: chuxian2 3s ease-in-out infinite;}
    .box33{position: absolute; top: 28vh; left: 29vw; width: 30vw; height: 30vw; animation: chuxian2 3s 1s ease-in-out infinite;}
    .box333{position: absolute; top: 28vh; left: 29vw; width: 30vw; height: 30vw; animation: chuxian2 3s 2s ease-in-out infinite;}
    .box4{position: absolute; top: 25vh; left: 25vw; width: 30vw; height: 30vw; animation: chuxian3 3s ease-in-out infinite;}
    .box44{position: absolute; top: 25vh; left: 25vw; width: 30vw; height: 30vw; animation: chuxian3 3s 1s ease-in-out infinite;}
    .box444{position: absolute; top: 25vh; left: 25vw; width: 30vw; height: 30vw; animation: chuxian3 3s 2s ease-in-out infinite;}
    .box5{position: absolute; top: 25vh; left: 28vw; width: 30vw; height: 30vw; animation: chuxian4 3s ease-in-out infinite;}
    .box55{position: absolute; top: 25vh; left: 28vw; width: 30vw; height: 30vw; animation: chuxian4 3s 1s ease-in-out infinite;}
    .box555{position: absolute; top: 25vh; left: 28vw; width: 30vw; height: 30vw; animation: chuxian4 3s 2s ease-in-out infinite;}
    .box6{position: absolute; top: 28vh; left: 27vw; width: 30vw; height: 30vw; animation: chuxian5 3s ease-in-out infinite;}
    .box66{position: absolute; top: 28vh; left: 27vw; width: 30vw; height: 30vw; animation: chuxian5 3s 1s ease-in-out infinite;}
    .box666{position: absolute; top: 28vh; left: 27vw; width: 30vw; height: 30vw; animation: chuxian5 3s 2s ease-in-out infinite;}
    @keyframes chuxian1{
        0%{}
        100%{transform: translateY(-625px) translateX(-416PX)}
    }
    @keyframes chuxian2{
        0%{}
        100%{transform: translateY(-625px) translateX(416PX)}
    }
    @keyframes chuxian3{
        0%{}
        100%{transform: translateY(226px) translateX(764PX);}
    }
    @keyframes chuxian4{
        0%{}
        100%{transform: translateY(694px)}
    }
    @keyframes chuxian5{
        0%{}
        100%{transform: translateX(-694PX) translateY(230px)}
    }
    .userphoto2{
        position: absolute;
        top: 33px;
        left: 28px;
        width: 67vw;
        height: 67vw;
        border-radius: 50%;
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .userphoto22{
        position: absolute;
        top: 105px;
        left: 15px;
        width: 174px;
        height: 243px;
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .photoborder1{
        position: absolute;
        width: 79vw;
        height: 79vw;   
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transform-origin: 50% 50%;
        pointer-events: none;
        animation: zhuan 3s linear infinite;
    }
    @keyframes zhuan{
        0{}
        100%{transform: rotate(360deg)}
    }
    .photoborder2{
        position: absolute;
        width: 200px;
        height: 451px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        pointer-events: none;
    }
    .paopao{
        position: absolute;
        top: 0; left: 0;
        width: 100vw;
        height: 100vh;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        pointer-events: none;
    }
</style>