import util from '@/st_comps.util'

const state = {
	// 应用配置
	appConfig: {},
	userInfo: null,
    musics: {},
	pageDatas: {}, // pageMaker中的响应式变量
    imagesObj: {
		"30638e2f59825cf4cc6e13f22b199a79": {
			"width": 475,
			"height": 648
		},
        "525dfeb30e56da73b2e9a4897268c2b5": {
            "width": 475,
			"height": 648
        }
	}, // 保存图片的对象信息
	compsStatus: {}, // 记录部分组件的创建状态 1 已创建，2 已销毁
	theme: {},
    themes: {},
    images: [],
    videos: {}, // 视频数据
}

const mutations = {
	setUserInfo (state, preload) {
        preload = preload || {};
        let userInfo = state.userInfo ? util.copy(state.userInfo) : {}
        Object.assign(userInfo, preload);
        state.userInfo = userInfo;
	},
	// 设置应用配置
	setAppConfig (state, preload) {
		let appConfig = preload.appConfig;
		state.appConfig = appConfig;
	},
	// 清空pageData
	clearPageDatas (state, preload) {
		if (JSON.stringify( state.pageDatas ) != '{}') {
			state.pageDatas = {};
		}
	},
	// 从数据源初始化pageDatas
	updatePageDatas (state, preload) {
		let datas = util.copy(state.pageDatas);
		for (let key in preload) {
			datas[key] = preload[key];
		}
		state.pageDatas = datas;
	},
	// 设置pageMaker中的响应式变量
	setPageDatas (state, preload) {
		let datas = util.copy(state.pageDatas);
		if (preload['key']) {
			datas[preload.key] = preload.value;
		} else {
			for (let key in preload) {
				datas[key] = preload[key];
			}
		}
		state.pageDatas = datas;
	},
	// 设置图片对象从而获取长宽
    setImagesObj (state, preload) {
        let imsrc = typeof preload.imagesrc == 'object' ? preload.imagesrc['im'] : preload.imagesrc;
        let _id = util.md5(imsrc);

        // 对用户照片提取关键信息，减少对内存的占用
        if (util.typeOf(preload.imagesrc) == 'object') {
            preload.imageobj = {
                'width': preload.imageobj.width,
                'height': preload.imageobj.height
            }
        }
        
        if (!state.imagesObj[ _id ]) state.imagesObj[ _id ] = preload.imageobj;

        // 设置orisize
        for (let i=0; i<state.images.length; i++) {
            if (imsrc == state.images[i]['im']) {
                state.images[i]['orisize'] = [preload.imageobj.width, preload.imageobj.height];
            }
        }
    },
	// 记录组件状态
	setCompStatus (state, preload) {
		let {
			comp,
			status
		} = preload;
		let comps_status = util.copy(state.compsStatus);
		comps_status[comp] = status;
		state.compsStatus = comps_status;
	},
	// 设置模板数据到当前模板缓存
	setTheme (state, preload) {
        state.theme = preload.theme;
    },
	// 设置模板数据到所有模板缓存
	setThemes (state, preload) {
        state.themes[preload.tid] = state.themes[preload.tid] ? Object.assign(state.themes[preload.tid], preload.theme) : preload.theme;
    },
    // 存视频数据结构
    setVideo (state, preload) {
        state.videos[preload.cid] = state.videos[preload.cid] ? Object.assign(state.videos[preload.cid], preload) : preload;
    },
}

const actions = {
	// 获取用户union data
	setUserMsg ({ commit, state }, preload) {
		return new Promise(( resolve, reject ) => {
			util.rget('get_user_msg', {
				'__appid__': preload.appid,
                'openid': preload.openid,
			}).then((response) => {
				commit('setUserMsg', {
					'userMsg': response
				})
				resolve(response);
			})
		})
	},
	// 获取模板配置
    getTheme ({ commit, state }, preload) {
        let _getNeedReqTid = (tid, fields) => {
            let exist = true;
            if (!state.themes[tid]) {
                exist = false;
            } else {
                if (fields.length == 0) { // fields为空数组时，代表获取所有字段
                    if (state.themes[tid]['__all__'] === true) { // 已存在完整字段的缓存数据
                        exist = true;
                    } else { // 存在数据但不是完整数据
                        exist = false;
                    }
                } else { // 指定请求的字段
                    for (let i=0; i<fields.length; i++) {
                        let field = fields[i];
                        if (state.themes[tid] && !state.themes[tid][field]) {
                            exist = false;
                            break;
                        }
                    }
                }
            }
            
            if (exist === false) {
                return tid;
            }
        }
        let _getThemesByTids = (tids) => {
            if (tids.length == 1) {
                let currentTheme = state.themes[tids]; // 获取单独的模板数据写入当前模板字段
                commit("setTheme", {
                    theme: currentTheme
                });
                return currentTheme;
            } else {
                let themes = [];
                for (let i=0; i<tids.length; i++) {
                    themes.push( state.themes[tids[i]] );
                }
                return themes;
            }
        }
        return new Promise(resovle => {
            let tids = util.typeOf(preload.tid == 'string') ? preload.tid.split(",") : preload.tid,
                fields = preload.fields && util.typeOf(preload.fields) == 'string' ? preload.fields.split(",") : preload.fields || [],
                need_req_tids = [];
            for (let i=0; i<tids.length; i++) {
                let need_req_tid = _getNeedReqTid(tids[i], fields);
                if (need_req_tid)
                    need_req_tids.push( need_req_tid );
            }

            if (need_req_tids.length) {
                let params = {
                    cid: tids.join(","),
                }
                if (fields.length) {
                    params['fields'] = fields.join(",");
                }
				util.$config.center.get_config(params).then(themes => {
                    
                    if (util.typeOf(themes) == 'object') {
                        themes = [themes];
                    }
                    for (let i=0; i<themes.length; i++) {
                        // 请求的如果是完整数据，则打上标记
                        if (fields.length == 0) {
                            themes[i]['__all__'] = true;
                        }
                        commit("setThemes", {
                            tid: tids[i],
                            theme: themes[i]
                        });
                    }
                    resovle( _getThemesByTids(tids) );
                })
            } else {
                resovle( _getThemesByTids(tids) );
            }
        })
    },
    // 获取视频配置
    getVideo ({ commit, state }, preload) {
        let _this = this;
        let {
            cid
        } = preload || {};
        
        if (!cid) { // 参数错误
            return Promise.reject()
        } else if (state.videos[cid]) { // 存在缓存中
            return Promise.resolve( state.videos[cid] )
        } else { // 从请求中返回
            return new Promise((resolve, reject) => {
                util.$config.center.get_config({
                    "cid": cid
                }).then(res=>{
                    res["cid"] = res["cid"] || cid;
                    commit("setVideo", res);
                    resolve(res);
                }).catch(err=>{
                    reject(err)
                })
            })
        }
    },

	// 获取app配置数据 被 $util.$config.get_app_config()取代
	// setAppConfig ({ commit, state }, preload) {
	// 	return new Promise((resolve, reject)=>{
    //         util.rget('/config_center/get_app_config', {}).then((config) => {
    //             commit('setAppConfig', {'appConfig': config});
    //             resolve()
    //         }).catch(err=>{
    //             console.error("get_app_config error: ", err);
    //             reject("get_app_config error")
    //         })
    //     })
	// }
}

const getters = {
}

export default {
	state,
	mutations,
	actions,
	getters
}
