<template>
	<div id="room3" class="po">
		<template v-for="elem in mode['elems']">
			<elem :mode="elem"></elem>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'room3',
		props: ['mode'],
		data () {
			return {}
		},
		components: {
		}
	}
</script>

<style scoped>
	#room3 {
		height: 100%; width: 100%;
		overflow: hidden;
		pointer-events: none;
	}
</style>
