<template>
    <component :is="canvasTag" class="gif-canvas po" 
        ref="gifCanvas"
        v-if="isGifShow"
        :style="getStyles"></component>
</template>

<script>
	import BaseComponent from '@/components/st_comps/st_comp/comps/StElem/BaseComponent'
    export default {
        name: 'gif',
        extends: BaseComponent,
        props: ['mode', 'canIShow'],
        data () {
            return {
                defaultWidth: 500,
                defaultHeight: 815,
                aniFrameObj: null,
                isGifShow: true
            }
        },
        watch: {
            canIShow: {
                handler () {
                    if (this.canIShow === true) {
                        this.isGifShow = true;
                        this.$nextTick(() => {
                            this.initGif();
                        })
                    } else if (this.canIShow === false) {
                        this.isGifShow = false;
                    }
                },
                immediate: true
            }
        },
        computed: {
            canvasTag () {
                if (this.$util.isEnv('taro') && this.$util.isEnv('h5')) {
                    return 'Canvas';
                }
                return 'canvas';
                // return this.$util.ISWEB ? "Canvas" : "canvas";
            },
        },
        mounted () {
            // 非elem调用则直接执行initGif
            if (this.canIShow === undefined) {
                this.initGif();
            }
            // 部分full模板情况下，会在开始播放后，返回结束事件
            setTimeout(() => {
                this.$emit('endInter');
            }, this.mode['duration'] * 1000);
        },
        destroyed () {
            window.cancelAnimationFrame(this.aniFrameObj);
        },
        methods: {
            async initGif () {
                if (this.mode['sprite_image']) {

                    let tw = this.mode['width'],
                        th = this.mode['height'],
                        len = this.mode['sprite_image']['steps'];

                    // 帧图片
                    if (this.mode['sprite_image']['path']) {
                        let frames = [], 
                            frame, im, imagekey;
                        for (let i=0; i<len; i++) {
                            
                            im = this.mode['sprite_image']['path'].replace('{index}', (i+1).toString());
                            imagekey = this.$util.md5(im);
                            frame = this.$store.state.imagesObj[imagekey];

                            if (!frame) {
                                frame = await this.__backup(im);
                            }

                            frames.push(frame);
                        }
                        this.__painting(frames, tw, th, len, 'frame')
                        
                    // 雪碧图
                    } else {
                        // 缩放原图至指定大小
                        let image = this.$store.state.imagesObj[ this.$util.md5(this.mode['sprite_image']['im']) ];
                        // 部分预加载失败的情况补充处理
                        if (!image) {
                            image = await this.__backup(this.mode['sprite_image']['im']);
                        }

                        this.__painting(image, tw, th, len, 'image')
                    }
                }
            },
            __painting (frames, tw, th, len, mode) {
                // 将帧图片绘制到画布展示
                let canvas = this.__getCanvas();
                // alert(String(typeof canvas.transferControlToOffscreen))
                canvas.width = tw;
                canvas.height = th;
                let ctx = canvas.getContext("2d");

                let bol = true,
                    stime = new Date().getTime(),
                    time = this.mode['duration'] * 1000,

                    runtime = this.mode['animationIterationCount'] || 'infinite',
                    runidx = 0,
                    that = this;

                let sw, sh;
                if (mode == 'image') {
                    sw = frames.width;
                    sh = frames.height / len;
                }

                let sIndex = this.mode['sprite_image'] && this.mode['sprite_image']['sIndex'] ? this.mode['sprite_image']['sIndex'] : 0;

                function animationSet() {
                    if (bol) {
                        let c = new Date().getTime() - stime;
                        let b = parseInt(c / time * len);
                        b = (b + sIndex) % len // 可以指定从第几帧开始
                        if (b + 1 >= len) {
                        // if (b >= len) { // 2021-06-23 yellow适配播放次数限制
                            runidx ++;
                            bol = false;
                            // 设置播放次数
                            if (runidx == runtime) {
                                if (that.mode['fillMode'] != 'both') {
                                    that.isGifShow = false;
                                }
                                return;
                            }
                            stime = new Date().getTime();
                            bol = true;
                        } else {
                            if (mode == 'frame') {
                                if (frames[b]) {
                                    ctx.clearRect(0, 0, Math.round(tw), Math.round(th))
                                    try {
                                        ctx.drawImage(frames[b], 0, 0, Math.round(tw), Math.round(th));
                                    } catch (e) {
                                        ctx.drawImage(frames[ (b-1)<0 ? frames.length-1 : b-1 ], 0, 0, Math.round(tw), Math.round(th));
                                    }
                                }
                            } else if (mode == 'image') {
                                ctx.clearRect(0, 0, Math.round(tw), Math.round(th))    
                                ctx.drawImage(frames, 0, Math.round(b*sh), Math.round(sw), Math.round(sh), 0, 0, Math.round(tw), Math.round(th));
                            }
                        }
                    }
                    that.aniFrameObj = requestAnimationFrame(animationSet);
                }
                animationSet();
            },
            __getCanvas () {
                let canvas = this.$refs['gifCanvas'];
                return canvas;
            },


            // canvas或者sprite统一的style设置
            // __backup__gifStyle (style) {
            //     // zIndex
            //     if (this.mode['zIndex']) {
            //         style['zIndex'] = this.mode['zIndex'];
            //     } else {
            //         style['zIndex'] = 'auto';
            //     }

                // width
                // if (this.mode['width']) {
                //     style['width'] = this.mode['width']+'px';
                // } else {
                //     style['width'] = this.defaultWidth + 'px';
                // }

                // // height
                // if (this.mode['height']) {
                //     style['height'] = this.mode['height']+'px';
                // } else {
                //     style['height'] = this.defaultHeight + 'px';
                // }

            //     if (this.mode['pos']) {
            //         if (this.mode['pos'] == "center") {
            //             style['top'] = '50%';
            //             style['left'] = '50%';
            //             style['marginLeft'] = '-' + (parseInt(this.mode['width']) / 2) + 'px';
            //             style['marginTop'] = '-' + (parseInt(this.mode['height']) / 2) + 'px';
            //         } else {
            //             // top
            //             if ('string' == typeof this.mode['pos'][0] && this.mode['pos'][0].indexOf('%') != -1) {
            //                 style['top'] = this.mode['pos'][0];
            //             } else if (this.mode['pos'][0] == null) {
            //                 style['top'] = 'initial';
            //             } else {
            //                 style['top'] = this.mode['pos'][0] + 'px';
            //             }
            //             // right
            //             if ('string' == typeof this.mode['pos'][1] && this.mode['pos'][1].indexOf('%') != -1) {
            //                 style['right'] = this.mode['pos'][1];
            //             } else if (this.mode['pos'][1] == null) {
            //                 style['right'] = 'initial';
            //             } else {
            //                 style['right'] = this.mode['pos'][1] + 'px';
            //             }
            //             // bottom
            //             if ('string' == typeof this.mode['pos'][2] && this.mode['pos'][2].indexOf('%') != -1) {
            //                 style['bottom'] = this.mode['pos'][2];
            //             } else if (this.mode['pos'][2] == null) {
            //                 style['bottom'] = 'initial';
            //             } else {
            //                 style['bottom'] = this.mode['pos'][2] + 'px';
            //             }
            //             // left
            //             if ('string' == typeof this.mode['pos'][3] && this.mode['pos'][3].indexOf('%') != -1) {
            //                 style['left'] = this.mode['pos'][3];
            //             } else if (this.mode['pos'][3] == null) {
            //                 style['left'] = 'initial';
            //             } else {
            //                 style['left'] = this.mode['pos'][3] + 'px';
            //             }
            //         }
            //     } else {
            //         style['top'] = '0px';
            //         style['right'] = 'initial';
            //         style['bottom'] = 'initial';
            //         style['left'] = '0px';
            //     }

            //     // 绑定动画
            //     if (this.mode['keyframe']) {
            //         style = Object.assign(style, this.getKeyframeStyle(this.mode['keyframe']));
            //     }

            //     if (this.mode['style']) {
            //         style = Object.assign(style, this.mode['style']);
            //     }
            // },
            __backup (im_src) {
                let that = this;

                return new Promise(resolve => {
                    let im = new Image();
                    im.onload = function() {
                        that.$store.commit('setImagesObj', {'imagesrc': im.src, 'imageobj': im});
                        resolve(im);
                    }
                    im.src = im_src;
                })
            },
            // __backup_frame ()
        }
    }
</script>

<style>
    .gif-canvas {
        pointer-events: none;
    }
</style>