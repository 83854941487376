import st_log_base from "./st_log.base"

class stLogManager extends st_log_base.stLogManagerBase {
  constructor (util) {
    super(util)
    this.params = {}; // 全局预设字段
    let _this = this;
    let umeng_key = this.$util.$config.st_config['umeng_key'];
    this.__gray_mark__ = Math.round( Math.random() * 1000 ) / 1000;
    if (!umeng_key) {
      console.error("埋点初始化失败，请先在st_config.json中配置umeng_key")
      return
    }
    window.addEventListener('load', function() {
        (function(w, d, s, q, i) {
            w[q] = w[q] || [];
            var f = d.getElementsByTagName(s)[0],j = d.createElement(s);
            j.async = true;
            j.id = 'beacon-aplus';
            j.src = 'https://d.alicdn.com/alilog/mlog/aplus/' + i + '.js';
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'aplus_queue', '203467608');
        _this.aplus_queue = aplus_queue;
        //集成应用的appKey
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['appKey', umeng_key]
        });
        /************************以下内容为可选配置内容****************************/
        //sdk提供手动pv发送机制，启用手动pv(即关闭自动pv)，需设置aplus-waiting=MAN;
        //注意：由于单页面路由改变时不会刷新页面，无法自动发送pv，所以对于单页应用，强烈建议您关闭自动PV, 手动控制PV事件
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['aplus-waiting', 'MAN']
        });
        //是否开启调试模式 
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['DEBUG', true]
        });
        //
        /**
         * 是否指定用作计算umid的id类型，默认为cnaid \ 用于统计分享等传参
         */
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['aplus-idtype', 'cnaid'] //取值参考见附表1
        });
        _this.$util.$eventCenter.trigger("APLUS_READY", {});
    })
  }
  setLogId (openid) {
    let _this = this;
    openid = openid ? openid : this.$util.$login.openid;
    new Promise((resolve, reject)=>{
      if (_this.aplus_queue){
        resolve()
      } else {
        _this.$util.$eventCenter.on("APLUS_READY").then(()=>{
          resolve()
        })
      }
    }).then(()=>{
      _this.aplus_queue.push({
          action: 'aplus.setMetaInfo',
          arguments: ['_user_id', _this.$util.$login.openid]
      });
    })
  }
  debug () {}
  info (msg) {
    if (this.$util.$config.ISDEBUG){console.info(msg)}
  }
  warn (msg) {
    if (this.$util.$config.ISDEBUG){console.warn(msg)}
  }
  error (msg) {
    if (this.$util.$config.ISDEBUG){console.error(msg)}
  }
  setFilterMsg (msg) {
  }
  addFilterMsg (msg) {}
  grayLog (options) {
    let {
      field,
      gray,
      data,
      infolog
    } = options || {};
    if (this.__gray_mark__ <= gray && field) {
      this.log(field, data, infolog);
    }
  }
  log (field, data, infolog) {
    let _this = this;
    new Promise((resolve, reject)=>{
      if (_this.aplus_queue){
        resolve()
      } else {
        _this.$util.$eventCenter.on("APLUS_READY").then(()=>{
          resolve()
        })
      }
    }).then(()=>{
      data = data ? data : {};
      // 记录全局预设字段
      Object.assign(data, this.params);
      data["st_domain"] = this.$util.getTopDomain();
      data["st_path"] = window.location.pathname;
      data["env"] = this.$util.isEnv("webview") ? "webview" : "h5";

      if (this.$util.isEnv("webview")) {
        // 埋点加入实验对照组数据
        if (this.$util.$wx && this.$util.$wx.wxExpt) {
          data = Object.assign(data, this.$util.$wx.wxExpt);
        }
  
        // 加入webview appid
        let loginSign = this.$util.$login.loginSign;
        if (loginSign && loginSign.appid) {
          data["webview_appid"] = loginSign.appid;
        }

        // webview上传埋点到小程序中上报
        if (this.$util.isEnv("webview")) {
          this.$util.$wx.wxsdk.miniProgram.postMessage( { data: { log: JSON.stringify(
            {
              "key": field,
              "value": data
            }
          ) } });
        }

      }
      

      aplus_queue.push({
          action: 'aplus.record',
          arguments: [field, "CLK", data]
      });
      // console.log(field, " ==> ", data)

      // 老版本服务器请求api埋点
      // let params = {
      //     'key': this.$util.$config.__APP__,
      //     'field': field
      // }
      // _this.$util.rpost('/api/log', params)
    }).catch(err=>{
      console.error("log err=>",err)
    })
  }
}

export default {
  stLogManager
}