<template>
	<div class="page-share page scrollable_1">
		<after-share 
			v-if="isAfterShareShow" 
			:showStatus="isAfterShareShow" 
			@hide="hideAfterShare"
			@refreshPage="refreshPage"></after-share>

		<template v-if="netWorkHealth">
			<div id="capp" class="fadeIn" v-if="$store.state.config.data['ttid'] && isThemeShow">
				<div id="ccanvas" class="share-model">

					<Enter v-if="isEnterShow" 
						:mode="currentMode" 
						@endEnter="endEnter"
						@showCanvas="showCanvas"></Enter>

					<component 
						v-if="isCanvasShow"
						:is="currentMode['type']" 
						:mode="currentMode['mode']['type'] == 'line' ? currentMode['mode'] : currentMode" 
						@wxPreview="wxPreview" 
						@showEndPage="showEndPage"></component>
					
					<!-- 官方相册引流广场按钮 -->
					<!-- <div v-if="isOfficial && !isMakeDetail && !isCoverMaker"
						class="festival-flow-btn btns-bounceup"
						:class="$store.state.wordCur.wordStatus ? 'festival-btn-has-usertext' : ''"
						@click="goSquare">
						<img class="festival-flow-img"  :src="getFollowPhoto" />
					</div> -->

				</div>

				<!-- <Report v-show="reportShow" @onReportHide="onReportHide"></Report> -->

				<!-- 悬浮弹幕 6 -->
				<FlowItems v-if="isThemeShow && !isMakeDetail && !isCoverMaker"></FlowItems>
				
				<!-- 送花点赞 8 -->
				<Zan v-show="!isCoverMaker"></Zan>

				<!-- 结束页面 9 -->
				<EndPage v-if="isEndPageShow && !isMakeDetail" @refreshPage="refreshPage" @onMakeTap="onMakeTap" @closeEndPage="closeEndPage" :is_author="isAuthor" open_type="share"></EndPage>

				<!-- 分享页按钮 9 -->
				<ShareBtns 
					v-show="(!isEndPageShow || isMakeDetail) && !isCoverMaker" 
					@onMakeTap="onMakeTap"
					@showPreShare="showPreShare" 
					:is_author="isAuthor" 
					:is_make_detail="isMakeDetail"></ShareBtns>

				<!-- 引导收藏 9 -->
				<!-- <FlowGuide v-if="!isMakeDetail && isThemeShow && !isCoverMaker" @closeGuideBtn="closeGuideBtn" :show_flow_guide="showFlowGuide"></FlowGuide> -->

				<!-- 引导订阅 9 -->
				<!-- <FlowSubMp v-show="canShowSubMp"
					@closeSubBtn="closeSubBtn"></FlowSubMp> -->

				<!-- 小程序返回按钮 9 -->
				<img v-if="showWxaBack" class="btns_wxa_back" src="http://resali.lititutu.cn/wxa_icon/btn/h5_back.png" @click="wxaBack()">

				<!-- 分享页提示 99 -->
				<span class="share-tips" v-show="!isCoverMaker">该相册为用户个人作品，非官方发布</span>
				
				<!-- 作品key 99 -->
				<div class="mantion" v-if="!isMakeDetail && !isCoverMaker">{{$route.query['key']}}</div>

				<!-- 歌词 -->
				<div id="music-lrc"></div>
				
			</div>

			<!-- 分享页第二屏 -->
			<ShareMore v-show="!isMakeDetail && isThemeShow && secondAbleTheme" @onMakeTap="onMakeTap"></ShareMore>
		</template>
		<NetError v-show="!netWorkHealth" @retry="reloadPage"></NetError>

		<!-- 分享tips -->
		<pre-share 
			ref="pre_share"
			:onHide="onPreShareHide"></pre-share>
	</div>
</template>

<script>

	import xc from '@/components/themes/xc/Xc'
	import full from '@/components/themes/full/Full'
	import exhi from '@/components/themes/exhi/Exhi'
	import wscene from '@/components/themes/wscene/Wscene'
	import other from '@/components/themes/other/Other'
	import simple3d from '@/components/themes/simple3d/Simple3d'
	import normal from '@/components/themes/normal/Normal'
	import simple from '@/components/themes/simple/Simple'

	import Zan from '@/components/Zan'
	import FlowItems from '@/components/FlowItems'
	import FlowGuide from '@/components/FlowGuide'
	import FlowSubMp from '@/components/FlowSubMp'
	// import Report from '@/components/Report'
	import EndPage from '@/components/EndPage'
	import Enter from '@/components/Enter'
	import NetError from '@/components/NetError'
	import AfterShare from '@/components/AfterShare'
	import PreShare from '@/components/PreShare'

	import ShareBtns from '@/views/share/ShareBtns'
	import ShareMore from '@/views/share/ShareMore'
	import StOpenTag from '@/components/st_comp/StOpenTag';

	import C from '@/config'
	import U from '@/util'

	export default {
		name: 'Share',
		components: {
			xc,
			full,
			exhi,
			wscene,
			other,
			simple3d,
			normal,
			simple,

			Zan,
			FlowItems,
			FlowGuide,
			FlowSubMp,
			// Report,
			EndPage,
			Enter,
			NetError,
			AfterShare,
			PreShare,

			ShareBtns,
			ShareMore,
			StOpenTag,
		},
		data () {
			return {
				isOfficial: false,
				isAuthor: false,
				isMakeDetail: false,
				showFlowGuide: false,
				showFlowSub: false,
				subedMp: false,

				// reportShow: false,
				isThemeShow: false,
				isAfterShareShow: false,
				
				pv: 0,
				btnTada: false,

				loadingBg: 'http://resali.lititutu.cn/icons/app/default.png',

				// isEndPageShow: false,
				// canEndPageShow: true,

				isLoadingShow: true,

				isEnterShow: false,
				isCanvasShow: false,
				netWorkHealth: true,
			}
		},
		computed: {
			currentMode () {
				let currentMode = this.$store.state.themes[ this.$store.state.config['data']['ttid'] ];
				if (!currentMode['mode']) {
					currentMode['mode'] = {};
				}
				return this.$store.state.themes[ this.$store.state.config['data']['ttid'] ];
			},
			playerMusic () {
				return this.$store.state.playerMusic;
			},
			tdatas () {
				
				if (this.$store.state['themeData']) {

					let tids = this.$store.state.themeData['theme_type_data']['hot']['tids'];

					tids = tids.slice(0, 20);

					let first_tid = tids.shift();

					tids.sort(function(m, n) {
						return Math.random() > .5 ? 1 : -1;
					});

					tids = tids.slice(0, 5);

					tids = [first_tid].concat(tids);

					let tdatas = [];
					for (let i=0, lens=tids.length; i<lens; i++) {
						tdatas.push({
							tid: tids[i],
							thumb: this.$store.state.themeData['theme'][tids[i]]['thumb'],
							name: this.$store.state.themeData['theme'][tids[i]]['name']
						})
					}
					return tdatas;
				} else {

					return [];
				}

			},
			secondAbleTheme () {
				return ['exhi', 'wscene', 'xc'].indexOf( this.$store.state.themes[ this.$store.state.config['data']['ttid'] ]['type'] ) == -1;
			},
			showWxaBack() {
				return this.$env.web == 'wxa' && this.$route.query && ( this.$route.query['from_favorit'] == 1 );
			},
			isCoverMaker () {
				let bool = Boolean(this.$route.query['action'] == 'covermaker');
				return bool;
			},
			canShowSubMp () {
				// return this.$store.state.config['follow_url'] && this.$store.state.config.follow_url.indexOf('http') > -1 && !this.isMakeDetail && this.isThemeShow && !this.isCoverMaker && this.showFlowSub;
				if (this.$env.web == 'wxa') {
					return this.$store.state.appConfig.flow_sub && !this.isMakeDetail && this.isThemeShow && !this.isCoverMaker && this.showFlowSub && !this.subedMp;
				} else {
					return this.$store.state.appConfig.flow_sub && !this.isMakeDetail && this.isThemeShow && !this.isCoverMaker && this.showFlowSub;
				}
			},
			isTimeline () {
				return Boolean(this.$route.query['is_timeline']) && this.$store.state.appConfig['share_mode'];
			},
			getFollowPhoto () {
				let app_config = this.$store.state.appConfig;
				if ('wxa' == this.$env.web && app_config.festival && app_config.festival.btn_img){
					return app_config.festival.btn_img;
				}
				if (app_config && app_config.flow_sub && this.isOfficial) { // 官方相册引流
					let keywords = ["早安","早上好","晚安","晚上好"],
						ti = this.$store.state.config.data.ti,
						mp_mid = app_config.get_xc;
					if (ti) {
						for (let i = 0 ; i < keywords.length ; i++){
							if (ti && ti.indexOf(keywords[i]) > -1){
								mp_mid = app_config.get_xc2 ? app_config.get_xc2 : app_config.get_xc;
								break;
							}
						}
					}
					return mp_mid == app_config.get_xc2 ? 
						"http://resali.lititutu.cn/tuku/prod/9dc64ea2f557c178367c57f7900af53e.gif" //获取祝福
						: "http://resali.lititutu.cn/tuku/prod/bb12e6ba89fea7a7d467b4874b521b3f.gif" //订阅精彩
				}
				return "http://resali.lititutu.cn/tuku/prod/51d02975209ac24e72b59c846fe82a99.gif";//更多佳作
			},
			isEndPageShow () {
				return this.$store.state.endPageRuntime == 1;
			}
		},
		created () {
			this.$o_util.log("vliti_load_created");
			// 加载时长计数
			setTimeout(() => {
				this.$o_util.log("vliti_load_timeout_5");
			}, 5000);
			setTimeout(() => {
				this.$o_util.log("vliti_load_timeout_10");
			}, 10000);

			// 控制制作按钮动画
			setInterval(() => {
				this.btnTada = true;
				setTimeout(() => {
					this.btnTada = false;
				}, 1000);
			}, 1500);

			// 计数
			this.$mta.click('pv_share');
			if ('wxa' == this.$env.web) {
				this.$mta.click('pv_share_wxa');
			} else {
				this.$mta.click('pv_share_h5');
			}

			// 计数 订阅号客服消息推广
			if (window.location.host == 'custom.litigogo.top' && this.$route.query['action'] == 'wx_custom') {
				this.$o_util.log('wxcustom_enter_'+this.$route.query['_source']);
			}
			// 计数 新推送系统
			if (this.$route.query['push_id']){
				this.rpost("https://alifun.litiskr.cn/api/manager", {
					"__api__": "report",
					"app": "vliti",
					"log_info": JSON.stringify({
						"push_id": this.$route.query['push_id'],
						"action": "open"
					})
				})
			}

			// 若在qq中，则绑定全局点击事件
			if (this.$o_util.isQQ()) {
				document.body.addEventListener('click', () => {
					this.$router.push({ name: 'search' });
				}, false);
			}
		},
		mounted () {
			// 判断页面是否退出调整音乐播放
			document.addEventListener('visibilitychange', () => {
				if ("hidden" == document.visibilityState) {
					this.pauseMusic();
				} else {
					this.playMusic();
				}
			});
			
			// 若在小程序中
			if ('wxa' == this.$env.web && this.$route.query['_source'] && this.$route.query['visitor']) {
				let _this = this;
				// 判断是否已关注
				this.$util.rget('check_sub_mp_api',{
					"__appid__": this.$route.query['_source'],
					"openid": this.$route.query['visitor'],
				}).then(res=>{
					_this.subedMp = res;
				}).catch(err=>{
					resolve(false);
				})
			}

			// wxtimeline蒙层处理
			// 应对2021.04.14的域名封禁事件 byhz
			// if (this.$route.query['is_timeline']) {
			// 	if (window.location.host == 'xcpapa.cn') {
			// 		return;
			// 	}
			// 	if (document.getElementById('timeline-tips')) {
			// 		return;
			// 	}
			// 	let t = document.createElement('div');
			// 	t.id = 'timeline-tips';
			// 	t.innerHTML = '<img style="position: relative;margin-top: 11px;width: 100%;" src="http://litiresource.xcgogo.site/user/5980171/images/038a18973998ecefc98a4e7b3c1ab5ee.png">';
            //     t.style.cssText = "position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.7); z-index: 9999;";
            //     t.onclick = function() {
            //     	this.style.display = 'none';
            //     }
            //     document.body.appendChild(t);
			// }
		},
		beforeCreate () {
			let _this = this;
			this.$util.rget('dconfig', {
				'key': this.$route.query['key'],
				'appid': this.$route.query['_source']
			})
			.then((response) => {
                this.$o_util.log("vliti_load_dconfig");
				_this.loadDconfig(response)
			})
			.catch((response) => {
                this.$o_util.log("vliti_load_unusual").then(() => {
					if (response['status'] == 'empty') {
						window.location.href = "http://litiv.litifly.xyz/vliti/main/empty?key="+_this.$route.query.key; // empty有被封的风险，找一个小流量域名跳转
					} else if (response['status'] == 'green') {
						_this.$o_util.block(response['appid'], response['openid']);
					} else {
						_this.netWorkHealth = false;
					}
				});
			});
		},
		methods: {
			wxPreview (image) {

				if (typeof(image) == 'string') {
					this.$wx.previewImage(image, [image]);
				} else {
					this.$wx.previewImage(image['im'], [image['im']]);
				}
			},
			// onReportShow () {
			// 	this.reportShow = true;
			// },
			// onReportHide () {
			// 	this.reportShow = false;
			// },
			onMakeTap: function(tid, vscene) {

				let _this = this,
					// 2021-02-07 yellow兼容小程序直接制作
					_appid = this.$route.query['_source'],
					_openid = this.isAuthor ? _this.$store.state.config['data']['openid'] : _this.$route.query['visitor'];

				if (vscene) {
					_this.$o_util.log([
						'select_image_h5',
						'select_image_'+vscene.toString().replace('20', '40')
					]);
				};

				_this.$o_util.uploadMedia({
					violate: async function () {
						return new Promise((resolve, reject)=>{
							resolve("C9130828AE2347CD2AD3F8D4C98EDC89")
						})
					},
					process (index, len) {
						if (index == 0) {
							_this.$toast.loading({
								message: '加载中...',
								duration: 'infinite'
							})
						} else if(index == len) {
							_this.$toast.loading('创建中...');
						} else {
							_this.$toast.loading(index+'/'+len+' 上传中');
						}
					},
				}).then(image_infos=>{ // images
					_this.$toast.loading('制作成功');
						
					// 优化判断逻辑 byhz 2021.08.10
					// 存在openid
					if (_openid) {
						let params = {
							'appid': _appid,
							'openid': _openid,
							// 'urls': images.join(',')
							'imgInfos': JSON.stringify(image_infos)
						};
						if (vscene) {
							params['vscene'] = vscene.toString().replace('20', '40');
						}
						if (tid) {
							params['tid'] = tid;
						}
						_this.$util.rpost('/vliti/new_book', params)
						.then((key) => {
							_this.$toast.clear();

							let jumpurl = "/vliti/main/"+(_this.$store.state.appConfig.editor_path)+"?key="+key+'&_source='+_this.$route.query['_source'];

							if (vscene) {
								jumpurl += '&vscene=' + vscene;
							}
							window.location.href = jumpurl;
						})
						.catch((err) => {
							_this.$toast.clear();
							_this.$toast.text({
								'message': err
							})
						});
					} else {
						// 无openid直接登陆制作
						this.$util.rpost('set_sharemake_images', {
							images: JSON.stringify(image_infos)//images.join(',')
						}).then(imagekeys => {

							let jumpurl = '//' + window.location.host + '/vliti/sharemake?v=1&imagekeys=' + imagekeys;
							if (vscene) {
								jumpurl += '&vscene=' + vscene;
							}

							if (tid) {
								window.location.href = jumpurl+'&tid='+tid;
							} else {
								window.location.href = jumpurl;
							}
						})
					}
				}).catch(err=>{
					_this.$toast.clear();
					_this.$o_util.log('upload_media_fail_0');
				})

				if (tid == this.$store.state.config.data.ttid) {
					this.$mta.click('share_btn_more');
				}
			},
			onThemeMoreClick () {
				window.location.href = '/vliti/login?target=menu';
			},
			hideAfterShare () {
				this.isAfterShareShow = false;
			},
			refreshPage () {
				this.isThemeShow = false;
				this.$nextTick(() => {
					this.isThemeShow = true;

					// 重启elems模式下的图片获取
					this.$util.restartUserPhoto();

					// 音乐重播
					if (this.$audio.exist()) {
						this.$audio.replay();
					} else {
						this.$kgPlayer.init2( this.$kgPlayer.getInfo() );
					}
				});
			},
			// refreshPage () {
			// 	// 重置是否可以展示结束页逻辑
			// 	this.isThemeShow = false;
				
			// 	this.$nextTick(() => {
			// 		this.isThemeShow = true;

			// 		// 音乐重播
			// 		alert(0)
			// 		if (this.$audio.exist()) {
			// 			alert(1)
			// 			this.$audio.replay();
			// 		} else {
			// 			alert(2)
			// 			this.$kgPlayer.init2( this.$kgPlayer.getInfo() );
			// 		}
			// 	});
			// },
			incrEndPage () {
				this.$store.commit('incrEndPageRuntime');
			},
			showEndPage () {
				// if (!this.isEndPageShow && this.canEndPageShow && !this.isMakeDetail) {
				// 	this.isEndPageShow = true;
				// }
				this.incrEndPage();
			},
			closeEndPage () {
				// this.isEndPageShow = false;
				// this.canEndPageShow = false;
				this.incrEndPage();
			},

			// 用于切换音乐时补充歌词颜色的逻辑
			getUserTextColor () {
				let color = '#dc2727';
				if (this.currentMode['mode'] && this.currentMode['mode']['text'] && this.currentMode['mode']['text']['textShadowColor']) {
					color = this.currentMode['mode']['text']['textShadowColor'];
				}
				return color;
			},
			// 绑定歌词样式
			setLrcStyle (music) {
				let shadow_color = "#dc2727",
					color = "#ffffff",
					s = {};
				if (this.currentMode['mode'] && this.currentMode['mode']['text']) {
					shadow_color = this.currentMode['mode']['text']['textShadowColor'] || shadow_color;
					color = this.currentMode['mode']['text']['color'] || color;
					s = this.currentMode['mode']['text']['style'] || {};
				}
				if (music) {
					music['lrc_color'] = shadow_color;
					music['lrc_text_color'] = color;
					music['lrc_style'] = s;
				}
				return {'lrc_color': shadow_color, 'lrc_text_color': color, 'lrc_style': s};
			},
			goSquare(e){
				let app_config = this.$store.state.appConfig;
				if ('wxa' == this.$env.web && app_config.festival && app_config.festival.btn_img) {
					wx.miniProgram.navigateTo({
						url: '/subpackages/festival/festival'
					})
				} else if ('wxa' == this.$env.web && this.subedMp) {
					wx.miniProgram.redirectTo({
						url: '/pages/make/make?tab=square'
					})
				} else if (app_config && app_config.flow_sub && app_config.get_xc2) {
					let keywords = ["早安","早上好","晚安","晚上好"],
						ti = this.$store.state.config.data.ti,
						mp_mid = app_config.get_xc;
					for (let i = 0 ; i < keywords.length ; i++){
						if (ti && ti.indexOf(keywords[i]) > -1){
							mp_mid = app_config.get_xc2 ? app_config.get_xc2 : app_config.get_xc;
							break;
						}
					}
					window.location.href = "https://mp.weixin.qq.com/s/" + mp_mid;
				} else {
					if ('wxa' == this.$env.web){
						wx.miniProgram.redirectTo({
							url: '/pages/make/make?tab=square'
						})
					} else {
						window.location.href = "/vliti/main/square";
					}
				}
			},
			wxaBack(){
				wx.miniProgram.redirectTo({
					url: '/pages/make/make?tab=make'
				})
			},
			// 显示模板画布
			showCanvas () {
				this.isCanvasShow = true;
			},
			// 结束开场
			endEnter () {
				this.isEnterShow = false;
			},
			// 关闭收藏提示
			closeGuideBtn(){
				this.showFlowGuide = false;
				let guideLog = localStorage.getItem("guide_collection_list");
				guideLog = guideLog ? JSON.parse( guideLog ):[];
				guideLog.push(this.$route.query['key'])
				if(guideLog.length > 20){
					guideLog.shift()
				}
				localStorage.setItem("guide_collection_list", JSON.stringify(guideLog))
			},
			// 尝试显示引导收藏
			tryShowFlowGuide (xc_key) {
				let _this = this;
				let guideLog = localStorage.getItem("guide_collection_list");
				guideLog = guideLog ? JSON.parse( guideLog ):[];
				if (guideLog.length < 1 || guideLog.indexOf(xc_key) < 0){
					// 定时展示引流
					let timer1 = setTimeout(()=>{
						_this.showFlowGuide = true;
					},3000)

					let timer2 = setTimeout(()=>{
						_this.showFlowGuide = false;
						guideLog.push(xc_key)
						if(guideLog.length > 20){
							guideLog.shift()
						}
						localStorage.setItem("guide_collection_list", JSON.stringify(guideLog))
					},14000)
				}
				let guided_mp = localStorage.getItem("guide_sub");
				if (!guided_mp) {
					let timer3 = setTimeout(()=>{
						_this.showFlowSub = true;
						let timer4 = setTimeout(()=>{
							_this.showFlowSub = false;
						},18000)
					},6000)
				}
			},
			// 关闭引流按钮
			closeSubBtn () {
				this.showFlowSub = false;
				localStorage.setItem("guide_sub", true)
			},
			reloadPage(){
				let _this = this;
				this.$util.rget('dconfig', {
					'key': this.$route.query['key'],
					'appid': this.$route.query['_source']
				})
				.then((response) => {
					_this.loadDconfig(response)
				})
				.catch((response) => {
					if (response['status'] == 'empty') {
						window.location.href = "/vliti/main/empty?key="+_this.$route.query.key;
					} else if (response['status'] == 'green') {
						_this.$o_util.block(response['appid'], response['openid']);
					} else {
						_this.netWorkHealth = false;
					}
				});
			},
			loadDconfig(response){
				let _this = this;
				// 用在网络错误刷新时重新加载页面
				_this.netWorkHealth = true;
				
				// 记录pv
				let logfield = [],
					vscene = _this.$route.query['vscene'],
					lscene = _this.$route.query['lscene'];

				// byhz 11.20 vscene为3001的页面访问不做计数
				if (vscene != '3001') {
					logfield.push( _this.$env.web == 'wxa' ? 'wxa-share' : 'h5-share' );
				}
				if (vscene) {
					logfield.push('vscene_'+vscene);
				}
				if (lscene) {
					logfield.push('lscene_'+lscene);
				}
				_this.$o_util.log(logfield);

				// 获取loading加载背景图
				_this.loadingBg = response['app_config']['loading']['bg'];

				// 新数据载入store
				_this.$store.commit('config', {
					config: response
				});

				// 图片数据处理
				let photos = [];
				for(let i=0,len=response['images'].length; i<len; i++) {
					response['images'][i]['im'] = this.$util.im( response['images'][i] );
					photos.push(response['images'][i]);
				}

				// 获取赞、评论
				_this.$util.rget("get_array", {
					key: _this.$route.query['key'],
					field: "flower,comment"
				}).then(response => {
				
					for (let idx = 0; idx < response.array.length; idx++) {
						response.array[idx]["time_for_show"] = response.array[idx]["create"]?
							_this.$o_util.dateDiff(response.array[idx]["create"]): "以前";
						_this.$store.commit("pushZan", response.array[idx]);
					}
				});

				// 判断是否作者
				let login_check_result = localStorage.getItem('loginCheckResult');
				login_check_result = login_check_result ? login_check_result = JSON.parse(login_check_result) : {};
				let owner = _this.$store.state.config.data['appid']+','+_this.$store.state.config.data['openid'];

				_this.isAuthor = (_this.$route.query['is_author'] == 1 && _this.$route.query['is_timeline'] != 1) || _this.$o_util.inSlog('riti_author3', owner) || Boolean(login_check_result[owner] == 1);
				_this.isOfficial = _this.$store.state.config.data['official'] == 1 //|| _this.$store.state.config.data['openid'] == "oKOxE1Cp4Lwt-fnoi0HjjUL96bFs";

				if (response['data']['sq']) {
					if(_this.isOfficial){
						logfield.push('official_share_pagein');
					} else {
						logfield.push('square_share_pagein');
					}
				}

				// 素材图片预加载
				_this.$store.dispatch('getTheme', {
					tid: response['data']['ttid']
				}).then((theme) => {

					_this.isEnterShow = this.currentMode['mode']['enter'] ? true : false;
					_this.isCanvasShow = !_this.isEnterShow;

					if (theme.model && theme.model == _this.$route.query['key']){
						_this.isMakeDetail = true;
					}

					// 获取用户信息
					_this.$store.dispatch('getUserInfo').then((userInfo) => {});
					
					// 加载第一轮的用户图片
					let loadingImages = this.$o_util.getFirstLoadPhoto(theme, photos);

					// 加载第一轮的模板图片
					loadingImages = loadingImages.concat( this.$o_util.getFirstLoadTheme(theme) );

					// 加载帧序列图片
					loadingImages = loadingImages.concat( this.$o_util.getGifImages(theme) );

					this.$o_util.log(['mainloading_h5_share_1', 'vliti_load_images_0']);

					_this.$mainloading.load({
						images: loadingImages,
						progress: response['app_config']['loading']['progress'],
						onUpdate: function(imagesrc, imageobj) {
							// 预加载的同时写入用户图片的尺寸数据
							if (typeof imagesrc == 'object') {
								_this.$store.commit('setImageOriSize', {'imageid': imagesrc['id'], 'orisize': imagesrc['orisize']});

								imagesrc = imagesrc['im'];
							}

							// 设置全局图片对象
							_this.$store.commit('setImageObj', {'imagesrc': imagesrc, 'imageobj': imageobj});
						},
						onEnd: function() {

							_this.$o_util.log(['mainloading_h5_share_2', 'vliti_load_images_1']);

							// 设置初始mid
							let mid = _this.$store.state.config['data']['mid'] || theme['mid'];
							_this.$o_util.getMusic(mid).then((music) => {

								if (music['src']) {
									// 若有歌词
									// if (music['lrc']) music['lrc_color'] = _this.getUserTextColor();
									if (music['lrc']) _this.setLrcStyle(music);
									if (!_this.isCoverMaker) {
										_this.$audio.init(music);
										if (!_this.isTimeline) {
											_this.$audio.play();
										}
									}
									// 判断歌词是否可以显示
									if(_this.$store.state.wordCur.wordStatus == 1){
										_this.$audio.closeLrc();
									}

									let mta_param = {};
									mta_param[ _this.$env.web + 'audio' ] = 'true';
									_this.$mta.click('music_play', mta_param);

									// 音乐自动播放 补充逻辑
									try {
										// let im = new Image();
										// im.onload = function() {
										// 	_this.$audio.play();
										// };
										// im.src = photos[0]['im'];

										let istouchplay = false;
										document.getElementById('ccanvas').addEventListener('touchstart', function() {
											if (istouchplay) {
												return;
											}
											istouchplay = true;
											_this.$audio.play();
										}, false);
									} catch(e) {}

								} else {
									_this.$wx.checkJsApi().then(() => {
										_this.$kgPlayer.init2(music);
			                    	})

			                    	let mta_param = {};
									mta_param[ _this.$env.web + 'kugou' ] = 'true';
									_this.$mta.click('music_play', mta_param);
								}
								_this.$store.commit('setMusicInfo', music);
							});

							// 设置标题
							document.title = _this.$store.state.config['data']['ti'] || theme['name'] || '甜豆相册';

							// 初始化微信分享
							wxReadyListener.load(function() {
								// _this.$store.dispatch('getAppConfig', 'share_title').then((default_share_title) => {
									// 设置scene参数
									let scene_param = [
										'vscene=2004'
									];
									if (_this.$route.query['vscene']) {
										scene_param.push('lscene='+_this.$route.query['vscene']);
									};

									let share_link = _this.$o_util.getLocationPath(_this.$o_util.getSharePath()) + "?key="+_this.$route.query['key']+"&_source="+_this.$route.query['_source']+"&"+scene_param.join('&');
									if (_this.$route.query['ulog']) {
										share_link += '&ulog=' + _this.$route.query['ulog'];
									}
									_this.$wx.share({
										// title: _this.$o_util.getShareTitle(_this.$store.state.config) || default_share_title,
										title: _this.$util.getShareTitle(),
										desc: '甜豆相册，送给你！',
										imgUrl: _this.$o_util.getShareImgurl(),//_this.$o_util.getShareImgurl(_this.$store.state.config),
										link: share_link,
										success: () => {
											// 更新微信sdk，on系列事件已废弃
											// _this.isAfterShareShow = true;
											// 关闭preShare
											_this.$refs.pre_share.hide();
										},
										from: 'share'
									});
								// });
							});


							// 设置pv
							if (!_this.isAuthor) {

								_this.$store.dispatch('setNum', {
									type: 'pv',
									key: _this.$route.query.key
								}).then((current_pv) => {
									// yellow 2021.03.04 官方相册不鉴黄
									if(!_this.$store.state.config.data['official']){
										if (current_pv == 10) {
											_this.$o_util.imgchecker('CONTENT_CHECK_10', _this.$route.query.key, _this.$store.state.config.images);
										} else if (current_pv == 50) {
											_this.$o_util.imgchecker('TEXT_CHECK_50', _this.$route.query.key, _this.$store.state.config.images);
										} else if (current_pv == 100) {
											_this.$o_util.imgchecker('CONTENT_CHECK_100', _this.$route.query.key, _this.$store.state.config.images);
										}
									}
								})
							}

							// 刷新页面
							_this.isLoadingShow = false;
							_this.isThemeShow = true;

							// 相关计数
							_this.$o_util.log('theme_share_pagein_'+_this.$store.state.config['data']['ttid']);
							_this.$o_util.log('mainloading_h5_share_3');

							// 获取相册分享信息模式
							if(C.debugHost.indexOf(location.host) > -1){
								console.log("获取相册分享信息模式=>")
								// _this.$store.dispatch('getAppConfig', 'share_title').then((default_share_title) => {
									// console.log(default_share_title)
									console.log("分享标题：", _this.$o_util.getShareTitle());
									console.log("/pages/make/make?tab=make&sence=" + _this.$route.query['key'] + "&_source=" + response['data']['appid'])
									let share_image_path = _this.$o_util.getShareImgurl(true)
									console.log("share_image_path=>",share_image_path)
								// })
							}

							// 2021.06.14恢复域名分享指引
							if (_this.isTimeline) {
								_this.showPreShare();
							}
						}
					})
				})

				_this.tryShowFlowGuide(_this.$route.query.key)	
			},
			pauseMusic () {
				try {
					this.$audio.pause();
				} catch(e) {}

				try {
					this.$kgPlayer.player.pause();
				} catch(e) {}
			},
			playMusic () {
				// 若原本是库音乐，则播放
				if (this.$audio.exist()) {
					this.$audio.play();
					// 若试听了音乐，需销毁
					try {
						this.$kgPlayer.player.destroy();
					} catch(e) {console.warn(e);};
				} else {
					this.$kgPlayer.player.playSong(0);
				}
			},
			showPreShare () {
				this.$refs.pre_share.show();
				setTimeout(() => {
					this.pauseMusic();
				}, 1000);
			},
			onPreShareHide () {
				this.playMusic();
			}
		}
	}
</script>

<style lang="less" scoped>
	.page-share {
		z-index: 1;
	}
	.scrollable_1 {
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	#capp {
		position: relative;
		top: 0; 
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.page-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
	}
	.share-model {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.share-tips {
		position: absolute;
		left: 3.5px;
		top: 12px;
		z-index: 99;
		font-size: 12.5px;
		color: rgba(255, 255, 255, 0.7);
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.47);
	}
	.title-model-enter-active, .title-model-leave-active {
		transition: opacity .5s ease-out;
	}
	.title-model-enter, .title-model-leave-to {
		opacity: 0;
	}
	.mantion {
		position: fixed;
		top: 2px;
		z-index: 99;
		left: 2px;
		color: #5d5d5d;
		opacity: .8;
		letter-spacing:@bw * 0.001;
		font-size:@bw * 0.015;
	}
	.festival-flow-btn{
		position: absolute;
		right: 0;
		bottom: 216px;
		z-index: 7;
		background-color: rgba(0, 0, 0, 0);
	}
	.festival-flow-img{
		width: 81px;
		height: 81px;
		margin: 16px 8px;
	}
	.festival-btn-has-usertext {
		bottom: 271px!important;
	}
	.btns_wxa_back {
		position: fixed;
		top:@bw * 0.062;
		left:@bw * 0.028;
		width:@bw * 0.208;
		height:@bw * 0.101;
		z-index: 9;
	}
</style>