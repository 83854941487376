class stLogManager {
    constructor (util) {
      let _this = this;
      this.util = util;
      let umeng_key = "618cea36e014255fcb75d63d";
      if (!umeng_key) {
        console.error("埋点初始化失败，请先在st_config.json中配置umeng_key")
        return
      }
      window.addEventListener('load', function() {
          (function(w, d, s, q, i) {
              w[q] = w[q] || [];
              var f = d.getElementsByTagName(s)[0],j = d.createElement(s);
              j.async = true;
              j.id = 'beacon-aplus';
              j.src = 'https://d.alicdn.com/alilog/mlog/aplus/' + i + '.js';
              f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'aplus_queue', '203467608');
          _this.aplus_queue = aplus_queue;
          //集成应用的appKey
          aplus_queue.push({
              action: 'aplus.setMetaInfo',
              arguments: ['appKey', umeng_key]
          });
          /************************以下内容为可选配置内容****************************/
          //sdk提供手动pv发送机制，启用手动pv(即关闭自动pv)，需设置aplus-waiting=MAN;
          //注意：由于单页面路由改变时不会刷新页面，无法自动发送pv，所以对于单页应用，强烈建议您关闭自动PV, 手动控制PV事件
          aplus_queue.push({
              action: 'aplus.setMetaInfo',
              arguments: ['aplus-waiting', 'MAN']
          });
          //是否开启调试模式 
          aplus_queue.push({
              action: 'aplus.setMetaInfo',
              arguments: ['DEBUG', true]
          });
          //
          /**
           * 是否指定用作计算umid的id类型，默认为cnaid \ 用于统计分享等传参
           */
          aplus_queue.push({
              action: 'aplus.setMetaInfo',
              arguments: ['aplus-idtype', 'cnaid'] //取值参考见附表1
          });
          _this.util.$eventCenter.trigger("APLUS_READY", {});
      })
    }
    setLogId (openid) {
      let _this = this;
      openid = openid ? openid : this.util.$login.openid;
      new Promise((resolve, reject)=>{
        if (_this.aplus_queue){
          resolve()
        } else {
          _this.util.$eventCenter.on("APLUS_READY").then(()=>{
            resolve()
          })
        }
      }).then(()=>{
        _this.aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['_user_id', _this.util.$login.openid]
        });
      })
    }
    debug () {}
    info (msg) {
      if (this.util.$config.ISDEBUG){console.info(msg)}
    }
    warn (msg) {
      if (this.util.$config.ISDEBUG){console.warn(msg)}
    }
    error (msg) {
      if (this.util.$config.ISDEBUG){console.error(msg)}
    }
    setFilterMsg (msg) {
    }
    addFilterMsg (msg) {}
    log (field, data, infolog) {
      let _this = this;
      new Promise((resolve, reject)=>{
        if (_this.aplus_queue){
          resolve()
        } else {
          _this.util.$eventCenter.on("APLUS_READY").then(()=>{
            resolve()
          })
        }
      }).then(()=>{
  
        // data = data ? Object.assign(data, default_data) : default_data;
        data = data ? data : {};
        data["st_domain"] = this.util.getTopDomain();
        data["st_path"] = window.location.pathname;
        data["env"] = this.util.isWebview() == 'wxa' ? "webview" : "h5";
        
        // console.error(field + "=>" + JSON.stringify(data, null, 4))
        aplus_queue.push({
            action: 'aplus.record',
            arguments: [field, "CLK", data]
        });
  
        // console.log("log == >",field, " ==> ", this.util.parseParams(data))
  
        // console.log("data",data)
        // let value = this.util.parseParams(data);
        // value = value.length > 20 ? value.slice(0,20) : value;
  
        // 老版本服务器请求api埋点
        // let params = {
        //     'key': this.util.$config.__APP__,
        //     'field': field
        // }
        // _this.util.rpost('/api/log', params)
      }).catch(err=>{
        console.error("log err=>",err)
      })
    }
  }
  
  export default {
    stLogManager
  }