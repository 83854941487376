<template>
	<div class="base-luo">
	</div>
</template>

<script>
	import BaseElem from "@/components/st_comps/st_comp/comps/StElem/BaseElem"
	export default {
        name: 'baseluo',
        extends: BaseElem,
		data () {
			return {}
		},
		computed: {
            // 兼容 mode 到 comp.im 到 comp.config 的过渡
            luoItems () {
                if (this.comp && this.comp['config'] && this.comp['config'][0]) {
                    if (this.$util.typeOf(this.comp['config'][0]) == 'array') {
                        return this.comp['config'][0];
                    } else if (this.$util.typeOf(this.comp['config'][0]) == 'string') {
                        return [this.comp['config'][0]];
                    }
                } else if (this.comp && this.comp['im']) {
                    return this.comp['im'];
                } else if (this.$util.typeOf(this.mode) == 'array') {
                    return this.mode;
                } else if (this.mode['im']) {
                    return this.mode['im'];
                } else {
                    return [];
                }
            }
        },
        methods:{
            getRandomLuo () {
                return this.getLuoItem(-1)
            },
            getLuoItem (index) {
                let list = this.luoItems;
                let len = list.length;
                let item;
                if (index < 0) {
                    item = list.randomChoice();
                } else {
                    item = list[ index % len ]
                }
                return this.$util.copy(item);
            },
            getLuoItemStyle(index){
                let s = {};
                if (this.$util.typeOf(this.mode) == 'array') {
                    s = Object.assign(s, this.getItemStyle( this.mode[ index % this.mode.length ] ) );
                } else if (this.$util.typeOf(this.mode) == 'object'){
                    if (this.mode['im']) {
                        if (this.$util.typeOf(this.mode['im']) == 'array') {
                            s = Object.assign(s, this.getItemStyle( this.mode['im'][ index % this.mode['im'].length ] ) );
                        } else {
                            s = Object.assign(s, this.mode['im']);
                        }
                    } else if (this.mode['comp'] && this.mode['comp']['im']) {
                        s = Object.assign(s, this.getItemStyle( this.mode['comp']['im'][ index % this.mode['comp']['im'].length ] ));
                    }
                }
                
                return s;
            },
        },
	}
</script>

<style>
	
</style>