<template>
	<div class="zhuanxin">
		<!-- <div class="zhuanxin_bg" :style="{backgroundImage: 'url('+mode[0]+')'}"></div> -->
		<div class="zhuanxin_main_photo">
			<div class="zhuanxin_aixin toairotate" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		</div>
		<div class="zhuanxin_inner_photo">
			<div class="zhuanxin_aixin airotate01" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
			<div class="zhuanxin_aixin airotate02" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
			<div class="zhuanxin_aixin airotate03" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
			<div class="zhuanxin_aixin airotate04" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
			<div class="zhuanxin_aixin airotate05" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
			<div class="zhuanxin_aixin airotate06" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'zhuanxin',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		mounted () {},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.zhuanxin {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
		perspective:@bw * 1.146;
		perspective-origin: 50% 58%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
	}
	.zhuanxin_bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}
	.zhuanxin_main_photo {}
	.zhuanxin_main_photo>.toairotate {
		width:@bw * 1.389;
		height:@bw * 1.389;
		// top: -@bw * 0.274;
		top: 0;
		left: -@bw * 0.139;
		-webkit-mask-size:@bw * 1.389@bw * 1.389;
		transform: translateZ(-@bw * 0.942);
		animation: zhuanxin_show_data 12s linear infinite;
	}
	.zhuanxin_aixin {
		overflow: hidden;
		position: absolute;
		-webkit-mask-image: url(http://litiresource.xcgogo.site/user/5980171/images/c008b2c5faef7a788e053fdce5aac931.png);
		mask-image: url(http://litiresource.xcgogo.site/user/5980171/images/c008b2c5faef7a788e053fdce5aac931.png);
		background-size: cover;
		background-position: 50%;
	}
	@keyframes zhuanxin_show_data {
		0% {
			transform: translateZ(-@bw * 0.942) rotateY(0deg);
			opacity: 0;
		}
		30% {
			opacity: 1;
		}
		100% {
			transform: translateZ(-@bw * 0.942) rotateY(1turn);
			opacity: 1;
		}
	}
	.zhuanxin_inner_photo {
		position: absolute;
		width: 100%;
		height: 100%;
		// top: -@bw * 0.417;
		top: 28%;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		transform-style: preserve-3d;
		transform: translateZ(-@bw * 0.942);
		animation: zhuanxin_rotate_data 10s linear infinite;
	}
	@keyframes zhuanxin_rotate_data {
		from {
			transform: translateZ(-@bw * 0.942) rotateY(0deg);
		}
		to {
			transform: translateZ(-@bw * 0.942) rotateY(1turn);
		}
	}
	.zhuanxin_inner_photo>div {
		width:@bw * 0.556;
		height:@bw * 0.556;
		top:@bw * 1.115;
		left:@bw * 0.219;
		-webkit-mask-size:@bw * 0.556, @bw * 0.556;
		transform-style: preserve-3d;
		background-repeat: no-repeat;
	}
	.airotate01 {
		transform: rotateY(60deg) translateZ(-@bw * 0.694);
	}
	.airotate02 {
		transform: rotateY(120deg) translateZ(-@bw * 0.694);
	}
	.airotate03 {
		transform: rotateY(180deg) translateZ(-@bw * 0.694);
	}
	.airotate04 {
		transform: rotateY(240deg) translateZ(-@bw * 0.694);
	}
	.airotate05 {
		transform: rotateY(300deg) translateZ(-@bw * 0.694);
	}
	.airotate06 {
		transform: rotateY(360deg) translateZ(-@bw * 0.694);
	}
</style>