<template>
    <div class="page after-share">
        <div class="page after-share_mask" @click="hide()"></div>
        <div class="po after-share_container">
            <div class="po after-share_hd">
                <img class="po after-share_hicon" src="http://resali.lititutu.cn/icons/other/8d57d400ea949067105cddcfb71d64a4.png" />
                <img class="po after-share_htips" src="http://resali.lititutu.cn/icons/app/share_success_tips.png" />
            </div>
            <div class="po after-share_bd">
                <div class="after-share_bbtns flex_r">
                    <div class="after-share_bbtn" @click="onMybookRedirect">
                        <img class="after-share-bicon" src="http://resali.lititutu.cn/icons/app/tab_complete_more.png">
                        <span class="after-share-btip">相册管理</span>
                    </div>
                    <div class="after-share_bbtn" @click="hide('refresh')">
                        <img class="after-share-bicon" src="http://resali.lititutu.cn/icons/app/tab_complete_refresh.png">
                        <span class="after-share-btip">重播</span>
                    </div>
                    <!-- <div class="after-share_bbtn" @click="hide('share')">
                        <img class="after-share-bicon" src="http://resali.lititutu.cn/icons/app/tab_complete_share.png">
                        <span class="after-share-btip">继续分享</span>
                    </div> -->
                </div>
                <div class="after-share_btips">更多推荐</div>
                <div class="after-share_bcontent">
                    
                    <div class="after-share_bitem bg"
                        v-if="$store.state.appConfig.flow_sub"
                        style="background-image: url(http://resali.lititutu.cn/icons/other/2317359996d697f2a8fd5b08f3b49606.gif);" 
                        @click="onRedirect($store.state.appConfig['flow_sub'], 'gofollow')"></div>
                        <!-- v-if="isFollowShow" -->

                    <template v-for="item in items">
                        <div class="after-share_bitem bg" :style="{backgroundImage: 'url('+ item['coverImg'] +')'}">
                            <template v-if="item['type'] == 'redirect'">
                                <div class="page" style="z-index: 2;" @click="onMoreClick(item)"></div>
                            </template>
                            <template v-else>
                                <div class="after-share-wxa_mask poc"></div>
                                <img class="after-share-wxa_play" src="http://resali.lititutu.cn/icons/app/play.png">
                                
                                <!-- toWxaAble为true时，导致页面的跳转事件均无效，暂停这里跳小程序的逻辑，以二维码跳转，等待后续统一的h5跳小程序解决方案 byhz 2021.06.09 -->
                                <div class="page" style="z-index: 2;" @click="onMoreClick(item)"></div>
                                <!-- <template v-if="toWxaAble">
                                    <div style="position: absolute;z-index: 1;overflow: hidden;top: 0;left: 0;width: 100%;height: 100%;" class="after-share_wxa_box" :data-mpid="item['mpid']" :data-id="item['id']"></div>
                                </template>
                                <template v-else>
                                    <div class="page" style="z-index: 2;" @click="onMoreClick(item)"></div>
                                </template> -->

                            </template>
                            <div class="after-share-wxa_title">
                                {{item['title']}}
                            </div>
                        </div>
                    </template>

                    <div class="after-share_end" @click="hide('end')">返回相册</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AfterShare',
		props: ['showStatus'],
        data () {
            return {
                items: []
            }
        },
        computed: {
            // toWxaAble () {
			// 	let able = false;
			// 	for (let i=0; i<this.$config.wxaAble.length; i++) {
			// 		if (window.location.href.indexOf(this.$config.wxaAble[i]) != -1) {
			// 			able = true;
			// 			break;
			// 		}
			// 	}
			// 	return able;
			// },
            isFollowShow () {
				if ( this.$store.state.config['follow_url'] && this.$store.state.config.follow_url.indexOf('http') > -1 && this.$route.query['is_author'] != 1 ) {
					return true;
				}
				return false;
			}
        },
        watch: {
            showStatus: {
                handler () {
					let appids = [
						'wxba7cee6e3846b66e', // 甜豆图文
						'wx4cf27a2def3b0d52', // 开心节日祝福
					];
					let mpids = [
						'gh_3abd2c962542', // 甜豆图文
						'gh_db121a18a71d', // 开心节日祝福
					]
					let mpidx = parseInt(Math.random()*10000) % appids.length;
					let appid = appids[mpidx],
						mpid = mpids[mpidx];

					if (this.showStatus) {
						this.$store.dispatch('getBlessArtiItems', {'appid': appid}).then((items) => {

							// 乱序
							items.sort(function(m, n) {
								return Math.random() > .5 ? -1 : 1;
							});

							for (let i=0, lens=items.length; i<lens; i++) {
								items[i]['appid'] = appid;
								items[i]['mpid'] = mpid;
								items[i]['coverImg'] = items[i]['coverImg'].replace('http://mmbiz.qpic.cn/', this.$store.state.appConfig.image_map.host_map['oss'])
							};
							this.items = items;

							let htm = '<wx-open-launch-weapp username="#mpid#" path="pages/arti/arti.html?scene=#id#"><script type="text/wxtag-template"><style>.btn { width: 480px; height: 280px; top: 0; left: 0; }</style><div class="btn"></div>';
							htm += '</';
							htm += 'script></wx-open-launch-weapp>';

							this.$nextTick(() => {
								let boxs = document.querySelectorAll('.after-share_wxa_box');
								for(let i=0; i<boxs.length; i++) {
									let box = boxs[i];
									box.innerHTML = htm.replace('#mpid#', box.getAttribute('data-mpid')).replace('#id#', box.getAttribute('data-id'));
								}
							})
						});
					}
                },
				immediate: true
            }
        },
        methods: {
            hide (from) {
                this.$emit('hide');

                if (from == 'refresh') {
					this.$emit('refreshPage');
				}
                
                // 计数
                this.$o_util.log('aftershare-'+from);
            },
            onMoreClick (item) {
				let p = {};
				p[ this.from + '_' + item['appid'] ] = 'true';
				this.$mta.click("btn_to_more", p);

				if (item['type'] == 'redirect') {
					window.location.href = item['url'];
				} else {
					this.$toast.loading({
						message: '加载中...',
						duration: 'infinite'
					});

					this.$o_util.getWxaQrcode(item['id'], 'pages/detail/detail', item['appid']).then((qrcode) => {
						
						this.$toast.clear();

						this.hide('more_qrcode');
						
						this.$toast.qrcode({
							isMask: true,
							bgSrc: 'http://resali.lititutu.cn/icons/app/qrcode_bg.png',
							qrcodeSrc: qrcode
						})
					});
				}
			},
            onRedirect (url, from) {
                this.$o_util.log('aftershare-'+from).then(() => {
                    window.location.href = url;
                })
			},
            onMybookRedirect () {
                this.$o_util.log('aftershare-tiandou').then(() => {
                    this.$store.dispatch('getUserInfo').then(userinfo => {
                        if (userinfo['unionid'] || window.location.pathname.indexOf('share') != -1) {
                            // 编辑页判断有无unionid、share页则直接跳转落地页
                            window.location.href = "https://mp.weixin.qq.com/s/JWvtKO_Y9riREJxJvdGXtw";
                        } else {
                            // 跳转管理页
                            window.location.href = "/vliti/main/mybook?openid="+this.$store.state.config.data['openid']+"&_source="+this.$store.state.config.data['appid'];
                        }
                    })
                })
            }
        }
    }
</script>

<style>
    .after-share {
        position: fixed;
        z-index: 10;
    }
    .after-share_mask {
        z-index: 1;
		background-color: rgba(0,0,0,.72);
    }
    .after-share_container {
        background-color: #b49393;
        width: 100%;
        height: 85%;
        bottom: 0;
        z-index: 2;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .after-share_hd {
        width: 100%;
        height: 130px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #ffd6df;
    }
    .after-share_hicon {
        width: 140px;
        height: 140px;
        top: -70px;
        left: 50%;
        margin-left: -70px;
    }
    .after-share_htips {
        top: 68px;
        left: 50%;
        margin-left: -80px;
        width: 160px;
        height: 42px;
    }
    .after-share_bd {
        width: 100%;
        height: calc(100% - 130px);
        top: 130px;
        background-color: #fff;
    }
    .after-share_bbtns {
        padding: 15px 0;
        border-bottom: 1px solid #ccc;
        background-color: #fff;
    }
    .after-share_bbtn {
        position: relative;
		width: 146px;
		height: 59px;
		border-radius: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: solid 2px;   
    }
    .after-share_bbtn:nth-child(1) {
		border-color: #eb4a6a;
	}
	.after-share_bbtn:nth-child(2) {
		border-color: #8a8a8a;
	}
	.after-share_bbtn:nth-child(3) {
		border-color: #09bb07;
	}
    .after-share-bicon {
		width: 30px;
		height: 30px;
	    margin-right: 10px;
	}
    .after-share-btip {
        font-size: 21px;
		font-weight: bold;
    }
    .after-share_btips {
		text-align: center;
		color: rgba(97, 97, 97, 0.64);
		font-size: 18px;
		font-weight: bold;
		margin: 12px 0;
	}
    .after-share_bcontent {
        position: relative;
		width: 100%;
		height: calc(100% - 118px);
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
    }
    .after-share_bitem {
        position: relative;
		width: 480px;
		height: 280px;
		border-radius: 10px;
		overflow: hidden;
		margin: 0 auto 14px;
    }
    .after-share-wxa_mask {
        position: absolute;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.2);
    }
    .after-share-wxa_play {
        position: absolute;
		z-index: 2;
		width: 80px;
		height: 80px;
		top: 50%;
		left: 50%;
		margin-left: -40px;
		margin-top: -40px;
		pointer-events: none;
    }
    .after-share-wxa_title {
		position: relative;
		color: white;
		z-index: 2;
		font-size: 28px;
		letter-spacing: 2px;
		font-weight: bold;
		padding: 10px;
	}
    .after-share_end {
        text-align: center;
		padding: 30px 0;
		font-size: 17px;
		color: #888;
		text-decoration: underline;
		letter-spacing: 1px;
    }
</style>