<template>
	<div class="page-editor page">
		<template v-if="netWorkHealth">
			<div id="capp" class="fadeIn" v-if="$store.state.config.data['ttid'] && isEditorShow">

				<div id="ccanvas" class="page" v-if="isThemeShow">

					<Enter v-if="isEnterShow" 
						:mode="currentMode" 
						@endEnter="endEnter" 
						@showCanvas="showCanvas"></Enter>
					<component v-if="isCanvasShow" 
						:is="currentTheme" 
						:mode="currentMode['mode']['type'] == 'line' ? currentMode['mode'] : currentMode" 
						@wxPreview="wxPreview" 
						@showEndPage="showEndPage"></component>

				</div>

				<!-- 新人模式 -->
				<template v-if="isEasyBtnShow">
					<div class="easy-btn-box">
						<img class="easy-btn change" src="http://litiresource.xcgogo.site/user/5980171/images/5ae5e0053f273726b211f49de58e8f7b.png" @click="onEasyChange">
						<img class="easy-btn share" src="http://litiresource.xcgogo.site/user/5980171/images/7d0ef4c34c94ffc8d9c6b49aeb843ba0.png" @click="onEasyShare">
					</div>
				</template>

				<!-- 早安相册编辑模式 -->
				<template v-else-if="editorEventId">
					<div class="editor-btn-box_1" :class="{
						'hide': canMode1BtnsShow,
						'editor_ipX_padding_bottom': $env.iphonex
					}">
						<img class="easy-btn change" src="http://resali.lititutu.cn/tuku/prod/9b800ed2b47327bdeaefe95ba85932e0.png?v=1" @click="onModulesChange">
						<img class="easy-btn share" v-show="$env.web == 'wxa'" src="http://resali.lititutu.cn/tuku/prod/f9087dd870fbe9097b0ef05a021f7c29.png" @click="showTab('save')">
					</div>
					<div class="editor-btn-group_1" :class="{
						'hide': canMode1BtnsShow,
						'editor_ipX_padding_bottom': $env.iphonex
					}">
						<div class="editor-btn-group_up_1" @click="canMode1BtnsShow = !canMode1BtnsShow">
							<img :class="{
								'hide': canMode1BtnsShow
							}" class="editor-btn-group_upicon_1" src="http://resali.lititutu.cn/tuku/prod/b4560f680f5385aac708338647ecbeac.png" />
						</div>
						<EditorBtns :class="{'editor_ipX_padding_bottom': $env.iphonex}" @onBtnClick="showTab"></EditorBtns>
					</div>
				</template>

				<template v-else>
					<template v-if="showSaveBtn">
						<img class="btns_wxa_save" src="http://resali.lititutu.cn/icons/app/finish.png" v-show="!downloadAble" @click="showTab('save')">
						<img class="btns_wxa_save" src="http://resali.lititutu.cn/icons/app/save.png?v=1" v-show="downloadAble" @click="showTab('save')">
					</template>
					<EditorBtns @onBtnClick="showTab"></EditorBtns>
				</template>

				<!-- 制作演示hint -->
				<div class="hint-make" v-if="isHintMakeShow">
					<div class="hint-make-content">
						<img class="hint-make-close" src="http://litiresource.xcgogo.site/user/5980171/images/d9a8c8b74ae9eb9ae436f18d5fd0a039.png" @click="onHintMakeClose">
						<template v-for="(img, index) in hintMakeImgs">
							<img :src="img" class="hint-make-img" v-show="index == hintMakeIdx" @click="onHintMakeClick">
						</template>
					</div>
				</div>
					
				<ThemeMenu v-show="tab.menu" @onThemeSelect="onThemeSelect"></ThemeMenu>
				
				<TabInfo ref="tab_info" v-show="tab.info" @hideTab="hideTab" @showTab="showTab"></TabInfo>	
				<TabMusic ref="tab_music" v-show="tab.music" :musicShow="tab.music" @hideTab="hideTab"></TabMusic>
				<TabText ref="tab_text" v-show="tab.text" :open_status="tab.text" :isTabShow="isTabShow" @hideTab="hideTab" @showTab="showTab" @refreshPage="refreshPage"></TabText>
				
				<EditorClose v-show="isTabCloseShow" @hideTab="hideTab"></EditorClose>
				<tab-complete v-show="tab.save" from="save" @hideTab="hideTab"></tab-complete>
				<after-share 
					v-if="isAfterShareShow" 
					:showStatus="isAfterShareShow" 
					@hide="hideAfterShare"
					@refreshPage="refreshPage"></after-share>

				<Copyright></Copyright>

				<!-- hint -->
				<div class="hintpv-con" v-show="isHintPvShow">
					<div>
						<img class="hintpv-bd" @click="onMakeTap" :class="{heartBeat: isHintPvShow}" :src="hintPvSrc">
						<img class="hintpv-close" @click="onHintHide" src="http://litiresource.xcgogo.site/user/5980171/images/d9a8c8b74ae9eb9ae436f18d5fd0a039.png">
					</div>
				</div>

				<div class="key-hint">{{$route.query.key}}</div>
				
				<!-- 结束页面 -->
				<EndPage v-if="isEndPageShow" @refreshPage="refreshPage" @onMakeTap="onMakeTap" @closeEndPage="closeEndPage" open_type="editor" :downloadAble="downloadAble"></EndPage>

				<!-- 歌词 -->
				<div id="music-lrc"></div>
				
			</div>
		</template>
		<NetError v-show="!netWorkHealth" @retry="reloadPage"></NetError>

		<!-- 分享tips -->
		<!-- <pre-share 
			ref="pre_share"
			:onHide="onPreShareHide"></pre-share> -->

		<!-- 归档相册请求等待提示 -->
        <template v-if="checkColded === false && checkColdRest > 0">
			<span class="image-cold_tips_1">相册加载中，感谢您的耐心等待🌹</span>
			<span class="image-cold_tips_2">预计等待时间{{checkColdRest}}秒</span>
		</template>
	</div>
</template>

<script>
	import C from '@/config'
	
	import Tabbar from '@/views/editor/Tabbar'
	import TabInfo from '@/views/editor/TabInfo'
	import TabMusic from '@/views/editor/TabMusic'
	import TabText from '@/views/editor/TabText'

	import EditorClose from "@/views/editor/EditorClose"
	import EditorBtns from "@/views/editor/EditorBtns"

	// import xc from '@/components/themes/xc/Xc'
	import xc from '@/components/st_comps/td_comp/comps/modules/xc/xc/Xc'
	import full from '@/components/themes/full/Full'
	import exhi from '@/components/themes/exhi/Exhi'
	import wscene from '@/components/themes/wscene/Wscene'
	import other from '@/components/themes/other/Other'
	import simple3d from '@/components/themes/simple3d/Simple3d'
	import normal from '@/components/themes/normal/Normal'
	import simple from '@/components/themes/simple/Simple'

	import ThemeMenu from '@/components/ThemeMenu'
	import TabComplete from '@/components/TabComplete'
	import Copyright from '@/components/Copyright'
	import Zan from '@/components/Zan'
	import Enter from '@/components/Enter'
	import EndPage from '@/components/EndPage'
	import NetError from '@/components/NetError'
	import AfterShare from '@/components/AfterShare'
	// import PreShare from '@/components/PreShare'

	export default {
		components: {
			Tabbar,
			ThemeMenu,
			TabInfo,
			TabMusic,
			TabComplete,
			TabText,

			EditorClose,
			Copyright,
			Zan,
			Enter,
			EditorBtns,
			
			xc,
			full,
			exhi,
			wscene,
			other,
			simple3d,
			normal,
			simple,

			EndPage,
			NetError,
			AfterShare,
			// PreShare,
		},
		data () {
			return {
				tab: {
					menu: false,
					info: (this.$route.query.action == 'editimage' ? true : false),
					music: false,
					title: false,
					save: false,
					text: false,
				},
				isTabCloseShow: (this.$route.query.action == 'editimage' ? true : false),
				isEditorShow: false,
				isThemeShow: true,
				isAfterShareShow: false,
				
				isHintPvShow: false,
				hintPvSrc: '',

				hintMakeIdx: 0,
				hintMakeImgs: [
					'http://litiresource.xcgogo.site/user/5980171/images/b70a46ce9db0d7e35c0fe2f1e96763d9.png',
					'http://litiresource.xcgogo.site/user/5980171/images/f70721cd0102faf5413b09481dd315d1.png',
					'http://litiresource.xcgogo.site/user/5980171/images/d4c2693df9d4a82452b5ebf350e953f3.png',
					'http://litiresource.xcgogo.site/user/5980171/images/2f5775e70d8194721d5ae8d33d62c1e2.png',
				],
				isHintMakeShow: false,
				isEasyBtnShow: false,
				easyBtnIndex: 0,

				btnTada: false,

				lastTheme: '',
				lastTid: '',

				// isEndPageShow: false,
				// canEndPageShow: true,

				// 用户是否执行修改
				// isEdited: false,

				// 菜单打开状态
				isTabShow: false,

				isEnterShow: false,
				isCanvasShow: false,
				netWorkHealth: true,

				// 记录编辑状态用于计数 byhz 2021.03.25
				isModify: false,

				checkColdRuntime: 0,
                checkColdRest: 0,
                checkColdRestEvent: null,
				checkColded: false,

				canMode1BtnsShow: true
			}
		},
		computed: {
			editorEventId () {
				return this.$route.query['fast_make'] || '';
			},
			currentMode () {
				let currentMode = this.$store.state.themes[ this.$store.state.config['data']['ttid'] ];
				if (!currentMode['mode']) {
					currentMode['mode'] = {};
				}
				return currentMode;
			},
			currentTheme () {
				let currentTheme = this.currentMode['type'],
					currentTid = this.$store.state.config['data']['ttid'];

				this.lastTid = currentTid;
				this.lastTheme = currentTheme;

				
				return currentTheme;
			},
			downloadAble () {
				try {
					if (this.currentMode['scene']) {
						return true;
					} else {
						return false;
					}
				} catch(e) {
					return true;
				}
			},
			showSaveBtn () {
				// action参数等于post_square时不显示保存按钮
				if (this.$route.query['action'] && this.$route.query.action.indexOf('post_square') != -1) {
					return false;
				}
				// 如果存在引流则显示保存
				if (this.$store.state.appConfig.share_mode) {
					return true;
				}
				// 兜底小程序显示保存按钮
				return this.$env.web == 'wxa';
			},
			isEndPageShow () {
				return this.$store.state.endPageRuntime == 1;
			}
		},
		created () {
			this.$mta.click('pv_editor');
			// try {
			// 	_czc && _czc.push(["_trackEvent", "编辑页", "访问"]);
			// } catch(e) {
			// 	console.error(e);
			// }

			this.isHintMakeShow = this.$route.query.is_new == 2 && this.$env.web == 'wxa';
			this.isEasyBtnShow = this.$route.query.is_new == 1 && this.$env.web == 'wxa';

			// 控制按钮动画
			setInterval(() => {
				this.btnTada = true;
				setTimeout(() => {
					this.btnTada = false;
				}, 1000);
			}, 1500);

			// 随机生成一个0 - 100的easybtn的起始下标
			this.easyBtnIndex = Math.floor(Math.random() * 100)

			// 监听结束页
			// window.addEventListener("message", (e) => {
			// 	e = e || window.event;

			// 	e = e.data;

			// 	if ("played" === e["action"]) {
			// 		this.showEndPage();
			// 	}
			// });
			this.$util.tdListen(e => {
				if ("played" === e["action"]) {
					this.showEndPage();
				}
            })
		},
		mounted () {
			
			// 判断页面是否退出调整音乐播放
			document.addEventListener('visibilitychange', () => {
				if ("hidden" == document.visibilityState) {
					this.pauseMusic();
				} else {
					this.playMusic();
				}
			});

			// 若在小程序中
			if ('wxa' == this.$env.web) {
				// 向小程序传递key，用于分享
				wx.miniProgram.postMessage({ data: {key: this.$route.query.key} });
			}
		},
		beforeCreate () {
			let _this = this;
			this.$o_util.rget('dconfig', {
				'key': this.$route.query.key,
				'appid': this.$route.query._source
			})
			.then((response) => {
				_this.loadDconfig(response);
			})
			.catch((response) => {
				if (response['status'] == 'empty') {
					window.location.href = "/vliti/main/empty?key="+this.$route.query.key;
				} else if (response['status'] == 'green') {
					this.$o_util.block(response['appid'], response['openid']);
				} else {
					_this.netWorkHealth = false;
				}
			});
		},
		methods: {
			login (appid, openid, authorid) {
				// 2022.04.21 byhz 暂时取消登陆逻辑
				return Promise.resolve();
				
				// return new Promise((resolve, reject) => {
				// 	// is_manager不需登陆
				// 	if (this.$route.query['is_timeline']) {
				// 		resolve();
				// 	} else {
				// 		// 当环境为微信内h5页面时登陆
				// 		if (this.$o_util.isWeiXin() && this.$env.web != 'wxa') {
				// 			loginManager.run(appid, openid, authorid, state => {
				// 				if (!state) {
				// 					// 若登录未完成，则不进行后续逻辑
				// 					reject();
				// 				} else {
				// 					resolve();
				// 				}
				// 			})
				// 		} else {
				// 			resolve();
				// 		}
				// 	}
				// })
			},
			wxPreview (image) {
				let preview_image = '';

				if (typeof(image) == 'string') {
					preview_image = image;
				} else {
					preview_image = image['im'];
				}

				this.$wx.previewImage(preview_image, [preview_image]);
			},
			showTab (target) {
				this.isTabShow = true;
				// 重置编辑状态
				// this.toggleEdited(false);

				// 兼容2020.06.19发文事故处理
				// if (this.$store.state.config['data']['appid'] == 'wxa14f0346ddc3bd37' && this.$store.state.config['data']['openid'] == 'oUvY81vTLkVYouuIjyUf8vzzJtBc' && this.$route.query['is_manager'] != 1) {
				// 	return;
				// }

				let _this = this;
				const ST = ['zhongqiubianlian', 'guoqingbianlian', 'guoqingheying', 'chongyangheying'];

				if (this.$audio.exist() && ['share', 'save'].indexOf(target) == -1 ) {
					this.$audio.hide();
				}

				if (target == 'save') {

					if (this.$env.web == 'wxa') {
						// this.$refs.audio_0.pause();
						this.pauseMusic();

						let navi_url = '/pages/savePage/savePage?key='+this.$route.query.key+'&_source='+this.$route.query._source;
						if (this.downloadAble) {
							navi_url = navi_url + "&mode=save";
						}
						wx.miniProgram.navigateTo({
					      	url: navi_url,
					    })
					} else {
						this.$toast.loading('保存中');
						setTimeout(() => {
							this.$toast.clear();
							this.tab['save'] = true;
						}, 700);
					}

					this.$o_util.log('theme_editor_done_'+this.$store.state.config['data']['ttid']);
					// 验证打开demo页再制作的效率，临时计数
					if (this.$route.query['t_make'] == 1) {
						this.$o_util.log('t_make_theme_editor_done_'+this.$store.state.config['data']['ttid']);
					}

					// 埋点
					this.$util.$loger.center.set_log({
						field: this.$util.getStLogEnv() + '_' + this.$util.getStLogPage() + '_save_click'
					})

					return;

				} else if (target == 'share') {
					
					
					this.$o_util.log('theme_editor_done_'+this.$store.state.config['data']['ttid']);
					// 验证打开demo页再制作的效率，临时计数
					if (this.$route.query['t_make'] == 1) {
						this.$o_util.log('t_make_theme_editor_done_'+this.$store.state.config['data']['ttid']);
					}

					if (this.$env.web == 'wxa') {
						this.$mta.click('wxa_editor_btn_share');
						this.$mta.click('wxa_editor_btn_share_'+this.$route.query._source);
						wx.miniProgram.navigateTo({
							  url: '/pages/savePage/savePage?key='+this.$route.query.key+'&share=1'
						});
					} else {
						this.$mta.click('h5_editor_btn_share');
						this.$mta.click('h5_editor_btn_share_'+this.$route.query._source);
						// this.$refs.pre_share.show();
					}

					this.pauseMusic();

					// 埋点
					this.$util.$loger.center.set_log({
						field: this.$util.getStLogEnv() + '_' + this.$util.getStLogPage() + '_share_click'
					})

					return;

				} else if (target == 'music') {

					this.pauseMusic();
					this.$audio.hide();

				} else if (target == 'menu') {
					
					// this.$o_util.log('theme_editor_select_'+this.$store.state.config['data']['ttid']);

				} else if (target == 'info') {

					if (ST.indexOf(this.$store.state.config.data['ttid']) != -1) {
						this.$toast.text({
							message: '十分抱歉，当前模板暂不支持换图噢',
							duration: 2
						});
						return;
					}

					this.$refs.tab_info.modi = false;

				} else if (target == 'text') {

					if (ST.indexOf(this.$store.state.config.data['ttid']) != -1) {
						this.$toast.text({
							message: '十分抱歉，当前模板暂不支持写字噢',
							duration: 2
						});
						return;
					}

					this.$refs.tab_text.modi = false;

				} else if (target == 'post_square'){
					wx.miniProgram.postMessage({ data: {refresh_list: 1} });
					_this.$toast.text({
						'message': this.$route.query.action,
						'duration' : 10,
					})
					this.$o_util.rget("set_event",{
						"__appid__": this.$store.state.config['data']['appid'],
						"openid": this.$store.state.config['data']['openid'],
						"xc_key": this.$route.query.key,
						"event": this.$route.query.action.replace("post_square_",""),
					}).then(res=>{
						_this.$toast.text({
							'message': "发布成功",
							'duration': 3
						})
					})
					return;
				}

				this.$util.$loger.log("editor_btn_click", {
					"btn_type": target,
					"tid": this.$store.state.config['data']['ttid'],
					"is_webview": this.$util.isEnv('webview')
				});

				this.$mta.click("menu_"+target);

				this.tab[target] = true;

				this.isTabCloseShow = true;
			},
			hideTab (item) {
				let target;
				for (target in this.tab) {
					if (this.tab[target]) {
						break;
					}
				}

				if (this.$audio.exist()) {
					this.$audio.show();
				}

				if (this.isEndPageShow) {
					this.refreshPage();
					this.closeEndPage();
					// this.isEndPageShow = false;
					// this.canEndPageShow = true;
				}

				// 特定模板更改图片需要刷新页面
				// this.$store.dispatch('getTheme', {tid: this.$store.state.config.data['ttid']}).then((theme) => {
				// 	if (['info', 'text'].indexOf(target) != -1 && this.isEdited) {
				// 	// if (['info', 'text'].indexOf(target) != -1 && ['exhi', 'xc', 'wscene'].indexOf(theme['type']) != -1 && this.isEdited) {
				// 		this.refreshPage();
				// 	}
				// });

				if (target == 'music') {
					// 若存在试听audio 则暂停
					if (this.$refs.tab_music.tryAudio) {
						this.$refs.tab_music.tryAudio.pause();
					}

					// 选择了音乐
					if (item) {
						// 选择了kugou音乐
						if (item['kugou']) {
							// 若原本是库音乐，则先销毁
							if (this.$audio.exist()) {
								this.$audio.destroy();
							}
							this.$kgPlayer.init2(item['kugou']['music']);
						} else {
							if (!this.$audio.exist()) {
								this.$kgPlayer.player.destroy();
							}
							// 若有歌词
							if (item['audio']['music']['lrc']) this.setLrcStyle(item['audio']['music']);
							this.$audio.change(item['audio']['music']);
						}

						// 记录修改状态
						this.__markModify();
					} 
					// 没有选择音乐
					else {
						this.playMusic();
					}
				} 
				else if (target == 'info') {
					if (this.$refs.tab_info.modi) {
						// 记录修改状态
						this.__markModify();
					}
				} else if (target == 'text') {
					if (this.$refs.tab_text.modi) {
						// 记录修改状态
						this.__markModify();
					}
				}

				this.__hideTab(target);
			},
			__hideTab (target) {
				// 执行页面显影
				this.isTabShow = false;
				this.tab[target] = false;
				this.isTabCloseShow = false;
			},
			// refreshEditor () {
			// 	this.isThemeShow = false;
			// 	this.$nextTick(() => {
			// 		this.isThemeShow = true;

			// 		// 重启elems模式下的图片获取
			// 		this.$util.restartUserPhoto();

			// 		// 音乐重播
			// 		if (this.$audio.exist()) {
			// 			this.$audio.replay();
			// 		} else {
			// 			this.$kgPlayer.init2( this.$kgPlayer.getInfo() );
			// 		}
			// 	});
			// },
			refreshPage () {
				// 重置是否可以展示结束页逻辑
				this.isThemeShow = false;
				
				this.$nextTick(() => {
					this.isThemeShow = true;

					// 重启elems模式下的图片获取
					this.$util.restartUserPhoto();

					// 音乐重播
					if (this.$audio.exist()) {
						this.$audio.replay();
					} else {
						this.$kgPlayer.init2( this.$kgPlayer.getInfo() );
					}
					// 歌词重置
					this.$store.commit('setLrc', {
						"lrc": ""
					})
				});
			},
			onMakeTap (tid, vscene) {
				let _this = this;
				let appid = _this.$route.query._source;

				_this.$o_util.log(['select_image_4002', 'select_image_h5']);

				_this.$o_util.uploadMedia({
					violate: async function () {
						return new Promise((resolve, reject)=>{
							resolve("C9130828AE2347CD2AD3F8D4C98EDC89")
						})
					},
					process (index, len) {
						if (index == 0) {
							_this.$toast.loading({
								message: '加载中...',
								duration: 'infinite'
							})
						} else if(index == len) {
							_this.$toast.loading('创建中...');
						} else {
							_this.$toast.loading(index+'/'+len+' 上传中');
						}
					},
				}).then(image_infos=>{ //images
					let params = {
						'appid': _this.$store.state.config['data']['appid'],
						'openid': _this.$store.state.config['data']['openid'],
						// 'urls': images.join(','),
						'imgInfos': JSON.stringify(image_infos),
						'vscene': '4002'
					};
					if (tid) {
						params['tid'] = tid;
					}
					_this.$o_util.rpost('/vliti/new_book', params)
					.then((response) => {
						_this.$toast.clear();
						let jumpurl = "/vliti/main/"+(_this.$store.state.appConfig.editor_path)+"?key="+response+'&_source='+appid;
						if (vscene) {
							jumpurl += '&vscene=2012';
						}
						window.location.href = jumpurl;
					})
					.catch((err) => {
						_this.$toast.clear();
						_this.$toast.text({
							'message': err
						})
					});
				}).catch(err=>{
					_this.$toast.clear();
				})
				_this.$mta.click('hintpv_btn_newbook');
			},
			onHintHide () {
				this.isHintPvShow = false;
				this.$mta.click('hintpv_btn_close');
			},
			showPvHint () {
				let _map = {
					'pv_100': 'http://litiresource.xcgogo.site/user/5980171/images/da94dc6118eb4469fca9229f29437811.png',
					'pv_50': 'http://litiresource.xcgogo.site/user/5980171/images/513e4035083c513dde2252d7a76b41e3.png',
					'pv_20': 'http://litiresource.xcgogo.site/user/5980171/images/222c57dfb98b262ba0646a8e0c891de4.png',
					'pv_10': 'http://litiresource.xcgogo.site/user/5980171/images/e0fc04d8016e10c90df4c287b3c67582.png',
					'pv_5': 'http://litiresource.xcgogo.site/user/5980171/images/308119acb4343d3bb5a70705b378f95d.png',
					'pv_1': 'http://litiresource.xcgogo.site/user/6403275/images/61d467a27d7f8de46595c21ce0e9e332.png'
				}
				let p = {},
					hintsrc = _map['pv_'+this.$route.query.num],
					_this = this;

				p[this.$route.query['num']] = 'true';
				this.$mta.click('pv_hintpv', p);

				let im = new Image();
				let handle = function() {
					_this.isHintPvShow = true;
					_this.hintPvSrc = hintsrc;
				}
				im.onload = handle;
				im.onerror = handle;

				im.src = hintsrc;
			},
			onHintMakeClick () {
				this.hintMakeIdx == 0 && wx.miniProgram.postMessage({ data: {guided: 1} });

				if (this.hintMakeIdx == this.hintMakeImgs.length-1) {
					this.isHintMakeShow = false;
					return;
				}
				this.hintMakeIdx ++;
			},
			onHintMakeClose () {
				this.isHintMakeShow = false;
				wx.miniProgram.postMessage({ data: {guided: 1} });
			},
			onEasyChange () {
				this.$store.dispatch('setThemeData', {}).then((themeData) => {
					let tids = themeData['theme_type_data']['hot_v2']['tids'];
					let tid = tids[ this.easyBtnIndex % tids.length ];
					this.onThemeSelect( tid );
					this.easyBtnIndex ++ ;
				});
			},
			onModulesChange () {
				this.$store.dispatch('getChangeModules', {
					"event_id": this.editorEventId
				}).then(modules => {
					let tid = modules[ this.easyBtnIndex % modules.length ];
					this.onThemeSelect( tid );
					this.easyBtnIndex ++ ;
				})
			},
			onEasyShare () {
				this.isEasyBtnShow = false;
				this.isHintMakeShow = true;
				this.showTab('share');
			},
			onThemeSelect (tid, isLoad) {
				isLoad = isLoad === undefined ? true : false;

				let last_tid = this.$store.state.config.data['ttid'],
					next_tid = tid,
					_this = this;

				// 关闭editor，显示loading
				_this.isThemeShow = false;
				// 更新进场动画状态
				_this.isEnterShow = false;
				_this.isCanvasShow = false;
				// 更新endpageRuntime
				_this.$store.commit('restartEndPageRuntime');

				_this.$mainloading.show();

				_this.$o_util.log('theme_editor_pagein_'+next_tid);

				_this.$store.dispatch('getTheme', {tid: next_tid}).then((next_theme) => {

					let type = next_theme['type'];

					_this.hideTab();

					// 记录被替换时原本的主题tid
					_this.$o_util.log('theme_editor_select_'+last_tid);

					// 验证打开demo页再制作的效率，临时计数
					if (_this.$route.query['t_make'] == 1) {
						_this.$o_util.log('t_make_theme_editor_select_'+last_tid);
					}
					
					_this.$store.dispatch('setdata', {
						k: 'ttid',
						v: tid,
						key: _this.$route.query.key
					})
					.then(() => {
						if ('full' == type) {
							_this.$store.commit('setfullSceneNum', 'clear');
						}

						// 设置分享标题
						// _this.$store.dispatch('getAppConfig', 'share_title').then((default_share_title) => {
							_this.$wx.share({
								title: _this.$util.getShareTitle(),
								imgUrl: _this.$o_util.getShareImgurl(),
							});
						// });
						document.title = _this.$store.state.config['data']['ti'] || next_theme['name'] || '甜豆相册';

						// 判断 修改音乐
						let next_mid = next_theme['mid'];

						// 如果没有选择音乐、或选择的音乐与模板默认音乐一致，则播放下一模板默认音乐
						if (!_this.$store.state.config['data']['mid']) {
							_this.$o_util.getMusic(next_mid).then((music) => {
								// 下一首是库音乐
								if (music['src']) {
									// 当前是库音乐
									if (_this.$audio.exist()) {
										// 若有歌词
										// if (music['lrc']) music['lrc_color'] = _this.getUserTextColor();
										if (music['lrc']) _this.setLrcStyle(music);
										_this.$audio.change(music);
									} 
									// 当前是kugou音乐
									else {
										_this.$kgPlayer.player.destroy();
										// 若有歌词
										// if (music['lrc']) music['lrc_color'] = _this.getUserTextColor();
										if (music['lrc']) _this.setLrcStyle(music);
										_this.$audio.init(music);
										_this.$audio.play();
									}
								}
								// 下一首是kugou音乐
								else {
									// 当前是库音乐
									if (_this.$audio.exist()) {
										_this.$audio.destroy();
										_this.$kgPlayer.init2(music);
									} 
									// 当前是kugou音乐
									else {
										_this.$kgPlayer.player.playSong(music);
									}
								}
							})
						} else {
							// 音乐重播
							if (_this.$audio.exist()) {
								// _this.$audio.changeLrcColor( _this.getUserTextColor() );
								_this.$audio.changeLrcColor( _this.setLrcStyle() );
								_this.$audio.replay();
							} else {
								_this.$kgPlayer.init2( _this.$kgPlayer.getInfo() );
							}
						}

						// 加载资源
						if (isLoad) {
							// 部分模板加载前需要加载所有照片，因此这里重新调用一次逻辑
							let loadingImages = this.$o_util.getFirstLoadPhoto(next_theme, this.$store.state.config.images);

							// 加载第一轮的模板图片
							loadingImages = loadingImages.concat( this.$o_util.getFirstLoadTheme(next_theme) );

							// 加载帧序列图片
							loadingImages = loadingImages.concat( this.$o_util.getGifImages(next_theme) );

							_this.$mainloading.load({
								images: loadingImages,
								onUpdate: function(imagesrc, imageobj) {
									// 预加载的同时写入用户图片的尺寸数据
									if (typeof imagesrc == 'object') {
										_this.$store.commit('setImageOriSize', {'imageid': imagesrc['id'], 'orisize': imagesrc['orisize']});

										imagesrc = imagesrc['im'];
										imageobj = {
											'width': imageobj.width,
											'height': imageobj.height
										}
									}

									// 存储图片对象
									_this.$store.commit('setImageObj', {'imagesrc': imagesrc, 'imageobj': imageobj});
								},
								onEnd: function () {
									// 更新进场动画状态
									_this.isEnterShow = next_theme['mode'] && next_theme['mode']['enter'] ? true : false;
									_this.isCanvasShow = !_this.isEnterShow;

									// 更新图片游标
									_this.$util.restartUserPhoto();

									// 打开模板
									_this.isThemeShow = true;
								}
							});

						} else {
							_this.$mainloading.hide();

							// 更新进场动画状态
							_this.isEnterShow = next_theme['mode']['enter'] ? true : false;
							_this.isCanvasShow = !_this.isEnterShow;

							// 更新图片游标
							_this.$util.restartUserPhoto();

							// 打开模板
							_this.isThemeShow = true;
						}
					});

					// 发送选择的模板id给小程序端
					if (_this.$env.web == 'wxa') {
						wx.miniProgram.postMessage({ data: {update_tid: tid} });
					}

					// 记录被替换的tid
					let mta_param = {};
					mta_param[tid] = 'true';
					_this.$mta.click('btn_theme_'+type, mta_param);
				});
			},
			hideAfterShare () {
				this.isAfterShareShow = false;
			},
			__wxShareInit () {
				let _this = this;
				// _this.$store.dispatch('getAppConfig', 'share_title').then((default_share_title) => {
					// 设置scene参数
					let scene_param = [
						'vscene=2003'
					];
					if (_this.$route.query['vscene']) {
						scene_param.push('lscene='+_this.$route.query['vscene']);
					};

					let share_link = _this.$o_util.getLocationPath(_this.$o_util.getSharePath()) + "?key="+_this.$route.query.key+"&_source="+_this.$route.query._source+"&"+scene_param.join('&');
					if (_this.$route.query['ulog']) {
						share_link += "&ulog=" + _this.$route.query['ulog'];
					}
					_this.$wx.share({
						// title: default_share_title || _this.$o_util.getShareTitle(_this.$store.state.config),
						title: _this.$util.getShareTitle(),
						desc: '甜豆相册，送给你！',
						imgUrl: _this.$o_util.getShareImgurl(),//_this.$o_util.getShareImgurl(_this.$store.state.config),
						link: share_link,
						success: () => {
							_this.$o_util.log('theme_editor_done_'+_this.$store.state.config['data']['ttid']);
							
							// 更新微信sdk，on系列事件已废弃
							// _this.isAfterShareShow = true;
							_this.hideTab();
							
							// // 关闭preShare
							// _this.$refs.pre_share.hide();
						},
						from: 'editor'
					});
				// });
			},
			pauseMusic () {
				if (this.$audio.exist()) {
					this.$audio.pause();
				} else {
					this.$kgPlayer.player.pause();
				}
			},
			incrEndPage () {
				this.$store.commit('incrEndPageRuntime');
			},
			showEndPage () {
				// if (!this.isEndPageShow && this.canEndPageShow && !this.isMakeDetail) {
				// 	this.isEndPageShow = true;
				// }
				this.incrEndPage();
			},
			closeEndPage () {
				// this.isEndPageShow = false;
				// this.canEndPageShow = false;
				this.incrEndPage();
			},

			// 切换编辑状态
			// toggleEdited (status) {
			// 	this.isEdited = status;
			// },
			// 用于切换音乐时补充歌词颜色的逻辑
			getUserTextColor () {
				let color = '#dc2727';
				if (this.currentMode['mode'] && this.currentMode['mode']['text'] && this.currentMode['mode']['text']['textShadowColor']) {
					color = this.currentMode['mode']['text']['textShadowColor'];
				}
				return color;
			},
			// 绑定歌词样式
			setLrcStyle (music) {
				let shadow_color = "#dc2727",
					color = "#ffffff",
					s = {};
				if (this.currentMode['mode'] && this.currentMode['mode']['text']) {
					shadow_color = this.currentMode['mode']['text']['textShadowColor'] || shadow_color;
					color = this.currentMode['mode']['text']['color'] || color;
					s = this.currentMode['mode']['text']['style'] || {};
				}
				if (music) {
					music['lrc_color'] = shadow_color;
					music['lrc_text_color'] = color;
					music['lrc_style'] = s;
				}
				return {'lrc_color': shadow_color, 'lrc_text_color': color, 'lrc_style': s};
			},
			// 显示模板画布
			showCanvas () {
				this.isCanvasShow = true;
			},
			// 结束开场
			endEnter () {
				this.isEnterShow = false;
			},
			// 重新加载页面
			reloadPage(){
				let _this = this;
				this.$o_util.rget('dconfig', {
					'key': this.$route.query['key'],
					'appid': this.$route.query['_source']
				})
				.then((response) => {
					_this.loadDconfig(response)
				})
				.catch((response) => {
					if (response['status'] == 'empty') {
						window.location.href = "/vliti/main/empty?key="+_this.$route.query.key;
					} else if (response['status'] == 'green') {
						_this.$o_util.block(response['appid'], response['openid']);
					} else {
						_this.netWorkHealth = false;
					}
				});
			},
			loadDconfig(response){
				let _this = this;
				// 用在网络错误刷新时重新加载页面
				_this.netWorkHealth = true;
				// 登录逻辑
				_this.login(response['data']['appid'], response['data']['openid'], response['authorid']).then(() => {
					// 记录pv
					let logfield = [],
						vscene = _this.$route.query['vscene'],
						lscene = _this.$route.query['lscene'];
					// byhz 11.20 vscene为3001的页面访问不做计数
					if (vscene != '3001') {
						logfield.push( _this.$env.web == 'wxa' ? 'wxa-editor' : 'h5-editor' );
					}
					if (vscene) {
						logfield.push('vscene_'+vscene);
						// 标记初始vscene
						if (!response['data']['vscene']) {
							_this.$store.dispatch('setdata', {
								k: 'vscene',
								v: vscene,
								key: _this.$route.query.key
							});
						}
					}
					if (lscene) {
						logfield.push('lscene_'+lscene);
					}
					// 记录登陆后的域名计数
					logfield.push('host_real_editor_'+window.location.host);
					
					_this.$o_util.log(logfield);

					// 兼容2020.06.19发文事故处理
					// if (response['data']['appid'] == 'wxa14f0346ddc3bd37' && response['data']['openid'] == 'oUvY81vTLkVYouuIjyUf8vzzJtBc' && _this.$route.query['is_manager'] != 1) {
					// 	window.location.href = window.location.href.replace('editor', 'share');
					// }

					// StLog计数
					_this.$util.$loger.center.set_log({
						field: this.$util.getStLogPrefix() + '_dconfig_in'
					}, 1)

					// 新数据载入store
					_this.$store.commit('config', {
						config: response
					});

					// 图片数据处理
					_this.$util.webpChecker(response['images']).then(() => {
						let photos = [];
						for(let i=0,len=response['images'].length; i<len; i++) {
							// response['images'][i]['im'] = _this.$o_util.im( response['images'][i] );
							response['images'][i]['im'] = _this.$util.packageIm( response['images'][i] );
							photos.push(response['images'][i]);
						}
						if ( this.__checkImagesCold(response) != "stand" ) {
							return;
						}
	
						// 素材图片预加载
						_this.$store.dispatch('getTheme', {
							tid: response['data']['ttid']
						}).then((theme) => {
	
							// 获取用户信息
							_this.$store.dispatch('getUserInfo').then((userInfo) => {});
	
							// 加载第一轮的用户图片
							let loadingImages = this.$o_util.getFirstLoadPhoto(theme, photos);
	
							// 加载第一轮的模板图片
							loadingImages = loadingImages.concat( this.$o_util.getFirstLoadTheme(theme) );
	
							// 加载帧序列图片
							loadingImages = loadingImages.concat( this.$o_util.getGifImages(theme) );
	
							// this.$o_util.log('mainloading_h5_editor_1');
	
							_this.$mainloading.load({
								images: loadingImages,
								onUpdate: function(imagesrc, imageobj) {
									// 预加载的同时写入用户图片的尺寸数据
									if (typeof imagesrc == 'object') {
										_this.$store.commit('setImageOriSize', {'imageid': imagesrc['id'], 'orisize': imagesrc['orisize']});
	
										imagesrc = imagesrc['im'];
										imageobj = {
											'width': imageobj.width,
											'height': imageobj.height
										}
									}
	
									// 存储图片对象
									_this.$store.commit('setImageObj', {'imagesrc': imagesrc, 'imageobj': imageobj});
								},
								onEnd: function() {
	
									// _this.$o_util.log('mainloading_h5_editor_2');
									
									// 设置初始mid
									let mid = _this.$store.state.config['data']['mid'] || theme['mid'];
									_this.$o_util.getMusic(mid).then((music) => {
										if (music['src']) {
	
											// 若有歌词
											if (music['lrc']) _this.setLrcStyle(music);
											_this.$audio.init(music);
											_this.$audio.play();
											// 判断歌词是否可以显示
											if(_this.$store.state.wordCur.wordStatus == 1){
												_this.$audio.closeLrc();
											}
	
											let mta_param = {};
											mta_param[ _this.$env.web + 'audio' ] = 'true';
											_this.$mta.click('music_play', mta_param);
	
											// 音乐自动播放 补充逻辑
											try {
												// let im = new Image();
												// im.onload = function() {
												// 	_this.$audio.play();
												// };
												// im.src = photos[0]['im'];
	
												let istouchplay = false;
												document.getElementById('ccanvas').addEventListener('touchstart', function() {
													if (istouchplay) {
														return;
													}
													istouchplay = true;
													_this.$audio.play();
												}, false);
											} catch(e) {}
	
										} else {
											_this.$wx.checkJsApi().then(() => {
												_this.$kgPlayer.init2(music);
											})
	
											let mta_param = {};
											mta_param[ _this.$env.web + 'kugou' ] = 'true';
											_this.$mta.click('music_play', mta_param);
										}
										_this.$store.commit('setMusicInfo', music);
									})
	
									// 设置标题
									document.title = _this.$store.state.config['data']['ti'] || theme['name'] || '甜豆相册';
	
									// action事件
									if (_this.$route.query['action'] == 'hintpv' && _this.$route.query['num']) {
										_this.showPvHint();
									}
	
									// 记录制作人信息 废弃 用新的登录逻辑代替
									// _this.$o_util.setSlog('riti_author3', _this.$store.state.config.data['appid']+','+_this.$store.state.config.data['openid']);
	
									// 初始化微信分享
									if (['mybook', 'share'].indexOf(_this.$route.query['pfrom']) != -1) { // 因出现页面跳转后wxsdk失效问题，因此补充reload逻辑
										_this.$wx.reload().then(() => {
											_this.__wxShareInit();
										})
									} else {
										wxReadyListener.load(function() {
											_this.__wxShareInit();
										})
									}
	
									// 刷新页面
									_this.isEditorShow = true;
	
									// 相关计数
									_this.$o_util.log('theme_editor_pagein_'+_this.$store.state.config.data['ttid']);
	
									// _this.$o_util.log('mainloading_h5_editor_3');
									_this.$util.$loger.center.set_log({
										field: _this.$util.getStLogPrefix() + '_module_in'
									}, 1)
	
									// 获取相册分享信息模式
									if(C.debugHost.indexOf(location.host) > -1){
										console.log("获取相册分享信息模式 => ")
										// _this.$store.dispatch('getAppConfig', 'share_title').then((default_share_title) => {
											// console.log(default_share_title)
											console.log("分享标题 => ", _this.$util.getShareTitle());
											console.log("/pages/make/make?tab=make&sence=" + _this.$route.query['key'] + "&_source=" + response['data']['appid']);
											let share_image_path = _this.$o_util.getShareImgurl(true);
											console.log("最终封面=>",share_image_path);
										// })
									}
	
									_this.isEnterShow = _this.currentMode['mode']['enter'] ? true : false;
									_this.isCanvasShow = !_this.isEnterShow;
								}
							});
	
							setTimeout(() => {
								this.$o_util.$loger.log("uni_pageload", {// editor_pagein
									"is_official": this.$store.state.config.data.official ? 1 : 0,
									"is_square": this.$store.state.config.data.sq ? 1 : 0
								})
							}, 500);
	
							// 免登陆期间，直接标记作者
							loginManager._setStorageAuthor(this.$store.state.config.data.appid + ',' + this.$store.state.config.data.openid);
						})
					})
				});
			},
			__markModify () {
				// if (this.isModify == false) {
				// 	try {
				// 		_czc && _czc.push(["_trackEvent", "编辑页", "编辑相册"]);
				// 	} catch(e) {
				// 		console.error(e);
				// 	}
				// }
				// 修改了图片，则刷新界面
				this.refreshPage();

				this.isModify = true;
			},
			playMusic () {
				// 若原本是库音乐，则播放
				if (this.$audio.exist()) {
					this.$audio.play();
					// 若试听了音乐，需销毁
					try {
						this.$kgPlayer.player.destroy();
					} catch(e) {console.warn(e);};
				} else {
					this.$kgPlayer.player.playSong(0);
				}
			},
			onPreShareHide () {
				this.playMusic();
			},
			__checkImagesCold (config) {
                /*
                 * return
                 * cold
                 * stand
                 * over
                 */
                let limit_times = 10,
                    wait_times = 10;

                let is_cold = 'stand';

                if (!config['archived']) {
                    // 不含归档图片 返回继续逻辑
                    this.checkColded = true;
                    return is_cold;
                }

                // 含有归档图片 进行等待
                is_cold = 'cold';

                if (this.checkColdRuntime == 0) {
                    this.checkColdRest = limit_times * wait_times;

                    this.checkColdRestEvent = setInterval(() => {
                        // 倒数完毕
                        if (this.checkColdRest == 0) {
                    		this.checkColded = true;
                            clearInterval(this.checkColdRestEvent);
                        }
                        this.checkColdRest -= 1;
                    }, 1000);

                    // 计数
                    this.$o_util.log("check_image_cold_0");
                }
                
                this.checkColdRuntime ++;
                // 处理
                if (this.checkColdRuntime == limit_times) {
                    is_cold = 'over';
                    alert("抱歉等待，我们会继续取回您的相册，请晚些时候再试。");

                } else if (is_cold == 'cold') {
                    // 10后检查图片状态
                    setTimeout(() => {
                        this.reloadPage();
                    }, 1000 * wait_times);
                }
                return is_cold;
            }
		}
	}
</script>

<style lang="less" scoped>
	.page-editor {
		z-index: 1;
	}
	.page-h5-editor {
		// height: calc(100% - 69px)!important;
	}
	#capp {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.page-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-size: 100% 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
	}

	.element-show {
		animation: element-show .4s both;
	}
	@keyframes element-show {
		from {
			transform: translateY(@bw * 0.014) scale(.9);
			opacity: 0;
		}
		to {
			transform: none;
			opacity: 1;
		}
	}

	.title-model-enter-active, .title-model-leave-active {
		transition: opacity .5s ease-out;
	}
	.title-model-enter, .title-model-leave-to {
		opacity: 0;
	}

	.hintpv-con {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background-color: rgba(0,0,0,.8);
		div {
			position: absolute;
			width:@bw * 1.0;
			height:@bw * 1.067;
			left: 0;
			top: 50%;
			margin-top: -@bw * 0.5;
			.hintpv-bd {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			.hintpv-close {
				position: absolute;
				width:@bw * 0.083;
				height:@bw * 0.083;
				left: 50%;
				margin-left: -@bw * 0.042;
				bottom: -@bw * 0.056;
			}
		}
	}
	@keyframes heartBeat {
		0% {
			transform: scale(1);
		}

		14% {
			transform: scale(1.3);
		}

		28% {
			transform: scale(1);
		}

		42% {
			transform: scale(1.3);
		}

		70% {
			transform: scale(1);
		}
	}
	.heartBeat {
		animation: heartBeat 1.3s ease-in-out;
	}
	.btns_wxa_save {
		position: fixed;
		top:55px;
		// top:@bw * 0.062;
		left:@bw * 0.028;
		width:@bw * 0.208;
		height:@bw * 0.101;
		z-index: 9;
	}

	.hint-make {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, .6);
		z-index: 99;
	}
	.hint-make-content {
		position: absolute;
		bottom:@bw * 0.046;
		width:@bw * 0.972;
		height:@bw * 1.201;
		left: 50%;
		margin-left: -@bw * 0.486;
	}
	.hint-make-img {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.hint-make-close {
		position: absolute;
		width:@bw * 0.111;
		height:@bw * 0.111;
		right:@bw * 0.042;
		top: -@bw * 0.094;

	}
	.editor-btn-box_1 {
		position: absolute;
		bottom: 110px;
		width: 100%;
		height:@bw * 0.278;
		z-index: 9;
	}
	.editor-btn-group_1 {
		position: absolute;
		width: 100%;
		height: 100px;
		background-color: rgba(255,255,255,.8);
		left: 0;
		bottom: 0;
		z-index: 10;
	}
	.editor-btn-group_1, .editor-btn-box_1 {
		transform: none;
		transition: all .6s ease;
	}
	.editor-btn-group_1.hide, .editor-btn-box_1.hide {
		transform: translateY(90px);
	}
	.editor-btn-group_up_1 {
		position: absolute;
		width: 72px;
		height: 30px;
		left: 50%;
		margin-left: -36px;
		top: -30px;
		background: rgba(255,255,255,.8);
		border-radius: 16px 16px 0px 0px;
	}
	.editor-btn-group_upicon_1 {
		position: absolute;
		width: 24px;
		height: 14px;
		bottom: 3px;
		margin-left: -12px;
		left: 50%;
	}
	.editor-btn-group_upicon_1.hide {
		transform: scale(1, -1);
	}
	.easy-btn-box {
		position: fixed;
		bottom: 0;
		width: 100%;
		height:@bw * 0.278;
		background: linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
		z-index: 9;
	}
	.easy-btn {
		position: absolute;
		z-index: 3;
		width:@bw * 0.217;
		height:@bw * 0.217;
		top: 50%;
		margin-top: -@bw * 0.108;
	}
	.easy-btn.change {
		left:@bw * 0.042;
	}
	.easy-btn.share {
		right:@bw * 0.042;
	}
	.tab-menu-container {
		position: absolute;
		width: 100%;
		height: calc(100%-@bw * 0.16);
		padding-top:@bw * 0.16;
		top: 0;
		left: 0;
		z-index: 999;
	}
	.key-hint {
		position: fixed;
		top: 0;
		z-index: 9;
		right: 0;
		color: #5d5d5d;
		opacity: .8;
		letter-spacing:@bw * 0.001;
		font-size: 7.6px;
	}
	// .editor-model-enter-active, .editor-model-leave-active {
	// 	transition: opacity 1s;
	// }
	// .editor-model-enter, .editor-model-leave-to {
	// 	opacity: 0;
	// }
	.image-cold_tips_1, .image-cold_tips_2 {
        font-size: 19px;
        font-weight: 500;
        width: 500px;
        position: absolute;
        text-align: center;
        height: 50px;
        line-height: 50px;
    }
    .image-cold_tips_1 {
        top: 41%;
    }
    .image-cold_tips_2 {
        top: 50%;
        margin-top: -25px;
    }
	.editor_ipX_padding_bottom {
		padding-bottom: 20px; /*兼容 IOS>11.2*/
	}
</style>