<template>
    <div class="hudie3 bg page poc">
        <div class="flyer1">
            <div class="flyer-container" :style="getStyle">
                <elem :mode="myfly"></elem>
            </div>
        </div>

        <div class="flyer2">
            <div class="flyer-container" :style="getStyle">
                <elem :mode="myfly"></elem>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

    export default {
        name: 'hudie3',
        extends: BaseElem,
        computed: {
            myfly () {
                return {
                    "is": "gif",
                    "width": 244,
                    "height": 192,
                    "sprite_image": {
                        "im": "http://resali.lititutu.cn/scenes/meiguifenfang/80899119EA746E2C46671D648DEC093E.png",
                        "steps": 50
                    },
                    "duration": 4,
                    "backgroundWidth": 244,
                    "backgroundHeight": 9600
                }
            },
			getStyle () {
                let twidth = 500, theight = 500;

				if (this.myfly['is'] == 'gif') {
                    twidth = this.myfly.width ? 1.5 * this.myfly.width : 500;
                    theight = this.myfly.height?  1.5 * this.myfly.height : 500;
                }

                return {
                    width: twidth + 'rpx',
					height: theight + 'rpx',
                }
            },
        },
        data () {
            return {}
        },
    }
</script>

<style>
    .hudie3{
        z-index: 8;
        pointer-events: none;
    }
    .flyer-container{
        position: relative;
    }
    .flyer{
        width: 100%;
        height: 100%;
    }
    .flyer1,.flyer2{
        position: absolute;
    }
    .flyer1{
        width: 244.00px;
        right: -300.00px;
        bottom: 0;
        animation: 16s linear 8s infinite normal forwards running fly1animation;
    }

    @keyframes fly1animation {
        0% {
            transform: translate(0, 0) scale3d(0.5, 0.5, 0.5) rotate(-45deg);
            animation-timing-function: ease-in;
        }

        13% {
            transform: translate(-100.00px,0) scale3d(1.3, 1.3, 1.3) rotate(-45deg);
            animation-timing-function: linear;
        }

        50% {
            transform: translate(-932.00px,-746.00px) scale3d(1.3, 1.3, 1.3) rotate(-45deg);
            animation-timing-function: linear;
        }

        100% {
            transform: translate(-932.00px,-746.00px) scale3d(1.3, 1.3, 1.3) rotate(-45deg);
            animation-timing-function: linear;
        }
    }

    .flyer2{
        width:244.00px;
        right: -300.00px;
        top: 0;
        animation: 16s linear 0s infinite normal forwards running fly2animation;
    }

    @keyframes fly2animation {
        0% {
            transform: translate(0,0) scale3d(0.5, 0.5, 0.5) rotate(-135deg);
            animation-timing-function: ease-in;
        }

        13% {
            transform: translate(-100.00px,0) scale3d(1.3, 1.3, 1.3) rotate(-135deg);
            animation-timing-function: linear;
        }

        50% {
            transform: translate(-932.00px,746.00px) scale3d(1.3, 1.3, 1.3) rotate(-135deg);
            animation-timing-function: linear;
        }

        100% {
            transform: translate(-932.00px,746.00px) scale3d(1.3, 1.3, 1.3) rotate(-135deg);
            animation-timing-function: linear;
        }
    }
</style>