<template>
	<div class="litihengfei-container perspective litihengfei-move" :style="getAnimationStyle">
        <div class="preserve3d po" :style="getStyle(index)" v-for="(liti, index) in litis" :key="'litihengfei-liti-'+index">
			<elem :mode="getTopElem(comp_config[1])" v-if="comp_config[1]"></elem>
            <div class="litihengfei-box preserve3d poc" :style="getBoxStyle">
                <div class="preserve3d litihengfei-div bg poc bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
                    <elem :mode="comp_config[2]" v-if="comp_config[2]"></elem>
                </div>
                <div class="preserve3d litihengfei-div bg poc bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
                    <elem :mode="comp_config[2]" v-if="comp_config[2]"></elem>
                </div>
                <div class="preserve3d litihengfei-div bg poc bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
                    <elem :mode="comp_config[2]" v-if="comp_config[2]"></elem>
                </div>
                <div class="preserve3d litihengfei-div bg poc bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
                    <elem :mode="comp_config[2]" v-if="comp_config[2]"></elem>
                </div>
                <div class="preserve3d litihengfei-div bg po" :style="getTopStyle(index)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

	export default {
		name: 'litihengfei',
        extends: BaseElem,
		data () {
			return {
                litis: [
                    {
                        'zIndex': 2,
                        'transform': 'translate3d(-100px, 200px, -400px)'
                    },
                    {
                        'zIndex': 1,
                        'transform': 'translate3d(130px, -150px, -800px)'
                    },
                    {
                        'zIndex': 2,
                        'transform': 'translate3d(620px, 50px, 0px)'
                    },
                    {
                        'zIndex': 1,
                        'transform': 'translate3d(1200px, -120px, -850px)'
                    },
                    {
                        'zIndex': 2,
                        'transform': 'translate3d(1350px, 130px, -350px)'
                    }
                ]
            }
		},
		computed: {
			getAnimationStyle () {
				return {
					animationDuration: (this.comp_config[3] || 18).toString() + "s"
				}
			},
			getBoxStyle () {
				return {
					// animationDuration: (this.comp_config[3] || 10).toString() + "s"
					animationDuration: (10).toString() + "s"
				}
			}
		},
		methods: {
			getTopElem (elem) {
				elem = this.$util.copy(elem);
				let top = elem['pos'] && elem['pos'][0] ? parseFloat(elem['pos'][0]) : 0;
				let height = elem['size'] && elem['size'][1] ? parseFloat(elem['size'][1]) : (elem['is'] == 'gif' ? parseFloat(elem['height']) : 0)
				if (!elem['pos']) {
					elem['pos'] = [0, null, null, 0];
				}
				elem['pos'][0] = top - height;
				return elem;
			},
            getStyle (idx) {
                let s = {},
                    twidth = 262,
                    theight = 345;

				if (this.currentPhoto['orisize']) {
					let tsize = this.$util.getSizeInArea(this.currentPhoto['orisize'], [262, 345], 'widthFix');

                    twidth = parseInt(tsize[0]);
                    theight = parseInt(tsize[1]);
					
				}

                s['width'] = this.$util.getPx(twidth);
                s['height'] = this.$util.getPx(theight);
                s['top'] = '50%';
                s['marginTop'] = - (theight/2) + 'px';

                s = Object.assign(s, this.litis[idx]);

                return s;
			},
            getTopStyle (index) {
                let s = {};

				if (this.comp_config[4]) {
					s['backgroundImage'] = 'url('+ this.comp_config[4] +')';
				}
                let ts = this.getStyle(index);
                s['width'] = ts['width'];
                s['height'] = ts['width'];
                return s;
            }
		}
	}
</script>

<style>
	.litihengfei-container {
        position: absolute;
        width: 1500px;
        height: 100%;
		animation: fadeIn 2s 0s both;
	}
    .litihengfei-move {
        animation: hengmove linear both infinite;
    }
    @keyframes hengmove {
        from {
            transform: none;
        }
        to {
            transform: translateX(-1500px);
        }
    }
	.litihengfei-container.perspective {
		perspective: 1000px!important;
        perspective-origin: 50% 22%;
	}
	@keyframes litihengfei_rote1 {
		0% {
		    transform: rotateY(0deg);
		}
		100% {
		    transform: rotateY(-360deg);
		}
	}
	.litihengfei-box {
	    animation: litihengfei_rote1 linear infinite both;
	}
	.litihengfei-div {
	    border: 4px solid #FFFFFF;
	    background-color: #eee;
	}
	.litihengfei-container .litihengfei-div:nth-of-type(1) {
	    transform: rotateY(90deg) translateZ(135px);
	}
	.litihengfei-container .litihengfei-div:nth-of-type(2) {
	    transform: rotateY(180deg) translateZ(135px);
	}
	.litihengfei-container .litihengfei-div:nth-of-type(3) {
		transform: rotateY(270deg) translateZ(135px);
	}
	.litihengfei-container .litihengfei-div:nth-of-type(4) {
	    transform: translateZ(135px);
	}
    .litihengfei-container .litihengfei-div:nth-of-type(5) {
	    transform: rotateX(90deg) translateZ(135px);
	}
</style>