<template>
    <div class="fengche_con">
        <div class="fengche_litbor">
            <div class="fengche_border_litbor" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            <div class="fengche_huizhang" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
            <div class="fengche_userImg_box">
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="fengche_userImg fengche_userImg1"><div :style="{backgroundImage: 'url('+mode[2]+')'}" class="userborder"></div></div>
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="fengche_userImg fengche_userImg2"><div :style="{backgroundImage: 'url('+mode[2]+')'}" class="userborder"></div></div>
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="fengche_userImg fengche_userImg3"><div :style="{backgroundImage: 'url('+mode[2]+')'}" class="userborder"></div></div>
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="fengche_userImg fengche_userImg4"><div :style="{backgroundImage: 'url('+mode[2]+')'}" class="userborder"></div></div>
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="fengche_userImg fengche_userImg5"><div :style="{backgroundImage: 'url('+mode[2]+')'}" class="userborder"></div></div>
                <div :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview" class="fengche_userImg fengche_userImg6"><div :style="{backgroundImage: 'url('+mode[2]+')'}" class="userborder"></div></div>
            </div>
        </div>
        <!-- <div class="ball">
            <img class="ani1">
            <img class="ani2">
            <img class="ani3">
            <img class="ani4">
            <img class="ani5">
            <img class="ani6">
            <img class="ani7">
            <img class="ani8">
            <img class="ani9">
            <img class="ani10">
            <img class="ani11">
            <img class="ani12">
            <img class="ani13">
            <img class="ani14">
            <img class="ani15">
            <img class="ani16">
            <img class="ani17">
            <img class="ani18">
            <img class="ani19">
            <img class="ani20">
            <img class="ani21">
            <img class="ani22">
            <img class="ani23 aniimg21a">
            <img class="ani24">
            <img class="ani25 aniimg21a">
            <img class="aniimg1">
            <img class="aniimg2">
            <img class="aniimg3">
            <img class="aniimg4">
            <img class="aniimg5">
            <img class="aniimg6">
            <img class="aniimg7">
            <img class="aniimg8">
            <img class="aniimg9">
            <img class="aniimg10">
            <img class="aniimg11">
            <img class="aniimg12">
            <img class="aniimg13">
            <img class="aniimg14">
            <img class="aniimg15">
            <img class="aniimg16">
            <img class="aniimg17">
            <img class="aniimg18">
            <img class="aniimg19">
            <img class="aniimg20">
            <img class="aniimg21">
            <img class="aniimg22">
            <img class="aniimg23">
            <img class="aniimg24">
            <img class="aniimg25">
        </div> -->
    </div>
</template>

<script>
    export default {
        name: 'fengche',
        props: ['mode', 'currentImage'],
        data () {
            return {}
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage)
            }
        }
    }
    // $(function (){
    //     $('.fengche_userImg').css("background-image", "url("+ __Ani__.get_images_update() +")").attr('data-bg',__Ani__.get_images_update())
    //     $('.fengche_userImg').click(function(){
    //         preview($(this).attr('data-bg'));
    //     })
    //     $('.fengche_bg').css("background-image","url("+ __Ani__.mode['fengche'][0] +")");
    //     $('.fengche_border_litbor').css("background-image","url("+ __Ani__.mode['fengche'][1] +")");
    //     $('.userborder').css("background-image","url("+ __Ani__.mode['fengche'][2] +")");
    //     $(".ball img[class^=ani]:odd").attr("src",__Ani__.mode['fengche'][3]);
    //     $(".ball img[class^=ani]:even").attr("src",__Ani__.mode['fengche'][4]);
    //     $(".ball img[class^=aniimg]:odd").attr("src",__Ani__.mode['fengche'][5]);
    //     $(".ball img[class^=aniimg]:even").attr("src",__Ani__.mode['fengche'][6]);
    //     $('.fengche_huizhang').css("background-image","url("+ __Ani__.mode['fengche'][7] +")");
    // })
</script>

<style scoped>
    .fengche_con{
        width: 100vw;
        height: 100vh;
        position: relative;
        -webkit-transform-style: preserve-3d; 
        transform-style: preserve-3d; 
    }
    .fengche_bg{
        width: 100%;
        height: 100%;
        background: no-repeat center;
        background-size: cover;
    }
    .fengche_litbor{
        position: absolute;
        top: 0;
        right: 0;
        width: 347px;
        height: 347px;
    }
    .fengche_border_litbor{
        width: 100%;
        height: 100%;
        background: no-repeat center;
        background-size: cover;
        position: absolute;
    }
    .fengche_userImg_box{
        height: 347px;
        transform-style: preserve-3d;
        top: 0;
        right: 0;
        width: 347px;
        position: absolute;
        /* animation: rotateBorder 19s linear infinite; */
    }
    .fengche_border_litbor,.fengche_userImg_box{
        animation: rotateBorderTest 4s linear infinite forwards
    }
    .fengche_huizhang{
        position: absolute;
        top: 44px;
        right: 52px;
        width: 243px;
        height: 243px;
        background: no-repeat center;
        background-size: cover;
        transform-style: preserve-3d;
        transform: rotate(-31deg);
    }
    .fengche_userImg{
        height: 417px;
        width: 278px;
        position: absolute;
        background: no-repeat center;
        opacity: 1;
        background-size: cover;
    }
    .userborder{
        width: 100%;
        height: 100%;
        position: absolute;
        background: no-repeat center;
        background-size: 100% 100%;
    }
    .fengche_userImg1{
        transform: rotateZ(0) translate(42px,347px) ;
        opacity: 1;
    }
    .fengche_userImg6{
        transform: rotateZ(64deg) translateY(333px);
    }
    .fengche_userImg5{
        transform: rotateZ(129deg) translate(-22px,376px);
    }
    .fengche_userImg4{
        transform: rotateZ(182deg) translate(-29px,416px);
    }
    .fengche_userImg3{
        transform: rotateZ(-124deg) translate(-16px,431px);
    }
    .fengche_userImg2{
        transform: rotateZ(-65deg) translate(20px,400px);
    }
    @keyframes rotateBorderTest {
        0% {
            transform:rotate(0deg);
        }
        40% {
            transform:rotate(30deg);
        }
        60%{
            transform:rotate(400deg);
        }
        100%{
            transform:rotate(360deg);
        }
    }
    /*.ball{width: 100%;height: 100%;position: absolute;left: 0;top: 0;pointer-events: none;}
    .ball img{position: absolute}
    .ani1{left: 443px;animation: ballup1 0.5s -5643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite alternate;bottom: 50px;}
    .ani2{left: 148px;animation: ballup2 0.5s -7643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite alternate;bottom: 80px;}
    .ani3{left: 291px;animation: ballup2 0.5s -2643ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite alternate;bottom: 210px;}
    .ani4{left: 62px;animation: ballup2 0.5s -3843ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite alternate;bottom: 200px;}
    .ani5{left: 344px;animation: ballup3 2s -1232.3ms linear infinite ;bottom: 450px;}
    .ani6{left: 62px;animation: ballup3 2.55s -8843s linear infinite ;bottom: 490px;}
    .ani7{left: 165px;animation: ballup2 0.5s -4843ms cubic-bezier(0.15, 0.15, 0.34, 0.16) infinite alternate;bottom: 600px;}
    .ani8{left: 455px;animation: ballup3 3s -7852ms linear infinite;bottom: 700px;}
    .ani9{left: 229px;animation: ballup3 2.15s -3423ms linear infinite;bottom: 1000px;}
    .ani10{left: 119px;animation: ballup3 2.65s -3103ms linear infinite;top: 540px;}
    .ani11{left: 129px;animation: ballup2 0.5s -2323.3ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite alternate;top: 400px;}
    .ani12{left: 350px;animation: ballup1 0.5s -4535.23ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite alternate;top: 460px;}
    .ani13{left: 590px;animation: ballup1 0.5s -1266ms linear infinite ;top: 700px;}
    .ani14{left: 500px;animation: ballup1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;top: 400px;}
    .ani15{
        left: 300px;
        animation: ballup1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 600px;
    }
    .ani16{
        left: 543px;animation: ballup3 2.65s -1266ms linear infinite ;top: 500px;
    }
    .ani17{
        left: 300px;
        animation: ballup3 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 800px;
    }
    .ani18{
        left: 450px;
        animation: ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 864px;
    }
    .ani19{
        left: 350px;
        animation: ballup3 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 1000px;
    }
    .ani20{
        left: 213px;
        animation: ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 1100px;
    }
    .ani21{
        left: 53px;
        animation: ballup1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 800px;
    }
    .ani22{
        left: 113px;
        animation: ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 1005px;
    }
    .ani23{
        left: 660px;
        animation: ballup1 1.35s -1266ms linear infinite;
        top: 293px;
    }
    .ani24{
        left: 360px;
        animation: ballup2 1.35s -1266ms linear infinite;
        top: 193px;
    }
    .aniimg1{left: 262px;animation: ballup4 3.5s -1233.3ms linear infinite;top: 100px;}
    .aniimg2{left: 230px;animation: ballup3 1.75s -12.3ms linear infinite;top: 400px;}
    .aniimg3{left: 183px;animation: ballup1 0.5s -4533ms cubic-bezier(0.77, 0.75, 0.14, 0.34) infinite alternate;top: 900px;}
    .aniimg4{left: 284px;animation: ballup3 2.25s -2324ms linear infinite;bottom: 600px;}
    .aniimg5{left: 477px;animation: ballup2 0.5s -6324ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite alternate;top: 950px;}
    .aniimg6{left: 430px;animation: ballup2 2.5s -2104ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite alternate;bottom: 500px;}
    .aniimg7{left: 630px;animation: ballup1 0.5s -4024ms cubic-bezier(0.17, 0.25, 0.74, 0.64) infinite alternate;bottom: 400px;}
    .aniimg8{left: 330px;animation: ballup3 2.5s -2310ms linear infinite;bottom: 60px;}
    .aniimg9{left: 30px;animation: ballup1 0.5s -1310ms cubic-bezier(0.67, 0.45, 0.24, 0.54) infinite alternate;bottom: 900px;}
    .aniimg10{left: 600px;animation: ballup3 3.15s -4310ms linear infinite;bottom: 900px;}
    .aniimg11{left: 400px;animation: ballup2 0.5s -1210ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite alternate;bottom: 950px;}
    .aniimg12{left: 600px; animation: ballup2 0.5s -1216ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite alternate;top: 100px;}
    .aniimg13{left: 650px; animation: ballup2 0.5s -6745.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;top: 500px;}
    .aniimg14{
        left: 200px;animation: ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
        top: 700px;
    }
    .aniimg15{
        left: 403px;
        animation: ballup3 2.65s -1286ms linear infinite;
        top: 740px;
    }
    .aniimg16{
        left: 423px;
        animation: ballup1 2.15s -126ms linear infinite;
        top: 240px;
    }
    .aniimg17{
        left: 427px;
        animation: ballup3 1.65s -1326ms linear infinite;
        top: 540px;
    }
    .aniimg18{
        left: 623px;
        animation: ballup2 2s -198ms linear infinite;
        top: 1113px;
    }
    .aniimg19{
        left: 603px;
        animation: ballup2 2.12s -1266ms linear infinite;
        top: 863px;
    }
    .aniimg20{
        left: 93px;
        animation: ballup3 2.56s -1266ms linear infinite;
        top: 10px;
    }
    .aniimg21{
        left: 3px;
        animation: ballup2 1.05s -1266ms linear infinite;
        top: 580px;
    }
    .aniimg21{
        left: 3px;
        animation: ballup2 1.2s -1266ms linear infinite;
        top: 1080px;
    }
    .aniimg22{
        left: 1px;
        animation: ballup1 1.15s -1266ms linear infinite;
        top: 320px;
    }
    .aniimg23{
        left: 30px;
        animation: ballup2 1.05s -1266ms linear infinite;
        top: 620px;
    }
    .aniimg24{
        left: 310px;
        animation: ballup1 1.75s -1266ms linear infinite;
        top: 1070px;
    }
    .aniimg25{
        left: 160px;
        animation: ballup1 1.85s -1266ms linear infinite;
        top: 270px;
    }
    @keyframes ballup1 {
        0% {
            transform: scale(0)
        }
        100% {
            transform: scale(1.3)
        }
    }
    @keyframes ballup2 {
        0% {
            transform: scale(0.1)
        }
        100% {
            transform: scale(1.2)
        }
    }
    @keyframes ballup3 {
        0% {
            transform: scale(0) rotate(0)
        }
        50%{
            transform: scale(1) rotate(180deg)
        }
        100% {
            transform: scale(0) rotate(360deg)
        }
    }
    @keyframes ballup4 {
        0% {
            transform: scale(0) rotate(0)
        }
        50%{
            transform: scale(1.1) rotate(180deg)
        }
        100% {
            transform: scale(0) rotate(360deg)
        }
    }*/
    /*.aniimg21 {
        background-repeat: no-repeat;
        background-position: center;
        width: 155px;
        height: 155px;
        position: absolute;
        animation: opacity-change 1s ease-in-out infinite;
    }
    .aniimg21a {
        animation: opacity-change 1.75s ease-in-out infinite;
    }
    @keyframes opacity-change {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }*/
</style>