<!-- 直飘 -->
<template>
	<div class="piao-cont">
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
			<div v-for="idx in 13" 
				class="piao-item" 
				:class="'piao-' + idx" 
				:key="'piao-' + idx" >
				<elem 
					:mode="getLuoItem(idx)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
			</div>
		</template>
		<template v-else>
				<img 
					v-for="idx in 13" 
					mode="widthFix"
					class="piao-item" 
					:class="'piao-' + idx" 
					:key="'piao-' + idx" 
					:src="getLuoItem(idx)">
		</template>
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'piao',
		extends: BaseLuo,
	}
</script>

<style>
	.piao-cont {
	    position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 100%;
		left: 50%;
		margin-left: -50px;
		-webkit-transform: rotateX(90deg) translateZ(-540px);
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		z-index: 1;
		pointer-events: none;
	}
	.piao-item {
		position: absolute;
	}
	.piao-1 {
		left: 354px;
		top: 314px;
		animation: piao_piao4 8037ms -4303ms linear infinite both;
	}
	.piao-2 {
		left: 166px;
		top: 406px;
		animation: piao_piao6 8631ms -454ms linear infinite both;
	}
	.piao-3 {
		left: 180px;
		top: 421px;
		animation: piao_piao1 7417ms -5488ms linear infinite both;
	}
	.piao-4 {
		left: 351px;
		top: 207px;
		animation: piao_piao4 9325ms -8429ms linear infinite both;
	}
	.piao-5 {
		left: 65px;
		top: 391px;
		animation: piao_piao7 7386ms -5997ms linear infinite both;
	}
	.piao-6 {
		left: 173px;
		top: 133px;
		animation: piao_piao5 8713ms -8786ms linear infinite both;
	}
	.piao-7 {
		left: 302px;
		top: 405px;
		animation: piao_piao2 9170ms -3568ms linear infinite both;
	}
	.piao-8 {
		left: 155px;
		top: 396px;
		animation: piao_piao3 8073ms -274ms linear infinite both;
	}
	.piao-9 {
		left: 253px;
		top: 178px;
		animation: piao_piao5 9050ms -1148ms linear infinite both;
	}
	.piao-10 {
		left: 66px;
		top: 447px;
		animation: piao_piao7 9934ms -3310ms linear infinite both;
	}
	.piao-11 {
		left: 242px;
		top: 64px;
		animation: piao_piao7 9840ms -3408ms linear infinite both;
	}
	.piao-12 {
		left: 560px;
		top: 64px;
		animation: piao_piao6 9440ms -1108ms linear infinite both;
	}
	.piao-13 {
		left: 342px;
		top: 64px;
		animation: piao_piao4 9440ms -1423ms linear infinite both;
	}
	@keyframes piao_piao1 {
		0% {
		    transform: translate3d(0,0,0) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(-200px,365px,745px) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes piao_piao2 {
		0% {
		    transform: translate3d(0,0,0) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(10px,-265px,745px) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes piao_piao3 {
		0% {
		    transform: translate3d(0,0,0) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(123px,-65px,700px) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes piao_piao4 {
		0% {
		    transform: translate3d(0,0,0) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(236px,186px,600px) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes piao_piao5 {
		0% {
		    transform: translate3d(0,0,0) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(-50px,-280px,650px) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes piao_piao6 {
		0% {
		    transform: rotate(0) translate3d(0,0,0) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(118px,165px,600px) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes piao_piao7 {
		0% {
		    transform: translate3d(0,0,0) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(-120px,206px,500px) rotateX(-90deg);
		    opacity: 0;
		}
	}
</style>