<template>
	<div>
		<div class="tabbar" :class="{
			'tabbar-up': hasICP
		}">
			<div class="tabbar-item" :class="{active: currentPage == 'square'}" @click="onTabSelected('square')">
				<img v-if="currentPage == 'square'" src="http://litiresource.xcgogo.site/user/5980171/images/5073795bf420804eeaa006ae6a97ba4a.png">
				<img v-else src="http://litiresource.xcgogo.site/user/5980171/images/9d5af4f1ee567c147afb039a2dd9142b.png">
				<span>广场</span>
			</div>
			<div>
				<div class="tabbar-make" @click="onMakeTap">
					<img src="http://litiresource.xcgogo.site/user/5980171/images/6b4ea52f26bad4359957c570fd146a8b.png">
					<span>制作相册</span>
				</div>
			</div>
			<div class="tabbar-item" :class="{active: currentPage == 'mybook'}" @click="onTabSelected('mybook')">
				<img v-if="currentPage == 'mybook'" src="http://litiresource.xcgogo.site/user/5980171/images/1c68eb4cf0d4fa6795aef83f8b840d17.png">
				<img v-else src="http://litiresource.xcgogo.site/user/5980171/images/4f034e7ffad429005842f5c748e9351a.png">
				<span>我的相册</span>
			</div>
		</div>
		<div class="tabbar-icp" v-if="hasICP" @click="toICP">
			<div class="tabbar-icp_cont">
				<p>粤ICP备17119023号</p>
				<p>© Copyright © 2023.Company name All rights reserved.</p>
				<p>深圳市日天信息科技有限公司 版权所有</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TabBar',
		props: ['from'],
		data () {
			return {
				currentPage: ''
			};
		},
		computed: {
			hasICP () {
				return !this.$util.isEnv("wxa");
			}
		},
		watch: {
			from () {
				this.currentPage = this.from;
			}
		},
		created () {
			this.currentPage = this.from;
		},
		methods: {
			onTabSelected (page) {

				if (page == 'mybook' && (!this.$route.query['_source'] || !this.$route.query['openid'])) {
					if (!this.$util.isEnv("wx")) {
						alert("需使用微信登录功能，请在微信环境中打开")
						return;
					}
					window.location.href = "/vliti/login?target=mybook";
				} else {
					this.currentPage = page;
					this.$emit('onTabSelected', page);
				}
			},
			jumpTo (target) {
				if (target == this.from) {
					return;
				}
				this.$router.replace({path: target, query: {
					openid: this.$route.query.openid,
					_source: this.$route.query._source
				}})
			},
			onMakeTap () {

				if (this.from != 'menu') {
					this.$emit('onTabSelected', 'menu');
					return;
				}

				if (!this.$util.isEnv("wx")) {
					alert("需使用微信选图功能，请在微信环境中打开")
					return;
				}

				let _this = this;

				var appid = _this.$route.query['_source'],
					openid = _this.$route.query['openid'];

				_this.$o_util.log(['select_image_4001', 'select_image_h5']);
				
				_this.$o_util.uploadMedia({
					violate: async function () {
						return new Promise((resolve, reject)=>{
							resolve("C9130828AE2347CD2AD3F8D4C98EDC89")
						})
					},
					process (index, len) {
						if (index == 0) {
							_this.$toast.loading({
								message: '加载中...',
								duration: 'infinite'
							})
						} else if(index == len) {
							_this.$toast.loading('创建中...');
						} else {
							_this.$toast.loading(index+'/'+len+' 上传中');
						}
					},
				}).then(image_infos=>{ // images
					if (appid && openid && loginManager._isStorageAuthor(appid, openid)) {
						_this.$util.rpost('/vliti/new_book', {
							'appid': appid,
							'openid': openid,
							// 'urls': images.join(','),
							'imgInfos': JSON.stringify(image_infos),
							'vscene': '4001'
						})
						.then((response) => {
							_this.$toast.loading('制作成功');
							setTimeout(() => {
								_this.$toast.clear();
								window.location.href = "/vliti/main/"+(_this.$store.state.appConfig.editor_path)+"?key="+response+'&_source='+appid+"&pfrom=mybook&vscene=2006";
							}, 1000);
						})
						.catch((err) => {
							_this.$toast.clear();
							setTimeout(() => {
								_this.$toast.text({
									'message': err,
									'duration': 10
								})
							}, 500);
						});
					} else {
						this.$util.rpost('set_sharemake_images', {
							images: JSON.stringify(image_infos),//images.join(',')
						}).then(imagekeys => {

							let jumpurl = '//' + window.location.host + '/vliti/sharemake?v=1&vscene=2006&imagekeys=' + imagekeys;
							window.location.href = jumpurl;
						})
					}
				}).catch(err=>{
					_this.$toast.clear();
				})

				this.$mta.click('mybook_btn_newbook');
			},
			toICP () {
				window.location.href = "https://beian.miit.gov.cn/";
			}
		}
	}
</script>

<style scoped lang="less">
	.tabbar {
		position: fixed;
		width: 100%;
		height:@bw * 0.153;
		background: white;
		bottom: 0;
		left: 0;
		display: flex;
		border-top-left-radius:@bw * 0.011;
		border-top-right-radius:@bw * 0.011;
		z-index: 1000;
		span {
			margin-top:@bw * 0.003;
			font-size:@bw * 0.033;
			font-weight: bold;
			font-family: 'Heiti';
			color: #8a8888;
		}
	}
	.tabbar-item.active span {
		color: black;
	}
	.tabbar > div {
		position: relative;
		flex: 1;
	}
	.tabbar-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img {
			position: relative;
			width:@bw * 0.083;
			height:@bw * 0.083;
		}
	}
	.tabbar-make {
		position: absolute;
		top: -@bw * 0.028;
		left: 50%;
		margin-left: -@bw * 0.133;
		width:@bw * 0.267;
		height:@bw * 0.167;
		background: linear-gradient(to top right, #ff9761, #E72047);
		border-radius:@bw * 0.017;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: 0 0@bw * 0.029@bw * 0.008 rgba(169, 169, 169, 0.5);
		img {
			position: relative;
			width:@bw * 0.083;
			margin-bottom:@bw * 0.006;
			// height:@bw * 0.083;
		}
		span {
			color: #000000!important;
		}
	}
	.tabbar-up {
		bottom: 80px!important;
	}
	.tabbar-icp {
		position: fixed;
		width: 100%;
		height: 80px;
		background: #262626;
		color: rgba(255,255,255,.4);
		bottom: 0;
		left: 0;
		font-size: 15px;
		display: flex;
		align-items: center;
	}
	.tabbar-icp_cont {
		padding-left: 12px;
	}
</style>