<template>
	<div class="qrcode-page">
		<div class="qrcode-mask page" v-show="qrcodeOptions['isMask']"></div>
		<div class="qrcode_container qrcode_show" :style="{backgroundImage: 'url('+ qrcodeOptions['bgSrc'] +')'}">
			<img class="qrcode_image" :src="qrcodeOptions['qrcodeSrc']">
			<img class="qrcode_close" src="http://resali.lititutu.cn/icons/app/close2.png" @click="hideQrcode">
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Qrcode',
		props: ['qrcodeOptions'],
		data () {
			return {}
		},
		methods: {
			hideQrcode () {
				this.$parent.qrcodeShow = false;
			}
		}
	}
</script>

<style>
	.qrcode-page {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 9999;
	}
	.qrcode-mask {
		background-color: rgba(0, 0, 0, .72);
	}
	.qrcode_container {
		position: absolute;
		background-position: center;
		background-size: 100% 100%;
		width: 430px;
		height: 483px;
		top: 50%;
		left: 50%;
		margin-top: -241.5px;
		margin-left: -215px;
		z-index: 2;
	}
	.qrcode_image {
		position: absolute;
		width: 200px;
		height: 200px;
		bottom: 30px;
		left: 50%;
		margin-left: -100px;
	}
	.qrcode_close {
		position: absolute;
		width: 50px;
		height: 50px;
		color: #cacaca;
		font-size: 25px;
		font-weight: bold;
		text-align: center;
		line-height: 34.5px;
		border-radius: 50%;
		left: 50%;
		margin-left: -25px;
		bottom: -65px;
	}
</style>