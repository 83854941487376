<template>
    <div class="preserve3d _page3 div poc">
        <elem v-if="config[0]" :mode="myPhotoElem" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
        <div v-else class="poc bg bg_photo" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}">
            <elem v-if="config[1]" :mode="config[1]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
        </div>    
    </div>    
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

    export default {
        name: 'DuolifangzhuanItem',
        extends: BaseElem,
        props: ['bsize', 'mode', 'currentPhoto', 'canIShow'],
        data () {
            return {
                myPhotoElem: {}
            }
        },
        created () {
            if (this.config[0]) {
                this.myPhotoElem = this.getLiFangMode(this.config[0]);
            }
        },
        computed: {
			config () {
				return this.comp['config'] || [];
			},
		},
        methods: {
            getLiFangMode (mode) {
                if (mode['size']) {
                    mode['size'] = this.getLiFangStyle(this.bsize);
                }
                return mode;
            },
            getLiFangStyle (bsize) {
                let tsize = this.$util.copy(bsize);
				if (this.config[0] && this.config[0]['size']) {
					tsize = this.$util.getSizeInArea(this.config[0]['size'], bsize, 'widthFix');
				} else {
					tsize = this.$util.getSizeInArea(this.currentPhoto['orisize'], bsize, 'widthFix');
				}
                return tsize;
			}
        }
    }
</script>

<style>

</style>