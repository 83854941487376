<template>
  <div class="guandao_BX_AEComposition">
    <div class="guandao_BX_Class1 guandao_BX_Ani1" AELayerName="1.pano">
      <!-- 右下 -->
      <div
        class="guandao_BX_Class11 guandao_BX_BG11 guandao_BX_Ani11"
        id="guandao_BX_Layer11"
        :style="{backgroundImage: 'url('+bg1+')'}"
      ></div>
      <!-- 右上 -->
      <div
        class="guandao_BX_Class10 guandao_BX_BG10 guandao_BX_Ani10"
        id="guandao_BX_Layer10"
        AELayerName="10.fc8f84f2144b7deb403e55b0b15d0a55.jpg"
        :style="{backgroundImage: 'url('+bg0+')'}"
      ></div>
      <div
        class="guandao_BX_Class9 guandao_BX_BG9 guandao_BX_Ani9 bg_photo"
        id="guandao_BX_Layer9"
        :style="{backgroundImage: 'url('+currentImage['im']+')'}"
        @click="wxPreview"
      ></div>
      <div
        class="guandao_BX_Class8 guandao_BX_BG8 guandao_BX_Ani8 bg_photo"
        id="guandao_BX_Layer8"
        :style="{backgroundImage: 'url('+currentImage['im']+')'}"
        @click="wxPreview"
      ></div>
      <!-- <div
        class="guandao_BX_Class7 guandao_BX_BG7 guandao_BX_Ani7"
        id="guandao_BX_Layer7"
        :style="{backgroundImage: 'url('+currentImage['im']+')'}"
        @click="wxPreview"
      ></div> -->
      <!-- 左下 -->
      <div
        class="guandao_BX_Class6 guandao_BX_BG6 guandao_BX_Ani6"
        id="guandao_BX_Layer6"
        AELayerName="6.27511787372202ba7fedf3be7609a350.jpg"
        :style="{backgroundImage: 'url('+bg3+')'}"
      ></div>
      <!-- 左上 -->
      <div
        class="guandao_BX_Class5 guandao_BX_BG5 guandao_BX_Ani5 bg_photo"
        id="guandao_BX_Layer5"
        AELayerName="5.87657dde43a8d45b4829a8c265f02992.jpg"
        :style="{backgroundImage: 'url('+bg2+')'}"
      ></div>
      <div
        class="guandao_BX_Class4 guandao_BX_BG4 guandao_BX_Ani4 bg_photo"
        id="guandao_BX_Layer4"
        :style="{backgroundImage: 'url('+currentImage['im']+')'}"
        @click="wxPreview"
      ></div>
      <div
        class="guandao_BX_Class3 guandao_BX_BG3 guandao_BX_Ani3 bg_photo"
        id="guandao_BX_Layer3"
        :style="{backgroundImage: 'url('+currentImage['im']+')'}"
        @click="wxPreview"
      ></div>
      <div
        class="guandao_BX_Class2 guandao_BX_BG2 guandao_BX_Ani2 bg_photo"
        id="guandao_BX_Layer2"
        :style="{backgroundImage: 'url('+currentImage['im']+')'}"
        @click="wxPreview"
      ></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "guandao",
  props: ["mode", "currentImage"],
  data() {
    return {
      bg0: this.mode[0][2],
      bg1: this.mode[0][3],
      bg2: this.mode[0][6],
      bg3: this.mode[0][7],
      bg4: this.mode[0][10],
      bg5: this.mode[0][11],
      bg6: this.mode[0][14],
      bg7: this.mode[0][15],
      requestAniEvent: null,
      systemInfo: {}
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    wxPreview() {
      this.$emit("wxPreview", this.currentImage);
    }
  }
};
</script>

<style lang="less">
.guandao_BX_AEComposition {
  background: transparent;
  width:@bw * 1.0;
  height:@bw * 2.178;
  display: block;
  overflow: hidden;
  position: relative;
  transform: translate3D(0, 0, 0) scale(1, 1);
  transform-origin: 50% 0%;
  perspective:@bw * 0.494;
  perspective-origin: 50% 50%;
}
.guandao_BX_Class11 {
  left: -@bw * 0.617;
  top:@bw * 0.0;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0,@bw * 0.0) rotateX(-90deg) rotateY(0deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG11 {
  //   background: url("imgs/02.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani11 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey11 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey11 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class10 {
  left: -@bw * 0.617;
  top: -@bw * 1.235;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0,@bw * 0.0) rotateX(-90deg) rotateY(0deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG10 {
  //   background: url("imgs/04.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani10 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey10 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey10 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class9 {
  left: -@bw * 0.617;
  top: -@bw * 0.617;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, -@bw * 0.617) rotateX(-0deg) rotateY(0deg)
    rotateZ(0deg) scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG9 {
  //   background: url("imgs/user.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani9 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey9 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey9 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class8 {
  left:@bw * 0.0;
  top: -@bw * 0.617;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0,@bw * 0.0) rotateX(-0deg) rotateY(90deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG8 {
  //   background: url("imgs/user.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani8 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey8 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey8 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class7 {
  left: -@bw * 1.235;
  top: -@bw * 0.617;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, 0) rotateX(-0deg) rotateY(-90deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG7 {
  //   background: url("imgs/user.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani7 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey7 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey7 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class6 {
  left: -@bw * 1.852;
  top: -0.1px;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, 0) rotateX(-90deg) rotateY(0deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG6 {
  //   background: url("imgs/03.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani6 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey6 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey6 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class5 {
  left: -@bw * 1.852;
  top: -@bw * 1.235;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, 0) rotateX(-90deg) rotateY(0deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG5 {
  //   background: url("imgs/01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani5 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey5 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey5 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class4 {
  left: -@bw * 1.852;
  top: -@bw * 0.617;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, -@bw * 0.617) rotateX(-0deg) rotateY(0deg)
    rotateZ(0deg) scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG4 {
  //   background: url("imgs/user.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani4 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey4 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey4 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class3 {
  left: -@bw * 1.235;
  top: -@bw * 0.617;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, 0) rotateX(-0deg) rotateY(90deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG3 {
  //   background: url("imgs/user.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani3 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey3 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey3 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class2 {
  left: -@bw * 2.469;
  top: -@bw * 0.617;
  width:@bw * 1.235;
  height:@bw * 1.235;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, 0) rotateX(-0deg) rotateY(-90deg) rotateZ(0deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
}
.guandao_BX_BG2 {
  //   background: url("imgs/user.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.guandao_BX_Ani2 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey2 8s linear 0s infinite normal none;
}
@keyframes guandao_BX_AniKey2 {
  /* 		This layer has no animations to export */
  /* 		This layer has no animations to export */
}
.guandao_BX_Class1 {
  left: @bw * 1.733;
  top: @bw * 1.044;
  width: @bw * 0.247;
  height: @bw * 0.247;
  transform-style: preserve-3d;
  transform: translate3D(0, 0, 0) rotateX(-0deg) rotateY(0deg) rotateZ(6deg)
    scale3d(1, 1, 1);
  opacity: 1;
  transform-origin: 0% 0%;
  display: block;
  position: absolute;
}
.guandao_BX_BG1 {
}
.guandao_BX_Ani1 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: guandao_BX_AniKey1 8s linear 0s infinite alternate none;
}
@keyframes guandao_BX_AniKey1 {
  0.00% {
    transform: translate3D(0, 0, 0) rotateX(-0deg) rotateY(0deg) rotateZ(6deg) scale3d(1, 1, 1);
  }
  50.00% {
    transform: translate3D(-@bw * 1.235, -@bw * 0.156, 0) rotateX(-0deg) rotateY(0deg) rotateZ(6deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3D(-@bw * 1.235, -@bw * 0.156, @bw * 0.83) rotateX(-0deg) rotateY(0deg) rotateZ(6deg) scale3d(1, 1, 1);
  }
}
</style>