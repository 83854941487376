export default {
	// 根据输入图片获取背景高斯模糊的默认分享图 byhz 2022.10.24
	getDefaultShareCover (usercover) {
		usercover = usercover.split("?")[0];

		let size = [350, 280];
		let bg = usercover + "?x-oss-process=image/resize,w_"+size[0]+",m_lfit/crop,w_"+size[0]+",h_"+size[1]+",g_center/blur,r_6,s_6";
		// 获取图片路径
		let path = usercover.split("/");
		path.shift();
		path.shift();
		path.shift();
		path = path.join("/") + "?x-oss-process=image/resize,h_"+size[1]+",m_lfit";
		let watermark = "/watermark,image_"+ this.urlEncodeBase64( path ) +",g_center"
		let cover = bg + watermark;
		return cover;
	},
    // 根据分享配置拼接分享封面
    getShareCover(usercover, coverconfig){
		let _bg = "",
			_this = this;
		if (!coverconfig) {
			return usercover;
		}
		for(let i = 0 ; i < coverconfig.length ; i++){
			(function(i){
				let _coverinfo = coverconfig[i];
				// 图片元素
				if (_coverinfo.type == "image"){
					if (_coverinfo.url){
						let before_base64 = _coverinfo.url + "?x-oss-process=image/resize,m_fill,w_" + _coverinfo.size[0] +",h_" + _coverinfo.size[1] + ",limit_0";
						if (_coverinfo.blur){
							before_base64 = before_base64 + "/blur,r_6,s_6"
						}
						if (_coverinfo.cir){
							before_base64 = before_base64 + "/circle,r_" + _coverinfo.size[0]
						}
						if( i == 0 ){
							_bg = before_base64
						} else {
							// _bg = _bg + "/watermark,image_" + _this.urlEncodeBase64( before_base64 ) + ",g_nw,x_" + _coverinfo.pos[1] + ",y_" + _coverinfo.pos[0];
							_bg += "/watermark,image_" + _this.urlEncodeBase64( before_base64 );
							_bg += _this.__buildShareItemPos(_coverinfo);
						}
					}
				} else if(_coverinfo.type == "cover"){
					let fit_mode = "fill";
					if (usercover.indexOf(".png") != -1) {
						fit_mode = "lfit";
					}
					let before_base64 = usercover + "?x-oss-process=image/resize,m_" + fit_mode + ",w_" + _coverinfo.size[0] +",h_" + _coverinfo.size[1] + ",limit_0";
					if (_coverinfo.blur){
						before_base64 = before_base64 + "/blur,r_6,s_6"
					}
					if (_coverinfo.cir){
						before_base64 = before_base64 + "/circle,r_" + _coverinfo.size[0]
					}
					if( i == 0 ){
						_bg = before_base64
					} else {
						_bg += "/watermark,image_" + _this.urlEncodeBase64( before_base64 );
						_bg += _this.__buildShareItemPos(_coverinfo);
					}
				}
			})(i)
		}
		return _bg;
	},
	__buildShareItemPos(_coverinfo) {
		let _bg = "";
		if (!_coverinfo['pos']) {
			_coverinfo.pos = [0, 0, null, null];
		}
		if (_coverinfo.pos.length == 2) {
			_coverinfo.pos = [_coverinfo.pos[0], _coverinfo.pos[1], null, null];
		}
		for (let i = 0 ; i < _coverinfo.pos.length ; i++) {
			if(!_coverinfo.pos[i] && _coverinfo.pos[i] !== 0) {
				_coverinfo.pos[i] = null;
			}
		}
		// console.log("pos==>",JSON.stringify(_coverinfo.pos));
		if (_coverinfo.pos_center) { // 居中
			// ["上", "左", "下", "右"]
			/**
				north：中上
				south：中下
				// voffset
				west：左中
				center：中部
				east：右中
				*  */ 
			// 纯居中
			if ((_coverinfo.pos[0] == 0 && _coverinfo.pos[1] == 0 && _coverinfo.pos[2] == 0 && _coverinfo.pos[3] == 0) 
				|| _coverinfo.pos[0] == null && _coverinfo.pos[1] == null && _coverinfo.pos[2] == null && _coverinfo.pos[3] == null) {
				_bg += ",g_center";
			}
			// 中上、中下
			else if ((_coverinfo.pos[1] == null && _coverinfo.pos[3] == null) || (_coverinfo.pos[1] == 0 && _coverinfo.pos[3] == 0)) {
				if (_coverinfo.pos[2] === null) { // 中上
					_bg += ",g_north,y_" + _coverinfo.pos[0];
				} else { // 中下
					_bg += ",g_south,y_" + _coverinfo.pos[2];
				}
			// 左中、右中
			} else if ((_coverinfo.pos[0] == null && _coverinfo.pos[2] == null) || _coverinfo.pos[0] == 0 && _coverinfo.pos[2] == 0) {
				if (_coverinfo.pos[3] === null) { // 左中
					_bg += ",g_west,x_" + _coverinfo.pos[1];
				} else { // 右中
					_bg += ",g_east,x_" + _coverinfo.pos[3];
				}
			} else {
				// 意外情况？？？兜底放在正中间
				_bg += ",g_center";
			}
		} else {
			// ["上", "左", "下", "右"]
			/**
				nw：左上
				ne：右上
				sw：左下
				se：右下
				*  */ 
			if (_coverinfo.pos[0] !== null && _coverinfo.pos[1] !== null) { // 左上
				_bg += ",g_nw,x_" + _coverinfo.pos[1] + ",y_" + _coverinfo.pos[0]; 
			} else if (_coverinfo.pos[3] !== null && _coverinfo.pos[0] !== null) { // 右上
				_bg += ",g_ne,x_" + _coverinfo.pos[3] + ",y_" + _coverinfo.pos[0];
			} else if (_coverinfo.pos[1] !== null && _coverinfo.pos[2] !== null) { // 左下
				_bg += ",g_sw,x_" + _coverinfo.pos[1] + ",y_" + _coverinfo.pos[2]; 
			} else if (_coverinfo.pos[3] !== null && _coverinfo.pos[2] !== null) { // 右下
				_bg += ",x_" + _coverinfo.pos[3] + ",y_" + _coverinfo.pos[2]
			} else { // 兜底放在右下角
				// _bg += ",g_nw,x_" + _coverinfo.pos[1] + ",y_" + _coverinfo.pos[0]
				_bg += ",x_0,y_0";
			}
		}
		// console.log("_bg==>",_bg);
		return _bg;
	},
    // 根据配置拼接分享图片
    buildShareCover(share_image_path, watermark, watermark2) {
		let host = (share_image_path.split('lititutu.cn/')[0] + 'lititutu.cn/').replace(this.$store.state.appConfig.image_map.host_map['qiniu'], 'http://wxaqiniu.lititutu.cn/');
		let suburl = share_image_path.split('?')[0].split('lititutu.cn/')[1];
		var bg = host + suburl + '?x-oss-process=image';
		let match = share_image_path.match(/rotate,(\S*)\//);
		let match2 = share_image_path.match(/rotate\/(\S*)/);
		var source_img = suburl + '?x-oss-process=image';
		// 判断是否需要webp转码
		if (this.getStorageSync("WEBP_ABLE") === 0) {
			source_img = source_img + "/format,jpg";
			bg = bg + "/format,jpg";
		}
		if(match && match[0]){
		  bg = bg + '/' + match[0].replace('/','');
		  source_img = source_img + '/' + match[0].replace('/','');
		} else if (match2 && match2[0]){
		  bg = bg + '/' + match2[0].replace('/',',');
		  source_img = source_img + '/' + match2[0].replace('/',',');
		}
		bg = bg + "/resize,m_fill,h_320,w_400,limit_0/blur,r_6,s_6"
		source_img = source_img + "/resize,h_320,w_400,limit_0"
		source_img = this.urlEncodeBase64(source_img);
		let res = bg + '/watermark,image_' + source_img + ',g_center' + '/watermark,image_' + this.urlEncodeBase64(watermark) + ',g_center';
		if (watermark2) {
		  res = res + '/watermark,image_' + this.urlEncodeBase64(watermark2) + ',g_south,y_0';
		}
		return res;
	},
    // 获取默认分享前缀
    getCurrentPreFix(){
		if (this.$store.state.appConfig && this.$store.state.appConfig.pre_fix){
			return this.$store.state.appConfig.pre_fix;
		}
		let TODAY= new Date();
		let HOUR = TODAY.getHours()
		let MONTH = TODAY.getMonth() + 1
		let DATE = TODAY.getDate()
		let WEEKDAY = TODAY.getDay()
		let preFix = ""
		if(DATE == 1){
			return "🗓️" + MONTH + "月你好"
		}
		if(WEEKDAY == 0 || WEEKDAY == 6){
			preFix = "🌈周末" + preFix
		}
		if(3 <= HOUR && HOUR < 11){
			preFix = (preFix ? preFix:"🌅") + "早上好"
		} else if(11 <= HOUR && HOUR < 13){
			preFix = (preFix ? preFix:"🔅") + "中午好"
		} else if(13 <= HOUR && HOUR < 18){
			preFix = (preFix ? preFix:"🌆") + "下午好"
		} else {
			preFix = (preFix ? preFix:"🌃") + "晚上好"
		}
		return preFix;
	},
	// 关键字替换
	replaceKeyWords (_string, user){
		/**
		 * _string 被替换的string
		 * user 用户类型"当前用户/相册作者"
		 * state vuex中的state对象
		 */
		let userinfo = {
				"nickname": user == "author" ? "我" : "相册用户",
				"city": "这里",
			};
		if (this.typeOf(user) == "object") {
			userinfo = this.copy(user);
		} else if (user == "author" && this.$store.state.authorInfo) { // 当前作者信息
			userinfo =  this.$store.state.authorInfo
		} else if (user == "visitor" && this.$store.state.userInfo){ // 访客则拿当下已登录的用户信息
			userinfo = this.$store.state.userInfo;
		}
		_string = _string.replace(/#nickname#/g, userinfo && userinfo.nickname && userinfo.nickname != "相册用户"?userinfo.nickname:"我");
		_string = _string.replace(/#city#/g, userinfo && userinfo.city ?userinfo.city:"这里");
		_string = _string.replace(/#prefix#/g, this.getCurrentPreFix());
		_string = this.replaceDate(_string);
		return _string;
	},
	// replace coundown
	replaceDate (_string) {
		if (!_string) {
			return _string
		}
		let __now__ = new Date();
		_string = _string.replace("#today#", __now__.getMonth()+1 + '月' + __now__.getDate()+'日');
		// 日期判断
		var reg = /(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)/;
		if (_string && _string.match(reg)) {
			let daysDistance = Math.abs(this.daysDistance(__now__.toLocaleDateString(), _string.match(reg)[0].replace(/-/g,"/")));
			_string = _string.replace("#"+ _string.match(reg)[0] + "#", daysDistance.toString())
		}

		// 倒数
		let reg2 = /#countdown_.*?#/;
		if (_string && _string.match(reg2)) {
			let countdown = _string.match(reg2)[0].replace(/#/g, "").replace("countdown_","");
			let [countdonw_day,day_name] = countdown.split("_");
			let daysDistance = parseInt( this.daysDistance(__now__.toLocaleDateString(), countdonw_day) ); 
			let count_down_temp = "";
			let coundownwords = [
				["今天是#name#","#name#到了"],
				["明天就是#name#了", "#name#马上到了", "马上就是#name#了"],
				["后天就是#name#了"],
				["距离#name#还有#day#天","还有#day#天就是#name#了","#day#天后是#name#"]
			]
			if (daysDistance > -1){
				let words = coundownwords[3]
				if (daysDistance <= 2) {
					words = coundownwords[daysDistance];
				}
				count_down_temp = words[parseInt(Math.random()*10000)%words.length];
				count_down_temp = count_down_temp.replace(/#day#/g, daysDistance).replace(/#name#/g, day_name)
			}
			_string = _string.replace(_string.match(reg2)[0], count_down_temp);
		}

		// 日期+格式
		let reg3 = /#today_.*?#/;
		if (_string && _string.match(reg3)) {
			let day_format = _string.match(reg3)[0].replace(/#/g, "").replace("today_","");
			_string = _string.replace(_string.match(reg3)[0], __now__.format(day_format));
		}
		return _string;
	}
}