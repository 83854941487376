<template>
	<div class="luo pointer" :style="getStyles">
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
			<div v-for="(idx, index) in 6"
				class="po"
				:class="'luo_' + index"
				:key="'luo_' + idx">
				<elem 
					:mode="getLuoItem(index)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
			</div>
		</template>
		<template v-else>
			<img v-for="(idx, index) in 6"
				mode="widthFix"
				class="po"
				:class="'luo_' + index"
				:key="'luo_' + idx"
				:src="getLuoItem(index)" />
		</template>
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'Luo',
		extends: BaseLuo,
		data () {
			return {}
		}
	}
</script>

<style>
	.luo {
		position: absolute;
	    top: 0;
	}
	.luo_0 {
		left: 304px!important;
		animation: luo1 11s linear -4.4s infinite normal both;
		width: 34px;	
	}
	.luo_1 {
		left: 74px!important;
		animation: luo1 11s linear -8.4s infinite normal both;
		width: 43px;	
	}
	.luo_2 {
		left: 391px!important;
		animation: luo1 12s linear -1.2s infinite normal both;
		width: 47px;	
	}
	.luo_3 {
		left: 21px!important;
		animation: luo1 12s linear -2.2s infinite normal both;
		width: 43px;	
	}
	.luo_4 {
		left: 373px!important;
		animation: luo1 13s linear -6.1s infinite normal both;
		width: 53px;	
	}
	.luo_5 {
		left: 118px!important;
		animation: luo2 11s linear -10.5s infinite normal both;
		width: 38px;	
	}
	@keyframes luo1 {
		0% {
		    transform: scale(1.2) translate(0,0) rotateZ(0);
		}
		100% {
		    transform: scale(1.2) translate(-250px,950px) rotateZ(213deg);
		}
	}
	@keyframes luo2 {
		0% {
		    transform: scale(1.2) translate(0,0) rotateZ(-20deg);
		}
		100% {
		    transform: scale(1.2) translate(-400px,950px) rotateZ(340deg);
		}
	}
</style>