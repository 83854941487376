class install {
    constructor (util) {
        this.$util = util;
    }
    check (options) {
        let condition = new stCondition(this.$util, options);
        return condition.check();
    }
    calculate (statement) {
        let condition = new stCondition(this.$util, {});
        return condition._calculateIf(statement);
    }
}

class stCondition {
    constructor (util, options) {
        this.$util = util;
        let {
            conditions,
            params
        } = options || {};
        this.params = params || {};
        if (conditions && util.typeOf(conditions) != "array") {
            conditions = [conditions];
        }
        this.conditions = conditions;
        this.show_stroage = null;
        this.mode = options['mode'];
    }
    get strorageId () {
        let storage_id = this.$util.md5(this.$util.$router.path.split("?")[0] + JSON.stringify(this.conditions));
        return storage_id;
    }
    // 检测当前condition状态并且返回match的对象
    check () {
        if (!this.conditions) {
            this.$util.$loger.warn("⚠️ conditions 为空")
            return false;
        }
        let condition = null;
        for (let i=0 ; i < this.conditions.length ; i++) {
            let temp = this.$util.copy( this.conditions[i] );
            if (temp) { // 关键词替换
                temp = JSON.parse ( this.$util.replaceReg(JSON.stringify(temp), this.params) );
            }
            let match = this._matchIf(temp);
            if (match) {
                condition = temp;
                break;
            }
        }
        return condition;
    }
    _matchIf (condition) {
        if (!condition['if'] && condition['if'] != "") {
            return true;
        }
        // 开关
        if (condition['switch'] === 0) {
            return false;
        }
        // 兜底逻辑
        if (condition['if'] == 'else') {
            return true;
        }
        // 处理可执行逻辑...
        return this._if(condition['if']);
    }
    _if (ifs){
        let match = true;
        
        // 文字式条件判断
        if (this.$util.typeOf(ifs) == "string") {
            return this._calculateIf(ifs);
        }

        // key value 模式条件判断
        for (let key in ifs) {
            match = match && this._findIf(key, ifs[key]);
            if (!match) {
                break;
            }
        }
        return match;
    }
    _findIf (key, value) {
        // 判断appid
        if ('appid' == key) {
            // if (!this.$util.$store.state.data || !this.$util.$store.state.data.appid) {
            //     this.$util.$loger.warn("StRecommend Error: condition get data.appid fail 推荐在保证获取应用所属的data.appid后加载推广位");
            //     return false;
            // }
            // return value.indexOf(this.$util.$store.state.data.appid) != -1;
            if (value && this.$util.typeOf(value) == "array") {
                return value.indexOf(this.$util.$login.appid) != -1;
            } else if (value && this.$util.typeOf(value) == "string") {
                return value == this.$util.$login.appid;
            }
        }
        // 判断当前app
        if ('app' == key) {
            return value == this.$util.$config.__APP__;
        }
        // 环境
        if ('env' == key) {
            return this.$util.isEnv(value);
        }
        // 是否关注服务号
        if ('sub_mp' == key) {
            let match = false;
            if (value && this.$util.typeOf(value) == "object" && this.$util.$login.openid && this.$util.$store.state.userInfo && this.$util.$store.state.userInfo.union_map) {
                // console.error("this.$util.$store.state.userInfo.union_map=>",this.$util.$store.state.userInfo.union_map)
                // console.error("value=>", value);
                // console.error("this.$util.$login.openid=>",this.$util.$login.openid)
                // console.error("this.$util.$store.state.userInfo=>",this.$util.$store.state.userInfo)
                let temp_match = true;
                for (let item in value) {
                    // 每一个关注参数都match
                    let sub_item = this.$util.$store.state.userInfo.union_map[item] && this.$util.$store.state.userInfo.union_map[item]['subscribe'] ? this.$util.$store.state.userInfo.union_map[item]['subscribe'] : 0
                    temp_match = temp_match && ( value[item] == sub_item );
                    // console.error("sub?=>",this.$util.$store.state.userInfo.union_map[item])
                    // console.error("item=>",item, " value[item]=>", value[item], " temp_match=>",temp_match)
                    if (!temp_match) {
                        break;
                    }
                }
                match = temp_match;
            } else {
                // console.error(" no login info ");
            }
            // console.log("match=>",match)
            return match;
        }
        // 显式登录
        if ('login' == key) {
            let has_login = Boolean( this.$util.$store.state.userInfo && !this.$util.$store.state.userInfo['need_login']);
            return has_login == Boolean( value );
        }
        // 静默登录
        if ('login_base' == key) {
            return Boolean( this.$util.$store.state.userInfo && this.$util.$store.state.userInfo['openid'] ) == value;
        }
        // 根据域名条件
        if ('host' == key) {
            if (this.$util.isEnv('wxa')) {
                return false;
            }

            let in_host = false; // value可以为数组或单一字符串
            if (this.$util.typeOf(value) == "string") {
                value = [value];
            }
            for (let i=0; i<value.length; i++) {
                if (value[i] == window.location.host) {
                    in_host = true;
                    break;
                }
            }
            
            return in_host;
        }
        // 缓存显示
        if ('storage' == key) {
            if (this.$util.typeOf(value) != 'array' || value.length != 2){
                this.$util.$loger.warn("缓存时间需设置成2位的数组")
                return false;
            } else if (value[0] > value[2]) {
                this.$util.$loger.warn("缓存时间数组需要第一位比第二位大")
                return false;
            }
            let storage = this.$util.getStorageSync(this.strorageId);
            let _now = Date.parse(new Date())/1000;
            if (!storage){ // 首次出现
                storage = {
                    "t": _now,
                    "r": value
                }
                this.$util.setStorageSync(this.strorageId, storage)
                return true;
            } else { // 非首次出现
                if (_now - storage['t'] < storage['r'][0] || _now - storage['t'] > storage['r'][1]) { // 范围外重新显示
                    storage = {
                        "t": _now,
                        "r": value
                    }
                    this.$util.setStorageSync(this.strorageId, storage) 
                    return true;
                } else {
                    return false;
                }
            }
        }
        // 生命周期内显示
        if ('lifecycle' == key) {
            if (this.$util.typeOf(value) == "number") {
                let gl = this.$util.$global.get(this.strorageId, 0);
                gl = gl == undefined ? 0 : gl;
                if (!gl || gl < value) {
                    this.$util.$global.set(this.strorageId, gl + 1);
                    return true;
                } else {
                    return false;
                }
            }
            if (this.$util.typeOf(value) != 'array' || value.length != 2){
                this.$util.$loger.warn("缓存时间需设置成2位的数组")
                return false;
            } else if (value[0] > value[2]) {
                this.$util.$loger.warn("缓存时间数组需要第一位比第二位大")
                return false;
            }
            let global = this.$util.$global.get(this.strorageId);
            let _now = Date.parse(new Date())/1000;
            if (!global){ // 首次出现
                this.btn_lifecycle_value = {
                    "t": _now,
                    "r": value
                }
                return true;
            } else { // 非首次出现
                if (_now - global['t'] < global['r'][0] || _now - global['t'] > global['r'][1]) { // 范围外重新显示
                    this.btn_lifecycle_value = {
                        "t": _now,
                        "r": value
                    }   
                    return true;
                } else {
                    return false;
                }
            }
        }
        // 时间段
        if ('time' == key) {
            let __now__ = new Date(),
                sec = __now__.getSec(),
                {year, month, day, hour, minute, second} = this.$util.getTimeDetail(__now__);
                
            let match = false;

            // 特殊判断
            if (this.$util.typeOf(value) == "string") {
                // value: morning, afternoon, night, weekend...
                match = this.$util.isTime(
                    __now__,
                    value
                );
            } else {
                for (let i = 0 ; i < value.length ; i++) {
                    let item = value[i];
                    if (this.$util.typeOf(item) == "array" && item.length == 2) {
                        let _from = item[0];
                        let _to = item[1];
                        if (this.$util.typeOf(_from) == "number") { // 时间戳
                            if (sec < _to && sec > _from){
                                match = true;
                            }
                        } else if (this.$util.typeOf(_from) == "string") {
                            if (_from.indexOf(":") != -1 && _to.indexOf(":") != -1) { // 一日中的时间段
                                _from = _from.split(":");
                                _to = _to.split(":");
                                let _from_sec = new Date(year, month - 1, day, _from[0], _from[1]).getSec();
                                let _to_sec = new Date(year, month - 1, day, _to[0], _to[1]).getSec();
                                if (sec < _to_sec && sec > _from_sec){
                                    match = true;
                                }
                            } else if (_from.indexOf("-") != -1 && _to.indexOf("-") != -1) { // 日期段
                                _from = new Date(_from).getSec();
                                _to = new Date(_to).getSec();
                                if (sec < _to && sec > _from){
                                    match = true;
                                }
                            }
                        }
                    }
                }
            }

            return match;
        }
        // 应用进入渠道
        if ('scene' == key) {
            if (value && this.$util.typeOf(value) == "array") {
                return Boolean( value.indexOf(this.$util.$router.scene) > -1 );
            }
            return value == this.$util.$router.scene;
        }
        // 当前应用版本
        if ('version' == key) {
            /*
             * 1. 判断环境
             * version
             *      develop 开发环境
             *      trial 审核/体验环境
             *      release 正式环境
             */
            let env_version = this.$util.$config.env_version;
            if (this.$util.typeOf(value) == 'string') {
                value = [value];
            }
            let result = this.$util.inAry(env_version, value);
            if (!result) {
                result = value == this.$util.$config.version;
            }
            return result;
            // if (['debug', 'audit', 'production'].indexOf(value[0]) != -1) {
            //     // 判断环境
            //     if (this.$util.$config.ISDEBUG && this.$util.inAry('debug', value)) {
            //         result = true;
            //     }
            //     if (this.$util.$config.ISDEBUG == false && online_version != local_version && this.$util.inAry('audit', value)) {
            //         result = true;
            //     }
            //     if (this.$util.$config.ISDEBUG == false && online_version == local_version && this.$util.inAry('production', value)) {
            //         result = true;
            //     }
            // } else {
            //     // 判断版本号
            //     if (value == local_version) {
            //         result = true;
            //     }
            // }
            // return result;
        }
        // 实验组
        if ('expt' == key) {
            let match = false
            if (value && this.$util.typeOf(value) == "object") {
                let temp_match = false;
                for (let item in value) {
                    // 每一个expt都需要match
                    let temp_expt = this.$util.$wx.wxExpt[item];
                    // console.log(item," : $wx.wxExpt[item]==>",temp_expt, "& value[item]==>",value[item]);
                    temp_match = value[item] == temp_expt;
                    if (!temp_match) {
                        // console.log("break==>", key, value)
                        break;
                    }
                }
                match = temp_match;
                // if (match) {
                //     console.log("match==>", key, value);
                // }
                // console.log("======================>")
            }
            return match;
        }
        // 是否新用户
        if ('is_new' == key) {
            let is_new = 0;
            try {
                // console.log("this.$util.$login.user==>",this.$util.$login.user);
                if (this.$util.$login.user
                    && this.$util.$login.user['created_at']) {
                    let create_date = this.$util.dateStr2Date( this.$util.$login.user['created_at'] ),
                        now_date = new Date(),
                        created_at = this.$util.dateStr2Date( this.$util.$login.user['created_at'] ).getSec(),
                        now = new Date().getSec();
                    // console.log("user created_at==>", created_at);
                    // 当value为秒数且 > 60秒时按照 value判断是否新用户
                    if (value > 60) {
                        is_new = ( now - created_at > value ) ? 0 : 1;
                    }
                    // else { // 默认判断24h内为新用户
                    //     return ( ( now - created_at > 86400 ) ? 0 : 1 ) == value
                    //     // return ! ( ( ( now - created_at > 86400 ) ? 0 : 1 ) == value )
                    // }
                    else { // 创建日期与当前日期不是同一天则判断为老用户/否则新用户
                        let today_zero = new Date(new Date().toLocaleDateString());
                        return Boolean( this.$util.daysDistance(create_date, today_zero) <= 1 ) == value;
                    }
                }   
            } catch (error) {
                if (this.$util.$config.ISDEBUG) {
                    console.error("check is_new error", error)
                }
            }
            return Boolean( is_new );
        }
        // 判断路由中参数
        if ('router' == key) {
            let match = false;
            if (this.$util.typeOf(value) == "object") {
                for (let item in value) {
                    if (this.$util.$router.params[item] == value[item]) {
                        match = true;
                    } else {
                        return false;// 一个不等于则return 不match
                    }
                }
            } else if (this.$util.typeOf(value) == "string") {
                match = this.$util.$router.params[value];
            }
            return match;
        }
        // sdk版本
        if ('sdkversion' == key) {
            let systeminfo = this.$util.getSystemInfoSync();
            if (systeminfo['SDKVersion']) {
                let version = systeminfo['SDKVersion'];
                return Boolean( version >= value )
            }
            return false;
        }
        // 路由动作判断
        if ('router_action' == key) {
            let {
                    path,
                    openType,
                    count
                } = value,
                gl = 0,
                router_logs = this.$util.$router.APP_ROUTE_LOG,
                route_log = router_logs && router_logs.length > 0 ? router_logs[ router_logs.length - 1 ] : {};
            if (!path || !openType) {
                return false;
            }

            if (count) {
                gl = this.$util.$global.get(this.strorageId);
                gl = gl == undefined ? 0 : gl;
                if (gl >= count) {
                    return false;
                }
            }

            // 加工path参数，变为纯净格式
            path = path.split("?")[0];
            path = path.split(".")[0];
            if (path.indexOf("/") == 0) {
                path = path.slice(1)
            }
            
            let visited = false;
            if (openType == "visited") {
                for (let i = 0 ; i < router_logs.length ; i++) {
                    if (router_logs[i]["path"] == path) {
                        visited = true;
                        break;
                    }
                }
            }

            visited = visited || Boolean( route_log["openType"] == openType && path == route_log["path"] );

            if (visited && count) {
                this.$util.$global.get(this.strorageId, (gl + 1));
            }
            return visited;
        }
        // 进入小程序参数判断
        if ('luanch_app' == key) {
            let luanch_log = this.$util.$router.APP_ROUTE_LOG && this.$util.$router.APP_ROUTE_LOG[0],
                match = true,
                {
                    has_params,
                    match_params
                } = value;
            if (has_params) {
                for (let item in has_params) {
                    if (!luanch_log.query[item]) {
                        match = false;
                        break;
                    }
                }
            }
            if (match_params) {
                for (let item in match_params) {
                    if (luanch_log.query[item] != match_params[item]) {
                        match = false;
                        break;
                    }
                }
            }
            return match;
        }
        // 其他外部变量
        return Boolean(value);
    }
    _calculateIf (statement) {
        statement = statement.split(" ");
        if (statement.length == 3) { // 等式判断
            let l = this._fitCondition(statement[0]),
                r = this._fitCondition(statement[2]),
                sign = statement[1];
            // console.log("statement >>>>>>> ", statement)
            // console.log("condition >>>>>>>>> ", l, r, sign)
            if (sign == '==') {
                return l == r;
            } else if (sign == '>') {
                return l > r;
            } else if (sign == '<') {
                return l < r;
            } else if (sign == '&&') {
                return l && r;
            } else if (sign == '||') {
                return l || r;
            }
        } else if (statement.length == 1) { // 真假判断
            // console.log("statement => ", statement[0]);
            return this._fitCondition(statement[0]);
        } else if (statement.length == 5) {
            return this._doCondition([this._doCondition([statement[0], statement[1], statement[2]].join(' ')), statement[3], statement[4]].join(' '));
        } else {
            console.log("statement", statement)
        }
    }
    _fitCondition (t) {
        if ('boolean' === this.$util.typeOf(t)) return t;
        if ('false' === t) return false;
        if ('true' === t) return true;
        if ("0" === t) return false;
        if ("1" === t) return true;
        if ("" === t) return false;
        if ("number" == this.$util.typeOf(t)) {
            return t;
        }
        if ("string" == this.$util.typeOf(t)) {
            if (t.match(/^\d+$/)) return parseInt(t);
        }
        return t;
    }
}

export default {
    install
}