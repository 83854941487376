const bw = 500;

export default {
	staticHost: [
		'litigogo.top',
		'static.litiskr.cn',
		'debugstatic.litiskr.cn'
	],
	originIdMap: {
		"wx2fb7317f6bc5b387": "gh_37a75119d615",
		"wxcd236d6f280a1644": "gh_cef5ebb87344",
		"wxdae27e027cadf4c6": "gh_3699801ea1f5",
		"wxa52d8e4c620f0e4e": "gh_61381758c3d7",
		"wxfd8adde97c295a6d": "gh_b90a85ee1b9d",
		"wx5f5d3f265242e546": "gh_67e5c0056b43"
	},
	debugHost: [
		'localhost:8081',
		'localhost:8080',
		'dev2.xcgogo.site',
		'debugwxa.litiskr.xyz',
		'debugg3.litiskr.cn',
		'debugwebview.litiskr.cn',
		// 临时
		// 'static.litiskr.cn',
		'debugstatic.litiskr.cn'
	],
	hint: {
		pv: {
			'pv_100': 'http://resliti.lititutu.cn/tuku/prod/979ab264c74e0e5e8e83b91fe5878fe6.png',
			'pv_50': 'http://resliti.lititutu.cn/tuku/prod/8242860b00af78254990b8d7cd29298e.png',
			'pv_20': 'http://resliti.lititutu.cn/tuku/prod/8767032b96294d26b1622a7186435433.png',
			'pv_10': 'http://resliti.lititutu.cn/tuku/prod/509539e9171eaa4ee857b3eb3f63d787.png',
			'pv_5': 'http://resliti.lititutu.cn/tuku/prod/91da8f59821b624f7e88f15c0596f5cb.png',
			'pv_1': 'http://resliti.lititutu.cn/tuku/prod/29224f37443eb4d0977d7923d27d120d.png'
		}
	},
	// 返利群物料
	qun: {
		"coverImgs": [
			"http://resali.lititutu.cn/tuku/prod/18788f831f893e9bcc1b7e5bf54e2026.gif"
		]
	},
	// h5直跳小程序绑定域名
	wxaAble: ['xcgogo.site', 'lititutu.top', 'xcpapa.cn'],
	// 优量汇绑定域名
	adAble: {
		'xcpapa.cn': {
			'app_id': '1110554071',
			'placement_ids': [
				'6011713886548899', // 分享页二屏banner广告
				'2051726061888321' // 分享页插屏广告
			]
		},
		'lititutu.top': {
			'app_id': '1110638837',
			'placement_ids': [
				'3061321138860393', // 分享页二屏banner广告
				'7061420188161224' // 分享页插屏广告
			]
		},
		'xcpapa.wang': {
			'app_id': '1110723500',
			'placement_ids': [
				'2051320300152414', // 分享页二屏banner广告
				'8071327310454405' // 分享页插屏广告
			]
		},
		'litigogo.cn': {
			'app_id': '1110723516',
			'placement_ids': [
				'5071827300851486', // 分享页二屏banner广告
				'3041125330150457' // 分享页插屏广告
			]
		},
		'xcgogo.cn': {
			'app_id': '1110645609',
			'placement_ids': [
				'6041321310450458', // 分享页二屏banner广告
				'1031524360756409' // 分享页插屏广告
			]
		},
		'xcgogo.xyz': {
			'app_id': '1110723542',
			'placement_ids': [
				'8041925320851590', // 分享页二屏banner广告
				'8001721320459581' // 分享页插屏广告
			]
		},
		'xcpapa.club': {
			'app_id': '1110645635',
			'placement_ids': [
				'7031826380250553', // 分享页二屏banner广告
				'6061725370159564' // 分享页插屏广告
			]
		},
		'xcgogo.club': {
			'app_id': '1110645675',
			'placement_ids': [
				'1071628330350595', // 分享页二屏banner广告
				'3051626320250506' // 分享页插屏广告
			]
		},
		'liticaca.xyz': {
			'app_id': '1110645683',
			'placement_ids': [
				'6071022310856517', // 分享页二屏banner广告
				'3041621300655538' // 分享页插屏广告
			]
		},
		'xcpapa.xyz': {
			'app_id': '1110645689',
			'placement_ids': [
				'4061025330450519', // 分享页二屏banner广告
				'2071223380057690' // 分享页插屏广告
			]
		},
		'litigogo.top': {
			'app_id': '1110723638',
			'placement_ids': [
				'5091020340253642', // 分享页二屏banner广告
				'1021026360850643' // 分享页插屏广告
			]
		}
	},
	// 酷狗音乐相关配置
	kgPlayer: {
		businessId: '7412b64d4cf148ed83a0'
	},
	// shareTitles: {
	// 	// 'xiaonian': '小年，送你祝福3D立体相册，打开看看✨',
	// 	// 'xinniankuaile2': '新年已来到，向你问个好，祝你新年快乐',
	// 	// 'gongxifacai2': '大财小财意外财，财源滚滚来，祝你新年快乐',
	// 	// 'zhaocaijinbao': '恭祝新年吉祥，幸福和财富与你同在。恭祝健康幸运，新年快乐',
	// 	// 'wufulinmen': '新年到，祝你福如东海长流水，寿比南山，新年快乐',
	// 	// 'wanshiruyi': '新年到，祝你新年快乐，万事如意！',
	// 	// 'zhuniankuaile': '祥风阵阵，福来运转，猪年新春，新年快乐',
	// 	// 'yuandankuaile': '喜迎猪年，愿你身体健康，福寿绵长',
	// 	// 'xinniankuaile': '愿你猪年吉祥，好运如潮，万事顺心',
	// 	// 'caiyuanguangjin': '愿你猪年吉祥，好运如潮，财源广进',
	// 	// 'jixiangzhunian': '愿你猪年吉祥，好运如潮，新年快乐',
	// 	// 'yuanxiaosongfu': '元宵节日祝你开心时时、快乐天天、幸福年年！',
	// 	// 'yuanxiao': '团团圆圆幸福比蜜甜。祝你元宵节快乐！',
	// 	// 'funvjie': '感情是真挚的，祝福是真心的，三八女神节愿你幸福相依!',
	// 	// 'funvjie2': '感情是真挚的，祝福是真心的，三八女神节愿你幸福相依!',
	// 	'fuqinjiexiangce': '💖祝天下所有父亲：开开心心快快乐乐🌷健健康康平平安安❗',
	// 	'laoertongjierikuaile': '六一到！老玩童们,节日快乐！送给群里每位朋友',
	// 	'lixiakuaile': '愿你快乐一夏，幸运一夏，立夏快乐！',
	// 	'muqinjiekuaile': '母亲节，一声祝福送给最伟大的母亲',
	// 	"zongqingduanwu": "端午粽子甜又甜✨微信祝福送心间💯端午快乐❗",
	// 	"duanwuankang": "端午粽子甜又甜✨微信祝福送心间💯端午快乐❗",
	// 	"duanwukuaile": "端午粽子甜又甜✨微信祝福送心间💯端午快乐❗",
	// 	"xiazhikuaile": "送你夏至祝福相册，愿你好运连连，祝夏至快乐！",

	// 	"zhongqiubianlian": "【@你】看我变身嫦娥送祝福，点开看看！",
	// 	// "zhongqiukuaile": "【@你】中秋到，绝美祝福相册送给你，点开看看！",
	// 	// "zqkl": "【@你】中秋到，月亮告诉我送给你祝福，点开看看！",
	// 	"qiufenkuaile": "秋日写满了祝福，好运不断，送你秋日祝福相册，点开看看！",
	// 	"xiyingguoqing": [
	// 		"【@你】🇨🇳太美了，我为祖国制作的「国庆3D立体相册」！打开看看！",
	// 		"【@你】🇨🇳我为祖国制作了相册，祝祖国繁荣昌盛！打开看看！",
	// 	],
	// 	"huanduguoqing": [
	// 		"【@你】🇨🇳太美了，我为祖国制作的「国庆3D立体相册」！打开看看！",
	// 		"【@你】🇨🇳我为祖国制作了相册，祝祖国生日快乐！打开看看！",
	// 	],
	// 	"guoqingbianlian": [
	// 		"【@你】🇨🇳我是第15221366位为祖国送祝福的人，点击接力！",
	// 		"【@你】🇨🇳我是第15221366位登上祝福祖国的列车的人，快上车！",
	// 	],
	// 	"guoqingheying": "【@你】🇨🇳祖国70华诞，我在这里和国旗合影，打开看看",
	// 	"chongyangheying": "【@你】重阳节，我在这里送你长长久久美好祝福，打开看看",
	// 	"chongyangjiajie": "【@你】重阳节，我在这里送你长长久久美好祝福，打开看看",

	// 	"xiaohanwenhou": "小雪， 为你送来祝福 ，祝愿你秒秒快乐 ，分分美好，时时如意！",
	// 	"ganen": "最美感恩祝福，送给身边的你！感恩有你！",

	// 	"dongzhikuaile3d": "冬至，送你世间最美的问候，快打开看看吧！",
	// 	"dongzhikuaile": "送你最美的冬至祝福！快打开看看吧！",


	// 	"xinnianbainian": "春节到，祝新年快乐，万事如意！给您拜年了！打开看看",

	// 	"xiaonian": [
	// 		"【@你】㊗️最美的小年立体相册，送给我最好的朋友！收到有好运！",
	// 		"【@你】㊗️小年祝福相册送给你，愿你日子甜蜜蜜，生活幸福又如意！",
	// 		"【@你】㊗️小年祝福相册！越早打开越幸福！"
	// 	],

	// 	'default': [
	// 		"✨好看的照片都放在立体相册里了！✨",
	// 		"✨太美了！没想到照片还能这样玩，打开看看✨",
	// 		"相册竟能旋转立体！身临其境！绝！",
	// 		"和你分享我刚刚制作的相册。颠覆想象！简直美绝了~"
	// 	]
	// },
	markTids: ["xiyingguoqing","huanduguoqing","qiufenkuaile", "jiushimeimeida", "haidishijie", "taohuayuan3d", "shenmihuayuan", "hetangyuese", "zhongqiukuaile", "langmanqingren", "suiyuejinghao", "meilinvren", "tongzhentongqu", "chunt", "jingsenianhua", "nvrenruhua", "funvjie", "chunyiangran", "tachunshanghua", "longtaitou", "chunfenshijie", "lvsexinqing", "mantianhuacai", "baihuazhengyan", "exhi4", "huashijie", "qingchihehua"],
	marks: {
		'最新': 'http://litiresource.xcgogo.site/user/9546085/images/2c3f3a389ae0735de700bada480ea1fa.png',
		'热门': 'http://litiresource.xcgogo.site/user/9546085/images/76e4284bde88ef2e3035958c8a437cbd.png',
		'限免': 'http://litiresource.xcgogo.site/user/9546085/images/e32209da1b8ed5ba52170df8884d329c.png',
	}
}

/*
themebak: {
		"wanhuatong3D": {
			"type": "full",
			"tid": "wanhuatong3D",
			"mid": "4a4ca8906c30e21b3aa4aef6bb3a329f",
			"name": "万花筒3D",
			"mode": {}
		},
		"menghuanmeigui": {
			"type": "simple",
			"tid": "menghuanmeigui",
			"mid": "230959be97375fc76513bdba370c196a",
			"name": "梦幻玫瑰",
			"mode": {
				"bg": "http://resali.lititutu.cn/scenes/menghuanmeigui/00.gif",
				"box": [440, 600],
				"photo": {
					"border": {
						"width": 8
					}
				},
				"scene": {
					"maskUpScaleIn": {
						"duration": 5
					},
					"rotateUpOut": {
						"duration": 3
					}
				},
				"timeline": [
					"0,maskUpScaleIn,3,rotateUpOut",
					"11,maskUpScaleIn,3,rotateUpOut",
					"22,end"
				],
				"decorate": {
					"gif": [
						{
							"duration": 2.5,
		    				"width": 500,
		    				"height": 815,
		    				"backgroundWidth": 500,
		    				"backgroundHeight": 815*31,
		    				"animationIterationCount": 1,
		    				"sprite_image": {
		    					"im": "http://resali.lititutu.cn/scenes/menghuanmeigui/01.png",
		    					"steps": 31
		    				},
						}
					]
				},
				"text": {
					"textShadowColor": "#e22e3c"
				}
			}
		},
		"huakaipiaoxiang": {
			"type": "simple",
			"tid": "huakaipiaoxiang",
			"mid": "e02a031992867261cb500fab67a6124e",
			"name": "花开飘香",
			"mode": {
				"bg": "http://resali.lititutu.cn/scenes/huakaipiaoxiang/58be2105db696.jpg",
				"box": [1000, 1000],
				"scene": {
					"fullScreenLeftIn": {
						"duration": 7,
					},
					"fullScreenRightIn": {
						"duration": 7,
					},
					"fadeOut": {
						"duration": 1,
					}
				},
				"timeline": [
					"0,fullScreenLeftIn,0,fadeOut",
					"7,fullScreenRightIn,0,fadeOut",
					"13,end"
				],
				"decorate": {
					"gif": [
						{
		    				"duration": 2.5,
		    				"width": 500,
		    				"height": 215,
		    				"backgroundWidth": 500,
		    				"backgroundHeight": 215*30,
		    				"sprite_image": {
		    					"im": "http://resali.lititutu.cn/icons/hua/huacong.png",
		    					"steps": 30
		    				},
		    				"pos": [-80, null, null, 0],
		    				'transform': 'rotate(180deg)'
		    			},{
		    				"duration": 2.5,
		    				"width": 500,
		    				"height": 215,
		    				"backgroundWidth": 500,
		    				"backgroundHeight": 215*30,
		    				"sprite_image": {
		    					"im": "http://resali.lititutu.cn/icons/hua/huacong.png",
		    					"steps": 30
		    				},
		    				"pos": [null, null, -60, 0]
		    			}
					],
					"elems": [
						{
							"im": "http://resali.lititutu.cn/icons/hua/001.png",
							"size": [228, 343],
							"pos": [null, -30, 0, null],
							"classname": "shuZhiHuang",
							"style": {
								"animationDuration": "2s",
								"transformOrigin": "50% 100%"
							}
						}
					],
					"piao3": [
						"http://resali.lititutu.cn/icons/hua/002.png",
						"http://resali.lititutu.cn/icons/hua/003.png",
						"http://resali.lititutu.cn/icons/hua/004.png",
					]
				},
				"text": {
					"textShadowColor": "#e22e3c"
				}
			}
		},
		"qingming3d": {
			"type": "normal",
			"tid": "qingming3d",
			"mid": "ca95c14473bc0c4c56229ec6d62600b3",
			"name": "祈福心灯3D",
			"mode": {
				"bg": [
					// "http://resali.lititutu.cn/scenes/qingming3d/0.jpg",
					"http://resali.lititutu.cn/scenes/qingming3d/1.jpg",
					"http://resali.lititutu.cn/scenes/qingming3d/2.jpg",
					// "http://resali.lititutu.cn/scenes/qingming3d/4.jpg",
				],
				"decorate": {
					"piao": [
						"http://resali.lititutu.cn/icons/denglong/denglong_1_120.png",
						"http://resali.lititutu.cn/icons/denglong/denglong_1_80.png",
						"http://resali.lititutu.cn/icons/denglong/denglong_1_60.png",
					]
				}
			}
		},
		"menghuanxianjing": {
			"type": "simple",
			"tid": "menghuanxianjing",
			"mid": "4db754ed526f772355af64ddb219428d",
			"name": "梦幻仙境",
			"mode": {
				"bg": "http://resali.lititutu.cn/scenes/menghuanxianjing/00.gif",
				"box": [440, 600],
				"photo": {
					"border": {
						"width": 8
					},
					"decorate": {
						"wing": 1
					}
				},
				"scene": {
					"topScaleIn": {
						"duration": 4
					},
					"topRightOut": {
						"duration": 3
					},
					"topRightIn": {
						"duration": 3
					},
					"topLeftOut": {
						"duration": 3
					},
					"topLeftIn": {
						"duration": 4
					},
					"topRightLeftOut": {
						"duration": 4
					},
					"maskUpScaleIn": {
						"duration": 5
					},
					"topLeftRightOut": {
						"duration": 4
					}
				},
				"timeline": [
					"0,topScaleIn,3,topRightOut",
					"10,topRightIn,3,topLeftOut",
					"18,topLeftIn,3,topRightLeftOut",
					"29,maskUpScaleIn,3,topLeftRightOut",
					"40,end"
				],
				"decorate": {
					"elems": [
						{
							"im": "http://resali.lititutu.cn/scenes/menghuanxianjing/01.png",
							"size": [190, 176],
							"pos": [null, null, 0, 0],
							"classname": "shuZhiHuang",
							"style": {
								"animationDuration": "3s",
								"transformOrigin": "50% 100%"
							}
						},
						{
							"im": "http://resali.lititutu.cn/scenes/menghuanxianjing/02.png",
							"size": [116, 113],
							"pos": [null, null, 20, 185],
							"classname": "shuZhiHuang",
							"style": {
								"animationDuration": "2s",
								"transformOrigin": "50% 100%"
							}
						},
						{
							"im": "http://resali.lititutu.cn/scenes/menghuanxianjing/03.png",
							"size": [168, 157],
							"pos": [null, null, 15, 310],
							"classname": "shuZhiHuang",
							"style": {
								"animationDuration": "5s",
								"transformOrigin": "50% 100%"
							}
						}
					]
				},
				"text": {
					"textShadowColor": "#aa07d2"
				}
			}
		},
		"qingming": {
			"type": "simple",
			"tid": "qingming",
			"mid": "380d91cea00d107d818655b9121af5b5",
			// "mid": "2cd70fcf21c8a8c7233a7973962eb41e",
			"name": "谷雨时节",
			// "name": "清明时节",
			"mode": {
				// "bg": "https://resali.lititutu.cn/scenes/qingming/09.jpg",
				"bg": "https://resali.lititutu.cn/scenes/qingming/11.jpg",
				"box": [440, 600],
				"photo": {
					"border": {
						"width": 6,
					},
					"reflect": 1
				},
				"scene": {
					"fanIn": {
						"duration": 3
					},
					"fanOut": {
						"duration": 3
					}
				},
				"timeline": [
					"0,fanIn,2,fanOut",
					"8,fanIn,2,fanOut",
					"16,end"
				],
				"decorate": {
					"gif": [
						{
		    				"duration": 1,
		    				"width": bw,
		    				"height": bw*1.575,
		    				"backgroundWidth": bw,
		    				"backgroundHeight": bw*12.6,
		    				"sprite_image": {
		    					"im": "http://resali.lititutu.cn/scenes/qingming/xiayu.png",
		    					"steps": 8
		    				}
		    			}
					],
					"elems": [
						{
							"im": "http://resali.lititutu.cn/scenes/qingming/00.png",
							"size": [500, 148],
							"pos": [null, null, 0, 0]
						},
						{
							"im": "http://resali.lititutu.cn/scenes/qingming/01.png",
							"size": [500, 152],
							"pos": [null, null, -24, 0]
						},
						{
							"im": "http://resali.lititutu.cn/scenes/qingming/02.png",
							"size": [200, 141],
							"pos": [null, 20, 20, null]
						},
						{
							"im": "https://resali.lititutu.cn/scenes/qingming/03.png",
							"size": [112, 230],
							"pos": [0, null, null, 0],
							"classname": "shuZhiHuang",
							"style": {
								"animationDuration": "2s",
								"transformOrigin": "0 0"
							}
						},
						{
							"im": "https://resali.lititutu.cn/scenes/qingming/04.png",
							"size": [87, 134],
							"pos": [0, null, null, 100],
							"classname": "shuZhiHuang",
							"style": {
								"animationDuration": "3s",
								"transformOrigin": "0 50%"
							}
						},
						// {
						// 	"im": "https://resali.lititutu.cn/scenes/qingming/08.gif",
						// 	"size": [500, 787],
						// 	"pos": [0, null, null , 0]
						// }
					]
				}
			}
		},
		"38funvjie3d": {
			"type": "full",
			"tid": "38funvjie3d",
			// "mid": "60f65de71cfd8207170d16587de23ab4",
			"mid": "b2a8106cdfb82c56912e869b6b9da9a8",
			"name": "花中女神3D",
			"mode": {
				"timeline": [
					"0,scene,zhuanyuan",
					"13,inter,zhuanzuoxiayoushang",
					"15,scene,aixintiao",
					"28,inter,zhuanyoushangzuoxia",
					"30,scene,fenshen",
					"43,inter,zhuanzuoshangyouxia",
					"45,scene,dongxin",
					"58,inter,zhuanyouxiazuoshang",
					"60,end"
				],
				"inter": {
					"zhuanzuoxiayoushang": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanyoushangzuoxia": ["http://resali.lititutu.cn/icons/hua/04.png"],
					"zhuanzuoshangyouxia": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanyouxiazuoshang": ["http://resali.lititutu.cn/icons/hua/04.png"]
				},
				"dongxin": [
					"http://resali.lititutu.cn/icons/huahuan/7.gif",
					"http://resali.lititutu.cn/icons/huahuan/05.png"
				],
				"zhuanyuan": [
					"http://resali.lititutu.cn/scenes/38funvjie3d/6.gif",
					"http://resali.lititutu.cn/icons/huahuan/04.png"
				],
				"fenshen": [
					// "https://resali.lititutu.cn/scenes/38funvjie3d/0.gif",
					"https://resali.lititutu.cn/scenes/38funvjie3d/2.gif",
					"https://resali.lititutu.cn/scenes/38funvjie3d/4.png",
					"https://resali.lititutu.cn/scenes/38funvjie3d/1.gif"
				],
				"aixintiao": [
					"https://resali.lititutu.cn/scenes/38funvjie3d/5.gif",
					"https://resali.lititutu.cn/icons/huahuan/01.png"
				],
				"text": {
					"textShadowColor": "rgb(173, 35, 23)"
				}
			},
			"prelude": {
				"duration": 5,
				"component": "fadescaleout",
				"mode": [
					// "http://litiresource.xcgogo.site/user/5980171/images/3bab0d4ae07de6aac7efbd1fa34ea861.gif",
					"http://litiresource.xcgogo.site/user/5980171/images/9de3279f047d5176a3bf6846236fed66.gif",
				]
			},
		},
		"jingzhe": {
			"type": "full",
			"tid": "jingzhe",
			"mid": "6219f44a87c54f2748b98ef2a07333d1",
			"name": "唯美惊蛰",
			"mode": {
				"scene": ["dong13", "dong13"],
				"dong13": [
					"http://litiresource.xcgogo.site/user/5980171/images/c429c7d21de091cfb1f1f0797cd1d131.gif"
				],
				"decorate": {
					"dong13": {
						"luo2": [
							"http://resali.lititutu.cn/icons/hua/01.png"
						],
						"elems": [
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/a493710f4ab1333a5acc17aff5175cc0.png",
								"size": [bw*.36, bw*.552],
								"pos": [0, 0]
							},
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/75a3125f15fe0bfb57d1963619d8866a.gif",
								"size": [bw, bw*.54],
								"pos": [null, null, 0, 0]
							},
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/0637a2c458bfb80f1c218366b9d72710.gif",
								"size": [bw*.44, bw*.524],
								"pos": [null, null, bw*.33, bw*.024]
							},
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/c735ac17c134dc9f6539a35bb111c5c8.gif",
								"size": [bw*.72, bw*.664],
								"pos": [0, 0, null, null]
							}
						]
					}
				}
			},
			"prelude": {
				"duration": 7,
				"component": "fadescaleout",
				"mode": [
					"http://litiresource.xcgogo.site/user/5980171/images/7cd0efde73fc5a506baa5cafb6b1db5f.gif"
				]
			},
		},
		"38funvjie": {
			"type": "full",
			"tid": "38funvjie",
			"mid": "nvrenhua",
			"name": "女神祝福",
			"mode": {
				// "scene": ["dong12", "dong12"],
				"timeline": [
					"0,scene,dong12",
					"8,scene,dong12",
					"16,end"
				],
				"dong12": [
					"https://resali.lititutu.cn/scenes/38funvjie/00.gif"
				],
				"decorate": {
					"dong12": {
						"elems": [
							{
								"im": "https://resali.lititutu.cn/scenes/38funvjie/01.gif",
								"size": [580, 320],
								"pos": [null, null, -40, -40]
							},
							// {
							// 	"im": "https://resali.lititutu.cn/scenes/38funvjie/02.png",
							// 	"size": [339, 183],
							// 	"pos": [null, null, 48, 80]
							// }
						],
						"luo3": [
							"https://resali.lititutu.cn/icons/hua/008_2.png",
							"https://resali.lititutu.cn/icons/hua/009_2.png",
							"https://resali.lititutu.cn/icons/hua/010_2.png",
							"https://resali.lititutu.cn/icons/hua/011_2.png",
						],
						"hudie": [
							"https://resali.lititutu.cn/icons/hudie/019.png"
						]
					}
				}
			}
		},
		"qingrenjie": {
			"type": "full",
			"tid": "qingrenjie",
			"mid": "5546777f0dc2d581bf75ec5cc10fee72",
			"name": "最爱情人3D",
			"mode": {
				"scene": ["fangkuai", "zhuanxin", "xinbox", "shuibo"],
				"shuibo": [
					"http://resali.lititutu.cn/scenes/qingrenjie/03.gif"
				],
				"xinbox": [
					"http://resali.lititutu.cn/scenes/qingrenjie/02.gif"
				],
				"zhuanxin": [
					"http://resali.lititutu.cn/scenes/qingrenjie/01.gif"
				],
				"fangkuai": {
					"room": {
						"top": {
							"gif": {
								"type": "gif",
								"duration": 2,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw * 19,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/qingrenjie/00.jpg",
									"steps": 19
								},
								"cover": "http://resali.lititutu.cn/scenes/qingrenjie/1.jpg"
							}
						},
						"bottom": {
							"gif": {
								"type": "gif",
								"duration": 4,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*34,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/mantianxincai/04.jpg",
									"steps": 34
								}
							}
						}
					}
				}
			}
		},
		"yuanxiaotuanyuan": {
			"type": "full",
			"tid": "yuanxiaotuanyuan",
			"mid": "7e0c71e584a605dad4945f465011c18d",
			"name": "元宵团圆3D",
			"mode": {
				"timeline": [
					"0,scene,denglong",
					"10,scene,aixintiao",
					"20,scene,yuanup",
					"30,scene,xuandong",
					"40,end"
				],
				"xuandong": [
					"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/000.png",
					"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/001.png",
					"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/02.gif",
				],
				"yuanup": [
					"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/03.jpg",
					"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/04.jpg"
				],
				"denglong": [
					"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/01.gif"
				],
				"aixintiao": [
					"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/00.gif"
				],
				"decorate": {
					"yuanup": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/shanshuo/09.png",
							"http://resali.lititutu.cn/icons/shanshuo/01.png",
						]
					},
					"denglong": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/shanshuo/09.png",
							"http://resali.lititutu.cn/icons/shanshuo/00.png",
						]
					},
					"xuandong": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/shanshuo/09.png",
							"http://resali.lititutu.cn/icons/shanshuo/00.png",
						]
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"baihuaqifang": {
			"type": "full",
			"tid": "baihuaqifang",
			"mid": "f6848f384c90b286885679e76f2fa72d",
			"name": "百花齐放3D",
			"mode": {
				"timeline": [
					"0,scene,fangkuai",
					"8,inter,zhuanzuoxiayoushang",
					"10,scene,xinbox",
					"18,inter,zhuanyoushangzuoxia",
					"20,scene,xuandong",
					"28,inter,zhuanzuoshangyouxia",
					"30,scene,fanzhuan",
					"38,inter,zhuanyouxiazuoshang",
					"40,end"
				],
				"inter": {
					"zhuanzuoxiayoushang": ["http://resali.lititutu.cn/icons/hua/04.png"],
					"zhuanyoushangzuoxia": ["http://resali.lititutu.cn/icons/hua/04.png"],
					"zhuanzuoshangyouxia": ["http://resali.lititutu.cn/icons/hua/04.png"],
					"zhuanyouxiazuoshang": ["http://resali.lititutu.cn/icons/hua/04.png"]
				},
				"fanzhuan": [
					"http://resali.lititutu.cn/scenes/baihuaqifang/02.gif"
				],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/7175946/images/19fa14e01e1a276a881d7df63f2acf8a.png",
					"http://litiresource.xcgogo.site/user/7175946/images/e382a0d087b260939f735586ba67d64f.png",
					"http://resali.lititutu.cn/scenes/baihuaqifang/03.gif"
				],
				"xinbox": [
					"http://resali.lititutu.cn/scenes/baihuaqifang/04.gif"
				],
				"fangkuai": {
					"room": {
						"top": {
							"gif": {
								"type": "gif",
								"duration": 2.5,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*20,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/baihuaqifang/01.jpg",
									"steps": 20
								},
								"cover": "http://resali.lititutu.cn/scenes/baihuaqifang/000.jpg"
							}
						},
						"bottom": {
							"gif": {
								"type": "gif",
								"duration": 2.5,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*20,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/baihuaqifang/00.jpg",
									"steps": 20
								},
								"cover": "http://resali.lititutu.cn/scenes/baihuaqifang/000.jpg"
							}
						}
					},
					"room2": {
						"piao": [
							"http://resali.lititutu.cn/icons/other/17.png"
						],
						"huabanshan": {
							"gradient": ["#bcbec0", "#dd657d", "#DC143C"]
						}
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"kangyitixing": {
			"type": "full",
			"tid": "kangyitixing",
			"mid": "525a511211b867c00753695f862e2585",
			"name": "抗击疫情提醒",
			"mode": {
				"timeline": [
					"0,scene,fangkuai",
					"10,scene,qianyidong",
					"20,scene,aixintiao",
					"30,scene,shuibo",
					"40,end"
				],
				"shuibo": [
					"http://resali.lititutu.cn/scenes/kangyitixing/04.jpg"
				],
				"aixintiao": [
					"http://resali.lititutu.cn/scenes/kangyitixing/03.jpg"
				],
				"qianyidong": [
					"http://resali.lititutu.cn/scenes/kangyitixing/02.jpg"
				],
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/kangyitixing/01.jpg",
					"http://resali.lititutu.cn/scenes/kangyitixing/00.jpg",
				],
				"decorate": {
					"shuibo": {
						"huojianslow": ["http://resali.lititutu.cn/icons/other/02.png"]
					},
					"qianyidong": {
						"huojianslow": ["http://resali.lititutu.cn/icons/other/02.png"]
					},
					"aixintiao": {
						"huojianslow": ["http://resali.lititutu.cn/icons/other/17.png"]
					}
				}
			}
		},
		"wuhanjiayou": {
			"type": "full",
			"tid": "wuhanjiayou",
			"mid": "b05ab92541174f10768ff61da0711c54",
			"name": "武汉加油",
			"mode": {
				"timeline": [
					"0,scene,fenshen",
					"10,scene,yuanup",
					"20,scene,denglong",
					"30,scene,zhuanyuan",
					"40,end"
				],
				"zhuanyuan": [
					"http://resali.lititutu.cn/scenes/wuhanjiayou/04.jpg",
					"http://resali.lititutu.cn/scenes/wuhanjiayou/05.png"
				],
				"yuanup": [
					"http://resali.lititutu.cn/scenes/wuhanjiayou/01.jpg",
					"http://resali.lititutu.cn/scenes/wuhanjiayou/02.jpg"
				],
				"fenshen": [
					"http://resali.lititutu.cn/scenes/wuhanjiayou/00.jpg",
					"http://resali.lititutu.cn/icons/border/00.png",
					{
						"type": "gif",
						"duration": 1.5,
						"width": 264,
						"height": 125,
						"images": [
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/1.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/2.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/3.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/4.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/5.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/6.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/7.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/8.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/9.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/10.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/11.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/12.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/13.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/14.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/15.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/16.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/17.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/18.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/19.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/20.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/21.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/22.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/23.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/24.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/25.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/26.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/27.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/28.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/29.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/30.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/31.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/32.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/33.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/34.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/35.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/36.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/37.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/38.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/39.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/40.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/41.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/42.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/43.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/44.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/45.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/46.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/47.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/48.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/49.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/50.jpg'
						]
					}
				],
				"denglong": [
					"http://resali.lititutu.cn/scenes/wuhanjiayou/03.jpg"
				],
				"decorate": {
					"fenshen": {
						"blink": [
							"http://resali.lititutu.cn/icons/shanshuo/12.png"
						]
					},
					"zhuanyuan": {
						"blink": [
							"http://resali.lititutu.cn/icons/shanshuo/12.png"
						]
					},
					"yuanup": {
						"huojian": [
							"http://resali.lititutu.cn/icons/shanshuo/13.png",
							"http://resali.lititutu.cn/icons/shanshuo/14.png",
							"http://resali.lititutu.cn/icons/shanshuo/16.png",
						]
					},
					"denglong": {
						"huojian": [
							"http://resali.lititutu.cn/icons/shanshuo/05.png",
							"http://resali.lititutu.cn/icons/shanshuo/06.png",
							"http://resali.lititutu.cn/icons/shanshuo/07.png",
							"http://resali.lititutu.cn/icons/shanshuo/08.png",
						]
					},
				}
			}
		},
		"chuntianlaile": {
			"type": "full",
			"tid": "chuntianlaile",
			"mid": "dfd9dac0fa3c8be3d11569819f7da665",
			"name": "春天来了3D",
			"mode": {
				"timeline": [
					"0,scene,fangkuai",
					"10,inter,zhuanzuoxiayoushang",
					"12,scene,xuandong",
					"22,inter,zhuanyoushangzuoxia",
					"24,scene,zhuanyuan",
					"34,inter,zhuanzuoshangyouxia",
					"36,scene,baozha",
					"46,inter,zhuanyouxiazuoshang",
					"48,end"
				],
				"baozha": [
					"http://resali.lititutu.cn/scenes/chuntianlaile/02.gif"
				],
				"zhuanyuan": [
					"http://resali.lititutu.cn/scenes/chuntianlaile/04.gif",
					"http://resali.lititutu.cn/icons/huahuan/00.png"
				],
				"xuandong": [
					"http://resali.lititutu.cn/scenes/chuntianlaile/05.png",
					"http://resali.lititutu.cn/scenes/chuntianlaile/06.png",
					"http://resali.lititutu.cn/scenes/chuntianlaile/03.gif",
				],
				"fangkuai": {
					"room": {
						"top": {
							"gif": {
								"type": "gif",
								"duration": 2,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*25,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/chuntianlaile/00.jpg",
									"steps": 25
								},
								"cover": "http://resali.lititutu.cn/scenes/chuntianlaile/000.jpg"
							}
						},
						"bottom": {
							"gif": {
								"type": "gif",
								"duration": 2,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*25,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/chuntianlaile/01.jpg",
									"steps": 25
								},
								"cover": "http://resali.lititutu.cn/scenes/chuntianlaile/000.jpg"
							}
						}
					}
				},
				"inter": {
					"zhuanzuoxiayoushang": ["http://resali.lititutu.cn/icons/hua/03.png"],
					"zhuanyoushangzuoxia": ["http://resali.lititutu.cn/icons/hua/03.png"],
					"zhuanzuoshangyouxia": ["http://resali.lititutu.cn/icons/hua/03.png"],
					"zhuanyouxiazuoshang": ["http://resali.lititutu.cn/icons/hua/03.png"]
				},
				"decorate": {
					"fangkuai": {
						"luo2": [
							"http://resali.lititutu.cn/icons/hua/01.png",
							"http://resali.lititutu.cn/icons/hua/02.png"
						]
					},
					"xuandong": {
						"luo2": [
							"http://resali.lititutu.cn/icons/hua/01.png",
							"http://resali.lititutu.cn/icons/hua/02.png"
						]
					},
					"baozha": {
						"luo2": [
							"http://resali.lititutu.cn/icons/hua/01.png",
							"http://resali.lititutu.cn/icons/hua/02.png"
						]
					}
				},
			}
		},
		"weixiaoshenghuo": {
			"type": "full",
			"tid": "weixiaoshenghuo",
			"mid": "9eca978d1a34843b24703da4e0b477ba",
			"name": "微笑生活（人声）",
			"mode": {
				"timeline": [
					"0,scene,chuang",
					"10,scene,zhuanxin",
					"20,scene,xuandong",
					"30,end"
				],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/5980171/images/f6d4d15b3f182ca946514f813501053a.png",
					"http://litiresource.xcgogo.site/user/5980171/images/900cc4837847332a5cebc02a453897a4.png",
					"http://resali.lititutu.cn/scenes/weixiaoshenghuo/01.gif"
				],
				"zhuanxin": [
					"http://resali.lititutu.cn/scenes/weixiaoshenghuo/02.gif",
				],
				"chuang": [
					'http://resali.lititutu.cn/scenes/weixiaoshenghuo/00.gif',
				],
				"decorate": {
					"xuandong": {
						"light": 1
					},
					"zhuanxin": {
						"luo": ["http://resali.lititutu.cn/scenes/mantianxincai/09.png"],
						"firefly": [
							"http://resali.lititutu.cn/icons/shanshuo/10.png",
							"http://resali.lititutu.cn/icons/shanshuo/11.png"
						]
					},
				}
			}
		},
		"mantianxincai": {
			"type": "full",
			"tid": "mantianxincai",
			"mid": "0f2f1016620e063eede3cbf94b8c2df4",
			"name": "漫天星彩",
			"mode": {
				"timeline": [
					"0,scene,fangkuai_0",
					"10,scene,zhuanxin",
					"20,scene,fangkuai_1",
					"30,scene,baozha",
					"40,end"
				],
				// "scene": ["zhuanxin", "fangkuai_0", "fangkuai_1"],
				"baozha": [
					"http://resali.lititutu.cn/scenes/mantianxincai/08.gif"
				],
				"zhuanxin": [
					"http://resali.lititutu.cn/scenes/mantianxincai/07.gif"
				],
				"fangkuai_0": {
					"room": {
						"top": {
							"gif": {
								"type": "gif",
								"duration": 4,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*34,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/mantianxincai/04.jpg",
									"steps": 34
								},
								"cover": "http://resali.lititutu.cn/scenes/mantianxincai/040.jpg"
							}
						},
						"bottom": {
							"gif": {
								"type": "gif",
								"duration": 4,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*34,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/mantianxincai/04.jpg",
									"steps": 34
								},
								"cover": "http://resali.lititutu.cn/scenes/mantianxincai/040.jpg"
							}
						}
					}
				},
				"fangkuai_1": {
					"room": {
						"top": {
							"gif": {
								"type": "gif",
								"duration": 4,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*32,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/mantianxincai/05.jpg",
									"steps": 32
								},
								"cover": "http://resali.lititutu.cn/scenes/mantianxincai/050.jpg"
							}
						},
						"bottom": {
							"gif": {
								"type": "gif",
								"duration": 4,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*30,
								"sprite_image": {
									"im": "http://resali.lititutu.cn/scenes/mantianxincai/06.jpg",
									"steps": 30
								},
								"cover": "http://resali.lititutu.cn/scenes/mantianxincai/060.jpg"
							},
							"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						}
					}	
				},
				"decorate": {
					"zhuanxin": {
						"luo": ["http://resali.lititutu.cn/scenes/mantianxincai/09.png"],
						"firefly": [
							"http://resali.lititutu.cn/icons/shanshuo/10.png",
							"http://resali.lititutu.cn/icons/shanshuo/11.png"
						]
					},
					"baozha": {
						"luo": ["http://resali.lititutu.cn/scenes/mantianxincai/09.png"],
						"firefly": [
							"http://resali.lititutu.cn/icons/shanshuo/10.png",
							"http://resali.lititutu.cn/icons/shanshuo/11.png"
						]
					}
				}
			}
		},
		"zuixuanguangchangwu": {
			"type": "full",
			"tid": "zuixuanguangchangwu",
			"mid": "b66fdd1641a849f682722c8f2125e681",
			"name": "最炫广场舞",
			"mode": {
				"timeline": [
					"0,scene,xuandong",
					"12,scene,luoxuan",
					"24,scene,yuanup",
					"36,scene,fangkuai",
					"48,end"
				],
				"fangkuai": {
					"room": {
						"top": {
							"gif": {
				        		"type": "gif",
					        	"duration": 2,
					        	"width": bw,
					        	"height": bw,
					        	"backgroundWidth": bw,
					        	"backgroundHeight": bw*20,
					        	"sprite_image": {
					        		"im": "http://resali.lititutu.cn/scenes/zuixuanguangchangwu/06.jpg",
					        		"steps": 20
					        	},
					        	"cover": "http://resali.lititutu.cn/scenes/zuixuanguangchangwu/05.jpg"
				        	}
						},
						"bottom": {
							"im": "http://resali.lititutu.cn/scenes/zuixuanguangchangwu/05.jpg",
				        	"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						},
						"piao2": [
							"http://resali.lititutu.cn/icons/other/05.png",
							"http://resali.lititutu.cn/icons/other/06.png",
							"http://resali.lititutu.cn/icons/other/07.png",
							"http://resali.lititutu.cn/icons/other/08.png",
							"http://resali.lititutu.cn/icons/other/09.png",
						]
					}
				},
				"yuanup": [
					"http://resali.lititutu.cn/scenes/zuixuanguangchangwu/04.gif",
					"http://resali.lititutu.cn/scenes/zuixuanguangchangwu/03.jpg",
				],
				"luoxuan": [
					"http://resali.lititutu.cn/scenes/zuixuanguangchangwu/01.gif"
				],
				"xuandong": [
					"http://resali.lititutu.cn/scenes/zuixuanguangchangwu/00.png",
					"http://resali.lititutu.cn/scenes/zuixuanguangchangwu/01.png",
					"http://resali.lititutu.cn/scenes/zuixuanguangchangwu/00.gif"
				],
				"decorate": {
					"xuandong": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/other/05.png",
							"http://resali.lititutu.cn/icons/other/06.png",
							"http://resali.lititutu.cn/icons/other/07.png",
							"http://resali.lititutu.cn/icons/other/08.png",
							"http://resali.lititutu.cn/icons/other/09.png",
						]
					},
					"luoxuan": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/other/05.png",
							"http://resali.lititutu.cn/icons/other/06.png",
							"http://resali.lititutu.cn/icons/other/07.png",
							"http://resali.lititutu.cn/icons/other/08.png",
							"http://resali.lititutu.cn/icons/other/09.png",
						]
					},
					"yuanup": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/other/05.png",
							"http://resali.lititutu.cn/icons/other/06.png",
							"http://resali.lititutu.cn/icons/other/07.png",
							"http://resali.lititutu.cn/icons/other/08.png",
							"http://resali.lititutu.cn/icons/other/09.png",
						]
					}
				}
			}
		},
		"xinnianyouyu": {
			"type": "full",
			"tid": "xinnianyouyu",
			"mid": "f300ab83242391a08c0d499272b0bd65",
			"name": "新年年年有余3D",
			"mode": {
				"timeline": [
					"0,scene,luoxuan",
					"10,inter,piaozuoxiayoushang",
					"12,scene,fangkuai",
					"22,inter,piaoyouxiazuoshang",
					"24,scene,nianlun",
					"34,inter,piaozuoxiayoushang",
					"36,scene,pingbox",
					"46,inter,piaoyouxiazuoshang",
					"48,end"
				],
				"luoxuan": [
					"http://resali.lititutu.cn/scenes/xinnianyouyu/20.jpg",
				],
				"nianlun": [
					"http://resali.lititutu.cn/scenes/gonghexinnian/02.gif",
					"http://resali.lititutu.cn/scenes/xinnianyouyu/16.png",
					"http://resali.lititutu.cn/scenes/xinnianyouyu/17.png",
					"http://resali.lititutu.cn/scenes/xinnianyouyu/18.png"
				],
				"pingbox": [
					"http://resali.lititutu.cn/scenes/xinnianyouyu/04.jpg",
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/06.png",
					[
						'http://resali.lititutu.cn/scenes/xinnianyouyu/05.png',
						'http://resali.lititutu.cn/scenes/xinnianyouyu/06.png',
						'http://resali.lititutu.cn/scenes/xinnianyouyu/07.png',
						'http://resali.lititutu.cn/scenes/xinnianyouyu/08.png',
					]
				],
				"fangkuai": {
					"room": {
						"top": {
							"gif": {
				        		"type": "gif",
					        	"duration": 2.5,
					        	"width": bw,
					        	"height": bw,
					        	"backgroundWidth": bw,
					        	"backgroundHeight": bw*20,
					        	"sprite_image": {
					        		"im": "http://resali.lititutu.cn/scenes/xinnianyouyu/00.jpg",
					        		"steps": 20
					        	},
				        	}
						},
						"bottom": {
							"im": "http://resali.lititutu.cn/scenes/xinnianyouyu/01.jpg",
							"elems": [
								{
									"im": "http://resali.lititutu.cn/scenes/xinnianyouyu/02.png",
									"pos": [0, 0],
									"size": [500, 500]
								}
							]
						}
					},
					"room2": {
						"huaban": ["http://resali.lititutu.cn/scenes/xinniandaji/02.png"],
						"elems": [
							{
								"im": "http://resali.lititutu.cn/scenes/xinnianyouyu/03.png",
								"pos": [100, 190, 0],
								"size": [300, 310]
							}
						]
					}
				},
				"decorate": {
					"nianlun": {
						"huojianslow": [
							"http://resali.lititutu.cn/scenes/xinnianyouyu/19.png"
						]
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"xinniancaiyuangungun": {
			"type": "full",
			"tid": "xinniancaiyuangungun",
			"mid": "gongxifacai",
			"name": "新年财源滚滚3D",
			"mode": {
				"timeline": [
					"0,scene,pingbox",
					"10,inter,zhuanzuoxiayoushang",
					"12,scene,zuoyidong",
					"22,inter,zhuanyoushangzuoxia",
					"24,scene,aixintiao",
					"34,inter,zhuanzuoshangyouxia",
					"36,scene,xuandong",
					"46,inter,zhuanyouxiazuoshang",
					"48,end"
				],
				"inter": {
					"zhuanzuoxiayoushang": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanyoushangzuoxia": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanzuoshangyouxia": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanyouxiazuoshang": ["http://resali.lititutu.cn/icons/hua/00.png"]
				},
				"aixintiao": [
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/01.gif"
				],
				"pingbox": [
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/05.jpg",
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/06.png",
					[
						'http://resali.lititutu.cn/scenes/xinniancaiyuangungun/061.png',
						'http://resali.lititutu.cn/scenes/xinniancaiyuangungun/062.png',
						'http://resali.lititutu.cn/scenes/xinniancaiyuangungun/063.png',
					]
				],
				"zuoyidong": [
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/02.gif",
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/03.png",
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/04.gif"
				],
				"xuandong": [
					"http://resali.lititutu.cn/scenes/xinnianbainian/03.png",
					"http://resali.lititutu.cn/scenes/xinnianbainian/00.png",
					"http://resali.lititutu.cn/scenes/xinniancaiyuangungun/00.gif"
				],
				"decorate": {
					"xuandong": {
						"luo2": [
							"http://resali.lititutu.cn/icons/other/04.png"
						]
					},
					"zuoyidong": {
						"luo2": [
							"http://resali.lititutu.cn/icons/shanshuo/09.png"
						]
					},
					"aixintiao": {
						"luo2": [
							"http://resali.lititutu.cn/icons/shanshuo/01.png",
							"http://resali.lititutu.cn/icons/shanshuo/03.png",
						]
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"xinnianbainian": {
			"type": "full",
			"tid": "xinnianbainian",
			"mid": "ed0da9ba6549fa88c198e1c06242851c",
			"name": "给您拜年（人声）",
			"mode": {
				"timeline": [
					"0,scene,xuandong",
					"10,inter,zhuanzuoyou",
					"12,scene,zuoyidong",
					"22,inter,zhuanyouzuo",
					"24,scene,zhuanbox",
					"34,inter,zhuanzuoyou",
					"36,scene,fangkuai",
					"46,inter,zhuanyouzuo",
					"48,end"
				],
				"fangkuai": {
					"room": {
						"top": {
							"gif": {
				        		"type": "gif",
					        	"duration": 1.5,
					        	"width": bw,
					        	"height": bw,
					        	"backgroundWidth": bw,
					        	"backgroundHeight": bw*19,
					        	"sprite_image": {
					        		"im": "http://resali.lititutu.cn/scenes/xinnianbainian/09.jpg",
					        		"steps": 19
					        	},
				        	}
						},
						"bottom": {
							"gif": {
				        		"type": "gif",
					        	"duration": 1.5,
					        	"width": bw,
					        	"height": bw,
					        	"backgroundWidth": bw,
					        	"backgroundHeight": bw*12,
					        	"sprite_image": {
					        		"im": "http://resali.lititutu.cn/scenes/xinnianbainian/10.jpg",
					        		"steps": 12
					        	},
				        	},
				        	"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						},
						"piao": ["http://resali.lititutu.cn/icons/other/02.png"]
					}
				},
				"zhuanbox": [
					"http://resali.lititutu.cn/scenes/xinnianbainian/081.gif",
					"http://resali.lititutu.cn/scenes/xinnianbainian/07.png",
				],
				"zuoyidong": [
					"http://resali.lititutu.cn/scenes/xinnianbainian/05.gif",
					"http://resali.lititutu.cn/scenes/xinnianbainian/04.png",
					"http://resali.lititutu.cn/scenes/xinnianbainian/061.gif"
				],
				"xuandong": [
					"http://resali.lititutu.cn/scenes/xinnianbainian/03.png",
					"http://resali.lititutu.cn/scenes/xinnianbainian/00.png",
					"http://resali.lititutu.cn/scenes/xinnianbainian/021.gif"
				],
				"decorate": {
					"xuandong": {
						"huojian": [
							"http://resali.lititutu.cn/icons/shanshuo/05.png",
							"http://resali.lititutu.cn/icons/shanshuo/06.png",
							"http://resali.lititutu.cn/icons/shanshuo/07.png",
							"http://resali.lititutu.cn/icons/shanshuo/08.png",
						]
					},
					"zuoyidong": {
						"huojian": [
							"http://resali.lititutu.cn/icons/shanshuo/05.png",
							"http://resali.lititutu.cn/icons/shanshuo/06.png",
							"http://resali.lititutu.cn/icons/shanshuo/07.png",
							"http://resali.lititutu.cn/icons/shanshuo/08.png",
						]
					},
					"zhuanbox": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/shanshuo/00.png",
							"http://resali.lititutu.cn/icons/shanshuo/01.png",
							"http://resali.lititutu.cn/icons/shanshuo/02.png",
							"http://resali.lititutu.cn/icons/shanshuo/03.png",
						]
					},
					"fangkuai": {

					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"xinnianhejiahuan": {
			"type": "full",
			"tid": "xinnianhejiahuan",
			"mid": "haoyunlai",
			"name": "新年合家欢3D",
			"mode": {
				"timeline": [
					"0,scene,zhuanyuan",
					"10,inter,zhuanzuoxiayoushang",
					"12,scene,yuanup",
					"22,inter,zhuanyoushangzuoxia",
					"24,scene,qianyidong",
					"34,inter,zhuanzuoshangyouxia",
					"36,scene,fenshen",
					"46,inter,zhuanyouxiazuoshang",
					"48,end"
				],
				"inter": {
					"zhuanzuoxiayoushang": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanyoushangzuoxia": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanzuoshangyouxia": ["http://resali.lititutu.cn/icons/hua/00.png"],
					"zhuanyouxiazuoshang": ["http://resali.lititutu.cn/icons/hua/00.png"]
				},
				"fenshen": [
					"http://resali.lititutu.cn/scenes/xinnianhejiahuan/04.gif",
					"http://resali.lititutu.cn/icons/border/00.png",
					{
						"type": "gif",
						"duration": 1.5,
						"width": 264,
						"height": 125,
						"images": [
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/1.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/2.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/3.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/4.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/5.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/6.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/7.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/8.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/9.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/10.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/11.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/12.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/13.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/14.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/15.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/16.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/17.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/18.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/19.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/20.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/21.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/22.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/23.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/24.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/25.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/26.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/27.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/28.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/29.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/30.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/31.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/32.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/33.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/34.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/35.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/36.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/37.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/38.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/39.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/40.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/41.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/42.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/43.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/44.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/45.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/46.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/47.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/48.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/49.jpg',
							'http://resali.lititutu.cn/scenes/xinnianhejiahuan/06/50.jpg'
						]
					}
				],
				"qianyidong": [
					"http://resali.lititutu.cn/scenes/xinnianhejiahuan/03.jpg"
				],
				"yuanup": [
					"http://resali.lititutu.cn/scenes/xinnianhejiahuan/01.jpg",
					"http://resali.lititutu.cn/scenes/xinnianhejiahuan/02.jpg"
				],
				"zhuanyuan": [
					'http://resali.lititutu.cn/scenes/xinnianhejiahuan/00.jpg',
					'http://resali.lititutu.cn/icons/huahuan/00.png'
				],
				"decorate": {
					"zhuanyuan": {
						"blink": [
							"http://resali.lititutu.cn/icons/shanshuo/12.png"
						]
					},
					"yuanup": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/shanshuo/00.png",
							"http://resali.lititutu.cn/icons/shanshuo/01.png",
							"http://resali.lititutu.cn/icons/shanshuo/02.png",
							"http://resali.lititutu.cn/icons/shanshuo/03.png",
							"http://resali.lititutu.cn/icons/other/00.png",
						]
					},
					"fenshen": {
						"sheng": [
							"http://resali.lititutu.cn/icons/other/01.png"
						]
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"gonghexinnian": {
			"type": "full",
			"tid": "gonghexinnian",
			"mid": "bfbd446a652b2cd137abba232417f6d6",
			"name": "恭贺新年3D",
			"mode": {
				// "timeline": [
				// 	"0,scene,yuanup",
				// 	"100,scene,yuanup",
				// 	"200,end"
				// ],
				"scene": ["yuanup", "fangkuai", "aixintiao", "xuandong"],
				"xuandong": [
					"http://resali.lititutu.cn/scenes/gonghexinnian/05.png",
					"http://resali.lititutu.cn/scenes/gonghexinnian/04.png",
					"http://resali.lititutu.cn/scenes/gonghexinnian/03.jpg",
				],
				"aixintiao": [
					"http://resali.lititutu.cn/scenes/gonghexinnian/02.gif"
				],
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/gonghexinnian/00.jpg",
					"http://resali.lititutu.cn/scenes/gonghexinnian/01.jpg"
				],
				"yuanup": [
					"http://resali.lititutu.cn/scenes/gonghexinnian/08.jpg",
					"http://resali.lititutu.cn/scenes/xinnianwenhou/06.png"
				],
				"decorate": {
					"aixintiao": {
						"flow": "http://resali.lititutu.cn/scenes/gonghexinnian/07.png"
					},
					"fangkuai": {
						"piao": ["http://resali.lititutu.cn/scenes/gonghexinnian/denglong.png"]
					},
					"yuanup": {
						"huojian": [
							'http://resali.lititutu.cn/decorate/huojian/huojian2.png',
							'http://resali.lititutu.cn/decorate/huojian/huojian3.png'
						]
					},
					"xuandong": {
						"huojian": [
							'http://resali.lititutu.cn/scenes/gonghexinnian/06.png',
							'http://resali.lititutu.cn/decorate/huojian/huojian3.png',
						]
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		// "xinnianxiyangyang": {
		// 	"type": "full",
		// 	"tid": "xinnianxiyangyang",
		// 	"mid": "48ff0672c0d04fcfcea34b627906fffc",
		// 	"name": "新年喜洋洋3D",
		// 	"mode": {
		// 		"scene": ["fangkuai", "fangkuai"],
		// 		"fangkuai": {
		// 	    	"room": {
		// 	    		"top": {
		// 		        	"gif": {
		// 		        		"type": "gif",
		// 			        	"duration": 2.5,
		// 			        	"width": bw,
		// 			        	"height": bw,
		// 			        	"backgroundWidth": bw,
		// 			        	"backgroundHeight": bw*22,
		// 			        	"sprite_image": {
		// 			        		"im": "http://resali.lititutu.cn/scenes/xinnianxiyangyang/sprite2.jpg",
		// 			        		"steps": 22
		// 			        	},
		// 			        	"cover": "http://resali.lititutu.cn/scenes/xinnianxiyangyang/bg-j-1_1.jpg"
		// 		        	}
		// 		        },
		// 		        "bottom": {
		// 		        	"gif": {
		// 		        		"type": "gif",
		// 						"duration": 2.5,
		// 						"width": bw,
		// 						"height": bw,
		// 						"backgroundWidth": bw,
		// 						"backgroundHeight": bw*23,
		// 						"sprite_image": {
		// 						"im": "http://resali.lititutu.cn/scenes/xinnianxiyangyang/01.jpg",
		// 						"steps": 23
		// 						},
		// 						"cover": "http://resali.lititutu.cn/scenes/xinnianxiyangyang/bg-j-2_1.jpg"
		// 		        	}
		// 		        }
		// 	    	}
		// 	    }
		// 	},
		// 	"prelude": {
		// 		"textcolor": "#d0353b"
		// 	}
		// },
		"xinnianwenhou": {
			"type": "full",
			"tid": "xinnianwenhou",
			"mid": "f6be68dbf564f74344b0c73c9e2ef6de",
			"name": "新年问候3D",
			"mode": {
				"timeline": [
					"0,scene,lifangzhuan",
					"8,inter,denglongshu",
					"10,scene,changlang",
					"18,inter,denglongheng",
					"20,scene,tuoyuan",
					"28,inter,denglongshu",
					"30,scene,huaquanzhuan",
					"38,inter,denglongheng",
					"40,end"
				],
				"tuoyuan": [
					"http://resali.lititutu.cn/scenes/xinnianwenhou/06.png"
				],
				"lifangzhuan": [
					"http://resali.lititutu.cn/scenes/xinnianwenhou/04.jpg"
				],
				"huaquanzhuan": [
					'http://resali.lititutu.cn/scenes/xinnianwenhou/00.gif'
				],
				"changlang": [
					// 'http://resali.lititutu.cn/scenes/xinnianwenhou/02/0.jpg',
					{
						'type': 'gif',
						'duration': 2,
						'width': bw,
						'height': bw,
						'backgroundWidth': bw,
						'backgroundHeight': bw * 9,
						"sprite_image": {
							"im": "http://resali.lititutu.cn/scenes/xinnianwenhou/07.jpg",
							"steps": 9
						}
						// 'zIndex': 9,
						// 'width': 500,
						// 'height': 500,
						// 'images': [
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/0.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/1.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/2.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/3.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/4.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/5.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/6.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/7.jpg',
						// 	'http://resali.lititutu.cn/scenes/xinnianwenhou/02/8.jpg',
						// ]
					},
					{
						'type': 'gif',
						'duration': 2,
						'width': bw,
						'height': bw,
						'backgroundWidth': bw,
						'backgroundHeight': bw * 14,
						'sprite_image': {
							'im': 'http://resali.lititutu.cn/scenes/xinnianwenhou/08.jpg',
							'steps': 14
						}
					}
					// 'http://resali.lititutu.cn/scenes/xinnianwenhou/01/0.jpg',
					// {
					// 	'type': 'gif',
					// 	'duration': 2,
					// 	// 'zIndex': 9,
					// 	'width': 500,
					// 	'height': 500,
					// 	'images': [
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/0.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/1.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/2.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/3.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/4.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/5.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/6.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/7.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/8.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/9.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/10.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/11.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/12.jpg',
					// 		'http://resali.lititutu.cn/scenes/xinnianwenhou/01/13.jpg',
					// 	]
					// }
				],
				"decorate": {
					"lifangzhuan": {
						"light": 1,
						"luopic": [
							"http://resali.lititutu.cn/icons/shanshuo/17.png",
							"http://resali.lititutu.cn/icons/shanshuo/18.png"
						]
					},
					// "changlang": {},
					"tuoyuan": {
						"flow": [
							"http://resali.lititutu.cn/scenes/xinniandaji/02.png"
						]
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"exhi_xinnian": {
			"type": "exhi",
			"tid": "exhi_xinnian",
			"mid": "48ff0672c0d04fcfcea34b627906fffc",
			"name": "新年画廊",
			"bg": "http://resali.lititutu.cn/scenes/exhi_xinnian/01.jpg",
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"yuandankuaile": {
			"type": "full",
			"tid": "yuandankuaile",
			"mid": "shengshengzhufuheyuandan",
			"name": "贺岁新年",
			"mode": {
				"timeline": [
					'0,scene,fangkuai',
					'6,inter,denglongheng',
					'8,scene,tiandi',
					'14,inter,denglongshu',
					'16,scene,chuang',
					'22,inter,denglongheng',
					'24,scene,lingxing',
					'30,inter,denglongshu',
					'32,end'
				],
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/yuandankuaile/02.jpg",
					"http://resali.lititutu.cn/scenes/yuandankuaile/01.jpg", 
				],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/dbce35e0e245bf6b6c3d34f744cd2505.jpg"],
				// "scene": ["fangkuai", "tiandi", "yuantong", "lingxing"],
				"lingxing": ["http://resali.lititutu.cn/scenes/yuandankuaile/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/17abdf51e0bd9e293abaf9f230356749.png"],
				"chuang": [
					"http://resali.lititutu.cn/scenes/yuandankuaile/04.jpg"
				],
				// "yuantong": ["http://resali.lititutu.cn/scenes/yuandankuaile/04.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1723be127e35703046c22e31aa066d9c.png", "http://litiresource.xcgogo.site/user/5980171/images/dcf429ff5e6071bb34c2efe66b446ab0.png"],
				"music": "shengshengzhufuheyuandan"
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"n_yuandankuaile": {
			"type": "normal",
			"tid": "n_yuandankuaile",
			"mid": "f6be68dbf564f74344b0c73c9e2ef6de",
			"name": "新年快乐"
		},
		"dongzhikuaile3d": {
			"type": "simple3d",
			"tid": "dongzhikuaile3d",
			"mid": "ageamei",
			"name": "冬至快乐3D",
			"mode": {
				"bg": {
					"im": "http://litiresource.xcgogo.site/user/6403275/images/751fc178aa903542108e705c6a3b6cbf.jpg"
				},
				"decorate": {
					"gif": [
						{
							'duration': 5,
							'zIndex': 9,
							// 'width': 720,
							// 'height': 1600,
							'images': [
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/1.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/2.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/3.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/4.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/5.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/6.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/7.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/8.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/9.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/10.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/11.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/12.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/13.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/14.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/15.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/16.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/17.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/18.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/19.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/20.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/21.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/22.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/23.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/24.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/25.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/26.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/27.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/28.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/29.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/30.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/31.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/32.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/33.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/34.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/35.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/36.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/37.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/38.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/39.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/40.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/41.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/42.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/43.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/44.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/45.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/46.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/47.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/48.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/49.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/50.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/51.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/52.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/53.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/54.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/55.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/56.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/57.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/58.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/59.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/60.png",
							]
						}
					]
				},
				"elems": [
					{
						"im": "http://litiresource.xcgogo.site/user/6403275/images/1ce6381cb679b9054958388662a724f9.png",
						"pos": [null, null, 0, 0],
						"size": [500, 266]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/6403275/images/16c5bbfb1f7c62b06755c3c1682842d2.png",
						"pos": [null, null, 60, 19],
						"size": [104, 199]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/6403275/images/64dadbc125725ac54da19c9a45c37bbc.png",
						"pos": [0, null, null, 230],
						"size": [493, 187],
						"style": {
							animation: "shuzhi 3s ease-in-out infinite alternate both"
						}
					}
				]
			}
		},
		"baihuazhengyan3d": {
			"type": "full",
			"tid": "baihuazhengyan3d",
			"mid": "7dbec5f62379504e077224e724b8a8d0",
			"name": "百花争艳3D",
			"mode": {
				// "scene": ["fangkuai_0", "fangkuai_1"],
				"timeline": [
					'0,scene,fangkuai_0',
					'14,inter,dahonghuadown',
					'15,scene,guandao',
					'29,inter,dahuanghuaup',
					'30,scene,fanzhuan',
					'44,inter,dahonghuadown',
					'45,scene,manping',
					'59,inter,dahuanghuascale',
					'60,scene,fangkuai_1',
					'74,inter,dahonghuaup',
					'75,end'
				],
				"decorate": {
					"guandao": {
						// "shan": [
						// 	"http://litiresource.xcgogo.site/user/6403275/images/8a8eac6cde3924d1f9248dc9c777f1fd.png"
						// ]
						"luo": [
							"http://litiresource.xcgogo.site/user/6403275/images/3961a109fd21915ec3b595c104e9dd84.png"
						]
					},
					"manping": {
						"luo": [
							"http://litiresource.xcgogo.site/user/6403275/images/487eeea5bd7eaabdd13596c58cb14281.png"
						]
					},
					"fanzhuan": {
						"light": 1
					}
				},
				"fanzhuan": [
					"https://resali.lititutu.cn/scenes/baihuazhengyan3d/7af06f1ca47934de87d153d70bf6079f.jpg",
				],
				"manping": [
					"http://litiresource.xcgogo.site/user/5980171/images/926e0947660045146dc651a01b2a6d7a.jpg"
				],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/87e213ab8aa6dee84a9ca229462005b8.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3c2456058cfe62ab3e0393e551f88e4b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1202b00e09ff8511a80b50c8e570078d.jpg", "http://litiresource.xcgogo.site/user/5980171/images/736c3f73dc2652ff4ae52ff9a7be1011.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/87e213ab8aa6dee84a9ca229462005b8.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3c2456058cfe62ab3e0393e551f88e4b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1202b00e09ff8511a80b50c8e570078d.jpg", "http://litiresource.xcgogo.site/user/5980171/images/736c3f73dc2652ff4ae52ff9a7be1011.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/87e213ab8aa6dee84a9ca229462005b8.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3c2456058cfe62ab3e0393e551f88e4b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1202b00e09ff8511a80b50c8e570078d.jpg", "http://litiresource.xcgogo.site/user/5980171/images/736c3f73dc2652ff4ae52ff9a7be1011.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/87e213ab8aa6dee84a9ca229462005b8.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3c2456058cfe62ab3e0393e551f88e4b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1202b00e09ff8511a80b50c8e570078d.jpg", "http://litiresource.xcgogo.site/user/5980171/images/736c3f73dc2652ff4ae52ff9a7be1011.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/15a80397e8adbf1b0318187c8a3dedcd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c89d4bce5581d8c371512f1c808b7c79.jpg"],
				],
				"fangkuai_0": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/6403275/images/e6539cb263e8565e8c9232cb988b7235.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/6403275/images/1aad2d3dee3ff6b8020824d4baf0f555.jpg"
						}
					},
					"room2": {
						"huaban": ["http://litiresource.xcgogo.site/user/5980171/images/9ab1152698e6c489c6986314b89b6a79.png"],
						"hudie": [
							"http://litiresource.xcgogo.site/user/6403275/images/46d517a9587b54f6accafbb7a70b9ea3.png",
							"http://litiresource.xcgogo.site/user/6403275/images/b664248d7d204d487f903d0aa1ee73d1.png",
						],
						// "shan": [
						// 	"http://litiresource.xcgogo.site/user/6403275/images/8a8eac6cde3924d1f9248dc9c777f1fd.png"
						// ]
					}
				},
				"fangkuai_1": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/29fcc271171973759c298e7092b29706.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/29fcc271171973759c298e7092b29706.jpg"
						}
					},
					"room2": {
						"huaban": ["http://litiresource.xcgogo.site/user/6403275/images/6057b155d0eea343e0c3f60b87a2575c.png"],
						// "hudie": ["http://litiresource.xcgogo.site/user/6403275/images/b5fcd08e8685475e0258ade2c24daf36.png"],
						"huabanshan": {
							"gradient": ["rgb(188, 190, 192)", "rgb(255, 198, 108)", "rgb(253, 232, 10)"]
						}
					}
				},
				"text": {
					"textShadowColor": "#851630"
				}
			}
		},
		"ganen": {
			"type": "full",
			"tid": "ganen",
			"mid": "1bc33cc030623833d1450cab42de3b25",
			"name": "感恩祝福",
			"mode": {
				"scene": ['fangkuai', 'xuanzhuan', 'manping', "zhuanxin"],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/a4ae9e3af85502f675a6a6fdc63eccfe.jpg"],
				"manping": ["http://litiresource.xcgogo.site/user/5980171/images/8e57bb75e04a94caaf87446b3cd94197.jpg"],
				"zhuanxin": ["http://litiresource.xcgogo.site/user/5980171/images/059775e4c1c22c0113e93be2c4491c93.jpg"],
				"fangkuai": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/e00e43ccec0634584fb6aa9d09160840.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/ede01a9fd8d4609c8c51faccaba40ab1.jpg",
							"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						}
					}
				}
			}
		},
		"dongtai_0": {
			"type": "full",
			"tid": "dongtai_0",
			"mid": "shuilaiaiwo",
			"name": "简约格调",
			"mode": {
				"timeline": [
					'0,scene,dong1',
					'12,scene,dong2',
					'24,scene,dong3',
					'36,scene,dong4',
					'48,scene,dong5',
					'60,scene,dong6',
					'72,scene,dong7',
					'84,scene,dong8',
					'96,end'
				],
			},
			"prelude": {
				"duration": 4,
				"component": "fadeinblur"
			}
		},
		"dongtai_1": {
			"type": "full",
			"tid": "dongtai_1",
			"mid": "meilidecaoyuanwodejiadizi",
			"name": "简约风情",
			"mode": {
				"timeline": [
					'0,scene,dong9',
					'10,scene,dong10',
					'20,scene,dong9',
					'30,scene,dong11',
					'40,end'
				]
			},
			"prelude": {
				"duration": 3,
				"component": "fadeinblur"
			}
		},
		"exhi_meihualei": {
			"type": "exhi",
			"tid": "exhi_meihualei",
			"mid": "1bea99b0c1f47c5fb9388904790c4109",
			"name": "梅花情深",
			"bg": "http://litiresource.xcgogo.site/user/5980171/images/5d7a26d8e64210b07e55a2de4578ed2f.png",
			"border": "http://litiresource.xcgogo.site/user/5980171/images/b2f82dc76c98fd717b1829d2684e5973.png",
			"mode": {
				"elems": [
					{
						"im": "http://litiresource.xcgogo.site/user/5980171/images/86287508c9e7d8ab51a2b5fb540aaafd.png",
						"pos": [0, null, null, 0],
						"size": [180, 163]
					}
				],
				"decorate": {
					"gif": [
						{
							'duration': 5,
							'zIndex': 9,
							// 'width': 720,
							// 'height': 1600,
							'images': [
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/1.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/2.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/3.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/4.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/5.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/6.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/7.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/8.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/9.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/10.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/11.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/12.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/13.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/14.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/15.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/16.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/17.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/18.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/19.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/20.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/21.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/22.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/23.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/24.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/25.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/26.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/27.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/28.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/29.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/30.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/31.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/32.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/33.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/34.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/35.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/36.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/37.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/38.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/39.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/40.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/41.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/42.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/43.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/44.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/45.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/46.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/47.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/48.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/49.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/50.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/51.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/52.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/53.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/54.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/55.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/56.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/57.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/58.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/59.png",
								"http://litiresource.xcgogo.site/theme/dongzhikuaile3d/1/60.png",
							]
						}
					]
				}
			}
		},
		"exhi_zhuanjingtong": {
			"type": "exhi",
			"tid": "exhi_zhuanjingtong",
			"mid": "edac1e40faa2ea75ace66797db080624",
			"name": "祝福转经筒",
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/117a6a81d73826e20eb2515598caa2c5.jpg',
			'thumb': "http://litiresource.xcgogo.site/user/5980171/images/04afd4f05d3fc70e3b62c2a968af847c.jpg",
			'top_h': '35vh',
			'top_w': '40vh',
			'img_h': '425px',
			'img_w': '360px',
			'box': [400, 400]
		},
		"qiufenkuaile": {
			"type": "full",
			"tid": "qiufenkuaile",
			"mid": "edac1e40faa2ea75ace66797db080624",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/a3ca15cbbb362e47eff71db70fbd7467.jpg",
			"name": "秋日祝福",
			"mode": {
				"scene": ["fangkuai", "xiayu", "babianxing", "manping"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/c193000e4f3b238d1131edaa2c3e9a30.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/acf2842d7a966abf9a26836701451e64.jpg",
				],
				"xiayu": [
					"http://litiresource.xcgogo.site/user/5980171/images/088a77ca5aed58cd13e5f085abbbfd29.jpg",
				],
				"babianxing": [
					"http://litiresource.xcgogo.site/user/5980171/images/92043857d405107e2b36eb424c2f37a7.jpg"
				],
				"manping": [
					"http://litiresource.xcgogo.site/user/5980171/images/a5ff6a70079d64c552ff789e6e69f1b9.jpg"
				]
			}
		},
		"chongyangheying": {
			"type": "other",
			"tid": "chongyangheying",
			"mid": "shuilaiaiwo",
			"name": "九九重阳送祝福",
			"api": "humanbodypp",
			"url": "/vliti/main/share?key=7ee653c80af12c061d1da0d7585ace6a&_source=wx552e3843819927e5"
		},
		"guoqingheying": {
			"type": "other",
			"tid": "guoqingheying",
			"mid": "c9cb0b28fc0a8babf5eff6e0ca1011fb",
			"name": "和国旗合影",
			"api": "humanbodypp",
			"url": "/vliti/main/share?key=18d74c0509968eeecec93aff79f3f0bb&_source=wx552e3843819927e5"
		},
		"guoqingbianlian": {
			"type": "other",
			"tid": "guoqingbianlian",
			"mid": "c9cb0b28fc0a8babf5eff6e0ca1011fb",
			"name": "国庆穿越历史",
			"preload": {
				"img": [
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/bird.png",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/cloud.png",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/cloud2.png",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/kaiguodadian.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/liangdanyixing.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/lianheguo.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/gaigekaifang.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/xiangganghuigui.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/jiarushimao.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/shenzhouwuhao.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/aoyun.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/dierdajingjiti.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/guochanhangmu.jpg",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/text.png",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/haihunshan.png?v=3",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/body1.png",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/train.png",
					"http://litiresource.xcgogo.site/theme/guoqingbianlian/head.png",
				]
			}
		},
		"zhongqiubianlian": {
			"type": "other",
			"tid": "zhongqiubianlian",
			"mid": "6dc94b2587098543d15153b2470ee73c",
			"name": "中秋祝福",
			"preload": {
				"img": [
					'http://litiresource.xcgogo.site/user/5980171/images/a36d56d3d3737eb00cc913289cdac98f.jpg',
					'http://litiresource.xcgogo.site/user/5980171/images/d1285184624e2711cc3ab0e4b729721c.jpg',
					'http://litiresource.xcgogo.site/user/5980171/images/4d81391da8ccc2176252b8978a7e9bbe.jpg',
					'http://litiresource.xcgogo.site/user/5980171/images/7b46b35d5da601dc95b876a3e742fcda.png',
					'http://litiresource.xcgogo.site/user/5980171/images/070188cdde742a785ecdb6abf0a76a94.png',
					'http://litiresource.xcgogo.site/user/5980171/images/eea8e17d7d59c3a3ab74d7c3650a8582.png',
					'http://litiresource.xcgogo.site/user/5980171/images/eea8e17d7d59c3a3ab74d7c3650a8582.png',
					'http://litiresource.xcgogo.site/user/5980171/images/4bb8b49406f8706cbf4ea3a7c1cc2d7c.png',
					'http://litiresource.xcgogo.site/user/5980171/images/01c7672edf50bd3a509ddab926375f68.png',
					'http://litiresource.xcgogo.site/user/5980171/images/fcbb1b0705d0a0f4f9f63f2287ff4819.png',
					'http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png',
					'http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png',
					'http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png',
					'http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png',
					'http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png'
				]
			}
		},
		"zhongqiukuaile": {
			"type": "full",
			"tid": "zhongqiukuaile",
			"mid": "d9d915ba35adebfc00562bc5a7ab4a24",
			"thumb": "http://litiresource.xcgogo.site/user/7753395/images/36e5ad350c27523cefaa111e164b5300.jpg",
			"cover": "http://litiresource.xcgogo.site/user/7753395/images/83eaf7cdf87f31f29ab96d24ff4f5856.gif",
			"name": "花容月貌",
			"mode": {
				"scene": ["litifei", "zhuan2", "zhuan3"],
				"zhuan2": [
					"http://litiresource.xcgogo.site/theme/zhongqiu/bg4.jpg",
					"http://litiresource.xcgogo.site/theme/zhongqiu/flower.png",
					"http://litiresource.xcgogo.site/theme/zhongqiu/fly.png"
				],
				"zhuan3": [
					'http://litiresource.xcgogo.site/user/5980171/images/8e0da8381be74387f062cd02c5193c26.jpg',
					'http://litiresource.xcgogo.site/user/5980171/images/a2e79ec2189571022ea95600c7bb60fd.png',
					'http://litiresource.xcgogo.site/user/5980171/images/c26b156d99086bb5104b409b8bd9f311.png',
					'http://litiresource.xcgogo.site/user/5980171/images/03d9895b881f7c814b9dedfab243016a.png',
					'http://litiresource.xcgogo.site/user/5980171/images/1d0c52da7d7ffebc91aa8ccc0829765b.png',
				],
				"litifei": [
					'http://litiresource.xcgogo.site/user/5980171/images/aed12ffb6a8bd916a7ca7d7bec5c5a8a.jpg',
					'http://litiresource.xcgogo.site/user/5980171/images/3481cf9fd8522ba4e9b27d7f0ec71371.png',
					"http://litiresource.xcgogo.site/user/5980171/images/57a4d8142bdfcf6417ef562e0081d613.png",
					'http://litiresource.xcgogo.site/user/5980171/images/5cf0f43e103ab93f8df8359f9849e573.png',
					'http://litiresource.xcgogo.site/user/5980171/images/dbe07bd75c35ade5e9788fdf7330f1f4.png',
					'http://litiresource.xcgogo.site/user/5980171/images/945dd2172576f5adb8060d0c248e24f7.png',
					'http://litiresource.xcgogo.site/user/5980171/images/2abbdbfc3d416e22a8075bfd9d56ffd2.png',
					'http://litiresource.xcgogo.site/user/5980171/images/72f81f7bd9772e562ff1733ff4fc6de3.png',
					'http://litiresource.xcgogo.site/user/5980171/images/1d3755a1192559551fc0688fe11c47f6.png',
				]
			}
		},
		"jiushimeimeida": {
			"type": "full",
			"tid": "jiushimeimeida",
			"mid": "7dbec5f62379504e077224e724b8a8d0",
			"name": "就是美美哒",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/53ac27aae6b5fd3de20c4fa964a72696.jpg",
			"mode": {
				"scene": ["changlang", "xiayu", "baozha", "xuandong"],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/5980171/images/4440199aff4ae43f83ac8809481edd1f.png",
					"https://resali.lititutu.cn/scenes/jiushimeimeida/6a79ca04294939420f39d7e2d73dbdbf.png",
					"https://resali.lititutu.cn/scenes/jiushimeimeida/56bad724de231d85c0560e03829ed62c.jpg",
				],
				"changlang": [
					"http://litiresource.xcgogo.site/user/5980171/images/37e446e6815a184d24c731664f48c77c.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/34ba4f67894536345870c92ed06ce771.jpg",
				],
				"baozha": [
					"https://resali.lititutu.cn/scenes/jiushimeimeida/f56438aa978d3994a8d1b787f2f32c98.jpg",
				],
				"xiayu": [
					"https://resali.lititutu.cn/scenes/jiushimeimeida/89e822106468ccf6fa543a5dc02ddaa2.jpg",
				],
				"decorate": {
					"changlang": {
						"luo": [
							"http://litiresource.xcgogo.site/user/5980171/images/c5f989279f3afd307fdfc9e8d568d665.png",
						]
					},
					"xiayu": {
						"luo": [
							"http://litiresource.xcgogo.site/user/5980171/images/6d04ab7889dc144b730f67455656c841.png",
							"http://litiresource.xcgogo.site/user/5980171/images/4c67c8d27ae0d1164952441ccbd59be9.png",
							"http://litiresource.xcgogo.site/user/5980171/images/0d6393cb8f4891a9e2d282faf93decbb.png",
						]
					}
				}
			},
			"tips": {
				"im": "http://litiresource.xcgogo.site/user/9546085/images/2c3f3a389ae0735de700bada480ea1fa.png",
			}
		},
		"xuanhua": {
			"type": "xc",
			"tid": "xuanhua",
			"mid": "9c31e97e6b2dcdc9d71f87e80c6ea25e",
			"name": "炫花3D",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/ec8e3a9134bbaf0ae1c3a785cc5ea6d2.jpg",
			"room": {
				"top": {
					"gif": {
						"duration": 3,
						"width": bw,
						"height": bw,
						"backgroundWidth": bw,
						"backgroundHeight": bw * 20,
						"sprite_image": {
							"im": "http://resali.lititutu.cn/scenes/xuanhua/00.jpg",
							"steps": 20
						}
					}
				},
				"bottom": {
					"gif": {
						"duration": 3,
						"width": bw,
						"height": bw,
						"backgroundWidth": bw,
						"backgroundHeight": bw * 20,
						"sprite_image": {
							"im": "http://resali.lititutu.cn/scenes/xuanhua/00.jpg",
							"steps": 20
						}
					}
				}
			}
		},
		"qingchihehua": {
			'type': 'xc',
			'tid': 'qingchihehua',
			'name': '清池荷花',
			'mid': 'c85f92d4d738edd104404f968f77c6c3',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/cad11abc2b67d89783365e97873bd44d.jpg',
			'border': 'http://litiresource.xcgogo.site/user/5980171/images/3c8aaeb510d21e437e3bcdb9fbbe8e78.png',
			"room": {
				'top': {
					'im': 'http://litiresource.xcgogo.site/user/5980171/images/e2b5de73ad03799640fed44e40892361.jpg',
					'elems': [
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/3832c74db720c62ea1b0ff999bb0a2a8.png',
							'size': [bw*.371, bw*.64],
							'pos': [bw*.162,-0.017*bw,null,null],
							'style': {
								transformOrigin: 'right center',
								animation: 'feng_chui_3 2s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/52b28a83e77a1765454e1e5f4eb0f740.png',
							'size': [bw*0.66, bw*0.34],
							'pos': [null, null, -0.056*bw, 0],
							'style': {
								transformOrigin: '40% 100%',
								animation: 'feng_chui_4 2.5s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/3832c74db720c62ea1b0ff999bb0a2a8.png',
							'cstyle': {
								top: (bw*0.128)+'px',
								left: '-2px',
								width: (bw*0.37)+'px',
								height: (bw*0.64)+'px',
								transform: 'rotateZ(180deg)'
							},
							'style': {
								transformOrigin: 'right',
								animation: 'feng_chui_4 2.2s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/52b28a83e77a1765454e1e5f4eb0f740.png',
							'cstyle': {
								top: (-0.056*bw)+'px',
								right: '0px',
								width: (bw*0.66)+'px',
								height: (bw+0.343)+'px',
								transform: 'rotateZ(180deg)'
							},
							'style': {
								transformOrigin: '40% 100%',
								animation: 'feng_chui_3 2.8s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/73d763e5ba20fbf63bd2fdb121a27748.png',
							'cstyle': {
								top: (bw*0.32)+'px',
								left: (bw*0.417)+'px',
								width: (bw*0.708)+'px',
								height: (bw*0.32)+'px',
								animation: 'cloud6 50s linear infinite both'
							},
							'style': {
								display: 'inline',
								animation: '1.8s linear 0s 1 normal both running fadeIn'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/73d763e5ba20fbf63bd2fdb121a27748.png',
							'cstyle': {
								top: (bw*0.069)+'px',
								left: (bw*0.153)+'px',
								width: (bw*0.825)+'px',
								height: (bw*0.375)+'px',
								animation: 'cloud3 50s linear infinite both'
							},
							'style': {
								display: 'inline',
								animation: '1.8s linear 0s 1 normal both running fadeIn'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/73d763e5ba20fbf63bd2fdb121a27748.png',
							'cstyle': {
								top: (bw*-0.069)+'px',
								left: (bw*-0.278)+'px',
								width: (bw*0.672)+'px',
								height: (bw*0.306)+'px',
								animation: 'cloud1 50s linear infinite both'
							},
							'style': {
								display: 'inline',
								animation: '1.8s linear 0s 1 normal both running fadeIn'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/73d763e5ba20fbf63bd2fdb121a27748.png',
							'cstyle': {
								top: (bw*0.083)+'px',
								left: (bw*-0.569)+'px',
								width: (bw*0.825)+'px',
								height: (bw*0.375)+'px',
								animation: 'cloud4 50s linear infinite both'
							},
							'style': {
								display: 'inline',
								animation: '1.8s linear 0s 1 normal both running fadeIn'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/73d763e5ba20fbf63bd2fdb121a27748.png',
							'cstyle': {
								top: (bw*0.25)+'px',
								left: (bw*-0.361)+'px',
								width: (bw*0.764)+'px',
								height: (bw*0.347)+'px',
								animation: 'cloud5 50s linear infinite both'
							},
							'style': {
								display: 'inline',
								animation: '1.8s linear 0s 1 normal both running fadeIn'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/73d763e5ba20fbf63bd2fdb121a27748.png',
							'cstyle': {
								top: (bw*0.489)+'px',
								left: (bw*0.097)+'px',
								width: (bw*0.528)+'px',
								height: (bw*0.24)+'px',
								animation: 'cloud7 50s linear infinite both'
							},
							'style': {
								display: 'inline',
								animation: '1.8s linear 0s 1 normal both running fadeIn'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/73d763e5ba20fbf63bd2fdb121a27748.png',
							'cstyle': {
								top: (bw*0.44)+'px',
								left: (bw*-0.514)+'px',
								width: (bw*0.528)+'px',
								height: (bw*0.24)+'px',
								animation: 'cloud8 50s linear infinite both'
							},
							'style': {
								display: 'inline',
								animation: '1.8s linear 0s 1 normal both running fadeIn'
							}
						}
					]
				},
				'bottom': {
					'im': 'http://litiresource.xcgogo.site/user/5980171/images/394a6640d476a330a085d8194847593f.jpg',
					'fish': {
						'num': 6
					},
					'elems': [
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/f271c9d34a1b932afa6663cbf8c53a77.png',
							'size': [bw*0.17, bw*0.15],
							'pos': [null, null, bw*0.083, bw*0.111],
							'style': {
								animation: 'lotus_leaf_1 3.3s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/f271c9d34a1b932afa6663cbf8c53a77.png',
							'size': [bw*0.233, bw*0.2125],
							'pos': [null, null, bw*0.169, bw*0.306],
							'style': {
								animation: 'lotus_leaf_2 3.3s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/f271c9d34a1b932afa6663cbf8c53a77.png',
							'size': [bw*0.285, bw*0.26],
							'pos': [bw*0.319, bw*0.0486, null, null],
							'style': {
								animation: 'lotus_leaf_3 3.3s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/f271c9d34a1b932afa6663cbf8c53a77.png',
							'size': [bw*0.19, bw*0.172],
							'pos': [bw*0.157, null, null, bw*0.139],
							'style': {
								animation: 'lotus_leaf_4 3.3s ease-in-out infinite alternate both'
							}
						},
						{
							'im': 'http://litiresource.xcgogo.site/user/5980171/images/f271c9d34a1b932afa6663cbf8c53a77.png',
							'size': [bw*0.2, bw*0.219],
							'pos': [bw*0.06, null, null, bw*0.417],
							'style': {
								animation: 'lotus_leaf_5 3.3s ease-in-out infinite alternate both'
							}
						}
					],
					"gif": {
						"type": "gif",
						"duration": 3,
						"width": bw,
						"height": bw,
						"backgroundWidth": bw,
						"backgroundHeight": bw * 40,
						"sprite_image": {
							"im": "http://resali.lititutu.cn/scenes/qingchihehua/00.png",
							"steps": 40
						}
					}
				}
			},
			'room2': {
				'huaban': ['http://litiresource.xcgogo.site/user/5980171/images/fbab11686bd16e58c80d88381cbd3ce1.png', 'http://litiresource.xcgogo.site/user/5980171/images/bc6c320f414377896925071f52b26b7b.png'],
				'hudie': ['http://litiresource.xcgogo.site/user/5980171/images/e5cac476e98180bac8edaf2377b6ae51.png', 'http://litiresource.xcgogo.site/user/5980171/images/461782eade59e2477db48f9c497f4fb0.png'],
				'elems': [
					{
						'im': 'http://litiresource.xcgogo.site/user/5980171/images/c9d16e781b9ff069c9c5a53b55b5df17.png',
						'size': [bw*0.517, bw*0.683],
						'pos': [bw*.5, bw*.556, bw*0.361],
					},
					{
						'im': 'http://litiresource.xcgogo.site/user/5980171/images/198815fe09a90b0f0ad852d4b99fa8a8.png',
						'size': [bw*0.426, bw*0.46],
						'pos': [0, bw*0.675, 0]
					},
					{
						'im': 'http://litiresource.xcgogo.site/user/5980171/images/198815fe09a90b0f0ad852d4b99fa8a8.png',
						'size': [bw*0.264, bw*0.218],
						'pos': [bw*0.5, bw*0.8625, -0.27*bw]
					},
					{
						'im': 'http://litiresource.xcgogo.site/user/5980171/images/1f55ae3652a97055d032c9dfa6996d6b.png',
						'size': [bw*0.335, bw*0.268],
						'pos': [bw*0.222, bw*0.87, -0.093*bw]
					},
					{
						'im': 'http://litiresource.xcgogo.site/user/5980171/images/198815fe09a90b0f0ad852d4b99fa8a8.png',
						'size': [bw*0.264, bw*0.218],
						'pos': [bw*0.50, bw*0.8625, bw*0.08]
					},
					// {
					// 	'im': 'http://litiresource.xcgogo.site/user/5980171/images/198815fe09a90b0f0ad852d4b99fa8a8.png',
					// 	'size': [159, 131],
					// 	'pos': [460, 597, -60]
					// }
					// {
					// 	"im": "http://litiresource.xcgogo.site/user/9546085/images/7faa0cd20ac8b6099d12a41fc01ee94e.png",
					// 	"size": [335, 338],
					// 	"pos": [0, 0, 0]
					// 	// "pos": [192, 478, 0]
					// },
				]
			},
			'room3': {
				'elems': [
					{
						'im': 'http://litiresource.xcgogo.site/user/5980171/images/405f8dabd4f49883755860c5ee92773c.png',
						'size': [bw*0.511, bw*0.646],
						'pos': [-0.125*bw, null, null, -0.089*bw],
						'style': {
							animation: 'feng_chui_1 3s ease-in-out infinite alternate both',
							transformOrigin: '45% 9%'
						}
					},
					{
						'im': 'http://litiresource.xcgogo.site/user/5980171/images/837ce50b9f1bbadafada93840eed325e.png',
						'size': [bw*0.544, bw*0.451],
						'pos': [-0.114*bw, null, null, 0.289*bw],
						'style': {
							animation: 'feng_chui_2 2.5s ease-in-out infinite alternate both',
							transformOrigin: '35% 0%'
						}
					}
				]
			}
		},
		"taohuayuan3d": {
			"type": 'xc',
			"tid": 'taohuayuan3d',
			"name": '桃花源3D',
			'room': {
				'top': 'http://litiresource.xcgogo.site/user/9546085/images/4620bc23e351be7538c6f9351cd71112.jpg',
				'bottom': 'http://litiresource.xcgogo.site/user/9546085/images/904b6ec0717a8335ad991be9c8f1e2c0.jpg',
			},
			'mid': '05c74fd68f69ba87af9cbddec06dfa0c',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/b2f93294dc10a5b2afbd204c12c70971.jpg',
			'room2': {
				'huaban': ['http://litiresource.xcgogo.site/user/9546085/images/334b01062f6f166d124d7b8f81744f88.png'],
				'hudie': ['http://litiresource.xcgogo.site/user/5980171/images/95ca1bbfaa03fac4142246557d3ab789.png']
			},
			"tips": {
				"im": "http://litiresource.xcgogo.site/user/9546085/images/2c3f3a389ae0735de700bada480ea1fa.png"
			}
		},
		"shenmihuayuan": {
			'type': 'xc',
			'tid': 'shenmihuayuan',
			'name': '神秘花园',
			'room': {
				'top': 'http://litiresource.xcgogo.site/user/9546085/images/9f540d35c8cb77b531211d934d7c66bd.jpg',
				'bottom': 'http://litiresource.xcgogo.site/user/9546085/images/9e3cb88d66ea806fc92cbb810357f75f.jpg',
			},
			'mid': 'c0544ef9c205ad9bb18c5ac22047eda6',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/254a7c84b077cd72d8319c373a640166.jpg',
			'room2': {
				"huaban": ["http://litiresource.xcgogo.site/user/9546085/images/93ab5c66a0fe520ecb9d48ee02e7ae8e.png","http://litiresource.xcgogo.site/user/9546085/images/9f3d8964ba5ab3c0d2a6c247e9d14d6e.png","http://litiresource.xcgogo.site/user/9546085/images/25560f52120259a05009d17a3d2aed3f.png"],
				"hudie": ["http://litiresource.xcgogo.site/user/9546085/images/8d01ef8bc25a589434528c078c8596bc.png","http://litiresource.xcgogo.site/user/9546085/images/08f90af8874ab75c52f5b47bbec93df5.png"],
				"elems": [
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/7faa0cd20ac8b6099d12a41fc01ee94e.png",
						"size": [232, 234],
						"pos": [133, 332, 0]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/7fd6167bf71bbae04750def98a1646f3.png",
						"size": [83, 108],
						"pos": [208, 402, -208]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/725688a5e701679f6f6f7d723c92fb67.png",
						"size": [69, 124],
						"pos": [139, 402, -83]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/a3428eba5549072caa3289cd840c743f.png",
						"size": [153, 164],
						"pos": [208, 402, 153]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/14ac7fcef6d4582b9d811d4e671b09c0.png",
						"size": [139, 178],
						"pos": [312, 402, 14]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/b723e46633d51d39b8910ef2de74c600.png",
						"size": [104, 150],
						"pos": [56, 470, 63]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/f78f4afd087567b27c9beb0f73d7ddfc.png",
						"size": [139, 139],
						"pos": [85, 332, 208]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/672b1af4ddc3f589ffb8a930de488e75.png",
						"size": [154, 216],
						"pos": [42, 332, 0],
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/2032a418c8a538987e1a7c2ec769f8af.png",
						"size": [115, 250],
						"pos": [258, 332, -111]
					},
					{
						"im": "http://litiresource.xcgogo.site/user/9546085/images/a3428eba5549072caa3289cd840c743f.png",
						"size": [153, 164],
						"pos": [15, 332, -139]
					},
				]
			},
			'tips': {
				'im': 'http://litiresource.xcgogo.site/user/9546085/images/76e4284bde88ef2e3035958c8a437cbd.png'
			}
		},
		"zhilaoqingnian": {
			"type": "full",
			"tid": "zhilaoqingnian",
			"mid": "7571fa332aae17ac8641e6c969c0f022",
			"name": "致老青年们",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/60f1053280f00359741be6ea52ffcbf3.jpg",
			"mode": {
				"scene": ["fangkuai", "baozha", "tiandi", "fanshu"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/abb46e48b9f22c7df116c11e64f67029.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/76497a8b8bb9ef3d62bb76a094dc26f9.jpg",
				],
				"baozha": ["http://litiresource.xcgogo.site/user/5980171/images/3032d79c0c2f8b332785626e002d7e7c.jpg"],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/00ad47fd6395a8371c40cdfbc52a5e56.jpg"],
				"fanshu": ["http://litiresource.xcgogo.site/user/5980171/images/3032d79c0c2f8b332785626e002d7e7c.jpg"]
			}
		},
		"langmanqingren": {
			"type": "full",
			"tid": "langmanqingren",
			"mid": "5e8d7eb3f8e8bbfa6fb2fd8061a1394e",
			"name": "浪漫情人",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/e3ec812288a38749e0e4fe0eeeefee3e.jpg",
			"mode": {
				"scene": ["aixinyuan", "zhuanxin", "fangkuai", "xinbox"],
				"xinbox": ["http://litiresource.xcgogo.site/user/5980171/images/d5caac6f2ce0fd8ea4ffbd537a67a8f9.jpg"],
				"zhuanxin": ["http://litiresource.xcgogo.site/user/5980171/images/57b06f07fe7918357a4f83d723bf60dd.jpg"],
				"aixinyuan": [
					"http://litiresource.xcgogo.site/user/5980171/images/dbdee25e243db35a688d613768729124.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/19ed5d053321dcca86b5eff07b3b31f0.png",
					"http://litiresource.xcgogo.site/user/5980171/images/ad97261ff913003e09db1d5e4d5a38e9.png",
					"http://litiresource.xcgogo.site/user/5980171/images/18b586b583461d3bd39439fe77177026.png",
					"http://litiresource.xcgogo.site/user/5980171/images/e1ff11a3c48ed72b10e07d1581a6a56e.png",
				],
				"fangkuai": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/b93e8a7e6254fb804ef1212bb5e26692.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/63ff3574a7bc02f51f77314d49695ff6.jpg",
							"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						}
					}
				}
			},
			"tips": {
				"im": "http://litiresource.xcgogo.site/user/9546085/images/76e4284bde88ef2e3035958c8a437cbd.png"
			}
		},
		"hetangyuese": {
			"type": "full",
			"tid": "hetangyuese",
			"mid": "02f38822a6a336a49b6b0c99323d7610",
			"name": "荷塘月色",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/3725b81f5182a347bbc48c6fc12d9797.jpg",
			"mode": {
				"scene": ["fangkuai", "xuanzhuan", "zhuanxin", "jiaban", "babianxing", "pingbox"],
				"pingbox": [
					"http://litiresource.xcgogo.site/user/5980171/images/61983c1d4d46717a85604ffaf3baccd8.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/c5cbabcd1557264eb7667b4d47d229b7.png",
					[
						'http://litiresource.xcgogo.site/user/5980171/images/05a4070171fb3a330501ffab93a8b222.png',
						'http://litiresource.xcgogo.site/user/5980171/images/05a4070171fb3a330501ffab93a8b222.png',
					],
				],
				"babianxing": ["http://litiresource.xcgogo.site/user/5980171/images/82f1d3097d4813a272b524a5bdabdd73.jpg"],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/9a1afc3ccfd58cc109205c8a5dc524b0.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/0e93ba87f0b2fce9dd78bde22555e6c6.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"zhuanxin": ["http://litiresource.xcgogo.site/user/5980171/images/76edfeeb1b85d33f2a6f64f66fde6565.jpg"],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/d61d960e9c699b9a643d8de1c8cafa18.jpg"],
				"fangkuai": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/c9185ad8b0542828ab946b0927b94bb9.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/0f80320dccc85a666af7381ad7687306.jpg"
						}
					},
					"room2": {
						"elems": [
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/24896326183296971ed9abd1da4bbef1.png",
								"pos": [bw*0.6, bw*.972, -(bw*.036)],
								"size": [bw*.306, bw*106]
							},
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/24896326183296971ed9abd1da4bbef1.png",
								"pos": [bw*.375, bw*.972, -(bw*.25)],
								"size": [bw*.217, bw*.075]
							},
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/62882a3d30e52499e6bde193d996832d.png",
								"pos": [-(bw*.069), bw*.625, bw*.25],
								"size": [bw*.426, bw*.46]
							},
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/8d6d381bba498904a9713d476288b93b.png",
								"pos": [bw*.278, bw*.764, 0],
								"size": [bw*.4, bw*.289]
							},
							{
								"im": "http://litiresource.xcgogo.site/user/5980171/images/17c961b4f3070c1b7c76ed6d0b8bc986.png",
								"pos": [0, bw, -(bw*.5)],
								"size": [bw*.24, bw*.189]
							}
						]
					}
				}
			},
			"tips": {
				"im": "http://litiresource.xcgogo.site/user/9546085/images/76e4284bde88ef2e3035958c8a437cbd.png"
			}
		},
		"meilinvren": {
			"type": "full",
			"tid": "meilinvren",
			"mid": "priceoflove",
			"name": "魅力女人",
			"thumb": "http://litiresource.xcgogo.site/user/7175946/images/f0c398ec4394594eda6ec37781044be5.jpg",
			"mode": {
				"scene": ["baozha", "guandao", "xinbox", "xuandong"],
				"xinbox": ["http://litiresource.xcgogo.site/user/7175946/images/8f9db1c21a918c6ec490d6b55f150dde.jpg"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/213196e95cb949c8cd99d357f3c039be.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/213196e95cb949c8cd99d357f3c039be.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/213196e95cb949c8cd99d357f3c039be.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/213196e95cb949c8cd99d357f3c039be.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg", "http://litiresource.xcgogo.site/user/7175946/images/438eb90a65d477240026cdad716c1cb1.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/e70e7f93faefb928f728f1344f2e825c.jpg", "http://litiresource.xcgogo.site/user/7175946/images/9a0b1fedec7888834683cccf5da3fec1.jpg"],
				],
				"baozha": ["http://litiresource.xcgogo.site/user/7175946/images/b3b8f40bd7cd389d2c1370483b28854a.jpg"],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/7175946/images/19fa14e01e1a276a881d7df63f2acf8a.png",
					"http://litiresource.xcgogo.site/user/7175946/images/e382a0d087b260939f735586ba67d64f.png",
					"http://litiresource.xcgogo.site/user/7175946/images/e7dfcd8e7489e93298662a4b8b0a73f9.jpg"
				],
			}
		},
		"jingsenianhua": {
			"type": "full",
			"tid": "jingsenianhua",
			"mid": "f5191553919047b96b8d178e102286a1",
			"name": "金色年华",
			"thumb": "http://litiresource.xcgogo.site/user/7175946/images/1b920181d75ab718dbc637c5bacb4334.jpg",
			"mode": {
				"scene": ["fangkuai", "xinbox", "fanzhuan", "fanshu"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/7175946/images/b015c69a235e5303e3ab07b94fd46df6.jpg",
					"http://litiresource.xcgogo.site/user/7175946/images/c6cca9000da7fe80d8e90b3cd745f31a.jpg",
				],
				"xinbox": ["http://litiresource.xcgogo.site/user/7175946/images/df82009b19a1077b737b085f3e0edc36.jpg"],
				"fanzhuan": ["http://litiresource.xcgogo.site/user/7175946/images/ad61804455db09222f4fbe3b4058fefb.jpg"],
				"fanshu": ["http://litiresource.xcgogo.site/user/7175946/images/8eda67bf4ff5f864eb3352ccc9a660d0.jpg", "http://litiresource.xcgogo.site/user/7175946/images/c1edba21aac175d428d43c69352d1988.png"],
			}
		},
		"haidishijie": {
			"type": "full",
			"tid": "haidishijie",
			"mid": "5e272c57e4b0620b95a48fe1e4da6914",
			"name": "海底世界",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/ab91957af6beee5ea8c2fbfc174efcb0.jpg",
			"mode": {
				"scene": ["sea", "tiandi", "dangao", "pingbox"],
				"pingbox": [
					"https://resali.lititutu.cn/scenes/haidishijie/a219522f0e072228c5a811d5f6d35efc.jpg",
					"https://resali.lititutu.cn/icons/other/fbcb1e088ec40931955dbe663f56eb7c.png",
					[
						'http://litiresource.xcgogo.site/user/5980171/images/814a8a5071c7ac5ec23068401364f391.png',
						'http://litiresource.xcgogo.site/user/5980171/images/5343230a0908c7e037168335bafd16ef.png',
						'http://litiresource.xcgogo.site/user/5980171/images/33c8caedc909b6b2ab4c1d2d8d9b06da.png',
						'http://litiresource.xcgogo.site/user/5980171/images/a1684d2e1e32b7fc2af1441f17bd69fc.png',
					]
				],
				"dangao": [
					"https://resali.lititutu.cn/scenes/haidishijie/44f3349384b1efced307821da929364e.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/af30e1d6fc7691857b79ec6b1d5d4cf6.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/3d28b801b464ee52ef92a07924ffd57e.jpg",
				],
				"tiandi": ["https://resali.lititutu.cn/scenes/haidishijie/d8c5af4a00bd9bfabf265d3ca9a18a2c.jpg"],
				"sea": ["http://litiresource.xcgogo.site/user/5980171/images/24dc657aa136ae2b097110f156d4a7ff.jpg", "https://resali.lititutu.cn/scenes/haidishijie/a6228ce6b37c7d0dea07260301f48e0a.jpg"]
			}
		},
		"suiyuejinghao": {
			"type": "full",
			"tid": "suiyuejinghao",
			"mid": "f5d0293475c0b795592db14611584094",
			"name": "岁月静好",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/219ebec0582bda21895f4ca5b0a3c52f.jpg",
			"mode": {
				"scene": ["fangkuai", "babianxing", "tuoyuan", "baozha", "zhuanxin", "fanshu"],
				"fanshu": ["http://litiresource.xcgogo.site/user/5980171/images/9b471a608ac0da3dda003044db7e8ffe.jpg", "http://litiresource.xcgogo.site/user/5980171/images/b8999c403e8408c59bd8770e490f029f.png"],
				"zhuanxin": ["http://litiresource.xcgogo.site/user/5980171/images/bd075d927c1164f431f72a920fac4aa5.jpg"],
				"baozha": ["http://litiresource.xcgogo.site/user/5980171/images/f8b804628137553cf44a8c4d72e04a82.jpg"],
				"tuoyuan": ["http://litiresource.xcgogo.site/user/5980171/images/22c316f1a63de146fc6bd635c6f6eca3.png"],
				"babianxing": ["http://litiresource.xcgogo.site/user/5980171/images/a350bcb683ec7414d90700bf8873a65f.jpg"],
				"fangkuai": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/03ba883c3dd47987ce002d3f5bb6a7ea.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/446322d83dc44da6c54ddb067f91f965.jpg",
							"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						}
					}
				},
			},
			"tips": {
				"im": "http://litiresource.xcgogo.site/user/9546085/images/76e4284bde88ef2e3035958c8a437cbd.png"
			}
		},
		"tongzhentongqu": {
			"type": "full",
			"tid": "tongzhentongqu",
			"mid": "ea6ace89157569b8dd5b58fce2f3a951",
			"name": "童真童趣",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/eff69820c4e65a9bdb80243c26f899df.jpg",
			"mode": {
				"scene": ["lifangzhuan", "xuanzhuan", "dangao", "fanzhuan", "jiaban"],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/1883948b2f72101a3d7cbed33d452a8a.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/47bbaa3da0dfc726fd524727a5444282.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"fanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/d1dc4d396649c1041e0fa777227a79a4.jpg"],
				"dangao": [
					"http://litiresource.xcgogo.site/user/5980171/images/5dc6046d86ba983d5c479dbd40a396ac.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/e7389d465df7b5ab201c8baeac184544.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/a26a06b7189f16a69c6805689729e31d.jpg",
				],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/fbedf29185a7c9e4fd31e6040f57885e.jpg"],
				"lifangzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/ca3621d7675ed4f97ca8b9103d92449c.jpg"],
			}
		},
		"tachunshanghua": {
			"type": "full",
			"tid": "tachunshanghua",
			"mid": "chuntianhuahuikai",
			"name": "踏春赏花",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/d569c87bf6a83266f128fd9ae49ae8dd.jpg",
			"mode": {
				"scene": ["xuandong", "fangkuai" , "pingbox", "shuibo", "baozha"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/1c1318059632f14c09bf439a79c2f268.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/d1300c65403f8169a436c66d36e67752.jpg",
				],
				"pingbox": [
					"http://litiresource.xcgogo.site/user/5980171/images/19bea6b80f95c4ec134156f4baa0a0ed.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/e05ac7ef3d23496a4bd19cee650c746f.png",
					[
						'http://litiresource.xcgogo.site/user/5980171/images/33b7c73d2fd38f30b3ff7b1933b5baf8.png',
						'http://litiresource.xcgogo.site/user/5980171/images/1bf1a0c45e1f30ebcbaf8f676038145e.png',
						'http://litiresource.xcgogo.site/user/5980171/images/08047b7fc1c38dd15c2319da1bf95a0a.png',
					]
				],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/5980171/images/f6d4d15b3f182ca946514f813501053a.png",
					"http://litiresource.xcgogo.site/user/5980171/images/900cc4837847332a5cebc02a453897a4.png",
					"http://litiresource.xcgogo.site/user/5980171/images/06f371a995b024fd0cb7e705f62022cd.jpg"
				],
				"shuibo": [
					"http://litiresource.xcgogo.site/user/5980171/images/db3dbec530d2556564855f004314496e.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png",
					"http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"
				],
				"baozha": ["http://litiresource.xcgogo.site/user/5980171/images/82c9255955cdb699833f63073aeca10b.jpg"],
			}
		},
		"nvrenruhua": {
			"type": "full",
			"tid": "nvrenruhua",
			"mid": "nvrenhuajiangyangzhuoma",
			"name": "女人如花",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/5526537c3cbb7ebf8a8ed84694ad1c58.jpg",
			"mode": {
				"scene": ["zhuanxin", "jiaban", "pingbox", "dangao", "xuandong"],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/f36798a7d225dd9aefa0792e3acc5ad9.png", "http://litiresource.xcgogo.site/user/5980171/images/57d831f8c9bd00a9ba82d6544b595c36.png", "http://litiresource.xcgogo.site/user/5980171/images/d21c7f0904a9930f17440ce1349cdbd9.jpg"],
				"dangao": [
					"http://litiresource.xcgogo.site/user/5980171/images/69cd97e67ac1af4821e2277e965ffe89.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/9b2ba18c2ed9c20a24f7bdc532216e37.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/9b2ba18c2ed9c20a24f7bdc532216e37.jpg",
				],
				"pingbox": [
					"http://litiresource.xcgogo.site/user/5980171/images/3365d27566b39d5efd3f0ef59cb2cad1.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/f58f4e77756c1a0b249bc6970eaeae6f.png",
					[
						'http://litiresource.xcgogo.site/user/5980171/images/dd841c2889e52e603057b870363c442a.png',
						'http://litiresource.xcgogo.site/user/5980171/images/c8565cd9e7aa90f82b9028690bfd9e54.png',
					]
				],
				"zhuanxin": ["http://litiresource.xcgogo.site/user/5980171/images/a71df3c0b1d4091f72450534c3319330.jpg"],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/e12b222062cb2fcd48b4b91e05572619.jpg",
					"http://resali.lititutu.cn/icons/huacong/5ad4bf21ad185.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				// "yuantong": [
				// 	"http://litiresource.xcgogo.site/user/5980171/images/e12b222062cb2fcd48b4b91e05572619.jpg",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/a39dd672235e822b46bddc2f8d0f0fca.png",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/8f91248fd7947c5396d796a08368c310.png",
				// ]
			}
		}, 
	    "zqkl": {
	    	"tid": "zqkl",
	    	"name": "花好月圆",
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/f39d269996ac9458e453b684dd0d7f9d.jpg", 
	        "mid": "shiwudeyueliang", 
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/7175946/images/3196c1e077f7cbd3cafc41bcbb9f2b8c.jpg", 
		        "bottom": {
		        	'im': "http://litiresource.xcgogo.site/user/7175946/images/3196c1e077f7cbd3cafc41bcbb9f2b8c.jpg",
					"gif": {
						"type": "gif",
						"duration": 3,
						"width": bw,
						"height": bw,
						"backgroundWidth": bw,
						"backgroundHeight": bw * 40,
						"sprite_image": {
							"im": "http://resali.lititutu.cn/scenes/qingchihehua/00.png",
							"steps": 40
						}
					}
		        },
	        },
	        "room3": {
	        	"elems": [
		        	{
		        		"im": "http://litiresource.xcgogo.site/user/5980171/images/671ce4d7c137fe1303b541090f7f9b44.png",
		        		"size": [bw*.36, bw*.634],
		        		"pos": [bw*.167, null, null, -(bw*.069)],
		        		"style": {
		        			transformOrigin: "0 0",
		        			animation: "feng_chui 3s linear infinite alternate both"
		        		}
		        	},
		        	{
		        		"im": "http://litiresource.xcgogo.site/user/7175946/images/6149bf8b8fc27d69361f842919355331.png",
		        		"size": [bw*.417, bw*.39],
		        		"pos": [-(bw*.0417), -(bw*.028), null, null],
		        		"style": {
		        			transformOrigin: "100% 0",
		        			animation: "feng_chui 5s linear .8s infinite alternate both"
		        		}
		        	}
		        ]
	        },
			"texts": [
				[
					['亲爱的朋友', 'rgb(117, 249, 81)'],
					['中秋已至', 'rgb(234, 50, 35)'],
					['星星告诉我，送你关怀', 'rgb(128, 43, 244)'],
					['让你吉星高照', 'rgb(233, 60, 228)'],
				],
				[
					['月亮告诉我，送你问候', 'rgb(117, 249, 81)'],
					['让你人月团圆', 'rgb(234, 50, 35)'],
					['我的心告诉我，送你幸福', 'rgb(128, 43, 244)'],
					['愿你幸福圆圆满满', 'rgb(233, 60, 228)'],
				],
				[
					['秋风将至', 'rgb(117, 249, 81)'],
					['别忘了添多件衣服', 'rgb(234, 50, 35)'],
					['祝你中秋快乐', 'rgb(128, 43, 244)']
				]
			]
	    }, 
	    "xuexiang": {
	    	"tid": "xuexiang",
	    	"name": "雪乡小镇",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/5980171/images/359edb49d2e4ce7ce8d15ce4726f6860.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/5980171/images/0fd102968bf3400b0845cf9e33b119aa.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/4080007a86a7431a129790eff3358c89.jpg", 
	        "mid": "meilidexuexiangwodejia", 
	        "room2": {
	        	"huaban": [
	        		"http://litiresource.xcgogo.site/user/5980171/images/c7b26819ce9a88d1c0a710b87fff3f54.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/0246fca3a48789475587d633979c079a.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/ce38238f6d3ec40770dac764e53f1946.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/d2dbcc65e0e58e789bb7958edac99e39.png"
	        	]
	        }
	    }, 
	    "xingk": {
	    	"tid": "xingk",
	    	"name": "星空",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/7f87b7369216560414f1db06ffb5bf59.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/7f87b7369216560414f1db06ffb5bf59.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/5ba6b09e46b966b05b4b03ac7e6ec549.jpg", 
	        "mid": "gaoshanliushui", 
	    }, 
	    "chunt": {
	    	"tid": "chunt",
	    	"name": "春色满园",
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/89040bb23b8ab0cf1a1204538147337e.jpg", 
	        "mid": "wodekuailejiushixiangni", 
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/aa72f45c7d9db13a57b9ab47907251b8.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/38f406f93f44063a408b19df102998bd.jpg",
	        },
	        "room2": {
	        	"huaban": ["http://litiresource.xcgogo.site/user/5980171/images/9ab1152698e6c489c6986314b89b6a79.png"],
	        	"hudie": ["http://litiresource.xcgogo.site/user/5980171/images/086781e0486b9bd8c515d52066e1ae1d.png","http://litiresource.xcgogo.site/user/5980171/images/7b881054c74f12e90a9d590cab507299.png"]
	        }
	    }, 
	    "xiat": {
	    	"tid": "xiat",
	    	"name": "夏日美荷",
	        "room": {
	        	"top": "http://resali.lititutu.cn/scenes/xiat/00.jpg", 
	        	"bottom": "http://resali.lititutu.cn/scenes/xiat/01.jpg"
	        },
	        "type": "xc", 
	        "mid": "shengxiadeguoshi", 
	    }, 
	    "haiy": {
	    	"tid": "haiy",
	    	"name": "海洋",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/d5e1d3b64873b220d8115d80f4f667df.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/3b3b6c42a698c6e7b06f24c4e24fc887.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/fed84b39d2d11674b2718a9f4fdf9fc5.jpg", 
	        "mid": "zaishuiyifang", 
	    }, 
	    "caiyuanguangjin": {
	    	"tid": "caiyuanguangjin",
	    	"name": "财源广进",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/5980171/images/64fc489f02db11aa80c346b26362bb3a.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/5980171/images/add619a1eff6e7746871b0ae215b188d.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/add619a1eff6e7746871b0ae215b188d.jpg", 
	        "mid": "5ed97531381934ef6fd7b11032acfeaa", 
	        'border': 'http://litiresource.xcgogo.site/user/5980171/images/482dae8272d5c0383f808610e27166a9.png',
	    }, 
	    "gxfc": {
	    	"tid": "gxfc",
	    	"name": "财运到",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/f4a0911c8598f463eb6155eab2ecd1b1.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/132202117244880af4ac27d216e20404.jpg@0-0-640-640a_80Q.src",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/a06ac9abf85ad003d02ea392d9c656d5.jpg", 
	        "mid": "gongxifacai", 
	        "room2": {
	        	"fall": [
	        		"http://litiresource.xcgogo.site/user/5980171/images/ad3cd97ecd9c1a7195e009a1094ea476.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/7b10cf4d9b3869c6702f00c8ac24ac3c.png"
	        	]
	        }
	    }, 
	    "huas": {
	    	"tid": "huas",
	    	"name": "花树",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/1c5b0a7c5be1032dbc8ebb94d01612fc.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/5730f1626db57aa99ce4ccd0cb7b4f52.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/49749cf234c82d2540c8543ec4fcea22.jpg", 
	        "mid": "gezaifei", 
	        "room2": {
	        	"huaban": [
	        		"http://litiresource.xcgogo.site/user/5980171/images/b335ea00f8feee288c7793559174a7f9.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/d9c6c7fd883240a8175a6749e9723861.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/07c7daf608eb4b0fac94874a12537b88.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/dd7ef0b6c877d4b027d0a380cb0993af.png",
	        		"http://litiresource.xcgogo.site/user/5980171/images/46189f4f79f8789566c693f9ec088ffa.png",
	        	]
	        }
	    }, 
	    "shat": {
	    	"tid": "shat",
	    	"name": "沙滩",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/2faef1285f87888cc4e096bede5af69f.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/346129ba2be6b14bb8b42bfaed4d82ab.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/5a44829f5b1656da3ed188f8095d67c6.jpg", 
	        "mid": "yuanzougaofei", 
	    }, 
	    "jinli": {
	    	"tid": "jinli",
	    	"name": "祝福锦鲤",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/b3edd1ca836a56d84ff6982b12da7152.jpg", 
	        	"bottom": {
		        	"gif": {
		        		"width": bw,
		        		"height": bw,
			        	"duration": 1.5,
			        	"images": [
				        	"http://litiresource.xcgogo.site/user/5980171/images/5db3dffe1b3a7cf281fbedba35dcf2db.png",
							"http://litiresource.xcgogo.site/user/5980171/images/18a5e663a1afd7e9f8fc8994f029a321.png",
							"http://litiresource.xcgogo.site/user/5980171/images/4b7403a184ef47a858408029230a164a.png",
							"http://litiresource.xcgogo.site/user/5980171/images/0e0ccec48d044ca6667a844796101673.png",
							"http://litiresource.xcgogo.site/user/5980171/images/bdcf13342fefb17a1e73acaeb4172475.png",
							"http://litiresource.xcgogo.site/user/5980171/images/b4d70afd12022b5dfc437b75416ea448.png",
							"http://litiresource.xcgogo.site/user/5980171/images/a69b3f4294eedf6e73cb4ab2dd9fdee2.png",
							"http://litiresource.xcgogo.site/user/5980171/images/6bf7c7e366f52f8d8d30f2dae68b1846.png",
							"http://litiresource.xcgogo.site/user/5980171/images/5b19be0c73f1b6038eefa4d97f287e6a.png",
							"http://litiresource.xcgogo.site/user/5980171/images/a924889322f19a49257c2431edd38d04.png",
							"http://litiresource.xcgogo.site/user/5980171/images/32def2a3e7a3b429dcd44c128059b574.png",
							"http://litiresource.xcgogo.site/user/5980171/images/ca92f074f8033afd3a412df8339a34db.png",
							"http://litiresource.xcgogo.site/user/5980171/images/96babab75a8b05898302554b9e57032a.png",
							"http://litiresource.xcgogo.site/user/5980171/images/8ec3ba8680236aef5ce879c2b55a128c.png",
							"http://litiresource.xcgogo.site/user/5980171/images/54bf9d3e02219f894eeb48d42e9abcf8.png",
							"http://litiresource.xcgogo.site/user/5980171/images/82890f17c6158001fa7d1dc1f2569f33.png",
							"http://litiresource.xcgogo.site/user/5980171/images/157ac45bdeea23c01393c177e82d5cca.png",
							"http://litiresource.xcgogo.site/user/5980171/images/f61497fd5a31ae477380f85ea200f9e3.png",
							"http://litiresource.xcgogo.site/user/5980171/images/3bd8b69ddeea5d0ab71154f84baffa40.png",
				        ]
		        	}
		        }
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/13207b99c2389eb2e2991a3f65b84087.jpg", 
	        "mid": "haorizi"
	    }, 
	    "chengs": {
	    	"tid": "chengs",
	    	"name": "城市",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/b191ad41beaf37bb55f0155d21627e84.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/8de02dd853539d82580f05663049928c.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/ff6ab3e3652a7de9a483b392a0d8323f.jpg", 
	        "mid": "piaoyangguohailaikanni", 
	    }, 
	    "xinniankuaile": {
	    	"tid": "xinniankuaile",
	    	"name": "新年快乐",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/5980171/images/a294c4efdc8a30db4b9784496300f76f.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/5980171/images/d2369ffedc35470866ad5ba092abf63a.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/d2369ffedc35470866ad5ba092abf63a.jpg", 
	        "mid": "5ed97531381934ef6fd7b11032acfeaa", 
	        'border': 'http://litiresource.xcgogo.site/user/5980171/images/482dae8272d5c0383f808610e27166a9.png',
	        "prelude": {
	        	"textcolor": "#d0353b"
	        }
	    }, 
	    "juhua": {
	    	"tid": "juhua",
	    	"name": "花样生活",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/9fdd660de940d37783a8134cfd869439.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/4edc071cf924879ca3f74709bae2bdd6.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/c55b90a2d77fd35c927174e186ac3179.jpg", 
	        "mid": "mengzuihetang", 
	        "room2": {
	        	"huaban": [
	        		"http://litiresource.xcgogo.site/user/6403275/images/c59fbde712cf6e89f5b7f3089fd896bc.png",
	        		"http://litiresource.xcgogo.site/user/6403275/images/fc3dc9bbf6667b2b892728ba82fa919f.png"
	        	]
	        }
	    }, 
	    "xinnian1": {
	    	"tid": "xinnian1",
	    	"name": "福气到",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/5e395e14a7dbd33e3d035200d50e2e69.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/fe1504d05a25e9ce20d516dbb5880541.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/30bac99029b0942e224582a34cfca612.jpg", 
	        "mid": "haoyunlai", 
	        "room2": {
	        	"elems": [
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/81ff007819763773b8c5493f08c4094e.png",
	        			"size": [450, 731],
	        			"pos": [-50, -150, 0]
	        		},
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/81ff007819763773b8c5493f08c4094e.png",
	        			"size": [450, 731],
	        			"pos": [330, -150, 0]
	        		}
	        	]
	        }
	    }, 
	    "huah": {
	    	"tid": "huah",
	    	"name": "花海",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/b1c59ebaea78789fc7fc1cc2f403e0d9.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/4dabc1be00f77080b45e1ef6ca66b355.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/e7043467209abf56907958b8a3a9f358.jpg", 
	        "mid": "ageamei", 
	    }, 
	    "dongt": {
	    	"tid": "dongt",
	    	"name": "冬日祝福",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/5b307d798a43be70276c419ed56dee60.jpg",
	        	"bottom": "http://litiresource.xcgogo.site/user/5980171/images/946199f10865597ae0dfc06a441ad717.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/7011c3f40c8292d7b3918033d858fa3f.jpg", 
	        "mid": "nishiwohongchenzhongzuimeideyuan", 
	        "border": "http://litiresource.xcgogo.site/user/5980171/images/c787964a6a6d1185b2ee2585d9699ade.png",
	        "room2": {
	        	"huaban": ["http://litiresource.xcgogo.site/user/5980171/images/82e0e11b5cb67087b4bb6182733b02dc.png"],
	        	"elems": [
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/e1a0154fedb8775599f0131e3a7b3e13.png",
	        			"size": [0.52*bw, .49*bw],
	        			"pos": [bw*0.239, bw*0.664, 0]
	        		},
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/f54c8439b6064f01b5daeef17ee57c5c.png",
	        			"size": [bw*0.356, bw*0.356],
	        			"pos": [0.0125*bw, 0.644*bw, 0.072*bw]
	        		},
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/3406116def3216f516337c482b64c209.png",
	        			"size": [0.319*bw, 0.496*bw],
	        			"pos": [.5*bw, 0.594*bw, -(bw*0.258)]
	        		},
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/f54c8439b6064f01b5daeef17ee57c5c.png",
	        			"size": [bw*0.356, bw*0.356],
	        			"pos": [bw*0.375, bw*0.7125, bw*0.307]
	        		},
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/8fb124f303b05dd34de20cd564973441.png",
	        			"size": [bw*0.139, bw*0.139],
	        			"pos": [bw*0.725, bw*0.93, bw*0.044]
	        		},
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/8fb124f303b05dd34de20cd564973441.png",
	        			"size": [bw*0.122, bw*0.122],
	        			"pos": [bw*0.27, bw*0.93, bw*0.275]
	        		},
	        		{
	        			"im": "http://litiresource.xcgogo.site/user/5980171/images/97663893a2eeb8cc3011ee9af14b0402.png",
	        			"size": [bw*0.192, bw*0.119],
	        			"pos": [bw*0.225, bw*0.93, -(bw*0.175)]
	        		}
	        	]
	        }
	    }, 
	    "huashijie": {
	    	"tid": "huashijie",
	    	"name": "花样年华",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/7175946/images/5cff64479fbff051b5d81ca291553306.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/7175946/images/eb6a2f0482d0f86ad2058fd11f63f404.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/40f4bb48709bac2d0f9c123c4d30063f.jpg", 
	        "mid": "dainiqulvxing", 
	    }, 
	    "caoy": {
	    	"tid": "caoy",
	    	"name": "草原",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/5980171/images/003471a2bbbfe18f9dfc9e8b45418f43.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/6c82425c0ac445d102e6932d32193f71.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/94dde1a7f96d44074f3ab082760a5fd5.jpg", 
	        "mid": "meilidecaoyuanwodejia", 
	    }, 
	    "qiut": {
	    	"tid": "qiut",
	    	"name": "落叶知秋",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/6403275/images/5bc418814f9648590d616eb4dba261ca.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/6403275/images/228fb99bca16c05c4d59ce872e2fa574.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/e55d34c4bfb3a1110340654f1ff75acb.jpg", 
	        "mid": "yushangnishiwodeyuan", 
	        "room2": {
	        	"huaban": ["http://litiresource.xcgogo.site/user/5980171/images/32f4981dab4f035d4115a9e8e892ce93.png"],
	        	"hudie": ["http://litiresource.xcgogo.site/user/5980171/images/ae98aa0eb6c35917f2937bb9eb929ee8.png"]
	        }
	    }, 
	    "huanduguoqing": {
	    	"tid": "huanduguoqing",
	    	"name": "🇨🇳欢度国庆3D",
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/224ab3a36ca22420dd66f8ae2791df34.jpg", 
	        "mid": "zhufuzuguo", 
	        "room": {
		        "top": {
		        	"gif": {
		        		"duration": 2.5,
		        		"width": bw,
		        		"height": bw,
		        		"backgroundWidth": bw,
		        		"backgroundHeight": bw*21,
		        		"sprite_image": {
		        			"im": "http://litiresource.xcgogo.site/theme/xiyingguoqing/bg_rotategoldred_500x500_21f.jpg",
		        			"steps": 21
		        		}
		        	}
		        },
		        "bottom": {
		        	"im": "http://litiresource.xcgogo.site/theme/huanduguoqing/bg-j-6_45.jpg"
		        	// "gif": {
		        	// 	"duration": 2.5,
		        	// 	"width": 720,
		        	// 	"height": 720,
		        	// 	"backgroundWidth": 720,
		        	// 	"backgroundHeight": 15840,
		        	// 	"sprite_image": {
		        	// 		"im": "http://litiresource.xcgogo.site/theme/xiyingguoqing/bg_circle_china_rotate_red500x500_22f_r.jpg",
		        	// 		"steps": 22
		        	// 	}
		        	// }
		        },
	    	},
	        "room2": {
	        	"isRotate": false,
	        	"elems": [
    				{
		        		"type": "gif",
		        		"duration": 2.5,
		        		"width": 250,
		        		"height": 250,
		        		"backgroundWidth": 250,
		        		"backgroundHeight": 10000,
		        		"sprite_image": {
		        			"im": "http://litiresource.xcgogo.site/theme/xiyingguoqing/star_sprite.png",
		        			"steps": 40
		        		},
		        		"pos": [460, null, null, 235]
		        	}
	        	]
	        },
	        "texts": [
				[
					['金秋十月，丹桂飘香', 'rgb(117, 249, 81)'],
					['五星红旗，迎风飘扬', 'rgb(255, 235, 59)'],
					['我们迎来了', 'rgb(255, 152, 0)'],
					['祖国的第七十个生日', 'rgb(233, 60, 228)'],
				],
				[
					['祖国的大地日新月异', 'rgb(117, 249, 81)'],
					['祖国的生活幸福美满', 'rgb(255, 235, 59)'],
					['祖国的人民和睦健康', 'rgb(255, 152, 0)'],
					['身为中国人，我骄傲', 'rgb(233, 60, 228)'],
				],
				[
					['此时此刻', 'rgb(117, 249, 81)'],
					['只有一句：祖国我爱你', 'rgb(255, 235, 59)'],
					['才能代表', 'rgb(255, 152, 0)'],
					['每一个中国人的心声', 'rgb(233, 60, 228)'],
				],
				[
					['让我们共同祝愿', 'rgb(117, 249, 81)'],
					['祖国繁荣昌盛', 'rgb(255, 235, 59)'],
					['也愿好运、幸福伴你一生', 'rgb(255, 152, 0)'],
				],
			],
	        // "room3": {
	        // 	"elems": [
	        // 		{
	        // 			"type": "gif",
	        // 			"duration": 2,
	        // 			"width": 720,
	        // 			"height": 125,
	        // 			"backgroundWidth": 720,
	        // 			"backgroundHeight": 2500,
	        // 			"sprite_image": {
	        // 				"im": "http://litiresource.xcgogo.site/theme/xiyingguoqing/red_sidai_350x51_20f.png",
	        // 				"steps": 20
	        // 			},
	        // 			"pos": [null, null, 1, 1]
	        // 		}
	        // 	]
	        // },
	        "prelude": {
				"duration": 6,
				"gif": {
					"width": bw,
					"height": bw*1.63,
					"backgroundWidth": bw,
					"backgroundHeight": bw*57,
					"duration": 6,
					"animationIterationCount": 1,
					"sprite_image": {
						"im": "http://litiresource.xcgogo.site/user/5980171/images/e4268df5040fe8af96ce9bd8d19c6582.jpg",
						// "im": "http://litiresource.xcgogo.site/theme/xiyingguoqing/kaichang.jpg",
						"steps": 35
					}
				}
			},
	    }, 
	    "jixiangzhunian": {
	    	"tid": "jixiangzhunian",
	    	"name": "猪年吉祥",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/5980171/images/ed16e6a9cb61eb942deafe52c553fe5c.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/5980171/images/35dd49fea7dde3de87cc0071139b56e9.jpg",
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/35dd49fea7dde3de87cc0071139b56e9.jpg", 
	        "mid": "5ed97531381934ef6fd7b11032acfeaa", 
	        'border': 'http://litiresource.xcgogo.site/user/5980171/images/482dae8272d5c0383f808610e27166a9.png',
	    }, 
	    "yuanxiao": {
	    	"tid": "yuanxiao",
	    	"name": "元宵快乐3D",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/5980171/images/dedda08e492f3220cde830ffa273f9cf.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/5980171/images/a0b0aa87810c22a220411bf8abce8493.jpg",
	        },
	        "room2": {
	        	"piao": ["http://resali.lititutu.cn/scenes/gonghexinnian/denglong.png"]
	        },
	        "type": "xc", 
	        "mid": "zhengyueshiwunaohuadeng", 
	        'border': 'http://litiresource.xcgogo.site/user/5980171/images/0f19343e1c758263287087bbe6c95ef4.png',
			"prelude": {
				"textcolor": "#d0353b"
			}
	    },
	    "longtaitou": {
			"tid": "longtaitou",
			"type": "full",
			"mid": "3df2aac26838cb3e5ab4de20699f69fc",
			"name": "二月二龙抬头",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/1c30fa8c550276f7b8c554619e2a70b3.jpg",
			"mode": {
				"scene": ["pingbox", "yuanup", "guandao", "aixintiao"],
				"yuanup": [
					"http://litiresource.xcgogo.site/user/5980171/images/17cf34138b0c20e2e16dd4239334ab80.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/0a6ff753d72efb2b24df56299b5d18b8.jpg"
				],
				"pingbox": [
					"http://litiresource.xcgogo.site/user/5980171/images/8e72d72c3f3035e63095159b661baf2d.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/128ce42be4f409300c0c278cd8310c9d.png",
					[
						'http://resali.lititutu.cn/scenes/longtaitou/00.png',
						'http://resali.lititutu.cn/scenes/longtaitou/01.png',
						'http://resali.lititutu.cn/scenes/longtaitou/02.png',
						'http://resali.lititutu.cn/scenes/longtaitou/03.png'
					]
				],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "http://litiresource.xcgogo.site/user/5980171/images/d443dc6a0b012f054b793341ea0c07ac.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "http://litiresource.xcgogo.site/user/5980171/images/d443dc6a0b012f054b793341ea0c07ac.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "http://litiresource.xcgogo.site/user/5980171/images/d443dc6a0b012f054b793341ea0c07ac.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "http://litiresource.xcgogo.site/user/5980171/images/d443dc6a0b012f054b793341ea0c07ac.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/792aac5f1b8bdcaaf3186a991850a8c1.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1fa38236fdbe54cf00f9c22adfc1c3c9.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4d10c5130b24d31a9c9a734bc40fedf2.jpg"],
				],
				// "yuantong": [
				// 	"http://litiresource.xcgogo.site/user/5980171/images/0a6ff753d72efb2b24df56299b5d18b8.jpg",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/e13bc2d4e1063aabb7ed314be15c82ea.png",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/cb41efb4580b2f6bf67352c7fdd818ea.png"
				// ],
				"aixintiao": [
					"http://resali.lititutu.cn/scenes/longtaitou/00.gif",
					"http://resali.lititutu.cn/scenes/wuhanjiayou/05.png"
				],
				"decorate": {
					"yuanup": {
						"huojian": [
							"http://resali.lititutu.cn/icons/shanshuo/14.png",
							"http://resali.lititutu.cn/icons/shanshuo/16.png",
						],
					},
					"aixintiao": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/shanshuo/10.png",
							"http://resali.lititutu.cn/icons/shanshuo/11.png",
							"http://resali.lititutu.cn/icons/shanshuo/12.png"
						]
					}
				}
			}
		},
		"funvjie": {
			"tid": "funvjie",
			"type": "full",
			"mid": "nvrenhua",
			"name": "女神祝福",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/cc45648eec4111b688c5cdb3e24d37a3.jpg",
			"mode": {
				"scene": ["pingbox", "xinbox", "jiaban", "xuanzhuan"],
				"pingbox": ["http://litiresource.xcgogo.site/user/5980171/images/dd81580c65e63728a6bb3af2014ac7c7.jpg", "http://litiresource.xcgogo.site/user/5980171/images/cb8565dceac12c22fcd1527ad2d8c42e.png", "http://litiresource.xcgogo.site/user/5980171/images/9616c544c4a97a549761b0c00e77a8b3.png"],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/140b7b2bc0578399cc0f9ab328f69638.jpg"],
				"xinbox": ["http://litiresource.xcgogo.site/user/5980171/images/0c7d26d8c247263ac5aaaa530f2b645d.jpg"],
				"jiaban": ["http://litiresource.xcgogo.site/user/5980171/images/cd031296bb4e29cf752a26ce7324f52e.jpg", "http://litiresource.xcgogo.site/user/5980171/images/0d1b9aee585c23e351fdcfc07a1fab63.png", "http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png", "http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"],
			// 38素材 http://litiresource.xcgogo.site/user/5980171/images/e22faa4a2bfaed77aaf1ead9768438fb.png http://litiresource.xcgogo.site/user/5980171/images/098dd1c3dc29f9a9beee9d7563eb1262.jpg
			}
		},
		"chunyiangran": {
			"type": "full",
			"tid": "chunyiangran",
			"mid": "9c31e97e6b2dcdc9d71f87e80c6ea25e",
			"name": "春意盎然",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/133cf28638ca1b554dd19d675a73fcb7.jpg",
			"mode": {
				"scene": ["lifangzhuan", "fanzhuan", "fangkuai", "xuanzhuan", "xuandong"],
				"lifangzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/0d970520ed37421c260fc905de3e0564.jpg"],
				"fanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/61824de2677c221319796e1981f7047e.jpg"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/94180ba1f73977cf548359f7027a98d0.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/a74fa9ec62f5528e2c2f0ca75c04886d.jpg",
				],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/6c7dd239816a44dc467bc8b366a018cc.jpg"],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/daa3e3e1d7a60fc25544d380ee9cbc23.png", "http://litiresource.xcgogo.site/user/5980171/images/a1f3f50bbe60d54019b7ed663388bad5.png", "http://litiresource.xcgogo.site/user/5980171/images/f576a5e6a467aab278c02f1bb8c92394.jpg"],
			}
		},
	    "zhoumo": {
			"type": "full",
			"tid": "zhoumo",
			"mid": "9c31e97e6b2dcdc9d71f87e80c6ea25e",
			"name": "周末愉快",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/345af5a3a1f3ac8980ff65028922f1d2.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg",
			"mode": {
				"scene": ["jiaban", "fangkuai", "xuanzhuan", "guandao"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/97ba79f52ded5fb4f6129a9a99937427.png",
					"http://litiresource.xcgogo.site/user/5980171/images/ba1599732705e2970415f2cc1b32a389.png",
				],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/4ac33c4c5f73b9bd01e4cdc2fd38bfe9.png"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d198999bb8edda20dc1a8e57f96c9cbd.png", "http://litiresource.xcgogo.site/user/5980171/images/179507010daee13ecacd441d8c1339d8.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b69f660528b83ad7ec6d296fc5bcec7c.png", "http://litiresource.xcgogo.site/user/5980171/images/79972d0034b053b4547c63742d5ac82a.png"],
				],
				"jiaban": ["http://litiresource.xcgogo.site/user/5980171/images/1d9edaec02af8626c6ad60b12ccbcd96.png", "http://litiresource.xcgogo.site/user/5980171/images/ca913a9e887b435d62349742384eda3d.png", "http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png", "http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"],
			}
		},
		"wanan": {
			"type": "full",
			"tid": "wanan",
			"mid": "71997124b4280f015ca2c96000fdecd6",
			"name": "晚安祝福",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/4c179e33325d37e713c63f6de19899de.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg",
			"mode": {
				"scene": ["fangkuai", "guandao", "xuanzhuan", "jiaban"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/2f33dc845a99b8ec667cde10206ae847.png",
					"http://litiresource.xcgogo.site/user/5980171/images/7dadbaa3e91356565f70d7ad3b105c5a.png",
				],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/637b738b63667e6bb56593b4c8f5994d.png"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/7e7f59a1c9259e679ac320abf8416971.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/7e7f59a1c9259e679ac320abf8416971.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/7e7f59a1c9259e679ac320abf8416971.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/7e7f59a1c9259e679ac320abf8416971.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png", "http://litiresource.xcgogo.site/user/5980171/images/f583984fab78c762ca02de76c5481174.png", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3550b6f2a3dce513f1d71af3d5966618.png", "http://litiresource.xcgogo.site/user/5980171/images/650aa648e8bddcc24ef3c30fafceadbd.png"],
				],
				"jiaban": ["http://litiresource.xcgogo.site/user/5980171/images/5c262ebd9dde780d720fc92f5a9959d3.png", "http://litiresource.xcgogo.site/user/5980171/images/e65f693f59263ab34824dfc66fda8a47.png", "http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png", "http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"],
			}
		},
		"kuaileyuanxiao": {
			"type": "full",
			"tid": "kuaileyuanxiao",
			"mid": "zhengyueshiwunaohuadeng",
			"name": "快乐元宵3D",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg",
			"mode": {
				"scene": ["fangkuai", "baozha", "guandao", "xuandong"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/f3a58cb14e402e3a50553035ffc60e62.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/b0cc2235db876a3251d62cd3aed98739.jpg",
				],
				"baozha": ["http://litiresource.xcgogo.site/user/5980171/images/4539866bb8d9203576f0f342141b2136.jpg"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/d979e6bea704d5e1eba64c24ced6a519.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3e34467c20006e1ff4cae39e5ba7135f.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/24758a8a8848f2503dd6f21afe35a9bc.jpg", "http://litiresource.xcgogo.site/user/5980171/images/bdcb839379510b575354db0e5482e8e3.jpg"],
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/1443024c2cf58c8e0889d2f01a135660.png", "http://litiresource.xcgogo.site/user/5980171/images/0b7d5c57fc0edd78b1d56071d298d0c0.png", "http://litiresource.xcgogo.site/user/5980171/images/e37df27733098bcb42b9c15c5c7427a6.jpg"],
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"yuanxiaosongfu": {
			"type": "full",
			"tid": "yuanxiaosongfu",
			"mid": "bfbd446a652b2cd137abba232417f6d6",
			"name": "喜乐元宵3D",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/61bc065544f189f4cf1dc897868b2195.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/46ebceaf36d6dbcf3465417fc88c96a0.png",
			"mode": {
				"timeline": [
					"0,scene,aixintiao",
					"8,inter,denglongheng",
					"10,scene,aixintiao",
					"18,inter,denglongshu",
					"20,scene,manping",
					"28,inter,denglongheng",
					"30,scene,chuang",
					"38,inter,denglongshu",
					"40,end"
				],
				// "scene": ["fangkuai", "aixintiao", "manping", "yuantong"],
				"fangkuai": {
					"room": {
						"top": {
							"gif": {
				        		"type": "gif",
					        	"duration": 2,
					        	"width": bw,
					        	"height": bw,
					        	"backgroundWidth": bw,
					        	"backgroundHeight": bw*20,
					        	"sprite_image": {
					        		"im": "http://resali.lititutu.cn/scenes/yuanxiaosongfu/00.jpg",
					        		"steps": 20
					        	},
					        	"cover": "http://litiresource.xcgogo.site/user/5980171/images/30ead6b5938ef0cb9ae8c53a8ef9badd.jpg"
				        	}
						},
						"bottom": {
							"gif": {
				        		"type": "gif",
					        	"duration": 2,
					        	"width": bw,
					        	"height": bw,
					        	"backgroundWidth": bw,
					        	"backgroundHeight": bw*20,
					        	"sprite_image": {
					        		"im": "http://resali.lititutu.cn/scenes/yuanxiaosongfu/01.jpg",
					        		"steps": 20
					        	},
					        	"cover": "http://litiresource.xcgogo.site/user/5980171/images/30ead6b5938ef0cb9ae8c53a8ef9badd.jpg"
				        	}
						}
					}
				},
				"aixintiao": [
					"http://resali.lititutu.cn/scenes/yuanxiaosongfu/03.gif",
					"http://resali.lititutu.cn/scenes/wuhanjiayou/05.png"
				],
				"manping": ["http://resali.lititutu.cn/scenes/yuanxiaosongfu/02.gif"],
				// "xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/99f84c263f6b68bd6a0f54b1185abc66.jpg"],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/7fee6376a7d8762341eb09c6f5882f9f.jpg"],
				"chuang": [
					"http://litiresource.xcgogo.site/user/5980171/images/e4b4befb42338ba100ffe867c7fabe10.jpg",
					// "http://litiresource.xcgogo.site/user/5980171/images/46ebceaf36d6dbcf3465417fc88c96a0.png",
					// "http://litiresource.xcgogo.site/user/5980171/images/2ee0e7e6bc0b99f21e1103348f530f6a.png"
				],
				"decorate": {
					"fangkuai": {
						"luopic": [
							"http://resali.lititutu.cn/icons/shanshuo/17.png",
							"http://resali.lititutu.cn/icons/shanshuo/18.png"
						]
					},
					"aixintiao": {
						"huojian": [
							"http://resali.lititutu.cn/icons/shanshuo/14.png",
							"http://resali.lititutu.cn/icons/shanshuo/16.png",
						],
					},
					"chuang": {
						"huojianslow": [
							"http://resali.lititutu.cn/icons/shanshuo/01.png",
							"http://resali.lititutu.cn/icons/shanshuo/00.png"
						]
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"zhaocaijinbao": {
			"type": "full",
			"tid": "zhaocaijinbao",
			"mid": "5ed97531381934ef6fd7b11032acfeaa",
			"name": "招财进宝",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/1e90e2484724536978a33cc400955ad1.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/03f7f414560e24e7845c58ad698bb49b.jpg",
			"mode": {
				"scene": ["fangkuai", "xuanzhuan", "tiandi", "chuang"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/3008126860d472ee7e2a38e8efd65c61.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/03f7f414560e24e7845c58ad698bb49b.jpg",
				],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/f691723c30517e86231cb7de1d8c8ba9.jpg"],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/67ee92944f67cd2c6540fe8b74b056d4.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"chuang": ["http://litiresource.xcgogo.site/user/5980171/images/aefd49e43ec52af58ec423a221dc45e6.jpg"],
				// "yuantong": ["http://litiresource.xcgogo.site/user/5980171/images/aefd49e43ec52af58ec423a221dc45e6.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c507ea1f53cf8a3017bcb0e295f4c126.png", "http://litiresource.xcgogo.site/user/5980171/images/330d853fd246d45bd25e74adc8eaf188.png"],
				"music": "hongyueliang",
			},
			"tag": "热"
		},
		"wufulinmen": {
			"type": "full",
			"tid": "wufulinmen",
			"mid": "48ff0672c0d04fcfcea34b627906fffc",
			"name": "五福临门",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/8f77aa3a7a9964f3ddf4aab85d505dd5.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/3d1559089c0e1cde6f92350377dc9b69.jpg",
			"mode": {
				"scene": ["fangkuai", "guandao", "tiandi", "chuang"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/b3934b87c57889ef87abb13e9f90bf12.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/3d1559089c0e1cde6f92350377dc9b69.jpg",
				],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/c6a41904753805e9ad0413a8fac0864f.jpg"],
				"chuang": ["http://litiresource.xcgogo.site/user/5980171/images/e8d5d340f821285748d7a4410e5ca0af.jpg"],
				// "yuantong": ["http://litiresource.xcgogo.site/user/5980171/images/e8d5d340f821285748d7a4410e5ca0af.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ee4310b657845f6a25bdccb09f5e37d2.png", "http://litiresource.xcgogo.site/user/5980171/images/b65aa2b415ed8bb83ee34d78ac3d3030.png"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/691524687071a26cb920ce4c82107644.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e9f3f69350d95277d0b37e173fe49510.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/40da11a4d06dc072497bf41d01c135bd.jpg", "http://litiresource.xcgogo.site/user/5980171/images/ce97c4059439333c7e0325c8007923da.jpg"],
				],
				"music": "hongyueliang",
			},
			"tag": "热"
		},
		"wanshiruyi": {
			"type": "full",
			"tid": "wanshiruyi",
			"mid": "9cd815d5dda0f7d3c75520f6bbf045ba",
			"name": "万事如意",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/289aa8ea2bb21c95b30ecda8e0815a52.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/8e12eb4cef105d44cfac75e376d48a76.jpg",
			"mode": {
				"scene": ["fangkuai", "xuandong", "jiaban", "shuibo"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/8a37c5c62bfb4b15a450a4afd938b653.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/8e12eb4cef105d44cfac75e376d48a76.jpg",
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/56bdceaba82cb515fd45393317676c41.png", "http://litiresource.xcgogo.site/user/5980171/images/054bbc93afb524529720be7ddde5f14c.png", "http://litiresource.xcgogo.site/user/5980171/images/f3a85206ef74254f74fad4c5d6f2b3dc.jpg"],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/0deace0ed49123d1949125f951f0cfb6.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/820629dc00560d9c77ee032d87b6ec5b.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"shuibo": ["http://litiresource.xcgogo.site/user/5980171/images/65cd9865626a5a2583b46edb7edd2fa2.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"music": "hongyueliang",
			},
			"tag": "热"
		},
		"gongxifacai2": {
			"type": "full",
			"tid": "gongxifacai2",
			"mid": "gongxifacai",
			"name": "恭喜发财3D",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/397a2a2088785cebca6c21ec94ea9672.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/8e65b997de69605a5a91f929e6cddd85.jpg",
			"mode": {
				"scene": ["fangkuai", "fanzhuan", "shuibo", "xuandong"],
				"fanzhuan": ["http://resali.lititutu.cn/scenes/gongxifacai2/00.jpg"],
				// "fangkuai": [
				// 	"http://litiresource.xcgogo.site/user/5980171/images/0ab2f6d713b5a2aa4567f6dea9d05a42.jpg",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/8e65b997de69605a5a91f929e6cddd85.jpg",
				// ],
				"fangkuai": {
					"room": {
						"top": {
							"im": "http://resali.lititutu.cn/scenes/gongxifacai2/02.jpg"
						},
						"bottom": {
							"im": "http://resali.lititutu.cn/scenes/gongxifacai2/03.jpg"
						}
					},
					"room2": {
						"huaban": ["http://resali.lititutu.cn/scenes/xinniandaji/02.png"]
					}
				},
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/a541cc42fccf6f0c3c299b6b90f5fb11.png", "http://litiresource.xcgogo.site/user/5980171/images/fd8c5d659fb37837df0879b44e9214f8.png", "http://litiresource.xcgogo.site/user/5980171/images/54f1684dc773dade42226444a94ff8bf.jpg"],
				"shuibo": ["http://resali.lititutu.cn/scenes/gongxifacai2/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"decorate": {
					"shuibo": {
						"huojian": [
							'http://resali.lititutu.cn/decorate/huojian/huojian1.png',
							'http://resali.lititutu.cn/decorate/huojian/huojian2.png'
						]
					},
					"xuandong": {
						"huojian": [
							'http://resali.lititutu.cn/decorate/huojian/huojian2.png',
							'http://resali.lititutu.cn/decorate/huojian/huojian3.png'
						]
					},
					"fanzhuan": {
						"flow": "http://resali.lititutu.cn/scenes/gongxifacai2/fu.png"
					}
				}
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"zhuniankuaile": {
			"type": "full",
			"tid": "zhuniankuaile",
			"mid": "c575dfaa28bee7d4847d2b0e415aa2e1",
			"name": "猪年平安",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/7cfe5fa43c1266e919420bc3173905e3.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/879cd62250c53911175f78cd306ef553.jpg",
			"mode": {
				"scene": ["tiandi", "fangkuai", "lingxing", "shuibo"],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/264b0224c2b4b6d1ae17719462779d20.jpg"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/42edaf000b9bcf0fc122cefe740bdbe7.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/879cd62250c53911175f78cd306ef553.jpg",
				],
				"lingxing": ["http://litiresource.xcgogo.site/user/5980171/images/e8c1dc695725fb717b943c76cb10abf3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/b0fdfdf1a2645b59b0b9b656ffddd395.png"],
				"shuibo": ["http://litiresource.xcgogo.site/user/5980171/images/f54bd5dcf4cbb9ef800f4e39d0a04662.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"music": "hongyueliang",
			},
			"tag": "热"
		},
		"xinniankuaile2": {
			"type": "full",
			"tid": "xinniankuaile2",
			"mid": "ffd9f644ee499ca6d6f09f568edbe934",
			"name": "新年快乐",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/032af8926992254c36a04a071680be87.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/bf57d95226742185a29223ced7bea2ed.jpg",
			"mode": {
				"scene": ["fangkuai", "jiaban", "guandao", "chuang"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/bf57d95226742185a29223ced7bea2ed.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/98c06ed8827c3138b1dce5857929c08e.jpg",
				],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/e26ba0b02e32b38faf132f55d76badcc.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/a937b1807844b5fca11137e61a834f45.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/98c85264d6f560fca09d672eb74db8c0.jpg", "http://litiresource.xcgogo.site/user/5980171/images/97e6321bc14c84eda89e6b16ed794592.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a14cc637e96bc7c64568e9813df51fa3.jpg", "http://litiresource.xcgogo.site/user/5980171/images/5194e74985048961f6dfc39e43f5773d.jpg"],
				],
				"chuang": ["http://litiresource.xcgogo.site/user/5980171/images/d44762bc4a6402a17cd0e9ec6b106595.jpg"],
				// "yuantong": ["http://litiresource.xcgogo.site/user/5980171/images/d44762bc4a6402a17cd0e9ec6b106595.jpg", "http://litiresource.xcgogo.site/user/5980171/images/685541fd8b9d520cbe8876129a901305.png", "http://litiresource.xcgogo.site/user/5980171/images/62ee817545293325f788ad68f8e8e768.png"],
				"music": "hongyueliang",
			},
			"tag": "热",
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"xiaonian": {
			"type": "full",
			"tid": "xiaonian",
			"mid": "huahaoyueyuan",
			"name": "欢度小年",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/98984c442c864d42b7814d5cb1e40f25.jpg",
			"mode": {
				"scene": ["fangkuai", "xuandong", "guandao", "lingxing"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/9c9801f1f1eb163cd43f67e5e59c9e7c.jpg",
					"http://resali.lititutu.cn/scenes/xiaonian/02.jpg",
					// "http://litiresource.xcgogo.site/user/5980171/images/4a1955bbdb068e4b901e2d9755ecc6f8.jpg",
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/e05c15bd6a2c853a229c43e4a59c7acc.png", "http://litiresource.xcgogo.site/user/5980171/images/395a4f20d53e633d6612bfc02fae0c67.png", "http://litiresource.xcgogo.site/user/5980171/images/f6a0ef834676b5212cda9ffdee1805e4.jpg"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/c45fc08d1c9e3eab31242531f29a9dab.jpg", "http://litiresource.xcgogo.site/user/5980171/images/8494329e1edbd2340c4123e4129a3e7d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2d8579d59692056de3a9ff183d4cc3a5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/c419582be80489342da9297248b44d53.jpg"],
				],
				"lingxing": ["http://resali.lititutu.cn/scenes/xiaonian/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/3ded6e7efcb564a39cd793c42777f53d.png"],
				"music": "hongyueliang",
			},
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/74b76e84dee099e1eab5ac786c56675b.jpg",
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		"zaoanzhufu": {
			"type": "full",
			"tid": "zaoanzhufu",
			"mid": "zaoshanghao",
			"name": "早安祝福",
			"mode": {
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/02bd713bf9f78589959a5f3b193a4c59.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/00.jpg", "", "", "http://resali.lititutu.cn/scenes/zaoanzhufu/03.jpg", "http://resali.lititutu.cn/scenes/zaoanzhufu/01.jpg"]
				],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/c47f3c000c5ccbc285713b1518bc6297.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/b264f6f8d0c87aff6bd17893cb6d7a27.jpg", 
				],
				"chuang": ["http://litiresource.xcgogo.site/user/5980171/images/23d58503de33289b78a4279a20551fb6.jpg"],
				// "yuantong": ["http://litiresource.xcgogo.site/user/5980171/images/11e775c535d3e1b2d026205e27aabcdb.jpg", "http://litiresource.xcgogo.site/user/5980171/images/92e76ea62e0f887c59a353617f1349fc.png", "http://litiresource.xcgogo.site/user/5980171/images/1edbceb952b8c3cc29449d2758e0b03b.png"],
				"jiaban": ["http://resali.lititutu.cn/scenes/zaoanzhufu/02.jpg", "http://litiresource.xcgogo.site/user/5980171/images/820f01ecc64aebfdfce8ca42b29813c9.png", "http://litiresource.xcgogo.site/user/5980171/images/a9bb207034b6508a1d800dd502c5dc1a.png", "http://litiresource.xcgogo.site/user/5980171/images/827ad79c5c5379eb1694a3f22b0e27ab.png"],
				"scene": ["chuang", "guandao", "jiaban", "fangkuai"],
			}
		},
		"mantianhuacai": {
			"type": "full",
			"tid": "mantianhuacai",
			"mid": "hongyueliang",
			"name": "漫天华彩",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/dfd786584660fe73347f2afc5fc27d7e.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/9d9a7ebd6396c4c119b03fef26c46ed4.jpg",
			"mode": {
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/mantianhuacai/01.jpg",
					"http://resali.lititutu.cn/scenes/mantianhuacai/00.jpg",
				],
				"tiandi": ["http://resali.lititutu.cn/scenes/mantianhuacai/02.jpg"],
				"scene": ["fangkuai", "xiayu", "lifangzhuan", "tiandi"],
				"xiayu": [
					"http://litiresource.xcgogo.site/user/5980171/images/366d16c86ddd8e4aa5ecea3496dbbbf8.jpg"
				],
				// "guandao": [
				// 	["", "", "http://resali.lititutu.cn/scenes/mantianhuacai/03.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/04.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/05.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/17.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/06.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/18.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/07.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/08.jpg"],
				// 	["", "", "http://resali.lititutu.cn/scenes/mantianhuacai/09.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/10.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/11.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/12.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/13.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/14.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/15.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/16.jpg"],
				// 	["", "", "http://resali.lititutu.cn/scenes/mantianhuacai/03.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/04.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/05.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/17.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/06.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/18.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/07.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/08.jpg"],
				// 	["", "", "http://resali.lititutu.cn/scenes/mantianhuacai/09.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/10.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/11.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/12.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/13.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/14.jpg", "", "", "http://resali.lititutu.cn/scenes/mantianhuacai/15.jpg", "http://resali.lititutu.cn/scenes/mantianhuacai/16.jpg"],
				// ],
				"lifangzhuan": [
					"http://litiresource.xcgogo.site/user/7175946/images/d0db7de41bb460b550d7105ff183a723.jpg"
				]
				// "yuanup": [
				// 	"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/03.jpg",
				// 	"http://resali.lititutu.cn/scenes/yuanxiaotuanyuan/04.jpg"
				// ]
				// "yuantong": ["http://litiresource.xcgogo.site/user/7175946/images/d0db7de41bb460b550d7105ff183a723.jpg", "http://litiresource.xcgogo.site/user/5980171/images/b5c01910b627d7f84f2ae117822522cf.png", "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1537534439157&di=466b333427ed2593bee0c4bb202a4d62&imgtype=0&src=http%3A%2F%2Fbpic.ooopic.com%2F16%2F21%2F21%2F16212106-2a1785ae008edf269a964ec22ea88f01-1.jpg"]
			},
			"tag": "爆"
		},
		"labasongfu": {
			"type": "full",
			"tid": "labasongfu",
			"mid": "guolelabajiushinian",
			"name": "腊八送福",
			"mode": {
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/28e660372d11555303556f626dc9eeed.jpg", "http://resali.lititutu.cn/scenes/labasongfu/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/5fda20bd73c3dc9be8a1395fa8750935.jpg", "http://resali.lititutu.cn/scenes/labasongfu/04.jpg"]
				],
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/labasongfu/01.jpg",
					"http://resali.lititutu.cn/scenes/labasongfu/02.jpg",
				],
				"scene": ["fangkuai", "fanzhuan", "guandao", "shuibo"],
				"shuibo": ["http://resali.lititutu.cn/scenes/labasongfu/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e5e32dc0efaeafb9d89e47bbb7c03a91.png", "http://litiresource.xcgogo.site/user/5980171/images/0ec7e449867df4db07492a849f4f7bab.png"],
				"music": "guolelabajiushinian",
				"fanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/b66c14b657f189eb0c908cb67725386d.jpg"]
			},
		},
		"xiaohanwenhou": {
			"type": "full",
			"tid": "xiaohanwenhou",
			"mid": "dongtianlideyibahuo",
			"name": "小雪问候",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/3a8cac31cdc40004733510a215829b9b.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/dc4f0b6434cd1361f818cdcbf170b505.jpg",
			"mode": {
				"fangkuai": [
					// "http://litiresource.xcgogo.site/user/5980171/images/4bbc4e7d49b20fa941df7bb3409d2ca1.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/8b78daa0d2f6f950c78367031276455d.jpg",
					"http://litiresource.xcgogo.site/user/8031670/images/f9331b8bf8d4fda42ecaef99edd57368.jpg",
				],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/5980171/images/8453400aa7797571ee89b8ad29589adf.png",
					"http://litiresource.xcgogo.site/user/5980171/images/137d8c4128f128a9ff15552dcb33ce07.png",
					"http://litiresource.xcgogo.site/user/5980171/images/869c752948040a359ca96a97315fd114.jpg"
				],
				// "yuantong": [
				// 	"http://litiresource.xcgogo.site/user/5980171/images/9ad0ba3cb5f644458f4b0671a47dd848.jpg",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/0dd5275be0a798d1b2e63609388390ee.png",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/56bebadb08b1acfcc732f5f9bbc79b41.png"
				// ],
				"chuang": [
					"http://litiresource.xcgogo.site/user/5980171/images/9ad0ba3cb5f644458f4b0671a47dd848.jpg",
				],
				"fanzhuan": [
					"http://litiresource.xcgogo.site/user/5980171/images/f3eced465c5a6428275ecb7d8f1b8a0d.jpg"
				],
				"scene": ["fangkuai","fanzhuan","xuandong","chuang"]
			},
		},
		"baihuazhengyan": {
			"type": "full",
			"tid": "baihuazhengyan",
			"mid": "xiangnideyewan",
			"name": "百花争艳",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/e5cc4da48dd015d4cffe7e77a657acea.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/0830931172fcef61f6f0cf5f832498f5.jpg",
			"mode": {
				"scene": ["fangkuai", "lingxing", "fanzhuan", "xuandong"],
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/baihuazhengyan/04.jpg",
					"http://resali.lititutu.cn/scenes/baihuazhengyan/05.jpg",
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/98c215acd184b7628f78c19ff217afa1.png", "http://litiresource.xcgogo.site/user/5980171/images/692c687ffcaf91b600bc42f7281390d6.png", "http://resali.lititutu.cn/scenes/baihuazhengyan/06.jpg"],
				"guandao": [
					["", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg", "", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg", "", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg", "", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg", "", "", "http://resali.lititutu.cn/scenes/baihuazhengyan/01.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/02.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/90985d17c1e2f4f398bfc1871f19b945.jpg", "http://resali.lititutu.cn/scenes/baihuazhengyan/03.jpg"]
				],
				"lingxing": ["http://litiresource.xcgogo.site/user/5980171/images/d4b0fbd68a2c9f95b6ac43e1cc5a824f.jpg", "http://litiresource.xcgogo.site/user/5980171/images/54523aedc42f02b2a9003240b05f5169.png"],
				"fanzhuan": [
					"http://resali.lititutu.cn/scenes/baihuazhengyan/00.jpg"
				]
			},
		},
		"lvsexinqing": {
			"type": "full",
			"tid": "lvsexinqing",
			"mid": "nishiwohongchenzhongzuimeideyuan",
			"name": "绿色心情",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/bb0145c4174d8de4d32c33450ca5759d.jpg",
			"cover": "http://litiresource.xcgogo.site/user/6403275/images/27c8acc64d3749c819b197ae6f337003.jpg",
			"mode": {
				"scene": ["fangkuai", "lingxing", "guandao", "tiandi"],
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/lvsexinqing/00.jpg",
					"http://resali.lititutu.cn/scenes/lvsexinqing/01.jpg",
				],
				"lingxing": ["https://resali.lititutu.cn/scenes/lvsexinqing/bffbe4f5ccfa48b0ba580c895fa92338.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/7faf108503bb7a3000673baa8929f9c7.png"],
				"guandao": [
					["", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg"],
					["", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg"],
					["", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg"],
					["", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/87657dde43a8d45b4829a8c265f02992.jpg", "https://resali.lititutu.cn/scenes/lvsexinqing/27511787372202ba7fedf3be7609a350.jpg", "", "", "https://resali.lititutu.cn/scenes/lvsexinqing/fc8f84f2144b7deb403e55b0b15d0a55.jpg", "http://resali.lititutu.cn/scenes/lvsexinqing/02.jpg"]
				],
				"tiandi": ["http://resali.lititutu.cn/scenes/lvsexinqing/03.jpg"],
			},
		},
		"dongrinuanyang": {
			"type": "full",
			"tid": "dongrinuanyang",
			"mid": "nishixinfude",
			"name": "冬日暖阳",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/179da2554a4b61b8d90010f54ca8420d.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/de0e22a2abea0fa3b76606f1030bf55c.jpg",
			"mode": {
				"shuibo": [
					"http://resali.lititutu.cn/scenes/dongrinuanyang/00.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png",
					"http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"
				],
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/dongrinuanyang/02.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/0c96907dac2418c89005138109cb80d7.jpg", 
				],
				"scene": ["fangkuai", "shuibo", "guandao", "fanzhuan"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/3dee50a45ec63fc313ac2afa0634b0ca.jpg", "http://resali.lititutu.cn/scenes/dongrinuanyang/01.jpg", "", "", "http://resali.lititutu.cn/scenes/dongrinuanyang/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/acdd1542a966a44d1db227eb5f92e2e2.jpg"]
				],
				"music": "nuannuan",
				"fanzhuan": ["http://resali.lititutu.cn/scenes/dongrinuanyang/04.jpg"]
			}
		},
		"taohuatanshui": {
			"type": "full",
			"tid": "taohuatanshui",
			"mid": "nishiwohongchenzhongzuimeideyuan",
			"name": "桃花潭水",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/2a5edeb2a67a98b8e28fb5de0311ee95.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/0b5de72bbb3a562f2690f0a1cb342bc7.jpg",
			"mode": {
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/e5decd33050e9f584b5dc6732d37d6ac.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/f95a333570625e8167a34f54e8504bab.jpg", 
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/98c215acd184b7628f78c19ff217afa1.png", "http://litiresource.xcgogo.site/user/5980171/images/692c687ffcaf91b600bc42f7281390d6.png", "http://litiresource.xcgogo.site/user/5980171/images/28cbd94dcd8c8f6a53e06a251d7f1463.jpg"],
				"jiaban": ["http://litiresource.xcgogo.site/user/5980171/images/6ee9267d17a8903799908e39783901ba.jpg", "http://litiresource.xcgogo.site/user/5980171/images/a76db8b836c7be93aae9947144987116.png", "http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png", "http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"],
				"scene": ["jiaban", "xuandong", "fangkuai", "shuibo"],
				"shuibo": ["http://litiresource.xcgogo.site/user/5980171/images/1df51f5968cc3263e9c84494d4f5fc8f.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4d4d16f39c8bf383a1ce69a2e446c220.png", "http://litiresource.xcgogo.site/user/5980171/images/e8fc8fabca699c42f678d0251d6d0b6b.png"],
				"music": "hongyueliang"
			},
		},
		"chongyangjiajie": {
			"type": "full",
			"tid": "chongyangjiajie",
			"mid": "shuilaiaiwo",
			"name": "重阳佳节", // 高山流水
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/225c0d9c9ea19655c3fee80b80853923.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/447139b185848491747bc406cf0bde12.jpg",
			"mode": {
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/2a04c4e4663a97b0e567e393d081fb28.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/c04d82af8d195197749faada9bc4bcb8.jpg", 
				],
				"scene": ["fangkuai", "fanzhuan", "guandao", "lingxing"],
				"guandao": [
					["", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg", "", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg", "", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg", "", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg", "", "", "http://resali.lititutu.cn/scenes/chongyangjiajie/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/e0e47b5dfa9186b41f6a0662224a7c7a.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/66ffe9ad37d35d808bd8b60141a4f07e.jpg", "http://resali.lititutu.cn/scenes/chongyangjiajie/01.jpg"]
				],
				"lingxing": ["http://resali.lititutu.cn/scenes/chongyangjiajie/02.jpg", "http://litiresource.xcgogo.site/user/5980171/images/89003238032c9a9750e85d438ed61923.png"],
				"music": "mengzuihetang",
				"fanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/74b0f6449f4d660bf4465910c0fe0d97.jpg"]
			}
		},
		"shengdankuaile": {
			"type": "full",
			"tid": "shengdankuaile",
			"mid": "jinglebells",
			"name": "圣诞快乐",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/7ca0a9cdf1a8074cb0ebaac34667810e.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/dc095e458ff5d8050022a703604dcb08.jpg",
			"mode": {
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/b31e4ad04b20ee4cfb7a17f9465724fa.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/913faf688785f7c1068c34b029b42aa1.jpg",
				],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/6acaa97ca125189eff25f10624cfff20.jpg"],
				"jiaban": ["http://litiresource.xcgogo.site/user/5980171/images/9ec5c5288ecd52c7f3915ccd2a9ac243.jpg", "http://litiresource.xcgogo.site/user/5980171/images/0e420c03654c5f3a18e1510841b901fd.png", "http://litiresource.xcgogo.site/user/5980171/images/1c5dab070ffbd804755df4be40254b6c.png", "http://litiresource.xcgogo.site/user/5980171/images/2d44a4ca233c64acf91d095d1f066ed6.png"],
				"scene": ["fangkuai", "tiandi", "guandao", "jiaban"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/43f3af8984c31df62d84965dc71d8984.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2e4eb0f2401d253bc2b19087d8255fcf.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/99499ca8603ea6360c5038e26c2cbfb3.jpg", "http://resali.lititutu.cn/scenes/shengdankuaile/01.jpg"]
				],
			},
		},
		"dongzhikuaile": {
			"type": "full",
			"tid": "dongzhikuaile",
			"mid": "ageamei",
			"name": "冬至祝福",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/bdcdc67f5f14daba9ca7ef151403fe6b.jpg",
			"cover": "http://litiresource.xcgogo.site/user/6403275/images/8d2d21f83f3521fb0a8f0825fafb5622.jpg",
			"mode": {
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/dongzhikuaile/00.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/8e78b9beb7c35e83998e9ea970ed37c5.jpg",
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/2532393fa90c676790ce980206e10ecf.png", "http://litiresource.xcgogo.site/user/5980171/images/acb0e7188a9e64e2a0faa6dc6cf67d7c.png", "http://litiresource.xcgogo.site/user/5980171/images/f98f7f22bf88eb6c26220712d95d1d05.jpg"],
				"scene": ["fangkuai", "xuandong", "fanzhuan", "chuang"],
				"music": "hongyueliang",
				"chuang": ["http://litiresource.xcgogo.site/user/5980171/images/057fc22edd395331aa89f637d986ac9a.jpg"],
				// "yuantong": ["http://litiresource.xcgogo.site/user/5980171/images/057fc22edd395331aa89f637d986ac9a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/08dc6bb98d03752d3e609db24236c106.png", "http://litiresource.xcgogo.site/user/5980171/images/abc749de85fa999c306c7e4dd3d030e7.png"],
				"fanzhuan": ["http://resali.lititutu.cn/scenes/dongzhikuaile/01.jpg"]
			},
		},
		"menghuanzhijing": {
			"type": "full",
			"tid": "menghuanzhijing",
			"mid": "xiexienizoujinwodeshijie",
			"name": "梦幻之境",
			"mode": {
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/menghuanzhijing/00.jpg",
					"http://resali.lititutu.cn/scenes/menghuanzhijing/02.jpg",
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/d6d6e65094ce7cc00d8921798b81887a.png", "http://litiresource.xcgogo.site/user/5980171/images/a48763787361daa8f97314f538d5311f.png", "http://litiresource.xcgogo.site/user/5980171/images/ae8d847ee9c59cbd81fc73743616cdcd.jpg"],
				"scene": ["fangkuai", "xuandong", "fanzhuan", "chuang"],
				"music": "meimeida",
				"chuang": ["http://resali.lititutu.cn/scenes/menghuanzhijing/01.jpg"],
				// "yuantong": ["http://resali.lititutu.cn/scenes/menghuanzhijing/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/fdc519aa30b9dbbd160f85fbc4f8d2ed.png", "http://litiresource.xcgogo.site/user/5980171/images/5b131d339301f6fec686c331a1488a43.png"],
				"fanzhuan": ["http://resali.lititutu.cn/scenes/menghuanzhijing/03.jpg"]
			},
		},
		"meihuanyuzhou": {
			"type": "full",
			"tid": "meihuanyuzhou",
			"mid": "yishengchilian",
			"name": "美幻宇宙",
			"mode": {
				"fangkuai": [
					"http://resali.lititutu.cn/scenes/meihuanyuzhou/00.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/46625b82b4be8d3a748dfbf6feefe56c.jpg",
				],
				"xuandong": ["http://litiresource.xcgogo.site/user/5980171/images/d6d6e65094ce7cc00d8921798b81887a.png", "http://litiresource.xcgogo.site/user/5980171/images/a48763787361daa8f97314f538d5311f.png", "http://resali.lititutu.cn/scenes/meihuanyuzhou/01.jpg"],
				"tiandi": ["http://resali.lititutu.cn/scenes/meihuanyuzhou/02.jpg"],
				"scene": ["fangkuai", "tiandi", "chuang", "xuandong"],
				"chuang": ["http://resali.lititutu.cn/scenes/meihuanyuzhou/03.jpg"]
				// "yuantong": ["http://resali.lititutu.cn/scenes/meihuanyuzhou/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/b91400c5c26897b2cf96fd5e4cc7a993.png", "http://litiresource.xcgogo.site/user/5980171/images/c82f903ffee6798388a4331e9e2b7a51.png"]
			}
		},
		"ertongleyuan": {
			"type": "full",
			"tid": "ertongleyuan",
			"mid": "qinqinwodebaobei",
			"name": "儿童乐园",
			"mode": {
				"jiaban": ["http://resali.lititutu.cn/scenes/ertongleyuan/00.jpg", "http://litiresource.xcgogo.site/user/5980171/images/668a6edae0370705ee164b71777d00ac.png", "http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png", "http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"],
				"scene": ["fanzhuan", "guandao", "jiaban", "lingxing"],
				"guandao": [
					["", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg", "", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg", "", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg", "", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg"],
					["", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg", "", "", "http://resali.lititutu.cn/scenes/ertongleyuan/01.jpg", "http://litiresource.xcgogo.site/user/5980171/images/efa3510d77bd9fafdb27a93fb9d78c5d.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/9332aecf8b604b525a52af76676e6639.jpg", "http://resali.lititutu.cn/scenes/ertongleyuan/02.jpg"]
				],
				"lingxing": ["http://resali.lititutu.cn/scenes/ertongleyuan/03.jpg", "http://litiresource.xcgogo.site/user/5980171/images/6aab83646ec28f6df341ccbf600fd0f7.png"],
				"music": "caihongdeyueding",
				"fanzhuan": ["http://resali.lititutu.cn/scenes/ertongleyuan/04.jpg"]
			}
		},
		"qihuanzhijing": {
			"type": "full",
			"tid": "qihuanzhijing",
			"mid": "shuilaiaiwo",
			"name": "奇幻之境",
			"mode": {
				"shuibo": ["http://litiresource.xcgogo.site/user/5980171/images/108ad252f77bfb4883e0734b2d711e95.png", "http://litiresource.xcgogo.site/user/5980171/images/4d4d16f39c8bf383a1ce69a2e446c220.png", "http://litiresource.xcgogo.site/user/5980171/images/e8fc8fabca699c42f678d0251d6d0b6b.png"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/a0560a123851ad51b5a61faedd9efac5.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4169562323b7b52fa73b6a2dbc7bbdbf.png",
				],
				"scene": ["fangkuai", "shuibo", "guandao", "fanzhuan"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/4e34cdf8ceea8fd94ffdd5b455878a0d.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/dd25f0f47c07c0c2b25ff7602c4f1499.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/fe29ec2948da61c98ed4ccfba6908ad5.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/f61f10bf9d7c18c9e76da09103f1cb65.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/fe29ec2948da61c98ed4ccfba6908ad5.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/f61f10bf9d7c18c9e76da09103f1cb65.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/fe29ec2948da61c98ed4ccfba6908ad5.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/f61f10bf9d7c18c9e76da09103f1cb65.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/fe29ec2948da61c98ed4ccfba6908ad5.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/f61f10bf9d7c18c9e76da09103f1cb65.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/fe29ec2948da61c98ed4ccfba6908ad5.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/f61f10bf9d7c18c9e76da09103f1cb65.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/fe29ec2948da61c98ed4ccfba6908ad5.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/f61f10bf9d7c18c9e76da09103f1cb65.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/fe29ec2948da61c98ed4ccfba6908ad5.jpg", "http://litiresource.xcgogo.site/user/6403275/images/204efbf604fc0ea97a6724801c4b89ae.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/f61f10bf9d7c18c9e76da09103f1cb65.jpg", "http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg"]
				],
				"music": "yuanzougaofei",
				"fanzhuan": ["http://resali.lititutu.cn/scenes/qihuanzhijing/00.jpg"]
			}
		},
		"huahuashijie": {
			"type": "full",
			"tid": "huahuashijie",
			"mid": "xiexienizoujinwodeshijie",
			"name": "花花世界",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/f402284abde2cb16b4d21246402cb1a0.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/d182087968998b811b83d208b410bfef.jpg",
			"mode": {
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/c4d2232e8394aacb4f1d7f334b5268d2.jpg",
					"http://litiresource.xcgogo.site/user/6403275/images/c7bb61c4ef724153c194576956c05154.jpg",
				],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/a023e0e5d6a4faaad9040b0a975c659e.jpg"],
				"scene": ["fangkuai", "guandao", "tiandi", "chuang"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/dd6027da289e1d23ff172df4cdff5ebb.jpg", "http://litiresource.xcgogo.site/user/6403275/images/cace001b0b2613306a3e85716b3af0a2.jpg", "", "", "http://litiresource.xcgogo.site/user/7175946/images/abf0d101a9b044d36b657f73a983922a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/f0ee6cebcc5373c5b9dc910141f73541.jpg"]
				],
				"music": "hongyueliang",
				"chuang": ["http://litiresource.xcgogo.site/user/6403275/images/6ce4a1bdbd594b5562fec754ba692737.jpg"]
				// "yuantong": ["http://litiresource.xcgogo.site/user/6403275/images/6ce4a1bdbd594b5562fec754ba692737.jpg", "http://litiresource.xcgogo.site/user/5980171/images/466046d7f91f308ea28a3ce80a1f986e.png", "http://litiresource.xcgogo.site/user/5980171/images/33521bb8abf2fa9ef9ee03a175fa0f8d.png"]
			}
		},
		"jinfengyulu": {
			"type": "full",
			"tid": "jinfengyulu",
			"mid": "laopo",
			"name": "金风玉露",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/57f2f374dbd9552fa000490cdc3423d6.jpg",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/57b27d4c3d5ec877fb55fe89c1a3e65c.jpg",
			"mode": {
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/56182d9cc6dea568876f32ba7702358d.jpg",
					"http://litiresource.xcgogo.site/user/6403275/images/b10bb8431b46ecae14f7cea08a21d5f2.jpg",
				],
				"tiandi": ["http://litiresource.xcgogo.site/user/5980171/images/4df42275c19833ce831473251e33d3c1.jpg"],
				"scene": ["fangkuai", "guandao", "tiandi", "chuang"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/b1ccb8001319fc5c38e4aefac2caf6c5.jpg", "http://litiresource.xcgogo.site/user/5980171/images/74834717355fa32f94dac6c5cc7826f9.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/482fb9179aa4b97d06d87a5176703a8a.jpg", "http://litiresource.xcgogo.site/user/5980171/images/827ac9218516c7eea6ac916a5f4f588b.jpg"]
				],
				"music": "hongyueliang",
				"chuang": ["http://litiresource.xcgogo.site/user/7175946/images/f230175f7b2c85e9df4893a5eb8123d8.jpg"]
				// "yuantong": ["http://litiresource.xcgogo.site/user/7175946/images/f230175f7b2c85e9df4893a5eb8123d8.jpg", "http://litiresource.xcgogo.site/user/5980171/images/795b25f87ca402c487fa40f14a4eb4d0.png", "http://litiresource.xcgogo.site/user/5980171/images/78329f68c568f4c3eb2143bce04f0dc7.png"]
			}
		},
		"xuewuqingyang": {
			"type": "full",
			"tid": "xuewuqingyang",
			"mid": "yishengchilian",
			"name": "霜降冬来",
			"mode": {
			    "scene": [
			        "fangkuai",
			        "tiandi",
			        "chuang",
			        "babianxing",
			    ],
			    "fangkuai": [
			        "http://litiresource.xcgogo.site/user/6403275/images/311b1ae8c08dda78bbe4d0cb87553653.jpg",
			        "http://resali.lititutu.cn/scenes/xuewuqingyang/03.jpg",
			    ],
			    "tiandi": [
			        "http://litiresource.xcgogo.site/user/5980171/images/2d813476d8405abafbffc80f6e8cb219.jpg"
			    ],
			    "chuang": [
			    	"http://resali.lititutu.cn/scenes/xuewuqingyang/02.jpg"
			    	// 'http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg'
			    ],
			    // "guandao": [
			    //     [
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg"
			    //     ],
			    //     [
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg"
			    //     ],
			    //     [
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg"
			    //     ],
			    //     [
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/00.jpg",
			    //         "http://litiresource.xcgogo.site/user/6403275/images/2e7036c12f5df3d32df28b0573ac7d18.jpg",
			    //         "",
			    //         "",
			    //         "http://resali.lititutu.cn/scenes/xuewuqingyang/01.jpg",
			    //         "http://litiresource.xcgogo.site/user/5980171/images/4890ec8dcfad93464ec6ff96615a5d59.jpg"
			    //     ]
			    // ],
			    "babianxing": [
			    	"http://resali.lititutu.cn/scenes/xuewuqingyang/04.jpg"
			    ],
			    // "yuantong": [
			    //     "http://resali.lititutu.cn/scenes/xuewuqingyang/02.jpg",
			    //     "http://litiresource.xcgogo.site/user/5980171/images/478d99f8192eb8ee613d3653a720962c.png",
			    //     "http://litiresource.xcgogo.site/user/5980171/images/70e45c4d110c5275db223d69d39fe397.png"
			    // ]
			}
		},
		"xiyingguoqing": {
			"type": "full",
			"tid": "xiyingguoqing",
			"mid": "3ebe13039a7b4d692bc109fe411ac05c",
			// "mid": "zhufuzuguo",
			"name": "🇨🇳我爱祖国",
			"thumb": "http://litiresource.xcgogo.site/user/8031670/images/caa72046842ad64f2c4582e08b458fb5.jpg",
			"cover": "http://litiresource.xcgogo.site/user/8031670/images/8a33484baa976f516e72e794d28febda.gif",
			"mode": {
				"timeline": [
					'0,scene,fangkuai',
					'9,inter,guoqi',
					'10,scene,lingxing',
					'19,inter,guoqi',
					'20,scene,manping',
					'29,inter,guoqi',
					'30,scene,xiayu',
					'39,inter,guoqi',
					'40,scene,tiandi',
					'49,inter,guoqi',
					'50,end'
				],
			    "decorate": {
			    	"lingxing": {
			    		"gif": [
			    			{
			    				"duration": 2.5,
			    				"width": bw,
			    				"height": bw*1.629,
			    				"backgroundWidth": bw,
			    				"backgroundHeight": bw*30.954,
			    				"sprite_image": {
			    					"im": "http://litiresource.xcgogo.site/theme/xiyingguoqing/baige_low.png",
			    					"steps": 19
			    				}
			    			}
			    		]
			    	},
			    	"xiayu": {
			    		'luo': [
			    			"http://litiresource.xcgogo.site/user/5980171/images/2faf8de255482702b6fff08d410431e1.png",
							"http://litiresource.xcgogo.site/user/5980171/images/539ddcfbf6f33283e5f435b0f2b0ad01.png",
							"http://litiresource.xcgogo.site/user/5980171/images/113b9f6c6d9148887dafa05e4ccce520.png",
						]
			    	},
			    	"changlang": {
			    		'luo': [
			    			'http://litiresource.xcgogo.site/theme/xiyingguoqing/star.png',
			    		]
			    	},
			    	"fangkuai": {
			    		"luo": [
			    			"http://litiresource.xcgogo.site/theme/xiyingguoqing/honghua1.png",
			    			"http://litiresource.xcgogo.site/theme/xiyingguoqing/honghua2.png",
			    		]
			    	},
			    	"manping": {
			    		"luo": [
			    			"http://litiresource.xcgogo.site/theme/xiyingguoqing/huanghua1.png",
			    			"http://litiresource.xcgogo.site/theme/xiyingguoqing/huanghua2.png",	
			    		]
			    	}
			    },
			    "manping": [
			    	{
			        	"type": "gif",
			        	"duration": 2.5,
			        	"width": bw,
			        	"height": bw*2.165,
			        	"backgroundWidth": bw,
			        	"backgroundHeight": bw*2.165*28,
			        	"sprite_image": {
			        		"im": "http://resali.lititutu.cn/scenes/xiyingguoqing/04.jpg",
			        		"steps": 28
			        	}
			        }
			    ],
			    "fangkuai": {
			    	"room": {
			    		"top": {
				        	"gif": {
				        		"type": "gif",
					        	"duration": 2.5,
					        	"width": bw,
					        	"height": bw,
					        	"backgroundWidth": bw,
					        	"backgroundHeight": bw*22,
					        	"sprite_image": {
					        		"im": "http://resali.lititutu.cn/scenes/xinnianxiyangyang/sprite2.jpg",
					        		"steps": 22
					        	},
					        	"cover": "http://resali.lititutu.cn/scenes/xinnianxiyangyang/bg-j-1_1.jpg"
				        	}
				        },
				        "bottom": {
				        	"gif": {
				        		"type": "gif",
								"duration": 2.5,
								"width": bw,
								"height": bw,
								"backgroundWidth": bw,
								"backgroundHeight": bw*23,
								"sprite_image": {
								"im": "http://litiresource.xcgogo.site/theme/xiyingguoqing/sprite.jpg",
								"steps": 23
								},
								"cover": "http://resali.lititutu.cn/scenes/xinnianxiyangyang/bg-j-2_1.jpg"
				        	}
				        }
			    	}
			    },
			    "tiandi": [
			        "http://resali.lititutu.cn/scenes/xiyingguoqing/00.jpg"
			    ],
			    "xiayu": [
			    	{
			    		"type": "gif",
	    				"duration": 2.5,
	    				"width": bw,
	    				"height": bw*1.78,
	    				"backgroundWidth": bw,
	    				"backgroundHeight": bw*53.333,
	    				"sprite_image": {
	    					// "im": "http://litiresource.xcgogo.site/user/5980171/images/af3ae033a72b6a098b1ce94a4be04458.jpg",
	    					"im": "http://resali.lititutu.cn/scenes/xiyingguoqing/05.jpg",
	    					"steps": 30
	    				}
	    			}
				],
				"lingxing": [
					"http://litiresource.xcgogo.site/user/5980171/images/3f1beb0433b2d512be193d2f1f4e11de.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/b0fdfdf1a2645b59b0b9b656ffddd395.png",
				],
			    // "yuantong": [
			    //     "http://litiresource.xcgogo.site/user/5980171/images/3f1beb0433b2d512be193d2f1f4e11de.jpg",
			    //     "http://litiresource.xcgogo.site/user/5980171/images/57666065b5140fc1ff8b8647afb1a7a1.png",
			    //     "http://litiresource.xcgogo.site/user/5980171/images/f63cf538e8fdcf7f9e7cba74a68855cf.png"
			    // ]
			},
			"prelude": {
				"textcolor": "#d0353b"
			}
		},
		'exhi4': {
			'type': 'exhi',
			'tid': 'exhi4',
			'name': '我的回忆',
			// 'bg': 'http://litiresource.xcgogo.site/user/7175946/images/c592bf0b95c931dc523ea8ab89f819cd.jpg',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/117a6a81d73826e20eb2515598caa2c5.jpg',
			'thumb': "http://litiresource.xcgogo.site/user/5980171/images/04afd4f05d3fc70e3b62c2a968af847c.jpg",
			'mid': 'yushangnishiwodeyuan',
			'top_h': '35vh',
			'top_w': '40vh',
			'img_h': '425px',
			'img_w': '360px',
			'box': [400, 400]
		},
		'exhi11': {
			'type': 'exhi',
			'tid': 'exhi11',
			'name': '中国红',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/90cf626f0016f1e73877c85148baf88a.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/846a1ac4456f75ca0b1318c4a5011da4.jpg',
			'mid': 'jintianshinideshengri',
			'top_h': '35vh',
			'top_w': '40vh',
			'img_h': '465px',
			'img_w': '360px'
		},
		'exhi7': {
			'type': 'exhi',
			'tid': 'exhi7',
			'name': '金殿画堂',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/22eea68373067ccfbc5aaaa823d9871b.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/60687c19fd8d5d9f16b0b408bebdc3b2.jpg',
			'mid': 'xiangfengshishouge',
			'top_h': '33vh',
			'top_w': '43vh',
			'img_h': '455px',
			'img_w': '320px',
			'border': 'http://litiresource.xcgogo.site/user/5980171/images/8dbcab7201ba1078ac0a8cf769127a44.png',
			'box': [400, 400]
		},
		'exhi8': {
			'type': 'exhi',
			'tid': 'exhi8',
			'name': '北欧画室',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/d00220f4c5185dd472e21224bfc4a238.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/a8600f8ccfbd47638869faf1aaf55441.jpg',
			'mid': 'enaiyisheng',
			'top_h': '33vh',
			'top_w': '42vh',
			'img_h': '368px',
			'img_w': '286px',
			'border': 'http://litiresource.xcgogo.site/user/5980171/images/defc1d03db4c5d7549faa9fbe85b6212.png',
		},
		'exhi9': {
			'type': 'exhi',
			'tid': 'exhi9',
			'name': '霓虹龙居',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/8a8a9f81a8f1c26496ee78c963487862.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/8aee85541f186045fd646138b3543241.jpg',
			'mid': 'fanshuihetang',
			'top_h': '35vh',
			'top_w': '40vh',
			'img_h': '474px',
			'img_w': '360px',
			'border': 'http://litiresource.xcgogo.site/user/5980171/images/782e7a4a54f9266f433cb578d65050e5.png',
		},
		'exhi10': {
			'type': 'exhi',
			'tid': 'exhi10',
			'name': '金榜题名',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/64f5b61b0e41f36e96c7af2d2a6ee5c1.jpg',
			'thumb': "http://litiresource.xcgogo.site/user/5980171/images/41ecb4d0b9675dbd0bf8d0db7797b6ff.jpg",
			'mid': 'gezaifei',
			'top_h': '35vh',
			'top_w': '40vh',
			'img_h': '379px',
			'img_w': '306px',
			'border': 'http://litiresource.xcgogo.site/user/5980171/images/78dd2a67f732ce45661dfdcade814ca1.gif',
		},
		'exhi0': {
			'type': 'exhi',
			'tid': 'exhi0',
			'name': '美好记忆',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/6edb41fdd133adb5d08aa24cacd4165d.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/c23612b8ed48c33d49ced95434966264.jpg',
			'mid': 'meilidecaoyuanwodejia',
			'top_h': '30vh',
			'top_w': '35vh',
			'img_h': '390px',
			'img_w': '370px'
		},
		'exhi2': {
			'type': 'exhi',
			'tid': 'exhi2',
			'name': '艺术画展',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/061cee1967acfc684f8a187561c56aa0.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/4701487777b2c0c34ec880381e3d4541.jpg',
			'mid': 'zaishuiyifang',
			'top_h': '30vh',
			'top_w': '35vh',
			'img_h': '475px',
			'img_w': '370px'
		},
		'exhi6': {
			'type': 'exhi',
			'tid': 'exhi6',
			'name': '北欧画廊',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/6b44ce016bf8b0e89a851757d88df804.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/1973e42e22baf45e6d6bb271d3eedd16.jpg',
			'mid': 'fanshuihetang',
			'top_h': '34vh',
			'top_w': '40vh',
			'img_h': '405px',
			'img_w': '325px'
		},
		'exhi1': {
			'type': 'exhi',
			'tid': 'exhi1',
			'name': '艺术殿堂',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/febc9057dde8b220e7bef37da5e49bbe.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/d709aad2d5b465682ee04fe85ed248a2.jpg',
			'mid': 'meiyounipeibanzhendehaogudan',
			'top_h': '37vh',
			'top_w': '40vh',
			'img_h': '320px',
			'img_w': '345px'
		},
		'exhi5': {
			'type': 'exhi',
			'tid': 'exhi5',
			'name': '鉴赏香茗',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/5d4d5a66127c44d0cc10c137f6107d1f.jpg',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/e0106266abc503943da2d4e17cdff3df.jpg',
			'mid': 'shangulidesinian',
			'top_h': '35vh',
			'top_w': '40vh',
			'img_h': '425px',
			'img_w': '360px'
		},
		'exhi3': {
			'type': 'exhi',
			'tid': 'exhi3',
			'name': '未来画廊',
			'bg': 'http://litiresource.xcgogo.site/user/5980171/images/fad399f8bfde3ec51c4c5e48e669537d.jpg',
			'thumb': "http://litiresource.xcgogo.site/user/5980171/images/76520d1743b892a9dacab816289e6a35.jpg",
			'mid': 'gezaifei',
			'top_h': '35vh',
			'top_w': '40vh',
			'img_h': '425px',
			'img_w': '360px'
		},
		"xinniandaji": {
			"type": "wscene",
			"tid": "xinniandaji",
			"mid": "f300ab83242391a08c0d499272b0bd65",
			"name": "鼠年大吉3D",
			"scene": {
				"bgimg": "http://resali.lititutu.cn/scenes/xinniandaji/00.gif",
				"midimg": "http://resali.lititutu.cn/scenes/xinniandaji/01.png",
			},
			"prelude": {
				"textcolor": "#d0353b"
			},
			"decorate": {
				"luo": [
					"http://resali.lititutu.cn/scenes/xinniandaji/02.png"
				]
			}
		},
		"gongtingmeijing": {
			"type": "wscene",
			"tid": "gongtingmeijing",
			"mid": "nishiwohongchenzhongzuimeideyuan",
			"name": "宫廷美境",
			"scene": {
				'bgimg': 'http://litiresource.xcgogo.site/user/5980171/images/e8181abaf78f9b4a57724da492e2cc6c.jpg',
				'midimg': 'http://litiresource.xcgogo.site/user/5980171/images/6cf44f3e43009f2acacea496689e0a03.png',
				'elems': [
					[4, 'w-wawa w-ele', 'http://litiresource.xcgogo.site/user/5980171/images/d385c57db4784bac5fb254c5583bb158.png'],
					[15, 'w-wawa w-ele', 'http://litiresource.xcgogo.site/user/5980171/images/d385c57db4784bac5fb254c5583bb158.png']
				]
			}
		}, 
		"huayudiemeng": {
			"type": "wscene",
			"tid": "huayudiemeng",
			"name": "花语蝶梦",
			"mid": "9694a4ec8eb03a524e876761291bc9a1",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/3b3405c9d833bbe74580aadcb0a5e3ff.jpg",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/ccb04ff551997735f52dbea2773b1d91.jpg",
			"scene": {
				'bgimg': 'http://litiresource.xcgogo.site/user/5980171/images/08a25a5b00beb6717fdc61b73bf5b55f.jpg',
				'midimg': 'http://litiresource.xcgogo.site/user/5980171/images/a7e3c1b90dca321963093e2703feec47.png'
			}
		}, 
		"shuguangdengta": {
			"type": "wscene",
			"tid": "shuguangdengta",
			"name": "曙光灯塔",
			"mid": "yelaixiang",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/0d85398992425b6612f927a4c917942b.jpg",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/7cadffc387b24373e9b275ab00425d66.jpg",
			"scene": {
				'bgimg': 'http://litiresource.xcgogo.site/user/5980171/images/5bed03b898b6e1614918032995b79d50.jpg',
				'midimg': 'http://litiresource.xcgogo.site/user/5980171/images/8408cca10b92656ea8751b56c6b9063c.png'
			}
		}, 
		"xingfuxuege": {
			"type": "wscene",
			"tid": "xingfuxuege",
			"name": "幸福雪歌",
			"mid": "wanwandeyueliang",
			"cover": "http://litiresource.xcgogo.site/user/5980171/images/a61f0353db17c04666c35baec9e62825.jpg@0-0-630-625a_80Q.src",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/2fb8b630eb8b620627ee2785f39eee4d.jpg",
			"scene": {
				'bgimg': 'http://litiresource.xcgogo.site/user/5980171/images/cdf1cbec1490c63dac434823a8ee7443.jpg',
				'midimg': 'http://litiresource.xcgogo.site/user/5980171/images/0a3c72b0fd78c8777449b93cd90938ce.png',
				'elems': [
					[3, "w-xueren w-ele", "http://litiresource.xcgogo.site/user/5980171/images/bc7fae064d7356f8eb897684d69c0a5b.png"],
					[11, "w-xueren w-ele", "http://litiresource.xcgogo.site/user/5980171/images/bc7fae064d7356f8eb897684d69c0a5b.png"],
					[17, "w-xueren w-ele", "http://litiresource.xcgogo.site/user/5980171/images/bc7fae064d7356f8eb897684d69c0a5b.png"],
					[22, "w-milu w-ele", "http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png"],
					[10, "w-milu w-ele", "http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png"],
					[15, "w-milu w-ele", "http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png"],
					[20, "w-milu w-ele", "http://litiresource.xcgogo.site/user/5980171/images/79dac311fb4cb2951c72c61258931827.png"]
				]
			}
		},
		"funvjie2": {
			"type": "xc",
			'tid': 'funvjie2',
			'name': '女人节快乐',
			'room': {
				'top': 'http://litiresource.xcgogo.site/user/5980171/images/88e098a83b70355ffd9b7f8a722f1cc8.jpg',
				'bottom': 'http://litiresource.xcgogo.site/user/5980171/images/93b4518d558b5eb332e0ff89e2aaf0a6.jpg',
			},
			'mid': 'nvrenhua',
			'thumb': 'http://litiresource.xcgogo.site/user/5980171/images/7238d315d032a4011f1e9088ebd3d7fe.jpg'
		},
		"langmanqixi": {
			'type': 'full',
			'tid': 'langmanqixi',
			'mid': 'edbb927ca50943254b033c7183423eca',
			'name': '浪漫七夕',
			'thumb': 'http://litiresource.xcgogo.site/user/9546085/images/3b0987c95cb78736be3def749f6a5d0d.jpg',
			'mode': {
				'scene': ["fangkuai", "zhuanxin", "shuibo", "xuanzhuan"],
				'fangkuai': [
					'http://litiresource.xcgogo.site/user/9546085/images/a71d776a196ddc2402825dc202cdb725.jpg',
					'http://litiresource.xcgogo.site/user/9546085/images/bb83665702f7c177cc489507aae3803a.jpg',
				],
				"zhuanxin": [
					'http://litiresource.xcgogo.site/user/9546085/images/a9b55681b3111925fc57d5e37ae7b90c.jpg'
				],
				"shuibo": ["http://litiresource.xcgogo.site/user/9546085/images/942f3a17291cd3a5c218941b756afdf1.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/9546085/images/a8b024a2ac098de6eec72527f95cd832.jpg"],
			}
		},
		"xiazhikuaile": {
			"type": "full",
			"tid": "xiazhikuaile",
			"mid": "268c1989267be66cdf8d480ff6d3a754",
			"name": "夏至祝福",
			"thumb": "http://litiresource.xcgogo.site/user/6403275/images/075e3cba75cc4e54ba02ab40de7d673e.jpg",
			"mode": {
				"scene": ["fangkuai", "chuang", "fanzhuan", "xuandong"],
				"fangkuai": ["http://litiresource.xcgogo.site/user/6403275/images/6c217370498fd4842647b7cab542f59b.jpg", "http://litiresource.xcgogo.site/user/6403275/images/b3f3937f7e5a3331934c977f5f2fd872.jpg"],
				"chuang": [
					'http://litiresource.xcgogo.site/user/6403275/images/ce4cebd67cecdacdd181b52a19098cf7.png',
					'http://litiresource.xcgogo.site/user/6403275/images/2cff68e92fe5cd49712f7101af992186.jpg',
				],
				"fanzhuan": ["http://litiresource.xcgogo.site/user/6403275/images/d8721683e984bf4128a26b790c528ddf.jpg"],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/6403275/images/130bd5cfc7223eab87a72a4b85957a4e.png",
					"http://litiresource.xcgogo.site/user/6403275/images/b5b36944dfb036950dbd3979d6dc3072.png",
					"http://litiresource.xcgogo.site/user/6403275/images/ffa28b70db4453f15ca56b607b5258e8.jpg"
				],
			}
		},
		"fuqinjiexiangce": {
			"type": "full",
			"tid": "fuqinjiexiangce",
			"mid": "07de1c3a8a5d4eb2ba4d11e18a29dfed",
			"name": "父亲节相册",
			"thumb": "http://litiresource.xcgogo.site/user/6403275/images/912a753d1ef4388bdb3ed25c7e004498.jpg",
			"mode": {
				"scene": ["fangkuai", "shuibo", "fanzhuan", "baozha"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/6403275/images/011865519ce4fdb6767338762798ef51.jpg",
					"http://litiresource.xcgogo.site/user/6403275/images/d0e2d74c1677d353b2dfd75215e70bf2.jpg",
				],
				"shuibo": ["http://litiresource.xcgogo.site/user/6403275/images/0c556853c76f2e301ce4a83342b494a8.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"fanzhuan": ["http://litiresource.xcgogo.site/user/6403275/images/9eeaaf995249720fa228290b2e71bc18.jpg"],
				"baozha": ["http://litiresource.xcgogo.site/user/6403275/images/3e91d72847c88e5894a6f97f01e33828.jpg"]
			}
		},
		"duanwuankang": {
			"type": "full",
			"tid": "duanwuankang",
			"mid": "691fd3af0f97d587d94ecc69b2006728",
			"name": "端午安康",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/6a94b3071585f537d58069b69f87a461.jpg",
			"mode": {
				"scene": ["fangkuai", "shuibo", "jiaban", "fanzhuan"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/1ee1f2b1f8991cfa9c24f8551a1eba3e.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/f02ac6d3f22db3128e470d2290ee03e5.jpg",
				],
				"shuibo": ["http://litiresource.xcgogo.site/user/5980171/images/0d6ec26bc75b28c0a219279b358f8077.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/929d715883dc6e2c8af88a3c3b44f5ef.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/1995fb3f445d9eafe682e42c81641f7c.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"fanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/fd92878adca6a2f6fcdc9886db65ea89.jpg"]
			}
		},
		"laoertongjierikuaile": {
			"type": "full",
			"tid": "laoertongjierikuaile",
			"mid": "46b2a646f16ba2df1efca42ca84e0cfb",
			"name": "老玩童节日快乐",
			"thumb": "http://litiresource.xcgogo.site/user/6403275/images/725139b7346a960d1e4a46fe8663dfcd.jpg",
			"mode": {
				"scene": ["fangkuai", "xuandong", "tiandi", "baozha"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/6403275/images/f1757f782bd503e17158920bae656dde.jpg",
					"http://litiresource.xcgogo.site/user/6403275/images/54e5be0c4ba017e4f6a4a05596aef076.jpg",
				],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/6403275/images/987bb3854824f7487bcf005deccf128d.png",
					"http://litiresource.xcgogo.site/user/6403275/images/9727da3f295119d5483a52ebf26dd494.png",
					"http://litiresource.xcgogo.site/user/6403275/images/347f22ce1f5e950a9fac7cbefa90bcda.jpg"
				],
				"tiandi": ["http://litiresource.xcgogo.site/user/6403275/images/5ad03637e51b4855268f2d24b5ef44f4.jpg"],
				"baozha": ["http://litiresource.xcgogo.site/user/6403275/images/2b506b2651652b798a8e87cd48d079ac.jpg"],
			}
		},
		"xiaomanzhufu": {
			"type": "full",
			"tid": "xiaomanzhufu",
			"mid": "4d01b4c87bbf01a3f8dae8e145b159be",
			"name": "小满祝福",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/1eed58348c78f286c80daeec3b20c3d0.jpg",
			"mode": {
				"scene": ["fangkuai", "pingbox", "fanzhuan", "xuandong", "babianxing"],
				"xuandong": [
					"http://litiresource.xcgogo.site/user/5980171/images/468538bdb53061afed5cd74d5b2b8de1.png",
					"http://litiresource.xcgogo.site/user/5980171/images/5622946b313cc795b8da5bd253ed2062.png",
					"http://litiresource.xcgogo.site/user/5980171/images/462b28e8412e0d686505943c7956c9b5.jpg"
				],
				"babianxing": ["http://litiresource.xcgogo.site/user/5980171/images/c0142c0f12b18a553f0c86a9652738fc.jpg"],
				"fanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/7c9b08ca6c8c9d71255ae42cacf83d63.jpg"],
				"pingbox": [
					"http://litiresource.xcgogo.site/user/5980171/images/c3e004e0ecdf0d8052f5fb17cf03acf9.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/1d89b5b543d48ecc497aa8ce249a563f.png",
					[
						'http://litiresource.xcgogo.site/user/5980171/images/7fdfc9371820182ebd694d7a4d14d357.png',
					],
				],
				"fangkuai": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/a83f76bfb318bd8fc38ae3792a2be0f7.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/09fdf05d5fb118490bee74c64858604e.jpg",
							"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						}
					}
				}
			}
		},
		"muqinjiekuaile": {
			"type": "full",
			"tid": "muqinjiekuaile",
			"mid": "cixiangdemuqin",
			"name": "母亲祝福",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/25c993e3686e2e91b72e51197fb1c8d6.png",
			"mode": {
				"scene": ["fangkuai", "guandao", "zhuanxin", "jiaban"],
				"guandao": [
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg"],
					["", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/a3fcca60644435d4c99baef702e6b6ad.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg", "", "", "http://litiresource.xcgogo.site/user/5980171/images/2bf5799b62fafd5b34a27112abf0fb8e.jpg", "http://litiresource.xcgogo.site/user/5980171/images/1bc0f2a6d88ed66e92245c1fa6c6ee45.jpg"],
				],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/d24746d698a859012c996cccb24abecc.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/09f0c77e461239cfee82a92bd3f62c6b.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"fangkuai": {
					"room": {
						"top": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/d02f187668e963484054784fe28e6c73.jpg"
						},
						"bottom": {
							"im": "http://litiresource.xcgogo.site/user/5980171/images/b236a357cbaa88b35b1175782bd57855.jpg",
							"elems": [
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.426, bw*0.382],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [-(bw*0.015), bw*0.454],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/8c13cb1f2c0a0d6374ed067deeedc01f.png",
									"pos": [bw*0.435, bw*0.049],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.285, bw*0.082],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.161, bw*0.347],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/9d2debe05cc7347ead081bf7529fe7e8.png",
									"pos": [bw*0.54, bw*0.5],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.083, bw*0.0125],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*.356, bw*0.314],
									"classfunc": "getScaleHuaAni"
								},
								{
									"im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png",
									"pos": [bw*0.017, bw*0.457],
									"classfunc": "getScaleHuaAni"
								},
								{
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/3513da04aabb09067ba7ccaebe8b7f37.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * -0.049, bw * 0.35],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.736, bw * 0.619],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e5dd5c14a0628326ec2cf948b142554f.png", 
							        "pos": [bw * 0.662, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.624, bw * 0.329],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.158, bw * 0.418],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.346, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.006, bw * 0.371],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.638, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.093, bw * 0.007],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.165, bw * 0.21],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * -0.042, bw * 0.447],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.064, bw * 0.583],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.59, bw * 0.699],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * 0.164, bw * -0.008],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.347, bw * -0.019],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * -0.083, bw * -0.014],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.458, bw * 0.562],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.179, bw * -0.056],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.344, bw * 0.561],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.024, bw * 0.204],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * -0.046, bw * 0.646],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.694, bw * 0.122],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/c2ffbaad09e78b02f17eb7876eea913b.png", 
							        "pos": [bw * 0.494, bw * -0.029],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.533, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.275, bw * 0.129],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ad3d85e4442575e2fdbb7641e271e45e.png", 
							        "pos": [bw * 0.222, bw * 0.588],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/1a001f2ed1645a5ae35981113909cc83.png", 
							        "pos": [bw * 0.682, bw * 0.253],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/48f0f139ad1f17bcd17949ab8a0f5f77.png", 
							        "pos": [bw * 0.538, bw * 0.067],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/ddb497a5fb80e43344500ef68f6f2501.png", 
							        "pos": [bw * 0.115, bw * 0.65],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/e915dab4f204da2991110b37d9ae14cb.png", 
							        "pos": [bw * -0.086, bw * 0.247],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/55bd221e789290a80a206707468e058e.png", 
							        "pos": [bw * 0.41, bw * 0.69],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/cb804dfc3e998874eae57a55aaa40395.png", 
							        "pos": [bw * 0.217, bw * 0.732],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.29, bw * 0.328],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/8390db3c8519fca7b632a054948ab47c.png", 
							        "pos": [bw * 0.428, bw * 0.483],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/b7e5e7cbffe1e6084055c123ab91d522.png", 
							        "pos": [bw * 0.256, bw * 0.657],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/6b3d2bec0ee373268a4985e6dbd0b592.png", 
							        "pos": [bw * 0.078, bw * 0.136],
							        "classfunc": "getScaleHuaAni"
							    }, 
							    {
							        "im": "http://litiresource.xcgogo.site/user/5980171/images/91b7cbd52c487f0e2882e3d54a01138b.png", 
							        "pos": [bw * 0.474, bw * 0.722],
							        "classfunc": "getScaleHuaAni"
							    }
							]
						}
					},
					"room2": {
						"huabanshan": {
							"gradient": ["#bcbec0", "#eca5b2", "#fd1a94"]
						}
					}
				},
				"zhuanxin": ["http://litiresource.xcgogo.site/user/5980171/images/a80e8136d8ece514ff8967674adc70a4.jpg"]
			}
		},
		"lixiakuaile": {
			"type": "full",
			"tid": "lixiakuaile",
			"mid": "cba077573716d16b32676669a321486f",
			"name": "立夏快乐",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/76c71904d0419168987c3ec30f7d88f9.jpg",
			"mode": {
				"scene": ["fangkuai", "xuanzhuan", "shuibo", "xinbox", "chuang"],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/9b509b609986c022c0761de1f7a168b8.jpg"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/7ddfc65ecbac4d05f70a2f137d0e92bf.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/eb135bb5a0f639b9c2dc941befe448f4.jpg",
				],
				"shuibo": ["http://litiresource.xcgogo.site/user/5980171/images/e4b99998c5e381886524b8937e0baa55.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"xinbox": ["http://litiresource.xcgogo.site/user/5980171/images/193ff885569ac3fc917972c8849215c1.jpg"],
				"chuang": [
					'http://litiresource.xcgogo.site/user/5980171/images/024737f82140f97ff45f0b79376cbb65.jpg'
				],
			}
		},
		"huanduwuyi": {
			"type": "full",
			"tid": "huanduwuyi",
			"mid": "e35d0ac8f213be5c3b8597b0fbb1fffc",
			"name": "欢度五一",
			"thumb": "http://litiresource.xcgogo.site/user/7175946/images/bf6dd47d9323211f44c95404800431a4.jpg",
			"mode": {
				"scene": ["fangkuai", "jiaban", "shuibo", "tiandi"],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/7175946/images/3e86a569ec9ec7e05aa9e65c434bde94.jpg",
					"http://litiresource.xcgogo.site/user/7175946/images/ad196db833d10c7377cb461f5ff48081.jpg",
				],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/7175946/images/72ad22f70ce56c56c2e63bb6b86ae330.jpg",
					"http://litiresource.xcgogo.site/user/7175946/images/ec70131f58d221730e9b25435c4fbb4a.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"shuibo": ["http://litiresource.xcgogo.site/user/7175946/images/7505f33d3ab013f2c9e6a259826ec571.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"tiandi": ["http://litiresource.xcgogo.site/user/7175946/images/75ab0df1e401b2b50a14528086439d21.jpg"],
			}
		},
		"guyu": {
			"type": "full",
			"tid": "guyu",
			"mid": "3ab06cb1d4a0e8298cfd343f79886dd5",
			"name": "谷雨时节",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/467090af732e2ef025d050dce71f84f1.jpg",
			"mode": {
				"scene": ["xuanzhuan", "shuibo", "baozha", "chuang", "fangkuai", "fanshu"],
				"xuanzhuan": ["http://litiresource.xcgogo.site/user/5980171/images/8bc6c956d8f7f2dafff77da9fa5f710b.jpg"],
				"shuibo": ["http://litiresource.xcgogo.site/user/5980171/images/b92982244e41fba81259f5e916d62e70.jpg", "http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png", "http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"],
				"baozha": ["http://litiresource.xcgogo.site/user/5980171/images/f17bb58f3d973eb142456f6564880db1.jpg"],
				"chuang": [
					'http://litiresource.xcgogo.site/user/5980171/images/5526234ed515d1cdc3ccddfea812a6ae.jpg'
				],
				"fangkuai": [
					"http://litiresource.xcgogo.site/user/5980171/images/fa979c351998b1ea52df44da898eb868.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/0905c09c6bf29d8b1f2657c42539f49d.jpg",
				],
				"fanshu": ["http://litiresource.xcgogo.site/user/5980171/images/20e78f17f25e1701171949c9ddfb4334.jpg", "http://litiresource.xcgogo.site/user/5980171/images/b8999c403e8408c59bd8770e490f029f.png"],
			}
		},
		"chunfenshijie": {
			"type": "full",
			"tid": "chunfenshijie",
			"mid": "chunnuanhuakai",
			"name": "春分时节",
			"thumb": "http://litiresource.xcgogo.site/user/5980171/images/564f958da0088b1c32c08cd777b2c681.jpg",
			"mode": {
				"scene": ["jiaban", "chuang", "shuibo", "lingxing"],
				"chuang": [
					'http://litiresource.xcgogo.site/user/5980171/images/63202c10a4308b5ce6ae2c01eb0d982e.jpg',
					'http://litiresource.xcgogo.site/user/5980171/images/b0674a10184e2328400457ddcec4de84.png'
				],
				"lingxing": [
					"http://litiresource.xcgogo.site/user/5980171/images/8899298958b9150e7446ead6541d3495.jpg",
					"https://resali.lititutu.cn/scenes/lvsexinqing/7faf108503bb7a3000673baa8929f9c7.png"
				],
				// "yuantong": [
				// 	"http://litiresource.xcgogo.site/user/5980171/images/22f860dc473b1e6b46a33653b9773b3d.jpg",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/6cecba53f894cd0be14286aa93078325.png",
				// 	"http://litiresource.xcgogo.site/user/5980171/images/dffd365d4375ad887a9322d88a4ced54.png"
				// ],
				"jiaban": [
					"http://litiresource.xcgogo.site/user/5980171/images/47262f0f4897cbaea376c9fdef9407f1.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/477b972512672fcfbb48d96ef0695f6a.png",
					"http://litiresource.xcgogo.site/user/5980171/images/23ffeb331ece6d73466511cea325eb1d.png",
					"http://litiresource.xcgogo.site/user/5980171/images/4c53a23f213addae653040d4131f6f53.png"
				],
				"shuibo": [
					"http://litiresource.xcgogo.site/user/5980171/images/f554c46a358709ae54e29259bb2c2f82.jpg",
					"http://litiresource.xcgogo.site/user/5980171/images/4c1cc4d28e3d7d3b2c17f5d519900526.png",
					"http://litiresource.xcgogo.site/user/5980171/images/6bcb397af49f3f20f70b8d775497f2e2.png"
				],
			}
		},
		"labakuaile": {
	    	"tid": "labakuaile",
	    	"name": "腊八快乐",
	        "room": {
	        	"top": "http://litiresource.xcgogo.site/user/5980171/images/5a9bd4d92310eea96c31aadfe4b1a773.jpg", 
	        	"bottom": "http://litiresource.xcgogo.site/user/5980171/images/cbc0c1b80773bfeeca2070b3809c1f3c.jpg"
	        },
	        "type": "xc", 
	        "thumb": "http://litiresource.xcgogo.site/user/5980171/images/84e7d336280d1c8a871fc4fa6e0d3e0f.jpg", 
	        "mid": "guolelabajiushinian", 
	    },
	}
*/