import { Base64 } from 'js-base64';
import st_login_base from './st_login.base'

/*
 @options {
     "params": {
         "action": "xxx"
     },
     "otherthings": "do somethings"
 }
 */

class stLogin extends st_login_base.stLoginBase {
    constructor (util) {
        super(util)
        // 微信登陆页
        this.wx_link = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid={appid}&redirect_uri={redirect_uri}&response_type=code&scope={scope}&state={state}#wechat_redirect';
        // 微信登陆产品落地页
        this.auth_path = util.$config.ISDEBUG ? "https://wxauth.litiskr.cn/wxauth/auth_dev" : "https://wxauth.litiskr.cn/wxauth/auth";
        this.auth_url = this.auth_path + "?redirect_url={redirect_url}&token={token}&scope={scope}"
        this.options = {};
        this.after_login = Boolean(util.getUrlQuery('userid')); // 是否进行了跳转登录流程
    }
    /**
     * 隐式登录
     */
    async base (options) {
        this.options = options || {};
        let user = this.getBaseInfo();

        if (user) {
            return Promise.resolve(user);
        } else {
            return this.login('snsapi_base');
        }
    }
    /**
     * 显式登录
     */
    async userinfo (options) {
        this.options = options || {};
        let user = await this.getUserInfo().catch(err=>{debugger;});
        if (user && user['nickname']) {
            return Promise.resolve(user);
        } else {
            // login_sign中的appid、openid无用户昵称信息则回到h5登录配置参数
            this.appid = this.util.$config['__APPID__']
            this.openid = ''
            return this.login('snsapi_userinfo');
        }
    }
    getRedirectUrl () {
        let url = window.location.href
                    .replace(/&userid=\w+/, '')
                    .replace(/&actbtn=\w+/, '');
        if (this.options['params']) {
            for (let key in this.options.params) {
                url += "&" + key + "=" + this.options.params[key];
            }
        }
        // 去除链接中的#
        url = url.replace("#", "");
        return url;
    }
    login (scope) {

        return new Promise(resolve => {
            
            // userid是跳转微信登录落地页后，转存到服务端的登陆信息的id
            let userid = this.util.getUrlQuery('userid');
    
            if (userid && !(scope == 'snsapi_userinfo' && userid.indexOf('userinfo') == -1)) { // 存在userid且userinfo模式下、userid不为userinfo的userid
                // 有userid 从服务端获取登陆信息
                this.util.rget('https://wxauth.litiskr.cn/wxauth/get_user_by_userid', {
                    userid: userid,
                    wxauth_appid: this.appid
                }).then(response => {
                    let user = response['user'];
                    
                    this.setUserInfo(user);
                    resolve(user);
                })
            } else {
                // 若显式登陆，先判断是否已经有隐式登陆的openid，则可以直接
                // 无userid 跳转微信登陆获取
                let state = parseInt(new Date().getTime()/1000);
                let token = this.util.md5(window.location.href + state.toString());
                let auth_url = this.auth_url.replace('{redirect_url}', encodeURIComponent(Base64.encode( this.getRedirectUrl() )));
                auth_url = auth_url.replace('{token}', token);
                auth_url = auth_url.replace('{scope}', scope);
        
                let wx_link = this.wx_link.replace('{appid}', this.appid);
                wx_link = wx_link.replace('{redirect_uri}', encodeURIComponent(auth_url));
                wx_link = wx_link.replace('{scope}', scope);
                wx_link = wx_link.replace('{state}', state);
        
                // window.location.href = wx_link;
                window.location.replace(wx_link);
            }
        })
    }
    get loginSign () {
        let loginSign = {};
        let login_sign = this.util.getUrlQuery('login_sign');
        if (login_sign) {
            loginSign = {
                "appid": login_sign.split('_',1)[0],
                "openid": login_sign.split('_',1)[1]
            }
        }
        return loginSign;
    }
}

export default {
    stLogin
};