<template>
    <div class="daoshu2-container re-container" v-show="count_down_started">
        <!-- 天 -->
        <template v-if="comp_config[4] && leftd != ['0']">
            <template v-for="(date, index) in leftd">
                <img class="daoshu2-date-text"
                    :key="'daoshu2-date-text_' + index"
                    :style="dateStyle" :src="comp_config[2][date]" />
            </template>
            <img class="daoshu2-date-text"
                :style="dayStyle" :src="comp_config[4]" />
        </template>    
        <!-- 时 -->
        <template v-if="comp_config[5] && lefth != ['0']">
            <template v-for="(date, index) in lefth">
                <img class="daoshu2-date-text"
                    :key="'daoshu2-hour-text_' + index"
                    :style="dateStyle" :src="comp_config[2][date]" />
            </template>
            <img class="daoshu2-date-text"
                :style="dayStyle" :src="comp_config[5]" />
        </template>
        <!-- 分 -->
        <template v-if="comp_config[6] && leftm != ['0']">
            <template v-for="(date, index) in leftm">
                <img class="daoshu2-date-text"
                    :key="'daoshu2-min-text_' + index"
                    :style="dateStyle" :src="comp_config[2][date]" />
            </template>
            <img class="daoshu2-date-text"
                :style="dayStyle" :src="comp_config[6]" />
        </template>
        <!-- 秒 -->
        <template v-if="comp_config[7]">
            <template v-for="(date, index) in lefts">
                <img class="daoshu2-date-text"
                    :key="'daoshu2-sec-text_' + index"
                    :style="dateStyle" :src="comp_config[2][date]" />
            </template>
            <img class="daoshu2-date-text"
                :style="dayStyle" :src="comp_config[7]" />
        </template>
    </div>
</template>

<script>
import Daoshu from "./Daoshu"
export default {
    name: 'daoshu',
    extends: Daoshu,
    data () {
        return {
        }
    },
    computed: {
        dateStyle() {
            return this.getDaoshuStyle(this.comp_config[1])
        },
        dayStyle() {
            return this.getDaoshuStyle(this.comp_config[3])
        }
    },
    mounted () {
        
    },
    methods: {
        getDaoshuStyle(item) {
            let s = {};
            if (!item) {
                return s;
            }
            if(this.$util.typeOf(item) == 'object'){
                return this.getItemStyle(s);
            }
            if(this.$util.typeOf(item) == 'array'){
                s['width'] = this.$util.getPx(item[0]);
                s['height'] = this.$util.getPx(item[1]);
            }
            return s;
        }
    }
}
</script>

<style>
.daoshu2-container{
    justify-content: center!important;;
}
.daoshu2-date-text{
    display: block;
}
</style>