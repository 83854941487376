<template>
    <div class="st-open-tag poc">
        <slot></slot>
        <template v-if="tag == 'launch_weapp' && can_use_open_tag && originid">
            <wx-open-launch-weapp :id="getId" :username="originid" :path="path" @ready="handleReady" @launch="handleLaunch" @error="handleError">
            </wx-open-launch-weapp>
        </template>
        <template v-if="tag == 'subscribe' && can_use_open_tag && channel_ids">
            <wx-open-subscribe :template="getTemplateidsStr" :id="getId">
            </wx-open-subscribe>
        </template>
        <div v-if="!(tag_ready && can_use_open_tag)" class="poc" v-html="getTagHtml"></div>
    </div>
</template>

<script>
    export default {
        name: 'stopentag',
        props: {
            tag: {
                type: String,
                default: "launch_weapp",//launch_weapp/subscribe/audio
            },
            originid: {
                type: String,
                default: "",
            },
            path: {
                type: String,
                default: "",
            },
            html: {
                type: String,
                default: ""
            },
            tagstyle: {
                type: String,
                default: ""
            },
            channel_ids: {
                type: Array,
                default () {
                    return []
                }
            }
        },
        data () {
            return {
                can_use_open_tag: true,
                result_html: "",
                tag_ready: false,
            }
        },
        computed: {
            getChannelIdMap () {
                let app_channel_map = {
                    "wxdb7036358ef60c03": ['','EKP-h27Wh0yYRfcaH9TyOu-rOHuQg5hRR6Z9pHmF3ls','y32Twn-_UatJ6lkZz7W4_b1y3iNDlmn0iOL4d4saYds','Lt41JIg_XshkC26KpE7zPtVkG7swHiNS0Heu8Xp3q_k','','0mbQwuDiZlvzet0sV8qpieVaBKeYvW9vuL8uh8lcjEE','VYwvxCj4YJ0ZbNG8f6xyNsBop_VhtaGsVoZfiRYHbFw'],//情歌
                    "wx1d92d19bce2bbfca": ['','IZ92s31UIRzn6UfAS4PwwVdujZBFvQ8s0tmD0524HO8','TolH2G39PvQeZl7LK7UFDq3FlZ2BGD-QjXxuGy6ExD8','lllLFxelly8vJWML32iu6k8C_FdJQ-kFXZ4a8RrWXNM','','MH5R5QXl0n-WGZyXYXZdxay1jgtdELEv-2HLZcjwbZI','wmMASz7kOq5eV9-HYliY93uT7-HzrvsbCFEZXyUM5R8'],
                    "wxe981e0b795e48f24": ['','D9sWLNmtH0ucVis_LnYWpuV2fNFlxYu2zyPVlW0rgnA','sIdjqqJtca6hABTwLkE7hpofgmcWOV8XHmQhAdQF7PY','db8mgv5OmuazvhnKQ-uBGUn0BoVxnBmgn_LC9hucfGE','','1SQo8e0WhLqtkhDrI3U2RpE8J04rf2cHiOuLLpENTSg','aeZlKCvo8ASDSaF-bxwgT8ZODxWyKdxCMDholxl5q7I'],//早晚安
                }
                let ids = wxConfigMsg["appId"] ? app_channel_map[wxConfigMsg["appId"]] : []
                return ids;
            },
            getTagHtml () {
                return this.tag_ready && this.can_use_open_tag ? '' : '<style>' + this.tagstyle + '</' + 'style>' + this.html;
            },
            getId () {
                return this.tag + this.$util.md5(this.html);
            },
            getTemplateids () {
                let templateids = [];
                let mapids = this.getChannelIdMap;
                for (let i = 0 ; i < this.channel_ids.length ; i++){
                    if (mapids[this.channel_ids[i]]){
                        templateids.push( mapids[this.channel_ids[i]] )
                    }
                }
                return templateids
            },
            getTemplateidsStr () {
                return this.getTemplateids.join(",")
            }
        },
        mounted () {
            if (!openTagsAble || (this.tag == "launch_weapp" && !this.originid)) { //不加载开放标签
                this.can_use_open_tag = false;
            } else if (this.tag == "subscribe" && !this.getChannelIdMap) {
                this.can_use_open_tag = false;
            } else { //可加载
                this.initBtn()
            }
        },
        methods: {
            initBtn () {
                let _this = this;
                var btn = document.getElementById(this.getId);
                console.log("btn=>",this.getId)
                btn.innerHTML = '<script type="text/wxtag-template"><style>' + this.tagstyle + '</' + 'style>' + this.html + '</' + 'script>';
                if (this.tag == "subscribe"){
                    this.tag_ready = true;
                    btn.addEventListener('success', function (e) {
                        _this.$emit("clickHandle")
                    });   
                    btn.addEventListener('error',function (e) {
                        _this.$emit("clickHandle")
                    });
                }
            },
            handleReady (options) {
                this.tag_ready = true;
                this.$emit("ready", options)
            },
            handleLaunch (options) {
                this.$emit("launch", options)
            },
            handleError (err) {
                this.$emit("error", err)
            },
        },
    }
</script>

<style>
.st-open-tag{
}
</style>
