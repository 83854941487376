<template>
	<div id="room2" class="po">
		<div id="panorama2" class="po" :style="{ transform: getRotateDeg }">
			<huaban :mode="mode['huaban']" v-if="mode['huaban']"></huaban>
			<hudie5 :mode="mode['hudie']" v-if="mode['hudie']"></hudie5>
			<elems :mode="mode['elems']" :rotateDeg="rotateDeg" v-if="mode['elems']" :currentImage="currentImage"></elems>
			<fall :mode="mode['fall']" v-if="mode['fall']"></fall>
			<piao :mode="mode['piao']" v-if="mode['piao']"></piao>
			
			<RoomTop :theme="mode"></RoomTop>
			<RoomBottom :theme="mode"></RoomBottom>
		</div>
	</div>
</template>

<script>
	import elems from './elems'
	import fall from './fall'

	import RoomTop from '../room/RoomTop'
	import RoomBottom from '../room/RoomBottom'

	export default {
		name: 'room2',
		props: ['rotateDeg', 'mode', 'currentImage'],
		components: {
			elems,
			fall,

			RoomTop,
			RoomBottom
		},
		data () {
			return {}
		},
		computed: {
			// showMap () {
			// 	let map = {}
			// 	for (var c in this.mode) {
			// 		map[c] = true;
			// 	}
			// 	return map;
			// },
			getRotateDeg () {
				if (this.mode['isRotate'] === false) {
					return 'none';
				} else {
					return 'rotateY('+this.rotateDeg+'deg)';
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	#room2 {
		height: 100%; width: 100%;
		perspective: @bw * .83;
		overflow: hidden;
		pointer-events: none;
	}
	#panorama2 {
		width: @bw;
		height: @bw;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		transform-style: preserve-3d;
		cursor: pointer;
	}
</style>