class stPreload {
    constructor (util) {
        this.$util = util;

        this.loading = false;
        this.finished = false;

        this.config = this.$util.$config['st_config']['preload'] || {};
    }

    stInit (options) {
        let _this = this;
        this.$util.$loger.log("uni_onlaunch", options || {});
        this.$util.$loger.log("uni_preload", {
            "step": "start"
        })
        return new Promise((resolve, reject)=>{
            if (!_this.loading){
                _this.loading = true;
                _this.preloadInit().then(()=>{
                    _this.$util.$loger.log("uni_preload", {
                        "step": "success"
                    })
                    _this.finished = true;
                    _this.loading = false;
                    _this.handleUrlQuery(options).catch(err=>{});
                    _this.$util.$eventCenter.trigger("PRELOAD_FINISH", {});
                    resolve()
                }).catch(err => {
                    _this.loading = false;
                    _this.$util.$loger.log("preload_err", {
                        "err": err && err.message,
                        "stack": err && err.stack
                    })
                    _this.$util.$eventCenter.trigger("NET_WORK_ERROR", {});
                    resolve()
                })
            }
        })
    }

    async preloadInit () {
        let missions = [];
        // 先进行登录操作
        if (this.config['need_wxlogin_first'] === 1) {
            await this.wxLogin();
        } else {
            missions.push( this.wxLogin() ); // 默认进行登陆逻辑
        }

        if (!(this.config['need_app_config'] === 0)) {
            missions.push( this.getAppConfig() ); // 默认获取app配置
        }
        // missions.push( this.handleUrlQuery() ); // 默认获取页面参数，后置

        // 小程序默认操作
        if (this.$util.isEnv('wxa')) {}
        
        // h5默认操作
        if (this.$util.isEnv('h5')) {
            missions.push( this.$util.$wx.jsload() ) // 默认进行微信sdk初始化
        }

        // 引入页面级preload
        if (this.$util.local_preload) {
            missions.push( this.$util.local_preload() )    
        }
        // 引入应用级preload
        if (this.$util.app_preload) {
            missions.push( this.$util.app_preload() )
        }

        return Promise.all(missions)
    }

    async getAppConfig () {
        let config = await this.$util.$config.center.get_app_config().catch(err=>{});
        if (!config) {
            return Promise.reject({
                "err": "get_app_config_fail"
            })
        }
        this.$util.$store.commit('setAppConfig', {'appConfig': config});
        //实验组配置在app_config中
        await this.$util.$wx.initExptInfo().catch(err=>{});
        return Promise.resolve();
    }

    async wxLogin () {
        if ( this.$util.isEnv('wxa') ) {
            // return this.$util.$login.base();
            return this.$util.$login.checkUserInfo();
        } else if ( this.$util.isEnv('h5') ) {
            // return this.$util.$login.getBaseInfo();
            return this.$util.$login.getUserInfo();
        }
    }

    // async listenHashChange () {
    //     let _this = this;
    //     window.addEventListener('hashchange',function(e) {
    //         _this.$util.$eventCenter.trigger("HASH_CHANGE", {
    //             "oldURL": e.oldURL,
    //             "newURL": e.newURL
    //         });
    //         // console.log(e.oldURL);  console.log(e.newURL)
    //     }, false);
    //     return Promise.resolve()
    // }

    async handleUrlQuery (options) {
        if (this.$util.isEnv("h5")) { 
            return Promise.resolve();
        }
        // 推送系统订阅打开上报
        // let querys = this.$util.getUrlQuerys();
        let {
            query
        } = options || (wx.getLaunchOptionsSync && wx.getLaunchOptionsSync()) || {};
        if (query && query.push_id) {
            this.$util.$loger.log("uni_submessage_open", {
                "push_id": query.push_id
            })
            this.$util.stPushReport(query.push_id, "open")
        }
        return Promise.resolve();
    }
}

export default {
    stPreload
}