<template>
	<div class="dangao_con">
		<div class="dangao">
			<div class="dangao_bg" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
			<div class="dangao_con01">
				<div class="dangao_each01" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
				<div class="dangao_each01" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
				<div class="dangao_each01" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
				<div class="dangao_each01" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
				<div class="dangao_each01" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
				<div class="dangao_each01" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
				<div class="dangao_top01"></div>
			</div>
			<div class="dangao_con02">
				<div class="dangao_each02 dangao_userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="dangao_each02 dangao_userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="dangao_each02 dangao_userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="dangao_each02 dangao_userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="dangao_each02 dangao_userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="dangao_each02 dangao_userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="dangao_top02"></div>
			</div>
			<div class="dangao_con03">
				<div class="dangao_each03" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="dangao_each03" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="dangao_each03" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="dangao_each03" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="dangao_each03" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="dangao_each03" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="dangao_top03"></div>
			</div>
			<div class="dangao_con05">
				<div class="dangao_star"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'dangao',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		mounted () {},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.dangao_con01>.dangao_each01:nth-child(1) {transform: rotateY(0deg) translateZ(@bw * 0.517)}
	.dangao_con01>.dangao_each01:nth-child(2) {transform: rotateY(60deg) translateZ(@bw * 0.517)}
	.dangao_con01>.dangao_each01:nth-child(3) {transform: rotateY(120deg) translateZ(@bw * 0.517)}
	.dangao_con01>.dangao_each01:nth-child(4) {transform: rotateY(180deg) translateZ(@bw * 0.517)}
	.dangao_con01>.dangao_each01:nth-child(5) {transform: rotateY(240deg) translateZ(@bw * 0.517)}
	.dangao_con01>.dangao_each01:nth-child(6) {transform: rotateY(300deg) translateZ(@bw * 0.517)}

	.dangao_con02>.dangao_each02:nth-child(1) {transform: rotateY(0deg) translateZ(@bw * 0.396)}
	.dangao_con02>.dangao_each02:nth-child(2) {transform: rotateY(60deg) translateZ(@bw * 0.396)}
	.dangao_con02>.dangao_each02:nth-child(3) {transform: rotateY(120deg) translateZ(@bw * 0.396)}
	.dangao_con02>.dangao_each02:nth-child(4) {transform: rotateY(180deg) translateZ(@bw * 0.396)}
	.dangao_con02>.dangao_each02:nth-child(5) {transform: rotateY(240deg) translateZ(@bw * 0.396)}
	.dangao_con02>.dangao_each02:nth-child(6) {transform: rotateY(300deg) translateZ(@bw * 0.396)}

	.dangao_con03>.dangao_each03:nth-child(1) {transform: rotateY(0deg) translateZ(@bw * 0.24)}
	.dangao_con03>.dangao_each03:nth-child(2) {transform: rotateY(60deg) translateZ(@bw * 0.24)}
	.dangao_con03>.dangao_each03:nth-child(3) {transform: rotateY(120deg) translateZ(@bw * 0.24)}
	.dangao_con03>.dangao_each03:nth-child(4) {transform: rotateY(180deg) translateZ(@bw * 0.24)}
	.dangao_con03>.dangao_each03:nth-child(5) {transform: rotateY(240deg) translateZ(@bw * 0.24)}
	.dangao_con03>.dangao_each03:nth-child(6) {transform: rotateY(300deg) translateZ(@bw * 0.24)}

	.dangao_con, .dangao {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.dangao {
		transform-style: preserve-3d;
		transform: rotateX(-9deg);
	}
	.dangao_bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		transform: rotateX(8deg) translateZ(-@bw * 0.694);
	}
	.dangao_con01 {
		position: absolute;
		top:@bw * 1.033;
		left: 50%;
		margin-left: -@bw * 0.299;
		width:@bw * 0.597;
		height:@bw * 0.403;
		transform-style: preserve-3d;
		animation: dangao_rotate_01 20s infinite linear;
	}
	@keyframes dangao_rotate_01 {
		from {
			transform: rotateY(0deg);
		}
		to {
			transform: rotateY(1turn);
		}
	}
	.dangao_each01 {
		position: absolute;
		top: 0;
		left: 0;
		width:@bw * 0.597;
		height:@bw * 0.403;
		overflow: hidden;
	}
	.dangao_top01 {
		position: absolute;
		top: -@bw * 0.517;
		width:@bw * 0.597;
		height:@bw * 1.033;
		transform: rotateX(90deg);
		background: hsla(0,0%,100%,.7);
	}
	.dangao_top01:after, .dangao_top01:before{
		width: 0;
		height: 0;
		content: "";
		position: absolute;
		top: 0;
		border-top:@bw * 0.517 solid transparent;
		border-bottom:@bw * 0.517 solid transparent;
	}
	.dangao_top01:before {
		left: -@bw * 0.299;
		border-right:@bw * 0.299 solid hsla(0,0%,100%,.7);
	}
	.dangao_top01:after {
		right: -@bw * 0.299;
		border-left:@bw * 0.299 solid hsla(0,0%,100%,.7);
	}
	.dangao_con02 {
		position: absolute;
		top:@bw * 0.574;
		left: 50%;
		margin-left: -@bw * 0.229;
		width:@bw * 0.458;
		height:@bw * 0.458;
		transform-style: preserve-3d;
		animation: dangao_rotate_02 20s infinite linear;
	}
	@keyframes dangao_rotate_02 {
		from {
			transform: rotateY(0deg);
		}
		to {
			transform: rotateY(-1turn);
		}
	}
	.dangao_each02 {
		position: absolute;
		left: 0;
		top: 0;
		width:@bw * 0.458;
		height:@bw * 0.458;
		overflow: hidden;
		background: no-repeat 50%;
		background-size: cover;
	}
	.dangao_top02 {
		position: absolute;
		top: -@bw * 0.399;
		width:@bw * 0.458;
		height:@bw * 0.793;
		transform: rotateX(90deg);
		background: hsla(0,0%,100%,.7);
	}
	.dangao_top02:after, .dangao_top02:before{
		width: 0;
		height: 0;
		content: "";
		position: absolute;
		top: 0;
		border-top:@bw * 0.396 solid transparent;
		border-bottom:@bw * 0.396 solid transparent;
	}
	.dangao_top02:before {
		left: -@bw * 0.226;
		border-right:@bw * 0.229 solid hsla(0,0%,100%,.5);
	}
	.dangao_top02:after {
		right: -@bw * 0.226;
		border-left:@bw * 0.229 solid hsla(0,0%,100%,.7);
	}

	.dangao_con03 {
		position: absolute;
		top:@bw * 0.29;
		left: 50%;
		margin-left: -@bw * 0.139;
		width:@bw * 0.278;
		height:@bw * 0.278;
		transform-style: preserve-3d;
		animation: dangao_rotate_01 20s infinite linear;
	}
	.dangao_each03 {
		position: absolute;
		top: 0;
		left: 0;
		width:@bw * 0.278;
		height:@bw * 0.278;
		overflow: hidden;
	}
	.dangao_top03 {
		position: absolute;
		top: -@bw * 0.24;
		width:@bw * 0.278;
		height:@bw * 0.481;
		transform: rotateX(90deg);
		background: hsla(0,0%,100%,.7);
	}
	.dangao_top03:after, .dangao_top03:before{
		width: 0;
		height: 0;
		content: "";
		position: absolute;
		top: 0;
		border-top:@bw * 0.24 solid transparent;
		border-bottom:@bw * 0.24 solid transparent;
	}
	.dangao_top03:before {
		left: -@bw * 0.139;
		border-right:@bw * 0.139 solid hsla(0,0%,100%,.7);
	}
	.dangao_top03:after {
		right: -@bw * 0.139;
		border-left:@bw * 0.139 solid hsla(0,0%,100%,.7);
	}
	.dangao_con05 {
		position: absolute;
		top:@bw * 0.092;
		left: 50%;
		margin-left: -@bw * 0.104;
		width:@bw * 0.208;
		height:@bw * 0.208;
		transform-style: preserve-3d;
		animation: dangao_rotate_02 4s infinite linear;
	}
	.dangao_star {
		position: absolute;
		--clip-path: polygon(50% 0%,61% 35%,98% 35%,68% 57%,79% 91%,50% 70%,21% 91%,32% 57%,2% 35%,39% 35%);
		--transform: translateY(-4vmin);
		background: linear-gradient(90deg,#ad7f0b 50%,#f2ba2c 0);
		-webkit-clip-path: var(--clip-path);
		top: 0;
		left: 0;
		width:@bw * 0.208;
		height:@bw * 0.208;
		overflow: hidden;
	}
</style>