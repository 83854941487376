<template>
    <!-- fanpai -->
	<div class="fanpai poc" :style="elemStyles">
	   	<div class="perspective poc bg">
			<div
				v-for="idx of 8"
        		:key="'fanpai_'+idx"
				class="fanpai-page1 preserve3d po">
				<div class="po fanpai-page1_item" :class="'fanpai-page1_item_'+idx" style="width: 299.081px; height: 300px;">
					<div
						class="fanpai-border poc bg" 
						:style="getBorderStyle"></div>
                    <elem v-if="comp_config[2]" :mode="comp_config[2]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
				</div>
			</div>
	   </div>
       <div class="fanpai_center po" style="width: 230px;">
           <elem v-if="comp_config[1]" :mode="comp_config[1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
       </div>
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'fanpai',
        extends: BaseElem,
		data () {
			return {}
		},
		computed: {
			getBorderStyle(){
                let s = {
                            "backgroundImage": 'url(' + this.currentPhoto['im'] +')',
							"borderImageSource": "url("+ (this.comp_config[0] || 'https://resali.lititutu.cn/icons/other/border.jpg') +")"
                        };
                return s;
			}	
		},
		methods: {
		}
	}
</script>

<style>
	.fanpai-page1 {
		width: 600px;
		height: 600px;
		top: calc(50% - 300px);
		left: calc(50% - 300px);
	}
	.fanpai-page1_item_1 {
	    transform: rotateZ(0);
	    animation: fanpai_page1v2 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item_2 {
	    transform: rotateZ(45deg);
	    animation: fanpai_page1v3 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item_3 {
	    transform: rotateZ(90deg);
	    animation: fanpai_page1v4 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item_4 {
	    transform: rotateZ(135deg);
	    animation: fanpai_page1v5 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item_5 {
	    transform: rotateZ(180deg);
	    animation: fanpai_page1v6 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item_6 {
	    transform: rotateZ(225deg);
	    animation: fanpai_page1v7 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item_7 {
	    transform: rotateZ(270deg);
	    animation: fanpai_page1v8 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item_8 {
	    transform: rotateZ(315deg);
	    animation: fanpai_page1v1 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1_item {
	    margin: auto;
	    left: 0;
	    right: 0;
	    bottom: 300px;
	    transform-origin: bottom;
	}
	@keyframes fanpai_page1v2 {
		0% {
		    transform: translateZ(0.2px) rotateZ(45deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.2px) rotateZ(45deg) rotateX(0deg);
		}
	}
	@keyframes fanpai_page1v3 {
		0% {
	    transform: translateZ(0.4px) rotateZ(90deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.4px) rotateZ(90deg) rotateX(0deg);
		}
	}
	@keyframes fanpai_page1v4 {
		0% {
		    transform: translateZ(0.6px) rotateZ(135deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.6px) rotateZ(135deg) rotateX(0deg);
		}
	}
	@keyframes fanpai_page1v5 {
		0% {
		    transform: translateZ(0.8px) rotateZ(180deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.8px) rotateZ(180deg) rotateX(0deg);
		}
	}
	@keyframes fanpai_page1v6 {
		0% {
		    transform: translateZ(1px) rotateZ(225deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(1px) rotateZ(225deg) rotateX(0deg);
		}
	}
	@keyframes fanpai_page1v7 {
		0% {
		    transform: translateZ(1.2px) rotateZ(270deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(1.2px) rotateZ(270deg) rotateX(0deg);
		}
	}
	@keyframes fanpai_page1v8 {
		0% {
		    transform: translateZ(1.4px) rotateZ(315deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(1.4px) rotateZ(315deg) rotateX(0deg);
		}
	}
	@keyframes fanpai_page1v1 {
		0% {
		    transform: translateZ(0) rotateZ(0) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0) rotateZ(0) rotateX(0deg);
		}
	}
	.fanpai_center {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	}
	.fanpai-border {
		z-index: 2;
		border: 3px solid;
		/* border-image: url(https://resali.lititutu.cn/icons/other/border.jpg); */
		border-image-slice: 3;
	}
</style>