class install {
    constructor (util) {
        this.$util = util;
    }
    // 异步
    async do (options) {
        let action = new stAction(this.$util, options);
        return action.do();
    }
    // // 同步
    // doSync (options) {
    // }
}


class stAction {
    constructor (util, options) {
        this.$util = util;
        this.options = options;
    }
    async do () {
        let _do = this.options['do'] || "",
            _does = [];
        
        if (this.$util.typeOf(_do) == "string") { // 适配老版本字符串形式的do
            _do = [Object.assign({
                "action": _do,
            }, this.options)];
        } else if (this.$util.typeOf(_do) == "object") { // 适配一个 do 的情况
            _do = [_do];
        }
        
        for (let i = 0 ; i < _do.length ; i++) {
            // 参数合并
            // console.log("_do[", i, "]=> " ,_do[i]);
            
            let the_do = _do[i];

            if (this.$util.typeOf(the_do) == "array") {
                the_do = the_do.randomChoice();
            }

            let action = the_do["action"];
            switch (action) {
                case "link":
                    _does.push( this._doLink(the_do) );
                    break;
                case "wxa":
                    _does.push( this._doWxa(the_do) );
                    break;
                case "login":
                    _does.push( this._doLogin(the_do) );
                    break;
                case "login_base":
                    _does.push( this._doLoginBase(the_do) );
                    break;
                case "toast":
                    _does.push( this._doToast(the_do) );
                    break;
                case "toast_modal":
                    _does.push( this._doToastModal(the_do) );
                    break
                case "incentive_video":
                    _does.push( this._doIncentiveVideo(the_do) );
                    break;
                case "download":
                    _does.push( this._doDownLoad(the_do) );
                    break;
                case "submsg":
                    _does.push( this._doSubmsg(the_do) );
                    break;
                case "pay":
                    _does.push( this._doPay(the_do) );
                    break;
                case "sph":
                    _does.push( this._doSph(the_do) );
                    break;
                case "inter":
                    _does.push( this._doInter(the_do) );
                    break;
                case "custom_server":
                    _does.push( this._doCustomServer(the_do) )
                    break;
                case "save_album":
                    _does.push( this._doSaveAlbum(the_do) );
                    break;
                case "into_view":
                    _does.push( this._doIntoView(the_do));
                    break;
                case "preview_image":
                    _does.push( this._doPreviewImage(the_do));
                    break;
                case "trigger_event":
                    _does.push( this._doTriggerEvent(the_do));
                    break;
                case "make":
                    _does.push( this._doFunc(the_do) );
                    break;
                case "func":
                    _does.push( this._doFunc(the_do) );
                    break;
                case "module":
                    _does.push( this._doModule(the_do) );
                    break;
                case "log":
                    _does.push( this._doLog(the_do) );
                    break;
                default:
                    // share，contact
                    _does.push( Promise.resolve(true) )
                    break;
            }
        }
        // // 全部do完成后检测条件是否必须中了才resolve
        // let res = await Promise.all( _does ).catch(err=>{
        //     console.log("err=>",err);
        // });
        // return res ? Promise.resolve(res) : Promise.reject();
        return Promise.all( _does );
    }

    // 转跳链接
    async _doLink (options) {
        options = options || {};
        let path = options['path'], // path字段支持关键字判断
            extraData = options['extraData'] || {},
            method = options['method'] || "navigateTo";

        let link_idx = options['link_idx'];
        
        if (this.$util.typeOf(path) == 'array') {
            if (link_idx === "" || link_idx === undefined || link_idx === null) {
                // link_idx不存在，则不执行跳转
                return Promise.resolve("ok");
            } else if (link_idx == "random") {
                path = path.randomChoice();
            } else {
                // link_idx存在，执行对应的路径跳转
                path = path[ parseInt(link_idx) ];
            }
        }

        let query = options['query'];
        if (query) {
            if (this.$util.typeOf(query) == 'array' && link_idx != undefined) {
                query = query[ parseInt(link_idx) ];
            }
            let querys = this.$util.setUrlQuery( query );
            if (querys) {
                path = path + "?" + querys;
            }
        }

        switch (method){
            case "navigateBack":
                this.$util.$router.navigateBack(options);
                break;
            case "redirectTo":
                this.$util.$router.redirectTo({
                    "url": path,
                })
                break;
            case "reLaunch":
                this.$util.$router.reLaunch({
                    "url": path,
                })
                break;
            case "navigateBackMiniProgram":
                wx.navigateBackMiniProgram({
                    "extraData": extraData
                })
                break;
            default://navigateTo
                this.$util.$router.navigateTo({
                    "url": path,
                });
                break;
        };
        return Promise.resolve("ok");
    }

    // 转跳小程序
    async _doWxa (options) {
        let _this = this,
            {
                appid,
                path,
                app,
                extraData,
                noRelaunchIfPathUnchanged,
                embedded
            } = options || {};
        
        // 适配本app跳转dolink
        if (app &&
            this.$util.$condition.check({
                "conditions": {
                    "if": {
                        "app": app
                    }
                }
            })
        ) {
            if (options.self_path) {
                options['path'] = options.self_path;
            }
            return this._doLink(options);
        }

        // 适配直接填写app不填写appid
        if (!appid && app) {
            const APP_MAP = this.$util.$config.app_config.app_map || {
                "wh": "wxdbd4be54f43cd528",
                "vliti": "wx2fb7317f6bc5b387",
                "shenbieffect": "wx3c87504da3679263",
                "gsj": "wx2ee9ce78ad6409c4",
                "op": "wx2403251aeda52199",
                "bl": "wxccf22927c0fea651"
            };
            appid = APP_MAP[app];
        }

        if ( _this.$util.isEnv("wxa") ) {
            let res,error;
            if (embedded) {
                res = await wx.openEmbeddedMiniProgram({
                    appId: appid,
                    path: path,
                    extraData: extraData ? extraData : {},
                    noRelaunchIfPathUnchanged: noRelaunchIfPathUnchanged != undefined ? noRelaunchIfPathUnchanged : false,
                }).catch(err=>{
                    console.warn("openEmbeddedMiniProgram err==>", err)
                })
            }
            if (!res) {
                res = await wx.navigateToMiniProgram({
                    appId: appid,
                    path: path,
                }).catch(err=>{
                    error = err;
                })
            }
            if (!res) {
                if (options['require']) {
                    return Promise.reject(error);
                } else {
                    return Promise.resolve(error);
                }
            } else {
                return Promise.resolve(res || "do wxa success");
            }
        } else if ( _this.$util.isEnv("webview") ) {
            _this._doLink(options);
            return Promise.resolve("webview do wxa");
        } else { // 开放标签转跳
            return Promise.resolve("open tag open wxa");
        }
    }

    // 显式登录
    async _doLogin (options) {
        // 如果是事件传递则需要用showModel衔接点击事件传递
        let userinfo = await this.$util.$login.userinfo({
            "params": options.redirectParam
        }).catch(err=>{
            console.error("login err=>",err)
        });

        if (userinfo) {
            if (options['toast']) {
                this._doToast({
                    "title": "登录成功"
                })
            }
            return Promise.resolve(userinfo);
        } else {
            return options['require'] ? Promise.reject("fail") : Promise.resolve("fail");
        }
    }

    // 隐式登陆
    async _doLoginBase (options) {
        let base = this.$util.$login.base({
            "params": options.redirectParam//this.getRedirectParam()
        }).catch(err=>{})
        
        if (base) {
            return Promise.resolve(base);
        } else {
            return options['require'] ? Promise.reject("fail") : Promise.resolve("fail");
        }
    }

    // 弹窗显示
    async _doToast (options) {
        let argvs = (options && options.argvs) || options || {};
        if (options && options['toast_id']) {
            let params = {};
            params[ options['toast_id'] ] =  options['act'] == "show" ? 1 : 0;
            this.$util.$store.commit("setPageDatas", params);
            return Promise.resolve()
        } else {
            argvs = Object.assign({
                "duration": 1500,
                "title": "操作成功",
                "icon": "success"
            }, argvs)
            return await this.$util.$taro.showToast(argvs);
        }
    }

    // 弹出系统确认弹框
    async _doToastModal(options) {
        let _this = this;
        return new Promise((resolve, reject)=>{
            _this.$util.$taro.showModal({
                title: options.toast && options.toast.title ? options.toast.title : '提示',
                content: options.toast && options.toast.content ? options.toast.content : '',
                showCancel: options.toast && options.toast.showCancel == false ? false : true,
                confirmText: options.toast && options.toast.confirmText ? options.toast.confirmText : "确定",
                success: res=> {
                    if (res.confirm){
                        resolve("confirm")
                    } else {
                        options['require'] ? reject("cancel") : resolve("cancel");
                    }
                }, 
                fail: err=>{
                    options['require'] ? reject("fail") : resolve("fail");
                }
            })
        })
    }

    // 激励视频广告
    async _doIncentiveVideo (options) {
        options = Object.assign(options, {
            "type": "video"
        })
        this.$util.$loger.log("uni_ad", {
            "action": "click",
            "ad_type": "video"
        })
        
        // // 开发环境跳过激励视频展示环节
        // if (this.$util.$config.env_version == "develop") {
        //     this.$util.$taro.showToast({
        //         "title": "开发环境暂时跳过激励视频",
        //         "icon": "none",
        //         "duration": 3000
        //     })
        //     return Promise.resolve();
        // }

        return this.$util.$ad.show(options);
    }

    // 插屏广告
    async _doInter (options) {
        this.$util.$ad.show(options).catch(err=>{
            debugger;
        });
        return Promise.resolve(true);
    }

    // 微信客服
    async _doCustomServer (options) {
        return new Promise((resolve, reject) => {
            let params = {
                extInfo: options['extInfo'],
                corpId: options['corpId'],
                fail: function(err) {
                    console.log("openCustomerServiceChat err==>",err)
                    options['require'] ? reject("fail") : resolve("fail");
                },
                success: function(res) {
                    resolve(true)
                }
            }
            if (options['messageCard']) {
                params['showMessageCard'] = true;
                params['sendMessageTitle'] = options['messageCard']['title'];
                params['sendMessagePath'] = options['messageCard']['path'];
                params['sendMessageImg'] = options['messageCard']['img'];
            }
            this.$util.$taro.openCustomerServiceChat(params);
        })
    }

    // 下载媒体
    async _doDownLoad (options) {
        let urls = this.$util.typeOf(options.url) == "string" ? [options.url] : options.url,
            paths = [];
        for (let i=0 ; i < urls.length ; i++){
            let path = await new Promise((resolve, reject) => {
                wx.downloadFile({
                    url: urls[i],
                    success(res) {
                        resolve(res.tempFilePath)
                    },
                    fail(err) {
                        reject(err);
                    }
                })
            })
            paths.push(path);
        }
        for (let i=0 ; i < paths.length ; i++){
            let path = paths[i];
            await new Promise((resolve, reject)=>{
                wx.saveImageToPhotosAlbum({
                    filePath: path,
                    success(data) {
                        resolve();
                    },
                    fail(err) {
                        if (err.errMsg.indexOf('auth') != -1) {
                            wx.showModal({
                                title: '提示',
                                content: '请先点击授权保存到相册',
                                showCancel: false,
                                success: ()=> {
                                    wx.openSetting({
                                        success: setting=>{
                                            if (setting.authSetting['scope.writePhotosAlbum']){
                                                 wx.saveImageToPhotosAlbum({
                                                    filePath: path,
                                                    success(data) {
                                                        resolve();
                                                    },
                                                    fail(err) {
                                                        reject()
                                                    }
                                                 })
                                            } else {
                                                wx.showToast({
                                                    title: '未打开授权保存到相册',
                                                    icon: 'none',
                                                    duration: 4000
                                                })    
                                            }
                                        },
                                        fail: ()=>{
                                            wx.showToast({
                                                title: '未打开授权保存到相册',
                                                icon: 'none',
                                                duration: 4000
                                            })
                                        }
                                    })
                                }
                            })
                        } else if (err.errMsg.indexOf('cancel') != -1) {  
                        } else {
                            wx.showToast({
                                title: '操作失败',
                                icon: 'none'
                            })
                        }
                    }
                })
            })
        }
        wx.showToast({
            title: '保存成功！',
            icon: 'success',
            duration: 4000
        })
        return Promise.resolve("保存成功！")
    }

    // 订阅消息
    async _doSubmsg (options) {
        options = options || {};
        let acceptedids = [];
        if ( this.$util.isEnv('wxa') ) {
            let scene_config = options['scene_config'] || {},
                params = Object.assign({
                    "ids": options['channel_ids'] || []
                }, scene_config);
            acceptedids = await this.$util.requareTemplate(params).catch(err=>{});
        }
        if (!acceptedids) {
            return options['require'] ? Promise.reject("fail") : Promise.resolve("fail");
        } else {
            if (options['toast']) {
                this._doToast({
                    "title": "订阅成功"
                })
            }
        }
        return Promise.resolve(acceptedids)
    }

    // 转跳视频号
    async _doSph (options) {
        options = options || {};
        let params = options['argvs'] || options,
            api = options['api'] || "openChannelsActivity";
        return new Promise((resolve, reject)=>{
            // 打开视频号视频
            if (api == "openChannelsActivity" && wx.openChannelsActivity) {
                wx.openChannelsActivity(Object.assign(params,{
                    success: res=>{
                        resolve(res);
                    },
                    fail: err=>{
                        options['require'] ? reject(err) : resolve(err);
                    }
                }))
            // 打开视频号主页
            } else if (api == "openChannelsUserProfile" && wx.openChannelsUserProfile) {
                wx.openChannelsUserProfile(Object.assign(params,{
                    success: res=>{
                        resolve(res);
                    },
                    fail: err=>{
                        options['require'] ? reject(err) : resolve(err);
                    }
                }))
            // 打开视频号活动页面
            } else if (api == "openChannelsEvent" && wx.openChannelsEvent) {
                wx.openChannelsEvent(Object.assign(params,{
                    success: res=>{
                        resolve(res);
                    },
                    fail: err=>{
                        options['require'] ? reject(err) : resolve(err);
                    }
                }))
            } else {
                options['require'] ? reject("api undefined") : resolve("api undefined");
            }
        })
    }

    // 支付
    async _doPay(options) {
        // 支持实验组
        let {
            st_channel_id,
            expt
        } = options;
        if (expt && this.$util.$wx.wxExpt && this.$util.$wx.wxExpt[expt]) {
            st_channel_id = "stpaychannel_" + this.$util.$config.__APP__ + "_" + this.$util.$wx.wxExpt[expt];
        }
        if ( this.$util.isEnv('webview') ){
            let st_order_id = this.$util.getUrlQuery("st_order_id", this.$util.getUrlHash("st_order_id")),
                order_used = st_order_id && this.$util.$global.get("st_order_" + st_order_id) ? true : false;
            if (st_order_id && !order_used) {
                let res = await this.$util.rpost(this.$util.$config.FC_HOST + "stpay/st_pay_api",{
                    "__api__": "query",
                    "app": this.$util.$config.__APP__,
                    "appid": this.$util.$login.appid,
                    "openid": this.$util.$login.openid,
                    "st_channel_id": st_channel_id,
                    "st_order_id": st_order_id
                }).catch(err=>{})
                this.$util.$global.set("st_order_" + st_order_id, 1);
                if (!res || res['trade_state'] != "SUCCESS") {
                    this.$util.$taro.showToast({
                        title: '支付失败',
                        icon: 'none',
                        duration: 3000
                    });
                    return options['require'] ? Promise.reject("fail") : Promise.resolve("fail");
                } else {
                    return Promise.resolve({
                        "params": {
                            "st_order_id": st_order_id,
                            "st_channel_id": st_channel_id
                        }
                    })
                }
            } else if (!st_order_id || (st_order_id && order_used)) {
                this.$util.$router.navigateTo({
                    "url": "/subpackages/wxpay/wxpay?st_channel_id=" + st_channel_id + "&" + this.$util.setUrlQuery( options.redirectParam )
                })
                return Promise.resolve()
            }

        } else { // h5 / wxa
            let res = await this.$util.rpost(this.$util.$config.FC_HOST + "stpay/st_pay_api",{
                "__api__": "unify_order",
                "app": this.$util.$config.__APP__,
                "appid": this.$util.$login.appid,
                "openid": this.$util.$login.openid,
                "st_channel_id": st_channel_id
            })
            /**
             * 
             *  返回值参考
                {
                    "package": "prepay_id=wx26182236750005737f15fccce9fd500000",
                    "timeStamp": "1643192556",
                    "signType": "RSA",
                    "paySign": "MI+LJE//hwZ0fDg9tq0cUErTSam1vBsYmJjDLKLwAcYQrhgXrvvR0/owXX+1U9mYwz0PIFy5jw9st9SI6kM9ciZvzPIkeRGjHc8w9ajpZ3uYkcHSD5OsineTJPu+njTSZCwScC1Wb+xh80H5V+fes2zCiowPB6PZdkRrJhMsuExJCFoafMig5aL6SFCJ1NIMZDAXtAVTpbctmpovsCQII76DRgfMf5md/CstRNf1FyrwRqSKmWQT6/koUJIiIpn6+wNUlx5RqCiWElojj8YGoubEQAJS939LbxVnuDgnyqFlGXzlZHIeOe7FtKgjLfA/78sLGEl+OJATiiXRS9jNHg==",
                    "appId": "wx2fb7317f6bc5b387",
                    "nonceStr": "6f16293fe445353ff9de68442118e0"
                }
             */
            let pay_res = await this.wxpay(res).catch(err=>{});

            // 埋点
            this.$util.$loger.log("uni_pay", {
                "status": pay_res ? "success" : "fail",
                "st_channel_id": st_channel_id
            });
            if (pay_res) {
                if (options['toast']) {
                    this._doToast({
                        "title": "支付成功"
                    })
                }
                let st_pay_info = {
                    "st_order_id": res.st_order_id,
                    "st_channel_id": st_channel_id
                }
                this.$util.$store.commit("setPageDatas", {
                    "st_pay_info": st_pay_info
                });
                st_pay_info["msg"] = "支付成功";
                console.log("st_pay_info==>", JSON.stringify(st_pay_info, null, 4));
                return Promise.resolve(st_pay_info);
            } else {
                return options['require'] ? Promise.reject("出现了未知错误") : Promise.resolve("出现了未知错误");
            }
        }
    }
    async wxpay(options) {
        // if (typeof WeixinJSBridge == "undefined") {
        //     if (document.addEventListener) {
        //         document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        //     } else if (document.attachEvent) {
        //         document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        //         document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        //     }
        // } else {
        // }
        // console.log(JSON.stringify(options, null, 4))
        return new Promise((resolve, reject)=>{
            if (this.$util.isEnv('wxa')) {
                wx.requestPayment({
                    timeStamp: options.timeStamp,
                    nonceStr: options.nonceStr,
                    package: options.package,
                    paySign: options.paySign,
                    signType: options.signType,
                    success: res => {
                        resolve(options.st_order_id)
                    },
                    fail: err => {
                        reject()
                    }
                })
            } else {
                WeixinJSBridge.invoke('getBrandWCPayRequest', options, res=> {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        resolve(options.st_order_id)
                    } else {
                        reject()
                    }
                });
            }
        })
    }

    // 保存图片/视频到本地相册
    async _doSaveAlbum (options) {
        if (!options['url']) {
            this.$util.$taro.showToast({
                title: '资源地址为空，无法下载',
                icon: 'none'
            })
            return;
        }
        console.log("_doSaveAlbum: ,", options);
        this.$util.$taro.showLoading({
            title: '保存中...',
            mask: true
        })

        let that = this,
            url = decodeURIComponent(options['url']);
        
        url = url.replace("http://", "https://")
        
        return new Promise((resolve, reject) => {
            this.$util.$taro.downloadFile({
                url: url,
                success (res) {
                    console.log("_doSaveAlbum download: ,", res);
                    let params = {
                        filePath: res.tempFilePath,
                        success () {
                            resolve();
                        },
                        fail (err) {
                            reject();
                        },
                        complete () {}
                    };
                    if (url.indexOf("mp4") != -1) {
                        // 保存视频
                        that.$util.$taro.saveVideoToPhotosAlbum(params)
                    } else {
                        // 保存图片
                        that.$util.$taro.saveImageToPhotosAlbum(params)
                    }
                },
                fail (err) {
                    that.$util.$taro.showToast({
                        title: '保存失败，请检查权限',
                        icon: 'none'
                    })
                }
            })
        })
    }

    // st-scroll-view中滑动到指定选择器
    async _doIntoView (options) {
        let _this = this,
        {   
            scrollview,
            selector,
            scene_id
        } = options || {};

        scrollview = scrollview || ".st-scroll-list"
        
        let cantscroll = scene_id && this.$util.$global.get("into_view_" + scene_id) && false;
        // 限制生命周期内一次 && 暂时只支持wxa
        if (!selector || cantscroll || this.$util.isEnv("h5")) {return Promise.resolve(true)};

        const query = wx.createSelectorQuery && wx.createSelectorQuery();
        if (!query) {return Promise.resolve(true)};

        new Promise((resolve, reject)=>{
            query.select(selector).boundingClientRect(rect=>{
                if (rect && rect.top) {
                    resolve(rect.top);
                }
            }).exec();
        }).then(rect_top=>{
            query.select(scrollview).node(function(res){
                let node = res.node;
                // 滚动高度
                if (node) {
                    if (!rect_top) {
                        node.scrollIntoView(selector);
                    } else {
                        let systemInfo = _this.$util.getSystemInfoSync();
                        node.scrollTo({
                            top: rect_top - systemInfo.navFullHeight,
                            animated: true,
                            duration: 800,
                        })
                    }
                    // node.scrollIntoView(selector);
                    console.log("selector=>", selector, "rect_top==>", rect_top,'node==>', node)
                    _this.$util.$global.set("into_view_" + scene_id, 1);
                }
            }).exec()
        }).catch(err=>{
            debugger
        });
        return Promise.resolve(true);
    }

    // 查看大图
    async _doPreviewImage (options) {
        let {
                current,
                urls
            } = options || {};
        if (!current) {
            return Promise.reject();
        }
        current = decodeURIComponent(current);
        if (!urls) {
            urls = [current];
        }
        wx.previewImage({
            current: current, // 当前显示图片的 http 链接
            urls: urls // 需要预览的图片 http 链接列表
        })
        return Promise.resolve();
    }

    // 通知中心
    async _doTriggerEvent (options) {
        let {
            event_name
        } = options || {};
        this.$util.$eventCenter.trigger(event_name);
        return Promise.resolve();
    }

    // 模板事件
    async _doModule (options) {
        if (!options.cid || options.cid.indexOf('module_') == -1) {
            console.error("【st_action error】do module执行失败，请填写正确的模板cid", options);
            return Promise.resolve();
        }
        let cell_module_condition = this.$util.$config.app_config['cell_module_condition'];
        let app = options.cid.split("_")[1];
        if (!cell_module_condition || !cell_module_condition[app]) {
            console.error("【st_action error】do module执行失败，请在appConfig中携带cell_module_condition配置");
            return Promise.resolve();
        }
        let tid = options.cid;
        if (['shenbi', 'vliti'].indexOf(app) != -1) { // 处理老产品tid
            tid = tid.replace("module_"+app+"_", "");
        }
        let env_data = this.$util.copy(options);
        env_data['tid'] = tid;
        let rconfig = this.$util.replaceReg(cell_module_condition[app], env_data);
        rconfig = JSON.parse(rconfig);
        rconfig = this.$util.typeOf(rconfig) == "array" ? rconfig : [rconfig];

        for (let i = 0 ; i < rconfig.length ; i++) {
            if (rconfig[i]["conditions"]) {
                if (this.$util.$condition.check({
                    "conditions": rconfig[i]["conditions"]
                })) {
                    this.$util.$action.do(rconfig[i]);    
                }
            } else {
                this.$util.$action.do(rconfig[i]);
                break;
            }
        }
    }

    // 埋点
    async _doLog (options) {
        let {
            field,
            params
        } = options || {};
        if (field) {
            this.$util.$loger.log(field, params || {})
        }
        return Promise.resolve()
    }

    // 其他事件
    async _doFunc (options) {
        let onFunc = options && options.onFunc || this.onFunc;
        await this.runFunc(options['func'], options['argvs'], onFunc).catch(err=>{
            console.error("do func error =>", err)
        });
        return Promise.resolve(true);
    }
    // 根据指令执行对应函数
    async runFunc (func, argvs, onFunc) {
        argvs = argvs || {};
        let _findFunc = (_func) => {
            if (!_func) {
                return ['', ''];
            } else if (_func.indexOf('store.commit') != -1) {
                return ['store.commit', _func.replace('store.commit.', '')];
            } else if (_func.indexOf('store.dispatch') != -1) {
                return ['store.dispatch', _func.replace('store.dispatch.', '')];
            } else if (_func.indexOf('eval.') === 0) {
                return ['eval', _func.replace('eval.', '')];
            } else if (_func.indexOf('util.router') != -1) {
                return ['util.router', _func.replace('util.router.', '')];
            } else {
                return ['utils', _func];
            }
        }
        let [funcType, funcName] = _findFunc(func);
        switch ( funcType ) {
            case 'store.commit':
                this.$util.$store.commit(funcName, argvs);
                break;
            case 'store.dispatch':
                await this.$util.$store.dispatch(funcName, argvs);
                break;
            case 'utils':
                if (funcName.indexOf('.') != -1) {
                    // 模块方法调用
                    let [utilClass, utilClassName] = funcName.split(".");
                    this.$util[utilClass][utilClassName] && this.$util[utilClass][utilClassName](argvs);
                } else {
                    // 方法调用
                    this.$util[funcName] && this.$util[funcName](argvs);
                }
                break;
            case 'util.router':
                this.$util.$router[funcName] && this.$util.$router[funcName](argvs);
                break;
            case 'eval':
                debugger;
                console.error(" can not use eval ")
                // try {
                //     eval(funcName);
                // } catch (e) {
                //     console.log("runFunc error: ", e);
                // }
                break;
            default:
                onFunc && await onFunc();
                break;
        }
    }
}


export default {
    install
}