<!-- 平面锦鲤 -->
<template>
	<div>		
		<div class="fish1 fish po">
		<!-- 	<div class="page po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_leftWill po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_rightWill po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/efb547769fb6bc6a9230d835942fc6a4.png" class="fish_body po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/cd7b182901a94318daecec815f77d4ae.png" class="fish_tail po">
			</div> -->
			<div class="page po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_leftWill po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_rightWill po">
				<img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_body.png" class="fish_body po">
				<img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_tail.png" class="fish_tail po">
			</div>
		</div>

		<div class="fish2 fish po">
		<!-- 	<div class="page po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_leftWill po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_rightWill po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/efb547769fb6bc6a9230d835942fc6a4.png" class="fish_body po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/cd7b182901a94318daecec815f77d4ae.png" class="fish_tail po">
			</div> -->
			<div class="page po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_leftWill po">
				<img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_rightWill po">
				<img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_body.png" class="fish_body po">
				<img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_tail.png" class="fish_tail po">
			</div>
		</div>

		<div class="fish3 fish po">
		    <!-- <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_rightWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/efb547769fb6bc6a9230d835942fc6a4.png" class="fish_body po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/cd7b182901a94318daecec815f77d4ae.png" class="fish_tail po"></div> -->
		    <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_rightWill po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_body.png" class="fish_body po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_tail.png" class="fish_tail po"></div>
		</div>

		<div class="fish4 fish po">
		   <!--  <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_rightWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/efb547769fb6bc6a9230d835942fc6a4.png" class="fish_body po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/cd7b182901a94318daecec815f77d4ae.png" class="fish_tail po"></div> -->
		    <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_rightWill po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_body.png" class="fish_body po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_tail.png" class="fish_tail po"></div>
		</div>

		<div class="fish5 fish po">
		  <!--   <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_rightWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/efb547769fb6bc6a9230d835942fc6a4.png" class="fish_body po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/cd7b182901a94318daecec815f77d4ae.png" class="fish_tail po"></div> -->
		    <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_rightWill po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_body.png" class="fish_body po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_tail.png" class="fish_tail po"></div>
		</div>

		<div class="fish6 fish po">
		   <!--  <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/7dce10f9b6a30c4f4635f39bc2f3652a.png" class="fish_rightWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/efb547769fb6bc6a9230d835942fc6a4.png" class="fish_body po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/cd7b182901a94318daecec815f77d4ae.png" class="fish_tail po"></div> -->
		    <div class="page po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_leftWill po">
		        <img src="http://litiresource.xcgogo.site/user/9546085/images/0e198a4ced166265c82e3b12c1d5a6c6.png" class="fish_rightWill po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_body.png" class="fish_body po">
		        <img src="http://resali.lititutu.cn/scenes/qingchihehua/fish_tail.png" class="fish_tail po"></div>
		        <!-- <img src="http://litiresource.xcgogo.site/user/9546085/images/c69608343ba1a84c37b82474d8f2e550.png" class="fish_body po"> -->
		        <!-- <img src="http://litiresource.xcgogo.site/user/9546085/images/b01aacd992d1777131520d55e22b6825.png" class="fish_tail po"></div> -->
		</div>
	
	</div>
</template>

<script>
	export default {
		name: 'Fish',
		data () {
			return {}
		}
	}
</script>

<style>
	.fish {
		width: 48px;
		height: 124px;
	}
	.fish div:nth-child(1) {
		top: -14px;
		left: 0;
	}
	.fish_rightWill {
		left: 4px;
	}
	.fish div {
		transform: scale(1.5);
	}
	.fish1 {
		animation: 15125ms linear 274ms infinite normal both running fish1;
		top: 316px;
		left: -50px;
		transform-origin: 550% -300%;
	}
	.fish1 div {
		transform: rotateZ(-60deg) scale(1.5);
	}
	@keyframes fish1 {
		0% {
		    transform: rotateZ(-150deg);
		}
		30% {
		    transform: rotateZ(-150deg);
		}
		100% {
		    transform: rotateZ(0deg);
		}
	}
	.fish2 {
		animation: 13194ms linear 353ms infinite normal both running fish2;
		bottom: -86px;
		left: 334px;
		-webkit-transform-origin: -330px 50%;
	}
	.fish2 div {
		transform: rotateZ(180deg) scale(1.2);
	}
	@keyframes fish2 {
		0% {
		    -webkit-transform: rotateZ(-118deg);
		}
		40% {
		    -webkit-transform: rotateZ(-118deg);
		}
		100% {
		    -webkit-transform: rotateZ(0deg);
		}
	}
	.fish3 {
		animation: 15222ms linear 7195ms infinite normal both running fish3;
		top: 188px;
		right: 0;
		-webkit-transform-origin: -100% 400%;
	}
	.fish3 div {
		transform: rotateZ(105deg) scale(1.5);
	}
	@keyframes fish3 {
		0% {
		    -webkit-transform: rotateZ(-155deg);
		}
		32% {
		    -webkit-transform: rotateZ(-155deg);
		}
		100% {
		    -webkit-transform: rotateZ(11deg);
		}
	}
	.fish4 {
		animation: 13289ms linear 463ms infinite normal both running fish4;
		top: -22px;
	    left: 330px;
	    -webkit-transform-origin: 800% 300%;
	}
	.fish4 div {
		transform: rotateZ(50deg) scale(1.3);
	}
	@keyframes fish4 {
		0% {
		    -webkit-transform: rotateZ(-175deg);
		}
		25% {
		    -webkit-transform: rotateZ(-175deg);
		}
		100% {
		    -webkit-transform: rotateZ(12deg);
		}
	}
	.fish5 {
		animation: 14736ms linear 1157ms infinite normal both running fish5;
		top: 43px;
		left: 0;
		-webkit-transform-origin: -200% 880%;
	}
	.fish5 div {
		transform: rotateZ(-90deg) scale(1.2);
	}
	@keyframes fish5 {
		0% {
		    -webkit-transform: rotateZ(85deg);
		}
		20% {
		    -webkit-transform: rotateZ(85deg);
		}
		100% {
		    -webkit-transform: rotateZ(-5deg);
		}
	}
	.fish6 {
		animation: 12764ms linear 5693ms infinite normal both running fish6;
		bottom: 0;
		left: 0;
		-webkit-transform-origin: 1680% 480%;
	}
	.fish6 div {
		transform: rotateZ(-150deg) scale(1.3);
	}
	@keyframes fish6 {
		0% {
		    -webkit-transform: rotateZ(80deg);
		}
		35% {
		    -webkit-transform: rotateZ(80deg);
		}
		100% {
		    -webkit-transform: rotateZ(-6deg);
		}
	}
	.fish_leftWill {
		top: 24px;
		left: -14px;
		transform-origin: 80% 80%;
		animation: fish_leftWill 1s linear infinite alternate;
	}
	@keyframes fish_leftWill {
		0% {
			transform: scale(1,1) rotateZ(-10deg);
		}
		100% {
			transform: scale(1,0.8) rotateZ(40deg);
		}
	}
	.fish_rightWill {
		top: 25px;
		left: 10px;
		transform-origin: 80% 80%;
		animation: fish_rightWill 1s linear infinite alternate;
	}
	@keyframes fish_rightWill {
		0% {
		    transform: scale(-1,1) rotateZ(-10deg);
		}
		100% {
		    transform: scale(-1,0.8) rotateZ(40deg);
		}
	}
	.fish_tail {
	    bottom: 14px;
	    left: 2px;
	    -webkit-transform-origin: 0% 0%;
	    -webkit-animation: fish_tail 0.8s linear infinite alternate;
	}
	@keyframes fish_tail {
		0% {
		    -webkit-transform: rotate(-10deg);
		}
		100% {
		    -webkit-transform: rotate(10deg);
		}
	}
</style>