<template>
    <div class="compluo5 po pointer" :style="getStyles">
        <template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
			<div v-for="index of 6"
                class="po"
                :class="'luo5_item_' + (index)"
                :key="'luo5_item_' + index">
				<elem 
					:mode="getLuoItem(index)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
			</div>
		</template>
		<template v-else>
            <img v-for="index of 6"
                mode="widthFix"
                class="po"
                :class="'luo5_item_' + (index)"
                :key="'luo5_item_' + index"
                :src="getRandomLuo()">
		</template>
    </div>
</template>

<script>
    import BaseLuo from './BaseLuo'
    export default {
        name: 'luo5',
        extends: BaseLuo,
    }
</script>

<style>
    .luo5_item_1 {
        width: 130px; left: 200px; 
        animation: 12s linear .4s infinite normal both running luo5_item_0;
    }
    .luo5_item_2 {
        width: 113px; left: 66.6667px; 
        animation: 13s linear 4s infinite normal both running luo5_item_1;;
    }
    .luo5_item_3 {
        width: 120px; left: 133.333px; 
        animation: 12s linear 3.2s infinite normal both running luo5_item_2;
    }
    .luo5_item_4 {
        width: 112px; left: 300px; 
        animation: 13s linear 5.6s infinite normal both running luo5_item_4;
    }
    .luo5_item_5 {
        width: 96px; left: 250px; 
        animation: 14s linear 1.2s infinite normal both running luo5_item_5;
    }
    .luo5_item_6 {
        width: 89px; left: 250px; 
        animation: 12s linear 7.5s infinite normal both running luo5_item_6;
    }
    @keyframes luo5_item_0 {
        0% {
            opacity: 0;
            -webkit-transform: translate(0,0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            -webkit-transform: translate(-350px,1110px);
        }   
    }
    @keyframes luo5_item_1 {
        0% {
            opacity: 0;
            -webkit-transform: translate(0,0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            -webkit-transform: translate(-250px,1110px);
        }
    }
    @keyframes luo5_item_2 {
        0% {
            opacity: 0;
            -webkit-transform: translate(0,0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            -webkit-transform: translate(450px,950px);
        }
    }
    @keyframes luo5_item_4 {
        0% {
            opacity: 0;
            -webkit-transform: translate(0,0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            -webkit-transform: translate(225px,1000px);
        }
    }
    @keyframes luo5_item_5 {
        0% {
            opacity: 0;
            -webkit-transform: translate(0,0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            -webkit-transform: translate(-525px,1125px);
        }
    }
    @keyframes luo5_item_6 {
        0% {
            opacity: 0;
            -webkit-transform: translate(0,0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            -webkit-transform: translate(-525px,1125px);
        }
    }
</style>