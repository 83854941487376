<template>
    <div class="snow-container" :style="getStyles">
        <div :style="{backgroundImage: 'url('+mode[0]+')'}" class="snow foreground"></div>
        <div :style="{backgroundImage: 'url('+mode[0]+')'}" class="snow foreground layered"></div>
        <div :style="{backgroundImage: 'url('+mode[1]+')'}" class="snow middleground"></div>
        <div :style="{backgroundImage: 'url('+mode[1]+')'}" class="snow middleground layered"></div>
        <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="snow background"></div>
        <div :style="{backgroundImage: 'url('+mode[2]+')'}" class="snow background layered"></div>
    </div>
</template>

<script>
    // $(".foreground").css({"background-image":"url(" + aniArrImg[0] + ")"})
    // $(".middleground").css({"background-image":"url(" + aniArrImg[1] + ")"})
    // $(".background").css({"background-image":"url(" + aniArrImg[2] + ")"})
    export default {
        name: 'snow2',
        props: ["mode"],
        data () {
            return {}
        },
        computed: {
            getStyles () {
                let item = this.mode;
                
                let s = {};
                
                if (item['zIndex']) {
                    s['zIndex'] = item['zIndex'];
                }

                if (item['style']) {
                    s = Object.assign(s, item['style']);
                }

                return s;
            }
        }
    }
</script>

<style>
    /* 飘雪 */
    .snow-container {
        position: absolute;
        height: 888px;
        width: 100%;
        max-width: 100%;
        top: 0;
        overflow: hidden;
        z-index: 6;
        pointer-events: none;
    }
    .snow {
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        -webkit-animation: snow linear infinite;
        animation: snow -5897ms linear infinite;
    }
    .snow.foreground {
    
        -webkit-animation-duration: 15s;
        animation-duration: 15s;
    }
    .snow.foreground.layered {
        -webkit-animation-delay: 7.5s;
        animation-delay: 7.5s;
    }
    .snow.middleground {
    
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
    }
    .snow.middleground.layered {
        -webkit-animation-delay: 10s;
        animation-delay: 10s;
    }
    .snow.background {
    
        -webkit-animation-duration: 25s;
        animation-duration: 25s;
    }
    .snow.background.layered {
        -webkit-animation-delay: 12.5s;
        animation-delay: 12.5s;
    }
    @keyframes snow {
        0% {
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
        100% {
            -webkit-transform: translate3d(5%, 100%, 0);
            transform: translate3d(5%, 100%, 0);
        }
    }
</style>
