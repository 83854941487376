<template>
	<div class="bbx-page">
	    <div class="bbx-container">
	        <div class="bbx-rect bg bg_photo bbx-div_1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_3" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_4" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_5" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_6" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_7" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_8" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_9" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	        <div class="bbx-rect bg bg_photo bbx-div_10" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				<elem v-if="comp_config[0]" :mode="comp_config[0]" :currentPhoto="currentPhoto" :canIShow="canIShow"></elem>
			</div>
	    </div>
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'baibianxing',
		extends: BaseElem,
		data () {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style>
	.bbx-page {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    background-position: center;
	    background-repeat: no-repeat;
	    background-size: cover;
	    perspective:1823.50px;
	}
	.bbx-container {
	    position: absolute;
	    transform-style: preserve-3d;
	    width: 222px;
	    height: 222px;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    margin: auto;
	    animation: bbx_xuanzhuan 8s infinite linear;
	}
	@keyframes bbx_xuanzhuan {
		0% {
			transform: rotateY(0deg);
		}
		100% {
			transform: rotateY(360deg);
		}
	}
	.bbx-div_1 {
	    transform: translateZ(222px);
	}
	.bbx-div_2 {
	    transform: rotateY(60deg) translateZ(222px);
	}
	.bbx-div_3 {
	    transform: rotateY(120deg) translateZ(222px);
	}
	.bbx-div_4 {
	    transform: rotateY(180deg) translateZ(222px);
	}
	.bbx-div_5 {
	    transform: rotateY(240deg) translateZ(222px);
	}
	.bbx-div_6 {
	    transform: rotateY(300deg) translateZ(222px);
	}
	.bbx-div_7 {
	    transform: rotateX(60deg) translateZ(222px);
	}
	.bbx-div_8 {
	    transform: rotateY(180deg) rotateX(-60deg) translateZ(222px);
	}
	.bbx-div_9 {
	    transform: rotateX(-60deg) translateZ(222px);
	}
	.bbx-div_10 {
	    transform: rotateY(180deg) rotateX(60deg) translateZ(222px);
	}
	.bbx-rect {
	    position: absolute;
	    width: 222px;
	    height: 222px;
	    overflow: hidden;
	    border: solid 6px #fff;
	    border-radius:14px;
	}
</style>