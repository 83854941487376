<template>
	<div class="luopic-container poc" :style="getStyles">
		<img mode="widthFix" class="luopic luopic1" :src="getLuoItem(0)">
		<img mode="widthFix" class="luopic luopic2" :src="getLuoItem(1)">
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'Luopic',
		extends: BaseLuo
	}
</script>

<style>
	.luopic {
		position: absolute;
		width: 500px;
		z-index: 1;
		pointer-events: none;
	}
	.luopic1 {
		animation: luopic1 4.2s linear infinite both;
	}
	@keyframes luopic1 {
		0% {
		    opacity: 0;
		    -webkit-transform: translateY(0px);
		}
		5% {
		    opacity: 1;
		}
		29% {
		    opacity: 1;
		}
		50% {
		    opacity: 0;
		    -webkit-transform: translateY(100px);
		}
		51% {
		    opacity: 0;
		    -webkit-transform: translateY(-100px);
		}
		67% {
		    opacity: 1;
		}
		96% {
		    opacity: 1;
		}
		100% {
		    opacity: 0;
		    -webkit-transform: translateY(50px);
		}
	}
	.luopic2 {
		animation: luopic2 4.2s linear infinite both;
	}
	@keyframes luopic2 {
		0% {
		    opacity: 0;
		}
		14% {
		    opacity: 1;
		}
		79% {
		    opacity: 1;
		}
		100% {
		    opacity: 0;
		    -webkit-transform: translateY(200px);
		}
	}
</style>