<template>
    <div class="zhuanta poc">
		<div class="perspective poc">
			<div class="zhuanta_1 preserve3d poc">
				<!-- <gif :mode="flower_1"></gif> -->
                <elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
				<div class="zhuanta_1_cont preserve3d poc">
					<div
						v-for="idx of 3"
						:key="'zhuanta_1_box' + idx"
						:class="'zhuanta_1_box preserve3d po zhuanta_1_' + idx">
						<div
							v-for="index of 7"
							:key="'zhuanta_1_1_item' + index"
							:class="'zhuanta_item poc bg bg_photo zhuanta_' + idx + '_' + index + '_item'"
							:style="getItemStyle(idx, index)">
							<div class="poc bg bg_photo" :style="getItemBgStyle(idx, index)"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: 'zhuanta',
        extends: BaseElem,
        methods: {
            getItemStyle(idx, index){
				let bw = 500;
				let deg = 51.4286 * (index - 1);
				let s = {};
				if(idx == 2){
					bw = bw * 0.86;
				} else if(idx == 3){
					bw = bw * 0.72;
				}
				// s['backgroundImage'] = 'url('+ this.currentPhoto['im'] +')';
				s['transform'] = "rotateY(" + (deg).toString() + "deg) translateZ(" + bw.toString() + "px)"
                if (this.comp_config[1]) {
                    s['borderImageSource'] = "url("+ this.comp_config[1] +")";
                } else {
                    s['borderImageSource'] = "url(https://resali.lititutu.cn/icons/other/border.jpg)";
                }
				return s;
			},
			getItemBgStyle () {
				let s =  {};
				s['backgroundImage'] = 'url('+ this.currentPhoto['im'] +')';
				s['animation'] = "fullscreen_bg_20_80 8s ease-in-out 0s 1 reverse both";
				return s;
			}
        }
    }
</script>

<style>
    .zhuanta_1 {
	    bottom: 50px;
	    -webkit-transform: translateZ(-650px);
	}
	.zhuanta_1_cont {
	    -webkit-animation: zhuanta_1_1 13s linear infinite both;
	}
	.zhuanta_1_box {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	}
	.zhuanta_item {
	    border: 5px solid;
	    /* border-image: url(https://resali.lititutu.cn/icons/other/border.jpg); */
	    border-image-slice: 5;
		background-size: cover;
		background-clip: content-box;
	}
	@keyframes zhuanta_1_1 {
		0% {
		    -webkit-transform: rotateY(360deg);
		}
		100% {
		    -webkit-transform: rotateY(0);
		}
	}
	.zhuanta_1_1 {
		width: 430px;
		height: 281.6px;
		top: 498.952px;
	}
	.zhuanta_1_2 {
		width: 369.8px;
		height: 261.976px;
		top: 50px;
	}
	.zhuanta_1_3 {
		width: 309.6px;
		height: 262.352px;
	    top: -410px;
	}
</style>