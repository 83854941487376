<template>
	<div id="bganim2">
        <img class="w-kongmingdeng1" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
        <img class="w-kongmingdeng2" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
        <img class="w-kongmingdeng3" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
        <img class="w-kongmingdeng4" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
        <img class="w-kongmingdeng5" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
        <img class="w-kongmingdeng6" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
        <img class="w-kongmingdeng7" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
        <img class="w-kongmingdeng8" src="http://litiresource.xcgogo.site/user/5980171/images/005a859517a58dadf713028ee86b73b0.png">
    </div>
</template>

<script>
    /* eslint-disable */
    
	export default {
		name: 'gongtingmeijing',
		data () {
			return {}
		}
	}
</script>

<style>
    .w-kongmingdeng1 {
        position: absolute;
        bottom: 0;
        animation: w-kongmingdeng1 13s infinite linear;
        opacity: 0;
    }

    @keyframes w-kongmingdeng1 {
        0% {
            transform: translate3d(-200px, 100px, -200px) rotate(0deg) scale(.6);
            opacity: 1;
        }
        10% {
            transform: translate3d(-150px, -30px, -200px) rotate(5deg) scale(.6);
        }
        20% {
            transform: translate3d(-100px, -260px, -200px) rotate(0deg) scale(.6);
        }
        30% {
            transform: translate3d(-50px, -390px, -200px) rotate(-5deg) scale(.6);
        }
        40% {
            transform: translate3d(0px, -520px, -200px) rotate(0deg) scale(.6);
        }
        50% {
            transform: translate3d(50px, -650px, -200px) rotate(5deg) scale(.6);
        }
        60% {
            transform: translate3d(100px, -780px, -200px) rotate(0deg) scale(.6);
        }
        70% {
            transform: translate3d(150px, -910px, -200px) rotate(-5deg) scale(.6);
        }
        80% {
            transform: translate3d(200px, -1040px, -200px) rotate(0deg) scale(.6);
        }
        90% {
            transform: translate3d(250px, -1170px, -200px) rotate(5deg) scale(.6);
        }
        100% {
            transform: translate3d(300px, -1200px, -200px) scale(.6);
            opacity: 1;
        }
    }

    .w-kongmingdeng2 {
        position: absolute;
        bottom: 0px;
        left: -300px;
        animation: w-kongmingdeng2 16s 4s infinite linear;
        opacity: 1;
        transform: scale(.5);
    }

    @keyframes w-kongmingdeng2 {
        0% {
            transform: translate3d(-200px, 100px, -200px) rotate(0deg) scale(0);
            opacity: 0;
        }
        10% {
            transform: translate3d(-160px, -30px, -190px) rotate(10deg) scale(0.1);
            opacity: .2;
        }
        20% {
            transform: translate3d(-120px, -160px, -180px) rotate(0deg) scale(0.2);
            opacity: .4;
        }
        30% {
            transform: translate3d(-80px, -290px, -170px) rotate(-10deg) scale(0.3);
            opacity: .6;
        }
        40% {
            transform: translate3d(-40px, -420px, -160px) rotate(0deg) scale(0.5);
            opacity: .8;
        }
        50% {
            transform: translate3d(0px, -550px, -150px) rotate(10deg) scale(0.5);
            opacity: 1;
        }
        60% {
            transform: translate3d(40px, -680px, -140px) rotate(0deg) scale(0.5);
        }
        70% {
            transform: translate3d(80px, -810px, -130px) rotate(-10deg) scale(0.5);
        }
        80% {
            transform: translate3d(120px, -940px, -120px) rotate(0deg) scale(0.5);
        }
        90% {
            transform: translate3d(160px, -1070px, -110px) rotate(10deg) scale(0.5);
        }
        100% {
            transform: translate3d(200px, -1200px, -100px) scale(0.5);
            opacity: 1;
        }
    }

    .w-kongmingdeng3 {
        position: absolute;
        bottom: 0px;
        left: -450px;
        animation: w-kongmingdeng3 16s infinite linear;
        opacity: 1;
        transform: scale(.7);
    }

    @keyframes w-kongmingdeng3 {
        0% {
            transform: translate3d(-200px, 100px, -200px) rotate(0deg) scale(0);
            opacity: 0;
        }
        10% {
            transform: translate3d(-160px, -30px, -190px) rotate(10deg) scale(0.1);
            opacity: .2;
        }
        20% {
            transform: translate3d(-120px, -160px, -180px) rotate(0deg) scale(0.2);
            opacity: .4;
        }
        30% {
            transform: translate3d(-80px, -290px, -170px) rotate(-10deg) scale(0.3);
            opacity: .6;
        }
        40% {
            transform: translate3d(-40px, -420px, -160px) rotate(0deg) scale(0.5);
            opacity: .8;
        }
        50% {
            transform: translate3d(0px, -550px, -150px) rotate(10deg) scale(0.7);
            opacity: 1;
        }
        60% {
            transform: translate3d(40px, -680px, -140px) rotate(0deg) scale(0.7);
        }
        70% {
            transform: translate3d(80px, -810px, -130px) rotate(-10deg) scale(0.7);
        }
        80% {
            transform: translate3d(120px, -940px, -120px) rotate(0deg) scale(0.7);
        }
        90% {
            transform: translate3d(160px, -1070px, -110px) rotate(10deg) scale(0.7);
        }
        100% {
            transform: translate3d(200px, -1200px, -100px) scale(0.7);
            opacity: 1;
        }
    }

    .w-kongmingdeng4 {
        position: absolute;
        top: 60px;
        left: -310px;
        animation: w-kongmingdeng4 16s infinite linear;
        opacity: .8;
        transform: scale(.15);
    }

    @-webkit-keyframes w-kongmingdeng4 {
        40% {
            transform: translateX(75px) translateY(-61px) scale(.15);
        }
        80% {
            transform: translateX(17px) translateY(100px) scale(.15);
        }
    }

    .w-kongmingdeng5 {
        position: absolute;
        bottom: 460px;
        left: -310px;
        animation: w-kongmingdeng5 16s 5s infinite linear;
        opacity: 0;
        transform: scale(.2);
    }

    @-webkit-keyframes w-kongmingdeng5 {
        40% {
            transform: translateX(75px) translateY(-61px) scale(.15);
            opacity: .9;
        }
        80% {
            transform: translateX(17px) translateY(100px) scale(.15);
            opacity: .9;
        }
    }

    .w-kongmingdeng6 {
        position: absolute;
        top: 600px;
        left: 50px;
        animation: w-kongmingdeng4 16s infinite linear;
        opacity: 1;
        transform: scale(.15);
    }

    .w-kongmingdeng7 {
        position: absolute;
        bottom: 0px;
        left: -500px;
        animation: w-kongmingdeng7 16s 10s infinite linear;
        opacity: 1;
        transform: scale(.5);
    }

    @keyframes w-kongmingdeng7 {
        0% {
            transform: translate3d(-200px, 100px, -200px) rotate(0deg) scale(0);
            opacity: 0;
        }
        10% {
            transform: translate3d(-160px, -30px, -190px) rotate(10deg) scale(0.1);
            opacity: .2;
        }
        20% {
            transform: translate3d(-120px, -160px, -180px) rotate(0deg) scale(0.2);
            opacity: .4;
        }
        30% {
            transform: translate3d(-80px, -290px, -170px) rotate(-10deg) scale(0.3);
            opacity: .6;
        }
        40% {
            transform: translate3d(-40px, -420px, -160px) rotate(0deg) scale(0.5);
            opacity: .8;
        }
        50% {
            transform: translate3d(0px, -550px, -150px) rotate(10deg) scale(0.5);
            opacity: 1;
        }
        60% {
            transform: translate3d(40px, -680px, -140px) rotate(0deg) scale(0.5);
        }
        70% {
            transform: translate3d(80px, -810px, -130px) rotate(-10deg) scale(0.5);
        }
        80% {
            transform: translate3d(120px, -940px, -120px) rotate(0deg) scale(0.5);
        }
        90% {
            transform: translate3d(160px, -1070px, -110px) rotate(10deg) scale(0.5);
        }
        100% {
            transform: translate3d(200px, -1200px, -100px) scale(0.5);
            opacity: 1;
        }
    }

    .w-kongmingdeng8 {
        position: absolute;
        left: -300px;
        top: 700px;
        animation: w-kongmingdeng8 11s 1s linear infinite;
        opacity: 1;
        transform: scale(0);
    }

    @keyframes w-kongmingdeng8 {
        0% {
            transform: translate3d(0, 0, 0) rotateX(0deg) rotateZ(0deg) rotateY(0deg) scale(.1);
            opacity: 0;
        }
        10% {
            transform: translate3d(60px, -20px, 40px) rotate(7deg) rotateZ(2deg) scale(0.1);
            opacity: .2;
        }
        20% {
            transform: translate3d(120px, -40px, 80px) rotate(0deg) rotateZ(4deg) scale(0.2);
            opacity: .4;
        }
        30% {
            transform: translate3d(180px, -60px, 120px) rotate(-7deg) rotateZ(6deg) scale(0.2);
            opacity: .6;
        }
        40% {
            transform: translate3d(240px, -80px, 160px) rotate(0deg) rotateZ(8deg) scale(0.2);
            opacity: .8;
        }
        50% {
            transform: translate3d(300px, -100px, 200px) rotate(7deg) rotateZ(10deg) scale(0.2);
            opacity: 1;
        }
        60% {
            transform: translate3d(360px, -120px, 240px) rotate(0deg) rotateZ(12deg) scale(0.2);
        }
        70% {
            transform: translate3d(420px, -140px, 280px) rotate(-7deg) rotateZ(14deg) scale(0.2);
        }
        80% {
            transform: translate3d(480px, -160px, 320px) rotate(0deg) rotateZ(16deg) scale(0.2);
        }
        90% {
            transform: translate3d(540px, -180px, 360px) rotate(7deg) rotateZ(18deg) scale(0.2);
        }
        100% {
            transform: translate3d(600px, -200px, 400px) rotateZ(20deg) scale(.2);
        }
    }

    .w-wawa {
        position: absolute;
        transform: scale(.8);
        bottom: 40px;
        left: 100px;
    }

    .w-zhongguojie {
        position: absolute;
        transform: scale(.5, .6);
        top: 170px;
        left: 120px;
    }
</style>