<template>
	<div class="BX_AEComposition" AELayerName="chongyang_2">
	    <div class="BX_Class9 BX_BG9 BX_Ani9" id="BX_Layer9" AELayerName="9.bg.jpg"></div>
	    <div class="BX_Class8 BX_BG8 BX_Ani8" id="BX_Layer8" AELayerName="8.item4.png"></div>
	    <div class="BX_Class7 BX_BG7 BX_Ani7" id="BX_Layer7" AELayerName="7.item3.png"></div>
	    <div class="BX_Class6 BX_BG6 BX_Ani6" id="BX_Layer6" AELayerName="6.item2.png"></div>
	    <div class="BX_Class5 BX_BG5 BX_Ani5" :style="{backgroundImage: 'url('+currentImage[0]['im']+')'}" id="BX_Layer5" AELayerName="5.user.png_2"></div>
	    <div class="BX_Class4 BX_BG4 BX_Ani4" id="BX_Layer4" AELayerName="4.item4.png"></div>
	    <div class="BX_Class3 BX_BG3 BX_Ani3" id="BX_Layer3" AELayerName="3.item1.png"></div>
	    <div class="BX_Class2 BX_BG2 BX_Ani2" id="BX_Layer2" AELayerName="2.titletext.png"></div>
	    <div class="BX_Class10 BX_BG10 BX_Ani10" id="BX_Layer10" AELayerName="2.bird.png"></div>
	    <div class="BX_Class12 BX_Ani15" id="BX_Layer15" AELayerName="15.text1">
	    	<span style="color: rgb(234, 50, 35);">亲爱的朋友</span>
	        <br /><span style="color: rgb(234, 50, 35);">又是一年重阳到</span>
	        <br /><span style="color: rgb(234, 50, 35);">你近来可好</span>
	        <br /><span style="color: rgb(234, 50, 35);">我送你久久的重阳问候</span></div>
	    <div class="BX_Class12 BX_Ani14" id="BX_Layer14" AELayerName="14.text2">
	    	<span style="color: rgb(234, 50, 35);">久久的祝福</span>
	        <br /><span style="color: rgb(234, 50, 35);">久久的平安</span>
	        <br /><span style="color: rgb(234, 50, 35);">久久的快乐</span>
	        <br /><span style="color: rgb(234, 50, 35);">久久的牵挂</span></div>
	    <div class="BX_Class12 BX_Ani13" id="BX_Layer13" AELayerName="13.text3">
	    	<span style="color: rgb(234, 50, 35);">送你一缕清风</span>
	        <br /><span style="color: rgb(234, 50, 35);">愿它拂去你的不如意</span>
	        <br /><span style="color: rgb(234, 50, 35);">时光易逝，记得多陪陪家人</span>
	        <br /><span style="color: rgb(234, 50, 35);">天气转凉，记得要多加衣裳</span></div>
	    <div class="BX_Class12 BX_Ani12" id="BX_Layer12" AELayerName="12.text4">
	    	<span style="color: rgb(234, 50, 35);">真心祝福你</span>
	        <br /><span style="color: rgb(234, 50, 35);">我最好的朋友</span>
	        <br /><span style="color: rgb(234, 50, 35);">祝你永远如意</span>
	        <br /><span style="color: rgb(234, 50, 35);">重阳节快乐！</span></div>
	    <div class="BX_Class1 BX_BG1 BX_Ani1" id="BX_Layer1" AELayerName="1.cover.jpg"></div>
	</div>
</template>

<script>
	export default {
		name: 'chongyangheying',
		props: ['currentImage'],
		data () {
			return {}
		}
	}
</script>

<style scoped>
	.BX_AEComposition {
	    background: transparent;
	    width: 720px;
	    height: 1280px;
	    display: block;
	    overflow: hidden;
	    position: relative;
	    transform: translate3d(0, 0, 0) scale(1, 1);
	    transform-origin:50% 0%;
	    perspective: 600px;
	    perspective-origin: 50% 50%;
	}
	.BX_Ani15 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey15 26s linear 3.50s infinite normal none;
	}
	@keyframes BX_AniKey15 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.00;
	    }
	    0.45% {
	        transform: translate3d(0.00px, -16.47px, 0.00px) rotate(0.00deg);
	        opacity: 0.06;
	    }
	    0.89% {
	        transform: translate3d(0.00px, -32.94px, 0.00px) rotate(0.00deg);
	        opacity: 0.12;
	    }
	    1.34% {
	        transform: translate3d(0.00px, -49.41px, 0.00px) rotate(0.00deg);
	        opacity: 0.18;
	    }
	    1.79% {
	        transform: translate3d(0.00px, -65.88px, 0.00px) rotate(0.00deg);
	        opacity: 0.24;
	    }
	    2.23% {
	        transform: translate3d(0.00px, -82.36px, 0.00px) rotate(0.00deg);
	        opacity: 0.29;
	    }
	    2.68% {
	        transform: translate3d(0.00px, -98.83px, 0.00px) rotate(0.00deg);
	        opacity: 0.35;
	    }
	    3.13% {
	        transform: translate3d(0.00px, -115.29px, 0.00px) rotate(0.00deg);
	        opacity: 0.41;
	    }
	    3.57% {
	        transform: translate3d(-0.00px, -131.77px, 0.00px) rotate(0.00deg);
	        opacity: 0.47;
	    }
	    4.02% {
	        transform: translate3d(0.00px, -148.23px, 0.00px) rotate(0.00deg);
	        opacity: 0.53;
	    }
	    4.46% {
	        transform: translate3d(0.00px, -164.71px, 0.00px) rotate(0.00deg);
	        opacity: 0.59;
	    }
	    4.91% {
	        transform: translate3d(0.00px, -181.17px, 0.00px) rotate(0.00deg);
	        opacity: 0.65;
	    }
	    5.36% {
	        transform: translate3d(0.00px, -197.65px, 0.00px) rotate(0.00deg);
	        opacity: 0.71;
	    }
	    5.80% {
	        transform: translate3d(0.00px, -214.12px, 0.00px) rotate(0.00deg);
	        opacity: 0.76;
	    }
	    6.25% {
	        transform: translate3d(0.00px, -230.59px, 0.00px) rotate(0.00deg);
	        opacity: 0.82;
	    }
	    6.70% {
	        transform: translate3d(0.00px, -247.06px, 0.00px) rotate(0.00deg);
	        opacity: 0.88;
	    }
	    7.14% {
	        transform: translate3d(-0.00px, -263.53px, 0.00px) rotate(0.00deg);
	        opacity: 0.94;
	    }
	    7.59% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    20.10% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    20.54% {
	        transform: translate3d(0.40px, -304.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.90;
	    }
	    20.98% {
	        transform: translate3d(0.80px, -328.81px, 0.00px) rotate(0.00deg);
	        opacity: 0.80;
	    }
	    21.43% {
	        transform: translate3d(1.20px, -353.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.70;
	    }
	    21.87% {
	        transform: translate3d(1.60px, -377.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.60;
	    }
	    22.32% {
	        transform: translate3d(2.00px, -402.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.50;
	    }
	    22.77% {
	        transform: translate3d(2.40px, -426.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.40;
	    }
	    23.21% {
	        transform: translate3d(2.80px, -450.80px, 0.00px) rotate(0.00deg);
	        opacity: 0.30;
	    }
	    23.66% {
	        transform: translate3d(3.20px, -475.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.20;
	    }
	    24.11% {
	        transform: translate3d(3.60px, -499.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.10;
	    }
	    24.55% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.00;
	    }
	    25.45% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    99.55% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    100.00% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	}
	.BX_Ani14 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey14 26s linear 3.50s infinite normal none;
	}
	@keyframes BX_AniKey14 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    25.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    25.45% {
	        transform: translate3d(0.00px, -16.47px, 0.00px) rotate(0.00deg);
	        opacity: 0.06;
	    }
	    25.89% {
	        transform: translate3d(0.00px, -32.94px, 0.00px) rotate(0.00deg);
	        opacity: 0.12;
	    }
	    26.34% {
	        transform: translate3d(0.00px, -49.41px, 0.00px) rotate(0.00deg);
	        opacity: 0.18;
	    }
	    26.79% {
	        transform: translate3d(0.00px, -65.88px, 0.00px) rotate(0.00deg);
	        opacity: 0.24;
	    }
	    27.23% {
	        transform: translate3d(0.00px, -82.36px, 0.00px) rotate(0.00deg);
	        opacity: 0.29;
	    }
	    27.68% {
	        transform: translate3d(0.00px, -98.83px, 0.00px) rotate(0.00deg);
	        opacity: 0.35;
	    }
	    28.12% {
	        transform: translate3d(0.00px, -115.29px, 0.00px) rotate(0.00deg);
	        opacity: 0.41;
	    }
	    28.57% {
	        transform: translate3d(-0.00px, -131.77px, 0.00px) rotate(0.00deg);
	        opacity: 0.47;
	    }
	    29.02% {
	        transform: translate3d(0.00px, -148.23px, 0.00px) rotate(0.00deg);
	        opacity: 0.53;
	    }
	    29.46% {
	        transform: translate3d(0.00px, -164.71px, 0.00px) rotate(0.00deg);
	        opacity: 0.59;
	    }
	    29.91% {
	        transform: translate3d(0.00px, -181.17px, 0.00px) rotate(0.00deg);
	        opacity: 0.65;
	    }
	    30.36% {
	        transform: translate3d(0.00px, -197.65px, 0.00px) rotate(0.00deg);
	        opacity: 0.71;
	    }
	    30.80% {
	        transform: translate3d(0.00px, -214.12px, 0.00px) rotate(0.00deg);
	        opacity: 0.76;
	    }
	    31.25% {
	        transform: translate3d(0.00px, -230.59px, 0.00px) rotate(0.00deg);
	        opacity: 0.82;
	    }
	    31.70% {
	        transform: translate3d(0.00px, -247.06px, 0.00px) rotate(0.00deg);
	        opacity: 0.88;
	    }
	    32.14% {
	        transform: translate3d(-0.00px, -263.53px, 0.00px) rotate(0.00deg);
	        opacity: 0.94;
	    }
	    32.59% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    45.10% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    45.54% {
	        transform: translate3d(0.40px, -304.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.90;
	    }
	    45.98% {
	        transform: translate3d(0.80px, -328.81px, 0.00px) rotate(0.00deg);
	        opacity: 0.80;
	    }
	    46.43% {
	        transform: translate3d(1.20px, -353.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.70;
	    }
	    46.87% {
	        transform: translate3d(1.60px, -377.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.60;
	    }
	    47.32% {
	        transform: translate3d(2.00px, -402.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.50;
	    }
	    47.77% {
	        transform: translate3d(2.40px, -426.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.40;
	    }
	    48.21% {
	        transform: translate3d(2.80px, -450.80px, 0.00px) rotate(0.00deg);
	        opacity: 0.30;
	    }
	    48.66% {
	        transform: translate3d(3.20px, -475.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.20;
	    }
	    49.11% {
	        transform: translate3d(3.60px, -499.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.10;
	    }
	    49.55% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.00;
	    }
	    50.45% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    99.55% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    100.00% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	}
	.BX_Ani13 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey13 26s linear 3.50s infinite normal none;
	}
	@keyframes BX_AniKey13 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    50.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    50.45% {
	        transform: translate3d(0.00px, -16.47px, 0.00px) rotate(0.00deg);
	        opacity: 0.06;
	    }
	    50.89% {
	        transform: translate3d(0.00px, -32.94px, 0.00px) rotate(0.00deg);
	        opacity: 0.12;
	    }
	    51.34% {
	        transform: translate3d(0.00px, -49.41px, 0.00px) rotate(0.00deg);
	        opacity: 0.18;
	    }
	    51.79% {
	        transform: translate3d(0.00px, -65.88px, 0.00px) rotate(0.00deg);
	        opacity: 0.24;
	    }
	    52.23% {
	        transform: translate3d(0.00px, -82.36px, 0.00px) rotate(0.00deg);
	        opacity: 0.29;
	    }
	    52.68% {
	        transform: translate3d(0.00px, -98.83px, 0.00px) rotate(0.00deg);
	        opacity: 0.35;
	    }
	    53.12% {
	        transform: translate3d(0.00px, -115.29px, 0.00px) rotate(0.00deg);
	        opacity: 0.41;
	    }
	    53.57% {
	        transform: translate3d(-0.00px, -131.77px, 0.00px) rotate(0.00deg);
	        opacity: 0.47;
	    }
	    54.02% {
	        transform: translate3d(0.00px, -148.23px, 0.00px) rotate(0.00deg);
	        opacity: 0.53;
	    }
	    54.46% {
	        transform: translate3d(0.00px, -164.71px, 0.00px) rotate(0.00deg);
	        opacity: 0.59;
	    }
	    54.91% {
	        transform: translate3d(0.00px, -181.17px, 0.00px) rotate(0.00deg);
	        opacity: 0.65;
	    }
	    55.36% {
	        transform: translate3d(0.00px, -197.65px, 0.00px) rotate(0.00deg);
	        opacity: 0.71;
	    }
	    55.80% {
	        transform: translate3d(0.00px, -214.12px, 0.00px) rotate(0.00deg);
	        opacity: 0.76;
	    }
	    56.25% {
	        transform: translate3d(0.00px, -230.59px, 0.00px) rotate(0.00deg);
	        opacity: 0.82;
	    }
	    56.70% {
	        transform: translate3d(0.00px, -247.06px, 0.00px) rotate(0.00deg);
	        opacity: 0.88;
	    }
	    57.14% {
	        transform: translate3d(-0.00px, -263.53px, 0.00px) rotate(0.00deg);
	        opacity: 0.94;
	    }
	    57.59% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    70.10% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    70.54% {
	        transform: translate3d(0.40px, -304.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.90;
	    }
	    70.98% {
	        transform: translate3d(0.80px, -328.81px, 0.00px) rotate(0.00deg);
	        opacity: 0.80;
	    }
	    71.43% {
	        transform: translate3d(1.20px, -353.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.70;
	    }
	    71.87% {
	        transform: translate3d(1.60px, -377.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.60;
	    }
	    72.32% {
	        transform: translate3d(2.00px, -402.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.50;
	    }
	    72.77% {
	        transform: translate3d(2.40px, -426.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.40;
	    }
	    73.21% {
	        transform: translate3d(2.80px, -450.80px, 0.00px) rotate(0.00deg);
	        opacity: 0.30;
	    }
	    73.66% {
	        transform: translate3d(3.20px, -475.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.20;
	    }
	    74.11% {
	        transform: translate3d(3.60px, -499.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.10;
	    }
	    74.55% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.00;
	    }
	    75.45% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    99.55% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    100.00% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	}
	.BX_Class12 {
	    left: 100.00px;
	    top: 1150.00px;
	    width: 580px;
	    height: auto;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	    font-size: 40px;
	    pointer-events: none;
	}
	.BX_Class12 span {
		font-weight: bolder;
		font-family: 'Heiti';
		font-size: 45px;
		letter-spacing: 3px;
		 text-shadow: 2px 2px 5px #989898; 
		/* -webkit-text-fill-color: #ffdaa6; */
		-webkit-text-stroke: 1px yellow;
	}
	.BX_Ani12 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey12 26s linear 3.50s infinite normal none;
	}
	@keyframes BX_AniKey12 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    75.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	    75.45% {
	        transform: translate3d(0.00px, -16.47px, 0.00px) rotate(0.00deg);
	        opacity: 0.06;
	    }
	    75.89% {
	        transform: translate3d(0.00px, -32.94px, 0.00px) rotate(0.00deg);
	        opacity: 0.12;
	    }
	    76.34% {
	        transform: translate3d(0.00px, -49.41px, 0.00px) rotate(0.00deg);
	        opacity: 0.18;
	    }
	    76.79% {
	        transform: translate3d(0.00px, -65.88px, 0.00px) rotate(0.00deg);
	        opacity: 0.24;
	    }
	    77.23% {
	        transform: translate3d(0.00px, -82.36px, 0.00px) rotate(0.00deg);
	        opacity: 0.29;
	    }
	    77.68% {
	        transform: translate3d(0.00px, -98.83px, 0.00px) rotate(0.00deg);
	        opacity: 0.35;
	    }
	    78.12% {
	        transform: translate3d(0.00px, -115.29px, 0.00px) rotate(0.00deg);
	        opacity: 0.41;
	    }
	    78.57% {
	        transform: translate3d(-0.00px, -131.77px, 0.00px) rotate(0.00deg);
	        opacity: 0.47;
	    }
	    79.02% {
	        transform: translate3d(0.00px, -148.23px, 0.00px) rotate(0.00deg);
	        opacity: 0.53;
	    }
	    79.46% {
	        transform: translate3d(0.00px, -164.71px, 0.00px) rotate(0.00deg);
	        opacity: 0.59;
	    }
	    79.91% {
	        transform: translate3d(0.00px, -181.17px, 0.00px) rotate(0.00deg);
	        opacity: 0.65;
	    }
	    80.36% {
	        transform: translate3d(0.00px, -197.65px, 0.00px) rotate(0.00deg);
	        opacity: 0.71;
	    }
	    80.80% {
	        transform: translate3d(0.00px, -214.12px, 0.00px) rotate(0.00deg);
	        opacity: 0.76;
	    }
	    81.25% {
	        transform: translate3d(0.00px, -230.59px, 0.00px) rotate(0.00deg);
	        opacity: 0.82;
	    }
	    81.70% {
	        transform: translate3d(0.00px, -247.06px, 0.00px) rotate(0.00deg);
	        opacity: 0.88;
	    }
	    82.14% {
	        transform: translate3d(-0.00px, -263.53px, 0.00px) rotate(0.00deg);
	        opacity: 0.94;
	    }
	    82.59% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    95.10% {
	        transform: translate3d(0.00px, -280.00px, 0.00px) rotate(0.00deg);
	        opacity: 1.00;
	    }
	    95.54% {
	        transform: translate3d(0.40px, -304.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.90;
	    }
	    95.98% {
	        transform: translate3d(0.80px, -328.81px, 0.00px) rotate(0.00deg);
	        opacity: 0.80;
	    }
	    96.43% {
	        transform: translate3d(1.20px, -353.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.70;
	    }
	    96.88% {
	        transform: translate3d(1.60px, -377.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.60;
	    }
	    97.32% {
	        transform: translate3d(2.00px, -402.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.50;
	    }
	    97.77% {
	        transform: translate3d(2.40px, -426.40px, 0.00px) rotate(0.00deg);
	        opacity: 0.40;
	    }
	    98.21% {
	        transform: translate3d(2.80px, -450.80px, 0.00px) rotate(0.00deg);
	        opacity: 0.30;
	    }
	    98.66% {
	        transform: translate3d(3.20px, -475.20px, 0.00px) rotate(0.00deg);
	        opacity: 0.20;
	    }
	    99.11% {
	        transform: translate3d(3.60px, -499.60px, 0.00px) rotate(0.00deg);
	        opacity: 0.10;
	    }
	    99.55% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(4.00px, -524.00px, 0.00px) rotate(0.00deg);
	        opacity: 0;
	    }
	}
	.BX_Class10 {
	    left: 868.00px;
	    top: 604.00px;
	    width: 200px;
	    height: 200px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(-1.49, 1.49);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG10 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/bird.png");
	}
	.BX_Ani10 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey10 7.00s linear 3.50s infinite normal none;
	}
	@keyframes BX_AniKey10 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(-1.49, 1.49);
	    }
	    40.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(-1.49, 1.49);
	    }
	    100.00% {
	        transform: translate3d(-948.00px, -808.00px, 0.00px) rotate(0.00deg) scale(-1.49, 1.49);
	    }
	}
	.BX_Class9 {
	    left: 0.00px;
	    top: 0.00px;
	    width: 720px;
	    height: 1280px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG9 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/bg.jpg");
	}
	.BX_Ani9 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey9 2.00s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey9 {
	    /* 		This layer has no animations to export */
	}
	.BX_Class8 {
	    left: 37.00px;
	    top: 815.00px;
	    width: 538px;
	    height: 246px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(-13.00deg) scale(1.30, 1.30);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG8 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/item4.png");
	}
	.BX_Ani8 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey8 1.1s linear 1.9s 1 normal forwards;
	}
	@keyframes BX_AniKey8 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-13.00deg) scale(1.3, 1.3);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-13.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class7 {
	    left: 379.50px;
	    top: 372.00px;
	    width: 373px;
	    height: 656px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(-0.92, 1.52);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG7 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/item3.png");
	}
	.BX_Ani7 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey7 1.1s linear 1.9s 1 normal forwards;
	}
	@keyframes BX_AniKey7 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(-0.92, 1.52);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(-1.22, 1.22);
	        opacity: 1.00;
	    }
	}
	.BX_Class6 {
	    left: 155.50px;
	    top: 483.00px;
	    width: 409px;
	    height: 586px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(2.15, 2.15);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG6 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/item2.png");
	}
	.BX_Ani6 {
	    animation:BX_AniKey6 1.1s linear 1.9s 1 normal forwards;
	}
	@keyframes BX_AniKey6 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(2.15, 2.15);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.85, 1.85);
	        opacity: 1.00;
	    }
	}
	.BX_Class5 {
		left: 100px;
		/*top: 450.00px;*/
		bottom: 320px;
		width: 481px;
		height: 524px;
		transform-style: preserve-3d;
		transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.57, 1.57);
		opacity: 0.00;
		transform-origin:50.00% 50.00%;
		display: block;
		position: absolute;
	}
	.BX_BG5 {
		background-repeat: no-repeat;
		background-size: contain;
		background-position: bottom center;
	}
	.BX_Ani5 {
	    animation:BX_AniKey5 1.10s linear 1.90s 1 normal forwards, BX_AniKey5_1 4.0s linear 3.00s infinite normal forwards;
	}
	@keyframes BX_AniKey5 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.57, 1.57);
	        opacity: 0.00;
	    }
	    80.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.30, 1.30);
	        opacity: 0.80;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.27, 1.27);
	        opacity: 1.00;
	    }
	}
	@keyframes BX_AniKey5_1 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.27, 1.27);
	        opacity: 1.00;
	    }
	    5.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.27, 1.27);
	        opacity: 1.00;
	    }
	    50.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.37, 1.37);
	        opacity: 1.00;
	    }
	    55.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.37, 1.37);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.27, 1.27);
	        opacity: 1.00;
	    }
	}
	.BX_Class4 {
	    left: 103.00px;
	    top: 980.00px;
	    width: 538px;
	    height: 246px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(-18.00deg) scale(2.00, 2.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG4 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/item4.png");
	}
	.BX_Ani4 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey4 1.1s linear 1.9s 1 normal forwards;
	}
	@keyframes BX_AniKey4 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-18.00deg) scale(2.00, 2.00);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-18.00deg) scale(1.70, 1.70);
	        opacity: 1.00;
	    }
	}
	.BX_Class3 {
	    left: 425px;
	    top: 774px;
	    width: 245px;
	    height: 427px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.80, 1.80);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG3 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/item1.png");
	}
	.BX_Ani3 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey3 1.1s linear 1.9s 1 normal forwards;
	}
	@keyframes BX_AniKey3 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.80, 1.80);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.50, 1.50);
	        opacity: 1.00;
	    }
	}
	.BX_Class2 {
	    left: 80.50px;
	    top: 115.50px;
	    width: 75px;
	    height: 145px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(2.46, 2.46);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG2 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/titletext.png");
	}
	.BX_Ani2 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey2 1.1s linear 1.9s 1 normal forwards;
	}
	@keyframes BX_AniKey2 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(2.46, 2.46);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(2.16, 2.16);
	        opacity: 1.00;
	    }
	}
	.BX_Class1 {
	    left: 0.00px;
	    top: 0.00px;
	    width: 720px;
	    height: 1280px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(1.00, 1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:0.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG1 {
	    background: url("http://resali.lititutu.cn/scenes/chongyangheying/cover.jpg");
	}
	.BX_Ani1 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey1 2.5s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey1 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(1, 1, 1);
	    }
	    45.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(1, 1, 1);
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotateX(-0.00deg) rotateY(-90.00deg) rotateZ(0.00deg) scale3d(1, 1, 1);
	    }
	}
</style>