<template>
    <div class="st-loading">
        <div class="st-loading-div rect1"></div>
        <div class="st-loading-div rect2"></div>
        <div class="st-loading-div rect3"></div>
        <div class="st-loading-div rect4"></div>
        <div class="st-loading-div rect5"></div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>
.st-loading {
  margin: 0 auto;
  width: 134px;
  height: 54px;
  text-align: center;
  font-size: 14px;
  padding: 20px 0 40px;
}

.st-loading-div {
  background-color: #ff9761;
  height: 100%;
  width: 8px;
  margin: 2px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.st-loading .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.st-loading .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.st-loading .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.st-loading .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4)
  }

  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
</style>