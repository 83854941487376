<template>
    <div id="sm-container">

        <div id="banner_2_0" style="position: relative;width: 100%;"></div>

        <guide-down-load></guide-down-load>

        <!-- 作者及相册信息 -->
        <div class="book-info-container container">
            <div class="userinfo-container re-container">
                <img class="author-headimg"
                    v-if="$store.state.config && $store.state.config.userInfo"
                    :src="$store.state.config.userInfo.avatarUrl" mode="widthFix" />
                <span class="username-span">{{$store.state.config && $store.state.config.userInfo? $store.state.config.userInfo.nickName: '甜豆相册用户'}}</span>
            </div>
            <span class="book-title-span"
                v-if="$store.state.config && $store.state.config.data.ti">{{$store.state.config.data.ti}}</span>
            <div class="book-data-container re-container">
                <div class="info-item-container re-container">
                    <img class="info-logo" mode="widthFix" src="http://resali.lititutu.cn/tuku/prod/d3514fddefe81a4aa5abf819aed3d692.png"/>
                    <span class="info-span">{{$store.state.config.pv_num}}</span>
                </div>
                <div class="info-item-container re-container">
                    <img class="info-logo" mode="widthFix" src="http://resali.lititutu.cn/tuku/prod/fca1ba8e4cd1f208eff5a673ff5b685d.png"/>
                    <span class="info-span">{{$store.state.config.zan_num}}</span>
                </div>
                <!-- <div class="info-item-container re-container">
                    <img class="info-logo" mode="widthFix" src="http://resali.lititutu.cn/tuku/prod/0e099ac0b7daf4bad0b77b7e26c23e4c.png"/>
                    <span class="info-span">{{$store.state.config.comment_num}}</span>
                </div> -->
            </div>
        </div>
        
        <div class="sm-row"><div class="sm-ti"><div class="sm-ti_tx">推荐模板</div></div></div>
        
        <ThemeMenu v-if="square_loaded" from="sharemore" @onThemeSelect="onThemeSelect"></ThemeMenu>

        <!-- <div class="sm-ad">
            广告位
        </div> -->
        
        <div class="sm-row"><div class="sm-ti"><div class="sm-ti_tx">更多精彩</div></div></div>
        <!-- <template
            v-if="$store.state.recMini">
            <template
                v-for="(item, index) in $store.state.recMini.detail_rec">
                <div class="sm-to-wxa"
                    v-show="( !item.self || (item.self && toMp) ) && !item.appid"
                    :key="'detail_rec' + index"
                    @click="jump(item)"
                    :style="'background-image: url(' + item.pic + ')'">
                </div>
            </template>
        </template> -->
        <!-- 引流公众号 -->
        <!-- <div class="sm-to-wxa" v-if="toMp" @click="jump('mp')" style="background-image: url(http://resali.lititutu.cn/icons/other/2317359996d697f2a8fd5b08f3b49606.gif)"></div> -->
        <!-- 祝福 -->
        <!-- <div class="sm-to-wxa" @click="jump('https://mp.weixin.qq.com/s/NwVdjPr97MilSIzCFQetwQ')" style="background-image: url(http://resali.lititutu.cn/icons/other/dc8c15ec0eca14bcfd2ddf16ae46647a.gif)"></div> -->
        <!-- 返利群 -->
        <!-- <div class="sm-to-wxa" @click="jump('https://mp.weixin.qq.com/s/lQJCQEuwDHGphuuViqSbGA')" :style="qunCover"></div> -->
        <!-- 图文 -->
        <!-- <div class="sm-to-wxa" @click="jump('https://mp.weixin.qq.com/s/5GKAprLODxbJTzMO55PRPA')" style="background-image: url(http://resali.lititutu.cn/icons/other/a96674345273e9d6cb479cbda16ba473.gif)"></div> -->

        <template v-for="book in books_data.list">
			<SquareCell :book="book" :key="book.key" v-on:goDetail="goDetail"></SquareCell>
		</template>
        <span @click="goSquare" class="more-btn">点击查看更多</span>
        
        <div class="sm-ft">3D · 立体 · 甜豆相册</div>
    </div>
</template>

<script>
    import ThemeMenu from '@/components/ThemeMenu'
    import SquareCell from '@/views/square/SquareCell'
    import GuideDownLoad from "@/components/GuideDownLoad"
    
    export default {
        name: 'ShareMore',
        components: {
            ThemeMenu,
            SquareCell,
            GuideDownLoad
        },
        data () {
            return {
                books_data: {
					list: [],
				},
                square_loaded: false,
            }
        },
        created() {
            let _this = this,
                books_data = this.books_data;

            window.addEventListener('scroll', () => {
                if (_this.square_loaded) {
                    return;
                }
                _this.square_loaded = true;
                
                // _this.$util.rget("get_rec_mini",{}).then(res=>{
                //     for(let _key in res){
                //         if(_key == "map"){
                //             continue
                //         }
                //         for(let idx = 0 ; idx < res[_key].length ; idx++){
                //             res[_key][idx] = res["map"][res[_key][idx]];
                //         }
                //     }
                // })

                _this.$store.dispatch('getRecMini', {}).then((recMini) => {})

                _this.$util.rget("get_square_data", {
                    "type": "random"
                }).then(res => {
                    if (!res["books"]) {
                        return;
                    }
                  
                    var list = [];
                    for (var i = 0; i < res.books.length; i++) {
                        var temp_book = res.books[i];
                        temp_book["userinfo"] = _this.$o_util.transformUserdata(temp_book["userinfo"]);
                        temp_book["s_type"] = "m_liti";
                        temp_book['cover'] = _this.$util.im(temp_book['cover'], 450);
                        list.push(temp_book);
                    }
                    _this.books_data.list = books_data.list.concat(list);
                });
            }, true);

        },
        mounted () {},
        computed: {
            qunCover () {
                return {backgroundImage: 'url('+ this.$config['qun']['coverImgs'][parseInt(Math.random() * 10000) % this.$config['qun']['coverImgs'].length] +')'};
            },
            // 是否跳转公众号
            toMp () {
                // return this.$store.state.config['follow_url'] != 'make';
                return this.$o_util.goFollow()
            }
        },
        methods: {
            onThemeSelect (tid) {
                let p = {};
                p[tid] = 'true';
                if (this.$env.web == 'wxa') {
                    this.$mta.click('share_to_second_theme_select', p);
                    
                    // wx.miniProgram.postMessage({ data: {tid: tid} });
                    wx.miniProgram.reLaunch({
                        // url: '/pages/make/make?tab=make&from_detail=1'
                        url: '/pages/make/make?tab=make&detail_tid=' + tid
                    })
                } else {
                    // if (this.$store.state.config['follow_url'] == 'make') {
                    if (!this.$o_util.goFollow()) {
                        this.$mta.click('share_to_second_theme_select_h5', p);
                        this.$emit('onMakeTap', tid, '2009');
                    } else {
                        this.$mta.click('share_btn_make', {'follow': 'true'});
                        window.location.href = this.$o_util.getFollowUrl(this.$store.state.config['follow_url'], 'make');
                    }
                }
            },
            jump (item) {
                let url = ""
                if (this.$o_util.isQQ()) {
                    this.$router.push({ name: 'search' });
                    return;
                }

                if (item.self) {
                    url = this.$store.state.config['follow_url'];
                } else if(item.mpid){
                    url = "https://mp.weixin.qq.com/s/" + item.mpid;
                }
                
                window.location.href = url;
            },
            goDetail(e){
                if (this.$o_util.isQQ()) {
                    this.$router.push({ name: 'search' });
                    return;
                }

                //转跳详情
                let detail_url = this.$o_util.getSharePath() + "?key=" + e.key + "&_source=" + e.value.appid;
                if (this.$route.query['visitor']) {
                    detail_url = detail_url + "&visitor=" + this.$route.query['visitor']
                }
                window.location.href = detail_url
                this.$o_util.log("square_pv")
				this.$o_util.log("square_pv_h5_secondpage")
            },
            goSquare(){
                if (this.$o_util.isQQ()) {
                    this.$router.push({ name: 'search' });
                    return;
                }
                if ('wxa' == this.$env.web) {
					wx.miniProgram.redirectTo({
						url: '/pages/make/make?tab=square'
					})
				} else {
                    //转跳广场页
                    window.location.href = "/vliti/main/square";
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    // 分享页更多内容
	#sm-container {
		position: relative;
		width: 100%;
		// height: 800px;
        // padding-bottom: 120px;
		background-color: #f1f1f1;
		color: rgb(38, 38, 38);
		// display: flex;
		// justify-content: center;
		// align-items: center;
    }
    .sm-title {
        position: relative;
        width: 280px;
        height: 80px;
        margin: 0 auto;
    }
    .sm-title_line {
        position: absolute;
        width: 280px;
        height: 3px;
        top: 50%;
        left: 50%;
        margin-top: -1.5px;
        margin-left: -140px;
        background-color: #EB3249;
        text-align: center;
    }
    .sm-title_text {
        position: absolute;
        font-size: 28px;
        width: 200px;
        height: 60px;
        line-height: 60px;
        left: 50%;
        font-weight: bold;
        margin-left: -100px;
        background-color: #f1f1f1;
        color: #EB3249;
        text-align: center;
        margin-top: -30px;
        top: 50%;
        z-index: 1;
    }
    .sm-to-wxa {
        position: relative;
        width: 480px;
        height: 288px;
        margin: 0 auto 10px;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .sm-row {
        position: relative;
        padding: 10px;
        margin-top: 20px;
    }
    .sm-ti {
        border-left: @bw * 0.007 solid #ed5003;
        font-weight: bold;
        padding-left: @bw * 0.014;
        height: 35px;
        line-height: 35px;
        color: #7b7b7b;
    }
    .sm-ti_tx {
        font-size:@bw * 0.044;
        color: #ed5002;
    }
    .sm-ft {
        position: relative;
        width: 100%;
        height: 120px;
        text-align: center;
        line-height: 120px;
        font-weight: bold;
        color: #999;
        font-family: heiti;
    }

    .sm-ad {
        text-align: center;
        padding: 30px;
        background-color: #ccc;
        font-size: 22px;
        margin: 20px 20px 0;
        border: dashed 3px #817575;
        color: #817575;
    }

    // 作者信息
    .book-info-container{
        align-items: flex-start;
        width: 468px;
        padding: 16px;
    }
    .author-headimg{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
    }
    .username-span{
        height: 30px;
        font-size: 21px;
        font-weight: 400;
        line-height: 30px;
        color:rgba(0,0,0,1);
        text-align: left;
    }
    .userinfo-container{
        margin-bottom: 16px;
        width: 468px;
        justify-content: flex-start;
    }
    .book-title-span{
        margin-bottom: 16px;
        font-size: 26px;
        font-weight: 500;
        line-height: 32px;
        color:rgba(0,0,0,1);
        opacity: 1;
    }
    .book-data-container{
        width: 468px;
        margin: 0 auto;
        justify-content: flex-start;
        .info-item-container{
            margin-right: 16px;
            .info-logo{
                width: 24px;
                margin-right: 8px;
            }
            .info-span{
                height: 21px;
                font-size: 19px;
                font-weight: 400;
                line-height: 21px;
                color:rgba(122,122,122,1);
                min-width: 66px;
                text-align: left;
                opacity: 0.99;
            }
        }
    }
    .more-btn{
        display: block;
        position: relative;
        color: #888;
        font-size: 24px;
        text-align: center;
        border: 1px solid #888;
        border-radius: 30px;
        margin: 32px auto 0;
        padding: 8px 16px;
        width: 214px;
    }
</style>