import util from '@/st_comps.util'

import Elem from './Elem'
import StElemLine from './ElemLine'
import StElemScene from './ElemScene'
import StElemPage from './ElemPage'
import StElemMask from './ElemMask'
import StElemIm from './ElemIm'

import StElem from './StElem'
import StPage from './StPage'
import Word from './Word'
import Gif from './Gif'
import GifWeapp from './Gif.weapp'

// import Taro from "@tarojs/taro"

// 导出组件所需方法
export default {
    install: function (Vue) {
        Vue.component('Elem', Elem)
        Vue.component('StElemLine', StElemLine)
        Vue.component('stElemScene', StElemScene)
        Vue.component('StElemPage', StElemPage)
        Vue.component('StElemMask', StElemMask)
        Vue.component('StElemIm', StElemIm)

        Vue.component('StElem', StElem)
        Vue.component('StPage', StPage)
        Vue.component('Word', Word)

        if (util.isEnv("wxa")) {
            Vue.component('Gif', GifWeapp)
        } else {
            Vue.component('Gif', Gif)
        }
    }
}