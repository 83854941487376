<template>
    <div class="dong13 bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
        <div class="dong13_mask" :style="getStyle">
            <div class="dong13_rot">
                <div class="dong13_scl" :style="{animationName: getAniName()}">
                    <div @click="wxPreview" class="dong13_div bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'dong13',
        props: ['mode', 'currentImage', 'showStatus'],
        data () {
            return {}
        },
        computed: {
            getStyle () {
                let twdith, theight;
                if (this.showStatus) {                
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [500, 800], 'widthFix');
                    twdith = tsize[0];
                    theight = tsize[1];
                } else {
                    twdith = 500;
                    theight = 800;
                }
                return {
                    width: twdith + 'px',
                    height: theight + 'px',
                    marginLeft: (0-(twdith/2)) + 'px',
                    marginTop: (0-(theight/2)) + 'px'
                }
            }
        },
        methods: {
            getAniName () {
                let anis = ['dong13_ani', 'dong13_ani_reverse'];
                return anis[parseInt(Math.random()*100) % anis.length];
            },
            wxPreview () {
                this.$emit('wxPreview', this.currentImage);
            }
        }
    }
</script>

<style>
    .dong13 {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0; left: 0;
    }
    .dong13_mask {
        position: absolute;
        /*width: 100%;
        height: 100%;*/
        top: 50%;
        left: 50%;
        /*width: 400px;
        height: 558px;
        top: 50%;
        left: 50%;
        margin-left: -200px;
        margin-top: -279px;*/
        -webkit-mask-image: url("http://resali.lititutu.cn/tuku/td/4b0d8d0c324f232973311b11a98f821b.png");
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        animation: fadeIn 1.5s both;
    }
    .dong13_rot {
        /*animation: 8000ms linear 0s 1 normal both running div1_rot;*/
        width: 100%;
        height: 100%;
    }
    /*@keyframes div1_rot {
        0% {
            transform: rotateZ(-2deg);
        }
        100% {
            transform: rotateZ(2deg);
        }
    }*/
    .dong13_scl {
        position: relative;
        width: 100%;
        height: 100%;
        -webkit-transform-origin: 50% 50%;
        animation: 8000ms linear 0s 1 normal both running;
    }
    @keyframes dong13_ani {
        0% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes dong13_ani_reverse {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
        }
    }
    .dong13_div {
        position: absolute;
        width: 100%;
        height: 100%;
        /*background-size: cover;
        background-position: 50% 10%;
        background-repeat: no-repeat;*/
    }
</style>