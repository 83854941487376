class stWx {
    constructor(util) {
        this.$util = util;
        if (util.isEnv('h5')) {
            // this.wxsdk = require('weixin-js-sdk');
            
            if (typeof(wx) == 'undefined') {
                this.wxsdk = require('weixin-js-sdk');
                window.wx = this.wxsdk;
            } else {
                this.wxsdk = wx;
            }
            
            this.appid = '';
        } else {
            this.wxsdk = util.$taro;
            this.appid = util.$login.appid;
        }

        this.shareOptions = {
            title: '送给你',
            desc: '朋友你好',
            link: 'http://xcgogo.site',
            imgUrl: '',
            success: null,
            fail: null
        }

        this.wxReadyListener = {
            ready: false,
            line: [],
            load: function (fn) {
                if (this.ready) {
                    fn();
                } else {
                    this.line.push(fn);
                }
            },
            run: function() {
                this.ready = true;

                for (let i=0; i<this.line.length; i++) {
                    this.line[i]();
                }
            }
        }

        this.wxExpt = {};
        this.enterOptions = null;
    }
    config (appId, timestamp, nonceStr, signature) {
        this.appid = appId;
        this.wxsdk.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                // 'updateAppMessageShareData',
                // 'updateTimelineShareData',
                'chooseImage',
                'previewImage',
                'uploadImage',
                'getLocalImgData',
                'hideAllNonBaseMenuItem',
                'showAllNonBaseMenuItem',
                'closeWindow',
                'hideMenuItems',
                'checkJsApi'
            ],
            openTagList: [
                'wx-open-launch-weapp',
				'wx-open-audio',
				'wx-open-subscribe'
            ]
        });
    }
    async jsload () {
        // // 测试延迟jsload
        // console.error("==>jsload 延迟5s");
        // await this.$util.sleep(5000);
        // console.error("==>jsload 延迟结束");
		return new Promise(( resolve, reject ) => {
            if (!this.$util.isEnv('wx') || location.host.indexOf(":") != -1) {
                this.appid = "wx1d92d19bce2bbfca";
                resolve();
                return;
            }
			this.$util.rget('/api/get_wx_config', {
				'full_url': encodeURIComponent(window.location.href)
			}).then((response) => {
				this.config(
					response['appid'],
					response['timestamp'],
					response['noncestr'],
					response['signature']
				);

                this.ready(() => {
                    this.wxReadyListener.run();
					resolve();
                }, () => {
					reject({
                        "err": "wxsdk_err"
                    });
                })
			}).catch(err=>{
                reject({
                    "err": "api_get_wx_config_err"
                });
            })
		})
	}
    ready (onready, onerror) {
        this.wxsdk.ready(() => {
            onready && onready();
        })
        this.wxsdk.error(err => {
            this.$util.$loger.warn('wx error: ', err);
            setTimeout(() => {
                onerror && onerror();
            }, 800);
        })
    }
    previewImage (current, urls) {
        if (!urls || !urls.length) {
            urls = [current];
        }
        if (this.$util.typeOf(current) == 'object') {
            current = current['im'];
        }
        
        let t_urls = this.$util.copy(urls);
        for (let i=0; i<urls.length; i++) {
            if (this.$util.typeOf(urls[i]) == 'object') {
                t_urls[i] = urls[i]['im'];
            }
            // 对url做urldecode
            t_urls[i] = decodeURIComponent(t_urls[i]);
        }
        // 对url做urldecode
        current = decodeURIComponent(current);

		this.wxsdk.previewImage({
			current: current,
			urls: t_urls
		});
	}
    checkJsApi () {
		return new Promise(( resolve, reject ) => {
			this.wxsdk.checkJsApi({
				jsApiList: ["checkJsApi"],
				success: function() {
					resolve();
				},
				fail: function() {
					reject();
				}
			});
		})
	}

    async initExptInfo () {
        if (this.$util.isEnv("webview")) { 
            // 尝试从参数中获取
            if (this.$util.$router.params.expt) {
                try {
                    this.wxExpt = JSON.parse( decodeURIComponent(this.$util.$router.params.expt) );
                    this.$util.setStorageSync("st_wxexpt", this.wxExpt);
                } catch (error) {
                    console.log("expt error==>", error)
                    console.log("this.$util.$router.params.expt==>",this.$util.$router.params.expt)
                }
            // 从storage中获取
            } else {
                console.log("从storage中获取==>")
                this.wxExpt = this.$util.getStorageSync("st_wxexpt", {});
            }
            // console.log("this.wxExpt==>",this.wxExpt);
        } else if (this.$util.isEnv("wxa") && wx.getExptInfoSync) {
            let wxExpts = this.$util.$config.app_config.expt
                && this.$util.$config.app_config.expt[this.appid];
            if (wxExpts) { 
                let result= {},
                    expt_ids = [];
                for (let i=0; i<wxExpts.length; i++) {
                    if (this.$util.typeOf(wxExpts[i]) == 'object') {
                        result[wxExpts[i]['expt_id']] = wxExpts[i]['default_id'];
                        expt_ids.push(wxExpts[i]['expt_id']);
                    } else {
                        result[wxExpts[i]] = '';
                        expt_ids.push(wxExpts[i]);
                    }
                }
                let result_from_wx = wx.getExptInfoSync(expt_ids);
                this.wxExpt = Object.assign(result, result_from_wx);
            }
        }

        for (let _exptid in this.wxExpt) {
            // st-pay相关实验组
            if (_exptid && this.wxExpt[_exptid] && _exptid.startsWith("pay_")) {
                let pay_config = await this.$util.$config.center.get_config({
                    cid: "stpaychannel_" + this.$util.$config.__APP__ + "_" + this.wxExpt[_exptid]
                })
                let pageData = [];
                pageData[ _exptid ] = pay_config
                this.$util.$store.commit("setPageDatas", pageData);
            }
            // 其他实验组
        }
        console.log("this.wxExpt==>", this.wxExpt);
        return Promise.resolve(this.wxExpt);
    }

    getEnterOptionsSync () {
        if (this.enterOptions) {
            return this.enterOptions;
        }
        let options = (wx && wx.getEnterOptionsSync && wx.getEnterOptionsSync()) || {};
        return options;
    }

    share (options) {
		Object.assign(this.shareOptions, options);

        if (this.$util.isEnv('wxa')) {
            console.log("this.shareOptions=>",this.shareOptions);
            return
        }
        // 分享个人或群聊
        this.wxsdk.onMenuShareAppMessage({
			title: this.shareOptions['title'],
			desc: this.shareOptions['desc'],
			link: this.shareOptions['link'],
			imgUrl: this.shareOptions['imgUrl'],
			success: () => {
                this.shareOptions.success && this.shareOptions.success();
                // 埋点
                this.$util.$loger.center.set_log({
                    field: this.$util.getStLogEnv() + '_' + this.$util.getStLogPage() + '_share_message_success'
                })
            }
		});

        // 分享朋友圈
        this.wxsdk.onMenuShareTimeline({
			title: this.shareOptions['title'],
			link: this.shareOptions['link'],
			imgUrl: this.shareOptions['imgUrl'],
			success: () => {
                this.shareOptions.success && this.shareOptions.success();
                // 埋点
                this.$util.$loger.center.set_log({
                    field: this.$util.getStLogEnv() + '_' + this.$util.getStLogPage() + '_share_timeline_success'
                })
            }
		});


        // 分享qq
        this.wxsdk.onMenuShareQQ({
			title: this.shareOptions['title'], // 分享标题
			desc: this.shareOptions['desc'], // 分享描述
			link: this.shareOptions['link'], // 分享链接
			imgUrl: this.shareOptions['imgUrl'], // 分享图标
			success: function () {},
			cancel: function () {}
		});

        // 分享qq空间
		this.wxsdk.onMenuShareQZone({
			title: this.shareOptions['title'], // 分享标题
			desc: this.shareOptions['desc'], // 分享描述
			link: this.shareOptions['link'], // 分享链接
			imgUrl: this.shareOptions['imgUrl'], // 分享图标
			success: function () {},
			cancel: function () {}
		});
    }
}

export default {
    stWx
}