<template>
	<div class="litihengfei-container perspective litihengfei-move">
        <div class="preserve3d po" :style="getStyle(index)" v-for="(liti, index) in litis" :key="'litihengfei-liti-'+index">
            <elem :mode="mode[1]" v-if="mode[1]"></elem>
            <div class="litihengfei-box preserve3d poc">
                <div class="preserve3d div bg poc bg_photo" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <elem :mode="mode[2]" v-if="mode[2]"></elem>
                </div>
                <div class="preserve3d div bg poc bg_photo" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <elem :mode="mode[2]" v-if="mode[2]"></elem>
                </div>
                <div class="preserve3d div bg poc bg_photo" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <elem :mode="mode[2]" v-if="mode[2]"></elem>
                </div>
                <div class="preserve3d div bg poc bg_photo" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <elem :mode="mode[2]" v-if="mode[2]"></elem>
                </div>
                <div class="preserve3d div bg po" :style="getTopStyle" @click="wxPreview"></div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'litihengfei',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {
                litis: [
                    {
                        'zIndex': 2,
                        'transform': 'translate3d(-100px, 200px, -400px)'
                    },
                    {
                        'zIndex': 1,
                        'transform': 'translate3d(130px, -150px, -800px)'
                    },
                    {
                        'zIndex': 2,
                        'transform': 'translate3d(620px, 50px, 0px)'
                    },
                    {
                        'zIndex': 1,
                        'transform': 'translate3d(1200px, -120px, -850px)'
                    },
                    {
                        'zIndex': 2,
                        'transform': 'translate3d(1350px, 130px, -350px)'
                    }
                ]
            }
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
            getStyle (idx) {
                let s = {},
                    twidth = 262,
                    theight = 345;

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [262, 345], 'widthFix');

                    twidth = parseInt(tsize[0]);
                    theight = parseInt(tsize[1]);
					
				}

                s['width'] = this.$util.getPx(twidth);
                s['height'] = this.$util.getPx(theight);
                s['top'] = '50%';
                s['marginTop'] = - (theight/2) + 'px';

                s = Object.assign(s, this.litis[idx]);

                return s;
			}
		},
		computed: {
            getTopStyle () {
                let top = '';
                if (this.$util.typeOf(this.mode[0]) == 'string') {
                    top = this.mode[0];
                } else if (this.$util.typeOf(this.mode[0]) == 'object') {
                    if (this.mode[0]['sprite_image'] && this.mode[0]['sprite_image']['path']) {
                        top = this.mode[0]['sprite_image']['path'].replace('{index}', 1);
                    }
                }

                let s = {};
                s['backgroundImage'] = 'url('+top+')';
                let ts = this.getStyle(0);
                s['width'] = ts['width'];
                s['height'] = ts['width'];
                return s;
            }
		}
	}
</script>

<style scoped>
	.litihengfei-container {
        position: absolute;
        width: 1500px;
        height: 100%;
		animation: fadeIn 2s 0s both;
	}
    .litihengfei-move {
        animation: hengmove 18s linear both infinite;
    }
    @keyframes hengmove {
        from {
            transform: none;
        }
        to {
            transform: translateX(-1500px);
        }
    }
	.litihengfei-container.perspective {
		perspective: 1000px!important;
        perspective-origin: 50% 22%;
	}
	@keyframes rote1 {
		0% {
		    transform: rotateY(0deg);
		}
		100% {
		    transform: rotateY(-360deg);
		}
	}
	.litihengfei-box {
	    /* margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0; */
	    animation: rote1 10s linear infinite both;
	}
	.div {
	    border: 4px solid #FFFFFF;
	    background-color: #eee;
	}
	.litihengfei-container .div:nth-of-type(1) {
	    transform: rotateY(90deg) translateZ(135px);
	}
	.litihengfei-container .div:nth-of-type(2) {
	    transform: rotateY(180deg) translateZ(135px);
	}
	.litihengfei-container .div:nth-of-type(3) {
		transform: rotateY(270deg) translateZ(135px);
	}
	.litihengfei-container .div:nth-of-type(4) {
	    transform: translateZ(135px);
	}
    .litihengfei-container .div:nth-of-type(5) {
	    transform: rotateX(90deg) translateZ(135px);
	}
</style>