<template>
	<div class="aixintiao_box">
	    <div class="aixintiao_bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
	        <div class="container_3d">
	            <div class="aixin_list">
	                <div class="aixin aixin1" @click="wxPreview">
                        <template v-if="mode.length == 2">
                            <img class="aixin_border" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
                            <elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
                        </template>
	                    <div class="aixin_shadow" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'aixintiao',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">

    .container_3d {

        position: absolute;

        width:@bw * 1.0;

        height:@bw * 1.0;

        top: 0;

        left: 0;

        right: 0;

        bottom: 0;

        margin: auto;

        transform-style: preserve-3d;

        z-index: 2;

    }

    @keyframes time {

        0% {

            transform: translateZ(@bw * 0.0);

        }

        100% {

            transform: translateZ(@bw * 1.0);

        }

    }

    .aixintiao_box {

        height: 100%;

        width: 100%;

        position: relative;

    }

    .aixintiao_bg {

        height: 100%;

        width: 100%;

        background: no-repeat center;

        background-size: cover;

        perspective:@bw * 1.0;

    }

    .aixin_shadow {

        height:@bw * 1.056;

        width:@bw * 1.056;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        overflow: hidden;

        // -webkit-mask-size:@bw * 1.056@bw * 1.056;

        // -webkit-mask-image: url('http://webupload.litilala.site/draw/aixinyinying_all.png');

    }

    .aixin_list {

        transform-style: preserve-3d;

        width:@bw * 1.056;

        height:@bw * 1.056;

        position: absolute;

        left: 50%;

        bottom: -@bw * 0.139;

        transform: translate(-50%);

    }

    .aixin {

        position: absolute;

        bottom:@bw * 0.028;

        left: 50%;

        opacity: 0;

    }

    .aixin1 {

        opacity: 0;

        transform: translateX(-50%) translateY(-5%) rotate(0deg);

        animation: bouncOut1 2.8s cubic-bezier(0.18, 0.27, 0.53, 0.63) infinite;

    }

    @keyframes bouncOut1 {

        0% {

            transform: translateX(-50%) translateY(-15%) rotate(0deg) scale(0);

        }

        10% {

            opacity: 1;

            transform: translateX(-50%) translateY(-15%) rotate(0deg) scale(0.2);

        }

        20% {

            opacity: 1;

            transform: translateX(-50%) translateY(-15%) rotate(0deg) scale(0.4);

        }

        40% {

            opacity: 1;

            transform: translateX(-50%) translateY(2%) rotate(0deg) scale(0.6);

        }

        90% {

            opacity: 1;

            transform: translateX(-50%) translateY(-25%) rotate(1deg) scale(0.8);

        }

        100% {

            opacity: 1;

            transform: translateX(-150%) translateY(-95%) rotate(-20deg) scale(1);

        }

    }
    .aixin_border {
        position: absolute;
        height: 110%;
        width: 110%;
        left: -5%;
        top: -5%;
        animation: aixin_zhuan 6s linear infinite;
    }
    @keyframes aixin_zhuan {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
</style>