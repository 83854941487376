<template>
	<div class="elem elem-image_div" 
		:style="myElemStyles" 
		:class="myElemClass"
		:data-id="mode['id']">

		<!-- 处理点击事件 -->
		<div v-if="mode['do']" class="page" @click="onClick"></div>
		
		<!-- 处理子elems嵌套 -->
		<elem v-for="(elem, index) in elems" 
			:key="'cover_sub_elems_' + index"
			:mode="elem"
			:currentPhoto="currentPhoto"
			:currentTime="currentTime"
			:canIShow="canIShow"
			:currentImage="currentImage"
			:scene="scene"
			:line="line"></elem>

		<div class="none" v-if="cssHtml" v-html="cssHtml"></div>
	</div>
</template>

<script>
	import BaseElem from './BaseElem'

	export default {
		name: 'ElemIm',
		extends: BaseElem,
		data () {
			return {}
		},
		created () {
			// 备份原始mode
			this.originMode = this.$util.copy(this.mode);

			// 非elem调用则直接设置样式
            if (this.canIShow === undefined) {
                this.setElemStyles(this.mode);
            }
		},
		watch: {
			canIShow: {
				handler () {
					if (this.canIShow === false && this.originMode) {
						// 结束时恢复初始样式
						this.setElemStyles(this.originMode);
					} else if (this.canIShow === true) {
						// 设置样式
						this.setElemStyles(this.mode);
					}
				},
				immediate: true
			}
		},
		computed: {
			// 是否contain模式
			isContainMode () {
				return ['aspectFit', 'contain'].indexOf(this.mode['mode']) != -1;
			},
			myElemStyles () {
				let s = this.elemStyles;

				// page产品中需要在计算属性中更新Im的值
				// if (JSON.stringify(this.pageDatas) != '{}' && this.mode['im']) {
				if (!this.scene && this.mode['im']) {
					s['backgroundImage'] = 'url('+this.getIm()+')';
				}

				// 做条件判断更改页面style状态
				s = this.doConditionStyle(s);

				return s;
			},
			myElemClass () {
				let elemClass = this.$util.copy(this.elemClass);

				if (this.mode['mode']) {
					let className = "aspect-fill";
					
					if (this.isContainMode) {
						className = this.isUserPhoto ? "aspect-full" : "aspect-fit";
					} else if (this.mode['mode'] == "widthFix") {
						className = "width-fix";
					}

					elemClass[className] = true;
				} else {
					if (this.mode['class'] != 'bg')
						elemClass['aspect-full'] = true;
				}

				if (this.mode['style'] && (this.mode['style']['mask-image'] 
					|| this.mode['style']['maskImage']
					|| this.mode['style']['MaskImage']
					|| this.mode['style']['WebkitMaskImage']
					|| this.mode['style']['webkitMaskImage']
					|| this.mode['style']['-webkit-mask-image'])) {
					let {
						elem_style,
						elem_class
					} = this.$util.getElemStyleClass(this.mode['style']);
					elemClass[elem_class] = true;
				}

				return elemClass;
			},
			containIm () {
				let containIm = this.$store.state.imagesObj[ this.$util.md5(this.getIm()) ];
				let containSize = this.$util.getContainSize( [containIm.width, containIm.height], [this.mode['size'][0], this.mode['size'][1]] )
				return {
					im: containIm['im'],
					width: containSize['width'],
					height: containSize['height']
				}
			}
		},
		methods: {
			getElemStyles (cmode) {
				cmode = cmode || this.mode;
				let mode = this.$util.copy(cmode);

				// contain模式下，计算原图尺寸并覆盖原设定尺寸
				if (this.isUserPhoto && this.isContainMode) {
					let containIm = this.containIm;

					mode['size'] = mode['size'] ? mode['size'] : [];
					mode['size'][0] = containIm.width;
					mode['size'][1] = containIm.height;
				}

				let s = this.getItemStyle(mode);
				s = this.$util.pxTransform(s, 'style');

				return s;
			},
			setElemStyles (cmode) {

				let mode = this.$util.copy(cmode);

				let styles = this.getElemStyles(mode);

				// 非page产品中在这里初始化Im的值
				// if (JSON.stringify(this.pageDatas) == '{}' && this.mode['im']) {
				if (this.scene && this.mode['im']) {
					styles['backgroundImage'] = 'url('+this.getIm()+')';
				}

				this.elemStyles = styles;
			},
			onClick () {
				let do_str = JSON.stringify(this.mode['do']);
				do_str = this.$util.replaceReg(do_str);
				let do_config = JSON.parse(do_str);
				this.$util.$action.do(do_config);
			}
		}
	}
</script>

<style>
	.elem-image_div {
		background-repeat: no-repeat;
		background-position: center;
	}
	.aspect-fill {
		background-size: cover;
	}
	.aspect-fit{
		background-size: contain;
	}
	.aspect-full {
		background-size: 100% 100%;
	}
	.width-fix {
		background-position: top;
    	background-size: 100%;
	}
</style>