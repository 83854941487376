<template>
	<div class="po" 
		v-show="canIshow"
		:style="elemStyles"
		:class="elemClass"
		:data-id="mode['id']">
		
		<!-- 处理子elems嵌套 -->
		<elem v-for="(elem, index) in elems" 
			:key="'scene_sub_elems_' + index"
			:mode="elem"
			:currentPhoto="userPhoto"
			:canIShow="canIshow"
			:currentTime="currentTime"
			:scene="mode"
			:line="line"></elem>
	</div>
</template>

<script>
	import BaseElem from './BaseElem'

	export default {
		name: 'ElemScene',
		extends: BaseElem,
		data () {
			return {
				userPhoto: {"im": ""},
				_scene: {} // 用于scenen本身action中获取
			}
		},
		watch: {
			canIshow: {
				handler () {
					if (this.canIshow) {
						// 获取并设置用户照片
						if ((this.hasUserPhoto || this.mode.elems[1]['elems'].length != 0)) {
							this.userPhoto = this.$util.getCurrentUserPhoto();

							// 发送播放完毕通知
							if (this.$util.getCurrentUserPhotoRuntime() && this.$store.state.themeRuntime && this.$store.state.modulePlayed == false) {
								this.$store.commit("setModulePlayed");
								this.$util.tdCall({
									action: 'played'
								})
							}
						}
					} else {
						// 结束时恢复初始样式
						this.elemStyles = this.getElemStyles(this.originMode);
						if (this.elemClass['debugger'])
							console.log("scene style 2 > ", this.elemStyles['zIndex']);
					}
				},
				immediate: true
			}
		},
		computed: {
			// 当前elem的子elem是否包含用户图片
			hasUserPhoto () {
				return Boolean(this.mode['elems']) && JSON.stringify(this.mode.elems).indexOf('#userphoto#') != -1;
			},
			canIshow () {
				// 若无指定显示时间区间，则直接返回true
				if (!this.mode['timing']) {
					return true;
				}
				let canIshow = false;
				for (let i=0; i<this.mode.timing.length; i++) {
					if (this.__canShow(this.mode.timing[i][0], this.mode.timing[i][1])) {
						canIshow = true;
						break;
					}
				}
				return canIshow;
			}
		},
		created () {
			// 备份原始mode
			this.originMode = this.$util.copy(this.mode);

			this._scene = this.$util.copy(this.mode);

			// 设置样式
			this.elemStyles = this.getElemStyles();
			if (this.elemClass['debugger'])
				console.log("scene style 1 > ", this.elemStyles['zIndex']);
		},
		methods: {
			__canShow (start, end) {
				// 这种情况一般是跨越头尾的时间间隔 且在第一次循环结束后开始跨越，跨越不可间断，故current不做取余
                if (end < start) {
                    if (this.$parent.$parent.runtime > 0) {
                        return (this.currentTime >= start && this.currentTime <= this.$parent.$parent.mode['duration']) || (this.currentTime >= 0 && this.currentTime < end);
                    } else {
                        return (this.currentTime >= start && this.currentTime <= this.$parent.$parent.mode['duration']);
                    }
                }

                // 判断区间
				let bol = this.currentTime >= start && this.currentTime <= end;
				if (this.currentTime == this.line['duration']) { // 到达时间线结束，同时判断时间线开始的scene
					bol = bol || (0 >= start && 0 <= end);
				}
				// console.log("start > ", start, "end > ", end, "current > ", this.currentTime, "bol > ", bol, "duration > ", this.line['duration'])
				return bol;
			},
		}
	}
</script>