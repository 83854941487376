<template>
	<div class="page pagehiden">
	    <div class="blurImage" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
	    </div>
	    <div class="page scaleRound">
	        <div class="dong3-im" :style="imageStyle" @click="wxPreview"></div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'dong3',
		props: ['mode', 'currentImage'],
		data () {
			return {
				imageStyle: {
					width: '0px',
					height: '0px',
					top: '50%',
					marginTop: '0px',
					left: '0px',
					backgroundImage: 'url()'
				}
			}
		},
		watch: {
			currentImage () {
				this.analyseImage();
			}
		},
		mounted () {
			this.analyseImage();
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			},
			analyseImage () {
				let twidth = this.$env.bw,
					theight = this.$env.bw * 1.25;
				this.imageStyle['width'] = twidth + 'px';
				this.imageStyle['height'] = theight.toString() + 'px';
				this.imageStyle['marginTop'] = -(theight/2) + 'px';
				this.imageStyle['backgroundImage'] = 'url('+ this.currentImage['im'] +')';
				// let im = new Image(),
				// 	_this = this;
				// im.onload = function() {
				// 	if (this.height/this.width > 1.25) {
				// 		let twidth = _this.$env.bw,
				// 			theight = twidth*this.height/this.width;
				// 		_this.imageStyle['width'] = twidth + 'px';
				// 		_this.imageStyle['height'] = theight + 'px';
				// 		_this.imageStyle['marginTop'] = -(theight/2) + 'px';
				// 	} else {
				// 		let theight = _this.$env.bw*1.25;
				// 		_this.imageStyle['width'] = theight*this.width/this.height + 'px';
				// 		_this.imageStyle['height'] = theight + 'px';
				// 		_this.imageStyle['marginTop'] = -(theight/2) + 'px';
				// 	}
				// 	_this.imageStyle['backgroundImage'] = 'url('+_this.currentImage['im']+')';
				// }
				// im.src = this.currentImage['im'];
			}
		}
	}
</script>

<style scoped lang="less">
	.page > * {
	    position: absolute;
	    overflow: hidden;
	}
	.page {
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    position: absolute;
	}
	.scaleRound {
	    -webkit-animation: scaleRound 1.5s ease-in-out infinite alternate;
	}
	@keyframes scaleRound {
		0% {
		    transform: scale3d(1,1,1);
		}
		100% {
		    transform: scale3d(1.02,1.02,1.02);
		}
	}
	.dong3-im {
		animation: 1.2s linear 1.2s 1 normal both running div3_clip;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	@keyframes div3_clip {
		0% {
		    -webkit-clip-path: polygon( 0% 0%, 0% 0%, 100% 0%, 100% 0%);
		}
		100% {
		    -webkit-clip-path: polygon( 0% 0%, 0% 100%, 100% 100%, 100% 0%);
		}
	}
</style>