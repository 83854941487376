<template>
	<div
        :style="'position:' + (currentPage == 'mybook'? 'relative':'relative') + ';' + 'display:' + ($store.state.tips.collection ? 'flex': 'none') + ';'"
        class="add-tips-container re-container" :class="{'add-tips_mybook': currentPage == 'mybook'}"
        v-show="isShow"
        >
        <div class="triangle"></div>
        <img
            @click="closeTips"
            class="close-btn add-tips-container-image" src="http://resali.lititutu.cn/wxa_icon/btn/close.png" />
        <span class="add-tips-container-text">喜欢甜豆相册？点击</span>
        <img class="three-point add-tips-container-image" src="http://resali.lititutu.cn/wxa_icon/btn/more.png" />
        <span class="add-tips-container-text">收藏</span>
	</div>
</template>

<script>
	export default {
        name: 'CollectionTips',
        props: {
            currentPage: {
                default: '',
            }
        },
		components: {},
		data () {
			return {
				isShow: true
			}
		},
		created () {
			if (this.$o_util.getSlog('isCollectionClosed')) {
				this.isShow = false;
			}
		},
		methods: {
			closeTips () {
				this.$store.state.tips.collection = false;
				this.$o_util.setSlog('isCollectionClosed', 1);
			},
		}
	}
</script>

<style scoped lang="less">
	.add-tips_mybook.add-tips-container {
		background-color: rgba(238,75,75,0.69)!important;
	}
	.add-tips_mybook .triangle:after {
		border-bottom: 9px rgba(238,75,75,0.69) solid!important;
	}
	.add-tips_mybook .triangle:before {
		border-bottom: 9px rgba(238,75,75,0) solid!important;
	}

	.add-tips-container {
		position: relative;
		width: 468px;
		margin: 8px 16px 0;
		padding: 5.3px 0;
		background-color: #EE4B4B;
		justify-content: space-evenly;
		border-radius: 5.3px;
		z-index: 99;
	}

	.add-tips-container-image {
		width: 20px;
		height: 20px;
	}

	.add-tips-container-text {
		color: #fff;
		font-weight: 400;
		font-size: 20px;
		letter-spacing: 6px;
	}

	.triangle {
		position: absolute;
		right: 30px;
		top: -18px;
	}

	.triangle:before,
	.triangle:after {
		position: absolute;
		content: '';
		border-top: 9px transparent dashed;
		border-left: 9px transparent dashed;
		border-right: 9px transparent dashed;
		border-bottom: 9px #EE4B4B solid;
	}

   	.triangle:before {
       	border-bottom: 9px #EE4B4B solid;
   	}


	.triangle:after {
		top: 1px;
		border-bottom: 9px #EE4B4B solid;
	}
</style>