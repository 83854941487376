export default {
    decodeUserText (text) {
		return this.decodeBase64(text);
	},
	im (imageData, resize_w) {
		return this.packageIm(imageData, resize_w);
	},
	getPx (s) {
		if (s === null || s === undefined) {
			return 'initial';
		}
		if (this.typeOf(s) != 'string') {
			s = s.toString();
		}
		// 若是纯数字，则返回单位
		if (s.match(/^(\-)?\d+(\.)?(\d+)?$/g)) {
			return s + 'px';
		}
		// 特殊尺寸处理
		if (s == 'navFullHeight') { 
			return this.getSystemInfoSync()['navFullHeight'] + 'px';
		}
		return s;				
	},
	// 通过原图尺寸获取展示区域比例
	getphotoboxsize (orisize, standsize, mode, isTest) {
		/*
			orisize 原图尺寸
			standsize 设定的最大长宽尺寸
			mode 缩放模式 
				widthFix 宽度不变，高度自动变化
				heightFix 高度不变，宽度自动变化
				both 横图按宽，竖图按高
		 */
		orisize = orisize || [500, 500];

		let owidth = parseInt(orisize[0]),
			oheight = parseInt(orisize[1]),
			twidth, theight;

		if (mode == 'both') {
			if (owidth >= oheight) {
				twidth = standsize[0];
				theight = twidth / owidth * oheight;
			} else {
				theight = standsize[1];
				twidth = theight / oheight * owidth;
			}
		} else if (mode == 'widthFix') {
			twidth = standsize[0];
			theight = twidth / orisize[0] * orisize[1];
		} else if (mode == 'heightFix') {
			theight = standsize[1];
			twidth = theight * orisize[0] / orisize[1];
		}

		twidth = twidth > standsize[0] ? standsize[0] : twidth;
		theight = theight > standsize[1] ? standsize[1] : theight;

		if (isTest) {
			_l('orisize', orisize);
			_l('size', [twidth, theight]);
		}

		return [twidth, theight];
	},
}