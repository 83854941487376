<template>
	<div class="zhuanbox-cont">
	    <div class="zhuanbox-page2" :style="{backgroundImage: 'url('+mode[0]+')'}">
	        <!-- <canvas id="zhuanbox-page2_canvas" class="con_bg_ver" height="1169" width="720"></canvas> -->
	        <!-- <div class="aixinBox"></div> -->
	        <div class="zhuanbox-page2_1" :style="getStyle">
	            <div class="preserve3d zhuan_page">
	                <div class="zhuanbox-page2_1_1 zhuan_zhuanbox-page2 preserve3d div_po scale6" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="zhuanbox-page2_1_1 zhuan_zhuanbox-page2 preserve3d div_po scale5" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="zhuanbox-page2_1_1 zhuan_zhuanbox-page2 preserve3d div_po scale4" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="zhuanbox-page2_1_1 zhuan_zhuanbox-page2 preserve3d div_po scale3" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="zhuanbox-page2_1_1 zhuan_zhuanbox-page2 preserve3d div_po scale2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="zhuanbox-page2_1_1 zhuan_zhuanbox-page2 preserve3d div_po scale1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'zhuanbox',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		computed: {
			getStyle () {
				let twidth, theight;
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [458, 600], 'both');
					twidth = tsize[0];
					theight = tsize[1];
				} else {
					twidth = 458;
					theight = 333;
				}
				return {
					width: twidth + 'px',
					height: theight + 'px'
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.zhuanbox-cont {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.zhuanbox-page2 {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.zhuanbox-page2_1 {
	    // height:@bw * 0.917;
	    // width:@bw * 0.667;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    margin: auto;
	    -webkit-perspective:@bw * 1.111;
	    position: absolute;

	}

	.zhuan_page{
	    animation: zhuanbox-page2_1 6s 2.5s cubic-bezier(0.67, 0.33, 0.51, 0.61) infinite both;
	    width: 100%;
	    height: 100%;
	    transform-style: preserve-3d
	}
	.zhuan_zhuanbox-page2 {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    transform-style: preserve-3d
	}
	.zhuanbox-page2_right {
	    width: 100%;
	    height:@bw * 0.667;
	}

	.zhuanbox-page2_1_2 {
	    transform: rotateY(90deg);
	}

	.posix1{
	    transform: rotateY(180deg);
	}

	.div_po{
	    position: absolute;
	    transform-style: preserve-3d;
	    transform-origin: bottom;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	}

	@keyframes zhuanscAni{
	    0% {
	        transform: scale(0.2);
	    }

	    100% {
	        transform: scale(1);
	    }
	}
	@keyframes zhuanbox-page2_1 {
	    0% {
	        transform: rotateY(0deg);
	    }
	    100% {
	        transform: rotateY(360deg);
	    }
	}

	.con_bg_ver{
	    height: 100%;
	}

	.div_po {
	    background-size: cover;
	    background-position: center 10%;
	    position: absolute;
	    background-size: cover;
	}

	.border_img{
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    background: no-repeat center;
	    background-size: 100% 100%;

	}
	.scale1{
	     width: 0%;
	    height: 0%;
	    animation: scale1 1.5s linear 1 both;
	}
	.scale2{
	    width: 0%;
	    height: 0%;
	    top: -@bw * 0.042;
	    animation: scale2 1.5s 0.1s linear 1 both;
	}
	.scale3{
	    width: 0%;
	    height: 0%;
	    top: -@bw * 0.083;
	    animation: scale4 1.5s 0.2s linear 1 both;
	}
	.scale4{
	    width: 0%;
	    height: 0%;
	    top: -@bw * 0.125;
	    animation: scale4 1.5s 0.3s linear 1 both;
	}
	.scale5{
	    width: 0%;
	    height: 0%;
	    top: -@bw * 0.153;
	    animation: scale5 1.5s 0.4s linear 1 both;
	}
	.scale6{
	    width: 0%;
	    height: 0%;
	    top: -@bw * 0.194;
	    animation: scale6 1.5s 0.5s linear 1 both;
	}
	@keyframes scale1 {
	   
	    100% {
	        width: 100%;
	        height: 100%;
	        top: 0;
	    }
	}
	@keyframes scale2 {
	   100% {
	        width: 100%;
	        height: 100%;
	        top: 0;
	        transform: translateZ(-@bw * 0.006);
	    }
	}
	@keyframes scale3 {
	    100% {
	        width: 100%;
	        height: 100%;
	        top: 0;
	        transform: translateZ(-@bw * 0.011);
	    }
	}
	@keyframes scale4 {
	   100% {
	        width: 100%;
	        height: 100%;
	        top: 0;
	        transform: translateZ(-@bw * 0.017);
	    }
	}
	@keyframes scale5 {
	    100% {
	        width: 100%;
	        height: 100%;
	        top: 0;
	        transform: translateZ(-@bw * 0.022);
	    }
	}
	@keyframes scale6 {
	    100% {
	        width: 100%;
	        height: 100%;
	        top: 0;
	        transform: translateZ(-@bw * 0.028);
	    }
	}
</style>