<template>
	<div class="elems preserve3d">
		<template v-for="(item, index) in mode">
			<template v-if="item['type'] == 'gif'">
				<gif :mode="item" :key="'xc-elems_gif_'+index"></gif>
			</template>
			<template v-else>
				<div class="elem" :style="getStyles(item)" :key="'xc-elems_elem_'+index">
					<img :src="(item['im'] == '#userphoto#' ? currentImage['im'] : item['im'])" :style="getImgStyles(item)">
				</div>
			</template>
		</template>
	</div>		
</template>

<script>
	export default {
		name: 'elems',
		props: ['mode', 'rotateDeg', 'currentImage'],
		methods: {
			getStyles (item) {
				let s = {
					width: item['size'][0] + 'px',
					height: item['size'][1] + 'px',
					transform: 'translate3d('+ (item['pos'].join('px,') + 'px') +') rotateY('+ (0-this.rotateDeg) +'deg)',
				}
				console.log("xc elems > ", s)
				return s;
			},
			getImgStyles (item) {
				let s = {};
				// style默认作用于img元素
				if (item['style']) {
					s = Object.assign(s, item['style']);
				}
				return s;
			}
		}
	}
</script>

<style scoped>
	.elem {
		position: absolute;
		transform-style: preserve-3d;
	}
	.elem img {
		width: 100%;
	}
</style>