<template>
    <div class="poc" :style="getStyles">
        <div class="hudie-btterfly2 po">
            <div class="hudie-btterfly2-div poc">
                <img :src="myfly" class="hudie-btf_left po">
                <div class="hudie-btf_right po">
                    <img class="hudie-btf_right_im" :src="myfly">
                </div>
            </div>
        </div>
        <div class="hudie-btterfly1 po">
            <div class="hudie-btterfly1-div poc">
                <img :src="myfly" class="hudie-btf_left po">
                <div class="hudie-btf_right po">
                    <img class="hudie-btf_right_im" :src="myfly">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: 'hudie',
        extends: BaseElem,
        computed: {
            myfly () {
                return this.comp_config[0] || "http://resali.lititutu.cn/tuku/td/b445500703ef3f331b7f5e49f88008dc.png";
            }
        },
        data () {
            return {}
        }
    }
</script>

<style>
    .hudie-btterfly1 {
        width: 106px;
        height: 88px;
        bottom: -90px;
        right: -120px;
        perspective: 500px;
        animation: hudie_btf1 16s 2s linear infinite both;
    }
    .hudie-btterfly2 {
        width: 106px;
        height: 88px;
        top: 300px;
        left: -120px;
        perspective: 500px;
        animation: hudie_btf2 16s linear 10s infinite both;
    }
    @keyframes hudie_btf1 {
        0% {
            transform: translate(50px,-50px);
            opacity: 1;
        }
        15% {
            /* transform: translate(50px,-50px); */
            opacity: 1;
        }
        60% {
            transform: translate(-650px,-300px);
            opacity: 1;
        }
        60.1% {
            transform: translate(-650px,-300px);
            opacity: 0;
        }
        100% {
            transform: translate(-650px,-300px);
            opacity: 0;
        }
    }
    @keyframes hudie_btf2 {
        0% {
            -webkit-transform: translate(-50px,-50px);
            opacity: 1;
        }
        15% {
            -webkit-transform: translate(-50px,-50px);
            opacity: 1;
        }
        60% {
            -webkit-transform: translate(650px,-300px);
            opacity: 1;
        }
        60.1% {
            -webkit-transform: translate(650px,-300px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translate(650px,-300px);
            opacity: 0;
        }
    }
    .hudie-btterfly1-div {
        transform: rotateX(56deg) rotateZ(-50deg);
        transform-style: preserve-3d;
        animation: hudie_btf1_rot 16s 7s linear infinite both;
    }
    @keyframes hudie_btf1_rot {
        0% {
            transform: rotateX(56deg) rotateZ(-50deg) scale(1.5);
        }
        15% {
            transform: rotateX(56deg) rotateZ(-50deg) scale(1.5);
        }
        60% {
            transform: rotateX(56deg) rotateZ(-60deg) scale(1.5);
        }
        60.1% {
            transform: rotateX(56deg) rotateZ(-60deg) scale(1.5);
        }
        100% {
            transform: rotateX(56deg) rotateZ(-60deg) scale(1.5);
        }
    }
    @keyframes hudie_btf2_rot {
        0% {
            transform: rotateX(60deg) rotateZ(50deg) scale(1.5);
        }
        15% {
            transform: rotateX(60deg) rotateZ(50deg) scale(1.5);
        }
        60% {
            transform: rotateX(63deg) rotateZ(70deg) scale(1.5);
        }
        60.1% {
            transform: rotateX(63deg) rotateZ(70deg) scale(1.5);
        }
        100% {
            transform: rotateX(63deg) rotateZ(70deg) scale(1.5);
        }
    }
    .hudie-btterfly2-div {
        transform: rotateX(56deg) rotateZ(50deg);
        transform-style: preserve-3d;
        animation: hudie_btf2_rot 16s linear 16.6s infinite both;
    }
    .hudie-btf_left {
        width: 53px;
        height: 88px;
        left: 0;
        transform-origin: 100% 50%;
        animation: hudie_btf_left 0.5s ease-in-out infinite alternate both;
    }
    @keyframes hudie_btf_left {
        0% {
            transform: rotateY(60deg);
        }
        100% {
            transform: rotateY(-60deg);
        }
    }
    .hudie-btf_right {
        right: 0;
        transform-origin: 0% 50%;
        animation: hudie_btf_right 0.5s ease-in-out infinite alternate both;
    }
    @keyframes hudie_btf_right {
        0% {
            transform: rotateY(-60deg);
        }
        100% {
            transform: rotateY(60deg);
        }
    }
    .hudie-btf_right_im {
        width: 53px;
        height: 88px;
        transform: rotateY(180deg);
    }
</style>