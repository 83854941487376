<template>
	<div class="square-container">
		<div class="swiper-header-bg container">
			<!-- <CollectionTips :currentPage="currentPage"></CollectionTips> -->
			<div class="swiper-header re-container" style="width: 100%;">
				<span class="swiper-header-btn"
					v-for="item in swiper_headers"
					:key="item.id"
					:class="selected_list_id == item.id ? 'swiper-header-btn-selected':''"
					@click="changeList(item.id)">{{item.name}}</span>
			</div>
		</div>

		<div v-show="selected_list_id == 'new'" style="padding-top: 56px;">
			<template v-for="book in new_data.list">
				<SquareCell :book="book" :key="book.key" v-on:goDetail="goDetail"></SquareCell>
			</template>
		</div>

		<div v-show="selected_list_id == 'book'" style="padding-top: 56px;">
			<template v-for="book in books_data.list">
				<SquareCell :book="book" :key="book.key" v-on:goDetail="goDetail"></SquareCell>
			</template>
		</div>
		
		<div v-show="selected_list_id == 'bless'" style="padding-top: 56px;">
			<template v-for="bless in bless_data.list">
				<BlessCell :bless="bless" :key="bless.id" v-on:goBlessDetail="goBlessDetail"></BlessCell>
			</template>
		</div>

		<div class="loading">
			<div class="l-preload" v-show="mineLoadStatus == 0">
				·下拉加载更多·
			</div>
			<div class="l-loading" v-show="mineLoadStatus == 1">
				<img src="http://resali.lititutu.cn/icons/app/loading2.gif">
			</div>
			<div class="l-loaded" v-show="mineLoadStatus == 2">
				<span>·没有更多了·</span>
			</div>
		</div>
	</div>
</template>

<script>
	import SquareCell from '@/views/square/SquareCell'
	import BlessCell from '@/views/square/BlessCell'
	import CollectionTips from '@/views/tips/CollectionTips.vue'

	let SQBOOKS = [];
	export default {
		name: 'Square',
		components: {
			CollectionTips,
			SquareCell,
			BlessCell
		},
		props: ['currentPage'],
		data () {
			return {
				books_data: {
					has_more: true,
					list: [],
					page_num: 0,
				},
				new_data:{
					has_more: true,
					list: [],
					page_num: 0,
					loading: false,
				},
				bless_data: {
					page_num: 0,
					has_more: true,
					list: [],
				},
				loading: false,
				mineLoadStatus: 0,
				swiper_headers: [
					{
						"id": "book",
						"name": "精选相册",
					},
					{
						"id": "new",
						"name": "最新投稿",
					},
					{
						"id": "bless",
						"name": "祝福美文",
					},
				],
				selected_list_id: "book",
			}
		},
		watch: {
			currentPage: {
				handler () {
					if (this.currentPage == 'square') {
						
						if (this.books_data.page_num == 0) {
							this.loadSquareList();

							// 记录pv
							this.$o_util.log('square');
							this.$mta.click('pv_square');
						}

						// 微信分享设置
						this.$wx.share({
							title: '甜豆相册广场，精彩内容点开看看！',
							link: window.location.protocol + '//' + window.location.host + '/vliti/main/square',
							imgUrl: 'http://resali.lititutu.cn/tuku/prod/e3124a15b00bb83e2ec4ff42e0a2f60e.jpg',
							success: () => {},
							from: 'square'
						});

						// 适配明哥账号制作入口流出的bug
						if (this.$route.query['_source'] == 'wx7d8d082b9cc46a28' && this.$route.query['openid'] == 'or11E1I-cXXF8vu8Px29DDjrrFOk') {
							window.location.replace( '/vliti/main/square' );
						}
					}
				},
				immediate: true
			}
		},
		mounted () {
			this.__scroll();
		},
		methods: {
			goDetail(item){
				// 转跳逻辑
				window.location.href = this.$o_util.getSharePath() + "?key=" + item.key + "&_source=" + item.value.appid;
				this.$o_util.log("square_pv")
				this.$o_util.log("square_pv_h5_square")
			},
			goBlessDetail(item){
				this.$toast.loading({
					message: '加载中...',
					duration: 'infinite'
				});

				let appid = item['t'] == 'arti' ? 'wxba7cee6e3846b66e' : 'wx4cf27a2def3b0d52'; // 甜豆图文 : 开心节日祝福

				this.$o_util.getWxaQrcode(item['id'], 'pages/detail/detail', appid).then((qrcode) => {
					
					this.$toast.clear();
					
					this.$toast.qrcode({
						isMask: true,
						bgSrc: 'http://resali.lititutu.cn/icons/app/qrcode_bg.png',
						qrcodeSrc: qrcode
					})
				});
			},
			loadSquareList(e) {
				// return this.selected_list_id == "bless" ? this.loadBless() : this.loadBook()
				if(this.selected_list_id == "book"){
					return this.loadBook("book");
				} else if (this.selected_list_id == "new"){
					return this.loadBook("new");
				}else{
					return this.loadBless();
				}
			},
			loadBook(book_type) {
				let _this = this;
				// let books_data = this.books_data;
				let books_data = (book_type == "book" ? this.books_data: this.new_data);
				this.mineLoadStatus = 1;
				return new Promise((resolve, reject) => {
					if (!books_data.has_more || books_data.loading) {
						resolve()
						return;
					}
					// _this.loading = true;
					let req_data = {
						'pagenum': books_data.page_num,
					}
					if (book_type == "new"){
						req_data["type"] = "new";
						// 优先显示本人投稿
						if (books_data.page_num > 0 && _this.$route.query.openid && _this.$route.query._source){
							req_data['display_openid'] = _this.$route.query.openid;
							req_data['display_appid'] = _this.$route.query._source;
						}
					}

					_this.$util.rget('get_square_data', req_data).then(res => {
						if (!res['books']) {
							resolve()
							return;
						}

						var list = [];

						for (var i = 0; i < res.books.length; i++) {
							var temp_book = res.books[i];
							temp_book['userinfo'] = _this.$o_util.transformUserdata(temp_book['userinfo'])
							temp_book['s_type'] = "m_liti";
							if(Object.prototype.toString.call(temp_book.cover).toLowerCase() == '[object string]'){
								let temp_cover = _this.$util.im(temp_book.cover, 450)
								if (_this.$util.typeOf(temp_cover) == "string"){
									temp_book.cover = temp_cover;
								}
							} else {
								temp_book['cover'] = _this.$util.im(temp_book['cover'], 450);	
							}
							if(SQBOOKS.indexOf(temp_book.key) < 0){
								SQBOOKS.push(temp_book.key)
								list.push(temp_book);
							}
						}
						if(book_type == "book"){
							_this.books_data.list = books_data.list.concat(list);
							_this.books_data.has_more = res.books.length > 0; 
							_this.books_data.page_num = res.pagenum; 
							_this.mineLoadStatus = res.books.length > 0 ? 0 : 2;
						} else {
							_this.new_data.list = books_data.list.concat(list);
							_this.new_data.has_more = res.books.length > 0; 
							_this.new_data.page_num = res.pagenum; 
							_this.mineLoadStatus = 0;
						}
						resolve()
					}).catch(err=>{
						debugger
					})
				})
			},
			loadBless(e) {
				let _this = this,
					square_data = this.bless_data;
				let _t = "arti";
				return new Promise((resove, reject)=>{
					_this.$util.rget('https://g5.litiskr.cn/blessarti/get_hot_list', {
						'_type': 'ajax',
						'pageidx': _this.bless_data.page_num,
						't': _t,
					}).then((res) => {
						var list = square_data.list,
						temp_list = [];
						for (var i = 0; i < res.length; i++) {
							var item = res[i];
							item['t'] = _t;
							temp_list.push(item);
						}
						_t = "bless";
						_this.$util.rget('https://g5.litiskr.cn/blessarti/get_hot_list', {
							'_type': 'ajax',
							'pageidx': _this.bless_data.page_num,
							't': _t,
						}).then(bres => {
							for (var i = 0; i < bres.length; i++) {
								var item = bres[i];
								item['t'] = _t;
								temp_list.push(item);
							}
							temp_list.sort(function () {
								return Math.random() > 0.5 ? -1 : 1;
							})
							list = list.concat(temp_list)
							_this.bless_data.list = list;
							_this.bless_data.has_more = bres.length != 0 || res.length != 0;
							_this.bless_data.page_num = square_data.page_num + 1;
							resove()
						})
					})
				})
			},
			__scroll () {
				var isLoading = false, that = this;
				window.addEventListener('scroll', () => {
					if (this.currentPage != 'square') {
						return;
					}
					let bottomOfWindow = that.__getScrollHeight() - that.__getWindowHeight() - that.__getDocumentTop() < 200;

					if (bottomOfWindow && isLoading === false && that.mineLoadStatus < 2) {
						isLoading = true;
						that.loadSquareList().then(()=>{
							isLoading = false;
						}).catch(err=>{
							isLoading = false;
						})
					}
				}, true);
			},
			__getDocumentTop () {
                var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
                return scrollTop;
            },
            __getScrollHeight () {
                var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
                return scrollHeight;
            },
            __getWindowHeight () {
                var windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
                return windowHeight;
			},
			changeList(key) {
				try {
					window.scrollTo(0, 0);
				} catch(e) {
					window.scrollTop = 0;
				}

				if(this.selected_list_id != key){
					this.selected_list_id = key;
				}
				// let list_length = this.selected_list_id == "bless"?this.bless_data.list.length:this.books_data.list.length;
				let list_length = this.selected_list_id == "bless" ? this.bless_data.list.length:(this.selected_list_id == "book" ? this.books_data.list.length : this.new_data.list.length);
				if(list_length < 1){
					this.loadSquareList()
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.square-container{
		padding-bottom: 100px;
	}
	.l-loaded, .l-preload {
	    color: #fff;
	    font-size:@bw * 0.039;
	    font-weight: 700;
	    letter-spacing:@bw * 0.003;
	    text-align: center;
	}
	.l-loading>img {
	    position: relative;
	    width:@bw * 0.078;
	    height:@bw * 0.078;
	    margin: 0 auto;
		display: block;
	}
	.swiper-header-bg{
		position: fixed;
		background-color: #fff;
		top: 0;
		left: 0;
		width: 500px;
		z-index: 99;
	}
	.swiper-header {
		justify-content: space-evenly;
		padding: 8px 0;
		background-color: #fff;
	}

	.swiper-header-placeholder {
		width: 500px;
		background-color: rgba(0, 0, 0, 0);
		height: 56px;
	}

	.swiper-header-btn{
		font-size: 20px;
		line-height: 30px;
	}

	.swiper-header-btn-selected {
		color: #EF5F2A;
		font-weight: 450;
		font-size: 24px;
		line-height: 30px;
	}

	.swiper-header-btn-selected:after {
		content: '';
		display: block;
		width: 48px;
		border-bottom: 4px solid #EF5F2A;
		margin: 6px auto 0;
	}
</style>







