<template>
	<div class="tongdao-box">
		<div id="panorama2" class="page_1 preserve3d po">
			<img :src="mode[0]" class="panorama_top2_1 page po">
			<img :src="mode[0]" class="panorama_top2_2 page po">
			<img :src="mode[0]" class="panorama_top2_3 page po">
			<img :src="mode[0]" class="panorama_top2_4 page po">
			<img :src="mode[0]" class="panorama_top2_5 page po">
			<div class="div2 panorama_mid2_1_1 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_1_2 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_1_3 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_1_4 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_1_5 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_2_1 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_2_2 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_2_3 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_2_4 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="div2 panorama_mid2_2_5 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<img :src="mode[1]" class="panorama_bottom2_1 page po">
			<img :src="mode[1]" class="panorama_bottom2_2 page po">
			<img :src="mode[1]" class="panorama_bottom2_3 page po">
			<img :src="mode[1]" class="panorama_bottom2_4 page po">
			<img :src="mode[1]" class="panorama_bottom2_5 page po">
			<div class="div2 panorama_mid2_3 page div po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		</div>
		
	</div>	
</template>

<script>

	export default {
		name: 'tongdao',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
        created () {
        }
	}
</script>

<style scoped>
	.page {
		width: 100%;
		height: 100%;
	}
	.po {
	    position: absolute;
	}
	.div {
	    background-size: cover;
	    background-position: center 10%;
	    background-repeat: no-repeat;
	}
	.tongdao-box {
		position: absolute;
		width: 100%;
		height: 100%;
		perspective: 448px;
	}
	#panorama2 {
	    animation: page2_1 3s linear infinite both;
	}
	.page_1 {
	    width: 500px;
	    height: 500px;
	    margin: auto;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    transform: rotateY(0deg);
	}
	.preserve3d {
	    transform-style: preserve-3d;
	}
	@keyframes page2_1 {
		0% {
		    transform: translateZ(500px);
		}
		100% {
		    transform: translateZ(0);
		}
	}
	.panorama_top2_1 {
	    transform: rotateX(-90deg) translateZ(-248px);
	}
	.panorama_top2_2 {
	    transform: rotateX(-90deg) translate3d(0,500px,-248px);
	}
	.panorama_top2_3 {
	    transform: rotateX(-90deg) translate3d(0,1000px,-248px);
	}
	.panorama_top2_4 {
	    transform: rotateX(-90deg) translate3d(0,1500px,-248px);
	}
	.panorama_top2_5 {
	    transform: rotateX(-90deg) translate3d(0,2000px,-248px);
	}
	.panorama_mid2_1_1 {
	    transform: rotateY(-90deg) translateZ(-248px);
	}
	.panorama_mid2_1_2 {
	    transform: rotateY(-90deg) translate3d(-500px,0,-248px);
	}
	.panorama_mid2_1_3 {
	    transform: rotateY(-90deg) translate3d(-1000px,0,-248px);
	}
	.panorama_mid2_1_4 {
	    transform: rotateY(-90deg) translate3d(-1500px,0,-248px);
	}
	.panorama_mid2_1_5 {
	    transform: rotateY(-90deg) translate3d(-2000px,0,-248px);
	}
	.panorama_mid2_2_1 {
	    transform: rotateY(90deg) translateZ(-248px);
	}
	.panorama_mid2_2_2 {
	    transform: rotateY(90deg) translate3d(500px,0,-248px);
	}
	.panorama_mid2_2_3 {
	    transform: rotateY(90deg) translate3d(1000px,0,-248px);
	}
	.panorama_mid2_2_4 {
	    transform: rotateY(90deg) translate3d(1500px,0,-248px);
	}
	.panorama_mid2_2_5 {
	    transform: rotateY(90deg) translate3d(2000px,0,-248px);
	}
	.panorama_bottom2_1 {
	    transform: rotateX(90deg) translateZ(-248px);
	}
	.panorama_bottom2_2 {
	    transform: rotateX(90deg) translate3d(0,-500px,-248px);
	}
	.panorama_bottom2_3 {
	    transform: rotateX(90deg) translate3d(0,-1000px,-248px);
	}
	.panorama_bottom2_4 {
	    transform: rotateX(90deg) translate3d(0,-1500px,-248px);
	}
	.panorama_bottom2_5 {
	    transform: rotateX(90deg) translate3d(0,-2000px,-248px);
	}
	.panorama_mid2_3 {
	    transform: translateZ(-2250px);
	}
	
</style>




