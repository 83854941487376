<template>
	<div class="page bg">
		<div class="page-hd">
			<img class="make-tips" src="http://litiresource.xcgogo.site/user/8031670/images/979f12aedc1222d6fdda6e54cf5d4d55.png" />
		</div>
		<div class="page-bd">
			<img class="make-theme" src="https://litiresource.xcgogo.site/user/7753395/images/1aeff82427879557ff60b99159f0c4ae.gif" @click="onCameraBtnTap" />
		</div>
		<div class="page-ft">
			<!-- <button class="make-btn" bindtap="onAlbumBtnTap">
				<img src="http://litiresource.xcgogo.site/user/5980171/images/e22138e0b89bcb0c5d8ab502c94e6d6e.png" />
				相册
			</button> -->
			<button class="make-btn" @click="onCameraBtnTap">
				<img src="http://litiresource.xcgogo.site/user/5980171/images/c76c035af83343427e2ccc74a23627e4.png" />
				选照片
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Merge',
		data () {
			return {}
		},
		methods: {
			onCameraBtnTap () {
				// this.$util.makeUpMerge(this, {
				// 	tid: 'zhongqiubianlian',
				// 	appid: this.$route.query._source,
				// 	openid: this.$route.query.openid
				// }).then((key) => {
				// 	this.$router.replace({path: 'editor', query: {
				// 		key: key,
				// 		_source: 'wx552e3843819927e5' // 变脸相册appid
				// 	}})
				// });
			}
		}
	}
</script>

<style scoped>
	.page {
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    display: flex;
	    flex-direction: column;
	}
	.bg {
	    background-image: url(http://litiresource.xcgogo.site/user/5980171/images/fb2d6e7509a7698bfe30977aebf7694d.jpg);
	    background-size: cover;
	    background-position: center;
	}
	.page>div {
	    position: relative;
	    width: 100%;
	}
	.page-bd {
	    flex: 1;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}
	.page-ft {
	    /* flex: 1; */
	    height: 240px;
	    display: flex;
	    justify-content: space-evenly;
	    align-items: center;
	}
	.make-tips {
	    position: relative;
	    display: block;
	    width: 480px;
	    margin: 64px auto 20px;
	    /* height: 230px; */
	}
	.make-theme {
	    position: relative;
	    display: block;
	    border: solid 8px white;
	    width: 480px;
	    margin: 0 auto;
	    border-radius: 
	}
	.make-btn {
	    /* color: #666; */
	    color: white;
	    font-weight: bold;
	    /* background-color: #EEE; */
	    background: linear-gradient(to top right, #ff9761, #E72047);
	    border: solid 6px #fff;
	    /* border-color: #EEE; */
	    /* font-weight: 300; */
	    font-size: 40px;
	    text-decoration: none;
	    text-align: center;
	    /* line-height: 88px; */
	    height: 108px;
	    padding: 0 72px;
	    margin: 0;
	    appearance: none;
	    cursor: pointer;
	    /* border: none; */
	    box-sizing: border-box;
	    display: flex;
	    justify-content: space-around;
	    align-items: center;

	}
	.make-btn>img {
	    position: relative;
	    width: 48px;
	    margin-right: 12px;
	}
</style>