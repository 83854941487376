<template>
	<div 
		class="elem" 
		:style="myElemStyles" 
		:class="elemClass"
		:data-id="mode['id']">
		
		<!-- 处理子elems嵌套 -->
		<template v-if="ready">
			<elem v-for="(elem, index) in elems" 
				:key="'cover_sub_elems_' + index"
				:mode="elem"
				:currentPhoto="currentPhoto"
				:currentTime="currentTime"
				:canIShow="canIShow"
				:currentImage="currentImage"
				:scene="scene"
				:line="line"></elem>
		</template>
		<div v-else class="page container">
			<st-loading></st-loading>
		</div>
	</div>
</template>

<script>
	import BaseElem from './BaseElem'
	import StLoading from '@/components/st_comps/st_comp/comps/StLoading/StLoading'

	export default {
		name: 'ElemPage',
		extends: BaseElem,
		components: {StLoading},
		data () {
			return {
				ready: false,
                datas: {}
            }
		},
		watch: {
			mode: {
				handler (nv, ov) {
					if (nv) {
						this.setPageDatas();
					}
				},
				immediate: true
			}	
		},
		created () {
			// // 初始化pageDatas -- this.$util.$router.params 中数据不写入store
			// this.$store.commit("updatePageDatas", this.$util.copy(this.$util.$router.params))

			// 设置样式
			this.setElemStyles(this.mode);
			// // 处理api
			// this.$util.$eventCenter.on("PRELOAD_FINISH").then(() => {
			// 	this.setPageDatas().then(() => {
			// 		this.ready = true;
			// 		// 设置微信分享
			// 		let _this = this;
			// 		if (this.mode['share_config']) {
			// 			_this.$util.$wx.share({
			// 				title: _this.getShareTitle(),
			// 				desc: _this.mode['share_config']['desc'],
			// 				imgUrl: _this.mode['share_config']['imgUrl'],
			// 				imageUrl: _this.getShareImage(),
			// 				link: _this.getShareLink(),
			// 				path: _this.getSharePath(),
			// 				success () {
			// 					_this.$util.$loger.log("uni_share", {
			// 						pid: _this.mode['pid']
			// 					})
			// 				}
			// 			})
			// 		}
			// 	});
			// })
		},
		computed: {
			myElemStyles () {
				let s = this.elemStyles;
				 // 响应im数据变化
				if (this.mode['im']) {
					s['backgroundImage'] = 'url('+this.getIm+')';
				}
				return s;
			},
		},
		methods: {
			async setPageDatas () {
				let _this = this;

				await this.$util.$eventCenter.on("PRELOAD_FINISH").catch(err=>{});

				this.datas = await new Promise(resolve => {
					this.fetchUserInfo().then(() => {
						let api = _this.mode['api'] || ( _this.stCondition && _this.$util.typeOf(_this.stCondition) == "object" && _this.stCondition['api'] );
						if (api) {
							api = _this.replaceReg(api);
							_this.$util.rget(api, {}).then(resp => {
								resolve( _this.fitRegKey(resp) );
							}).catch(err=>{
								// api请求失败
								_this.$util.$eventCenter.trigger("NET_WORK_ERROR");
							})
						} else {
							resolve({});
						}
					})
				})
				this.$store.commit("updatePageDatas", this.$util.copy(this.datas));

				this.ready = true;

				if (this.mode['share_config']) {
					this.$util.$wx.share({
						title: this.getShareTitle(),
						desc: this.mode['share_config']['desc'],
						imgUrl: this.mode['share_config']['imgUrl'],
						imageUrl: this.getShareImage(),
						link: this.getShareLink(),
						path: this.getSharePath(),
						success () {
							this.$util.$loger.log("uni_share", {
								pid: this.mode['pid']
							})
						}
					})
				}

				return Promise.resolve();
			},
			fetchUserInfo () {
				return new Promise(resolve => {
					// 处理登陆
					if (this.mode['login'] && this.$util.isEnv('wx')) {
						this.$util.$login.base().then(openid => {
							this.$util.$login.userinfo().then(userinfo => {
								resolve();
							})
						})
					} else {
						setTimeout(() => {
							resolve();
						}, 50);
					}
				})
			},
			getShareTitle () {
				let title = this.mode['share_config']['title'];
				let {
					page_data_shareconfig
				} = this.pageDatas;
				if (page_data_shareconfig && page_data_shareconfig['titles']) {
					title = page_data_shareconfig['titles'].randomChoice();
				}
				title = title && this.$util.replaceDate(title);
				title = title && this.replaceReg(title);
				return title;
			},
			getSharePath () {
				let page = this.$util.getPageInstance();
				let path = this.mode['share_config']['path']
					.replace("#currentPage#", page.route);
				return this.replaceReg(path);
			},
 			getShareLink () {
				if (this.$util.isEnv("wxa")) {
					return "";
				}
				// 替换关键字
				let link = this.mode['share_config']['link']
					.replace("#currentHref#", window.location.href)
					.replace("#currentPath#", window.location.pathname);

				link = this.replaceReg(link);
				
				// 补充域名
				if (link.indexOf('http') == -1) {
					if (link[0] != '/') {
						link = '/' + link;
					}
					link = window.location.origin + link;
				}
				return link;
			},
			getShareImage () {
				if (this.mode['share_config']['imageUrl']) {
					return this.replaceReg(this.mode['share_config']['imageUrl']);
				}
				let imageUrl = "",
					{
						page_data_photo,
						page_data_shareconfig
					} = this.pageDatas;
				if (page_data_photo && page_data_shareconfig) {
					let usercover = this.$util.packageIm(page_data_photo);
					let host = "",
					hostend = usercover.indexOf(".cn/") > -1 ? ".cn/":".com/";
					host = usercover.split(hostend)[0] + hostend;
					let _usercover = usercover.split(hostend)[1].split("?")[0];
					// 七牛图片替换host到回源的oss_bucket
					if (this.$util.$config.app_config.image_map && host == this.$util.$config.app_config.image_map.host_map['qiniu']) {
						host = 'http://wxaqiniu.lititutu.cn/';
					}
					imageUrl = host + this.$util.getShareCover(_usercover, page_data_shareconfig.cover);
				}
				return imageUrl;
			}
		}
	}
</script>

<style>

</style>