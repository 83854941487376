class stRequestBase {
    constructor (util) {
        this.$util = util;
    }
    _findUrl (api) {
        let url = "";
        if (api.indexOf('//') != -1) {
            url = api;
        } else {
            let host = this.$util.$config.REQ_HOST,
                path = api.indexOf('/') == -1 ? '/' + this.$util.$config.__APP__ + '/' + api : api;
            if (path[0] != '/') {
                path = '/' + path;
            }
            url = host + path;
        }
        return url;
    }
    _findParams (params) {
        params = params || {};
        params['_type'] = 'ajax';
        params['__debug__'] = this.$util.$config.ISDEBUG;
        params['__app__'] = params['__app__'] || this.$util.$config['__APP__'];
        params['__version__'] = this.$util.$config.VERSION;
        if ( this.$util.isEnv("wxa") ) {
            params['__wxa__'] = true;
            params['__appid__'] = this.$login.appid;
        }
        // 参数格式检查
        for (let k in params) {
            if (['array', 'object'].indexOf(this.$util.typeOf(params[k])) != -1) {
                let err = "请求参数: "+k+" 存在多层value，请检查格式";
                if (this.$util.$config.ISDEBUG) {
                    alert(err);
                }
                console.warn(err);
            }
        }
        return params;
    }
    _log (options) {
        this.$util.$loger.grayLog && this.$util.$loger.grayLog({
            "field": "uni_request",
            "gray": 0.1,
            "data": options || {}
        });
    }
}

export default {
    stRequestBase
};