<template>
	<div class="poc zuofengche">
	    <div class="zuofengche_1 po zuofengche_per" style="width: 888px; height: 530px;">
	        <div class="preserve3d poc">
	            <div class="zuofengche_rotY preserve3d poc">
	                <div class="page preserve3d po">
	                    <div class="zuofengche_F poc">
	                        <div class="zuofengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                        <div class="zuofengche_left zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                        <div class="zuofengche_right zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                    </div>
	                    <div class="zuofengche_border_l zuofengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                    <div class="zuofengche_border_r zuofengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                    <div class="zuofengche_1_B poc">
	                        <div class="zuofengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                        <div class="zuofengche_left zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                        <div class="zuofengche_right zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                    </div>
	                </div>
	                <div class="zuofengche_1_2 page preserve3d po">
	                    <div class="zuofengche_1_F page border_bg po">
	                        <div class="zuofengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                        <div class="zuofengche_left zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                        <div class="zuofengche_right zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                    </div>
	                    <div class="zuofengche_border_l zuofengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                    <div class="zuofengche_border_r zuofengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                    <div class="zuofengche_1_B page border_bg po">
	                        <div class="zuofengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url('+ mode[1] +')'}"></div>
	                        <div class="zuofengche_left zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                        <div class="zuofengche_right zuofengche_div po" @click="wxPreview" :style="getStyle"></div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'zuofengche',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {
				borderHeight: '100%'
			}
		},
		computed: {
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [420, 530], 'widthFix');

					this.borderHeight = (tsize[1] + 12) + 'px';
					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				} else {
					return {
						width: '420px',
						height: '530px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style>
	.zuofengche_1 {
		margin: auto;
		top: 0;
		bottom: 30px;
		left: -444px;
		/*width: 888px;*/
		/*height: 331px;*/
	}
	.zuofengche_per {
		perspective: 1200px;
	}
	.zuofengche_rotY {
		animation: zuofengche_rotY 16s linear infinite both;
	}
	@keyframes zuofengche_rotY {
		0% {
			transform: rotateY(90deg);
		}
		100% {
			transform: rotateY(-270deg);
		}
	}
	.zuofengche_F {
		transform: translateZ(-8px) rotateY(180deg);
	}
	.zuofengche_kuang_bg {
		background-size: 100% 100%;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	.zuofengche_div {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #eee;
		/*width: calc(50% - 24px);
		height: calc(100% - 16px);*/
	}
	.zuofengche_left {
		margin: auto;
		left: 8px;
		top: 0;
		bottom: 0;
	}
	.zuofengche_right {
		margin: auto;
		right: 8px;
		top: 0;
		bottom: 0;
	}
	.zuofengche_border_l {
		left: 0;
    	transform: translateX(-8px) translateZ(0) rotateY(-90deg);
	}
	.zuofengche_border_r {
		right: 0;
    	transform: translateX(8px) translateZ(0) rotateY(90deg);
	}
	.zuofengche_kuang_border {
		width: 16px;
		background-size: auto 100%;
		background-position: 0 0;
		backface-visibility: hidden;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	.zuofengche_1_B {
		transform: translateZ(8px);
	}
	.zuofengche_1_2 {
		transform: rotateY(90deg);
	}
	.zuofengche_1_F {
	    transform: translateZ(-8px) rotateY(180deg);
	}
</style>




















