export default {
	// 实验组换模板
	async payChangeTheme (options) {
		let {
				tid,
				key
			} = options;
		// 解锁模板
		this.uplockTheme(options);
		// 请求换模板
		await this.rget('setdata', {
			"k": "ttid",
			"v": tid,
			"key": key
		}).catch(err=>{})
		this.$global.set("td_webview_reload_url", 1);
		this.$router.navigateBack();
	},
	// 实验组制作相册
	async exptSelectorNewBook (options) {
		let{
				tid,
				selector
			} = options;
		// 未解锁
		if (await this.checkThemeLock(options).catch(()=>{})) {
			this.$loger.log("uni_toast", {
				"action": "show",
				"vscene": 8004,
				"tid": tid
			})
			// 弹窗
			this.$action.do({
				"toast_id": "st_make_middle_toast1",//selector ? "toast1" : "toast2",
				"do": "toast",
				"if": "else",
				"act": "show"
			})
			return Promise.resolve();
		}
		// 已解锁/无锁
		return await this.selecotorNewBook(options);
	},
	// 判断模板是否锁
	async checkThemeLock (options) {
		let is_lock = false,
			{
				tid
			} = options;
			// theme_locked = false;
		if (!tid) {
			return Promise.resolve(is_lock);
		}

		if (this.$wx.wxExpt['expt_td_212']
			&& this.$wx.wxExpt['expt_td_212'] > 0) {
			let unlock_tids = this.getStorageSync("td_unlock_tids", []);
			if (unlock_tids.indexOf(tid) > -1) { // 已解锁
				return Promise.resolve(is_lock);
			} else {
				let res = await this.rget("check_theme_islock", {
					"tid": tid,
					"ukey": this.$login.user.ukey
				});
				if (res['unlock_tids']) {
					unlock_tids = res['unlock_tids'];
					this.setStorageSync("td_unlock_tids", unlock_tids);
				}
				return Promise.resolve( Boolean( res['locked'] ) );
			}
		}
		return Promise.resolve(is_lock);
	},
	// 解锁模板
	uplockTheme (options) {
		let {
			tid
		} = options;
		if (tid
			&& this.$wx.wxExpt['expt_td_212']
			&& this.$wx.wxExpt['expt_td_212'] > 0) {
			let unlock_tids = this.getStorageSync("td_unlock_tids", []);
			if (unlock_tids.indexOf(tid) == -1) {
				unlock_tids.push(tid);
				this.setStorageSync("td_unlock_tids", unlock_tids);
				this.rget("unlock_theme", {
					"tid": tid,
					"ukey": this.$login.user.ukey
				})
			}
		}
	},
	// 图片选择器制作相册
	async selecotorNewBook (options) {
		let params = {},
			{
				selector,
				tid
			} = options;
		// 选择历史照片模式
		if (selector) {
			let st_selected_images = this.$global.get(selector);
			if (!params['imgInfos'] && st_selected_images) {
				params['imgInfos'] = st_selected_images;
			}
		}
		if (tid) {
			params['tid'] = tid;
		}

		// 解锁模板
		this.uplockTheme(options);

		return await this.newBook(params);
	},
    // 新建相册
    async newBook (params) {
		params = params || {};
		if (!params['mid'] && this.$router.params['mid']) {
			params['mid'] = this.$router.params['mid'];
		}
		let {
			vscene,
			msource,
			stype,
			tid,
			step
		} = params,
		log_params = {};
        if (!vscene) {
			vscene = this.$router.params.vscene || "4000";
		}
		if (!step) {
			step = "make";
			// logs 新增
			if (stype) {
				log_params["make_mode"] = "camara";
			} else if (msource) {
				log_params["make_mode"] = "chat";
			} else if (params['imgInfos']) {
				log_params["make_mode"] = "history";
			} else {
				log_params["make_mode"] = "album";
			}	
		}
		log_params["step"] = step;
		log_params["vscene"] = vscene;
		if (tid) {
			log_params["tid"] = tid;
		}

		// h5中-拦截制作跳转制作中间页
		// 2022-08-27 新增条件 新人拦截
		if ( this.isEnv("webview") 
			&& this.$condition.check({
				"conditions": {
					"if": {
						"is_new": 0
					}
				}
			})
		){
			this.$loger.log("uni_make", Object.assign(log_params, {
				"step": "click"
			}))

			let arvgs = this.parseParams(params);
			// let path = "/subpackages/page/page?pid=stpage_vliti_make" + (arvgs ? "&" + arvgs : "");
			let path = "/subpackages/make/make" + (arvgs ? "?" + arvgs : "");
			
			if (await this.checkThemeLock({"tid": tid})) {
				path = path + "&theme_lock=1"
			}

			this.$router.navigateTo({
				"url": path
			});
			return;
		}

		this.$loger.log("uni_make", log_params)

        // 支持在指定模板数组中随机选择一个
        if (params['tids']) {
            params['tid'] = params['tids'][ parseInt(Math.random() * 100000) % params.tids.length ];
            delete params['tids'];
        }
        // 获取相册照片列表参数
        if (params['urls']) {
            params['imgInfos'] = [];
            if (this.typeOf(params['urls']) == 'array') {
                // 对传入的urls做纯净处理
                for (let i=0; i<params.urls.length; i++) {
                    params['urls'][i] = params['urls'][i].split("?")[0];
                    let image_info = await this.getImageInfo({
                        "src": params['urls'][i]
                    });
                    params['imgInfos'].push({
                        "url": params['urls'][i],
                        "width": image_info['width'],
                        "height": image_info['height']
                    })
                }
            }
        }
        
        if (!params['imgInfos']) {
            params['imgInfos'] = await this.$upload.uploadMedia({
				"mediaSource": msource,
				"sourceType": stype
			}).catch(err=>{});
            if (!params['imgInfos']){
                return Promise.reject();
            }
        }

        if (this.typeOf( params['imgInfos'] )  !== "string") {
            params['imgInfos'] = JSON.stringify( params['imgInfos'] )
        }

		// 小程序检查默认登录
		if (!this.$login.openid && this.isEnv("wxa")) {
			await this.$login.base();
		}

        // 获取相册作者信息
        if (this.$login.openid) { // 存在用户信息、直接制作
            
            params['appid'] = this.$login.appid;
            params['openid'] = this.$login.openid;
            let key = await this.rpost('/vliti/new_book', params).catch(err=>{})
			if (key) {
				this.$global.set("td_list_need_refresh", true); //标记需要刷新列表

				// 埋点
				this.$loger.log("uni_make_success",log_params);
				this.$loger.center.set_log({
					field: this.getStLogEnv() + '_'+ vscene +'_make_success'
				})
				
				this.tdRedirectTo('editor', {
					'key': key,
					'_source': params['appid']
				})
				// 记录上一制作完成key
				this.$global.set("td_last_success_key", key);
			}
        
        } else { // 不存在用户登陆信息、前往sharemake登陆并制作
            this.rpost("set_sharemake_images", {
                images: params['imgInfos']
            }).then(imagekeys => {
				// 埋点
                this.$loger.log("uni_make_success",{
                    "vscene": vscene
                })
				this.$loger.center.set_log({
					field: this.getStLogEnv() + '_'+ vscene +'_make_success'
				})
				
				// 处理参数
                delete params['urls'];
                delete params['imgInfos'];
                params['v'] = 1;
                params['imagekeys'] = imagekeys;

                this.tdRedirectTo("sharemake", params);
            })
        }

        // 针对老版本小程序适配 2022-03-01
        if (this.isEnv("webview")) {
            if (params['tid']) this.$wx.wxsdk.miniProgram.postMessage( { data: { update_tid: params['tid'] } });
            this.$wx.wxsdk.miniProgram.postMessage( { data: { refresh_list: 1 } });
        }
    },
	// 根据配置生成图片
	async drawPicture (options) {
		let {
			elem
		} = options;
		// 画图
		let path = await this.$canvas.stGetCanvasImage({"elem": elem}).catch(err=>{
			debugger
		});
		let image = await this.$upload.uploadOSS({
			"path": path
		}).catch(err=>{
			console.log("drawPicture err=>",err)
		})
		return image;
	},
	async fastMake (options) {
		let _this = this,
			{
				params,
				elem,
				elems,
				action,
				event,
				origin_key,
				vscene
			} = options,
			promises = [];
		action = action || "go_edit";

		this.$loger.log("fast_make", {
			"step": "start"
		})
		this.$loger.log("uni_make",{
			"vscene": vscene ? vscene.replace("20", "40") : '4000',
			"step": "click"
		})

		if (this.isEnv("wxa")) { // 小程序添加订阅消息&登录
			// 设置下次优先订阅消息"作品收花提醒"
			this.$submsg.pushNextSubIds([5]);
			let sub_promise = new Promise( (resolve, reject)=> {
				_this.requareTemplate({}).then(()=>{resolve(true)}).catch(()=>{resolve(true)})
			})
			promises.push(sub_promise);

			if ( !(this.$store.state.userInfo && this.$store.state.userInfo['nickname']) ) {
				promises.push(this.$login.userinfo({}));
			}
			let [tmpl, userinfo] =  await Promise.all(promises).catch(err=>{});
			if (!userinfo) {
				this.$loger.log("fast_make", {
					"err": "nouserinfo"
				})
			}
		}

		this.$taro.showLoading({
			title: '生成中...',
			mask: true
		});

		// 限制一日之内制作一次
		let today = new Date().format("yyyy_MM_dd"),
			storage_key = "st_" + today + "_" + event;
		let key = this.getStorageSync(storage_key);

		let rand_num = 0;
		try {
			let openid_num = this.$login.openid && this.$login.openid.replace(/[^\d]/g, "");
			openid_num = openid_num && openid_num.length > 0 ? parseInt(openid_num) : 0;
			let today_num = this.getTimeDetail(new Date());
			rand_num = openid_num + today_num.year + today_num.month + today_num.day;
		} catch (error) {
			_this.$loger.log("fast_make", {
				"err": "rand_num_err"
			})
		}

		// params
		let event_config = await this.rget("/config_center/get_config", {
			cid: "app_vliti_event_" + event,
			is_debug: this.$config.ISDEBUG ? 1 : 0
		}).catch(err=>{
			_this.$loger.log("fast_make", {
				"err": "get_config_err"
			})
		})

		params = params || {};
		let event_image = "";

		params["origin_key"] = origin_key || "";
		if (!origin_key && event_config["keys"]) {
			params["origin_key"] = event_config["keys"][ rand_num % event_config["keys"].length]
		}

		if (event_config["images"]) {
			event_image = event_config["images"][ rand_num % event_config["images"].length ];
		}

		if (!params["origin_key"] && event_config["modules"]) {
			let rand_module = event_config["modules"].randomChoice();
			if (rand_module) {
				params["tid"] = rand_module.split("vliti_")[1];
			}
		}

		if (!key || !params["origin_key"]) { // 今天之内未制作过对应event的相册

			// 小程序检查默认登录
			if (!this.$login.openid && this.isEnv("wxa")) {
				await this.$login.base().catch(err=>{
					debugger;
				});
			}

			event_config = JSON.parse( this.replaceReg( JSON.stringify(event_config), {
				"event_image": event_image
			} ) );

			elem = elem || event_config["elem"];

			if (params['vscene']) {
				vscene = params['vscene'];
			}
			vscene = vscene ? vscene.replace("20", "40") : '4000';
			this.$loger.log("uni_make",{
				"vscene": vscene,
				"step": "make"
			})
	
			params['appid'] = this.$login.appid;
			params['openid'] = this.$login.openid;

			if ( !(params && params['origin_key']) ) { //非复制模式
				params['imgInfos'] = await this.$upload.uploadMedia().catch(err=>{
					debugger;
				});
				
				
				if (!params['imgInfos']){
					this.$taro.hideLoading();
					return Promise.reject();
				}

				params['imgInfos'] = JSON.stringify( params['imgInfos'] );

				key = await this.rpost('/vliti/new_book', params).catch(err=>{})
				
			} else { // 复制相册并且插入首图模式
				console.log("elem==>",elem);
				let a = await this.drawPicture({"elem": elem});
				debugger;
				let promises = [
					this.rpost('/vliti/new_book', params).catch(err=>{debugger})
				];
				if (elem) { // 需要插入首图则
					promises.push(this.drawPicture({"elem": elem}));
				} else if (elems) { // 插入一系列图片
					debugger;
				} else {
					this.$loger.log("fast_make", {
						"err": "noelem"
					})
				}
	
		
				let results = await Promise.all(promises).catch(err=>{debugger;})
		
				key = results[0];
				let token = (this.$store.state.userInfo && this.$store.state.userInfo.token);// await this.$login.getUserInfo().catch(err=>{})
				
				if (!key || !token) {
					this.$taro.hideLoading();
					this.$loger.log("fast_make", {
						"err": !key ? "nokey" : "notoken"
					})
					return Promise.reject();
				}
		
				if (results.length > 1 && results[1]) {
					await this.rpost("/vliti/new_images", {
						"token": token,
						"imgInfos": JSON.stringify([{
							"tag": event,
							"url": results[1]["url"],
							"width": results[1]["width"],
							"height": results[1]["height"]
						}]),
						"key": key,
						"pre_id": -1,
					}).catch(err=>{debugger})
				}
			}
			
			// 制作成功
			if (key) {
				// 上广场
				this.rget("wxa_enter_square",{
					"event": event,
					"openid": this.$login.openid,
					"xc_key": key,
					"origin_key": params["origin_key"]
				})
	
				this.setStorageSync(storage_key, key);
	
				// 主动触发塞入假数据
				this.tryInsertFakeValue("", key);
			}
		}

		this.$loger.log("fast_make", {
			"step": "end"
		})

		this.$taro.hideLoading();

		// 结束动作 默认进入编辑页
		if (key && action == "go_edit") {
			this.tdRedirectTo('editor', {
				'key': key,
				'_source': this.$login.appid,
				'fast_make': event
			})
		}

		return Promise.resolve();
	},
    // 甜豆路径跳转
	tdRedirectTo (path, params) {
        let url;
        switch (path) {
            case 'editor':
                if (this.isEnv("wxa")) {
                    this.$router.navigateTo({
                        "url": "/pages/detail/detail?open_type=editor&" + this.setUrlQuery(params)
                    })
                } else {
                    window.location.href = "/vliti/main/" + this.$store.state.appConfig.editor_path + "?" + this.setUrlQuery(params);
                }
                break;

            case 'share':
                params = params || this.getUrlQuerys();
			    url = "/vliti/main/" + this.$store.state.appConfig.share_path + "?" + this.setUrlQuery(params);
                window.location.replace(url);
                break;

            case 'sharemake':
                window.location.href = "/vliti/sharemake?" + this.setUrlQuery(params);
                break;

            case 'mybook':
                if (this.isAuthor()) {
                    window.location.href = "/vliti/main/mybook?openid=" + this.$store.state.data.openid + "&_source=" + this.$store.state.data.appid;
                } else {
                    window.location.href = "/vliti/login?target=mybook";
                }
                break;

            case 'mybook.sub':
                window.location.href = "https://mp.weixin.qq.com/s/JWvtKO_Y9riREJxJvdGXtw";
                break;

            case 'menu':
                if (this.isEnv('webview')) {
                    this.$router.redirectTo({
                        url: "/pages/make/make?tab=my"
                    })
                } else {
                    window.location.replace("/vliti/main/" + this.$store.state.appConfig.menu_path);
                }
                break;

            case 'empty':
                this.$router.redirectTo({
                    url: "http://litiv.litifly.xyz/vliti/main/empty?key="+this.getUrlQuery("key")
                })
                break;

            case 'block':
                let redirect_url = '/vliti/main/mybook?' + this.setUrlQuery(params);
                url = '/public/msg?msgtype=warn&title='+encodeURIComponent('相册审核中')+'&desc='+encodeURIComponent('根据相关法律法规，当前相册中存在需要审核的内容，工作人员正在加紧审核，请耐心等待！给您带来不便，敬请谅解！')+'&default_url='+encodeURIComponent('/public/apps_report?__app__=liti&redirect_url='+encodeURIComponent(redirect_url)+"&remark=reblock&__appid__="+params._source+"&openid="+params.openid+"&key="+this.getUrlQuery("key"))+'&default_txt='+encodeURIComponent('加快审核');
			    window.location.replace(url);
                break;
        }
	},
    // 照片获取逻辑
	getCurrentUserPhoto (carry) {
		// 适用于carry为false，不进位获取当前图片的逻辑

		// carry: 是否进位
		if (carry == undefined) {
			carry = true;
		}
		// 不进位获取当前照片
        let imageCur = this.getCurrentUserPhotoCur(),
            imageRuntime = this.getCurrentUserPhotoRuntime();
		if (!carry) {
			return this.$store.state.images[ imageCur ];
		}

		if (imageCur == null) {
            imageCur = 0;
		} else {
            imageCur += 1;
		}

		// 若累加溢出则置零
		if (imageCur == this.$store.state.images.length) {
            imageRuntime += 1;
            imageCur = 0;
		}
        
        this.$global.set('imageRuntime', imageRuntime);
        this.$global.set('imageCur', imageCur);

		// 同步到store
		this.$store.commit('setImageCur', {cur: imageCur});

		let currentPhoto = this.$store.state.images[ imageCur ];
		
		// 传递给父窗口
		let previewData = this.copy(currentPhoto);
		previewData['idx'] = imageCur;
		this.tdCall({
			action: "preview",
			data: previewData
		})

		return currentPhoto;
	},
	getRandomUserPhoto () {
		let image = this.$store.state.images[ Math.round(Math.random() * 1000) % this.$store.state.images.length ];
		return image;
	},
	getNextUserPhoto () {
        let imageCur = this.getCurrentUserPhotoCur();
		let next = imageCur + 1 == this.$store.state.images.length ? 0 : imageCur + 1; // 防止溢出
		return this.$store.state.images[ next ];
	},
	getCurrentUserPhotoCur () {
		return this.$global.get('imageCur', null);
	},
	getCurrentUserPhotoRuntime () {
		return this.$global.get('imageRuntime', 0);
	},
	restartUserPhoto () {
        this.$global.set('imageRuntime', 0);
        this.$global.set('imageCur', null);
		this.$store.commit('setImageCur', {cur: null});
	},
    // 老版本的计数log，逐渐平替
	log (field) {
		return new Promise((resolve, reject) => {
			let filter_fields = [];

			if (this.$store.state.data && this.$store.state.data['official']) { //官方相册过滤log
				filter_fields = ["theme_share"]
			}

			let params = {
				'key': 'vliti'
			};

			if (this.typeOf(field) == 'array') {
				let _fields = [];
				for (let i = 0 ; i < field.length ; i++ ) {
					let can_log = true;
					for (let j = 0 ; j < filter_fields.length ; j++){
						if (field[i] && field[i].indexOf(filter_fields[j]) > -1) {
							can_log = false;
							break;
						}
					}
					if (can_log) {
						_fields.push(field[i])
					}
				}
				if (!_fields){
					return;
				}
				params['fields'] = JSON.stringify(_fields);
			} else {
				for (let j = 0 ; j < filter_fields.length ; j++){
					if (field && field.indexOf(filter_fields[j]) > -1) {
						return;
					}
				}
				params['field'] = field;
			}

			this.rpost('/api/log', params)
			.then((response) => {
				resolve();
			});
		})
	},
    // 设置甜豆相册页面标题
    setPageTitle () {
		let title = this.$store.state.data['ti'] || this.$store.state.theme['name'] || '甜豆相册';
        this.$taro.setNavigationBarTitle({
            title: title
        })
    },
    // // 获取分享标题
    // getShareTitle () {
	// 	debugger
	// 	let share_title = '',
	// 		book_data = this.$store.state.data

	// 	// step 1. 获取用户ti
	// 	share_title = book_data['ti'] || share_title;
		
	// 	// step 2. 获取主题配置中的标题
	// 	if (!share_title) {
	// 		let share_config_titles = this.$store.state.theme && this.$store.state.theme['shareconfig'] && this.$store.state.theme['shareconfig']['titles'] ? this.$store.state.theme['shareconfig']['titles'] : [];
	// 		if (this.typeOf(share_config_titles) == 'string') share_config_titles = JSON.parse(share_config_titles);
	// 		if (share_config_titles.length) {
	// 			share_title = share_config_titles[ parseInt(Math.random() * 10000) % share_config_titles.length ];
	// 			share_title = this.replaceKeyWords(share_title, "author");
	// 		}
	// 	}

	// 	// step 3. 获取服务端下发默认标题
	// 	if (!share_title) {
	// 		let prefix = this.getCurrentPreFix();
	// 		let titles = this.$config.st_config['share_titles'];
	// 		share_title = titles[ parseInt(Math.random() * 10000) % titles.length ];
	// 		if (share_title.indexOf(prefix) < 0){
	// 			share_title = prefix + share_title;
	// 		}
	// 	}

	// 	return share_title;
	// },
    // // 获取分享封面
    // getShareImgurl (normal_mode) {
	// 	debugger
	// 	let _this = this,
	// 		state = this.$store.state,
	// 		usercover = this.packageIm(this.$store.state.images[0], normal_mode ? 250 : 160),
	// 		simple_cover = this.buildShareCover(usercover
	// 			,'play_video.png?x-oss-process=image/resize,w_120',
    //         	'cv2.png?x-oss-process=image/resize,w_400');
	// 	if (normal_mode){
	// 		this.$loger.info("简约封面=>" + simple_cover)
	// 	}
	// 	try { //模板配置封面
	// 		let theme_value = state.theme;
	// 		if(theme_value && theme_value.shareconfig && theme_value.shareconfig.cover && theme_value.shareconfig.cover.length > 0){
	// 			let host = "",
	// 				hostend = usercover.indexOf(".cn/") > -1 ? ".cn/":".com/";
	// 			host = usercover.split(hostend)[0] + hostend
	// 			let _usercover = usercover.split(hostend)[1].split("?")[0];
				
	// 			// 七牛图片需触发一次回源到oss中作分享封面
	// 			if (this.isEnv("h5") && host.indexOf(state.appConfig.image_map.host_map['qiniu']) != -1) {
	// 				host = host.replace( state.appConfig.image_map.host_map['qiniu'], 'http://wxaqiniu.lititutu.cn/')
	// 				let im = new Image();
	// 				im.src = host + _usercover + "?x-oss-process=image/resize,w_10";
	// 				im.onload = ()=>{}
	// 				im.onerror = ()=>{}
	// 			}
	// 			let share_image_path = host + _this.getShareCover(_usercover, theme_value.shareconfig.cover);
	// 			return share_image_path;
	// 		} else {
	// 			return simple_cover;
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 		return simple_cover;
	// 	}
	// },
	// 老的storage不兼容Taro.getStorageSync，这个方法临时使用
	getH5Storage (key, defval) {
		let value = localStorage.getItem(key);
		value = value || defval;
		if (this.typeOf(value) == 'string') {
			value = JSON.parse(value);
		}
		return value;
	},
	setH5Storage (key, value) {
		if (this.typeOf(value) != 'string') {
			value = JSON.stringify(value);
		}
		localStorage.setItem(key, value);
	},
	// 判断是否指定页面
	isPage (page) {
		return this.$router.path.indexOf("/main/" + page) != -1;
	},
	// 判断是否作者
	isAuthor () {
		if (this.isPage('editor')) {
			return true;
		}
		// 获取本地登陆结果集
		let login_check_result = this.getH5Storage('loginCheckResult', {});
		let is_author = this.getUrlQuery('is_author') || Boolean(login_check_result[ this.$store.state.data['appid'] + ',' + this.$store.state.data['openid'] ] == 1);
		return Boolean(is_author);
	},
	setAuthor () {
		let login_check_result = this.getH5Storage('loginCheckResult', {});
		login_check_result[ this.$store.state.data['appid'] + ',' + this.$store.state.data['openid'] ] = 1;
		this.setH5Storage('loginCheckResult', login_check_result);
	},
	// 对模板列表数据进行加工
	fitThemeListDatas (datas) {
		for (let i=0; i<datas.length; i++) {
			if (datas[i]['title'] && datas[i]['cover']) {
				datas[i]['is'] = 'stMenuCell1';
			}
		}
		return datas;
	},
	// 相册分享配置获取
    getShareConfig (book_data) {
		book_data = book_data || this.$store.state.data;
        let title = this.getShareTitle(book_data),
            imgUrl = this.getShareImgurl({
				"book_data": book_data
			}),
			path = "pages/make/make?sence=" + book_data.key;
        return {
            "title": title,
            "imgUrl":  imgUrl,
            "imageUrl": imgUrl,
            "path": path,
        }
    },
    // 获取分享标题
    getShareTitle (book_data) {
		book_data = book_data || this.$store.state.data;
		let share_title = '';

		// step 1. 获取用户ti
		share_title = book_data['ti'] || share_title;
		
		// step 2. 获取主题配置中的标题
		if (!share_title) {
			let share_config_titles = book_data['shareconfig'] ? book_data['shareconfig']['titles'] : (this.$store.state.theme && this.$store.state.theme['shareconfig'] && this.$store.state.theme['shareconfig']['titles'] ? this.$store.state.theme['shareconfig']['titles'] : []);
			if (this.typeOf(share_config_titles) == 'string') share_config_titles = JSON.parse(share_config_titles);
			if (share_config_titles.length) {
				share_title = share_config_titles[ parseInt(Math.random() * 10000) % share_config_titles.length ];
				share_title = this.replaceKeyWords(share_title, "author");
			}
		}

		// step 3. 获取服务端下发默认标题
		if (!share_title) {
			let prefix = this.getCurrentPreFix();
			let titles = this.$config.st_config['share_titles'];
			share_title = titles[ parseInt(Math.random() * 10000) % titles.length ];
			if (share_title.indexOf(prefix) < 0){
				share_title = prefix + share_title;
			}
		}

		return share_title;
	},
    // 获取分享封面
    getShareImgurl (options) {
		let {
			normal_mode,
			book_data
		} = options || {};
		book_data = book_data || this.$store.state.data;
		let usercover = book_data.cover && this.typeOf(book_data.cover) == 'object' ? book_data.cover : this.$store.state.images[0];
		usercover = this.imPackage(usercover, normal_mode ? 250 : 160);
		let _this = this,
			state = this.$store.state,
			simple_cover = this.buildShareCover(usercover
				,'play_video.png?x-oss-process=image/resize,w_120',
            	'cv2.png?x-oss-process=image/resize,w_400');
		if (normal_mode){
			this.$loger.info("简约封面=>" + simple_cover)
		}
		try { //模板配置封面
			let shareconfig = book_data['shareconfig'] || (state.theme ? state.theme.shareconfig : null);
			if(shareconfig && shareconfig.cover && shareconfig.cover.length > 0){
				let host = "",
					hostend = usercover.indexOf(".cn/") > -1 ? ".cn/":".com/";
				host = usercover.split(hostend)[0] + hostend
				let _usercover = usercover.split(hostend)[1].split("?")[0];
				
				// 七牛图片需触发一次回源到oss中作分享封面
				if (host.indexOf(state.appConfig.image_map.host_map['qiniu']) != -1) {
					host = host.replace( state.appConfig.image_map.host_map['qiniu'], 'http://wxaqiniu.lititutu.cn/')
					if (this.isEnv("h5")) {
						let im = new Image();
						im.src = host + _usercover + "?x-oss-process=image/resize,w_10";
						im.onload = ()=>{}
						im.onerror = ()=>{}
					}
				}
				let share_image_path = host + _this.getShareCover(_usercover, shareconfig.cover);
				return share_image_path;
			} else {
				return simple_cover;
			}
		} catch (error) {
			console.error(error);
			return simple_cover;
		}
	},
	// 反馈用户操作日志用于bug检测上报
	ulog (t) {
		if (!this.$router.params['ulog']) {
			return;
		}
		if (typeof t == 'object') {
			t = JSON.stringify(t);
		}
		this.rpost('/api/ulog', {msg: t});
	},
	// event 保存头像
	async saveHeadImg (options) {
		let {
				mode
			} = options || {},
			width = 200,
			pageData = this.$store.state.pageDatas || {},
			index = pageData.st_swiper_index || this.getStorageSync("st_event_head_img_index", 0),
			headimg1 = this.$login.user.headimgurl
				.replace("thirdwx.qlogo.cn","wxhead.lititutu.cn")
				.replace("wx.qlogo.cn","wxhead.lititutu.cn")
				.replace("http://","https://");
		if (!this.$router.params.sub || mode == "check_sub") {
			index = (pageData.st_swiper_index || 0);
			this.setStorageSync("st_event_head_img_index", index);
			return Promise.reject();
		} else if (mode == "onload") {
			await this.sleep(1500);
			this.$store.commit("setPageDatas", {
				"st_swiper_index": index
			});
		}

		index = index + 1;
		let headimg = headimg1 + "?x-oss-process=image/resize,h_"+ width + ",w_"+ width + ",limit_0/watermark,image_" + this.urlEncodeBase64( "festival/" + pageData.page_data_frame.path + index + ".png?x-oss-process=image/resize,h_" + width + ",w_" + width + ",limit_0") + ",g_south,y_0";
		await this.$action.do({
			"do": "save_album",
			"url": headimg,
			"if": "else"
		}).catch(err=>{})
		await this.$action.do({
			"do": "save_album",
			"url": headimg1,
			"if": "else"
		}).catch(err=>{});

		this.$store.commit("setPageDatas", {
			"st_event_headimg_saved": 1
		});
		return Promise.resolve();
	},
	// 通过原图尺寸获取展示区域比例
	getphotoboxsize (orisize, standsize, mode, isTest) {
		/*
			orisize 原图尺寸
			standsize 设定的最大长宽尺寸
			mode 缩放模式 
				widthFix 宽度不变，高度自动变化
				heightFix 高度不变，宽度自动变化
				both 横图按宽，竖图按高
		 */
		orisize = orisize || [500, 500];

		let owidth = parseInt(orisize[0]),
			oheight = parseInt(orisize[1]),
			twidth, theight;

		if (mode == 'both') {
			if (owidth >= oheight) {
				twidth = standsize[0];
				theight = twidth / owidth * oheight;
			} else {
				theight = standsize[1];
				twidth = theight / oheight * owidth;
			}
		} else if (mode == 'widthFix') {
			twidth = standsize[0];
			theight = twidth / orisize[0] * orisize[1];
		} else if (mode == 'heightFix') {
			theight = standsize[1];
			twidth = theight * orisize[0] / orisize[1];
		}

		twidth = twidth > standsize[0] ? standsize[0] : twidth;
		theight = theight > standsize[1] ? standsize[1] : theight;

		if (isTest) {
			_l('orisize', orisize);
			_l('size', [twidth, theight]);
		}

		return [twidth, theight];
	},
	// 给父窗口发送信息
	tdCall (e) {
		if (window.top == window.self) {
			// 内部传递
			if (['updatelrc', 'word_status', 'preview'].indexOf(e['action']) == -1) {
				console.log("message call > ", e);
			}
			this.$eventCenter.broadcast("message", e);
		} else {
			// 父子传递
			window && window.parent && window.parent.postMessage && window.parent.postMessage(e, "*");
		}
	},
	tdListen (cb) {
		this.$eventCenter.listen("message", e => {
			// 内部传递
			if (['updatelrc', 'word_status', 'preview'].indexOf(e['action']) == -1) {
				console.log("message listen > ", e);
			}
			// console.log("message on", e);
			cb && cb(e);
		})
	},
	getStLogEnv () {
		return (this.isEnv("wxa") || this.isEnv('webview')) ? 'wxa' : 'h5';
	},
	getStLogPage () {
		return this.$config.st_config.page == 'share' ? (this.isDetailPage() ? 'detail' : 'share') : this.$config.st_config.page;
	},
	getStLogPrefix () {
		let env = this.getStLogEnv(),
			page = this.$config.st_config.page;
		return env + "_" + page;
	},
	// 判断是否详情页
	isDetailPage () {
		let detail_key = this.$store.state.theme['model'];
		return JSON.stringify(this.$store.state.theme) == "{}" || detail_key == this.$router.params.key;
	},
	// 获取所有要加载的gif链接
	getGifImages (theme) {
		let steps, i, j, m, gif, image,
			images = [],
			gifs = this.forJSON(theme, 'key', 'sprite_image');
		for (i=0; i<gifs.length; i++) {
			gif = gifs[i];
			if (gif['path']) {
				steps = parseInt(gif['steps']);
				for (j=0; j<steps; j++) {
					image = gif['path'].replace('{index}', (j+1).toString());
					// 去重
					if (images.indexOf(image) != -1) {
						continue;
					}
					images.push(image);
				}
			}
		}
		return images;
	},
	// 处理elem中keyframe为html模式的样式
	getElemKeyframeStyle (keyframe) {
		let keyframe_id = 'temp_' + this.md5( keyframe['animation'] + keyframe['html'] );
		let fixHTMl = (htm) => {
			if (htm[0] != '{') {
				htm = '{' + htm + '}';
			}
			return htm;
		}
		let keyframe_html = "@keyframes "+ keyframe_id +" "+ fixHTMl(keyframe['html']);
		return {
			keyframe_id,
			keyframe_html
		}
	},
	// 处理elem中maskImage的样式
	getElemMaskStyle (im, sprite_steps, sprite_duration, sprite_width, sprite_height) {
		let mask_style = {
			"-webkit-mask-image": `url(${im})`,
			"mask-image": `url(${im})`
		};
		if (sprite_steps) {
			mask_style['animation'] = `dynamic_mask ${sprite_duration}s steps(${sprite_steps - 1}) forwards`
		}
		let width = sprite_width ? "100%" : "101%",
			height = sprite_height ? ( 100 * sprite_steps ) + "%" : "101%";
		mask_style["-webkit-mask-size"] = `${width} ${height}`;
		mask_style["mask-size"] = `${width} ${height}`;

		let mask_class = "elem_mask_" + this.md5( im );

		return {
			mask_style,
			mask_class
		};
	},
	getElemStyleClass (styles) {
		let elem_style = "";
		// 对key进行排序
		let keys = [];
		for (let key in styles) {
			if (key.indexOf("webkit") == 0) {
				let t = styles[key];
				key = key.replace("webkit", "Webkit");
				styles[key] = t;
			}
			if (key.indexOf('-Webkit') != -1) {
				continue;
			}
			keys.push(key);
		}
		keys.sort((m, n) => m.length - n.length);
		// 拼接并获取唯一class
		for (let i=0; i<keys.length; i++) {
			let key = keys[i];
			let s_k = key.replace(/([A-Z])/g,"-$1").toLowerCase(),
				s_v = styles[key];
				elem_style += s_k + ":" + s_v + ";";
		}
		let elem_class = "elem_style_" + this.md5( elem_style );
		return {
			elem_style,
			elem_class
		}
	},
	// 随机转跳模板列表id内的模板详情页
	async goModelDetailByListid (options) {
		let {
				listid,
				vscene,
				lens
			} = options || {},
			url = "/pages/detail/detail?open_from=model&open_type=share",
			models = [];
		if (vscene) {
			url = url + "&vscene=" + vscene;
		}

		listid = listid ? listid : "module_list_vliti_hot";

		let configs = await this.$config.center.get_configs({
			"listid": listid,
			"fields": "model,name",
			"lens": lens && lens > 0 ? lens : 12,
			"rttype": "array"
		})
		
		let MODEL_LIMIT = listid == "module_list_vliti_hot" ? 6 : 0;

		// let names = [];

		for (let i = 0 ; i < configs.length ; i++ ) {
			let model = configs[i] && configs[i]['model'];
			if (model) {
				models.push(model);
				// names.push(configs[i]['name'])
			}
			// module_list_vliti_hot限制6个model以跟节日运营齐平
			if (MODEL_LIMIT != 0 && models.length >= MODEL_LIMIT) {
				break;
			}
		}

		// console.log("models==>",models)
		// console.log("names==>",names);

		if (models.length < 1) {
			console.warn("⚠️模板列表，相关详情页为空，请检查参数⚠️goModelDetailByListid");
			this.$router.navigateTo({
				"url": "/subpackages/make/make",
			})
		} else {
			this.$router.navigateTo({
				"url": url + "&key=" + models.randomChoice(),
			})
		}
	},
	// 检查当前浏览器环境是否支持webp
	webpCheckerDemoImage () {
		return "http://resliti.lititutu.cn/tuku/prod/a2250f7a89b8a1c8ebd133c5fbc11644.webp?v=1";
	},
	webpNeedCheck () {
		console.log("webpNeedCheck > ", this.getStorageSync("WEBP_ABLE"), this.getStorageSync("WEBP_ABLE") === "");
		return this.getStorageSync("WEBP_ABLE") === "";
	},
	webpAble () {
		console.log("webpAble > ", this.webpCheckerDemoImage());
		this.setStorageSync("WEBP_ABLE", 1);
	},
	webpUnAble () {
		console.log("webpUnAble > ", this.webpCheckerDemoImage());
		this.setStorageSync("WEBP_ABLE", 0);
	},
	async webpChecker (images) {
		console.log("webpChecker in");
		let checked = false;
		let has_webp = false;
		if (images) {
			for (let i=0; i<images.length; i++) {
				if (images[i]['o']) {
					has_webp = true;
					break;
				}
			}
			if (!has_webp) {
				return;
			}
		}
		if (!this.webpNeedCheck()) {
			console.log("webpChecker out 1");
			return Promise.resolve();
		}
		let that = this;
		return new Promise(resolve => {
			// 兜底方式
			setTimeout(() => {
				if (checked == false) {
					console.log("webpChecker out 4");
					resolve();
				}
			}, 1500);
			
			// 检测逻辑
			let im = new Image();
			im.onload = () => {
				console.log("webpChecker out 2");
				// 解除对webp不支持的状态
				checked = true;
				that.webpAble();
				resolve();
			};
			im.onerror = () => {
				console.log("webpChecker out 3");
				// 标记当前环境不支持webp
				checked = true;
				that.webpUnAble();
				resolve();
			}
			im.src = this.webpCheckerDemoImage();
		})
	}
}