<template>
    <div class="litizhuan-container page">
        <div class="litizhuan-container_1 page preserve3d" :style="getAnimationTimingStyle">
            <div class="litizhuan-container_2 page preserve3d"  :style="getAnimationTimingStyle">
                <div class="litizhuan-box1 page">
                    <div class="litizhuan-up oh page">
                        <elem v-if="getCompElems(0)" :mode="getCompElems(0)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div class="litizhuan-down oh page">
                        <elem v-if="getCompElems(0)" :mode="getCompElems(0)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </div>
                <div class="litizhuan-box2 page">
                    <div class="litizhuan-up oh page">
                        <elem v-if="getCompElems(1)" :mode="getCompElems(1)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div class="litizhuan-down oh page">
                        <elem v-if="getCompElems(1)" :mode="getCompElems(1)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

    export default {
        name: 'LitiZhuan',
        extends: BaseElem,
        data () {
            return {}
        },
        computed: {
            getAnimationTimingStyle () {
                let duration = this.comp_config[2] || 1.5,
                    delay = this.comp_config[3] || 8.5;
                return {
                    animationDuration: duration + "s",
                    animationDelay: delay + "s"
                }
            }
        },
        methods: {
            getCompElems (index) {
                let rt;
                if (this.comp['config'] && this.comp['config'][index]) {
                    rt = this.comp['config'][index];
                } else if (this.comp['elems'] && this.comp['elems'][index]) {
                    rt = this.comp['elems'][index];
                }
                return rt;
            }
        }
    }
</script>

<style>
    .litizhuan-container {
        -webkit-perspective: 720px;
    }
    .litizhuan-container_1 {
        animation: litizhuan-in_out ease-in-out both;
        transform: translate3d(0,0,-250px);
    }
    @keyframes litizhuan-in_out {
        0%,100% {
            transform: translate3d(0,0,-250px);
        }
        40%, 60% {
            transform: translate3d(0,0,-550px);
        }
    }
    .litizhuan-container_2 {
        animation: litizhuan-zhuan ease-in-out both;
        transform: rotateY(0);
    }
    @keyframes litizhuan-zhuan {
        0% {
            transform: rotateY(0);
        }
        100% {
            transform: rotateY(90deg)
        }
    }
    .litizhuan-box1 {
        transform: translate3d(0,0,250px) rotateY(0);
    }
    .litizhuan-box2 {
        transform: translate3d(0, 0, -250.5px) rotateY(-90deg);
        transform-origin: left;
    }
    .litizhuan-up {
        /* background-color: red; */
    }
    .litizhuan-down {
        background-color: rgb(247, 236, 236);
        opacity: 0.8;
        transform: rotateX(180deg);
        transform-origin: bottom;
    }
</style>