<template>
	<div class="baobao3 page po bg" :style="{backgroundImage: 'url('+ mode[0] +')'}">
	    <div class="baobao3_div1 po" :class="{
	    	'none': div1 == 2,
	    	'baobao3_hippo_in': div1 == 0,
	    	'baobao3_hippo_out': div1 == 1
	    }">
	        <div class="baobao3_hippo_1 po">
	            <img src="http://resali.lititutu.cn/scenes/kuailebaobao/shuzhi.png" class="baobao3_pillar po">
	            <div class="baobao3_div page po" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	            </div>
	            <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_2_h.png" class="baobao3_border_v po">
	            <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_2_v.png" class="border_h po"> -->
	        </div>
	    </div>
	    <div class="baobao3_div2 po" :class="{
	    	'none': div2 == 2,
	    	'baobao3_sheep_up': div2 == 0,
	    	'baobao3_sheep_down': div2 == 1
	    }">
		    <div class="baobao3_sheep_1 page po">
		        <img src="http://resali.lititutu.cn/scenes/kuailebaobao/shuzhi.png" class="baobao3_pillar po">
		        <div class="baobao3_div page po" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
		        </div>
		        <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_2_h.png" class="baobao3_border_v po">
		        <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_2_v.png" class="border_h po"> -->
	        </div>
		</div>
	</div>
</template>

<script>
	export default {
        name: 'baobao3',
        props: ['mode', 'currentImage', 'showStatus'],
        watch: {
        	showStatus: {
        		handler () {
	        		if (this.showStatus) {
	        			this.run();
	        		}
        		},
        		immediate: true
        	}
        },
        data () {
            return {
            	div1: 0,
            	div2: 2,
            	runtime: 0,
            	runEvent: null
            }
        },
        methods: {
        	wxPreview () {
                this.$emit('wxPreview', this.currentImage);
            },
        	run () {
        		this.div1 = 0;
        		this.div2 = 2;
        		this.runtime = 0;
        		clearInterval(this.runEvent);

        		let self = this;

        		this.runEvent = setInterval(function() {

        			if (self.runtime == 0) {
        				self.div1 = 0;
        			}
        			if (self.runtime == 6000) {
        				self.div1 = 1;
        			}
        			if (self.runtime == 7000) {
        				self.div1 = 2;
        				self.div2 = 0;
        			}
        			if (self.runtime == 13000) {
        				self.div2 = 1;
        			}
        			if (self.runtime == 14500) {
        				self.div2 == 2;
        			}

        			if (self.runtime >= 15000) {
        				clearInterval(self.runEvent);
        			}

        			self.runtime += 100;
        		}, 100);
        	}
        }
    }
</script>

<style>
	.baobao3_sheep_1 {
		animation: 2s ease 2s infinite normal none running baobao3_sheep_1;
	    -webkit-transform-origin: 50% calc(100% + 500px);
	}
	@keyframes baobao3_sheep_1 {
		0% {
		    -webkit-transform: translate3d(0,0,0);
		}
		30% {
		    -webkit-transform: translate3d(0,-20px,0);
		}
		37.5% {
		    -webkit-transform: translate3d(0,10px,0);
		}
		45% {
		    -webkit-transform: translate3d(0,-5px,0);
		}
		50% {
		    -webkit-transform: translate3d(0,0,0);
		}
		100% {
		    -webkit-transform: translate3d(0,0,0);
		}
	}
	.baobao3_sheep_down {
		animation: 1.5s ease 0s 1 normal both running baobao3_sheep_down;
	}
	@keyframes baobao3_sheep_down {
		0%, 10%, 25%, 40%, 100% {
		    -webkit-transition-timing-function: cubic-bezier(0.215,0.610,0.355,1.000);
		}
		0% {
		    -webkit-transform: translate3d(0,0,0) scale(0.9);
		}
		10% {
		    -webkit-transform: translate3d(0,-5px,0) scale(0.9);
		}
		25% {
		    -webkit-transform: translate3d(0,10px,0) scale(0.9);
		}
		40% {
		    -webkit-transform: translate3d(0,-20px,0) scale(0.9);
		}
		80% {
		    opacity: 1;
		}
		100% {
		    opacity: 0;
		    -webkit-transform: translate3d(0,737px,0) scale(0.9);
		}
	}
	.baobao3_sheep_up {
		animation: 1s ease 0s 1 normal both running baobao3_sheep_up;
	}
	.baobao3_div2 {
		margin: auto;
		left: 0;
		right: 0;
		bottom: 265px;
		-webkit-transform-origin: 50% calc(100% + 500px);
		width: 402px;
		height: 502px;
	}
	@keyframes baobao3_sheep_up {
		0%, 60%, 75%, 90%, 100% {
		    -webkit-transition-timing-function: cubic-bezier(0.215,0.610,0.355,1.000);
		}
		0% {
		    -webkit-transform: translate3d(0,635px,0) scale(0.9);
		    opacity: 0;
		}
		20% {
		    opacity: 1;
		}
		60% {
		    -webkit-transform: translate3d(0,-20px,0) scale(0.9);
		}
		75% {
		    -webkit-transform: translate3d(0,10px,0) scale(0.9);
		}
		90% {
		    -webkit-transform: translate3d(0,-5px,0) scale(0.9);
		}
		100% {
		    -webkit-transform: translate3d(0,0,0) scale(0.9);
		}
	}
	.baobao3_hippo_out {
		animation: 1s ease 0s 1 normal both running baobao3_hippo_out;
	}
	@keyframes baobao3_hippo_out {
		0% {
		    -webkit-transform: rotateZ(4deg) scale(0.9);
		}
		100% {
		    -webkit-transform: rotateZ(112deg) scale(0.9);
		}
	}
	.baobao3_hippo_in {
		animation: 1s ease 0s 1 normal both running baobao3_hippo_in;
	}
	.baobao3_div1 {
		width: 402px;
		height: 502px;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 5px;
		/*bottom: 265px;*/
		-webkit-transform-origin: 50% calc(100% + 500px);
	}
	@keyframes baobao3_hippo_in {
		0% {
		    -webkit-transform: rotateZ(-100deg) scale(0.9);
		}
		100% {
		    -webkit-transform: rotateZ(4deg) scale(0.9);
		}
	}
	.baobao3_hippo_1 {
		animation: 1s ease-in-out 1s infinite alternate none running baobao3_hippo_1;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 265px;
		-webkit-transform-origin: 50% calc(100% + 500px);
		width: 402px;
		height: 502px;
	}
	@keyframes baobao3_hippo_1 {
		0% {
		    -webkit-transform: rotateZ(0deg);
		}
		100% {
		    -webkit-transform: rotateZ(-8deg);
		}
	}

	.baobao3_pillar {
	    margin: auto;
	    left: 0;
	    right: 0;
	    bottom: -500px;
	}
	.baobao3_div {
		background-size: cover;
		background-position: 50% 10%;
		border-radius: 30px;
		overflow: hidden;
	}
	.baobao3_border_v {
		top: -132px;
		left: -36px;
	}
</style>







