<template>
	<div class="lifangzhuan">
		<div class="wrap">
	        <div class="cube">
	            <div class="lifangzhuan_bg front" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	            <div class="lifangzhuan_bg back" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	            <div class="lifangzhuan_bg top" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	            <div class="lifangzhuan_bg bottom" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	            <div class="lifangzhuan_bg left" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	            <div class="lifangzhuan_bg right" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	        </div>
	    </div>	
	</div>
</template>

<script>
	export default {
		name: 'lifangzhuan',
		props: ['mode', 'currentImage'],
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
        created () {
        }
	}
</script>

<style scoped lang="less">
	.lifangzhuan-box {
		position: absolute;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center 20%;
		background-size: cover;
		animation: mscale 5s both;
	}
	.lifangzhuan_bg {
		background-position: center 20%;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.wrap {
		position: absolute;
		perspective:@bw * 1.389;
		perspective-origin: 50% 50%;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		animation: wrapscale 6s both;
    }
    .cube {
		position: absolute;
		margin: auto;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height:@bw * 0.556;
		width:@bw * 0.556;
		transform-style: preserve-3d;
    }
    .cube div {
        position: absolute;
        padding:@bw * 0.014;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        opacity: 0.95;
        background-color: #000;
        border: solid @bw * 0.001 #eee;
        color: #fff;
        font:@bw * 0.014 arial;
        transition: transform 0.2s ease-in;
        animation: bordershadow 6s infinite alternate;
    }
    .front {
        transform: translateZ(@bw * 0.278);
    }
    .back {
        transform: translateZ(-@bw * 0.278) rotateY(180deg);
    }
    .right {
        transform: rotateY(-270deg) translateX(@bw * 0.278);
        transform-origin: top right;
    }
    .left {
        transform: rotateY(270deg) translateX(-@bw * 0.278);
        transform-origin: center left;
    }
    .top {
        transform: rotateX(-270deg) translateY(-@bw * 0.278);
        transform-origin: top center;
    }
    .bottom {
        transform: rotateX(270deg) translateY(@bw * 0.278);
        transform-origin: bottom center;
    }
    @keyframes rotate {
        from {
            transform: rotateX(0deg) rotateY(0deg);
        }

        to {
            transform: rotateX(360deg) rotateY(360deg);
        }
    }
    .cube {
        animation: rotate 15s infinite linear;
    }

    .wrap.hover .front {
        transform: translateZ(@bw * 0.417);
    }

    .wrap.hover .back {
        transform: translateZ(-@bw * 0.278) rotateY(180deg);
    }

    .wrap.hover .right {
        transform: rotateY(-270deg) translateZ(@bw * 0.139) translateX(@bw * 0.139);
    }

    .wrap.hover .left {
        transform: rotateY(270deg) translateZ(@bw * 0.139) translateX(-@bw * 0.139);
    }

    .wrap.hover .top {
        transform: rotateX(-270deg) translateZ(@bw * 0.139) translateY(-@bw * 0.139);
    }

    .wrap.hover .bottom {
        transform: rotateX(270deg) translateZ(@bw * 0.139) translateY(@bw * 0.139);
    }
    // @keyframes bgscale {
    // 	from {
    // 		transform: none;
    // 	}
    // 	to {
    // 		transform: scale(1.1);
    // 	}
    // }
    @keyframes wrapscale {
    	from {
    		transform: scale(0.4);
    	}
    	to {
    		transform: none;
    	}
    }
    @keyframes bordershadow {
    	from {
    		box-shadow: 0 0 @bw * 0.097 blue;
    	}
    	to {
    		box-shadow: 0 0 @bw * 0.097 red;
    	}
    }
    @keyframes bordershadowa {
    	25% {
    		box-shadow: 0 0 @bw * 0.097 red;
    	}
    	50% {
    		box-shadow: 0 0 @bw * 0.097 orange;
    	}
    	75% {
    		box-shadow: 0 0 @bw * 0.097 blue;
    	}
    	100% {
    		box-shadow: 0 0 @bw * 0.097 green;
    	}
    }
</style>