import st_canvas from './st_canvas.base'

class stCanvas extends st_canvas.stCanvas {
    constructor (util, elem) {
        super(util, elem);
        this.ContextType = "2d";
    }

    initContext (elem) {
        if (elem['size']) {
            // 初始化设置画布大小
            this.canvasSize = this.$util.copy(elem['size']);
        } else {
            console.warn("画布大小未设置！");
        }
        this.elem = this.fitStyle({
            "elem": elem
        });

        let _w = Math.round( this.elem.size[0] ),
            _h = Math.round (this.elem.size[1] );
        this.width = _w;
        this.height = _h;
        this.destWidth = _w;
        this.destHeight = _h;

        this.os_canvas = document.createElement('canvas');
        this.os_canvas.width = this.width;
        this.os_canvas.height = this.height;
        this.os_ctx = this.os_canvas.getContext('2d')
        // this.os_canvas = this.$util.$taro.createOffscreenCanvas({
        //     type: this.ContextType,
        //     width: this.width,
        //     height: this.height,
        // });
        // this.os_ctx = this.os_canvas.getContext(this.ContextType);

        this.os_ctx.clearRect(0, 0, this.width, this.height);
    }

    async stGetCanvasImage (options) {
        let _this = this,
            {
                id,
                elem,
                quality,
                fileType,
                save_to_oss
            } = options;
        if (!elem) {
            return "";
        }

        let canvas_line = this.$util.$global.get("st_canvas_line") || [];
        canvas_line.push(options);
        this.$util.$global.set("st_canvas_line", canvas_line);
        if (canvas_line.length > 1) { // 队列进行中
            await this.$util.$eventCenter.on("canvas_" + id).catch(err=>{});
        }

        // let st_canvas = new stCanvas(this, elem);
        this.initContext(elem);
        await this.drawImage().catch(err=>{
            console.log("err==>",err)
            debugger;
        });

        // 获取画完后的数据
        // const imgData = this.os_ctx.getImageData(0, 0, this.width, this.height);
        let tempFilePath = this.os_canvas.toDataURL('image/jpeg', 0.9);
        debugger;
        // let tempFilePath = await new Promise((resolve, reject)=>{
        //     debugger;
        //     wx.canvasPutImageData({
        //         data: imgData.data,
        //         x: 0,
        //         y: 0,
        //         width: _this.width,
        //         height: _this.height,
        //         destWidth: _this.destWidth,
        //         destHeight: _this.destHeight,
        //         canvasId: "st-off-screen-canvas",
        //         // canvas: CANVAS,
        //         success: ()=>{
        //             wx.canvasToTempFilePath({
        //                 x: 0,
        //                 y: 0,
        //                 width: _this.width,
        //                 height: _this.height,
        //                 destWidth: _this.destWidth,
        //                 destHeight: _this.destHeight,
        //                 canvasId: "st-off-screen-canvas",
        //                 quality: quality ? quality : 1,
        //                 // canvas:  CANVAS, //这里是重点，获取实例的时候保存为全局变量就行了
        //                 fileType: fileType || "jpg",
        //                 success: (res) => {
        //                     resolve(res.tempFilePath);
        //                 },
        //                 fail: err=>{
        //                     reject();
        //                     debugger;
        //                 }
        //             })
        //         },
        //         fail: err=>{
        //             console.log("canvasPutImageData err=>", err)
        //             if (elem && elem['im']) {
        //                 resolve(elem['im'])
        //             } else {
        //                 debugger;
        //                 reject();
        //             }
        //         }
        //     })
        // }).catch(err=>{
        //     debugger;
        // })

        // 工作结束后 shift 队列
        let canvas_line_after = this.$util.$global.get("st_canvas_line") || [];
        if (canvas_line_after && canvas_line_after.length > 0){
            canvas_line_after.shift();
            this.$util.$global.set("st_canvas_line", canvas_line_after);
        }

        // 触发下一个
        if (canvas_line_after && canvas_line_after.length > 0){
            let next = canvas_line_after[0];
            if (next && next.id) {
                this.$util.$eventCenter.trigger("canvas_" + next.id, {});
            }
        }

        if (save_to_oss) {
            let oss_object = await this.$util.$upload.uploadFile({
                "path": tempFilePath
            })
            return {
                "oss_object": oss_object,
                "temp_file_path": tempFilePath
            }
        }
        
        return tempFilePath;
    }
}

export default {
    stCanvas
};