<template>
	<div class="baobao1 poc bg" :style="{backgroundImage: 'url('+mode[0]+')'}">

	    <div class="baobao1-div1 page po" :class="{
	    	'none': div1 == 2,
	    	'baobao1-div1_in': div1 == 0,
	    	'baobao1-div1_out': div1 == 1
	    }">
	        <div class="baobao1-div_tremble page po">
	            <div class="baobao1-page1_1 page po">
	                <div class="baobao1-div page po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview">
	                </div>
	                <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png" class="baobao1-border_v po">
	                <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_v.png" class="baobao1-border_h po"> -->
	            </div>
	        </div>
	    </div>
	    <div class="baobao1-div2 page po" :class="{
	    	'baobao1-div2_in': div2 == 0,
	    	'baobao1-div2_out': div2 == 1,
	    	'none': div2 == 2
	    }">
		    <div class="baobao1-div_tremble page po">
		        <div class="baobao1-page1_2 page po">
		            <div class="baobao1-div page po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview">
		            </div>
		            <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png" class="baobao1-border_v po">
		            <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_v.png" class="border_h po"> -->
		        </div>
		    </div>
		</div>
	    <div class="baobao1-div3 page po" :class="{
	    	'baobao1-div3_in': div3 == 0,
	    	'baobao1-div3_out': div3 == 1,
	    	'none': div3 == 2
	    }">
		    <div class="baobao1-div_tremble page po">
		        <div class="page po baobao1-page1_1">
		            <div class="baobao1-div page po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview">
		            </div>
		            <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png" class="baobao1-border_v po">
		            <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_v.png" class="border_h po"> -->
	            </div>
		    </div>
		</div>
	    <div class="baobao1-div4 page po" :class="{
	    	'baobao1-div4_in': div4 == 0,
	    	'none': div4 == 2
	    }">
		    <div class="page po baobao1-div4_in2">
		        <div class="page po baobao1-div4_in3">
		            <div class="page po baobao1-div4_in4">
		                <div class="page po baobao1-div_tremble">
		                    <div class="page po baobao1-page1_2">
		                        <div class="div page po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview">
		                            <span class="divText imgTxt4"></span>
		                        </div>
		                        <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png" class="baobao1-border_v po">
		                        <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_v.png" class="border_h po"> -->
	                        </div>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

	</div>
</template>

<script>
	export default {
        name: 'baobao1',
        props: ['mode', 'currentImage', 'showStatus'],
        data () {
            return {
            	div1: 0,
            	div2: 2,
            	div3: 2,
            	div4: 2,
            	runtime: 0,
            	runEvent: null
            }
        },
        watch: {
        	showStatus: {
        		handler () {
        			if (this.showStatus) {
        				this.run();
        			}
        		},
        		immediate: true
        	}
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage);
            },
            run () {
            	this.div1 = 0;
            	this.div2 = 2;
            	this.div3 = 2;
            	this.div4 = 2;
            	this.runtime = 0;
            	clearInterval(this.runEvent);

            	let self = this;

            	this.runEvent = setInterval(function() {

            		if (self.runtime == 0) {
            			self.div1 = 0;
            		}
            		if (self.runtime == 6000) {
            			self.div1 = 1;
            		}
            		if (self.runtime == 6600) {
            			self.div1 = 2;
            			self.div2 = 0;
            		}
            		if (self.runtime == 11600) {
            			self.div2 = 1;
            		}
            		if (self.runtime == 12400) {
            			self.div2 = 2;
            			self.div3 = 0;
            		}
            		if (self.runtime == 17200) {
            			self.div3 = 1;
            		}
            		if (self.runtime == 18000) {
            			self.div3 = 2;
            			self.div4 = 0;
            		}

            		if (self.runtime >= 24000) {
            			self.div4 = 2;
            			clearInterval(self.runEvent);
            		}

            		self.runtime += 100;
            	}, 100);
            }
        }
    }
</script>

<style>
	.baobao1-div4 {
		margin: auto;
		top: 50px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 402px;
		height: 502px;
	}
	.baobao1-div4_in {
	    animation: 2s ease-in-out 0s 1 normal both running baobao1-div4_in;
	}
	@keyframes baobao1-div4_in {
		0% {
		    -webkit-transform: translate(300px);
		}
		50% {
		    -webkit-transform: translateX(-100px);
		}
		100% {
		    -webkit-transform: translateY(0);
		}
	}
	.baobao1-div4_out {

	}
	@keyframes baobao1-div4_out {
		
	}
	.baobao1-div4_in2 {
		animation: 2s ease-in-out 0s 1 normal both running baobao1-div4_in2;
	}
	@keyframes baobao1-div4_in2 {
		0% {
		    -webkit-transform: translateY(-400px);
		}
		100% {
		    -webkit-transform: translateY(0);
		}
	}
	.baobao1-div4_in3 {
		animation: 2s linear 0s 1 normal both running baobao1-div4_in3;
	}
	@keyframes baobao1-div4_in3 {
		0% {
		    -webkit-transform: scale(0);
		}
		100% {
		    -webkit-transform: scale(1);
		}
	}
	.baobao1-div4_in4 {
		animation: 1.7s ease-out 0s 1 normal both running baobao1-div4_in4;
	}
	@keyframes baobao1-div4_in4 {
		0% {
		    -webkit-transform: rotate(720deg);
		}
		100% {
		    -webkit-transform: rotate(0);
		}
	}

	.baobao1-div3 {
		margin: auto;
		top: 50px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 402px;
		height: 502px;
	}
	.baobao1-div3_in {
		animation: 0.8s linear 0s 1 normal both running baobao1-div3_in;
	}
	@keyframes baobao1-div3_in {
		0% {
		    -webkit-transform: translateY(-100px) scale(0);
		}
		100% {
		    -webkit-transform: translateY(0) scale(1);
		}
	}
	.baobao1-div3_out {
		animation: 0.8s linear 0s 1 normal both running baobao1-div3_out;
	}
	@keyframes baobao1-div3_out {
		0% {
		    -webkit-transform: translateX(0);
		}
		100% {
		    -webkit-transform: translateX(-520px);
		}
	}

	.baobao1-div2 {
		margin: auto;
		top: 50px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 402px;
		height: 502px;
	}
	.baobao1-div2_in {
		animation: 1s ease-in-out 0s 1 normal both running baobao1-div2_in;
	}
	@keyframes baobao1-div2_in {
		0% {
		    -webkit-transform: translateX(-520px) rotate(190deg);
		}
		100% {
		    -webkit-transform: translateX(0) rotate(0);
		}
	}
	.baobao1-div2_out {
		animation: 0.8s linear 0s 1 normal both running baobao1-div2_out;
	}
	@keyframes baobao1-div2_out {
		0% {
		    -webkit-transform: translateX(0);
		}
		100% {
		    -webkit-transform: translateX(520px);
		}
	}

	.baobao1-div2 .baobao1-page1_2 {
	    animation: 4s linear 1.4s 1 normal none running baobao1-page1_2;
	}
	.baobao1-div3 .baobao1-page1_2 {
		animation: 4s linear 2.4s 1 normal none running baobao1-page1_2;
	}
	@keyframes baobao1-page1_2 {
		0% {
		    -webkit-transform: translate(0);
		}
		22% {
		    -webkit-transform: translate(-63px,22px);
		}
		44% {
		    -webkit-transform: translate(25px,-55px);
		}
		66% {
		    -webkit-transform: translate(51px,25px);
		}
		88% {
		    -webkit-transform: translate(-27px,-39px);
		}
		100% {
		    -webkit-transform: translate(0);
		}
	}

	.baobao1-div1 {
		margin: auto;
		top: 50px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 402px;
		height: 502px;
	}
	.baobao1-div1_in {
		animation: 0.5s linear 0s 1 normal both running baobao1-div1_in;
	}
	@keyframes baobao1-div1_in {
		0% {
		    -webkit-transform: translateY(750px);
		    opacity: 0;
		}
		33.333% {
		    opacity: 1;
		}
		100% {
		    -webkit-transform: translateY(0);
		}
	}
	.baobao1-div1_out {
		animation: 0.6s linear 0s 1 normal both running baobao1-div1_out;
	}
	@keyframes baobao1-div1_out {
		0% {
		    -webkit-transform: translateY(0);
		}
		66.666% {
		    opacity: 1;
		}
		100% {
		    opacity: 0;
		    -webkit-transform: translateY(-750px);
		}
	}
	.baobao1-div1 .baobao1-div_tremble {
		animation: 0.6s ease-in-out 0.4s 1 normal both running baobao1-div_tremble;
	}
	.baobao1-div2 .baobao1-div_tremble {
		animation: 0.6s ease-in-out 0.8s 1 normal both running baobao1-div_tremble;
	}
	.baobao1-div3 .baobao1-div_tremble {
		animation: 0.5s ease-in-out 0.6s 1 normal both running baobao1-div_tremble;
	}
	.baobao1-div4 .baobao1-div_tremble {
		animation: 0.6s ease-in-out 1.8s 1 normal both running baobao1-div_tremble;
	}
	@keyframes baobao1-div_tremble {
		0% {
		    -webkit-transform: scaleY(0.97);
		}
		33.333% {
		    -webkit-transform: scaleY(1.05);
		}
		66.666% {
		    -webkit-transform: scaleY(0.985);
		}
		100% {
		    -webkit-transform: scaleY(1);
		}
	}
	.baobao1-div1 .baobao1-page1_1 {
		animation: 4s linear 1s 1 normal none running baobao1-page1_1;
	}
	.baobao1-div3 .baobao1-page1_1 {
		animation: 4s linear 1.1s 1 normal none running baobao1-page1_1;;
	}
	@keyframes baobao1-page1_1 {
		0% {
		    -webkit-transform: translate(0);
		}
		22% {
		    -webkit-transform: translate(63px,22px);
		}
		44% {
		    -webkit-transform: translate(-25px,-55px);
		}
		66% {
		    -webkit-transform: translate(-51px,25px);
		}
		88% {
		    -webkit-transform: translate(27px,-39px);
		}
		100% {
		    -webkit-transform: translate(0);
		}
	}
	.baobao1-div {
		background-size: cover;
		background-position: 50% 10%;
		border-radius: 30px;
		overflow: hidden;
	}
	.baobao1-border_v {
		top: -132px;
		left: -36px;
	}
	.baobao1-border_h {
		top: -132px;
		left: -36px;
	}
</style>