<template>
	<elem
		v-if="myMode"
		:mode="myMode" 
		:root="true"
		@onEvent="onEvent">
		<slot></slot>
	</elem>
</template>

<script>
import BaseElem from "./BaseElem"

export default {
	name: 'st-elem',
	extends: BaseElem,
	data () {
		return {
			reqMode: null,
			inputMode: null
		}
	},
	props: {
		cid: {
			type: String,
			default: ""
		},
		mode: {
			type: Object
		}
	},
	computed: {
		config () {
			return this.comp['config'] || {};
		},
		myMode () {
			let mode = this.inputMode || this.reqMode;
			return mode;
		}
	},
	watch: {
		mode: {
			handler () {
				if (!this.mode) {
					// 传入cid的情况下，通过cid获取mode
					this.initById(this.cid);
				} else {
					if (this.config['cid']) {
						// 在组件配置中传入cid的情况下，通过配置中cid获取mode
						let cid = this.replaceReg(this.config['cid']);
						this.initById(cid);
					} else {
						// 直接通过配置中的mode生成elem
						this.inputMode = this.mode;
					}
				}
			},
			immediate: true
		}
	},
	created () {},
	methods: {
		initById (cid) {
			this.$util.$config.center.get_elem({
				cid: cid
			}).then(mode => {
				this.reqMode = mode;
			}).catch(err => {
				this.reqMode = {
					"is":"word",
					"text":"404 elem not found"
				};
				this.$emit("onError", err);
			})
		},
		onEvent (data) {
			this.$emit("onEvent", data);
		}
	}
}
</script>

<style>

</style>
