<template>
	<div class="neterror-bg container">
        <img class="net-error-img" :src="neterror_img" mode="widthFix" />
        <span class="net-error-text">当前网络环境不佳，请稍后再试</span>
        <div class="error-btns container">
            <div class="retry-btn container" :class="{disable:countDown != 0}" @click="emitRetry">
                <span class="text">{{countDown != 0 ? ("正在刷新(" + countDown + ")") : "点击重试"}}</span>
            </div>
            <div class="custom-btn container" @click="goContect">
                <span class="text">联系客服></span>
            </div>
        </div>
	</div>
</template>

<script>
	export default {
		name: 'neterror',
		props: [],
		components: {
		},
		data () {
			return {
                neterror_img: "http://resali.lititutu.cn/icons/app/neterror.png",
                countDown: 0,
                timer: null
			}
		},
		watch: {
		},
		created () {
			
		},
		mounted () {
			
		},
		methods: {
            emitRetry(){
                let _this = this;
                if(this.countDown == 0){
                    new Promise((resolve, reject)=>{
                        let _simple_animations = getApp().globalData.keyFrames.simple_animations;
                        if(_simple_animations){
                            resolve()
                        }
                    })
                    .then(()=>{
                        _this.$emit("retry")
                        _this.countDown = 10;
                        let timer = setInterval(()=>{
                            _this.countDown = _this.countDown - 1;
                            if(_this.countDown == 0){
                                clearInterval(timer)
                            }
                        }, 1000)
                    })
                    .catch(()=>{
                        window.location.reload();
                    })
                } else {
                    this.$toast.text({
                        message: '请稍后再试',
                    })
                }
            },
            goContect(){
                window.location.href = "https://mp.weixin.qq.com/s/MTM9yAxar0CHyvibcj0OLg"
            },
		}
	}
</script>

<style lang="less">
.neterror-bg{
    width: @bw;
    height: 100%;
    .error-btns{
        margin-top:@bw * 0.06;
        justify-content: space-evenly;
        .retry-btn,.custom-btn{
            border-radius:@bw * 0.016;
            width:@bw * 0.3;
            height:@bw * 0.1;
            align-items: stretch;
            .text{
                display: block;
                // vertical-align: center;
                color: #626262;
                flex-grow: 1;
                line-height:@bw * 0.1;
                text-align: center;
                font-size:@bw * 0.04;
            }
        }
        .retry-btn{
            border: #626262 solid@bw * 0.004;
            .text{
                font-weight: 500;
                color: #444;
            }
        }
        .custom-btn{
            margin-top:@bw * 0.06;
            font-size:@bw * 0.028;
            .text{
                text-decoration: underline;
            }
        }
        .disable{
            border: #bbb solid@bw * 0.004;
            .text{
                font-weight: 500;
                color: #bbb;
            }
        }
    }
    .net-error-img{
        display: block;
        width:@bw * 0.6;
        height:@bw * 0.6;
    }
    .net-error-text{
        margin-top:@bw * 0.06;
        display: block;
        color: #bbb;
        font-size:@bw * 0.05;
    }
}
</style>




