<template>
    <div class="sahua flower" :style="getStyles">
        <img 
			v-for="idx in 3"
			class="po"
			:src="getItemIm(comp_config[0])"
			:style="getItemStyle(comp_config[0])"
			:class="'redflower0'+idx"
			:key="'redflower0'+idx" />
        <div class="luohua po">
            <div class="po" v-for="idx in 21" :key="'luohua'+idx">
                <img
					mode="widthFix"
					:src="getItemIm(comp_config[1])"
					:style="getItemStyle(comp_config[1])"
					class="po luohua-img" :class="'luohua'+idx"
				/>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        extends: BaseElem,
        data () {
            return {}
        }
    }
</script>

<style>
.redflower01 {
	top: -34px;
	right: -31px;
}
.redflower02 {
	top: -5px;
	right:12.5px;
}
.redflower03 {
	top:30.5px;
	right: -43px;
}
.red {
	width:139px;
	height:139px;
	z-index: 100;
}
.luohua {
	top:10.5px;
	right:54px;
}
.luohua-img {
	width:28px;
}
.luohua1 {
	animation: luohua03 5s linear 7.9s infinite;
}
.luohua2 {
	animation: luohua02 5s linear 5.5s infinite;
}
.luohua3 {
	animation: luohua01 5s linear 6.9s infinite;
}
.luohua4 {
	animation: luohua05 5s linear 4.3s infinite;
}
.luohua5 {
	animation: luohua04 5s linear 15.2s infinite;
}
.luohua6 {
	animation: luohua06 5s linear infinite;
}
.luohua7 {
	animation: luohua02 5s linear 8.4s infinite;
}
.luohua8 {
	animation: luohua03 5s linear 7.9s infinite;
}
.luohua9 {
	animation: luohua01 5s linear 5.3s infinite;
}
.luohua10 {
	animation: luohua02 5s linear 4.6s infinite;
}
.luohua11 {
	animation: luohua05 5s linear 18.9s infinite;
}
.luohua12 {
	animation: luohua04 5s linear 6.3s infinite;
}
.luohua13 {
	animation: luohua06 5s linear 19.2s infinite;
}
.luohua14 {
	animation: luohua04 5s linear 9.6s infinite;
}
.luohua15 {
	animation: luohua03 5s linear 7.9s infinite;
}
.luohua16 {
	animation: luohua04 5s linear 16.3s infinite;
}
.luohua17 {
	animation: luohua01 5s linear 14.2s infinite;
}
.luohua18 {
	animation: luohua04 5s linear 16.6s infinite;
}
.luohua19 {
	animation: luohua03 5s linear 7.9s infinite;
}
.luohua20 {
	animation: luohua04 5s linear 14.3s infinite;
}
.luohua21 {
	animation: luohua06 5s linear 11.2s infinite;
}
@keyframes luohua01 {
	from {
		transform: translate(0);
	}
	to {
		transform: translate(-451.5px,847px);
	}
}
@keyframes luohua02 {
	from {
		transform: translate(0);
	}
	to {
		transform: translate(-90.5px,847px);
	}	
}
@keyframes luohua03 {
	from {
		transform: translate(0);
	}
	to {
		transform: translate(-229px,847px);
	}	
}
@keyframes luohua04 {
	from {
		transform: translate(0);
	}
	to {
		transform: translate(-451.5px,389px);
	}	
}
@keyframes luohua05 {
	from {
		transform: translate(0);
	}
	to {
		transform: translate(-90.5px,305.5px);
	}
}
@keyframes luohua06 {
	from {
		transform: translate(0);
	}
	to {
		transform: translate(-146px,216.5px);
	}
}
</style>