<template>
	<div class="huojian-cont" :style="getStyles">
        <div class="huojian-box">
            <img v-for="(idx, index) in 28" 
				mode="widthFix" 
				class="huojian-item" 
				:class="'huojian-'+idx" 
				:src="getLuoItem(index)"
				:key="'huojian-'+idx">
        </div>
    </div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'Huojian',
		extends: BaseLuo,
	}
</script>

<style>
	.huojian-cont {
		position: absolute;
		bottom: 0;
	}
	.huojian-box {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
	}
	.huojian-item {
		position: absolute;
		bottom: 0;
	}
	.huojian-1 {
		left:307.50px;
		width:34.50px;
		animation: ballup1 0.7s -5643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite;
	}
	.huojian-2 {
		left:103.00px;
		width:34.50px;
		animation: ballup1 0.7s -7643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite;
	}
	.huojian-3 {
		left:202.00px;
		width:47.00px;
		animation: ballup1 0.7s -2643ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite;
	}
	.huojian-4 {
		left:43.00px;
		width:31.00px;
		animation: ballup1 0.7s -3843ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite;
	}
	.huojian-5 {
		left:239.00px;
		width:41.50px;
		animation: ballup1 2s -1232.3ms linear infinite;
	}
	.huojian-6 {
	    left:43.00px;
	    width:34.50px;
	    animation: ballup1 2.55s -8843s linear infinite;
	}
	.huojian-7 {
		left:114.50px;
		width:27.00px;
		animation: ballup1 0.7s -4843ms cubic-bezier(0.15, 0.15, 0.34, 0.16) infinite;
	}
	.huojian-8 {
		left:316.00px;
		width:41.00px;
		animation: ballup1 3s -7852ms linear infinite;
	}
	.huojian-9 {
		left:159.00px;
		width:29.00px;
		animation: ballup1 2.15s -3423ms linear infinite;
	}
	.huojian-10 {
		left:82.50px;
		width:29.00px;
		animation: ballup1 2.65s -3103ms linear infinite;
	}
	.huojian-11 {
		left:89.50px;
		width:29.00px;
		animation: ballup1 0.7s -2323.3ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite;
	}
	.huojian-12 {
		left:243.00px;
		width:29.00px;
		animation: ballup1 0.7s -4535.23ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite;
	}
	.huojian-13 {
		left:451.50px;
		width:29.00px;
		animation: ballup1 0.7s -6745.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite;
	}
	.huojian-14 {
		left:347.00px;
		width:29.00px;
		animation: ballup1 0.7s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite;
	}
	.huojian-15 {
		left:182.00px;
		width:59.50px;
		animation: ballup1 3.5s -1233.3ms linear infinite;
	}
	.huojian-16 {
		left:159.50px;
		width:48.50px;
		animation: ballup1 1.75s -12.3ms linear infinite;
	}
	.huojian-17 {
		left:127.00px;
		width:37.00px;
		animation: ballup1 0.7s -4533ms cubic-bezier(0.77, 0.75, 0.14, 0.34) infinite;
	}
	.huojian-18 {
		left:197.00px;
		width:61.00px;
		animation: ballup1 2.25s -2324ms linear infinite;
	}
	.huojian-19 {
		left:331.00px;
		width:59.50px;
		animation: ballup1 0.7s -6324ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite;
	}
	.huojian-20 {
		left:298.50px;
		width:43.00px;
		animation: ballup1 2.5s -2104ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite;
	}
	.huojian-21 {
		left:437.50px;
		width:43.00px;
		animation: ballup1 0.7s -4024ms cubic-bezier(0.17, 0.25, 0.74, 0.64) infinite;
	}
	.huojian-22 {
		left:229.00px;
		width:43.00px;
		animation: ballup1 2.5s -2310ms linear infinite;
	}
	.huojian-23 {
		left:21.00px;
		width:43.00px;
		animation: ballup1 0.7s -1310ms cubic-bezier(0.67, 0.45, 0.24, 0.54) infinite;
	}
	.huojian-24 {
		left:416.50px;
		width:43.00px;
		animation: ballup1 3.15s -4310ms linear infinite;
	}
	.huojian-25 {
		left:278.00px;
		width:43.00px;
		animation: ballup1 0.7s -1210ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite;
	}
	.huojian-26 {
		left:416.50px;
		width:43.00px;
		animation: ballup1 0.7s -1216ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite;
	}
	.huojian-27 {
		left:409.50px;
		width:29.00px;
		animation: ballup1 0.7s -1266ms linear infinite;
	}
	.huojian-28 {
		left:377.00px;
		width:43.00px;
		animation: ballup1 2.65s -1266ms linear infinite;
	}
	@keyframes ballup1 {
		100% {
		    transform: translateY(-871.00px);
		}
	}
</style>