<template>
	<div class="page-fadeinblur page">
    	<div class="page-fadeinblur_blurImage blurImage" :style="blurImageStyle"></div>
        <div class="page-fadeinblur_titlediv">
            <div class="page-fadeinblur_title">{{title}}</div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'fadeinblur',
		props: ['title'],
		computed: {
			blurImageStyle () {
				return {
					backgroundImage: 'url('+ this.$store.state.config.images[0]['im'] +')'
				}
			}
		},
		data () {
			return {}
		},
		created () {}
	}
</script>

<style scoped lang="less">
	.page-fadeinblur {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.page-fadeinblur_titlediv {
		position: relative;
		width: 100%;
		height:@bw * 0.389;
		overflow: hidden;
		display: table;
		background-color: rgba(0,0,0,0.5);
	}
	.page-fadeinblur_title {
		width:@bw * 0.4;
		height:@bw * 0.208;
		vertical-align: middle;
		display: table-cell;
		text-align: center;
		font-size:@bw * 0.053;
		font-weight: bold;
		line-height:@bw * 0.062; 
		color: rgb(255, 255, 255); 
		animation: 1s linear 0.1s 1 normal both running titlein;
	}
	@keyframes titlein {
		0% {
		    -webkit-transform: scale(1.5);
		    -webkit-filter: blur(@bw * 0.028);
		    opacity: 0;
		}
		100% {
		    -webkit-transform: scale(1);
		    -webkit-filter: blur(@bw * 0.0);
		    opacity: 1;
		}
	}
	.page > * {
	    position: absolute;
	    overflow: hidden;
	}
	.page {
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    position: absolute;
	}
</style>