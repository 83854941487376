<template>
	<div></div>
</template>

<script>
	import BaseComponent from './BaseComponent'
	export default {
		extends: BaseComponent,
		name: 'BaseElem',
		props: {
			currentPhoto: {
				type: Object,
				default () {
					return (this.$util.$store.state.userInfo && this.$util.$store.state.userInfo.userphoto) || {'im': "http://resliti.lititutu.cn/tuku/prod/2812459954e1873274fbb41079b065d3.png"}
				}
			},
			canIShow: {
				type: Boolean,
				// default: undefined
				default: true
			},
			mode: {
				type: [Array, Object]
			},
			currentTime: Number,
			currentImage: String,
			scene: Object,
			line: Object
		},
		data () {
			return {
				elemStyles: {},
				originMode: null
			}
		},
		watch: {
			currentTime: {
				handler () {
					// 处理action
					if (this.mode && this.mode['action']) {
						let scene = this._scene || this.scene;
						// 支持相对时间及绝对时间
						let action = scene && this.mode.action[ 'd_' + (this.currentTime - scene.timing[0][0]).toString() ] || this.mode.action[ this.currentTime ];
						if (!action) return;

						action = this.$util.copy(action);
						let mode = this.$util.copy(this.mode);
						Object.assign(mode, action);
						this.setElemStyles(mode);
					}
				},
				immediate: true
			}
		},
		computed: {
			elems () {
				return this.mode && this.mode['elems'] ? this.mode['elems'] : [];
			},
			elemClass () {
				let _class = {};

				let input_class = this.mode['classname'] || this.mode['class'];
				if (!input_class) return _class
				
				input_class = input_class.split(",");
				for (let i=0; i<input_class.length; i++) {
					_class[ input_class[i] ] = true;
				}

				return _class;
			},
			// 是否是用户照片展示elem
			isUserPhoto () {
				return Boolean(this.mode['im']) && this.mode['im'].indexOf('#userphoto#') != -1;
			},
			stCondition () {
				let pageDatas = this.pageDatas || {};
				// pageDatas = Object.assign(this.$util.$router.params, pageDatas);
				let match_condition = this.mode && this.mode.condition && this.$util.$condition.check({
					"conditions": this.mode.condition,
					"params": pageDatas
				});
				// if (match_condition && this.$util.typeOf(match_condition) == "object") {
				// 	debugger;
				// }
				return match_condition;
			},
			pageDatas () {
				return this.$store.state.pageDatas;
			},
			myCurrentPhoto () {
				return this.currentPhoto['im'] || "http://resliti.lititutu.cn/tuku/prod/2812459954e1873274fbb41079b065d3.png";
			},
			isInPage () {
				return this.$util.typeOf(this.pageDatas) == "object" && JSON.stringify(this.pageDatas) != "{}";
			}
		},
		created () {},
		methods: {
			setElemStyles (mode) {
				this.elemStyles = this.getElemStyles(mode);
			},
			getElemStyles (mode) {
				mode = mode || this.mode;
				let s = this.getItemStyle(mode);
				return s;
			},
			// computed会缓存结果、故改为methods
			getIm () {
				let im = this.mode['im'];
				// 关键字替换
				if (im.indexOf('#month#') != -1) {
					im = im.replace('#month#', (new Date().getMonth()+1).toString());
				}
				if (im.indexOf('#date#') != -1) {
					im = im.replace('#date#', (new Date().getDate()).toString());
				}
				if (im.indexOf('#userphoto_random#') != -1) {
					im = im.replace('#userphoto_random#', this.$util.getRandomUserPhoto()['im']);
				}
				if (im.indexOf('#userphoto_next#') != -1) {
					im = im.replace('#userphoto_next#', this.$util.getNextUserPhoto()['im']);
				}
				if (this.isUserPhoto) {
					im = im.replace('#userphoto#', this.currentImage ? this.currentImage : this.currentPhoto['im']);
				}
				// 变量关键字替换 会导致模板卡顿，暂时只在page/st项目中执行这个逻辑
				try {
					if (JSON.stringify(this.pageDatas) != '{}') {
						im = this.replaceReg(im);
					}
				} catch(e) {
					console.error('find stconfig app error: ', e);
				}

				// 对im做urldecode
				im = decodeURIComponent(im);
                
				return im;
			},
			// 获取变量关键字相关函数
			fitRegKey (datas) {
				let r_datas = {};
				for (let key in datas) {
					r_datas['page_data_' + key] = datas[key]; // 将请求下来的字段和本地全局好字段分开
				}
				return r_datas;
			},
			fitRegData (data) { // 处理图片等特殊变量
				if (this.$util.typeOf(data) == 'object') {
					if (data['f'] && data['id']) { // im类型数据
						data = this.$util.imPackage(data);
					}
				}
				return data;
			},
			replaceReg (tx, pageDatas) {
				// pageDatas = pageDatas || this.pageDatas; // this.pageDatas由ElemPage组件下发，但ElemPage组件中由参数直接传递
				tx = this.$util.replaceReg(tx, pageDatas);
				return tx;
			},
			// 关于条件判断的工具函数
			doConditionClass (c) {
				if (!this.mode['condition']) {
					return c;
				}
				// 合并 stCondition 2022-04-08 yellow
				let condition = this.stCondition;//let condition = this._matchIf (this.mode['condition']);

				if (condition['class']) {
					let _class = {};
					return _class[condition['class']] = true;
				}

				return c;
			},
			doConditionStyle (s) {
				if (!this.mode['condition']) {
					return s;
				}

				// 合并 stCondition 2022-04-08 yellow
				let condition = this.stCondition;//this._matchIf (this.mode['condition']);
				if (condition && condition['style']) {
					s = Object.assign(s, condition['style']);
				}
				return s;
			},
			_matchIf (conditions) {
				for (let i=0; i<conditions.length; i++) {
					if (this._if(conditions[i]['if'])) {
						return conditions[i];
					}
				}
			},
			_if (ifs) {
				if (ifs == 'else') {
					return true;
				}
				// console.log("ifs => ", ifs);
				let statement = this.replaceReg(ifs);
				// console.log("statement => ", statement);
				if (statement) {
					return this._doCondition(statement);
				}
			},
			_fitCondition (t) {
				if ('boolean' === this.$util.typeOf(t)) return t;
				if ('false' === t) return false;
				if ('true' === t) return true;
				if ("0" === t) return false;
				if ("1" === t) return true;
				if ("" === t) return false;
				return parseInt(t);
			},
			_doCondition (statement) {
				statement = statement.split(" ");
				if (statement.length == 3) { // 等式判断
					let l = this._fitCondition(statement[0]),
						r = this._fitCondition(statement[2]),
						sign = statement[1];
					// console.log(l, r, sign)
					if (sign == '==') {
						return l == r;
					} else if (sign == '>') {
						return l > r;
					} else if (sign == '<') {
						return l < r;
					} else if (sign == '&&') {
						return l && r;
					}
				} else if (statement.length == 1) { // 真假判断
					// console.log("statement => ", statement[0]);
					return this._fitCondition(statement[0]);
				} else if (statement.length == 5) {
					return this._doCondition([this._doCondition([statement[0], statement[1], statement[2]].join(' ')), statement[3], statement[4]].join(' '));
				}
			}
		}
	}
</script>

<style>
	
</style>