<template>
    <div class="flowitems" :class="$store.state.wordCur.wordStatus? 'flowitemshas-user-text' : ''">
        <div class="flow-items-container re-container"
            v-for="(flowitem, idx) in getFlowItems"
            :key="flowitem.image + idx.toString()"
            :style="'animation-delay:' + 4 * parseInt(idx + 1) + 's;'">
            <img class="icon-img" :src="flowitem.image? flowitem.image : 'http://resali.lititutu.cn/wxa_icon/btn/default_icon.png'" />
            <span class="flow-item-text">{{flowitem.text}}</span>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'flowitems',
		props: {
			
        },
        created(){
            // 获取用户信息
			// this.$store.dispatch('getUserInfo').then((userInfo) => {});
        },
		data () {
			return {}
		},
		computed: {
            getFlowItems(){
                let flow_items = [];
                if(this.$store.state.config){
                    if(this.$store.state.config.userInfo){
                        flow_items.push({
                            "image": this.$store.state.config.userInfo.avatarUrl,
                            "text": "作者：" + this.$store.state.config.userInfo.nickName,
                        })
                    }
                    if(this.$store.state.themeData){
                        flow_items.push({
                            "image": this.$store.state.config && this.$store.state.config.userInfo ? this.$store.state.config.userInfo.avatarUrl : "http://resali.lititutu.cn/wxa_icon/btn/default_icon.png",
                            "text": "模板名称：" + this.$store.state.themeData['theme'][this.$store.state.config.data.ttid]['name'],
                        })
                    }
                }
                if(this.$store.state.config.musicInfo && this.$store.state.config.musicInfo.name){
                    flow_items.push({
                        "image": "http://resali.lititutu.cn/wxa_icon/btn/default_icon.png",
                        "text": "歌曲名称：" + this.$store.state.config.musicInfo.name,
                    })
                }

                if(this.$store.state.zanList){
                    for(let j = 0 ; j < this.$store.state.zanList.length ; j++){
                        let comment_item = this.$store.state.zanList[j];
                        let userdata = {
                            "avatarUrl": "http://resali.lititutu.cn/icons/app/logo_140.png",
                            "nickName": "相册用户",
                        }
                        if(comment_item && comment_item.user_data){
                            userdata = this.$o_util.transformUserdata(comment_item.user_data)
                        }
                        flow_items.push({
                            "image": userdata.avatarUrl,
                            "text":  userdata.nickName + (comment_item.content ? "：" + comment_item.content : "给作品送了花"),
                        })
                    }
                }
                return flow_items
            },
		}
	}
</script>

<style lang="less" scoped>
.flowitemshas-user-text{
    bottom: 206px!important;
}
.flowitems{
    position: absolute;
    bottom: 160px;
    left: 10px;
    height: 80px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: visible;
    z-index: 8;
    pointer-events: none;
    .flow-items-container{
        position: absolute;
        bottom: 0;
        left: 0;
        background:linear-gradient(90deg,rgba(24,24,24,1) 20%,rgba(24,24,24,0.49) 90%,rgba(24,24,24,0) 100%);
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        justify-content: flex-start;
        animation: flow_item_up 4.5s ease-out both;
        padding-right: 16px;
    }
    .icon-img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .flow-item-text{
        padding: 8px 0;
        margin-left: 8px;
        font-size: 21px;
        font-weight: 400;
        line-height: 21px;
        color: #fff;
    }
    @keyframes flow_item_up {
        from {
            transform: translateY(80px);
            opacity: 0;
        }
        20% {
            transform: translateY(0);
            opacity: .9;

        }
        80% {
            transform: translateY(0);
            opacity: .9;
        }
        to {
            transform: translateY(-80px);
            opacity: 0;
        }
    }
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.re-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.container>span,.container>img,.re-container>span,.re-container>img{
    display: block;
}
	
</style>