<template>
	<div class="fanpai">
	   	<div class="perspective poc bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
			<div
				v-for="idx of 8"
        		:key="'fanpai_'+idx"
				class="fanpai-page1 preserve3d poc">
				<div class="po" style="width: 299.081px; height: 300px;">
					<img :src="mode[1]" class="fanpai_flower1 po">
					<div
						class="fanpai-border poc bg" 
						:style="getBorderStyle" @click="wxPreview"></div>
				</div>
			</div>
          <!-- <div class="fanpai-page1 preserve3d poc">
                 <div class="po" style="width: 299.081px; height: 300px;">
                        <img :src="mode[1]" class="fanpai_flower1 po">
                        <div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
                 </div>
          </div>
          <div class="fanpai-page1 preserve3d poc">
                 <div class="po" style="width: 299.081px; height: 300px;">
                        <img :src="mode[1]" class="fanpai_flower1 po">
                        <div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
                 </div>
          </div>
          <div class="fanpai-page1 preserve3d poc">
                 <div class="po" style="width: 299.081px; height: 300px;">
                        <img :src="mode[1]" class="fanpai_flower1 po">
                        <div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
                 </div>
          </div>
          <div class="fanpai-page1 preserve3d poc">
                 <div class="po" style="width: 299.081px; height: 300px;">
                        <img :src="mode[1]" class="fanpai_flower1 po">
                        <div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
                 </div>
          </div>
          <div class="fanpai-page1 preserve3d poc">
                 <div class="po" style="width: 299.081px; height: 300px;">
                        <img :src="mode[1]" class="fanpai_flower1 po">
                        <div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
                 </div>
          </div>
          <div class="fanpai-page1 preserve3d poc">
				<div class="po" style="width: 299.081px; height: 300px;">
						<img :src="mode[1]" class="fanpai_flower1 po">
						<div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				</div>
		  </div> -->
          	<!-- <div class="fanpai-page1 preserve3d poc">
			 <div class="po" style="width: 299.081px; height: 300px;">
	                <img :src="mode[1]" class="fanpai_flower1 po">
	                <div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
			 </div>
          	</div>
          	<div class="fanpai-page1 preserve3d poc">
				<div class="po" style="width: 299.081px; height: 300px;">
		            <img :src="mode[1]" class="fanpai_flower1 po">
		            <div class="fanpai-border poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				</div>
          	</div> -->
	   </div>
	   <img :src="mode[1]" class="fanpai_flower2 po" style="width: 230px;">
	</div>
</template>

<script>
	export default {
		name: 'fanpai',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		computed: {
			getBorderStyle(){
			let border = this.mode.length > 2 ? this.mode[2]:'https://resali.lititutu.cn/icons/other/border.jpg';
			let s = {
					"backgroundImage": 'url('+ this.currentImage['im'] +')',
					"borderImageSource": 'url('+ border +')',
					}
			return s
			}	
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style>
	.fanpai-page1 {
		width: 600px;
		height: 600px;
		top: calc(50% - 300px);
		left: calc(50% - 300px);
	}
	.fanpai-page1:nth-of-type(1)>div {
	    transform: rotateZ(0);
	    animation: page1v2 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1:nth-of-type(2)>div {
	    transform: rotateZ(45deg);
	    animation: page1v3 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1:nth-of-type(3)>div {
	    transform: rotateZ(90deg);
	    animation: page1v4 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1:nth-of-type(4)>div {
	    transform: rotateZ(135deg);
	    animation: page1v5 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1:nth-of-type(5)>div {
	    transform: rotateZ(180deg);
	    animation: page1v6 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1:nth-of-type(6)>div {
	    transform: rotateZ(225deg);
	    animation: page1v7 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1:nth-of-type(7)>div {
	    transform: rotateZ(270deg);
	    animation: page1v8 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1:nth-of-type(8)>div {
	    transform: rotateZ(315deg);
	    animation: page1v1 3s cubic-bezier(0,0,0.6,1) 3 alternate both;
	}
	.fanpai-page1>div {
	    margin: auto;
	    left: 0;
	    right: 0;
	    bottom: 300px;
	    transform-origin: bottom;
	}
	@keyframes page1v2 {
		0% {
		    transform: translateZ(0.2px) rotateZ(45deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.2px) rotateZ(45deg) rotateX(0deg);
		}
	}
	@keyframes page1v3 {
		0% {
	    transform: translateZ(0.4px) rotateZ(90deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.4px) rotateZ(90deg) rotateX(0deg);
		}
	}
	@keyframes page1v4 {
		0% {
		    transform: translateZ(0.6px) rotateZ(135deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.6px) rotateZ(135deg) rotateX(0deg);
		}
	}
	@keyframes page1v5 {
		0% {
		    transform: translateZ(0.8px) rotateZ(180deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0.8px) rotateZ(180deg) rotateX(0deg);
		}
	}
	@keyframes page1v6 {
		0% {
		    transform: translateZ(1px) rotateZ(225deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(1px) rotateZ(225deg) rotateX(0deg);
		}
	}
	@keyframes page1v7 {
		0% {
		    transform: translateZ(1.2px) rotateZ(270deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(1.2px) rotateZ(270deg) rotateX(0deg);
		}
	}
	@keyframes page1v8 {
		0% {
		    transform: translateZ(1.4px) rotateZ(315deg) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(1.4px) rotateZ(315deg) rotateX(0deg);
		}
	}
	@keyframes page1v1 {
		0% {
		    transform: translateZ(0) rotateZ(0) rotateX(-90deg);
		}
		100% {
		    transform: translateZ(0) rotateZ(0) rotateX(0deg);
		}
	}
	
	.fanpai_flower1 {
	    margin: auto;
	    left: 0;
	    right: 0;
	    top: -60px;
	    animation: fanpai_flower2 6s linear infinite both;
	}
	@keyframes fanpai_flower2 {
		0% {
		    transform: rotateZ(360deg);
		}
		100% {
		    transform: rotateZ(0);
		}
	}
	.fanpai-border {
		border: 3px solid;
		border-image: url(https://resali.lititutu.cn/icons/other/border.jpg);
		border-image-slice: 3;
	}
	.fanpai_flower2 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    animation: fanpai_flower2 6s linear infinite both;
	}
	@keyframes fanpai_flower2 {
		0% {
    		transform: rotateZ(360deg);
		}
		100% {
		    transform: rotateZ(0);
		}
	}
</style>