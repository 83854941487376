class stObserver{
    constructor (util) {
        this.$util = util;
        this.observer_map = {};
        this.observe_status_map = {}
    }

    getObserverId (parent, target){
        parent = parent || "__viewport__";
        return this.$util.md5( "ob_" + parent + "_" + target );
    }

    destroy (observer_id) {
        let observer = this.observer_map[observer_id];
        observer.disconnect();
        this.observer_map[observer_id] = null;
        this.observe_status_map[observer_id] = null;
    }
}

export default {
    stObserver
}