<template>
	<div class="gundong po page bg" :style="{backgroundImage: 'url('+ mode[0]['im'] +')'}">

		<template v-for="item in mode[0]['elems']">
			<img class="po" :src="item['im']" :style="item['style']" :class="item['classname']">
		</template>

	    <div class="gundong-page perspective poc">
	        <div class="gundong-div po perspective" :style="getStyle">
	            <div class="gundong-div1">
	                <div class="gundong-div2 po" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            </div>
	        </div>
	        <div class="gundong-bottom po perspective">
	            <div class="gundong-bottom1 po">
	            	<template v-for="item in mode[1]['elems']">
	            		<img class="po" :src="item['im']" :style="item['style']" :class="item['classname']">
	            	</template>
                </div>
	        </div>
	    </div>
	</div>
</template>

<script>

	export default {
		name: 'gundong',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		computed: {
			getStyle () {
				let twidth, theight, standsize = [472, 628];

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], standsize, 'both');
					twidth = tsize[0];
					theight = tsize[1];
				} else {
					twidth = standsize[0];
					theight = standsize[1];
				}

				return {
					width: twidth + 'px',
					height: theight + 'px'
				}
			}
		},
		methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage);
            }
        }
	}
</script>

<style>
	.gundong {

	}
	.gundong-page {
		animation: 8s linear 0s 1 normal both running gundong_page;
	}
	@keyframes gundong_page {
		0% {
		    -webkit-transform: rotateY(5deg);
		}
		100% {
		    -webkit-transform: rotateY(-5deg);
		}
	}
	.gundong-div {
		animation: gundong_div 1s ease-in-out both;
		/*width: 472px;
		height: 628px;*/
		top: 4.54%;
		left: 0;
		right: 0;
		margin: auto;
		-webkit-transform-origin: 50% 110%;
	}
	@keyframes gundong_div {
		0% {
		    -webkit-transform: translate3d(0,320px,-500px) scale(2) rotateY(10deg) rotateX(120deg);
		    opacity: 0;
		}
		1% {
		    opacity: 1;
		}
		60% {
		    -webkit-transform: translate3d(0,320px,-500px) scale(2) rotateY(0deg) rotateX(-5deg);
		}
		80% {
		    -webkit-transform: translate3d(0,320px,-500px) scale(2) rotateY(0deg) rotateX(3deg);
		}
		100% {
		    -webkit-transform: translate3d(0,320px,-500px) scale(2) rotateY(0deg) rotateX(0deg);
		}
	}
	.gundong-div1 {
		width: 100%;
		height: 100%;
		-webkit-animation: gundong_div_1 1.3s 0.8s ease-in-out infinite alternate both;
	}
	@keyframes gundong_div_1 {
		0% {
		    -webkit-transform: rotateY(5deg);
		}
		100% {
		    -webkit-transform: rotateY(-3deg);
		}
	}
	.gundong-div2 {
		border: 8px solid #FFFFFF;
		box-sizing: border-box;
		background-size: cover;
		background-position: center 10%;
		background-clip: content-box;
		width: 100%;
		height: 100%;
		/*width: 472px;
		height: 339px;*/
		top: 0;
		bottom: 0;
		margin: auto;
	}
	.gundong-bottom {
		bottom: 0;
		-webkit-transform-origin: bottom;
		-webkit-animation: gundong_bottom 2s ease-in-out both;
	}
	@keyframes gundong_bottom {
		0% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(120deg);
		    opacity: 0;
		}
		1% {
		    opacity: 1;
		}
		30% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(-15deg);
		}
		40% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(13deg);
		}
		50% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(-10deg);
		}
		60% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(7deg);
		}
		70% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(-5deg);
		}
		80% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(3deg);
		}
		90% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(-1deg);
		}
		100% {
		    -webkit-transform: translate3d(0,-20px,80px) rotateX(0deg);
		}
	}
	.gundong-bottom1 {
		width: 500px;
		height: 300px;
		bottom: 0;
		left: 0;
		animation: 6s linear 1s 1 normal both running gundong_bottom1;
	}
	@keyframes gundong_bottom1 {
		0% {
		    -webkit-transform: rotateY(5deg);
		}
		100% {
		    -webkit-transform: rotateY(-5deg);
		}
	}

	.gundong_item2 {
	    left: 0;
	    bottom: 179px;
	}
	.gundong_item3 {
		left: 186px;
		bottom: 203px;
	}
	.gundong_item4 {
		left: 146px;
		bottom: 175px;
	}
	.gundong_item5 {
		left: 590px;
		bottom: 325px;
		-webkit-transform: rotate(25deg) translateZ(-450px);
	}
	.gundong_item6 {
		left: 415px;
		bottom: 257px;
	}
	.gundong_balloon {
		-webkit-animation: gundong_balloon 3s ease-in-out infinite alternate both;
	}
	@keyframes gundong_balloon {
		0% {
		    -webkit-transform: rotate(25deg) translate3d(-20px,30px,-450px);
		}
		100% {
		    -webkit-transform: rotate(10deg) translate3d(0,0px,-450px);
		}
	}
	.gundong_windmill {
	    -webkit-animation: gundong_windmill 2.5s linear infinite both;
	}
	@keyframes gundong_windmill {
		0% {
		    -webkit-transform: rotateZ(0deg);
		}
		100% {
		    -webkit-transform: rotateZ(-360deg);
		}
	}

	/*.cloud1 {
		animation: cloud1 8s linear infinite both;
	}
	@keyframes cloud1 {
		0% {
		    -webkit-transform: rotateZ(59deg);
		}
		100% {
		    -webkit-transform: rotateZ(-40deg);
		}
	}
	.cloud2 {
		animation: cloud2 8s 1.6s linear infinite both;
	}
	@keyframes cloud2 {
		0% {
		    -webkit-transform: rotateZ(14deg);
		}
		100% {
		    -webkit-transform: rotateZ(-45deg);
		}
	}
	.cloud3 {
		animation: cloud3 8s linear infinite both;
	}
	@keyframes cloud3 {
		0% {
		    -webkit-transform: rotateZ(33deg);
		}
		100% {
		    -webkit-transform: rotateZ(-20deg);
		}
	}
	.cloud4 {
		animation: cloud4 8s 3.2s linear infinite both;
	}
	@keyframes cloud4 {
		0% {
		    -webkit-transform: rotateZ(23deg);
		}
		100% {
		    -webkit-transform: rotateZ(-25deg);
		}
	}
	.cloud5 {
		animation: cloud5 8s 5.6s linear infinite both;
	}
	@keyframes cloud5 {
		0% {
		    -webkit-transform: rotateZ(47deg);
		}
		100% {
		    -webkit-transform: rotateZ(-45deg);
		}
	}
	.cloud6 {
		animation: cloud6 7s ease-in-out infinite alternate both;
	}
	@keyframes cloud6 {
		0% {
		    -webkit-transform: translateX(0) rotateY(180deg) rotateZ(-10deg);
		}
		100% {
		    -webkit-transform: translateX(-100px) rotateY(180deg) rotateZ(-10deg);
		}
	}
	.cloud7 {
		animation: cloud7 5s ease-in-out infinite alternate both;
	}
	@keyframes cloud7 {
		0% {
		    -webkit-transform: translateX(-40px) rotateY(180deg);
		}
		100% {
		    -webkit-transform: translateX(30px) rotateY(180deg);
		}
	}
	.cloud8 {
		animation: cloud8 9s ease-in-out infinite alternate both;
	}
	@keyframes cloud8 {
		0% {
		    -webkit-transform: translateX(0px) rotateZ(-30deg);
		}
		100% {
		    -webkit-transform: translateX(130px) rotateZ(-30deg);
		}
	}
	.cloud9 {
		animation: cloud9 8s ease-in-out infinite alternate both;
	}
	@keyframes cloud9 {
		0% {
		    -webkit-transform: translateX(30px) rotateY(180deg) rotateZ(-30deg);
		}
		100% {
		    -webkit-transform: translateX(-90px) rotateY(180deg) rotateZ(-30deg);
		}
	}
	.cloud10 {
		animation: cloud10 6s ease-in-out infinite alternate both;
	}
	@keyframes cloud10 {
		0% {
		    -webkit-transform: translateX(30px);
		}
		100% {
		    -webkit-transform: translateX(-90px);
		}
	}
	.moonmove {
		animation: moonmove 3s ease-in-out infinite alternate both;
	}
	@keyframes moonmove {
		0% {
		    -webkit-transform: rotateZ(0) translateX(0) translateY(0);
		}
		100% {
		    -webkit-transform: rotateZ(-30deg) translateX(10px) translateY(20px);
		}
	}
	.starmove1 {
		animation: starmove1 3.5s ease-in-out infinite alternate both;
	}
	@keyframes starmove1 {
		0% {
		    -webkit-transform: translateY(30px) scale(0.9);
		}
		100% {
		    -webkit-transform: translateY(-20px) scale(0.9);
		}
	}
	.starmove2 {
		animation: starmove2 3.5s ease-in-out infinite alternate both;
	}
	@keyframes starmove2 {
		0% {
		    -webkit-transform: translateY(-30px) scale(0.9);
		}
		100% {
		    -webkit-transform: translateY(20px) scale(0.9);
		}
	}
	.animalmove1 {
		animation: animalani1 2.5s ease-in-out infinite alternate both;
	}
	.animalmove2 {
		animation: animalani1 2.8s ease-in-out infinite alternate both
	}
	@keyframes animalani1 {
		0% {
		    -webkit-transform: rotateZ(-10deg) scale(1.2);
		}
		100% {
		    -webkit-transform: rotateZ(10deg) scale(1.2);
		}
	}
	.animalmove3 {
		animation: animalani2 2s ease-in-out infinite alternate both;
	}
	@keyframes animalani2 {
		0% {
		    -webkit-transform: rotateZ(0deg);
		}
		100% {
		    -webkit-transform: rotateZ(-10deg);
		}
	}
	.animalmove4 {
		animation: animalani3 2s ease-out infinite both;
	}
	@keyframes animalani3 {
		0% {
		    -webkit-transform: translateY(0px);
		}
		15% {
		    -webkit-transform: translateY(-15px);
		}
		30% {
		    -webkit-transform: translateY(0px);
		}
		45% {
		    -webkit-transform: translateY(-15px);
		}
		60% {
		    -webkit-transform: translateY(0px);
		}
		100% {
		    -webkit-transform: translateY(0px);
		}
	}
	.animalmove5 {
		animation: animalani4 4s ease-in-out infinite alternate both;
	}
	@keyframes animalani4 {
		0% {
		    -webkit-transform: rotateZ(-10deg);
		}
		30% {
		    -webkit-transform: rotateZ(-10deg);
		}
		95% {
		    -webkit-transform: rotateZ(15deg);
		}
		100% {
		    -webkit-transform: rotateZ(15deg);
		}
	}
	.fixstarmove1 {
		animation: fixstarani2 3s ease-in-out infinite alternate both;
	}
	.fixstarmove2 {
		animation: fixstarani2 2.5s ease-in-out infinite alternate both;
	}
	@keyframes fixstarani2 {
		0% {
		    -webkit-transform: translateY(-10px);
		}
		100% {
		    -webkit-transform: translateY(10px);
		}
	}
	.fixstarmove3 {
		animation: fixstarani1 3.1s ease-in-out infinite alternate both;
	}
	@keyframes fixstarani1 {
		0% {
		    -webkit-transform: translateY(10px);
		}
		100% {
		    -webkit-transform: translateY(-10px);
		}
	}
	.fixstarmove4 {
		animation: fixstarani2 2.8s ease-in-out infinite alternate both;
	}
	.fixstarmove5 {
		animation: fixstarani1 3s ease-in-out infinite alternate both;
	}
	.fixstarmove6 {
		animation: fixstarani2 2.3s ease-in-out infinite alternate both;
	}
	.fixstarmove7 {
		animation: fixstarani1 3.3s ease-in-out infinite alternate both;
	}
	.fixstarmove8 {
		animation: fixstarani3 2s ease-in-out infinite alternate both;
	}
	@keyframes fixstarani3 {
		0% {
		    -webkit-transform: rotateZ(30deg);
		}
		100% {
		    -webkit-transform: rotateZ(390deg);
		}
	}
	.fixstarmove9 {
		animation: fixstarani4 6s 1.5s ease-out both;
	}
	@keyframes fixstarani4 {
		0% {
		    -webkit-transform: translate(0,0) rotateZ(0);
		}
		100% {
		    -webkit-transform: translate(30px,-120px) rotateZ(40deg);
		}
	}
	.bear {
		animation: bear 3s ease-in-out infinite alternate both;
	}
	@keyframes bear {
		0% {
		    -webkit-transform: rotateZ(-8deg);
		}
		100% {
		    -webkit-transform: rotateZ(8deg);
		}
	}*/
</style>









