<template>
	<div class="box9">
	    <div class="page1 box9_bg" style="display: block;background-size: auto 111%;" :style="{backgroundImage: 'url('+mode[0]+')'}">
	        <div class="state_ani">
	            <div class="lit_img">
	                <div class="shanke_rotate_ani">
	                    <div class="shanke_rotate_ani">
	                        <div class="shanke_rotate_ani">
	                            <div class="div2Img" :style="getStyle" @click="wxPreview">
	                                <div class="page2Btf_box" :style="getHudieStyle">
										<div class="page2_butterfly">
											<!-- <img class="page2Btf_leftWing po hudie" :src="mode[2]"> -->
											<div class="page2Btf_rightWing">
												<img class="po hudie" :src="mode[2]">
											</div>
											<img class="page2Btf_body po" style="opacity: 0.99;" :src="mode[3]">
										</div>
	                                    <!-- <div class="page2_butterfly">
											<div class="page2Btf_leftWing">
	                                            <img class="po hudie" :src="mode[2]">
	                                        </div>
	                                        <div class="page2Btf_rightWing">
	                                            <img class="po hudie" :src="mode[2]">
	                                        </div>
	                                    </div> -->
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
			<div class="page2_hua hua4 po">
	            <div class="all_width">
	                <div class="all_width">
	                    <div class="all_width">
	                        <img class="page2_hua1 po" :src="mode[1]"></div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'jiaban',
		props: ['mode', 'currentImage', 'showStatus'],
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
		},
		computed: {
			getStyle () {
                let twidth, theight;
                if (this.showStatus) {
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [500, 650], 'widthFix');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = 500;
                    theight = 500;
                }

                return {
                    width: twidth + 'px',
					height: theight + 'px',
					backgroundImage: 'url('+this.currentImage['im']+')',
                }
			},
			getHudieStyle (){
				let twidth, theight;
                if (this.showStatus) {
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [500, 650], 'widthFix');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = 500;
                    theight = 500;
				}
				return {
					top: -(twidth)/10 + 'px'
				}
			},
		},
	}
</script>

<style lang="less" scoped>
	.box9 {
		position:absolute;
		width:100%;
		height:100%;
	}
	.box9_bg {
		width:@bw*1.718;
		height:100%;
		position:relative;
		background:no-repeat center;
		transform:translateX(-@bw*.694);
		-webkit-animation:box9_bg 9s linear both;
	}
	@-webkit-keyframes box9_bg {
		0% {
		-webkit-transform:translateX(-@bw*.694);
	}
	100% {
		-webkit-transform:translateX(0);
	}
	}@-webkit-keyframes div2_box {
		0% {
		-webkit-transform:translateX(@bw*.972);
	}
	100% {
		-webkit-transform:translateX(-@bw*.0139);
	}
	}.lit_img {
		position:absolute;
		-webkit-animation:div2_box 12s normal both;
	}
	@-webkit-keyframes divPageTrx {
		0% {
		-webkit-transform:translateX(@bw * 0.0);
	}
	20% {
		-webkit-transform:translateX(0.3px);
	}
	40% {
		-webkit-transform:translateX(-0.85px);
	}
	60% {
		-webkit-transform:translateX(-0.3px);
	}
	80% {
		-webkit-transform:translateX(0.6px);
	}
	100% {
		-webkit-transform:translateX(0.25px);
	}
	}.div2Img {
	    border-width: @bw*.025 @bw*.025 @bw*.025 @bw*.025;
	    border-style: solid;
	    border-color: #FFFFFF;
	    background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center 20%;
	    position: relative;
		// width: @bw*.86;
		// height: @bw*1.011;
	    box-sizing: border-box;
	    -webkit-transform: rotateZ(-5deg);
	}
	.page2Btf_box {
		width: 220px;
		height: 500px;
		left: 0;
		position: absolute;
		-webkit-perspective: 555.5px;
		transform: translate(298.5px, -146px);
		animation: page2Btf_box 2s ease-in 0s 1 normal both running;
	}
	@-webkit-keyframes page2Btf_box {
		0% {
			-webkit-transform:translate(@bw*.597,-@bw*.292);
		}
		100% {
			-webkit-transform:translate(@bw*.093,@bw*.0097);
		}
	}
	.page2_butterfly {
		width: 120px;
		height: 80px;
		top: 0px;
		left: 0px;
		-webkit-transform-style: preserve-3d;
		-webkit-transform: rotateX(-100deg) rotateY(-11deg) rotateZ(-90deg);
		position: absolute;
	}
	.page2Btf_leftWing {
		z-index: 2 !important;
		-webkit-transform-origin:@bw*.079 @bw*.05;
		-webkit-animation:page1Btf_leftWing 0.6s ease-in-out infinite alternate;
		position:absolute;
	}
	.page2Btf_rightWing {
		position: absolute;
		right: 0;
		width: 59px;
		height: 78px;
		-webkit-transform-origin: 2px 37px;
		-webkit-animation: page1Btf_rightWing 0.6s ease-in-out infinite alternate;
	}
	.page2Btf_body {
		width:@bw * 0.068;
		height:@bw * 0.042;
		top:43%;
		left:48.5%;
		-webkit-transform:translate3d(@bw * 0.0,-@bw * 0.026,3px) rotateY(110deg) rotateZ(65deg);
		-webkit-animation:page2Btf_body 0.6s ease-in-out infinite alternate;
	}
	.po {
		position:absolute;
	}
	.page2Btf_rightWing>img {
		-webkit-transform:rotateY(180deg);
	}
	@-webkit-keyframes page1Btf_rightWing {
		0% {
		-webkit-transform:rotateY(-60deg);
	}
	100% {
		-webkit-transform:rotateY(60deg);
	}
	}@-webkit-keyframes page1Btf_leftWing {
		0% {
		-webkit-transform:rotateY(60deg);
	}
	100% {
		-webkit-transform:rotateY(-60deg);
	}
	}@-webkit-keyframes page2Btf_body {
		0% {
		-webkit-transform:translate3d(@bw * 0.0,-@bw * 0.026,3px) rotateY(110deg) rotateZ(65deg);
	}
	100% {
		-webkit-transform:translate3d(@bw * 0.0,-@bw * 0.026,4px) rotateY(110deg) rotateZ(65deg);
	}
	}.state_ani {
		position:absolute;
		-webkit-animation:div_flow 3.5s linear 0s 1 both;
	}
	@-webkit-keyframes div_flow {
		0% {
		transform:translateY(-@bw * 0.675) translateX(@bw * 0.438)
	}
	50% {
		transform:translateY(@bw * 0.514) translateX(-@bw * 0.076)
	}
	100% {
		transform:translateY(@bw * 0.338) translateX(@bw * 0.069)
	}
	}@-webkit-keyframes divPageRotz {
		0% {
		-webkit-transform:rotateZ(0deg);
	}
	20% {
		-webkit-transform:rotateZ(-0.032deg);
	}
	40% {
		-webkit-transform:rotateZ(-0.122deg);
	}
	60% {
		-webkit-transform:rotateZ(-0.032deg);
	}
	80% {
		-webkit-transform:rotateZ(0.122deg);
	}
	100% {
		-webkit-transform:rotateZ(0.032deg);
	}
	}@-webkit-keyframes divPageTrz {
		0% {
		-webkit-transform:translateZ(@bw * 0.0);
	}
	20% {
		-webkit-transform:translateZ(@bw * 0.003);
	}
	40% {
		-webkit-transform:translateZ(1.6px);
	}
	60% {
		-webkit-transform:translateZ(1.4px);
	}
	80% {
		-webkit-transform:translateZ(1.3px);
	}
	100% {
		-webkit-transform:translateZ(0.5px);
	}
	}@-webkit-keyframes divPageTrx {
		0% {
		-webkit-transform:translateX(@bw * 0.0);
	}
	20% {
		-webkit-transform:translateX(0.3px);
	}
	40% {
		-webkit-transform:translateX(-0.85px);
	}
	60% {
		-webkit-transform:translateX(-0.3px);
	}
	80% {
		-webkit-transform:translateX(0.6px);
	}
	100% {
		-webkit-transform:translateX(0.25px);
	}
	}.shanke_rotate_ani {
		width:100%;
		height:100%;
		-webkit-animation:divPageTrz 3.15s ease-in-out infinite alternate both;
	}
	.page2_hua {
		width:100%;
		height:100%;
		pointer-events:none;
		-webkit-animation:divPageTrx 2.17s ease-in-out infinite alternate both;
	}
	.hua4 {
		-webkit-animation:divPageTrx 2.17s ease-in-out infinite alternate both;
	}
	.hua4>div {
		-webkit-perspective:@bw;
		-webkit-animation:divPageTry 2s ease-in-out infinite alternate both;
	}
	.hua4>div>div {
		-webkit-animation:divPageTrz 1.95s ease-in-out infinite alternate both;
	}
	.hua4>div>div>div {
		-webkit-transform-origin:50% 100%;
		-webkit-animation:divPageRotz 2.06s ease-in-out infinite alternate both;
	}
	.page2_hua1 {
		bottom:0;
		left:0;
		height: @bw * .6;
	}
	.page2_hua2 {
		bottom:0;
		left:@bw * 0.208;
	}
	.page2_hua3 {
		bottom:0;
		left:@bw * 0.431;
	}
	.page2_hua4 {
		bottom:0;
		left:@bw * 0.718;
	}
	.page2_hua5 {
		bottom:0;
		left:@bw * 1.028;
	}
	.page2_hua6 {
		bottom:0;
		left:@bw * 1.296;
	}
	.page2_hua7 {
		bottom:0;
		right:0;
	}
	.all_width {
		width:100%;
		height:100%;
	}
	.bor_div {
		border-width:@bw*.025 @bw*.025 @bw*.125 @bw*.025;
		border-style:solid;
		border-color:#FFFFFF;
		background-size:cover;
		background-repeat:no-repeat;
		position:relative;
	}
	@-webkit-keyframes trX {
		0% {
		-webkit-transform:translateX(@bw * 0.0);
	}
	20% {
		-webkit-transform:translateX(0.5px);
	}
	40% {
		-webkit-transform:translateX(-1.98px);
	}
	60% {
		-webkit-transform:translateX(-1.6px);
	}
	80% {
		-webkit-transform:translateX(1.3px);
	}
	100% {
		-webkit-transform:translateX(0.6px);
	}
	}@-webkit-keyframes trY {
		0% {
		-webkit-transform:translateY(@bw * 0.0);
	}
	20% {
		-webkit-transform:translateY(1.6px);
	}
	40% {
		-webkit-transform:translateY(-1.3px);
	}
	60% {
		-webkit-transform:translateY(1.2px);
	}
	80% {
		-webkit-transform:translateY(1.6px);
	}
	100% {
		-webkit-transform:translateY(0.5px);
	}
	}@-webkit-keyframes trZ {
		0% {
		-webkit-transform:translateZ(@bw * 0.0);
	}
	20% {
		-webkit-transform:translateZ(1.6px);
	}
	40% {
		-webkit-transform:translateZ(1.2px);
	}
	60% {
		-webkit-transform:translateZ(2.0px);
	}
	80% {
		-webkit-transform:translateZ(1.7px);
	}
	100% {
		-webkit-transform:translateZ(1.7px);
	}
	}@-webkit-keyframes rotZ {
		0% {
		-webkit-transform:rotateZ(0deg);
	}
	20% {
		-webkit-transform:rotateZ(-0.1deg);
	}
	40% {
		-webkit-transform:rotateZ(-0.2deg);
	}
	60% {
		-webkit-transform:rotateZ(-0.1deg);
	}
	80% {
		-webkit-transform:rotateZ(0.2deg);
	}
	100% {
		-webkit-transform:rotateZ(0.1deg);
	}
	}/*@-webkit-keyframes div10_box {
		0% {
		-webkit-transform:translate(0,0);
	}
	100% {
		-webkit-transform:translate(-@bw * 1.181,-@bw * 0.903);
	}
	}*/
	// .bg {
	// 	width:100%;
	// 	height:100%;
	// 	-webkit-animation:trX 1.8s ease-in-out infinite alternate both;
	// }
	// .bg div {
	// 	width:100%;
	// 	height:100%;
	// }
	// .bg>div {
	// 	-webkit-perspective:@bw;
	// 	-webkit-animation:trY 2.04s ease-in-out infinite alternate both;
	// }
	// .bg>div>div {
	// 	-webkit-animation:trZ 1.92s ease-in-out infinite alternate both;
	// }
	// .bg>div>div>img {
	// 	width:100%;
	// 	height:100%;
	// 	-webkit-transform-origin:50% 100%;
	// 	-webkit-animation:rotZ 2.16s ease-in-out infinite alternate both;
	// }
</style>