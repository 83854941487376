<template>
	<div class="chuang">
		<div class="chuang_bg" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
		<div class="chuang_bd">
			<div class="chuang_sml">
				<div class="chuang_sc">
					<div class="chuang_pic" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				</div>
			</div>
		</div>
		<div class="chuang_top">
			<img :src="mode[1]" v-show="mode.length > 1">
		</div>
	</div>	
</template>

<script>
	export default {
		name: 'chuang',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		mounted () {},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.chuang_bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width:@bw * 1.778;
		background: no-repeat 50%;
		background-size: cover;
		-webkit-animation: chuang-translateBg 10s linear both;
	}
	@-webkit-keyframes chuang-translateBg {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-@bw * 0.778);
		}
	}
	.chuang_top {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 12;
		animation: chuang-translateTopImg 10s ease-in-out 0s 1 alternate both;
		text-align: left;
		width:@bw * 1.778;
		height:@bw * 1.778;
	}
	.chuang_top>img {
		width:@bw * 2.083;
		display: block;
	}
	@-webkit-keyframes chuang-translateTopImg {
		0% {
			transform: translateX(0) rotate(0);
		}
		50% {
			transform: translateX(-@bw * 0.451) rotate(3deg) translateZ(@bw * 0.004);
		}
		100% {
			transform: translateX(-@bw * 0.903) rotate(0deg) translateZ(0);
		}
	}
	.chuang_bd {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		/*-webkit-animation: chuang-in-out 10s linear both;*/
	}
	/*@-webkit-keyframes chuang-in-out {

	}*/
	.chuang_sml {
		width:@bw * 1.114;
		height:@bw * 1.425;
		transform: translateZ(0);
		-webkit-animation: chuang-page_show 4s ease-out both;
		-webkit-mask-size: 50% 50%;
		-webkit-mask-position: center center;
		-webkit-mask-repeat: no-repeat;
		overflow: hidden;
		-webkit-mask-image: url(http://litiresource.xcgogo.site/user/5980171/images/b4990b6b972e16e71244cc2784f0d930.png);
	}
	@-webkit-keyframes chuang-page_show {
		from {
			-webkit-mask-size: 50% 50%;
		}
		to {
			-webkit-mask-size: 100% 100%;
		}
	}
	.chuang_sc {
		transform: translateZ(0);
	}
	.chuang_pic {
		width:@bw * 1.296;
		height:@bw * 1.421;
		animation: chuang-rot_v 10s ease-out 0s 1 normal both running;
		background-size: cover;
		background-position: center center;
	}
	@-webkit-keyframes chuang-rot_v {
		from {
			transform: rotate(-12deg);
		}
		to {
			transform: rotate(10deg);
		}
	}
</style>