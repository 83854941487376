export default {
	init () {
		// if (process.env.NODE_ENV == 'development') {
		// 	return null;
		// }
		let screenWidth = document.documentElement.clientWidth || document.body.clientWidth,
			screenHeight = document.documentElement.clientHeight || document.body.clientHeight;;

		let Cropper = window.Cropper,
			image = document.getElementById('CropperImg');

		let options = {
			minContainerHeight :  screenHeight,
			minContainerWidth : screenWidth,
			aspectRatio: 1 / 1,//裁剪框比例 1：1
			viewMode : 1,//显示
			guides :false,//裁剪框虚线 默认true有
			dragMode : "move",
			build: function (e) { //加载开始
				//可以放你的过渡 效果
			},
			built: function (e) { //加载完成
				// $("#containerDiv").show();
				// $("#imgEdit").show();
			},
			zoom: function (e) {
				// console.log(e.type, e.detail.ratio);
			},
			background : true,// 容器是否显示网格背景
			movable : true,//是否能移动图片
			cropBoxMovable :false,//是否允许拖动裁剪框
			cropBoxResizable :false,//是否允许拖动 改变裁剪框大小
			autoCropArea: 1, // 裁剪框占比
			zoomable: false, // 是否允许缩放
		};
		
		return new Cropper(image, options);
	}
}