<template>
	<div class="stage">
	    <div class="ytbg" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
	    <div class="box" id="box">
	        <div class="ytbot threedee" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	        <div @click="wxPreview" class="threedee cube_1 assembly click_img"
	        :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	        <div @click="wxPreview" class="threedee cube_2 assembly click_img"
	        :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	        <div @click="wxPreview" class="threedee rect_left assembly click_img" id="cube_left"
	        :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	        <div @click="wxPreview" class="threedee cube_right assembly click_img" id="cube_right"
	        :style="{backgroundImage: 'url('+currentImage['im']+')'}">
	            <div @click="wxPreview" class="erduo_right click_img"
	            :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	            <div @click="wxPreview" class="erduo_left click_img"
	            :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	        </div>
	        <div @click="wxPreview" class="threedee cube_3 assembly click_img" id="cube_3"
	        :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'yuanup',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.ytbg {
	    height: 100%;
	    width: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    background-size: cover;
	}
	.stage {
	    height: 100%;
	    width: 100%;
	    position: relative;
	    overflow: hidden;
	    perspective:@bw * 0.833;
	    color: #fff;
	    text-align:center;
	}
	.threedee, .threedee2 {
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    transform-style: preserve-3d;
	    transform-origin: 50% 50%;
	    width:@bw * 0.278;
	    height:@bw * 0.278;
	    margin-top: -@bw * 0.065;
	    margin-left: -@bw * 0.139;
	    // background-size: auto 100%;
	    background-size: cover;
	    background-position: center;
	    background-repeat: no-repeat;
	    transform: translateZ(@bw * 0.346) rotateY(0deg);
	}
	.threedee2 {
	    z-index: 3;
	}
	.threedee2 .threedee {
	    background-size:@bw * 0.486;
	    background-repeat: no-repeat;
	}
	.ytbot {
	    background-position:@bw * 0.0 @bw * 0.0;
	    width:@bw * 1.25;
	    height:@bw * 1.25;
	    margin-top: -@bw * 0.389;
	    margin-left: -@bw * 0.625;
	    transform: translate3d(@bw * 0.0,@bw * 0.099,@bw * 0.0) rotateX(60deg) rotateY(0rad) rotateZ(0rad);
	    animation: rotateBot 15s linear infinite alternate;
	    border-radius: 50%;
	}
	.box {
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    perspective:@bw * 0.694
	}
	.user_photo {
	    position: absolute;
	    width:@bw * 0.382;
	    height:@bw * 0.517;
	    margin-top: -@bw * 0.254;
	    margin-left: -@bw * 0.189;
	    top: 50%;
	    left: 50%;
	    background: no-repeat center center #fff;
	    background-size: contain;
	}
	@keyframes qipaoright {
	    0% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.139) translateY(0) scale(0.2);
	    }
	    10% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.139) translateY(-@bw * 0.139) scale(1)
	    }
	    40% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.139) translateY(-@bw * 0.417) scale(1)
	    }
	    60% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.139) translateY(-@bw * 0.444) scale(1)
	    }
	    100% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.139) translateY(-@bw * 0.694);
	    }
	}
	@keyframes qipaoleft {
	    0% {
	        transform: translateZ(@bw * 0.139) translateX(@bw * 0.278) translateY(@bw * 0.278) scale(0);
	    }
	    20% {
	        transform: translateZ(@bw * 0.139) translateX(@bw * 0.278) translateY(@bw * 0.139) scale(1)
	    }
	    100% {
	        transform: translateZ(@bw * 0.139) translateX(@bw * 0.278) translateY(-@bw * 0.556);
	    }
	}
	@keyframes qipao3 {
	    0% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.278) translateY(@bw * 0.278) scale(0);
	    }
	    20% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.278) translateY(@bw * 0.139) scale(1)
	    }
	    100% {
	        transform: translateZ(@bw * 0.139) translateX(-@bw * 0.278) translateY(-@bw * 0.556);
	    }
	}
	@keyframes qipao1 {
	    0% {
	        transform: translateZ(@bw * 0.0) translateX(-@bw * 0.278) translateY(-@bw * 0.486) scale(0);
	    }
	    10% {
	        transform: translateZ(@bw * 0.0) translateX(-@bw * 0.278) translateY(-@bw * 0.5) scale(1)
	    }
	    100% {
	        transform: translateZ(@bw * 0.0) translateX(-@bw * 0.278) translateY(-@bw * 0.972) scale(1);
	    }
	}
	@keyframes top {
	    50% {
	        opacity: 1;
	    }
	    70% {
	        opacity: 0.5;
	    }
	    100% {
	        opacity: 0;
	    }
	}
	.photo_box {
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    transform-style: preserve-3d;
	    transform-origin: 50% 50%;
	    width:@bw * 0.547;
	    height:@bw * 0.674;
	    margin-top: -@bw * 0.356;
	    box-sizing: border-box;
	    padding:@bw * 0.083;
	    margin-left: -@bw * 0.269;
	    z-index: 2;
	    transform: translate3d(@bw * 0.0,@bw * 0.496,-@bw * 0.581) rotateX(0rad) rotateY(0rad) rotateZ(0rad) scale(2);
	    z-index: 5;
	}
	.rect_left {
	    transform: translateZ(@bw * 0.014) translateX(@bw * 0.292) translateY(@bw * 0.278) scale(0);
	    width:@bw * 0.278;
	    height:@bw * 0.278;
	    border-radius: 50%;
	    animation: qipaoleft 12.3s 4.5s linear 1;
	}
	.cube_right {
	    transform: translateZ(@bw * 0.139) translateX(-@bw * 0.139) scale(0);
	    animation: qipaoright 12s linear infinite;
	    width:@bw * 0.556;
	    height:@bw * 0.556;
	    border-radius: 50%;
	}
	.cube_3 {
	    transform: translateZ(@bw * 0.139) translateX(-@bw * 0.278) translateY(@bw * 0.278) scale(0);
	    width:@bw * 0.278;
	    border-radius: 50%;
	    height:@bw * 0.278;
	    animation: qipao3 12.3s 4.5s linear 1;
	}
	.cube_1 {
	    transform: translateZ(@bw * 0.0) translateX(-@bw * 0.278) scale(0);
	    width:@bw * 0.278;
	    border-radius: 50%;
	    height:@bw * 0.278;
	    animation: qipao1 3s 0.4s linear 1;
	}
	.cube_2 {
	    transform: translateZ(@bw * 0.0) translateX(-@bw * 0.278) scale(0);
	    width:@bw * 0.278;
	    border-radius: 50%;
	    height:@bw * 0.278;
	    left:@bw * 1.111;
	    animation: qipao1 3s 0.4s linear 1;
	}
	@keyframes rotateBot {
	    0% {
	        transform: translate3d(@bw * 0.0,@bw * 0.099,@bw * 0.0) rotateX(60deg) rotateY(0rad) rotateZ(0deg);
	    }
	    50% {
	        transform: translate3d(@bw * 0.0,@bw * 0.099,@bw * 0.0) rotateX(60deg) rotateY(0rad) rotateZ(360deg)
	    }
	    100% {
	        transform: translate3d(@bw * 0.0,@bw * 0.099,@bw * 0.0) rotateX(60deg) rotateY(0rad) rotateZ(730deg)
	    }
	}
	@keyframes tranToperduo {
	    0% {
	        transform: translateY(0) translateZ(-@bw * 0.278);
	    }
	    100% {
	        transform: translateY(-@bw * 0.278) translateZ(-@bw * 0.278);
	    }
	}
	.erduo_right {
	    width:@bw * 0.181;
	    height:@bw * 0.181;
	    position: fixed;
	    top: -@bw * 0.074;
	    right: 0;
	    border-radius: 50%;
	    transform: translateZ(-@bw * 0.001);
	    background: no-repeat center;
	    background-size: cover;
	    /* animation: tranToperduo 12s linear infinite; */
	}
	.erduo_left {
	    width:@bw * 0.181;
	    height:@bw * 0.181;
	    position: fixed;
	    top: -@bw * 0.074;
	    left:@bw * 0.0;
	    border-radius: 50%;
	    transform: translateZ(-@bw * 0.001);
	    background: no-repeat center;
	    background-size: cover;
	    /* animation: tranToperduo 12s linear infinite; */
	}
</style>