class stUpload {
    constructor (util) {
        this.$util = util;

        this.maxWidth = 1300; // 图片宽限制
        this.maxHeight = 1300; // 图片高限制
        this.last_time = 0;
        
        this.aliyun_access_data = null;
        this.aliyun_access_data_map = {};
        this.media_infos = [];
        this.chosed_count = 0;
        this.finsh_count = 0;
        
        this.maxsize = 2 * 1048576;
        this.count = 9;
        this.sizeType = ['compressed'];
        this.sourceType = ['album'];
        this.lock = false;
        this.isShowLoaing = true;
        this.temp_upload_config = null;

        this.process = async function(){return};
        this.violate = async function(){return {
            "mode": "default"
        }};
        this.oversize = async function(){return};
        this.cancel = async function(){return};
        this.fail = async function(){return};
        this.raiseError = async function(){return};
    }

    get upload_config () {
        return this.temp_upload_config || this.$util.$config.app_config.upload_config;
    }

    log (key, params) {
        this.$util.$loger.log(key, params)
        if (this.$util.isEnv('h5')){
            this.$util.log(key + "_step_" + params['step'])
        }
        console.error(key,"=>",params['step']);
    }

    countTime(name) {
        if (this.last_time) {
            console.error( name, "=>: ", ( Date.now() - this.last_time) );
        }
        this.last_time = Date.now();
    }

    async getAliyunAccessData (options) {
        let {
                content_type
            } = options || {},
            params = {"bucket": this.upload_config['ali']['bucket']};
        if (!this.aliyun_access_data || Math.round(Date.now()/1000) > this.aliyun_access_data["exprie"]) {
            this.aliyun_access_data = {
                "exprie": Math.round(Date.now()/1000 + 60 * 5),// token 5min 过期
            };
            if (this.$util.isEnv("wxa")) {
                params["content_type"] = "image/jpeg";
            }
            this.aliyun_access_data = await this.$util.rget('api/get_aliyun_oss_form_token', params).catch(err=>{
                debugger;
            })
        }

        if (content_type) {
            params["content_type"] = content_type;
            if (this.aliyun_access_data_map[content_type]) {
                return Promise.resolve(this.aliyun_access_data_map[content_type])
            } else {
                this.aliyun_access_data_map[content_type] = await this.$util.rget('api/get_aliyun_oss_form_token', params).catch(err=>{
                    debugger;
                })
                return Promise.resolve(this.aliyun_access_data_map[content_type]);
            }
        }

        return Promise.resolve(this.aliyun_access_data)
    }

    async prepare () {
        if (this.$util.isEnv("h5")) {
            await this.canIUseB64().catch(err=>{});
            if (!this.can_i_use_b64) {
                return
            }
        }
        await this.getAliyunAccessData().catch(err=>{
            debugger;
        });
        this.lock = false;
    }

    showLoading (options) {
        if (this.isShowLoaing) {
            this.$util.$taro.showLoading(options);
        }
    }

    hideLoading () {
        if (this.isShowLoaing)
            this.$util.$taro.hideLoading();
    }

    showToast (options) {
        if (this.isShowLoaing) {
            this.$util.$taro.showToast(options)
        }
    }

    hideToast () {
        if (this.isShowLoaing)
            this.$util.$taro.hideToast();
    }

    /** 
    * @函数名 媒体上传函数
    * @parm mediaType (上传媒体类型) 'image'、'video' <string> 【必填】
    * @parm count (所选的媒体的数量) 1~9 <number>【必填】
    * @parm compressed (所选的媒体是否压缩) true、false <boolen>【必填】
    * @parm sourceType (所选的媒体的来源) ['album', 'camera'] <Array>【必填】
    * @parm maxDuration (拍摄时长) max:60 <number>【非必填】
    * @parm maxsize (所选的媒体的最大大小) 单位 M <number>【非必填】
    * @parm violate (违规处理函数) <Promise function>【非必填】
    * @parm process (上传进度回调函数) <Function> 【非必填】
    * @parm cancel (取消选图回调函数) <Function> 【非必填】
    * @parm oversize (文件过大处理函数) <Promise function>【非必填】
    * @parm fail (其他报错处理函数) <Promise function>【非必填】
    * @parm isShowLoading (显示进度提示) true false <boolean>【非必填】默认 true
    * @return media_infos <Promise function>
    */
    async uploadMedia (options) {
        let _this = this;
        
        if (!this.upload_config) {
            let msg = "⚠️ 如需使用上传api，请先设置上传配置 st_comps.config.json->upload_config ⚠️";
            this.$util.$loger.error(msg);
            return Promise.reject(msg);
        }

        if (this.lock) {
            this.log("upload_media",{
                "step": "lock"
            })
            return Promise.reject();
        }

        this.log("upload_media",{
            "step": "start"
        })
        this.lock = true;

        setTimeout(() => {
            this.lock = false;
        }, 2000);

        // 环境预备
        this.prepare();

        let {mediaType, count, sizeType, compressed, sourceType, violate, maxsize, oversize, process, cancel, fail, isShowLoaing, mediaSource, tempUploadConfig, checkMode} = options || {};

        
        this.temp_upload_config = tempUploadConfig;
        // 内部参数
        this.media_infos = [];
        this.chosed_count = 0; // 用户选择了的图片张数
        this.finsh_count = 0; // 完成上传并获得链接的图片张数

        // 外部传入参数
        this.mediaType = mediaType || "image";
        this.count = count || 9; // 1~9
        this.sizeType = sizeType || ['compressed']; // ['original', 'compressed']
        this.sourceType = sourceType || ['album']; // ['album', 'camera'] 可以指定来源是相册还是相机，默认二者都有，在H5浏览器端支持使用 `user` 和 `environment`分别指定为前后摄像头
        this.maxsize = (maxsize || 2) * 1048576;//最高限制在2M
        this.isShowLoaing = isShowLoaing === undefined ? true : isShowLoaing;
        this.checkMode = checkMode || 2;
        
        // 外部传入函数
        this.process = process || async function(){return};
        this.violate = violate || async function(){return {
            "mode": "default"
        }};
        this.oversize = oversize || async function(){return};
        this.cancel = cancel || async function(){return};
        this.fail = fail || async function(){return};
        this.mediaSource = mediaSource || "album";

        if (this.mediaType == "video"){ // 视频
            this.count = 1;
            let {maxDuration} = options || {};
            await this.chooseVideo({
                maxDuration: maxDuration,
                compressed: compressed,
                sourceType: sourceType
            }).catch(err=>{
                _this.lock = false;
                return Promise.reject(err)
            })
        } else { // 图片
            await this.chooseImage().catch(err=>{
                _this.lock = false;
                return Promise.reject(err)
            })
        }

        this.log("upload_media",{
            "step": "end"
        })
        this.lock = false;

        let media_infos = []
        for(let i = 0 ; i < this.media_infos.length ; i++){
            if (this.media_infos[i] && this.media_infos[i]['url']){
                media_infos.push(this.media_infos[i]);
            } else {
                this.log("upload_media",{
                    "step": "empty_media_info"
                })
            }
        }

        this.hideLoading();

        if (media_infos.length > 0) {
            return media_infos;
        } else {
            this.log("upload_media",{
                "step": "image_length_0"
            })
            return Promise.reject({
                "errcode": 40001,
                "msg": "未选择照片"
            });
        }
    }

    /**
     * 上传文件至阿里云
     */
    async uploadBase64 (params) {
        let _this = this,
            aliyunServerURL = this.upload_config['ali']['upload_host'];
        const aliyunFileKey = params.fileName;
        if (params['fileHost']) {
            aliyunServerURL = params['fileHost'];
        }

        await this.getAliyunAccessData();

        const accessid = this.aliyun_access_data.access_id;
        const policyBase64 = this.aliyun_access_data.policy;
        const signature = this.aliyun_access_data.sign;

        return await new Promise ((resolve, reject)=>{
            _this.$util.$taro.uploadFile({
                url: aliyunServerURL,
                filePath: params.filePath,
                name: 'file',
                formData: {
                    'key': _this.upload_config['ali']['sub_path'] + aliyunFileKey,
                    'policy': policyBase64,
                    'OSSAccessKeyId': accessid,
                    'signature': signature,
                    'success_action_status': '200',
                },
                success: res=> {
                    resolve(true)
                },
                fail: err=> {
                    debugger;
                    _this.log("upload_media",{
                        "step": "oss_upload_fail"
                    })
                    resolve(false)
                },
            })
        })
    }

    /**
     * 抠图
     */
    async bodySeg (options) {
        options = options || {};
        // 抠图暂时支支持1张
        options['count'] = 1;
        // 抠人原图
        options['tempUploadConfig'] = options['tempUploadConfig'] || this.$util.copy( this.$util.$config.app_config.upload_config );
        options['tempUploadConfig']['ali']['sub_path'] = options["origin_img_sub_path"] || "body_cache/";

        console.error("uploadMedia before");

        let img = await this.uploadMedia(options).catch(err=>{
            return Promise.reject(err);
        });
        if (!img || !img[0]) {
            return Promise.reject('未能获取上传后的img链接')
        }

        console.error("uploadMedia after", img);
        
        // 开始人体分割流程
        options = Object.assign({
            detect: "1",
            sub_path: "body/",
            bucket_name: this.upload_config['ali']['bucket'],
            form: 2,
            appid: this.$util.$login.appid,
            openid: this.$util.$login.openid
        }, options)

        let src_img = img[0]['url'],
            img_sign = img[0]['sign'],
            {  
                appid,
                openid,
                form,
                bucket_name,
                sub_path,
                detect
            } = options || {};
        
        this.showLoading({
            title: '智能分析...',
            mask: true,
            duration: 10000
        });

        // 检查人像
        let detect_tmp = detect.split(":"),
            detect_num = detect_tmp[1],
            detect_type = detect_tmp[0],
            detect_type_api = "";

        if (detect_type == "1") {
            detect_type_api = "/oldpic/body_detect";
        } else if (detect_type == "2") {
            detect_type_api = "/oldpic/face_detect";
        }

        if (detect_type_api) {
            console.error("detect_type_api > ", detect_type_api);
            
            console.error(detect_type_api +" before", {
                "pic_url": src_img
            });
            let detect_resp = await this.$util.rget(detect_type_api, {
                "pic_url": src_img
            }).catch(err => {console.error(detect_type_api + " fail > ", err)});
            console.error(detect_type_api + " after", detect_resp);
    
            try {
                console.error("detect_num > ", detect_num)
                if (!detect_num) {
                    // 无人像即驳回
                    if (detect_resp && detect_resp.num.toString() == "0") {
                        this.hideLoading();
            
                        this.showToast({
                            title: '请上传清晰正脸人像照片',
                            icon: 'none'
                        })
                        return Promise.reject({
                            errcode: 40003,
                            msg: '未上传正脸人像照片'
                        });
                    }
                } else if (detect_num == 'n') {
                    // 无多人像（<2）即驳回
                    if (detect_resp && parseInt(detect_resp.num) < 2) {
                        this.hideLoading();
            
                        this.showToast({
                            title: '请上传多人照片',
                            icon: 'none'
                        })
                        return Promise.reject({
                            errcode: 40003,
                            msg: '请上传多人照片'
                        });
                    }
                } else {
                    // 非指定数量人像驳回
                    if (detect_resp && detect_resp.num.toString() != detect_num) {
                        this.hideLoading();
            
                        this.showToast({
                            title: '请上传' + detect_num + '人照片',
                            icon: 'none'
                        })
                        return Promise.reject({
                            errcode: 40003,
                            msg: '请上传' + detect_num + '人照片'
                        });
                    }
                }
            } catch (err) {console.error(err)};
        }

        // 抠图
        let cut_resp = await this.$util.rpost( this.$util.$config.FC_HOST + "st_ai/body_seg", {
            appid: appid || this.$util.$login.appid,
            openid: openid ||  this.$util.$login.openid,
            src_img: src_img,
            img_sign: img_sign,
            form: form || 2,
            bucket_name: bucket_name || this.upload_config['ali']['bucket'],
            sub_path: sub_path || "body/"
        }).catch(err => {
            debugger;
            console.error("cut error > ", err);
            this.hideLoading();
            return Promise.reject({
                errcode: 40004,
                msg: '抠图失败，请更换照片再试'
            });
        });
        console.error("cut after", cut_resp);

        // 错误处理
        if (!cut_resp) {
            this.hideLoading();
            return Promise.reject({
                errcode: 40004,
                msg: '抠图失败，请更换照片再试'
            })
        }

        cut_resp = JSON.parse(cut_resp);

        let cut_url = cut_resp["foreground_url"];
        // 替换域名
        let cut_url_temp = cut_url.split("//")[1].split("/");
        cut_url_temp.shift();
        cut_url = options['tempUploadConfig']['ali']['host'] + cut_url_temp.join("/");

        let rt = {
            "sign": img_sign,
            "url": cut_url,
            "rect": cut_resp["rect"]
        }
        
        console.error("cut after > ", rt);
        
        this.hideLoading();

        return rt;
    }
}

export default {
    stUpload
};