import mainLoadingVue from './MainLoading.vue'

const MainLoading = {};

MainLoading.install = function(Vue, options) {
	const MainLoadingInstance = Vue.extend(mainLoadingVue);
	let currentMainLoading;
	const initInstance = () => {
		currentMainLoading = new MainLoadingInstance();
		let MainLoadingEl = currentMainLoading.$mount().$el;
		document.body.appendChild(MainLoadingEl);
	}

	Vue.prototype.$mainloading = {
		load (options) {
			if (!currentMainLoading) {
				initInstance();
			}
			currentMainLoading.options = options;

			return currentMainLoading.load();
		},
		show (options) {
			return currentMainLoading.show();
		},
		hide (options) {
			return currentMainLoading.hide();
		}
	}
}

export default MainLoading