<!-- 旋转飘 -->
<template>
	<div class="piao4-cont" :style="getStyles">
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
			<div v-for="index in 7" class="po"
				:class="'piao4-' + index"
				:key="'piao4_' + index">
				<elem 
					:mode="getLuoItem(index)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
			</div>
		</template>
		<template v-else>
			<img
				v-for="index in 7" class="po"
				mode="widthFix"
				:class="'piao4-' + index"
				:key="'piao4_' + index"
				:src="getLuoItem(index)">
		</template>
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'piao4',
		props: ["mode"],
		extends: BaseLuo,
		data () {
			return {}
		}
	}
</script>

<style>
	.piao4-cont {
		position: absolute;
	    bottom: 0;
	    pointer-events: none;
	    z-index: 6;
	}
	.piao4-1 {
		left: 453px;
		bottom: 49px;
		animation: piao4_1 3.8s linear infinite;
	}
	@keyframes piao4_1 {
		0% {
		    transform: translate(0px,0px) rotate(0deg);
		    opacity: 0;
		}
		20% {
		    transform: translate(0px,-40px) rotate(0deg);
		    opacity: 1;
		}
		50% {
		    transform: translate(0px,-80px) rotate(50deg);
		    opacity: 1;
		}
		60% {
		    transform: translate(0px,-90px) rotate(80deg);
		    opacity: 1;
		}
		80% {
		    transform: translate(0px,-110px) rotate(130deg);
		    opacity: 0;
		}
		100% {
		    opacity: 0;
		}
	}
	.piao4-2 {
		left: 228px;
		bottom: 64px;
		animation: piao4_2 3s 2s linear infinite;
	}
	@keyframes piao4_2 {
		0% {
		    opacity: 0;
		    transform: translate(0px,0px) rotate(0deg);
		}
		5% {
		    opacity: 1;
		}
		30% {
		    opacity: 1;
		    transform: translate(0px,-45px) rotate(42deg);
		}
		100% {
		    opacity: 0;
		    transform: translate(0px,-160px) rotate(130deg);
		}
	}
	.piao4-3 {
		left: 25px;
		bottom: 40px;
		animation: piao4_3 3s 1s linear infinite;
	}
	@keyframes piao4_3 {
		0% {
		    opacity: 0;
		    transform: translate(0px,0px);
		}
		5% {
		    opacity: 1;
		}
		60% {
		    opacity: 1;
		    transform: translate(0px,-90px);
		}
		100% {
		    opacity: 0;
		    transform: translate(0px,-130px);
		}
	}
	.piao4-4 {
		left: 373px;
		bottom: 65px;
		animation: piao4_4 3.3s linear infinite;
	}
	@keyframes piao4_4 {
		0% {
		    opacity: 0;
		    transform: translate(0px,0px) rotate(-60deg);
		}
		30% {
		    opacity: 1;
		    transform: translate(0px,-50px) rotate(-42deg);
		}
		100% {
		    opacity: 0;
		    transform: translate(0px,-160px) rotate(0deg);
		}
	}
	.piao4-5 {
		left: 100px;
		bottom: 60px;
		animation: piao4_5 3.3s linear infinite;
	}
	@keyframes piao4_5 {
		0% {
		    opacity: 0;
		    transform: translate(0px,0px) rotate(60deg);
		}
		30% {
		    opacity: 1;
		    transform: translate(0px,-60px) rotate(35deg);
		}
		60% {
		    opacity: 1;
		    transform: translate(0px,-100px) rotate(5deg);
		}
		100% {
		    opacity: 0;
		    transform: translate(0px,-165px) rotate(-20deg);
		}
	}
	.piao4-6 {
		left: 165px;
		bottom: 64px;
		animation: piao4_6 6s linear infinite;
	}
	@keyframes piao4_6 {
		0% {
		    opacity: 0;
		    transform: translate(0px,0px) rotate(-20deg);
		}
		30% {
		    opacity: 1;
		    transform: translate(0px,0px) rotate(-20deg);
		}
		60% {
		    opacity: 1;
		    transform: translate(0px,-70px) rotate(-90deg);
		}
		100% {
		    opacity: 0;
		    transform: translate(0px,-150px) rotate(-170deg);
		}
	}
	.piao4-7 {
		left: 310px;
		bottom: 64px;
		animation: piao4_7 4.5s linear infinite;
	}
	@keyframes piao4_7 {
		0% {
		    opacity: 0;
		    transform: translate(0px,0px) rotate(0deg);
		}
		30% {
		    opacity: 1;
		    transform: translate(0px,0px) rotate(0deg);
		}
		60% {
		    opacity: 1;
		    transform: translate(0px,-60px) rotate(-30deg);
		}
		80% {
		    opacity: 1;
		    transform: translate(0px,-90px) rotate(-45deg);
		}
		100% {
		    opacity: 0;
		    transform: translate(0px,-120px) rotate(-60deg);
		}
	}
</style>