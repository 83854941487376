<template>
	<div class="yuanluo poc bg">
		<!-- <canvas id="yuanluo_canvas" width="500" height="815" class="fullScreen po"></canvas> -->
		<div class="perspective poc">
			<div class="yuanluo_1 preserve3d po" @click="wxPreview">
				<div class="preserve3d poc">
					<div class="yuanluo_1_1 yuanluo_div poc" style="background-position-x: 0px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_2 yuanluo_div poc" style="background-position-x: -100px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_3 yuanluo_div poc" style="background-position-x: -200px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_4 yuanluo_div poc" style="background-position-x: -300px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_5 yuanluo_div poc" style="background-position-x: -400px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_6 yuanluo_div poc" style="background-position-x: -500px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_7 yuanluo_div poc" style="background-position-x: -600px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_8 yuanluo_div poc" style="background-position-x: -700px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_9 yuanluo_div poc" style="background-position-x: -800px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_10 yuanluo_div poc" style="background-position-x: -900px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_11 yuanluo_div poc" style="background-position-x: -1000px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_12 yuanluo_div poc" style="background-position-x: -1100px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_13 yuanluo_div poc" style="background-position-x: -1200px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_14 yuanluo_div poc" style="background-position-x: -1300px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_15 yuanluo_div poc" style="background-position-x: -1400px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_16 yuanluo_div poc" style="background-position-x: -1500px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_17 yuanluo_div poc" style="background-position-x: -1600px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_18 yuanluo_div poc" style="background-position-x: -1700px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_19 yuanluo_div poc" style="background-position-x: -1800px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_20 yuanluo_div poc" style="background-position-x: -1900px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_21 yuanluo_div poc" style="background-position-x: -2000px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_22 yuanluo_div poc" style="background-position-x: -2100px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_23 yuanluo_div poc" style="background-position-x: -2200px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_24 yuanluo_div poc" style="background-position-x: -2300px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_25 yuanluo_div poc" style="background-position-x: -2400px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_26 yuanluo_div poc" style="background-position-x: -2500px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_27 yuanluo_div poc" style="background-position-x: -2600px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_28 yuanluo_div poc" style="background-position-x: -2700px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_29 yuanluo_div poc" style="background-position-x: -2800px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					<div class="yuanluo_1_30 yuanluo_div poc" style="background-position-x: -2900px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				</div>
			</div>
			<div class="yuanluo_2 preserve3d po" @click="wxPreview">
				<div class="preserve3d poc">
				    <div class="yuanluo_1_1 yuanluo_div poc" style="background-position-x: 0px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_2 yuanluo_div poc" style="background-position-x: -100px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_3 yuanluo_div poc" style="background-position-x: -200px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_4 yuanluo_div poc" style="background-position-x: -300px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_5 yuanluo_div poc" style="background-position-x: -400px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_6 yuanluo_div poc" style="background-position-x: -500px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_7 yuanluo_div poc" style="background-position-x: -600px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_8 yuanluo_div poc" style="background-position-x: -700px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_9 yuanluo_div poc" style="background-position-x: -800px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_10 yuanluo_div poc" style="background-position-x: -900px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_11 yuanluo_div poc" style="background-position-x: -1000px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_12 yuanluo_div poc" style="background-position-x: -1100px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_13 yuanluo_div poc" style="background-position-x: -1200px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_14 yuanluo_div poc" style="background-position-x: -1300px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_15 yuanluo_div poc" style="background-position-x: -1400px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_16 yuanluo_div poc" style="background-position-x: -1500px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_17 yuanluo_div poc" style="background-position-x: -1600px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_18 yuanluo_div poc" style="background-position-x: -1700px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_19 yuanluo_div poc" style="background-position-x: -1800px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_20 yuanluo_div poc" style="background-position-x: -1900px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_21 yuanluo_div poc" style="background-position-x: -2000px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_22 yuanluo_div poc" style="background-position-x: -2100px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_23 yuanluo_div poc" style="background-position-x: -2200px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_24 yuanluo_div poc" style="background-position-x: -2300px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_25 yuanluo_div poc" style="background-position-x: -2400px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_26 yuanluo_div poc" style="background-position-x: -2500px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_27 yuanluo_div poc" style="background-position-x: -2600px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_28 yuanluo_div poc" style="background-position-x: -2700px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_29 yuanluo_div poc" style="background-position-x: -2800px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				    <div class="yuanluo_1_30 yuanluo_div poc" style="background-position-x: -2900px;" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				</div>
			</div>
		</div>
		<div class="yuanluo_canvas">
			<template v-if="mode[1]['top']">
				<template v-if="mode[1]['top']['type'] && mode[1]['top']['type'] == 'elem'">
					<elem :mode="mode[1]['top']"></elem>
				</template>
				<template v-else>
					<gif :mode="mode[1]['top']"></gif>
				</template>
			</template>
		</div>
		<!-- <canvas id="yuanluo_1_canvas" width="200" height="131" class="po"></canvas><span class="divText imgTxt1">非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常</span> -->
	</div>
</template>

<script>
	export default {
		name: 'yuanluo',
		components: {},
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style>
.yuanluo .perspective {
    -webkit-perspective: 1500px;
    top: 0;
    margin: auto;
    bottom: 93%;
}
.yuanluo_1 {
    -webkit-animation: yuanluo_1 3s 0.5s ease-out both;
    bottom: -1010px;
    -webkit-transform: translateZ(-2000px);
}
@keyframes yuanluo_1 {
	0% {
	    -webkit-transform: translateZ(-2000px) translateY(-189vh);
	}
	100% {
	    -webkit-transform: translateZ(-2000px) translateY(0);
	}
}
.yuanluo_2 {
    -webkit-animation: yuanluo_2 3s 1.5s ease-out both;
    bottom: -560px;
    -webkit-transform: translateZ(-2000px) scale(0.65);
}
@keyframes yuanluo_2 {
	0% {
	    -webkit-transform: translateZ(-2000px) translateY(-126vh) scale(0.65);
	}
	100% {
	    -webkit-transform: translateZ(-2000px) translateY(0) scale(0.65);
	}
}
.yuanluo_1, .yuanluo_2 {
    width: 100px;
    height: 370px;
    margin: auto;
    left: 0;
    right: 0;
}
.yuanluo_1>div, .yuanluo_2>div {
    -webkit-animation: yuanluo_1_1 15s linear infinite both;
}
@keyframes yuanluo_1_1 {
	0% {
	    -webkit-transform: rotateY(360deg);
	}
	100% {
	    -webkit-transform: rotateY(0);
	}
}
.yuanluo_div {
    border-top: 6px solid;
    border-bottom: 6px solid;
    border-image: url(https://resali.lititutu.cn/icons/other/border.jpg);
    border-image-slice: 6;
    background-position-y: 10%;
    background-size: cover;
}
.yuanluo_1_1 {
    -webkit-transform: rotateY(0) translateZ(470px);
}
.yuanluo_1_2 {
    -webkit-transform: rotateY(12deg) translateZ(470px);
}
.yuanluo_1_3 {
    -webkit-transform: rotateY(24deg) translateZ(470px);
}
.yuanluo_1_4 {
    -webkit-transform: rotateY(36deg) translateZ(470px);
}
.yuanluo_1_5 {
    -webkit-transform: rotateY(48deg) translateZ(470px);
}
.yuanluo_1_6 {
    -webkit-transform: rotateY(60deg) translateZ(470px);
}
.yuanluo_1_7 {
    -webkit-transform: rotateY(72deg) translateZ(470px);
}
.yuanluo_1_8 {
    -webkit-transform: rotateY(84deg) translateZ(470px);
}
.yuanluo_1_9 {
    -webkit-transform: rotateY(96deg) translateZ(470px);
}
.yuanluo_1_10 {
    -webkit-transform: rotateY(108deg) translateZ(470px);
}
.yuanluo_1_11 {
    -webkit-transform: rotateY(120deg) translateZ(470px);
}
.yuanluo_1_12 {
    -webkit-transform: rotateY(132deg) translateZ(470px);
}
.yuanluo_1_13 {
    -webkit-transform: rotateY(144deg) translateZ(470px);
}
.yuanluo_1_14 {
    -webkit-transform: rotateY(156deg) translateZ(470px);
}
.yuanluo_1_15 {
    -webkit-transform: rotateY(168deg) translateZ(470px);
}
.yuanluo_1_16 {
    -webkit-transform: rotateY(180deg) translateZ(470px);
}
.yuanluo_1_17 {
    -webkit-transform: rotateY(192deg) translateZ(470px);
}
.yuanluo_1_18 {
    -webkit-transform: rotateY(204deg) translateZ(470px);
}
.yuanluo_1_19 {
    -webkit-transform: rotateY(216deg) translateZ(470px);
}
.yuanluo_1_20 {
    -webkit-transform: rotateY(228deg) translateZ(470px);
}
.yuanluo_1_21 {
    -webkit-transform: rotateY(240deg) translateZ(470px);
}
.yuanluo_1_22 {
    -webkit-transform: rotateY(252deg) translateZ(470px);
}
.yuanluo_1_23 {
    -webkit-transform: rotateY(264deg) translateZ(470px);
}
.yuanluo_1_24 {
    -webkit-transform: rotateY(276deg) translateZ(470px);
}
.yuanluo_1_25 {
    -webkit-transform: rotateY(288deg) translateZ(470px);
}
.yuanluo_1_26 {
    -webkit-transform: rotateY(300deg) translateZ(470px);
}
.yuanluo_1_27 {
    -webkit-transform: rotateY(312deg) translateZ(470px);
}
.yuanluo_1_28 {
    -webkit-transform: rotateY(324deg) translateZ(470px);
}
.yuanluo_1_29 {
    -webkit-transform: rotateY(336deg) translateZ(470px);
}
.yuanluo_1_30 {
    -webkit-transform: rotateY(348deg) translateZ(470px);
}
.yuanluo_1_31 {
    -webkit-transform: rotateY(360deg) translateZ(470px);
}
.yuanluo_canvas {
	/*margin: auto;
	left: 5px;
	right: 0;
	bottom: 56%;
	top: 0;*/
	/*width: 330px;*/
    -webkit-animation: page7_3 2.5s 2.5s ease-out both;
}
@keyframes page7_3 {
	0% {
	    -webkit-transform: translateY(-34vh);
	}
	100% {
	    -webkit-transform: translateY(0);
	}
}
</style>