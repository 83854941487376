<template>
    <div class="page po">
        <div class="page perspective">
            <div class="fangkuai-panorama_1" :style="{animationDuration: duration}">
				<template v-if="$util.typeOf(config[0]) == 'string'">
                	<div class="bg bg_photo page fangkuai-panorama_1_item fangkuai-panorama_1_top" :style="{backgroundImage: 'url('+ (comp['config'] ? comp['config'][0] : '') +')'}"></div>
				</template>
				<template v-else>
					<div class="page fangkuai-panorama_1_item fangkuai-panorama_1_top">
						<elem :mode="config[0]"></elem>
					</div>
				</template>

                <div class="bg bg_photo page fangkuai-panorama_1_item fangkuai-panorama_1_mid_1" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}">
	            </div>
	            <div class="bg bg_photo page fangkuai-panorama_1_item fangkuai-panorama_1_mid_2" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}">
	            </div>
	            <div class="bg bg_photo page fangkuai-panorama_1_item fangkuai-panorama_1_mid_3" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}">
	            </div>
	            <div class="bg bg_photo page fangkuai-panorama_1_item fangkuai-panorama_1_mid_4" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}">
	            </div>

				<template v-if="$util.typeOf(config[1]) == 'string'">
                	<div class="bg bg_photo page fangkuai-panorama_1_item fangkuai-panorama_1_bottom" :style="{backgroundImage: 'url('+ (comp['config'] ? comp['config'][1] : '') +')'}"></div>
				</template>
				<template v-else>
                	<div class="page fangkuai-panorama_1_item fangkuai-panorama_1_bottom">
						<elem :mode="config[1]"></elem>
					</div>
				</template>
            </div>
        </div>
		<div class="page perspective">
			<div class="fangkuai-panorama_2" :style="{animationDuration: duration}">
				<!-- <elem v-if="comp['elems']" :mode="comp['elems'][0]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem> -->
				
				
				<template v-if="config[3] == 'square2'">
					<div class="page fangkuai-square_1 preserve3d">
						<elem v-if="config[4]" :mode="config[4]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
						<Square class="square_1" :currentPhoto="currentPhoto" :mode="mode"></Square>
						<Square class="square_2" :currentPhoto="currentPhoto" :mode="mode"></Square>
					</div>
				</template>
				<template v-else-if="config[3] == 'square4'">
					<div class="page fangkuai-square_2 preserve3d">
						<Square class="square_1" :currentPhoto="currentPhoto" :mode="mode"></Square>
						<Square class="square_2" :currentPhoto="currentPhoto" :mode="mode"></Square>
					</div>
					<div class="page fangkuai-square_3 preserve3d">
						<elem v-if="config[4]" :mode="config[4]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
						<Square class="square_1" :currentPhoto="currentPhoto" :mode="mode"></Square>
						<Square class="square_2" :currentPhoto="currentPhoto" :mode="mode"></Square>
					</div>
				</template>
				<template v-else>
					<elem v-if="config[4]" :mode="config[4]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
				</template>
			</div>
		</div>
    </div>
</template>

<script>
	import Square from "./FangkuaiSquare"
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: 'Fangkuai',
		extends: BaseElem,
		components: {Square},
        data () {
            return {}
        },
		computed: {
			config () {
				return this.comp['config'] || [];
			},
			duration () {
				return this.comp['config'] && this.comp['config'][2] ? this.comp['config'][2] + 's' : '12s';
			}
		}
    }
</script>

<style>
    .fangkuai-panorama_1 {
        position: absolute;
		width: 500px;
		/* height: 500px; */
		height: 600px;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		transform: rotateY(0deg);
		transform-style: preserve-3d;
		animation: fangkuai_zhuan linear infinite both;
		/* animation-duration:  12s; */
    }
	.fangkuai-panorama_2 {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
	}
    @keyframes fangkuai_zhuan {
		0% {
		    transform: rotateY(0);
		}
		100% {
		    transform: rotateY(360deg);
		}
	}
	.fangkuai-fan_zhuan {
		transform-style: preserve-3d;
		animation: fangkuai_zhuan linear infinite both reverse;
	}
    .fangkuai-panorama_1_item {
        -webkit-transform-style: preserve-3d;
		-webkit-backface-visibility: hidden;
    }
    .fangkuai-panorama_1_top {
        transform: rotateX(-90deg) translateZ(-298px);
    }
    .fangkuai-panorama_1_bottom {
		-webkit-transform: rotateX(90deg) translateZ(-298px);
	}
    .fangkuai-panorama_1_mid_1 {
		-webkit-transform: translateZ(-248px);
	}
	.fangkuai-panorama_1_mid_2 {
		-webkit-transform: rotateY(-90deg) translateZ(-248px);
	}
	.fangkuai-panorama_1_mid_3 {
		-webkit-transform: rotateY(180deg) translateZ(-248px);
	}
	.fangkuai-panorama_1_mid_4 {
		-webkit-transform: rotateY(90deg) translateZ(-248px);
	}
	.fangkuai-panorama_1_item>canvas {
		width: 100%;
		height: 100%;
	}

	.square_1 {
		left: -175px;
		bottom: -250px;
	}
	.square_2 {
		right: -175px;
		bottom: -250px;
	}
	.fangkuai-square_1 {

	}
	.fangkuai-square_2 {
		transform: translate3d(0px,100px, 228px);
	}
	.fangkuai-square_3 {
		transform: translate3d(0px,0px, -500px);
	}
</style>