<template>
	<div class="zhuan3">		
		<div class="BX_Class28 BX_BG28 zhuan3BX_Ani28"></div>
		<div class="BX_Class27 BX_BG27 zhuan3BX_Ani27" :style="{backgroundImage: 'url('+mode[4]+')'}"></div>
		<div class="BX_AEComposition">
			<div class="BX_Class25 BX_BG25 zhuan3BX_Ani25 bg" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
			<div class="BX_Class24 BX_BG24 zhuan3BX_Ani24 bg" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
			<div class="BX_Class23 BX_BG23 zhuan3BX_Ani23 bg" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>

			<div class="BX_Class1 zhuan3BX_Ani1">
				<div class="BX_Class22 BG_CENTER BX_BG22 zhuan3BX_Ani22" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				<div class="BX_Class21 BX_BG21 zhuan3BX_Ani21" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class20 BX_BG20 zhuan3BX_Ani20" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class19 BG_CENTER BX_BG19 zhuan3BX_Ani19" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				<div class="BX_Class18 BX_BG18 zhuan3BX_Ani18" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class17 BX_BG17 zhuan3BX_Ani17" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class16 BG_CENTER BX_BG16 zhuan3BX_Ani16" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				<div class="BX_Class15 BX_BG15 zhuan3BX_Ani15" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class14 BX_BG14 zhuan3BX_Ani14" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class13 BG_CENTER BX_BG13 zhuan3BX_Ani13" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				<div class="BX_Class12 BX_BG12 zhuan3BX_Ani12" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class11 BX_BG11 zhuan3BX_Ani11" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class10 BG_CENTER BX_BG10 zhuan3BX_Ani10" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				<div class="BX_Class9 BX_BG9 zhuan3BX_Ani9" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class8 BX_BG8 zhuan3BX_Ani8" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class7 BG_CENTER BX_BG7 zhuan3BX_Ani7" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				<div class="BX_Class6 BX_BG6 zhuan3BX_Ani6" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class5 BX_BG5 zhuan3BX_Ani5" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
				<div class="BX_Class4 BG_CENTER BX_BG4 zhuan3BX_Ani4" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
				<div class="BX_Class3 BX_BG3 zhuan3BX_Ani3" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
				<div class="BX_Class2 BX_BG2 zhuan3BX_Ani2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'zhuan3',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.BG_CENTER {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.BX_AEComposition{
	    background: transparent;
	    width:@bw * 1.0;
	    height:@bw * 1.778;
	    display: block;
	    overflow: hidden;
	    position: relative;
	    transform: translate3d(0,0,0) scale(1,1);
	    transform-origin:50% 0%;
	    perspective:@bw * 2.083;
	    perspective-origin: 50% 50%;
	}
	.BX_Class28{
	    left:@bw * 0.278;
	    top:@bw * 0.539;
	    width:@bw * 0.444;
	    height:@bw * 0.7;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(2.25,2.54);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class27{
	    left:@bw * 0.056;
	    top: -@bw * 0.367;
	    width:@bw * 0.867;
	    height:@bw * 0.85;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(3.51,3.28);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class26{
	    left:@bw * 1.146;
	    top:@bw * 0.475;
	    width:@bw * 0.34;
	    height:@bw * 0.394;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.75,1.75);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class25{
	    left:@bw * 0.151;
	    top:@bw * 1.608;
	    width:@bw * 0.157;
	    height:@bw * 0.128;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(-3.00deg) scale(1.39,1.39);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG25{
	}
	.zhuan3BX_Ani25{
	    animation:zhuan3BX_AniKey25 13.00s linear 0.00s infinite normal none;
	}
	@keyframes zhuan3BX_AniKey25{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.00deg) scale(1.38938053097345,1.38938053097345);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-0.40deg) scale(1.38938053097345,1.38938053097345);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.20deg) scale(1.38938053097345,1.38938053097345);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(1.20deg) scale(1.38938053097345,1.38938053097345);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.40deg) scale(1.38938053097345,1.38938053097345);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-2.00deg) scale(1.38938053097345,1.38938053097345);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.60deg) scale(1.38938053097345,1.38938053097345);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.85deg) scale(1.38938053097345,1.38938053097345);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.90deg) scale(1.38938053097345,1.38938053097345);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.05deg) scale(1.38938053097345,1.38938053097345);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.00deg) scale(1.38938053097345,1.38938053097345);}
	}
	.BX_Class24{
	    left:@bw * 0.429;
	    top:@bw * 1.667;
	    width:@bw * 0.157;
	    height:@bw * 0.128;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(-3.00deg) scale(1.26,1.26);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.zhuan3BX_Ani24{
		animation:BX_AniKey24 13.00s linear 0.00s infinite normal none;
	}
	@keyframes zhuan3BX_AniKey24{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.00deg) scale(1.25894574836476,1.25894574836476);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-0.40deg) scale(1.25894574836476,1.25894574836476);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.20deg) scale(1.25894574836476,1.25894574836476);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(1.20deg) scale(1.25894574836476,1.25894574836476);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.40deg) scale(1.25894574836476,1.25894574836476);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-2.00deg) scale(1.25894574836476,1.25894574836476);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.60deg) scale(1.25894574836476,1.25894574836476);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.85deg) scale(1.25894574836476,1.25894574836476);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.90deg) scale(1.25894574836476,1.25894574836476);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.05deg) scale(1.25894574836476,1.25894574836476);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.00deg) scale(1.25894574836476,1.25894574836476);}
	}
	.zhuan3BX_Ani23{
		animation:BX_AniKey23 13.00s linear 0.00s infinite normal none;
	}
	.BX_Class23{
	    left:@bw * 0.776;
	    top:@bw * 1.572;
	    width:@bw * 0.157;
	    height:@bw * 0.128;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(-3.00deg) scale(1.01,1.09);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	@keyframes zhuan3BX_AniKey23{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.00deg) scale(1.01115813774529,1.08503270488649);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-0.40deg) scale(1.01115813774529,1.08503270488649);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.20deg) scale(1.01115813774529,1.08503270488649);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(1.26deg) scale(1.01115813774529,1.08503270488649);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.26deg) scale(1.01115813774529,1.08503270488649);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-2.27deg) scale(1.01115813774529,1.08503270488649);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.09deg) scale(1.01115813774529,1.08503270488649);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.45deg) scale(1.01115813774529,1.08503270488649);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(1.33deg) scale(1.01115813774529,1.08503270488649);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-0.83deg) scale(1.01115813774529,1.08503270488649);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.00deg) scale(1.01115813774529,1.08503270488649);}
		}
	.BX_Class22{
	    left: -@bw * 0.417;
	    top:@bw * 0.321;
	    width:@bw * 0.417;
	    height:@bw * 0.415;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.24) rotateX(-0.00deg) rotateY(120.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
		position: absolute; 
		border-radius: 50%;
		background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class21{
	    left: -@bw * 0.447;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.239) rotateX(-0.00deg) rotateY(120.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class20{
	    left: -@bw * 0.451;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.242) rotateX(-0.00deg) rotateY(120.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class19{
	    left: -@bw * 0.421;
	    top:@bw * 0.321;
	    width:@bw * 0.417;
	    height:@bw * 0.415;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.243) rotateX(-0.00deg) rotateY(60.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
		position: absolute; 
		border-radius: 50%;
		background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class18{
	    left: -@bw * 0.451;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.24) rotateX(-0.00deg) rotateY(60.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class17{
	    left: -@bw * 0.457;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.244) rotateX(-0.00deg) rotateY(60.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class16{
	    left: -@bw * 0.0;
	    top:@bw * 0.321;
	    width:@bw * 0.417;
	    height:@bw * 0.415;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.486) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
		position: absolute; 
		border-radius: 50%;
		background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class15{
	    left: -@bw * 0.033;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.483) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class14{
	    left: -@bw * 0.033;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.489) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class13{
	    left:@bw * 0.419;
	    top:@bw * 0.321;
	    width:@bw * 0.417;
	    height:@bw * 0.415;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.242) rotateX(-0.00deg) rotateY(-60.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
		position: absolute; 
		border-radius: 50%;
		background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class12{
	    left:@bw * 0.382;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.239) rotateX(-0.00deg) rotateY(-60.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class11{
	    left:@bw * 0.389;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.243) rotateX(-0.00deg) rotateY(-60.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class10{
	    left:@bw * 0.419;
	    top:@bw * 0.321;
	    width:@bw * 0.417;
	    height:@bw * 0.415;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.242) rotateX(-0.00deg) rotateY(-120.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
		position: absolute; 
		border-radius: 50%;
		background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class9{
	    left:@bw * 0.382;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.24) rotateX(-0.00deg) rotateY(-120.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class8{
	    left:@bw * 0.388;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.243) rotateX(-0.00deg) rotateY(-120.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class7{
	    left: -@bw * 0.0;
	    top:@bw * 0.321;
	    width:@bw * 0.417;
	    height:@bw * 0.415;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.482) rotateX(-0.00deg) rotateY(180.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
		position: absolute; 
		border-radius: 50%;
		background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class6{
	    left: -@bw * 0.033;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.474) rotateX(-0.00deg) rotateY(180.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class5{
	    left: -@bw * 0.033;
	    top:@bw * 0.288;
	    width:@bw * 0.483;
	    height:@bw * 0.483;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.488) rotateX(-0.00deg) rotateY(180.00deg) rotateZ(0.00deg) scale3d(0.51,0.51,0.51);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class4{
	    left: -@bw * 0.0;
	    top: -@bw * 0.207;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(-30.00deg) rotateZ(0.00deg) scale3d(1.40,1.40,1.40);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
		position: absolute; 
	}
	.BX_Class3{
	    left: -@bw * 0.039;
	    top: -@bw * 0.242;
	    width:@bw * 0.488;
	    height:@bw * 0.694;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.007) rotateX(-0.00deg) rotateY(-30.00deg) rotateZ(0.00deg) scale3d(1.40,1.40,1.40);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class2{
	    left: -@bw * 0.039;
	    top: -@bw * 0.242;
	    width:@bw * 0.488;
	    height:@bw * 0.694;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.007) rotateX(-0.00deg) rotateY(-30.00deg) rotateZ(0.00deg) scale3d(1.40,1.40,1.40);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
	}
	.BX_Class1{
	    left:@bw * 0.292;
	    top:@bw * 0.492;
		top: 50%;
		margin-top: -@bw * 0.208;
	    width:@bw * 0.417;
	    height:@bw * 0.417;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(30.00deg) rotateZ(0.00deg) scale3d(1.20,1.20,1.20);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG1{
	    background: rgb(16, 255, 0);
	}
	.zhuan3BX_Ani1{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:zhuan3BX_AniKey1 13.00s linear 0.00s infinite normal none;
	}
	@keyframes zhuan3BX_AniKey1{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(30.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(66.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(102.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(138.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(174.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(210.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(246.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(282.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(318.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(354.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(390.00deg) rotateZ(0.00deg) scale3d(1.2,1.2,1.2);}
	}
</style>