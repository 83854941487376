<template>
	<div class="page">
	    <div class="litiquanzhuan_album_preview">
	        <div class="litiquanzhuan_album flex_r">
	            <div class="litiquanzhuan_item litiquanzhuan_item_1">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	            <div class="litiquanzhuan_item litiquanzhuan_item_2">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	            <div class="litiquanzhuan_item litiquanzhuan_item_3">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	            <div class="litiquanzhuan_item litiquanzhuan_item_4">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	            <div class="litiquanzhuan_item litiquanzhuan_item_5">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	            <div class="litiquanzhuan_item litiquanzhuan_item_6">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	            <div class="litiquanzhuan_item litiquanzhuan_item_7">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	            <div class="litiquanzhuan_item litiquanzhuan_item_8">
	                <div class="litiquanzhuan_userImage"
	                :style="{'backgroundImage': 'url('+ currentPhoto['im'] +')'}">
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'Litiquanzhuan',
		extends: BaseElem,
		data () {
			return {}
		},
		computed: {},
        created () {}
	}
</script>

<style>
	.yhxc_jingmian_gai {
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    overflow: hidden;
	}
	.litiquanzhuan_album {
	    position: relative;
	    width: 210px;
	    height: 238px;
	    margin: auto;
	    transform-style: preserve-3d;
	    transform: rotateX(-9deg) rotateY(45deg);
	    transform-origin: 50% 50%;
	    animation: litiquanzhuan_rotate_box 30s linear 1.5s infinite alternate both;
	    left: 50%;
	    margin-left: -105px
	}
	@keyframes litiquanzhuan_rotate_box {
	    0% {
	        transform: rotateX(-9deg) rotateY(0deg)
	    }
	    100% {
	        transform: rotateX(-9deg) rotateY(720deg)
	    }
	}
	.litiquanzhuan_item {
	    position: absolute;
	    /*bottom: 0;*/
	    /*left: 0;*/
	    width: 161px;
	    height: 245px;
	    overflow: hidden;
	}
	.litiquanzhuan_item_1 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg1Ani 1s ease forwards 0.8s;
	}
	@keyframes litiquanzhuan_img_bg1Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(0deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_item_2 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg2Ani 1s ease forwards 0.7s;
	}
	@keyframes litiquanzhuan_img_bg2Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(45deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_item_3 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg3Ani 1s ease forwards 0.6s;
	}
	@keyframes litiquanzhuan_img_bg3Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(90deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_item_4 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg4Ani 1s ease forwards 0.5s;
	}
	@keyframes litiquanzhuan_img_bg4Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(135deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_item_5 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg5Ani 1s ease forwards 0.4s;
	}
	@keyframes litiquanzhuan_img_bg5Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(180deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_item_6 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg6Ani 1s ease forwards 0.3s;
	}
	@keyframes litiquanzhuan_img_bg6Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(225deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_item_7 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg7Ani 1s ease forwards 0.2s;
	}
	@keyframes litiquanzhuan_img_bg7Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(270deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_item_8 {
	    transform: rotateY(0deg) translateZ(0px);
	    animation: litiquanzhuan_img_bg8Ani 1s ease forwards 0.1s;
	}
	.litiquanzhuan_userImage {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background-position: center;
	    background-size: contain;
	    background-repeat: no-repeat;
	}
	@keyframes litiquanzhuan_img_bg8Ani {
	    0% {
	        transform: rotateY(0deg) translateZ(0px);
	    }
	    100% {
	        transform: rotateY(315deg) translateZ(210px);
	    }
	}
	.litiquanzhuan_album_preview {
	    width: 100%;
	    height: 100%;
	    display: flex;
	    perspective: 420px;
	    overflow: hidden
	}
</style>