<template>
    <div class="qianyidong_box">
        <div class="qianyidong_bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
        </div>
        <div class="qianyidong_block">
            <!-- 1 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene1">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 2 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene2">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 3 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene3">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 4 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene4">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 5 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene5">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 6 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene6">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 7 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene7">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 8 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene8">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 9 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene9">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
            <!-- 10 -->
            <div class="qianyidong_page-block qianyidong_scene qianyidong_scene10">
                <div class="scene-lit">
                    <div class="userImg_left">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-left border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                    <div class="userImg_right">
                        <div class="userImg" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                        <img class="lit-right border" src="http://resali.lititutu.cn/icons/border/01.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'qianyidong',
        props: ['mode', 'currentImage'],
        data () {
            return {}
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage)
            }
        }
    }
</script>

<style scoped lang="less">
    .qianyidong_box {
        width: 100%;
        height: 100%;
    }
    .qianyidong_bg {
        width: 100%;
        height: 100%;
        position: absolute;
        background: no-repeat center;
        background-size: cover;
    }
    .qianyidong_page-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        overflow: hidden;
    }
    .qianyidong_scene {
        transform-style: preserve-3d;
        perspective: 2000px;
        overflow: hidden;
        opacity: 1;
    }
    .qianyidong_scene1 {
        transform: translateZ(100px);
        animation: qianyi 3s normal forwards running 1;
    }
    .qianyidong_scene2 {
        transform: translateZ(-400px);
        animation: qianyi 9s normal forwards running 1;
    }
    .qianyidong_scene3 {
        transform: translateZ(-900px);
        animation: qianyi 11s normal forwards running 1;
    }
    .qianyidong_scene4 {
        transform: translateZ(-1100px);
        animation: qianyi 13s normal forwards running 1;
    }
    .qianyidong_scene5 {
        transform: translateZ(-1500px);
        animation: qianyi 15s normal forwards running 1;
    }
    .qianyidong_scene6 {
        transform: translateZ(-1900px);
        animation: qianyi 17s normal forwards running 1;
    }
    .qianyidong_scene7 {
        transform: translateZ(-2300px);
        animation: qianyi 21s normal forwards running 1;
    }
    .qianyidong_scene8 {
        transform: translateZ(-2700px);
        animation: qianyi 25s normal forwards running 1;
    }
    .qianyidong_scene9 {
        transform: translateZ(-3100px);
        animation: qianyi 29s normal forwards running 1;
    }
    .qianyidong_scene10 {
        transform: translateZ(-3500px);
        animation: qianyi 33s normal forwards running 1;
    }
    .qianyidong_block {
        width: 500px;
        height: 100%;
        left: 0;
        top: 0;
        transform-style: preserve-3d;
        perspective: 500px;
        position: absolute;
    }
    .scene-lit {
        position: absolute;
        width: 500px;
        height: 430px;
        transform: translateZ(0);
        top: 210px;
    }
    .userImg_left {
        width: 400px;
        height: 620px;
        transform: rotateY(15deg);
        left: -60px;
        position: absolute;
    }
    .qianyidong_scene1 .userImg_left {
        animation: translateZAni_left 5s linear 1 forwards;
    }
    .qianyidong_scene1 .userImg_right {
        animation: translateZAni_right 5s linear 1 forwards;
    }
    .qianyidong_scene2 .userImg_left {
        animation: translateZAni_left 5s 1s linear 1 forwards;
    }
    .qianyidong_scene2 .userImg_right {
        animation: translateZAni_rightOdd 5s 1s linear 1 forwards;
    }
    .qianyidong_scene3 .userImg_left {
        animation: translateZAni_leftOdd 5s 2.4s linear 1 forwards;
    }
    .qianyidong_scene3 .userImg_right {
        animation: translateZAni_right 5s 2.4s linear 1 forwards;
    }
    .qianyidong_scene4 .userImg_left {
        animation: translateZAni_left 5s 3s linear 1 forwards;
    }
    .qianyidong_scene4 .userImg_right {
        animation: translateZAni_rightOdd 6s 3s linear 1 forwards;
    }
    .qianyidong_scene5 .userImg_left {
        animation: translateZAni_leftOdd 6s 5s linear 1 forwards;
    }
    .qianyidong_scene5 .userImg_right {
        animation: translateZAni_right 6s 5s linear 1 forwards;
    }
    .qianyidong_scene6 .userImg_left {
        animation: translateZAni_left 6s 7s linear 1 forwards;
    }
    .qianyidong_scene6 .userImg_right {
        animation: translateZAni_rightOdd 6s 7s linear 1 forwards;
    }
    .qianyidong_scene7 .userImg_left {
        animation: translateZAni_leftOdd 6s 10s linear 1 forwards;
    }
    .qianyidong_scene7 .userImg_right {
        animation: translateZAni_right 6s 10s linear 1 forwards;
    }
    .qianyidong_scene8 .userImg_left {
        animation: translateZAni_left 7s 16s linear 1 forwards;
    }
    .qianyidong_scene8 .userImg_right {
        animation: translateZAni_rightOdd 8s 16s linear 1 forwards;
    }
    .qianyidong_scene9 .userImg_left {
        animation: translateZAni_leftOdd 8s 18s linear 1 forwards;
    }
    .qianyidong_scene9 .userImg_right {
        animation: translateZAni_right 8s 18s linear 1 forwards;
    }
    .qianyidong_scene10 .userImg_left {
        animation: translateZAni_left 8s 19s linear 1 forwards;
    }
    .qianyidong_scene10 .userImg_right {
        animation: translateZAni_rightOdd 8s 19s linear 1 forwards;
    }
    .userImg_right {
        width: 400px;
        height: 620px;
        transform: rotateY(15deg);
        right: -60px;
        position: absolute;
    }
    .lit-left {
        display: block;
        position: absolute;
    }
    .lit-right {
        display: block;
    }
    .userImg {
        -webkit-clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
        -moz-clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
        clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
        transition: 1s clip-path;
        width: 200px;
        height: 200px;
        top: 63px;
        left: 58px;
        position: absolute;
        background: no-repeat center;
        background-size: cover;
    }
    .litflower {
        position: absolute
    }
    .litflower1 {
        bottom: 420px;
        left: 46px;
        width: 79px;
        animation: litflowerRotate 5s linear infinite;
    }
    .litflower2 {
        bottom: 257px;
        left: 176px;
        width: 39px;
        animation: litflowerRotate 5.5s linear infinite;
    }
    .litflower3 {
        bottom: 307px;
        right: 57px;
        width: 79px;
        animation: litflowerRotate 5.8s linear infinite;
    }
    @keyframes qianyi {
        80% {
            opacity: 1
        }
        90% {
            opacity: 0
        }
        100% {
            transform: translateZ(240px);
            opacity: 0
        }
    }
    @keyframes translateZAni_left {
        85% {
            opacity: 0;
        }
        100% {
            transform: rotateY(95deg);
            opacity: 0;
        }
    }
    @keyframes translateZAni_right {
        85% {
            opacity: 0;
        }
        100% {
            transform: rotateY(-95deg);
            opacity: 0;
        }
    }
    @keyframes translateZAni_leftOdd {
        60% {
            transform: translateX(0);
        }
        70% {
            transform: translateX(190px) scale(1.5) translateY(18px);
        }
        95% {
            transform: translateX(216px) scale(1.8) translateY(48px);
        }
        100% {
            transform: rotateY(85deg);
        }
    }
    @keyframes translateZAni_rightOdd {
        60% {
            transform: translateX(0);
        }
        70% {
            transform: translateX(-106px) scale(1.5) translateY(18px);
        }
        95% {
            transform: translateX(-176px) scale(1.8) translateY(48px);
        }
        100% {
            transform: rotateY(-85deg);
        }
    }
</style>