<template>
	<div id="model" ref="model">
		<div class="theme-title__container theme-show">
			<span class="theme-title fadeHalf" :class="{'title-move': currentTitle.length >= 12}" :style="{
				'animationDuration': currentTitle.length + 's'
			}">{{currentTitle}}</span>
		</div>
		<div id="room" class="room" @touchstart="down" @touchmove="move" @touchend="up">
			<div class="stage" :style="{ transform: 'rotateY('+rotateDeg+'deg)' }">
				<div v-for="(image, index) in timages" class="item" :style="{transform: getItemTransform(index), backgroundImage: 'url('+ mode['bg'] +')'}">

					<template v-if="mode['mode'] && mode['mode']['box'] && mode['mode']['box']['elems']">
						<elem v-for="item in mode['mode']['box']['elems']" :mode="item"></elem>
					</template>

					<template v-if="page && page[index] && page[index]['elems']">
						<elem v-for="item in page[index]['elems']" :mode="item"></elem>
					</template>
					
					<div class="exhi-box poc" :class="{
						'fadeOut': !imageShow,
						'fadeIn': imageShow
					}">
						<div class="photo-box" @click="wxPreview(image)">

							<div class="photo-cont" :style="getPhotoStyle(index)">
								<img class="photo-image" :src="image['im']" :style="borderStyle">
							</div>

							<UserText 
								:mode="mode['mode'] || {}" 
								:currentText="image['tx']" from="exhi"
								:lineType="1"></UserText>
						</div>
					</div>

				</div>
			</div>
		</div>

		<!-- decorate -->
		<template v-if="mode['mode']">
			<template v-if="mode['mode']['decorate']">
				<template v-for="(decorate, decorate_id) in mode['mode']['decorate']">
					<template v-if="decorate_id == 'gif'">
						<template v-for="item in decorate">
							<gif :mode="item"></gif>
						</template>
					</template>
					<template v-else-if="decorate_id == 'elems'">
						<template v-for="item in decorate">
							<elem :mode="item"></elem>
						</template>
					</template>
					<template v-else>
						<component :is="decorate_id" :mode="decorate"></component>
					</template>
				</template>
			</template>
		</template>
	</div>
</template>

<script>
	/* eslint-disable */
	
	import UserText from '@/components/UserText'

	let G = {
		imagesIndent: 6,
		images: []
	}

	export default {
		name: 'exhi',
		data () {
			return {
				// box: [260, 360],
				rotateDeg: 0,
				imageShow: true,
				timages: [],

				drag: false,
				speed: .5,
				r: 0,
				brake: 1,
				o: null,
				x: null,
				time: null,

				imageIdx: 0,

				// loading: false
			}
		},
		props: ['mode'],
		components: {
			UserText,
		},
		computed: {
			box () {
				return this.mode['box'] ? this.mode['box'] : [260, 360];
			},
			page () {
				return this.mode['mode'] && this.mode['mode']['box'] && this.mode['mode']['box']['page'];
			},
			currentTitle () {
				return this.$store.state.config['data']['ti'] || this.mode['name'];
			},
			photoMode () {
				let mode = {};
				if (this.mode['mode'] && this.mode['mode']['photo']) {
					mode = this.mode['mode']['photo'];
				}
				return mode;
			},
			borderStyle () {
				let style = {
					"borderStyle": "solid",
					"borderWidth": "4px",
					"borderColor": "#fff",
					"boxShadow": "0 7px 10.5px rgba(104, 104, 104, 0.6)"
				};

				if (!this.photoMode['border']) return style;

				let border = this.mode['mode']['photo']['border'];

				if (border['width']) style['borderWidth'] = border['width']+'px';
				if (border['color']) style['borderColor'] = border['color'];

				if (border['im']) {
					style['borderImageSource'] = 'url(' + border['im'] + ')';
			        style['borderImageSlice'] = border['imageSlice'];
			        style['borderImageWidth'] = border['imageWidth'];
			        style['borderImageOutset'] = border['imageOutset'];
			        style['borderImageRepeat'] = border['imageRepeat'];
			        if (border['borderImageWidth']) {
			        	style['borderImageWidth'] = border['imageWidth'];
			        }

			        delete style['boxShadow'];
			        delete style['borderColor'];

				}

				return style;
			},
			
		},
		created () {
			G.images = this.$o_util.getImagesArray(this.$store.state.config.images, G.imagesIndent);

			this.imageIdx = G.images.length * 1000000000;

			let next_timages;

			[this.timages, next_timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);

			// 加载下一组照片
			this._preloadNextPhotos(next_timages);

		},
		mounted () {
			this.$refs.model.addEventListener('touchmove', function(e) {
				e.preventDefault();
			})
			
			setTimeout(() => {
				this.spin();
			}, 1200);
		
		},
		methods: {
			getItemTransform (idx) {
				let angle = idx * ( 360 / G.imagesIndent ),
					distance = 250;
				return 'rotateY(' + angle + 'deg) translateZ(' + (distance) + 'px)';
			},
			getPhotoStyle (idx) {
				let contain_size = this.__getContainSize(idx);
				let border_width = parseInt(this.borderStyle['borderWidth']);

				let s = {
					'width': contain_size[0] + 'px',
					'height': contain_size[1] + 'px',
				};

				let borderStyle = this.borderStyle;
				if (borderStyle['borderWidth']) {
					s['marginLeft'] = '-' + ( (parseInt(borderStyle['borderWidth']) * 2 ).toString() ) + 'px';
					s['marginBottom'] = ( parseInt(borderStyle['borderWidth']) * 2 ).toString() + 'px';
				}

				if (this.photoMode['style']) {
					s = Object.assign(s, this.photoMode['style']);
				}

				return s;
			},
			getTextStyle (idx) {
				let style = {
					"bottom": "-100px",
					"fontSize": "19px",
					"textShadow": "rgb(36 48 73) 1px 1px 0px, rgb(36 48 73) -1px 1px 0px, rgb(36 48 73) 1px -1px 0px, rgb(36 48 73) -1px -1px 0px"
				};

				if (!this.mode['mode']) return style;
				if (!this.mode['mode']['text']) return style;

				let text = this.mode['mode']['text'];

				if (text['fontSize']) style['fontSize'] = text['fontSize'] + 'px';

				if (text['textShadowColor']) {
					style['textShadow'] = text['textShadowColor']+" 1px 1px 0px, "+text['textShadowColor']+" -1px 1px 0px, "+text['textShadowColor']+" 1px -1px 0px, "+text['textShadowColor']+" -1px -1px 0px";
				}

				return style;
			},
			__getContainSize (idx) {
				let image = this.timages[idx];
				let size = image['orisize'];
				if (!size) {
					size = this.$o_util.getImageDataById(image['id'], this.$store.state.config.images)['orisize'];
				}
				if (!size) {
					return {
						width: '250px',height: '250px',top: '50%',left: '50%',marginTop: '-125px',marginLeft: '-125px',backgroundImage: 'url('+ image['im'] +')'
					}
				}
				let contain_size = this.$o_util.getContainSize(size, this.box)
				return [contain_size.width, contain_size.height];
			},
			wxPreview (image) {
				this.$emit('wxPreview', image);
			},
			spin () {
				if ( !this.drag ) {

					if ( this.r * 180 / 400 < -360 || this.r * 180 / 400 > 360 ) {
						this.r = 0;
						if (G.images.length > 1) {
							this.imageShow = false;
							setTimeout(() => {
								let timages = [];
								let next_timages;
								[timages, next_timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);

								// 预加载下一张用户图片
								this._preloadNextPhotos(next_timages);

								for (let j=0,jlens=timages.length; j<jlens; j++) {
									this.$set(this.timages, j, timages[j]);
								}
								setTimeout(() => {
									this.imageShow = true;
								}, 200)
							}, 500);
						}
					}

					if ( this.speed < .5 && this.speed > 0 ) {
						this.speed = .5;
					} else if ( this.speed > -.5 && this.speed < 0 ) {
						this.speed = -.5;
					}

					this.r += this.speed;
					this.speed /= this.brake;
					
					let ry = 0 - (this.r * 180 / 400);

					this.rotateDeg = ry;
				}
				requestAnimationFrame(this.spin);
			},
			down (event) {
				this.o = this.r;
				this.x = this.r + event.touches[0].clientX;
				this.drag = true;
				this.time = new Date();
			},
			move (event) {
				if (this.drag) {
					let ry = 0 - (this.r * 180 / 400);
					this.r = this.x - event.touches[0].clientX;
					this.rotateDeg = ry;
				}
			},
			up (event) {
				if (this.drag) {
					let time = new Date() - this.time;
					let path = this.r - this.o;
					this.speed = path / time * 5;
					this.brake = 1.01;
					this.drag = false;
				}
			},
			// 预加载下一张用户图片
			_preloadNextPhotos (photos) {

				let _this = this;

				for (let i=0; i<photos.length; i++) {

					let photo = photos[i];

					let im = new Image();
					
					im.onload = (function(_photo) {
						return function() {
							_this.$store.commit('setImageOriSize', {'imageid': _photo['id'], 'orisize': [this.width, this.height]});
						}
					})(photo);
					im.onerror = (function(_photo) {
						return function() {
							_this.$store.commit('setImageOriSize', {'imageid': _photo['id'], 'orisize': [500, 500]});
						}
					})(photo);

					im.src = photo['im'];
				}

			}
		}
	}
</script>

<style scoped lang="less">
	.exhi-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.room {
		position: absolute;
		width: 100%;
		height: 100%;
		perspective-origin: 50% 50%;
		perspective:@bw * 3.056;
		overflow: hidden;
		/*background-color: #891224;*/
		/*background-color: #3e1219;*/
		background-color: #575650;
	}
	.stage {
		position: absolute;
		width: 292px;
		height: 100vh;
		top: 50%;
		left: 50%;
		margin-top: -50vh;
		margin-left: -@bw * 0.292;
		transform-style: preserve-3d;
	}
	.item {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		// display: flex;
		// flex-direction: column;
		// align-items: center;
		// justify-content: center;
		// background-size: 100% 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.photo-box {
		position: relative;
		// position: absolute;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		/*border: dashed@bw * 0.004 red;*/
	}
	.photo-frame {
		position: relative;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		transition: opacity .5s linear;
	}
	.photo-text {
		position: absolute;
		width: 292px;
	    text-align: center;
	    color: white;
	    font-weight: bold;
	    height: 65px;
	    color: #fff;
	    text-align: center;
	    font-weight: 700;
	    z-index: 8;
	    border-radius: 5px;
	    line-height: 30px;
        letter-spacing: 1px;
	}
	.photo-border {
		position: absolute;
		width: 110%;
		height: 110%;
		left: -5%;
		top: -5%;
	}

	// .exhi-images-leave {
	// 	opacity: 1;
	// }
	// .exhi-images-leave-to {
	// 	opacity: 0;
	// }
	// .exhi-images-enter {
	// 	opacity: 0;
	// }
	// .exhi-images-enter-to {
	// 	opacity: 1;
	// }
	
	.exhi-images-enter-active, .exhi-images-leave-active {
		transition: opacity .3s;
	}
	.exhi-images-enter, .exhi-images-leave-to {
		opacity: 0;
	}

	.photo-cont {
		position: relative;
	}
	.photo-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.theme-title__container {
	    position: fixed;
	    left: 50%;
	    overflow: hidden;
	    width: 346px;
	    height: 60px;
	    line-height: 60px;
	    margin-left: -173px;
	    opacity: 0;
	    z-index: 9;
	    top: 23px;
	}
	.theme-show {
	    animation: fadeIn 1s both ease-in-out;
	}
	.theme-title {
	    position: absolute;
	    top: 0;
	    left: 0;
	    color: white;
	    font-size: 28px;
	    letter-spacing: 1px;
	    text-align: center;
	    margin: 0 auto;
	    white-space: nowrap;
	    font-weight: bolder;
	    pointer-events: none;
	    min-width: 100%;
	    text-shadow: #000 3px 3px 3px;
	}
	.title-move {
		animation: title_move infinite linear;
	}
	@keyframes title_move {
		from {
			-webkit-transform: translateX(30%);
		}
		to {
			-webkit-transform: translateX(-100%);
		}
	}
	
</style>