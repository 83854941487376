<template>
	<div class="poc yuanzhuanshu">
		<div class="yuanzhuanshu_per po" :style="getStyle">
		    <div class="yuanzhuanshu_div2 poc preserve3d">
		        <div class="yuanzhuanshu_div2_1 preserve3d poc">
		            <div class="yuanzhuanshu_div_2 preserve3d poc">
		                <div class="yuanzhuanshu_rim1 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		                <div class="yuanzhuanshu_border_t yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_border_b yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_rim9 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		            </div>
		        </div>
		        <div class="yuanzhuanshu_div2_2 preserve3d poc">
		            <div class="yuanzhuanshu_div_2 preserve3d poc">
		                <div class="yuanzhuanshu_rim1 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		                <div class="yuanzhuanshu_border_t yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_border_b yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_rim9 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		            </div>
		        </div>
		        <div class="yuanzhuanshu_div2_3 preserve3d poc">
		            <div class="yuanzhuanshu_div_2 preserve3d poc">
		                <div class="yuanzhuanshu_rim1 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		                <div class="yuanzhuanshu_border_t yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_border_b yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_rim9 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		            </div>
		        </div>
		        <div class="yuanzhuanshu_div2_4 preserve3d poc">
		            <div class="yuanzhuanshu_div_2 preserve3d poc">
		                <div class="yuanzhuanshu_rim1 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		                <div class="yuanzhuanshu_border_t yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_border_b yuanzhuanshu_border poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
		                <div class="yuanzhuanshu_rim9 poc bg" :style="{backgroundImage: 'url('+ mode[1] +')'}">
		                	<div class="yuanzhuanshu_userimg bg po" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
		                    
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'yuanzhuanshu',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
		computed: {
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [436, 436], 'widthFix');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px'
					}
				} else {
					return {
						width: '436px',
						height: '331px'
					}
				}
			}
		}
	}
</script>

<style>
	.yuanzhuanshu_per {
		width: 436px;
		height: 331px;
	    -webkit-perspective: 720px;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 30px;
	}
	.yuanzhuanshu_div2 {
	    /*-webkit-transform: translateZ(-245.5px);
		-webkit-transform: translateZ(-250px) translateZ(0px);*/
		-webkit-animation: yuanzhuanshu_div2 24s linear infinite both;
		/*-webkit-transform-style: preserve-3d;*/
	}
	@keyframes yuanzhuanshu_div2 {
		0% {
		    -webkit-transform: translateZ(-245.5px) rotateX(0);
		}
		100% {
		    -webkit-transform: translateZ(-245.5px) rotateX(-360deg);
		}
	}
	.yuanzhuanshu_div2_1 {
		-webkit-transform-origin: bottom;
		-webkit-transform: translateY(-165.5px) rotateX(0);
		-webkit-transform: translateY(-50%) rotateX(0);
	}
	.yuanzhuanshu_div2_2 {
		-webkit-transform-origin: bottom;
		-webkit-transform: translateY(-165.5px) rotateX(90deg);
		-webkit-transform: translateY(-50%) rotateX(90deg);
	}
	.yuanzhuanshu_div2_3 {
		-webkit-transform-origin: bottom;
		-webkit-transform: translateY(-165.5px) rotateX(180deg);
		-webkit-transform: translateY(-50%) rotateX(180deg);
	}
	.yuanzhuanshu_div2_4 {
		-webkit-transform-origin: bottom;
		-webkit-transform: translateY(-165.5px) rotateX(270deg);
		-webkit-transform: translateY(-50%) rotateX(270deg);
	}
	.yuanzhuanshu_div_2 {
		-webkit-transform: rotateX(90deg) translateZ(80px);
	}
	.yuanzhuanshu_rim1 {
		-webkit-transform: translateZ(-14px);
	}
	.yuanzhuanshu_border_t {
		top: 0;
    	-webkit-transform: translateY(-14px) translateZ(0) rotateX(90deg);
	}
	.yuanzhuanshu_border_b {
		bottom: 0;
    	-webkit-transform: translateY(14px) translateZ(0) rotateX(-90deg);
	}
	.yuanzhuanshu_border {
		width: 100%;
		height: 28px;
		/*background-image: url(http://resali.lititutu.cn/scenes/zuimeixiahua3d/83197598E40E153357A92349A1C80036.jpeg);*/
		background-size: 100% auto;
		background-position: 0 0;
		backface-visibility: hidden;
	}
	.yuanzhuanshu_rim9 {
		-webkit-transform: translateZ(14px);
	}
	.yuanzhuanshu_bg {
		/*background-image: url(http://resali.lititutu.cn/scenes/zuimeixiahua3d/83197598E40E153357A92349A1C80036.jpeg);*/
		background-size: 100% 100%;
	}
	.yuanzhuanshu_userimg {
		width: calc(100% - 16px);
		height: calc(100% - 16px);
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #eee;
	}
</style>



