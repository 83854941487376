class stGlobalData {
    constructor(util) {
      this.$util = util;
      this.globalData = {};
    }
    set = (key, val) => {
      this.globalData[key] = val;
    }
    get = (key, defval) => {
      let val = this.globalData[key];
      return val === undefined ? defval : val;
    }
    // getter方法获取当下store的state对象
    get storeState () {
      return this.$util.$store.state;
    }
  }
  
  export default {
    stGlobalData
  }