<template>
    <div class="st-button" v-if="condition"
        :id="_getStid"
        @click="onClick">
        <!-- <st-toast v-model="toast_show">
            <slot name="toast"></slot>
        </st-toast> -->
        <slot name="default"></slot>
        <!-- wxa按钮实现open-type的功能 -->
        <button v-if="$util.isEnv('wxa')" class="st-button st-wx-button"
            :openType="getopenType"
            :data-share_config="condition['share_config']"
            :showMessageCard="getShowMessageCard"
            :sendMessagePath="getSendMessagePath"
            :sendMessageImg="getSendMessageImg">
        </button>
        <!-- h5开放标签 -->
        <st-open-tag v-else-if="isOpenTag"
            :tag="condition['appid'] ? 'launch_weapp' : 'subscribe'"
            :appid="condition['appid']"
            :log="(config && config.log) || ''"
            :originid="condition['originid']"
            :path="condition['path']"
            :channel_ids="condition['channel_ids'] || []"
            :tag_size="el_size"
            @clickHandle="onClick">
        </st-open-tag>
    </div>
</template>

<script>
    import StBaseComp from "../StBaseComp";
    import StOpenTag from "./StOpenTag";

    export default {
        name: "st-button",
        extends: StBaseComp,
        components: {StOpenTag},
        props: {
            app: {
                type: String,
                default: 'vliti'
            },
            config: {
                type: Object
            },
            idx: {
                type: Number,
                default: -1
            },
            envDatas: {
                type: Object,
                default: ()=>{
                    return {}
                }
            },
            openType:{
                type: String,
            },
            showMessageCard:{
                type: Boolean,
            },
            sendMessagePath: {
                type: String,
            },
            sendMessageImg:{
                type: String,
            },
            onFunc: {
                type: Function
            }
        },
        data () {
            return {
                condition: null, // 命中的条件
                // toast_show: false,
                st_preload_finish: false,
                btn_stroage: "",
                btn_lifecycle_value: "",
                bid: null,
                el_size: [0, 0],
                act_res: {}, //执行结果
            }
        },
        computed: {
            isOpenTag () {
                return this.$util.isEnv('h5')
                        && !this.$util.isEnv("webview")
                        && this.bid
                        && this.st_preload_finish 
                        && this.condition 
                        && ( (this.condition['appid'] && this.condition['path'] ) || this.condition['do'] == "submsg")
            },
            strorageId () { //区别于bid 因 bid在condition后生成无法用于condition 暂时妥协
                // let storage_id = this.$util.md5(Taro.getCurrentInstance().router.path.split("?")[0] + JSON.stringify(this.config));
                let storage_id = this.$util.md5(this.$util.$router.path.split("?")[0] + JSON.stringify(this.config));
                return storage_id;
            },
            conditionDo () {
                let _do = this.condition.do;
                if (this.$util.typeOf(_do) == "string") {
                    return [_do];
                } else if (this.$util.typeOf(_do) == "object") {
                    return [_do['action']];
                } else if (this.$util.typeOf(_do) == "array") {
                    let _does = [];
                    for (let i = 0 ; i < _do.length ; i++) {
                        if (this.$util.typeOf(_do[i]) == "array") {
                            for (let j = 0 ; j < _do[0] ; j++) {
                                _does.push(_do[i][j]['action']);
                            }
                        } else {
                            _does.push(_do[i]['action']);
                        }
                    }
                    return _does;
                }
                return [];
            },
            getopenType () {
                let open_type = "";
                if (this.openType){
                    open_type = this.openType;
                }
                if (this.condition.open_type) {
                    return this.condition.open_type;
                }
                // if (this.condition.do && ["share", "contact"].indexOf(this.condition.do) !== -1) {
                //     open_type = this.condition.do;
                // }
                if (this.conditionDo) {
                    let array_intersection = this.$util.arrayIntersection(["share", "contact"], this.conditionDo);
                    if (array_intersection && array_intersection.length > 0) {
                        open_type = array_intersection[0];
                    }
                }
                if (!this.condition) {
                    return open_type;
                }
                return open_type;
            },
            getShowMessageCard () {
                let showMessageCard = "";
                if (this.showMessageCard){
                    showMessageCard = this.showMessageCard;
                }
                // if (this.condition.do == "contact") {
                if (this.conditionDo && this.conditionDo.indexOf("contact") != -1) {
                    showMessageCard = this.condition.card;
                }
                return showMessageCard;
            },
            getSendMessagePath () {
                let sendMessagePath = "";
                if (this.sendMessagePath){
                    sendMessagePath = this.sendMessagePath;
                }
                // if (this.condition.do == "contact") {
                if (this.conditionDo && this.conditionDo.indexOf("contact") != -1) {
                    sendMessagePath = this.condition.path;
                }
                return sendMessagePath;
            },
            getSendMessageImg () {
                let sendMessageImg = "";
                if (this.sendMessageImg){
                    sendMessageImg = this.sendMessageImg;
                }
                if (this.conditionDo && this.conditionDo.indexOf("contact") != -1) {
                    sendMessageImg = this.condition.img;
                }
                return sendMessageImg;
            }
        },
        created () {
            this.$util.$login.getUserInfo();
            let _this = this;
            this.getCondition({"action": "create"}).then(()=>{
                _this.$nextTick(() => {
                    // 设置全局唯一id
                    _this.setBid();
                    // 设置opentag大小
                    _this.setElSize();
                    // 监控bid做出反应
                    if (_this.$util.isEnv('h5')){
                        _this.checkAction(); // 直接进行一次检测
                    }
                })
            })

            // 监听preload finish 微信config后才能正常显示开放标签
            this.$util.$eventCenter.on("PRELOAD_FINISH").then(options=>{
                _this.st_preload_finish = true;
            })
        },
        methods: {
            // 检测自动触发按钮事件
            checkAction () {
                if (!this.condition) {
                    return;
                }
                let actbtn = this.$util.getUrlQuery('actbtn', this.$util.getUrlHash('actbtn'));
                let _now = Date.parse(new Date())/1000;
                if (actbtn) {
                    let [actbtn_id,actbtn_time] = actbtn.split("_");
                    if (actbtn_id == this.bid && _now - parseInt(actbtn_time) < 10){
                        this.onClick({
                            "auto": 1
                        });
                    }
                }
            },
            // 触发显示后设置缓存状态
            btnDidShow () {
                // 如果缓存、global则写入
                if (this.btn_lifecycle_value) {
                    this.$util.$global.set(this.strorageId, this.btn_lifecycle_value)
                }
                // if (this.btn_stroage) {
                //     this.$util.setStorageSync(this.strorageId, this.btn_stroage)
                // }
            },
            setBid () {
                // 当前按钮未显示，无需绑定bid
                if (!this.$el) {
                    return;
                }

                // 由config、class_name、idx三个变量定义bid
                let config_str = JSON.stringify(this.config),
                    class_name = ( this.idx > -1 ? this.$el.parentNode : this.$el ).className || "";

                this.bid = this.$util.md5( class_name + this.idx + config_str );

                // debug 环境中检测bid是否重复
                if ( this.$util.$config.ISDEBUG ) {
                    let bid_exist = this.$util.$global.get("bid_" + this.bid)
                    if (bid_exist) {
                        this.$util.$loger.warn("st-button 警告：bid 重复 => stid=>" + this._getStid + " 请仔细检查，bid需全局唯一,可以设置唯一的classname保持唯一性");
                    } else {
                        this.$util.$global.set("bid_" + this.bid, "1");
                    }
                }
            },
            // 设置按钮大小（opentag渲染需要固定size）
            setElSize (size) {
                if (this.el_size && this.el_size[0] && this.el_size[1]){
                    return;
                }
                if (size && size[0] && size[1]) {
                    this.el_size = size;
                    return;
                }
                if (!this.condition){
                    return;
                }
                if (this.condition['size'] && this.$util.typeOf(this.condition['size']) == "array" && this.condition['size'][0] && this.condition['size'][1]){
                    this.el_size = this.condition['size'];
                    return;
                }
                if (['wxa','submsg'].indexOf(this.condition['do']) == -1) {
                    return;
                }
                let _dom = document.getElementById(this._getStid);
                let width = this.idx > -1 ? _dom.parentNode.offsetWidth : _dom.offsetWidth;
                let height = this.idx > -1 ? _dom.parentNode.offsetHeight : _dom.offsetHeight;
                this.el_size = [width, height];
                // 当按钮未被撑开时可监听按钮撑开后再设置opentag的宽高
                if (width == 0 || height == 0) {
                    let _this = this;
                    this.$util.$observer.observeShow({
                        target: "#" + this._getStid
                    }).then(res=>{
                        width = _this.idx > -1 ? _dom.parentNode.offsetWidth : _dom.offsetWidth;
                        height = _this.idx > -1 ? _dom.parentNode.offsetHeight : _dom.offsetHeight;
                        _this.el_size = [width, height];
                    })
                }
            },
            // 获取外部传入dataset
            getDataset () {
                let _dom = document.getElementById(this._getStid),
                dataset = {};
                if (this.idx > -1) {
                    if (_dom && _dom.parentNode) {
                        dataset = _dom.parentNode.dataset;
                    }
                } else if (_dom) {
                    dataset = _dom.dataset;
                }
                return dataset;
            },
            // 获取转跳参数
            getRedirectParam () {
                let params = {};
                
                // 记录触发按钮
                if (this.bid){
                    params['actbtn'] = this.bid + "_" + Date.parse(new Date())/1000;
                }

                // 记录当前滚动高度
                let scrollTop = this.$util.$global.get("st_page_scroll_top");
                if (scrollTop > 0){
                    params['scrollto'] = scrollTop + "_" + Date.parse(new Date())/1000;
                }
                
                return params;
            },
            
            // condition关键词转换
            transCondition (condition) {
                // 拼接参数
                if (condition && condition.hasOwnProperty("path") && condition.hasOwnProperty("params")) {
                    for (let j in condition['params']) {
                        if (condition['params'][j]){
                            if (condition["path"].indexOf("?") == -1) {
                                condition["path"] = condition["path"] + "?" + j + "=" + condition['params'][j];
                            } else {
                                condition["path"] = condition["path"] + "&" + j + "=" + condition['params'][j];
                            }
                        }
                    }
                }
                return condition;
            },
            async getCondition (options) {
                let {action} = options ? options : {},
                    conditions = this.config.condition,
                    condition = null;
                
                condition = this.$util.$condition.check({
					"conditions": conditions
				})

                // 2022-04-08 yellow 替换成统一的condition方法
                // for (let i=0; i<conditions.length; i++) {
                //     if (await this.matchIf(conditions[i])) {
                //         condition = conditions[i];
                //         break;
                //     }
                // }
                // console.log("condition=>",condition)

                if (this.idx > -1 && !condition){
                    if (action == "create"){
                        this.$emit("onShowNextButton", this.idx)
                    }
                    return Promise.resolve()
                }
                // 命中条件、开始显示
                if (condition) {
                    this.$emit("onBtnShow", {
                        "idx": this.idx,
                        "config": this.config,
                        "condition": condition
                    })
                    if (this.idx < 1){
                        this.btnDidShow();
                    }
                }
                // 加工condition
                condition = this.transCondition( this.$util.copy(condition) );
                this.condition = condition;
                return Promise.resolve()
            },

            // action相关
            __callBack (options) {
                let mode = options && options.mode ? options.mode : "success";
                if (mode != "success" || (this.condition && this.condition['success'] != "break") ) {
                    let dataset = this.getDataset();
                    this.$emit("onCallBack",{
                        "idx": this.idx,
                        "config": this.config,
                        "condition": this.condition,
                        "dataset": dataset,
                        "act_res": this.act_res,
                        "params": options && options.params ? options.params : {}
                    })
                }
                this.getCondition({"action": "create"}); //执行完毕后检测一次自身condition
            },
            onClick (options) {
                let _this = this;
                // 存储执行结果
                if (options && options.auto){ // 自动调用之前重新判断condition
                    options['status'] = options['status'] || "success";
                    this.getCondition().then(()=>{
                        if (_this.condition){
                            _this.do();
                        } else {
                            _this.__callBack({
                                "mode": "auto"
                            });
                        }
                    })
                } else if (options && options.status) {
                    if (options.status == "success") {
                        this.__callBack();
                    } else if (options.status == "cancel") {
                        if (this.condition && this.condition['cancel'] != "break") {
                            this.__callBack({
                                "mode": "cancel"
                            })
                        }
                    } else if (options.status == "error") {
                        this.__callBack({
                            "mode": "error"
                        })
                    }
                } else {
                    this.do();
                }

                if (options) {
                    this.act_res = this.act_res ? Object.assign(this.act_res, options) : options;
                }

                // 埋点log计数
                if (this.config.log){
                    let key = "",
                        params = {
                            "do": this.condition && this.condition['do'] && this.$util.typeOf(this.condition['do']) == "string" ? this.condition['do'] : ""
                        }
                    if (this.$util.typeOf(this.config.log) == "object") {
                        params = Object.assign(params, this.config.log.params)
                        key = this.config.log.key;
                    } else {
                        key = this.config.log;
                    }
                    this.$util.$loger.log(key, params);
                }
            },

            do () {
                let _this = this,
                    options = this.condition && this.$util.copy(this.condition);
                if (options) {
                    options['redirectParam'] = this.getRedirectParam();
                    if (this.onFunc) {
                        options["onFunc"] = this.onFunc;
                    }
                    this.$util.$action.do(
                        options
                    ).then(res=>{
                        _this.__callBack(res);
                    }).catch(err=>{
                        console.log("action reject =>", err)
                        // _this.__callBack({
                        //     "mode": "error"
                        // });
                    });
                } else {
                    // 没有命中任何条件
                    // debugger 
                    this.__callBack();
                }
                return;
                switch(this.condition && this.condition['do']) {
                    case "link":
                        this._doLink();
                        break;
                    case "wxa":
                        this._doWxa();
                        break;
                    case "login":
                        this._doLogin();
                        break;
                    case "login_base":
                        this._doLoginBase();
                        break;
                    case "toast":
                        this._doToast();
                        break;
                    case "toast_modal":
                        this._doToastModal();
                        break
                    case "incentive_video":
                        this._doIncentiveVideo();
                        break;
                    case "download":
                        this._doDownLoad();
                        break;
                    case "submsg":
                        this._doSubmsg();
                        break;
                    case "pay":
                        this._doPay();
                        break;
                    case "make":
                        this._doFunc();
                        break;
                    case "func":
                        this._doFunc();
                        break;
                    case "sph":
                        this._doSph();
                        break;
                    default:
                        this.__callBack();
                        break;
                }
            },
            /**
             * do相关事宜转移到 st_action 中
            _doLink () {
                let path = this.condition['path'];
                switch (this.condition['method'] || "navigateTo"){
                    case "redirectTo":
                        this.$util.$router.redirectTo({
                            "url": path,
                        })
                        break;
                    case "reLaunch":
                        this.$util.$router.reLaunch({
                            "url": path,
                        })
                        break;
                    default://navigateTo
                        this.$util.$router.navigateTo({
                            "url": path,
                        });
                        this.__callBack();
                        break;
                };
            },
            _doWxa () {
                let _this = this;
                if ( this.$util.isEnv("wxa") ) {
                    wx.navigateToMiniProgram({
                        appId: this.condition['appid'],
                        path: this.condition['path'],
                        success: res => {
                            _this.__callBack();
                        }
                    })
                } else if ( this.$util.isEnv("webview") ) {
                    // this.$util.$router.navigateTo({
                    //     url: this.condition['path']
                    // })
                    this._doLink();
                } else {
                    // 开放标签转跳
                    _this.__callBack({
                        "mode": "error"
                    });
                }
            },
            async _doLogin () {
                let _this = this,
                    confirm = false;
                if (this.$util.isEnv("wxa")){
                    confirm = await new Promise((resolve, reject)=>{ //克服事件传递问题
                        if (_this.idx > 0){
                            this.$util.$taro.showModal({
                                title: this.config.toast && this.config.toast.title ? this.config.toast.title : '温馨提示',
                                content: this.config.toast && this.config.toast.content ? this.config.toast.content : '需要获取您的微信名和头像',
                                showCancel: this.config.toast && this.config.toast.showCancel == false ? false : true,
                                confirmText: this.config.toast && this.config.toast.confirmText ? this.config.toast.confirmText : "确定",
                                success: ()=>{
                                    resolve(true);
                                },
                                fail: ()=>{
                                    reject();
                                }
                            })
                        } else {
                            resolve(true)
                        }
                    }).catch(err=>{})
                }
                if (confirm || !this.$util.isEnv("wxa")){
                    let userinfo = await this.$util.$login.userinfo({
                        "params": this.getRedirectParam()
                    }).catch(err=>{
                        console.error("login err=>",err)
                    }); //传参显式登录
                    if (userinfo) {
                        _this.__callBack();
                    }
                }
            },
            async _doLoginBase () {
                await this.$util.$login.base({
                    "params": this.getRedirectParam()
                })
                this.__callBack()
                return Promise.resolve()
            },
            _doToast () {
                this.toast_show = true;
            },
            _doToastModal() {
                this.$util.$taro.showModal({
                    title: this.config.toast && this.config.toast.title ? this.config.toast.title : '提示',
                    content: this.config.toast && this.config.toast.content ? this.config.toast.content : '',
                    showCancel: this.config.toast && this.config.toast.showCancel == false ? false : true,
                    confirmText: this.config.toast && this.config.toast.confirmText ? this.config.toast.confirmText : "确定",
                    success: res=> {
                        if (res.confirm){
                            this.__callBack()
                        }
                    }
                })
            },
            _doIncentiveVideo () {
                let _this = this,
                    key = this.$util.$router.path + "/incentive_video",
                    admanager = this.$util.$global.get(key);
                if (!admanager){
                    this.$util.$loger.warn("激励视频需在当前页面生命周期创建单例，并确保单例存入全局变量中");
                    _this.__callBack();
                } else {
                    admanager.show().then(res=>{
                        if (res == false){
                            _this.$util.$taro.showToast({
                                title: '视频未播放完成无法获得奖励',
                                icon: 'none',
                                duration: 3000
                            });
                        } else if (res){
                            _this.__callBack()
                        } else { // 发生了未知错误
                            _this.__callBack()
                        }
                    })
                }
            },
            async _doDownLoad () {
                let _this = this,
                    urls = this.$util.typeOf(this.config.url) == "string" ? [this.config.url] : this.config.url,
                    paths = [];
                for (let i=0 ; i < urls.length ; i++){
                    let path = await new Promise((resolve, reject) => {
                        wx.downloadFile({
                            url: urls[i],
                            success(res) {
                                resolve(res.tempFilePath)
                            },
                            fail(err) {
                                reject(err);
                            }
                        })
                    })
                    paths.push(path);
                }
                for (let i=0 ; i < paths.length ; i++){
                    let path = paths[i];
                    await new Promise((resolve, reject)=>{
                        wx.saveImageToPhotosAlbum({
                            filePath: path,
                            success(data) {
                                resolve();
                            },
                            fail(err) {
                                if (err.errMsg.indexOf('auth') != -1) {
                                    wx.showModal({
                                        title: '提示',
                                        content: '请先点击授权保存到相册',
                                        showCancel: false,
                                        success: ()=> {
                                            wx.openSetting({
                                                success: setting=>{
                                                    if (setting.authSetting['scope.writePhotosAlbum']){
                                                         wx.saveImageToPhotosAlbum({
                                                            filePath: path,
                                                            success(data) {
                                                                resolve();
                                                            },
                                                            fail(err) {
                                                                reject()
                                                            }
                                                         })
                                                    } else {
                                                        wx.showToast({
                                                            title: '未打开授权保存到相册',
                                                            icon: 'none',
                                                            duration: 4000
                                                        })    
                                                    }
                                                },
                                                fail: ()=>{
                                                    wx.showToast({
                                                        title: '未打开授权保存到相册',
                                                        icon: 'none',
                                                        duration: 4000
                                                    })
                                                }
                                            })
                                        }
                                    })
                                } else if (err.errMsg.indexOf('cancel') != -1) {  
                                } else {
                                    wx.showToast({
                                        title: '操作失败',
                                        icon: 'none'
                                    })
                                }
                            }
                        })
                    })
                }
                wx.showToast({
                    title: '保存成功！',
                    icon: 'success',
                    duration: 4000
                })
            },
            async _doSubmsg () {
                let acceptedids = [];
                if ( this.$util.isEnv('wxa') ) {
                    acceptedids = await this.$util.requareTemplate({
                        "ids": this.condition['channel_ids'] || []
                    }).catch(err=>{})
                    this.__callBack()
                } else {
                    this.__callBack()
                }
                return Promise.resolve(acceptedids)
            },
            async _doSph () {
                let _this = this;
                return new Promise((resolve, reject)=>{
                    if (wx.openChannelsActivity) {
                        wx.openChannelsActivity(Object.assign(_this.condition['argvs'],{
                          success: res=>{
                            _this.__callBack();
                          },
                          fail: err=>{debugger;}
                        }))
                    } else {
                        reject();
                    }
                })
            },
            // 支付
            async _doPay() {
                let _this = this;
                if ( this.$util.isEnv('webview') ){
                    let st_order_id = this.$util.getUrlQuery("st_order_id", this.$util.getUrlHash("st_order_id")),
                        order_used = st_order_id && this.$util.$global.get("st_order_" + st_order_id) ? true : false;

                    if (st_order_id && !order_used) {
                        let options = await this.$util.rpost(this.$util.$config.FC_HOST + "stpay/st_pay_api",{
                            "__api__": "query",
                            "app": this.$util.$config.__APP__,
                            "appid": this.$util.$login.appid,
                            "openid": this.$util.$login.openid,
                            "st_channel_id": this.condition["st_channel_id"],
                            "st_order_id": st_order_id
                        }).catch(err=>{})
                        this.$util.$global.set("st_order_" + st_order_id, 1);
                        if (!options || options['trade_state'] != "SUCCESS") {
                            this.$util.$taro.showToast({
                                title: '支付失败',
                                icon: 'none',
                                duration: 3000
                            });
                        } else {
                            this.__callBack({
                                "params": {
                                    "st_order_id": st_order_id,
                                    "st_channel_id": this.condition["st_channel_id"]
                                }
                            });
                        }
                    } else if (!st_order_id || (st_order_id && order_used)) {
                        this.$util.$router.navigateTo({
                            "url": "/subpackages/wxpay/wxpay?st_channel_id=" + this.condition["st_channel_id"] + "&" + this.$util.setUrlQuery( this.getRedirectParam() )
                        })
                    }

                } else if ( this.$util.isEnv('wxa') ) {

                } else {
                    let options = await this.$util.rpost(this.$util.$config.FC_HOST + "stpay/st_pay_api",{
                        "__api__": "unify_order",
                        "app": this.$util.$config.__APP__,
                        "appid": this.$util.$login.appid,
                        "openid": this.$util.$login.openid,
                        "st_channel_id": this.condition["st_channel_id"]
                    })
                    //  返回值参考
                    //     {
                    //         "package": "prepay_id=wx26182236750005737f15fccce9fd500000",
                    //         "timeStamp": "1643192556",
                    //         "signType": "RSA",
                    //         "paySign": "MI+LJE//hwZ0fDg9tq0cUErTSam1vBsYmJjDLKLwAcYQrhgXrvvR0/owXX+1U9mYwz0PIFy5jw9st9SI6kM9ciZvzPIkeRGjHc8w9ajpZ3uYkcHSD5OsineTJPu+njTSZCwScC1Wb+xh80H5V+fes2zCiowPB6PZdkRrJhMsuExJCFoafMig5aL6SFCJ1NIMZDAXtAVTpbctmpovsCQII76DRgfMf5md/CstRNf1FyrwRqSKmWQT6/koUJIiIpn6+wNUlx5RqCiWElojj8YGoubEQAJS939LbxVnuDgnyqFlGXzlZHIeOe7FtKgjLfA/78sLGEl+OJATiiXRS9jNHg==",
                    //         "appId": "wx2fb7317f6bc5b387",
                    //         "nonceStr": "6f16293fe445353ff9de68442118e0"
                    //     }
                    // 
                    let pay_res = await this.wxpay(options).catch(err=>{})
                    if (pay_res) {
                        this.__callBack();
                    } else {
    
                    }
                }
            },
            async wxpay(options) {
                // if (typeof WeixinJSBridge == "undefined") {
                //     if (document.addEventListener) {
                //         document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                //     } else if (document.attachEvent) {
                //         document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                //         document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                //     }
                // } else {
                // }
                let _this = this;
                // console.log(JSON.stringify(options, null, 4))
                return new Promise((resolve, reject)=>{
                    WeixinJSBridge.invoke('getBrandWCPayRequest', options, res=> {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                            resolve(true)
                        } else {
                            reject()
                        }
                    });
                })
            },
            async _doFunc () {

                await this.runFunc(this.condition['func'], this.condition['argvs'], this.onFunc);

                this.__callBack();
            },
            // 根据指令执行对应函数
            async runFunc (func, argvs, onFunc) {
                argvs = argvs || {};
                let _findFunc = (_func) => {
                    if (!_func) {
                        return ['', ''];
                    } else if (_func.indexOf('store.commit') != -1) {
                        return ['store.commit', _func.replace('store.commit.', '')];
                    } else if (_func.indexOf('store.dispatch') != -1) {
                        return ['store.dispatch', _func.replace('store.dispatch.', '')];
                    } else if (_func.indexOf('eval.') === 0) {
                        return ['eval', _func.replace('eval.', '')];
                    } else if (_func.indexOf('util.router') != -1) {
                        return ['util.router', _func.replace('util.router.', '')];
                    } else {
                        return ['utils', _func];
                    }
                }
                let [funcType, funcName] = _findFunc(func);
                switch ( funcType ) {
                    case 'store.commit':
                        this.$store.commit(funcName, argvs);
                        break;
                    case 'store.dispatch':
                        await this.$store.dispatch(funcName, argvs);
                        break;
                    case 'utils':
                        this.$util[funcName] && this.$util[funcName](argvs);
                        break;
                    case 'util.router':
                        this.$util.$router[funcName] && this.$util.$router[funcName](argvs);
                        break;
                    case 'eval':
                        try {
                            eval(funcName);
                        } catch (e) {
                            console.log("runFunc error: ", e);
                        }
                        break;
                    default:
                        onFunc && await onFunc();
                        break;
                }
            },
            */
        }
    }
</script>

<style>
    .st-button {
        position: absolute;
        width: 100%;
        height: 100%;
        /* 某些块级布局中，设置top、left可以默认左上顶格 */
        top: 0;
        left: 0;
        z-index: 1;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .st-wx-button{
        background: none;
    }
    .st-wx-button::after{
        border: none;
    }
</style>