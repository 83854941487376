<template>
    <st-music-player
        v-model="playerState"
        :mid="mid"
        :style="elemStyles"></st-music-player>
</template>

<script>
	import BaseElem from '../BaseElem'

    export default {
        name: 'StButtonTrans',
		extends: BaseElem,
        data () {
            return {
                elemStyles: {},
                playerState: 1
            }
        },
        computed: {
            mid () {
                return this.config['mid'] || '';
            },
            config () {
                return this.comp['config'] || {};
            }
        },
        watch: {
            // pageDatas: {
            //     handler () {
                   
            //     }
            // }
        },
        created () {  
            this.elemStyles = this.getElemStyles();
        }
    }
</script>

<style>
</style>