<template>
	<div
		@click="goDetail"
		class='book-container container'>
		<div class='images-container re-container' style="align-items: stretch;">
			<div class="images-container-image" :style="{backgroundImage: 'url('+ getBookCover(book) +')'}">
				<img class="play-image" src="http://resali.lititutu.cn/wxa_icon/btn/play.png" />
			</div>
			
			<div
				class='book-info-container container'
				style="justify-content: space-between; margin: 0;">
				<span v-if="book.value.ti || book.theme_name" class='title-container' style="color: #545454; padding: 0; margin: auto 0 10px;">{{book.value.ti?book.value.ti:book.theme_name}}</span>
				<!-- <span class="book-info-container-text" style="margin-top: 15px;">照片数量：{{book.images.length}}</span> -->
				<span class="book-info-container-text">玫瑰：{{book.zan ? book.zan : '0'}}</span>
				<div class="re-container">
					<span class="book-info-container-text">浏览数：{{book.pv ? book.pv : '0'}}</span>
				</div>
			</div>
		</div>

		<!-- <span class="book-info-container-text" style="margin-left: 16px;">来自{{wxa_appids.indexOf(book.value.appid) > -1 ? '小程序' : '公众号'}}：{{book.mpname}}</span> -->
		
		<!-- 底部按钮 -->
		<div class='bottom-btn-container re-container'>
			<template v-for="(item, index) in book_bottom_btn">
				<div
					:key="item.type"
					@click.stop ='bottomBtnClicked(item.type)'
					:class='"book-btn re-container" + (item.class? item.class:"")'>
					<img
						v-if="item.url"
						class="book-btn-image"
						:src='item.url' />
					<span :class="'book-btn-text' + (item.type=='recycle'? 'book-btn-text-recycle': (item.type == 'del_forever' ?'book-btn-text-del':'') )">{{item.name}}</span>
				</div>
				<div class="split-line"
					:key="item.type + '_line'"
					v-if="index != (book_bottom_btn.length-1)"></div>
			</template>
		</div>

		<!-- 右上角标签 -->
		<span :class="'book-tag ' + ( book.audit > 0 ? 'audit-tag':'')" :style="'display: ' + ( book.value.sq == 1 ? 'block' : 'none' ) + ';'">{{book.audit > 0 ? '佳作':'已发布'}}</span>
		
	</div>		
</template>

<script>
	import PromoScan from '@/components/promo/PromoScan.vue'

	export default {
		name: 'BookCell',
		data () {
			return {
				isPromoScanShow: false,
				promoFingerImage: '',
				promoQrcode: '',
				book_bottom_btn: [
					{
						'name': '打开',
						'type': 'open',
					},
					{
						'name': '更多',
						'type': 'more',
					},
					{
						'name': '分享',
						'type': 'share',
						'open_type': 'share',
						'url': 'http://resali.lititutu.cn/icons/app/share_3.png',
					},
				],
			}
		},
		props: ['book', 'index', 'headimgurl'],
		computed: {
			toWxaAble () {
				let able = false;
				for (let i=0; i<this.$config.wxaAble.length; i++) {
					if (window.location.href.indexOf(this.$config.wxaAble[i]) != -1) {
						able = true;
						break;
					}
				}
				return able;
			}
		},
		methods: {
			goDetail() {
				this.$emit("onBookEventHandle", this.book, this.index, "open")
			},
			bottomBtnClicked(btn_type) {
				this.$emit("onBookEventHandle", this.book, this.index, btn_type)
			},
			onPhotoDownload (key, _source) {
				this.$emit('onPhotoDownload', key);
			},
			onPromoScanShow (e) {
				let node = e.target;

				this.promoFingerImage = 'http://litiresource.xcgogo.site/user/5980171/images/76432c925159c3bedec19d7cbeb1c94c.gif';

				let key = node.getAttribute('data-key');

				this.$toast.loading({
					message: '加载中...',
					duration: 'infinite'
				})

				let _this = this;

				_this.$o_util.getWxaQrcode(key).then((qrcode) => {
					_this.isPromoScanShow = true;
					_this.promoQrcode = qrcode;
					_this.$toast.clear();
				})

				this.$mta.click('mybook_btn_downphoto');
			},
			onPromoScanHide () {
				this.isPromoScanShow = false;
			},
			getBookCover (book) {
				let url = (typeof(book.cover) == 'object' ? book.cover['url'] : book.cover);
				if (this.$util.getStorageSync("WEBP_ABLE") === 0) {
					url = url.replace("x-oss-process=image/", "x-oss-process=image/format,jpg/");
					url = url.replace("imageMogr2/", "imageMogr2/format/jpg/");
				}
				return url;
			}
		},
		components: {PromoScan},
		created () {},
		mounted () {
			if (this.toWxaAble) {
				let boxs = document.querySelectorAll('.down_photo_towxa'), box, key, htm;
				for (let i=0; i<boxs.length; i++) {
					box = boxs[i];
					key = box.getAttribute('data-key');
					if (!box.innerHTML) {
						htm = '<wx-open-launch-weapp username="gh_37a75119d615" path="pages/make/make.html?scene='+key+'"><script type="text/wxtag-template"><style>.down_photo { width: 137px; height: 47.5px; top: 0; left: 0; }</style><div class="down_photo"></div></';
						htm += 'script></wx-open-launch-weapp>';
						box.innerHTML = htm;
					}
				}
			}
		}
}
</script>

<style scoped lang="less">
.book-container {
	position: relative;
	border-radius: 8px;
	width: 468px;
	margin: 12px 16px;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.08);
	padding: 5px 0 0;
	align-items: stretch;
	background-color: #fff;
	overflow: hidden;
}

.images-container {
	justify-content: flex-start;
	margin: 5px 0;
	padding: 0 16px;
}

.images-container-image {
	width: 114px;
	height: 114px;
	min-width: 114px;
	margin: 0 11px 0 0;
	background-color: #f0f0f0;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius:@bw * 0.004;
}

.play-image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 40px;
	height: 40px;
}

.title-container {
	padding: 5px 0;
	letter-spacing: 1px;
	font-weight: bold;
	font-size: 27px;
}

.book-info-container {
	justify-content: flex-start;
	align-items: flex-start;
	margin: 5px 0;
	padding: 4px 16px;
}

.book-info-container-text {
	font-size: 21px;
	margin: 0 11px 2px 0;
	color: #545454;
}

.bottom-btn-container {
	margin: 8px 0 0;
	border-top: 1px solid #ddd;
	overflow: hidden;
	justify-content: space-evenly;
}

.book-btn {
	background-color: rgba(0, 0, 0, 0);
	line-height: 0;
	margin: 0;
	padding: 16px 13px;
	flex-grow: 1;
	justify-content: center;
}

.book-btn::after {
	border: 0 !important;
	padding: 0;
	margin: 0;
	line-height: 0;
	background-color: rgba(0, 0, 0, 0);
}

.book-btn-image {
	width: 27px;
	height: 27px;
	margin-right: 8px;
}

.book-btn-text {
	font-size: 24px;
	line-height: 27px;
	letter-spacing: 1px;
	color: #545454;
}

.book-btn-text-del {
	color: #888;
}

.book-btn-text-recycle {
	color: #FF6126;
}

.split-line {
	background-color: #ddd;
	width: 1px;
	height: 58px;
}

.book-tag {
	position: absolute;
	top: 11px;
	right: -51px;
	transform: rotate(45deg);
	background: linear-gradient(to top right, #ff9761, #e72047);
	color: #fff;
	padding: 4px 53px;
	font-weight: 450;
	font-size: 16px;
}

.audit-tag {
	background: rgba(2, 193, 98, 1) !important;
	padding: 4px 57px;
	font-size: 17px;
}
</style>