<template>
	<div class="close" @click="hideTab">
		<img src="http://litiresource.xcgogo.site/user/6403275/images/e111cdd93a251a51368868ca00c0de46.png">
		<!-- <img src="http://litiresource.xcgogo.site/user/5980171/images/d1bf2d16f1af74aa5b82c9e090d7896f.png"> -->
	</div>
</template>

<script>
	export default {
		data () {
			return {}
		},
		methods: {
			hideTab () {
				this.$emit('hideTab');
			}
		}
	}
</script>

<style lang="less">
	.close {
		position: fixed;
		top: 0;
		width: 100%;
		height: @tabtop;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 999;
		img {
			position: absolute;
			width: @bw*.1;
			height: @bw*.086;
			left: 50%;
			top: 50%;
			margin-left: -(@bw*.049);
			margin-top: -(@bw*.043);
		}
	}
</style>