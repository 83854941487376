import U from './util'
import mta from './mta'
import store from './st_comps.store'
import stutil from './st_comps.util'

export default {
	config (appId, timestamp, nonceStr, signature) {
		appId = appId || wxConfigMsg.appId;
		timestamp = timestamp || wxConfigMsg.timestamp;
		nonceStr = nonceStr || wxConfigMsg.nonceStr;
		signature = signature || wxConfigMsg.signature;
		
		/** 监听是否能使用开放标签 */
		document.addEventListener('WeixinOpenTagsError', function (e) {
			console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开发标签，JS-SDK其他功能不受影响
			openTagsAble = false;
			console.log("WeixinOpenTagsError can't use open tag!!!!")
		});
		wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                // 'updateAppMessageShareData',
                // 'updateTimelineShareData',
                'chooseImage',
				'getLocalImgData',
                'previewImage',
                'uploadImage',
                'hideAllNonBaseMenuItem',
                'showAllNonBaseMenuItem',
                'closeWindow',
                'hideMenuItems',
                'checkJsApi'
            ],
            openTagList: [
            	'wx-open-launch-weapp',
				'wx-open-audio',
				'wx-open-subscribe'
            ]
        });
	},
	reload () {
		return this.jsload();
	},
	jsload () {
		return new Promise(( resolve, reject ) => {
			if (location.host.indexOf(":") != -1) {
				resolve();
				return;
			}

			U.rget('/api/get_wx_config', {
				'full_url': encodeURIComponent(window.location.href)
			}).then((response) => {
				wxConfigMsg.appId = response['appid'];
				wxConfigMsg.timestamp = response['timestamp'];
				wxConfigMsg.noncestr = response['noncestr'];
				wxConfigMsg.signature = response['signature'];
				this.config(
					response['appid'],
					response['timestamp'],
					response['noncestr'],
					response['signature']
				);
				this.ready().then(() => {
					resolve();
				}).catch(() => {
					reject();
				});
			})
		})
	},
	ready () {

		// testlog('in ready');
		return new Promise(( resolve, reject ) => {

			// testlog('in Promise');

			if (U.isWeiXin()) {

				let pass = false;
				setTimeout(() => {
					if ((!pass) && U.getUrlQuery('vscene') != 3001 && !(U.isWebview() == 'wxa' && window.location.href.indexOf('share') != -1)) {
						let querys = U.getQuerysByUrl();
						querys['vscene'] = 3001;
						window.location = U.getUrlByQuerys(querys);
					}
				}, 1500);
				
				// testlog('in if');

				wx.ready(() => {
					pass = true;
					// testlog('wx ready');
					resolve();
				})
				wx.error((res) => {
					console.warn('wx error: ', res);
					// alert('wx error');
					U.log('wxerr_1');

					// reject();
					setTimeout(() => {
						resolve();
					}, 800);
				})
			} else {
				setTimeout(() => {
					resolve();
				}, 800);
			}
		})
	},
	shareOptions: {
		title: '送给你',
		desc: '朋友你好',
		link: 'http://xcgogo.site',
		imgUrl: 'http://litiresource.xcgogo.site/user/7175946/images/93279bedb74b2ecc555d38f719f03e16.jpg',
		success: null,
		fail: null,
		onSuccessFuncs: [],
		from: ''
	},
	share (options) {
		
		Object.assign(this.shareOptions, options);

		// if (options['success']) this.shareOptions.onSuccessFuncs.push(options['success']);

		if (typeof(wx) == 'undefined') {
			return;
		}

		// this.shareOptions = {
		// 	title: '测试',
		// 	desc: '测试描述',
		// 	link: 'http://dev2.xcgogo.site/liti/mybookweb',
		// 	imgUrl: 'http://hdalioss.lititutu.cn/mmbiz_jpg/0QicI7yQXhWYbl5EwagvugDdrZgl5qqsdvhjFR2mCOb421yIIxGoqhUZbROFibxoOxI4hAlJkkxeal1r90WV9N8Q/0',
		// }

		// alert(JSON.stringify(this.shareOptions));

		// wx.updateAppMessageShareData({
		// 	title: this.shareOptions['title'],
		// 	desc: this.shareOptions['desc'],
		// 	link: this.shareOptions['link'],
		// 	imgUrl: this.shareOptions['imgUrl'],
		// 	success: () => {
				
		// 		// let mta_params = {};
		// 		// mta_params[this.shareOptions['from']] = 'true';
		// 		// mta.click('wx_share', mta_params);

		// 		// U.rget('onMenuShareAppMessage', {}).then((response) => {
		// 		// 	this.shareOptions['success'] && this.shareOptions.success();
		// 		// 	// for(let i=0; i<this.shareOptions.onSuccessFuncs.length; i++) {
		// 		// 	// 	this.shareOptions.onSuccessFuncs[i]();
		// 		// 	// }
		// 		// })
		// 	}			
		// })
		wx.onMenuShareAppMessage({
			title: this.shareOptions['title'],
			desc: this.shareOptions['desc'],
			link: this.shareOptions['link'],
			imgUrl: this.shareOptions['imgUrl'],
			success: () => {
				// 埋点
                stutil.$loger.center.set_log({
                    field: stutil.getStLogEnv() + '_' + stutil.getStLogPage() + '_share_message_success'
                })

				let mta_params = {};
				mta_params[this.shareOptions['from']] = 'true';
				mta.click('wx_share', mta_params);

				U.rget('onMenuShareAppMessage', {}).then((response) => {
					this.shareOptions['success'] && this.shareOptions.success();
					// for(let i=0; i<this.shareOptions.onSuccessFuncs.length; i++) {
					// 	this.shareOptions.onSuccessFuncs[i]();
					// }
				})
			}
		});

		// wx.updateTimelineShareData({
		// 	title: this.shareOptions['title'],
		// 	link: this.shareOptions['link'],
		// 	imgUrl: this.shareOptions['imgUrl'],
		// 	success: () => {
				
		// 		// mta.click('wx_share');

		// 		// U.rget('onMenuShareTimeline', {}).then((response) => {
		// 		// 	this.shareOptions['success'] && this.shareOptions.success();
		// 		// })
		// 	}
		// })

		wx.onMenuShareTimeline({
			title: this.shareOptions['title'],
			link: this.shareOptions['link'],
			imgUrl: this.shareOptions['imgUrl'],
			success: () => {
				// 埋点
                stutil.$loger.center.set_log({
                    field: stutil.getStLogEnv() + '_' + stutil.getStLogPage() + '_share_timeline_success'
                })

				mta.click('wx_share');

				U.rget('onMenuShareTimeline', {}).then((response) => {
					this.shareOptions['success'] && this.shareOptions.success();
				})
			}
		});

		wx.onMenuShareQQ({
			title: this.shareOptions['title'], // 分享标题
			desc: this.shareOptions['desc'], // 分享描述
			link: this.shareOptions['link'], // 分享链接
			imgUrl: this.shareOptions['imgUrl'], // 分享图标
			success: function () {
				mta.click('wx_share_qq');

				// U.rget('onMenuShareTimeline', {}).then((response) => {
				// 	this.shareOptions['success'] && this.shareOptions.success();
				// })
			},
			cancel: function () {
				// 用户取消分享后执行的回调函数
			}
		});

		wx.onMenuShareQZone({
			title: this.shareOptions['title'], // 分享标题
			desc: this.shareOptions['desc'], // 分享描述
			link: this.shareOptions['link'], // 分享链接
			imgUrl: this.shareOptions['imgUrl'], // 分享图标
			success: function () {
				mta.click('wx_share_qqzone');
			},
			cancel: function () {
				// 用户取消分享后执行的回调函数
			}
		});

	},
	checkJsApi () {
		return new Promise(( resolve, reject ) => {
			if (typeof(wx) == 'undefined') {
				resolve();
			};

			wx.checkJsApi({
				jsApiList: ["checkJsApi"],
				success: function() {
					resolve();
				},
				fail: function() {
					reject();
				}
			});

		})
	},
	chooseImage (options) {
		if (typeof(wx) == 'undefined') {
			options['onEnd'] && options['onEnd']();
			return;
		}

		let count = options['count'] || 1;
		let sizeType = options['sizeType'] || ['compressed'] // ['original', 'compressed']
		let sourceType = ['album']; // ['album', 'camera']
		// let needupload = needupload != false ? true : false;
		let isShowProgressTips = 0;

		// let serverIds = [];
		let images = [];

		let isSafetyCheck = options['isSafetyCheck'] || 0;

		var indx = 0;
		var max = 0;

		// 微信不支持异步上传、只能同步一张一张传
		var sync = function(localIds) {
			max = localIds.length;

			// options['onUpload'] && options['onUpload'](indx, max);
			if (localIds.length === 0) {
				images.reverse();
				options['onEnd'] && options['onEnd'](images);
				return;
			}

			let localId = localIds.pop();

			wx.uploadImage({
				localId: localId,
				isShowProgressTips: isShowProgressTips,
				success (res) {
					// console.log("wxConfigMsg.appId=>",wxConfigMsg.appId)
					// console.log("res.serverId=>",res.serverId)
					U.rpost('https://alifun.litiskr.cn/script/wx_qiniu_fetch', {
						'appid': wxConfigMsg.appId,
						'serverids': res.serverId
					})
					.then((upload_res) => {
						// options['onEnd'] && options['onEnd'](_images);
						if (isSafetyCheck) {

							let image = store.state.appConfig.image_map.host_map['qiniu_inter']+res.serverId;

							U.rpost('https://alifun.litiskr.cn/api/wx_safety_check', {
								'app': 'vliti',
								'image': image,
								'mode': 'tx'
							}).then((safety_check_res) => {

								if (safety_check_res == 'safe') {
									// 图片检测为safe
									// serverIds.push(res.serverId);
									images.push(store.state.appConfig.image_map.host_map['qiniu'] + res.serverId)
								} else {
									// 图片检测为danger、默认图替代
									images.push(store.state.appConfig.image_map.host_map['oss'] + 'mmbiz_jpg/LhYAiaRvYxIic7OavJoa2bIelsM6aH0U5TkiauwKEo4t1fhaAYxjvfcxPOngXnKLIiaHUxnHXlJ8gyvkWtCfmdSoxA/0')
								}

								options['onUpload'] && options['onUpload'](indx, (max+indx));
								indx ++;

								sync(localIds);
							});

						} else {
							// alert(2);
							// serverIds.push(res.serverId);
							images.push(store.state.appConfig.image_map.host_map['qiniu']+res.serverId);

							options['onUpload'] && options['onUpload'](indx, (max+indx));
							indx ++;

							sync(localIds);
						}
					});
				}
			})
		}

		wx.chooseImage({
			count: count,
			sizeType: sizeType,
			sourceType: sourceType,
			success (res) {
				sync(res.localIds);
			},
			fail (err) {
				alert('功能启动失败，请重新打开页面，再次尝试，感谢您');
				options['onError'] && options['onError']('fail', err);
			},
			cancel (res) {
				options['onError'] && options['onError']('cancel', res);
			},
			complete (res) {
				options['onError'] && options['onError']('complete', res);
			}
		})
	},
	previewImage (current, urls) {
		if (typeof(wx) == 'undefined') {
			return;
		}
		if (!urls || !urls.length) {
			return;
		}
		if (!urls[0]) {
			return;
		}
		wx.previewImage({
			current: current,
			urls: urls
		});
	}
}