<template>
	<div id="dialog">
		<div class="d-mask" v-show="maskShow"></div>

		<Alert v-show="alertShow" :alertOptions="alertOptions" v-on:clickAlert="clickAlert"></Alert>
		<Confirm v-show="confirmShow" :confirmOptions="confirmOptions" v-on:clickConfirm="clickConfirm"></Confirm>
	</div>
</template>

<script>
	/* eslint-disable */
	import Alert from './Alert'
	import Confirm from './Confirm'

	export default {
		data () {
			return {
				maskShow: false,

				confirmOptions: {
					'title': '标题',
					'message': '内容'
				},
				confirmShow: false,
				confirmResolve: '',
				confirmReject: '',

				alertOptions: {
					'title': '标题',
					'message': '内容'
				},
				alertShow: false,
				alertResolve: '',
				alertReject: ''
			}
		},
		methods: {
			alert () {
				this.maskShow = true;
				this.alertShow = true;

				return new Promise((resolve, reject) => {
					this.alertResolve = resolve;
					this.alertReject = reject;
				})
			},
			clickAlert() {
				this.maskShow = false
				this.alertShow = false;
				this.alertResolve();
			},
			confirm () {
				this.maskShow = true;
				this.confirmShow = true;

				return new Promise((resolve, reject) => {
					this.confirmResolve = resolve;
					this.confirmReject = reject;
				})
			},
			clickConfirm (action) {
				this.maskShow = false
				this.confirmShow = false;
				if ('confirm' == action) {
					this.confirmResolve();
				} else if ('cancel' == action) {
					this.confirmReject();
				}
			}
		},
		components: {
			Alert,
			Confirm,
		}
	}
</script>

<style scoped lang="less">

	.d-mask {
		z-index: 2002;
		position: fixed;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background-color: rgba(0, 0, 0, 0.7);
	}
	
</style>