<!-- 直飘 -->
<template>
	<div class="Particle">
    </div>
</template>

<script>
	export default {
		name: 'Particle',
		props: ["mode"],
		data () {
			return {}
		},
		computed: {
			
		},
		methods: {
			
		}
	}
</script>

<style>

</style>