<template>
	<div id="toast">
		<transition name="toast">
			<div class="d-mask" v-show="maskShow"></div>
		</transition>

		<transition name="toast">
			<Loading v-show="loadingShow" :loadingOptions="loadingOptions"></Loading>
		</transition>

		<transition name="toast">
			<vText v-show="textShow" :textOptions="textOptions"></vText>
		</transition>

		<transition name="toast">
			<Qrcode v-show="qrcodeShow" :qrcodeOptions="qrcodeOptions"></Qrcode>
		</transition>
	</div>
</template>

<script>
	/* eslint-disable */

	import Loading from './Loading'
	import vText from './vText'
	import Qrcode from './Qrcode'

	export default {
		components: {
			Loading,
			vText,
			Qrcode,
		},
		data () {
			return {
				maskShow: false,

				loadingShow: false,
				loadingOptions: {
					'message': '加载中...',
					'duration': 10
				},

				textShow: false,
				textOptions: {
					'message': '文字',
					'duration': 5
				},

				qrcodeShow: false,
				qrcodeOptions: {
					'isMask': false,
					'bgSrc': '',
					'qrcodeSrc': ''
				},
			}
		},
		methods: {
			loading () {
				this.maskShow = true;
				this.loadingShow = true;

				if (this.loadingOptions['duration'] != 'infinite') {
					setTimeout(() => {
						this.maskShow = false;
						this.loadingShow = false;
					}, this.loadingOptions['duration']*1000);
				}

			},
			text () {
				this.textShow = true;

				return new Promise((resolve, reject) => {
					setTimeout(() => {
						this.textShow = false;
						resolve();
					}, this.textOptions['duration']*1000);
				})
			},
			vqrcode () {
				this.qrcodeShow = true;
			},
			clear () {
				this.maskShow = false;
				
				this.loadingShow = false;
			}
		}
	}
</script>

<style scoped lang="less">
	.d-mask {
		z-index: 2002;
		position: fixed;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background-color: rgba(0, 0, 0, 0.7);
	}
	.toast-enter-active, .toast-leave-active {
		transition: opacity .3s;
	}
	.toast-enter, .toast-leave-to {
		opacity: 0;
	}
</style>