<template>
	<div class="page denglongshu-container">
		<img class="denglongshu_BX_Class1 denglongshu_BX_Ani1" src="http://resali.lititutu.cn/inter/denglongheng/denglong.png">
	</div>
</template>

<script>
	export default {
		name: 'denglongshu',
		data () {
			return {}
		}
	}
</script>

<style scoped>
	.denglongshu-container {
		z-index: 9;
	}
	.denglongshu_BX_Class1{
	    position: absolute; 
		top: 0;
	    left: -366px;
	    width: 1240px;
	    height: 1607px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0px) rotate(0deg);;
	    opacity: 1;
	    transform-origin:50% 50%;
	    display: block;
	    z-index: 2;
	    pointer-events: none;
	}
	.denglongshu_BX_Ani1{
	    animation:denglongshu_BX_AniKey1 4s linear 0s 1 both;
	}
	@keyframes denglongshu_BX_AniKey1{
		0% {opacity: 1;transform: translate3d(0px, 1607px, 0px) rotate(0deg);}
		6.67% {opacity: 1;}
		93.33% {opacity: 1;}
		100% {opacity: 1;transform: translate3d(0px,-1607px,0px) rotate(0deg);}
	}
</style>