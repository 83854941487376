<template>
    <div class="luo4 page po pointer" :style="getStyles">
        <!-- <img v-for="index of 12" -->
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
            <div v-for="index of 6"
                :class="'luo4_item_' + index"
                :key="'luo4_item_' + index">
                <elem 
					:mode="getLuoItem(index)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"
                    :scene="scene"
                    :line="line"></elem>
            </div>
        </template>
        <template v-else>
            <img v-for="index of 6"
                mode="widthFix"
                :src="getLuoItem(index)"
                :class="'luo4_item_' + index"
                :key="'luo4_item_' + index"
                class="po">
        </template>
    </div>
</template>

<script>
import BaseLuo from './BaseLuo'
    export default {
        name: 'luo4',
        extends: BaseLuo,
        data () {
            return {}
        },
    }
</script>

<style>
    .luo4_item_1 {
        top: -100px; left: 71px; animation: luo4_1 11s linear 4s infinite both;
    }
    .luo4_item_2 {
        top: -100px; left: 203px; animation: luo4_1 10s linear 6s infinite both;
    }
    .luo4_item_3 {
        top: -100px; left: 274px; animation: luo4_3 8s linear 2s infinite both;
    }
    .luo4_item_4 {
        top: -100px; left: 68px; animation: luo4_4 6s linear 0s infinite both;
    }
    .luo4_item_5 {
        top: -100px; left: 368px; animation: luo4_3 10s linear 2s infinite both;
    }
    .luo4_item_6 {
        top: -100px; left: 265px; animation: luo4_3 7s linear 1s infinite both;
    }
    .luo4_item_7 {
        top: -100px; left: 371px; animation: luo4_1 8s linear 10s infinite both;
    }
    .luo4_item_8 {
        top: -100px; left: 465px; animation: luo4_4 9s linear 10s infinite both;
    }
    .luo4_item_9 {
        top: -100px; left: 309px; animation: luo4_1 11s linear 3s infinite both;
    }
    .luo4_item_10 {
        top: -100px; left: 247px; animation: luo4_2 10s linear 8s infinite both;
    }
    .luo4_item_11 {
        top: -100px; left: 422px; animation: luo4_3 9s linear 7s infinite both;
    }
    .luo4_item_12 {
        top: -100px; left: 47px; animation: luo4_3 11s linear 7s infinite both;
    }
    .luo4_item_13 {
        top: -100px; left: 451px; animation: luo4_2 11s linear 3s infinite both;
    }
    .luo4_item_14 {
        top: -100px; left: 100px; animation: luo4_4 6s linear -1s infinite both;
    }
    .luo4_item_15 {
        top: -100px; left: 479px; animation: luo4_3 8s linear 7s infinite both;
    }
    .luo4_item_16 {
        top: -100px; left: 386px; animation: luo4_2 12s linear 2s infinite both;
    }
    .luo4_item_17 {
        top: -100px; left: 272px; animation: luo4_4 12s linear 3s infinite both;
    }
    .luo4_item_18 {
        top: -100px; left: 379px; animation: luo4_2 7s linear 7s infinite both;
    }
    .luo4_item_19 {
        top: -100px; left: 215px; animation: luo4_3 11s linear 7s infinite both;
    }
    .luo4_item_20 {
        top: -100px; left: 463px; animation: luo4_4 11s linear 7s infinite both;
    }
    .luo4_item_21 {
        top: -100px; left: 415px; animation: luo4_2 9s linear 1s infinite both;
    }
    .luo4_item_22 {
        top: -100px; left: 476px; animation: luo4_1 12s linear 3s infinite both;
    }
    .luo4_item_23 {
        top: -100px; left: 289px; animation: luo4_1 7s linear 10s infinite both;
    }
    .luo4_item_24 {
        top: -100px; left: 165px; animation: luo4_2 11s linear 2s infinite both;
    }
    @keyframes luo4_1 {
        0% {
            -webkit-transform: translate(0px, 0px) rotate(0);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(342px, 976px) rotate(-125deg);
            opacity: 0;
        }
    }
    @keyframes luo4_2 {
        0% {
            -webkit-transform: translate(0px,0px) rotate(0);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(-342px,976px) rotate(256deg);
            opacity: 0;
        }
    }
    @keyframes luo4_3 {
        0% {
            -webkit-transform: translate(0px,0px) rotate(0);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(-202px,1076px) rotate(168deg);
            opacity: 0;
        }
    }
    @keyframes luo4_4 {
        0% {
            -webkit-transform: translate(0px,0px) rotate(0);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(102px,1076px) rotate(-300deg);
            opacity: 0;
        }
    }
</style>