<template>
	<div class="model-page">
	    <div class="rectangle_box" :style="{backgroundImage: 'url('+ mode[0] +')'}">
	        <div class="rect_rotate">
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect1"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect2"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect3"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect4"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect5"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect6"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect7"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect8"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect9"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect10"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect11"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect12"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect13"></div>
	            <div @click="wxPreview" :style="getStyle('max')" class="rect rect14"></div>
	            <div @click="wxPreview" :style="getStyle('min')" class="rect rect15"></div>
	            <div @click="wxPreview" :style="getStyle('min')" class="rect rect16"></div>
	            <div @click="wxPreview" :style="getStyle('min')" class="rect rect17"></div>
	            <div @click="wxPreview" :style="getStyle('min')" class="rect rect18"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'tiandi',
		props: ['mode', 'currentImage', 'showStatus'],
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
			getStyle (m) {
				let twidth, theight, standsize;

				if (m == 'max') {
					standsize = [500, 600];
				} else if (m == 'min') {
					standsize = [70, 70];
				}

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], standsize, 'widthFix');
					twidth = tsize[0];
					theight = tsize[1];
				} else {
					twidth = standsize[0];
					theight = standsize[1];
				}

				return {
					width: twidth + 'px',
					height: theight + 'px',
					backgroundImage: 'url('+ this.currentImage['im'] +')'
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.rectangle_box,.rect_rotate {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		overflow: hidden;
		perspective: @bw * 0.694;
		-webkit-user-select: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.rectangle_box {
		background:no-repeat center;
		background-size: cover;
	}
	.model-page {
		position:absolute;
		width:100%;
		height:100%;
		/* background-image:url('http://bpic.ooopic.com/15/34/34/15343455-50bdabfdd46a2507b47d9926635ae717-1.jpg');
		*/
	}
	.flex_box {
		position:fixed;
		width:100%;
		top:0;
		left: 0;
		display:flex;
		align-content:center;
		justify-content:space-around;
		font-size:@bw * 0.056;
		z-index:99;
		color:#fff;
	}
	.rect {
		position:absolute;
		left:@bw * 0.0;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
		background-position:center center;
		background-repeat:no-repeat;
	}
	.rect img {
		display:block;
		width:100%;
	}
	.rect {
		width:@bw * 1.0;
		height:@bw * 1.0;
	}
	.rect1 {
		transform:translateZ(-@bw * 0.25) translateY(0) translateX(@bw * 0.014);
		z-index:30;
		animation:rotate_rect 10s linear infinite alternate;
	}
	.rect2 {
		transform:translateZ(-@bw * 1.333) translateY(0) translateX(-@bw * 0.833);
		z-index:28;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.rect3 {
		transform:translateZ(-@bw * 1.333) translateY(0) translateX(@bw * 0.347);
		z-index:28;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.rect4 {
		transform:translateZ(-@bw * 1.333) translateY(0) translateX(@bw * 1.528);
		z-index:28;
		animation:rotate_rect4 10s linear infinite alternate;
	}
	.rect5 {
		transform:translateZ(-@bw * 2.417) translateY(0) translateX(-@bw * 1.736);
		z-index:26;
		animation:rotate_rect5 10s linear infinite alternate;
	}
	.rect6 {
		transform:translateZ(-@bw * 2.417) translateY(0) translateX(-@bw * 0.528);
		z-index:26;
		animation:rotate_rect6 10s linear infinite alternate;
	}
	.rect7 {
		transform:translateZ(-@bw * 2.417) translateY(0) translateX(@bw * 0.611);
		z-index:26;
		animation:rotate_rect7 10s linear infinite alternate;
	}
	.rect8 {
		transform:translateZ(-@bw * 2.417) translateY(0) translateX(@bw * 1.764);
		z-index:26;
		animation:rotate_rect8 10s linear infinite alternate;
		z-index:26;
	}
	.rect9 {
		transform:translateZ(-@bw * 3.806) translateY(0) translateX(-@bw * 2.792);
		animation:rotate_rect9 10s linear infinite alternate;
		z-index:24;
	}
	.rect10 {
		transform:translateZ(-@bw * 3.806) translateY(0) translateX(-@bw * 1.194);
		animation:rotate_rect10 10s linear infinite alternate;
		z-index:24;
	}
	.rect11 {
		transform:translateZ(-@bw * 3.806) translateY(0) translateX(@bw * 0.139);
		animation:rotate_rect11 10s linear infinite alternate;
		z-index:24;
	}
	.rect12 {
		transform:translateZ(-@bw * 3.806) translateY(0) translateX(@bw * 1.472);
		animation:rotate_rect12 10s linear infinite alternate;
		z-index:24;
	}
	.rect13 {
		transform:translateZ(-@bw * 3.806) translateY(0) translateX(@bw * 2.806);
		animation:rotate_rect13 10s linear infinite alternate;
		z-index:24;
	}
	.rect14 {
		transform:translateZ(-@bw * 0.903) translateY(0) translateX(-@bw * 0.319);
		width:@bw * 0.139;
		height:@bw * 0.139;
		animation:rotate_rect14 10s linear infinite alternate;
	}
	.rect15 {
		transform:translateZ(-@bw * 0.903) translateY(0) translateX(@bw * 0.092);
		width:@bw * 0.139;
		height:@bw * 0.139;
		animation:rotate_rect15 10s linear infinite alternate;
	}
	.rect16 {
		transform:translateZ(-@bw * 0.903) translateY(0) translateX(@bw * 0.444);
		width:@bw * 0.139;
		height:@bw * 0.139;
		animation:rotate_rect16 10s linear infinite alternate;
	}
	.rect17 {
		transform:translateZ(-@bw * 0.903) translateY(0) translateX(@bw * 0.847);
		width:@bw * 0.139;
		height:@bw * 0.139;
		animation:rotate_rect17 10s linear infinite alternate;
	}
	.rect18 {
		transform:translateZ(-@bw * 0.903) translateY(0) translateX(@bw * 1.25);
		width:@bw * 0.139;
		height:@bw * 0.139;
		animation:rotate_rect18 10s linear infinite alternate;
	}
	@keyframes rotate_rect {
		0% {
		transform:translateZ(-@bw * 0.25) translateY(0) translateX(@bw * 0.014) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 0.25) translateY(0) translateX(@bw * 0.014) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 0.25) translateY(0) translateX(@bw * 0.014) rotateY(360deg);
	}
	}@keyframes rotate_rect2 {
		0% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(-@bw * 0.833) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(-@bw * 0.833) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(-@bw * 0.833) rotateY(360deg);
	}
	}@keyframes rotate_rect3 {
		0% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 0.347) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 0.347) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 0.347) rotateY(360deg);
	}
	}@keyframes rotate_rect4 {
		0% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 1.528) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 1.528) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 1.528) rotateY(360deg);
	}
	}@keyframes rotate_rect5 {
		0% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(-@bw * 1.736) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(-@bw * 1.736) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(-@bw * 1.736) rotateY(360deg);
	}
	}@keyframes rotate_rect6 {
		0% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(-@bw * 0.528) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(-@bw * 0.528) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(-@bw * 0.528) rotateY(360deg);
	}
	}@keyframes rotate_rect7 {
		0% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(@bw * 0.611) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(@bw * 0.611) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(@bw * 0.611) rotateY(360deg);
	}
	}@keyframes rotate_rect8 {
		0% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(@bw * 1.764) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(@bw * 1.764) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 2.417) translateY(@bw * 0.153) translateX(@bw * 1.764) rotateY(360deg);
	}
	}@keyframes rotate_rect9 {
		0% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.153) translateX(-@bw * 2.792) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.153) translateX(-@bw * 2.792) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.153) translateX(-@bw * 2.792) rotateY(360deg);
	}
	}@keyframes rotate_rect10 {
		0% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.181) translateX(-@bw * 1.194) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.181) translateX(-@bw * 1.194) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.181) translateX(-@bw * 1.194) rotateY(360deg);
	}
	}@keyframes rotate_rect11 {
		0% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.208) translateX(@bw * 0.139) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.208) translateX(@bw * 0.139) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.208) translateX(@bw * 0.139) rotateY(360deg);
	}
	}@keyframes rotate_rect12 {
		0% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.236) translateX(@bw * 1.472) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.236) translateX(@bw * 1.472) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.236) translateX(@bw * 1.472) rotateY(360deg);
	}
	}@keyframes rotate_rect13 {
		0% {
			transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.264) translateX(@bw * 2.806) rotateY(0deg);
		}
		50% {
			transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.264) translateX(@bw * 2.806) rotateY(180deg);
		}
		100% {
			transform:translateZ(-@bw * 3.806) translateY(-@bw * 0.264) translateX(@bw * 2.806) rotateY(360deg);
		}
	}
	@keyframes rotate_rect14 {
		0% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(-@bw * 0.319) rotateY(0deg);
		}
		50% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(-@bw * 0.319) rotateY(180deg);
		}
		100% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(-@bw * 0.319) rotateY(360deg);
		}
	}
	@keyframes rotate_rect15 {
		0% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.092) rotateY(0deg);
		}
		50% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.092) rotateY(180deg);
		}
		100% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.092) rotateY(360deg);
		}
	}
	@keyframes rotate_rect16 {
		0% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.444) rotateY(0deg);
		}
		50% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.444) rotateY(180deg);
		}
		100% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.444) rotateY(360deg);
		}
		}@keyframes rotate_rect17 {
			0% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.444) rotateY(0deg);
		}
		50% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.444) rotateY(180deg);
		}
		100% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 0.444) rotateY(360deg);
		}
	}
	@keyframes rotate_rect18 {
		0% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 1.25) rotateY(0deg);
		}
		50% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 1.25) rotateY(180deg);
		}
		100% {
			transform:translateZ(-@bw * 0.903) translateY(@bw * 0.597) translateX(@bw * 1.25) rotateY(360deg);
		}
	}
</style>