<template>
	<div class="BX_AEComposition_guoqi">
		<div class="BX_Class7 BX_BG7 BX_Ani7" id="BX_Layer7" AELayerName="7.022.png"></div>
		<div class="BX_Class6 BX_BG6 BX_Ani6" id="BX_Layer6" AELayerName="6.022.png"></div>
	</div>
</template>

<script>
	export default {
		name: 'guoqi',
		props: ['mode', 'showStatus'],
		watch: {
            showStatus: {
                handler () {
                    if (this.showStatus) {
                        setTimeout(() => {
                            this.$emit('endInter');
                        }, this.mode['duration'] ? this.mode['duration'] * 1000 : 2000);
                    }
                },
                immediate: true
            }
        },
		data () {
			return {}
		}
	}
</script>

<style scoped>
	.BX_AEComposition_guoqi {
	    position: absolute;
	    z-index: 8;
	    background: transparent;
	    width: 100%;
	    height: 100%;
	    display: block;
	    overflow: hidden;
	    transform: translate3d(0,0,0) scale(1,1);
	    transform-origin:50% 0%;
	    pointer-events: none;
	}
	.BX_Class7 {
	    left: 1377.50px;
	    top: 502.00px;
	    width: 717px;
	    height: 500px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(10.00deg) scale(2.43, 3.17);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	    background-position: center;
	    background-size: contain;
	    background-repeat: no-repeat;
	}
	.BX_BG7 {
	    background-image: url("http://resali.lititutu.cn/scenes/xiyingguoqing/022.png");
	}
	.BX_Ani7 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation: BX_AniKey7 2s linear 0.00s 1 normal none;
	}
	@keyframes BX_AniKey7 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(10.00deg) scale(2.43, 3.17);
	    }
	    100.00% {
	        transform: translate3d(-2733.00px, -116.00px, 0.00px) rotate(10.00deg) scale(2.43, 3.17);
	    }
	}
	.BX_Class6 {
	    left: -1634.50px;
	    top: 534.00px;
	    width: 717px;
	    height: 500px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(-10.00deg) scale(-3.17, 3.17);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	    background-position: center;
	    background-size: contain;
	    background-repeat: no-repeat;
	}
	.BX_BG6 {
	    background-image: url("http://resali.lititutu.cn/scenes/xiyingguoqing/022.png");
	}
	.BX_Ani6 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation: BX_AniKey6 2s linear 0.00s 1 normal none;
	}
	@keyframes BX_AniKey6 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-10.00deg) scale(-3.17, 3.17);
	    }
	    100.00% {
	        transform: translate3d(3225.00px, -172.00px, 0.00px) rotate(-10.00deg) scale(-3.17, 3.17);
	    }
	}
</style>