import st_config from '@/st_comps.config.json'

class ConfigCenter {
    constructor (self, _util) {
        this.self = self;
        this.$util = _util;
        
        this.APP_CONFIG = "app_" + self.__APP__ + "_config";
        this.GET_API = "/config_center/get_config"
        this.GETS_API = "/config_center/get_configs"
    }
    get_config (options) {
        options = options || {};
        options['is_debug'] = (options['is_debug'] === undefined ? (this.self.ISDEBUG ? 1 : 0) : (options['is_debug'] ? 1 : 0))
        return this.$util.rget(this.GET_API, options);
    }
    get_configs (options) {
        options = options || {};
        options['is_debug'] = (options['is_debug'] === undefined ? (this.self.ISDEBUG ? 1 : 0) : (options['is_debug'] ? 1 : 0))
        return this.$util.rpost(this.GETS_API, options);
    }
    get_app_config (options) {
        options = options || {};
        options['cid'] = this.APP_CONFIG;
        return this.get_config(options);
    }
    /*
     * options 配置服务请求参数
     * cb 过程中的回调函数
     */
    get_elem (options, cb) {
        options = options || {};

        let local_elem,
            cid = options['cid'];

        // 开发环境下不进行本地缓存逻辑
        if (this.self.ISDEBUG) {
            return this.get_config(options);
        }

        options['version'] = this.self.version;
        
        // 动态require
        try {
            local_elem = require("@/components/local_comps/comps/Elems/"+ cid +".json");
            // 先进行骨架屏渲染
            cb && cb({
                mode: "skeleton",
                elem: {
                    "mode": {
                        "pid": cid,
                        "type": "page",
                        "elems": local_elem['elem']['mode']['elems']
                    }
                }
            })
        } catch(e) {
            // 无本地缓存配置时，直接请求
            console.warn("can not find local elem: ", cid);
            return this.get_config(options);
        }

        options['is_debug'] = this.self.ISDEBUG ? 1 : 0;
        options['t_version'] = local_elem['version'] + "," + local_elem['id'];

        return new Promise(resolve => {
            this.$util.rget(this.GET_API, options).then(resp => {
                if (resp == 'lasted') {
                    resolve(local_elem['elem']);
                } else {
                    resolve(resp);
                }
            })
        })
    }
}

class stConfig {
    constructor(_util) {
        this.$util = _util;

        this.ISDEBUG = process.env.NODE_ENV == 'development' ? true : (_util.$taro.getEnv() == 'WEB' && this.st_config['debug_host'].indexOf(window.location.host) != -1 ? true : false );
        // this.ISDEBUG = false;

        this.ISSIM = this.ISDEBUG ? false : false;

        this.REQ_HOST = this.ISDEBUG ? (this.ISSIM ? this.st_config["host_map"]["sim"] : this.st_config["host_map"]["debug"] ) : this.st_config["host_map"]["prod"];
        this.WEBVIEWHOST = this.ISDEBUG ? this.st_config["web_view_map"]["debug"] : this.st_config["web_view_map"]["prod"];
        this.FC_HOST = "https://alifun.litiskr.cn/";
        this.SAFETY_CHECK_API = "stverify/verify_api";
        this.VERSION = this.st_config['version'];
        
        this.__APP__ = this.st_config["app"];
        // 针对page类型应用
        if (this.__APP__ == 'st') {
            let querys = this.$util.getUrlQuerys();
            let pid = querys['pid'],
                path = querys['st_page_route'];
            if (pid) {
                this.__APP__ = pid.split("_")[1];
            } else if (path) {
                this.__APP__ = path.split("/")[1];
            }
        }
        this.__PAGE__ = this.st_config["page"] || "";
        this.__APPID__ = this.st_config["app_appid_map"] && this.st_config["app_appid_map"][this.__APP__] || this.st_config['appid'];
        this.__DESIGN_WIDTH__ = 500;
        this.__DESIGN_RATIO__ = {
            500: 3 / 2
        }

        // 配置服务接口
        this.center = new ConfigCenter(this, _util);
    }

    /**
     * 本地st_comps.config.json中的静态配置
     */
    get st_config () {
        return this.$util.copy( Object.assign( st_config[this.$util.$taro.getEnv()], st_config["PUB"] ) )
    }

    get tuku_host () {
        let tuku_config = this.st_config['tuku_config'];
        if (!tuku_config) {
            return "";
        }
        console.log("tuku_config==>",tuku_config)
        return tuku_config["cdn"]["hosts"][tuku_config["cdn"]["current_host"]];
    }

    /**
     * 合并「本地静态配置」与「服务端动态配置」
     * ！注意：其中「服务端动态配置」优先级更高
     * ！注意：动态配置在项目初始化流程中无法生效
     */
    get app_config () {
        let st_config = this.st_config;
        let app_config = this.$util.$store.state.appConfig || {};
        return Object.assign(st_config, app_config);
    }

    /*
        * 获取当前程序环境
        *   version
        *      develop 开发环境
        *      trial 审核/体验环境
        *      release 正式环境
    */
    get env_version () {
        let env_version = this.$util.$taro.getMiniProgramInfoSync()['envVersion'];
        // 若为开发者，则将正式环境改为体验环境
        if (env_version == 'release' && this.$util.inAry(this.$util.$login.openid, this.app_config['manager'] || [])) {
            env_version = 'trial';
        }
        return env_version;
    }
    /*
     * 获取当前小程序线上版本号 例，2.1.0
     * 非线上版本返回空字符串
     */
    get version () {
        let version = this.$util.$taro.getMiniProgramInfoSync()['version'];
        return version || "";
        // return version || "2.1.0";
    }
    /*
     * 埋点记录冷启动并记录小程序打开参数
     */
    get launch_params () {
        if (this.__luanch_params__){
            return this.__luanch_params__;
        }
        let options = (wx.getLaunchOptionsSync && wx.getLaunchOptionsSync()) || {};
        if (options.referrerInfo) {
            options.refer_appid = options.referrerInfo.appId;
        }
        this.$util.$config.__luanch_params__ = options;
        return options;
    }
}

export default {
    stConfig
}