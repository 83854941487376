var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$util.typeOf(_vm.mode['mode']['bg']) == 'array')?_vm._l((_vm.mode['mode']['bg']),function(bgitem,index){return _c('div',{key:bgitem.toString() + index,staticClass:"simple-bg page bg",class:{
				'simple-bg_in':  _vm.currentBgIdx == index && _vm.runtime != 0,
				'simple-bg_out': _vm.currentBgIdx != index,
				'hide': _vm.runtime != 0
			},style:({
				animationDuration: (index == 0 ? 0.6 : 1.2) + 's',
				zIndex: (_vm.currentBgIdx == index ? -1 : -2)
			})},[(_vm.$util.typeOf(bgitem) == 'object')?_c('div',{staticClass:"page bg"},[_c('gif',{attrs:{"mode":bgitem}})],1):_c('div',{staticClass:"page bg",style:({backgroundImage: 'url('+bgitem+')'})})])}):(_vm.$util.typeOf(_vm.mode['mode']['bg']) == 'object')?[(_vm.mode['mode']['bg']['im'])?[(_vm.mode['mode']['photo'] 
					&& _vm.mode['mode']['photo']['photo_mode'] 
					&& _vm.mode['mode']['photo']['photo_mode']['mode'] == 'frame')?_c('div',{staticClass:"simple-bg page bg",class:_vm.mode['mode']['bg']['className'],staticStyle:{"z-index":"-1"},style:(_vm.getFrameStyle)}):_c('div',{staticClass:"simple-bg page bg",class:_vm.mode['mode']['bg']['className'],staticStyle:{"z-index":"-1"},style:(_vm.getImStyle)})]:[_c('gif',{attrs:{"mode":_vm.mode['mode']['bg']}})]]:[_c('div',{staticClass:"simple-bg page bg",staticStyle:{"z-index":"-1"},style:({backgroundImage: 'url('+_vm.mode['mode']['bg']+')'})})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }