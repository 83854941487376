<template>
    <div class="page">
        <div class="baozha-wrap" :class="{hover: wrapClassShow}" :style="getStyles">
	        <div class="baozha-cube">
	            <div class="baozha_div baozha_front">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_div baozha_back">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_div baozha_top">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_div baozha_bottom">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_div baozha_left">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_div baozha_right">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_i i_front">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_i i_back">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_i i_top">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_i i_bottom">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_i i_left">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	            <div class="baozha_i i_right">
                    <div class="page baozha_bg bg photo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
                </div>
	        </div>
	    </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: "Baozha",
        extends: BaseElem,
        data () {
            return {
                wrapClassShow: false
            }
        },
        watch: {
            canIShow: {
                handler () {
                    if (this.canIShow) {
                        setTimeout(() => {
                            this.wrapClassShow = true;
                        }, 1500);
                    } else {
                        setTimeout(() => {
                            this.wrapClassShow = false;
                        }, 1500);
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style>
    .baozha-wrap {
        position: absolute;
		perspective:694.5px;
		perspective-origin: 50% 50%;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
    }
    .baozha-cube {
        position: absolute;
		margin: auto;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: 208.5px;
		width: 208.5px;
		transform-style: preserve-3d;
    }
    .baozha_div {
        position: absolute;
        padding: 7px;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        opacity: 0.95;
        background-color: #000;
        /* border: solid 5px #eee; */
        color: #fff;
        font: 7px arial;
        transition: transform 0.2s ease-in;
    }
    .baozha_front {
        transform: translateZ(104px);
    }
    .baozha_back {
        transform: translateZ(-104px) rotateY(180deg);
    }
    .baozha_right {
        transform: rotateY(-270deg) translateX(104px);
        transform-origin: top right;
    }
    .baozha_left {
        transform: rotateY(270deg) translateX(-104px);
        transform-origin: center left;
    }
    .baozha_top {
        transform: rotateX(-270deg) translateY(-104px);
        transform-origin: top center;
    }
    .baozha_bottom {
        transform: rotateX(270deg) translateY(104px);
        transform-origin: bottom center;
    }
    /* inner cube */
    .baozha_i {
      position:absolute;
      top: 52px;
      left: 52px;
      width: 104px;
      height: 104px;
      display: block;
      transition: all 1s linear;
    }
    .baozha_i.i_front{
      transform: translateZ(69.5px);
    }
    .baozha_i.i_back{
      transform: translateZ(-34.5px) rotateY(180deg);
    }
    .baozha_i.i_bottom{
      transform:rotateX(-90deg) translateY(34.5px);
      transform-origin: left bottom;
    }
    .baozha_i.i_left{
      transform:rotateY(270deg) translateX(-34.5px);
      transform-origin: center left;
    }
    .baozha_i.i_right{
      transform:rotateY(-270deg) translateX(34.5px);
      transform-origin: top right;
    }
    .baozha_i.i_top{
      transform:rotateX(-270deg) translateY(-34.5px);
      transform-origin: left top;
    }
    
    .baozha-cube {
        animation: baozha_rotate 20s infinite linear;
    }
    @keyframes baozha_rotate {
        from {
            transform: rotateX(0deg) rotateY(0deg);
        }

        to {
            transform: rotateX(360deg) rotateY(360deg);
        }
    }

    .baozha-wrap.hover .baozha_front {
        transform: translateZ(208.5px);
    }

    .baozha-wrap.hover .baozha_back {
        transform: translateZ(-208.5px) rotateY(180deg);
    }

    .baozha-wrap.hover .baozha_right {
        transform: rotateY(-270deg) translateZ(104px) translateX(104px);
    }

    .baozha-wrap.hover .baozha_left {
        transform: rotateY(270deg) translateZ(104px) translateX(-104px);
    }

    .baozha-wrap.hover .baozha_top {
        transform: rotateX(-270deg) translateZ(104px) translateY(-104px);
    }

    .baozha-wrap.hover .baozha_bottom {
        transform: rotateX(270deg) translateZ(104px) translateY(104px);
    }
    .baozha_bg {
        transform: scale(1, -1);
    }
</style>
