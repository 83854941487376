<template>
	<div class="menu-bg container" v-if="can_display">
		<div class="menu-bg-placeholder" @click="closeMenu"></div>
		<div class="menu-operate-area container menuFadeInUp">
		
			<!-- <div class="operate-title-container re-container">
				<span class="operate-title">发布</span>
				<span class="operate-sub-title">将相册推荐给更多网友欣赏</span>
			</div>
			<div class="operate-container re-container"
				@click="hasUserInfo?'showHideSquare':''">
				<span class="square-text">发布到广场</span>
				<img class="square-image" mode="widthFix" :src="'http://resali.lititutu.cn/wxa_icon/basic/' + (book.value.sq == 1 ? 'success': 'success2') + '.png'"/>
			</div> -->

			<div class="operate-container re-container">
				<span class="operate-title">更多选项</span>
			</div>
			<div class="operate-container re-container">
				<template v-for="item in operate_btns">
					<div class="operate-btn container"
						:key="item.type"
						v-if="item.type !='download' || book.can_show_save"
						@click="menuBtnClicked(item.type)">
						<img class="operate-btn-image"
							mode="widthFix"
							:src="'http://resali.lititutu.cn/wxa_icon/menu/' + item.type + '.png'"/>
						<span class="operate-btn-text">{{item.name}}</span>
						
						<st-button v-if="['extract','download'].indexOf(item.type) > -1"
								:rconfig='{
								"condition": [{
									"if": "else",
									"do": "wxa",
									"appid": "wx2fb7317f6bc5b387",
									"path": getWxaPath(item.type)
								}]
							}'>
						</st-button>
						<!-- <st-open-tag
							v-if="['extract','download'].indexOf(item.type) > -1"
							tag="launch_weapp"
							:tag_size="[60, 60]"
							originid="gh_37a75119d615"
							:path="getWxaPath(item.type)"
							:html='getHtml(item.type)'>
						</st-open-tag> -->

						<!-- <template v-if="item.type == 'extract'">
							<div v-if="toWxaAble" class="page down_photo_towxa" style="z-index: 2;" :data-key="item.key"></div>
							<div class="page" style="z-index: 2;"></div>
						</template> -->
					</div>
				</template>
			</div>
			<span class="cancelBtn container" @click="closeMenu">取消</span>
		</div>
	</div>
</template>

<script>
	import U from "@/util"
	// import StOpenTag from '@/components/st_comp/StOpenTag'

	export default {
		name: 'BookMenu',
		data () {
			return {
				operate_btns:[
					{
						type: "del",
						name: "删除相册",
					},
					{
						type: "download",
						name: "保存/下载",
					},
					{
						type: "extract",
						name: "提取照片"
					},
				],
				book: {},
				index: -1,
				can_display: false,
			}
		},
		components: {
			// StOpenTag
		},
		props: [],
		computed: {
		},
		methods: {
			display(book, index){
				this.book = book;
				this.index = index;
				this.can_display = true;
			},
			closeMenu (){
				this.can_display = false;
			},
			getWxaPath (btn_type) {
				let path = "/pages/make/make";
				if (btn_type == "extract") {
					path = "/pages/make/make?scene=" + this.book['key']
				} else if (btn_type == "download") {
					path = "/pages/savePage/savePage?scene=" + this.book['key']
				}
				return path;
			},
			getHtml (btn_type) {
				return '<div data="' + btn_type + '" style="top: 0; left: 0;width: 120px; height: 100px;"></div>'
			},
			menuBtnClicked (btn_type) {
				this.$emit('onBookEventHandle', this.book, this.index, btn_type);
				this.closeMenu();
			}
		},
		created () {},
		mounted () {
		}
    }
</script>

<style scoped lang="less">
.menu-bg {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.menu-bg-placeholder {
  width: 500px;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0);
}

.menu-operate-area {
  background-color: #fff;
}

.operate-title-container,
.operate-container {
  width: 470px;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0);
}

.operate-title-container {
  margin: 16px 16px;
}

.operate-container {
  margin: 19px 16px;
}

.operate-btn {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  padding: 0 16px;
  min-width: 90px;
  margin-bottom: 42px;
}

.operate-btn-image {
  width: 45px;

}

.operate-btn-text {
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(82, 82, 82, 1);
  margin: 8px 0 16px 0;
}

.operate-title {
  font-size: 30px;
  font-weight: bold;
  color: rgba(46, 46, 46, 1);
}

.operate-sub-title {
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(128, 128, 128, 1);
  align-self: flex-end;
  margin-left: 8px;
}

.square-text {
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  color: rgba(46, 46, 46, 1);
  margin: 0 16px;
}

.square-image {
  width: 30px;
  max-height: 30px;
}

.cancelBtn {
  width: 500px;
  background-color: #EEE;
  padding: 12px 0;
  font-size: 21px;
  font-weight: 400;
  line-height: 44px;
  color: rgba(82, 82, 82, 1);
}


@keyframes menuFadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.menuFadeInUp {
	animation: menuFadeInUp .3s ease-out;
}
</style>