<template>
	<div class="zhuanta poc bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
		<!-- <canvas id="page2_canvas" width="500" height="815" class="fullScreen po"></canvas> -->
		<div class="perspective poc">
			<div class="zhuanta_1 preserve3d poc">
				<gif :mode="flower_1"></gif>
				<!-- <canvas id="zhuanta_1_canvas" width="391" height="324" class="po" style="bottom: 843.952px;"></canvas> -->
				<div class="zhuanta_1_cont preserve3d poc">
					<!-- <div class="zhuanta_1_box zhuanta_1_1 preserve3d poc" @click="wxPreview">
						<div class="zhuanta_1_1_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_1_2_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_1_3_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_1_4_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_1_5_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_1_6_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_1_7_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					</div>
					<div class="zhuanta_1_box zhuanta_1_2 preserve3d poc" @click="wxPreview">
						<div class="zhuanta_2_1_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_2_2_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_2_3_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_2_4_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_2_5_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_2_6_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_2_7_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					</div>
					<div class="zhuanta_1_box zhuanta_1_3 preserve3d poc" @click="wxPreview">
						<div class="zhuanta_3_1_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_3_2_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_3_3_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_3_4_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_3_5_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_3_6_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
						<div class="zhuanta_3_7_item zhuanta_item poc bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
					</div> -->
					<div
						v-for="idx of 3"
						:key="'zhuanta_1_box' + idx"
						:class="'zhuanta_1_box preserve3d po zhuanta_1_' + idx" @click="wxPreview">
						<div
							v-for="index of 7"
							:key="'zhuanta_1_1_item' + index"
							:class="'zhuanta_item poc bg bg_photo zhuanta_' + idx + '_' + index + '_item'"
							:style="getItemStyle(idx, index)"></div>
					</div>
				</div>
			</div>
		</div>
		<gif :mode="flower_2"></gif>
		<gif :mode="flower_3"></gif>
		<!-- <canvas id="page2_2_canvas" width="391" height="324" class="po"></canvas> -->
		<!-- <canvas id="page2_3_canvas" width="391" height="324" class="po"></canvas><span class="divText imgTxt1">非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒</span> -->
</div>
</template>

<script>
	export default {
		name: 'zhuanta',
		props: ['mode', 'currentImage'],
		components: {},
		computed: {
			flower_1 () {
				if (this.mode[1] && this.mode[1][0]) {
					return this.mode[1][0];
				} else {
					return {};
				}
			},
			flower_2 () {
				if (this.mode[1] && this.mode[1][1]) {
					return this.mode[1][1];
				} else {
					return {};
				}
			},
			flower_3 () {
				if (this.mode[1] && this.mode[1][2]) {
					return this.mode[1][2];
				} else {
					return {};
				}
			}
		},
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			},
			getItemStyle(idx, index){
				let bw = 500;
				let deg = 51.4286 * (index - 1);
				let s = {};
				if(idx == 2){
					bw = bw * 0.86;
				} else if(idx == 3){
					bw = bw * 0.72;
				}
				s['backgroundImage'] = 'url('+ this.currentImage['im'] +')';
				s['transform'] = "rotateY(" + (deg).toString() + "deg) translateZ(" + bw.toString() + "px)"
				// s['transform'] = "rotateY(51.4286deg) translateZ(500px)"
				return s;
			}
		}
	}
</script>

<style>
	.zhuanta_1 {
	    bottom: 50px;
	    -webkit-transform: translateZ(-650px);
	}
	.zhuanta_1_cont {
	    -webkit-animation: zhuanta_1_1 13s linear infinite both;
	}
	.zhuanta_1_box {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	}
	.zhuanta_item {
	    border: 5px solid;
	    border-image: url(https://resali.lititutu.cn/icons/other/border.jpg);
	    border-image-slice: 5;
		background-size: cover;
		background-clip: content-box;
	}
	@keyframes zhuanta_1_1 {
		0% {
		    -webkit-transform: rotateY(360deg);
		}
		100% {
		    -webkit-transform: rotateY(0);
		}
	}
	.zhuanta_1_1 {
		width: 430px;
		height: 281.6px;
		top: 498.952px;
	}
	.zhuanta_1_2 {
		width: 369.8px;
		height: 261.976px;
		top: 50px;
	}
	.zhuanta_1_3 {
		width: 309.6px;
		height: 262.352px;
		/*bottom: 395.952px;*/
	    top: -410px;
	}
	/* .zhuanta_1_1_item {
		transform: rotateY(0deg) translateZ(500px);
	}
	.zhuanta_1_2_item {
		transform: rotateY(51.4286deg) translateZ(500px);
	}
	.zhuanta_1_3_item {
		transform: rotateY(102.857deg) translateZ(500px);
	}
	.zhuanta_1_4_item {
		transform: rotateY(154.286deg) translateZ(500px);
	}
	.zhuanta_1_5_item {
		transform: rotateY(205.714deg) translateZ(500px);
	}
	.zhuanta_1_6_item {
		transform: rotateY(257.143deg) translateZ(500px);
	}
	.zhuanta_1_7_item {
		transform: rotateY(308.571deg) translateZ(500px);
	}

	.zhuanta_2_1_item {
		transform: rotateY(0deg) translateZ(430px);
	}
	.zhuanta_2_2_item {
		transform: rotateY(51.4286deg) translateZ(430px);
	}
	.zhuanta_2_3_item {
		transform: rotateY(102.857deg) translateZ(430px);
	}
	.zhuanta_2_4_item {
		transform: rotateY(154.286deg) translateZ(430px);
	}
	.zhuanta_2_5_item {
		transform: rotateY(205.714deg) translateZ(430px);
	}
	.zhuanta_2_6_item {
		transform: rotateY(257.143deg) translateZ(430px);
	}
	.zhuanta_2_7_item {
		transform: rotateY(308.571deg) translateZ(430px);
	}

	.zhuanta_3_1_item {
		transform: rotateY(0deg) translateZ(360px);
	}
	.zhuanta_3_2_item {
		transform: rotateY(51.4286deg) translateZ(360px);
	}
	.zhuanta_3_3_item {
		transform: rotateY(102.857deg) translateZ(360px);
	}
	.zhuanta_3_4_item {
		transform: rotateY(154.286deg) translateZ(360px);
	}
	.zhuanta_3_5_item {
		transform: rotateY(205.714deg) translateZ(360px);
	}
	.zhuanta_3_6_item {
		transform: rotateY(257.143deg) translateZ(360px);
	}
	.zhuanta_3_7_item {
		transform: rotateY(308.571deg) translateZ(360px);
	} */

</style>