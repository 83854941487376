<template>
	<div class="square2_box shuibo_bg">
	    <div class="feiHeBox">
	        <div class="feiHe">
	            <img class="shuibo_feihe" src="http://resali.lititutu.cn/icons/other/feihe.png"></div>
	        <div class="feiHe feiHe2">
	            <img class="shuibo_feihe" src="http://resali.lititutu.cn/icons/other/feihe.png"></div>
	        <div class="feiHe feiHe3">
	            <img class="shuibo_feihe" src="http://resali.lititutu.cn/icons/other/feihe.png"></div>
	    </div>
	    <div class="square2">
	        <div class="lit_square_box square_box1">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box2">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box3">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box4">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box5">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box6">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box7">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box8">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box9">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box10">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box11">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box12">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box13">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            <div class="face face3 user_img" @click="wxPreview">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img" @click="wxPreview">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	            </div>
	        </div>
	    </div>
	    <div class="whater">
	        <img class="shuibo1_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo1_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo1_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <div class="whater whater2">
	        <img class="shuibo2_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <div class="whater whater3">
	        <img class="shuibo2_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <div class="whater whater4">
	        <img class="shuibo2_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <!--<canvas id="smokeCanvas" class="can_ani"></canvas>-->
	</div>
</template>

<script>
	export default {
		name: 'shuibo',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style scoped lang="less">
	.square2 {
		top:-22%;
		height:100%;
		width:100%;
		z-index:3;
		overflow:hidden;
		position:relative;
		perspective:@bw * 0.694;
		-moz-perspective:@bw * 0.694;
		perspective:@bw * 0.694;
		user-select:none;
		-moz-user-select:none;
		user-select:none;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		animation:scr 2s 1.1s linear both;
		transform:scale(0);
		transform:scale(0);
		transform-origin:center;
	}
	@keyframes scr {
		0% {
			transform:scale(0);
			transform:scale(0);
		}
		50% {
			transform:scale(0.5);
			transform:scale(0.5);
		}
		100% {
			transform:scale(1);
			transform:scale(1);
		}
	}.square2_box {
		height:100%;
		width:100%;
		overflow:hidden;
		position:relative;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background:no-repeat center;
		background-size: cover;
		/*background-size:100%;*/
	}
	.lit_square_box {
		position:absolute;
		left:@bw * 0.0;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
		background-position:center center;
		background-repeat:no-repeat;
		width:@bw * 0.5;
		height:@bw * 0.5;
	}
	.square_box1 {
		top:56%;
		left:30%;
		transform:translateZ(-@bw * 0.208);
		z-index:5;
		animation:rotate_rect1 10s linear infinite alternate;
		animation:rotate_rect1 10s linear infinite alternate;
	}
	.square_box2 {
		top:45%;
		left:-75%;
		transform:translateZ(-@bw * 1.639);
		z-index:2;
		animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.square_box3 {
		top:45%;
		left:150%;
		transform:translateZ(-@bw * 1.639);
		z-index:2;
		animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.square_box4 {
		top:33%;
		left:45%;
		transform:translateZ(-@bw * 1.639);
		z-index:2;
		animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.square_box5 {
		top:-20%;
		left:267%;
		transform:translateZ(-@bw * 3.431);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box6 {
		top:25%;
		left:-27%;
		transform:translateZ(-@bw * 2.319);
		animation:rotate_rect4 10s linear infinite alternate;
		animation:rotate_rect4 10s linear infinite alternate;
	}
	.square_box7 {
		top:-30%;
		left:-199%;
		transform:translateZ(-@bw * 3.431);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box8 {
		top:25%;
		left:-147%;
		transform:translateZ(-@bw * 2.319);
		animation:rotate_rect4 10s linear infinite alternate;
		animation:rotate_rect4 10s linear infinite alternate;
	}
	.square_box9 {
		top:-12%;
		left:-118%;
		transform:translateZ(-@bw * 3.431);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box10 {
		top:25%;
		left:130%;
		transform:translateZ(-@bw * 2.319);
		animation:rotate_rect4 10s linear infinite alternate;
		animation:rotate_rect4 10s linear infinite alternate;
	}
	.square_box11 {
		top:-31%;
		left:127%;
		transform:translateZ(-@bw * 3.431);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box12 {
		top:-27%;
		left:-56%;
		transform:translateZ(-@bw * 3.431);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box13 {
		top:-42%;
		left:23%;
		transform:translateZ(-@bw * 3.431);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	@keyframes rotate_rect1 {
		0% {
		transform:translateZ(-@bw * 0.208) rotateY(0deg);
		transform:translateZ(-@bw * 0.208) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 0.208) rotateY(180deg);
		transform:translateZ(-@bw * 0.208) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 0.208) rotateY(360deg);
		transform:translateZ(-@bw * 0.208) rotateY(360deg);
	}
	}@keyframes rotate_rect2 {
		0% {
		transform:translateZ(-@bw * 1.639) rotateY(0deg);
		transform:translateZ(-@bw * 1.639) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 1.639) rotateY(180deg);
		transform:translateZ(-@bw * 1.639) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 1.639) rotateY(360deg);
		transform:translateZ(-@bw * 1.639) rotateY(360deg);
	}
	}@keyframes rotate_rect3 {
		0% {
		transform:translateZ(-@bw * 3.431) rotateY(0deg);
		transform:translateZ(-@bw * 3.431) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 3.431) rotateY(180deg);
		transform:translateZ(-@bw * 3.431) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 3.431) rotateY(360deg);
		transform:translateZ(-@bw * 3.431) rotateY(360deg);
	}
	}@keyframes rotate_rect4 {
		0% {
		transform:translateZ(-@bw * 2.319) rotateY(0deg);
		transform:translateZ(-@bw * 2.319) rotateY(0deg);
	}
	50% {
		transform:translateZ(-@bw * 2.319) rotateY(180deg);
		transform:translateZ(-@bw * 2.319) rotateY(180deg);
	}
	100% {
		transform:translateZ(-@bw * 2.319) rotateY(360deg);
		transform:translateZ(-@bw * 2.319) rotateY(360deg);
	}
	}.lit_square_box .face {
		position:absolute;
		left:@bw * 0.0;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
		background-position:center center;
		background-repeat:no-repeat;
		width:@bw * 0.5;
		height:@bw * 0.5;
		background:no-repeat center;
		background-size:cover
	}
	.face1 {
		transform:rotateX(90deg) translateZ(-@bw * 0.333);
		-moz-transform:rotateX(90deg) translateZ(-@bw * 0.333);
		transform:rotateX(90deg) translateZ(-@bw * 0.333);
	}
	.face2 {
		transform:rotateX(-90deg) translateZ(-@bw * 0.167);
		-moz-transform:rotateX(-90deg) translateZ(-@bw * 0.167);
		transform:rotateX(-90deg) translateZ(-@bw * 0.167);
	}
	.face3 {
		transform:rotateY(90deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		-moz-transform:rotateY(90deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		transform:rotateY(90deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
	}
	.face4 {
		transform:rotateY(-90deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		-moz-transform:rotateY(-90deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		transform:rotateY(-90deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
	}
	.face_bg {
		transform: rotateY(180deg);
	}
	.face5 {
		transform:translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		-moz-transform:translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		transform:translateZ(-@bw * 0.25) translateY(@bw * 0.083);
	}
	.face6 {
		transform:rotateY(180deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		-moz-transform:rotateY(180deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
		transform:rotateY(180deg) translateZ(-@bw * 0.25) translateY(@bw * 0.083);
	}
	.can_ani {
		position:absolute;
		bottom:@bw * 0.0;
		z-index:2;
		width:100%;
	}
	.feiHe {
		width:@bw * 0.246;
		height:@bw * 0.188;
		overflow:hidden;
		position:absolute;
		z-index:2;
	}
	.feiHe2 {
		top:@bw * 0.143;
		right:@bw * 0.139;
	}
	.feiHe3 {
		top:@bw * 0.028;
		right:@bw * 0.06;
	}
	.feiHe>img {
		animation:feiHe 2s steps(28,end) infinite;
	}
	@keyframes feiHe {
		0% {
		transform:translateX(0);
	}
	100% {
		transform:translateX(-@bw * 6.883);
	}
	}.feiHeBox {
		position:absolute;
		z-index:1;
		bottom:54%;
		width:100%;
		height:20%;
		left:-@bw * 0.069;
		animation:feiHeBox 20s linear infinite;
	}
	@keyframes feiHeBox {
		0% {
		transform:translateX(@bw * 0.0);
		bottom:54%;
	}
	100% {
		transform:translateX(@bw * 1.736) translateZ(@bw * 0.208);
		bottom:69%
	}
	}.whater {
		position:absolute;
		width:100%;
	}
	.shuibo1_1 {
		animation:shuibo2 4.5s -2s ease-out infinite both;
		opacity:0;
	}
	.shuibo1_2 {
		animation:shuibo2 4.5s ease-out -0.5s infinite both;
		opacity:0;
	}
	.shuibo1_3 {
		animation:shuibo2 4.5s ease-out 1s infinite both;
		opacity:0;
	}
	.shuibo2_1 {
		animation:shuibo12 4.5s -2s ease-out infinite both;
		opacity:0;
	}
	.shuibo2_2 {
		animation:shuibo12 4.5s ease-out -0.5s infinite both;
		opacity:0;
	}
	.shuibo2_3 {
		animation:shuibo12 4.5s ease-out 1s infinite both;
		opacity:0;
	}
	@keyframes shuibo2 {
		0% {
		transform:scale(0.4);
		opacity:0;
	}
	10% {
		opacity:1;
	}
	60% {
		opacity:1;
	}
	100% {
		transform:scale(1.2);
		opacity:0;
	}
	}@keyframes trX_Y1 {
		0% {
		transform:translate(@bw * 0.0,@bw * 0.0);
	}
	100% {
		transform:translate(@bw * 0.028,-@bw * 0.083);
	}
	}.whater {
		width:@bw * 0.314;
		height:@bw * 0.312;
		bottom:-@bw * 0.069;
		right:@bw * 0.071;
		animation:trX_Y1 6s ease-in-out infinite alternate both;
	}
	.whater2 {
		width:@bw * 0.181;
		height:@bw * 0.139;
		bottom:@bw * 0.278;
		left:@bw * 0.042;
	}
	.whater3 {
		width:@bw * 0.181;
		height:@bw * 0.139;
		bottom:@bw * 0.368;
		left:-@bw * 0.265;
	}
	.whater4 {
		width:@bw * 0.181;
		height:@bw * 0.139;
		bottom:@bw * 0.732;
		left:@bw * 0.142;
		z-index:-1;
	}
	@keyframes shuibo1 {
		0% {
		transform:scale(0.1);
		opacity:0;
		}
		10% {
			opacity:1;
		}
		60% {
			opacity:1;
		}
		100% {
			transform:scale(0.6);
			opacity:0;
		}
	}@keyframes shuibo12 {
		0% {
		transform:rotateX(45deg) scale(0.1);
		opacity:0;
		}
		10% {
			opacity:1;
		}
		60% {
			opacity:1;
		}
		100% {
			transform:rotateX(45deg) scale(0.6);
			opacity:0;
		}
	}
	.shuibo_feihe {
		width:@bw * 7.129;
		height:@bw * 0.188;
	}
</style>