import store from './st_comps.store'

// 阿里云配置
const ALI_CONFIG = {
    host: "https://hb-mp-aliyunpic.oss-cn-beijing.aliyuncs.com", //(阿里云oss地址)
    bucket: "hb-mp-aliyunpic",
    sub_path: "wxapp/liti_group/",
    timeout: 87600 //这个是上传文件时Policy的失效时间
}

// 图片最大尺寸限制
const maxWidth = 1500,maxHeight = 1500;

/**
 * h5 模拟Taro.uploadFile
 */
function createFormData (file, body, name) {
    let _data = new FormData()
    Object.keys(body).forEach(key => {
        _data.append(key, body[key])
    })
    _data.append(name, base64ToFile(file))
    return _data
}

// base64 转为二进制字节流 => 会有文件破损的问题
// => 传入文件base64 和文件名合成 file 对象 
function base64ToFile (base64Image, fileName) {
    const arr = base64Image.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    if (!fileName){
        let sub_fix = mime.toLowerCase().replace("image/","")
        fileName = ('img' + Date.now() + '.' + sub_fix);
    }
    return new File([u8arr], fileName, { type: mime })
}

/**
 * 压缩图片
 * @param imgb64 被压缩的base64 img
 */
function compressImg(imgb64) {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = function() {
            const { width: originWidth, height: originHeight } = this;
            if (imgb64.length * 0.75 < 1048576) {
                resolve({
                    "url": imgb64,
                    "width": originWidth,
                    "height": originHeight
                });
            } else {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                // 目标尺寸
                let targetWidth = originWidth
                let targetHeight = originHeight
                if (originWidth > maxWidth || originHeight > maxHeight) {
                    if (originWidth / originHeight > 1) {
                        // 宽图片
                        targetWidth = maxWidth
                        targetHeight = Math.round(maxWidth * (originHeight / originWidth))
                    } else {
                        // 高图片
                        targetHeight = maxHeight
                        targetWidth = Math.round(maxHeight * (originWidth / originHeight))
                    }
                }
                // 设置 canvas 画布大小
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                // 图片绘制
                context.drawImage(img, 0, 0, originWidth, originHeight, 0, 0, targetWidth, targetHeight);
                resolve({
                    "url": canvas.toDataURL('image/jpeg', 0.9),
                    "width": targetWidth,
                    "height": targetHeight
                });
            }
        }
        img.onerror = function (err) {
            resolve({
                "url": imgb64
            })
            _this.log('upload_media_fail_4')
        }
        img.src = imgb64;
    })
}

const media_manager = {
    last_time: null,

    countTime(name) {
        if (this.last_time) {
            console.log( "count: ", name, ( Date.now() - this.last_time) );
        }
        this.last_time = Date.now();
    },

    async canIUseB64() {
        let canuseapi = await new Promise((resolve, reject)=>{
            wx.checkJsApi({
                jsApiList: ['getLocalImgData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                success: function(res) {
                    if (res && res["checkResult"] && res["checkResult"]["getLocalImgData"]) {
                        resolve(true)
                    } else {
                        resolve(false);
                    }
                }
            })
        })
        if (!canuseapi){
            this.log('upload_media_cantuseapi');
            return false;
        }

        let userAgent = navigator.userAgent.toLowerCase();
        // 测试并已适配的机型
        // let isIphone = userAgent.match(/iphone|ipad/i) == "iphone";
        // let isHuawei = userAgent.match(/huawei/i) == "huawei";
        // let isHonor = userAgent.match(/honor/i) == "honor";
        // let isOppo = userAgent.match(/oppo/i) == "oppo";
        // let isOppoR15 = userAgent.match(/pacm/i) == "pacm";
        // let isOppoV = userAgent.match(/pbcm/i) == "pbcm";
        // let isVivo = userAgent.match(/vivo/i) == "vivo";
        // let isZte = userAgent.match(/zte/i) == "zte";
        let onePlus = userAgent.indexOf("gm1910") > 0;
        let isXiaomi = userAgent.match(/mi\s/i) == "mi " || userAgent.match(/mix\s/i) == "mix " || userAgent.match(/redmi/i) == "redmi";
        let isSamsung = userAgent.match(/sm-/i) == "sm-" || userAgent.match(/gt-/i) == "gt-" || userAgent.match(/sch-/i) == "sch-";
        let isPC = userAgent.indexOf('windows') > -1;
        if (isXiaomi || isSamsung || onePlus || isPC){//三星，小米，一加，PC端适配明哥制作
            this.log('upload_media_old');
            return false;
        } else {
            this.log('upload_media_new');
            return true;
        }
    },

    // 内容审核接口
    safetyCheck(options) {
        let {key, imgs, images, mode} = options,
            _this = this;

        if (images){
            imgs = [];
            for (let i = 0 ; i < images.length ; i++){
                let img = images[i];
                img["host"] = img["im"].split("?")[0].replace(img["p"], "").replace("https","http")
                img["path"] = img["p"]
                imgs.push(img)
            }
        }
		let params = {
			"__app__": "vliti",
			"__api__": "check_image",
			"check_mode": mode ? mode : 2,
    		"images": JSON.stringify(imgs),
		}
        if (key){
            params["key"] = key;
        }
        return new Promise((resolve, reject)=>{
            _this.rpost('https://alifun.litiskr.cn/stverify/verify_api', params).then(res=>{
                resolve(res && res.suggestion ? res.suggestion : "pass");
            }).catch(err=>{
                debugger;
                resolve("pass");
            })
        })
    },

    /**
     * 将本地资源上传到服务器。客户端发起一个 HTTPS POST 请求，其中 content-type 为 multipart/form-data。
     * @param {object} opts
     * @param {string} opts.url - 开发者服务器地址
     * @param {string} opts.filePath - 要上传文件资源的路径
     * @param {string} opts.name - 文件对应的 key，开发者在服务端可以通过这个 key 获取文件的二进制内容
     * @param {object} [opts.formData] - HTTP 请求中其他额外的 form data
     * @return UploadTask - 一个可以监听上传进度进度变化的事件和取消上传的对象
     */
    uploadFile (opts = {}) {
        const { url, filePath, name, formData, success, fail, complete } = opts;
        return this.rfetch(url, createFormData(filePath, formData, name)).then(res => {
            if (res && res.status && res.status == 200){
                success && success(res)
            } else {
                fail && fail()    
            }
        }).catch(e => {
            fail && fail()
        })
    },

    /*
    阿里云上传图片
    */
    aliUploadFile (params) {
        const aliyunFileKey = params.fileName;
        var aliyunServerURL = ALI_CONFIG.host;
        if (params['fileHost']) {
            aliyunServerURL = params['fileHost'];
        }
        const accessid = params.access_id;
        const policyBase64 = params.policy;
        const signature = params.signature;
        this.uploadFile({
            url: aliyunServerURL,
            filePath: params.filePath,
            name: 'file',
            formData: {
                'key': params.subPath + aliyunFileKey,
                'policy': policyBase64,
                'OSSAccessKeyId': accessid,
                'signature': signature,
                'success_action_status': '200',
            },
            success: res=> {
                if (res.status != 200) {
                    if (params.fail) {
                        params.fail(res)
                    }
                } else if (params.success) {
                    params.success(aliyunFileKey);
                }
            },
            fail: err=> {
                debugger;
                if (params.fail) {
                    params.fail(err)
                }
            },
        })
    },

    // 上传到OSS
    async uploadMediaToOss (options) {
        let {files, violate, process, maxsize, oversize, retry} = options;
        if (this.typeOf(files) == "string"){
            files = [files];
        }
        let _this = this,
            count = 0,
            maxlen = files.length;
            
        // 如果是特殊机型(获取了七牛链接的)则直接微信上传
        if ( files[0]['path'].indexOf(store.state.appConfig.image_map.host_map['qiniu']) != -1) {
            let urls = await new Promise((resolve, reject)=>{
                let _urls = [];
                for (let idx = 0 ; idx < files.length ; idx++) {_urls.push("");}
                for (let idx = 0 ; idx < files.length ; idx++) {
                    (function(idx) { //闭包处理
                        let _path = files[idx]['path'];
                        if (_this.isWebview() == "wxa"){
                            _this.safetyCheck({
                                "imgs": [{
                                    "host": store.state.appConfig.image_map.host_map['qiniu'],
                                    "path": _path.replace(store.state.appConfig.image_map.host_map['qiniu'], "")
                                }]
                            }).then(issave=>{
                                if (issave == "block"){
                                    _urls[idx] = store.state.appConfig.image_map.host_map['oss'] + 'mmbiz_jpg/LhYAiaRvYxIic7OavJoa2bIelsM6aH0U5TkiauwKEo4t1fhaAYxjvfcxPOngXnKLIiaHUxnHXlJ8gyvkWtCfmdSoxA/0';
                                } else {
                                    _urls[idx] = _path;
                                }
                                count += 1;
                                if (count == maxlen){
                                    resolve(_urls)
                                }
                            }).catch(()=>{
                                _urls[idx] = _path;
                                count += 1;
                                if (count == maxlen){
                                    resolve(_urls)
                                }
                            })
                        } else {
                            _urls[idx] = _path;
                            count += 1;
                            if (count == maxlen){
                                resolve(_urls)
                            }
                        }
                    })(idx)
                }
            })
            let imageInfos = [];
            for (let i = 0 ; i < urls.length ; i++){
                if (urls[i].indexOf(store.state.appConfig.image_map.host_map['oss']) != -1){
                    imageInfos.push({
                        "url": urls[i],
                        "width": 100,
                        "height": 100
                    })
                } else {
                    let _info = await this.getImageInfo({
                        "src": urls[i]
                    }).catch(err=>{})
                    if (_info && _info['width'] && _info['height']) {
                        imageInfos.push({
                            "url": urls[i],
                            "width": _info['width'],
                            "height": _info['height']
                        })
                    }
                }
            }
            return imageInfos;
        }

        //获取阿里云上传图片配置
        let aliyun_access_data = await this.rget('api/get_aliyun_oss_form_token', { //转为函数计算要注意跨域问题
            bucket: ALI_CONFIG.bucket
        })

        let _files_with_name = await new Promise((resolve, reject)=>{
            for (let i = 0; i < maxlen; i++) {
                (function(idx) { //闭包处理
                    new Promise((resol, rej)=>{
                        compressImg(files[idx]["path"]).then(compressed_img=>{
                            resol(compressed_img)
                        }).catch(()=>{resol(files[idx]["path"])})
                    }).then(img_obj=>{
                        
                        let file_path = img_obj['url'];

                        let fileName = _this.md5(file_path);

                        if (img_obj['width'] && img_obj['height']){
                            files[idx]['width'] = img_obj['width'];
                            files[idx]['height'] = img_obj['height'];
                        }
                        // 上传oss
                        _this.aliUploadFile({
                            access_id: aliyun_access_data.access_id,
                            policy: aliyun_access_data.policy,
                            signature: aliyun_access_data.sign,
                            filePath: file_path,
                            fileName: fileName,
                            subPath: ALI_CONFIG['sub_path'],
                            success: async function (){
                                files[idx]["file_name"] = fileName;
                                //暂时只在小程序端鉴黄
                                if (_this.isWebview() == "wxa"){
                                    try {
                                        let issave = await _this.safetyCheck({
                                            "imgs": [{
                                                "host": store.state.appConfig.image_map.host_map['oss'],
                                                "path": ALI_CONFIG['sub_path'] + fileName
                                            }]
                                        }).catch(err=>{});
                                        if (issave == "block") {
                                            if (violate) {
                                                files[idx]["file_name"] = await violate() || null;
                                            } else {
                                                files[idx]["file_name"] = "";//图片暂时无法显示C9130828AE2347CD2AD3F8D4C98EDC89
                                            }
                                            files[idx]['width'] = 100;
                                            files[idx]['height'] = 100;
                                        } else {
                                            files[idx]["file_name"] = fileName;
                                        }
                                    } catch (error) {
                                        files[idx]["file_name"] = fileName;
                                        _this.log('upload_media_fail_3')
                                    }
                                } else {
                                    files[idx]["file_name"] = fileName;
                                }
                                count += 1;
                                if (count == maxlen){
                                    resolve(files)
                                }
                            },
                            fail: error=> {
                                _this.log('upload_media_fail_2')
                                // // 处理上传失败的图片
                                // count += 1;
                                // if (count == maxlen){
                                //     resolve(files)
                                // }
                                reject();
                            }
                        })
                    })
                })(i)
            }
        }).catch(err=>{
        })

        if (!_files_with_name && !retry) {
            let localIds = [];
            for (let i = 0 ; i < files.length ; i++) {
                localIds.push(files[i]['localId']);
            }
            this.log('upload_media_qiniu_retry');
            await new Promise((resolve, reject)=>{
                const getResults = ids => {
                    const localId = ids.shift();
                    let idx = localIds.indexOf(localId);
                    wx.uploadImage({
                        localId: localId,
                        isShowProgressTips: 0,
                        success (upload_img_res) {
                            new Promise((resol, rejec)=>{
                                _this.rpost('https://alifun.litiskr.cn/script/wx_qiniu_fetch', {
                                    'appid': wxConfigMsg.appId,
                                    'serverids': upload_img_res.serverId
                                }).then((upload_res) => {
                                    resol()
                                }).catch(()=>{
                                    resol()
                                })
                            }).then(()=>{
                                // 确保上传顺序
                                files[idx]["path"] = store.state.appConfig.image_map.host_map['qiniu'] + upload_img_res.serverId;
                                if (ids.length > 0) {
                                    getResults(ids)
                                } else {
                                    // 递归结束
                                    // console.log("files=>",JSON.stringify(files))
                                    return resolve(files)
                                }
                            }).catch(err=>{
                                _this.log('upload_media_fail_6')
                                if (ids.length > 0) {
                                    getResults(ids)
                                } else {
                                    // 递归结束
                                    return resolve(files)
                                }
                            })
                        },
                        fail () {
                            _this.log('upload_media_fail_7')
                            if (ids.length > 0) {
                                getResults(ids)
                            } else {
                                // 递归结束
                                return resolve(files)
                            }
                        }
                    })
                }
                getResults(_this.copy(localIds))
            })
            return this.uploadMediaToOss({
                "files": files,
                "violate": violate,
                "process": process,
                "maxsize": maxsize,
                "oversize": oversize,
                "retry": "qiniu"
            })
        }

        let image_infos = [];
        
        for (let i = 0 ; i < _files_with_name.length ; i++) {
            let _file = {};
            let _file_name = _files_with_name[i]["file_name"];
            if (_file_name){
                _file['url'] = store.state.appConfig.image_map.host_map['oss'] + ALI_CONFIG.sub_path + _file_name;
                if (_files_with_name[i]["width"] && _files_with_name[i]["height"]) {
                    _file["width"] = _files_with_name[i]["width"];
                    _file["height"] = _files_with_name[i]["height"];
                    image_infos.push(_file);
                } else {
                    let _info = await this.getImageInfo({
                        "src": _file['url']
                    }).catch(err=>{})
                    if (_info && _info['width'] && _info['height']) {
                        _file["width"] = _info[i]["width"];
                        _file["height"] = _info[i]["height"];
                        image_infos.push(_file);
                    }
                }
            }
        }
        return image_infos;
    },

    async chooseImage (options) {
        let _this = this,
            {count, sourceType, process} = options || {};
        let canIUseB64 = await _this.canIUseB64().catch(err=>{});
        // 选择图片
        let files = await new Promise((resolve, reject)=>{
            wx.chooseImage({
                count: count || 9, // 1-9
                sizeType: ['compressed'], // ['original', 'compressed']
                sourceType: sourceType || ['album'], // ['album', 'camera'] 可以指定来源是相册还是相机，默认二者都有，在H5浏览器端支持使用 `user` 和 `environment`分别指定为前后摄像头
                success: res=>{
                    // _this.countTime("finish_chose")
                    let localIds = res["localIds"],
                        localFiles = [],
                        dcount = 0;
                        
                    // 确保上传顺序
                    for (let i = 0 ; i < localIds.length ; i ++ ){
                        localFiles.push({
                            "localId": localIds[i]
                        })
                    }

                    // 选择图片完毕报告进度
                    process && process(0, localIds.length)
                    // 必须用递归实现逐张图片获取base64

                    const getResults = ids => {
                        const localId = ids.shift();
                        let idx = localIds.indexOf(localId);
                        if (canIUseB64) {
                            wx.getLocalImgData({
                                localId,
                                success: response => {
                                    dcount += 1;
                                    process && process(dcount, localIds.length)
                                    // _this.countTime("finish_getLocalImageData_1");
                                    let { localData } = response;
                                    // 适配安卓
                                    if (localData.indexOf('data:image') != 0) {
                                        //判断是否有这样的头部
                                        localData = 'data:image/jpeg;base64,' + localData.replace(/\r|\n/g, '');
                                    }
                                    // 确保上传顺序
                                    localFiles[idx]["path"] = localData;
                                    if (ids.length > 0) {
                                        getResults(ids)
                                    } else {
                                        // 递归结束
                                        return resolve(localFiles)
                                    }
                                },
                            })
                        } else {
                            wx.uploadImage({
                                localId: localId,
                                isShowProgressTips: 0,// 2021-07-09 临时规避8.07版本微信bug isShowProgressTips: 1 // 2022-01-25已修复
                                success (upload_img_res) {
                                    new Promise((resol, rejec)=>{
                                        _this.rpost('https://alifun.litiskr.cn/script/wx_qiniu_fetch', {
                                            'appid': wxConfigMsg.appId,
                                            'serverids': upload_img_res.serverId
                                        }).then((upload_res) => {
                                            resol()
                                        }).catch(()=>{
                                            resol()
                                        })
                                    }).then(()=>{
                                        dcount += 1;
                                        process && process(dcount, localIds.length)
                                        // 确保上传顺序
                                        localFiles[idx]["path"] = store.state.appConfig.image_map.host_map['qiniu']+upload_img_res.serverId;
                                        if (ids.length > 0) {
                                            getResults(ids)
                                        } else {
                                            // 递归结束
                                            return resolve(localFiles)
                                        }
                                    }).catch(()=>{
                                        _this.log('upload_media_fail_6')
                                    })
                                },
                                fail () {
                                    _this.log('upload_media_fail_7')
                                }
                            })
                        }
                    }
                    getResults(_this.copy(localIds))
                },
                fail: (err)=>{
                    _this.log('upload_media_fail_1')
                    reject()
                },
                cancel: ()=>{
                    _this.log('upload_media_canceled')
                    reject()
                }
            })
        }).catch(err=>{
            debugger;
        })
        // _this.countTime("finish_getLocalImageData_2");
        return files;
    },

    /** 
    * @函数名 媒体上传函数
    * @parm count (所选的媒体的数量) 1~9 <number>【必填】
    * @parm compressed (所选的媒体是否压缩) true、false <boolen>【必填】
    * @parm sourceType (所选的媒体的来源) ['album', 'camera'] <Array>【必填】
    * @parm maxDuration (拍摄时长) max:60 <number>【非必填】
    * @parm maxsize (所选的媒体的最大大小) 单位 M <number>【非必填】
    * @parm violate (违规处理函数) <Promise function>【非必填】
    * @parm oversize (文件过大处理函数) <Promise function>【非必填】
    * @parm process (进度处理函数)【非必填】
    * @parm fail (其他报错处理函数) <Promise function>【非必填】
    */
    async uploadMedia (options) {
        this.log('upload_media_start');
        
        let {count, original, sourceType, violate, oversize, process, fail} = options || {};

        let files = await this.chooseImage({
            count: count,
            sizeType: original ? ['original'] : ['compressed'],
            sourceType: sourceType,
            process: process,
        });

        if (!files) {
            this.log('upload_media_no_files');
            return Promise.reject();
        }

        this.log('upload_media_choosed_image');

        let img_data = await this.uploadMediaToOss({
            files: files,
            violate: violate,
            process: process,
            oversize: oversize,
        }).catch(err=>{});

        this.log('upload_media_end');

        if (!img_data || img_data.length < 1) {
            this.log('upload_media_fail_images_length_0')
            return Promise.reject();
        }

        return img_data;
    }
}

export default media_manager