<!-- 从屏幕中游过的鱼 -->
<template>
    <div class="po fish2-con" :style="getStyles">
        <img 
            v-for="idx in 9" 
            :src="getLuoItem(idx)" 
            :style="getLuoItemStyle(idx)"
            class="po fish2_item" 
            :class="'fish2_'+idx" 
            :key="'fish2_'+idx">
    </div>
</template>

<script>
    import BaseLuo from './BaseLuo'
    export default {
		name: 'Fish2',
		props: ["mode"],
		extends: BaseLuo,
		data () {
			return {}
		}
	}
</script>

<style>
    .fish2-con {
        top: 0!important;
        left: 500px!important;
    }
    .fish2_item {
        opacity: 0;
    }
    .fish2_1 {
        top: 262px;
        animation: fish2_3 9s linear 0s infinite;
    }
    .fish2_2 {
        top: 318px;
        animation: fish2_7 8s linear 5s infinite;
    }
    .fish2_3 {
        top: 501px;
        animation: fish2_2 10s linear 7s infinite;
    }
    .fish2_4 {
        top: 624px;
        animation: fish2_4 10s linear 0s infinite;
    }
    .fish2_5 {
        top: 378px;
        animation: fish2_4 7s linear 3s infinite;
    }
    .fish2_6 {
        top: 285px;
        animation: fish2_8 7s linear 9s infinite;
    }
    .fish2_7 {
        top: 388px;
        animation: fish2_5 8s linear 1s infinite;
    }
    .fish2_8 {
        top: 638px;
        animation: fish2_3 8s linear 6s infinite;
    }
    .fish2_9 {
        top: 369px;
        animation: fish2_5 8s linear 8s infinite;
    }
    @keyframes fish2_2 {
        0% {
            opacity: 0;
            transform: translate(0px,0);
        }
        5% {
            opacity: 1;
        }
        50% {
            opacity: 1;
            transform: translate(-350px,-80px);
        }
        100% {
            opacity: 1;
            transform: translate(-600px,10px);
        }
    }
    @keyframes fish2_3 {
        0% {
            opacity: 0;
            transform: translate(0px,0);
        }
        5% {
            opacity: 1;
        }
        50% {
            opacity: 1;
            transform: translate(-350px,-100px);
        }
        100% {
            opacity: 1;
            transform: translate(-600px,-30px);
        }
    }
    @keyframes fish2_4 {
        0% {
            opacity: 0;
            transform: translate(0px,0);
        }
        5% {
            opacity: 1;
        }
        50% {
            opacity: 1;
            transform: translate(-350px,100px);
        }
        100% {
            opacity: 1;
            transform: translate(-600px,20px);
        }
    }
    @keyframes fish2_5 {
        0% {
            opacity: 0;
            transform: translate(0px,0);
        }
        5% {
            opacity: 1;
        }
        50% {
            opacity: 1;
            transform: translate(-350px,100px);
        }
        100% {
            opacity: 1;
            transform: translate(-600px,20px);
        }
    }
    @keyframes fish2_7 {
        0% {
            opacity: 0;
            transform: translate(0px,0px);
        }
        5% {
            opacity: 1;
        }
        50% {
            opacity: 1;
            transform: translate(-381px,100px);
        }
        100% {
            opacity: 1;
            transform: translate(-631px,0);
        }
    }
    @keyframes fish2_8 {
        0% {
            opacity: 0;
            transform: translate(0px,0px);
        }
        5% {
            opacity: 1;
        }
        50% {
            opacity: 1;
            transform: translate(-381px,-100px);
        }
        100% {
            opacity: 1;
            transform: translate(-631px,0);
        }
    }
</style>