<template>
	<div class="p-page page-text">
		<div class="text-bd">
			<div class="tab-switch">
			<!-- <div class="tab-switch" v-if="$env.web == 'wxa'"> -->
				<span @click="switchTab">照片</span>
				<span class="active">字幕/标题</span>
			</div>
			<div class="text-scroll">
				<span class="p-title">
					<span>相册标题</span>
				</span>
				<div class="p-box">
					<div class="text-cell">
						<div class="text-cell-bd">
							<textarea v-model="title" rows="3" placeholder="请输入相册标题" class="text-title-input text-textarea" @blur="onTitleBlur"></textarea>
							<div class="text-title-counter">
								<span class="text-counter-num">{{titleInputCount}}</span>/{{titleInputMax}}
							</div>
						</div>
					</div>
				</div>

				<span class="p-title">
					<span>添加文字</span>
				</span>
				<div class="pic-unable-tips" v-show="!isTextAble">
					<p>抱歉，添加文字功能正在升级中，预计将于<span style="color: #ed5100;font-family: '黑体';">8月3日</span>恢复使用。</p>
					<p>非常感谢您的支持，敬请期待。</p>
				</div>
				<template v-for="(image, index) in images">
					<div class="p-box pic-box">
						<div class="pic-cell">
							<div class="pic-cell-index">{{index+1}}</div>
							<div class="pic-cell-photo" :style="{backgroundImage: 'url('+image['im']+')'}"></div>
							<div class="pic-cell-input-unable" v-show="!isTextAble" @click="onUnableClick">
								<span>{{text[index] ? "“"+text[index]+"”" : "功能升级中，敬请期待"}}</span>
							</div>
							<div class="pic-cell-input" v-show="isTextAble">
								<textarea v-model="text[index]" rows="3" placeholder="点击添加照片文字" class="text-pic-input text-textarea" @blur="onTextBlur(index)"></textarea>
								<span v-show="false">{{texta[index]}}</span>
								<div class="text-title-counter">
									<span class="text-counter-num">{{text[index].length}}</span>/{{textInputMax}}
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TabText',
		props: ['open_status', 'isTabShow'],
		data () {
			return {
				title: this.$store.state.config.data['ti'] || '',
				titleInputCount: (this.$store.state.config.data['ti'] || '').length,
				titleInputMax: 30,
				textInputMax: 30,
				text: [],
				isTextAble: true,
				modi: false
			}
		},
		computed: {
			images () {
				return this.$store.state.config.images;
			},
			texta () {
				let text = [];
				for(let i=0,len=this.$store.state.config.images.length; i<len; i++) {
					text.push( this.$store.state.config.images[i]['tx'] );
				}
				this.text = text;
				return text;
			}
		},
		watch: {
			title (txt) {
				this.titleInputCount = txt.length;
				if (txt.length > this.titleInputMax) {
					this.title = txt.substr(0, this.titleInputMax);
					this.$dialog.alert({
						'title': '提示',
						'message': '抱歉，当前标题超过字数限制'+this.titleInputMax+'字'
					});
				}
			},
			text (txts) {
				for (let i=0,len=txts.length; i<len; i++) {
					if (txts[i].length > this.textInputMax) {
						this.text[i] = this.text[i].substr(0, this.textInputMax);
						this.$dialog.alert({
							'title': '提示',
							'message': '抱歉，当前输入超过字数限制'+this.textInputMax+'字'
						})
						break;
					}
				}
			},
			// 打开窗口时更新title
			open_status () {
				if (this.open_status) {
					this.title = this.$store.state.config.data['ti'] || '';
				}
			}
		},
		methods: {
			onTextInput (event, index) {
				let text = event.currentTarget.value;
				if (text.length > this.textInputMax) {
					text = text.substr(0, this.textInputMax);
					this.$dialog.alert({
						'title': '提示',
						'message': '抱歉，当前输入超过字数限制'+this.textInputMax+'字'
					})
					return;
				}
				this.$store.commit('setImageText', {
					'text': text,
					'index': index
				})
			},
			onTextBlur (index) {
				let imageId = this.$store.state.config['images'][index]['id'],
					text = this.text[index],
					_this = this;

				this.$util.rpost('https://alifun.litiskr.cn/stverify/verify_api', {
					"__api__": "check_text",
					'app': 'vliti',
					'content': text,
					'mode': 'tx'
				}).then((response) => {
					if (response && response.suggestion == 'block') {
						_this.$toast.text({
							message: '文字中包含敏感内容，请检查后再试',
							duration: 2
						});
						_this.$set(_this.text, index, '');
					} else {
						_this.$store.commit('setImageText', {
							'text': text,
							'index': index
						});
						_this.$util.rpost('text_image', {
							'imageId': imageId,
							'text': _this.$o_util.encodeUserText(text),
							'key': _this.$route.query.key
						}).then((response) => {
							_this.$toast.text({
								message: '已保存',
								duration: 1
							});
							// 判断歌词是否可以显示
							if(_this.$store.state.wordCur.wordStatus == 1){
								if (_this.$audio.exist()) _this.$audio.closeLrc();
							} else {
								if (_this.$audio.exist()) _this.$audio.openLrc();
							}
							// 若请求结束时菜单处于关闭状态，则需要重新刷新编辑器 
							if (!_this.isTabShow) {
								_this.$emit('refreshPage');
							}
						});
					}
				})

				this.modi = true;

				this.$emit('toggleEdited', true);
			},
			onTitleBlur () {
				let _this = this;
				if (!this.title || this.title.length < 1){
					this.$store.dispatch('setdata', {
								k: 'ti',
								v: this.title,
								key: this.$route.query.key
					}).then(() => {
						_this.$toast.text({
							message: '已保存',
							duration: 1
						});
						document.title = "";
						if (!_this.isTabShow) {
							_this.$emit('refreshPage');
						}
						_this.$wx.share({
							title: _this.$o_util.getShareTitle()
						});
					})
				} else {
					// 保存title
					this.$util.rpost('https://alifun.litiskr.cn/stverify/verify_api', {
						"__api__": "check_text",
						'app': 'vliti',
						'content': this.title,
						'mode': 'tx'
					}).then((response) => {
						if (response && response.suggestion == 'block') {
							this.$toast.text({
								message: '文字中包含敏感内容，请检查后再试',
								duration: 2
							});
							this.title = '';
						} else {
							this.$store.dispatch('setdata', {
								k: 'ti',
								v: this.title,
								key: this.$route.query.key
							}).then(() => {
								// 若请求结束时菜单处于关闭状态，则需要重新刷新编辑器 
								if (!this.isTabShow) {
									this.$emit('refreshPage');
								}
							});
							this.$wx.share({
								title: this.title
							});
							this.$toast.text({
								message: '已保存',
								duration: 1
							});
							document.title = this.title;
						}
					});
				}

				this.modi = true;
				
				this.$emit('toggleEdited', true);
			},
			onUnableClick () {
				this.$toast.text({
					message: '功能升级中，暂不可修改',
					duration: 2
				})
			},
			switchTab () {
				this.$emit('hideTab');
				this.$emit('showTab', 'info');
			}
		},
	}
</script>

<style scoped lang="less">
	@orange: #ff6126;
	@orange-light: #ff9761;
   	@orange-dark: #ed5100;

   	.p-page {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		background-color: white;
	}
	.p-title {
		position: relative;
		display: block;
		font-size: @bw*.056;
		font-weight: bold;
		font-family: '黑体';
		padding: @bw*.028 @bw*.014;
	}
	.p-title>span {
		padding: @bw*.014;
   		border-left: solid @bw*0.01 @orange-dark;
   	}
	.p-box {
		position: relative;
		padding: 0 @bw*.028;
	}
   	.page-text {
   		margin-top: @tabtop;
   	}
   	.text-bd {
		position: relative;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
   	}
	.text-ft {
		position: fixed;
		border-top: solid 1px #ccc;
		width: 100%;
		height: @bw*.17;
		bottom: 0;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.text-scroll {
		padding-bottom: @bw*.181;
	}
	.text-confirm {
		position: absolute;
		color: @orange-dark;
		border: 3px solid @orange-dark;
		font-size: @bw*.042;
		width: @bw*.528;
		height: @bw*.097;
		line-height: @bw*.097;
		text-align: center;
		border-radius: @bw*.017;
		// left: 50%;
		// margin-left: -90px;
		// top: 50%;
		// margin-top: -35px;
	}

	.pic-unable-tips {
		font-size: @bw*.042;
		padding: @bw*.014 0;
		font-weight: bold;
		background-color: #efefef;
		margin-bottom: @bw*.014;
	}
	.pic-unable-tips>p {
		padding: 0 @bw*.036;
		line-height: @bw*.054;
	}
	.pic-cell-input-unable {
		position: relative;
		height: @bw*.222;
		font-size: @bw*.042;
		text-align: center;
		border: 1px solid #ccc;
		border-radius: @bw*.011;
		color: #555;
		background-color: #eee;
	}
	.pic-cell-input-unable>span {
		position: absolute;
		width: 100%;
		height: @bw*.222;
		line-height: @bw*.222;
		left: 0;
		top: 0;
	}

	.pic-box {
		// margin-bottom: @bw*.042;
	}
	.pic-cell {
		position: relative;
		display: flex;
		align-items: center;
		border-bottom: solid 2px #dcdcdc;
	}
	.pic-cell-index {
		width: @bw*.111;
		font-size: @bw*.042;
		line-height: @bw*.222;
		text-align: center;
	}
	.pic-cell-photo {
		width: @bw*.222;
		height: @bw*.222;
		// background-image: url('http://hb-mp-aliyunpic.oss-cn-beijing.aliyuncs.com/mmbiz_jpg/0006rwohadxFC0gQA5FVeBAWXKXbfXhTBmP0PiclupXUss1zAAvwastwb2sUNNciasbtzUKvgLyU9XwvXOaAsl0Q/0');
		background-position: center center;
		background-size: cover;
		margin: @bw*.017 @bw*.017 @bw*.017 0;
		border-radius: @bw*.014;
	}
	.pic-cell-input-unable {
		flex: 1;
	}
	.pic-cell-input {
		flex: 1;
		// background-color: #efefef;
	}
	.text-cell {
		position: relative;
		background-color: #efefef;
		// border-bottom: 1px solid #ccc;
	}
	.text-cell-bd {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: @bw*.035;
	}
	.text-textarea {
		resize: none;
		line-height: inherit;
		outline: 0;
		color: inherit;
		border: 0;
	}
	.text-title-input, .text-pic-input {
		display: block;
		font-size: @bw*.042;
    	background-color: inherit;
		line-height: @bw*.046;
	    letter-spacing: @bw*.002;
    	// margin: @bw*.014 0;
	}
	.text-pic-input {
		width: 90%;
		margin: @bw*.017 auto 0;
		// padding: 0 @bw*.014;
	}
	.text-title-input {
		width: 95%;
	}
	.text-title-counter {
		color: black;
		text-align: right;
		font-size: @bw*.042;
		// margin: @bw*.028 0;
		width: 95%;
	}
	.text-counter-num {
		color: @orange-light;
	}

	.tab-switch {
		position: relative;
		display: flex;
		justify-content: center;
		margin: 0 0 @bw*.028;
		border-bottom: solid 2px #ccc;
		span {
			flex: 1;
			height: @bw*.125;
			line-height: @bw*.125;
			text-align: center;
			// border: solid 2px #ed5100;
			color: #676767;
			font-size: @bw*.05;
			font-weight: bold;
		}
		span.active {
			color: #ed5100;
			border-bottom: solid 5px #ed5100;
		}
	}
</style>