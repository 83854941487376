<template>
	<div class="xiayupage">
	    <div class="con1">
	        <template v-if="Object.prototype.toString.call(mode[0]).toLowerCase() == '[object object]'">
	            <gif :mode="mode[0]"></gif>            
	        </template>
	        <template v-else>
	        	<div class="bg1" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div>
	        </template>
	        <div class="wrap">
	            <div @click="wxPreview" class="userImg userImg1" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg2" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg3" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg4" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg5" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg6" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg7" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg8" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg9" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg10" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg11" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg12" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg13" :style="getStyle"></div>
	            <div @click="wxPreview" class="userImg userImg14" :style="getStyle"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'xiayu',
		props: ['mode', 'currentImage', 'showStatus'],
		components: {},
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
		computed: {
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [500, 400], 'both');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				} else {
					return {
						width: '278px',
						height: '347px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.xiayupage {
		position:absolute;
		width:100%;
		height:100%;
		/* perspective:@bw * 1.389;
		*/
	overflow:hidden;
	}
	.con1 {
		position:absolute;
		width:100%;
		height:100%;
		/* perspective:@bw * 1388.889;
		*/
	}
	.wrap {
		position:absolute;
		width:100%;
		height:100%;
		transform-style:preserve-3d;
		/* transform:rotateX(-13deg) */
	}
	.userImg {
		position:absolute;
		top:-@bw * 0.833;
		// width:@bw * 0.556;
		// height:@bw * 0.694;
		transform-style:preserve-3d;
		/* background:pink;
		*/
	opacity:0;
		background:no-repeat center center;
		background-size:cover;
	}
	.bg1 {
		position:absolute;
		width:100%;
		height:100%;
		background:no-repeat center center;
		background-size:cover;
	}
	/* .bg2 {
		position:absolute;
		width:100%;
		height:100%;
		top:64%;
		background:url(img/haimian.jpg) no-repeat center top;
		background-size:cover;
		transform:rotateX(90deg);
		transform-origin:center 20%;
		perspective-origin:bottom;
	}
	*/
	.bg2 {
		position:absolute;
		width:100%;
		height:25%;
		bottom:-@bw * 0.014;
		background:no-repeat center top;
		background-size:cover;
		z-index:999;
	}
	.userImg1 {
		left:@bw * 0.222;
		animation:luo 12s -3s infinite linear;
	}
	.userImg2 {
		left:-@bw * 0.139;
		animation:luo 12s -5s forwards linear;
	}
	.userImg3 {
		left:@bw * 0.347;
		animation:luo 12s -7s forwards linear;
	}
	.userImg4 {
		left:@bw * 0.0;
		animation:luo 12s 2s infinite linear;
	}
	.userImg5 {
		right:-@bw * 0.083;
		animation:luo 12s 0s infinite linear;
	}
	.userImg6 {
		left:@bw * 0.278;
		animation:luo 12s 5s infinite linear;
	}
	.userImg7 {
		left:@bw * 0.556;
		animation:luo2 20s -10s infinite linear;
	}
	.userImg8 {
		left:-@bw * 0.069;
		animation:luo3 20s -5s infinite linear;
	}
	.userImg9 {
		left:@bw * 0.139;
		animation:luo2 20s 0s infinite linear;
	}
	.userImg10 {
		left:@bw * 0.0;
		animation:luo2 20s -10s infinite linear;
	}
	.userImg11 {
		right:@bw * 0.0;
		animation:luo3 20s -8s infinite linear;
	}
	.userImg12 {
		left:@bw * 0.278;
		animation:luo2 20s -4s infinite linear;
	}
	.userImg13 {
		right:-@bw * 0.208;
		animation:luo4 20s 2s infinite linear;
	}
	.userImg14 {
		left:-@bw * 0.208;
		animation:luo2 20s 6s infinite linear;
	}
	@keyframes luo {
		0% {
		opacity:1;
		top:-@bw * 1.389;
		transform:rotateY(-90deg);
	}
	50% {
		opacity:1;
		top:@bw * 0.694;
		transform:rotateY(0deg);
	}
	100% {
		opacity:1;
		top:@bw * 2.778;
		transform:rotateY(-90deg);
	}
	}@keyframes luo2 {
		0% {
		opacity:1;
		top:@bw * 0.0;
		transform:translateZ(-@bw * 2.083) rotateY(-90deg) scale(0.2);
	}
	50% {
		opacity:1;
		top:@bw * 1.042;
		transform:translateZ(-@bw * 2.083) rotateY(0deg) scale(0.2);
	}
	100% {
		opacity:1;
		top:@bw * 2.083;
		transform:translateZ(-@bw * 2.083) rotateY(-180deg) scale(0.2);
	}
	}@keyframes luo3 {
		0% {
		opacity:1;
		top:@bw * 0.0;
		transform:translateZ(-@bw * 1.389) rotateY(-90deg) scale(0.3);
	}
	50% {
		opacity:1;
		top:@bw * 1.042;
		transform:translateZ(-@bw * 1.389) rotateY(0deg) scale(0.3);
	}
	100% {
		opacity:1;
		top:@bw * 2.083;
		transform:translateZ(-@bw * 1.389) rotateY(-180deg) scale(0.3);
	}
	}@keyframes luo4 {
		0% {
		opacity:1;
		top:@bw * 0.0;
		transform:translateZ(-@bw * 2.083) rotateY(-90deg) scale(0.1);
	}
	50% {
		opacity:1;
		top:@bw * 1.042;
		transform:translateZ(-@bw * 2.083) rotateY(0deg) scale(0.1);
	}
	100% {
		opacity:1;
		top:@bw * 2.5;
		transform:translateZ(-@bw * 2.083) rotateY(-90deg) scale(0.1);
	}
	}@-webkit-keyframes hualuo1 {
		0% {
		-webkit-transform:scale(1.2) translate(0,0) rotateZ(0);
	}
	100% {
		-webkit-transform:scale(1.2) translate(-@bw * 0.347,@bw * 1.736) translateZ(-@bw * 0.694) rotateZ(213deg);
	}
	}@-webkit-keyframes hualuo2 {
		0% {
		-webkit-transform:scale(1.2) translate(0,0) rotateZ(-20deg);
	}
	100% {
		-webkit-transform:scale(1.2) translate(-@bw * 0.556,@bw * 1.736) rotateZ(340deg);
	}
	}@-webkit-keyframes hualuo3 {
		0% {
		-webkit-transform:scale(1.2) translate(0,0) rotateZ(-120deg);
	}
	100% {
		-webkit-transform:scale(1.2) translate(-@bw * 0.556,@bw * 1.736) rotateZ(150deg);
	}
	}@-webkit-keyframes hualuo4 {
		0% {
		-webkit-transform:scale(1.2) translate(0,0) rotateZ(60deg);
	}
	100% {
		-webkit-transform:scale(1.2) translate(-@bw * 0.431,@bw * 1.736) rotateZ(-260deg);
	}
	}@-webkit-keyframes hualuo5 {
		0% {
		-webkit-transform:translate(0,0) rotateZ(30deg);
	}
	100% {
		-webkit-transform:translate(-@bw * 0.625,@bw * 1.736) rotateZ(-320deg);
	}
	}
</style>