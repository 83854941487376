import Vue from 'vue'
import Router from 'vue-router'

import Editor from '@/views/editor/Editor'
import Share from '@/views/share/Share'
import Mybook from '@/views/Mybook'
import Empty from '@/views/empty/Empty'
import Search from '@/views/empty/Search'
import Merge from '@/views/merge/Merge'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/vliti/main/editor',
			name: 'editor',
			component: Editor
		},
		{
			path: '/vliti/main/leditor',
			name: 'leditor',
			component: Editor
		},
		{
			path: '/vliti/main/oeditor',
			name: 'oeditor',
			component: Editor
		},
		{
			path: '/vliti/main/share*',
			name: 'share',
			component: Share
		},
		{
			path: '/vliti/main/nshare',
			name: 'nshare',
			component: Share
		},
		{
			path: '/vliti/main/mshare',
			name: 'mshare',
			component: Share
		},
		{
			path: '/vliti/main/oshare',
			name: 'oshare',
			component: Share
		},
		{
			path: '/vliti/main/lshare',
			name: 'lshare',
			component: Share
		},
		{
			path: '/vliti/main/menu',
			name: 'menu',
			component: Mybook
		},
		{
			path: '/vliti/main/lmenu',
			name: 'menu',
			component: Mybook
		},
		{
			path: '/vliti/main/mybook',
			name: 'mybook',
			component: Mybook
		},
		{
			path: '/vliti/main/empty',
			name: 'empty',
			component: Empty
		},
		{
			path: '/vliti/main/merge',
			name: 'merge',
			component: Merge
		},
		{
			path: '/vliti/main/square',
			name: 'square',
			component: Mybook
		},
		{
			path: '/vliti/main/search',
			name: 'search',
			component: Search
		}
	]
})
