<template>
	<div id="bganim2">
        <div id="w-scalewing1">
            <img src="http://litiresource.xcgogo.site/user/5980171/images/b25a97fcc6570f63da8a4ea9428252d7.png" id="w-zuochi1">
            <img src="http://litiresource.xcgogo.site/user/5980171/images/b25a97fcc6570f63da8a4ea9428252d7.png" id="w-youchi1">
        </div>
        <div id="w-scalewing2">
            <img src="http://litiresource.xcgogo.site/user/5980171/images/b25a97fcc6570f63da8a4ea9428252d7.png" id="w-zuochi2">
            <img src="http://litiresource.xcgogo.site/user/5980171/images/b25a97fcc6570f63da8a4ea9428252d7.png" id="w-youchi2">
        </div>
        <div id="w-scalewing3">
            <img src="http://litiresource.xcgogo.site/user/5980171/images/b25a97fcc6570f63da8a4ea9428252d7.png" id="w-zuochi3">
            <img src="http://litiresource.xcgogo.site/user/5980171/images/b25a97fcc6570f63da8a4ea9428252d7.png" id="w-youchi3">
        </div>
    </div>
</template>

<script>
	/* eslint-disable */

	export default {
		name: 'huayudiemeng',
		data () {
			return {}
		}
	}
</script>

<style>
    #w-scalewing1 {
		position: absolute;
		transform-style: preserve-3d;
		width: 60px;
		height: 60px;
		left: -61%;
		bottom: 19%;
		animation: w-scalewing1 20s linear infinite;
    }
    @keyframes w-scalewing1 {
		0%{transform: translate3d(0px,0px,0px) rotateX(70deg) rotateZ(0deg)  rotateY(10deg);}
		30%{transform: translate3d(215px,-110px,-70px) rotateX(70deg) rotateZ(-10deg) rotateY(10deg);}
		45%{transform: translate3d(215px,-110px,--167.5px) rotateX(70deg) rotateZ(-20deg) rotateY(10deg);}
		50%{transform: translate3d(190px,-110px,-200px) rotateX(70deg) rotateZ(-40deg) rotateY(10deg) scale(1);}
		55%{transform: translate3d(130.7px,-140.3px,-211px) rotateX(45deg) rotateZ(-65deg) rotateY(45deg) scale(.8);}
		95%{transform: translate3d(-370px,-230px,-300px) rotateX(45deg) rotateZ(-70deg) rotateY(45deg) scale(.6);}
		100%{transform: translate3d(-420px,-260px,-300px) rotateX(45deg) rotateZ(-70deg) rotateY(45deg) scale(.4);}
    }
    #w-zuochi1 {
		position:absolute;
		transform-origin: 100% 50%;
		animation: w-flap1 .4s linear infinite alternate;
    }
    @keyframes w-flap1 {
		0%{transform: rotateY(0deg);}
		100%{transform: rotateY(90deg);}
    }
    @keyframes w-flap2 {
		0%{transform: rotateY(0deg) scale(-1,1);}
		100%{transform: rotateY(-90deg) scale(-1,1);}
    }
    #w-youchi1 {
		position: absolute;
		transform-origin: 100% 50%;
		transform: scale(-1,1);
		animation: w-flap2 .4s linear infinite alternate;
    }
    #w-scalewing2 {
		position: absolute;
		transform-style: preserve-3d;
		width: 60px;
		height: 60px;
		left: -60%;
		bottom: 25%;
		transform: translateZ(-200px) rotateX(72deg) rotateY(36deg) rotateZ(-73deg) scale(.8);
    }
    #w-zuochi2 {
		position: absolute;
		transform-origin: 100% 50%;
		animation: w-flap1 1s linear infinite alternate;
    }
    #w-youchi2 {
		position: absolute;
		transform-origin: 100% 50%;
		transform: scale(-1,1);
		animation: w-flap2 1s linear infinite alternate;
    }
     #w-scalewing3 {
		position: absolute;
		transform-style: preserve-3d;
		width: 60px;
		height: 60px;
		left: -61%;
		bottom: 23%;
		transform: translate3d(0px,0px,0px) rotateX(45deg) rotateY(0deg) rotateZ(0deg);
		animation: w-scalewing3 linear 20s 10s infinite ;
    }
    @keyframes w-scalewing3 {
		0%{transform: translate3d(0px,0px,0px) rotateX(80deg) rotateY(10deg) rotateZ(10deg) ;}
		/*20%{transform: translate3d(0px,-130px,0px) rotateX(70deg) rotateY(30deg) rotateZ(-20deg);}*/
		/*25%{transform: translate3d(-10px,-165px,-12.5px) rotateX(70deg) rotateY(30deg) rotateZ(-30deg);}
		35%{transform: translate3d(-40px,-270px,-50px) rotateX(70deg) rotateY(5deg) rotateZ(-20deg) scale(1);}*/
		100%{transform: translate3d(900px,-300px,-500px) rotateX(90deg) rotateY(-10deg) rotateZ(45deg) scale(.5);}
    }
    #w-zuochi3 {
		position: absolute;
		transform-origin: 100% 50%;
		animation: w-flap1 .3s linear infinite alternate;
    }
    #w-youchi3 {
		position: absolute;
		transform-origin: 100% 50%;
		transform: scale(-1,1);
		animation: w-flap2 .3s linear infinite alternate;
    }
</style>