<template>
	<div class="fanzhuan bg" :style="{backgroundImage: 'url('+ mode[0] +')'}">
	    <div class="fanzhuan_con poc" :class="{animated: animated}">
	        <div class="fanzhuan_6 fanzhuan_page7 po">
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_5 fanzhuan_page7 po">
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_4 fanzhuan_page7 po">
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_3 fanzhuan_page7 po">
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_2 fanzhuan_page7 po">
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_1 fanzhuan_page7 po">
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'fanzhuan',
		props: ['mode', 'currentImage'],
		data () {
			return {
				animated: false
			}
		},
		created () {
			
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			},
			start () {
				this.animated = true;
			},
			end () {
				setTimeout(() => {
					this.animated = false;
				}, 1000);
			}
		}
	}
</script>

<style scoped lang="less">
	.div9 {
		background-color: #eee;
	}
	.fanzhuan {
		position: absolute;
		width: 100%;
		height: 100%;
		perspective: @bw;
	}
	.fanzhuan_con {
	    -webkit-transform-style: preserve-3d;
	    perspective: 888px;
	}
	.animated {
		animation: fanzhuan 4s linear both;
		// animation: fanzhuan 4s 1.6s linear both;
	}
	.page {
	    width: 100%;
	    height: 100%;
	}
	.po {
	    position: absolute;
	}
	.div {
	    // background-size: cover;
	    // background-position: center 10%;
	    // background-repeat: no-repeat;
	}
	.div .divText {
	    bottom: 48px;
	    font-size: 26px;
	}
	.divText {
	    padding: 0px 30px;
	    max-width: 500px;
	    width: 500px;
	    left: 0;
	    pointer-events: none;
	}
	.divText {
	    padding: 6px 12px;
	    max-width: 80%;
	    line-height: 140%;
	    color: #FFF;
	    font-weight: bold;
	    letter-spacing: 3px;
	    text-align: center;
	    position: absolute;
	    text-shadow: 0 0 5px #000, 0 0 10px #000, 0 0 15px #000, 0 0 40px #000, 0 0 70px #000;
	    font-size: 21px;
	    bottom: 25px;
	    left: 25px;
	}
	.fanzhuan_6 {
	    right: -225px;
	    top: 39%;
	    bottom: 0;
	    transform: translateZ(-400px);
	    animation: fanzhuan_4 12s linear infinite both;
	}
	.fanzhuan_page7 {
	    width: 185px;
	    height: 138px;
	    margin: auto;
	    -webkit-transform-origin: 35% 50%;
	    -webkit-transform-style: preserve-3d;
	}
	.fanzhuan_page7>div {
	    -webkit-transform-style: preserve-3d;
	    -webkit-animation: fanzhuan_1x 4s 1.6s linear both;
	}
	@keyframes fanzhuan_1x {
		0% {
		    -webkit-transform: rotateX(-360deg) translate(-100px,100px);
		}
		100% {
		    -webkit-transform: rotateX(0) translate(0,0);
		}
	}
	.fanzhuan_page7>div>div {
	    border: 2px solid #FFFFFF;
	    // background-clip: content-box;
	}
	.fanzhuan_1_1 {
	    transform: translateZ(178px);
	}
	.fanzhuan_1_2 {
	    transform: rotateY(60deg) translateZ(178px);
	}
	.fanzhuan_1_3 {
	    transform: rotateY(120deg) translateZ(178px);
	}
	.fanzhuan_1_4 {
	    transform: rotateY(180deg) translateZ(178px);
	}
	.fanzhuan_1_5 {
	    transform: rotateY(240deg) translateZ(178px);
	}
	.fanzhuan_1_6 {
	    transform: rotateY(300deg) translateZ(178px);
	}
	#page7 .divText {
	    bottom: 10px;
	    -webkit-transform-origin: left bottom;
	    transform: translateZ(0) scale(0.37);
	}
	.fanzhuan_5 {
	    right: -225px;
	    top: 0;
	    bottom: 5%;
	    transform: translateZ(-400px);
	    animation: fanzhuan_4 12s linear infinite both;
	}
	.fanzhuan_4 {
	    left: -180px;
	    top: 41%;
	    bottom: 0;
	    transform: translateZ(-400px);
	    animation: fanzhuan_4 12s linear infinite both;
	}
	.fanzhuan_3 {
	    left: -250px;
	    top: 0;
	    bottom: 8%;
	    transform: translateZ(-383px) rotateX(-12deg);
	    animation: fanzhuan_3 12s linear infinite both;
	}
	.fanzhuan_2 {
	    left: 90px;
	    right: 0;
	    top: 27%;
	    bottom: 0;
	    transform: translateZ(-83px) rotateY(0deg);
	    animation: fanzhuan_2 12s linear infinite both;
	}
	.fanzhuan_1 {
	    left: 88px;
	    right: 0;
	    top: 0;
	    bottom: 19%;
	    animation: fanzhuan_1 12s linear infinite both;
	}
	@keyframes fanzhuan_4 {
		0% {
		    transform: translateZ(-400px) rotateY(0deg);
		}
		100% {
		    transform: translateZ(-400px) rotateY(360deg);
		}
	}
	@keyframes fanzhuan_3 {
		0% {
		    transform: translateZ(-383px) rotateX(-12deg) rotateY(0deg);
		}
		100% {
		    transform: translateZ(-383px) rotateX(-12deg) rotateY(360deg);
		}
	}
	@keyframes fanzhuan_2 {
		0% {
		    transform: translateZ(-83px) rotateY(0deg);
		}
		100% {
		    transform: translateZ(-83px) rotateY(360deg);
		}
	}
	@keyframes fanzhuan_1 {
		0% {
		    transform: rotateY(0);
		}
		100% {
		    transform: rotateY(360deg);
		}
	}
	@keyframes fanzhuan {
		0% {
		    transform: rotateX(-360deg) rotateY(180deg);
		}
		100% {
		    transform: rotateX(0) rotateY(0);
		}
	}
</style>

<!-- <template>
	<div class="box7_bg_in" :style="{backgroundImage: 'url('+ mode[0] +')'}">
	    <div class="box7_bg">
	        <div class="box7 box7_ani1">
	            <div class="box7_img img1" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img2" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img3" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img4" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img5" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img6" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="box7 box7_ani2">
	            <div class="box7_img img1" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img2" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img3" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img4" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img5" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img6" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="box7 box7_ani3">
	            <div class="box7_img img1" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img2" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img3" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img4" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img5" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img6" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="box7 box7_ani4">
	            <div class="box7_img img1" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img2" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img3" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img4" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img5" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img6" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="box7 box7_ani5">
	            <div class="box7_img img1" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img2" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img3" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img4" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img5" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img6" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="box7 box7_ani6">
	            <div class="box7_img img1" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img2" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img3" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img4" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img5" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="box7_img img6" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'fanzhuan',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		created () {
			
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style scoped lang="less">
	.box7 {
		position:relative;
		width:100%;
		height:@bw * 0.278;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		transform-style:preserve-3d;
	}
	@keyframes animate {
		0% {
			transform:rotateX(0deg);
		}
		100% {
			transform:rotateX(360deg);
		}
	}.box7_ani1 {
		animation:animate71 12s linear infinite;
		-o-animation:animate71 12s linear infinite;
		animation:animate71 12s linear infinite;
		top:43%;
		left:-143%;
		transform:rotateX(-12deg) translateZ(-@bw * 1.618);
	}
	.box7_ani2 {
		animation:animate7 12s linear infinite;
		-o-animation:animate72 12s linear infinite;
		animation:animate72 12s linear infinite;
		top:1%;
		transform:translateZ(-@bw * 0.093);
	}
	.box7_ani3 {
		animation:animate73 12s linear infinite;
		-o-animation:animate73 12s linear infinite;
		animation:animate73 12s linear infinite;
		top:22%;
		transform:translateZ(-@bw * 0.417);
	}
	.box7_ani4 {
		animation:animate74 12s linear infinite;
		-o-animation:animate74 12s linear infinite;
		animation:animate74 12s linear infinite;
		transform:translateZ(-@bw * 1.618);
		left:-118%;
		z-index:-1;
		top:17%;
	}
	.box7_ani5 {
		animation:animate75 12s linear infinite;
		-o-animation:animate75 12s linear infinite;
		animation:animate75 12s linear infinite;
		z-index:-1;
		right:-106%;
	}
	.box7_ani6 {
		animation:animate76 12s linear infinite;
		-o-animation:animate76 12s linear infinite;
		animation:animate76 12s linear infinite;
		z-index:-1;
		right:-106%;
		top:-49%;
	}
	@keyframes animate7 {
		0% {
		transform:rotateY(0deg);
	}
	100% {
		transform:rotateY(360deg);
	}
	}@keyframes animate71 {
		0% {
		transform:rotateX(-12deg) translateZ(-@bw * 1.618) rotateY(0deg);
	}
	100% {
		transform:rotateX(-12deg) translateZ(-@bw * 1.618) rotateY(360deg);
	}
	}@keyframes animate72 {
		0% {
		transform:translateZ(-@bw * 0.389) rotateY(0deg);
	}
	100% {
		transform:translateZ(-@bw * 0.389) rotateY(360deg);
	}
	}@keyframes animate73 {
		0% {
		transform:translateZ(-@bw * 0.667) rotateY(0deg);
	}
	100% {
		transform:translateZ(-@bw * 0.667) rotateY(360deg);
	}
	}@keyframes animate74 {
		0% {
		transform:translateZ(-@bw * 1.618) rotateY(0deg);
	}
	100% {
		transform:translateZ(-@bw * 1.618) rotateY(360deg);
	}
	}@keyframes animate75 {
		0% {
		transform:translateZ(-@bw * 1.618) rotateY(0deg);
	}
	100% {
		transform:translateZ(-@bw * 1.618) rotateY(360deg);
	}
	}@keyframes animate76 {
		0% {
		transform:translateZ(-@bw * 1.618) rotateY(0deg);
	}
	100% {
		transform:translateZ(-@bw * 1.618) rotateY(360deg);
	}
	}.box7_img {
		position:absolute;
		width:@bw * 0.556;
		height:@bw * 0.417;
		background:#8959a8;
		top:@bw * 0.139;
		left:@bw * 0.347;
		box-shadow:inset 0 0@bw * 0.007 #bbbbbb;
		border:@bw * 0.007 solid white;
	}
	.img1 {
		background-repeat:no-repeat;
		background-position: center 20%;
		background-size:cover;
		transform:rotateY(0deg) translateZ(@bw * 0.556);
	}
	.img2 {
		background-repeat:no-repeat;
		background-position: center 20%;
		background-size:cover;
		transform:rotateY(60deg) translateZ(@bw * 0.556);
	}
	.img3 {
		background-repeat:no-repeat;
		background-position: center 20%;
		background-size:cover;
		transform:rotateY(120deg) translateZ(@bw * 0.556);
	}
	.img4 {
		background-repeat:no-repeat;
		background-position: center 20%;
		background-size:cover;
		transform:rotateY(180deg) translateZ(@bw * 0.556);
	}
	.img5 {
		background-repeat:no-repeat;
		background-position: center 20%;
		background-size:cover;
		transform:rotateY(240deg) translateZ(@bw * 0.556);
	}
	.img6 {
		background-repeat:no-repeat;
		background-position: center 20%;
		background-size:cover;
		transform:rotateY(300deg) translateZ(@bw * 0.556);
	}
	/* .box7_bg {
		width:100%;
		height:100%;
		position:relative;
		background-size:100% 100%;
		-webkit-perspective:@bw * 0.694;
		-moz-perspective:@bw * 0.694;
		perspective:@bw * 0.694;
		top:-5%;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		transform-style:preserve-3d;
		animation:animate 5s linear 1;
	}
	*/
	.box7_bg {
		width:100%;
		height:100%;
		position:relative;
		background-size:100% 100%;
		-webkit-perspective:@bw * 0.694;
		-moz-perspective:@bw * 0.694;
		perspective:@bw * 0.694;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		transform-style:preserve-3d;
		animation:animate 5s linear 1;
	}
	.box7_bg_in {
		background-repeat:no-repeat;
		background-position:center;
		background-size:100% 100%;
		width:100%;
		height:100%;
		position:relative;
	}
</style> -->