<template>
	<div class="model-page">
	    <div class="perspective">
	        <div class="panorama_1">
	        	<template v-if="mode['room']">
	        		<template v-if="mode['room']['top']['gif']">
	        			<div class="bg bg_photo panorama_1_item panorama_1_top">
            				<gif :mode="mode['room']['top']['gif']"></gif>
            			</div>
	        		</template>
	        		<template v-else>
	        			<div class="bg bg_photo panorama_1_item panorama_1_top" :style="{backgroundImage: 'url('+ mode['room']['top']['im'] +')'}"></div>
	        		</template>
	        	</template>
	        	<template v-else>
	        		<div class="bg bg_photo panorama_1_item panorama_1_top" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div>
	        	</template>


	            <div class="bg bg_photo panorama_1_item panorama_1_mid_1" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
	            </div>
	            <div class="bg bg_photo panorama_1_item panorama_1_mid_2" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
	            </div>
	            <div class="bg bg_photo panorama_1_item panorama_1_mid_3" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
	            </div>
	            <div class="bg bg_photo panorama_1_item panorama_1_mid_4" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
	            </div>

	            <template v-if="mode['room']">
	            	<template v-if="mode['room']['bottom']['gif']">
	            		<div class="bg bg_photo panorama_1_item panorama_1_bottom">
            				<gif :mode="mode['room']['bottom']['gif']"></gif>
            			</div>
	            	</template>
	            	<template v-if="mode['room']['bottom']['elems']">
		        		<div class="bg bg_photo panorama_1_item panorama_1_bottom" :style="{backgroundImage: 'url('+ mode['room']['bottom']['im'] +')'}">
		        			<template v-if="mode['room']['bottom']['elems']">
		        				<template v-for="mode in mode['room']['bottom']['elems']">
									<elem :mode="mode"></elem>
								</template>
			            	</template>
		        		</div>
	            	</template>
	            	<template v-else>
	            		<div 
	            			class="bg bg_photo panorama_1_item panorama_1_bottom" 
	            			v-if="mode['room']['bottom']['im']"
	            			:style="{backgroundImage: 'url('+ mode['room']['bottom']['im'] +')'}"></div>
	            	</template>
	        	</template>
	        	<template v-else>
	        		<div class="bg bg_photo panorama_1_item panorama_1_bottom" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	        	</template>
	        </div>
	    </div>
	    <div class="perspective_2">
	        <div class="panorama_2">
	        	<template v-if="mode['room2']">
	        		<template v-if="mode['room2']['huabanshan']">
	        			<div class="huabanshan huabanshan_1">
							<div class="huabanshan_box_01" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_02" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_03" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_04" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_05" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_06" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_07" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_08" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_09" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
						</div>
						<div class="huabanshan huabanshan_2">
							<div class="huabanshan_box_01" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_02" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_03" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_04" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_05" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_06" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_07" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_08" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
							<div class="huabanshan_box_09" :style="{background: 'linear-gradient(45deg, '+ mode['room2']['huabanshan']['gradient'][0] +' 8%, '+ mode['room2']['huabanshan']['gradient'][1] +' 30%, '+ mode['room2']['huabanshan']['gradient'][2] +' 100%)'}"></div>
						</div>
	        		</template>
		        	<template v-else-if="mode['room2']['elems']">
		        		<div class="panorama_1_2">
				        	<div class="panorama_1_2_elem" v-for="el in mode['room2']['elems']" :style="{'width': el['size'][0]+'px', 'height': el['size'][1]+'px', 'transform': 'translate3d('+el['pos'][0]+'px, '+el['pos'][1]+'px, '+el['pos'][2]+'px'}">
				        		<img :src="el['im']" class="fanzhuan">
				        	</div>
				        </div>
		        	</template>
		        	<template v-else>
		        		<div class="square_1">
		            		<template v-if="mode['room']">
			            		<template v-if="mode['room']['top']['gif']">
			            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['gif']['cover'] +')'}"></div>
			            		</template>
			            		<template v-else>
		            				<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['im'] +')'}"></div>
			            		</template>
		            		</template>
		            		<template v-else>
		            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div>
		            		</template>
		            		
			                <div class="square_item square_mid_1" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_mid_2" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_mid_3" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_mid_4" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_bottom"></div>
			            </div>
			            <div class="square_2">
	            			<template v-if="mode['room']">
			            		<template v-if="mode['room']['top']['gif']">
			            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['gif']['cover'] +')'}"></div>
			            		</template>
			            		<template v-else>
		            				<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['im'] +')'}"></div>
			            		</template>
		            		</template>
		            		<template v-else>
		            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div>
		            		</template>
			            	
			                <div class="square_item square_mid_1" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_mid_2" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_mid_3" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_mid_4" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
			                </div>
			                <div class="square_item square_bottom"></div>
			            </div>
		        	</template>

		        	<huaban :mode="mode['room2']['huaban']" v-if="mode['room2']['huaban']"></huaban>
		            <hudie5 :mode="mode['room2']['hudie']" v-if="mode['room2']['hudie']"></hudie5>
		            <shan :mode="mode['room2']['shan']" v-if="mode['room2']['shan']"></shan>
		            <piao :mode="mode['room2']['piao']" v-if="mode['room2']['piao']"></piao>
		            <piao2 :mode="mode['room2']['piao2']" v-if="mode['room2']['piao2']"></piao2>
	        	</template>
	        	<template v-else-if="mode['room2'] === null">
	        	</template>
	        	<template v-else>
	            	<div class="square_1">
	            		<template v-if="mode['room']">
		            		<template v-if="mode['room']['top']['gif']">
		            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['gif']['cover'] +')'}"></div>
		            		</template>
		            		<template v-else>
	            				<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['im'] +')'}"></div>
		            		</template>
	            		</template>
	            		<template v-else>
	            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div>
	            		</template>
	            		
		                <div class="square_item square_mid_1" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_mid_2" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_mid_3" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_mid_4" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_bottom"></div>
		            </div>
		            <div class="square_2">
            			<template v-if="mode['room']">
		            		<template v-if="mode['room']['top']['gif']">
		            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['gif']['cover'] +')'}"></div>
		            		</template>
		            		<template v-else>
	            				<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode['room']['top']['im'] +')'}"></div>
		            		</template>
	            		</template>
	            		<template v-else>
	            			<div class="square_item square_top" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div>
	            		</template>
		            	
		                <div class="square_item square_mid_1" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_mid_2" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_mid_3" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_mid_4" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
		                </div>
		                <div class="square_item square_bottom"></div>
		            </div>
	            </template>

	        </div>
	    </div>

	</div>

</template>

<script>
	let G = {
		idx: 0,
		effect_arr: ['effect-one', 'effect-two']
	}

	export default {
		name: 'fangkuai',
		props: ['mode', 'currentImage'],
		components: {},
		mounted () {},
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.model-page {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.perspective {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-perspective: @bw * 0.896;
	}
	.panorama_1, .panorama_1_2 {
		position: absolute;
		width: 500px;
		height: 500px;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		transform: rotateY(0deg);
		transform-style: preserve-3d;
		animation: panorama_1 12s linear infinite both;
	}
	@keyframes panorama_1 {
		0% {
		    transform: rotateY(0);
		}
		100% {
		    transform: rotateY(360deg);
		}
	}
	.panorama_1_2_elem {
		position: absolute;
		-webkit-transform-style: preserve-3d;
	}
	.panorama_1_2_elem > img {
		position: absolute;
		width: 100%;
		height: 100%;
		animation: panorama_1_fanzhuan 12s linear infinite both;
	}
	@keyframes panorama_1_fanzhuan {
		0% {
		    transform: rotateY(0);
		}
		100% {
		    transform: rotateY(-360deg);
		}
	}
	.panorama_1_item {
		position: absolute;
		width: 500px;
		height: 500px;
		-webkit-transform-style: preserve-3d;
		-webkit-backface-visibility: hidden;
		// background-size: cover;
		// background-position: center;
		// background-repeat: no-repeat;
	}
	.panorama_1_top {
		transform: rotateX(-90deg) translateZ(-248px);
	}
	.panorama_1_bottom {
		-webkit-transform: rotateX(90deg) translateZ(-248px);
		div {
			position: absolute;
			width:@bw * 0.278;
		}
	}
	.panorama_1_mid_1 {
		-webkit-transform: translateZ(-248px);
	}
	.panorama_1_mid_2 {
		-webkit-transform: rotateY(-90deg) translateZ(-248px);
	}
	.panorama_1_mid_3 {
		-webkit-transform: rotateY(180deg) translateZ(-248px);
	}
	.panorama_1_mid_4 {
		-webkit-transform: rotateY(90deg) translateZ(-248px);
	}

	.perspective_2 {
		position: absolute;
		width: 500px;
		height: 500px;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		-webkit-transform: rotateY(0deg);
		-webkit-transform-style: preserve-3d;
		-webkit-perspective: 448px;
		pointer-events: none;
		-webkit-transform-style: initial;
	}
	.panorama_2 {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
	}
	.square_1 {
		left: -175px;
		bottom: -250px;
	}
	.square_2 {
		right: -175px;
		bottom: -250px;
	}
	.square_1, .square_2 {
		position: absolute;
		width: 250px;
		height: 250px;
		-webkit-transform-style: preserve-3d;
		// bottom: 50px;
		-webkit-animation: square_rotate 7.5s linear infinite both;
	}
	@keyframes square_rotate {
		0% {
		    transform: translateZ(-680px) rotateY(0);
		}
		100% {
		    transform: translateZ(-680px) rotateY(-360deg);
		}
	}
	.square_item {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
		background-size: cover;
		background-position: center;
		background-color: #eee;
	}
	.square_top {
		transform: rotateX(90deg) translateZ(125px);
	}
	.square_mid_1 {
		transform: translateZ(125px);
	}
	.square_mid_2 {
		transform: rotateY(-90deg) translateZ(125px);
	}
	.square_mid_3 {
		transform: rotateY(180deg) translateZ(125px);
	}
	.square_mid_4 {
		transform: rotateY(90deg) translateZ(125px);
	}
	.square_bottom {
		transform: rotateX(-90deg) translateZ(125px);
	}

	.huabanshan {
		position: absolute;
		transform-style: preserve-3d;
		width: 250px;
		height: 250px;
		bottom: -160px;
		background-size: cover;
		background-position: center 20%;
		background-repeat: no-repeat;
	}
	.huabanshan_1 {
		left: 69.5px;
		// bottom: 139px;
		transform: translateZ(-437.5px) translateX(-243px);
	}
	.huabanshan_2 {
		right: 69.5px;
		// bottom: 139px;
		transform: translateZ(-437.5px) translateX(243px);
	}
	.huabanshan_box_01, .huabanshan_box_02, .huabanshan_box_03, .huabanshan_box_04, .huabanshan_box_05, .huabanshan_box_06, .huabanshan_box_07, .huabanshan_box_08, .huabanshan_box_09 {
	    width:@bw * 0.417;
	    height:@bw * 0.417;
	    border-radius:@bw * 0.417 0;
	    /* background: pink; */
	    opacity: 0.6;
	    position: absolute;
	    bottom:@bw * 0.139;
	    left:@bw * 0.254;
	    transform-origin: 0 @bw * 0.417;
	    transform: rotate(-45deg);
	    transition: all 8s;
	    // background: linear-gradient(45deg, #bcbec0 8%, #eca5b2 30%, #fd1a94 100%);
	    animation-timing-function: ease-in-out;
	}
	.huabanshan_box_02, .huabanshan_box_01 {
	    // animation: fangkuai-bac 3s .51s linear infinite alternate both;
	}
	.huabanshan_box_03 {
	    animation: fangkuai-huabanshan3 3s .51s linear infinite alternate both;
	    // ,fangkuai-bac 3s .51s linear infinite alternate both;
	}
	.huabanshan_box_04 {
	    animation: fangkuai-huabanshan4 3s .51s linear infinite alternate both;
	    // ,fangkuai-bac 3s .51s linear infinite alternate both;
	}
	.huabanshan_box_05 {
	    animation: fangkuai-huabanshan5 3s .51s linear infinite alternate both;
	    // ,fangkuai-bac 3s .51s linear infinite alternate both;
	}
	.huabanshan_box_06 {
	    animation: fangkuai-huabanshan6 3s .51s linear infinite alternate both;
	    // ,fangkuai-bac 3s .51s linear infinite alternate both;
	}
	.huabanshan_box_07 {
	    animation: fangkuai-huabanshan7 3s .51s linear infinite alternate both;
	    // ,fangkuai-bac 3s .51s linear infinite alternate both;
	}
	.huabanshan_box_08 {
	    animation: fangkuai-huabanshan8 3s .51s linear infinite alternate both;
	    // ,fangkuai-bac 3s .51s linear infinite alternate both;
	}
	.huabanshan_box_09 {
	    animation: fangkuai-huabanshan9 3s .51s linear infinite alternate both;
	    // ,fangkuai-bac 3s .51s linear infinite alternate both;
	}
	@keyframes fangkuai-bac {
		100% {
		    background: linear-gradient(45deg, #bcbec0 8%, #ffbdc9 30%, #fd1a1a 100%);
		}
	}
	@keyframes fangkuai-huabanshan3 {
		100% {
		    transform: rotate(-23deg);
		}
	}
	@keyframes fangkuai-huabanshan4 {
		100% {
		    transform: rotate(0deg);
		}
	}
	@keyframes fangkuai-huabanshan5 {
		100% {
	        transform: rotate(23deg);
		}
	}
	@keyframes fangkuai-huabanshan6 {
		100% {
		    transform: rotate(45deg);
		}
	}
	@keyframes fangkuai-huabanshan6 {
		100% {
		    transform: rotate(-68deg);
		}
	}
	@keyframes fangkuai-huabanshan7 {
		100% {
		    transform: rotate(45deg);
		}
	}
	@keyframes fangkuai-huabanshan8 {
		100% {
		    transform: rotate(-90deg);
		}
	}
	@keyframes fangkuai-huabanshan9 {
		100% {
		    transform: rotate(-113deg);
		}
	}
</style>