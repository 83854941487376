<template>
	<div
        class="rec-arti-container re-container"
        @click="cellClicked">
        <img class="cellbgbottom-image" src="http://resali.lititutu.cn/tuku/prod/e31e72175d302d068b87adc5371eb115.png" />
        <div class="arti-left">
            <img
                class="arti-left-image"
                :src="bless.coverImg.replace('mmbiz.qpic.cn', 'qlogo.lititutu.cn')"
                mode="fill" />
            <img
                class="arti-cover-img"
                :src="bless.border.replace('mmbiz.qpic.cn', 'qlogo.lititutu.cn')" />
        </div>
        <div class="arti-right container">
            <span class="arti-title">{{bless.title}}</span>
        </div>
    </div>
</template>

<script>
	export default {
        name: 'BlessCell',
        props: ['bless'],
		components: {},
		data () {
			return {
			}
		},
		created () {
			
		},
		methods: {
			cellClicked(){
        this.$emit('goBlessDetail', this.bless)
      },
		}
	}
</script>

<style>
.rec-arti-container {
  position: relative;
  background: rgba(255, 255, 255, 1);
  box-shadow: var(--box_shadow);
  border-radius: 4px;
  width: 476px;
  margin: 10px 12px 10px;
  align-items: stretch;
  padding: 16px 0;
}

.arti-title {
  margin-top: 8px;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.arti-bottom {
  color: #999;
  font-weight: 400;
  font-size: 24px;
  justify-self: flex-end;
}

.cellbgbottom-image {
  width: 244px;
  height: 54px;
  position: absolute;
  right: 8px;
  bottom: 0;
}

.arti-right {
  flex-grow: 1;
  margin: 0 8px auto 16px;
  justify-content: flex-start;
  align-items: stretch;
}

.arti-left {
  position: relative;
  margin-left: 16px;
}

.arti-left-image {
  position: relative;
  width: 174px;
  height: 174px;
  border-radius: 50%;
}

.arti-cover-img {
  position: absolute;
  left: -12px;
  top: -12px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
</style>