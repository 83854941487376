<template>
	<div class="duolifangzhuan poc">
	    <div class="duolifangzhuan_1 poc">
			<div class="c_duolifangzhuan_2 poc">
				<div class="b_duolifangzhuan_2_1 duolifangzhuan_scale_2 perspective poc">
					<!-- [99, 156] -->
					<div class="duolifangzhuan_2_1 duolifangzhuan_rot preserve3d poc duolifangzhuan_n" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
				<div class="b_duolifangzhuan_2_2 duolifangzhuan_scale_2 perspective poc">
					<div class="duolifangzhuan_2_2 duolifangzhuan_rot preserve3d poc duolifangzhuan_n" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
				<div class="b_duolifangzhuan_2_3 duolifangzhuan_scale_2 perspective poc">
					<div class="duolifangzhuan_2_3 duolifangzhuan_rot preserve3d poc duolifangzhuan_n" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
				<div class="b_duolifangzhuan_2_4 duolifangzhuan_scale_2 perspective poc">
					<div class="duolifangzhuan_2_4 duolifangzhuan_rot preserve3d poc duolifangzhuan_n" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
			</div>
			<!-- [144, 200] -->

			<div class="c_duolifangzhuan_1 poc">
				<div class="b_duolifangzhuan_1_2 duolifangzhuan_scale_1 perspective poc">
					<div class="duolifangzhuan_1_2 duolifangzhuan_rot preserve3d poc duolifangzhuan_m" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
				<div class="b_duolifangzhuan_1_3 duolifangzhuan_scale_1 perspective poc">
					<div class="duolifangzhuan_1_3 duolifangzhuan_rot preserve3d poc duolifangzhuan_m" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
				<div class="b_duolifangzhuan_1_1 duolifangzhuan_scale_1 perspective poc">
					<div class="duolifangzhuan_1_1 duolifangzhuan_rot preserve3d poc duolifangzhuan_m" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
				<div class="b_duolifangzhuan_1_4 duolifangzhuan_scale_1 perspective poc">
					<div class="duolifangzhuan_1_4 duolifangzhuan_rot preserve3d poc duolifangzhuan_m" :style="getLiFangStyle([262, 345])">
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
						<duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
					</div>
				</div>
			</div>

	        <div class="b_duolifangzhuan_0 perspective poc">
	            <div class="duolifangzhuan_0 duolifangzhuan_rot preserve3d poc duolifangzhuan_p" :style="getLiFangStyle([262, 345])">
	                <duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
	                <duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
	                <duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
	                <duolifangzhuan-item :bsize="[262, 345]" :mode="mode" :currentPhoto="currentPhoto" :canIShow="canIShow"></duolifangzhuan-item>
	            </div>
	        </div>
	    </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	import DuolifangzhuanItem from "./DuolifangzhuanItem"

	export default {
		name: 'Duolifangzhuan',
        extends: BaseElem,
		components: {DuolifangzhuanItem},
		data () {
			return {}
		},
		methods: {
			getLiFangStyle (bsize) {
                let tsize = this.$util.copy(bsize);
				if (this.config[0] && this.config[0]['size']) {
					tsize = this.$util.getSizeInArea(this.config[0]['size'], bsize, 'widthFix');
				} else {
					tsize = this.$util.getSizeInArea(this.currentPhoto['orisize'], bsize, 'widthFix');
				}
				return {
					width: tsize[0] + 'px',
					height: tsize[1] + 'px'
				}
			}
		},
		computed: {
			config () {
				return this.comp['config'] || [];
			}
		}
	}
</script>

<style>
	.b_duolifangzhuan_0 {
		animation: fadeIn 2s 0s both;
	}
	.c_duolifangzhuan_1 {
		animation: fadeIn 2s 1s both;
	}
	.c_duolifangzhuan_2 {
		animation: fadeIn 2s 2s both;
	}
	.duolifangzhuan .perspective {
		perspective: 448px;
	}
	.b_duolifangzhuan_2_1 {
	    bottom: 29.5%;
		/* left: -5%; */
		left: 0;
	}
	.b_duolifangzhuan_2_2 {
	    top: 29.5%;
		/* left: -5%; */
		left: 0;
	}
	.b_duolifangzhuan_2_3 {
		/* left: -25%; */
		left: -42%;
	}
	.b_duolifangzhuan_2_4 {
		right: -25%;
	}
	.b_duolifangzhuan_1_2, .b_duolifangzhuan_1_3 {
	    bottom: 32%;
	}
	.b_duolifangzhuan_1_2, .b_duolifangzhuan_1_1 {
		right: 0;
		/* right: -25%; */
	}
	.b_duolifangzhuan_1_3, .b_duolifangzhuan_1_4 {
		left: -25%;
	}
	.b_duolifangzhuan_1_1, .b_duolifangzhuan_1_4 {
	    top: 32%;
	}
	.duolifangzhuan_rot {
	    animation: duolifangzhuan_rot1 10s linear infinite both;
	}
	@keyframes duolifangzhuan_rot1 {
		0% {
		    transform: rotateY(0deg);
		}
		100% {
		    transform: rotateY(360deg);
		}
	}
	.duolifangzhuan_0, .duolifangzhuan_1_1, .duolifangzhuan_1_2, .duolifangzhuan_1_3, .duolifangzhuan_1_4, .duolifangzhuan_2_1, .duolifangzhuan_2_2, .duolifangzhuan_2_3, .duolifangzhuan_2_4 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	}
	.duolifangzhuan_2_1 {
	    left: 50%;
	    margin-left: -49.5px;
	    right: 0;
	}
	.duolifangzhuan_2_2 {
	    left: 50%;
	    margin-left: -49.5px;
	    right: 0;
	}
	.duolifangzhuan_2_3 {
	    left: 0;
	}
	.duolifangzhuan_2_4 {
	    right: 0;
	}
	.duolifangzhuan_1_1, .duolifangzhuan_1_2 {
	    right: 0px;
	}
	.duolifangzhuan_1_3, .duolifangzhuan_1_4 {
	    left: 0px;
	}
	.duolifangzhuan_0 {
	    left: 0;
	    right: 0;
	}
	._page3 {
	    border: 3px solid #FFFFFF;
	    background-color: #eee;
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(1), .b_duolifangzhuan_2_2 .div:nth-of-type(1), .b_duolifangzhuan_2_3 .div:nth-of-type(1), .b_duolifangzhuan_2_4 .div:nth-of-type(1) {
	    /* transform: rotateY(90deg) translateZ(49px); */
		transform: rotateY(90deg) translateZ(135px);
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(2), .b_duolifangzhuan_2_2 .div:nth-of-type(2), .b_duolifangzhuan_2_3 .div:nth-of-type(2), .b_duolifangzhuan_2_4 .div:nth-of-type(2) {
	    /* transform: rotateY(180deg) translateZ(49px); */
		transform: rotateY(180deg) translateZ(135px);
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(3), .b_duolifangzhuan_2_2 .div:nth-of-type(3), .b_duolifangzhuan_2_3 .div:nth-of-type(3), .b_duolifangzhuan_2_4 .div:nth-of-type(3) {
	    /* transform: rotateY(270deg) translateZ(49px); */
		transform: rotateY(270deg) translateZ(135px);
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(4), .b_duolifangzhuan_2_2 .div:nth-of-type(4), .b_duolifangzhuan_2_3 .div:nth-of-type(4), .b_duolifangzhuan_2_4 .div:nth-of-type(4) {
	    /* transform: translateZ(49px); */
		transform: translateZ(135px);
	}
	/*.duolifangzhuan_img {
	    margin: auto;
	    left: 0;
	    right: 0;
	    bottom: 70px;
	    transform-origin: bottom;
	    animation: page1_img 1.5s ease-in-out infinite alternate both;
	}
	@keyframes page1_img {
		0% {
		    transform: translateY(0) scale(0.8);
		}
		100% {
		    transform: translateY(10px) scale(0.8);
		}
	}*/
	.b_duolifangzhuan_1_1 .div:nth-of-type(1), .b_duolifangzhuan_1_2 .div:nth-of-type(1), .b_duolifangzhuan_1_3 .div:nth-of-type(1), .b_duolifangzhuan_1_4 .div:nth-of-type(1) {
		/* transform: rotateY(90deg) translateZ(72px); */
		transform: rotateY(90deg) translateZ(135px);
	}
	.b_duolifangzhuan_1_1 .div:nth-of-type(2), .b_duolifangzhuan_1_2 .div:nth-of-type(2), .b_duolifangzhuan_1_3 .div:nth-of-type(2), .b_duolifangzhuan_1_4 .div:nth-of-type(2) {
		/* transform: rotateY(180deg) translateZ(72px); */
		transform: rotateY(180deg) translateZ(135px);
	}
	.b_duolifangzhuan_1_1 .div:nth-of-type(3), .b_duolifangzhuan_1_2 .div:nth-of-type(3), .b_duolifangzhuan_1_3 .div:nth-of-type(3), .b_duolifangzhuan_1_4 .div:nth-of-type(3) {
		/* transform: rotateY(270deg) translateZ(72px); */
		transform: rotateY(270deg) translateZ(135px);
	}
	.b_duolifangzhuan_1_1 .div:nth-of-type(4), .b_duolifangzhuan_1_2 .div:nth-of-type(4), .b_duolifangzhuan_1_3 .div:nth-of-type(4), .b_duolifangzhuan_1_4 .div:nth-of-type(4) {
		/* transform: translateZ(72px); */
		transform: translateZ(135px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(1) {
	    transform: rotateY(90deg) translateZ(135px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(2) {
	    transform: rotateY(180deg) translateZ(135px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(3) {
		transform: rotateY(270deg) translateZ(135px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(4) {
	    transform: translateZ(135px);
	}
	.b_duolifangzhuan_0 ._page3 {
	    border: 4px solid #FFFFFF;
	}
	.duolifangzhuan_scale_2 {
		transform: scale(0.38);
		/* transform-origin: 0 0; */
		transform-origin: 50% 50%;
	}
	.duolifangzhuan_scale_1 {
		transform: scale(0.55);
		/* transform-origin: 0 0; */
		transform-origin: 50% 50%;
	}
</style>