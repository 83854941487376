<template>
	<div>
		<Mine :currentPage="currentPage" v-show="currentPage == 'mybook'"></Mine>
		<Menu ref="menu_page" :currentPage="currentPage" v-show="currentPage == 'menu'"></Menu>
		<Square :currentPage="currentPage" v-show="currentPage == 'square'"></Square>
		<TabBar @onTabSelected="onTabSelected" :from="currentPage"></TabBar>
	</div>
</template>

<script>
	import C from "@/config"
	import Mine from '@/views/mine/Mine.vue'
	import Menu from '@/views/menu/Menu.vue'
	import Square from '@/views/square/Square.vue'
	import TabBar from '@/components/TabBar'
	// import StVideo from '@/components/st_comp/StVideo'

	export default {
		name: 'Mybook',
		components: {
			Mine,
			Menu,
			Square,
			TabBar,
			// StVideo
		},
		data () {
			return {
				currentPage: '',
				isFresh: C.debugHost.indexOf(location.host) > -1,
				// new_guide_video_src: null,
				playBtnShow: true,
			}
		},
		created () {
			if (window.location.href.indexOf('mybook') != -1) {
				this.currentPage = 'mybook';
			} else if (window.location.href.indexOf('menu') != -1) {
				this.currentPage = 'menu';
				// this.tryGetGuide()
			} else if (window.location.href.indexOf('square') != -1) {
				this.currentPage = 'square';
			}

			// 记录pv
			if (this.$route.query['vscene']) {
				this.$o_util.log('vscene_'+this.$route.query['vscene']);
			}

			// 微信分享设置
			let that = this,
				share_link = window.location.protocol + '//' + window.location.host + '/vliti/main/' + this.$store.state.appConfig.menu_path;

			wxReadyListener.load(function() {
				that.$wx.reload().then(() => {
					that.$wx.share({
						title: '邀请你制作甜豆相册',
						desc: '甜豆相册，送给你！',
						imgUrl: 'http://resali.lititutu.cn/icons/logo/logo_td.jpg',
						link: share_link,
						success: () => {},
						from: that.currentPage
					});
				})
			});
		},
		methods: {
			onThemeSelect () {
				// this.new_guide_video_src = null;
				this.$refs['menu_page'].onThemeSelect(this.$store.state.appConfig.new_guide.tid)
				this.$o_util.log("fresh_guide_make")
			},
			onTabSelected (page) {
				this.currentPage = page;
				// if(page == "menu"){this.tryGetGuide()}
				try {
					window.scrollTo(0, 0);
				} catch(e) {
					window.scrollTop = 0;
				}
			},
			// tryGetGuide (){
			// 	if(!this.$store.state.app || !this.$store.state.app.new_guide){
			// 		this.$store.dispatch('getAppConfig', 'new_guide').then((res) => {});
			// 	}
			// },
			// showGuideVideo () {
			// 	// $store.state.appConfig.new_guide.video
			// 	let _this = this;
			// 	if ( this.$store.state.appConfig && this.$store.state.appConfig.new_guide && this.$store.state.appConfig.new_guide.vid){
			// 		this.$o_util.getVideoSrc(this.$store.state.appConfig.new_guide.vid).then(video_src=>{
			// 			_this.new_guide_video_src = video_src;
			// 			if(_this.currentPage == 'menu'){
			// 				_this.$o_util.log("fresh_guide_show")
			// 			}
			// 		})
			// 	}
			// },
		}
	}
</script>

<style scoped lang="less">
	// 新人引导
	.fresh-guide-container{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.51);
		z-index: 1001;
		justify-content: center;
		align-content: center;
		align-items: center;
	}
	.guide-theme-container{
		position: relative;
		background-color: #fff;
		border-radius: 8px;
	}
	.new-guide-video{
		position: relative;
		width: 400px;
		height: 584px;
	}
	.new-guide-text,.new-guide-btn {
		letter-spacing: 1px;
		text-align: center;
	}
	.new-guide-text{
		margin: 18px auto 0;
		font-size: 22px;
		color: #5F5F5F;
	}
	.new-guide-btn {
		color: #fff;
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 1px;
		margin: 18px auto 21px;
		background-color: #D93641;
		border-radius: 36px;
		padding: 12px 70px;
	}
	.new-guide-tab{
		position: absolute;
		left: 0;
		top: 0;
		padding: 9px 16px;
		background-color: #266FFC;
		color: #fff;
	}
	.new-guide-close {
		position: absolute;
		margin: 9px;
		padding: 9px;
		right: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, .41);
	}
	.rubberBand{
		animation: rubberBand 3s linear infinite;
	}
	@keyframes rubberBand {
		from {
			transform: scale3d(1, 1, 1);
		}

		15% {
			transform: scale3d(1.1, 0.8, 1);
		}

		20% {
			transform: scale3d(0.8, 1.1, 1);
		}

		25% {
			transform: scale3d(1.1, 0.85, 1);
		}

		32.5% {
			transform: scale3d(0.95, 1.05, 1);
		}

		37.5% {
			transform: scale3d(1.05, 0.95, 1);
		}

		50% {
			transform: scale3d(1, 1, 1);
		}

		to {
			transform: scale3d(1, 1, 1);
		}
	}
</style>