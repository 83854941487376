<template>
	<div class="page flex_r" style="background-color: #f3f3f3;">
		<img style="position: relative;width: 88%;" src="http://resali.lititutu.cn/icons/app/search_tiandou.png">
	</div>
</template>

<script>
	export default {
		name: 'search',
		data () {
			return {}
		},
		created () {
			this.$mta.click('qq_to_wx_search');
		}
	}
</script>

<style>
	
</style>