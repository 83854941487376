<template>
    <div class="po page" :style="getStyles">
        <div class="po page perspective">
            <div class="po preserve3d changlang-con">
                
                <!-- 上方开始 -->
                <template v-if="getCompElems(0)">
                    <div class="page bg changlang-top1">
                        <elem :mode="getCompElems(0)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </template>
                <template v-else>
                    <div class="page bg changlang-top1" :style="{backgroundImage: 'url('+ (comp['config'] && comp['config'][0]) +')'}"></div>
                </template>

                <template v-if="getCompElems(1)">
                    <div class="page bg changlang-top2">
                        <elem :mode="getCompElems(1)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </template>
                <template v-else>
                    <div class="page bg changlang-top2" :style="{backgroundImage: 'url('+ (comp['config'] && comp['config'][1]) +')'}"></div>
                </template>

                <template v-if="getCompElems(2)">
                    <div class="page bg changlang-top3">
                        <elem :mode="getCompElems(2)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </template>
                <template v-else>
                    <div class="page bg changlang-top3" :style="{backgroundImage: 'url('+ (comp['config'] && comp['config'][2]) +')'}"></div>
                </template>
                <!-- 上方结束 -->

                <!-- 左方开始 -->
                <div class="page changlang-left1">
                    <div v-if="getCompElems(6)">
                        <elem :mode="getCompElems(6)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div v-else class="poc bg" 
                        :class="{'bg_photo': !(getCompConfig(6))}" 
                        :style="{
                            backgroundImage: 'url('+ ((getCompConfig(6)) || myCurrentPhoto) +')'
                        }"></div>
                </div>
                <div class="page changlang-left2">
                    <div v-if="getCompElems(7)">
                        <elem :mode="getCompElems(7)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div v-else class="poc bg" 
                        :class="{'bg_photo': !(getCompConfig(7))}" 
                        :style="{
                            backgroundImage: 'url('+ ((getCompConfig(7)) || myCurrentPhoto) +')'
                        }"></div>
                </div>
                <div class="page changlang-left3">
                    <div v-if="getCompElems(8)">
                        <elem :mode="getCompElems(8)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div v-else class="poc bg" 
                        :class="{'bg_photo': !(getCompConfig(8))}" 
                        :style="{
                            backgroundImage: 'url('+ ((getCompConfig(8)) || myCurrentPhoto) +')'
                        }"></div>
                </div>
                <!-- 左方结束 -->

                <!-- 右方开始 -->
                <div class="page changlang-right1">
                    <div v-if="getCompElems(9)">
                        <elem :mode="getCompElems(9)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div v-else class="poc bg" 
                        :class="{'bg_photo': !(getCompConfig(9))}" 
                        :style="{
                            backgroundImage: 'url('+ ((getCompConfig(9)) || myCurrentPhoto) +')'
                        }"></div>
                </div>
                <div class="page changlang-right2">
                    <div v-if="getCompElems(10)">
                        <elem :mode="getCompElems(10)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div v-else class="poc bg" 
                        :class="{'bg_photo': !(getCompConfig(10))}" 
                        :style="{
                            backgroundImage: 'url('+ ((getCompConfig(10)) || myCurrentPhoto) +')'
                        }"></div>
                </div>
                <div class="page changlang-right3">
                    <div v-if="getCompElems(11)">
                        <elem :mode="getCompElems(11)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div v-else class="poc bg" 
                        :class="{'bg_photo': !(getCompConfig(11))}" 
                        :style="{
                            backgroundImage: 'url('+ ((getCompConfig(11)) || myCurrentPhoto) +')'
                        }"></div>
                </div>
                <!-- 右方结束 -->

                <!-- 前方开始 -->
                <div class="page changlang-front">
                    <div v-if="getCompElems(12)">
                        <elem :mode="getCompElems(12)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                    <div v-else class="poc bg" 
                        :class="{'bg_photo': !(getCompConfig(12))}" 
                        :style="{
                            backgroundImage: 'url('+ ((getCompConfig(12)) || myCurrentPhoto) +')'
                        }"></div>
                </div>
                <!-- 前方结束 -->

                <!-- 下方开始 -->
                <template v-if="getCompElems(3)">
                    <div class="page bg changlang-bottom1">
                        <elem :mode="getCompElems(3)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </template>
                <template v-else>
                    <div class="page bg changlang-bottom1" :style="{backgroundImage: 'url('+ (comp['config'] && comp['config'][3]) +')'}"></div>
                </template>

                <template v-if="getCompElems(4)">
                    <div class="page bg changlang-bottom2">
                        <elem :mode="getCompElems(4)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </template>
                <template v-else>
                    <div class="page bg changlang-bottom2" :style="{backgroundImage: 'url('+ (comp['config'] && comp['config'][4]) +')'}"></div>
                </template>

                <template v-if="getCompElems(5)">
                    <div class="page bg changlang-bottom3">
                        <elem :mode="getCompElems(5)" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                    </div>
                </template>
                <template v-else>
                    <div class="page bg changlang-bottom3" :style="{backgroundImage: 'url('+ (comp['config'] && comp['config'][5]) +')'}"></div>
                </template>
                <!-- 下方结束 -->

                <!-- 长廊元素 -->
                <div class="po changlang-item"
                    v-for="idx of 6"
                    :class="'changlang-item_' + (idx - 1)"
                    :key="'changlang-item_' + idx">
                    <elem v-if="items[(idx - 1) % items.length]" :mode="items[(idx - 1) % items.length]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

    export default {
        name: 'Changlang',
        extends: BaseElem,
        computed: {
            items () {
                if (!(this.comp['elems'] || this.comp['config'])) {
                    return [];
                }
                let items = [];
                if (this.comp.elems && this.comp.elems.length >= 13) {
                    for (let i=0; i<this.comp.elems.length; i++) {
                        if (i >= 13 && i <= 18) {
                            items.push(this.comp.elems[i]);
                        }
                    }
                } else if (this.comp.config && this.comp.config.length >= 13) {
                    for (let i=0; i<this.comp.config.length; i++) {
                        if (i >= 13 && i <= 18) {
                            items.push(this.comp.config[i]);
                        }
                    }
                }
                return items;
            }
        },
        data () {
            return {}
        },
        methods: {
            getCompConfig (index) {
                return this.comp['config'] && this.comp['config'][index];
            },
            getCompElems (index) {
                let elem = this.comp['elems'] && this.comp['elems'][index],
                    config = this.getCompConfig(index);
                return this.$util.typeOf(config) == 'object' ? config : elem;
            }
        }
    }
</script>

<style>
    .changlang-item {
        width: 500px;
        height: 500px;
    }
    .changlang-item_1 {
        transform: translateZ(100px);
    }
    .changlang-item_2 {
        transform: translateZ(-230px);
    }
    .changlang-item_3 {
        transform: translateZ(-400px);
    }
    .changlang-item_4 {
        transform: translateZ(-730px);
    }
    .changlang-item_5 {
        transform: translateZ(-900px);
    }
    .changlang-con {
        width: 500px;
        height: 500px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        animation: changlang_time 7s linear infinite both;
    }
    .changlang-top1 {
        transform: rotateX(-90deg) translateZ(-230px);
    }
    .changlang-top2 {
        transform: rotateX(-90deg) translate3d(0, 500px, -230px);
    }
    .changlang-top3 {
        transform: rotateX(-90deg) translate3d(0, 1000px, -230px);
    }
    .changlang-bottom1 {
        transform: rotateX(90deg) translateZ(-230px);
    }
    .changlang-bottom2 {
        transform: rotateX(90deg) translate3d(0, -500px, -230px);
    }
    .changlang-bottom3 {
        transform: rotateX(90deg) translate3d(0, -1000px, -230px);
    }
    .changlang-left1 {
        transform: rotateY(90deg) translateZ(-230px);
    }
    .changlang-left2 {
        transform: rotateY(90deg) translate3d(500px, 0, -230px);
    }
    .changlang-left3 {
        transform: rotateY(90deg) translate3d(1000px, 0, -230px);
    }
    .changlang-right1 {
        transform: rotateY(-90deg) translateZ(-230px);
    }
    .changlang-right2 {
        transform: rotateY(-90deg) translate3d(-500px, 0, -230px);
    }
    .changlang-right3 {
        transform: rotateY(-90deg) translate3d(-1000px, 0, -230px);
    }
    .changlang-front {
        animation: changlang_time2 7s linear infinite both
    }
    @keyframes changlang_time {
        0% {
            transform: translateZ(0);
        }
        100% {
            transform: translateZ(500px);
        }
    }
    @keyframes changlang_time2 {
        0% {
            transform: translateZ(-1050px);
        }

        100% {
            transform: translateZ(-1348px);
        }
    }
</style>