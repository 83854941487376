<!-- 气泡飘 -->
<template>
    <div class="po page" :style="getStyles">
        <template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
            <div v-for="index in 34"
                :key="'piao5-'+index"
                class="po piao5-item"
                :class="'piao5-'+index">
                <elem 
					:mode="getLuoItem(index)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
            </div>
        </template>
        <template v-else>
            <img v-for="index in 34"
                mode="widthFix"
                :key="'piao5-'+index"
                class="po piao5-item"
                :class="'piao5-'+index"
                :src="getLuoItem(index)" />
        </template>
    </div>
</template>

<script>
    import BaseLuo from './BaseLuo.vue'
    export default {
        name: 'Piao5',
        extends: BaseLuo
    }

</script>

<style>
.piao5-item {bottom: 0;opacity: 0;}
.piao5-0 {left:-71px!important;top: initial!important;animation:piao5_1 8.8s 2s linear infinite;}
.piao5-1 {left:140px!important;top: initial!important;animation:piao5_2 9.6s 2s linear infinite;}
.piao5-2 {left:178px!important;top: initial!important;animation:piao5_2 8.2s 2s linear infinite;}
.piao5-3 {left:61px!important;top: initial!important;animation:piao5_2 8.2s 4s linear infinite;}
.piao5-4 {left:101px!important;top: initial!important;animation:piao5_1 6.1s 1s linear infinite;}
.piao5-5 {left:197px!important;top: initial!important;animation:piao5_2 9.6s 4s linear infinite;}
.piao5-6 {left:433px!important;top: initial!important;animation:piao5_2 7.4s 2s linear infinite;}
.piao5-7 {left:584px!important;top: initial!important;animation:piao5_1 7.4s 4s linear infinite;}
.piao5-8 {left:599px!important;top: initial!important;animation:piao5_2 7.2s 2s linear infinite;}
.piao5-9 {left:-76px!important;top: initial!important;animation:piao5_2 8.6s 2s linear infinite;}
.piao5-10 {left:567px!important;top: initial!important;animation:piao5_2 7.3s 1s linear infinite;}
.piao5-11 {left:519px!important;top: initial!important;animation:piao5_2 8.5s 3s linear infinite;}
.piao5-12 {left:67px!important;top: initial!important;animation:piao5_1 9.8s 4s linear infinite;}
.piao5-13 {left:347px!important;top: initial!important;animation:piao5_2 7.6s 4s linear infinite;}
.piao5-14 {left:-96px!important;top: initial!important;animation:piao5_1 8.9s 4s linear infinite;}
.piao5-15 {left:99px!important;top: initial!important;animation:piao5_1 9.2s 2s linear infinite;}
.piao5-16 {left:524px!important;top: initial!important;animation:piao5_1 7.8s 2s linear infinite;}
.piao5-17 {left:36px!important;top: initial!important;animation:piao5_1 5.7s 1s linear infinite;}
.piao5-18 {left:325px!important;top: initial!important;animation:piao5_1 9.7s 1s linear infinite;}
.piao5-19 {left:549px!important;top: initial!important;animation:piao5_1 5.6s 2s linear infinite;}
.piao5-20 {left:366px!important;top: initial!important;animation:piao5_1 7.3s 2s linear infinite;}
.piao5-21 {left:556px!important;top: initial!important;animation:piao5_2 9.9s 1s linear infinite;}
.piao5-22 {left:-61px!important;top: initial!important;animation:piao5_2 7.3s 3s linear infinite;}
.piao5-23 {left:516px!important;top: initial!important;animation:piao5_1 8.5s 3s linear infinite;}
.piao5-24 {left:-32px!important;top: initial!important;animation:piao5_2 8.1s 3s linear infinite;}
.piao5-25 {left:534px!important;top: initial!important;animation:piao5_2 9.4s 4s linear infinite;}
.piao5-26 {left:155px!important;top: initial!important;animation:piao5_1 7.8s 1s linear infinite;}
.piao5-27 {left:145px!important;top: initial!important;animation:piao5_1 7.6s 1s linear infinite;}
.piao5-28 {left:371px!important;top: initial!important;animation:piao5_1 7.2s 3s linear infinite;}
.piao5-29 {left:86px!important;top: initial!important;animation:piao5_2 6.9s 4s linear infinite;}
.piao5-30 {left:460px!important;top: initial!important;animation:piao5_1 6.2s 2s linear infinite;}
.piao5-31 {left:-28px!important;top: initial!important;animation:piao5_2 6.6s 3s linear infinite;}
.piao5-32 {left:367px!important;top: initial!important;animation:piao5_1 8.7s 1s linear infinite;}
.piao5-33 {left:32px!important;top: initial!important;animation:piao5_2 7.4s 1s linear infinite;}
@keyframes piao5_1 {
    0% {
        transform: translate(0px,0px);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    80% {
        transform: translate(160px,-560px);
        opacity: 1;
    }
    100% {
        transform: translate(200px,-700px);
        opacity: 0;
    }
}
@keyframes piao5_2 {
    0% {
        transform: translate(0px,0px);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    80% {
        transform: translate(-80px,-600px);
        opacity: 1;
    }
    100% {
        transform: translate(-100px,-750px);
        opacity: 0;
    }
}
</style>