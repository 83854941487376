<template>
    <div class="quantu1_con">
        <div class="quantu1_userbox">
            <div class="quantu1_user quantu1_user1">
                <div class="quantu1_pic" :style="getStyle" @click="wxPreview"></div>
            </div>
            <div class="quantu1_user quantu1_user2">
                <div class="quantu1_pic" :style="getStyle" @click="wxPreview"></div>
            </div>
            <div class="quantu1_user quantu1_user3">
                <div class="quantu1_pic" :style="getStyle" @click="wxPreview"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'quantu1',
        props: ['mode', 'currentImage', 'showStatus'],
        data () {
            return {}
        },
        computed: {
            getStyle () {
                let twidth, theight;
                if (this.showStatus) {
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [417, 625], 'widthFix');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = 417;
                    theight = 625;
                }
                return {
                    width: twidth + 'px',
                    height: theight + 'px',
                    backgroundImage: 'url('+this.currentImage['im']+')'
                }
            }
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage)
            }
        }
    }
</script>

<style scoped>
    .quantu1_con {
        width:100vw;
        height:100vh;
        position:relative;
        perspective:556px;
    }
    .quantu1_bg {
        position:absolute;
        top:0;
        left:0;
        width:100vw;
        height:100vh;
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center center;
    }
    .quantu1_userbox {
        position:absolute;
        top:0px;
        left:0px;
        width:100vw;
        height:100vh;
        transform-style:preserve-3d;
    }
    .quantu1_user {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .quantu1_user1 {
        position: absolute;
        /*bottom: 69px;*/
        top: 20%;
        left: 44px;
        width: 416px;
        height: 625px;
        opacity: 1;
        animation: quantu1_art1 4s linear both;
    }
    @keyframes quantu1_art1 {
        0% {
        transform:translateZ(-60vw);
        opacity:1
    }
    70% {
        transform:translateZ(0vw);
        opacity:1
    }
    90% {
        opacity: 1;
    }
    100% {
        transform:translateZ(10vw);
        opacity:0
    }
    }
    .quantu1_user2 {
        position:absolute;
        /*bottom:69px;*/
        top: 20%;
        left:-323px;
        width:417px;
        height:625px;
        animation: quantu1_art2 4s linear both, quantu1_art22 3s 4s linear forwards, quantu1_art222 3s 7s linear forwards;
    }
    @keyframes quantu1_art2 {
        0% {
        transform:translateZ(-160vw);
    }
    100% {
        transform:translateZ(-105vw);
    }
    }@keyframes quantu1_art22 {
        0% {
        transform:translateX(0px) translateZ(-105vw);
    }
    100% {
        transform:translateX(367px) translateZ(-55vw);
    }
    }@keyframes quantu1_art222 {
        0% {
        transform:translateX(367px) translateZ(-55vw);
    }
    100% {
        transform:translateX(367px) translateZ(0);
    }
    }.quantu1_user3 {
        position:absolute;
        /*bottom:69px;*/
        top: 20%;
        right:-240px;
        width:417px;
        height:625px;
        opacity:1;
        animation:quantu1_art3 4s linear both,quantu1_art33 3s 4s linear forwards;
    }
    @keyframes quantu1_art3 {
        0% {
        transform:translateZ(-110vw);
    }
    100% {
        transform:translateZ(-55vw);
    }
    }@keyframes quantu1_art33 {
        0% {
        transform:translateX(0px) translateZ(-55vw);
        opacity:1;
    }
    70% {
        transform:translateX(-278px) translateZ(0);
        opacity:1;
    }
    90% {
        opacity: 1;
    }
    100% {
        transform:translateX(-278px) translateZ(10vw);
        opacity:0;
    }
    }.quantu1_pic {
        position:absolute;
        /*top:0px;
        left:0px;*/
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center center;
        -webkit-box-reflect:below 0 -webkit-linear-gradient(top,rgba(250,250,250,0),rgba(250,250,250,.0) 50%,rgba(250,250,250,.3));
        box-reflect:below 0 linear-gradient(top,rgba(250,250,250,0),rgba(250,250,250,.0) 50%,rgba(250,250,250,.3));
        border:solid 10px rgba(255,255,255,.6);
    }
</style>