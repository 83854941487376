<template>
  <div class="yuanzhuanting page po">
    <div class="yuanzhuanting_per page po">
      <div class="preserve3d page po">
        <!-- 星星转 -->
        <div class="preserve3d page po star-container">
          <gif v-if="mode.length > 1 && mode[1]['middle']" :mode="mode[1]['middle']"></gif>
        </div>

        <div class="preserve3d page po yuanzhuanting_div" :class="getZhuanAniClass">
          <div class="preserve3d page po float-x">
          <div class="preserve3d page po float-y">
            <div v-for="idx of 8" :class="'yuanzhuanting_item_div' + idx" :key="'div' + idx" class="preserve3d page po">
              <div class="page po bg yuanzhuanting_user_img fadein" :style="getStyle"></div>
            </div>
          </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "yuanzhuanting",
  props: ["mode", "currentImage", "showStatus"],
  components: {},
  data() {
    return {
      current_index: 1
    };
  },
  computed: {
    getZhuanAniClass() {
      return "yuanzhuanting_div_" + this.current_index%4;
    },
    getStyle() {
      let _style = {
        width: "430px",
        height: "626px"
      };
      if (this.showStatus) {
        let tsize = this.$o_util.getphotoboxsize(
          this.currentImage["orisize"],
          [430, 626],
          "widthFix"
        );
        _style = {
          width: tsize[0] + "px",
          height: tsize[1] + "px"
        };
      }
      _style["backgroundImage"] = "url(" + this.currentImage["im"] + ")";
      if (
        this.mode.length > 1 &&
        this.mode[1]["border"] &&
        this.mode[1]["border"]["im"]
      ) {
        _style["borderImageSource"] =
          "url(" + this.mode[1]["border"]["im"] + ")";
      } else {
        _style["borderImageSource"] =
          "url(http://resali.lititutu.cn/icons/other/border.jpg)";
      }
      return _style;
    },
  },
  methods: {
    wxPreview() {
      this.$emit("wxPreview", this.currentImage);
    },
  }
};
</script>

<style lang="less">
.yuanzhuanting{
  .yuanzhuanting_per {
    perspective: 1200px;
  }
  .yuanzhuanting_user_img {
    border: 6px solid;
    border-image-slice: 6;
    transition: all 0.5s both;
    background-color: #eee;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .star-container{
    transform: translateZ(-900px);
  }
  .yuanzhuanting_div {
    z-index: 5;
    animation: 48000ms linear 0s infinite normal both running;
    transform: translateZ(-1200px) rotateY(25deg);
    overflow: visible!important;
  }
  .yuanzhuanting_div_1 {
    animation-name: yuanzhuantingani;
  }

  .yuanzhuanting_item_div1 {
    -webkit-transform: translateZ(1200px);
  }
  .yuanzhuanting_item_div2 {
    -webkit-transform: rotateY(-315deg) translateZ(1200px);
  }
  .yuanzhuanting_item_div3 {
    -webkit-transform: rotateY(-270deg) translateZ(1200px);
  }
  .yuanzhuanting_item_div4 {
    -webkit-transform: rotateY(-225deg) translateZ(1200px);
  }
  .yuanzhuanting_item_div5 {
    -webkit-transform: rotateY(-180deg) translateZ(1200px);
  }
  .yuanzhuanting_item_div6 {
    -webkit-transform: rotateY(-135deg) translateZ(1200px);
  }
  .yuanzhuanting_item_div7 {
    -webkit-transform: rotateY(-90deg) translateZ(1200px);
  }
  .yuanzhuanting_item_div8 {
    -webkit-transform: rotateY(-45deg) translateZ(1200px);
  }


  // .yuanzhuan_float {
  //   animation: 4500ms linear 0s infinite normal both running yuanzhuan_float;
  // }
  // @-webkit-keyframes yuanzhuan_float {
  //   0% {
  //     transform: translateX(12px) translatey(12px);
  //   }
  //   50% {
  //     transform: translateX(-12px) translatey(-12px);
  //   }
  //   100% {
  //     transform: translateX(12px) translatey(12px);
  //   }
  // }
 

  @keyframes yuanzhuantingani {
    0% {
      -webkit-transform: translateZ(-1200px) rotateY(25deg);
    }

    5%{
      -webkit-transform: translateZ(-1200px) rotateY(0);
    }
    20%{
      -webkit-transform: translateZ(-1200px) rotateY(0);
    }
    25%{
      -webkit-transform: translateZ(-1200px) rotateY(-20deg);
    }

    30%{
      -webkit-transform: translateZ(-1200px) rotateY(-45deg);
    }
    45%{
      -webkit-transform: translateZ(-1200px) rotateY(-45deg);
    }
    50%{
      -webkit-transform: translateZ(-1200px) rotateY(-65deg);
    }

    55%{
      -webkit-transform: translateZ(-1200px) rotateY(-90deg);
    }
    70%{
      -webkit-transform: translateZ(-1200px) rotateY(-90deg);
    }
    75%{
      -webkit-transform: translateZ(-1200px) rotateY(-110deg);
    }

    80%{
      -webkit-transform: translateZ(-1200px) rotateY(-135deg);
    }
    95%{
      -webkit-transform: translateZ(-1200px) rotateY(-135deg);
    }
    100% {
      -webkit-transform: translateZ(-1200px) rotateY(-150deg);
    }
  }
  
}
</style>