<template>
	<div class="page wutai-perspective po pointer" :style="getStyles">
		<div class="wutai-box3d preserve3d po">
			<img :src="mode['im']" class="wutai-turn po">
		</div>
	</div>
		<!-- <canvas id="people_canvas" width="306" height="544" class="po"></canvas> -->
</template>

<script>
	export default {
		name: 'wutai',
		props: ["mode"],
		data () {
			return {}
		},
		computed: {
			getStyles () {
				let item = this.mode;
				
				let s = {};
				
				if (item['zIndex']) {
					s['zIndex'] = item['zIndex'];
				}

				if (item['style']) {
					s = Object.assign(s, item['style']);
				}

				return s;
			}
		}
	}
</script>

<style>
	.wutai-perspective {
	    -webkit-perspective: 950px;
	}
	.wutai-box3d {
		top: 0;
		left: 0;
		width: 500px;
		height: 500px;
		bottom: 220px;
	}
	.wutai-turn {
		width: 1096px;
		height: 1096px;
		margin: auto;
		top: 0;
		bottom: 0;
		left: -298px;
		-webkit-animation: turn 25s linear infinite both;
		border-radius: 50%;
		overflow: hidden;
	}
	@keyframes turn {
		0% {
			-webkit-transform: rotateX(90deg) translateZ(-678px) rotateZ(0);
		}
		100% {
			-webkit-transform: rotateX(90deg) translateZ(-678px) rotateZ(-360deg);
		}
	}
</style>