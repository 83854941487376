<template>
	<component
		v-if="canElemShow"
		:is="getIs"
		:mode="mode"
		:currentPhoto="currentPhoto"
		:currentTime="currentTime"
		:canIShow="canIShow"
		:currentImage="currentImage"
		:scene="scene"
		:line="line"
		@onEvent="onEvent">
		<!-- <slot></slot> -->
		<!-- 不适合使用slot，会导致组件不断自刷新 -->
	</component>
</template>

<script>
	import BaseElem from './BaseElem'

	import stButtonTrans from './Trans/StButtonTrans'
	import stMusicPlayerTrans from './Trans/StMusicPlayerTrans'
	// import stToastTrans from './Trans/StToastTrans'
	// import stToastConfirmTrans from './Trans/StToastConfirmTrans'

	export default {
		name: 'Elem',
		extends: BaseElem,
		data () {
			return {
				// canElemShow: true
			}
		},
		components: {
			stButtonTrans,
			stMusicPlayerTrans,
			// stToastTrans,
			// stToastConfirmTrans,
		},
		watch: {
			canElemShow: {
				handler () {
					if (this.canElemShow) {
						// 计数
						if (this.mode['log']) {
							this.$util.$loger.log(this.mode['log']['key'], this.mode['log']['params']);
						}
						// 其他操作
						if (this.stCondition) {
							this.$util.$action.do(this.stCondition);
						}
					}
				},
				immediate: true
			}
		},
		created () {},
		computed: {
			// 通过store动态修改状态
			canElemShow () {
				let canElemShow = true;
				if (this.stCondition) {
					// 判断elem显隐
					if (this.stCondition['do'] == 'hide') {
						canElemShow = false;
					}
				}
				return canElemShow;
			},
			getIs () {
				if (this.mode && this.mode["is"] && this.mode["is"] != "elem") {
					let inTrans = ['stButton', 'stMusicPlayer']; //, 'stToast', 'stToastConfirm'
					return inTrans.indexOf(this.mode['is']) != -1 ? this.mode['is'] + 'Trans' : this.mode['is'];
				}
				if (this.mode['type'] == 'line') {
					return 'stElemLine';
				}
				if (this.mode['type'] == 'scene') {
					return 'stElemScene';
				}
				if (this.mode['type'] == 'page') {
					return 'stElemPage';
				}
				if (this.mode['mask'] && this.$util.typeOf(this.mode['mask']) == 'object' && !this.mode['mask']['maskImage']) {
					return 'stElemMask';
				}
				return 'stElemIm';
			},
		},
		methods: {
			onEvent (data) {
				this.$emit("onEvent", data);
			}
		}
	}
</script>

<style>
	.elem {
		position: absolute;
		/* pointer-events: none; */
		vertical-align: top;
	}
</style>