<template>
	<div v-if="Object.prototype.toString.call(theme['top']).toLowerCase() == '[object string]'" class="po xc-panorama_m xc-panorama_top" :style="{backgroundImage: 'url('+theme['top']+')'}"></div>	

	<div v-else-if="Object.prototype.toString.call(theme['top']).toLowerCase() == '[object object]'" class="po xc-panorama_m xc-panorama_top" :style="{backgroundImage: 'url('+theme['top']['im']+')'}">
		<gif v-if="theme['top']['gif']" :mode="theme['top']['gif']"></gif>
		<template v-if="theme['top']['elems']">
			<elem v-for="(elem, index) in theme['top']['elems']" :mode="elem" :key="'xc-room-top_' + index"></elem>
		</template>
	</div>	
</template>

<script>
	export default {
		name: 'RoomTop',
		props: ['theme']
	}
</script>

<style>
	
</style>