<template>
	<div class="van-toast van-toast--default van-toast--middle van-fade-leave-active van-fade-leave-to" style="z-index: 2017;">
		<div class="van-loading van-loading--circular van-loading--white" style="color: white;">
			<span class="van-loading__spinner van-loading__spinner--circular">
				<svg viewBox="25 25 50 50" class="van-loading__circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
				</svg>
			</span>
		</div>
		<div class="van-toast__text">{{loadingOptions.message}}</div>
	</div>
</template>

<script>
	export default {
		name: 'Loading',
		props: ['loadingOptions'],
		data () {
			return {}
		}
	}
</script>

<style lang="less" scoped>
	.van-toast--default {
	    width: @bw*.25;
	    padding: @bw*.056;
	    min-height: @bw*.125;
	}
	.van-toast {
		position: fixed;
		top: 50%;
		left: 50%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		color: #fff;
		/* width: 70%; */
		font-size: @bw*.033;
		line-height: @bw*.036;
		border-radius: @bw*.0.014;
		word-break: break-all;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		-webkit-transform: translate3d(-50%, -50%, 0);
		transform: translate3d(-50%, -50%, 0);
		background-color: rgba(50, 50, 51, 0.88);
		/* width: -webkit-fit-content; */
		width: -moz-fit-content;
		/* width: 150px; */
		white-space: pre-wrap;
	}
	.van-loading {
	    color: white;
		width: @bw*.083;
		height: @bw*.083;
		z-index: 0;
		font-size: 0;
		line-height: 0;
		position: relative;
		vertical-align: middle;
	}
	.van-toast--default .van-loading {
	    margin: @bw*.05 0;
	}
	.van-loading__spinner--circular {
	    -webkit-animation-duration: 2s;
	    animation-duration: 2s;
	}
	.van-loading__spinner {
	    z-index: -1;
	    width: 100%;
	    height: 100%;
	    position: relative;
	    display: inline-block;
	    box-sizing: border-box;
	    -webkit-animation: van-rotate 0.8s linear infinite;
	    animation: van-rotate 0.8s linear infinite;
	}
	.van-loading__circular {
	    width: 100%;
	    height: 100%;
	}
	@keyframes van-rotate {
		0% {
		    -webkit-transform: rotate(0deg);
		    transform: rotate(0deg);
		}
		100% {
		    -webkit-transform: rotate(360deg);
		    transform: rotate(360deg);
		}
	}
	.van-loading__circular circle {
	    stroke: currentColor;
	    stroke-width: 3;
	    stroke-linecap: round;
	    -webkit-animation: van-circular 1.5s ease-in-out infinite;
	    animation: van-circular 1.5s ease-in-out infinite;
	}
	@keyframes van-circular {
		0% {
		    stroke-dasharray: 1, 200;
		    stroke-dashoffset: 0;
		}
		50% {
		    stroke-dasharray: 90, 150;
		    stroke-dashoffset: -40;
		}
		100% {
		    stroke-dasharray: 90, 150;
		    stroke-dashoffset: -120;
		}
	}	
	.van-toast--default .van-toast__text {
	    padding-top: 5px;
	}
</style>