<template>
    <div class="chongdie_page2">
        <div class="chongdie_page2_1" :style="getStyle">
            <div class="preserve3d chongdie_zhuan_page">
                <div class="chongdie_page2_1_1 zhuan_chongdie_page2 preserve3d po bg chongdie_scale6" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    
                </div>
                <div class="chongdie_page2_1_1 zhuan_chongdie_page2 preserve3d po bg chongdie_scale5" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    
                </div>
                <div class="chongdie_page2_1_1 zhuan_chongdie_page2 preserve3d po bg chongdie_scale4" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    
                </div>
                <div class="chongdie_page2_1_1 zhuan_chongdie_page2 preserve3d po bg chongdie_scale3" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    
                </div>
                <div class="chongdie_page2_1_1 zhuan_chongdie_page2 preserve3d po bg chongdie_scale2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    
                </div>
                <div class="chongdie_page2_1_1 zhuan_chongdie_page2 preserve3d po bg chongdie_scale1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "chongdie",
        props: ['mode', 'currentImage', 'showStatus'],
        data () {
            return {}
        },
        computed: {
            getStyle () {
                let twidth, theight;
                if (this.showStatus) {
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [480, 660], 'both');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = 480;
                    theight = 660;
                }
                return {
                    width: twidth + 'px',
                    height: theight + 'px'
                }
            }
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage)
            }
        }
    }
</script>

<style scoped>
    .chongdie_page2_1 {
        /*height: 660px;
        width: 480px;*/
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        -webkit-perspective: 800px;
        position: absolute;

    }

    .chongdie_zhuan_page{
        animation: chongdie_page2_1 6s 2.5s cubic-bezier(0.67, 0.33, 0.51, 0.61) infinite both;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d
    }
    .zhuan_chongdie_page2 {
        width: 100%;
        height: 100%;
        position: absolute;
        transform-style: preserve-3d
    }
    .chongdie_page2_right {
        width: 100%;
        height: 480px;
    }

    .chongdie_page2_1_2 {
        transform: rotateY(90deg);
    }

    .posix1{
        transform: rotateY(180deg);
    }

    .div_po{
        position: absolute;
        transform-style: preserve-3d;
        transform-origin: bottom;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    @keyframes zhuanscAni{
        0% {
            transform: scale(0.2);
        }

        100% {
            transform: scale(1);
        }
    }
    @keyframes chongdie_page2_1 {
        0% {
            transform: rotateY(0deg);
        }
        100% {
            transform: rotateY(360deg);
        }
    }

    .con_bg_ver{
        height: 100%;
    }
    
    .div_po {
        /*position: absolute;*/
        /*background-size: cover;*/
        /*background-position: center 10%;*/
        /*background-size: cover;*/
    }

    .border_img{
        width: 100%;
        height: 100%;
        position: absolute;
        background: no-repeat center;
        background-size: 100% 100%;

    }
    .chongdie_scale1{
         width: 0%;
        height: 0%;
        animation: chongdie_scale1 1.5s linear 1 both;
    }
    .chongdie_scale2{
        width: 0%;
        height: 0%;
        top: -30px;
        animation: chongdie_scale2 1.5s 0.1s linear 1 both;
    }
    .chongdie_scale3{
        width: 0%;
        height: 0%;
        top: -60px;
        animation: chongdie_scale4 1.5s 0.2s linear 1 both;
    }
    .chongdie_scale4{
        width: 0%;
        height: 0%;
        top: -90px;
        animation: chongdie_scale4 1.5s 0.3s linear 1 both;
    }
    .chongdie_scale5{
        width: 0%;
        height: 0%;
        top: -110px;
        animation: chongdie_scale5 1.5s 0.4s linear 1 both;
    }
    .chongdie_scale6{
        width: 0%;
        height: 0%;
        top: -140px;
        animation: chongdie_scale6 1.5s 0.5s linear 1 both;
    }
    @keyframes chongdie_scale1 {
       
        100% {
            width: 100%;
            height: 100%;
            top: 0;
        }
    }
    @keyframes chongdie_scale2 {
       100% {
            width: 100%;
            height: 100%;
            top: 0;
            transform: translateZ(-4px);
        }
    }
    @keyframes chongdie_scale3 {
        100% {
            width: 100%;
            height: 100%;
            top: 0;
            transform: translateZ(-8px);
        }
    }
    @keyframes chongdie_scale4 {
       100% {
            width: 100%;
            height: 100%;
            top: 0;
            transform: translateZ(-12px);
        }
    }
    @keyframes chongdie_scale5 {
        100% {
            width: 100%;
            height: 100%;
            top: 0;
            transform: translateZ(-16px);
        }
    }
    @keyframes chongdie_scale6 {
        100% {
            width: 100%;
            height: 100%;
            top: 0;
            transform: translateZ(-20px);
        }
    }
    .chongdie_page2_bg{
        width: 100vw;
        height: 100vh;
        position: absolute;
        top:0;
        left:0;
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center center;
    } 
</style>
