<template>
	<div class="page">
		<div class="bg" style="background-image: url(http://litiresource.xcgogo.site/user/6403275/images/96a022363f32ee110716e40c007318fd.jpg)"></div>
		<img class="n_jie n_jie_0" src="http://litiresource.xcgogo.site/user/6403275/images/87201d798035aa7bd65e17cdbcb9a73c.png">
		<img class="n_jie n_jie_1" src="http://litiresource.xcgogo.site/user/6403275/images/87201d798035aa7bd65e17cdbcb9a73c.png">
		<div class="n_frame_hd"></div>
		<div class="n_frame_bd" :style="{opacity: isFrameShow ? 1 : 0}">
			<div class="n_frame" :style="frameStyle" :class="frameAni">
				<img class="n_photo_border" src="http://litiresource.xcgogo.site/user/6403275/images/d4a8c8626388525b8df3b575028cab5a.png">
				<img class="n_denglong n_denglong_0" src="http://litiresource.xcgogo.site/user/6403275/images/dd5bfd4f68b38fd623091d33e974e7d3.png">
				<img class="n_denglong n_denglong_1" src="http://litiresource.xcgogo.site/user/6403275/images/dd5bfd4f68b38fd623091d33e974e7d3.png">
				<img class="n_bianpao" src="http://litiresource.xcgogo.site/user/6403275/images/a38c808ee8c22a4c3850568acef83d09.png">
				<img class="n_photo" :src="currentImage">
				<!-- <span class="n_photo_text">{{currentText}}</span> -->
				<!-- 字幕 -->
				<UserText :mode="mode['mode']" :currentText="currentText"></UserText>
			</div>
		</div>
		<div class="n_text_ft">
			<div class="n_text">
				<img id="xin_1" class="letter letter-zi letter1" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/003.png">
			    <img id="nian_1" class="letter letter-zi letter2" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/004.png">
			    <img id="xi_1" class="letter letter-zi letter3" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/005.png">
			    <img id="qi_1" class="letter letter-zi letter4" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.9s 1 normal both running;" src="http://image1.17ll.com/gcard/745/006.png">
			    <img id="yang_1" class="letter letter-zi letter5" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.8s 1 normal both running;" src="http://image1.17ll.com/gcard/745/007.png">
			    <img id="yang1_1" class="letter letter-zi letter6" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.7s 1 normal both running;" src="http://image1.17ll.com/gcard/745/007.png">
			    <img id="dou_1" class="letter letter7" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.6s 1 normal both running;top: 32px!important;" src="http://image1.17ll.com/gcard/745/008.png">
			    <img id="man_1" class="letter letter-zi letter8" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.5s 1 normal both running;" src="http://image1.17ll.com/gcard/745/009.png">
			    <img id="mian_1" class="letter letter-zi letter9" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.4s 1 normal both running;" src="http://image1.17ll.com/gcard/745/010.png">
			    <img id="can_1" class="letter letter-zi letter10" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.3s 1 normal both running;" src="http://image1.17ll.com/gcard/745/011.png">
			    <img id="lan_1" class="letter letter-zi letter11" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/012.png">
			    <img id="yang2_1" class="letter letter-zi letter12" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/013.png">
			    <img id="guang_1" class="letter letter-zi letter13" :style="{animationName: (currentZhufu == 0 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0s 1 normal both running;" src="http://image1.17ll.com/gcard/745/014.png">

			    <img id="yi_2" class="letter letter1" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.2s 1 normal both running;top: 23px!important;" src="http://image1.17ll.com/gcard/745/016.png">
			    <img id="sheng_2" class="letter letter2" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/017.png">
			    <img id="jian_2" class="letter letter3" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/018.png">
			    <img id="kang_2" class="letter letter4" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.9s 1 normal both running;" src="http://image1.17ll.com/gcard/745/019.png">
			    <img id="ping_2" class="letter letter5" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.8s 1 normal both running;" src="http://image1.17ll.com/gcard/745/020.png">
			    <img id="an_2" class="letter letter6" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.7s 1 normal both running;" src="http://image1.17ll.com/gcard/745/021.png">
			    <img id="dou_2" class="letter letter7" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.6s 1 normal both running;top: 32px!important;" src="http://image1.17ll.com/gcard/745/008.png">
			    <img id="hao_2" class="letter letter8" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.5s 1 normal both running;" src="http://image1.17ll.com/gcard/745/022.png">
			    <img id="shi_2" class="letter letter9" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.4s 1 normal both running;" src="http://image1.17ll.com/gcard/745/023.png">
			    <img id="jie_2" class="letter letter10" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.3s 1 normal both running;" src="http://image1.17ll.com/gcard/745/024.png">
			    <img id="er_2" class="letter letter11" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.2s 1 normal both running;top: 17px!important;" src="http://image1.17ll.com/gcard/745/025.png">
			    <img id="lian_2" class="letter letter12" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/026.png">
			    <img id="san_2" class="letter letter13" :style="{animationName: (currentZhufu == 1 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0s 1 normal both running;top: 17px!important;" src="http://image1.17ll.com/gcard/745/027.png">

			    <img id="tai_3" class="letter letter1" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/028.png">
			    <img id="tou_3" class="letter letter2" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/029.png">
			    <img id="si_3" class="letter letter3" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1s 1 normal both running;top: 17px!important;" src="http://image1.17ll.com/gcard/745/030.png">
			    <img id="ji_3" class="letter letter4" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.9s 1 normal both running;" src="http://image1.17ll.com/gcard/745/031.png">
			    <img id="lai_3" class="letter letter5" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.8s 1 normal both running;" src="http://image1.17ll.com/gcard/745/032.png">
			    <img id="cai_3" class="letter letter6" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.7s 1 normal both running;" src="http://image1.17ll.com/gcard/745/033.png">
			    <img id="dou_3" class="letter letter7" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.6s 1 normal both running;top: 32px!important;" src="http://image1.17ll.com/gcard/745/008.png">
			    <img id="sheng_3" class="letter letter8" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.5s 1 normal both running;" src="http://image1.17ll.com/gcard/745/017.png">
			    <img id="huo_3" class="letter letter9" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.4s 1 normal both running;" src="http://image1.17ll.com/gcard/745/034.png">
			    <img id="wu_3" class="letter letter10" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.3s 1 normal both running;top: 16px!important;" src="http://image1.17ll.com/gcard/745/035.png">
			    <img id="cai1_3" class="letter letter11" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/036.png">
			    <img id="bin_3" class="letter letter12" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/037.png">
			    <img id="fen_3" class="letter letter13" :style="{animationName: (currentZhufu == 2 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0s 1 normal both running;" src="http://image1.17ll.com/gcard/745/038.png">
			    
			    <img id="shi_4" class="letter letter1" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/023.png">
			    <img id="ye_4" class="letter letter2" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/040.png">
			    <img id="liu_4" class="letter letter3" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1s 1 normal both running;top: 16px!important;" src="http://image1.17ll.com/gcard/745/041.png">
			    <img id="liu1_4" class="letter letter4" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.9s 1 normal both running;top: 16px!important;" src="http://image1.17ll.com/gcard/745/041.png">
			    <img id="da_4" class="letter letter5" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.8s 1 normal both running;" src="http://image1.17ll.com/gcard/745/042.png">
			    <img id="shun_4" class="letter letter6" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.7s 1 normal both running;" src="http://image1.17ll.com/gcard/745/043.png">
			    <img id="dou_4" class="letter letter7" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.6s 1 normal both running;top: 32px!important;" src="http://image1.17ll.com/gcard/745/008.png">
			    <img id="ri_4" class="letter letter8" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.5s 1 normal both running;" src="http://image1.17ll.com/gcard/745/044.png">
			    <img id="zi_4" class="letter letter9" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.4s 1 normal both running;" src="http://image1.17ll.com/gcard/745/045.png">
			    <img id="qi_4" class="letter letter10" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.3s 1 normal both running;" src="http://image1.17ll.com/gcard/745/046.png">
			    <img id="xing_4" class="letter letter11" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/047.png">
			    <img id="gao_4" class="letter letter12" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/048.png">
			    <img id="zhao_4" class="letter letter13" :style="{animationName: (currentZhufu == 3 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0s 1 normal both running;" src="http://image1.17ll.com/gcard/745/049.png">
			    
			    <img id="ba_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.2s 1 normal both running;" class="letter letter1" src="http://image1.17ll.com/gcard/745/050.png">
			    <img id="fang_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.1s 1 normal both running;" class="letter letter2" src="http://image1.17ll.com/gcard/745/051.png">
			    <img id="hao_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1s 1 normal both running;" class="letter letter3" src="http://image1.17ll.com/gcard/745/022.png">
			    <img id="yun_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.9s 1 normal both running;" class="letter letter4" src="http://image1.17ll.com/gcard/745/052.png">
			    <img id="jie_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.8s 1 normal both running;" class="letter letter5" src="http://image1.17ll.com/gcard/745/053.png">
			    <img id="lai_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.7s 1 normal both running;" class="letter letter6" src="http://image1.17ll.com/gcard/745/032.png">
			    <img id="dou_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.6s 1 normal both running;top: 32px!important;" class="letter letter7" src="http://image1.17ll.com/gcard/745/008.png">
			    <img id="fan_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.5s 1 normal both running;" class="letter letter8" src="http://image1.17ll.com/gcard/745/054.png">
			    <img id="nao_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.4s 1 normal both running;" class="letter letter9" src="http://image1.17ll.com/gcard/745/055.png">
			    <img id="jiu_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.3s 1 normal both running;" class="letter letter10" src="http://image1.17ll.com/gcard/745/056.png">
			    <img id="xiao_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.2s 1 normal both running;" class="letter letter11" src="http://image1.17ll.com/gcard/745/057.png">
			    <img id="yun1_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.1s 1 normal both running;" class="letter letter12" src="http://image1.17ll.com/gcard/745/052.png">
			    <img id="wai_5" :style="{animationName: (currentZhufu == 4 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0s 1 normal both running;" class="letter letter13" src="http://image1.17ll.com/gcard/745/058.png">
			    
			    <img id="ai_6" class="letter letter1" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0s 1 normal both running;" src="http://image1.17ll.com/gcard/745/059.png">
			    <img id="qing_6" class="letter letter2" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/060.png">
			    <img id="yang_6" class="letter letter3" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/061.png">
			    <img id="mei_6" class="letter letter4" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.3s 1 normal both running;" src="http://image1.17ll.com/gcard/745/062.png">
			    <img id="tu_6" class="letter letter5" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.4s 1 normal both running;" src="http://image1.17ll.com/gcard/745/063.png">
			    <img id="qi_6" class="letter letter6" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.5s 1 normal both running;" src="http://image1.17ll.com/gcard/745/006.png">
			    <img id="dou_6" class="letter letter7" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.6s 1 normal both running;top: 32px!important;" src="http://image1.17ll.com/gcard/745/008.png">
			    <img id="wan_6" class="letter letter8" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.7s 1 normal both running;" src="http://image1.17ll.com/gcard/745/064.png">
			    <img id="shi_6" class="letter letter9" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.8s 1 normal both running;" src="http://image1.17ll.com/gcard/745/023.png">
			    <img id="yang1_6" class="letter letter10" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 0.9s 1 normal both running;" src="http://image1.17ll.com/gcard/745/061.png">
			    <img id="yang2_6" class="letter letter11" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/061.png">
			    <img id="de_6" class="letter letter12" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.1s 1 normal both running;" src="http://image1.17ll.com/gcard/745/065.png">
			    <img id="yi_6" class="letter letter13" :style="{animationName: (currentZhufu == 5 ? 'showzimu' : 'diszimu')}" style="display: block; animation: 0.5s linear 1.2s 1 normal both running;" src="http://image1.17ll.com/gcard/745/066.png">

				<img src="http://litiresource.xcgogo.site/user/5980171/images/4ac859549363e53d0f21d2a39d51dbe4.png">
			</div>
		</div>

		<!--开场-->
		<div class="n_BX_AEComposition">
		    <div class="n_BX_Class6 n_BX_Ani6">
		        <div class="n_BX_Class7 n_BX_BG7 n_BX_Ani7" id="n_BX_Layer7"></div>
		    </div>
		    <div class="n_BX_Class6 n_BX_BG6 n_BX_Ani6" id="n_BX_Layer6">
		    	<span class="BX_title">{{currentTitle}}</span>
		    </div>
		    <div class="n_BX_Class6 n_BX_Ani6">
		        <div class="n_BX_Class5 n_BX_BG5 n_BX_Ani5" id="n_BX_Layer5"></div>
		    </div>
		    <div class="n_BX_Class6 n_BX_Ani6">
		        <div class="n_BX_Class4 n_BX_BG4 n_BX_Ani4" id="n_BX_Layer4"></div>
		    </div>
		    <div class="n_BX_Class6 n_BX_Ani6">
		        <div class="n_BX_Class3 n_BX_BG3 n_BX_Ani3" id="n_BX_Layer3"></div>
		    </div>
		    <div class="n_BX_Class6 n_BX_Ani6">
		        <div class="n_BX_Class2 n_BX_BG2 n_BX_Ani2" id="n_BX_Layer2"></div>
		    </div>
		    <div class="n_BX_Class6 n_BX_Ani6">
		        <div class="n_BX_Class1 n_BX_BG1 n_BX_Ani1" id="n_BX_Layer1"></div>
		    </div>
		</div>

		<!--下雪-->
		<snow :mode="['http://resali.lititutu.cn/tuku/0e53e7543cca705acb673b1f2fc5e8b0.png']"></snow>
	</div>
</template>

<script>
	
	// import C from '@/config'

	import UserText from '@/components/UserText'

	let showFrameAnis = ['frame_show_1', 'frame_show_2', 'frame_show_3', 'frame_show_4'];

	export default {
		name: 'n_yuandankuaile',
		components: {UserText},
		props: ['mode'],
		data () {
			return {
				frameSize: [460, 600],
				frameStyle: {
					width: '0px',
					height: '0px'
				},
				currentImage: '',
				frameAni: '',
				pageidx: -1,
				currentZhufu: 0,
				currentText: '',

				duration: 6000,
				aniEvent: null,

				isFrameShow: false
			}
		},
		watch: {
			pageidx () {
				let imageidx = this.pageidx % this.$store.state.config.images.length;

				this.getImageOriSize(imageidx).then((orisize) => {
					let twidth, theight;

					// 横图
					if (orisize[0] > orisize[1]) {
						twidth = this.frameSize[0];
						theight = twidth * orisize[1] / orisize[0];
					} else {
						theight = this.frameSize[1];
						twidth = theight * orisize[0] / orisize[1];
					}

					this.frameStyle = {
						width: twidth + 'px',
						marginLeft: (-(twidth/2)).toString() + 'px',
						height: theight + 'px',
						marginTop: (-(theight/2)).toString() + 'px'
					}

					this.currentImage = this.$store.state.config['images'][imageidx]['im'];
					this.currentText = this.$store.state.config['images'][imageidx]['tx'];

					this.frameAni = showFrameAnis[ this.pageidx % showFrameAnis.length ];

					this.currentZhufu = this.pageidx % 6;

					setTimeout(() => {
						this.frameAni = 'frame_hide';
					}, this.duration - 1000);
				})
				// this.$store.dispatch('', {
				// 	imageidx: imageidx
				// }).then((orisize) => {
				// 	let twidth, theight;

				// 	// 横图
				// 	if (orisize[0] > orisize[1]) {
				// 		twidth = this.frameSize[0];
				// 		theight = twidth * orisize[1] / orisize[0];
				// 	} else {
				// 		theight = this.frameSize[1];
				// 		twidth = theight * orisize[0] / orisize[1];
				// 	}

				// 	this.frameStyle = {
				// 		width: twidth + 'px',
				// 		marginLeft: (-(twidth/2)).toString() + 'px',
				// 		height: theight + 'px',
				// 		marginTop: (-(theight/2)).toString() + 'px'
				// 	}

				// 	this.currentImage = this.$store.state.config['images'][imageidx]['im'];
				// 	this.currentText = this.$store.state.config['images'][imageidx]['tx'];

				// 	this.frameAni = showFrameAnis[ this.pageidx % showFrameAnis.length ];

				// 	this.currentZhufu = this.pageidx % 6;

				// 	setTimeout(() => {
				// 		this.frameAni = 'frame_hide';
				// 	}, this.duration - 1000)
				// });
			},
			// imageidx: {
			// 	handler () {
					
			// 	},
			// 	immediate: true
			// }
		},
		created () {
			this.aniEvent = setInterval(() => {
				this.isFrameShow = true;
				this.pageidx ++;
			}, this.duration);
		},
		destroyed () {
			clearInterval(this.aniEvent);
		},
		computed: {
			currentTitle () {
				return this.$store.state.config['data']['ti'] || this.mode['name'];
			}
		},
		methods: {
			getImageOriSize (imageidx) {
				let that = this;
				return new Promise(( resolve, reject ) => {
					if (that.$store.state.config['images'][imageidx]['orisize']) {
						resolve(that.$store.state.config['images'][imageidx]['orisize']);
					} else {
						let imsrc = that.$store.state.config['images'][imageidx]['im'];
						let im = new Image();
						im.onload = function() {
							let orisize = [im.width, im.height];
							that.$store.commit('setImageOriSize', {
								orisize: orisize,
								imageidx: imageidx
							});
							resolve(orisize);
						}
						im.src = imsrc;
					}
				})
			}
		}
	}
</script>

<style lang="less">
	
	@left: 23px;

	.n_frame_hd {
		position: relative;
		width: 100%;
		height: 10%;
	}
	.n_frame_bd {
		position: relative;
		width: 100%;
		height: 70%;
		// background-color: black;
	}
	.n_text_ft {
		position: relative;
		width: 100%;
		height: 20%;
		/*display: flex;
		justify-content: center;
		align-items: center;*/
	}
	.n_text {
		position: absolute;
		width: 430px;
		height: 57px;
		top: 20px;
		left: 50%;
		margin-left: -215px;
	}
	.n_text>img {
		position: absolute;
	}
	.n_text_border {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.n_frame {
		position: absolute;
		top: 50%; left: 50%;
		/*background-color: black;*/
	}
	.n_frame_move {
		animation: 1.5s linear 2s infinite alternate both running huandong;
	}
	.n_photo {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.n_photo_border {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		z-index: 1;
	}
	.n_photo_text {
		position: absolute;
		width: 100%;
		height: 50px;
		left: 0;
		right: 0;
		text-align: center;
		margin: auto;
		bottom: 10px;
		color: white;
		font-size: 35px;
		text-shadow: 2px 2px 10px black;
		font-weight: bold;
		z-index: 999;
	}
	.n_denglong {
		position: absolute;
		top: 0;
		z-index: 1;
	}
	.n_denglong_0 {
		left: 20px;
	    transform-origin: 50% 0%;
    	animation: huangdong 3s linear infinite alternate both;
	}
	.n_denglong_1 {
		right: 20px;
		transform-origin: 50% 0%;
    	animation: huangdong 3.2s linear -3s infinite alternate both;
	}
	.n_bianpao {
		position: absolute;
		left: -15px;
		bottom: -15px;
		z-index: 1;
	}
	.n_jie {
		position: absolute;
		top: 72px;
		width: 70px;
	}
	.n_jie_0 {
		left: 10px;
		transform-origin: 27px 3px;
    	animation: huangdong 3.2s linear infinite alternate both;
	}
	.n_jie_1 {
		right: 10px;
		transform-origin: 27px 3px;
    	animation: huangdong 3s linear -2s infinite alternate both;
	}
	@keyframes huangdong {
		0% {
		    transform: rotateZ(-5deg);
		}
		100% {
		    transform: rotateZ(5deg);
		}
	}
	@keyframes huandong {
		0% {
		    transform: translate(0,8px);
		}
		100% {
		    transform: translate(0,-8px);
		}
	}
	.frame_show_1 {
		animation: 2s ease-in-out 0s 1 normal both running frame_show_1;
	}
	.frame_show_2 {
		animation: 2s ease-in-out 0s 1 normal both running frame_show_2;
	}
	.frame_show_3 {
		animation: 2s ease-in-out 0s 1 normal both running frame_show_3;
	}
	.frame_show_4 {
		animation: 2s ease-in-out 0s 1 normal both running frame_show_4;
	}
	.frame_hide {
		animation: 1s ease-in-out 0s 1 normal both running frame_hide;
	}
	@keyframes frame_hide {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
	@keyframes frame_show_1 {
		0% {
		    transform: scale(1.2) translate(0,-30px);
		    opacity: 0;
		}
		50% {
		    opacity: 1;
		}
		100% {
		    transform: scale(1) translate(0,0px);
		    opacity: 1;
		}
	}
	@keyframes frame_show_2 {
		0% {
		    transform: scale(1.2) translate(0,30px);
		    opacity: 0;
		}
		50% {
		    opacity: 1;
		}
		100% {
		    transform: scale(1) translate(0,0px);
		    opacity: 1;
		}
	}
	@keyframes frame_show_3 {
		0% {
		    transform: scale(1.2) translate(30px,0px);
		    opacity: 0;
		}
		50% {
		    opacity: 1;
		}
		100% {
		    transform: scale(1) translate(0,0px);
		    opacity: 1;
		}
	}
	@keyframes frame_show_4 {
		0% {
		    transform: scale(1.2) translate(-30px, 0px);
		    opacity: 0;
		}
		50% {
		    opacity: 1;
		}
		100% {
		    transform: scale(1) translate(0, 0px);
		    opacity: 1;
		}
	}
	.letter {
		top: 15px;
		// width: 25px;
		// height: 25px;
	}
	.letter1 {
		left: @left;
		/*top: 676px;*/
	}
	.letter2 {
		left: @left+30;
		/*top: 676px;*/
	}
	.letter3 {
		left: @left+60;
		/*top: 676px;*/
	}
	.letter4 {
		left: @left+90;
		/*top: 676px;*/
	}
	.letter5 {
		left: @left+120;
		/*top: 676px;*/
	}
	.letter6 {
		left: @left+150;
		/*top: 676px;*/
	}
	.letter7 {
		left: @left+180;
		/*top: 676px;*/
	}
	.letter8 {
		left: @left+210;
		/*top: 676px;*/
	}
	.letter9 {
		left: @left+240;
		/*top: 676px;*/
	}
	.letter10 {
		left: @left+270;
		/*top: 676px;*/
	}
	.letter11 {
		left: @left+300;
		/*top: 676px;*/
	}
	.letter12 {
		left: @left+330;
		/*top: 676px;*/
	}
	.letter13 {
		left: @left+360;
		/*top: 676px;*/
	}
	.letter-zi {
		width: 25px;
		height: 25px;
	}
	@keyframes showzimu {
		0% {
		    transform: translate(10px,-5px);
		    opacity: 0;
		}
		100% {
		    transform: translate(0px,0px);
		    opacity: 1;
		}
	}
	@keyframes diszimu {
		100% {
		    transform: translate(10px,-5px);
		    opacity: 0;
		}
		0% {
		    transform: translate(0px,0px);
		    opacity: 1;
		}
	}

	/* 开场 */
	.n_BX_AEComposition {
	    position: absolute;
	    background: transparent;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    // display: flex;
	    // justify-content: center;
	    // align-items: center;
	    // left: -20px;
	    // width: 540px;
	    // height: 960px;
	    display: block;
	    overflow: hidden;
	    transform: translate3d(0, 0, 0) scale(1, 1);
	    transform-origin:50% 0%;
	    z-index: 8;
	}
	.n_BX_Class7 {
	    left: 280.00px;
	    top: 306.50px;
	    width: 141px;
	    height: 120px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.23, 1.23);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.n_BX_BG7 {
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/33500b4fbafd2b177fc1b6c6d655e3a5.png");
	}
	.n_BX_Ani7 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:n_BX_AniKey7 6.67s ease 0.00s 1 normal none;
	}
	@keyframes n_BX_AniKey7 {
	    0.00% {
	        opacity: 0.00;
	    }
	    10.50% {
	        opacity: 0.10;
	    }
	    11.00% {
	        opacity: 0.20;
	    }
	    11.50% {
	        opacity: 0.30;
	    }
	    12.00% {
	        opacity: 0.40;
	    }
	    12.50% {
	        opacity: 0.50;
	    }
	    13.00% {
	        opacity: 0.60;
	    }
	    13.50% {
	        opacity: 0.70;
	    }
	    14.00% {
	        opacity: 0.80;
	    }
	    14.50% {
	        opacity: 0.90;
	    }
	    15.00% {
	        opacity: 1.00;
	    }
	    100.00% {
	        opacity: 1.00;
	    }
	}
	.n_BX_Class6 {
	    // left: 49.50px;
	    // top: 259.50px;
	    top: 50%;
	    left: 50%;
	    margin-left: -220.5px;
	    margin-top: -220.5px;
	    width: 441px;
	    height: 441px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.01, 0.01);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.n_BX_BG6 {
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/33b03d47d09e664820add79f4a3db5ef.png");
	}
	.n_BX_Ani6 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:n_BX_AniKey6 6.67s ease 0.00s 1 normal none;
	}
	@keyframes n_BX_AniKey6 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.01, 0.01);
	        opacity: 0.00;
	    }
	    0.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.159, 0.159);
	        opacity: 0.10;
	    }
	    1.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.308, 0.308);
	        opacity: 0.20;
	    }
	    1.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.457, 0.457);
	        opacity: 0.30;
	    }
	    2.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.606, 0.606);
	        opacity: 0.40;
	    }
	    2.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.755, 0.755);
	        opacity: 0.50;
	    }
	    3.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.904, 0.904);
	        opacity: 0.60;
	    }
	    3.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.053, 1.053);
	        opacity: 0.70;
	    }
	    4.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.202, 1.202);
	        opacity: 0.80;
	    }
	    4.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.351, 1.351);
	        opacity: 0.90;
	    }
	    5.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.5, 1.5);
	        opacity: 1.00;
	    }
	    5.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.42, 1.42);
	        opacity: 1.00;
	    }
	    6.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.34, 1.34);
	        opacity: 1.00;
	    }
	    6.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.26, 1.26);
	        opacity: 1.00;
	    }
	    7.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.18, 1.18);
	        opacity: 1.00;
	    }
	    7.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.1, 1.1);
	        opacity: 1.00;
	    }
	    8.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.02, 1.02);
	        opacity: 1.00;
	    }
	    8.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.94, 0.94);
	        opacity: 1.00;
	    }
	    9.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.86, 0.86);
	        opacity: 1.00;
	    }
	    9.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.78, 0.78);
	        opacity: 1.00;
	    }
	    10.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.7, 0.7);
	        opacity: 1.00;
	    }
	    10.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.755, 0.755);
	        opacity: 1.00;
	    }
	    11.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.81, 0.81);
	        opacity: 1.00;
	    }
	    11.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.865, 0.865);
	        opacity: 1.00;
	    }
	    12.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.92, 0.92);
	        opacity: 1.00;
	    }
	    12.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.975, 0.975);
	        opacity: 1.00;
	    }
	    13.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.03, 1.03);
	        opacity: 1.00;
	    }
	    13.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.085, 1.085);
	        opacity: 1.00;
	    }
	    14.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.14, 1.14);
	        opacity: 1.00;
	    }
	    14.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.195, 1.195);
	        opacity: 1.00;
	    }
	    15.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.25, 1.25);
	        opacity: 1.00;
	    }
	    15.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.21, 1.21);
	        opacity: 1.00;
	    }
	    16.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.17, 1.17);
	        opacity: 1.00;
	    }
	    16.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.13, 1.13);
	        opacity: 1.00;
	    }
	    17.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.09, 1.09);
	        opacity: 1.00;
	    }
	    17.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.05, 1.05);
	        opacity: 1.00;
	    }
	    18.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.01, 1.01);
	        opacity: 1.00;
	    }
	    18.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.97, 0.97);
	        opacity: 1.00;
	    }
	    19.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.93, 0.93);
	        opacity: 1.00;
	    }
	    19.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.89, 0.89);
	        opacity: 1.00;
	    }
	    20.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.85, 0.85);
	        opacity: 1.00;
	    }
	    20.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.875, 0.875);
	        opacity: 1.00;
	    }
	    21.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.9, 0.9);
	        opacity: 1.00;
	    }
	    21.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.925, 0.925);
	        opacity: 1.00;
	    }
	    22.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.95, 0.95);
	        opacity: 1.00;
	    }
	    22.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.975, 0.975);
	        opacity: 1.00;
	    }
	    23.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    23.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.025, 1.025);
	        opacity: 1.00;
	    }
	    24.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.05, 1.05);
	        opacity: 1.00;
	    }
	    24.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.075, 1.075);
	        opacity: 1.00;
	    }
	    25.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.1, 1.1);
	        opacity: 1.00;
	    }
	    25.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.09, 1.09);
	        opacity: 1.00;
	    }
	    26.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.08, 1.08);
	        opacity: 1.00;
	    }
	    26.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.07, 1.07);
	        opacity: 1.00;
	    }
	    27.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.06, 1.06);
	        opacity: 1.00;
	    }
	    27.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.05, 1.05);
	        opacity: 1.00;
	    }
	    28.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.04, 1.04);
	        opacity: 1.00;
	    }
	    28.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.03, 1.03);
	        opacity: 1.00;
	    }
	    29.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.02, 1.02);
	        opacity: 1.00;
	    }
	    29.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.01, 1.01);
	        opacity: 1.00;
	    }
	    30.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    90.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.05, 1.05);
	        opacity: 1.00;
	    }
	    91.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.1, 1.1);
	        opacity: 1.00;
	    }
	    91.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.15, 1.15);
	        opacity: 1.00;
	    }
	    92.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.2, 1.2);
	        opacity: 1.00;
	    }
	    92.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.25, 1.25);
	        opacity: 1.00;
	    }
	    93.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.3, 1.3);
	        opacity: 1.00;
	    }
	    93.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.35, 1.35);
	        opacity: 1.00;
	    }
	    94.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.4, 1.4);
	        opacity: 1.00;
	    }
	    94.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.45, 1.45);
	        opacity: 1.00;
	    }
	    95.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.5, 1.5);
	        opacity: 1.00;
	    }
	    95.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.351, 1.351);
	        opacity: 0.90;
	    }
	    96.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.202, 1.202);
	        opacity: 0.80;
	    }
	    96.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.053, 1.053);
	        opacity: 0.70;
	    }
	    97.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.904, 0.904);
	        opacity: 0.60;
	    }
	    97.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.755, 0.755);
	        opacity: 0.50;
	    }
	    98.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.606, 0.606);
	        opacity: 0.40;
	    }
	    98.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.457, 0.457);
	        opacity: 0.30;
	    }
	    99.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.308, 0.308);
	        opacity: 0.20;
	    }
	    99.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.159, 0.159);
	        opacity: 0.10;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.01, 0.01);
	        opacity: 0.00;
	    }
	}
	.n_BX_Class5 {
	    left: 5.58px;
	    top: 307.01px;
	    width: 141px;
	    height: 120px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.n_BX_BG5 {
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/33500b4fbafd2b177fc1b6c6d655e3a5.png");
	}
	.n_BX_Ani5 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:n_BX_AniKey5 6.67s ease 0.00s 1 normal none;
	}
	@keyframes n_BX_AniKey5 {
	    0.00% {
	        opacity: 0.00;
	    }
	    10.50% {
	        opacity: 0.10;
	    }
	    11.00% {
	        opacity: 0.20;
	    }
	    11.50% {
	        opacity: 0.30;
	    }
	    12.00% {
	        opacity: 0.40;
	    }
	    12.50% {
	        opacity: 0.50;
	    }
	    13.00% {
	        opacity: 0.60;
	    }
	    13.50% {
	        opacity: 0.70;
	    }
	    14.00% {
	        opacity: 0.80;
	    }
	    14.50% {
	        opacity: 0.90;
	    }
	    15.00% {
	        opacity: 1.00;
	    }
	    100.00% {
	        opacity: 1.00;
	    }
	}
	.n_BX_Class4 {
	    left: -31.00px;
	    top: 186.00px;
	    width: 121px;
	    height: 59px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.n_BX_BG4 {
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/11cb13e092a38eeb3aa393ef3fc03a64.png");
	}
	.n_BX_Ani4 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:n_BX_AniKey4 6.67s ease 0.00s 1 normal none;
	}
	@keyframes n_BX_AniKey4 {
	    0.00% {
	        opacity: 0.00;
	    }
	    10.50% {
	        opacity: 0.10;
	    }
	    11.00% {
	        opacity: 0.20;
	    }
	    11.50% {
	        opacity: 0.30;
	    }
	    12.00% {
	        opacity: 0.40;
	    }
	    12.50% {
	        opacity: 0.50;
	    }
	    13.00% {
	        opacity: 0.60;
	    }
	    13.50% {
	        opacity: 0.70;
	    }
	    14.00% {
	        opacity: 0.80;
	    }
	    14.50% {
	        opacity: 0.90;
	    }
	    15.00% {
	        opacity: 1.00;
	    }
	    100.00% {
	        opacity: 1.00;
	    }
	}
	.n_BX_Class3 {
	    left: 281.00px;
	    top: 7.00px;
	    width: 121px;
	    height: 59px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.n_BX_BG3 {
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/11cb13e092a38eeb3aa393ef3fc03a64.png");
	}
	.n_BX_Ani3 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:n_BX_AniKey3 6.67s ease 0.00s 1 normal none;
	}
	@keyframes n_BX_AniKey3 {
	    0.00% {
	        opacity: 0.00;
	    }
	    10.50% {
	        opacity: 0.10;
	    }
	    11.00% {
	        opacity: 0.20;
	    }
	    11.50% {
	        opacity: 0.30;
	    }
	    12.00% {
	        opacity: 0.40;
	    }
	    12.50% {
	        opacity: 0.50;
	    }
	    13.00% {
	        opacity: 0.60;
	    }
	    13.50% {
	        opacity: 0.70;
	    }
	    14.00% {
	        opacity: 0.80;
	    }
	    14.50% {
	        opacity: 0.90;
	    }
	    15.00% {
	        opacity: 1.00;
	    }
	    100.00% {
	        opacity: 1.00;
	    }
	}
	.n_BX_Class2 {
	    left: 202.00px;
	    top: 213.00px;
	    width: 139px;
	    height: 161px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(180.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.n_BX_BG2 {
		// 元旦
	    // background: url("http://litiresource.xcgogo.site/user/5980171/images/038d2faadc232ce750660a0c2a3f6ab7.png");

	    // 新年
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/7f6984abb7bd308cb62babb113adf2b7.png");
	    background-size: contain;
	    background-position: center;
	    background-repeat: no-repeat;
	}
	.n_BX_Ani2 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:n_BX_AniKey2 6.67s ease 0.00s 1 normal none;
	}
	@keyframes n_BX_AniKey2 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(180.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    15.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(157.50deg) scale(1, 1);
	        opacity: 0.13;
	    }
	    16.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(135.00deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    16.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(112.50deg) scale(1, 1);
	        opacity: 0.38;
	    }
	    17.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(90.00deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    17.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(67.50deg) scale(1, 1);
	        opacity: 0.63;
	    }
	    18.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(45.00deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    18.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(22.50deg) scale(1, 1);
	        opacity: 0.88;
	    }
	    19.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    60.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(22.50deg) scale(1, 1);
	        opacity: 0.88;
	    }
	    61.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(45.00deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    61.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(67.50deg) scale(1, 1);
	        opacity: 0.63;
	    }
	    62.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(90.00deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    62.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(112.50deg) scale(1, 1);
	        opacity: 0.38;
	    }
	    63.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(135.00deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    63.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(157.50deg) scale(1, 1);
	        opacity: 0.13;
	    }
	    64.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(180.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(180.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	}
	.n_BX_Class1 {
	    left: 81.50px;
	    top: 73.50px;
	    width: 182px;
	    height: 136px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(-180.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.n_BX_BG1 {
		// 元旦
	    // background: url("http://litiresource.xcgogo.site/user/5980171/images/66482ee58a155118a9d39f90fec01d84.png");
	    // 新年
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/8f72e4bb09fc5a33a79d6848e89b56c2.png");
	    background-size: contain;
	    background-position: center;
	    background-repeat: no-repeat;
	}
	.n_BX_Ani1 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:n_BX_AniKey1 6.67s ease 0.00s 1 normal none;
	}
	@keyframes n_BX_AniKey1 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-180.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    11.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-157.50deg) scale(1, 1);
	        opacity: 0.13;
	    }
	    12.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-135.00deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    12.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-112.50deg) scale(1, 1);
	        opacity: 0.38;
	    }
	    13.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-90.00deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    13.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-67.50deg) scale(1, 1);
	        opacity: 0.63;
	    }
	    14.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-45.00deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    14.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-22.50deg) scale(1, 1);
	        opacity: 0.88;
	    }
	    15.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    56.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-22.50deg) scale(1, 1);
	        opacity: 0.88;
	    }
	    57.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-45.00deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    57.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-67.50deg) scale(1, 1);
	        opacity: 0.63;
	    }
	    58.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-90.00deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    58.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-112.50deg) scale(1, 1);
	        opacity: 0.38;
	    }
	    59.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-135.00deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    59.50% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-157.50deg) scale(1, 1);
	        opacity: 0.13;
	    }
	    60.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-180.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(-180.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	}
	.BX_title {
		position: absolute;
		z-index: 10;
		font-size: 35px;
		width: 348px;
		height: 695px;
		left: 50%;
		margin-left: -174px;
		top: 50%;
		margin-top: -348px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		color: #f30f4a;
		text-shadow: 0 0 5px #FFFFFF, 0 0 5px #FFFFFF, 0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 10px #FFFFFF, 0 0 10px #FFFFFF, 0 0 15px #FFFFFF, 0 0 15px #FFFFFF, 0 0 15px #FFFFFF;
		animation: n_BX_AniTitle .8s ease 4s both;
	}
	@keyframes n_BX_AniTitle {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
</style>