class stRouter {
    constructor (util) {
        this.$util = util;
        this._scene = 1000;
    }
    transformUrl (url) {
        if (!url) { // 检查url合法性
            this.$util.$loger.warn("⚠️转跳路由url不合法")
            return url;
        }
        if (this.$util.isEnv("wxa") && url.indexOf("mp.weixin.qq.com") !== -1) {
            url = "/subpackages/mpwebview/mpwebview?url=" + encodeURI( url );
        }
        if (url.indexOf("http") == 0) { // url为h5url时直接返回
            return url;
        }
        url = url.replace(".html", "");
        if (url.indexOf("/") != 0) {
            url = "/" + url;
        }

        
        // 对问号前的路径进行decode
        if (url.indexOf("?") != -1) {
            let [path, params] = url.split("?");
            path = decodeURIComponent(path);
            url = path + "?" + params;
        } else {
            url = decodeURIComponent(url);
        }
        
        return url;
    }
    get instance_router() {
        let router = this.$util.$taro.getCurrentInstance().router;
        if (!router){
            console.log("⚠️ instance_router 需要在mounted生命周期内调用 ")
        }
        return router;
    }
    get params () {
        return this.$util.getUrlQuerys();
    }
}

export default {
    stRouter
};