<template>
	<div class="page endpage container">
		<div class="endpage-bg" @click="closeEndPage"></div>
		<div class="endpage-container container" :class="isWxa?'wxa-endpage-container':''">
			<span class="endpage-title">播放结束</span>
			<span class="endpage-subtitle">点击空白处继续播放</span>
		</div>
		
		<div class="endpage-userinfo-container container"
			v-if="userInfo">
			<img class="author-headimg"
			:src="userInfo['avatarUrl'] || userInfo['headimgurl']" mode="widthFix" />
			<span class="username-span">制作人：{{userInfo['nickName'] || userInfo['nickname']}}</span>
		</div>
		<div class="share-card container" @click="operateBtnClicked('share')" v-if="isWxa">
			<div class="share-card-head re-container container">
				<img class="share-card-head-img" src="http://resali.lititutu.cn/icons/app/logo_140.png" />
				<span class="share-card-head-text">{{getShareAppName}}</span>
			</div>
			<span class="share-card-title">{{shareTitle}}</span>
			<!-- {{getShareTiele}} -->
			<img class="share-cover" :src="getShareImagePath" mode="widthFix"/>
			<div class="triangle"></div>
		</div>
		
		<div class="operate-btns-container re-container container">
			<div class="operate-btn container"
				v-for="item in currentOperateBtns"
				:key="item.key"
				@click="operateBtnClicked(item.key)">
				<img class="operate-btn-img" :src="'http://resali.lititutu.cn/wxa_icon/endpage/' + item.key + '.png'" />
				<span class="operate-btn-text">{{item.name}}</span>
			</div>
		</div>

		<div class="operate-btn container"
			@click="operateBtnClicked('replay')" v-show="isWxa && is_author">
			<img class="small-operate-btn-img"
				:src="'http://resali.lititutu.cn/wxa_icon/endpage/replay.png'" />
			<span class="operate-btn-text" style="font-size: 16px;">重播</span>
		</div>

		<div class="operate-btn-gap"></div>

		<div class="operate-themes" v-if="$env.web != 'wxa' && open_type == 'share'">
			<ThemeMenu from="endpage" @onThemeSelect="onThemeSelect"></ThemeMenu>
		</div>

		<!-- <div style="position: relative; height: 66px; width: 500px;"></div> -->
		<div class="scroll-up-container container" v-if="open_type == 'share'">
			<span class="scroll-tips">上滑查看更多</span>
			<img class="endpage-arrow-up fadeInUp"
			src="http://resali.lititutu.cn/tuku/prod/6b43c8fe7751a0d7fd0ad6893a3e715f.png" />
		</div>
	</div>
</template>

<script>
    import ThemeMenu from '@/components/ThemeMenu'

	export default {
		name: 'endpage',
		props:["systemInfo" ,"is_author", "open_type", "downloadAble"],
		data () {
			return {
				shareTitle: ''
			}
		},
		components: {
            ThemeMenu
        },
		created() {
			// this.shareTitle = this.$o_util.getShareTitle();
			this.shareTitle = this.$util.getShareTitle();
		},
		computed: {
			isWxa () {
				return this.$env.web == "wxa";
			},
			userInfo () {
				return this.$store.state.config.userInfo;
			},
			currentOperateBtns(){
				let operate_btns = [];

				// 编辑页
				if (this.open_type == 'editor') {
					// 小程序编辑页
					if (this.isWxa) {
						operate_btns = [
							{
								"key": "replay2",
								"name": "重播",
							},{
								"key": "edit",
								"name": "继续编辑",
							},{
								"key": "share",
								"name": "分享好友",
							}
						];
						if (this.downloadAble) {
							operate_btns = [
								{
									"key": "make",
									"name": "再做一个",
								},{
									"key": "download",
									"name": "下载",
								},{
									"key": "share",
									"name": "分享好友",
								}
							]
						}
					// h5编辑页
					} else {
						operate_btns = [
							{
								"key": "replay2",
								"name": "重播",
							},{
								"key": "edit",
								"name": "继续编辑",
							}
						];
					}
				// 分享页
				} else {
					// 小程序分享页
					if (this.isWxa) {
						// 作者
						if (this.is_author) {
							operate_btns = [
								{
									"key": "edit",
									"name": "继续编辑",
								},{
									"key": "make",
									"name": "再做一个",
								},{
									"key": "share",
									"name": "分享好友",
								}
							]
						// 游客
						} else {
							operate_btns = [
								{
									"key": "replay2",
									"name": "重播",
								},{
									"key": "make",
									"name": "我要制作",
								},{
									"key": "share",
									"name": "分享好友",
								}
							]
						}
					// h5分享页
					} else {
						// 作者
						if (this.is_author) {
							operate_btns = [
								{
									"key": "edit",
									"name": "继续编辑",
								},{
									"key": "make",
									"name": "再做一个",
								},{
									"key": "replay2",
									"name": "重播",
								}
							]
						// 游客
						} else {
							operate_btns = [
								{
									"key": "replay2",
									"name": "重播",
								},{
									"key": "make",
									"name": "我要制作",
								}
							]
						}
					}
				}
				return operate_btns;
			},
			getShareAppName(){
				let name_map = {
					"wx2fb7317f6bc5b387": "甜豆相册",
					"wxcd236d6f280a1644": "甜豆特效相册",
					"wxdae27e027cadf4c6": "甜豆相册+",
					"wxa52d8e4c620f0e4e": "全景相册3D",
					"wxfd8adde97c295a6d": "立体相册app",
					"wx5f5d3f265242e546": "立体相册3D",
				}
				if (name_map[this.$store.state.config['data']['appid']]) {
					return name_map[this.$store.state.config['data']['appid']];
				} else {
					return '甜豆相册';
				}
			},
			getShareImagePath(){
				let share_image_path = "",
					_this = this,
					usercover = "";
				try {
					usercover = this.$store.state.config.images[0]["im"];
					share_image_path = this.$o_util.buildShareCover(
						usercover,
						'play_video.png?x-oss-process=image/resize,w_120',
						'');	
				} catch (error) {
					console.log("getShareImagePath")
					console.log(error)
				}

				try {
					let currentMode = this.$store.state.themes[ this.$store.state.config['data']['ttid'] ];
					if(currentMode.shareconfig){
						share_image_path = _this.$o_util.getShareImgurl()
					}
				} catch (error) {
					console.log("currentMode error=>",error)
				}
				return share_image_path;
			},
		},
		methods: {
			operateBtnClicked (key) {
				if (key == "comment"){
					wx.pageScrollTo({
						scrollTop: this.systemInfo.screenHeight - 60,
					})
				} else if (key && key.indexOf("make") != -1){
					this.$util.log('theme_share_make_'+this.$store.state.config.data['ttid']);
					if (this.isWxa) {
						wx.miniProgram.reLaunch({
							url: '/pages/make/make?tab=make&detail_tid=' + this.$store.state.config.data['ttid']
					    })
					} else {
						// if (this.$store.state.config['follow_url'] == 'make') {
						if (!this.$o_util.goFollow()) {
							this.$emit('onMakeTap', false, '2012');
						} else {
							this.$mta.click('share_btn_make', {'follow': 'true'});
							window.location.href = this.$o_util.getFollowUrl(this.$store.state.config['follow_url'], 'make');
						}
					}
				} else if (key == "edit"){
					if (this.open_type == 'editor') {
						this.closeEndPage();
					} else {
						window.location.href = window.location.protocol + '//' + window.location.host + '/vliti/main/'+(this.$store.state.appConfig.editor_path)+'?key=' + this.$route.query['key'] + '&_source=' + this.$route.query['_source'] + '&pfrom=share&vscene=2014';
					}
				
				} else if (key == "share"){
					wx.miniProgram.navigateTo({
				      	url: '/pages/savePage/savePage?key='+this.$route.query.key+'&share=1'
				    })
				} else if (key == "replay" || key == "replay2"){
					this.closeEndPage();
					this.$emit('refreshPage');
				} else if (key == "view_pic") {
                    wx.miniProgram.navigateTo({
                        url: '/subpackages/page/page?toast1=1&pid=stpage_vliti_view_big_picture&show_mask=1&key=' + this.$route.query.key
                    })
                } else if (key == "download") {
                    wx.miniProgram.navigateTo({
                        url: '/pages/savePage/savePage?mode=save&key=' + this.$route.query.key + "&_source=" + this.$store.state.config['data']['appid']
                    })
                }
			},
			onThemeSelect (tid) {
				// if (this.$store.state.config['follow_url'] == 'make') {
				if (!this.$o_util.goFollow()) {
					this.$mta.click('share_to_endpage_theme_select');
					this.$emit('onMakeTap', tid, '2013');
                } else {
                    this.$mta.click('share_btn_make', {'follow': 'true'});
                    window.location.href = this.$o_util.getFollowUrl(this.$store.state.config['follow_url'], 'make');
                }
			},
			closeEndPage(){
				this.$emit('closeEndPage')
			},
		}
	}
</script>

<style lang="less" scoped>
	.endpage {
		background-color: rgba(0, 0, 0, .8);
		z-index: 9;
		animation: endpage_in .6s both;
		justify-content: center;
		.endpage-container{
			position: absolute;
			top: 100px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		.wxa-endpage-container{
			top: 20px;
		}
		.endpage-title{
			font-size: 30px;
			font-weight: 400;
			line-height: 30px;
			color: #fff;
			text-align: center;
		}
		.endpage-subtitle{
			margin-top: 8px;
			font-size: 19px;
			color: #aaa;
		}
		.endpage-userinfo-container{
			position: relative;
			margin: 0 auto 44px;
			// width: 468px;
		}
		.endpage-bg{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.author-headimg{
			width: 80px;
			height: 80px;
			border-radius: 50%;
			margin-bottom: 12px;
		}
		.username-span{
			height: 30px;
			font-size: 27px;
			font-weight: 400;
			line-height: 30px;
			color: #fff;
			text-align: center;
			margin-top: 10px;
		}
		.share-card{
			position: relative;
			// margin-top: 44px;
			background-color: #fff;
			padding: 16px 16px 12px;
			align-items: stretch;
			overflow: visible;
			border-radius: 8px;
		}
		.share-card-head{
			justify-content: flex-start;
		}
		.share-card-head-img{
			width: 36px;
			height: 36px;
			margin-right: 16px;
		}
		.share-card-head-text{
			text-align: left;
			font-size: 16px;
			color: #aaa;
		}
		.share-card-title{
			color: #000;
			font-weight: bold;
			margin: 8px 0;
			text-align: left;
			font-size: 22px;
			max-width: 280px;
			overflow : hidden;
			text-overflow: ellipsis;
			line-height: 24px;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}
		.share-cover{
			width: 280px;
			background: url(https://mmbiz.qlogo.cn/mmbiz_png/TZuNDtCW6sRmqO0oOJKyZAoOKojyOt7xsy5vm5l3uK16IlzqnGfwznOgdDrayy5sTxzh5ico3ucKKNc2yyemsgw/0?wx_fmt=png) no-repeat center #fff;
			background-size:contain;
		}
		.triangle{
			position: absolute;
			right: 36px;
			bottom: 1px;
		}
		.triangle:before,.triangle:after{
			position: absolute;
			content: '';
			border-bottom: 9px transparent dashed;
			border-left: 9px transparent dashed;
			border-right: 9px transparent dashed;
			border-top: 9px #fff solid;
		}
		.triangle:before{
			border-top: 9px #fff solid;
		}
		.triangle:after{
			top: 1px;
			border-top: 9px #fff solid;
		}
		
		.operate-btns-container{
			position: relative;
			justify-content: space-around;
			// justify-content: space-between;
			margin: 26px 54px 0;
		    width: 365px;
		}
		.operate-btn{
			position: relative;
			// padding: 0 20px;
			width: 106px;
		}
		.operate-btn-img{
			width: 60px;
			height: 60px;
			border-radius: 50%;
		}
		.small-operate-btn-img{
			width: 32px;
			border-radius: 50%;
			margin-top: 32px;
		}
		.operate-btn-text{
			font-size: 20px;
			margin-top: 12px;
			color: #FFFFFF;
			font-weight: 400;
		}

		.operate-btn-gap {
			position: relative;
			width: 100%;
			height: 60px;
		}

		.scroll-up-container{
			position: absolute;
			overflow: visible;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 0;
		}
		.scroll-tips{
			color: #fff;
			font-weight: 450;
			font-size: 16px;
		}
		.endpage-arrow-up{
			// top: -48px;
			width: 48px;
			// left: 0;
			// right: 0;
			// margin: 0 auto;
			// position: absolute;
		}
		@keyframes fadeInUp {
			0% {
				opacity: 0;
				-webkit-transform: translate3d(0, 100%, 0);
				transform: translate3d(0, 100%, 0);
			}

			20% {
				opacity: 0;
				-webkit-transform: translate3d(0, 100%, 0);
				transform: translate3d(0, 100%, 0);
			}

			100% {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
		.fadeInUp {
			animation: fadeInUp 2s both infinite;
		}
	}
	@keyframes endpage_in {
		from {
			opacity: .6;
			transform: translateY(100px);
		}
		to {
			opacity: 1;
			transform: none;
		}
	}
	.operate-themes {
		position: relative;
		width: 100%;
		height: 200px;
	}
</style>