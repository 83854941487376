<template>
  <div class="xuanzhuan_BX_AEComposition">
    <div class="xuanzhuan_BX_container" @click="wxPreview">
      <div
        v-for="idx of 8"
        :key="'xuanzhuan_item_' + idx"
        class="xuanzhuan-outer" :style="getOutAni(idx - 1)">
        <div
          class="xuanzhuan_uimg1 bg_photo xuanzhuan_uimg"
          :style="getCurrentImg(idx - 1)"
        ></div>
        <div
          class="xuanzhuan_uimg2 bg_photo xuanzhuan_uimg"
          :style="getCurrentImg(idx - 1)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

let G = {
  width: 280,
  height: 300,
  perspective: 2000,
  rotateX: -14,
  rotateY: 0,
  speed: 1,
  r: 0
};

export default {
  name: "xuanzhuan",
  props: ["mode", "currentImage"],
  data() {
    return {};
  },
  methods: {
    wxPreview() {
      this.$emit("wxPreview", this.currentImage);
    },
    getCurrentImg(index){
      let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [380, 400], 'widthFix');

      let s = {
        "backgroundImage": 'url('+this.currentImage['im']+')',
        "animationDelay": (1.4 - parseFloat(index * 0.2)).toString() + "s"
      };
      s['width'] = tsize[0] + 'px';
      s['height'] = tsize[1] + 'px';

      return s;
    },
    getOutAni(index){
      let aniName = ["xuanzhuan_BX_AniKey23", "xuanzhuan_BX_AniKey20", "xuanzhuan_BX_AniKey17", "xuanzhuan_BX_AniKey14", "xuanzhuan_BX_AniKey11", "xuanzhuan_BX_AniKey8", "xuanzhuan_BX_AniKey5", "xuanzhuan_BX_AniKey2"]
      let aniDelay = (1.4 - parseFloat(index * 0.2)).toString() + "s";
      return {
        "animation": aniName[index] + " 12.5s linear " + aniDelay + " infinite normal none"
      }
    },
  }
};
</script>

<style lang="less">
.xuanzhuan_uimg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.xuanzhuan_BX_AEComposition {
  background: transparent;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: absolute;
  transform-origin: 50% 5%;
  perspective: @bw * 1.85 * 2.667;
  perspective-origin: 50% 5%;
  z-index: 1;
}
.xuanzhuan_bg {
  width: 100%;
  height: 100%;
  opacity: 1;
  display: block;
  position: absolute;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.xuanzhuan-outer {
  left: 0;
  top: 0;
  width: @bw * 1.85 * 0.133;
  height: @bw * 1.85 * 0.133;
  transform-style: preserve-3d;
  opacity: 1;
  transform-origin: 104.25% 0%;
  display: block;
  position: absolute;
}
.xuanzhuan_uimg1{
  left: -@bw * 1.85 * 0.138;
  top: @bw * 1.85 * 0.172;
  // width: @bw * 1.85 * 0.279;
  // height: @bw * 1.85 * 0.232;
  transform-style: preserve-3d;
  opacity: 0;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
  animation: dakai2 12.5s linear infinite normal none;
}
.xuanzhuan_uimg2 {
  left: -@bw * 1.85 * 0.138;
  top: -@bw * 1.85 * 0.116;
  // width: @bw * 1.85 * 0.279;
  // height: @bw * 1.85 * 0.232;
  transform-style: preserve-3d;
  opacity: 1;
  transform-origin: 50% 50%;
  display: block;
  position: absolute;
  animation: dakai1 12.5s linear infinite normal none;
}
@keyframes xuanzhuan_BX_AniKey23 {
  0.00% {
    transform: translate3d(0, 0, @bw * 1.85 * 0)
      rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(45deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(45deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(45deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
}
@keyframes xuanzhuan_BX_AniKey20 {
  0.00% {
    transform: translate3d(0, 0, 0)
      rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(90deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(90deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(90deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
}
@keyframes xuanzhuan_BX_AniKey17 {
  0.00% {
    transform: translate3d(0, 0, 0)
      rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(135deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(135deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(135deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
}
@keyframes xuanzhuan_BX_AniKey14 {
  0.00% {
    transform: translate3d(0, 0, @bw * 1.85 * 0)
      rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(180deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(180deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(180deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
}
@keyframes xuanzhuan_BX_AniKey11 {
  0.00% {
    transform: translate3d(0, 0, @bw * 1.85 * 0)
      rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(225deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(225deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(225deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
}
@keyframes xuanzhuan_BX_AniKey8 {
  0.00% {
    transform: translate3d(0, 0, 0)
      rotateY(0deg);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateY(270deg);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateY(270deg);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateY(270deg);
  }
}
@keyframes xuanzhuan_BX_AniKey5 {
  0.00% {
    transform: translate3d(0, 0, 0)
      rotateY(0deg);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateY(315deg);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateY(315deg);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateY(315deg);
  }
}
@keyframes xuanzhuan_BX_AniKey2 {
  0.00% {
    transform: translate3d(0, 0, @bw * 1.85 * 0)
      rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  16.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(360deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  99.60% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(360deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3d(-@bw * 1.85 * 0.139, 0, 0)
      rotateX(-0deg) rotateY(360deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
}
.xuanzhuan_BX_container {
  width: 124px;
  height: 124px;
  left: 50%;
  margin-left: -62px;
  bottom: 120px;
  transform-style: preserve-3d;
  opacity: 1;
  z-index: 1;
  display: block;
  position: absolute;
  animation: xuanzhuan_BX_AniKey1 12.5s linear 0s infinite normal none;
}
@keyframes xuanzhuan_BX_AniKey1 {
  0.00% {
    transform: translate3d(0, 0, -@bw * 1.85 * 0.667)
      rotateX(6deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  16.40% {
    transform: translate3d(0, 0, -@bw * 1.85 * 0.667)
      rotateX(6deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
  100.00% {
    transform: translate3d(0, 0, -@bw * 1.85 * 0.667)
      rotateX(6deg) rotateY(270deg) rotateZ(0deg) scale3d(1, 1, 1);
  }
}
@keyframes dakai2 {
  0.00% {
    transform: translate3d(0, 0, 0) scale3d(1, -1, 1);
    opacity: 0;
  }
  16.00% {
    transform: translate3d(
        @bw * 1.85 * 0.139,
        0,
        @bw * 1.85 * 0.58
      ) scale3d(1, -1, 1);
    opacity: 0.2;
  }
  99.60% {
    transform: translate3d(
        @bw * 1.85 * 0.139,
        0,
        @bw * 1.85 * 0.58
      ) scale3d(1, -1, 1);
    opacity: 0.2;
  }
  100.00% {
    transform: translate3d(
        @bw * 1.85 * 0.139,
        0,
        @bw * 1.85 * 0.58
      ) scale3d(1, -1, 1);
    opacity: 0;
  }
}
@keyframes dakai1 {
  0.00% {
    transform: translate3d(0, 0, 0);
  }
  16.00% {
    transform: translate3d(
        @bw * 1.85 * 0.139,
        0,
        @bw * 1.85 * 0.58
      );
  }
  99.60% {
    transform: translate3d(
        @bw * 1.85 * 0.139,
        0,
        @bw * 1.85 * 0.58
      );
  }
  100.00% {
    transform: translate3d(
        @bw * 1.85 * 0.139,
        0,
        @bw * 1.85 * 0.58
      );
  }
}
</style>