<template>
	<component :is="currentScene" :mode="mode" @wxPreview="wxPreview"></component>
</template>

<script>

	import n_yuandankuaile from './scene/n_yuandankuaile'
	import qingming3d from './scene/qingming3d'

	export default {
		name: 'normal',
		components: {
			n_yuandankuaile,
			qingming3d
		},
		props: ['mode'],
		data () {
			return {}
		},
		created () {
			
		},
		computed: {
			currentScene () {
				return this.$store.state.config.data['ttid'];
			}
		},
		methods: {
			wxPreview (image) {
				this.$emit('wxPreview', image);
			}	
		}
	}
</script>

<style>
	
</style>