import Vue from 'vue'
import util from "@/st_comps.util"

function setWordStatus(state, status, from) {
    state.wordStatus = status;

    util.tdCall({
        action: 'word_status',
        data: state.wordStatus
    })
}

const state = {
	data: {}, // 当前相册的数据结构
    images: [], // 当前相册的图片
    config: {}, // dconfig中其他字段
    
    theme: {}, // 当前模板数据
    themes: {}, // 所有请求过的模板数据
    tlistConfig: null,
    zanList: [],
    authorInfo: null,

    imageCur: null, // 当前照片游标
    wordStatus: 0, // 0 无歌词无用户文字 1 有用户文字, 2 有歌词, 3 zimu5
	userTextDisplayState: 0, // 用户文字显示状态
    
	themeRuntime: 0, // 模板播放次数

	currentLrc: '', // 当前歌词

    modulePlayed: false // 结束页是否已放过标志
}

const mutations = {
	setData (state, preload) {
        state.data = preload.data;
        // Vue.set(state, 'data', preload.data);
    },
    setConfig (state, preload) {
        state.config = preload.config;
        // Vue.set(state, 'config', preload.config);
    },
    initImages (state, preload) {
        let images = preload.images;

        state.wordStatus = 0;
        util.tdCall({
            action: 'word_status',
            data: 0
        })
        
        for (let i=0; i<images.length; i++) {
            // 解码图片链接
            images[i]['im'] = util.packageIm( images[i] );
            
            // 设置文字是否存在状态
            if (images[i]['tx']) {
                state.wordStatus = 1;
                util.tdCall({
                    action: 'word_status',
                    data: 1
                })
            }

            // 解码文字
            images[i]['tx'] = util.decodeBase64( images[i]['tx'] );
        }
        state.images = images;
    },
    setImageText (state, preload) {
        state.images[preload['index']]['tx'] = preload['text'];

        // 设置文字存在状态
        state.wordStatus = 1;
        util.tdCall({
            action: 'word_status',
            data: 1
        })
    },
    setTheme (state, preload) {
        state.theme = preload.theme;
    },
    setThemes (state, preload) {
        state.themes[preload.tid] = state.themes[preload.tid] ? Object.assign(state.themes[preload.tid], preload.theme) : preload.theme;
    },
    setThemesList(state, preload){
        let themes = preload['themes'],
            activeType = preload['activeType'];
        Vue.set(state.tlistConfig['theme_type_data'][activeType], "themes", themes)
    },
    setTlistConfig(state, preload){
        // 加工
        if(util.ISDEBUG && preload["theme_types"].indexOf("debug") < 0){
            preload["theme_types"].splice(2, 0, "debug")
        }
        let theme_type_data = util.copy(preload['theme_type_data'])
        for(let themetype in theme_type_data){
            theme_type_data[themetype]["themes"] = [];
        }
        preload['theme_type_data'] = theme_type_data
        state.tlistConfig = preload;
    },
    // 设置点赞数、评论数、pv
    setNum (state, preload) {
        if (preload['type'] == 'zan') {
            state.config.zan = preload['num'];
        } else if (preload['type'] == 'pv') {
            state.config.pv = preload['num'];
        } else if (preload['type'] == 'comment') {
            state.config.comment = preload['num'];
        }
    },
    // 插入评论、点赞
    pushZan (state, preload) {
        state.zanList.push(preload);
    },
    insertZan(state, preload){
        if(state.zanList.length > 0){
            state.zanList.splice(0,0,preload)
        }
        else{
            state.zanList = [preload];
        }
    },
    // 设置作者信息
    setAuthorInfo (state, preload) {
		state.authorInfo = preload;
	},
    // 设置文字状态
    setWordStatus (state, preload) {
        setWordStatus(state, preload.status, preload.from);
    },
    // 设置当前照片播放位置
    setImageCur (state, preload) {
        state.imageCur = preload.cur;
    },
	// 设置用户文字状态
	setUserTextDisplayState (state, preload) {
		state.userTextDisplayState = preload;
	},
    incrThemeRuntime (state, preload) {
		state.themeRuntime ++;
	},
	clearThemeRuntime (state, preload) {
		state.themeRuntime = 0;
	},
    // 写入歌词
	setLrc (state, preload) {
        state.currentLrc = preload.lrc;
		if (state.wordStatus != 1 && state.wordStatus != 3) {
            if (state.currentLrc == "") {
                // 歌词置空
                setWordStatus(state, 0, 1);
            } else {
                setWordStatus(state, 2, 2);
            }
		}
	},
    // 标记模版播放完毕
	setModulePlayed (state, preload) {
		state.modulePlayed = true;
	}
}

const actions = {
	setData ({ commit, state }, preload) {
        return new Promise(( resolve, reject ) => {
            util.rget('/vliti/setdata', {
                k: preload.k,
                v: preload.v,
                key: preload.key
            })
            .then((data) => {
                commit('setData', {
                    data: data
                });
                resolve();
            })
        })
    },
    // 获取模板配置
    getTheme ({ commit, state }, preload) {
        let _getNeedReqTid = (tid, fields) => {
            let exist = true;
            if (!state.themes[tid]) {
                exist = false;
            } else {
                if (fields.length == 0) { // fields为空数组时，代表获取所有字段
                    if (state.themes[tid]['__all__'] === true) { // 已存在完整字段的缓存数据
                        exist = true;
                    } else { // 存在数据但不是完整数据
                        exist = false;
                    }
                } else { // 指定请求的字段
                    for (let i=0; i<fields.length; i++) {
                        let field = fields[i];
                        if (state.themes[tid] && !state.themes[tid][field]) {
                            exist = false;
                            break;
                        }
                    }
                }
            }
            
            if (exist === false) {
                return tid;
            }
        }
        let _getThemesByTids = (tids) => {
            if (tids.length == 1) {
                let currentTheme = state.themes[tids]; // 获取单独的模板数据写入当前模板字段
                commit("setTheme", {
                    theme: currentTheme
                });
                return currentTheme;
            } else {
                let themes = [];
                for (let i=0; i<tids.length; i++) {
                    themes.push( state.themes[tids[i]] );
                }
                return themes;
            }
        }
        return new Promise(resovle => {
            let tids = util.typeOf(preload.tid == 'string') ? preload.tid.split(",") : preload.tid,
                fields = preload.fields && util.typeOf(preload.fields) == 'string' ? preload.fields.split(",") : preload.fields || [],
                need_req_tids = [];
            for (let i=0; i<tids.length; i++) {
                let need_req_tid = _getNeedReqTid(tids[i], fields);
                if (need_req_tid)
                    need_req_tids.push( need_req_tid );
            }

            if (need_req_tids.length) {
                let params = {
                    tid: tids.join(","),
                }
                if (fields.length) {
                    params['fields'] = fields.join(",");
                }
                util.rget("/vliti/get_theme_data", params).then(themes => {
                    
                    if (util.typeOf(themes) == 'object') {
                        themes = [themes];
                    }
                    for (let i=0; i<themes.length; i++) {
                        // 请求的如果是完整数据，则打上标记
                        if (fields.length == 0) {
                            themes[i]['__all__'] = true;
                        }
                        commit("setThemes", {
                            tid: tids[i],
                            theme: themes[i]
                        });
                    }
                    resovle( _getThemesByTids(tids) );
                })
            } else {
                resovle( _getThemesByTids(tids) );
            }
        })
    },
    // // 获取模板分类
    // getTlistConfig({ commit, state }, preload) {
    //     return new Promise((resolve, reject)=>{
    //         if (!state.tlistConfig) {
    //             util.rget('/vliti/get_theme_list_config',{}).then((response) => {
    //                 commit('setTlistConfig', response)
    //                 resolve(state.tlistConfig);
    //             })
    //         } else {
    //             resolve(state.tlistConfig)
    //         }
    //     })
    // },
    // // 获取模板列表
    // getThemeList ({ commit, state }, preload) {
    //     return new Promise(( resolve, reject ) => {
    //         let active_type_data = state.tlistConfig['theme_type_data'][preload.activeType];
    //         let themes = active_type_data["themes"] || [];
    //         let lens = preload["len"] || 12,
    //             requare_tids = [],
    //             start = preload.hasOwnProperty("start")? preload.start : (themes && themes.length > 0 ? active_type_data['tids'].indexOf(themes[themes.length - 1].tid) + 1 : 0),
    //             end = start + lens >= active_type_data['tids'].length? active_type_data['tids'].length : start + lens;
    //         if(start >= active_type_data["tids"]){
    //             resolve({
    //                 "hasmore": false,
    //             })
    //         } else {
    //             for(let i = start ; i < end ; i ++){
    //                 let tid = active_type_data['tids'][i];
    //                 if(!state.themes[tid]){
    //                     requare_tids.push(tid)
    //                 }
    //             }
    //             if(requare_tids){
    //                 let param = {
    //                     "tids": JSON.stringify(requare_tids),
    //                 }
    //                 util.rpost('/vliti/get_themes', param).then((response) => {
    //                     for(let themeid in response){
    //                         commit('setThemes', {
    //                             'theme': response[themeid],
    //                             'tid': themeid
    //                         })
    //                     }
    //                     let concat_themes = [];
    //                         // hideLens = 0;
    //                     for(let i = start ; i < end ; i ++){
    //                         let tid = active_type_data['tids'][i];
    //                         // // 判断模板上是否显示
    //                         // if (util.ISWXA || ( state.themes[tid] && !state.themes[tid]['appid'] )) {
    //                         //  concat_themes.push( state.themes[tid] )
    //                         // } else {
    //                         //  hideLens += 1;
    //                         // }
    //                         concat_themes.push( state.themes[tid] )
    //                     }
    //                     commit("setThemesList",{
    //                         "themes": themes.concat(concat_themes),
    //                         "activeType": preload.activeType,
    //                     })
    //                     resolve({
    //                         "hasmore": concat_themes.length == lens,//(lens - hideLens),
    //                         "list": concat_themes,
    //                     });
    //                 })
    //             } else {
    //                 resolve({
    //                     "hasmore": false,
    //                 })
    //             }
    //         }
    //     })
    // },
    // 设置计数
    setNum ({ commit, state }, preload) {
        return new Promise((resolve, reject) => {

            if (preload.type == 'zan') {

                util.rget('/vliti/get_num', {
                    'key': preload.key,
                    'field': 'default'
                })
                .then((current_zan) => {
                    commit('setNum', {'type': 'zan', 'num': current_zan});
                    resolve(current_zan);
                })
            } else if (preload.type == 'pv') {

                util.rget('/vliti/set_pv', {
                    'key': preload.key,
                    'appid': state.data['appid'],
                    'openid': state.data['openid'],
                    'thumb': state.images[0]['im']
                })
                .then((current_pv) => {
                    commit('setNum', {'type': 'pv', 'num': current_pv});
                    resolve(current_pv);
                })
            } else if (preload.type == 'comment') {
                util.rget('/vliti/get_num', {
                    'key': preload.key,
                    'field': 'comment'
                })
                .then((current_comment) => {
                    commit('setNum', {'type': 'comment', 'num': current_comment});
                    resolve(current_comment);
                })
            }
        })
    },
    // 获取作者信息
    getAuthorInfo ({ commit, state }, preload) {
        return new Promise(resolve => {
            util.rget("ucenter/get_user", {
                "openid": preload.openid,
                "appid": preload.appid
            }).then(userinfo=>{
                userinfo = util.transformUserdata(userinfo);
                commit('setAuthorInfo', userinfo);
                resolve(userinfo)
            })
        })
    }
}

const getters = {
}

export default {
	state,
	mutations,
	actions,
	getters
}
