/**
 * 原生方法拓展
 */

// 数组去重
Array.prototype.uniq = function () {
    var arr = [];
    var flag = true;
    this.forEach(function(item) {
        // 排除 NaN
        if (item != item) {
            flag && arr.indexOf(item) === -1 ? arr.push(item) : '';
            flag = false;
        } else {
            arr.indexOf(item) === -1 ? arr.push(item) : ''
        }
    });
    return arr;
}

// 数组随机选择
Array.prototype.randomChoice = function() {
    if (!this || this.length == 0) {
        return null;
    }
    return this[ Math.floor( Math.random() * this.length ) ];
}

// 乱序
if (!Array.prototype.shuffle) {
  Array.prototype.shuffle = function () {
    let arr = this;
    for (let i=arr.length-1; i>=0; i--) {
        let rIndex = Math.floor(Math.random()*(i+1));
        let temp = arr[rIndex];
        arr[rIndex] = arr[i];
        arr[i] = temp;
    }
    return arr;
  }
}

// 数组中有多少个这个元素
if (!Array.prototype.numOf) {
  Array.prototype.numOf = function (item) {
    var newArray = this.filter(function(ary_item){
      return ary_item == item
    })
    return newArray ? newArray.length : 0;
  }
}

export default {
    // 数组去重
    arrayDistinct (arr) {
		  return Array.from(new Set(arr));
    },
    // 数组求差集
    arrayDifferenceSet (arr1, arr2) {
        let arr3 = this.arrayIntersection(arr1, arr2);
        return arr1.concat(arr2).filter(function (v) {
            return arr3.indexOf(v)===-1
        })
    },
    // 数组2不在数组1的差集
    arrayDifferenceFrom (arr1, arr2) {
        let arr3 = this.arrayIntersection(arr1, arr2);
        return arr2.concat(arr3).filter(function (v) {
            return arr2.indexOf(v)===-1 || arr3.indexOf(v)===-1
        })
    },
    // 数组求交集
    arrayIntersection (arr1, arr2){
        // 交集
        return arr2.filter(function(v){
            return arr1.indexOf(v)!==-1
        })
    },
    // 切分、插入数组
    spliceAry(ary, index, num, data) {
        var new_ary = [];
        try {
          if (data) {
            ary.splice(index, num, data);
          } else {
            ary.splice(index, num);
          }
          new_ary = ary;
        } catch (error) {
            console.error(error);
          for (var j = 0; j < num; j++) {
            (function(j) {
              for (var i = 0; i < ary.length; i++) {
                (function(i) {
                  if (i != index) {
                    new_ary.push(ary[i]);
                  } else {
                    if (data[j]) {
                      new_ary.push(data[j]);
                    }
                  }
                })(i);
              }
            })(j);
          }
        }
        return new_ary;
    },
    // 判断元素是否在数组内
    inAry (item, arr) {
        let _in = false;
        for (let i=0; i<arr.length; i++) {
            if (arr[i] == item) {
                _in = true;
                break;
            }
        }
        return _in;
    },
    // 获取两个数中间的任意值
    randomNumsInAry (min, max, type) {
      type = type || 'int';
      let random = Math.round((Math.random() * (max - min) + min) * 10) / 10;
      if (type == 'int') {
        return parseInt(random);
      }
      return random;
    },
    // 输入两组数组，获取数组B中在数组A中存在个数更少的（如果存在多个结果则random）
    randomMin (ary1, ary2) {
      // console.log("ary1==>",ary1)
      // console.log("ary2==>",ary2)

      let instance_ary = this.arrayIntersection(ary1, ary2); // 数组交集
      let diff_from = this.arrayDifferenceFrom(instance_ary, ary1); // 不在ary2中的ary1的所有
      let left_ary = this.arrayDifferenceFrom(diff_from, ary1); // 在ary2中的ary1剩余数组
      
      // console.log("在ary2中的ary1剩余数组==>", left_ary);
      let mins = [],
        last_min = 0;
      for (let i = 0 ; i < ary2.length ; i++) {
        let temp_ary_item = ary2[i],
          num = left_ary.numOf(temp_ary_item);
        // console.log("left_ary==>numof" + temp_ary_item + ":" + num);
        if (i == 0) {
          last_min = num;
        }
        if (num == last_min) {
          mins.push(temp_ary_item);
        } else if (num < last_min) {
          last_min = num;
          mins = [temp_ary_item];
        }
      }
      // console.log("mins==>",mins)
      return mins.randomChoice();
    }
}