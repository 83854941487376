<template>
    <div class="st-comp">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'StBaseComp',
        props: {
        },
        data() {
            return {
                systemInfo: {}
            }
        },
        created () {
            this.systemInfo = this.$util.getSystemInfoSync();
        },
        computed: {
            _isTaroTag () {
                let is_taro_tag = this.$util.isEnv("h5") && this.$util.isEnv("taro");
                if (is_taro_tag) {
                    is_taro_tag = ["div"].indexOf(this._vnode.tag) == -1;
                }
                return is_taro_tag;
            },
            _parent () {
                return this._isTaroTag ? this.$parent.$parent : this.$parent;
            },
            _child () {
                return this._isTaroTag && this.$children && this.$children.length > 0 ? this.$children[0].$children : this.$children;
            },
            _getStid () {
                return "st_" + this._uid;
            }
        }
    }
</script>

<style>

</style>
