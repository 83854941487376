<template>
    <div class="guide-download-container">
        <div @click="naviToDownLoad" class="guide_download re-container">
            <span class="guid_text">> > ></span>
            <span class="guid_text middle">点击提取全部图片</span>
            <span class="guid_text">{{right_text}}</span>
            <st-open-tag
                originid="gh_37a75119d615"
                :path="getWxaPath"
                html='<div style="top: 0; left: 0;width: 500px; height: 80px;"></div>'>
            </st-open-tag>
        </div>
        <promo-scan v-show="isPromoScanShow" 
            :promoFingerImage="promoFingerImage" @onPromoScanHide="onPromoScanHide" :promoQrcode="promoQrcode" promoFrom="downphoto"></promo-scan>
    </div>
</template>

<script>
import PromoScan from '@/components/promo/PromoScan.vue'
import StOpenTag from '@/components/st_comp/StOpenTag'
/* eslint-disable */
export default {
  name: "guide_download",
  components: {
      PromoScan,
      StOpenTag
  },
  data() {
    return {
        "right_text": "< < <",
        promoFingerImage: "",
        isPromoScanShow: false,
        promoQrcode: '',
    };
  },
  watch: {},
  computed: {
        getWxaPath () {
            return '/pages/make/make?scene=' + encodeURIComponent(this.$route.query.key)
        },
  },
  methods: {
      naviToDownLoad () {
        if (this.$env.web == 'wxa') {
            wx.miniProgram.navigateTo({
                url: "/subpackages/save/save?key=" + this.$route.query.key,
            })    
        } else {
            this.onPromoScanShow();
        }
      },
      onPromoScanShow (e) {
        this.promoFingerImage = 'http://litiresource.xcgogo.site/user/5980171/images/76432c925159c3bedec19d7cbeb1c94c.gif';
        
        let key = this.$route.query.key

        this.$toast.loading({
            message: '加载中...',
            duration: 'infinite'
        })

        let _this = this;

        this.$o_util.getWxaQrcode(key).then((qrcode) => {
            _this.isPromoScanShow = true;
            _this.promoQrcode = qrcode;
            _this.$toast.clear();
        })
    },
    onPromoScanHide () {
        this.promoFingerImage = "";
        this.promoQrcode = "";
        this.isPromoScanShow = false;
    }
  }
};
</script>

<style lang="less">
.guide-download-container{
    width: 100%;
    position: relative;
    .guide_download{
        position: relative;
        width: 100%;
        padding: 24px 0;
        margin-bottom: 20px;
        background: linear-gradient(180deg, #FDEFDC 0%, #F9E5DF 21%, #FFEFE9 80%, #f1f1f1 100%) #f1f1f1;
        box-shadow: 0px 0px 10px -3px rgba(153, 153, 153, 0.6) inset;
        justify-content: center;
        .guid_text{
            color: #FF9000;
            font-weight: bolder;
            font-size: 24px;
        }
        .middle{
            border-bottom: 2px solid#FF9000;
            margin: 0 6px;
        }
    }
}
</style>