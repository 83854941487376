<template>
<div class="guandao-viewer page po">
    <div class="guandao-container po">
        <div class="page guandao-box">
            <div class="po guandao-room1 preserve3d">
                <elem v-if="config[5]" :mode="config[5]"></elem>
            </div>
            <div class="po guandao-room2 preserve3d">
                <elem v-if="config[6]" :mode="config[6]"></elem>
            </div>
            <div class="div2 guandao_panorama_mid2_1 guandao_panorama2_1 guandao_div po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}"></div>
            <div class="div2 guandao_panorama_mid2_4 guandao_panorama2_1 guandao_div po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}"></div>
            <!-- 空间1组件 -->
            <div class="guandao_panorama_bottom2_1 guandao_panorama2_1 po">
                <!-- 底部图片 -->
                <elem class="guandao_panorama_bottom2_1_image guandao_panorama_bottom_image2_1_1 page po" :mode="config[1]" v-if="config[1]"></elem>
            </div>
            <div class="guandao_panorama_top2_1 guandao_panorama2_1 po">
                <elem class="page po" v-if="config[0]" :mode="config[0]"></elem>
            </div>
            <div class="guandao_panorama_mid2_2 guandao_panorama2_1 guandao_div po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}"></div>
            <!-- 顶部动图 -->
            <elem class="guandao_panorama_top2_2 guandao_panorama2_1 po" v-if="config[2] || config[0]" :mode="config[2] || config[0]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
            <div class="guandao_panorama_mid2_5 guandao_panorama2_1 guandao_div po">
                <div class="page po">
                    <div v-if="!config[4] || config[4] == 1" class="div3 guandao_panorama_mid2_5_2 guandao_div page po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')', animation: '2s ease-out 6.8s 1 normal both running guandao_panorama_mid2_5_2'}"></div>
                    <div v-if="!config[4] || config[4] == 1" class="div3 guandao_panorama_mid2_5_1 guandao_div page po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')', animation: '2s ease-out 6.8s 1 normal both running guandao_panorama_mid2_5_1'}"></div>
                    <div v-if="config[4] != 1" class="div3 guandao_panorama_mid2_5_3 guandao_div page po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}"></div>
                </div>
            </div>
            <div class="div3 guandao_panorama_mid2_6 guandao_panorama2_1 guandao_div po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}"></div>
            <div class="div3 guandao_panorama_mid2_8 guandao_panorama2_1 guandao_div po" :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}"></div>
            <!-- 底部动图 -->
            <elem class="guandao_panorama_bottom2_2 guandao_panorama2_1 po" v-if="config[3] || config[1]" :mode="config[3] || config[1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
        </div>
    </div>
</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

    export default {
        name: 'Guandao',
        extends: BaseElem,
        data () {
            return {}
        },
        computed: {
			config () {
				return this.comp['config'] || [];
			}
		}
    }
</script>

<style>
    .guandao-viewer {
        -webkit-perspective: 448px;
    }
    .guandao-container {
        width: 500px;
        height: 500px;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        -webkit-transform: rotateY(0deg);
        -webkit-transform-style: preserve-3d;
        animation: 5s cubic-bezier(0.1, 0, 0.9, 1) 0.8s 1 normal both running guandao_page2_1;
    }
    @keyframes guandao_page2_1 {
        0% {
            -webkit-transform: rotateZ(8deg) translate3d(350px,0,0);
        }
        100% {
            -webkit-transform: rotateZ(-8deg) translate3d(-280px,0,0);
        }
    }
    .guandao-box {
        -webkit-transform-style: preserve-3d;
        -webkit-transform: translateZ(0);
        animation: 4.5s cubic-bezier(0.1, 0, 0.9, 1) 4.8s 1 normal both running guandao_page2_1_1;
    }
    @keyframes guandao_page2_1_1 {
        0% {
            -webkit-transform: rotateZ(0) translate3d(0,0,0);
        }
        100% {
            -webkit-transform: rotateZ(8deg) translate3d(30px,-38px,500px);
        }
    }
    .guandao_panorama2_1 {
        width: 500px;
        height: 500px;
        -webkit-transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
    }
    .guandao_panorama_mid2_1 {
        -webkit-transform: translateZ(-250px) translateX(-250px);
    }
    .guandao_div {
        background-size: cover;
        background-position: center 10%;
        background-repeat: no-repeat;
    }
    .guandao_panorama_mid2_4 {
        -webkit-transform: rotateY(90deg) translateZ(-496px);
    }
    .guandao_panorama_bottom2_1 {
        background-color: #000000;
        overflow: hidden;
        -webkit-transform: rotateX(90deg) translateZ(-250px) translateX(-250px);
    }
    .guandao_panorama_bottom_image2_1_1 {
        /* -webkit-animation: guandao_panorama_bottom2_1 4.5s linear infinite both; */
    }
    .guandao_panorama_bottom_image2_1_2 {
        /* -webkit-animation: guandao_panorama_bottom2_1 4.5s -2.25s linear infinite both; */
    }
    .guandao_panorama_bottom2_1_image {
        -webkit-transform-origin: 50% 59.6%;
    }
    @keyframes guandao_panorama_bottom2_1 {
        0% {
            -webkit-transform: translateZ(0) scale(1) rotateZ(0);
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        40% {
            opacity: 1;
        }
        70% {
            -webkit-transform: translateZ(1px) scale(2.3) rotateZ(45deg);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    .guandao_panorama_top2_1 {
        -webkit-transform: rotateX(-90deg) translateZ(-250px) translateX(-250px);
        pointer-events: none; 
    }
    .guandao_panorama_mid2_2 {
        -webkit-transform: rotateY(-90deg);
    }
    .guandao_panorama_top2_2, .guandao_panorama_top2_2_hua {
        -webkit-transform: rotateX(-90deg) translateZ(-250px) translateX(250px);
        pointer-events: none;
    }
    .guandao_panorama_mid2_5 {
        -webkit-transform: translateZ(-250px) translateX(250px);
        -webkit-transform-style: flat;
    }
    .guandao_panorama_mid2_5_2 {
        -webkit-transform-origin: right;
    }
    @keyframes guandao_panorama_mid2_5_2 {
        0% {
            -webkit-transform: rotateY(0);
        }
        100% {
            -webkit-transform: rotateY(-120deg);
        }
    }
    .guandao_panorama_mid2_5_1 {
        -webkit-transform-origin: left;
    }
    @keyframes guandao_panorama_mid2_5_1 {
        0% {
            -webkit-transform: rotateY(0);
        }
        100% {
            -webkit-transform: rotateY(120deg);
        }
    }
    .guandao_panorama_mid2_5_3 {
        z-index: -1;
    }
    .guandao_panorama_mid2_6 {
        -webkit-transform: rotateY(90deg);
    }
    .guandao_panorama_mid2_8 {
        -webkit-transform: rotateY(-90deg) translateZ(-496px);
    }
    .guandao_panorama_bottom2_2 {
        -webkit-transform: rotateX(90deg) translateZ(-250px) translateX(250px);
    }
    .guandao-room1, .guandao-room2 {
        width: 500px;
        height: 500px;
    }
    .guandao-room1 {
        left: -250px;
    }
    .guandao-room2 {
        right: -250px;
    }
</style>