import st_router_base from './st_router.base'

class stRouter extends st_router_base.stRouter {
    constructor (util) {
        super(util)
        this.path = window.location.pathname + window.location.search;
    }
    get scene (){
        let {
            vscene
        } = this.params || {};
        return vscene || 1000;
    }
    isToWxa (url) {
        return url.indexOf("/pages") == 0 || url.indexOf("/subpages") == 0 || url.indexOf("/subpackages") == 0
    }
    redirectTo (options) {
        options['url'] = this.transformUrl(options['url'])
        let to_wxa = this.isToWxa(options['url']);
        if (this.$util.isEnv('webview') && to_wxa) {
            this.$util.$wx.wxsdk.miniProgram.redirectTo(options)
        } else {
            window.location.replace(options.url)
        }
    }
    reLaunch (options) {
        options['url'] = this.transformUrl(options['url'])
        let to_wxa = this.isToWxa(options['url']);
        if (this.$util.isEnv('webview') && to_wxa) {
            this.$util.$wx.wxsdk.miniProgram.reLaunch(options)
        } else {
            window.location.replace(options.url)
        }
    }
    navigateTo (options) {
        options['url'] = this.transformUrl(options['url'])
        let to_wxa = this.isToWxa(options['url']);
        if (this.$util.isEnv('webview') && to_wxa) {
            this.$util.$wx.wxsdk.miniProgram.navigateTo(options)
        } else if (this.$util.isEnv('wx')) {
            window.location.href = options.url;
        } else {
            window.open(options.url);
        }
    }
    navigateBack (options) {
        let {
            delta,
            url
        } = options;
        this.$util.$wx.wxsdk.miniProgram({
            delta: delta || 1
        })
    }
}

export default {
    stRouter
};