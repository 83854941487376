<template>
	<div id="xc-model" ref="model">
		<div id="xc-room" class="po" @touchstart="down" @touchmove="move" @touchend="up">
			<div id="xc-panorama" class="po" :style="{ transform: 'rotateY('+rotateDeg+'deg)' }">
				<RoomTop :theme="mode['room']"></RoomTop>
					
				<div :key="image+'-'+index" v-for="(image, index) in timages" class="po xc-panorama_c" :class="getClass(index)" :style="{backgroundImage: 'url('+image['im']+')'}" @click="wxPreview(image)">
					<img v-if="mode['border']" :src="mode['border']">
					<!-- 字幕 -->
					<UserText 
						:mode="mode['mode'] || {}" 
						:currentText="image['tx']" 
						from="xc"></UserText>
				</div>

				<RoomBottom :theme="mode['room']"></RoomBottom>
			</div>
		</div>
		<Room2 v-if="mode['room2']" :mode="mode['room2']" :rotateDeg="rotateDeg" :currentImage="timages[0]"></Room2>
		<Room3 v-if="mode['room3']" :mode="mode['room3']"></Room3>

		<!-- 老场内元素 -->
		<!-- <template v-if="mode['gif']">
			<gif :mode="mode['gif'][0]"></gif>
		</template>
		<template v-if="mode['piao']">
			<piao :mode="mode['piao']"></piao>
		</template>
		<template v-if="mode['luo']">
			<luo :mode="mode['luo']"></luo>
		</template> -->

		<!-- 场内元素decorate -->
		<template v-if="mode['mode']">
			<template v-if="mode['mode']['decorate']">
				<template v-for="(decorate, decorate_id) in mode['mode']['decorate']">
					<template v-if="decorate_id == 'gif'">
						<template v-for="item in decorate">
							<gif :mode="item"></gif>
						</template>
					</template>
					<template v-else-if="decorate_id == 'elems'">
						<template v-for="item in decorate">
							<elem :mode="item"></elem>
						</template>
					</template>
					<template v-else>
						<component :is="decorate_id" :mode="decorate"></component>
					</template>
				</template>
			</template>
		</template>

		<template v-if="mode['texts']">
			<div class="xc-text">
				<template v-for="(texts, index) in mode['texts']">
					<div class="xc-text-con" :class="{'xc-text-show': textIdx%textNum == index}">
						<div v-for="text in texts" :style="{color: text[1]}">
							{{text[0]}}
						</div>
					</div>
				</template>
			</div>
		</template>

		<!-- 开场动画 -->
		<Prelude :mode="prelude" @preluded="preluded"></Prelude>
	</div>
</template>

<script>
	// import C from '@/config'
	// import U from '@/util'

	import Room2 from './room2/room2'
	import Room3 from './room3/room3'

	import RoomTop from './room/RoomTop'
	import RoomBottom from './room/RoomBottom'

	// import elem from '@/components/themes/components/Elem'
	// import gif from '@/components/themes/components/Gif'
	// import piao from '@/components/themes/components/Piao'
	// import luo from '@/components/themes/components/Luo'
	// import luo2 from '@/components/themes/components/Luo2'
	// import luo3 from '@/components/themes/components/Luo3'
	// import luo4 from '@/components/themes/components/Luo4'

	import Prelude from '@/components/Prelude'
	import UserText from '@/components/UserText'

	const prelude_duration = 3; // 默认开场

	let G = {
		imagesIndent: 4,
		images: [],
	}

	export default {
		name: 'xc',
		data () {
			return {
				rotateDeg: 0,
				imageShow: true,
				timages: [], // 当前相册中的照片组（4个一组）

				drag: false,
				speed: .5,
				r: 0,
				brake: 1,
				o: null,
				x: null,
				time: null,
			
				imageIdx: 0,

				textIdx: 0,
				textNum: 3,
				textEvent: null,
			}
		},
		props: ['mode'],
		computed: {
			prelude () {
				let prelude = Boolean(this.mode['prelude']) ? this.mode['prelude'] : false;
				if (!prelude) {
					// prelude = {
					// 	'type': 'default',
					// 	'duration': prelude_duration
					// }
					prelude = {}
				}
				if (this.mode['is_prelude'] === false) {
					prelude = {
						'type': 'none',
						'duration': 0
					}
				}
				prelude['title'] = this.$store.state.config['data']['ti'] || this.mode['name'];
				if (!prelude['textcolor']) {
					// prelude['textcolor'] = '#ed5100';
					prelude['textcolor'] = '#066f06';
				}
				if (!prelude['type']) {
					prelude['type'] = 'default';
				}
				if (!prelude['duration']) {
					prelude['duration'] = prelude_duration;
				}
				return prelude;
			},
		},
		components: {
			Room2,
			Room3,
			RoomTop,
			RoomBottom,
			Prelude,
			UserText,

			// gif,
			// elem,
			// piao,
			// luo,
			// luo2,
			// luo3,
			// luo4
		},
		mounted () {
			this.$refs.model.addEventListener('touchmove', function(e) {
				e.preventDefault();
			})

			this.spin();
		},
		created () {
			G.images = this.$o_util.getImagesArray(this.$store.state.config.images, G.imagesIndent);
			
			this.imageIdx = G.images.length * 1000000000;

			let next_timages;

			[this.timages, next_timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);

			// 加载下一组照片
			this._preloadNextPhotos(next_timages);

			// text模板配字
			this.textIdx = 0;
			clearInterval(this.textEvent);
			setTimeout(() => {
				this.textEvent = setInterval(() => {
					this.textIdx ++;
				}, 8000);
			}, 2000);
		},
		methods: {
			getClass (index) {
				let c = {
					'fadeOut': !this.imageShow,
					'fadeIn': this.imageShow
				};
				c['xc-panorama_c'+index] = true;
				return c;
			},
			wxPreview (image) {
				this.$emit('wxPreview', image);
			},
			spin () {
				if ( !this.drag ) {

					if ( this.r * 180 / 400 < -360 || this.r * 180 / 400 > 360 ) {
						this.r = 0;
						if (G.images.length > 1) {
							this.imageShow = false;
							setTimeout(() => {

								let timages = [];
								let next_timages;
								[timages, next_timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);

								// 预加载下一张用户图片
								this._preloadNextPhotos(next_timages);

								for (let j=0,jlens=timages.length; j<jlens; j++) {
									this.$set(this.timages, j, timages[j]);
								}
								setTimeout(() => {
									this.imageShow = true;
								}, 200)
							}, 200);
						}
					}

					if ( this.speed < .5 && this.speed > 0 ) {
						this.speed = .5;
					} else if ( this.speed > -.5 && this.speed < 0 ) {
						this.speed = -.5;
					}

					this.r += this.speed;
					this.speed /= this.brake;
					
					let ry = (this.r * 180 / 400);
					this.rotateDeg = ry;
				}
				requestAnimationFrame(this.spin);
			},
			down (event) {
				this.o = this.r;
				this.x = this.r + event.touches[0].clientX;
				this.drag = true;
				this.time = new Date();
			},
			move (event) {
				if (this.drag) {
					let ry = (this.r * 180 / 400);
					this.r = this.x - event.touches[0].clientX;
					this.rotateDeg = ry;
				}
			},
			up (event) {
				if (this.drag) {
					let time = new Date() - this.time;
					let path = this.r - this.o;
					this.speed = path / time * 5;
					this.brake = 1.01;
					this.drag = false;
				}
			},
			// 预加载下一张用户图片
			_preloadNextPhotos (photos) {

				let _this = this;

				for (let i=0; i<photos.length; i++) {

					let photo = photos[i];

					let im = new Image();
					
					im.onload = (function(_photo) {
						return function() {
							_this.$store.commit('setImageOriSize', {'imageid': _photo['id'], 'orisize': [this.width, this.height]});
						}
					})(photo);
					im.onerror = (function(_photo) {
						return function() {
							_this.$store.commit('setImageOriSize', {'imageid': _photo['id'], 'orisize': [500, 500]});
						}
					})(photo);

					im.src = photo['im'];
				}
			},
			preluded () {}
		}
	}
</script>

<style lang="less">
	.xc-text {
		position: fixed;
		width: @bw * 0.83;
		height: @bw * 0.42;
		// background: red;
		z-index: 2;
		bottom: @bw * 0.2;
		left: 50%;
		margin-left: -(@bw*0.45);
		font-size: @bw*.07;
		pointer-events: none;
		background-color: rgba(0,0,0,.2);
		box-shadow: 0 0 0.03*@bw 0.01*@bw rgba(0,0,0,.2);
	}
	.xc-text-con {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		opacity: 0;
		transition: opacity 2s;
	}
	.xc-text-con>div {
		font-weight: bold;
		font-family: 'Heiti';
		text-shadow: 2px 2px 5px #000;
	}

	#xc-model {
		overflow: hidden;
	}
	
	#xc-room {
		height: 100%; width: 100%;
		perspective: @bw * .83;
		overflow: hidden;
	}
	#xc-panorama {
		width: @bw;
		height: @bw;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		transform-style: preserve-3d;
		cursor: pointer;
	}
	.xc-panorama_c, .xc-panorama_m {
		width: @bw;
		height: @bw;
		background-size: cover;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		transform-style: preserve-3d;
	}
	.xc-panorama_c {
		background-position: center 15%;
		// background-position: center 10%;
		transition: opacity .5s linear;
		img {
			position: absolute;
			z-index: 10;
			width: 100%;
			height: 100%;
		}
		span {
			position: absolute;
			width: @bw * 0.93;
			left: 0;
			right: 0;
			text-align: center;
			margin: auto;
			bottom: @bw * .06;
			color: white;
			font-size: @bw * .086;
			text-shadow: 2px 2px 10px black;
			font-family: '\9ED1\4F53';
			font-weight: bold;
		}
	}
	.xc-panorama_m {
		background-position: center center;
	}
	.xc-panorama_top {
		transform: rotateX(-90deg) translateZ(-(@bw * .5));
		overflow: hidden;
	}
	.xc-panorama_bottom {
		transform: rotateX(90deg) translateZ(-(@bw * .5));
		overflow: hidden;
	}
	.xc-panorama_c0 {
		transform: translateZ(-(@bw * .486));
	}
	.xc-panorama_c1 {
		transform: rotateY(-90deg) translateZ(-(@bw * .486));
	}
	.xc-panorama_c2 {
		transform: rotateY(180deg) translateZ(-(@bw * .486));
	}
	.xc-panorama_c3 {
		transform: rotateY(90deg) translateZ(-(@bw * .486));
	}

	// .xc-images-active, .xc-images-leave-active {
	// 	transition: opacity .3s linear;
	// }
	.xc-images-leave {
		opacity: 1;
	}
	.xc-images-leave-to {
		opacity: 0;
	}

	.xc-images-enter {
		opacity: 0;
	}
	.xc-images-enter-to {
		opacity: 1;
	}

	.xc-text-show {
		opacity: 1!important;
	}
	
</style>







