<!-- 旋转飘 -->
<template>
	<div class="piao3-cont">
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
			<div v-for="idx in 10" 
				class="po"
				:class="'piao3-'+idx"
				:key="'piao3-'+idx">
				<elem 
					:mode="getLuoItem(idx)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
			</div>
		</template>
		<template v-else>
			<img 
				v-for="idx in 10" 
				mode="widthFix"
				class="po"
				:class="'piao3-'+idx"
				:key="'piao3-'+idx"
				:src="getLuoItem(idx)">
		</template>
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'piao3',
		extends: BaseLuo,
	}
</script>

<style>
	.piao3-cont {
		position: absolute;
	    bottom: 0;
	    pointer-events: none;
	    z-index: 6;
	    transform: translateZ(1px);
	}
	.piao3-item {
		position: absolute;
	}
	.piao3-1 {
		width: 21px;
		left:149px;
		animation: piao3_1 12s linear 4s infinite both
	}
	.piao3-2 {
		width: 21px;
		left:279px;
		animation: piao3_2 10s linear 4s infinite both
	}
	.piao3-3 {
		width: 27px;
		left:358px;
		animation: piao3_3 11s linear 0s infinite both
	}
	.piao3-4 {
		width: 30px;
		left:0px;
		top: -640px;
		animation: piao3_4 12s linear 2s infinite both
	}
	.piao3-5 {
		width: 25px;
		left:0px;
		top: -450px;
		animation: piao3_5 10s linear 6s infinite both
	}
	.piao3-6 {
		width: 21px;
		left:0px;
		top: -55px;
		animation: piao3_6 12s linear 7s infinite both
	}
	.piao3-7 {
		width: 26px;
		left:4px;
		top: -75px;
		animation: piao3_7 12s linear 9s infinite both
	}
	.piao3-8 {
		width: 26px;
		left:47px;
		animation: piao3_8 10s linear 1s infinite both
	}
	.piao3-9 {
		width: 30px;
		left:0px;
		animation: piao3_7 10s linear 1s infinite both
	}
	.piao3-10 {
		width: 25px;
		left:400px;
		animation: piao3_9 10s linear 8s infinite both
	}
	@keyframes piao3_1 {
		0% {
    		transform: translate(0px,0px) rotateZ(0deg) rotateY(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(545px,-565px) rotateZ(350deg) rotateY(-720deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_2 {
		0% {
		    transform: translate(0px,0px) rotateZ(0deg) rotateY(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(443px,-606px) rotateZ(360deg) rotateY(-1080deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_3 {
		0% {
		    transform: translate(-218px,0px) rotateZ(0deg) rotateY(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(380px,-820px) rotateZ(350deg) rotateY(1080deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_4 {
		0% {
		    transform: translate(-30px,0px) rotateZ(0deg) rotateY(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(443px,-306px) rotateZ(360deg) rotateY(-1080deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_5 {
		0% {
		    transform: translate(-25px,0px) rotateZ(0deg) rotateX(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(350px,-680px) rotateZ(360deg) rotateX(-720deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_6 {
		0% {
		    transform: translate(-21px,0px) rotateZ(0deg) rotateY(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(545px,-565px) rotateZ(350deg) rotateY(-720deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_7 {
		0% {
		    transform: translate(0px,0px) rotateZ(0deg) rotateX(0deg);
		    opacity: 0;
		}
		1% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(550px,-300px) rotateZ(360deg) rotateX(720deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_8 {
		0% {
		    transform: translate(-222px,0px) rotateZ(0deg) rotateX(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(643px,-830px) rotateZ(350deg) rotateX(1080deg);
		    opacity: 0;
		}
	}
	@keyframes piao3_9 {
		0% {
		    transform: translate(0px,0px) rotateZ(0deg) rotateX(0deg);
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate(300px,-780px) rotateZ(360deg) rotateX(-1080deg);
		    opacity: 0;
		}
	}
</style>