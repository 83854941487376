<template>
	<div class="sheng-cont">
        <div class="sheng-box">
            <img class="sheng-item sheng-1" :src="mode[0]">
            <img class="sheng-item sheng-2" :src="mode[0]">
            <img class="sheng-item sheng-3" :src="mode[0]">
            <img class="sheng-item sheng-4" :src="mode[0]">
            <img class="sheng-item sheng-5" :src="mode[0]">
            <img class="sheng-item sheng-6" :src="mode[0]">
            <img class="sheng-item sheng-7" :src="mode[0]">
            <img class="sheng-item sheng-8" :src="mode[0]">
            <img class="sheng-item sheng-9" :src="mode[0]">
        </div>
        <div class="sheng-box-2">
            <img class="sheng-item sheng-10" :src="mode[0]">
            <img class="sheng-item sheng-11" :src="mode[0]">
            <img class="sheng-item sheng-12" :src="mode[0]">
            <img class="sheng-item sheng-13" :src="mode[0]">
            <img class="sheng-item sheng-14" :src="mode[0]">
            <img class="sheng-item sheng-15" :src="mode[0]">
        </div>
    </div>
</template>

<script>
	export default {
		name: 'sheng',
		props: ['mode'],
		data () {
			return {}
		}
	}
</script>

<style>
	.sheng-cont {
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    z-index: 1;
	}
	.sheng-box, .sheng-box-2 {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    top: 90%;
	    left: 0;
	}
	.sheng-item {
	    position: absolute
	}
	.sheng-1 {
	    left:307.50px;
	    width:24.50px;
	    -webkit-animation: sheng_ani_4 9212ms -2984ms linear infinite both;
	}
	.sheng-2 {
	    left:103.00px;
	    width:24.50px;
	    -webkit-animation: sheng_ani_1 9049ms -5140ms linear infinite both;
	}
	.sheng-3 {
	    left:174.50px;
	    width:47.00px;
	    -webkit-animation: sheng_ani_3 9604ms -8103ms linear infinite both;
	}
	.sheng-4 {
	    left:43.00px;
	    width:31.00px;
	    -webkit-animation: sheng_ani_3 9538ms -2002ms linear infinite both;
	}
	.sheng-5 {
	    left:239.00px;
	    width:41.50px;
	    -webkit-animation: sheng_ani_4 8302ms -3631ms linear infinite both;
	}
	.sheng-6 {
	    left:43.00px;
	    width:24.50px;
	    -webkit-animation: sheng_ani_2 8239ms -6106ms linear infinite both;
	}
	.sheng-7 {
	    left:114.50px;
	    width:27.00px;
	    -webkit-animation: sheng_ani_1 8237ms -2361ms linear infinite both;
	}
	.sheng-8 {
	    left:316.00px;
	    width:31.00px;
	    -webkit-animation: sheng_ani_3 9554ms -4618ms linear infinite both;
	}
	.sheng-9 {
	    left:159.00px;
	    width:29.00px;
	    -webkit-animation: sheng_ani_4 9675ms -6948ms linear infinite both;
	}
	.sheng-10 {
	    left:182.00px;
	    width:44.50px;
	    -webkit-animation: sheng_ani_3 8310ms -1740ms linear infinite both;
	}
	.sheng-11 {
	    left:159.50px;
	    width:48.50px;
	    -webkit-animation: sheng_ani_2 9455ms -3024ms linear infinite both;
	}
	.sheng-12 {
	    left:127.00px;
	    width:37.00px;
	    -webkit-animation: sheng_ani_4 8896ms -6151ms linear infinite both;
	}
	.sheng-13 {
	    left:197.00px;
	    width:36.00px;
	    -webkit-animation: sheng_ani_4 8232ms -471ms linear infinite both;
	}
	.sheng-14 {
	    left:331.00px;
	    width:44.50px;
	    -webkit-animation: sheng_ani_3 9068ms -4981ms linear infinite both;
	}
	.sheng-15 {
	    left:298.50px;
	    width:43.00px;
	    -webkit-animation: sheng_ani_3 9524ms -7295ms linear infinite both;
	}
	@-webkit-keyframes sheng_ani_4 {
	    0% {
	        -webkit-transform: translate(0, 0);
	        opacity: 0;
	    }
	    10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
	    100% {
	        -webkit-transform: translate(71.00px, -747.00px);
	        opacity: 0;
	    }
	}
	@-webkit-keyframes sheng_ani_3 {
	    0% {
	        -webkit-transform: translate(0, 0);
	        opacity: 0;
	    }
	    10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
	    100% {
	        -webkit-transform: translate(-140.50px, -747.00px);
	        opacity: 0;
	    }
	}
	@-webkit-keyframes sheng_ani_2 {
	    0% {
	        -webkit-transform: translate(0, 0);
	        opacity: 0;
	    }
	    10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
	    100% {
	        -webkit-transform: translate(-237.50px, -678.00px);
	        opacity: 0;
	    }
	}
	@-webkit-keyframes sheng_ani_1 {
	    0% {
	        -webkit-transform: translate(0, 0);
	        opacity: 0;
	    }
	    10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
	    100% {
	        -webkit-transform: translate(237.50px, -678.00px);
	        opacity: 0;
	    }
	}
</style>