<template>
	<div class="inter">
		<img class="interani" :src="mode[0]">
	</div>
</template>

<script>
	export default {
        name: 'zhuanyouxiazuoshang',
        props: ['mode'],
        data () {
            return {}
        }
    }
</script>

<style scoped lang="less">
	.inter {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		z-index: 8;
		pointer-events: none;
	}
    .interani{
        width: @bw * 2.528;
        top: @bw * 0.558;
        left: @bw * 0.347;
        animation: interani 4s linear both;
    }
    @keyframes interani {
        0% {
            transform: translate(@bw * 0.556,@bw * 1.403) rotateZ(540deg);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        50% {
            transform: translate(-@bw * 0.718,-@bw * 0.112) rotateZ(270deg);
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            transform: translate(-@bw * 2.222,-@bw * 1.25) rotateZ(0deg);
            opacity: 0;
        }
    }
</style>