<template>
	<div class="square-book-cell contianer" @click="goDetail()">
      <div class="sq-book-image" :style="{
				  backgroundImage: 'url('+book['cover']+')',
			  }">
			<div class="book-info-bg re-container">
				<div class="book-info-container re-container">
					<img class="book-info-img" src="https://mp-resource-liti.oss-cn-shenzhen.aliyuncs.com/wxa_icon/btn/pv.png" />
					<span class="book-info-text">{{book.pv}}人播放</span>
				</div>
			</div>
          	<img class="play-image" src="https://mp-resource-liti.oss-cn-shenzhen.aliyuncs.com/wxa_icon/btn/play.png" />
        </div>
        <div class="square-book-cell-bottom re-container">

			<img class="cell-userimg" :src="book.userinfo.avatarUrl" />
			<span class="cell-username">{{book.userinfo.nickName}}</span>

			<img class="cell-flower" src="https://mp-resource-liti.oss-cn-shenzhen.aliyuncs.com/wxa_icon/btn/like.png" />
			<span class="cell-bottom-text">{{book.zan}}</span>
			<!-- <button class="book-share-btn re-container">
				<img class="book-share-img" src="https://mp-resource-liti.oss-cn-shenzhen.aliyuncs.com/wxa_icon/btn/share.png" />
				<span class="book-share-text">分享</span>
			</button> -->
			<div class="ti-container re-container">
				<span class="book-ti">
          <span v-if="book.audit > 0" class="book-ti-tag">佳作</span>
          <span style="vertical-align: middle;">{{book.value.ti? book.value.ti : "《"+ book.theme_name +"》 - "+ book.userinfo.nickName}}</span>
        </span>
			</div>
      </div>
    </div>
</template>

<script>

	export default {
		name: 'SquareCell',
		props: ['book'],
		data () {
			return {}
		},
		created () {

		},
		mounted () {
			
		},
		methods: {
			goDetail(e){
        this.$emit('goDetail', this.book)
      },
		}
	}
</script>

<style scoped>
.square-book-cell {
  position: relative;
  width: 468px;
  margin: 12px 16px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

.sq-book-image {
	position: relative;
	width: 468px;
	height: 272px;
	background-size: cover;
	/* background-position: center; */
  background-position: center 25%!important;
	background-repeat: no-repeat;
}

.play-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}

.square-book-cell-bottom {
  width: 468px;
  justify-content: flex-start;
  padding: 8px 0;
}

.cell-userimg,.cell-flower{
	width: 18px;
	margin: 0 12px;
}

.cell-userimg{
	width: 36px;
	height: 36px;
  	border-radius: 50%;
}

.cell-bottom-text {
  font-size: 20px;
  font-weight: 400;
  color: #666;
  margin-right: 16px;
  text-align: left;
}

.cell-username {
  font-size: 20px;
  font-weight: 400;
  color: #666;
  margin-right: auto;
  text-align: left;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.book-share-btn {
  border-radius: 20px;
  background-color: rgba(2, 193, 98, 1);
  padding: 8px 16px;
  margin-right: 12px;
  border: 0;
}

.book-share-img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.book-share-text {
  font-size: 24px;
  color: #fff;
}

.ti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 448px;
  max-width: 448px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
  padding: 10px 10px 16px;
}

.book-ti {
  display: block;
  color: #fff;
  font-weight: 450;
  text-align: left;
  font-size: 26px;
  line-height: 42px;
}

.book-ti-tag {
  color: #fff;
  font-weight: 450;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  margin: auto 0;
  background-color: rgba(2, 193, 98, 1);
  padding: 6px 4px;
  border-radius: 4px;
  margin: 0 6px 8px 0;
  vertical-align: middle;
}

.book-info-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 446px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
  padding: 16px 10px 10px;
}

.book-info-img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.book-info-text {
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
</style>







