<template>
	<div class="van-toast van-toast--text van-toast--middle" style="z-index: 2015;">
		<div style="line-height: 30px;">{{textOptions.message}}</div>
	</div>
</template>

<script>
	export default {
		name: 'vText',
		props: ['textOptions'],
		data () {
			return {}
		}
	}
</script>

<style scoped lang="less">
	.van-toast--text {
	    padding:@bw * 0.039@bw * 0.049;
	    min-width:@bw * 0.133;
	}
	.van-toast {
	    position: fixed;
	    top: 50%;
	    left: 50%;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: flex;
	    color: #fff;
	    max-width: 70%;
	    font-size:@bw * 0.042;
	    line-height:@bw * 0.028;
	    border-radius:@bw * 0.017;
	    word-break: break-all;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    align-items: center;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	    flex-direction: column;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    justify-content: center;
	    box-sizing: content-box;
	    -webkit-transform: translate3d(-50%, -50%, 0);
	    transform: translate3d(-50%, -50%, 0);
	    background-color: rgba(50, 50, 51, 0.88);
	    width: -webkit-fit-content;
	    width: fit-content;
	    white-space: pre-wrap;
	}
</style>