<template>
	<div class="fall">
		<img class="hb1 hb" :src="getItem()">
	    <img class="hb2 qb" :src="getItem()">
	    <img class="hb3 hb" :src="getItem()">
	    <img class="hb4 qb" :src="getItem()">
	    <img class="hb5 hb" :src="getItem()">
	    <img class="hb6 hb" :src="getItem()">
	    <img class="hb7 qb" :src="getItem()">
	    <img class="hb8 hb" :src="getItem()">
	    <img class="hb9 qb" :src="getItem()">
	    <img class="hb10 hb" :src="getItem()">
	    <img class="hb11 hb" :src="getItem()">
	    <img class="hb12 qb" :src="getItem()">
	    <img class="hb13 hb" :src="getItem()">
	    <img class="hb14 hb" :src="getItem()">
	</div>
</template>

<script>
	export default {
		props: ['mode'],
		methods: {
			getItem () {
				let len = this.mode.length;
				return this.mode[parseInt( Math.random() * 1000000 ) % len];
			}
		}
	}
</script>

<style scoped>
	.fall > img {
        position: absolute;
		opacity: 0;
	}
    .hb1 {
        -webkit-transform: translate3d(219px, -80px, 48px) rotateX(30deg) rotateZ(98deg);
        -webkit-animation: hb1 6.5s 2.9s linear infinite;
    }
    @-webkit-keyframes hb1 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(219px, -80px, 48px) rotateX(30deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(219px, 700px, 48px) rotateX(-230deg) rotateZ(98deg);
        }
    }
    .hb2 {
        -webkit-transform: translate3d(319px, -80px, 77px) rotateY(50deg) rotateZ(98deg);
        -webkit-animation: hb2 7.2s 5.33s linear infinite;
    }
    @-webkit-keyframes hb2 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(319px, -80px, 77px) rotateY(50deg) rotateZ(38deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(219px, 700px, 77px) rotateY(-120deg) rotateZ(38deg);
        }
    }
    .hb3 {
        -webkit-transform: translate3d(411px, -80px, -20px) rotateX(130deg) rotateY(28deg);
        -webkit-animation: hb3 7s 1.25s linear infinite;
    }
    @-webkit-keyframes hb3 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(411px, -80px, -20px) rotateX(130deg) rotateY(28deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(411px, 700px, -20px) rotateX(-130deg) rotateY(28deg);
        }
    }
    .hb4 {
        -webkit-transform: translate3d(392px, -80px, -122px) rotateX(10deg) rotateY(100deg) rotateY(118deg);
        -webkit-animation: hb4 8s 3.8s linear infinite;
    }
    @-webkit-keyframes hb4 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(392px, -80px, -122px) rotateX(10deg) rotateY(100deg) rotateZ(118deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(392px, 700px, -122px) rotateX(-110deg) rotateY(100deg) rotateZ(118deg);
        }
    }
    .hb5 {
        -webkit-transform: translate3d(437px, -80px, 77px) rotateY(50deg) rotateY(108deg);
        -webkit-animation: hb5 6.8s .2s linear infinite;
    }
    @-webkit-keyframes hb5 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(437px, -80px, 77px) rotateY(50deg) rotateY(108deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(437px, 700px, 77px) rotateY(-150deg) rotateY(108deg);
        }
    }
    .hb6 {
        -webkit-transform: translate3d(255px, -80px, 182px) rotateX(100deg) rotateZ(22deg);
        -webkit-animation: hb6 7.3s 2.55s linear infinite;
    }
    @-webkit-keyframes hb6 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(255px, -80px, 182px) rotateX(100deg) rotateZ(22deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(255px, 700px, 182px) rotateX(-100deg) rotateZ(22deg);
        }
    }
    .hb7 {
        -webkit-transform: translate3d(611px, -80px, 111px) rotateY(10deg) rotateX(108deg);
        -webkit-animation: hb7 8.2s 4.7s linear infinite;
    }
    @-webkit-keyframes hb7 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(611px, -80px, 111px) rotateY(10deg) rotateX(108deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(611px, 700px, 111px) rotateY(-210deg) rotateX(108deg);
        }
    }
    .hb8 {
        -webkit-transform: translate3d(80px, -80px, -229px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        -webkit-animation: hb8 7.8s .2s linear infinite;
    }
    @-webkit-keyframes hb8 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(80px, -80px, -229px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(80px, 700px, -229px) rotateX(-145deg) rotateY(20deg) rotateZ(98deg);
        }
    }
    .hb9 {
        -webkit-transform: translate3d(500px, -80px, 77px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        -webkit-animation: hb9 7.8s .2s linear infinite;
    }
    @-webkit-keyframes hb9 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(500px, -80px, 77px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(500px, 700px, 77px) rotateX(-145deg) rotateY(20deg) rotateZ(98deg);
        }
    }
    .hb10 {
        -webkit-transform: translate3d(410px, -80px, -129px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        -webkit-animation: hb10 5.8s .6s linear infinite;
    }
    @-webkit-keyframes hb10 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(410px, -80px, -129px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(410px, 700px, -129px) rotateX(-145deg) rotateY(20deg) rotateZ(98deg);
        }
    }
    .hb11 {
        -webkit-transform: translate3d(310px, -80px, -219px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        -webkit-animation: hb11 5.8s 2.2s linear infinite;
    }
    @-webkit-keyframes hb11 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(310px, -80px, -219px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(310px, 700px, -219px) rotateX(-145deg) rotateY(20deg) rotateZ(98deg);
        }
    }
    .hb12 {
        -webkit-transform: translate3d(351px, -80px, -20px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        -webkit-animation: hb12 8.8s 4.2s linear infinite;
    }
    @-webkit-keyframes hb12 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(351px, -80px, -20px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(351px, 700px, -20px) rotateX(-145deg) rotateY(20deg) rotateZ(98deg);
        }
    }
    .hb13 {
        -webkit-transform: translate3d(190px, -80px, 223px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        -webkit-animation: hb13 7.8s 1.2s linear infinite;
    }
    @-webkit-keyframes hb13 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(190px, -80px, 223px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(190px, 700px, 223px) rotateX(-145deg) rotateY(20deg) rotateZ(98deg);
        }
    }
    .hb14 {
        -webkit-transform: translate3d(489px, -80px, -419px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        -webkit-animation: hb14 5.8s 2.2s linear infinite;
    }
    @-webkit-keyframes hb14 {
        from {
        	opacity: 0;
            -webkit-transform: translate3d(489px, -80px, -419px) rotateX(45deg) rotateY(20deg) rotateZ(98deg);
        }
        5% {
        	opacity: 1;
        }
        95% {
        	opacity: 1;
        }
        to {
        	opacity: 0;
            -webkit-transform: translate3d(489px, 700px, -419px) rotateX(-145deg) rotateY(20deg) rotateZ(98deg);
        }
    }
</style>