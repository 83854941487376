<template>
	<div class="m-items" :class="{active: index==activeItemIndex}">
		<div class="m-icon">♪</div>
		<div class="m-name" @click="onItemClick">{{data['name']}}</div>
		
		<template>
			<div class="m-user">
				<div class="m-user-box">
					<div class="m-user-headimgurl">
						<img :src="user['headimgurl']" />
					</div>
					<div class="m-user-nickname">
						用户-{{user['nickname']}}-上传
					</div>
				</div>
			</div>
		</template>
		
		<div class="m-confirm" @click="onConfirmClick">确定</div>
	</div>
</template>

<script>
	export default {
		name: 'MusicItem',
		props: ['data', 'index', 'activeItemIndex', 'user'],
		data () {
			return {}
		},
		mounted () {},
		methods: {
			onItemClick (index) {
				this.$emit('onItemClick', this.index);
			},
			onConfirmClick () {
				this.$emit('onConfirmClick', this.index);
			}
		}
	}
</script>

<style lang="less">
	@orange: #ff6126;
	@orange-light: #ff9761;
	@orange-dark: #ed5100;

	.m-items {
	    position: relative;
	    // display: flex;
	    font-size: @bw*.049;
	    // height: @bw*.156;
	    line-height: @bw*.156;
	    border-bottom: 1px ridge #c7c7c7;
	}
	.m-items:active {
		background: #ccc;
	}
	.m-items.active {
		background-color: white!important;
	}
	.m-icon {
	    width: @bw*.139;
	    text-align: center;
	    font-size: @bw*.06;
        color: #2b2b2b;
	}
	.m-name {
		// flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		// font-weight: bold;
		color: #2b2b2b;
		width: @bw*.833;
	}
	.m-icon, .m-name {
		vertical-align: top;
		display: inline-block;
	}
	.m-confirm {
	    // width: 145px;
	    background: linear-gradient(to bottom left, #ff9761, #E72047);
		color: white;
		text-align: center;
		position: absolute;
		right: @bw*.042;
		height: @bw*.097;
		line-height: @bw*.097;
		border-radius: @bw*.069;
		top: 50%;
		margin-top: -(@bw*.097/2);
		display: none;
		padding: 0 @bw*.039;
		font-size: @bw*.042;
	}
	.m-items.active .m-confirm, .m-items.active .m-user {
		display: block!important;
	}
	.m-items.active .m-icon, .m-items.active .m-name {
		color: #FC6326!important;
	}
	.m-items.active .m-name {
		width: @bw*.636!important;
	}
	.m-items.active .m-icon {
		animation: zhuan 2s linear infinite;
	}
	.m-search-confirm {
		display: none;
	}
	.m-search-items.active .m-search-confirm {
		display: block!important;
	}
	.m-user {
		position: relative;
		// padding-left: @bw*.139;
		font-size: @bw*.035;
		color: #909090;
		height: @bw*.053;
		line-height: 0px;
		display: none;
	}
	.m-user-box {
		position: absolute;
		top: -(@bw*.028);
		left: @bw*.139;
		height: 100%;
		display: flex;
		align-items: center;
	}
	.m-user-headimgurl {
		position: relative;
		width: @bw*.056;
		height: @bw*.056;
		border-radius: 50%;
		overflow: hidden;
		img {
			position: absolute;
			width: 100%; height: 100%; top: 0; left: 0;
		}
	}
	.m-user-nickname {
		flex: 1;
		line-height: @bw*.042;
		padding-left: @bw*.017;
	}
</style>