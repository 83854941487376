<template>
	<div class="page-fadeinblur page">
    	<div class="po page-fadeinblur_blurImage blurImage" :style="blurImageStyle"></div>
        <div class="page-fadeinblur_titlediv">
            <div class="page-fadeinblur_title">{{title}}</div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'fadeinblur',
		props: ['title'],
		computed: {
			blurImageStyle () {
				return {
					backgroundImage: 'url('+ this.$store.state.images[0]['im'] +')'
				}
			}
		},
		data () {
			return {}
		},
		created () {}
	}
</script>

<style>
	.page-fadeinblur {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.page-fadeinblur_titlediv {
		position: relative;
		width: 100%;
		height:194.50px;
		overflow: hidden;
		display: table;
		background-color: rgba(0,0,0,0.5);
	}
	.page-fadeinblur_title {
		width:200.00px;
		height:104.00px;
		vertical-align: middle;
		display: table-cell;
		text-align: center;
		font-size:26.50px;
		font-weight: bold;
		line-height:31.00px; 
		color: rgb(255, 255, 255); 
		animation: 1s linear 0.1s 1 normal both running fadeinblur_titlein;
	}
	@keyframes fadeinblur_titlein {
		0% {
		    -webkit-transform: scale(1.5);
		    -webkit-filter: blur(14.00px);
		    opacity: 0;
		}
		100% {
		    -webkit-transform: scale(1);
		    -webkit-filter: blur(0.00px);
		    opacity: 1;
		}
	}
</style>