<template>
	<div class="musics-copyright" v-show="$store.state.isCopyrightShow">
    	<div class="musics-copyright__hd">版权指引</div>
    	<div class="musics-copyright__bd">
    		<span>立体相册一贯高度重视信息网络传播中的知识产权保护，严格遵守知识产权法律法规和具有法律约束力的规范性文件，致力于提供优质的音乐相册编辑服务。</span>
    		<span>由于供相册搭配的音乐来自用户通过网络共享，对非法转载、盗版等侵权行为的发生不具备充分的监控能力。知识产权权利人发现在立体相册上的内容侵犯其知识产权权利时，可将详细情况及相关侵权内容通过电子邮件发送至mp@riti.net.cn。</span>
    		<span>我们将根据有关法律规定采取措施移除相关内容或屏蔽相关链接。</span>
    	</div>
    	<div class="musics-copyright__ft">
    		<span @click="$store.commit('copyrightShow', {'isShow': false})">返回</span>
    	</div>
    </div>	
</template>

<script>
	export default {
		name: 'Copyright',
		data () {
			return {}
		}
	}
</script>

<style scoped lang="less">
	@orange: #ff6126;
	@orange-light: #ff9761;
	@orange-dark: #ed5100;

	.musics-copyright {
	    position: fixed;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    background: white;
	    z-index: 9999;
	    /*display: none;*/
	}

	.musics-copyright__txt {
	    padding: @bw*.069 0 @bw*.486;
	    text-align: center;
	    font-size: @bw*.032;
	    color: #949494;
	}
	.musics-copyright__hd {
	    text-align: center;
	    color: white;
	    background-color: @orange-dark;
	    line-height: @bw*.12;
	    font-size: @bw*.042;
	}
	.musics-copyright__bd {
	    font-size: @bw*.042;
	    text-indent: @bw*.083;
	    letter-spacing: 2px;
	    line-height: @bw*.053;
	    color: #3a3a3a;
	    margin: @bw*.1 @bw*.031;
	}
	.musics-copyright__bd>span {
	    text-indent: @bw*.083;
	    margin-top: @bw*.028;
	    display: block;
	}
	.musics-copyright__ft {
	    position: absolute;
	    background-color: rgb(246, 248, 245);
	    width: 100%;
	    height: @bw*.153;
	    bottom: 0;
	    color: white;
	    border-top: solid 2px #ccc;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    z-index: 999;
	}
	.musics-copyright__ft>span {
	    position: absolute;
	    width: @bw*.4;
	    height: @bw*.1;
	    background-color: @orange;
	    font-weight: bold;
	    font-size: @bw*.04;
	    letter-spacing: @bw*.014;
	    text-align: center;
	    line-height: @bw*.1;
	    border-radius: 6px;
	}
</style>