class stCanvas {
    constructor (util) {
        this.$util = util;
        // this.user = this.$util.$store.state.userInfo;
        this.userphoto = "";
        this.scale = 1;
        this.canvasSize = [500, 400]; // 初始存储画布尺寸
    }
    get user () {
        return this.$util.$store.state.userInfo;
    }
    scaleStyle (datas) {
        datas = this.$util.copy(datas);
        for (let i=0; i<datas.length; i++) {
            if (datas[i]) {
                datas[i] = this.$util.toFixed( parseFloat(datas[i]) * this.scale , 1 );
            }
        }
        return datas;
    }
    fitStyle (options) {
        let {
            elem,
            parent,
            index
        } = options;
        index = index || 0;
        if (index > 1) {
            this.$util.$loger.warn("画布暂不支持绘画超过「2」层元素");
            return {};
        }
        let item = this.$util.copy(elem);
        // size
        if (!item['size']) {
            item['size'] = this.canvasSize;
        }
        // pos
        item['pos'] = item['pos'] && this.$util.typeOf(item['pos']) == 'array' ? item['pos'] : [0, null, null, 0];
        // 如果有pos_mode
        if (index != 0 && item['pos_mode']) {
            if (item['pos_mode'] == 'center_x') {
                item['pos'][0] = this.canvasSize[1]/2 - item['size'][1]/2;
            } else if (item['pos_mode'] == 'center_y') {
                item['pos'][1] = this.canvasSize[2]/2 - item['size'][2]/2;
            } else if (item['pos_mode'] == 'center') {
                item['pos'][0] = this.canvasSize[1]/2 - item['size'][1]/2;
                item['pos'][1] = this.canvasSize[2]/2 - item['size'][2]/2;
            }
        }
        // 按比例放大
        item['size'] = this.scaleStyle(item['size'])
        item['pos'] = this.scaleStyle(item['pos'])
        // 定位转换
        if (index > 0) {
            if (item['pos'][0] == null) {
                item['pos'][0] = this.canvasSize[1] - item['pos'][2] - item['size'][1];
            }
            if (item['pos'][3] == null) {
                item['pos'][3] = this.canvasSize[0] - item['pos'][1] - item['size'][0];
            }
        }

        let style = item["style"] || {};

        // 是否圆形
        item['circle'] = (( item['border_radius'] || style["border-radius"] || style["borderRadius"]) == "50%") ? 1 : 0;
        // 图片特性
        if (item['im'] && this.$util.typeOf(item['im']) == "string") {
            item['im'] = item['im']
                            .replace("#headimgurl#", this.user.headimgurl)
                            .replace("#userphoto#", this.$util.typeOf(this.user.userphoto) == 'object' ? this.user.userphoto['im'] : this.user.userphoto)
                            // .replace("http://", "https://")
            if (item['im'].indexOf('tmp') == -1) {
                item['im'] = item['im'].replace("http://", "https://")
            }
            // style["border-radius"]
            item['circle'] = ((style["border-radius"] || style["borderRadius"]) == "50%") ? 1 : 0;
        }
        // 文字特性
        if (item["is"] == "word") {
            // 关键字替换
            item["text"] = this.$util.replaceKeyWords(item["text"], this.user);
            // 样式属性
            let fontSize = ((item["font_size"] ? item["font_size"] + "px" : null) || style["font-size"] || style["fontSize"] || "20px"),
                textAlign = style["text-align"] || style["textAlign"] || "center",
                fontWeight = style["font-weight"] || style["fontWeight"] || "";
            item["font"] = fontSize + " " + textAlign + " " +  fontWeight;
            item["color"] = style["color"] || "#ffffff";
            item["size"][1] = parseInt( fontSize.replace("px", "") );
        }

        // 逐层递归
        if (item['elems']) {
            for (let i = 0 ; i < item['elems'].length ; i++){
                item['elems'][i] = this.fitStyle({
                    "parent": this.$util.copy(item),
                    "elem": item['elems'][i],
                    "index": index + 1
                })
            }
        }
        return item;
    }
    async drawImage (options){
        let {
            elem,
            parent,
            index
        } = options || {};
        index = index || 0;
        elem = elem || this.elem;
        let item = this.$util.copy(elem);

        const WIDTH = item.size[0],
            HEIGHT = item.size[1];
        
        // 通用属性
        // 背景颜色
        if (item['bg_color']) {
            this.os_ctx.fillStyle = item['bg_color'];
        }

        // 图片对象
        if (item['im']) {
            let image = this.$util.isEnv("wxa") ? this.os_canvas.createImage() : new Image();
            if (image) {
                await new Promise((resolve, reject) => {
                    image.onload = ()=>{
                        resolve("ok");
                    };
                    image.onerror = err=>{
                        console.log("img onerror==>",err);
                        reject(err);
                    }
                    // setTimeout(() => {
                    //     reject("timeout");
                    // }, 12000);
                    // image.src = "https://";
                    // 安卓机-离屏canvas二次加载图片不进onload
                    if (this.$util.isEnv("ios")) {
                        image.src = item['im'];
                    } else {
                        if (this.$util.$global.get( "image_load" + item['im'] )){
                            let random_v = "v=" + new Date().getSec();
                            let mark = item['im'].indexOf("?") == -1 ? "?" : "&";
                            image.src = item['im'] + mark + random_v; // 要加载的图片 url
                        } else {
                            image.src = item['im'];
                            this.$util.$global.set("image_load" + item['im'], 1);
                        }
                    }
                }).catch(err=>{
                    console.log("err==>", err)
                })
                let imgw = image.width,
                    imgh = image.height;
                if (imgw && imgh) {
                    // 圆形
                    if (item['circle']) {
                        this.os_ctx.save();
                        this.os_ctx.beginPath(); //开始绘制
                        this.os_ctx.arc(WIDTH / 2 + item["pos"][3], HEIGHT / 2 + item["pos"][0], WIDTH / 2, 0, Math.PI *2, false);
                        this.os_ctx.closePath();
                        this.os_ctx.clip(); //画好了圆 剪切 原始画布中剪切任意形状和尺寸。一旦剪切了某个区域，则所有之后的绘图都会被限制在被剪切的区域内 这也是我们要save上下文的原因
                        // 圆形时确保图形不拉伸
                        imgh = imgw;
                    }
                    // 把图片画到离屏 canvas 上
                    this.os_ctx.drawImage(image, 0, 0, imgw, imgh, item["pos"][3], item["pos"][0], WIDTH, HEIGHT);
                    this.os_ctx.restore();
                    // this.os_ctx.draw(true);
                }
            }
        }
        // 文字元素
        if (item["is"] == "word") {
            this.os_ctx.font = item["font"];
            this.os_ctx.fillStyle = item["color"];
            this.os_ctx.fillText(item["text"], item['pos'][3], item['pos'][0], item['size'][0]);
            this.os_ctx.restore();
        }

        if (item['is'] == "baseelem") {
            console.log("3==>");
            // 圆形
            if (item['circle']) {
                this.os_ctx.beginPath(); //开始绘制
                this.os_ctx.arc(WIDTH / 2 + item["pos"][3], HEIGHT / 2 + item["pos"][0], WIDTH / 2, 0, Math.PI *2, false);
                this.os_ctx.closePath();
                this.os_ctx.clip(); //画好了圆 剪切 原始画布中剪切任意形状和尺寸。一旦剪切了某个区域，则所有之后的绘图都会被限制在被剪切的区域内 这也是我们要save上下文的原因
            }
            this.os_ctx.fillRect(item["pos"][3], item["pos"][0], WIDTH, HEIGHT)
            this.os_ctx.restore();
        }

        this.os_ctx.save();

        /**
         * 接着画其他内容
         */
        if (index == 0 && item.elems) {
            for (let i = 0 ; i < item.elems.length ; i++) {
                await this.drawImage({
                    "elem": item.elems[i],
                    "index": 1,
                    "parent": item
                }).catch(err=>{
                    debugger;
                })
            }
        }
    }
}

export default {
    stCanvas
};