<template>
	<div class="zhuan poc">
	    <div class="perspective poc">
	        <div class="zhuan_1 preserve3d po" :style="getStyle">
	            <div class="zhuan_1_f zhuan_div poc bg" :style="getBorderStyle()">
	            	<elem v-if="comp_config[5]" :mode="comp_config[5]"></elem>
	            </div>
	            <div class="zhuan_1_a zhuan_div poc bg" :style="getBorderStyle()">
	            	<elem v-if="comp_config[5]" :mode="comp_config[5]"></elem>
	            </div>
	            <div class="zhuan_1_t zhuan_t po" :style="getBorderStyle(0)"></div>
	            <div class="zhuan_1_b zhuan_b po" :style="getBorderStyle(1)"></div>
	            <div class="zhuan_1_l zhuan_l po" :style="getBorderStyle(2)"></div>
	            <div class="zhuan_1_r zhuan_r po" :style="getBorderStyle(3)"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'zhuan',
        extends: BaseElem,
		data () {
			return {
			
			}
		},
		computed: {
			getStyle () {
                let tsize = this.$util.getphotoboxsize(this.currentPhoto['orisize'], [440, 600], 'both');

                return {
                    width: tsize[0] + 'px',
                    height: tsize[1] + 'px'
                }
			}
		},
		methods: {
			getBorderStyle (side) {
				/*
					side: undefined / 0(top) / 1(bottom) / 2(left) / 3(right)
				*/
				let css = {};

				let src = side === undefined ? (this.comp_config[0] ? this.comp_config[0] : 'https://resali.lititutu.cn/icons/other/border.jpg') : (this.comp_config[side+1] ? this.comp_config[side+1] : 'https://resali.lititutu.cn/icons/other/' + ['borderT.png', 'borderB.png', 'borderL.png', 'borderR.png'][side])

				src = "url("+ src +")";

				if (side === undefined) {
					css['backgroundImage'] = 'url('+this.currentPhoto['im']+')';
					css['borderImageSource'] = src;
				} else {
					css['backgroundImage'] = src;
				}
				return css;
			}
		}
	}
</script>

<style>
	.zhuan .perspective {
	    perspective: 600px;
	}
	.zhuan_1 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    animation: zhuan_1_1 6s linear infinite both;
	    background-color: #eee;
	}
	@keyframes zhuan_1_1 {
		0% {
		    transform: rotateY(360deg);
		}
		100% {
		    transform: rotateY(0deg);
		}
	}
	.zhuan_div {
	    border: 5px solid;
	    border-image-slice: 5;
	    background-size: cover;
	    background-clip: content-box;
	}
	.zhuan_1_f {
	    transform: translateZ(15px);
	}
	.zhuan_1_a {
	    transform: translateZ(-15px);
	}
	.zhuan_t {
	    top: -15px;
	}
	.zhuan_t, .zhuan_b {
	    height: 32px;
	    width: 100%;
	    background-size: 100% auto;
	}
	.zhuan_1_t {
	    transform: translateZ(0) rotateX(90deg);
	}
	.zhuan_b {
	    bottom: -15px;
	}
	.zhuan_t, .zhuan_b {
	    height: 32px;
	    width: 100%;
	    background-size: 100% auto;
	}
	.zhuan_1_b {
	    transform: translateZ(0) translateY(12px) rotateX(-90deg);
	}
	.zhuan_l {
	    left: -15px;
	}
	.zhuan_l, .zhuan_r {
	    width: 32px;
	    height: 102%;
	    background-size: auto 100%;
	}
	.zhuan_1_l {
	    transform: translateZ(0) rotateY(90deg);
	}
	.zhuan_r {
	    right: -25px;
	}
	.zhuan_1_r {
	    transform: translateZ(0) rotateY(-90deg);
	}
	.zhuan_hudie {
		width: 170px;
		height: 147px;
	}
</style>





