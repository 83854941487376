<template>
	<div class="duolingxing">
	    <div class="duolingxing_bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
            <template v-if="'xinnianhejiahuan' == currentTid">
	    	  <div class="duolingxing_bg" style="background-image: url(http://resali.lititutu.cn/scenes/xinnianhejiahuan/05.png)"></div>
            </template>
	        <div class="duolingxing_3dBox">
	            <div class="user_lingxing_box duolingxing_box_ani1">
	                <div class="duolingxing_user_box" @click="wxPreview">
	                    <div class="duolingxing_userImg imgpo" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="duolingxing_border imgpo" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div id="duolingxing_bot1" class="duolingxing_bot">
	                	<template v-if="Object.prototype.toString.call(mode[2]).toLowerCase() == '[object object]'">
                            <gif :mode="mode[2]"></gif>
                        </template>
                        <template v-else>
                            <img :src="mode[2]">
                        </template>
	                </div>
	                <!-- <canvas height="180" width="380" class="duolingxing_bot"></canvas> -->
	            </div>
	            <div class="user_lingxing_box duolingxing_box_ani2">
	                <div class="duolingxing_user_box" @click="wxPreview">
	                    <div class="duolingxing_userImg imgpo" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="duolingxing_border imgpo" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div id="duolingxing_bot2" class="duolingxing_bot">
	                	<template v-if="Object.prototype.toString.call(mode[2]).toLowerCase() == '[object object]'">
                            <gif :mode="mode[2]"></gif>
                        </template>
                        <template v-else>
                            <img :src="mode[2]">
                        </template>
	                </div>
	                <!-- <canvas height="180" width="380" class="duolingxing_bot"></canvas> -->
	            </div>
	            <div class="user_lingxing_box duolingxing_box_ani3">
	                <div class="duolingxing_user_box" @click="wxPreview">
	                    <div class="duolingxing_userImg imgpo" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="duolingxing_border imgpo" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div id="duolingxing_bot3" class="duolingxing_bot">
	                	<template v-if="Object.prototype.toString.call(mode[2]).toLowerCase() == '[object object]'">
                            <gif :mode="mode[2]"></gif>
                        </template>
                        <template v-else>
                            <img :src="mode[2]">
                        </template>
	                </div>
	                <!-- <canvas height="180" width="380" class="duolingxing_bot"></canvas> -->
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'fenshen',
		props: ['mode', 'currentImage'],
		components: {
		},
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
        computed: {
            currentTid () {
                return this.$store.state.config.data['ttid'];
            }
        }
	}
</script>

<style scoped lang="less">
	
    .duolingxing {
        width: 100%;
        height: 100%;
    }
    .duolingxing_bg {
        height: 100%;
        width: 100%;
        position: absolute;
        background: no-repeat center;
        background-size: cover
    }
    .duolingxing_canvas {
        position: absolute;
    }
    .duolingxing_3dBox {
        height: 100%;
        width: 100%;
        position: absolute;
        transform-style: preserve-3d;
        perspective:@bw * 0.972;
    }
    .user_lingxing_box {
        transform-style: preserve-3d;
        width:@bw * 0.833;
        height:@bw * 0.833;
        position: absolute;
    }
    .duolingxing_box_ani1 {
        transform: translateZ(-@bw * 1.085) translateX(-@bw * 0.675) translateY(@bw * 0.953);
        animation: box_move1 8s linear infinite forwards alternate;
    }
    .duolingxing_box_ani2 {
        transform: translateZ(-@bw * 0.529) translateX(@bw * 0.912);
        animation: box_move2 8s linear infinite forwards;
    }
    .duolingxing_box_ani3 {
        transform: translateZ(-@bw * 1.1) translateX(@bw * 0.267) translateY(@bw * 0.396);
        animation: box_move3 8s 0.9s linear infinite forwards alternate;
    }
    .duolingxing_bot {
        position: absolute;
        // transform: translateX(-50%);
        background: no-repeat center;
        background-size: cover;
        width:@bw * 0.528;
        height:@bw * 0.25;
        bottom: -@bw * 0.097;
        left: 50%;
        margin-left: -(@bw * 0.528 / 2 + 10);
    }
    .duolingxing_user_box {
        width:@bw * 0.833;
        height:@bw * 0.833;
        animation: rotate_border_user 8s linear infinite forwards;
    }
    .duolingxing_userImg {
        width:@bw * 0.6;
        height:@bw * 0.6;
        top: 50%;
        left: 50%;
        margin-top: -(@bw * 0.6 / 2);
        margin-left: -(@bw * 0.6 / 2);
        -webkit-clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
        -moz-clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
        clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
        transition: 1s clip-path;
        // margin-left:@bw * 0.069;
        background-size: cover;
    }
    .duolingxing_border {
        width:@bw * 0.694;
        height:@bw * 0.694;
        top: 50%;
        left: 50%;
        margin-top: -(@bw * 0.694 / 2);
        margin-left: -(@bw * 0.694 / 2);
        background: no-repeat center;
        background-size: contain;
        // margin-left:@bw * 0.069;
    }
    .imgpo {
        position: absolute;
    }
    @keyframes rotate_border_user {
        30% {
            transform:rotateY(170deg)
        }
        40% {
            transform:rotateY(180deg)
        }
        60% {
            transform:rotateY(190deg)
        }
        90% {
            transform:rotateY(350deg)
        }
        100% {
            transform:rotateY(360deg)
        }
    }
    @keyframes box_move1 {
        30% {
            transform: translateZ(-@bw * 1.1) translateX(@bw * 0.128) translateY(@bw * 0.396);
        }
        60% {
            transform: translateZ(@bw * 0.142) translateX(@bw * 0.128) translateY(@bw * 0.351)
        }
        100% {
            transform: translateZ(@bw * 0.142) translateX(@bw * 0.128) translateY(@bw * 0.351)
        }
    }
    @keyframes box_move2 {
        30% {
            transform: translateZ(-@bw * 1.1) translateX(@bw * 0.267) translateY(@bw * 0.396);
        }
        50% {
            transform: translateZ(-@bw * 0.267) translateX(@bw * 0.267) translateY(@bw * 0.396);
        }
        70% {
            transform: translateZ(@bw * 0.142) translateX(@bw * 0.128) translateY(@bw * 0.351);
        }
        90% {
            transform: translateZ(-@bw * 0.419) translateX(@bw * 0.683) translateY(@bw * 0.074)
        }
        100% {
            transform: translateZ(-@bw * 1.1) translateX(@bw * 0.267) translateY(@bw * 0.396)
        }
    }
    @keyframes box_move3 {
        30% {
            transform: translateZ(@bw * 0.142) translateX(@bw * 0.128) translateY(@bw * 0.351)
        }
        60% {
            transform: translateZ(@bw * 0.308) translateX(@bw * 0.128) translateY(@bw * 0.351)
        }
        80% {
            transform: translateZ(-@bw * 0.762) translateX(-@bw * 0.544) translateY(@bw * 0.754)
        }
    }
</style>