<template>
    <div class="zimu_con po" :style="getStyles">
        <div class="zimu-container zimu-container1 po" :style="getConStyles">
            <span
                v-for="(item,index) in txs"
                :key="'txitem' + index"
                class="zimus"
                :class="'zimu_0' + (index%4)"
                :style="getZimuItemStyle(index)">
                {{item}}
            </span>
        </div>
        <div class="zimu-container zimu-container2 po" :style="getConStyles">
            <span
                v-for="(item,index) in txs"
                :key="'txitem2_' + index"
                class="zimus"
                :class="'zimu_0' + (index%4)"
                :style="getZimuItemStyle(index)">
                {{item}}
            </span>
        </div>
    </div>
</template>

<script>
    import BaseElem from "@/components/st_comps/st_comp/comps/StElem/BaseElem"
	export default {
        name: 'zimu',
        extends: BaseElem,
		data () {
			return {
                txs: [],
            }
		},
        mounted () {
            if (this.mode['tx']) {
                let _state = this.$store.state;
                for (let i=0; i<this.mode.tx.length; i++) {
                    let _tx = this.$util.replaceKeyWords(this.mode.tx[i], "author", _state)
                    this.$set(this.mode.tx, i, _tx);
                }
                this.txs = JSON.parse( JSON.stringify( this.mode.tx ) )
                this.txs.reverse()
            }
            // 适配baseelem
            if (this.comp_config&&this.comp_config[0]) {
                this.txs = this.comp_config[0];
            }
        },
        computed: {
            getConStyles () {
                let item = this.mode['item'] || {};
                
                let s = {};

                if (this.$store.state.hasUsertext
                    && this.$store.state.hasUsertext != 0
                    && !this.mode['withtext']
                    && (!this.comp_config || !this.comp_config[2])){
                    s = {
                        "display": "none"
                    }
                    return s;
                }
                if (!item['keyframe']){
                    item['keyframe'] = "zimu_2"
                }
                s = Object.assign(s, this.getKeyframeStyle( item['keyframe'] ));

                let tx_len = this.txs.length;

                s['width'] =  (173 * tx_len).toString() + "px";
                return s;
            },
        },
        methods:{
            getZimuItemStyle (index) {
                let item = this.mode['item'] || null,
                    shadow = "red",
                    s = {
                        "color": "yellow",
                    }

                // 老版配置适配
                if (item) {
                    if (item["color"]){
                        s['color'] = item["color"]
                    }
                    if (item['itemStyle']) {
                        s = Object.assign(s, item['itemStyle']);
                    }
                    if (item['shadow']){
                        shadow = item['shadow']
                    }
                }
                s['textShadow'] = "-6px -8px 0px " + shadow + ", -4px -8px 0px " + shadow + ", -6px -8px 0px " + shadow + ", 8px 6px 0px " + shadow + ", 6px 5px"


                // 新样式覆盖
                if (this.comp_config && this.comp_config[1]) {
                    s = Object.assign(s, this.comp_config[1]);
                }
                
                return s;
            },
        }
	}
</script>

<style>
    .zimu_con{
        pointer-events: none;
        bottom: 0px;
        left: 0px;
    }
    .zimu-container{
        white-space: nowrap;
        height: 300px;
        bottom: 60px;
        padding: 0 80px;
    }
    .zimu-container1{
        animation-delay: 0s;
    }
    .zimu-container2{
        animation-delay: 7s!important;
    }
    .zimus{
        display: inline;
        font-size: 73px;
        line-height: 73px;
        font-weight: bolder;
        text-align: center;
        writing-mode: vertical-lr;
        /* width: 73px; */
    }
    .zimu_01 {
        margin: 0 60px;
        transform: scale(.8);
    }
    .zimu_02 {
        margin: 0 50px;
        transform: scale(.6);
    }
    .zimu_03 {
        margin: 0 70px;
        transform: scale(.9);
    }
    .zimu_04 {
        margin: 0 40px;
        transform: scale(.7);
    }
</style>