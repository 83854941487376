// stComp引入
import StButton from "@/components/st_comps/st_comp/comps/StButton"
import StElem from "@/components/st_comps/st_comp/comps/StElem"
import StVision from "@/components/st_comps/st_comp/comps/StVision"
// import Guandao from "@/components/st_comps/st_comp/comps/StVision/Guandao"
// import Guandao from "@/components/st_comps/st_comp/comps/StVision/Guandao"

// {app}Comp引入

// localComp引入

// Util & Store 引入
import StStore from './st_comps.store'
import StUtil from './st_comps.util'

export default {
    install: function (Vue) {
        // stComp引入
        StButton.install(Vue);
        StElem.install(Vue);
        StVision.install(Vue);
        // Vue.component('guandao', Guandao);

        // {app}Comp引入

        // localComp引入
        // Vue.component('lrc', Lrc)
        
        // Store引入
        Vue.prototype.$store = StStore;

        // Util引入
        Vue.prototype.$util = StUtil;
        Vue.prototype.$util.$store = StStore; // 挂载store，方便所有的工具函数内部调用store
    }
}