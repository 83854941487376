<template>
	<div class="con">
	    <div class="perspective_div">
	        <div class="container_3d">
	        	<template v-if="Object.prototype.toString.call(mode[0]).toLowerCase() == '[object object]'">
					<div v-for="index of 5" :class="'top top' + index" :key="'topclass' + index">
	        			<gif :mode="mode[0]"></gif>
	        		</div>
	        	</template>
	        	<template v-else>
		            <div :style="{backgroundImage: 'url('+ mode[0] +')'}" class="top top1"></div>
		            <div :style="{backgroundImage: 'url('+ mode[0] +')'}" class="top top2"></div>
		            <div :style="{backgroundImage: 'url('+ mode[0] +')'}" class="top top3"></div>
		            <!-- <div :style="{backgroundImage: 'url('+ mode[0] +')'}" class="top top4"></div> -->
		            <!-- <div :style="{backgroundImage: 'url('+ mode[0] +')'}" class="top top5"></div> -->
	        	</template>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_left1"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_left2"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_left3"></div>
	            <!-- <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_left4"></div> -->
	            <!-- <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_left5"></div> -->
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_right1"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_right2"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_right3"></div>
	            <!-- <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_right4"></div> -->
	            <!-- <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg userImg_right5"></div> -->
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="bg bg_photo userImg front"></div>

	            <template v-if="Object.prototype.toString.call(mode[1]).toLowerCase() == '[object object]'">
	            	<div class="bottom bottom1">
	            		<template v-if="mode[1]['type'] == 'gif'">
	            			<gif :mode="mode[1]"></gif>
	            		</template>
	            		<template v-else-if="mode[1]['type'] == 'mode-1'">
	            			<div v-for="item in mode[1]['elems']">
		            			<div style="transition: .5s" :class="getElemsBotAni()">
		            				<img style="width: 100%;" :src="item['im']">
		            			</div>
	            			</div>
	            		</template>
	            	</div>
		            <div class="bottom bottom2">
		            	<template v-if="mode[1]['type'] == 'gif'">
		            		<gif :mode="mode[1]"></gif>
		            	</template>
		            	<template v-else-if="mode[1]['type'] == 'mode-1'">
		            		<div v-for="item in mode[1]['elems']">
			            		<div style="transition: .5s" :class="getElemsBotAni()">
			            			<img style="width: 100%;" :src="item['im']">
			            		</div>
		            		</div>
		            	</template>
		            </div>
		            <div class="bottom bottom3">
		            	<template v-if="mode[1]['type'] == 'gif'">
		            		<gif :mode="mode[1]"></gif>
		            	</template>
		            	<template v-else-if="mode[1]['type'] == 'mode-1'">
		            		<div v-for="item in mode[1]['elems']">
			            		<div style="transition: .5s" :class="getElemsBotAni()">
			            			<img style="width: 100%;" :src="item['im']">
			            		</div>
		            		</div>
		            	</template>
		            </div>
		            <div class="bottom bottom4">
		            	<template v-if="mode[1]['type'] == 'gif'">
		            		<gif :mode="mode[1]"></gif>
		            	</template>
		            	<template v-else-if="mode[1]['type'] == 'mode-1'">
		            		<div v-for="item in mode[1]['elems']">
			            		<div style="transition: .5s" :class="getElemsBotAni()">
			            			<img style="width: 100%;" :src="item['im']">
			            		</div>
		            		</div>
		            	</template>
		            </div>
		            <div class="bottom bottom5">
		            	<template v-if="mode[1]['type'] == 'gif'">
		            		<gif :mode="mode[1]"></gif>
		            	</template>
		            	<template v-else-if="mode[1]['type'] == 'mode-1'">
		            		<div v-for="item in mode[1]['elems']">
			            		<div style="transition: .5s" :class="getElemsBotAni()">
			            			<img style="width: 100%;" :src="item['im']">
			            		</div>
		            		</div>
		            	</template>
		            </div>
	            </template>
	            <template v-else>
		            <div :style="{backgroundImage: 'url('+ mode[1] +')'}" class="bottom bottom1"></div>
		            <div :style="{backgroundImage: 'url('+ mode[1] +')'}" class="bottom bottom2"></div>
		            <div :style="{backgroundImage: 'url('+ mode[1] +')'}" class="bottom bottom3"></div>
	            </template>
	            <template v-if="mode.length == 3">
	            	<template v-if="Object.prototype.toString.call(mode[2]).toLowerCase() == '[object object]'">
	            		<template v-if="mode[2]['type'] == 'lantern'">
							<img
								v-for="index of 6"
								mode="widthFix"
								:src="getLanterns('top')"
								class="page2_lantern po"
								:class="'page2_lantern' + index"
								:key="'page2_lantern' + index">
							<template v-for="idx of [3,4,5]">
								<img
									v-for="index of 5"
									mode="widthFix"
									:src="getLanterns('bottom', index - 1)"
									:style="getLanternStyle('bottom', index - 1)"
									class="po"
									:class="'page2_' + index + ' page2_' + index + '_' + idx"
									:key="'page2_' + index + ' page2_' + index + '_' + idx">
							</template>
	            		</template>
	            	</template>
	            	<template v-else>
						<img v-for="index of 8" :src="getFlowers(index - 1)" class="panorama_flower2 po" :class="'panorama_flower2_' + (8 - index)" :key="'panorama_flower2_'+index">
	            	</template>
	            </template>
	            <template v-else>
					<div
						v-for="index of 2"
						:class="'container'+ index + '_3d'"
						:key="'container'+ index + '_3d'">
						<div v-for='item in ["top", "left", "right", "font", "after", "bottom"]'
							@click="wxPreview"
							:style="{backgroundImage: 'url('+ currentImage['im'] +')'}"
							class="userImg bg bg_photo"
							:class="'small_' + item"
							:key="'container' + index + '_3d_small_' + item"></div>
		            </div>
	            </template>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'changlang',
		props: ['mode', 'currentImage'],
		components: {},
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
			getElemsBotAni () {
				return 'anima' + parseInt(Math.random()*10000)%8;
			},
			getFlowers (index) {
				return this.mode[2][index%this.mode[2].length];
			},
			getLanterns (place, index) {
				if (place == 'top') {
					return this.mode[2]['top'][0];
				} else if (place == 'bottom') {
					return this.mode[2]['bottom'][index%this.mode[2]['bottom'].length]['im'];
				}
			},
			getLanternStyle (place, index) {
				let s = {};
				if (place == 'bottom') {
					let c = this.mode[2]['bottom'][index%this.mode[2]['bottom'].length];
					s['width'] = c['size'][0] + 'px';
					s['height'] = c['size'][1] + 'px';
				}
				return s;
			}
		}
	}
</script>

<style scoped lang="less">
	.con {
		position:absolute;
		width:100%;
		height:100%;
		overflow:hidden;
	}
	.perspective_div {
		position:absolute;
		width:100%;
		height:100%;
		perspective:@bw * 1.0;
	}
	.container_3d {
		position:absolute;
		width:@bw * 1.0;
		height:@bw * 1.0;
		top:0;
		left:0;
		right:0;
		bottom:0;
		margin:auto;
		transform-style:preserve-3d;
		animation:time 7s linear infinite both;
	}
	.container1_3d {
		position:absolute;
		width:@bw * 0.556;
		height:@bw * 0.556;
		left:@bw * 0.07;
		bottom:@bw * 0.028;
		transform-style:preserve-3d;
		animation:time3 7s linear infinite both;
	}
	.container2_3d {
		position:absolute;
		width:@bw * 0.556;
		height:@bw * 0.556;
		right:@bw * 0.14;
		bottom:@bw * 0.028;
		transform-style: preserve-3d;
		animation:time4 7s linear infinite both;
	}
	.container3_3d {
		position:absolute;
		width:@bw * 0.556;
		height:@bw * 0.556;
		right:@bw * 0.07;
		bottom: -@bw * 0.07;
		transform-style:preserve-3d;
		animation: time5 7s linear infinite both;
	}
	.small_top {
		transform:rotateX(90deg) translateZ(@bw * 0.278)
	}
	.small_left {
		transform:rotateY(-90deg) translateZ(@bw * 0.278)
	}
	.small_right {
		transform:rotateY(90deg) translateZ(@bw * 0.278)
	}
	.small_font {
		transform:translateZ(@bw * 0.278)
	}
	.small_after {
		transform:rotateX(180deg) translateZ(@bw * 0.278)
	}
	.small_bottom {
		transform:rotateX(-90deg) translateZ(@bw * 0.278)
	}
	.top,.userImg,.bottom,.front {
		position:absolute;
		width:100%;
		height:100%;
		/*padding:@bw * 0.008@bw * 0.0;*/
	}
	.top1 {
		transform:rotateX(-90deg) translateZ(-@bw * 0.46);
	}
	.top2 {
		transform:rotateX(-90deg) translate3d(0,@bw * 1.0,-@bw * 0.46);
	}
	.top3 {
		transform:rotateX(-90deg) translate3d(0,@bw * 2.0,-@bw * 0.46);
	}
	.top4 {
		transform:rotateX(-90deg) translate3d(0,@bw * 2.8,-@bw * 0.46);
	}
	.top5 {
		transform:rotateX(-90deg) translate3d(0,@bw * 4.0,-@bw * 0.46);
	}
	.top {
		background:no-repeat center center;
		background-size:cover;
		/* background-image:url(images/qixi/qixi_changlang_top.jpg) */
	}
	.userImg_left1 {
		transform:rotateY(90deg) translateZ(-@bw * 0.46);
	}
	.userImg_left2 {
		transform:rotateY(90deg) translate3d(@bw * 1.0,0,-@bw * 0.46);
	}
	.userImg_left3 {
		transform:rotateY(90deg) translate3d(@bw * 2.0,0,-@bw * 0.46);
	}
	.userImg_left4 {
		transform:rotateY(90deg) translate3d(@bw * 2.8,0,-@bw * 0.46);
	}
	.userImg_left5 {
		transform:rotateY(90deg) translate3d(@bw * 4.0,0,-@bw * 0.46);
	}
	.userImg_right1 {
		transform:rotateY(-90deg) translateZ(-@bw * 0.46);
	}
	.userImg_right2 {
		transform:rotateY(-90deg) translate3d(-@bw * 1.0,0,-@bw * 0.46);
	}
	.userImg_right3 {
		transform:rotateY(-90deg) translate3d(-@bw * 2.0,0,-@bw * 0.46);
	}
	.userImg_right4 {
		transform:rotateY(-90deg) translate3d(-@bw * 2.8,0,-@bw * 0.46);
	}
	.userImg_right5 {
		transform:rotateY(-90deg) translate3d(-@bw * 4.0,0,-@bw * 0.46);
	}
	.userImg {
		// background:no-repeat center center;
		// background-size:cover;
	}
	.bottom1 {
		transform:rotateX(90deg) translateZ(-@bw * 0.46);
	}
	.bottom2 {
		transform:rotateX(90deg) translate3d(0,-@bw * 1.0,-@bw * 0.46);
	}
	.bottom3 {
		transform:rotateX(90deg) translate3d(0,-@bw * 2.0,-@bw * 0.46);
	}
	.bottom4 {
		transform:rotateX(90deg) translate3d(0,-@bw * 2.8,-@bw * 0.46);
	}
	.bottom5 {
		transform:rotateX(90deg) translate3d(0,-@bw * 4.0,-@bw * 0.46);
	}
	.bottom {
		background:no-repeat center center;
		background-size:cover;
	}
	.front {
		animation:time2 7s linear infinite both;
	}
	.front1 {
		transform:translateZ(-@bw * 3.5) scale(.35);
	}
	@keyframes time {
		0% {
			transform:translateZ(@bw * 0.0);
		}
		100% {
			transform:translateZ(@bw * 1.0);
		}
	}@keyframes time2 {
		0% {
			transform:translateZ(-@bw * 2.1);
		}
		100% {
			transform:translateZ(-@bw * 2.696);
		}
	}@keyframes time3 {
		0% {
			transform:translateZ(-@bw * 0.49) rotateX(-250deg) scale(.3) scaleZ(.3);
		}
		100% {
			transform:translateZ(-@bw * 1.24) rotateX(0deg) scale(.3) scaleZ(.3);
		}
	}@keyframes time4 {
		0% {
			transform:translateZ(@bw * 0.416) rotateX(-250deg) scale(.3) scaleZ(.3);
		}
		100% {
			transform:translateZ(-@bw * 0.584) rotateX(0deg) scale(.3) scaleZ(.3);
		}
	}@keyframes time5 {
		0% {
			transform:translateZ(-@bw * 2.056) rotateX(-250deg) scale(.3) scaleZ(.3);
		}
		100% {
			transform:translateZ(-@bw * 3.056) rotateX(0deg) scale(.3) scaleZ(.3);
		}
	}.po {
		position:absolute;
	}

	.anima2 {
	    transform: scale(0.3);
	    animation: scaleHua 3s linear 0.1s infinite alternate;
	}
	.anima3 {
		transform: scale(0.3);
		animation: scaleHua 2.6s linear 0.5s infinite alternate;
	}
	.anima4 {
	    transform: scale(0.3);
	    animation: scaleHua 3.6s linear .8s infinite alternate;
	}
	.anima5 {
	    transform: scale(0.3);
	    animation: scaleHua 2.3s linear .6s infinite alternate;
	}
	.anima6 {
	    transform: scale(0.3);
	    opacity: 0;
	    animation: scaleHua 2.3s linear 3s infinite alternate;
	}
	.anima7 {
	    transform: scale(0.3);
	    opacity: 0;
	    animation: scaleHua 2s linear 3s infinite alternate;
	}
	.anima0 {
	    transform: scale(0.3);
	    opacity: 0;
	    animation: scaleHua 2.5s linear 3s infinite alternate;
	}
	.anima1 {
	    transform: scale(0.3);
	    opacity: 0;
	    animation: scaleHua 2.7s linear 3s infinite alternate;
	}
	@keyframes scaleHua {
		0% {
		    transform: scale(0.3);
		    animation-timing-function: ease-in;
		    opacity: 0.7;
		}
		100% {
		    transform: scale(1);
		    opacity: 1;
		}
	}

	.panorama_flower2_8 {
	    width: 500px;
	    bottom: -130px;
	    transform: translateZ(-1730px);
	}
	.panorama_flower2_7 {
    	transform: translateZ(-1400px);
	}
	.panorama_flower2 {
	    bottom: -110px;
	}
	.panorama_flower2_6 {
	    width: 500px;
	    bottom: -130px;
	    transform: translateZ(-1230px);
	}
	.panorama_flower2_5 {
	    transform: translateZ(-900px);
	}
	.panorama_flower2_4 {
	    width: 500px;
	    bottom: -130px;
	    transform: translateZ(-730px);
	}
	.panorama_flower2_3 {
	    transform: translateZ(-400px);
	}
	.panorama_flower2_2 {
	    width: 500px;
	    bottom: -130px;
	    transform: translateZ(-230px);
	}
	.panorama_flower2_1 {
	    transform: translateZ(100px);
	}

	.page2_lantern1 {
	    -webkit-transform: translateZ(250px);
	}
	.page2_lantern {
	    top: 0;
	    left: 50px;
	}
	.page2_lantern2 {
	    -webkit-transform: translateZ(0);
	}
	.page2_lantern3 {
	    -webkit-transform: translateZ(-250px);
	}
	.page2_lantern4 {
	    -webkit-transform: translateZ(-500px);
	}
	.page2_lantern5 {
	    -webkit-transform: translateZ(-750px);
	}
	.page2_lantern6 {
	    -webkit-transform: translateZ(-1000px);
	}
	.page2_1_2 {
	    -webkit-transform: translateZ(-1450px);
	}
	.page2_1 {
	    width: 85px;
	    left: 140px;
	    bottom: 0;
	}
	.page2_2_2 {
	    -webkit-transform: translateZ(-1600px);
	}
	.page2_2 {
	    width: 80px;
	    right: 100px;
	    bottom: 0;
	}
	.page2_3_2 {
	    -webkit-transform: translateZ(-1300px);
	}
	.page2_3 {
	    width: 85px;
	    right: 100px;
	    bottom: 0;
	}
	.page2_4_2 {
	    -webkit-transform: translateZ(-1350px);
	}
	.page2_4 {
	    width: 100px;
	    left: 150px;
	    bottom: 0;
	}
	.page2_5_2 {
	    -webkit-transform: translateZ(-1220px);
	}
	.page2_5 {
	    width: 85px;
	    left: 100px;
	    bottom: 0;
	}
	.page2_1_3 {
	    -webkit-transform: translateZ(-950px);
	}
	.page2_2_3 {
	    -webkit-transform: translateZ(-1100px);
	}
	.page2_3_3 {
	    -webkit-transform: translateZ(-800px);
	}
	.page2_4_3 {
	    -webkit-transform: translateZ(-850px);
	}
	.page2_5_3 {
	    -webkit-transform: translateZ(-720px);
	}
	.page2_1_4 {
	    -webkit-transform: translateZ(-450px);
	}
	.page2_2_4 {
	    -webkit-transform: translateZ(-600px);
	}
	.page2_3_4 {
	    -webkit-transform: translateZ(-300px);
	}
	.page2_4_4 {
	    -webkit-transform: translateZ(-350px);
	}
	.page2_5_4 {
	    -webkit-transform: translateZ(-220px);
	}
	.page2_1_5 {
	    -webkit-transform: translateZ(50px);
	}
	.page2_2_5 {
	    -webkit-transform: translateZ(-100px);
	}
	.page2_3_5 {
	    -webkit-transform: translateZ(200px);
	}
	.page2_4_5 {
	    -webkit-transform: translateZ(150px);
	}
	.page2_5_5 {
	    -webkit-transform: translateZ(280px);
	}
</style>