<template>
	<div class="box7">
	    <div class="rectangle">
	        <div class="rect_rotate">
	            <div class="rect_box rect_box1">
	                <div class="rect_div rect_div1 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="rect_div rect_div2 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="rect_div rect_div3 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	            </div>
	            <div class="rect_box rect_box2">
	                <div class="rect_div rect_div1 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="rect_div rect_div2 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="rect_div rect_div3 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	            </div>
	            <div class="rect_box rect_box3">
	                <div class="rect_div rect_div1 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="rect_div rect_div2 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	                <div class="rect_div rect_div3 img" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                    <div class="border_img" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'lingxing',
		props: ['mode', 'currentImage'],
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.box_content,.box7 {
		width:100%;
		height:100%;
		position:relative
	}
	.rectangle {
		position:relative;
		width:100%;
		height:100%
	}
	.rectangle_box,.rect_rotate {
		height:100%;
		width:100%;
		overflow:hidden;
		position:relative;
		-webkit-perspective:@bw * 0.694;
		-moz-perspective:@bw * 0.694;
		perspective:@bw * 0.694;
		-webkit-user-select:none;
		-moz-user-select:none
	}
	.rectangle_box {
		background:no-repeat center;
		background-size:100% 100%
	}
	.rect_rotate {
		position:absolute;
		left:0;
		right:0;
		bottom:0;
		top:0
	}
	.flex_box {
		position:fixed;
		width:100%;
		top:0;
		display:flex;
		align-content:center;
		justify-content:space-around;
		font-size:@bw * 0.056;
		z-index:99;
		color:#fff
	}
	.rect,.rect_bot,.rect_top {
		position:absolute;
		left:0;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
		background-position:center center;
		background-repeat:no-repeat
	}
	.rect img {
		display:block;
		width:100%
	}
	.rect {
		width:@bw * 1.0;
		height:@bw * 1.0
	}
	.rect1,.rect_box1 {
		transform:translateZ(-@bw * 0.25) translateY(@bw * 0.444) translateX(@bw * 0.014);
		z-index:30;
		-webkit-animation:rotate_rect 10s linear infinite alternate;
		animation:rotate_rect 10s linear infinite alternate
	}
	.rect2,.rect_box2 {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(-@bw * 0.833);
		z-index:28;
		-webkit-animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate
	}
	.rect3,.rect_box3 {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 0.347);
		z-index:28;
		-webkit-animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate
	}
	.rect_top {
		width:@bw * 1.806;
		height:@bw * 1.208;
		transform-style:preserve-3d;
		background-position:center;
		position:absolute;
		transform-origin:50% 0;
		left:0;
		background-size:100% 100%;
		transform:translateZ(@bw * 0.0) translateY(@bw * 0.0) translateX(-@bw * 0.417) rotateX(-30deg)
	}
	.rect_bot {
		width:@bw * 1.944;
		height:@bw * 0.911;
		transform-style:preserve-3d;
		background-position:center;
		position:absolute;
		transform-origin:50% 100%;
		transform:translateZ(@bw * 0.0) translateY(@bw * 0.893) translateX(-@bw * 0.347) rotateX(25deg)
	}
	.border_img {
		position:absolute;
		background-repeat:no-repeat;
		background-position:center;
		width:100%;
		height:100%;
		background-size:100% 100%
	}
	@keyframes rotate_rectbe {
		0% {
		-webkit-transform:rotateZ(0deg);
		transform:rotateZ(0deg)
	}
	50% {
		-webkit-transform:rotateZ(180deg);
		transform:rotateZ(180deg)
	}
	100% {
		-webkit-transform:rotateZ(360deg);
		transform:rotateZ(360deg)
	}
	}@keyframes rotate_rect {
		0% {
		-webkit-transform:rotateY(0deg);
		transform:rotateY(0deg)
	}
	50% {
		-webkit-transform:rotateY(180deg);
		transform:rotateY(180deg)
	}
	100% {
		-webkit-transform:rotateY(360deg);
		transform:rotateY(360deg)
	}
	}@keyframes rotate_rect2 {
		0% {
		-webkit-transform:rotateY(0deg);
		transform:rotateY(0deg)
	}
	50% {
		-webkit-transform:rotateY(180deg);
		transform:rotateY(180deg)
	}
	100% {
		-webkit-transform:rotateY(360deg);
		transform:rotateY(360deg)
	}
	}@keyframes rotate_rect3 {
		0% {
		-webkit-transform:rotateY(0deg);
		transform:rotateY(0deg)
	}
	50% {
		-webkit-transform:rotateY(180deg);
		transform:rotateY(180deg)
	}
	100% {
		-webkit-transform:rotateY(360deg);
		transform:rotateY(360deg)
	}
	}.rect_box {
		width:@bw * 0.278;
		height:@bw * 0.278;
		position:absolute;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		top:30%
	}
	.rect_box1 {
		left:-9.5%
	}
	.rect_box2 {
		left:50%;
		margin-left:-@bw * 0.139
	}
	.rect_box3 {
		left:82%
	}
	.rect_div {
		width:@bw * 0.417;
		height:@bw * 0.556;
		position:absolute;
		-webkit-transform-style:preserve-3d;
		transform-origin:center center;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background:no-repeat center;
		background-size:100%;
		background-color:#fff;
		box-sizing:border-box
	}
	.rect_div1 {
		transform:rotateY(179deg) translateZ(@bw * 0.114) rotateX(0deg);
		width:@bw * 0.432
	}
	.rect_div2 {
		transform:rotateY(60.5deg) translateZ(@bw * 0.144) rotateX(0deg);
		width:@bw * 0.406
	}
	.rect_div3 {
		transform:rotateY(302deg) translateZ(@bw * 0.117) rotateX(0);
		width:@bw * 0.432
	}

</style>
<!-- <template>
	<div class="model-page">
	    <div class="rectangle">
	        <div class="rectangle_box" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div>
	        <div class="rect_rotate">
	            <div class="rect_box rect_box1">
	                <div class="rect_div rect_div1 img" @click="wxPreview" :style="getStyle(1)">
	                    <div class="rect_photo rect_180 bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img rect_180" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="rect_div rect_div2 img" @click="wxPreview" :style="getStyle(2)">
	                    <div class="rect_photo bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="rect_div rect_div3 img" @click="wxPreview" :style="getStyle(3)">
	                    <div class="rect_photo rect_180 bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img rect_180" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	            </div>
	            <div class="rect_box rect_box2">
	                <div class="rect_div rect_div1 img" @click="wxPreview" :style="getStyle(1)">
	                    <div class="rect_photo rect_180 bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img rect_180" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="rect_div rect_div2 img" @click="wxPreview" :style="getStyle(2)">
	                    <div class="rect_photo bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="rect_div rect_div3 img" @click="wxPreview" :style="getStyle(3)">
	                    <div class="rect_photo rect_180 bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img rect_180" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	            </div>
	            <div class="rect_box rect_box3">
	                <div class="rect_div rect_div1 img" @click="wxPreview" :style="getStyle(1)">
	                    <div class="rect_photo rect_180 bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img rect_180" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="rect_div rect_div2 img" @click="wxPreview" :style="getStyle(2)">
	                    <div class="rect_photo bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="rect_div rect_div3 img" @click="wxPreview" :style="getStyle(3)">
	                    <div class="rect_photo rect_180 bg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                    <div class="border_img rect_180" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'lingxing',
		props: ['mode', 'currentImage', 'showStatus'],
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
			getStyle (idx) {
				let twidth, theight, standsize;

				if (idx == 1 || idx == 3) {
					standsize = [216, 278];
				} else if (idx == 2) {
					standsize = [203, 278];
				}

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], standsize, 'widthFix');
					twidth = tsize[0];
					theight = tsize[1];
				} else {
					twidth = standsize[0];
					theight = standsize[1];
				}

				return {
					width: twidth + 'px',
					height: theight + 'px'
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.model-page {
		width:100%;
		height:100%;
		position:relative;
	}
	.rectangle {
		position:relative;
		width:100%;
		height:100%;
	}
	.rectangle_box, .rect_rotate {
		overflow:hidden;
		position:absolute;
		perspective:@bw * 0.694;
		perspective:@bw * 0.694;
		user-select:none;
	}
	.rect_rotate {
		height:100%;
		width:100%;
	}
	.rectangle_box {
		height:100%;
		width:@bw * 1.778;
		animation: lingxing-translateBg 10s both;
	}
	@keyframes lingxing-translateBg {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-@bw * 0.778);
		}
	}
	.rectangle_box {
		background:no-repeat center;
		background-size: cover;
		/*background-size:100% auto;*/
	}
	.rect_rotate {
		position:absolute;
		left:0;
		right:0;
		bottom:0;
		top:0;
	}
	.flex_box {
		position:fixed;
		width:100%;
		top:0;
		left:0;
		display:flex;
		align-content:center;
		justify-content:space-around;
		font-size:@bw * 0.056;
		z-index:99;
		color:#fff;
	}
	.rect,.rect_bot,.rect_top {
		position:absolute;
		left:@bw * 0.0;
		transform-style:preserve-3d;
		background-size:cover;
		background-position:center center;
		background-repeat:no-repeat;
	}
	.rect img {
		display:block;
		width:100%;
	}
	.rect {
		width:@bw * 1.0;
		height:@bw * 1.0;
	}
	.rect1,.rect_box1 {
		transform:translateZ(-@bw * 0.25) translateY(@bw * 0.444) translateX(@bw * 0.014);
		z-index:30;
		animation:rotate_rect 10s linear infinite alternate;
		animation:rotate_rect 10s linear infinite alternate;
	}
	.rect2,.rect_box2 {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(-@bw * 0.833);
		z-index:28;
		animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.rect3,.rect_box3 {
		transform:translateZ(-@bw * 1.333) translateY(@bw * 0.292) translateX(@bw * 0.347);
		z-index:28;
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	/* .rect_bot {
		transform:translateZ(@bw * 0.403) translateY(@bw * 0.611) translateX(@bw * 0.068) rotateX(51deg);
		background-position:center;
		width:@bw * 0.833;
		height:@bw * 0.972;
	}
	.rect_top {
		transform:translateZ(@bw * 0.224) translateY(@bw * 0.375) translateX(@bw * 0.093) rotateX(-26deg);
		width:@bw * 0.833;
		height:@bw * 0.576;
		background-position:center;
		background-size:100% 100%;
	}
	*/
	.rect_top {
		width:@bw * 1.806;
		height:@bw * 1.208;
		transform-style:preserve-3d;
		background-position:center;
		position:absolute;
		transform-origin:50% 0%;
		left:0;
		background-size:100% 100%;
		transform:translateZ(@bw * 0.0) translateY(@bw * 0.0) translateX(-@bw * 0.417) rotateX(-30deg);
	}
	.rect_bot {
		width:@bw * 1.944;
		height:@bw * 0.911;
		transform-style:preserve-3d;
		background-position:center;
		position:absolute;
		transform-origin:50% 100%;
		transform:translateZ(@bw * 0.0) translateY(@bw * 0.893) translateX(-@bw * 0.347) rotateX(25deg);
	}
	.border_img {
		position:absolute;
		background-repeat:no-repeat;
		background-position:center;
		width:100%;
		height:100%;
		background-size:100% 100%;
		z-index: 2;
	}
	.rect_photo {
		position: absolute;
		width:100%;
		height:100%;
		z-index: 1;
	}
	.rect_180 {
		transform: rotateY(180deg);
	}
	@keyframes rotate_rectbe {
		0% {
		transform:rotateZ(0deg);
		transform:rotateZ(0deg);
	}
	50% {
		transform:rotateZ(180deg);
		transform:rotateZ(180deg);
	}
	100% {
		transform:rotateZ(360deg);
		transform:rotateZ(360deg);
	}
	}@keyframes rotate_rect {
		0% {
		transform:rotateY(0deg);
		transform:rotateY(0deg);
	}
	50% {
		transform:rotateY(180deg);
		transform:rotateY(180deg);
	}
	100% {
		transform:rotateY(360deg);
		transform:rotateY(360deg);
	}
	}@keyframes rotate_rect2 {
		0% {
		transform:rotateY(0deg);
		transform:rotateY(0deg);
	}
	50% {
		transform:rotateY(180deg);
		transform:rotateY(180deg);
	}
	100% {
		transform:rotateY(360deg);
		transform:rotateY(360deg);
	}
	}@keyframes rotate_rect3 {
		0% {
		transform:rotateY(0deg);
		transform:rotateY(0deg);
	}
	50% {
		transform:rotateY(180deg);
		transform:rotateY(180deg);
	}
	100% {
		transform:rotateY(360deg);
		transform:rotateY(360deg);
	}
	}
	.rect_box {
		width:@bw * 0.194;
		height:@bw * 0.194;
		top: 50%;
		left: 0;
		margin-top: -@bw * 0.097;
		position:absolute;
		transform-style:preserve-3d;
		display: flex;
		justify-content: center;
		align-items: center;
		// top:30%;
	}
	.rect_box1 {
		left:-9.5%;
	}
	.rect_box2 {
		left:50%;
		margin-left:-@bw * 0.139;
	}
	.rect_box3 {
		left:82%;
	}
	.rect_div {
		// width:@bw * 0.417;
		height:@bw * 0.556;
		position:absolute;
		transform-style:preserve-3d;
		transform-origin:center center;
		background:no-repeat center;
		background-size:100%;
		background-color:#fff;
		box-sizing:border-box;
	}
	.rect_div1 {
		transform:rotateY(0deg) translateZ(-@bw * 0.107);
		width:@bw * 0.432;
	}
	.rect_div2 {
		transform:rotateY(60deg) translateZ(@bw * 0.134);
		width:@bw * 0.406;
	}
	.rect_div3 {
		transform:rotateY(123deg) translateZ(-@bw * 0.124);
		width:@bw * 0.432;
	}
</style> -->