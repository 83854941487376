class stGlobalData {
  constructor(util) {
    this.$util = util;
    this.globalData = {};
    this.globalOpen = {};
  }
  set = (key, val) => {
    this.globalData[key] = val;
  }
  get = (key, defval) => {
    let val = this.globalData[key];
    return val === undefined ? defval : val;
  }
  setOpen = (key, val) => {
    this.globalOpen[key] = val;
  }
  getOpen = (key, defval) => {
    let val = this.globalOpen[key];
    return val === undefined ? defval : val;
  }
  resetOpen () {
    this.globalOpen = {};
  }
  // getter方法获取当下store的state对象
  get storeState () {
    return this.$util.$store.state;
  }
}

export default {
  stGlobalData
}