<template>
	<div class="fanshu-bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
	    <div class="fanshu-book" :style="{backgroundImage: 'url('+mode[1]+')'}">
	        <div class="po fanshu-page" :style="getStyle">
	            <div class="fanshu-sonpage fanshu-top" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="fanshu-sonpage fanshu-bottom" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="po fanshu-page fanshu-animation-3" :style="getStyle">
	            <div class="fanshu-sonpage fanshu-top" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="fanshu-sonpage fanshu-bottom" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="po fanshu-page fanshu-animation-2" :style="getStyle">
	            <div class="fanshu-sonpage fanshu-top" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="fanshu-sonpage fanshu-bottom" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="po fanshu-page fanshu-animation-1" :style="getStyle">
	            <div class="fanshu-sonpage fanshu-top" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="fanshu-sonpage fanshu-bottom" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div class="po fanshu-page fanshu-leftPage" :style="getStyle">
	            <div class="fanshu-sonpage fanshu-top" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	            <div class="fanshu-sonpage fanshu-bottom" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" @click="wxPreview"></div>
	        </div>
	        <div id="starCon">
	            <div class="star"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'fanshu',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
        computed: {
            getStyle () {
                let twidth, theight;
                
                if (this.showStatus) {
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [220, 500], 'widthFix');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = 220;
                    theight = 300;
                }

                return {
                    width: twidth + 'px',
                    height: theight + 'px'
                }
            }
        },
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.fanshu-bg,.fanshu-wrap_bg {
        position:absolute;
        width:100%;
        height:100%;
        perspective:@bw * 1.389;
        background-size:cover;
    }
    .fanshu-imglit {
        width:25%;
        float:left;
        height:50%;
        background-size:100% 100%;
    }
    .fanshu-book_bg {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        margin:auto;
        width:100%;
        height:@bw * 0.667;
        transform:rotateZ(-0.6deg) rotateX(10deg);
        background-size:100%;
    }
    .fanshu-book {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        margin:auto;
        width:100%;
        height:@bw * 0.667;
        transform:rotateX(10deg);
        transform-style:preserve-3d;
        background-size:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fanshu-sonpage {
        position:absolute;
        top:0;
        left:50%;
        width: 100%;
        height: 100%;
        // width:@bw * 0.44;
        // height:@bw * 0.593;
        border:@bw * 0.014 solid #fff;
        border-radius:0 @bw * 0.028 @bw * 0.028 0;
        overflow:hidden;
        background-position: center 15%!important;
    }
    .fanshu-page {
        // margin-top:@bw * 0.025;
        // margin-left:-@bw * 0.007;
        transform-origin:50% 50%;
        transform:rotateY(0deg) translateZ(@bw * 0.004);
        transform-style:preserve-3d;
    }
    @keyframes fanshu-flipBook1 {
        0% {
	        transform:rotateY(0deg);
	    }
	    100% {
	        transform:rotateY(-180deg);
	    }
    }
    .fanshu-animation-1 {
        animation:fanshu-flipBook1 2s forwards;
    }
    .fanshu-animation-2 {
        animation:fanshu-flipBook1 2s 3s forwards;
    }
    .fanshu-animation-3 {
        animation:fanshu-flipBook1 2s 6s forwards;
    }
    .fanshu-animation-4 {
        animation:fanshu-flipBook1 2s 9s forwards;
    }
    .fanshu-top {
        background-size:cover;
    }
    .fanshu-bottom {
        transform:translateZ(-@bw * 0.001);
        background-size:cover;
    }
    .fanshu-leftPage {
        transform:rotateY(-180deg);
    }
    @keyframes fanshu-kf_star_move {
	        0% {
	        opacity:1;
	        -webkit-transform:translate3d(0,0,@bw * 0.139) scale3d(1,1,1);
	    }
	    100% {
	        opacity:0.5;
	        -webkit-transform:translate3d(0,0,@bw * 1.111) scale3d(0.3,0.3,0.3);
	    }
    }
    .star {
        position:absolute;
        width:@bw * 0.139;
        height:@bw * 0.139;
        background-size:100%;
        animation-name:fanshu-kf_star_move;
        animation-iteration-count:infinite;
    }
</style>