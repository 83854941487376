<template>
	<div class="fadescaleout" :style="getStyle">
		<template v-if="typeof mode[0] == 'object'">
			<gif :mode="mode[0]"></gif>
		</template>
		<span class="fadescaleout-title">{{title}}</span>
	</div>
</template>

<script>
	export default {
		name: 'fadescaleout',
		props: ['title', 'mode'],
		components: {},
		computed: {
			getStyle () {
				if (this.$util.typeOf(this.mode[0]) == 'string') {
					return {backgroundImage: 'url('+ this.mode[0] +')'};
				} else {
					return {};
				}
			}
		},
		data () {
			return {}
		},
		created () {}
	}
</script>

<style scoped lang="less">
	.fadescaleout {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: 999;
		animation: fadescaleout 6s ease-in-out 1s 1 normal forwards;
		transform-style: preserve-3d;
		opacity: 1;
		// transform-origin: 0% 50%;
	}
	// @keyframes fadescaleout {
	//   	0% {
	//     	transform: translate3d(0px, 0px, 0px) rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
	//   	}

	// 	50% {
	// 		transform: translate3d(0px, 0px, 0px) rotateX(-0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);
	// 	}

	//   	100% {
	// 		transform: translate3d(0px, 0px, 0px) rotateX(-0deg) rotateY(-90deg) rotateZ(0deg) scale3d(1, 1, 1);
	//   	}
	// }
	@keyframes fadescaleout_title {
		from {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}
		to {
			opacity: 0;
			transform: scale(1.5);
		}
	}
	@keyframes fadescaleout {
		from {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}
		to {
			opacity: 0;
			transform: scale(1.2);
		}
	}
	.fadescaleout-title {
		position: absolute;
		width: @bw;
		height: @bw;
		text-align: center;
		line-height: @bw;
		top: 50%;
		margin-top: -@bw/2;

		display: table-cell;
	    font-size: 30px;
	    font-weight: bold;
	    letter-spacing: 2px;
	    color: #ff60f2;
	    text-shadow: 0 0 3px #ffffff, 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 15px #ffffff;

	    animation: fadescaleout_title 2s ease-in-out 4s 1 normal forwards;
	}
</style>