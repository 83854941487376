var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"baobao3 page po bg",style:({backgroundImage: 'url('+ _vm.mode[0] +')'})},[_c('div',{staticClass:"baobao3_div1 po",class:{
    	'none': _vm.div1 == 2,
    	'baobao3_hippo_in': _vm.div1 == 0,
    	'baobao3_hippo_out': _vm.div1 == 1
    }},[_c('div',{staticClass:"baobao3_hippo_1 po"},[_c('img',{staticClass:"baobao3_pillar po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/shuzhi.png"}}),_c('div',{staticClass:"baobao3_div page po",style:({backgroundImage: 'url('+_vm.currentImage['im']+')'}),on:{"click":_vm.wxPreview}}),_c('img',{staticClass:"baobao3_border_v po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/frame_2_h.png"}})])]),_c('div',{staticClass:"baobao3_div2 po",class:{
    	'none': _vm.div2 == 2,
    	'baobao3_sheep_up': _vm.div2 == 0,
    	'baobao3_sheep_down': _vm.div2 == 1
    }},[_c('div',{staticClass:"baobao3_sheep_1 page po"},[_c('img',{staticClass:"baobao3_pillar po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/shuzhi.png"}}),_c('div',{staticClass:"baobao3_div page po",style:({backgroundImage: 'url('+_vm.currentImage['im']+')'}),on:{"click":_vm.wxPreview}}),_c('img',{staticClass:"baobao3_border_v po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/frame_2_h.png"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }