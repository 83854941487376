<template>
	<!-- <div class="zan" @click="onZanClick" :class="{'zan-overflow': !flowering, 'in': isZanIn, 'out': !isZanIn}">
		<span :class="{active: !clickAble}">{{num}}</span>
		<img :class="{'flower-active': !clickAble}" src="http://resali.lititutu.cn/icons/app/flower.png">
	</div>	 -->
	<!-- :class="{'on': !clickAble, 'zan-has-usertext': ($store.state.hasUsertext && $store.state.hasUsertext > 0)}" -->
	<div class="zan-btn po"
		:class="{'on': !clickAble, 'zan-has-usertext': $store.state.wordCur.wordStatus > 0}"
		@click="onZanClick">
		<img class="zan-icon zan-off" src="http://resali.lititutu.cn/tuku/prod/1af03688867ff0f1b546af980bdf0bd0.png">
		<img class="zan-icon zan-on" src="http://resali.lititutu.cn/tuku/prod/593fff8fb37f6874bfb73f0280436d57.png">

	    <img class="zan-min-icon zan-min-icon_1" src="http://resali.lititutu.cn/tuku/prod/593fff8fb37f6874bfb73f0280436d57.png">
	    <img class="zan-min-icon zan-min-icon_2" src="http://resali.lititutu.cn/tuku/prod/593fff8fb37f6874bfb73f0280436d57.png">
	    <img class="zan-min-icon zan-min-icon_3" src="http://resali.lititutu.cn/tuku/prod/593fff8fb37f6874bfb73f0280436d57.png">

		<span class="zan-num">{{$store.state.config.zan_num > 0 ? $store.state.config.zan_num : '送花'}}</span>

		<img
			v-if="showSongHua"
			class="songhua"
			:class="$store.state.wordCur.wordStatus?'songhua-hasusertext':''"
			src="http://resali.lititutu.cn/tuku/prod/4dd9d48551cd89ebb9592cfb05f8fb96.png" />

		<st-open-tag @clickHandle="onZanClick" tag="subscribe" :channel_ids="[1,2,3,5,6]" html='<div style="width: 75px; height: 75px;border-radius: 50%;"></div>'></st-open-tag>
	</div>
</template>

<script>
	import StOpenTag from '@/components/st_comp/StOpenTag';
	// import U from '@/util'

	export default {
		name: 'Zan',
		components: {
			StOpenTag
		},
		data () {
			return {
				num: 0,
				clickAble: true,
				showSongHua: false
			}
		},
		computed: {},
		mounted () {
			// let _this = this;
			// this.$util.rget('get_num', {
			// 	'key': this.$route.query.key,
			// 	'field': 'default'
			// })
			// .then((response) => {
			// 	// if (response) {
			// 	//  	this.num = response;
			// 	// } else {
			// 	// 	this.num = '送花';
			// 	// }
			// 	console.log("response")
			// 	_this.$store.commit("setNum", {
			// 		'type': 'zan',
			// 		'num': response,
			// 	})
			// });
		},
		methods: {
			onZanClick () {
				let _this = this;
				if (this.clickAble) {

					this.$store.dispatch('setNum', {
						type: 'zan',
						key: this.$route.query.key
					}).then(num => {
						this.clickAble = false;
					})
					this.showSongHua = true;
				} else {
					if(this.showSongHua){
						this.showSongHua = false;
						setTimeout(() => {
							_this.showSongHua = true
						}, 500);
					}
					// this.$dialog.alert({
					// 	'title': '提示',
					// 	'message': '您已经送过玫瑰了，感谢您的玫瑰'
					// })
				}

				// let _this = this;
				// this.$mta.click('btn_zan');
				// if (this.clickAble) {
				// 	this.clickAble = false;
				// 	this.$util.setNum(this.$route.query.key).then((response) => {
				// 		// this.num = response;
				// 		_this.$store.commit("setNum", {
				// 			'type': 'zan',
				// 			'num': response,
				// 		})
				// 	});
				// } else {
				// 	this.$dialog.alert({
				// 		'title': '提示',
				// 		'message': '您已经送过玫瑰了，感谢您的玫瑰'
				// 	})
				// }
			}
		}
	}
</script>

<style scoped lang="less">
	.zan-btn {
		width: 75px;
		height: 75px;
		background-color: rgba(0, 0, 0, 0.64) !important;
		border-radius: 50%;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
		right: 12px;
		bottom: 150px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 6;
	}
	.zan-has-usertext {
		bottom: 200px!important;
	}
	.zan-icon {
		position: relative;
		width: 24px;
		height: 44px;
	}
    .zan-btn>.zan-off {
        transform-origin: 50% 50%;
        transform: scale(.8);
    }
	.zan-num {
		color: white;
		font-size: 16px;
		font-family: Heiti;
	}
	.zan-btn.on > .zan-on {
		display: block!important;
	}
	.zan-btn.on > .zan-off {
		display: none;
	}
    .zan-btn.on > .zan-min-icon {
        display: none;
    }
	.zan-on {
		display: none;
        animation: zan_on .2s ease-in-out 0s both;
	}
    @keyframes zan_on {
        0% {
            transform: none;
        }
        5% {
            transform: scale(.8);
        }
        70% {
            transform: scale(2);
        }
        100% {
            transform: none;
        }
    }
    .zan-min-icon {
        position: absolute;
        width: 20px;
        top: 10px;
        opacity: 0;
		pointer-events: none;
    }
    .zan-min-icon_1 {
        left: 24px;
        animation: zan_up 1.8s ease-out both 1s infinite;
    }
    .zan-min-icon_2 {
        animation: zan_up 1.8s ease-out both 1.6s infinite;
    }
    .zan-min-icon_3 {
        right: 24px;
        animation: zan_up 1.8s ease-out both 2.4s infinite;
    }
    @keyframes zan_up {
        from {
            transform: none;
            opacity: 0;
        }
        5% {
            opacity: .8;
        }
        to {
            transform: translateY(-80px);
            opacity: 0;
        }
	}
	
	.songhua {
		z-index: 10;
		position: absolute;
		bottom: -90px;
		right: 170px;
		margin: 0 auto;
		width: 160px;
		opacity: 0;
		animation: songhuaani 3s both;
	}

	.songhua-hasusertext{
		bottom: -74px;
	}

	@keyframes songhuaani {
		0% {
			opacity: 0.8;
		}
		30%,
		70% {
			opacity: 1;
			transform: translateY(-200px) translateX(0) scale(1);
		}
		90% {
			opacity: 1;
			transform: translateY(10px) translateX(210px) scale(0.125);
		}
		100% {
			opacity: 0;
			transform: translateY(10px) translateX(210px) scale(0.125);
		}
	}

</style>