<template>
	<div class="duolifangzhuan poc">
	<!-- <div class="duolifangzhuan poc bg" :style="{backgroundImage: 'url('+mode[0]+')'}"> -->
	    <div class="duolifangzhuan_1 poc">
	        <div class="b_duolifangzhuan_2_1 perspective poc">
	            <div class="duolifangzhuan_2_1 preserve3d poc duolifangzhuan_n" :style="getStyleN">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	        </div>
	        <div class="b_duolifangzhuan_2_2 perspective poc">
	            <div class="duolifangzhuan_2_2 preserve3d poc duolifangzhuan_n" :style="getStyleN">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	        </div>
	        <div class="b_duolifangzhuan_2_3 perspective poc">
	            <div class="duolifangzhuan_2_3 preserve3d poc duolifangzhuan_n" :style="getStyleN">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	            <div class="duolifangzhuan_2_4 preserve3d poc duolifangzhuan_n" :style="getStyleN">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	        </div>
	        <div class="b_duolifangzhuan_1_2 perspective poc">
	            <div class="duolifangzhuan_1_2 preserve3d poc duolifangzhuan_m" :style="getStyleM">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	            <div class="duolifangzhuan_1_3 preserve3d poc duolifangzhuan_m" :style="getStyleM">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	        </div>
	        <div class="b_duolifangzhuan_1_1 perspective poc">
	            <div class="duolifangzhuan_1_1 preserve3d poc duolifangzhuan_m" :style="getStyleM">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	            <div class="duolifangzhuan_1_4 preserve3d poc duolifangzhuan_m" :style="getStyleM">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	        </div>
	        <div class="b_duolifangzhuan_0 perspective poc">
	            <div class="duolifangzhuan_0 preserve3d poc duolifangzhuan_p" :style="getStyleP">
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	                <div class="preserve3d _page3 div bg poc" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            </div>
	        </div>
	    </div>
    </div>
</template>

<script>
	export default {
		name: 'duolifangzhuan',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
		},
		computed: {
			getStyleP (type) {

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [262, 345], 'widthFix');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px'
					}
				} else {
					return {
						width: '262px',
						height: '345px'
					}
				}
			},
			getStyleN (type) {

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [99, 156], 'widthFix');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px'
					}
				} else {
					return {
						width: '99px',
						height: '156px'
					}
				}
			},
			getStyleM (type) {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [144, 200], 'widthFix');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px'
					}
				} else {
					return {
						width: '144px',
						height: '200px'
					}
				}
			}
		}
	}
</script>

<style scoped>
	.b_duolifangzhuan_0 {
		animation: fadeIn 2s 0s both;
	}
	.b_duolifangzhuan_1_1, .b_duolifangzhuan_1_2 {
		animation: fadeIn 2s 1s both;
	}
	.b_duolifangzhuan_2_1, .b_duolifangzhuan_2_2, .b_duolifangzhuan_2_3 {
		animation: fadeIn 2s 2s both;
	}
	.duolifangzhuan_n {
		/*width: 99px;
		height: 156.097px;*/
	}
	.duolifangzhuan_m {
		/*width: 144px;
		height: 199.777px;*/
	}
	.duolifangzhuan_p {
		/*width: 262px;*/
		/*height: 345.428px;*/
	}
	.duolifangzhuan .perspective {
		perspective: 448px;
	}
	.b_duolifangzhuan_2_1 {
	    bottom: 27.5%;
	}
	.b_duolifangzhuan_2_2 {
	    top: 27.5%;
	}
	.b_duolifangzhuan_1_2 {
	    bottom: 32%;
	}
	.b_duolifangzhuan_1_1 {
	    top: 32%;
	}
	.duolifangzhuan>div>div>div {
	    animation: rote1 10s linear infinite both;
	}
	@keyframes rote1 {
		0% {
		    transform: rotateY(0deg);
		}
		100% {
		    transform: rotateY(360deg);
		}
	}
	.duolifangzhuan_0, .duolifangzhuan_1_1, .duolifangzhuan_1_2, .duolifangzhuan_1_3, .duolifangzhuan_1_4, .duolifangzhuan_2_1, .duolifangzhuan_2_2, .duolifangzhuan_2_3, .duolifangzhuan_2_4 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	}
	.duolifangzhuan_2_1 {
	    left: 50%;
	    margin-left: -49.5px;
	    right: 0;
	}
	.duolifangzhuan_2_2 {
	    left: 50%;
	    margin-left: -49.5px;
	    right: 0;
	}
	.duolifangzhuan_2_3 {
	    left: 0;
	}
	.duolifangzhuan_2_4 {
	    right: 0;
	}
	.duolifangzhuan_1_1, .duolifangzhuan_1_2 {
	    right: 0px;
	}
	.duolifangzhuan_1_3, .duolifangzhuan_1_4 {
	    left: 0px;
	}
	.duolifangzhuan_0 {
	    left: 0;
	    right: 0;
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(1), .b_duolifangzhuan_2_2 .div:nth-of-type(1), .b_duolifangzhuan_2_3 .div:nth-of-type(1) {
	    transform: rotateY(90deg) translateZ(49px);
	}
	._page3 {
	    border: 3px solid #FFFFFF;
	    background-color: #eee;
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(2), .b_duolifangzhuan_2_2 .div:nth-of-type(2), .b_duolifangzhuan_2_3 .div:nth-of-type(2) {
	    transform: rotateY(180deg) translateZ(49px);
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(3), .b_duolifangzhuan_2_2 .div:nth-of-type(3), .b_duolifangzhuan_2_3 .div:nth-of-type(3) {
	    transform: rotateY(270deg) translateZ(49px);
	}
	.b_duolifangzhuan_2_1 .div:nth-of-type(4), .b_duolifangzhuan_2_2 .div:nth-of-type(4), .b_duolifangzhuan_2_3 .div:nth-of-type(4) {
	    transform: translateZ(49px);
	}
	/*.duolifangzhuan_img {
	    margin: auto;
	    left: 0;
	    right: 0;
	    bottom: 70px;
	    transform-origin: bottom;
	    animation: page1_img 1.5s ease-in-out infinite alternate both;
	}
	@keyframes page1_img {
		0% {
		    transform: translateY(0) scale(0.8);
		}
		100% {
		    transform: translateY(10px) scale(0.8);
		}
	}*/
	.b_duolifangzhuan_1_1 .div:nth-of-type(4), .b_duolifangzhuan_1_2 .div:nth-of-type(4) {
	    transform: translateZ(72px);
	}
	.b_duolifangzhuan_1_1 .div:nth-of-type(3), .b_duolifangzhuan_1_2 .div:nth-of-type(3) {
	    transform: rotateY(270deg) translateZ(72px);
	}
	.b_duolifangzhuan_1_1 .div:nth-of-type(2), .b_duolifangzhuan_1_2 .div:nth-of-type(2) {
	    transform: rotateY(180deg) translateZ(72px);
	}
	.b_duolifangzhuan_1_1 .div:nth-of-type(1), .b_duolifangzhuan_1_2 .div:nth-of-type(1) {
	    transform: rotateY(90deg) translateZ(72px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(1) {
	    transform: rotateY(90deg) translateZ(135px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(2) {
	    transform: rotateY(180deg) translateZ(135px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(3) {
		transform: rotateY(270deg) translateZ(135px);
	}
	.b_duolifangzhuan_0 .div:nth-of-type(4) {
	    transform: translateZ(135px);
	}
	.b_duolifangzhuan_0 ._page3 {
	    border: 4px solid #FFFFFF;
	}
	.div {
	    /*background-size: cover;
	    background-position: center 10%;*/
	}
</style>