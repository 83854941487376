<template>
	<!-- <transition name='prelude-model'> -->
		<!-- <div ref="prelude" class="prelude" v-show="isPreludeShow"> -->
		<div ref="prelude" class="prelude" :class="{fadeOut: !isPreludeShow}" v-show="isPreludeExist">
			<template v-if="mode['gif']">
				<div class="prelude-gif">
					<gif :mode="mode['gif']"></gif>
				</div>
				<span class="prelude-title" :style="titleStyle">
					{{mode['title']}}
				</span>
			</template>
			<template v-else-if="mode['component']">
				<component :is="mode['component']" :title="mode['title']" :mode="mode['mode']"></component>
			</template>
			<template v-else-if="mode['elems']">
				<template v-for="mode in mode['elems']">
					<elem :mode="mode"></elem>
				</template>
				<span class="prelude-title" :style="titleStyle">
					{{mode['title']}}
				</span>
			</template>
			<template v-else> <!-- 若没有对应的序幕，则使用默认样式 -->
				<div class="title">
					<div class="title-con">
						<span class="title-tx" :style="titleStyle">
							{{mode['title']}}
						</span>
					</div>
				</div>
			</template>
		</div>
	<!-- </transition> -->
</template>

<script>
	import fadeinblur from '@/components/themes/components/prelude/fadeinblur'
	import fadescaleout from '@/components/themes/components/prelude/fadescaleout'

	export default {
		name: 'prelude',
		props: ['mode'],
		components: {
			fadeinblur,
			fadescaleout
		},
		data () {
			return {
				preludeEvent: null,
				isPreludeShow: false,
				isPreludeExist: true
			}
		},
		computed: {
			titleStyle () {
				let s = {
					color: this.mode['textcolor'] ? this.mode['textcolor'] : '#f30f4a'
				};
				if (this.mode['style']) {
					s = Object.assign(s, this.mode['style']);
				}
				return s;
			}
		},
		watch: {
			mode () {
				this.preludeShow();
			}
		},
		created () {
			this.preludeShow();
		},
		mounted () {
			this.$refs.prelude.addEventListener('touchmove', function(e) {
				e.preventDefault();
			})
		},
		methods: {
			preludeShow () {
				if (this.mode['type'] == 'none') {
					this.isPreludeShow = false;
					this.isPreludeExist = false;
					this.$emit('preluded');
				} else {
					this.isPreludeShow = true;
					this.isPreludeExist = true;
					clearTimeout(this.preludeEvent);

					this.preludeEvent = setTimeout(() => {
						this.isPreludeShow = false;
						this.$emit('preluded');
					}, this.mode['duration']*1000);
				}
			}
		}
	}
</script>

<style scoped>
	/*.prelude-model-enter-active, .prelude-model-leave-active {
		transition: opacity 1s;
	}
	.prelude-model-enter, .prelude-model-leave-to {
		opacity: 0;
	}*/
	.prelude, .prelude-gif {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		pointer-events: none;
	}
	.prelude-gif {
		/*background-color: rgb(39, 40, 44);*/
	}

	.title {
		position: absolute;
		width: 416px;
		height: 250px;
		top: 50%;
		margin-top: -125px;
		left: 50%;
		margin-left: -208px;
		background-color: rgba(255, 255, 255, 0.3);
		z-index: 99;
		/*background-color: rgba(0, 0, 0, 0.2);*/
	}
	.title-con {
		position: absolute;
		width: 396px;
		height: 230px;
		top: 50%;
		left: 50%;
		margin-top: -115px;
		margin-left: -198px;
		background-color: rgba(255, 255, 255, 0.9);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.title-tx {
		font-size: 30px;
    	padding: 0 18px;
    	/*text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.47);*/
    	text-align: center;
    	line-height: 40px;
    	font-weight: bold;
		overflow-wrap: anywhere;
	}

	.prelude-title {
		position: absolute;
		z-index: 10;
		font-size: 35px;
		width: 348px;
		height: 695px;
		left: 50%;
		margin-left: -174px;
		top: 50%;
		margin-top: -348px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		text-shadow: 0 0 5px #FFFFFF, 0 0 5px #FFFFFF, 0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 10px #FFFFFF, 0 0 10px #FFFFFF, 0 0 15px #FFFFFF, 0 0 15px #FFFFFF, 0 0 15px #FFFFFF;
		line-height: 50px;
		letter-spacing: 2px;
		text-align: center;
	}
	
</style>




