<template>
	<div class="square2_box shuibo_bg">
	    <div class="square2">
	        <div class="lit_square_box square_box1">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box2">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box3">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box4">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box5">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box6">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box7">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box8">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box9">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box10">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box11">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box12">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	        <div class="lit_square_box square_box13">
	            <div class="face face1 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face2 shuibo_bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            <div class="face face3 user_img">
	            	<div class="face_bg bg"  :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face4 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face5 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	            <div class="face face6 user_img">
	            	<div class="face_bg bg" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
	            </div>
	        </div>
	    </div>
	    <div class="whater">
	        <img class="shuibo1_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo1_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo1_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <div class="whater whater2">
	        <img class="shuibo2_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <div class="whater whater3">
	        <img class="shuibo2_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <div class="whater whater4">
	        <img class="shuibo2_1 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_2 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}">
	        <img class="shuibo2_3 shuibo_quanquan" :style="{backgroundImage: 'url('+ mode[2] +')'}"></div>
	    <!--<canvas id="smokeCanvas" class="can_ani"></canvas>-->
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'shuibo',
        extends: BaseElem,
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentPhoto);
			}
		}
	}
</script>

<style scoped>
	.square2 {
		top:-22%;
		height:100%;
		width:100%;
		z-index:3;
		overflow:hidden;
		position:relative;
		perspective:347.00px;
		-moz-perspective:347.00px;
		perspective:347.00px;
		user-select:none;
		-moz-user-select:none;
		user-select:none;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		animation:scr 2s 1.1s linear both;
		transform:scale(0);
		transform:scale(0);
		transform-origin:center;
	}
	@keyframes scr {
		0% {
			transform:scale(0);
			transform:scale(0);
		}
		50% {
			transform:scale(0.5);
			transform:scale(0.5);
		}
		100% {
			transform:scale(1);
			transform:scale(1);
		}
	}.square2_box {
		height:100%;
		width:100%;
		overflow:hidden;
		position:relative;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background:no-repeat center;
		background-size: cover;
		/*background-size:100%;*/
	}
	.lit_square_box {
		position:absolute;
		left:0.00px;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
		background-position:center center;
		background-repeat:no-repeat;
		width:250.00px;
		height:250.00px;
	}
	.square_box1 {
		top:56%;
		left:30%;
		transform:translateZ(-104.00px);
		z-index:5;
		animation:rotate_rect1 10s linear infinite alternate;
		animation:rotate_rect1 10s linear infinite alternate;
	}
	.square_box2 {
		top:45%;
		left:-75%;
		transform:translateZ(-819.50px);
		z-index:2;
		animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.square_box3 {
		top:45%;
		left:150%;
		transform:translateZ(-819.50px);
		z-index:2;
		animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.square_box4 {
		top:33%;
		left:45%;
		transform:translateZ(-819.50px);
		z-index:2;
		animation:rotate_rect2 10s linear infinite alternate;
		animation:rotate_rect2 10s linear infinite alternate;
	}
	.square_box5 {
		top:-20%;
		left:267%;
		transform:translateZ(-1715.50px);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box6 {
		top:25%;
		left:-27%;
		transform:translateZ(-1159.50px);
		animation:rotate_rect4 10s linear infinite alternate;
		animation:rotate_rect4 10s linear infinite alternate;
	}
	.square_box7 {
		top:-30%;
		left:-199%;
		transform:translateZ(-1715.50px);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box8 {
		top:25%;
		left:-147%;
		transform:translateZ(-1159.50px);
		animation:rotate_rect4 10s linear infinite alternate;
		animation:rotate_rect4 10s linear infinite alternate;
	}
	.square_box9 {
		top:-12%;
		left:-118%;
		transform:translateZ(-1715.50px);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box10 {
		top:25%;
		left:130%;
		transform:translateZ(-1159.50px);
		animation:rotate_rect4 10s linear infinite alternate;
		animation:rotate_rect4 10s linear infinite alternate;
	}
	.square_box11 {
		top:-31%;
		left:127%;
		transform:translateZ(-1715.50px);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box12 {
		top:-27%;
		left:-56%;
		transform:translateZ(-1715.50px);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	.square_box13 {
		top:-42%;
		left:23%;
		transform:translateZ(-1715.50px);
		animation:rotate_rect3 10s linear infinite alternate;
		animation:rotate_rect3 10s linear infinite alternate;
	}
	@keyframes rotate_rect1 {
		0% {
		transform:translateZ(-104.00px) rotateY(0deg);
		transform:translateZ(-104.00px) rotateY(0deg);
	}
	50% {
		transform:translateZ(-104.00px) rotateY(180deg);
		transform:translateZ(-104.00px) rotateY(180deg);
	}
	100% {
		transform:translateZ(-104.00px) rotateY(360deg);
		transform:translateZ(-104.00px) rotateY(360deg);
	}
	}@keyframes rotate_rect2 {
		0% {
		transform:translateZ(-819.50px) rotateY(0deg);
		transform:translateZ(-819.50px) rotateY(0deg);
	}
	50% {
		transform:translateZ(-819.50px) rotateY(180deg);
		transform:translateZ(-819.50px) rotateY(180deg);
	}
	100% {
		transform:translateZ(-819.50px) rotateY(360deg);
		transform:translateZ(-819.50px) rotateY(360deg);
	}
	}@keyframes rotate_rect3 {
		0% {
		transform:translateZ(-1715.50px) rotateY(0deg);
		transform:translateZ(-1715.50px) rotateY(0deg);
	}
	50% {
		transform:translateZ(-1715.50px) rotateY(180deg);
		transform:translateZ(-1715.50px) rotateY(180deg);
	}
	100% {
		transform:translateZ(-1715.50px) rotateY(360deg);
		transform:translateZ(-1715.50px) rotateY(360deg);
	}
	}@keyframes rotate_rect4 {
		0% {
		transform:translateZ(-1159.50px) rotateY(0deg);
		transform:translateZ(-1159.50px) rotateY(0deg);
	}
	50% {
		transform:translateZ(-1159.50px) rotateY(180deg);
		transform:translateZ(-1159.50px) rotateY(180deg);
	}
	100% {
		transform:translateZ(-1159.50px) rotateY(360deg);
		transform:translateZ(-1159.50px) rotateY(360deg);
	}
	}.lit_square_box .face {
		position:absolute;
		left:0.00px;
		transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
		background-position:center center;
		background-repeat:no-repeat;
		width:250.00px;
		height:250.00px;
		background:no-repeat center;
		background-size:cover
	}
	.face1 {
		transform:rotateX(90deg) translateZ(-166.50px);
		-moz-transform:rotateX(90deg) translateZ(-166.50px);
		transform:rotateX(90deg) translateZ(-166.50px);
	}
	.face2 {
		transform:rotateX(-90deg) translateZ(-83.50px);
		-moz-transform:rotateX(-90deg) translateZ(-83.50px);
		transform:rotateX(-90deg) translateZ(-83.50px);
	}
	.face3 {
		transform:rotateY(90deg) translateZ(-125.00px) translateY(41.50px);
		-moz-transform:rotateY(90deg) translateZ(-125.00px) translateY(41.50px);
		transform:rotateY(90deg) translateZ(-125.00px) translateY(41.50px);
	}
	.face4 {
		transform:rotateY(-90deg) translateZ(-125.00px) translateY(41.50px);
		-moz-transform:rotateY(-90deg) translateZ(-125.00px) translateY(41.50px);
		transform:rotateY(-90deg) translateZ(-125.00px) translateY(41.50px);
	}
	.face_bg {
		transform: rotateY(180deg);
	}
	.face5 {
		transform:translateZ(-125.00px) translateY(41.50px);
		-moz-transform:translateZ(-125.00px) translateY(41.50px);
		transform:translateZ(-125.00px) translateY(41.50px);
	}
	.face6 {
		transform:rotateY(180deg) translateZ(-125.00px) translateY(41.50px);
		-moz-transform:rotateY(180deg) translateZ(-125.00px) translateY(41.50px);
		transform:rotateY(180deg) translateZ(-125.00px) translateY(41.50px);
	}
	.can_ani {
		position:absolute;
		bottom:0.00px;
		z-index:2;
		width:100%;
	}
    .whater {
		position:absolute;
		width:100%;
	}
	.shuibo1_1 {
		animation:shuibo2 4.5s -2s ease-out infinite both;
		opacity:0;
	}
	.shuibo1_2 {
		animation:shuibo2 4.5s ease-out -0.5s infinite both;
		opacity:0;
	}
	.shuibo1_3 {
		animation:shuibo2 4.5s ease-out 1s infinite both;
		opacity:0;
	}
	.shuibo2_1 {
		animation:shuibo12 4.5s -2s ease-out infinite both;
		opacity:0;
	}
	.shuibo2_2 {
		animation:shuibo12 4.5s ease-out -0.5s infinite both;
		opacity:0;
	}
	.shuibo2_3 {
		animation:shuibo12 4.5s ease-out 1s infinite both;
		opacity:0;
	}
	@keyframes shuibo2 {
		0% {
		transform:scale(0.4);
		opacity:0;
	}
	10% {
		opacity:1;
	}
	60% {
		opacity:1;
	}
	100% {
		transform:scale(1.2);
		opacity:0;
	}
	}@keyframes trX_Y1 {
		0% {
		transform:translate(0.00px,0.00px);
	}
	100% {
		transform:translate(14.00px,-41.50px);
	}
	}.whater {
		width:157.00px;
		height:156.00px;
		bottom:-34.50px;
		right:35.50px;
		animation:trX_Y1 6s ease-in-out infinite alternate both;
	}
	.whater2 {
		width:90.50px;
		height:69.50px;
		bottom:139.00px;
		left:21.00px;
	}
	.whater3 {
		width:90.50px;
		height:69.50px;
		bottom:184.00px;
		left:-132.50px;
	}
	.whater4 {
		width:90.50px;
		height:69.50px;
		bottom:366.00px;
		left:71.00px;
		z-index:-1;
	}
	@keyframes shuibo1 {
		0% {
		transform:scale(0.1);
		opacity:0;
		}
		10% {
			opacity:1;
		}
		60% {
			opacity:1;
		}
		100% {
			transform:scale(0.6);
			opacity:0;
		}
	}@keyframes shuibo12 {
		0% {
		transform:rotateX(45deg) scale(0.1);
		opacity:0;
		}
		10% {
			opacity:1;
		}
		60% {
			opacity:1;
		}
		100% {
			transform:rotateX(45deg) scale(0.6);
			opacity:0;
		}
	}
	.shuibo_feihe {
		width:3564.50px;
		height:94.00px;
	}
</style>