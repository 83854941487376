<template>
	<div class="zhuan poc">
	    <div class="perspective poc">
	        <div class="zhuan_1 preserve3d po" :style="getStyle">
	            <div class="zhuan_1_f zhuan_div poc bg" :style="getBorderStyle()" @click="wxPreview">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="top: -100px; left: 0;">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="top: -100px; right: 0;">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="bottom: -95px; left: 0;">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="bottom: -95px; right: 0;">
            		<!-- <gif :mode="hudie_1"></gif>
            		<gif :mode="hudie_2"></gif>
            		<gif :mode="hudie_3"></gif>
            		<gif :mode="hudie_4"></gif> -->
	            </div>
	            <div class="zhuan_1_a zhuan_div poc bg" :style="getBorderStyle()" @click="wxPreview">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="top: -100px; left: 0;">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="top: -100px; right: 0;">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="bottom: -95px; left: 0;">
	            	<img src="http://resali.lititutu.cn/tuku/95fe97fdf6458c590374d6faf7c16f3b.gif" class="zhuan_hudie po" style="bottom: -95px; right: 0;">
	            	<!-- <gif :mode="hudie_1"></gif>
            		<gif :mode="hudie_2"></gif>
            		<gif :mode="hudie_3"></gif>
            		<gif :mode="hudie_4"></gif> -->
	            </div>
	            <div class="zhuan_1_t zhuan_t poc" :style="getBorderStyle(0)"></div>
	            <div class="zhuan_1_b zhuan_b poc" :style="getBorderStyle(1)"></div>
	            <div class="zhuan_1_l zhuan_l poc" :style="getBorderStyle(2)"></div>
	            <div class="zhuan_1_r zhuan_r poc" :style="getBorderStyle(3)"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'zhuan',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {
				// hudie_1: {
				// 	"duration": 1.8,
    // 				"width": 170,
    // 				"height": 147,
    // 				"backgroundWidth": 170,
    // 				"backgroundHeight": 3969,
    // 				"sprite_image": {
    // 					"im": "http://resali.lititutu.cn/icons/hudie/sprite_hudie.png",
    // 					"steps": 27
    // 				},
    // 				"pos": [-100, null, null, 0]
				// },
				// hudie_2: {
				// 	"duration": 1.8,
    // 				"width": 170,
    // 				"height": 147,
    // 				"backgroundWidth": 170,
    // 				"backgroundHeight": 3969,
    // 				"sprite_image": {
    // 					"im": "http://resali.lititutu.cn/icons/hudie/sprite_hudie.png",
    // 					"steps": 27
    // 				},
    // 				"pos": [-100, 0, null, null],
    // 				"transform": "rotateY(180deg)"
				// },
				// hudie_3: {
				// 	"duration": 1.8,
    // 				"width": 170,
    // 				"height": 147,
    // 				"backgroundWidth": 170,
    // 				"backgroundHeight": 3969,
    // 				"sprite_image": {
    // 					"im": "http://resali.lititutu.cn/icons/hudie/sprite_hudie.png",
    // 					"steps": 27
    // 				},
    // 				"pos": [null, null, -95, 0],
    // 				"transform": "rotateZ(180deg)"
				// },
				// hudie_4: {
				// 	"duration": 1.8,
    // 				"width": 170,
    // 				"height": 147,
    // 				"backgroundWidth": 170,
    // 				"backgroundHeight": 3969,
    // 				"sprite_image": {
    // 					"im": "http://resali.lititutu.cn/icons/hudie/sprite_hudie.png",
    // 					"steps": 27
    // 				},
    // 				"pos": [null, 0, -95, null],
    // 				"transform": "rotateX(180deg)"
				// }
			}
		},
		computed: {
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [440, 600], 'both');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px'
					}
				} else {
					return {
						width: '440px',
						height: '600px'
					}
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			},
			getBorderStyle (side) {
				/*
					side: undefined / 0(top) / 1(bottom) / 2(left) / 3(right)
				*/
				let css = {}, src;

				switch (typeof this.mode[1]) {
					case 'object':
						src = 'url('+this.mode[1][side]+')';
						break;
					case 'undefined':
						let border_im = "border.jpg";
						if (side !== undefined) {
							let border_map = ['borderT.png', 'borderB.png', 'borderL.png', 'borderR.png'];
							border_im = border_map[side];
						}
						src = 'url(https://resali.lititutu.cn/icons/other/'+ border_im +')';
						break
					default:
						src = 'url('+this.mode[1]+')';
						break
				}

				if (side === undefined) {
					css['backgroundImage'] = 'url('+this.currentImage['im']+')';
					css['borderImageSource'] = src;
				} else {
					css['backgroundImage'] = src;
				}
				return css;
			}
		}
	}
</script>

<style>
	.zhuan .perspective {
	    perspective: 600px;
	}
	.zhuan_1 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    animation: zhuan_1_1 6s linear infinite both;
	    background-color: #eee;
	}
	@keyframes zhuan_1_1 {
		0% {
		    transform: rotateY(360deg);
		}
		100% {
		    transform: rotateY(0deg);
		}
	}
	.zhuan_div {
	    border: 5px solid;
	    /*border-image: url(https://resali.lititutu.cn/icons/other/border.jpg);*/
	    border-image-slice: 5;
	    background-size: cover;
	    background-clip: content-box;
	}
	.zhuan_1_f {
	    transform: translateZ(15px);
	}
	.zhuan_1_a {
	    transform: translateZ(-15px);
	}
	.zhuan_t {
	    /*background-image: url(https://resali.lititutu.cn/icons/other/borderT.png);*/
	    top: -15px;
	}
	.zhuan_t, .zhuan_b {
	    height: 32px;
	    width: 100%;
	    background-size: 100% auto;
	}
	.zhuan_1_t {
	    transform: translateZ(0) rotateX(90deg);
	}
	.zhuan_b {
	    /*background-image: url(https://resali.lititutu.cn/icons/other/borderB.png);*/
	    bottom: -15px;
	}
	.zhuan_t, .zhuan_b {
	    height: 32px;
	    width: 100%;
	    background-size: 100% auto;
	}
	.zhuan_1_b {
	    transform: translateZ(0) translateY(12px) rotateX(-90deg);
	}
	.zhuan_l {
	    /*background-image: url(https://resali.lititutu.cn/icons/other/borderL.png);*/
	    left: -15px;
	}
	.zhuan_l, .zhuan_r {
	    width: 32px;
	    height: 102%;
	    background-size: auto 100%;
	}
	.zhuan_1_l {
	    transform: translateZ(0) rotateY(90deg);
	}
	.zhuan_r {
	    /*background-image: url(https://resali.lititutu.cn/icons/other/borderR.png);*/
	    right: -25px;
	}
	.zhuan_1_r {
	    transform: translateZ(0) rotateY(-90deg);
	}
	.zhuan_hudie {
		width: 170px;
		height: 147px;
	}
</style>





