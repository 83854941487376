var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"baobao1 poc bg",style:({backgroundImage: 'url('+_vm.mode[0]+')'})},[_c('div',{staticClass:"baobao1-div1 page po",class:{
    	'none': _vm.div1 == 2,
    	'baobao1-div1_in': _vm.div1 == 0,
    	'baobao1-div1_out': _vm.div1 == 1
    }},[_c('div',{staticClass:"baobao1-div_tremble page po"},[_c('div',{staticClass:"baobao1-page1_1 page po"},[_c('div',{staticClass:"baobao1-div page po",style:({backgroundImage: 'url('+ _vm.currentImage['im'] +')'}),on:{"click":_vm.wxPreview}}),_c('img',{staticClass:"baobao1-border_v po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png"}})])])]),_c('div',{staticClass:"baobao1-div2 page po",class:{
    	'baobao1-div2_in': _vm.div2 == 0,
    	'baobao1-div2_out': _vm.div2 == 1,
    	'none': _vm.div2 == 2
    }},[_c('div',{staticClass:"baobao1-div_tremble page po"},[_c('div',{staticClass:"baobao1-page1_2 page po"},[_c('div',{staticClass:"baobao1-div page po",style:({backgroundImage: 'url('+ _vm.currentImage['im'] +')'}),on:{"click":_vm.wxPreview}}),_c('img',{staticClass:"baobao1-border_v po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png"}})])])]),_c('div',{staticClass:"baobao1-div3 page po",class:{
    	'baobao1-div3_in': _vm.div3 == 0,
    	'baobao1-div3_out': _vm.div3 == 1,
    	'none': _vm.div3 == 2
    }},[_c('div',{staticClass:"baobao1-div_tremble page po"},[_c('div',{staticClass:"page po baobao1-page1_1"},[_c('div',{staticClass:"baobao1-div page po",style:({backgroundImage: 'url('+ _vm.currentImage['im'] +')'}),on:{"click":_vm.wxPreview}}),_c('img',{staticClass:"baobao1-border_v po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png"}})])])]),_c('div',{staticClass:"baobao1-div4 page po",class:{
    	'baobao1-div4_in': _vm.div4 == 0,
    	'none': _vm.div4 == 2
    }},[_c('div',{staticClass:"page po baobao1-div4_in2"},[_c('div',{staticClass:"page po baobao1-div4_in3"},[_c('div',{staticClass:"page po baobao1-div4_in4"},[_c('div',{staticClass:"page po baobao1-div_tremble"},[_c('div',{staticClass:"page po baobao1-page1_2"},[_c('div',{staticClass:"div page po",style:({backgroundImage: 'url('+ _vm.currentImage['im'] +')'}),on:{"click":_vm.wxPreview}},[_c('span',{staticClass:"divText imgTxt4"})]),_c('img',{staticClass:"baobao1-border_v po",attrs:{"src":"http://resali.lititutu.cn/scenes/kuailebaobao/frame_3_h.png"}})])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }