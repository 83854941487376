<template>
	<div class="flower flower-x po" :style="getStyles">
		<div class="flower-y flower-pre">
			<div class="flower-z">
				<img class="flower-im" :src="mode['im']">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'flower',
		props: ['mode'],
		data () {
			return {}
		},
		computed: {
			getStyles () {
				let s = {};
				if (this.mode['size']) {
					s['width'] = this.mode['size'][0].toString().indexOf('%') == -1 ? this.mode['size'][0] + 'px' : this.mode['size'][0];
					s['height'] = this.mode['size'][1].toString().indexOf('%') == -1 ? this.mode['size'][1] + 'px' : this.mode['size'][1];
				}
				if (this.mode['pos']) {
					if (this.mode['pos'].length == 2) {
						s['transform'] = 'translate('+ this.mode['pos'][0] +'px, '+ this.mode['pos'][1] +'px)';
					} else if (this.mode['pos'].length == 4) {
						s['top'] = (this.mode['pos'][0] == null ? 'initial' : this.mode['pos'][0]+'px');
						s['right'] = (this.mode['pos'][1] == null ? 'initial' : this.mode['pos'][1]+'px');
						s['bottom'] = (this.mode['pos'][2] == null ? 'initial' : this.mode['pos'][2]+'px');
						s['left'] = (this.mode['pos'][3] == null ? 'initial' : this.mode['pos'][3]+'px');
					}
				}
				return s;
			}
		}
	}
</script>

<style>
	.flower-x {
	    animation: flower_x 0.52s steps(6, end) infinite alternate both;
	}
	@keyframes flower_x {
		0% {
		    transform: translateX(-3px);
		}
		100% {
		    transform: translateX(3px);
		}
	}
	.flower-y {
	    animation: flower_y 0.5s steps(6, end) -0.2s infinite alternate both;
	}
	@keyframes flower_y {
		0% {
		    transform: translateY(3px);
		}
		100% {
		    transform: translateY(-3px);
		}
	}
	.flower-z {
		animation: flower_z 0.56s steps(6, end) -0.1s infinite alternate both;
	}
	@keyframes flower_z {
		0% {
		    transform: translateZ(-3px);
		}
		100% {
		    transform: translateZ(3px);
		}
	}
	.flower-im {
		width: 100%;
		height: 100%;
	}
	.flower-pre {
	    perspective: 800px;
	}
</style>