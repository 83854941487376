import util from "@/st_comps.util"

function setWordStatus(state, wordStatus, from) {
	state.wordCur.wordStatus = wordStatus;
	state.wordStatus = wordStatus;
}

const state = {
	config: {
		data: {
			ttid: '',
			mid: '',
		},
		images: [
			{
				f: '',
				id: '',
				p: '',
				pr: {},
				tx: "",
				im: ""
			}
		],
		follow_url: '',
		token: '',
		share_key: '',
		zan_num: 0,
		pv_num: 0,
		comment_num: 0,
		userInfo: null,
		musicInfo: null
	},
	editor: {
		theme: {
			full: {
				sceneNum: 0
			}
		},
		musicTypes: []
	},
	tips: {
		collection: true,
	},
	theme: {},
	themes: {},
	themeData: false,
	changeModules: false,
	themeHot: false,
	articleData: false,
	isCopyrightShow: false,
	blessartis: null,
	zanList: [],
	// 管理图片对象
	imageObj: {},
	imagesObj: {},
	// 推广位配置
	recMini: null,
	// 应用配置
	appConfig: {
		// 图片域名对应表
		image_map: {}
	},
	// 控制结束页的显示属性
	endPageRuntime: 0,
	// 当前照片游标
	imageCur: null,

	wordCur: {
		userTextDisplay: 0,
		lrc: null,
		lrcDisplay: 0,
		wordStatus: 0, // 0 无歌词无用户文字 1 有用户文字, 2 有歌词, 3 zimu5
	},
	wordStatus: 0, // 同上

	currentLrc: '', // 当前歌词
	modulePlayed: false, // 标志模板是否播放完毕
}

const mutations = {
	config (state, preload) {
		let datas = {};
		datas['data'] = preload.config['data'];
		
		let imageDatas = preload.config['images'];
		datas['images'] = [];

		let _wordStatus = 0;
		for(let i=0,len=imageDatas.length; i<len; i++) {
			imageDatas[i]['tx'] = util.decodeUserText( imageDatas[i]['tx'] );
			datas['images'].push( imageDatas[i] );
			if(imageDatas[i]['tx']){
				_wordStatus = 1;
			}
		}
		setWordStatus(state, _wordStatus, 1);

		datas['follow_url'] = preload.config['follow_url'];
		datas['token'] = preload.config['token'];
		datas['share_key'] = preload.config['share_key'];
		datas['zan_num'] = preload.config['zan'];
		datas['pv_num'] = preload.config['pv'];
		datas['comment_num'] = preload.config['comment_num'];
		datas['userInfo'] = preload.config['userInfo'];
		datas['musicInfo'] = preload.config['musicInfo'];

		state.config = datas;
		
		state.images = datas['images'];
		state.data = datas['data'];
	},
	setdata (state, preload) {
		state.config.data = preload.data;
		state.data = preload.data;
	},
	setimages (state, preload) {
		let imageDatas = preload.imageDatas;
		
		let images = []
		for(let i=0,len=imageDatas.length; i<len; i++) {
			imageDatas[i]['im'] = util.im( imageDatas[i] );
			// imageDatas[i]['tx'] = util.decodeUserText( imageDatas[i]['tx'] ); // 本地设置新images无需再进行base64解码 byhz 2021.09.06
			images.push( imageDatas[i] );
		}

		state.config.images = images;
		state.images = images;
	},
	setImageText (state, preload) {
		state.config.images[preload['index']]['tx'] = preload['text'];
		state.images[preload['index']]['tx'] = preload['text'];
		let _wordStatus = 0;
		for(let i = 0 ; i < state.config.images.length ; i++){
			if(state.config.images[i]['tx']){
				_wordStatus = 1
			}
		}
		setWordStatus(state, _wordStatus, 2);
	},
	setImageCur (state, preload) {
		state.imageCur = preload.cur;
	},
	setfullSceneNum (state, mode) {
		if (mode == 'incr') {
			state.editor.theme.full.sceneNum += 1
		} else if (mode == 'clear') {
			state.editor.theme.full.sceneNum = 0
		}
	},
	copyrightShow (state, preload) {
		state.isCopyrightShow = preload.isShow;
	},
	setThemeData (state, preload) {
		state.themeData = preload.themeData;
	},
	setUserMsg (state, preload) {
		state.userMsg = preload.userMsg;
	},
	setThemeHot (state, preload) {
		state.themeHot = preload.themeHot;
	},
	setArticleData (state, preload) {
		state.articleData = preload.articleData;
	},
	setImageOriSize (state, preload) {
		let imageidxs = [];
		if (preload['imageid']) {
			for(let i=0,lens=state.config['images'].length; i<lens; i++) {
				if (state.config['images'][i]['id'] == preload.imageid) {
					// imageidx = i;
					// break;
					imageidxs.push(i);
				}
			}
		} else {
			// imageidx = preload.imageidx;
			imageidxs.push(preload.imageidx);
		}
		for (let i=0; i<imageidxs.length; i++) {
			let imageidx = imageidxs[i];
			state.config['images'][imageidx]['orisize'] = preload.orisize;
			state['images'][imageidx]['orisize'] = preload.orisize;
		}
	},
	setTheme (state, preload) {
		state.theme = preload.theme;
		state.themes[preload.tid] = preload.theme;
	},
	setRecMini (state, preload) {
		state.recMini = preload;
	},
	setBlessArtiItems (state, preload) {
		state.blessartis = preload.items;
	},
	setUserInfo (state, preload) {
		state.config['userInfo'] = preload['userInfo'];
	},
	setMusicInfo (state, preload) {
		state.config['musicInfo'] = preload;
		// if(state.wordCur.wordStatus != 1 && preload['src'] && preload['lrc']){
		// 	state.wordCur.wordStatus = 2;
		// 	state.wordStatus = 2;
		// }
	},
	// 设置点赞数、评论数、pv
	setNum (state, preload) {
		if(preload['type'] == 'zan'){
			state.config.zan_num = preload['num'];
		} else if(preload['type'] == 'pv'){
			state.config.pv_num = preload['num'];
		} 
	},
	// 插入评论、点赞
	pushZan (state, preload){
		state.zanList.push(preload);
	},
	// 设置图片对象管理
	setImageObj (state, preload) {
		let _id = util.md5(preload.imagesrc);
		if (!state.imageObj[ _id ] ) state.imageObj[ _id ] = preload.imageobj;
		if (!state.imagesObj[ _id ] ) state.imagesObj[ _id ] = preload.imageobj;
	},
	setImagesObj (state, preload) {
		let imsrc = typeof preload.imagesrc == 'object' ? preload.imagesrc['im'] : preload.imagesrc;
        let _id = util.md5(imsrc);
		
        // 对用户照片提取关键信息，减少对内存的占用
        if (util.typeOf(preload.imagesrc) == 'object') {
            preload.imageobj = {
                'width': preload.imageobj.width,
                'height': preload.imageobj.height
            }
        }
        
        if (!state.imageObj[ _id ]) state.imageObj[ _id ] = preload.imageobj;
        if (!state.imagesObj[ _id ]) state.imagesObj[ _id ] = preload.imageobj;

        // 设置orisize
        for (let i=0; i<state.images.length; i++) {
            if (imsrc == state.images[i]['im']) {
                state.images[i]['orisize'] = [preload.imageobj.width, preload.imageobj.height];
            }
        }
    },
	setMusicTypes (state, preload) {
		state.editor['musicTypes'] = preload.music_types;
	},
	setMusicTypeDatas (state, preload) {
		state.editor['musicTypes'][preload.index]['datas'] = preload.datas;
	},
	// 设置应用配置
	setAppConfig (state, preload) {
		let appConfig = preload.appConfig;
		state.appConfig = appConfig;
	},
	// 结束页功能
	incrEndPageRuntime (state, preload) {
		state.endPageRuntime += 1;
	},
	restartEndPageRuntime (state, preload) {
		state.endPageRuntime = 0;
	},
	// 文字管理
	setCurrentWord (state, preload){
		state.wordCur = Object.assign(state.wordCur, preload);
	},
	// 写入歌词
	setLrc (state, preload) {
		state.currentLrc = preload.lrc;
		// if (state.wordStatus != 1) {
		// 	state.wordStatus = 2;
		// 	util.call({
		// 		action: 'word_status',
		// 		data: 2
		// 	})
		// }
	},
	// 设置文字状态
	setWordStatus (state, preload) {
		setWordStatus(state, preload.status, "3."+preload.from);
	},
	// 标记模版播放完毕
	setModulePlayed (state, preload) {
		state.modulePlayed = true;
	}
}

const actions = {
	setdata ({ commit, state }, preload) {
		return new Promise(( resolve, reject ) => {
			// 兼容2020.06.19发文事故处理
			if (state.config['data']['appid'] == 'wxa14f0346ddc3bd37' && state.config['data']['openid'] == 'oUvY81vTLkVYouuIjyUf8vzzJtBc' && window.location.href.indexOf('is_manager') == -1) {
				resolve();
				return;
			}

			util.rget('setdata', {
				k: preload.k,
				v: preload.v,
				key: preload.key
			})
			.then((data) => {
				commit('setdata', {
					data: data
				});
				resolve();
			})
		})
	},
	setThemeData ({ commit, state }, preload) {
		return new Promise(( resolve, reject ) => {
			if (state.themeData) {
				resolve(state.themeData);
			} else {
				util.rget('get_theme_list', {}).then((response) => {
					commit('setThemeData', {
						'themeData': response
					})
					resolve(response);
				})
			}
		})
	},
	// 获取用户union data
	setUserMsg ({ commit, state }, preload) {
		return new Promise(( resolve, reject ) => {
			util.rget('get_user_msg', {
				'__appid__': preload.appid,
				'openid': preload.openid,
			}).then((response) => {
				commit('setUserMsg', {
					'userMsg': response
				})
				resolve(response);
			})
		})
	},
	setArticleData ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {
			util.rget('get_mp_articles', {}).then((response) => {
				commit('setArticleData', {
					'articleData': response
				})
				resolve(response);
			})
		})
	},
	setThemeHot ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {
			util.rget('get_hot_list', {}).then((response) => {
				commit('setThemeHot', {
					'themeHot': response
				})
				resolve(response);
			})
		})
	},
	getTheme ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {
			if (state['themes'][preload.tid]) {
				commit('setTheme', {
					'theme': state['themes'][preload.tid],
					'tid': preload.tid
				})
				resolve(state['themes'][preload.tid]);
			} else {
				util.rget('get_theme_data', {tid: preload.tid}).then((response) => {
					commit('setTheme', {
						'theme': response,
						'tid': preload.tid
					})
					resolve(response);
				})
			}
		})
	},
	getRecMini ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {
			if (state['recMini']) {
				resolve(state['recMini']);
			} else {
				util.rget("get_rec_mini",{}).then(res=>{
					for(let _key in res){
						if(_key == "map"){
							continue
						}
						for(let idx = 0 ; idx < res[_key].length ; idx++){
							res[_key][idx] = res["map"][res[_key][idx]];
						}
					}
					commit('setRecMini', res)
					resolve(res)
				})
			}
		})
	},
	// getAppConfig ({ commit, state }, preload) {
	// 	return new Promise((resolve, reject) => {
	// 		if (state['app'][preload]) {
	// 			if(preload == "share_title"){
	// 				state['app'][preload] = util.buildShareTitle(state, state['app']);
	// 			}
	// 			resolve(state['app'][preload]);
	// 		} else {
	// 			util.rget('get_xc_config', {}).then((response) => {
	// 				if(response && response["share_title"]){ //添加默认分享语前缀
	// 					response['def_share_title'] = util.getCurrentPreFix(response) + response['share_title'];
	// 					response['share_title'] = util.buildShareTitle(state, response);
	// 				}
	// 				commit('setAppConfig', {app: response});
	// 				resolve(response[preload]);
	// 			})
	// 		}
	// 	})
	// },
	getBlessArtiItems ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {
			if (state['blessartis']) {
				resolve(state['blessartis']);
			} else {
				util.rget('https://g5.litiskr.cn/blessarti/get_list', {
					'_type': 'ajax',
					'pageidx': 0,
					'__appid__': preload['appid']
				}).then((response) => {
					commit('setBlessArtiItems', {'items': response['list']});
					resolve(response['list']);
				})
			}
		})
	},
	getUserInfo ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {
			if (state['config']['userInfo']) {
				resolve(state['config']['userInfo']);
			} else {
				util.rget('/api/get_wxa_user_info', {
					appid: state['config']['data']['appid'],
					openid: state['config']['data']['openid']
				}).then((response) => {
					let userInfo = util.transformUserdata(response);
					commit('setUserInfo', {'userInfo': userInfo});
					resolve(userInfo);
				});
			}
		})
	},
	setNum ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {

			if (preload.type == 'zan') {

				util.rget('set_num', {
					'key': preload.key,
					'field': 'default'
				})
				.then((current_zan) => {
					commit('setNum', {'type': 'zan', 'num': current_zan});
					resolve(current_zan);
				})
			} else if (preload.type == 'pv') {

				util.rget('set_pv', {
					'key': preload.key,
					'appid': state.config.data['appid'],
					'openid': state.config.data['openid'],
					'thumb': state.config.images[0]['im']
				})
				.then((current_pv) => {
					commit('setNum', {'type': 'pv', 'num': current_pv});
					resolve(current_pv);
				})
			}
		})
	},
	getMusicTypes ({ commit, state }, preload) {
		return new Promise((resolve, reject) => {

			if (state['editor']['musicTypes'].length) {
				resolve(state['editor']['musicTypes']);
			} else {
				util.rget('get_music_types', {}).then((music_types) => {
					commit('setMusicTypes', {'music_types': music_types});
					resolve(music_types);
				})
			}
		})
	},
	getChangeModules ({ commit, state }, preload) {
		return new Promise(( resolve, reject ) => {
			if (state.changeModules) {
				resolve(state.changeModules);
			} else {
				util.rget('/config_center/get_config', {
					cid: "app_vliti_event_" + preload.event_id,
					fields: "modules"
				}).then(config => {
					let modules = config['modules'];
					for (let i=0; i<modules.length; i++) {
						modules[i] = modules[i].replace("module_vliti_", "");
					}
					let current_tid = state.config.data['ttid'];
					if (modules.indexOf(current_tid) == -1) {
						modules.unshift(current_tid);
					}
					state.changeModules = modules;
					resolve(state.changeModules);
				})
			}
		})
	}
}

const getters = {
}

export default {
	state,
	mutations,
	actions,
	getters
}
