<template>
	<div class="BX_AEComposition" AELayerName="chuanyuelishi_4">
	    <div class="BX_Class19 BX_BG19 BX_Ani19" id="BX_Layer19" AELayerName="19.bg"></div>
	    <div class="BX_Class18 BX_BG18 BX_Ani18" id="BX_Layer18" AELayerName="18.bird.png"></div>
	    <div class="BX_Class17 BX_BG17 BX_Ani17" id="BX_Layer17" AELayerName="17.cloud.png"></div>
	    <div class="BX_Class16 BX_BG16 BX_Ani16" id="BX_Layer16" AELayerName="16.cloud2.png"></div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class15 BX_BG15 BX_Ani15" id="BX_Layer15" AELayerName="15.kaiguodadian.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class14 BX_BG14 BX_Ani14" id="BX_Layer14" AELayerName="14.liangdanyixing.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class13 BX_BG13 BX_Ani13" id="BX_Layer13" AELayerName="13.lianheguo.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class12 BX_BG12 BX_Ani12" id="BX_Layer12" AELayerName="12.gaigekaifang.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class11 BX_BG11 BX_Ani11" id="BX_Layer11" AELayerName="11.xiangganghuigui.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class10 BX_BG10 BX_Ani10" id="BX_Layer10" AELayerName="10.jiarushimao.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class9 BX_BG9 BX_Ani9" id="BX_Layer9" AELayerName="9.shenzhouwuhao.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class8 BX_BG8 BX_Ani8" id="BX_Layer8" AELayerName="8.aoyun.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class7 BX_BG7 BX_Ani7" id="BX_Layer7" AELayerName="7.dierdajingjiti.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class6 BX_BG6 BX_Ani6" id="BX_Layer6" AELayerName="6.guochanhangmu.jpg"></div>
	    </div>
	    <div class="BX_Class19 BX_Ani19" AELayerName="19.bg">
	        <div class="BX_Class5 BX_BG5 BX_Ani5" id="BX_Layer5" AELayerName="5.text.png"></div>
	    </div>
	    <div class="BX_Class4 BX_BG4 BX_Ani4" id="BX_Layer4" AELayerName="4.haihunshan.png"></div>
	    <div class="BX_Class2 BX_Ani2" AELayerName="2.train.png">
	        <div class="BX_Class3 BX_BG3 BX_Ani3" id="BX_Layer3" AELayerName="3.body1.png"></div>
	    </div>
	    <div class="BX_Class2 BX_BG2 BX_Ani2" id="BX_Layer2" AELayerName="2.train.png"></div>
	    <div class="BX_Class2 BX_Ani2" AELayerName="2.train.png">
	        <div class="BX_Class1 BX_BG1 BX_Ani1" id="BX_Layer1" AELayerName="1.head.png" :style="{backgroundImage: 'url('+currentImage[0]['im']+')'}"></div>
	    </div>
	    <div class="BX_Class2 BX_Ani2" AELayerName="3.train.png">
			<div class="BX_Class20 BX_BG20 BX_Ani20" id="BX_Layer20" AELayerName="1.hat.png"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'guoqingbianlian',
		props: ['currentImage'],
		data () {
			return {}
		},
		mounted () {
			let o = document.querySelector('#BX_Layer19');
			o.addEventListener('animationend', () => {
				this.letAniEndPageIn();
			})
		},
		methods: {
			letAniEndPageIn () {
				this.$emit('letAniEndPageIn');
			}
		}
	}
</script>

<style scoped>
	 .BX_AEComposition {
	    background: transparent;
	    width: 720px;
	    height: 1280px;
	    display: block;
	    overflow: hidden;
	    position: relative;
	    transform: translate3d(0, 0, 0) scale(1, 1);
	    transform-origin:50% 0%;
	}
	.BX_Class19 {
	    left: -6.00px;
	    top: 1.00px;
	    width: 9360px;
	    height: 1280px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG19 {
	    background: rgb(194, 10, 13);
	}
	.BX_Ani19 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey19 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey19 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.22% {
	        transform: translate3d(-2.26px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.32% {
	        transform: translate3d(-8.85px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.43% {
	        transform: translate3d(-19.46px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.53% {
	        transform: translate3d(-33.77px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.63% {
	        transform: translate3d(-51.48px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.74% {
	        transform: translate3d(-72.28px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.84% {
	        transform: translate3d(-95.86px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    3.95% {
	        transform: translate3d(-121.91px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.05% {
	        transform: translate3d(-150.12px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.15% {
	        transform: translate3d(-180.19px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.26% {
	        transform: translate3d(-211.80px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.36% {
	        transform: translate3d(-244.64px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.47% {
	        transform: translate3d(-278.41px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.57% {
	        transform: translate3d(-312.80px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.67% {
	        transform: translate3d(-347.50px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.78% {
	        transform: translate3d(-382.20px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.88% {
	        transform: translate3d(-416.59px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    4.98% {
	        transform: translate3d(-450.36px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.09% {
	        transform: translate3d(-483.20px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.19% {
	        transform: translate3d(-514.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.30% {
	        transform: translate3d(-544.88px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.40% {
	        transform: translate3d(-573.09px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.50% {
	        transform: translate3d(-599.14px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.61% {
	        transform: translate3d(-622.72px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.71% {
	        transform: translate3d(-643.52px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.82% {
	        transform: translate3d(-661.23px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    5.92% {
	        transform: translate3d(-675.54px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    6.02% {
	        transform: translate3d(-686.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    6.13% {
	        transform: translate3d(-692.74px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    6.23% {
	        transform: translate3d(-695.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    11.53% {
	        transform: translate3d(-697.28px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    11.63% {
	        transform: translate3d(-703.91px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    11.73% {
	        transform: translate3d(-714.57px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    11.84% {
	        transform: translate3d(-728.97px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    11.94% {
	        transform: translate3d(-746.78px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.05% {
	        transform: translate3d(-767.70px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.15% {
	        transform: translate3d(-791.41px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.25% {
	        transform: translate3d(-817.61px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.36% {
	        transform: translate3d(-845.99px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.46% {
	        transform: translate3d(-876.22px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.56% {
	        transform: translate3d(-908.02px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.67% {
	        transform: translate3d(-941.05px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.77% {
	        transform: translate3d(-975.02px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.88% {
	        transform: translate3d(-1009.61px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    12.98% {
	        transform: translate3d(-1044.51px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.08% {
	        transform: translate3d(-1079.40px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.19% {
	        transform: translate3d(-1113.98px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.29% {
	        transform: translate3d(-1147.95px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.40% {
	        transform: translate3d(-1180.98px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.50% {
	        transform: translate3d(-1212.77px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.60% {
	        transform: translate3d(-1243.01px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.71% {
	        transform: translate3d(-1271.39px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.81% {
	        transform: translate3d(-1297.59px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    13.91% {
	        transform: translate3d(-1321.30px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    14.02% {
	        transform: translate3d(-1342.22px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    14.12% {
	        transform: translate3d(-1360.03px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    14.23% {
	        transform: translate3d(-1374.43px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    14.33% {
	        transform: translate3d(-1385.09px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    14.43% {
	        transform: translate3d(-1391.72px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    14.54% {
	        transform: translate3d(-1394.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    19.83% {
	        transform: translate3d(-1396.45px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    19.94% {
	        transform: translate3d(-1403.59px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.04% {
	        transform: translate3d(-1415.08px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.15% {
	        transform: translate3d(-1430.59px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.25% {
	        transform: translate3d(-1449.78px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.35% {
	        transform: translate3d(-1472.31px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.46% {
	        transform: translate3d(-1497.86px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.56% {
	        transform: translate3d(-1526.08px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.66% {
	        transform: translate3d(-1556.65px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.77% {
	        transform: translate3d(-1589.23px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.87% {
	        transform: translate3d(-1623.47px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    20.98% {
	        transform: translate3d(-1659.06px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.08% {
	        transform: translate3d(-1695.65px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.18% {
	        transform: translate3d(-1732.91px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.29% {
	        transform: translate3d(-1770.49px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.39% {
	        transform: translate3d(-1808.08px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.50% {
	        transform: translate3d(-1845.35px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.60% {
	        transform: translate3d(-1881.94px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.70% {
	        transform: translate3d(-1917.53px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.81% {
	        transform: translate3d(-1951.78px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    21.91% {
	        transform: translate3d(-1984.35px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.01% {
	        transform: translate3d(-2014.92px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.12% {
	        transform: translate3d(-2043.14px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.22% {
	        transform: translate3d(-2068.69px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.33% {
	        transform: translate3d(-2091.22px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.43% {
	        transform: translate3d(-2110.41px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.53% {
	        transform: translate3d(-2125.92px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.64% {
	        transform: translate3d(-2137.40px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.74% {
	        transform: translate3d(-2144.55px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    22.85% {
	        transform: translate3d(-2147.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.14% {
	        transform: translate3d(-2149.35px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.25% {
	        transform: translate3d(-2156.20px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.35% {
	        transform: translate3d(-2167.22px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.45% {
	        transform: translate3d(-2182.08px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.56% {
	        transform: translate3d(-2200.49px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.66% {
	        transform: translate3d(-2222.09px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.76% {
	        transform: translate3d(-2246.58px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.87% {
	        transform: translate3d(-2273.65px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    28.97% {
	        transform: translate3d(-2302.95px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.08% {
	        transform: translate3d(-2334.19px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.18% {
	        transform: translate3d(-2367.02px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.28% {
	        transform: translate3d(-2401.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.39% {
	        transform: translate3d(-2436.23px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.49% {
	        transform: translate3d(-2471.96px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.60% {
	        transform: translate3d(-2507.99px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.70% {
	        transform: translate3d(-2544.04px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.80% {
	        transform: translate3d(-2579.77px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    29.91% {
	        transform: translate3d(-2614.86px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.01% {
	        transform: translate3d(-2648.97px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.11% {
	        transform: translate3d(-2681.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.22% {
	        transform: translate3d(-2713.04px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.32% {
	        transform: translate3d(-2742.36px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.43% {
	        transform: translate3d(-2769.42px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.53% {
	        transform: translate3d(-2793.91px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.63% {
	        transform: translate3d(-2815.52px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.74% {
	        transform: translate3d(-2833.91px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.84% {
	        transform: translate3d(-2848.78px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    30.94% {
	        transform: translate3d(-2859.80px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    31.05% {
	        transform: translate3d(-2866.65px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    31.15% {
	        transform: translate3d(-2869.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    36.45% {
	        transform: translate3d(-2871.32px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    36.55% {
	        transform: translate3d(-2878.08px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    36.66% {
	        transform: translate3d(-2888.96px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    36.76% {
	        transform: translate3d(-2903.65px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    36.86% {
	        transform: translate3d(-2921.82px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    36.97% {
	        transform: translate3d(-2943.15px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.07% {
	        transform: translate3d(-2967.34px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.18% {
	        transform: translate3d(-2994.07px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.28% {
	        transform: translate3d(-3023.01px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.38% {
	        transform: translate3d(-3053.86px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.49% {
	        transform: translate3d(-3086.28px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.59% {
	        transform: translate3d(-3119.98px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.69% {
	        transform: translate3d(-3154.62px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.80% {
	        transform: translate3d(-3189.90px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    37.90% {
	        transform: translate3d(-3225.49px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.01% {
	        transform: translate3d(-3261.09px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.11% {
	        transform: translate3d(-3296.37px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.21% {
	        transform: translate3d(-3331.02px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.32% {
	        transform: translate3d(-3364.72px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.42% {
	        transform: translate3d(-3397.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.53% {
	        transform: translate3d(-3427.99px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.63% {
	        transform: translate3d(-3456.93px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.73% {
	        transform: translate3d(-3483.66px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.84% {
	        transform: translate3d(-3507.85px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    38.94% {
	        transform: translate3d(-3529.18px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    39.04% {
	        transform: translate3d(-3547.35px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    39.15% {
	        transform: translate3d(-3562.04px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    39.25% {
	        transform: translate3d(-3572.91px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    39.36% {
	        transform: translate3d(-3579.68px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    39.46% {
	        transform: translate3d(-3582.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    44.76% {
	        transform: translate3d(-3584.25px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    44.86% {
	        transform: translate3d(-3590.80px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    44.96% {
	        transform: translate3d(-3601.35px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.07% {
	        transform: translate3d(-3615.58px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.17% {
	        transform: translate3d(-3633.19px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.28% {
	        transform: translate3d(-3653.86px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.38% {
	        transform: translate3d(-3677.31px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.48% {
	        transform: translate3d(-3703.21px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.59% {
	        transform: translate3d(-3731.26px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.69% {
	        transform: translate3d(-3761.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.79% {
	        transform: translate3d(-3792.58px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    45.90% {
	        transform: translate3d(-3825.23px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.00% {
	        transform: translate3d(-3858.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.11% {
	        transform: translate3d(-3893.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.21% {
	        transform: translate3d(-3927.49px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.31% {
	        transform: translate3d(-3961.99px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.42% {
	        transform: translate3d(-3996.18px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.52% {
	        transform: translate3d(-4029.77px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.63% {
	        transform: translate3d(-4062.42px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.73% {
	        transform: translate3d(-4093.85px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.83% {
	        transform: translate3d(-4123.74px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    46.94% {
	        transform: translate3d(-4151.79px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.04% {
	        transform: translate3d(-4177.69px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.14% {
	        transform: translate3d(-4201.14px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.25% {
	        transform: translate3d(-4221.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.35% {
	        transform: translate3d(-4239.42px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.46% {
	        transform: translate3d(-4253.65px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.56% {
	        transform: translate3d(-4264.19px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.66% {
	        transform: translate3d(-4270.75px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    47.77% {
	        transform: translate3d(-4273.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.06% {
	        transform: translate3d(-4275.16px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.17% {
	        transform: translate3d(-4281.46px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.27% {
	        transform: translate3d(-4291.59px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.37% {
	        transform: translate3d(-4305.27px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.48% {
	        transform: translate3d(-4322.19px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.58% {
	        transform: translate3d(-4342.06px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.69% {
	        transform: translate3d(-4364.58px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.79% {
	        transform: translate3d(-4389.47px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    53.89% {
	        transform: translate3d(-4416.43px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.00% {
	        transform: translate3d(-4445.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.10% {
	        transform: translate3d(-4475.35px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.21% {
	        transform: translate3d(-4506.73px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.31% {
	        transform: translate3d(-4538.99px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.41% {
	        transform: translate3d(-4571.85px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.52% {
	        transform: translate3d(-4604.99px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.62% {
	        transform: translate3d(-4638.14px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.72% {
	        transform: translate3d(-4671.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.83% {
	        transform: translate3d(-4703.27px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    54.93% {
	        transform: translate3d(-4734.65px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.04% {
	        transform: translate3d(-4764.85px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.14% {
	        transform: translate3d(-4793.57px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.24% {
	        transform: translate3d(-4820.53px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.35% {
	        transform: translate3d(-4845.42px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.45% {
	        transform: translate3d(-4867.94px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.56% {
	        transform: translate3d(-4887.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.66% {
	        transform: translate3d(-4904.73px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.76% {
	        transform: translate3d(-4918.41px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.87% {
	        transform: translate3d(-4928.54px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    55.97% {
	        transform: translate3d(-4934.84px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    56.07% {
	        transform: translate3d(-4937.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    61.37% {
	        transform: translate3d(-4939.25px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    61.47% {
	        transform: translate3d(-4945.80px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    61.58% {
	        transform: translate3d(-4956.35px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    61.68% {
	        transform: translate3d(-4970.58px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    61.79% {
	        transform: translate3d(-4988.19px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    61.89% {
	        transform: translate3d(-5008.86px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    61.99% {
	        transform: translate3d(-5032.31px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.10% {
	        transform: translate3d(-5058.21px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.20% {
	        transform: translate3d(-5086.26px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.31% {
	        transform: translate3d(-5116.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.41% {
	        transform: translate3d(-5147.58px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.51% {
	        transform: translate3d(-5180.23px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.62% {
	        transform: translate3d(-5213.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.72% {
	        transform: translate3d(-5248.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.82% {
	        transform: translate3d(-5282.49px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    62.93% {
	        transform: translate3d(-5316.99px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.03% {
	        transform: translate3d(-5351.18px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.14% {
	        transform: translate3d(-5384.77px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.24% {
	        transform: translate3d(-5417.42px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.34% {
	        transform: translate3d(-5448.85px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.45% {
	        transform: translate3d(-5478.74px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.55% {
	        transform: translate3d(-5506.79px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.66% {
	        transform: translate3d(-5532.69px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.76% {
	        transform: translate3d(-5556.14px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.86% {
	        transform: translate3d(-5576.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    63.97% {
	        transform: translate3d(-5594.42px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    64.07% {
	        transform: translate3d(-5608.65px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    64.17% {
	        transform: translate3d(-5619.19px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    64.28% {
	        transform: translate3d(-5625.75px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    64.38% {
	        transform: translate3d(-5628.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    69.68% {
	        transform: translate3d(-5630.26px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    69.78% {
	        transform: translate3d(-5636.83px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    69.89% {
	        transform: translate3d(-5647.40px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    69.99% {
	        transform: translate3d(-5661.68px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.09% {
	        transform: translate3d(-5679.33px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.20% {
	        transform: translate3d(-5700.07px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.30% {
	        transform: translate3d(-5723.58px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.40% {
	        transform: translate3d(-5749.56px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.51% {
	        transform: translate3d(-5777.69px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.61% {
	        transform: translate3d(-5807.67px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.72% {
	        transform: translate3d(-5839.19px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.82% {
	        transform: translate3d(-5871.94px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    70.92% {
	        transform: translate3d(-5905.62px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.03% {
	        transform: translate3d(-5939.91px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.13% {
	        transform: translate3d(-5974.51px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.24% {
	        transform: translate3d(-6009.10px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.34% {
	        transform: translate3d(-6043.39px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.44% {
	        transform: translate3d(-6077.06px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.55% {
	        transform: translate3d(-6109.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.65% {
	        transform: translate3d(-6141.33px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.75% {
	        transform: translate3d(-6171.31px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.86% {
	        transform: translate3d(-6199.44px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    71.96% {
	        transform: translate3d(-6225.42px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    72.07% {
	        transform: translate3d(-6248.93px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    72.17% {
	        transform: translate3d(-6269.66px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    72.27% {
	        transform: translate3d(-6287.32px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    72.38% {
	        transform: translate3d(-6301.60px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    72.48% {
	        transform: translate3d(-6312.17px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    72.59% {
	        transform: translate3d(-6318.74px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    72.69% {
	        transform: translate3d(-6321.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    77.99% {
	        transform: translate3d(-6323.27px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.09% {
	        transform: translate3d(-6329.88px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.19% {
	        transform: translate3d(-6340.52px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.30% {
	        transform: translate3d(-6354.87px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.40% {
	        transform: translate3d(-6372.63px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.50% {
	        transform: translate3d(-6393.49px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.61% {
	        transform: translate3d(-6417.13px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.71% {
	        transform: translate3d(-6443.26px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.82% {
	        transform: translate3d(-6471.56px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    78.92% {
	        transform: translate3d(-6501.70px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.02% {
	        transform: translate3d(-6533.41px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.13% {
	        transform: translate3d(-6566.34px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.23% {
	        transform: translate3d(-6600.22px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.34% {
	        transform: translate3d(-6634.71px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.44% {
	        transform: translate3d(-6669.51px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.54% {
	        transform: translate3d(-6704.30px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.65% {
	        transform: translate3d(-6738.79px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.75% {
	        transform: translate3d(-6772.65px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.85% {
	        transform: translate3d(-6805.59px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    79.96% {
	        transform: translate3d(-6837.29px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.06% {
	        transform: translate3d(-6867.45px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.17% {
	        transform: translate3d(-6895.74px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.27% {
	        transform: translate3d(-6921.87px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.37% {
	        transform: translate3d(-6945.51px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.48% {
	        transform: translate3d(-6966.37px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.58% {
	        transform: translate3d(-6984.13px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.69% {
	        transform: translate3d(-6998.48px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.79% {
	        transform: translate3d(-7009.12px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    80.89% {
	        transform: translate3d(-7015.73px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    81.00% {
	        transform: translate3d(-7018.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    86.29% {
	        transform: translate3d(-7020.31px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    86.40% {
	        transform: translate3d(-7027.04px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    86.50% {
	        transform: translate3d(-7037.85px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    86.60% {
	        transform: translate3d(-7052.46px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    86.71% {
	        transform: translate3d(-7070.52px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    86.81% {
	        transform: translate3d(-7091.74px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    86.92% {
	        transform: translate3d(-7115.79px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.02% {
	        transform: translate3d(-7142.36px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.12% {
	        transform: translate3d(-7171.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.23% {
	        transform: translate3d(-7201.81px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.33% {
	        transform: translate3d(-7234.07px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.44% {
	        transform: translate3d(-7267.57px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.54% {
	        transform: translate3d(-7302.03px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.64% {
	        transform: translate3d(-7337.11px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.75% {
	        transform: translate3d(-7372.51px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.85% {
	        transform: translate3d(-7407.90px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    87.95% {
	        transform: translate3d(-7442.98px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.06% {
	        transform: translate3d(-7477.43px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.16% {
	        transform: translate3d(-7510.94px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.27% {
	        transform: translate3d(-7543.18px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.37% {
	        transform: translate3d(-7573.85px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.47% {
	        transform: translate3d(-7602.64px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.58% {
	        transform: translate3d(-7629.21px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.68% {
	        transform: translate3d(-7653.26px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.79% {
	        transform: translate3d(-7674.48px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.89% {
	        transform: translate3d(-7692.55px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    88.99% {
	        transform: translate3d(-7707.15px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    89.10% {
	        transform: translate3d(-7717.97px, -0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    89.20% {
	        transform: translate3d(-7724.69px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    89.30% {
	        transform: translate3d(-7727.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    99.58% {
	        transform: translate3d(-7727.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    99.69% {
	        transform: translate3d(-7727.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    99.79% {
	        transform: translate3d(-7727.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    99.90% {
	        transform: translate3d(-7727.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	    100.00% {
	        transform: translate3d(-7727.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	    }
	}
	.BX_Class18 {
	    left: 172.50px;
	    top: 59.50px;
	    width: 391px;
	    height: 237px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.71, 1.69);
	    opacity: 0.60;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG18 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/bird.png");
	}
	.BX_Ani18 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey18 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey18 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class17 {
	    left: -28.50px;
	    top: 1125.50px;
	    width: 149px;
	    height: 45px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(2.80, 2.80);
	    opacity: 0.50;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG17 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/cloud.png");
	}
	.BX_Ani17 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey17 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey17 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class16 {
	    left: 539.50px;
	    top: 799.50px;
	    width: 149px;
	    height: 41px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(2.80, 2.80);
	    opacity: 0.40;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG16 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/cloud2.png");
	}
	.BX_Ani16 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey16 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey16 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class15 {
	    left: 757.00px;
	    top: 33.00px;
	    width: 500px;
	    height: 368px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.91, 0.91);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG15 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/kaiguodadian.jpg");
	}
	.BX_Ani15 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey15 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey15 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class14 {
	    left: 1508.00px;
	    top: 70.00px;
	    width: 300px;
	    height: 284px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.24, 1.24);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG14 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/liangdanyixing.jpg");
	}
	.BX_Ani14 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey14 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey14 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class13 {
	    left: 2152.00px;
	    top: -11.50px;
	    width: 640px;
	    height: 431px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.77, 0.77);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG13 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/lianheguo.jpg");
	}
	.BX_Ani13 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey13 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey13 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class12 {
	    left: 3000.00px;
	    top: 79.50px;
	    width: 384px;
	    height: 257px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.26, 1.26);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG12 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/gaigekaifang.jpg");
	}
	.BX_Ani12 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey12 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey12 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class11 {
	    left: 3645.00px;
	    top: 39.00px;
	    width: 500px;
	    height: 338px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.95, 0.95);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG11 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/xiangganghuigui.jpg");
	}
	.BX_Ani11 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey11 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey11 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class10 {
	    left: 4320.00px;
	    top: 6.50px;
	    width: 600px;
	    height: 395px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.84, 0.84);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG10 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/jiarushimao.jpg");
	}
	.BX_Ani10 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey10 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey10 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class9 {
	    left: 5015.00px;
	    top: 44.50px;
	    width: 500px;
	    height: 335px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG9 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/shenzhouwuhao.jpg");
	}
	.BX_Ani9 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey9 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey9 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class8 {
	    left: 5648.00px;
	    top: 17.00px;
	    width: 600px;
	    height: 406px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.82, 0.82);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG8 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/aoyun.jpg");
	}
	.BX_Ani8 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey8 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey8 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class7 {
	    left: 6337.00px;
	    top: 9.50px;
	    width: 641px;
	    height: 429px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.81, 0.81);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG7 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/dierdajingjiti.jpg");
	}
	.BX_Ani7 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey7 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey7 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class6 {
	    left: 7044.50px;
	    top: 19.00px;
	    width: 615px;
	    height: 410px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(0.84, 0.84);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG6 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/guochanhangmu.jpg");
	}
	.BX_Ani6 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey6 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey6 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class5 {
	    left: 25.00px;
	    top: -1.00px;
	    width: 8500px;
	    height: 1280px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG5 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/text.png");
	}
	.BX_Ani5 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey5 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey5 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class4 {
	    left: 316.00px;
	    top: 814.00px;
	    width: 400px;
	    height: 400px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(2.04, 2.04);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG4 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/haihunshan.png?v=3");
	}
	.BX_Ani4 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey4 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey4 {
	    0.00% {
	        opacity: 0;
	    }
	    29.80% {
	        opacity: 0;
	    }
	    30.90% {
	        opacity: 1.00;
	    }
	    99.58% {
	        opacity: 1.00;
	    }
	    99.69% {
	        opacity: 1.00;
	    }
	    99.79% {
	        opacity: 1.00;
	    }
	    99.90% {
	        opacity: 1.00;
	    }
	    100.00% {
	        opacity: 1.00;
	    }
	}
	.BX_Class3 {
	    left: -144.28px;
	    top: -159.00px;
	    width: 750px;
	    height: 746px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(-0.86,0.75);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG3 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/body1.png");
	}
	.BX_Ani3 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey3 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey3 {
	    0.00% {
	        opacity: 1.00;
	    }
	    29.80% {
	        opacity: 1.00;
	    }
	    30.90% {
	        opacity: 0;
	    }
	    99.58% {
	        opacity: 0;
	    }
	    99.69% {
	        opacity: 0;
	    }
	    99.79% {
	        opacity: 0;
	    }
	    99.90% {
	        opacity: 0;
	    }
	    100.00% {
	        opacity: 0;
	    }
	}
	.BX_Class2{
	    left: -944.00px;
	    top: 744.00px;
	    width: 1024px;
	    height: 768px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(-0.94,1.07);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG2 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/train.png");
	}
	.BX_Ani2 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey2 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey2 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.10% {
	        transform: translate3d(5.07px, -0.09px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.21% {
	        transform: translate3d(19.60px, -0.34px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.31% {
	        transform: translate3d(42.53px, -0.73px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.42% {
	        transform: translate3d(72.80px, -1.25px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.52% {
	        transform: translate3d(109.38px, -1.88px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.62% {
	        transform: translate3d(151.20px, -2.59px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.73% {
	        transform: translate3d(197.23px, -3.38px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.83% {
	        transform: translate3d(246.41px, -4.22px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    0.93% {
	        transform: translate3d(297.68px, -5.10px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.04% {
	        transform: translate3d(350.00px, -6.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.14% {
	        transform: translate3d(402.32px, -6.90px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.25% {
	        transform: translate3d(453.59px, -7.78px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.35% {
	        transform: translate3d(502.77px, -8.62px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.45% {
	        transform: translate3d(548.80px, -9.41px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.56% {
	        transform: translate3d(590.62px, -10.12px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.66% {
	        transform: translate3d(627.20px, -10.75px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.77% {
	        transform: translate3d(657.47px, -11.27px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.87% {
	        transform: translate3d(680.40px, -11.66px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    1.97% {
	        transform: translate3d(694.93px, -11.91px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    2.08% {
	        transform: translate3d(700.00px, -12.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    99.58% {
	        transform: translate3d(700.00px, -12.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    99.69% {
	        transform: translate3d(700.00px, -12.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    99.79% {
	        transform: translate3d(700.00px, -12.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    99.90% {
	        transform: translate3d(700.00px, -12.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	    100.00% {
	        transform: translate3d(700.00px, -12.00px, 0.00px) rotate(0.00deg) scale(-0.935, 1.06857142857143);
	    }
	}
	.BX_Class1 {
		left: -121.65px;
		top: -433.19px;
		width: 710px;
		height: 710px;
		transform-style: preserve-3d;
		transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(-0.39,0.34);
		opacity: 1.00;
		transform-origin:50.00% 50.00%;
		display: block;
		position: absolute; 
	}
	.BX_BG1 {
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center;
	    /*background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/head.png?v=2");*/
	}
	.BX_Ani1 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey1 96.30s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey1 {
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	    /* 		This layer has no animations to export */
	}
	.BX_Class20{
	    left: 187.72px;
	    top: -184.00px;
	    width: 86px;
	    height: 86px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(-2.93,2.56);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG20{
	    background: url("http://resali.lititutu.cn/scenes/guoqingbianlian/hat.png");
	}
	.BX_Ani20{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey20 96.30s linear 0.00s infinite normal forwards;
	}
</style>