<template>
	<div class="dangaotai poc bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
		<!-- <canvas id="page6_canvas" width="500" height="815" class="fullScreen po"></canvas> -->
		<div class="perspective poc">
			<div class="dangaotai preserve3d poc">
       			<gif :mode="mode[1]['middle']"></gif>
				<!-- <canvas id="page6_trun_canvas" width="500" height="815" class="fullScreen po"></canvas> -->
				<div class="dangaotai_1 preserve3d poc">
					<div class="dangaotai_item1 preserve3d poc">
						<div :style="getStyle" class="dangaotai_div dangaotai_1_1 poc bg" @click="wxPreview"></div>
						<div :style="getStyle" class="dangaotai_div dangaotai_1_2 poc bg" @click="wxPreview"></div>
					</div>
					<div class="dangaotai_item2 preserve3d poc">
						<div :style="getStyle" class="dangaotai_div dangaotai_1_1 poc bg" @click="wxPreview"></div>
						<div :style="getStyle" class="dangaotai_div dangaotai_1_2 poc bg" @click="wxPreview"></div>
					</div>
					<div class="dangaotai_item3 preserve3d poc">
						<div :style="getStyle" class="dangaotai_div dangaotai_1_1 poc bg" @click="wxPreview"></div>
						<div :style="getStyle" class="dangaotai_div dangaotai_1_2 poc bg" @click="wxPreview"></div>
					</div>
					<div class="dangaotai_item4 preserve3d poc">
						<div :style="getStyle" class="dangaotai_div dangaotai_1_1 poc bg" @click="wxPreview"></div>
						<div :style="getStyle" class="dangaotai_div dangaotai_1_2 poc bg" @click="wxPreview"></div>
					</div>
				</div>
			</div>
       </div>
       <!-- <canvas id="dangaotai_canvas" width="350" height="115" class="po"></canvas><span class="divText imgTxt1">非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒非常棒</span> -->
</div>
</template>

<script>
	export default {
		name: 'dangaotai',
		props: ['mode', 'currentImage', 'showStatus'],
		components: {},
		data () {
			return {}
		},
		computed: {
			getStyle () {
				let twidth, theight;

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [460, 400], 'heightFix');
					twidth = tsize[0];
					theight = tsize[1];
				} else {
					twidth = 460;
					theight = 400;
				}

				return {
					width: twidth + 'px',
					height: theight + 'px',
					backgroundImage: 'url('+this.currentImage['im']+')'
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style>
	.dangaotai .perspective {
	    perspective: 1800px!important;
	}
	.dangaotai {
	    transform: translateZ(-1800px);
	}
	.dangaotai_1 {
	    animation: dangaotai_1 12s linear infinite both;
	}
	@keyframes dangaotai_1 {
		0% {
		    transform: rotateY(360deg);
		}
		100% {
		    transform: rotateY(0);
		}
	}
	.dangaotai_item1 {
	    transform: translateX(-260px) rotateY(0deg);
	}
	.dangaotai_item2 {
	    transform: translateX(-260px) rotateY(90deg);
	}
	.dangaotai_item3 {
	    transform: translateX(-260px) rotateY(180deg);
	}
	.dangaotai_1>div {
	    transform-origin: right;
	}
	.dangaotai_item4 {
	    transform: translateX(-260px) rotateY(270deg);
	}
	.dangaotai_div {
	    border: 6px solid;
	    border-image: url(https://resali.lititutu.cn/icons/other/border.jpg);
	    border-image-slice: 6;
	    margin: auto;
	    left: 0;
	    right: 0;
	    background-size: cover;
	    background-clip: content-box;
	    /*width: 460px;*/
	    /*height: 300px;*/
	}
	.dangaotai_1_1 {
		top: 28px;
	    /*bottom: 55.5%;*/
	    transform: rotateY(90deg) translateZ(550px) rotateX(11deg);
	}
	.dangaotai_1_2 {
		top: 500px;
	    /*bottom: -6.2%;*/
	    transform: rotateY(90deg) translateZ(640px) rotateX(10deg);
	}
</style>