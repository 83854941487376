<template>
	<div class="model-page">
	    <div class="zy-zhuanyuan_page po zy-zhuangyuan_bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
	    	<template v-if="'xinnianhejiahuan' == currentTid">
		        <div class="zy-aixinBox">
		            <div class="zy-zhuanyuanAni1_box">
		                <img class="zy-center zy-shanzi" alt="" src="http://resali.lititutu.cn/icons/other/11.png">
		                <img class="zy-center zy-mengtou" alt="" src="http://resali.lititutu.cn/icons/other/12.png">
		                <img class="zy-center zy-hengbi " alt="" src="http://resali.lititutu.cn/icons/other/13.png">
		                <img class="zy-center zy-lianbu" alt="" src="http://resali.lititutu.cn/icons/other/14.png">
		                <img class="zy-zhongguojie zy-zhongguojie_left" alt="" src="http://resali.lititutu.cn/icons/other/15.png">
		                <img class="zy-zhongguojie zy-zhongguojie_right" alt="" src="http://resali.lititutu.cn/icons/other/15.png">
		                <img class="zy-center zy-zhutizi" alt="" src="http://resali.lititutu.cn/icons/other/16.png">
		            </div>
		        </div>
	    	</template>
	        <!--后面-->
	        <div class="zy-Fdiv po zy-zhuanyuan_page">
	            <div class="zy-Udiv po">
	                <div class="zy-Ldiv po" @click="wxPreview">
	                    <div class="zy-div4 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div3 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div2 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div1 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <img class="zy-borderImg zy-bor1 zy-zhuanyuan_page po" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	                <div class="zy-Rdiv po" @click="wxPreview">
	                    <div class="zy-div4 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div3 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div2 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div1 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <img class="zy-borderImg zy-bor1 zy-zhuanyuan_page po" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	                <div class="zy-Zdiv po" @click="wxPreview">
	                    <div class="zy-div4 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div3 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div2 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div1 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <img class="zy-borderImg zy-bor1 zy-zhuanyuan_page po" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	            </div>
	            <div class="zy-Ddiv po">
	                <div class="zy-Ldiv po" @click="wxPreview">
	                    <div class="zy-div4 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div3 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div2 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div1 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <img class="zy-borderImg zy-bor1 zy-zhuanyuan_page po" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	                <div class="zy-Rdiv po" @click="wxPreview">
	                    <div class="zy-div4 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div3 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div2 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div1 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <img class="zy-borderImg zy-bor1 zy-zhuanyuan_page po" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	                <div class="zy-Zdiv po" @click="wxPreview">
	                    <div class="zy-div4 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div3 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div2 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <div class="zy-div1 zy-div po bg_photo zy-uerImg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                    <img class="zy-borderImg zy-bor1 zy-zhuanyuan_page po" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	            </div>
	        </div>
	        <!--前面-->
	        <div class="zy-Bdiv zy-per po">
	            <div class="zy-zhuanyuan_page zy-pre3d po zy-duoAni" id="zy-duoAni" :style="zyAni">
	                <div class="zy-Bdiv_div1 zy-Bdiv_div po">
	                    <div class="zy-div1 zy-div po">
	                        <div class="zy-zhuanyuan_page po">
	                            <div class="zy-div_img bg_photo po zy-uerPic zy-picAni1 bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                        </div>
	                    </div>
	                    <img class="zy-borderImg zy-zhuanyuan_page po" @click="wxPreview" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	                <div class="zy-Bdiv_div3 zy-Bdiv_div po">
	                    <div class="zy-div3 zy-div po">
	                        <div class="zy-zhuanyuan_page po">
	                            <div class="zy-div_img bg_photo po zy-uerPic zy-picAni3 bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                        </div>
	                    </div>
	                    <img class="zy-borderImg zy-zhuanyuan_page po" @click="wxPreview" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	                <div class="zy-Bdiv_div5 zy-Bdiv_div po">
	                    <div class="zy-div1 zy-div po">
	                        <div class="zy-zhuanyuan_page po">
	                            <div class="zy-div_img bg_photo po zy-uerPic zy-picAni1 bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                        </div>
	                    </div>
	                    <img class="zy-borderImg zy-zhuanyuan_page po" @click="wxPreview" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	                <div class="zy-Bdiv_div7 zy-Bdiv_div po">
	                    <div class="zy-div3 zy-div po">
	                        <div class="zy-zhuanyuan_page po">
	                            <div class="zy-div_img bg_photo po zy-uerPic zy-picAni3 bg" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
	                        </div>
	                    </div>
	                    <img class="zy-borderImg zy-zhuanyuan_page po" @click="wxPreview" :src="mode[1]" v-if="$util.typeOf(mode[1]) == 'string'">
						<elem :mode="mode[1]" v-else-if="$util.typeOf(mode[1]) == 'object'"></elem>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'zhuanyuan',
		props: ['mode', 'currentImage'],
		data () {
			return {
				aniEvent: null,
				zyAni: 'animation: 3s ease-in-out 0s 1 normal both running page1_div1_in'
			}
		},
		destroyed () {
			clearInterval(this.aniEvent);
		},
		created () {
			let aniIdx = 1;
			clearInterval(this.aniEvent);
			this.aniEvent = setInterval(() => {
				aniIdx ++;

				this.zyAni = 'animation: 3s ease-in-out 0s 1 normal both running page1_div'+ aniIdx +'_in';

				if (aniIdx >= 5) {
					aniIdx = 0;
				}
			}, 4000);
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
		computed: {
			currentTid () {
				return this.$store.state.config.data['ttid'];
			}
		}
	}
</script>

<style lang="less">
	.zy-zhuanyuan_page {
	    width: 100%;
	    height: 100%;
	}
	.zy-zhuangyuan_bg, .zy-uerImg {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.zy-pre3d {
	    transform-style: preserve-3d;
	}
	.zy-div {
	    margin: auto;
	    width: 78%;
	    height: 78%;
	    border-radius: 50%;
	    background-size: cover;
	    background-position: 50% 10%;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    overflow: hidden;
	}
	.zy-div>div {
	    border-radius: 50%;
	    overflow: hidden;
	}
	.zy-Fdiv .zy-div {
	    transform: translateZ(0);
	}
	.zy-Ldiv, .zy-Rdiv, .zy-Zdiv {
	    width: @bw * 0.361;
	    height: @bw * 0.361;
	}
	.zy-Ldiv {
	    left: -@bw * 0.049;
	}
	.zy-Rdiv {
	    right: -@bw * 0.049;
	}
	.zy-Zdiv {
	    left: 50%;
	    margin-left: -90px;
	}
	.zy-Mdiv {
	    margin: auto;
	    width: @bw * 0.278;
	    height: @bw * 0.278;
	    left: @bw * 0.028;
	    right: 0;
	    bottom: -@bw * 0.339;
	}
	.zy-Udiv {
	    width: @bw * 1.0;
	}
	.zy-Udiv .zy-Ldiv, .zy-Udiv .zy-Rdiv {
	    top: @bw * 0.062;
	}
	.zy-Udiv .zy-Mdiv {
	    top: -@bw * 0.042;
	}
	.zy-Ddiv {
	    width: @bw * 1.0;
	    bottom: 0;
	}
	.zy-Ddiv .zy-Ldiv, .zy-Ddiv .zy-Rdiv {
	    bottom: @bw * 0.12;
	}
	.zy-Ddiv .zy-Zdiv {
		bottom: @bw * 0.062;
	}
	.zy-Ddiv .zy-Mdiv {
	    bottom: -@bw * 0.014;
	    left: 0;
	}
	// .zy-Ddiv .zy-Rdiv {
	//     bottom: @bw * 0.062;
	// }
	.zy-Bdiv {
	    margin: auto;
	    width: @bw * 1.0;
	    height: @bw * 1.0;
	    top: 0;
	    bottom: 0;
	}
	.zy-Bdiv>div {
	    transform: translateZ(-@bw * 0.903) rotateY(-22.5deg);
	}
	.zy-per {
	    perspective: @bw * 3.0;
	}
	.zy-Bdiv_div {
	    margin: auto;
	    width: @bw * 1.056;
	    height: @bw * 1.056;
	    top: 0;
	    left: -4.5px;
	    bottom: 0;
	}
	.zy-Bdiv_div1 {
	    transform: rotateY(0deg) translateZ( @bw * 1.25);
	    animation:3s ease-in-out .1s 1 normal both running zy-aniscal1
	}
	.zy-Bdiv_div3 {
	    transform: rotateY(-90deg) translateZ( @bw * 1.25);
	}
	.zy-Bdiv_div5 {
	    transform: rotateY(-180deg) translateZ( @bw * 1.25);
	}
	.zy-Bdiv_div7 {
	    transform: rotateY(-270deg) translateZ( @bw * 1.25);
	}
	.divlitton {
	    width: @bw * 0.833;
	    height: @bw * 0.833;
	    left: @bw * 0.167;
	}
	// @keyframes rot_z {
	//     0% {
	//         transform: rotate(0);
	//     }
	//     100% {
	//         transform: rotate(360deg);
	//     }
	// }
	@keyframes rot_f {
	    0% {
	        transform: rotate(360deg);
	    }
	    100% {
	        transform: rotate(0);
	    }
	}
	// @keyframes rotY {
	//     0% {
	//         transform: translateZ(-@bw * 0.903) rotateY(0);
	//     }
	//     100% {
	//         transform: translateZ(-@bw * 0.903) rotateY(360deg);
	//     }
	// }
	@keyframes page1_div1_in {
	    0% {
	        transform: translateZ(-@bw * 0.903) rotateY(0deg);
	    }
	    100% {
	        transform: translateZ(-@bw * 0.903) rotateY(0deg);
	    }
	}
	@keyframes page1_div2_in {
	    0% {
	        transform: translateZ(-@bw * 0.903) rotateY(0deg);
	    }
	    100% {
	        transform: translateZ(-@bw * 0.903) rotateY(90deg);
	    }
	}
	@keyframes page1_div3_in {
	    0% {
	        transform: translateZ(-@bw * 0.903) rotateY(90deg);
	    }
	    100% {
	        transform: translateZ(-@bw * 0.903) rotateY(180deg);
	    }
	}
	@keyframes page1_div4_in {
	    0% {
	        transform: translateZ(-@bw * 0.903) rotateY(180deg);
	    }
	    100% {
	        transform: translateZ(-@bw * 0.903) rotateY(270deg);
	    }
	}
	@keyframes page1_div5_in {
	    0% {
	        transform: translateZ(-@bw * 0.903) rotateY(270deg);
	    }
	    100% {
	        transform: translateZ(-@bw * 0.903) rotateY(360deg);
	    }
	}
	// @keyframes div_rotY {
	//     0% {
	//         transform: rotateY(0deg);
	//     }
	//     100% {
	//         transform: rotateY(360deg);
	//     }
	// }
	// @keyframes div_trX {
	//     0% {
	//         transform: translateX(0);
	//     }
	//     100% {
	//         transform: translateX(@bw * 3.75);
	//     }
	// }
	.zy-borderImg {
	    animation: 10s linear 0s infinite normal both running rot_f;
	}
	.zy-picAni1 {
	    width: 100%;
	    height: 100%;
	    transform: translate( @bw * 0.0, @bw * 0.0);
	    animation: 6000ms linear 2000ms 1 normal both running page1_div1;
	    // background: no-repeat center;
	    // background-size: cover;
	}
	.zy-picAni2 {
	    width: 100%;
	    height: 100%;
	    transform: translate( @bw * 0.0, @bw * 0.0);
	    animation: 6000ms linear 2000ms 1 normal both running page1_div2;
	    // background: no-repeat center;
	    // background-size: cover;
	}
	.zy-picAni3 {
	    width:100%;
	    height:100%;
	    transform: translate(@bw * 0.0,@bw * 0.0);
	    animation: 6000ms linear 2000ms 1 normal both running page1_div3;
	    // background: no-repeat center;
	    // background-size: cover;
	}
	@keyframes zy-aniscal1 {
	    0% {
	        transform:rotateY(0deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform:rotateY(0deg) translateZ(@bw * 1.25) scale(1);
	    }
	}
	@keyframes zy-aniscal2 {
	    0% {
	        transform:rotateY(-45deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform: rotateY(-45deg) translateZ(@bw * 1.25)scale(1);
	    }
	}
	@keyframes zy-aniscal3 {
	    0% {
	        transform:rotateY(-90deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-90deg) translateZ(@bw * 1.25) scale(1);
	    }
	}
	@keyframes zy-aniscal4 {
	    0% {
	        transform:rotateY(-135deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-135deg) translateZ(@bw * 1.25) scale(1);
	    }
	}
	@keyframes zy-aniscal5 {
	    0% {
	        transform:rotateY(-180deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-180deg) translateZ(@bw * 1.25) scale(1);
	    }
	}
	@keyframes zy-aniscal6 {
	    0% {
	        transform:rotateY(-225deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-225deg) translateZ(@bw * 1.25) scale(1);
	    }
	}
	@keyframes zy-aniscal7 {
	    0% {
	        transform:rotateY(-270deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-270deg) translateZ(@bw * 1.25) scale(1);
	    }
	}
	@keyframes zy-aniscal8 {
	    0% {
	        transform:rotateY(-315deg) translateZ(@bw * 1.25) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-315deg) translateZ(@bw * 1.25) scale(1);
	    }
	}
	.zy-duoAni {
	    transition: all .5s;
	}
	.zy-uerPic {
	    // background: no-repeat center;
	    // background-size: cover;
	}

	.zy-zhuanyuanAni1_box{
        position: absolute;
        width: 100%;
        height: 100%;

    }
    .zy-center{
        position: absolute;
        left: 50%;
        top: 0;

    }
    .zy-hengbi{
        width:@bw * 0.583;
        margin-left: -@bw * 0.292;
        top:@bw * 0.049;
        animation: zy-tranleft 1s linear infinite alternate;
        transition: .1s;

    }
  
    .zy-shanzi{
       
        width:@bw * 0.653;
        margin-left: -@bw * 0.326;
        top: -16%;
        animation: zy-tranrotate 11s linear infinite both;
        transition: .1s;

    }
    .zy-mengtou{
        width:@bw * 1.036;
        margin-left: -@bw * 0.525;
    }
    .zy-lianbu{
        width:@bw * 1.042;
        margin-left: -@bw * 0.521;
        top:@bw * 0.0;

    }
    .zy-zhutizi{
        width:@bw * 0.492;
        top: 41%;
        transform: translate(-50%,-50%);
        animation: zy-trantop 1s linear infinite alternate;
        transition: .1s;

    }

    @keyframes zy-trantop {
        0% {
            top:42%;
        }

      
        100% {
            top:40%;
        }
    }
    @keyframes zy-tranleft {
        0% {
            left:49%;
        }

      
        100% {
            left:51%;
        }
    }
    @keyframes zy-tranrotate {
        0% {
            transform:rotate(0)
        }

        100% {
            transform:rotate(360deg)
        }
    }

    @keyframes zy-tranrotop1{
        0% {
             top:@bw * 0.288;
        }

        100% {
            top:@bw * 0.311;
        }
    }

    @keyframes zy-tranrotop2{
        0% {
            top:@bw * 0.339;
        }

      
        100% {
            top:@bw * 0.361;
        }
    }


    .zy-zhongguojie_left{
        left: 0%;
        animation: zy-tranrotop1 1s linear infinite alternate;
        transition: .1s;
    }
    .zy-zhongguojie{
        position: absolute;
        width:@bw * 0.25;
        top:@bw * 0.288;
    }
    .zy-zhongguojie_right{
        right:@bw * 0.083;
        top:@bw * 0.339;
        animation: zy-tranrotop2 1s linear infinite alternate;
        transition: .1s;
    }
</style>