<template>
	<div class="inter">
        <div class="intercon">
            <img class="interani" :src="mode[0]">
        </div>
	</div>
</template>

<script>
	export default {
        name: 'zhuanzhong',
        props: ['mode', 'showStatus'],
        data () {
            return {}
        },
        watch: {
            showStatus: {
                handler () {
                    if (this.showStatus) {
                        setTimeout(() => {
                            this.$emit('endInter');
                        }, 2000);
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style scoped lang="less">
	.inter {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		z-index: 8;
		pointer-events: none;
	}
    .intercon {
        position: absolute;
        width: 300px;
        height: 300px;
        top: 50%; left: 50%;
        margin-top: -150px;
        margin-left: -150px;
        animation: intercon 2s ease-out both;
    }
    @keyframes intercon {
        from {
            opacity: 0;
            transform: scale(.8);
        }
        10% {
            opacity: 1;
        }
        20% {
            opacity: 1;
            transform: scale(2.5);
        }
        80% {
            opacity: 1;
            transform: scale(2.5);
        }
        90% {
            opacity: 1;
        }
        to {
            opacity: 0;
            transform: scale(.8);
        }
    }
    .interani{
        position: absolute;
        width: 100%; height: 100%;
        top: 0; left: 0;
        animation: interani 6s linear both infinite;
    }
    @keyframes interani {
        0%{
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>