<template>
	<elem
		v-if="page"
		:mode="page" 
		:root="true"></elem>
</template>

<script>

export default {
	name: 'Page',
	data () {
		return {
			page: null
		}
	},
	props: {
		pid: {
			type: String,
			default: ""
		}
	},
	components: {
	},
	created () {
		this.init();
	},
	methods: {
		init (try_times) {
			try_times = try_times === undefined ? 0 : try_times;

			let pid = this.pid || this.$util.$router.params.pid || 'stpage_st_helloworld';

			// 兼容老的pid
			pid = pid.indexOf("stpage") === 0 ? pid : 'stpage_' + pid;

			this.$util.$config.center.get_elem({
				cid: pid,
			}, res=>{
				// 骨架屏预渲染
				if (res && res.mode=="skeleton") {
					this.page = res['elem']['mode'];
				}
			}).then(config => {
				config.mode['pid'] = pid;
				this.page = config.mode;
			}).catch(err => {
				if (try_times < 3) {
					// try again
					setTimeout(() => {
						this.init(++try_times);
					}, 1500);
				} else {
					this.page = {
						"elems": [
							{
								"is":"word",
								"text":"因网络问题，页面加载失败，点击重试",
								"style": {
									"text-decoration": "underline",
									"color": "#030389"
								}
							},{
								"is": "stButton",
								"comp": {
									"config": {
										"rconfig": {
											"condition": {
												"if": "else",
												"do": "link",
												"path": "/pages/index/index",
												"method": "reLaunch"
											}
										}
									}
								},
							}
						]
					};
					this.$emit("onError", err);
				}
			})

			this.$util.$loger.log("uni_pv",{
				"pid": pid
			})
		}
	}
}
</script>

<style>

</style>
