<template>
    <div class="st-open-tag poc" :id="_getStid + '_opentag'">
        <span style="position: absolute; font-size: 20px;" v-show="$util.$config.ISDEBUG && myWidth == 0">open-tag没拿到到宽高无法渲染，请检查组件初始化时父节点的宽高，或显式传入tag_size</span>
        <div v-if="!(tag_ready && can_use_open_tag) && getTagHtml" class="poc" v-html="getTagHtml"></div>
        <slot></slot>
        <template v-if="can_use_open_tag && tag_text">
            <template v-if="tag == 'launch_weapp' && getOriginid">
                <!-- <wx-open-launch-weapp :username="getOriginid" :path="path" @ready="handleReady" @launch="handleLaunch" @error="handleError">
                    <script type="text/wxtag-template"><style></style><div style="z-index: 1; width: 50px; height: 50px; background-color: red;"></div></script>
                </wx-open-launch-weapp> -->
                <wx-open-launch-weapp :id="getId" :username="getOriginid" :path="path" @ready="handleReady" @launch="handleLaunch" @error="handleError">
                    <script type="text/wxtag-template">{{tag_text}}</script>
                </wx-open-launch-weapp>
            </template>
            <template v-if="tag == 'subscribe' && getTemplateids">
                <!-- <wx-open-subscribe :template="getTemplateidsStr">
                    <script type="text/wxtag-template"><style></style><div style="z-index: 1;width: 50px; height: 50px; background-color: blue;"></div></script>
                </wx-open-subscribe> -->
                <wx-open-subscribe :id="getId" :template="getTemplateidsStr">
                    <script type="text/wxtag-template">{{tag_text}}</script>
                </wx-open-subscribe>
            </template>
        </template>
    </div>
</template>

<script>
    let openTagsAble = true;
    import StBaseComp from "../StBaseComp"
    export default {
        name: 'stopentag',
        extends: StBaseComp,
        props: {
            tag: {
                type: String,
                default: "launch_weapp", //launch_weapp/subscribe/audio
            },
            originid: {
                type: String,
                default: "",
            },
            appid: {
                type: String,
                default: "",
            },
            path: {
                type: String,
                default: "",
            },
            html: {
                type: String,
                default: "",
            },
            tag_size: {
                type: Array,
                default () {
                    return [0, 0]
                }
            },
            tagstyle: {
                type: String,
                default: ""
            },
            channel_ids: {
                type: Array,
                default () {
                    return []
                }
            },
            log: {
                type: [String, Object],
            },
        },
        data () {
            return {
                can_use_open_tag: true,
                tag_ready: false,
                tag_originid: "",
                tag_text: null,
                listener_ready: false,
            }
        },
        watch: {
			tag_size: {
				handler (nv, ov) {
                    this.getSize();
                }
            }
        },
        computed: {
            myWidth () {
                return this.tag_size[0] ? this.tag_size[0] : 0;
            },
            myHeight () {
                return this.tag_size[1] ? this.tag_size[1] : 0;
            },
            getTagHtml () {
                return this.tag_ready || this.can_use_open_tag ? '' : ('<style>' + this.tagstyle + '</' + 'style>' + this.html);
            },
            getId () {
                return this.tag + "_" + this._getStid;
            },
            getTemplateids () {
                return this.$util.requareTemplate({
                    "ids": this.channel_ids
                });
            },
            getTemplateidsStr () {
                return this.getTemplateids.join(",")
            },
            getOriginid () {
                if (this.originid){
                    return this.originid;
                } else if (this.appid) {
                    return this.$util.$config.st_config['origin_id_map'] && this.$util.$config.st_config['origin_id_map'][this.appid];
                } else {
                    return ""
                }
            }
        },
        mounted () {
            if (!openTagsAble || (this.tag == "launch_weapp" && !this.getOriginid)) { //不加载开放标签
                this.can_use_open_tag = false;
            } else if (this.tag == "subscribe" && (!this.getTemplateids || this.getTemplateids.length < 1)) {
                this.can_use_open_tag = false;
            } else { //可加载
                // this.initBtn();
                this.getSize();
            }
        },
        methods: {
            initBtn (options) {
                let _this = this,
                    {
                        is_again
                    } = options || {};
                var btn = document.getElementById(this.getId);
                if (this.listener_ready) {
                    return;
                }
                if (!btn) {
                    if (is_again) {
                        console.warn("【StOpenTag ERROR】btn init fail not btn hint. id : ==>", this.getId);
                    } else {
                        setTimeout(() => {
                            _this.initBtn({
                                "is_again": true
                            })           
                        }, 800);
                    }
                    return;
                }
                this.listener_ready = true;
                if (this.tag == "subscribe"){
                    this.tag_ready = true;
                    btn.addEventListener('success', function (e) {
                        _this.$emit("clickHandle",{
                            "status": "success"
                        })
                        // _this.$util.$loger.log("subscirbe_message",{})
                        _this.$util.$loger.log("uni_submessage",{})
                    });   
                    btn.addEventListener('error',function (e) {
                        _this.$emit("clickHandle",{
                            "status": "cancel"
                        })
                    });
                } else if (this.tag == "launch_weapp") {
                    btn.addEventListener('launch', function (e) {
                        setTimeout(() => {
                            if (document.visibilityState == 'hidden') {
                                _this.$util.$loger.log("uni_openwxa", {
                                    "appid": _this.appid
                                })
                            } else {
                                _this.$emit("clickHandle",{
                                    "status": "cancel"
                                })
                            }
                        }, 800);
                        if (_this.log) {
                            // 自定义埋点
                            let key = "",
                                params = {};
                            if (_this.$util.typeOf(_this.log) == "object") {
                                params = Object.assign(params, _this.log.params)
                                key = _this.log.key;
                            } else {
                                key = _this.log;
                            }
                            _this.$util.$loger.log(key, params);
                        }
                    })
                    btn.addEventListener('error',function (e) {
                        _this.$emit("clickHandle",{
                            "status": "cancel"
                        })
                    });
                }
            },
            handleReady (options) {
                this.tag_ready = true;
                this.initBtn();
                this.$emit("ready", options)
            },
            handleLaunch (options) {
                this.$emit("launch", options)
            },
            handleError (err) {
                this.$util.$loger.warn("【StOpenTag ERROR】 handleError err ==>", err);
            },
            getSize () {
                let width = this.myWidth,
                    height = this.myHeight;
                // var btn = document.getElementById(this.getId);
                if (width && height) {
                    // let tag_html = this.html ? this.html : ('<div style="z-index: 9; width: ' + width + 'px; height: ' + height + 'px;' + 'background-color: red;' + '">' + this.getId + 'width:' + width + ' height:' + height + '</div>');
                    let tag_html = this.html ? this.html : ('<div style="z-index: 9; width: ' + width + 'px; height: ' + height + 'px;' + '' + '"></div>');
                    this.tag_text = '<style></style>' + tag_html;
                }
                this.initBtn();
                // if (btn) {
                //     // this.tag_text = '<style></style>' + tag_html;
                //     btn.innerHTML = '<script type="text/wxtag-template"><style></style>' + tag_html + '</' + 'script>';
                // } else {
                //     console.warn("【StOpenTag ERROR】cannot find size to set wxtag: ", this.getId)
                // }
            }
        },
    }
</script>

<style>
.st-open-tag{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
</style>
