<template>
	<div class="duolingxing" :style="{backgroundImage: getBackgroundImage}">
		<div class="Bpage4_2_1 perspective page po none" style="display: block;">
		    <div class="page4_2 page4_2_1 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		</div>
		<div class="Bpage4_2_2 perspective page po none" style="display: block;">
		    <div class="page4_2 page4_2_2 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		</div>
		<div class="Bpage4_2_3 perspective page po none" style="display: block;">
		    <div class="page4_2 page4_2_3 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		    <div class="page4_2 page4_2_4 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		</div>
		<div class="Bpage4_1_2 perspective page po none" style="display: block;">
		    <div class="page4_1 page4_1_2 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		    <div class="page4_1 page4_1_3 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		</div>
		<div class="Bpage4_1_1 perspective page po none" style="display: block;">
		    <div class="page4_1 page4_1_1 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		    <div class="page4_1 page4_1_4 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		</div>
		<div class="Bpage4_0 perspective page po none">
		    <div class="page4_0 preserve3d po">
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		        <div class="page div6 preserve3d _page4 div po" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
		    </div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'duolingxing',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		computed: {
			getBackgroundImage () {
				if (this.mode.length && this.mode[0]) {
					return 'url('+this.mode[0]+')';
				} else {
					return 'none';
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped>
	.page {
		width: 100%;
		height: 100%;
	}
	.duolingxing {
		position: absolute;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.perspective {
	    perspective: 448px;
	}
	.preserve3d {
	    transform-style: preserve-3d;
	}
	.div {
	    background-size: cover;
	    background-position: center 10%;
	    background-repeat: no-repeat;
	}
	.page4_2_1 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 100px;
	    right: 0;
	    width: 121.125px; 
	    height: 161.62px;
	}
	.page4_2_2 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 100px;
	    right: 0;
	    width: 121.125px;
	    height: 161.62px;
	}
	.page4_2_3 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 121.125px; 
	    height: 161.62px;
	}
	.page4_2_4 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    width: 121.125px; 
	    height: 161.62px;
	}
	.page4_1_2 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    right: -41px;
	    width: 175.95px; 
	    height: 235px;
	}
	.page4_1_3 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 40px;
	    width: 175.95px; 
	    height: 235px;
	}
	.page4_1_1 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    right: -41px;
	    width: 175.95px;
	    height: 235px;
	}
	.page4_1_4 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 40px;
	    width: 175.95px; 
	    height: 235px;
	}
	.page4_0 {
	    margin: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    width: 320.45px; 
	    height: 427.74px;
	}

	.Bpage4_2_3, .Bpage4_2_2, .Bpage4_2_1 {
	    animation: fadeIn 0.6s 1.2s linear both;
	}
	.Bpage4_1_1, .Bpage4_1_2 {
	    -webkit-animation: fadeIn 0.6s 0.6s linear both;
	}
	.Bpage4_2_1 {
	    bottom: 27.5%;
	}
	.page4_0, .page4_1, .page4_2 {
	    -webkit-animation: page4_1 8.5s linear infinite both;
	}
	.page4_2>.div:nth-of-type(1) {
	    -webkit-transform: rotateY(90deg) translateZ(62px);
	}
	.page4_2>.div:nth-of-type(2) {
	    -webkit-transform: rotateY(180deg) translateZ(62px);
	}
	.page4_2>.div:nth-of-type(3) {
	    -webkit-transform: rotateY(270deg) translateZ(62px);
	}
	.page4_2>.div:nth-of-type(4) {
	    -webkit-transform: translateZ(62px);
	}
	._page4 {
	    border: 3px solid #FFFFFF;
	    background-clip: content-box;
	}

	.Bpage4_2_2 {
	    top: 27.5%;
	}


.Bpage4_1_2 {
    bottom: 32%;
}

.page4_1>.div:nth-of-type(1) {
    -webkit-transform: rotateY(90deg) translateZ(91px);
}
.page4_1>.div:nth-of-type(2) {
    -webkit-transform: rotateY(180deg) translateZ(91px);
}
.page4_1>.div:nth-of-type(3) {
    -webkit-transform: rotateY(270deg) translateZ(91px);
}
.page4_1>.div:nth-of-type(4) {
    -webkit-transform: translateZ(91px);
}

.Bpage4_1_1 {
    top: 32%;
}

.Bpage4_0 {
    -webkit-animation: fadeIn 0.6s linear both;
}
.page4_0>.div:nth-of-type(1) {
    -webkit-transform: rotateY(90deg) translateZ(164px);
}
.page4_0>.div:nth-of-type(2) {
    -webkit-transform: rotateY(180deg) translateZ(164px);
}
.page4_0>.div:nth-of-type(3) {
    -webkit-transform: rotateY(270deg) translateZ(164px);
}
.page4_0>.div:nth-of-type(4) {
    -webkit-transform: translateZ(164px);
}
.page4_0>div {
    border: 4px solid #FFFFFF;
}

	@keyframes page4_1 {
		0% {
		    -webkit-transform: rotateY(0deg);
		}
		100% {
		    -webkit-transform: rotateY(360deg);
		}
	}
	@keyframes fadeIn {
		0% {
		    opacity: 0;
		}
		100% {
		    opacity: 1;
		}
	}
</style>