<template>
    <div class="diqiuyuan_box">
        <div class="diqiuyuan_bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
            <!-- <canvas id="diqiuyuan_canvas" height="1169" width="720"></canvas> -->
            <div class="litbor">
                <div class="border_litbor" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                <!-- <canvas id="chuntext" class="litbor1"></canvas> -->
                <div class="litborimg" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
            </div>
            <div class="userImg_box" id="box">
                <div class="userImg userImg1" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                    <div class="userborder" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
                </div>
                <div class="userImg userImg2" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                    <div class="userborder" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
                </div>
                <div class="userImg userImg3" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                    <div class="userborder" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
                </div>
                <div class="userImg userImg4" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                    <div class="userborder" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
                </div>
                <div class="userImg userImg5" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                    <div class="userborder" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
                </div>
                <div class="userImg userImg6" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                    <div class="userborder" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'nianlun',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		wxPreview () {
			this.$emit('wxPreview', this.currentImage)
		}
	}
</script>

<style scoped lang="less">
	.diqiuyuan_box {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.diqiuyuan_canvas {
	    position: absolute;
	    top: 0;
	}
	.diqiuyuan_bg {
	    width: 100%;
	    height: 100%;
	    background: no-repeat center;
	    background-size: cover;
	}
	.litbor {
	    position: absolute;
	    top: 0;
	    right: 0;
	    width:@bw * 0.694;
	    height:@bw * 0.694;
	}
	// .litbor1, .litborimg {
	//     width:@bw * 0.403;
	//     display: block;
	//     height:@bw * 0.431;
	//     left: 23%;
	//     position: relative;
	//     top:@bw * 0.132;
	// }
	.border_litbor {
	    width: 100%;
	    height: 100%;
	    background: no-repeat center;
	    background-size: cover;
	    position: absolute;
	}
	.userImg_box {
	    height:@bw * 0.694;
	    transform-style: preserve-3d;
	    top: 0;
	    right: 0;
	    width:@bw * 0.694;
	    position: absolute;
	    /* animation: rotateBorder 19s linear infinite; */
	}
	.border_litbor, .userImg_box {
	    animation: rotateBorderTest 4s linear infinite forwards
	}
	.userImg {
	    height:@bw * 0.833;
	    width:@bw * 0.556;
	    position: absolute;
	    background: no-repeat center;
	    opacity: 1;
	    background-size: cover;
	}
	.userborder {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    background: no-repeat center;
	    background-size: 100% 100%;
	}
	.userImg1 {
	    transform: rotateZ(0) translate(@bw * 0.083,@bw * 0.694);
	    opacity: 1;
	}
	.userImg6 {
	    transform: rotateZ(64deg) translateY(@bw * 0.667);
	}
	.userImg5 {
	    transform: rotateZ(129deg) translate(-@bw * 0.043,@bw * 0.751);
	}
	.userImg4 {
	    transform: rotateZ(182deg) translate(-@bw * 0.058,@bw * 0.833);
	}
	.userImg3 {
	    transform: rotateZ(-124deg) translate(-@bw * 0.032,@bw * 0.862);
	}
	.userImg2 {
	    transform: rotateZ(-65deg) translate(@bw * 0.042,@bw * 0.8);
	}
	@keyframes rotateBorderTest {
	    0% {
	        transform:rotate(0deg);
	    }
	    40% {
	        transform:rotate(30deg);
	    }
	    /* 40% {
	                transform:rotate(0deg);
	            } */
	    60% {
	        transform:rotate(400deg);
	    }
	    100% {
	        transform:rotate(360deg);
	    }
	}
	// .litborimg {
	//     top: -62%;
	//     width:@bw * 0.694;
	//     height:@bw * 0.694;
	//     left: 0;
	//     background-size: cover;
	// }
	.litborimg {
		position: relative;
		width: 200px;
		height: 200px;
		top: 50%; left: 50%;
		margin-left: -100px; margin-top: -100px;
		background-position: center;
		background-size: cover;
	}
</style>