<template>
	<div class="shan3_ball" :style="getStyles">
	    <img v-for="(idx, index) in 50" 
			:class="'shan3_ani'+idx" 
			:key="'shan3_ani'+idx" 
			:src="getLuoItem(index)" 
			:style="getLuoItemStyle(index)">
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'shan3',
		extends: BaseLuo,
	}
</script>

<style>
	.shan3_ball {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    pointer-events: none;
	}
	.shan3_ball img {
	    position: absolute
	}
	.shan3_ani1 {
	    left: 443px;
	    animation: shan3_ballup1 0.5s -5643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite alternate;
	    bottom: 50px;
	}
	.shan3_ani2 {
	    left: 148px;
	    animation: shan3_ballup2 0.5s -7643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite alternate;
	    bottom: 80px;
	}
	.shan3_ani3 {
	    left: 291px;
	    animation: shan3_ballup2 0.5s -2643ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite alternate;
	    bottom: 210px;
	}
	.shan3_ani4 {
	    left: 62px;
	    animation: shan3_ballup2 0.5s -3843ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite alternate;
	    bottom: 200px;
	}
	.shan3_ani5 {
	    left: 344px;
	    animation: shan3_ballup3 2s -1232.3ms linear infinite;
	    bottom: 450px;
	}
	.shan3_ani6 {
	    left: 62px;
	    animation: shan3_ballup3 2.55s -8843s linear infinite;
	    bottom: 490px;
	}
	.shan3_ani7 {
	    left: 165px;
	    animation: shan3_ballup2 0.5s -4843ms cubic-bezier(0.15, 0.15, 0.34, 0.16) infinite alternate;
	    bottom: 600px;
	}
	.shan3_ani8 {
	    left: 455px;
	    animation: shan3_ballup3 3s -7852ms linear infinite;
	    bottom: 700px;
	}
	.shan3_ani9 {
	    left: 229px;
	    animation: shan3_ballup3 2.15s -3423ms linear infinite;
	    bottom: 1000px;
	}
	.shan3_ani10 {
	    left: 119px;
	    animation: shan3_ballup3 2.65s -3103ms linear infinite;
	    top: 540px;
	}
	.shan3_ani11 {
	    left: 129px;
	    animation: shan3_ballup2 0.5s -2323.3ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite alternate;
	    top: 400px;
	}
	.shan3_ani12 {
	    left: 350px;
	    animation: shan3_ballup1 0.5s -4535.23ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite alternate;
	    top: 460px;
	}
	.shan3_ani13 {
	    left: 590px;
	    animation: shan3_ballup1 0.5s -1266ms linear infinite;
	    top: 700px;
	}
	.shan3_ani14 {
	    left: 500px;
	    animation: shan3_ballup1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 400px;
	}
	.shan3_ani15 {
	    left: 300px;
	    animation: shan3_ballup1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 600px;
	}
	.shan3_ani16 {
	    left: 543px;
	    animation: shan3_ballup3 2.65s -1266ms linear infinite;
	    top: 500px;
	}
	.shan3_ani17 {
	    left: 300px;
	    animation: shan3_ballup3 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 800px;
	}
	.shan3_ani18 {
	    left: 450px;
	    animation: shan3_ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 864px;
	}
	.shan3_ani19 {
	    left: 350px;
	    animation: shan3_ballup3 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 1000px;
	}
	.shan3_ani20 {
	    left: 213px;
	    animation: shan3_ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 1100px;
	}
	.shan3_ani21 {
	    left: 53px;
	    animation: shan3_ballup1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 800px;
	}
	.shan3_ani22 {
	    left: 113px;
	    animation: shan3_ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 1005px;
	}
	.shan3_ani23 {
	    left: 660px;
	    animation: shan3_ballup1 1.35s -1266ms linear infinite;
	    top: 293px;
	    animation: shan3_opacity-change 1.75s ease-in-out infinite;
	}
	.shan3_ani24 {
	    left: 360px;
	    animation: shan3_ballup2 1.35s -1266ms linear infinite;
	    top: 193px;
	}
	.shan3_ani25 {
	    animation: shan3_opacity-change 1.75s ease-in-out infinite;
	}
	.shan3_ani26 {
	    left: 262px;
	    animation: shan3_ballup4 3.5s -1233.3ms linear infinite;
	    top: 100px;
	}
	.shan3_ani27 {
	    left: 230px;
	    animation: shan3_ballup3 1.75s -12.3ms linear infinite;
	    top: 400px;
	}
	.shan3_ani28 {
	    left: 183px;
	    animation: shan3_ballup1 0.5s -4533ms cubic-bezier(0.77, 0.75, 0.14, 0.34) infinite alternate;
	    top: 900px;
	}
	.shan3_ani29 {
	    left: 284px;
	    animation: shan3_ballup3 2.25s -2324ms linear infinite;
	    bottom: 600px;
	}
	.shan3_ani30 {
	    left: 477px;
	    animation: shan3_ballup2 0.5s -6324ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite alternate;
	    top: 950px;
	}
	.shan3_ani31 {
	    left: 430px;
	    animation: shan3_ballup2 2.5s -2104ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite alternate;
	    bottom: 500px;
	}
	.shan3_ani32 {
	    left: 630px;
	    animation: shan3_ballup1 0.5s -4024ms cubic-bezier(0.17, 0.25, 0.74, 0.64) infinite alternate;
	    bottom: 400px;
	}
	.shan3_ani33 {
	    left: 330px;
	    animation: shan3_ballup3 2.5s -2310ms linear infinite;
	    bottom: 60px;
	}
	.shan3_ani34 {
	    left: 30px;
	    animation: shan3_ballup1 0.5s -1310ms cubic-bezier(0.67, 0.45, 0.24, 0.54) infinite alternate;
	    bottom: 900px;
	}
	.shan3_ani35 {
	    left: 600px;
	    animation: shan3_ballup3 3.15s -4310ms linear infinite;
	    bottom: 900px;
	}
	.shan3_ani36 {
	    left: 400px;
	    animation: shan3_ballup2 0.5s -1210ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite alternate;
	    bottom: 950px;
	}
	.shan3_ani37 {
	    left: 600px;
	    animation: shan3_ballup2 0.5s -1216ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite alternate;
	    top: 100px;
	}
	.shan3_ani38 {
	    left: 650px;
	    animation: shan3_ballup2 0.5s -6745.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 500px;
	}
	.shan3_ani39 {
	    left: 200px;
	    animation: shan3_ballup2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top: 700px;
	}
	.shan3_ani40 {
	    left: 403px;
	    animation: shan3_ballup3 2.65s -1286ms linear infinite;
	    top: 740px;
	}
	.shan3_ani41 {
	    left: 423px;
	    animation: shan3_ballup1 2.15s -126ms linear infinite;
	    top: 240px;
	}
	.shan3_ani42 {
	    left: 427px;
	    animation: shan3_ballup3 1.65s -1326ms linear infinite;
	    top: 540px;
	}
	.shan3_ani43 {
	    left: 623px;
	    animation: shan3_ballup2 2s -198ms linear infinite;
	    top: 1113px;
	}
	.shan3_ani44 {
	    left: 603px;
	    animation: shan3_ballup2 2.12s -1266ms linear infinite;
	    top: 863px;
	}
	.shan3_ani45 {
	    left: 93px;
	    animation: shan3_ballup3 2.56s -1266ms linear infinite;
	    top: 10px;
	}
	.shan3_ani46 {
	    position: absolute;
	    left: 3px;
	    top: 1080px;
		background-repeat: no-repeat;
	    background-position: center;
	    width: 155px;
	    height: 155px;
	    animation: shan3_opacity-change 1s ease-in-out infinite;
	}
	.shan3_ani47 {
	    left: 1px;
	    animation: shan3_ballup1 1.15s -1266ms linear infinite;
	    top: 320px;
	}
	.shan3_ani48 {
	    left: 30px;
	    animation: shan3_ballup2 1.05s -1266ms linear infinite;
	    top: 620px;
	}
	.shan3_ani49 {
	    left: 310px;
	    animation: shan3_ballup1 1.75s -1266ms linear infinite;
	    top: 1070px;
	}
	.shan3_ani50 {
	    left: 160px;
	    animation: shan3_ballup1 1.85s -1266ms linear infinite;
	    top: 270px;
	}
	@keyframes shan3_ballup1 {
	    0% {
	        transform: scale(0)
	    }
	    100% {
	        transform: scale(1.3)
	    }
	}
	@keyframes shan3_ballup2 {
	    0% {
	        transform: scale(0.1)
	    }
	    100% {
	        transform: scale(1.2)
	    }
	}
	@keyframes shan3_ballup3 {
	    0% {
	        transform: scale(0) rotate(0)
	    }
	    50% {
	        transform: scale(1) rotate(180deg)
	    }
	    100% {
	        transform: scale(0) rotate(360deg)
	    }
	}
	@keyframes shan3_ballup4 {
	    0% {
	        transform: scale(0) rotate(0)
	    }
	    50% {
	        transform: scale(1.1) rotate(180deg)
	    }
	    100% {
	        transform: scale(0) rotate(360deg)
	    }
	}
	@keyframes shan3_opacity-change {
	    0% {
	        opacity: 0;
	    }
	    50% {
	        opacity: 1;
	    }
	    100% {
	        opacity: 0;
	    }
	}
</style>