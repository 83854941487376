<template>
	<div class="shan">
	    <div :class="'shan'+idx" v-for="(idx, index) in 15">
	        <img :src="getLuoItem(index)">
	    </div>
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'Shan',
		extends: BaseLuo,
	}
</script>

<style>
	.shan {
		opacity: .1;
	}
	.shan1 {
		position: absolute;
		left:99px;top:298px;-webkit-animation: shan1 10088ms -2456ms linear infinite both;
	}
	.shan1 img {
		height:38px;width:38px;-webkit-animation: shan_2 1s -21ms linear infinite alternate both;
	}
	.shan2 {
		position: absolute;
		left:300px;top:271px;-webkit-animation: shan7 10856ms -382ms linear infinite both;
	}
	.shan2 img {
		height:42px;width:42px;-webkit-animation: shan_1 1s -922ms linear infinite alternate both;
	}
	.shan3 {
		position: absolute;
		left:419px;top:399px;-webkit-animation: shan2 10289ms -962ms linear infinite both;
	}
	.shan3 img {
		height:41px;width:41px;-webkit-animation: shan_2 1s -726ms linear infinite alternate both;
	}
	.shan4 {
		position: absolute;
		left:380px;top:63px;-webkit-animation: shan1 13867ms -2174ms linear infinite both;
	}
	.shan4 img {
		height:56px;width:56px;-webkit-animation: shan_2 1s -744ms linear infinite alternate both;
	}
	.shan5 {
		position: absolute;
		left:35px;top:129px;-webkit-animation: shan7 12820ms -775ms linear infinite both;
	}
	.shan5 img {
		width:42px;-webkit-animation: shan_2 1s -143ms linear infinite alternate both;
	}
	.shan6 {
		position: absolute;
		left:446px;top:95px;-webkit-animation: shan3 13102ms -206ms linear infinite both;
	}
	.shan6 img {
		height:54px;width:54px;-webkit-animation: shan_1 1s -622ms linear infinite alternate both;
	}
	.shan7 {
		position: absolute;
		left:81px;top:115px;-webkit-animation: shan7 12149ms -9408ms linear infinite both;
	}
	.shan7 img {
		height:57px;width:57px;-webkit-animation: shan_1 1s -905ms linear infinite alternate both;
	}
	.shan8 {
		position: absolute;
		left:209px;top:465px;-webkit-animation: shan1 14896ms -11767ms linear infinite both;
	}
	.shan8 img {
		height:41px;width:41px;-webkit-animation: shan_2 1s -553ms linear infinite alternate both;
	}
	.shan9 {
		position: absolute;
		left:273px;top:470px;-webkit-animation: shan2 11455ms -5636ms linear infinite both;
	}
	.shan9 img {
		height:54px;width:54px;-webkit-animation: shan_2 1s -211ms linear infinite alternate both;
	}
	.shan10 {
		position: absolute;
		left:191px;top:418px;-webkit-animation: shan7 12664ms -12781ms linear infinite both;
	}
	.shan10 img {
		height:53px;width:53px;-webkit-animation: shan_1 1s -95ms linear infinite alternate both;
	}
	.shan11 {
		position: absolute;
		left:46px;top:474px;-webkit-animation: shan2 14241ms -10359ms linear infinite both;
	}
	.shan11 img {
		height:49px;width:49px;-webkit-animation: shan_2 1s -843ms linear infinite alternate both;
	}
	.shan12 {
		position: absolute;
		left:290px;top:84px;-webkit-animation: shan2 13737ms -6933ms linear infinite both;
	}
	.shan12 img {
		height:51px;width:51px;-webkit-animation: shan_1 1s -301ms linear infinite alternate both;
	}
	.shan13 {
		position: absolute;
		left:186px;top:455px;-webkit-animation: shan4 12028ms -7171ms linear infinite both;
	}
	.shan13 img {
		height:51px;width:51px;-webkit-animation: shan_1 1s -339ms linear infinite alternate both;
	}
	.shan14 {
		position: absolute;
		left:131px;top:443px;-webkit-animation: shan3 11305ms -6522ms linear infinite both;
	}
	.shan14 img {
		height:42px;width:42px;-webkit-animation: shan_1 1s -820ms linear infinite alternate both;
	}
	.shan15 {
		position: absolute;
		left:477px;top:216px;-webkit-animation: shan7 10651ms -5411ms linear infinite both;
	}
	.shan15 img {
		height:54px;width:54px;-webkit-animation: shan_1 1s -617ms linear infinite alternate both;
	}
	@keyframes shan1 {
		0% {
		    transform: translate3d(0px,0px,0px) rotate(0deg) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(-50px,215px,745px) rotate(50deg) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes shan2 {
		0% {
		    transform: translate3d(0px,0px,0px) rotateX(0deg) rotateY(0deg) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(10px,-115px,745px) rotateX(20deg) rotateY(80deg) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes shan3 {
		0% {
		    transform: translate3d(0px,0px,0px) rotate(0deg) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(0px,-65px,700px) rotate(50deg) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes shan4 {
		0% {
		    transform: translate3d(0px,0px,0px) rotateX(0deg) rotateY(0deg) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(86px,36px,600px) rotateX(20deg) rotateY(50deg) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes shan7 {
		0% {
		    transform: translate3d(0px,0px,0px) rotateX(0deg) rotateY(0deg) rotateX(-90deg);
		    opacity: 0;
		}
		10% {
		    opacity: 1;
		}
		95% {
		    opacity: 1;
		}
		100% {
		    transform: translate3d(20px,56px,500px) rotateX(20deg) rotateY(50deg) rotateX(-90deg);
		    opacity: 0;
		}
	}
	@keyframes shan_1 {
		0% {
		    opacity: 0;
		}
		70% {
		    opacity: 1;
		}
		100% {
		    opacity: 1;
		}
	}
	@keyframes shan_2 {
		0% {
		    opacity: 1;
		}
		30% {
		    opacity: 1;
		}
		100% {
		    opacity: 0;
		}
	}
</style>