<template>
	<div class="BX_AEComposition_dahuanghuascale">
		<div class="BX_dahuanghuascale BX_dahuanghuascale BX_dahuanghuascale" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
	</div>
</template>

<script>
	export default {
		name: 'dahuascale',
		props: ['mode'],
		data () {
			return {}
		}
	}
</script>

<style scoped lang="less">
	.BX_AEComposition_dahuanghuascale{
	    position: absolute;
	    background: transparent;
	    width: 500px;
	    height: 100%;
	    top: 0;
	    left: 0;
	    display: block;
	    overflow: hidden;
	    transform: translate3d(0,0,0) scale(1,1);
	    transform-origin:50% 0%;
	    z-index: 9;
	    pointer-events: none;
	}
	.BX_dahuanghuascale{
		position: absolute; 
		left: 50%;
		top: 50%;
		margin-left: -150px;
		margin-top: -150px;
		width: 300px;
		height: 300px;
		transform-style: preserve-3d;
		transform: translate3d(0,0,0px) rotate(0deg) scale(0.01,0.01);
		opacity: 0.01;
		transform-origin: 50% 50%;
		display: block;
	}
	.BX_dahuanghuascale{
	    /*background: url("imgs/tab3.png");*/
	    // background: url('http://litiresource.xcgogo.site/user/5980171/images/ef38a6341d12c19e7ccc00f2abd4784d.png');
	}
	.BX_dahuanghuascale{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey1 2s linear 0.00s 1 normal none;
	}
	@keyframes BX_AniKey1{
		0% {
			transform: translate3d(0px,0px,0px) rotate(0deg) scale(0.01,0.01);
			opacity: 0;
		}
		20% {
			opacity: 1;
		}
		80% {
			opacity: 1;
		}
		// 50% {
		// 	opacity: 1;
		// }
		100% {
			transform: translate3d(0px,0px,0px) rotate(100deg) scale(3.53,3.53);
			opacity: 0;
		}
	}
</style>