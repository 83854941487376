<template>
	<div class="tab-btns tab-editor-btns flex_r">
	    <div class="tab-item tab-editor-btn" @click="onBtnClick('menu')">
	        <img src="http://resliti.lititutu.cn/tuku/prod/bc8d5f06d60035eb05bea9a31004c451.png" class="tab-item-image">
	    </div>
	    <div class="tab-item tab-editor-btn" @click="onBtnClick('music')">
	        <img src="http://resliti.lititutu.cn/tuku/prod/c199394f8045f6e48868ae6023154025.png" class="tab-item-image">
	    </div>
	    <div class="tab-item tab-editor-btn" @click="onBtnClick('info')">
	        <img src="http://resliti.lititutu.cn/tuku/prod/5ca4f4ea7d31e1b3f48e8b650e3077be.png" class="tab-item-image">
	    </div>
	    <template v-if="showShareBtn">
			<div class="tab-item tab-editor-btn" @click="onBtnClick(!posted && checkPostStatus ? 'post_square' : 'share')">
				<img :src="!posted && checkPostStatus ? 'http://resliti.lititutu.cn/icons/app/post.png' : 'http://resliti.lititutu.cn/tuku/prod/1331b99ecaafdcf68e9ef952f2f57291.png'" class="tab-item-image">
			</div>
	    </template>
	    <template v-else>
	    	<div class="tab-item tab-editor-btn" @click="onBtnClick('save')">
		        <img src="http://resliti.lititutu.cn/icons/app/save.png?v=1" class="tab-item-image">
		    </div>
	    </template>
	</div>
</template>

<script>
	export default {
		name: 'EditorBtns',
		data () {
			return {
				posted: false,
			}
		},
		computed:{
			checkPostStatus () {
				return this.$route.query.action && this.$route.query.action.indexOf('post_square') > -1;
			},
			showShareBtn () {
				// 如果存在引流则显示保存
				if (this.$store.state.appConfig.share_mode) {
					return true;
				}
				return this.$env.web == 'wxa';
			}
		},
		methods: {
			onBtnClick (param) {
				if(param == "post_square"){
					this.posted = true;
				}
				this.$emit('onBtnClick', param);
			}
		}
	}
</script>

<style scoped lang="less">
	.tab-btns {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100px;
		z-index: 10;
		display: flex;
		align-items: center;
		background: linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
	}
	.tab-item {
		position: relative;
	}
	.tab-item-image {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	}
	.tab-editor-btn {
		width:@bw * 0.229;
		height:@bw * 0.111;
	}
</style>