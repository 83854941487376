// 自有服务端stLogCenter
class stLogCenter {
  constructor (self, _util) {
    this.self = self;
    this.$util = _util;
    this.log_times = {};
    this.params = {}; // 全局预设字段

    this.SET_API = "/stlog/set_log";
    this.GET_API = "/stlog/get_log";
  }
  set_log (options, limit_times) {
    limit_times = limit_times || -1;

    options = options || {};
    options['capp'] = options['capp'] || this.$util.$config.st_config.app;
    if (! (options['capp'] && options['field']) ) {
      return Promise.reject('need capp and field');
    }
    // 记录计数次数
    if (this.log_times[options['field']] == limit_times) {
      return Promise.reject('log out of limit times: ' + limit_times);
    }

    if (this.log_times[options['field']] === undefined) {
      this.log_times[options['field']] = 1;
    } else {
      this.log_times[options['field']] += 1;
    }
    return this.$util.rget(this.SET_API, options);
  }
  get_log (options) {
    options = options || {};
    options['capp'] = options['capp'] || this.$util.$config.st_config.app;
    return this.$util.rget(this.GET_API, options);
  }
}

class stLogManagerBase {
  constructor (util) {
      this.$util = util;
      // 绑定stLogCenter逻辑
      this.center = new stLogCenter(this, util);
  }
}

export default {
  stLogManagerBase
};