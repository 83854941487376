<template>
    <div class="preshare-page page" v-if="isShow">
        <div class="preshare-page_mask page" @click="hide()"></div>
        <div class="preshare-container hd">
            <img class="poc" src="http://resali.lititutu.cn/tuku/prod/1d4602e7e6d8a7373440580519172e86.gif" />
            
            <div class="po preshare-play" @click="toggle()">
                <img class="poc" v-show="!playing" src="http://resali.lititutu.cn/tuku/prod/8def6776fc080289bb4a8da42ae84bab.png" />
                <img class="poc" v-show="playing" src="http://resali.lititutu.cn/tuku/prod/0923e7ee59d3a59243df714c44c63673.png" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PreShare',
        props: {
            onHide: {
                type: Function
            }
        },
        data () {
            return {
                audio: null,
                playing: false,
                isShow: false,
                timeEvent: null
            }
        },
        watch: {
            isShow: {
                handler () {
                    if (this.isShow) {
                        this.play();
                        // 每次显示只保留10s
                        // this.timeEvent = setTimeout(() => {
                        //     this.hide();
                        // }, 10000);
                    } else {
                        this.stop();
                    }
                }
            }
        },
        methods: {
            show () {
                this.isShow = true;
                // 隐藏音乐播放控件
                if (this.$audio.exist()) {
                    this.$audio.hide();
                }
            },
            hide () {
                this.isShow = false;
                this.onHide && this.onHide();
                // 显示音乐播放控件
                if (this.$audio.exist()) {
                    this.$audio.show();
                }
            },
            play () {
                if (!this.audio) {
                    this.audio = new Audio();
                    this.audio.src = "http://stmusic.lititutu.cn/db57ddd864f9179414a4669d78806be0.mp3";
                }
                this.audio.play();
                wx.checkJsApi({
					jsApiList: ["checkJsApi"],
					success: () => {
						this.audio.play();
					}
				});
                // 播放完毕时，关闭提示
                this.audio.onended = () => {
                    setTimeout(() => {
                        this.hide();
                    }, 1000);
                }
                this.playing = true;
            },
            stop () {
                this.audio.pause();
                this.audio.currentTime = 0;
                this.playing = false;
            },
            toggle () {
                if (this.playing) {
                    this.stop();
                } else {
                    this.play();
                }
            }
        }
    }
</script>

<style>
    .preshare-page {
        position: fixed;
        z-index: 10;
    }
    .preshare-page_mask {
        background-color: rgba(0, 0, 0, .72);
    }
    .preshare-container {
        position: absolute;
        width: 500px;
        height: 333px;
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-image: url(http://resali.lititutu.cn/tuku/prod/7aa5cafd6c7afbf9e7873ed90e115155.jpg);
        background-position: 0 0;
        background-size: 100% 100%;
    }
    .preshare-play {
        width: 50px;
        height: 50px;
        top: 36px;
        left: 118px;
    }
</style>