var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"po page",style:(_vm.getStyles)},[_c('div',{staticClass:"po page perspective"},[_c('div',{staticClass:"po preserve3d changlang-con"},[(_vm.getCompElems(0))?[_c('div',{staticClass:"page bg changlang-top1"},[_c('elem',{attrs:{"mode":_vm.getCompElems(0),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1)]:[_c('div',{staticClass:"page bg changlang-top1",style:({backgroundImage: 'url('+ (_vm.comp['config'] && _vm.comp['config'][0]) +')'})})],(_vm.getCompElems(1))?[_c('div',{staticClass:"page bg changlang-top2"},[_c('elem',{attrs:{"mode":_vm.getCompElems(1),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1)]:[_c('div',{staticClass:"page bg changlang-top2",style:({backgroundImage: 'url('+ (_vm.comp['config'] && _vm.comp['config'][1]) +')'})})],(_vm.getCompElems(2))?[_c('div',{staticClass:"page bg changlang-top3"},[_c('elem',{attrs:{"mode":_vm.getCompElems(2),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1)]:[_c('div',{staticClass:"page bg changlang-top3",style:({backgroundImage: 'url('+ (_vm.comp['config'] && _vm.comp['config'][2]) +')'})})],_c('div',{staticClass:"page changlang-left1"},[(_vm.getCompElems(6))?_c('div',[_c('elem',{attrs:{"mode":_vm.getCompElems(6),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1):_c('div',{staticClass:"poc bg",class:{'bg_photo': !(_vm.getCompConfig(6))},style:({
                        backgroundImage: 'url('+ ((_vm.getCompConfig(6)) || _vm.myCurrentPhoto) +')'
                    })})]),_c('div',{staticClass:"page changlang-left2"},[(_vm.getCompElems(7))?_c('div',[_c('elem',{attrs:{"mode":_vm.getCompElems(7),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1):_c('div',{staticClass:"poc bg",class:{'bg_photo': !(_vm.getCompConfig(7))},style:({
                        backgroundImage: 'url('+ ((_vm.getCompConfig(7)) || _vm.myCurrentPhoto) +')'
                    })})]),_c('div',{staticClass:"page changlang-left3"},[(_vm.getCompElems(8))?_c('div',[_c('elem',{attrs:{"mode":_vm.getCompElems(8),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1):_c('div',{staticClass:"poc bg",class:{'bg_photo': !(_vm.getCompConfig(8))},style:({
                        backgroundImage: 'url('+ ((_vm.getCompConfig(8)) || _vm.myCurrentPhoto) +')'
                    })})]),_c('div',{staticClass:"page changlang-right1"},[(_vm.getCompElems(9))?_c('div',[_c('elem',{attrs:{"mode":_vm.getCompElems(9),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1):_c('div',{staticClass:"poc bg",class:{'bg_photo': !(_vm.getCompConfig(9))},style:({
                        backgroundImage: 'url('+ ((_vm.getCompConfig(9)) || _vm.myCurrentPhoto) +')'
                    })})]),_c('div',{staticClass:"page changlang-right2"},[(_vm.getCompElems(10))?_c('div',[_c('elem',{attrs:{"mode":_vm.getCompElems(10),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1):_c('div',{staticClass:"poc bg",class:{'bg_photo': !(_vm.getCompConfig(10))},style:({
                        backgroundImage: 'url('+ ((_vm.getCompConfig(10)) || _vm.myCurrentPhoto) +')'
                    })})]),_c('div',{staticClass:"page changlang-right3"},[(_vm.getCompElems(11))?_c('div',[_c('elem',{attrs:{"mode":_vm.getCompElems(11),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1):_c('div',{staticClass:"poc bg",class:{'bg_photo': !(_vm.getCompConfig(11))},style:({
                        backgroundImage: 'url('+ ((_vm.getCompConfig(11)) || _vm.myCurrentPhoto) +')'
                    })})]),_c('div',{staticClass:"page changlang-front"},[(_vm.getCompElems(12))?_c('div',[_c('elem',{attrs:{"mode":_vm.getCompElems(12),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1):_c('div',{staticClass:"poc bg",class:{'bg_photo': !(_vm.getCompConfig(12))},style:({
                        backgroundImage: 'url('+ ((_vm.getCompConfig(12)) || _vm.myCurrentPhoto) +')'
                    })})]),(_vm.getCompElems(3))?[_c('div',{staticClass:"page bg changlang-bottom1"},[_c('elem',{attrs:{"mode":_vm.getCompElems(3),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1)]:[_c('div',{staticClass:"page bg changlang-bottom1",style:({backgroundImage: 'url('+ (_vm.comp['config'] && _vm.comp['config'][3]) +')'})})],(_vm.getCompElems(4))?[_c('div',{staticClass:"page bg changlang-bottom2"},[_c('elem',{attrs:{"mode":_vm.getCompElems(4),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1)]:[_c('div',{staticClass:"page bg changlang-bottom2",style:({backgroundImage: 'url('+ (_vm.comp['config'] && _vm.comp['config'][4]) +')'})})],(_vm.getCompElems(5))?[_c('div',{staticClass:"page bg changlang-bottom3"},[_c('elem',{attrs:{"mode":_vm.getCompElems(5),"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}})],1)]:[_c('div',{staticClass:"page bg changlang-bottom3",style:({backgroundImage: 'url('+ (_vm.comp['config'] && _vm.comp['config'][5]) +')'})})],_vm._l((6),function(idx){return _c('div',{key:'changlang-item_' + idx,staticClass:"po changlang-item",class:'changlang-item_' + (idx - 1)},[(_vm.items[(idx - 1) % _vm.items.length])?_c('elem',{attrs:{"mode":_vm.items[(idx - 1) % _vm.items.length],"canIShow":_vm.canIShow,"currentPhoto":_vm.currentPhoto}}):_vm._e()],1)})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }