<template>
    <div class="fangkuai-square">
        <template v-if="$util.typeOf(config[0]) == 'string'">
            <div class="bg bg_photo page fangkuai-panorama_1_item fangkuai-panorama_1_top" :style="{backgroundImage: 'url('+ (comp['config'] ? comp['config'][0] : '') +')'}"></div>
        </template>
        <template v-else>
            <div class="page fangkuai-panorama_1_item fangkuai-panorama_1_top">
                <elem class="square_item square_top" :mode="config[0]"></elem>
            </div>
        </template>
        
        <div class="square_item square_mid_1 bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}">
        </div>
        <div class="square_item square_mid_2 bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}">
        </div>
        <div class="square_item square_mid_3 bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}">
        </div>
        <div class="square_item square_mid_4 bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}">
        </div>
        <div class="square_item square_bottom"></div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: 'FangkuiaSquare',
        extends: BaseElem,
        data () {
            return {}
        },
        computed: {
			config () {
				return this.comp['config'] || [];
			}
		}
    }
</script>

<style>
    .fangkuai-square {
        position: absolute;
		width: 250px;
		height: 250px;
		-webkit-transform-style: preserve-3d;
		-webkit-animation: square_rotate 7.5s linear infinite both;
    }
    @keyframes square_rotate {
		0% {
		    transform: translateZ(-680px) rotateY(0);
		}
		100% {
		    transform: translateZ(-680px) rotateY(-360deg);
		}
	}
    .square_item {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
		background-size: cover;
		background-position: center;
		background-color: #eee;
	}
	.square_top {
		/* transform: rotateX(90deg) translateZ(125px); */
		transform: rotateX(180deg) translateZ(-180px);
	}
	.square_mid_1 {
		transform: translateZ(125px);
	}
	.square_mid_2 {
		transform: rotateY(-90deg) translateZ(125px);
	}
	.square_mid_3 {
		transform: rotateY(180deg) translateZ(125px);
	}
	.square_mid_4 {
		transform: rotateY(90deg) translateZ(125px);
	}
	.square_bottom {
		transform: rotateX(-90deg) translateZ(125px);
	}
</style>