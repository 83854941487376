class stEventCenter{
    constructor (util) {
        this.$util = util;
        this.eventCenter = util.$taro.eventCenter;
        this.event_status = {
            "PRELOAD_FINISH": null
        }
        this.broadcast_map = {}
    }
    on (event_name, timeout) {
        let _this = this;
        return new Promise((resolve, reject)=>{
            if (_this.event_status[event_name]){
                resolve(_this.event_status[event_name])
            } else {
                let done = false;
                _this.eventCenter.on(event_name, (options)=>{
                    done = true;
                    resolve(options)
                })
                // 超时处理
                if (timeout) {
                    setTimeout(() => {
                        // 防止重复触发
                        if (!done) {
                            resolve()
                        } 
                    }, timeout);
                }
            }
        })
    }
    off (event_name) {
        this.eventCenter.off(event_name)
        this.event_status[event_name] = null;
    }
    trigger (event_name, options){
        this.eventCenter.trigger(event_name, options)
        this.event_status[event_name] = options ? options : true;
    }
    broadcast (event_name, params) {
        for (let i = 0; this.broadcast_map[event_name] && i < this.broadcast_map[event_name].length ; i++) {
            this.broadcast_map[event_name][i] && this.broadcast_map[event_name][i](params);
        }
    }
    listen (event_name, cb) {
        let index = 0;
        if ( this.broadcast_map[event_name] ) {
            this.broadcast_map[event_name].push(cb);
            index = this.broadcast_map[event_name].length - 1;
        } else {
            this.broadcast_map[event_name] = [cb];
        }
        return {
            "index": index
        }
    }
    offListen (event_name, options) {
        let {
            index
        } = options || {};
        if (index !== undefined && this.$util.typeOf(index) == "Number" && this.broadcast_map[event_name] && this.broadcast_map[event_name].length >= index + 1) {
            this.broadcast_map[event_name][index] = null;
        }
    }
    pageOnShow (cb) {
        let router = this.$util.$router.instance_router;
        if (!router){return;}
        this.eventCenter.once(router.onShow, res=> {
            cb && cb(res)
        })
    }
    pageOnHide (cb) {
        let router = this.$util.$router.instance_router;
        if (!router){return;}
        this.eventCenter.once(router.onHide, res=> {
            cb && cb(res)
        })
    }
    pageOnReady (cb) {
        let router = this.$util.$router.instance_router;
        if (!router){return;}
        this.eventCenter.once(router.onReady, res=> {
            cb && cb(res)
        })
    }
}
export default {
    stEventCenter
}