<template>
	<div class="flow-cont pointer" :style="getStyles">
        <img v-for="(idx, index) in 16" class="flow-item" :class="'flow-'+idx" :src="getLuoItem(idx)">
    </div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: "flow",
		extends: BaseLuo,
	}
</script>

<style>
	.flow-cont {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}
	.flow-item {
		position: absolute;
	}
	.flow-1 {
	    width: 54px;
	    left: 301px;
	    animation: flow_ani4 13.2s linear -9.2s infinite both;
	}
	.flow-2 {
		width: 50px;
		left: 328px;
		animation: flow_ani4 13.6s linear -7.7s infinite both;
	}
	.flow-3 {
		width: 83px;
		left: 279px;
		animation: flow_ani4 13.5s linear -5.7s infinite both;
	}
	.flow-4 {
		width: 49px;
		left: 279px;
		animation: flow_ani3 12.8s linear -8.8s infinite both;
	}
	.flow-5 {
		width: 62px;
		left: 41px;
		animation: flow_ani3 12.9s linear -4.5s infinite both;
	}
	.flow-6 {
		width: 47px;
		left: 64px;
		animation: flow_ani4 13.3s linear -11.5s infinite both;
	}
	.flow-7 {
		width: 51px;
		left: 385px;
		animation: flow_ani3 12.4s linear -9.3s infinite both;
	}
	.flow-8 {
		width: 60px;
		left: 129px;
		animation: flow_ani4 13.3s linear -8.9s infinite both;
	}
	.flow-9 {
		width: 56px;
		left: 150px;
		animation: flow_ani2 10.3s linear -8.9s infinite both;
	}
	.flow-10 {
		width: 54px;
		left: 167px;
		animation: flow_ani1 14.3s linear -8.9s infinite both;
	}
	.flow-11 {
		width: 51px;
		left: 187px;
		animation: flow_ani1 11.3s linear -11.9s infinite both;
	}
	.flow-12 {
		width: 60px;
		left: 200px;
		animation: flow_ani5 8.3s linear -11.9s infinite both;
	}
	.flow-13 {
		width: 53px;
		left: 150px;
		animation: flow_ani5 10.3s linear -11.9s infinite both;
	}
	.flow-14 {
		width: 56px;
		left: 130px;
		animation: flow_ani5 8.3s linear -10.9s infinite both;
	}
	.flow-15 {
		width: 63px;
		left: 153px;
		animation: flow_ani5 12.3s linear -8.9s infinite both;
	}
	.flow-16 {
		width: 48px;
		left: 170px;
		animation: flow_ani5 9.3s linear -13.9s infinite both;
	}
	@keyframes flow_ani5 {
		0% {
		    transform: translate(0,-100%) rotateZ(0);
		    opacity: 0;
		}
		2% {
		    opacity: 1;
		}
		90% {
		    opacity: 1;
		}
		100% {
		    transform: translate(250px,900px) rotateZ(360deg);
		    opacity: 0;
		}
	}
	@keyframes flow_ani4 {
		0% {
		    transform: translate(0,-100%) rotateZ(20deg);
		    opacity: 0;
		}
		2% {
		    opacity: 1;
		}
		90% {
		    opacity: 1;
		}
		100% {
		    transform: translate(-250px,1010px) rotateZ(-340deg);
		    opacity: 0;
		}
	}
	@keyframes flow_ani3 {
		0% {
		    transform: translate(0,-100%) rotateZ(-60deg);
		    opacity: 0;
		}
		2% {
		    opacity: 1;
		}
		90% {
		    opacity: 1;
		}
		100% {
		    transform: translate(190px,1010px) rotateZ(300deg);
		    opacity: 0;
		}
	}
	@keyframes flow_ani2 {
		0% {
		    transform: translate(0,-100%) rotateZ(60deg);
		    opacity: 0;
		}
		2% {
		    opacity: 1;
		}
		90% {
		    opacity: 1;
		}
		100% {
		    transform: translate(380px,990px) rotateZ(420deg);
		    opacity: 0;
		}
	}
	@keyframes flow_ani1 {
		0% {
		    transform: translate(0,-100%) rotateZ(0);
		    opacity: 0;
		}
		2% {
		    opacity: 1;
		}
		90% {
		    opacity: 1;
		}
		100% {
		    transform: translate(250px,1010px) rotateZ(360deg);
		    opacity: 0;
		}
	}
</style>