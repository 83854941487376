<template>
<div class="page denglongheng-container">
	<img class="denglongheng_BX_Class1 denglongheng_BX_Ani1" src="http://resali.lititutu.cn/inter/denglongheng/denglong.png">
</div>
</template>

<script>
	export default {
		name: 'denglongheng',
		data () {
			return {}
		}
	}
</script>

<style scoped>
	.denglongheng-container {
		z-index: 9;
	}
	.denglongheng_BX_Class1{
	    position: absolute; 
	    top: -390px;
		left: 0;
	    width: 1240px;
	    height: 1607px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0px) rotate(0deg);
	    opacity: 1;
	    transform-origin:50% 50%;
	    display: block;
	    z-index: 2;
	    pointer-events: none;
	}
	.denglongheng_BX_Ani1{
	    animation:denglongheng_BX_AniKey1 4s linear 0s 1 both;
	}
	@keyframes denglongheng_BX_AniKey1{
		0% {opacity: 0;transform: translate3d(-1240px,0px,0px) rotate(0deg);}
		6.67% {opacity: 1;}
		93.33% {opacity: 1;}
		100% {opacity: 0;transform: translate3d(1740px,0px,0px) rotate(0deg);}
	}
</style>