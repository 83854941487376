<template>
    <div class="luo3 po pointer"
        style="opacity: 0.990851; display: block;"
        :style="getStyles">
        <template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
            <div v-for="index in 8"
                :class="'luo3_' + index"
                :key="'luo3_' + index"
                class="po">
                <elem 
					:mode="getLuoItem(index)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
            </div>
        </template>
        <template v-else>
            <img
                v-for="index in 8"
                mode="widthFix"
                :class="'luo3_' + index"
                :key="'luo3_' + index"
                class="po luo3_item"
                :src="getRandomLuo()">
        </template>
    </div>
</template>

<script>
import BaseLuo from './BaseLuo'
    export default {
        name: 'luo3',
        extends: BaseLuo,
        data () {
            return {}
        },
    }
</script>

<style>
    .luo3_item {
        width: 50px;
    }
    .luo3_1 {
        left: 5px; -webkit-animation: luo2 14.7s 3.0s linear infinite both;
    }
    .luo3_2 {
        left: 109px; -webkit-animation: luo2 13.3s 1.5s linear infinite both;
    }
    .luo3_3 {
        left: 64px; -webkit-animation: luo2 14.8s 11.6s linear infinite both;
    }
    .luo3_4 {
        left: 126px; -webkit-animation: luo4 13.8s 12.3s linear infinite both;
    }
    .luo3_5 {
        left: 207px; -webkit-animation: luo3 14.7s 2.7s linear infinite both;
    }
    .luo3_6 {
        left: 401px; -webkit-animation: luo2 13.3s 8.5s linear infinite both;
    }
    .luo3_7 {
        left: 391px; -webkit-animation: luo1 14.6s 12.7s linear infinite both;
    }
    .luo3_8 {
        left: 70px; -webkit-animation: luo4 14.5s 7.8s linear infinite both;
    }
    @keyframes luo3 {
        0% {
            -webkit-transform: translate(0,0) rotateX(0) rotateZ(-60deg);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(-190px,1010px) rotateX(720deg) rotateZ(300deg);
            opacity: 0;
        }
    }
    @keyframes luo4 {
        0% {
            -webkit-transform: translate(0,0) rotateX(0) rotateZ(20deg);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(250px,1010px) rotateX(720deg) rotateZ(-340deg);
            opacity: 0;
        }
    }
    @keyframes luo2 {
        0% {
            -webkit-transform: translate(220px,0) rotateX(0) rotateZ(60deg);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(-180px,990px) rotateX(1080deg) rotateZ(420deg);
            opacity: 0;
        }
    }
    @keyframes luo1 {
        0% {
            -webkit-transform: translate(0,0) rotateX(0) rotateZ(0);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            -webkit-transform: translate(-250px,1010px) rotateX(720deg) rotateZ(360deg);
            opacity: 0;
        }
    }
</style>