// storo引入
import Vue from 'vue'
import Vuex from 'vuex'
import st_store from "@/components/st_comps/st_comp/store"
import td_store from "@/components/st_comps/td_comp/store"
import local_store from "@/components/local_comps/store"

Vue.use(Vuex)

const state = Object.assign(
    st_store.state,
    td_store.state,
    local_store.state
)

const mutations = Object.assign(
    st_store.mutations,
    td_store.mutations,
    local_store.mutations
)

const actions = Object.assign(
    st_store.actions,
    td_store.actions,
    local_store.actions
)

const getters = Object.assign(
    st_store.getters,
    td_store.getters,
    local_store.getters
)

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
});