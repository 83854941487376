<template>
	<div class="stage">
	    <div class="box">
	        <div class="box-bg insert-user-bg image" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="threedee assembly run">
	            <div class="threedee upSide insert-up sucai1" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
	            <div class="threedee downSide insert-down sucai1" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
	            <div class="insert-user user-one image" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            <div class="insert-user user-two image" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            <div class="insert-user user-three image" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	            <div class="insert-user user-four image" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'tuoyuan',
		props: ['mode', 'currentImage'],
		data () {
			return {

			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped>

	.box-bg {
		width:100%;
		height:100%;
		position:absolute;
		background-size:auto 100%;
		background-position:center;
		filter:blur(5px)
	}
	.insert-user {
		position:absolute;
		top: -10px;
		background-size:auto 100%;
		width:250px;
		width:200px;
		height:170px;
		background-position:center;
		background-size:cover;
		border-radius:50%;
	}
	.user-one {
		transform:translate3d(-25px,30px,-120px) rotateX(0rad) rotateY(0rad) rotateZ(0rad);
	}
	.user-two {
		transform:translate3d(-25px,30px,120px) rotateX(0rad) rotateY(0rad) rotateZ(0rad);
	}
	.user-three {
		width:200px;
		height:150px;
		transform:translate3d(-160px,40px,0px) rotateX(0rad) rotateY(1.57rad) rotateZ(0rad);
	}
	.user-four {
		width:200px;
		height:150px;
		transform:translate3d(160px,40px,0px) rotateX(0rad) rotateY(1.57rad) rotateZ(0rad);
	}
	.stage {
		height:100%;
		width:100%;
		position:relative;
		overflow:hidden;
		font:12px/1.2 Arial;
		perspective:600px;
		perspective:600px;
		color:#fff;
		text-align:center;
	}
	.box {
		width:100%;
		height:100%;
		overflow:hidden;
	}
	.threedee {
		position:absolute;
		left:50%;
		top:50%;
		transform-style:preserve-3d;
		-webkit-transform-style:preserve-3d;
		transform-origin:50% 50%;
		-webkit-transform-origin:50% 50%;
		width:200px;
		height:200px;
		margin-top:-100px;
		margin-left:-100px;
		background-size:auto 100%;
		transform:translateZ(249px) rotateY(0deg);
		-webkit-transform:translateZ(249px) rotateY(0deg);
		background-size:cover
	}
	.upSide {
		background-position:center;
		width:440px;
		height:350px;
		margin-top:100px;
		margin-left:-220px;
		transform:translate3d(0px,-369px,0px) rotateX(1.57rad) rotateY(0rad) rotateZ(0rad);
		border-radius:50%;
		box-shadow:0 0 50px;
	}
	.downSide {
		background-position:center;
		width:440px;
		height:350px;
		margin-top:-460px;
		margin-left:-220px;
		transform:translate3d(0px,390px,0px) rotateX(-1.57rad) rotateY(0rad) rotateZ(0rad);
		border-radius:50%;
		box-shadow:0 0 50px;
	}
	.run {
		-webkit-animation:run 16s linear infinite alternate;
		animation:run 16s linear infinite alternate;
	}
	@keyframes run {
		0% {
			-webkit-transform:translateZ(480px) rotateY(0deg) rotateY(0deg);
			transform:translateZ(480px) rotateY(0deg) rotateY(0deg);
		}
		50% {
			-webkit-transform:translateZ(480px) rotateY(0deg) rotateY(360deg);
			transform:translateZ(480px) rotateY(0deg) rotateY(360deg);
		}
		100% {
			-webkit-transform:translateZ(480px) rotateY(0deg) rotateY(720deg);
			transform:translateZ(480px) rotateY(0deg) rotateY(720deg);
		}
	}
</style>