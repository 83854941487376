<template>
	<div class="music-con">
		<audio id="player" ref="player" loop="loop" :src="music.src"></audio>
		<span class="music-txt">{{playTxt}}</span>
		<span class="music-popout" v-show="!playing" @click="__onMusicClick">
			<span class="music-popout_txt">点击播放音乐</span>
		</span>
		<div class="music-icon" :class="{playing: playing}" @click="__onMusicClick">
			<img class="music-icon_img poc" :src="icon">
		</div>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				music: {},
				icon: 'http://litiresource.xcgogo.site/user/5980171/images/3fad467fd8be19f60c2b8e38f2bfb764.svg',
				player: null,
				playerSource: null,
				playerObj: null,
				playTxt: '',
				// playTxt: '加载中...',
				playing: false,
				playerExist: false,
				lrc_lineno: 0,
				lrc_closed: false,
				lrc_interval: null,
				env: 'android',
				starttime: 0
			}
		},
		methods: {
			// 创建
			init (music) {
				this.music = music;
				this.__findStartTime();
				this.playerExist = true;
				this.env = music['src'].indexOf('res.wx.qq.com') != -1 ? 'ios' : this.$o_util.getMobEnv();

				if (this.env != 'android') {
					this.player = this.$refs.player;
					setTimeout(() => {
						this.player.currentTime = this.starttime;
					}, 500);
				}

				// 监听歌曲播放
				this.lrcListener();
			},
			createAudioContext () {
				this.player = new (window.AudioContext || window.webkitAudioContext);
				this.playerObj = new Audio();
				this.playerObj.src = this.music.src;
				return new Promise(resolve => {
	
					let e = new XMLHttpRequest;
					e.open("GET", this.music.src, !0);
					e.responseType = "arraybuffer";
					e.onload = () => {
						let arrayBuffer = e.response;
						this.player.decodeAudioData(arrayBuffer, (buffer) => {
							resolve(buffer)
						}, (e) => {});
					}
					e.send();
				})
			},
			// 播放
			play () {
				this.playTxt = '加载中...';

				if (this.env == 'android') {
					if (!this.player) {
						// this.lrc_lineno = 0;
						this.__resetLineno();
						this.createAudioContext().then(buffer => {
							this.playerSource = this.player.createBufferSource();
							this.playerSource.buffer = buffer;
							this.playerSource.connect(this.player.destination);
							this.playerSource.start(0, this.starttime);
							this.playerSource.loop = !0;
							this.playTxt = '';
						})
					} else {
						this.player.resume();
						this.playTxt = '';
					}
				} else {
					let that = this;
					that.player.onloadedmetadata = () => {
						that.playTxt = '';
					}

					that.player.play();

					wx.checkJsApi({
						jsApiList: ["checkJsApi"],
						success: function() {
							that.player.play();
						}
					});
				}

				this.playing = true;

				document.querySelector('.music-con').style.display = 'block';

				// 判断是否已经播放
				setTimeout(() => {
					if (this.env == 'android') {
						if (this.player.state == 'running') {
							this.playTxt = '';
						}
					} else {
						if (!player.paused) {
							this.playTxt = '';
						}
					}
				}, 1000);

				// 判断是否无法播放
				if (this.env != 'android') {
					setTimeout(() => {
						if (player.paused) {
							this.pause();
						}
					}, 1500);
				}
			},
			// 暂停
			pause () {

				if (this.env == 'android') {
					this.player.suspend();
				} else {
					this.player.pause();
				}

				this.playing = false;
				// this.playTxt = '点击播放'; // 微信举措升级，改为弹窗，强调点击 2021.10.31
				this.playTxt = '';

			},
			// 重播
			replay () {
                this.pause();

				// 重置starttime
				this.__findStartTime();

                if (this.player) {
                    if (this.env == 'android') {
						this.player.close();
						this.player = null;
					} else {
						this.player.currentTime = this.starttime;
                    }
                }
				setTimeout(() => {
					// this.lrc_lineno = 0;
					this.__resetLineno();
					this.play();
				}, 500);
            },
			// replay () {
			// 	// let player = document.getElementById('player');

			// 	this.pause();
			// 	setTimeout(() => {
			// 		if (this.env == 'android') {
			// 			this.player = null;
			// 			this.playerSource = null;
			// 		} else {
			// 			this.player.currentTime = 0;
			// 		}
			// 		this.lrc_lineno = 0;
			// 		setTimeout(() => {
			// 			this.play();
			// 		}, 500);
			// 	}, 300);

			// },
			// 替换音乐
			change (music) {
				this.pause();

				this.player = null;
				this.playerSource = null;

				this.init(music)

				this.$nextTick(() => {
					this.play();
				})


				// 监听歌曲播放
				// this.lrcListener();
				// this.play();
			},
			// 销毁
			destroy () {
				this.pause();
				document.body.removeChild( document.querySelector('.music-con') );
				this.playerExist = false;
			},
			// 隐藏
			hide () {
				document.querySelector('.music-con').style.display = 'none';
			},
			// 显示
			show () {
				document.querySelector('.music-con').style.display = 'block';
			},
			// 是否存在
			exist () {
				return this.playerExist;
			},
			// 获取当前音乐信息
			getInfo () {
				return this.music;
			},
			__onMusicClick () {
				if (this.playing) {
					this.pause();
				} else {
					this.play();
				}
			},
			// 监听歌词播放
			setLrc (currentTime) {
				// 切换音乐后可能会出现无lrc情况处理
				if (!this.music['lrc']) {
					this.hideLrc();
					// this.lrc_lineno = 0;
					this.__resetLineno();
					return;
				}
				// 歌曲播放完毕lrcno重置
                if (parseInt(currentTime) == 0) {
                    // this.lrc_lineno = 0;
					this.__resetLineno();
                }
				this.showLrc();

				// // 重播重置
				// if (currentTime == 0) {
				// 	this.lrc_lineno = 0;
				// }
				
				// 歌词播完但歌曲未完时不执行歌词切换操作
				if (!this.music.lrc[this.lrc_lineno]) {
					return;
				}

				// 若从头播放且第一句歌词的时间点与当前播放时间点差多于2s，则seek
				// if ((this.music.lrc[this.lrc_lineno]['time'] - currentTime) > 2 && this.lrc_lineno == 0) {
				// 	currentTime = parseFloat(this.music.lrc[this.lrc_lineno]['time']);
				// }

				if ( this.__getCurrentLrcTime(this.lrc_lineno) <= currentTime ) {
					this.__lrcHighLine();
					this.lrc_lineno ++;
				}
			},
			lrcListener () {
				if (!this.music['lrc']) {
					return;
				}
				if (this.env == 'android') {
					if (this.lrc_interval) clearInterval(this.lrc_interval);
					this.$nextTick(() => {
						this.lrc_interval = setInterval(() => {
							if (!this.player) return;
							if (this.player.state == 'suspended') return;
							let currentTime = parseFloat( (this.player.currentTime + this.starttime).toFixed(2) );
							if (!isNaN(this.playerObj.duration)) {
								currentTime = currentTime % this.playerObj.duration;
							}
							// currentTime存在2s的偏移，进行修正
                            currentTime = currentTime - 1.5 < 0 ? 0 : currentTime - 1.5;
                            currentTime = parseFloat(currentTime.toFixed(2));
							this.setLrc(currentTime)

							// 判断结束
							if (currentTime >= this.playerObj.duration) {
								this.replay();
							}
						}, 300);
					})
				} else {
					this.player.ontimeupdate = () => {
						this.setLrc(this.player.currentTime);
					}
				}
			},
			// 打开歌词
			openLrc () {
				this.lrc_closed = false;
				this.showLrc();
			},
			// 关闭歌词
			closeLrc () {
				this.lrc_closed = true;
				this.hideLrc();
				this.$o_util.setCurrentWord({
					"lrc": null
				})
			},
			// 显示歌词
			showLrc () {
				if (this.lrc_closed) return;

				let lrc = document.getElementById('music-lrc');
				
				// if (this.$store.state['compsStatus'] && this.$store.state['compsStatus']['zimu5']) {
				// 	lrc.style.display = 'none';
				// 	return;
				// } else 

				if (this.$store.state.wordStatus == 0) {
					this.$store.commit("setWordStatus", {
						status: 2
					})
				} else if (this.$store.state.wordStatus == 1 || this.$store.state.wordStatus == 3) {
					lrc.style.display = 'none';
					return;
				}
				if (!lrc) return;
				lrc.style.textShadow = "2px 2px 0 "+ this.music['lrc_color'] +",-2px 2px 0 "+ this.music['lrc_color'] +",2px -2px 0 "+ this.music['lrc_color'] +",-2px -2px 0 "+ this.music['lrc_color'];
				lrc.style.color = this.music['lrc_text_color'];
				if (this.music['lrc_style'] && this.music['lrc_style']['textShadow']){
					lrc.style.textShadow = this.music['lrc_style']['textShadow'] 
				}
				lrc.style.display = 'block';
			},
			// 隐藏歌词
			hideLrc () {
				let lrc = document.getElementById('music-lrc');
				if (!lrc) return;
				lrc.style.display = 'none';
			},
			// 切换当前歌词
			__lrcHighLine () {
				let lrc = document.getElementById('music-lrc');
				if (!lrc) return;

				let lrc_text  = this.music['lrc'][this.lrc_lineno]['lineLyric'].replace(/\+/g, ' ');
				lrc.innerText = '♫ ' + lrc_text;

				this.$o_util.setCurrentWord({
					"lrc": lrc_text
				})
			},
			// 修改歌词颜色
			changeLrcColor (color) {
				this.music['lrc_color'] = color['lrc_color'];
				this.music['color'] = color['lrc_text_color'];
			},
			__findStartTime () {
                if (this.music['lrc']) {
					let firsttime = this.__getCurrentLrcTime(0);
                    if (firsttime > 2) {
                        this.starttime = parseFloat( firsttime );
                    } else {
						this.starttime = 0;
					}
                } else {
					this.starttime = 0;
				}
            },
            __getCurrentLrcTime (idx) {
                let time = parseFloat( this.music.lrc[idx]['time'] );
                if (this.music['lrc_pre_cut']) {
                    time = time - parseFloat(this.music['lrc_pre_cut']);
                }
                return time;
            },
			__resetLineno () {
				let lineno = 0;
				if (this.music['lrc_pre_cut'] && this.music['lrc']) {
					let pre_cut_time = parseFloat( this.music['lrc_pre_cut'] ),
						time;
					for (let i=0; i<this.music['lrc'].length; i++) {
						time = parseFloat( this.music['lrc'][i]['time'] );
						if (pre_cut_time < time) {
							lineno = (i - 1 > 0 ? i - 1 : 0);
							break;
						}
					}
				}
				this.lrc_lineno = lineno;
			}
		}
	}
</script>

<style>
	.music-con {
		position: absolute;
		top: 60px;
		right: 13px;
		width: 42px;
		height: 42px;
		display: none;
		z-index: 8;
	}
	.music-icon {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.music-icon.playing {
		animation: zhuan 2s linear infinite;
	}
	.music-txt {
		position: absolute;
		width: 70px;
		left: -15px;
		bottom: -20px;
		text-align: center;
		font-size: 12.5px;
		color: white;
		text-shadow: 1px 1px 0px #000000;
	}
	.music-popout {
		position: absolute;
		width: 188px;
		height: 42px;
		top: 0;
		left: -150px;
		overflow: hidden;
		border-top-right-radius: 20%;
		border-bottom-right-radius: 20%;
	}
	.music-popout_txt {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgb(0 0 0 / 47%);
		/*font-weight: bold;*/
		font-size: 21px;
		letter-spacing: 1px;
		text-align: left;
		line-height: 42px;
		color: white;
		padding-left: 12px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		animation: txt_run 1s ease-out both;
	}
	@keyframes txt_run {
		from {
			transform: translateX(100%);
		}
		to {
			transform: none;
		}
	}
</style>


<!--
// .onabort = function() {}
// .oncanplay = function() {}
// .oncanplaythrough = function() {);}
// .ondurationchange = function() {);}
// .onemptied = function() {}
// .onended = function() {}
// .onerror = function() {}
// .onloadeddata = function() {}
// .onloadedmetadata = function() {');}
// .onloadstart = function() {}
// .onpause = function() {}
// .onplay = function() {}
// .onplaying = function() {}
// .onprogress = function() {}
// .onratechange = function() {}
// .onseeked = function() {}
// .onseeking = function() {}
// .onstalled = function() {}
// .onsuspend = function() {}
// .ontimeupdate = function() {}
// .onvolumechange = function() {}
// .onwaiting = function() {}
-->