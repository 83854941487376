<template>
    <div class="poc oh firework-container pointer" :style="getStyles">
        <div class="po taro-img firework-img" :class="'firework-img-'+index" v-for="index in 6" :key="index">
            <img class="taro-img__mode-scaletofill"
                :src="getLuoItem(index)"
			    :style="getLuoItemStyle(index)">
        </div>
    </div>
</template>

<script>
    import BaseLuo from './BaseLuo'
    export default {
        name: 'Firework',
        extends: BaseLuo,
        data () {
            return {}
        },
        methods: {
        }
    }
</script>

<style scoped>
    .firework-img-4 {
        animation: 4.2s linear 0s infinite normal none running firework;
        bottom: 167px;
        right: 35px;
    }
    .firework-img-5 {
        animation: 5.4s linear 0s infinite normal none running firework;
        bottom: 340px;
        right: 0;
    }
    .firework-img-1 {
        animation: 5s linear 0s infinite normal none running firework;
        top: 146px;
        left: 57px;
    }
    .firework-img-2 {
        top: 319px;
        left: 53px;
    }
    .firework-img-3 {
        top: 147px;
        right: -38px;
    }
    .firework-img-2, .firework-img-3 {
        animation: 4s linear 0s infinite normal none running firework;
    }
    .firework-img-6 {
        animation: 3.4s linear 0s infinite normal none running firework;
        left: 28px;
        bottom: 217px;
    }
</style>