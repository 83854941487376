<template>
	<div v-if="Object.prototype.toString.call(theme['bottom']).toLowerCase() == '[object string]'" class="po xc-panorama_m xc-panorama_bottom" :style="{backgroundImage: 'url('+theme['bottom']+')'}"></div>	

	<div v-else-if="Object.prototype.toString.call(theme['bottom']).toLowerCase() == '[object object]'" class="po xc-panorama_m xc-panorama_bottom" :style="{backgroundImage: 'url('+theme['bottom']['im']+')'}">
		<gif v-if="theme['bottom']['gif']" :mode="theme['bottom']['gif']"></gif>
		<fish v-if="theme['bottom']['fish']"></fish>
		<template v-if="theme['bottom']['elems']">
			<elem v-for="(elem, index) in theme['bottom']['elems']" :mode="elem" :key="'xc-room-bottom_' + index"></elem>
		</template>
	</div>
	
</template>

<script>
	export default {
		name: 'RoomBottom',
		props: ['theme'],
	}
</script>

<style>
	
</style>