<template>
	<div class="snow1_box" :style="getStyles">
	    <div class="snow1_item" 
			v-for="(index, idx) in 26"
			:class="'snow1_'+idx"
			:key="'snow1_'+idx">
	        <img class="snow1_img" 
				:src="getLuoItem(index)" 
				:style="getLuoItemStyle(index)"></div>
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: "snow",
		extends: BaseLuo,
	}
</script>

<style>
	.snow1_box {
	    position: absolute;
	    width: 100%;
	    top: -25px;
	    z-index: 9;
	}
	.snow1_item {
	    position: absolute;
	}
	.snow1_img {
	    width: 20px;
	    height: 20px;
	}
	.snow1_1 {
		top: -63px;
		animation: 8.4s linear 2s infinite normal none running snow1_fall1;
		left: 486px;
	}
	.snow1_2 {
		top: -43px;
		animation: 5.8s linear 2s infinite normal none running snow1_fall2;
		left: 7px;
	}
	.snow1_3 {
		top: -47px;
		animation: 8.3s linear 2s infinite normal none running snow1_fall2;
		left: 674px;
	}
	.snow1_4 {
		top: -14px;
		animation: 7.5s linear 4s infinite normal none running snow1_fall2;
		left: 471px;
	}
	.snow1_5 {
		top: -18px;
		animation: 8.7s linear 3s infinite normal none running snow1_fall2;
		left: 131px;
	}
	.snow1_6 {
		top: -61px;
		animation: 8.3s linear 3s infinite normal none running snow1_fall1;
		left: 691px;
	}
	.snow1_7 {
		top: -64px;
		animation: 7.9s linear 4s infinite normal none running snow1_fall1;
		left: 662px;
	}
	.snow1_8 {
		top: -27px;
		animation: 9.7s linear 2s infinite normal none running snow1_fall2;
		left: 40px;
	}
	.snow1_9 {
		top: -60px;
		animation: 7.8s linear 4s infinite normal none running snow1_fall1;
		left: 510px;
	}
	.snow1_10 {
		top: -12px;
		animation: 8.2s linear 1s infinite normal none running snow1_fall1;
		left: 488px;
	}
	.snow1_11 {
		top: -63px;
		animation: 7.8s linear 2s infinite normal none running snow1_fall2;
		left: 28px;
	}
	.snow1_12 {
		top: -55px;
		animation: 7.4s linear 4s infinite normal none running snow1_fall2;
		left: 197px;
	}
	.snow1_13 {
		top: -58px;
		animation: 7.6s linear 3s infinite normal none running snow1_fall1;
		left: 163px;
	}
	.snow1_14 {
		top: -50px;
		animation: 8.4s linear 4s infinite normal none running snow1_fall2;
		left: 96px;
	}
	.snow1_15 {
		top: -50px;
		animation: 6.1s linear 4s infinite normal none running snow1_fall1;
		left: 458px;
	}
	.snow1_16 {
		top: -42px;
		animation: 6.9s linear 3s infinite normal none running snow1_fall2;
		left: 441px;
	}
	.snow1_17 {
		top: -18px;
		animation: 9.4s linear 3s infinite normal none running snow1_fall2;
		left: 155px;
	}
	.snow1_18 {
		top: -66px;
		animation: 7.8s linear 2s infinite normal none running snow1_fall2;
		left: 391px;
	}
	.snow1_19 {
		top: -53px;
		animation: 8.9s linear 3s infinite normal none running snow1_fall1;
		left: 44px;
	}
	.snow1_20 {
		top: -17px;
		animation: 6.7s linear 4s infinite normal none running snow1_fall1;
		left: 675px;
	}
	.snow1_21 {
		top: -47px;
		animation: 7.9s linear 1s infinite normal none running snow1_fall2;
		left: 176px;
	}
	.snow1_22 {
		top: -19px;
		animation: 5.3s linear 3s infinite normal none running snow1_fall1;
		left: 420px;
	}
	.snow1_23 {
		top: -36px;
		animation: 8.3s linear 2s infinite normal none running snow1_fall2;
		left: 403px;
	}
	.snow1_24 {
		top: -62px;
		animation: 9.9s linear 2s infinite normal none running snow1_fall1;
		left: 633px;
	}
	.snow1_25 {
		top: -55px;
		animation: 8.3s linear 4s infinite normal none running snow1_fall2;
		left: 554px;
	}
	.snow1_26 {
		top: -19px;
		animation: 9.4s linear 4s infinite normal none running snow1_fall2;
		left: 401px;
	}
	@keyframes snow1_fall1 {
		0% {
		    transform: translate(0px, 0px);
		    opacity: 1;
		}
		80% {
		    transform: translate(160px, 672px);
		    opacity: 1;
		}
		100% {
		    transform: translate(200px, 840px);
		    opacity: 0;
		}
	}
	@keyframes snow1_fall2 {
		0% {
		    transform: translate(0px, 0px);
		    opacity: 1;
		}
		80% {
		    transform: translate(-80px, 720px);
		    opacity: 1;
		}
		100% {
		    transform: translate(-100px, 900px);
		    opacity: 0;
		}
	}
</style>