<template>
	<div class="blink-cont pointer" :style="getStyles">
		<!-- <img v-for="idx in 50"
			:class="'blink-' + idx + ( idx == 23 || idx == 25? 'aniimg21a' : '')"
			:key="'blink-' + idx"
			class="blink-item"
			:src="comp_config[0]"> -->
		<!-- <div v-for="idx in 50"
			:class="'blink-' + idx + ( idx == 23 || idx == 25? 'aniimg21a' : '')"
			:key="'blink-' + idx"
			class="blink-item">
			<elem 
				:mode="getLuoItem(idx)"
				:currentPhoto="currentPhoto"
				:canIShow="canIShow"></elem>	
		</div> -->
		<elem 
			v-for="idx in 50"
			:key="'blink-' + idx"
			:mode="getMyLuoItem(idx)"
			:currentPhoto="currentPhoto"
			:canIShow="canIShow"></elem>
    </div>
</template>

<script>
	// import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	import BaseLuo from './BaseLuo'
	export default {
		name: 'blink',
		// extends: BaseElem,
		extends: BaseLuo,
		data () {
			return {}
		},
		methods: {
			getMyLuoItem (idx) {
				let elem = this.getLuoItem(idx);
				elem['pos'] = "none";
				elem['class'] = ( idx == 23 || idx == 25 ? 'aniimg21a' : ('blink-' + idx)) + ",blink-item";
				return elem;
			}
		}
	}
</script>

<style>
	.blink-cont {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.blink-item {
		position: absolute;
		width: 30px;
	}
	.blink-1 {
		left:307.5px!important;
		animation: blink-ani-1 0.5s -5643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite alternate;
		bottom:34.5px!important;
	}
	.blink-2 {
		left:103px!important;
		animation: blink-ani-2 0.5s -7643ms cubic-bezier(0.18, 0.65, 0.67, 0.43) infinite alternate;
		bottom:55.5px!important;
	}
	.blink-3 {
	    left:202px!important;
	    animation: blink-ani-2 0.5s -2643ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite alternate;
		bottom:146px!important;
	}
	.blink-4 {
	    left:43px!important;
	    animation: blink-ani-2 0.5s -3843ms cubic-bezier(0.10, 0.45, 0.54, 0.43) infinite alternate;
		bottom:139px!important;
	}
	.blink-5 {
	    left:239px!important;
	    animation: blink-ani-3 2s -1232.3ms linear infinite;
		bottom:312.5px!important;
	}
	.blink-6 {
	    left:43px!important;
	    animation: blink-ani-3 2.55s -8843s linear infinite;
		bottom:340.5px!important;
	}
	.blink-7 {
	    left:114.5px!important;
	    animation: blink-ani-2 0.5s -4843ms cubic-bezier(0.15, 0.15, 0.34, 0.16) infinite alternate;
		bottom:416.5px!important;
	}
	.blink-8 {
	    left:316px!important;
	    animation: blink-ani-3 3s -7852ms linear infinite;
		bottom:486px!important;
	}
	.blink-9 {
	    left:159px!important;
	    animation: blink-ani-3 2.15s -3423ms linear infinite;
		bottom:694.5px!important;
	}
	.blink-10 {
	    left:82.5px!important;
	    animation: blink-ani-3 2.65s -3103ms linear infinite;
	    top:375px!important;
	}
	.blink-11 {
	    left:89.5px!important;
	    animation: blink-ani-2 0.5s -2323.3ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite alternate;
	    top:278px!important;
	}
	.blink-12 {
	    left:243px!important;
	    animation: blink-ani-1 0.5s -4535.23ms cubic-bezier(0.50, 0.65, 0.44, 0.53) infinite alternate;
	    top:319.5px!important;
	}
	.blink-13 {
	    left:409.5px!important;
	    animation: blink-ani-1 0.5s -1266ms linear infinite;
	    top:486px!important;
	}
	.blink-14 {
		left:347px!important;
		animation: blink-ani-1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
		top:278px!important;
	}
	.blink-15 {
	    left:208.5px!important;
	    animation: blink-ani-1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top:416.5px!important;
	}
	.blink-16 {
	    left:375px4!important;
	    animation: blink-ani-3 2.65s -1266ms linear infinite;
	    top:347px!important;
	}
	.blink-17 {
	    left:208.5px!important;
	    animation: blink-ani-3 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top:555.5px!important;
	}
	.blink-18 {
	    left:312.5px!important;
	    animation: blink-ani-2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top:600px!important;
	}
	.blink-19 {
	    left:243px!important;
	    animation: blink-ani-3 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top:694.5px!important;
	}
	.blink-20 {
	    left:148px!important;
	    animation: blink-ani-2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top:764px!important;
	}
	.blink-21 {
	    left:37px!important;
	    animation: blink-ani-1 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top:555.5px!important;
	}
	.blink-22 {
	    left:78.5px!important;
	    animation: blink-ani-2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
	    top:698px!important;
	}
	.blink-23 {
	    left:458.5px!important;
	    animation: blink-ani-1 1.35s -1266ms linear infinite;
	    top:203.5px!important;
	}
	.aniimg21a {
	    animation: blink-ani-5 1.75s ease-in-out infinite;
	}
	.blink-24 {
	    left:250px!important;
	    animation: blink-ani-2 1.35s -1266ms linear infinite;
	    top:134px!important;
	}
	.blink-25 {}
	@keyframes blink-ani-1 {
		0% {
		    transform: scale(0);
		}
		100% {
		    transform: scale(1.3);
		}
	}
	@keyframes blink-ani-2 {
		0% {
		    transform: scale(0.1);
		}
		100% {
		    transform: scale(1.2);
		}
	}
	@keyframes blink-ani-3 {
		0% {
		    transform: scale(0) rotate(0);
		}
		50% {
		    transform: scale(1) rotate(180deg);
		}
		100% {
		    transform: scale(0) rotate(360deg);
		}
	}
	@keyframes blink-ani-4 {
		0% {
    		transform: scale(0) rotate(0);
		}
		50% {
		    transform: scale(1.1) rotate(180deg);
		}
		100% {
		    transform: scale(0) rotate(360deg);
		}
	}
	@keyframes blink-ani-5 {
		0% {
		    opacity: 0;
		}
		50% {
		    opacity: 1;
		}
		100% {
		    opacity: 0;
		}
	}

	.blink-26 {
		left:182px!important;
		animation: blink-ani-4 3.5s -1233.3ms linear infinite;
		top:69.5px!important;
	}
	.blink-27 {
		left:159.5px!important;
		animation: blink-ani-3 1.75s -12.3ms linear infinite;
		top:278px!important;
	}
	.blink-28 {
		left:127px!important;
		animation: blink-ani-1 0.5s -4533ms cubic-bezier(0.77, 0.75, 0.14, 0.34) infinite alternate;
		top:625px!important;
	}
	.blink-29 {
		left:197px!important;
		animation: blink-ani-3 2.25s -2324ms linear infinite;
		bottom:416.5px!important;
	}
	.blink-30 {
		left:331px!important;
		animation: blink-ani-2 0.5s -6324ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite alternate;
		top:659.5px!important;
	}
	.blink-31 {
		left:298.5px!important;
		animation: blink-ani-2 2.5s -2104ms cubic-bezier(0.27, 0.35, 0.64, 0.54) infinite alternate;
		bottom:347px!important;
	}
	.blink-32 {
		left:437.5px!important;
		animation: blink-ani-1 0.5s -4024ms cubic-bezier(0.17, 0.25, 0.74, 0.64) infinite alternate;
		bottom:278px!important;
	}
	.blink-33 {
		left:229px!important;
		animation: blink-ani-3 2.5s -2310ms linear infinite;
		bottom:41.5px!important;
	}
	.blink-34 {
		left:21px!important;
		animation: blink-ani-1 0.5s -1310ms cubic-bezier(0.67, 0.45, 0.24, 0.54) infinite alternate;
		bottom:625px!important;
	}
	.blink-35 {
		left:416.5px!important;
		animation: blink-ani-3 3.15s -4310ms linear infinite;
		bottom:625px!important;
	}
	.blink-36 {
		left:278px!important;
		animation: blink-ani-2 0.5s -1210ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite alternate;
		bottom:659.5px!important;
	}
	.blink-37 {
		left:416.5px!important;
		animation: blink-ani-2 0.5s -1216ms cubic-bezier(0.3, 0.4, 0.44, 0.69) infinite alternate;
		top:69.5px!important;
	}
	.blink-38 {
		left:451.5px!important;
		animation: blink-ani-2 0.5s -6745.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
		top:347px!important;
	}
	.blink-39 {
		left:139px!important;
		animation: blink-ani-2 0.5s -7885.3ms cubic-bezier(0.67, 0.85, 0.74, 0.58) infinite alternate;
		top:486px!important;
	}
	.blink-40 {
		left:280px!important;
		animation: blink-ani-3 2.65s -1286ms linear infinite;
		top:514px!important;
	}
	.blink-41 {
		left:294px!important;
		animation: blink-ani-1 2.15s -126ms linear infinite;
		top:166.5px!important;
	}
	.blink-42 {
		left:296.5px!important;
		animation: blink-ani-3 1.65s -1326ms linear infinite;
		top:375px!important;
	}
	.blink-43 {
		left:432.5px!important;
		animation: blink-ani-2 2s -198ms linear infinite;
		top:773px!important;
	}
	.blink-44 {
		left:419px!important;
		animation: blink-ani-2 2.12s -1266ms linear infinite;
		top:599.5px!important;
	}
	.blink-45 {
		left:64.5px!important;
		animation: blink-ani-3 2.56s -1266ms linear infinite;
		top:7px!important;
	}
	.blink-46 {
		background-repeat: no-repeat;
		background-position: center;
		animation: blink-ani-5 1s ease-in-out infinite;
	}
	.blink-47 {
		left:0.5px!important;
		animation: blink-ani-1 1.15s -1266ms linear infinite;
		top:222px!important;
	}
	.blink-48 {
		left:21px!important;
		animation: blink-ani-2 1.05s -1266ms linear infinite;
		top:430.5px!important;
	}
	.blink-49 {
		left:215.5px!important;
		animation: blink-ani-1 1.75s -1266ms linear infinite;
		top:743px!important;
	}
	.blink-50 {
		left:111px!important;
		animation: blink-ani-1 1.85s -1266ms linear infinite;
		top:187.5px!important;
	}
</style>