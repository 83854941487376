<template>
	<div class="model-page bg">
	    <div class="manping_container" :class="{animated: animated}">
	        <div class="manping_page po">
	            <div class="manping_perspective manping_page po">
	                <div class="manping_page4_4 manping_page4_4_8 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="manping_page4_4 manping_page4_4_7 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="manping_page4_4 manping_page4_4_6 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="manping_page4_4 manping_page4_4_5 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="manping_page4_4 manping_page4_4_4 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="manping_page4_4 manping_page4_4_3 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="manping_page4_4 manping_page4_4_2 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="manping_page4_4 manping_page4_4_1 manping_userimg po bg_photo"
	                :style="getStyle(4)"></div>
	            </div>
	            <div class="manping_perspective manping_page po">
	                <div class="manping_page4_3 manping_page4_3_6 manping_userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="manping_page4_3 manping_page4_3_5 manping_userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="manping_page4_3 manping_page4_3_4 manping_userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="manping_page4_3 manping_page4_3_3 manping_userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="manping_page4_3 manping_page4_3_2 manping_userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="manping_page4_3 manping_page4_3_1 manping_userimg po bg_photo"
	                :style="getStyle(3)"></div>
	            </div>
	            <div class="manping_perspective manping_page po">
	                <div class="manping_page4_2 manping_page4_2_6 manping_userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_2 manping_page4_2_5 manping_userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_2 manping_page4_2_4 manping_userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_2 manping_page4_2_3 manping_userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_2 manping_page4_2_2 manping_userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_2 manping_page4_2_1 manping_userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
                    </div>
	            <div class="manping_perspective manping_page po">
	                <div class="manping_page4_1 manping_page4_1_5 manping_userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_1 manping_page4_1_4 manping_userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_1 manping_page4_1_3 manping_userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_1 manping_page4_1_2 manping_userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="manping_page4_1 manping_page4_1_1 manping_userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="config[0]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                                <elem :mode="config[0]" v-else></elem>
                            </div>
                        </template>
                    </div>
	            </div>
	            <div class="manping_perspective manping_page po">
	                <div class="manping_page4_0 manping_userimg po bg_photo preserve3d"
	                :style="getStyle(0)">
                    <template v-if="config[0]">
                        <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                            <img v-if="$util.typeOf(config[0]) == 'string'" class="manping_page_border po" :src="config[0]" />
                            <elem :mode="config[0]" v-else></elem>
                        </div>
                    </template>
                    </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'manping',
		extends: BaseElem,
		data () {
			return {
                animated: true
            }
		},
        computed: {
            config () {
				return this.comp['config'] || [];
			}
        },
		mounted () {},
		methods: {
            start () {
                setTimeout(() => {
                    this.animated = true;
                }, 500);
            },
            end () {
                setTimeout(() => {
                    this.animated = false;
                }, 1000);
            },
            getStyle (idx) {
                let twidth, theight, standsize;
                if (idx == 0) {
                    standsize = [400, 450];
                } else if (idx == 1) {
                    standsize = [150, 180];
                } else if (idx == 2) {
                    standsize = [90, 160];
                } else if (idx == 3) {
                    standsize = [70, 140];
                } else if (idx == 4) {
                    standsize = [30, 55];
                }

                if (this.currentPhoto['orisize']) {
                    let tsize = this.$util.getSizeInArea(this.currentPhoto['orisize'], standsize, 'both');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = standsize[0];
                    theight = standsize[1];
                }

                return {
                    width: twidth + 'px',
                    height: theight + 'px',
                    backgroundImage: 'url('+ this.myCurrentPhoto +')'
                }
            },
            getBorderStyle (idx, lens) {
                let z = 5,
                    gap = Math.floor(lens / 2);
                let t = (idx - gap) * z;
                let s = {};
                s['transform'] = 'translateZ('+ t +'px)';
                return s;
            }
		}
	}
</script>

<style>
    .manping_container {
        width:500.00px;
        height: 100%;
        position: absolute;
        overflow: hidden;
    }
    .manping_page {
        width: 100%;
        height: 100%;
    }
    .manping_perspective {
        -webkit-perspective:486.00px;
    }
    .manping_userimg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border:2.00px solid #FFFFFF;
        background-clip: content-box;
    }
    .manping_page4_0 {
        border:3.50px solid #FFFFFF;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-animation: manping_page4_1 8.5s linear infinite both;
    }
    .manping_page4_1 {
        -webkit-transform: translateZ(69.50px);
        -webkit-animation: manping_page4_1_1 8.5s linear infinite both;
    }
    .manping_page4_1_1 {
        margin: auto;
        top: 0;
        bottom: 0;
        left:64.00px;
        right: 0;
    }
    .manping_page4_1_2 {
        margin: auto;
        top: 50%;
        bottom: 0;
        left:50.50px;
    }
    .manping_page4_1_3 {
        margin: auto;
        top: 0;
        bottom: 50%;
        left:50.50px;
    }
    .manping_page4_1_4 {
        margin: auto;
        top: 0;
        bottom: 50%;
        right: -14.00px;
    }
    .manping_page4_1_5 {
        margin: auto;
        top: 50%;
        bottom: 0;
        right: -14.00px;
    }
    .manping_page4_2 {
        -webkit-transform: translateZ(0);
        -webkit-animation: manping_page4_2_1 8.5s linear infinite both;
    }
    .manping_page4_2_1 {
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
    }
    .manping_page4_2_2 {
        margin: auto;
        top: 0;
        bottom: 0;
        right: -2.00px;
    }
    .manping_page4_2_3 {
        margin: auto;
        top: 0;
        bottom: 55%;
        left: 0;
        right:123.50px;
    }
    .manping_page4_2_4 {
        margin: auto;
        top: 55%;
        bottom: 0;
        left: 0;
        right:123.50px;
    }
    .manping_page4_2_5 {
        margin: auto;
        top: 0;
        bottom: 55%;
        left:114.50px;
        right: 0;
    }
    .manping_page4_2_6 {
        margin: auto;
        top: 55%;
        bottom: 0;
        left:114.50px;
        right: 0;
    }
    .manping_page4_3 {
        width:69.50px;
        height:139.00px;
        -webkit-transform: translateZ(-69.50px);
        -webkit-animation: manping_page4_3_1 8.5s linear infinite both;
    }
    .manping_page4_3_1 {
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right:157.00px;
    }
    .manping_page4_3_2 {
        margin: auto;
        top: 0;
        bottom: 0;
        left:192.50px;
        right: 0;
    }
    .manping_page4_3_3 {
        margin: auto;
        top: 0;
        bottom: 66%;
        left: -41.50px;
    }
    .manping_page4_3_4 {
        margin: auto;
        top: 66%;
        bottom: 0;
        left: -41.50px;
    }
    .manping_page4_3_5 {
        margin: auto;
        top: 0;
        bottom: 66%;
        right: -43.00px;
    }
    .manping_page4_3_6 {
        margin: auto;
        top: 66%;
        bottom: 0;
        right: -43.00px;
    }
    .manping_page4_4 {
        width:30.50px;
        height:55.50px;
        -webkit-transform: translateZ(-139.00px);
        -webkit-animation: manping_page4_4_1 7.5s linear infinite both;
    }
    .manping_page4_4_1 {
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right:149.50px;
    }
    .manping_page4_4_2 {
        margin: auto;
        top: 0;
        bottom: 0;
        left:182.00px;
        right: 0;
    }
    .manping_page4_4_3 {
        margin: auto;
        top: 0;
        bottom: 75%;
        left: -5.50px;
    }
    .manping_page4_4_4 {
        margin: auto;
        top: 75%;
        bottom: 0;
        left: -5.50px;
    }
    .manping_page4_4_5 {
        margin: auto;
        top: 0;
        bottom: 75%;
        left:5.50px;
        right: 0;
    }
    .manping_page4_4_6 {
        margin: auto;
        top: 75%;
        bottom: 0;
        left:5.50px;
        right: 0;
    }
    .manping_page4_4_7 {
        margin: auto;
        top: 0;
        bottom: 75%;
        right: -21.00px;
    }
    .manping_page4_4_8 {
        margin: auto;
        top: 75%;
        bottom: 0;
        right: -21.00px;
    }
    .manping_page4_luo {
        top: 0;
    }
    .manping_page4_luo>img {
        width:34.50px;
        bottom:3.50px;
    }
    @-webkit-keyframes manping_page4_1 {
        0% {
            -webkit-transform: rotateY(0deg);
        }
        100% {
            -webkit-transform: rotateY(360deg);
        }
    }
    @-webkit-keyframes manping_page4_1_1 {
        0% {
            -webkit-transform: translateZ(69.50px) rotateY(0deg);
        }
        100% {
            -webkit-transform: translateZ(69.50px) rotateY(360deg);
        }
    }
    @-webkit-keyframes manping_page4_2_1 {
        0% {
            -webkit-transform: translateZ(0) rotateY(0deg);
        }
        100% {
            -webkit-transform: translateZ(0) rotateY(360deg);
        }
    }
    @-webkit-keyframes manping_page4_3_1 {
        0% {
            -webkit-transform: translateZ(-69.50px) rotateY(0deg);
        }
        100% {
            -webkit-transform: translateZ(-69.50px) rotateY(360deg);
        }
    }
    @-webkit-keyframes manping_page4_4_1 {
        0% {
            -webkit-transform: translateZ(-139.00px) rotateY(0deg);
        }
        100% {
            -webkit-transform: translateZ(-139.00px) rotateY(360deg);
        }
    }
    @-webkit-keyframes mytranslate {
        0% {
            -webkit-transform: scale(0.1);
        }
        100% {
            -webkit-transform: scale(1);
        }
    }
    .animated {
        -webkit-animation: mytranslate 1s forwards linear;
    }
    .manping_page_border {
        width: 120%;
        height: 120%;
        left: -10%;
        top: -10%;
    }
</style>