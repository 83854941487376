<template>
	<div class="fanzhuan bg">
	    <div class="fanzhuan_con poc" :class="{animated: animated}">
	        <div class="fanzhuan_6 fanzhuan_page7 po" :style="getSize">
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_5 fanzhuan_page7 po" :style="getSize">
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_4 fanzhuan_page7 po" :style="getSize">
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_3 fanzhuan_page7 po" :style="getSize">
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_2 fanzhuan_page7 po" :style="getSize">
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	        <div class="fanzhuan_1 fanzhuan_page7 po" :style="getSize">
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_1 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_2 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_3 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_4 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_5 poc"></div>
	            </div>
	            <div class="poc">
	                <div :style="{backgroundImage: 'url('+ myCurrentPhoto +')'}" class="bg bg_photo fanzhuan_div9 fanzhuan_1_6 poc"></div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'fanzhuan',
		extends: BaseElem,
		data () {
			return {
				animated: false
			}
		},
		created () {
			
		},
		computed: {
			getSize () {
				let tsize = [185, 226];
				if (this.currentPhoto['orisize']) {
					tsize = this.$util.getSizeInArea(this.currentPhoto['orisize'], tsize, 'widthFix');
				}
				return {
					width: tsize[0] + "px",
					height: tsize[1] + "px"
				}
			}
		},
		methods: {
			start () {
				this.animated = true;
			},
			end () {
				setTimeout(() => {
					this.animated = false;
				}, 1000);
			}
		}
	}
</script>

<style>
	.fanzhuan_div9 {
		background-color: #eee;
	}
	.fanzhuan {
		position: absolute;
		width: 100%;
		height: 100%;
		perspective: 500px;
	}
	.fanzhuan_con {
	    -webkit-transform-style: preserve-3d;
	    perspective: 888px;
	}
	.animated {
		animation: fanzhuan 4s linear both;
	}
	.fanzhuan_6 {
	    right: -225px;
	    top: 39%;
	    bottom: 0;
	    transform: translateZ(-400px);
	    animation: fanzhuan_4 12s linear infinite both;
	}
	.fanzhuan_page7 {
	    /* width: 185px;
	    height: 138px; */
	    margin: auto;
	    -webkit-transform-origin: 35% 50%;
	    -webkit-transform-style: preserve-3d;
	}
	.fanzhuan_page7>div {
	    -webkit-transform-style: preserve-3d;
	    -webkit-animation: fanzhuan_1x 4s 1.6s linear both;
	}
	@keyframes fanzhuan_1x {
		0% {
		    -webkit-transform: rotateX(-360deg) translate(-100px,100px);
		}
		100% {
		    -webkit-transform: rotateX(0) translate(0,0);
		}
	}
	.fanzhuan_page7>div>div {
	    border: 2px solid #FFFFFF;
	}
	.fanzhuan_1_1 {
	    transform: translateZ(178px);
	}
	.fanzhuan_1_2 {
	    transform: rotateY(60deg) translateZ(178px);
	}
	.fanzhuan_1_3 {
	    transform: rotateY(120deg) translateZ(178px);
	}
	.fanzhuan_1_4 {
	    transform: rotateY(180deg) translateZ(178px);
	}
	.fanzhuan_1_5 {
	    transform: rotateY(240deg) translateZ(178px);
	}
	.fanzhuan_1_6 {
	    transform: rotateY(300deg) translateZ(178px);
	}
	.fanzhuan_5 {
	    right: -225px;
	    top: 0;
	    bottom: 5%;
	    transform: translateZ(-400px);
	    animation: fanzhuan_4 12s linear infinite both;
	}
	.fanzhuan_4 {
	    left: -180px;
	    top: 41%;
	    bottom: 0;
	    transform: translateZ(-400px);
	    animation: fanzhuan_4 12s linear infinite both;
	}
	.fanzhuan_3 {
	    left: -250px;
	    top: 0;
	    bottom: 8%;
	    transform: translateZ(-383px) rotateX(-12deg);
	    animation: fanzhuan_3 12s linear infinite both;
	}
	.fanzhuan_2 {
	    left: 90px;
	    right: 0;
	    top: 27%;
	    bottom: 0;
	    transform: translateZ(-83px) rotateY(0deg);
	    animation: fanzhuan_2 12s linear infinite both;
	}
	.fanzhuan_1 {
	    left: 88px;
	    right: 0;
	    top: 0;
	    bottom: 19%;
	    animation: fanzhuan_1 12s linear infinite both;
	}
	@keyframes fanzhuan_4 {
		0% {
		    transform: translateZ(-400px) rotateY(0deg);
		}
		100% {
		    transform: translateZ(-400px) rotateY(360deg);
		}
	}
	@keyframes fanzhuan_3 {
		0% {
		    transform: translateZ(-383px) rotateX(-12deg) rotateY(0deg);
		}
		100% {
		    transform: translateZ(-383px) rotateX(-12deg) rotateY(360deg);
		}
	}
	@keyframes fanzhuan_2 {
		0% {
		    transform: translateZ(-83px) rotateY(0deg);
		}
		100% {
		    transform: translateZ(-83px) rotateY(360deg);
		}
	}
	@keyframes fanzhuan_1 {
		0% {
		    transform: rotateY(0);
		}
		100% {
		    transform: rotateY(360deg);
		}
	}
	@keyframes fanzhuan {
		0% {
		    transform: rotateX(-360deg) rotateY(180deg);
		}
		100% {
		    transform: rotateX(0) rotateY(0);
		}
	}
</style>