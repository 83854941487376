var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.can_display)?_c('div',{staticClass:"menu-bg container"},[_c('div',{staticClass:"menu-bg-placeholder",on:{"click":_vm.closeMenu}}),_c('div',{staticClass:"menu-operate-area container menuFadeInUp"},[_vm._m(0),_c('div',{staticClass:"operate-container re-container"},[_vm._l((_vm.operate_btns),function(item){return [(item.type !='download' || _vm.book.can_show_save)?_c('div',{key:item.type,staticClass:"operate-btn container",on:{"click":function($event){return _vm.menuBtnClicked(item.type)}}},[_c('img',{staticClass:"operate-btn-image",attrs:{"mode":"widthFix","src":'http://resali.lititutu.cn/wxa_icon/menu/' + item.type + '.png'}}),_c('span',{staticClass:"operate-btn-text"},[_vm._v(_vm._s(item.name))]),(['extract','download'].indexOf(item.type) > -1)?_c('st-button',{attrs:{"rconfig":{
							"condition": [{
								"if": "else",
								"do": "wxa",
								"appid": "wx2fb7317f6bc5b387",
								"path": _vm.getWxaPath(item.type)
							}]
						}}}):_vm._e()],1):_vm._e()]})],2),_c('span',{staticClass:"cancelBtn container",on:{"click":_vm.closeMenu}},[_vm._v("取消")])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operate-container re-container"},[_c('span',{staticClass:"operate-title"},[_vm._v("更多选项")])])}]

export { render, staticRenderFns }