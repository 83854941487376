<template>
	<div class="music">
		<div class="music-box">
			<div class="music-search_box">
				<div class="music-search_bar">
				<!-- <div class="music-search_bar" @click="onSearchOpen"> -->
					<input class="music-search_input" v-model="searchInput" type="text" name="" placeholder="请输入您想查找的歌曲名称">
					<!-- <div class="music-search_input">请输入您想查找的歌曲名称</div> -->
					<!-- <div class="music-search_btn">搜索</div> -->
					<div class="music-search_btn" @click="onSearchClick">搜索</div>
				</div>
			</div>
			<div class="music-types_box">
				<div :id="type['type']" class="music-types_item" :class="{active: type['type'] == activeType}" v-for="(type, index) in musicTypes" @click="onTypeClick(index, type['type'])">{{type['name']}}
				</div>
			</div>
			<div class="music-items_box" ref="musicItemsBox" @scroll="onScroll">
				<div class="music-items_contain" v-for="(type, index) in musicTypes" v-show="type['type'] == activeType && !isSearch">
					<MusicItem v-for="(data, index) in type['datas']" :data="data" :index="index" :activeItemIndex="activeItemIndex" :user="user" @onItemClick="onItemClick" @onConfirmClick="onItemConfirmClick"></MusicItem>
				</div>
				<div class="music-items_contain" v-show="isSearch">
					<template v-if="musicSearchPagenum == 0 && musicSearchEnd">
						<div class="m-searchfail__tips">搜不到相关歌曲，换个关键字试试呗~</div>
					</template>
					<template v-else-if="musicSearchData.length">
						<MusicItem v-for="(data, index) in musicSearchData" :data="data" :index="index" :activeItemIndex="activeItemIndex" :user="user" @onItemClick="onSearchItemClick" @onConfirmClick="onSearchItemConfirmClick"></MusicItem>
					</template>
				</div>
				<div class="m-copyright__txt" @click="$store.commit('copyrightShow', {'isShow': true})">@版权指引</div>
				<!-- <div class="m-copyright__txt" v-if="$env.web == 'wxa'" @click="$store.commit('copyrightShow', {'isShow': true})">@版权指引</div> -->
				<!-- <div class="m-copyright__txt" v-else>海量歌曲由酷狗音乐提供</div> -->
			</div>
		</div>
	</div>
</template>

<script>

	import MusicItem from "@/views/editor/MusicItem"

	export default {
		name: 'TabMusic',
		props: ['musicShow'],
		components: { MusicItem },
		watch: {
			musicShow () {
				this.onTypeClick(0, 'tuijian');
				this.searchInput = '';
			}
		},
		data () {
			return {
				activeType: 'tuijian',
				activeIndex: 0,
				activeItemIndex: null,
				searchInput: '',
				isSearch: false,
				selectedMusicUrl: '',
				user: {
					nickname: '',
					headimgurl: ''
				},
				tryAudio: null,
				musicSearchData: [],
				musicSearchPagenum: 0,
				musicSearchEnd: false,
				musicSearching: false,
			}
		},
		computed: {
			musicTypes () {
				return this.$store.state.editor.musicTypes;
			}
		},
		created () {
			
		},
		methods: {
			onTypeClick (index, type) {

				this.activeIndex = index;
				this.activeType = type;
				this.activeItemIndex = null;
				this.isSearch = false;

				try {
					this.$refs.musicItemsBox.scrollTo(0, 0);
				} catch(e) {
					this.$refs.musicItemsBox.scrollTop = 0;
				}

				// 补全music信息
				this.$store.dispatch('getMusicTypes').then((musicTypes) => {

					if (!musicTypes[index]['datas'].length) {

						this.$util.rpost('https://alifun.litiskr.cn/stmusic/get_music', {
							mids: musicTypes[index]['mids'].join(',')
						})
						.then((response) => {
							this.$store.commit('setMusicTypeDatas', {'index': index, 'datas': response});
						})

						// this.$util.rpost('https://alifun.litiskr.cn/api/music2', {
						// 	mids: musicTypes[index]['mids'].join(','),
						// 	t: 'get_mdatas_by_mids'
						// })
						// .then((response) => {
						// 	this.$store.commit('setMusicTypeDatas', {'index': index, 'datas': response});
						// 	// this.$nextTick(() => {
						// 	// 	if (this.$util.getMobEnv() != 'ios') {
						// 	// 		let active_type = document.getElementById(type);
						// 	// 		active_type.scrollIntoView({
						// 	// 			block: "end",
						// 	// 			behavior: "smooth",
						// 	// 			inline: "center"
						// 	// 		})
						// 	// 	}
						// 	// })
						// });
					}
				})
			},
			confirmSong (music) {
				if (music['mid']) {
					this.$store.dispatch('setdata', {
						k: 'mid',
						v: music['mid'],
						key: this.$route.query.key
					})
					.then(() => {

						// 关闭选音乐界面
						this.$emit('hideTab', {
							'audio': {
								'selected': 1,
								'music': music
							}
						});
					})
				// 保存选中酷狗音乐
				} else {
					this.$util.rpost('https://alifun.litiskr.cn/api/music', {
		        		t: 'new_music_from_kugou',
		        		music: JSON.stringify(music)
		        	}).then((music) => {

						this.$store.dispatch('setdata', {
							k: 'mid',
							v: music['_id'],
							key: this.$route.query.key
						})
						.then(() => {

							// 关闭选音乐界面
							this.$emit('hideTab', {
								'kugou': {
									'selected': 1,
									'music': music['_source']
								}
							});
						});
		        	});
				}
			},
			trySong (music) {
				if (music['src']) {
					if (!this.tryAudio) {
						this.tryAudio = new Audio();
					}

					// 若stalled则播放oss音乐
					let that = this;
					this.tryAudio.onstalled = function() {
						let src = music['oss_url'].replace('mp-musics-kugou.oss-cn-shenzhen.aliyuncs.com', 'music.lititutu.cn');
						src = src.replace('https://', 'http://');
						that.tryAudio.src = src;
						that.$nextTick(() => {
							that.tryAudio.play();
						});
					}

					this.tryAudio.src = music['src'];
					this.tryAudio.play();
				} else {
					// 若存在音乐库音乐，则需新建kugou音乐挂件
					if (this.$audio.exist()) {
			   			this.$kgPlayer.init2(music);
					} else {
						this.$kgPlayer.player.addSongList(music, {'pos': 1});
						this.$kgPlayer.player.playSong(1);
					}
				}
			},
			onItemConfirmClick (index) {
				let data = this.$store.state.editor['musicTypes'][this.activeIndex]['datas'][index];

				this.confirmSong(data);

			},
			onItemClick (index) {
				this.activeItemIndex = index;
				let data = this.$store.state.editor['musicTypes'][this.activeIndex]['datas'][index];

				if (data['uid']) {
					this.fitUser(data);
				}

				this.trySong(data);
			},
			setUser (nickname, headimgurl) {
				try {
					nickname = nickname.match(/[\u4e00-\u9fa5]/g).join('');
				} catch(e) {}
				this.$set(this.user, 'nickname', nickname);
				this.$set(this.user, 'headimgurl', headimgurl);
			},
			doSearch (reSearch) {
				this.$toast.loading('搜索中...');
				
				if (reSearch) {
					this.musicSearchData = [];
					this.musicSearchEnd = false;
					this.musicSearchPagenum = 0;
				}

				return new Promise((resolve, reject) => {
					// this.$util.rget('https://alifun.litiskr.cn/api/music', {
					// 	t: 'search',
					// 	keyword: this.searchInput
					// }).then((musicSearchData) => {
					// 	this.musicSearchData = musicSearchData;
					// 	this.$toast.clear();
					// })
					this.$util.rget('https://alifun.litiskr.cn/stmusic/search', {
						keyword: this.searchInput,
						pagenum: this.musicSearchPagenum
					}).then(musicSearchData => {
						let datas = this.$util.copy(this.musicSearchData);
						if (reSearch) {
							datas = [];
						}
						datas = datas.concat(musicSearchData);
						this.musicSearchData = datas
	
						this.musicSearchPagenum ++;
	
						this.$toast.clear();

						if (musicSearchData.length == 0) {
							this.musicSearchEnd = true;
						}

						resolve();
					})
				})

			},
			onSearchClick () {
				if (!this.searchInput) {
					this.$toast.text({
						message: '请输入歌曲或歌手名',
						duration: 2
					});
					return;
				}

				this.activeType = '';
				this.activeItemIndex = null;
				this.isSearch = true;

				this.doSearch(true);

				this.$mta.click('editor_btn_search_confirm');
			},
			onSearchItemClick (index) {
				this.activeItemIndex = index;
				let data = this.musicSearchData[index];

				if (data['src']) {
					if (data['uid']) {
						this.fitUser(data);
					}
					this.trySong(data);
				} else {
					this.$util.rget('https://alifun.litiskr.cn/stmusic/get_music', {
						mids: data['mid'],
						fields: 'mid,name,src,uid'
					}).then(res=>{
						data = res[0];
						if (data['uid']) {
							this.fitUser(data);
						}
						this.musicSearchData[index] = data;
						this.trySong(data);
					})
				}

			},
			onSearchItemConfirmClick (index) {
				let data = this.musicSearchData[index]

				this.confirmSong(data);
			},
			fitUser (data, cb) {
				if (!data['user']) {
					this.$util.rget('https://alifun.litiskr.cn/api/get_vuser', {
						'uid': data['uid']
					}).then((response) => {
						cb && cb(response);
						this.setUser(response['nickname'], response['headimgurl']);
					})
				} else {
					this.setUser(data['user']['nickname'], data['user']['headimgurl']);
				}
			},
			onScroll (e) {
				if (!this.isSearch) {
					return;
				}
				if (e.target.scrollTop + e.target.clientHeight > e.target.scrollHeight - 10) {
					if (this.musicSearching || this.musicSearchEnd) {
						return;
					}
					this.musicSearching = true;
					this.doSearch().then(() => {
						this.musicSearching = false;
					})
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.music {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}
	.music-box {
		position: absolute;
		width: 100%;
		height: calc(100% - @tabtop);
		top: @tabtop;
		left: 0;
		background-color: #f3f3f3;
		display: flex;
		flex-direction: column;
	}
	.music-search_box {
		position: relative;
		width: 100%;
		height: @bw*.167;
		padding: @bw*.028 0;
	}
	.music-search_bar {
		position: relative;
		height: @bw*.1;
		width: @bw*.833;
		margin: @bw*.028 auto;
		background-color: #D4D4D4;
		border-radius: @bw*.069;
		overflow: hidden;
	}
	.music-search_input {
		position: absolute;
		height: 100%;
		left: @bw*.069;
		width: @bw*.556;
		padding: 0;
		margin: 0;
		border: 0;
		background-color: inherit;
		font-size: @bw*.042;
		font-weight: bold;
		color: #6b6b6b;
		line-height: @bw*.1;
	}
	.music-search_btn {
		position: absolute;
		background-color: #FC6326;
		color: white;
		width: @bw*.18;
		height: @bw*.1;
		right: 0;
		line-height: @bw*.1;
		text-align: center;
		font-size: @bw*.042;
		border-radius: @bw*.069;
		font-weight: bold;
	}
	.music-types_box {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		overflow-x: scroll;
		// padding: @bw*.016 @bw*.029 0;
		width: @bw;
		// background-color: @menuBgColor;
		// position: relative;
		// width: 100%;
		// height: @bw*.097;
		// display: flex;
		// align-items: center;
		// justify-content: space-evenly;
		// div {
		// 	padding: 0 @bw*.039;
		// 	height: @bw*.097;
		// 	line-height: @bw*.097;
		// 	background-color: white;
		// 	font-size: @bw*.042;
		// 	color: #6b6b6b;
		// 	font-weight: bold;
		// 	border-radius: @bw*.053;
		// }
	}
	.music-types_item {
		line-height: @bw*.097;
		background-color: white;
		padding: 0 @bw*.039;
		margin: @bw*.016 @bw*.01;
		border-radius: @bw*.053;
		height: @bw*.097;
		width: @bw*0.15;
		font-size: @bw*.042;
		color: #6b6b6b;
		font-weight: bold;
	}
	.music-types_box>div.active {
		// border-bottom: 2px solid;
		background: linear-gradient(to bottom left, #ff9761, #E72047);
		color: white;
	}
	.music-items_box {
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		flex: 1;
		margin-top: 10px;
	}
	.m-copyright__txt {
		padding: @bw*.069 0;
		text-align: center;
		font-size: @bw*.032;
		color: #949494;
	}
	.m-searchfail__tips {
		text-align: center;
		padding: 30px 0;
		color: #989898;
		font-size: 18px;
	}
</style>




