<template>
	<div class="model-page manpingcontainer bg">
	    <div class="manpingcontainer" :class="{animated: animated}" v-show="animated">
	        <div class="manping_page po">
	            <div class="perspective manping_page po">
	                <div class="page4_4 page4_4_8 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="page4_4 page4_4_7 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="page4_4 page4_4_6 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="page4_4 page4_4_5 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="page4_4 page4_4_4 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="page4_4 page4_4_3 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="page4_4 page4_4_2 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	                <div class="page4_4 page4_4_1 userimg po bg_photo"
	                :style="getStyle(4)"></div>
	            </div>
	            <div class="perspective manping_page po">
	                <div class="page4_3 page4_3_6 userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="page4_3 page4_3_5 userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="page4_3 page4_3_4 userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="page4_3 page4_3_3 userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="page4_3 page4_3_2 userimg po bg_photo"
	                :style="getStyle(3)"></div>
	                <div class="page4_3 page4_3_1 userimg po bg_photo"
	                :style="getStyle(3)"></div>
	            </div>
	            <div class="perspective manping_page po">
	                <div class="page4_2 page4_2_6 userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_2 page4_2_5 userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_2 page4_2_4 userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_2 page4_2_3 userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_2 page4_2_2 userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_2 page4_2_1 userimg po bg_photo" :style="getStyle(2)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
                    </div>
	            <div class="perspective manping_page po">
	                <div class="page4_1 page4_1_5 userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_1 page4_1_4 userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_1 page4_1_3 userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_1 page4_1_2 userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	                <div class="page4_1 page4_1_1 userimg po bg_photo preserve3d"
	                :style="getStyle(1)">
                        <template v-if="mode[1]">
                            <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                                <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                                <elem :mode="mode[1]" v-else></elem>
                            </div>
                        </template>
                    </div>
	            </div>
	            <div class="perspective manping_page po">
	                <div class="page4_0 userimg po bg_photo preserve3d"
	                :style="getStyle(0)">
                    <template v-if="mode[1]">
                        <div class="page preserve3d" v-for="(idx, jidx) in 3" :key="'manping_photo_1_'+idx" :style="getBorderStyle(jidx, 3)">
                            <img v-if="$util.typeOf(mode[1]) == 'string'" class="manping_page_border page" :src="mode[1]" />
                            <elem :mode="mode[1]" v-else></elem>
                        </div>
                    </template>
                    </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'manping',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {
                animated: false
            }
		},
		mounted () {},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
            start () {
                setTimeout(() => {
                    this.animated = true;
                }, 500);
            },
            end () {
                setTimeout(() => {
                    this.animated = false;
                }, 1000);
            },
            getStyle (idx) {
                let twidth, theight, standsize;
                if (idx == 0) {
                    standsize = [400, 450];
                } else if (idx == 1) {
                    standsize = [150, 180];
                } else if (idx == 2) {
                    standsize = [90, 160];
                } else if (idx == 3) {
                    standsize = [70, 140];
                } else if (idx == 4) {
                    standsize = [30, 55];
                }

                if (this.showStatus) {
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], standsize, 'both');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = standsize[0];
                    theight = standsize[1];
                }


                return {
                    width: twidth + 'px',
                    height: theight + 'px',
                    backgroundImage: 'url('+ this.currentImage['im'] +')'
                }
            },
            getBorderStyle (idx, lens) {
                let z = 5,
                    gap = Math.floor(lens / 2);
                let t = (idx - gap) * z;
                let s = {};
                s['transform'] = 'translateZ('+ t +'px)';
                return s;
            }
		}
	}
</script>

<style scoped lang="less">
.manpingcontainer {
    width:@bw * 1.0;
    height: 100%;
    position: absolute;
    overflow: hidden;
}
.po {
    position: absolute;
}
.manping_page {
    width: 100%;
    height: 100%;
}
.bg {
    width:@bw * 1.0;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.perspective {
    -webkit-perspective:@bw * 0.972;
}
.preserve3d {
    -webkit-transform-style: preserve-3d;
}
.userimg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border:@bw * 0.004 solid #FFFFFF;
    background-clip: content-box;
}
.page4_0 {
    // width:@bw * 0.694;
    // height:@bw * 0.903;
    border:@bw * 0.007 solid #FFFFFF;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-animation: page4_1 8.5s linear infinite both;
    /* width:@bw * 0.511; height: 245.088px; */
}
.page4_1 {
    // width:@bw * 0.208;
    // height:@bw * 0.278;
    -webkit-transform: translateZ(@bw * 0.139);
    -webkit-animation: page4_1_1 8.5s linear infinite both;
    /* width:@bw * 0.185; height: 88.578px; */
}
.page4_1_1 {
    margin: auto;
    top: 0;
    bottom: 0;
    left:@bw * 0.128;
    right: 0;
}
.page4_1_2 {
    margin: auto;
    top: 50%;
    bottom: 0;
    left:@bw * 0.101;
}
.page4_1_3 {
    margin: auto;
    top: 0;
    bottom: 50%;
    left:@bw * 0.101;
}
.page4_1_4 {
    margin: auto;
    top: 0;
    bottom: 50%;
    right: -@bw * 0.028;
}
.page4_1_5 {
    margin: auto;
    top: 50%;
    bottom: 0;
    right: -@bw * 0.028;
}
.page4_2 {
    // width:@bw * 0.181;
    // height:@bw * 0.319;
    -webkit-transform: translateZ(0);
    -webkit-animation: page4_2_1 8.5s linear infinite both;
    /* width:@bw * 0.156; height: 74.592px; */
}
.page4_2_1 {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
}
.page4_2_2 {
    margin: auto;
    top: 0;
    bottom: 0;
    right: -@bw * 0.004;
}
.page4_2_3 {
    margin: auto;
    top: 0;
    bottom: 55%;
    left: 0;
    right:@bw * 0.247;
}
.page4_2_4 {
    margin: auto;
    top: 55%;
    bottom: 0;
    left: 0;
    right:@bw * 0.247;
}
.page4_2_5 {
    margin: auto;
    top: 0;
    bottom: 55%;
    left:@bw * 0.229;
    right: 0;
}
.page4_2_6 {
    margin: auto;
    top: 55%;
    bottom: 0;
    left:@bw * 0.229;
    right: 0;
}
.page4_3 {
    width:@bw * 0.139;
    height:@bw * 0.278;
    -webkit-transform: translateZ(-@bw * 0.139);
    -webkit-animation: page4_3_1 8.5s linear infinite both;
    /* width:@bw * 0.131; height: 62.604px; */
}
.page4_3_1 {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right:@bw * 0.314;
}
.page4_3_2 {
    margin: auto;
    top: 0;
    bottom: 0;
    left:@bw * 0.385;
    right: 0;
}
.page4_3_3 {
    margin: auto;
    top: 0;
    bottom: 66%;
    left: -@bw * 0.083;
}
.page4_3_4 {
    margin: auto;
    top: 66%;
    bottom: 0;
    left: -@bw * 0.083;
}
.page4_3_5 {
    margin: auto;
    top: 0;
    bottom: 66%;
    right: -@bw * 0.086;
}
.page4_3_6 {
    margin: auto;
    top: 66%;
    bottom: 0;
    right: -@bw * 0.086;
}
.page4_4 {
    width:@bw * 0.061;
    height:@bw * 0.111;
    -webkit-transform: translateZ(-@bw * 0.278);
    -webkit-animation: page4_4_1 7.5s linear infinite both;
    /* width:@bw * 0.061; height: 29.304px; */
}
.page4_4_1 {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right:@bw * 0.299;
}
.page4_4_2 {
    margin: auto;
    top: 0;
    bottom: 0;
    left:@bw * 0.364;
    right: 0;
}
.page4_4_3 {
    margin: auto;
    top: 0;
    bottom: 75%;
    left: -@bw * 0.011;
}
.page4_4_4 {
    margin: auto;
    top: 75%;
    bottom: 0;
    left: -@bw * 0.011;
}
.page4_4_5 {
    margin: auto;
    top: 0;
    bottom: 75%;
    left:@bw * 0.011;
    right: 0;
}
.page4_4_6 {
    margin: auto;
    top: 75%;
    bottom: 0;
    left:@bw * 0.011;
    right: 0;
}
.page4_4_7 {
    margin: auto;
    top: 0;
    bottom: 75%;
    right: -@bw * 0.042;
}
.page4_4_8 {
    margin: auto;
    top: 75%;
    bottom: 0;
    right: -@bw * 0.042;
}
.page4_luo {
    top: 0;
}
.page4_luo>img {
    width:@bw * 0.069;
    bottom:@bw * 0.007;
}
@-webkit-keyframes page4_1 {
    0% {
        -webkit-transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
    }
}
@-webkit-keyframes page4_1_1 {
    0% {
        -webkit-transform: translateZ(@bw * 0.139) rotateY(0deg);
    }
    100% {
        -webkit-transform: translateZ(@bw * 0.139) rotateY(360deg);
    }
}
@-webkit-keyframes page4_2_1 {
    0% {
        -webkit-transform: translateZ(0) rotateY(0deg);
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(360deg);
    }
}
@-webkit-keyframes page4_3_1 {
    0% {
        -webkit-transform: translateZ(-@bw * 0.139) rotateY(0deg);
    }
    100% {
        -webkit-transform: translateZ(-@bw * 0.139) rotateY(360deg);
    }
}
@-webkit-keyframes page4_4_1 {
    0% {
        -webkit-transform: translateZ(-@bw * 0.278) rotateY(0deg);
    }
    100% {
        -webkit-transform: translateZ(-@bw * 0.278) rotateY(360deg);
    }
}
@-webkit-keyframes luo1 {
    0% {
        -webkit-transform: scale(1.2) translate(0, 0) rotateZ(0);
    }
    100% {
        -webkit-transform: scale(1.2) translate(-@bw * 0.347,@bw * 1.319) rotateZ(213deg);
    }
}
@-webkit-keyframes luo2 {
    0% {
        -webkit-transform: scale(1.2) translate(0, 0) rotateZ(-20deg);
    }
    100% {
        -webkit-transform: scale(1.2) translate(-@bw * 0.556,@bw * 1.319) rotateZ(340deg);
    }
}
@-webkit-keyframes luo3 {
    0% {
        -webkit-transform: scale(1.2) translate(0, 0) rotateZ(-120deg);
    }
    100% {
        -webkit-transform: scale(1.2) translate(-@bw * 0.556,@bw * 1.319) rotateZ(150deg);
    }
}
@-webkit-keyframes luo4 {
    0% {
        -webkit-transform: scale(1.2) translate(0, 0) rotateZ(60deg);
    }
    100% {
        -webkit-transform: scale(1.2) translate(@bw * 0.431,@bw * 1.319) rotateZ(-260deg);
    }
}
@-webkit-keyframes luo5 {
    0% {
        -webkit-transform: translate(0, 0) rotateZ(30deg);
    }
    100% {
        -webkit-transform: translate(@bw * 0.625,@bw * 1.319) rotateZ(-320deg);
    }
}
@-webkit-keyframes mytranslate {
    0% {
        -webkit-transform: scale(0.1);
    }
    100% {
        -webkit-transform: scale(1);
    }
}
.animated {
    -webkit-animation: mytranslate 1s forwards linear;
    /* width:@bw * 0.061; height: 29.304px; */
}
.manping_page_border {
    width: 120%;
    height: 120%;
    left: -10%;
    top: -10%;
}
</style>