<template>
	<div class="simple-page page bg">
		
		<Bg 
			:mode="mode" 
			:currentBgIdx="currentBgIdx" 
			:currentImage="currentUserImage"
			:currentIdx="currentIdx">
		</Bg>
		<!-- currentImageUrl => currentUserImage -->

		<div class="simple-contain page poc" :style="getStyle" :class="getClass">
			<template v-for="(line, index) in timeline">
				<div class="simple-photo_box poc" :style="{zIndex: (line['ani_step'] == 1 ? 2 : 1)}" v-if="line['type'] != 'end'">
					<div class="poc simple-ani_0" ref="simple-ani_0" :style="getAniStyle(line, 0)" :class="getAni(line, 0)" v-if="getAni(line, 0) != 'none'">
						<div class="simple-photo_contain po" :style="getContainStyle">

							<div class="simple-ani_1 po" ref="simple-ani_1" :style="getAniStyle(line, 1)" :class="getAni(line, 1)">
								<div class="poc" ref="simple-ani_2" :style="getAniStyle(line, 2)" :class="getAni(line, 2)">
									<div class="poc" ref="simple-ani_3" :style="getAniStyle(line, 3)" :class="getAni(line, 3)">
										<div class="poc" ref="simple-ani_4" :style="getAniStyle(line, 4)" :class="getAni(line, 4)" @click="wxPreview">
											<Img 
												:line="line"
												:photo="photo" 
												:fillMode="photoFillMode" 
												:elems="imgElems" 
												:gifs="imgGifs">
											</Img>
										</div>
									</div>
								</div>
							</div>

							<template v-if="photoFillMode == 'cover'">
								<template v-for="mode in imgElems">
									<elem :mode="mode" :currentImage="currentImageUrl"></elem>
								</template>

								<template v-for="mode in imgGifs">
									<gif :mode="mode"></gif>
								</template>
							</template>

						</div>
					</div>
				</div>
			</template>
		</div>

		<!-- 屏幕组件 -->
		<template v-for="(decorate, decorate_id) in mode['mode']['decorate']">
			<template v-if="decorate_id == 'gif'">
				<template v-for="mode in decorate">
					<gif :mode="mode"></gif>
				</template>
			</template>
			<template v-else-if="decorate_id == 'elems'">
				<template v-for="mode in decorate">
					<elem :mode="mode" :currentImage="currentImageUrl"></elem>
				</template>
			</template>
			<template v-else>
				<component :is="decorate_id" :mode="decorate"></component>
			</template>
		</template>

		<!-- 字幕 -->
		<UserText :mode="mode['mode']" :currentText="currentText"></UserText>

		<!-- 开场动画 -->
		<Prelude :mode="prelude" @preluded="preluded"></Prelude>
		
	</div>
</template>

<script type="text/javascript">

	import Img from '@/components/themes/simple/SimpleImg'
	import Bg from '@/components/themes/simple/SimpleBg'

	import UserText from '@/components/UserText'
	import Prelude from '@/components/Prelude'

	const APP = getApp();

	export default {
		name: 'simple',
		props: ['mode'],
		components: {
			Img,
			Bg,

			Prelude,
			UserText,
		},
		data () {
			return {
				second: -1,
				currentIdx: 0,
				timeEvent: null,
				timeline: [],
				currentBgIdx: 0,
				currentText: ''
			}
		},
		created () {

			let timeline = this.mode['mode']['timeline'],
				rtimeline = [];

			for (let i=0,lens=timeline.length; i<lens; i++) {
				let t = timeline[i].split(','),
					m = {}; 

				m['second'] = t[0];
				if ('end' == t[1]) {
					m['type'] = 'end';
				} else {
					m['type'] = 'ani';
					m['in'] = t[1];
					m['in_duration'] = 0;
					m['out'] = t[3];
					m['out_duration'] = 0;
					m['gap'] = t[2];
					m['ani_step'] = 0;
					m['photo'] = {
						'src': '',
						'size': {
							'width': 0,
							'height': 0
						},
						'idx': 0
					};
				}
				rtimeline.push(m);
			}
			this.timeline = rtimeline;

			clearInterval(this.timeEvent);

			this.timer();
		},
		destroyed () {
			clearInterval(this.timeEvent);
		},
		computed: {
			photo () {
				let photo = {};
				if (this.mode['mode']['photo']) {
					photo = this.mode['mode']['photo'];
				}
				return photo;
			},
			prelude () {
				let prelude = Boolean(this.mode['prelude']) ? this.mode['prelude'] : false;

				if (!prelude) {
				// 如果设置无开场或无用户设置标题，则不显示 
					if (this.mode['is_prelude'] === false || !this.$store.state.config['data']['ti']) {
						prelude = {
							'type': 'none',
							'duration': 0
						}
					}
					// 其他情况则显示默认开场样式
					else {
						prelude = {}
						prelude['title'] = this.$store.state.config['data']['ti'];
						if (!prelude['textcolor']) {
							prelude['textcolor'] = '#066f06';
						}
						if (!prelude['type']) {
							prelude['type'] = 'default';
						}
					}
				}

				if (!prelude['duration']) {
					prelude['duration'] = 3;
				}
				prelude['title'] = this.$store.state.config['data']['ti'] || false;

				if (!prelude['title']) {
					prelude = {
						'type': 'none',
						'duration': 0
					}
				}

				return prelude;
			},
			getClass () {
				return this.photo['className'] ? this.photo['className'] : '';
			},
			getStyle () {
				let s = {
					'perspective': '500px'
				};
				if (this.photo['perspective']) {
					s['perspective'] = this.photo['perspective'];
				}
				return s;
			},
			scene () {
				return this.mode['mode']['scene'] || {};
			},
			// 关于照片定位模式
			photoPosMode () {
				let pos_mode = {
					"mode": "center"
				};
				if (this.photo['pos_mode']) {
					pos_mode = this.photo['pos_mode'];
				};
				return pos_mode;
			},
			photoPos () {
				let pos = null;
				if (this.photo['pos']) {
					pos = this.photo['pos'];
				};
				return pos;
			},
			photoFillMode () {
				let fill_mode = "contain";
				if (this.photo['fill_mode']) {
					fill_mode = this.photo['fill_mode'];
				}
				return fill_mode;
			},
			getContainStyle () {
				let style = {
					width: this.$util.getPx(this.mode['mode']['box'][0]), 
					height: this.$util.getPx(this.mode['mode']['box'][1]),
					perspective: '500px'
				};

				if (this.photo['perspective']) {
					style['perspective'] = this.photo['perspective'];
				}

				let pos_mode = this.photoPosMode,
					pos = this.photoPos;

				// contain定位
				if (pos) {
					style['top'] = this.$util.getPx(pos[0]);
					style['right'] = this.$util.getPx(pos[1]);
					style['bottom'] = this.$util.getPx(pos[2]);
					style['left'] = this.$util.getPx(pos[3]);
				} else if (style['width'].indexOf('%') == -1 && style['height'].indexOf('%') == -1) {
					// 长宽不为百分比则做下面的定位处理
					if (pos_mode['mode'] == 'bottom') {
						style['bottom'] = this.$util.getPx(this.photo['pos_mode']['bottom']);
						style['left'] = "50%";
						style['marginLeft'] = this.$util.getPx('-'+(this.mode['mode']['box'][0]/2));
					} else if (pos_mode['mode'] == 'top') {
						style['top'] = this.$util.getPx(this.photo['pos_mode']['top']);
						style['left'] = "50%";
						style['marginLeft'] = this.$util.getPx('-'+(this.mode['mode']['box'][0]/2));
					} else {
						style['top'] = "50%";
						style['left'] = "50%";
						style['marginTop'] = this.$util.getPx('-'+(this.mode['mode']['box'][1]/2));
						style['marginLeft'] = this.$util.getPx('-'+(this.mode['mode']['box'][0]/2));
					}
				}

				if (this.photoFillMode == 'cover') {
					if (this.photo['border'] && this.photo['border']['radius']) {
						style['borderRadius'] = this.photo['border']['radius'];
					}
					style['overflow'] = 'hidden';
				}

				if (this.photo['style']) {
					style = Object.assign(style, this.photo['style']);
				}

				return style;
			},
			imgElems () {
				if (this.photo['elems']) {
					return this.photo['elems'];
				} else {
					return [];
				}
			},
			imgGifs () {
				if (this.photo['gif']) {
					return this.photo['gif'];
				} else {
					return [];
				}
			},
			// 02-22 yellow修改currentImageUrl为计算属性适配 问候预览模式 适配 先赋值第一张图给currentImageUrl 方便elems展示用户图片
			currentImageUrl () {
				let idx = this.currentImageIdx;
				let imgurl = this.$store.state.config.images[idx]['im'] ? this.$store.state.config.images[idx]['im'] : "";

				if (this.$route.query['action'] == "wenhou") {
					if (this.mode.model_img) {
						imgurl = this.mode.model_img;
					}
				}
				return imgurl;
			},
			currentImageIdx () {
				return this.currentIdx % this.$store.state.config.images.length;
			},
			currentUserImage () {
				let index = (this.currentIdx - 1) % this.$store.state.config.images.length;
				return this.$store.state.config.images && this.$store.state.config.images[index] ? this.$store.state.config.images[index]['im'] : this.currentImageUrl;
			}
		},
		methods: {
			getAniStyle (item, index) {
				let animationid = 'none';
				if (item['ani_step'] == 1) {
					animationid = item['in'];
				} else if (item['ani_step'] == 2) {
					animationid = item['out'];
				}

				let _style = {},
					keyframeid = APP.globalData.keyFrames.simple_animations[animationid]['keyframes'][index],
					scene_config = this.scene[animationid];

				// 下标为0（第一层）设置遮罩层
				if (index == 0) {
					// 处理蒙层
					if (scene_config && scene_config['mask']) {
						// 长宽适配
						if (scene_config['mask']['row']) {
							if (item['photo']['size']['width'] < item['photo']['size']['height']) {
								_style = scene_config['mask']['col']['style'];
							} else {
								_style = scene_config['mask']['row']['style'];
							}
						} else {
							_style = scene_config['mask']['style'];
						}
					}
				}

				// 下标为1（第二层）设置为图片外框层
				if (index == 1 && item['photo']['size']['width']) {
					if (this.photo['photo_mode']) {
						_style['width'] = '100%';
						_style['height'] = '100%';
						_style['top'] = '0';
						_style['left'] = '0';
					} else {
						_style['width'] = item['photo']['size']['width'] + 'px';
						_style['height'] = item['photo']['size']['height'] + 'px';
						_style['marginTop'] = item['photo']['size']['marginTop'] + 'px';
						_style['marginLeft'] = ((
							this.photo['border'] 
								&& this.photo['border']['width'] != null) ? (item['photo']['size']['marginLeft'] - this.photo['border']['width']) : item['photo']['size']['marginLeft']) + 'px';
						if (this.photoPosMode['mode'] == "bottom") {
							if (this.photoFillMode == "contain") {
								delete _style['marginTop'];
								_style['bottom'] = 0;
							}
						} else if (this.photoPosMode['mode'] == "top") {
							if (this.photoFillMode == "contain") {
								delete _style['marginTop'];
								_style['top'] = 0;
							}
						}
					}
				}

				// 替换配置中自定义动画 需在keyframeid确定前
				let keyframeids = this.__reBuildKeyframeidsBySceneKeyframe(animationid);

				// 若存在自定义动画则替换
				if (keyframeids[index]) {
					keyframeid = keyframeids[index];
				}

				if (APP.globalData.keyFrames.keyframes_data[keyframeid]) {
					_style['animation'] = keyframeid + ' ' + APP.globalData.keyFrames.keyframes_data[keyframeid]['animation'];
				}

				// 替换配置中自定义动画样式 需在_style确定后
				if (scene_config && scene_config['keyframes']) {
					let scene_keyframes = scene_config['keyframes'];
					// 替换动画样式
					if (this.$util.typeOf(scene_keyframes) == 'object' && scene_keyframes['data'] && scene_keyframes['data'][keyframeid]) {
						_style = Object.assign(_style, scene_keyframes['data'][keyframeid]['style']);
						// 按照习惯，animation不设置animationName名称，故补全
						if (_style['animation']) {
							_style['animation'] = keyframeid + ' ' + _style['animation'];
						}
					}
				}
				return _style;
			},
			getAni (item, index) {
				let animationid = 'none';
				if (item['ani_step'] == 1) {
					animationid = item['in'];
				} else if (item['ani_step'] == 2) {
					animationid = item['out'];
				}

				let _class = {};
				let keyframeid = APP.globalData.keyFrames.simple_animations[animationid]['keyframes'][index];

				if (keyframeid == 'ellimask') {
					if (item['photo']['size']['width'] < item['photo']['size']['height']) {
						keyframeid = 'ellimask_col';
					} else {
						keyframeid = 'ellimask_row';
					}
				}

				if (keyframeid == 'maskupscalein-mask') {
					setTimeout(() => {
						let maskobj = document.querySelector('.maskupscalein-mask');
						if (maskobj) {
							maskobj.className = maskobj.className.replace('maskupscalein-mask', '');
						}
					}, 2250);
				}

				return keyframeid;
				// _class[ keyframeid ] = true;
				// return _class;
			},
			timer () {
				this.run();
				this.timeEvent = setInterval(() => {
					this.run();
				}, 1000)
			},
			__getIdxBySceond (second) {
				second = parseInt(second);
				let idx = null;
				for (let i=0, lens=this.timeline.length; i<lens; i++) {
					if (this.timeline[i]['second'] == second) {
						if (this.timeline[i]['type'] == 'end') {
							idx = 0; // 重新开始
						}  else {
							idx = i;
						}
						break;
					}
				}
				return idx;
			},
			run () {
				let idx = this.__getIdxBySceond(this.second);

				if (idx === 0) { // 动画结束，重新循环
					this.second = 0;
				}
				if (idx !== null) { // 获取进场及出场动画时间
					let timeline = this.$util.copy(this.timeline[idx]);
					let in_duration = this.getSimpleAnimationDuration(timeline['in']),
						out_duration,
						gap_duration;

					if (timeline['gap']) {
						gap_duration = parseInt(timeline['gap']);
					}

					if (timeline['out']) {
						out_duration = this.getSimpleAnimationDuration(timeline['out']);
					}
					
					timeline['ani_step'] = 1; // 触发进场动画
					timeline['in_duration'] = in_duration;

					// 进场时切换图片
					let photoidx = this.currentIdx % this.$store.state.config.images.length;
					let nextphotoidx = (this.currentIdx + 1) % this.$store.state.config.images.length;
					
					// 触发结束页面开始
					let theme_num = this.$o_util.getSceneNum(this.mode),
						images_num = this.$store.state.config.images.length;
					theme_num = images_num > theme_num ? images_num : theme_num;
					if (this.currentIdx == theme_num) {
						this.$emit('showEndPage');
					}
					// 触发结束页面结束

					let bgidx = (this.currentIdx + 1) % this.mode['mode']['bg'].length;
					let orisize = this.$store.state.config.images[photoidx]['orisize'];
					let contain_size = this.photoFillMode == 'cover' ? this.$o_util.getCoverSize(orisize, this.mode['mode']['box']) : this.$o_util.getContainSize(orisize, this.mode['mode']['box']);

					timeline['photo']['size'] = {
						'width': parseInt(contain_size['width']),
						'height': parseInt(contain_size['height']),
						'marginTop': contain_size['marginTop'],
						'marginLeft': contain_size['marginLeft']
					};

					// 预加载下一张图片
					this._preloadNextPhotos(this.$store.state.config.images[nextphotoidx])

					this.currentText = this.$store.state.config.images[photoidx]['tx'];

					// 赋值当前图片
					timeline['photo']['src'] = this.currentImageUrl;
					timeline['photo']['idx'] = this.currentImageIdx;
					timeline['idx'] = this.currentIdx;

					// 更新timeline
					this.$set(this.timeline, idx, timeline);

					this.currentIdx ++;

					if (this.timeline[idx]['out']) {

						setTimeout(() => {
							this.timeline[idx]['ani_step'] = 2; // 触发出场动画
							this.timeline[idx]['out_duration'] = out_duration;

							setTimeout(() => {
								this.currentBgIdx = bgidx;// 触发背景切换
								this.timeline[idx]['ani_step'] = 0; // 动画结束，元素隐藏
							}, out_duration*1000);

						}, (in_duration+gap_duration)*1000);

					} else {

						let duration = 0;

						if (this.timeline[idx]['gap']) {
							duration += parseInt(this.timeline[idx]['gap']);
						}

						duration += in_duration;

						setTimeout(() => {
							this.currentBgIdx = bgidx;// 触发背景切换
							this.timeline[idx]['ani_step'] = 0;
						}, duration*1000);
					}
				}
				this.second ++;
			},
			wxPreview () {
				let index = (this.currentIdx - 1) % this.$store.state.config.images.length
				this.$emit('wxPreview', this.$store.state.config.images[index])
				// this.$emit('wxPreview', this.currentImageUrl);
			},
			// 生命周期：进场动画播放完毕
			preluded () {},
			// 预加载下一张用户图片
			_preloadNextPhotos (photo) {
				if (photo['orisize']) {
					return;
				}

				let _this = this;

				let im = new Image();
				im.onload = function() {
					_this.$store.commit('setImageOriSize', {'imageid': photo['id'], 'orisize': [this.width, this.height]});
					_this.$store.commit('setImageObj', {'imagesrc': photo['im'], 'imageobj': this});
				};
				im.onerror = function() {
					_this.$store.commit('setImageOriSize', {'imageid': photo['id'], 'orisize': [500, 500]});
				};
				im.src = photo['im'];
			},
			// 获取动画的运行时长
			getSimpleAnimationDuration (animationid) {
				if (this.scene[animationid] && this.scene[animationid]['duration']) {
					return parseFloat(this.scene[animationid]['duration']);
				}

				let keyframeids = this.__reBuildKeyframeidsBySceneKeyframe(animationid);

				let r = [];
				for(let i=0; i<keyframeids.length; i++) {
					// 若存在动画属性中定义的animation，则覆盖默认animation
					if (this.scene[animationid] 
						&& this.scene[animationid]['keyframes']
						&& this.scene[animationid]['keyframes']['data']
						&& this.scene[animationid]['keyframes']['data'][keyframeids[i]]) {
						
						r.push( parseFloat(this.scene[animationid]['keyframes']['data'][keyframeids[i]]['style']['animation'].split(' ')[0]) );
					} else{
						r.push(this.$o_util.getKeyframeDuration(keyframeids[i]));
					}
				}
				r.sort(function(a, b){return b - a});
				return r[0];
			},
			// 覆盖自定义keyframeids
			__reBuildKeyframeidsBySceneKeyframe (animationid) {
				let keyframeids = APP.globalData.keyFrames.simple_animations[animationid]['keyframes'];

				// 覆盖自定义keyframeid
				let scene_keyframeids = this.__getSceneKeyframeids(animationid);
				for (let i=0; i<scene_keyframeids.length; i++) {
					if (scene_keyframeids[i]) {
						keyframeids[i] = scene_keyframeids[i];
					}
				}
				return keyframeids;
			},
			// 获取动画属性中定义的keyframes
			__getSceneKeyframeids (animationid) {
				let scene_keyframeids = [];
				if (this.scene[animationid] && this.scene[animationid]['keyframes']) {

					let scene_keyframes = this.scene[animationid]['keyframes'];

					if (this.$util.typeOf(scene_keyframes) == 'array') {
						scene_keyframeids = scene_keyframes;
					} else if (scene_keyframes['id']) {
						scene_keyframeids = this.scene[animationid]['keyframes']['id'];
					}
				}
				return scene_keyframeids;
			}
		}
	}
</script>

<style>
	.simple-page {
		z-index: 1;
		overflow: hidden;
	}
	.simple-contain {
		z-index: 5;
	}
	.simple-ani_1 {
		margin: auto;
	}
	/* .pers-inherit{
		-webkit-perspective: inherit;
	} */
	.simple-photo_box, .simple-photo {
		left: 0;
		top: 0;
	}
	.simple-photo_border {
		position: relative;
	}
	.end {
		display: none!important;
	}

	.maskupscalein-mask {
        -webkit-mask: -webkit-gradient(linear, 50% 65%, 50% 58%, from(rgba(0,0,0,0)), to(rgba(0,0,0,1)));
        -webkit-mask-size: 100% 800px;
        -webkit-mask-position: 0 0;
        -webkit-mask-repeat: no-repeat;
        width: 100%!important;
        height: 600px!important;
        position: static!important;
    }
    .circlemask {
        width: 500px;
        height: 500px;
        top: 150px;
        -webkit-mask-image: url('http://resali.lititutu.cn/scenes/jiuzuidehudie/76FC406AE656B63E8F906C1AC28374BB.png');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        overflow: hidden;
    }
    .fullScreenmask {
        width: 500px;
        height: 100%;
        -webkit-mask-image: url('http://resali.lititutu.cn/icons/mask/yuanding.png');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        overflow: hidden;
    }
    .ellimask_row {
        width: 550px;
        height: 389.4px;
        top: 50%;
        left: 50%;
        margin-top: -194.7px;
        margin-left: -275px;
        -webkit-mask-image: url('http://resali.lititutu.cn/icons/tuku/4245A8730198C8B230A54495374FE85C.png');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        overflow: hidden;
    }
    .ellimask_col {
        width: 550px;
        height: 776px;
        top: 50%;
        left: 50%;
        margin-top: -388px;
        margin-left: -275px;
        -webkit-mask-image: url('http://resali.lititutu.cn/icons/tuku/583A99A920ABF8C3EA802422B4A84B4F.png');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        overflow: hidden;
    }
    .ellimask2_col {
        width: 500px;
        height: 685px;
        -webkit-mask-image: url('http://resali.lititutu.cn/icons/tuku/2F6DB4FE38933EFCAA78FEF7FA91F31B.png');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        overflow: hidden;
    }
    .falldown_mask {
        -webkit-mask-size: 500px calc(100% - 100px);
        -webkit-mask-image: url('http://resali.lititutu.cn/scenes/mangzhong/mask.png');
        -webkit-mask-repeat: no-repeat;
	}
	
	@keyframes dynamic_mask {
		0% {
			-webkit-mask-position: 0 0;
		}
		100% {
			-webkit-mask-position: 0 bottom;
		}
	}
</style>









