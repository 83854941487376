<template>
	<div class="bg_con">
	    <div class="bg_container" :style="{'backgroundImage': 'url('+mode[0]+')'}">
	          <div class="flower_box">
	              <div class="flower3 flower" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div v-for="idx of 100" :key="'aixinyuanflower_'+idx" class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <!-- <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	              <div class="flower2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div> -->
	          </div>
	          <div class="flower3 flower3_1" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	          <div class="flower3 flower3_2" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	          <div class="flower3 flower3_3" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	          <div class="flower3 flower3_4" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	          <div class="flower3 flower3_5" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
	      </div>
	    <div class="user_container">
	        <div class="user_box">
	            <div class="user bg_photo" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        </div>
	        <div class="aiXinBorder" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
	    </div>
	    <div class="circle">
	        <div class="center_circle" :style="{backgroundImage: 'url('+mode[4]+')'}"></div>
	        <div class="out_circle" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'aixinyuan',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.bg_con {
		position:absolute;
		width:100%;
		height:100%;
		transform-style:preserve-3d;
	}
	.bg_container {
		position:absolute;
		width:100%;
		height:100%;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		transform:translateZ(-@bw * 1.389);
		perspective:@bw * 1.389;
		transform-origin:50% 40%;
		transform-style:preserve-3d;
	}
	.flower {
		position:absolute;
		width:@bw * 0.139;
		height:@bw * 0.153;
		opacity:0;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		animation-name:innn;
		animation-iteration-count:infinite;
	}
	.flower2 {
		position:absolute;
		width:@bw * 0.139;
		height:@bw * 0.153;
		opacity:0;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		animation-name:innn2;
		animation-iteration-count:infinite;
	}
	@keyframes innn2 {
		0% {
		opacity:1;
		-webkit-transform:translate3d(0,0,-@bw * 1.389) scale(1.5);
	}
	70% {
		opacity:1;
	}
	100% {
		opacity:0;
		-webkit-transform:translate3d(@bw * 0.0,-@bw * 0.069,@bw * 1.111) scale(0.2);
	}
	}@keyframes innn {
		0% {
		opacity:1;
		-webkit-transform:translate3d(0,0,-@bw * 1.389) scale3d(1,1,1);
	}
	70% {
		opacity:1;
	}
	100% {
		opacity:0;
		-webkit-transform:translate3d(@bw * 0.0,-@bw * 0.069,@bw * 1.111) scale3d(0.1,0.1,0.1);
	}
	}.user_container {
		position:absolute;
		width:@bw * 1.506;
		height:@bw * 1.389;
		bottom:16%;
		left:-@bw * 0.26;
		transform:translateZ(@bw * 13.889);
		animation:userAni 2.5s 1s infinite alternate ease-in-out both;
	}
	@keyframes userAni {
		0% {
		-webkit-transform:translateZ(@bw * 13.889) rotateZ(1deg) rotateX(1deg);
	}
	100% {
		-webkit-transform:translateZ(@bw * 13.889) rotateZ(-4deg) rotateX(10deg);
	}
	}.aiXinBorder {
		position:absolute;
		width:@bw * 1.506;
		height:@bw * 1.389;
		top:50%;
		margin-top:-@bw * 0.694;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:100%;
		pointer-events: none;
		/* transform:translateZ(@bw * 13.89);
		*/
	}
	.user_box {
		position:absolute;
		width:@bw * 0.972;
		height:@bw * 0.972;
		/* top:@bw * 0.278;
		left:@bw * 0.007;
		*/
		left:@bw * 0.276;
		top:@bw * 0.193;
		-webkit-mask-repeat:no-repeat;
		mask-repeat:no-repeat;
		-webkit-mask-position:center center;
		mask-position:center center;
		-webkit-mask-size:cover;
		mask-size:cover;
		-webkit-mask-image:url('http://litiresource.xcgogo.site/user/5980171/images/73b227315a99f4db0d00e2e40d1fc36b.png')
	}
	.user {
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		right:0;
		bottom:0;
		margin:auto;
		background-repeat:no-repeat;
		background-position:center;
		background-size:cover;
	}
	.flower3 {
		top:50%;
		position:absolute;
		width:@bw * 0.139;
		height:50%;
		background-size:100%;
		background-repeat:no-repeat;
		background-position:center bottom;
	}
	.flower3_1 {
		transform-origin:0% -100%;
		animation:flower3_1 4s linear infinite;
	}
	@keyframes flower3_1 {
		0% {
		}20% {
		transform:rotateZ(-20deg);
	}
	50% {
		transform:rotateZ(-45deg);
	}
	100% {
		transform:rotateZ(-45deg);
	}
	}.flower3_2 {
		animation:flower3_2 4s linear infinite;
	}
	@keyframes flower3_2 {
		0% {
		}10% {
		transform:translate3d(@bw * 0.417,@bw * 0.0,0);
	}
	50% {
		transform:translate3d(@bw * 1.111,@bw * 0.139,0);
	}
	100% {
		transform:translate3d(@bw * 1.111,@bw * 0.139,0);
	}
	}.flower3_3 {
		animation:flower3_3 4s linear infinite;
	}
	@keyframes flower3_3 {
		0% {
		}15% {
		transform:translate3d(@bw * 0.322,-@bw * 0.275,0);
	}
	50% {
		transform:translate3d(@bw * 0.694,-@bw * 0.694,0);
	}
	100% {
		transform:translate3d(@bw * 0.694,-@bw * 0.694,0);
	}
	}.flower3_4 {
		animation:flower3_4 4s linear infinite;
	}
	@keyframes flower3_4 {
		0% {
		}10% {
		transform:translate3d(@bw * 0.144,-@bw * 0.246,0);
	}
	50% {
		transform:translate3d(@bw * 0.556,-@bw * 0.972,0);
	}
	100% {
		transform:translate3d(@bw * 0.556,-@bw * 0.972,0);
	}
	}.flower3_5 {
		transform-origin:-400% -150%;
		animation:flower3_5 4s linear infinite;
	}
	@keyframes flower3_5 {
		0% {
		}
		20% {
			transform:rotateZ(-15deg);
		}
		50% {
			transform:rotateZ(-40deg);
		}
		100% {
			transform:rotateZ(-40deg);
		}
	}
	.circle {
		position:absolute;
		width:@bw * 0.694;
		height:@bw * 0.694;
		left:@bw * 0.175;
		top:55%;
		-webkit-transform:rotateX(30deg);
		transform:translateZ(-@bw * 0.139) rotateX(60deg);
		/* background:burlywood;
		*/
	}
	.center_circle {
		position:absolute;
		width:50%;
		height:50%;
		top:0;
		left:0;
		right:0;
		bottom:0;
		margin:auto;
		border-radius:50%;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
	}
	.out_circle {
		position:absolute;
		width:100%;
		height:100%;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		animation:myscale 8s infinite linear;
	}
	@keyframes myscale {
		0% {

		}
		100% {
			transform:rotate(360deg);
		}
	}

	.flower2:nth-child(2) {top:@bw * 0.483; left:@bw * 0.351; animation-duration: 9.43819s; animation-delay: -4.7191s;}
	.flower2:nth-child(3) {top:@bw * 0.483; left:@bw * 0.351; animation-duration: 9.43819s; animation-delay: -4.7191s;}
	.flower2:nth-child(4) {top:@bw * 0.9; left:@bw * 0.297; animation-duration: 3.31356s; animation-delay: -1.65678s;}
	.flower2:nth-child(5) {top:@bw * 0.9; left:@bw * 0.297; animation-duration: 3.31356s; animation-delay: -1.65678s;}
	.flower2:nth-child(6) {top:@bw * 0.471; left:@bw * 0.343; animation-duration: 3.71806s; animation-delay: -1.85903s;}
	.flower2:nth-child(7) {top:@bw * 0.471; left:@bw * 0.343; animation-duration: 3.71806s; animation-delay: -1.85903s;}
	.flower2:nth-child(8) {top:@bw * 0.829; left:@bw * 0.533; animation-duration: 4.96217s; animation-delay: -2.48108s;}
	.flower2:nth-child(9) {top:@bw * 0.829; left:@bw * 0.533; animation-duration: 4.96217s; animation-delay: -2.48108s;}
	.flower2:nth-child(10) {top:@bw * 0.583; left:@bw * 0.633; animation-duration: 4.25976s; animation-delay: -2.12988s;}
	.flower2:nth-child(11) {top:@bw * 0.583; left:@bw * 0.633; animation-duration: 4.25976s; animation-delay: -2.12988s;}
	.flower2:nth-child(12) {top:@bw * 0.59; left:@bw * 0.319; animation-duration: 9.75519s; animation-delay: -4.8776s;}
	.flower2:nth-child(13) {top:@bw * 0.59; left:@bw * 0.319; animation-duration: 9.75519s; animation-delay: -4.8776s;}
	.flower2:nth-child(14) {top:@bw * 0.906; left:@bw * 0.467; animation-duration: 9.50133s; animation-delay: -4.75067s;}
	.flower2:nth-child(15) {top:@bw * 0.906; left:@bw * 0.467; animation-duration: 9.50133s; animation-delay: -4.75067s;}
	.flower2:nth-child(16) {top:@bw * 0.989; left:@bw * 0.504; animation-duration: 8.62026s; animation-delay: -4.31013s;}
	.flower2:nth-child(17) {top:@bw * 0.989; left:@bw * 0.504; animation-duration: 8.62026s; animation-delay: -4.31013s;}
	.flower2:nth-child(18) {top:@bw * 0.861; left:@bw * 0.328; animation-duration: 3.16607s; animation-delay: -1.58304s;}
	.flower2:nth-child(19) {top:@bw * 0.861; left:@bw * 0.328; animation-duration: 3.16607s; animation-delay: -1.58304s;}
	.flower2:nth-child(20) {top:@bw * 0.915; left:@bw * 0.443; animation-duration: 8.57087s; animation-delay: -4.28544s;}
	.flower2:nth-child(21) {top:@bw * 0.915; left:@bw * 0.443; animation-duration: 8.57087s; animation-delay: -4.28544s;}
	.flower2:nth-child(22) {top:@bw * 0.754; left:@bw * 0.274; animation-duration: 6.68859s; animation-delay: -3.3443s;}
	.flower2:nth-child(23) {top:@bw * 0.754; left:@bw * 0.274; animation-duration: 6.68859s; animation-delay: -3.3443s;}
	.flower2:nth-child(24) {top:@bw * 0.912; left:@bw * 0.26; animation-duration: 2.42615s; animation-delay: -1.21308s;}
	.flower2:nth-child(25) {top:@bw * 0.912; left:@bw * 0.26; animation-duration: 2.42615s; animation-delay: -1.21308s;}
	.flower2:nth-child(26) {top:@bw * 0.864; left:@bw * 0.276; animation-duration: 4.77255s; animation-delay: -2.38627s;}
	.flower2:nth-child(27) {top:@bw * 0.864; left:@bw * 0.276; animation-duration: 4.77255s; animation-delay: -2.38627s;}
	.flower2:nth-child(28) {top:@bw * 0.6; left:@bw * 0.324; animation-duration: 3.37439s; animation-delay: -1.68719s;}
	.flower2:nth-child(29) {top:@bw * 0.6; left:@bw * 0.324; animation-duration: 3.37439s; animation-delay: -1.68719s;}
	.flower2:nth-child(30) {top:@bw * 0.778; left:@bw * 0.307; animation-duration: 5.29588s; animation-delay: -2.64794s;}
	.flower2:nth-child(31) {top:@bw * 0.778; left:@bw * 0.307; animation-duration: 5.29588s; animation-delay: -2.64794s;}
	.flower2:nth-child(32) {top:@bw * 0.803; left:@bw * 0.378; animation-duration: 4.78977s; animation-delay: -2.39488s;}
	.flower2:nth-child(33) {top:@bw * 0.803; left:@bw * 0.378; animation-duration: 4.78977s; animation-delay: -2.39488s;}
	.flower2:nth-child(34) {top:@bw * 0.831; left:@bw * 0.533; animation-duration: 7.02889s; animation-delay: -3.51445s;}
	.flower2:nth-child(35) {top:@bw * 0.831; left:@bw * 0.533; animation-duration: 7.02889s; animation-delay: -3.51445s;}
	.flower2:nth-child(36) {top:@bw * 0.531; left:@bw * 0.389; animation-duration: 9.28241s; animation-delay: -4.64121s;}
	.flower2:nth-child(37) {top:@bw * 0.531; left:@bw * 0.389; animation-duration: 9.28241s; animation-delay: -4.64121s;}
	.flower2:nth-child(38) {top:@bw * 0.471; left:@bw * 0.393; animation-duration: 4.26301s; animation-delay: -2.13151s;}
	.flower2:nth-child(39) {top:@bw * 0.471; left:@bw * 0.393; animation-duration: 4.26301s; animation-delay: -2.13151s;}
	.flower2:nth-child(40) {top:@bw * 0.626; left:@bw * 0.354; animation-duration: 8.6519s; animation-delay: -4.32595s;}
	.flower2:nth-child(41) {top:@bw * 0.626; left:@bw * 0.354; animation-duration: 8.6519s; animation-delay: -4.32595s;}
	.flower2:nth-child(42) {top:@bw * 0.464; left:@bw * 0.386; animation-duration: 2.37695s; animation-delay: -1.18847s;}
	.flower2:nth-child(43) {top:@bw * 0.464; left:@bw * 0.386; animation-duration: 2.37695s; animation-delay: -1.18847s;}
	.flower2:nth-child(44) {top:@bw * 0.744; left:@bw * 0.464; animation-duration: 4.00528s; animation-delay: -2.00264s;}
	.flower2:nth-child(45) {top:@bw * 0.744; left:@bw * 0.464; animation-duration: 4.00528s; animation-delay: -2.00264s;}
	.flower2:nth-child(46) {top:@bw * 0.557; left:@bw * 0.436; animation-duration: 4.12121s; animation-delay: -2.0606s;}
	.flower2:nth-child(47) {top:@bw * 0.557; left:@bw * 0.436; animation-duration: 4.12121s; animation-delay: -2.0606s;}
	.flower2:nth-child(48) {top:@bw * 0.962; left:@bw * 0.383; animation-duration: 5.34915s; animation-delay: -2.67457s;}
	.flower2:nth-child(49) {top:@bw * 0.962; left:@bw * 0.383; animation-duration: 5.34915s; animation-delay: -2.67457s;}
	.flower2:nth-child(50) {top:@bw * 0.614; left:@bw * 0.254; animation-duration: 9.52641s; animation-delay: -4.76321s;}
	.flower2:nth-child(51) {top:@bw * 0.614; left:@bw * 0.254; animation-duration: 9.52641s; animation-delay: -4.76321s;}
	.flower2:nth-child(52) {top:@bw * 0.883; left:@bw * 0.64; animation-duration: 2.34937s; animation-delay: -1.17469s;}
	.flower2:nth-child(53) {top:@bw * 0.883; left:@bw * 0.64; animation-duration: 2.34937s; animation-delay: -1.17469s;}
	.flower2:nth-child(54) {top:@bw * 0.681; left:@bw * 0.319; animation-duration: 3.67593s; animation-delay: -1.83797s;}
	.flower2:nth-child(55) {top:@bw * 0.681; left:@bw * 0.319; animation-duration: 3.67593s; animation-delay: -1.83797s;}
	.flower2:nth-child(56) {top:@bw * 0.567; left:@bw * 0.618; animation-duration: 3.52434s; animation-delay: -1.76217s;}
	.flower2:nth-child(57) {top:@bw * 0.567; left:@bw * 0.618; animation-duration: 3.52434s; animation-delay: -1.76217s;}
	.flower2:nth-child(58) {top:@bw * 0.725; left:@bw * 0.454; animation-duration: 8.52434s; animation-delay: -4.26217s;}
	.flower2:nth-child(59) {top:@bw * 0.725; left:@bw * 0.454; animation-duration: 8.52434s; animation-delay: -4.26217s;}
	.flower2:nth-child(60) {top:@bw * 0.972; left:@bw * 0.357; animation-duration: 7.87862s; animation-delay: -3.93931s;}
	.flower2:nth-child(61) {top:@bw * 0.972; left:@bw * 0.357; animation-duration: 7.87862s; animation-delay: -3.93931s;}
	.flower2:nth-child(62) {top:@bw * 0.499; left:@bw * 0.608; animation-duration: 3.77785s; animation-delay: -1.88892s;}
	.flower2:nth-child(63) {top:@bw * 0.499; left:@bw * 0.608; animation-duration: 3.77785s; animation-delay: -1.88892s;}
	.flower2:nth-child(64) {top:@bw * 0.546; left:@bw * 0.326; animation-duration: 7.09619s; animation-delay: -3.54809s;}
	.flower2:nth-child(65) {top:@bw * 0.546; left:@bw * 0.326; animation-duration: 7.09619s; animation-delay: -3.54809s;}
	.flower2:nth-child(66) {top:@bw * 0.618; left:@bw * 0.414; animation-duration: 3.09763s; animation-delay: -1.54882s;}
	.flower2:nth-child(67) {top:@bw * 0.618; left:@bw * 0.414; animation-duration: 3.09763s; animation-delay: -1.54882s;}
	.flower2:nth-child(68) {top:@bw * 0.675; left:@bw * 0.446; animation-duration: 7.10251s; animation-delay: -3.55125s;}
	.flower2:nth-child(69) {top:@bw * 0.675; left:@bw * 0.446; animation-duration: 7.10251s; animation-delay: -3.55125s;}
	.flower2:nth-child(70) {top:@bw * 0.994; left:@bw * 0.432; animation-duration: 4.16922s; animation-delay: -2.08461s;}
	.flower2:nth-child(71) {top:@bw * 0.994; left:@bw * 0.432; animation-duration: 4.16922s; animation-delay: -2.08461s;}
	.flower2:nth-child(72) {top:@bw * 0.875; left:@bw * 0.261; animation-duration: 6.86309s; animation-delay: -3.43155s;}
	.flower2:nth-child(73) {top:@bw * 0.875; left:@bw * 0.261; animation-duration: 6.86309s; animation-delay: -3.43155s;}
	.flower2:nth-child(74) {top:@bw * 0.539; left:@bw * 0.497; animation-duration: 6.2716s; animation-delay: -3.1358s;}
	.flower2:nth-child(75) {top:@bw * 0.539; left:@bw * 0.497; animation-duration: 6.2716s; animation-delay: -3.1358s;}
	.flower2:nth-child(76) {top:@bw * 0.606; left:@bw * 0.526; animation-duration: 8.0384s; animation-delay: -4.0192s;}
	.flower2:nth-child(77) {top:@bw * 0.606; left:@bw * 0.526; animation-duration: 8.0384s; animation-delay: -4.0192s;}
	.flower2:nth-child(78) {top:@bw * 0.593; left:@bw * 0.604; animation-duration: 5.8663s; animation-delay: -2.93315s;}
	.flower2:nth-child(79) {top:@bw * 0.593; left:@bw * 0.604; animation-duration: 5.8663s; animation-delay: -2.93315s;}
	.flower2:nth-child(80) {top:@bw * 0.706; left:@bw * 0.294; animation-duration: 4.99831s; animation-delay: -2.49915s;}
	.flower2:nth-child(81) {top:@bw * 0.706; left:@bw * 0.294; animation-duration: 4.99831s; animation-delay: -2.49915s;}
	.flower2:nth-child(82) {top:@bw * 0.942; left:@bw * 0.318; animation-duration: 2.32395s; animation-delay: -1.16197s;}
	.flower2:nth-child(83) {top:@bw * 0.942; left:@bw * 0.318; animation-duration: 2.32395s; animation-delay: -1.16197s;}
	.flower2:nth-child(84) {top:@bw * 0.953; left:@bw * 0.499; animation-duration: 5.24788s; animation-delay: -2.62394s;}
	.flower2:nth-child(85) {top:@bw * 0.953; left:@bw * 0.499; animation-duration: 5.24788s; animation-delay: -2.62394s;}
	.flower2:nth-child(86) {top:@bw * 0.812; left:@bw * 0.251; animation-duration: 7.00956s; animation-delay: -3.50478s;}
	.flower2:nth-child(87) {top:@bw * 0.812; left:@bw * 0.251; animation-duration: 7.00956s; animation-delay: -3.50478s;}
	.flower2:nth-child(88) {top:@bw * 0.821; left:@bw * 0.312; animation-duration: 2.38422s; animation-delay: -1.19211s;}
	.flower2:nth-child(89) {top:@bw * 0.821; left:@bw * 0.312; animation-duration: 2.38422s; animation-delay: -1.19211s;}
	.flower2:nth-child(90) {top:@bw * 0.967; left:@bw * 0.433; animation-duration: 5.69467s; animation-delay: -2.84733s;}
	.flower2:nth-child(91) {top:@bw * 0.967; left:@bw * 0.433; animation-duration: 5.69467s; animation-delay: -2.84733s;}
	.flower2:nth-child(92) {top:@bw * 0.931; left:@bw * 0.451; animation-duration: 5.66201s; animation-delay: -2.831s;}
	.flower2:nth-child(93) {top:@bw * 0.931; left:@bw * 0.451; animation-duration: 5.66201s; animation-delay: -2.831s;}
	.flower2:nth-child(94) {top:@bw * 0.694; left:@bw * 0.45; animation-duration: 7.43392s; animation-delay: -3.71696s;}
	.flower2:nth-child(95) {top:@bw * 0.694; left:@bw * 0.45; animation-duration: 7.43392s; animation-delay: -3.71696s;}
	.flower2:nth-child(96) {top:@bw * 0.836; left:@bw * 0.489; animation-duration: 4.7239s; animation-delay: -2.36195s;}
	.flower2:nth-child(97) {top:@bw * 0.836; left:@bw * 0.489; animation-duration: 4.7239s; animation-delay: -2.36195s;}
	.flower2:nth-child(98) {top:@bw * 0.746; left:@bw * 0.492; animation-duration: 6.29343s; animation-delay: -3.14671s;}
	.flower2:nth-child(99) {top:@bw * 0.746; left:@bw * 0.492; animation-duration: 6.29343s; animation-delay: -3.14671s;}
	.flower2:nth-child(100) {top:@bw * 0.618; left:@bw * 0.4; animation-duration: 3.7071s; animation-delay: -1.85355s;}
	.flower2:nth-child(101) {top:@bw * 0.618; left:@bw * 0.4; animation-duration: 3.7071s; animation-delay: -1.85355s;}
</style>