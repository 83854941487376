<template>
	<div class="dialog" style="z-index: 2003;">
		<div class="dialog__header">{{confirmOptions.title}}</div>
		<div class="dialog__content">
			<div class="dialog__message dialog__message--has-title" v-if="confirmOptions.message[0] == '<'"><span v-html="confirmOptions.message"></span></div>
			<div class="dialog__message dialog__message--has-title" v-else>{{confirmOptions.message}}</div>
		</div>
		<div class="hairline--top dialog__footer dialog__footer--buttons">
			<button class="button button--default button--large dialog__cancel" @click="clickConfirm('cancel')">
				<span class="button__text">取消</span>
			</button>
			<button class="button button--default button--large dialog__confirm hairline--left" @click="clickConfirm('confirm')">
				<span class="button__text">确认</span>
			</button>
		</div>
	</div>	
</template>

<script>
	/* eslint-disable */
	export default {
		props: ['confirmOptions'],
		data () {
			return {}
		},
		methods: {
			clickConfirm (action) {
				this.$emit('clickConfirm', action);
			},
		}
	}
</script>

<style scoped lang="less">
	@orange: #ff6126;

	.dialog {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 85%;
		font-size:@bw * 0.049;
		overflow: hidden;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		border-radius: 8.8px;
		background-color: #fff;
		-webkit-transform: translate3d(-50%, -50%, 0);
		transform: translate3d(-50%, -50%, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
	.dialog__header {
		font-weight: 500;
		padding-top:@bw * 0.067;
		text-align: center;
	}
	.dialog__message {
		padding:@bw * 0.076;
		font-size:@bw * 0.042;
		line-height: 1.5;
		max-height: 60vh;
		overflow-y: auto;
		text-align: center;
		-webkit-overflow-scrolling: touch;
		white-space: pre-wrap;
	}
	.dialog__message--has-title {
		padding-top:@bw * 0.036;
		color: #7d7e80;
	}
	.hairline--top {

	}
	.dialog__footer {
	    overflow: hidden;
	    -webkit-user-select: none;
	    user-select: none;
	}
	.dialog__footer--buttons {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
	}
	.dialog__footer--buttons .button {
	    -webkit-box-flex: 1;
	    -webkit-flex: 1;
	    flex: 1;
	}
	.dialog .button {
	    border: 0;
	}
	.dialog__confirm, .dialog__confirm:active {
	    color: @orange!important;
	}
	.button--default {
	    color: #323233;
	    background-color: #fff;
	    border:@bw * 0.003 solid #ebedf0;
	}
	.button--large {
	    width: 100%;
	    height:@bw * 0.153;
	    line-height:@bw * 0.147;
	}
	.button {
	    position: relative;
	    padding: 0;
	    display: inline-block;
	    height:@bw * 0.136;
	    line-height:@bw * 0.129;
	    font-size:@bw * 0.05;
	    text-align: center;
	    box-sizing: border-box;
	    border-radius:@bw * 0.006;
	    -webkit-appearance: none;
	    -webkit-text-size-adjust: 100%;
	}
	.button:active {
		background-color: #ebedf0;
	}
	.button::before {
	    content: " ";
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    opacity: 0;
	    width: 100%;
	    height: 100%;
	    border: inherit;
	    border-color: #000;
	    background-color: #000;
	    border-radius: inherit;
	    -webkit-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
	}
	.hairline--top {
		border-top:@bw * 0.003 solid #ebedf0;
	}
</style>