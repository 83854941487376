<template>
	<div class="huaquanzhuan poc">
		<div class="st-hqz-cont-1">
			<div class="st-hqz-rim-box preserve3d">
				<div :style="{backgroundImage: 'url('+currentPhoto['im']+')'}" class="st-hqz-rim-photo st-hqz-rim-photo-1 preserve3d"></div>
				<div class="poc hqz-rim-kuang-1 preserve3d">
					<div class="poc hqz-rim hqz-rim-kuang" :style="{borderImageSource: 'url('+getFangBorder+')', borderImageSlice: myBorderSlice, borderWidth: myBorderWidth}"></div>
				</div>
				<div class="poc hqz-rim-2 preserve3d">
					<div class="poc hqz-rim hqz-rim-item" :style="{borderImageSource: 'url('+getFangBorder+')', borderImageSlice: myBorderSlice, borderWidth: myBorderWidth}"></div>
				</div>
				<div class="poc hqz-rim-3 preserve3d">
					<div class="poc hqz-rim hqz-rim-item" :style="{borderImageSource: 'url('+getFangBorder+')', borderImageSlice: myBorderSlice, borderWidth: myBorderWidth}"></div>
				</div>
				<div class="poc hqz-rim-4 preserve3d">
					<div class="poc hqz-rim hqz-rim-item" :style="{borderImageSource: 'url('+getFangBorder+')', borderImageSlice: myBorderSlice, borderWidth: myBorderWidth}"></div>
				</div>
				<div class="poc hqz-rim-5 preserve3d">
					<div class="poc hqz-rim hqz-rim-item" :style="{borderImageSource: 'url('+getFangBorder+')', borderImageSlice: myBorderSlice, borderWidth: myBorderWidth}"></div>
				</div>
				<div class="poc hqz-rim-6 preserve3d">
					<div class="poc hqz-rim hqz-rim-item" :style="{borderImageSource: 'url('+getFangBorder+')', borderImageSlice: myBorderSlice, borderWidth: myBorderWidth}"></div>
				</div>
				<div :style="{backgroundImage: 'url('+currentPhoto['im']+')'}" class="st-hqz-rim-photo st-hqz-rim-photo-2 preserve3d"></div>
				<div class="poc hqz-rim-kuang-2 preserve3d">
					<div class="poc hqz-rim hqz-rim-kuang" :style="{borderImageSource: 'url('+getFangBorder+')', borderImageSlice: myBorderSlice, borderWidth: myBorderWidth}"></div>
				</div>
			</div>
		</div>
			<!-- <div class="st-hqz-rim-cont preserve3d">
		</div> -->
		<div class="st-hqz-cont-2">
			<div class="st-hqz-bot-cont">
				<div class="st-hqz-bot-item st-hqz-bot-item-1">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
				<div class="st-hqz-bot-item st-hqz-bot-item-2">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
				<div class="st-hqz-bot-item st-hqz-bot-item-3">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
				<div class="st-hqz-bot-item st-hqz-bot-item-4">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
		        <div class="st-hqz-bot-item st-hqz-bot-item-5">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
		        <div class="st-hqz-bot-item st-hqz-bot-item-6">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
		        <div class="st-hqz-bot-item st-hqz-bot-item-7">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
		        <div class="st-hqz-bot-item st-hqz-bot-item-8">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
		        <div class="st-hqz-bot-item st-hqz-bot-item-9">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
		        <div class="st-hqz-bot-item st-st-hqz-bot-item-10">
		            <div class="hqz-bot-photo-1" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
		            <div class="hqz-bot-photo-2" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
					<img :src="getYuanBorder" class="st-hqz-bot-item-border">
		        </div>
			</div>
		</div>
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'huaquanzhuan',
		extends: BaseElem,
		data () {
			return {}
		},
		computed: {
			config () {
				return this.comp['config'] || [];
			},
			getYuanBorder () {
				return this.config[0] || 'http://resali.lititutu.cn/icons/huahuan/06.png';
			},
			getFangBorder () {
				return this.config[1] || 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png';
			},
			myBorderSlice () {
				return this.config[2] || 45;
			},
			myBorderWidth () {
				let w = this.$util.getPx(this.config[3] || 50);
				return this.$util.pxTransform(w);
			}
		},
		methods: {
			
		}
	}
</script>

<style>
	.st-hqz-cont-1 {
		-webkit-perspective: 1600px;
    	transform: translateY(60px);
	}
	.st-hqz-cont-2 {
		-webkit-perspective: 720px;
    	transform: translateY(60px);
	}
	.st-hqz-rim-cont {
		transform-style: preserve-3d;
		animation: st_huaquanzhuan_zhuan 6s linear infinite both;
		margin-top: 70px;
	}
	.st-hqz-bot-cont {
		transform-style: preserve-3d;
		transform-origin: 50% 50%;
		animation: st_huaquanzhuan_zhuan 12s linear infinite both;
		/* margin-top: 600px; */
		margin-top: 680px;
	}
	@keyframes st_huaquanzhuan_zhuan {
		0% {
		    transform: rotateY(90deg);
		    /* transform: rotateX(-10deg) rotateY(90deg); */
		}
		100% {
		    transform: rotateY(-270deg);
		    /* transform: rotateX(-10deg) rotateY(-270deg); */
		}
	}
	.st-hqz-bot-item-1 {
	    -webkit-transform: rotateY(0deg) translateZ(250px);
	}
	.st-hqz-bot-item-2 {
	    -webkit-transform: rotateY(36deg) translateZ(250px);
	}
	.st-hqz-bot-item-3 {
	    -webkit-transform: rotateY(72deg) translateZ(250px);
	}
	.st-hqz-bot-item-4 {
	    -webkit-transform: rotateY(108deg) translateZ(250px);
	}
	.st-hqz-bot-item-5 {
		-webkit-transform: rotateY(144deg) translateZ(250px);
	}
	.st-hqz-bot-item-6 {
		-webkit-transform: rotateY(180deg) translateZ(250px);
	}
	.st-hqz-bot-item-7 {
	    -webkit-transform: rotateY(216deg) translateZ(250px);
	}
	.st-hqz-bot-item-8 {
		-webkit-transform: rotateY(252deg) translateZ(250px);
	}
	.st-hqz-bot-item-9 {
		-webkit-transform: rotateY(288deg) translateZ(250px);
	}
	.st-st-hqz-bot-item-10 {
		-webkit-transform: rotateY(324deg) translateZ(250px);
	}
	.st-hqz-bot-item {
		position: absolute;
		width: 110px;
		height: 110px;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	.hqz-bot-photo-1 {
		position: absolute;
		width: 100%;
    	height: 100%;
		-webkit-mask-image: url('http://resali.lititutu.cn/icons/mask/yuan.png');
		-webkit-mask-size: 100% 100%;
		background-size: cover;
		background-position: center;
		animation: 0.8s linear 0s 1 normal both running fadeOut;
	}
	.hqz-bot-photo-2 {
		position: absolute;
		width: 100%;
    	height: 100%;
		-webkit-mask-image: url('http://resali.lititutu.cn/icons/mask/yuan.png');
		-webkit-mask-size: 100% 100%;
		background-size: cover;
		background-position: center;
		animation: 0.8s linear 0s 1 normal both running fadeIn;
	}
	.st-hqz-rim-container {
	    position: absolute;
		width: 100%;
    	height: 100%;
		-webkit-perspective: 1600px;
		-webkit-perspective-origin: 50% 50%;
	}
	.st-hqz-rim-box {
		position: absolute;
		/* width: 300px;
		height: 300px; */
		width: 416px;
		height: 569px;
		left: 50%;
		margin-left: -208px;
		animation: st_huaquanzhuan_zhuan 6s linear infinite both;
		/* margin-left: -200px; */
	}
	.st-hqz-rim-photo {
		position: absolute;
		/* width: 100%;
		height: 100%; */
		width: calc(100% - 100px);
		height: calc(100% - 100px);
		top: 50px;
		left: 50px;
		background-size: cover;
		background-position: center;
	}
	.st-hqz-rim-photo-1 {
		-webkit-transform: translateZ(12px);
	}
	.st-hqz-rim-photo-2 {
		-webkit-transform: translateZ(-12px);
	}
	.hqz-rim {
		position: absolute;
		width: 100%;
		height: 100%;
		border-style: solid;
		/* border-width: 50px; */
		border-color: transparent;
		box-sizing: border-box;
	}
	.hqz-rim-item {
		border-image-repeat: round;
		/* border-image-slice: 45; */
	}
	.hqz-rim-kuang {
		border-image-repeat: round;
		/* border-image-slice: 45; */
	}
	.hqz-rim-kuang-1 {
		-webkit-transform: translateZ(16px);
	}
	.hqz-rim-kuang-2 {
	    -webkit-transform: translateZ(-16px);
	}
	.hqz-rim-2 {
		-webkit-transform: translateZ(8px);
	}
	.hqz-rim-3 {
		-webkit-transform: translateZ(4px);
	}
	.hqz-rim-4 {
		-webkit-transform: translateZ(0px);
	}
	.hqz-rim-5 {
		-webkit-transform: translateZ(-4px);
	}
	.hqz-rim-6 {
		-webkit-transform: translateZ(-8px);
	}
	.st-hqz-bot-item-border {
		position: absolute;
		width: 110%;
		height: 110%;
		top: -5%;
		left: -5%;
	}
</style>





