<template>
	<div class="model-page">
		<!-- <div class="baozha-box" :style="{backgroundImage: 'url('+ mode[0] +')'}"></div> -->
		<div class="wrap" :class="{hover: wrapClassShow}">
	        <div class="cube">
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg front"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg back"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg top"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg bottom"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg left"></div>
	            <div @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg right"></div>
	            <i @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg baozha_i i_front"></i>
	            <i @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg baozha_i i_back"></i>
	            <i @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg baozha_i i_top"></i>
	            <i @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg baozha_i i_bottom"></i>
	            <i @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg baozha_i i_left"></i>
	            <i @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}" class="baozha_bg baozha_i i_right"></i>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'baozha',
		props: ['mode', 'currentImage'],
		data () {
			return {
				wrapClassShow: false
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
            start () {
                setTimeout(() => {
                    this.wrapClassShow = true;
                }, 1500);
            },
            end () {
                setTimeout(() => {
                    this.wrapClassShow = false;
                }, 1500);
            }
		}
	}
</script>

<style scoped lang="less">
    .model-page, .baozha-box {
		position: absolute;
		width: 100%;
		height: 100%;
    }
	.baozha-box {
		background-repeat: no-repeat;
		background-position: center 20%;
		background-size: cover;
	}
	.baozha_bg {
		background-position: center 20%;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.wrap {
		position: absolute;
		perspective:@bw * 1.389;
		perspective-origin: 50% 50%;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
    }
    .cube {
		position: absolute;
		margin: auto;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height:@bw * 0.417;
		width:@bw * 0.417;
		transform-style: preserve-3d;
    }
    .cube div {
        position: absolute;
        padding:@bw * 0.014;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        opacity: 0.95;
        background-color: #000;
        border: solid@bw * 0.001 #eee;
        color: #fff;
        font:@bw * 0.014 arial;
        transition: transform 0.2s ease-in;
    }
    .front {
        transform: translateZ(@bw * 0.208);
    }
    .back {
        transform: translateZ(-@bw * 0.208) rotateY(180deg);
    }
    .right {
        transform: rotateY(-270deg) translateX(@bw * 0.208);
        transform-origin: top right;
    }
    .left {
        transform: rotateY(270deg) translateX(-@bw * 0.208);
        transform-origin: center left;
    }
    .top {
        transform: rotateX(-270deg) translateY(-@bw * 0.208);
        transform-origin: top center;
    }
    .bottom {
        transform: rotateX(270deg) translateY(@bw * 0.208);
        transform-origin: bottom center;
    }
    /* inner cube */
    .baozha_i {
      position:absolute;
      top:@bw * 0.104;
      left:@bw * 0.104;
      width:@bw * 0.208;
      height:@bw * 0.208;
      display:block;
      transition: all 1s linear;
    }
    .baozha_i.i_front{
      transform: translateZ(@bw * 0.139);
    }
    .baozha_i.i_back{
      transform: translateZ(-@bw * 0.069) rotateY(180deg);
    }
    .baozha_i.i_bottom{
      transform:rotateX(-90deg) translateY(@bw * 0.069);
      transform-origin: left bottom;
    }
    .baozha_i.i_left{
      transform:rotateY(270deg) translateX(-@bw * 0.069);
      transform-origin: center left;
    }
    .baozha_i.i_right{
      transform:rotateY(-270deg) translateX(@bw * 0.069);
      transform-origin: top right;
    }
    .baozha_i.i_top{
      transform:rotateX(-270deg) translateY(-@bw * 0.069);
      transform-origin: left top;
    }
    @keyframes rotate {
        from {
            transform: rotateX(0deg) rotateY(0deg);
        }

        to {
            transform: rotateX(360deg) rotateY(360deg);
        }
    }
    .cube {
        animation: rotate 20s infinite linear;
    }

    .wrap.hover .front {
        transform: translateZ(@bw * 0.417);
    }

    .wrap.hover .back {
        transform: translateZ(-@bw * 0.417) rotateY(180deg);
    }

    .wrap.hover .right {
        transform: rotateY(-270deg) translateZ(@bw * 0.208) translateX(@bw * 0.208);
    }

    .wrap.hover .left {
        transform: rotateY(270deg) translateZ(@bw * 0.208) translateX(-@bw * 0.208);
    }

    .wrap.hover .top {
        transform: rotateX(-270deg) translateZ(@bw * 0.208) translateY(-@bw * 0.208);
    }

    .wrap.hover .bottom {
        transform: rotateX(270deg) translateZ(@bw * 0.208) translateY(@bw * 0.208);
    }
</style>