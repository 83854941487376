<template>
	<div class="BX_Composition">
		<div class="BX_Class16 BX_BG16 BX_Ani16" id="BX_Layer16"></div>
		<div class="BX_Class15 BX_BG15 BX_Ani15" id="BX_Layer15"></div>
		<div class="BX_Class14 BX_BG14 BX_Ani14" id="BX_Layer14"></div>
		<div class="BX_Class13 BX_BG13 BX_Ani13" id="BX_Layer13"></div>
		<div class="BX_Class9 BX_Ani9">
		  	<div class="BX_Class12 BX_BG12 BX_Ani12" id="BX_Layer12" :style="{backgroundImage: 'url('+currentImage[0]['im']+')'}"></div>
		</div>
		<div class="BX_Class9 BX_BG9 BX_Ani9" id="BX_Layer9"></div>
		<div class="BX_Class8 BX_BG8 BX_Ani8" id="BX_Layer8"></div>
		<div class="BX_Class7 BX_BG7 BX_Ani7" id="BX_Layer7"></div>
		<div class="BX_Class6 BX_BG6 BX_Ani6" id="BX_Layer6"></div>
		<div class="BX_Class5 BX_BG5 BX_Ani5" id="BX_Layer5"></div>
		<div class="BX_Class4 BX_BG4 BX_Ani4" id="BX_Layer4"></div>
		<div class="BX_Class3 BX_BG3 BX_Ani3" id="BX_Layer3"></div>
		<div class="BX_Class2 BX_BG2 BX_Ani2" id="BX_Layer2"></div>
	</div>
</template>

<script>
	export default {
		name: 'zhongqiubianlian',
		props: ['currentImage'],
		data () {
			return {}
		}
	}
</script>

<style scoped>
	.BX_Composition{
	    background: transparent;
	    width: 720px;
	    height: 1280px;
	    display: block;
	    overflow: hidden;
	    position: relative;
	    transform: translate3d(0,0,0) scale(1,1);
	    transform-origin:50% 0%;
	}
	.BX_Class16{
	    left: -400.00px;
	    top: 960.00px;
	    width: 640px;
	    height: 1008px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(2.61,2.98);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG16{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/a36d56d3d3737eb00cc913289cdac98f.jpg");
	}
	.BX_Ani16{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey16 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey16{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(2.61081545857988,2.98158378207159);opacity: 0;}
		59.31% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(2.61081545857988,2.98158378207159);opacity: 0.00;}
		60.92% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(2.61081545857988,2.98158378207159);opacity: 0.50;}
		61.15% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(2.61081545857988,2.98158378207159);opacity: 1.00;}
		67.59% {transform: translate3d(5.00px,-9.41px,0.00px) rotate(0.00deg) scale(2.5939972767617,2.96237720889606);opacity: 1.00;}
		67.82% {transform: translate3d(10.00px,-18.82px,0.00px) rotate(0.00deg) scale(2.57717909494352,2.94317063572053);opacity: 1.00;}
		68.05% {transform: translate3d(15.00px,-28.23px,0.00px) rotate(0.00deg) scale(2.56036091312533,2.92396406254499);opacity: 1.00;}
		68.28% {transform: translate3d(20.00px,-37.64px,0.00px) rotate(0.00deg) scale(2.54354273130715,2.90475748936946);opacity: 1.00;}
		68.51% {transform: translate3d(25.00px,-47.05px,0.00px) rotate(0.00deg) scale(2.52672454948897,2.88555091619393);opacity: 1.00;}
		68.74% {transform: translate3d(30.00px,-56.45px,0.00px) rotate(0.00deg) scale(2.50990636767079,2.8663443430184);opacity: 1.00;}
		68.97% {transform: translate3d(35.00px,-65.87px,0.00px) rotate(0.00deg) scale(2.49308818585261,2.84713776984287);opacity: 1.00;}
		69.20% {transform: translate3d(40.00px,-75.28px,0.00px) rotate(0.00deg) scale(2.47627000403442,2.82793119666734);opacity: 1.00;}
		69.43% {transform: translate3d(45.00px,-84.68px,0.00px) rotate(0.00deg) scale(2.45945182221624,2.80872462349181);opacity: 1.00;}
		69.66% {transform: translate3d(50.00px,-94.09px,0.00px) rotate(0.00deg) scale(2.44263364039806,2.78951805031628);opacity: 1.00;}
		69.89% {transform: translate3d(55.00px,-103.50px,0.00px) rotate(0.00deg) scale(2.42581545857988,2.77031147714075);opacity: 1.00;}
		70.11% {transform: translate3d(60.00px,-112.91px,0.00px) rotate(0.00deg) scale(2.4089972767617,2.75110490396521);opacity: 1.00;}
		70.34% {transform: translate3d(65.00px,-122.32px,0.00px) rotate(0.00deg) scale(2.39217909494352,2.73189833078968);opacity: 1.00;}
		70.57% {transform: translate3d(70.00px,-131.73px,0.00px) rotate(0.00deg) scale(2.37536091312533,2.71269175761415);opacity: 1.00;}
		70.80% {transform: translate3d(75.00px,-141.14px,0.00px) rotate(0.00deg) scale(2.35854273130715,2.69348518443862);opacity: 1.00;}
		71.03% {transform: translate3d(80.00px,-150.55px,0.00px) rotate(0.00deg) scale(2.34172454948897,2.67427861126309);opacity: 1.00;}
		71.26% {transform: translate3d(85.00px,-159.95px,0.00px) rotate(0.00deg) scale(2.32490636767079,2.65507203808756);opacity: 1.00;}
		71.49% {transform: translate3d(90.00px,-169.37px,0.00px) rotate(0.00deg) scale(2.30808818585261,2.63586546491203);opacity: 1.00;}
		71.72% {transform: translate3d(95.00px,-178.78px,0.00px) rotate(0.00deg) scale(2.29127000403442,2.6166588917365);opacity: 1.00;}
		71.95% {transform: translate3d(100.00px,-188.18px,0.00px) rotate(0.00deg) scale(2.27445182221624,2.59745231856097);opacity: 1.00;}
		72.18% {transform: translate3d(105.00px,-197.59px,0.00px) rotate(0.00deg) scale(2.25763364039806,2.57824574538543);opacity: 1.00;}
		72.41% {transform: translate3d(110.00px,-207.00px,0.00px) rotate(0.00deg) scale(2.24081545857988,2.5590391722099);opacity: 1.00;}
		72.64% {transform: translate3d(115.00px,-216.41px,0.00px) rotate(0.00deg) scale(2.2239972767617,2.53983259903437);opacity: 1.00;}
		72.87% {transform: translate3d(120.00px,-225.82px,0.00px) rotate(0.00deg) scale(2.20717909494352,2.52062602585884);opacity: 1.00;}
		73.10% {transform: translate3d(125.00px,-235.23px,0.00px) rotate(0.00deg) scale(2.19036091312533,2.50141945268331);opacity: 1.00;}
		73.33% {transform: translate3d(130.00px,-244.64px,0.00px) rotate(0.00deg) scale(2.17354273130715,2.48221287950778);opacity: 1.00;}
		73.56% {transform: translate3d(135.00px,-254.05px,0.00px) rotate(0.00deg) scale(2.15672454948897,2.46300630633225);opacity: 1.00;}
		73.79% {transform: translate3d(140.01px,-263.46px,0.00px) rotate(0.00deg) scale(2.13990636767079,2.44379973315672);opacity: 1.00;}
		74.02% {transform: translate3d(145.01px,-272.87px,0.00px) rotate(0.00deg) scale(2.12308818585261,2.42459315998119);opacity: 1.00;}
		74.25% {transform: translate3d(150.01px,-282.28px,0.00px) rotate(0.00deg) scale(2.10627000403442,2.40538658680566);opacity: 1.00;}
		74.48% {transform: translate3d(155.00px,-291.69px,0.00px) rotate(0.00deg) scale(2.08945182221624,2.38618001363012);opacity: 1.00;}
		74.71% {transform: translate3d(160.00px,-301.10px,0.00px) rotate(0.00deg) scale(2.07263364039806,2.36697344045459);opacity: 1.00;}
		74.94% {transform: translate3d(165.00px,-310.50px,0.00px) rotate(0.00deg) scale(2.05581545857988,2.34776686727906);opacity: 1.00;}
		75.17% {transform: translate3d(170.00px,-319.91px,0.00px) rotate(0.00deg) scale(2.0389972767617,2.32856029410353);opacity: 1.00;}
		75.40% {transform: translate3d(175.00px,-329.32px,0.00px) rotate(0.00deg) scale(2.02217909494352,2.309353720928);opacity: 1.00;}
		75.63% {transform: translate3d(180.00px,-338.73px,0.00px) rotate(0.00deg) scale(2.00536091312533,2.29014714775247);opacity: 1.00;}
		75.86% {transform: translate3d(185.00px,-348.14px,0.00px) rotate(0.00deg) scale(1.98854273130715,2.27094057457694);opacity: 1.00;}
		76.09% {transform: translate3d(190.00px,-357.55px,0.00px) rotate(0.00deg) scale(1.97172454948897,2.25173400140141);opacity: 1.00;}
		76.32% {transform: translate3d(195.00px,-366.96px,0.00px) rotate(0.00deg) scale(1.95490636767079,2.23252742822588);opacity: 1.00;}
		76.55% {transform: translate3d(200.00px,-376.36px,0.00px) rotate(0.00deg) scale(1.93808818585261,2.21332085505034);opacity: 1.00;}
		76.78% {transform: translate3d(205.00px,-385.77px,0.00px) rotate(0.00deg) scale(1.92127000403442,2.19411428187481);opacity: 1.00;}
		77.01% {transform: translate3d(210.00px,-395.18px,0.00px) rotate(0.00deg) scale(1.90445182221624,2.17490770869928);opacity: 1.00;}
		77.24% {transform: translate3d(215.00px,-404.59px,0.00px) rotate(0.00deg) scale(1.88763364039806,2.15570113552375);opacity: 1.00;}
		77.47% {transform: translate3d(220.00px,-414.00px,0.00px) rotate(0.00deg) scale(1.87081545857988,2.13649456234822);opacity: 1.00;}
		77.70% {transform: translate3d(225.00px,-423.41px,0.00px) rotate(0.00deg) scale(1.8539972767617,2.11728798917269);opacity: 1.00;}
		77.93% {transform: translate3d(230.00px,-432.82px,0.00px) rotate(0.00deg) scale(1.83717909494352,2.09808141599716);opacity: 1.00;}
		78.16% {transform: translate3d(235.00px,-442.23px,0.00px) rotate(0.00deg) scale(1.82036091312533,2.07887484282163);opacity: 1.00;}
		78.39% {transform: translate3d(240.00px,-451.63px,0.00px) rotate(0.00deg) scale(1.80354273130715,2.0596682696461);opacity: 1.00;}
		78.62% {transform: translate3d(245.00px,-461.04px,0.00px) rotate(0.00deg) scale(1.78672454948897,2.04046169647057);opacity: 1.00;}
		78.85% {transform: translate3d(250.00px,-470.45px,0.00px) rotate(0.00deg) scale(1.76990636767079,2.02125512329503);opacity: 1.00;}
		79.08% {transform: translate3d(255.00px,-479.86px,0.00px) rotate(0.00deg) scale(1.75308818585261,2.0020485501195);opacity: 1.00;}
		79.31% {transform: translate3d(260.00px,-489.27px,0.00px) rotate(0.00deg) scale(1.73627000403442,1.98284197694397);opacity: 1.00;}
		79.54% {transform: translate3d(265.00px,-498.68px,0.00px) rotate(0.00deg) scale(1.71945182221624,1.96363540376844);opacity: 1.00;}
		79.77% {transform: translate3d(270.00px,-508.09px,0.00px) rotate(0.00deg) scale(1.70263364039806,1.94442883059291);opacity: 1.00;}
		80.00% {transform: translate3d(275.00px,-517.50px,0.00px) rotate(0.00deg) scale(1.68581545857988,1.92522225741738);opacity: 1.00;}
		80.23% {transform: translate3d(280.00px,-526.91px,0.00px) rotate(0.00deg) scale(1.6689972767617,1.90601568424185);opacity: 1.00;}
		80.46% {transform: translate3d(285.00px,-536.32px,0.00px) rotate(0.00deg) scale(1.65217909494352,1.88680911106632);opacity: 1.00;}
		80.69% {transform: translate3d(290.00px,-545.73px,0.00px) rotate(0.00deg) scale(1.63536091312533,1.86760253789079);opacity: 1.00;}
		80.92% {transform: translate3d(295.00px,-555.14px,0.00px) rotate(0.00deg) scale(1.61854273130715,1.84839596471525);opacity: 1.00;}
		81.15% {transform: translate3d(300.00px,-564.55px,0.00px) rotate(0.00deg) scale(1.60172454948897,1.82918939153972);opacity: 1.00;}
		81.38% {transform: translate3d(305.00px,-573.95px,0.00px) rotate(0.00deg) scale(1.58490636767079,1.80998281836419);opacity: 1.00;}
		81.61% {transform: translate3d(310.00px,-583.36px,0.00px) rotate(0.00deg) scale(1.56808818585261,1.79077624518866);opacity: 1.00;}
		81.84% {transform: translate3d(315.00px,-592.77px,0.00px) rotate(0.00deg) scale(1.55127000403442,1.77156967201313);opacity: 1.00;}
		82.07% {transform: translate3d(320.00px,-602.18px,0.00px) rotate(0.00deg) scale(1.53445182221624,1.7523630988376);opacity: 1.00;}
		82.30% {transform: translate3d(325.00px,-611.59px,0.00px) rotate(0.00deg) scale(1.51763364039806,1.73315652566207);opacity: 1.00;}
		82.53% {transform: translate3d(330.00px,-621.00px,0.00px) rotate(0.00deg) scale(1.50081545857988,1.71394995248654);opacity: 1.00;}
		82.76% {transform: translate3d(335.00px,-630.41px,0.00px) rotate(0.00deg) scale(1.4839972767617,1.69474337931101);opacity: 1.00;}
		82.99% {transform: translate3d(340.00px,-639.82px,0.00px) rotate(0.00deg) scale(1.46717909494352,1.67553680613547);opacity: 1.00;}
		83.22% {transform: translate3d(345.00px,-649.22px,0.00px) rotate(0.00deg) scale(1.45036091312533,1.65633023295994);opacity: 1.00;}
		83.45% {transform: translate3d(350.00px,-658.63px,0.00px) rotate(0.00deg) scale(1.43354273130715,1.63712365978441);opacity: 1.00;}
		83.68% {transform: translate3d(355.00px,-668.04px,0.00px) rotate(0.00deg) scale(1.41672454948897,1.61791708660888);opacity: 1.00;}
		83.91% {transform: translate3d(360.00px,-677.45px,0.00px) rotate(0.00deg) scale(1.39990636767079,1.59871051343335);opacity: 1.00;}
		84.14% {transform: translate3d(365.00px,-686.86px,0.00px) rotate(0.00deg) scale(1.38308818585261,1.57950394025782);opacity: 1.00;}
		84.37% {transform: translate3d(370.00px,-696.27px,0.00px) rotate(0.00deg) scale(1.36627000403442,1.56029736708229);opacity: 1.00;}
		84.60% {transform: translate3d(375.00px,-705.68px,0.00px) rotate(0.00deg) scale(1.34945182221624,1.54109079390676);opacity: 1.00;}
		84.83% {transform: translate3d(380.00px,-715.09px,0.00px) rotate(0.00deg) scale(1.33263364039806,1.52188422073123);opacity: 1.00;}
		85.06% {transform: translate3d(385.00px,-724.50px,0.00px) rotate(0.00deg) scale(1.31581545857988,1.5026776475557);opacity: 1.00;}
		85.29% {transform: translate3d(390.00px,-733.91px,0.00px) rotate(0.00deg) scale(1.2989972767617,1.48347107438016);opacity: 1.00;}
		85.52% {transform: translate3d(395.00px,-743.32px,0.00px) rotate(0.00deg) scale(1.28217909494352,1.46426450120463);opacity: 1.00;}
		85.75% {transform: translate3d(400.00px,-752.72px,0.00px) rotate(0.00deg) scale(1.26536091312533,1.4450579280291);opacity: 1.00;}
		85.98% {transform: translate3d(405.00px,-762.13px,0.00px) rotate(0.00deg) scale(1.24854273130715,1.42585135485357);opacity: 1.00;}
		86.21% {transform: translate3d(410.00px,-771.54px,0.00px) rotate(0.00deg) scale(1.23172454948897,1.40664478167804);opacity: 1.00;}
		86.44% {transform: translate3d(415.00px,-780.95px,0.00px) rotate(0.00deg) scale(1.21490636767079,1.38743820850251);opacity: 1.00;}
		86.67% {transform: translate3d(420.00px,-790.36px,0.00px) rotate(0.00deg) scale(1.19808818585261,1.36823163532698);opacity: 1.00;}
		86.90% {transform: translate3d(425.00px,-799.77px,0.00px) rotate(0.00deg) scale(1.18127000403442,1.34902506215145);opacity: 1.00;}
		87.13% {transform: translate3d(430.00px,-809.18px,0.00px) rotate(0.00deg) scale(1.16445182221624,1.32981848897592);opacity: 1.00;}
		87.36% {transform: translate3d(435.00px,-818.59px,0.00px) rotate(0.00deg) scale(1.14763364039806,1.31061191580038);opacity: 1.00;}
		87.59% {transform: translate3d(440.00px,-828.00px,0.00px) rotate(0.00deg) scale(1.13081545857988,1.29140534262485);opacity: 1.00;}
		99.54% {transform: translate3d(440.00px,-828.00px,0.00px) rotate(0.00deg) scale(1.13081545857988,1.29140534262485);opacity: 1.00;}
		99.77% {transform: translate3d(440.00px,-828.00px,0.00px) rotate(0.00deg) scale(1.13081545857988,1.29140534262485);opacity: 0.75;}
		}
	.BX_Class15{
	    left: 650.00px;
	    top: 390.00px;
	    width: 900px;
	    height: 500px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(2.56,2.56);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG15{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/d1285184624e2711cc3ab0e4b729721c.jpg");
	}
	.BX_Ani15{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey15 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey15{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(2.56,2.56);opacity: 0;}
		29.59% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(2.56,2.56);opacity: 1.00;}
		29.89% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(2.56,2.56);opacity: 1.00;}
		32.41% {transform: translate3d(5.97px,4.68px,0.00px) rotate(0.00deg) scale(2.57484848484849,2.57484848484849);opacity: 1.00;}
		32.64% {transform: translate3d(13.13px,7.81px,0.00px) rotate(0.00deg) scale(2.58969696969697,2.58969696969697);opacity: 1.00;}
		32.87% {transform: translate3d(20.42px,10.62px,0.00px) rotate(0.00deg) scale(2.60454545454546,2.60454545454545);opacity: 1.00;}
		33.10% {transform: translate3d(27.76px,13.29px,0.00px) rotate(0.00deg) scale(2.61939393939394,2.61939393939394);opacity: 1.00;}
		33.33% {transform: translate3d(35.13px,15.90px,0.00px) rotate(0.00deg) scale(2.63424242424243,2.63424242424242);opacity: 1.00;}
		33.56% {transform: translate3d(42.50px,18.48px,0.00px) rotate(0.00deg) scale(2.64909090909091,2.64909090909091);opacity: 1.00;}
		33.79% {transform: translate3d(49.86px,21.07px,0.00px) rotate(0.00deg) scale(2.6639393939394,2.66393939393939);opacity: 1.00;}
		34.02% {transform: translate3d(57.22px,23.68px,0.00px) rotate(0.00deg) scale(2.67878787878788,2.67878787878788);opacity: 1.00;}
		34.25% {transform: translate3d(64.56px,26.35px,0.00px) rotate(0.00deg) scale(2.69363636363636,2.69363636363636);opacity: 1.00;}
		34.48% {transform: translate3d(71.88px,29.09px,0.00px) rotate(0.00deg) scale(2.70848484848485,2.70848484848485);opacity: 1.00;}
		34.71% {transform: translate3d(79.15px,31.94px,0.00px) rotate(0.00deg) scale(2.72333333333333,2.72333333333333);opacity: 1.00;}
		34.94% {transform: translate3d(86.37px,34.92px,0.00px) rotate(0.00deg) scale(2.73818181818182,2.73818181818182);opacity: 1.00;}
		35.17% {transform: translate3d(93.50px,38.10px,0.00px) rotate(0.00deg) scale(2.7530303030303,2.7530303030303);opacity: 1.00;}
		35.40% {transform: translate3d(100.51px,41.56px,0.00px) rotate(0.00deg) scale(2.76787878787879,2.76787878787879);opacity: 1.00;}
		35.63% {transform: translate3d(107.28px,45.46px,0.00px) rotate(0.00deg) scale(2.78272727272727,2.78272727272727);opacity: 1.00;}
		35.86% {transform: translate3d(113.52px,50.15px,0.00px) rotate(0.00deg) scale(2.79757575757576,2.79757575757576);opacity: 1.00;}
		36.09% {transform: translate3d(117.74px,56.57px,0.00px) rotate(0.00deg) scale(2.81242424242424,2.81242424242424);opacity: 1.00;}
		36.32% {transform: translate3d(114.73px,63.36px,0.00px) rotate(0.00deg) scale(2.82727272727273,2.82727272727273);opacity: 1.00;}
		36.55% {transform: translate3d(108.00px,67.27px,0.00px) rotate(0.00deg) scale(2.84212121212121,2.84212121212121);opacity: 1.00;}
		36.78% {transform: translate3d(100.64px,69.90px,0.00px) rotate(0.00deg) scale(2.8569696969697,2.8569696969697);opacity: 1.00;}
		37.01% {transform: translate3d(93.09px,71.89px,0.00px) rotate(0.00deg) scale(2.87181818181818,2.87181818181818);opacity: 1.00;}
		37.24% {transform: translate3d(85.44px,73.51px,0.00px) rotate(0.00deg) scale(2.88666666666667,2.88666666666667);opacity: 1.00;}
		37.47% {transform: translate3d(77.75px,74.86px,0.00px) rotate(0.00deg) scale(2.90151515151515,2.90151515151515);opacity: 1.00;}
		37.70% {transform: translate3d(70.03px,76.01px,0.00px) rotate(0.00deg) scale(2.91636363636364,2.91636363636364);opacity: 1.00;}
		37.93% {transform: translate3d(62.28px,77.02px,0.00px) rotate(0.00deg) scale(2.93121212121212,2.93121212121212);opacity: 1.00;}
		38.16% {transform: translate3d(54.52px,77.91px,0.00px) rotate(0.00deg) scale(2.94606060606061,2.94606060606061);opacity: 1.00;}
		38.39% {transform: translate3d(46.75px,78.69px,0.00px) rotate(0.00deg) scale(2.96090909090909,2.96090909090909);opacity: 1.00;}
		38.62% {transform: translate3d(38.97px,79.40px,0.00px) rotate(0.00deg) scale(2.97575757575758,2.97575757575758);opacity: 1.00;}
		38.85% {transform: translate3d(31.18px,80.03px,0.00px) rotate(0.00deg) scale(2.99060606060606,2.99060606060606);opacity: 1.00;}
		39.08% {transform: translate3d(23.39px,80.60px,0.00px) rotate(0.00deg) scale(3.00545454545455,3.00545454545455);opacity: 1.00;}
		39.31% {transform: translate3d(15.60px,81.11px,0.00px) rotate(0.00deg) scale(3.02030303030303,3.02030303030303);opacity: 1.00;}
		39.54% {transform: translate3d(7.80px,81.58px,0.00px) rotate(0.00deg) scale(3.03515151515152,3.03515151515152);opacity: 1.00;}
		39.77% {transform: translate3d(0.00px,82.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		40.00% {transform: translate3d(-17.47px,82.81px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		40.23% {transform: translate3d(-34.94px,83.49px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		40.46% {transform: translate3d(-52.42px,84.07px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		40.69% {transform: translate3d(-69.90px,84.56px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		40.92% {transform: translate3d(-87.37px,85.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		41.15% {transform: translate3d(-104.85px,85.38px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		41.38% {transform: translate3d(-122.33px,85.72px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		41.61% {transform: translate3d(-139.82px,86.03px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		41.84% {transform: translate3d(-157.30px,86.30px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		42.07% {transform: translate3d(-174.78px,86.54px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		42.30% {transform: translate3d(-192.26px,86.75px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		42.53% {transform: translate3d(-209.75px,86.95px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		42.76% {transform: translate3d(-227.23px,87.12px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		42.99% {transform: translate3d(-244.72px,87.27px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		43.22% {transform: translate3d(-262.20px,87.41px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		43.45% {transform: translate3d(-279.68px,87.53px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		43.68% {transform: translate3d(-297.16px,87.63px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		43.91% {transform: translate3d(-314.65px,87.73px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		44.14% {transform: translate3d(-332.14px,87.80px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		44.37% {transform: translate3d(-349.62px,87.87px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		44.60% {transform: translate3d(-367.11px,87.93px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		44.83% {transform: translate3d(-384.59px,87.98px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		45.06% {transform: translate3d(-402.07px,88.01px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		45.29% {transform: translate3d(-419.55px,88.04px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		45.52% {transform: translate3d(-437.04px,88.06px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		45.75% {transform: translate3d(-454.53px,88.07px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		45.98% {transform: translate3d(-472.01px,88.07px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		46.21% {transform: translate3d(-489.49px,88.07px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		46.44% {transform: translate3d(-506.98px,88.06px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		46.67% {transform: translate3d(-524.46px,88.04px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		46.90% {transform: translate3d(-541.95px,88.02px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		47.13% {transform: translate3d(-559.43px,87.99px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		47.36% {transform: translate3d(-576.92px,87.95px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		47.59% {transform: translate3d(-594.40px,87.91px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		47.82% {transform: translate3d(-611.88px,87.87px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		48.05% {transform: translate3d(-629.37px,87.82px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		48.28% {transform: translate3d(-646.85px,87.76px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		48.51% {transform: translate3d(-664.34px,87.70px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		48.74% {transform: translate3d(-681.82px,87.64px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		48.97% {transform: translate3d(-699.31px,87.57px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		49.20% {transform: translate3d(-716.79px,87.50px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		49.43% {transform: translate3d(-734.28px,87.42px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		49.66% {transform: translate3d(-751.76px,87.34px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		49.89% {transform: translate3d(-769.24px,87.26px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		50.11% {transform: translate3d(-786.73px,87.17px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		50.34% {transform: translate3d(-804.21px,87.08px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		50.57% {transform: translate3d(-821.69px,86.99px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		50.80% {transform: translate3d(-839.18px,86.89px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		51.03% {transform: translate3d(-856.66px,86.79px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		51.26% {transform: translate3d(-874.15px,86.69px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		51.49% {transform: translate3d(-891.63px,86.59px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		51.72% {transform: translate3d(-909.12px,86.48px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		51.95% {transform: translate3d(-926.60px,86.37px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		52.18% {transform: translate3d(-944.09px,86.26px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		52.41% {transform: translate3d(-961.57px,86.14px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		52.64% {transform: translate3d(-979.06px,86.03px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		52.87% {transform: translate3d(-996.54px,85.91px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		53.10% {transform: translate3d(-1014.02px,85.79px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		53.33% {transform: translate3d(-1031.51px,85.67px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		53.56% {transform: translate3d(-1048.99px,85.55px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		53.79% {transform: translate3d(-1066.47px,85.42px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		54.02% {transform: translate3d(-1083.96px,85.30px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		54.25% {transform: translate3d(-1101.44px,85.17px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		54.48% {transform: translate3d(-1118.93px,85.04px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		54.71% {transform: translate3d(-1136.41px,84.91px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		54.94% {transform: translate3d(-1153.89px,84.78px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		55.17% {transform: translate3d(-1171.38px,84.65px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		55.40% {transform: translate3d(-1188.86px,84.52px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		55.63% {transform: translate3d(-1206.35px,84.39px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		55.86% {transform: translate3d(-1223.83px,84.26px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		56.09% {transform: translate3d(-1241.31px,84.12px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		56.32% {transform: translate3d(-1258.79px,83.99px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		56.55% {transform: translate3d(-1276.28px,83.86px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		56.78% {transform: translate3d(-1293.77px,83.73px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		57.01% {transform: translate3d(-1311.25px,83.59px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		57.24% {transform: translate3d(-1328.74px,83.46px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		57.47% {transform: translate3d(-1346.22px,83.33px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		57.70% {transform: translate3d(-1363.70px,83.21px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		57.93% {transform: translate3d(-1381.18px,83.08px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		58.16% {transform: translate3d(-1398.67px,82.95px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		58.39% {transform: translate3d(-1416.16px,82.83px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		58.62% {transform: translate3d(-1433.64px,82.71px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		58.85% {transform: translate3d(-1451.12px,82.60px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		59.08% {transform: translate3d(-1468.60px,82.49px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		59.31% {transform: translate3d(-1486.09px,82.39px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		59.54% {transform: translate3d(-1503.58px,82.29px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		59.77% {transform: translate3d(-1521.06px,82.20px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		60.00% {transform: translate3d(-1538.54px,82.12px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		60.23% {transform: translate3d(-1556.03px,82.06px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		60.46% {transform: translate3d(-1573.52px,82.02px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		60.69% {transform: translate3d(-1591.00px,82.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 1.00;}
		60.92% {transform: translate3d(-1591.00px,82.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 0.50;}
		61.15% {transform: translate3d(-1591.00px,82.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 0.00;}
		62.53% {transform: translate3d(-1591.00px,82.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 0;}
		99.54% {transform: translate3d(-1591.00px,82.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 0;}
		99.77% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(3.05,3.05);opacity: 0;}
		}
	.BX_Class14{
	    left: 234.50px;
	    top: 362.00px;
	    width: 853px;
	    height: 1280px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.60,1.60);
	    opacity: 0.50;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG14{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/4d81391da8ccc2176252b8978a7e9bbe.jpg");
	}
	.BX_Ani14{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey14 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey14{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.6,1.6);opacity: 0.50;}
		0.23% {transform: translate3d(-6.29px,-7.01px,0.00px) rotate(0.00deg) scale(1.59472727272727,1.59472727272727);opacity: 0.75;}
		0.46% {transform: translate3d(-12.56px,-14.02px,0.00px) rotate(0.00deg) scale(1.58945454545455,1.58945454545455);opacity: 1.00;}
		0.69% {transform: translate3d(-18.83px,-21.05px,0.00px) rotate(0.00deg) scale(1.58418181818182,1.58418181818182);opacity: 1.00;}
		0.92% {transform: translate3d(-25.10px,-28.07px,0.00px) rotate(0.00deg) scale(1.57890909090909,1.57890909090909);opacity: 1.00;}
		1.15% {transform: translate3d(-31.36px,-35.10px,0.00px) rotate(0.00deg) scale(1.57363636363636,1.57363636363636);opacity: 1.00;}
		1.38% {transform: translate3d(-37.62px,-42.14px,0.00px) rotate(0.00deg) scale(1.56836363636364,1.56836363636364);opacity: 1.00;}
		1.61% {transform: translate3d(-43.87px,-49.17px,0.00px) rotate(0.00deg) scale(1.56309090909091,1.56309090909091);opacity: 1.00;}
		1.84% {transform: translate3d(-50.13px,-56.21px,0.00px) rotate(0.00deg) scale(1.55781818181818,1.55781818181818);opacity: 1.00;}
		2.07% {transform: translate3d(-56.38px,-63.24px,0.00px) rotate(0.00deg) scale(1.55254545454546,1.55254545454545);opacity: 1.00;}
		2.30% {transform: translate3d(-62.64px,-70.28px,0.00px) rotate(0.00deg) scale(1.54727272727273,1.54727272727273);opacity: 1.00;}
		2.53% {transform: translate3d(-68.89px,-77.32px,0.00px) rotate(0.00deg) scale(1.542,1.542);opacity: 1.00;}
		2.76% {transform: translate3d(-75.14px,-84.36px,0.00px) rotate(0.00deg) scale(1.53672727272727,1.53672727272727);opacity: 1.00;}
		2.99% {transform: translate3d(-81.39px,-91.40px,0.00px) rotate(0.00deg) scale(1.53145454545455,1.53145454545455);opacity: 1.00;}
		3.22% {transform: translate3d(-87.64px,-98.44px,0.00px) rotate(0.00deg) scale(1.52618181818182,1.52618181818182);opacity: 1.00;}
		3.45% {transform: translate3d(-93.89px,-105.48px,0.00px) rotate(0.00deg) scale(1.52090909090909,1.52090909090909);opacity: 1.00;}
		3.68% {transform: translate3d(-100.14px,-112.52px,0.00px) rotate(0.00deg) scale(1.51563636363636,1.51563636363636);opacity: 1.00;}
		3.91% {transform: translate3d(-106.39px,-119.56px,0.00px) rotate(0.00deg) scale(1.51036363636364,1.51036363636364);opacity: 1.00;}
		4.14% {transform: translate3d(-112.64px,-126.59px,0.00px) rotate(0.00deg) scale(1.50509090909091,1.50509090909091);opacity: 1.00;}
		4.37% {transform: translate3d(-118.89px,-133.63px,0.00px) rotate(0.00deg) scale(1.49981818181818,1.49981818181818);opacity: 1.00;}
		4.60% {transform: translate3d(-125.15px,-140.68px,0.00px) rotate(0.00deg) scale(1.49454545454546,1.49454545454545);opacity: 1.00;}
		4.83% {transform: translate3d(-131.40px,-147.72px,0.00px) rotate(0.00deg) scale(1.48927272727273,1.48927272727273);opacity: 1.00;}
		5.06% {transform: translate3d(-137.65px,-154.76px,0.00px) rotate(0.00deg) scale(1.484,1.484);opacity: 1.00;}
		5.29% {transform: translate3d(-143.91px,-161.79px,0.00px) rotate(0.00deg) scale(1.47872727272727,1.47872727272727);opacity: 1.00;}
		5.52% {transform: translate3d(-150.16px,-168.83px,0.00px) rotate(0.00deg) scale(1.47345454545455,1.47345454545455);opacity: 1.00;}
		5.75% {transform: translate3d(-156.41px,-175.86px,0.00px) rotate(0.00deg) scale(1.46818181818182,1.46818181818182);opacity: 1.00;}
		5.98% {transform: translate3d(-162.66px,-182.90px,0.00px) rotate(0.00deg) scale(1.46290909090909,1.46290909090909);opacity: 1.00;}
		6.21% {transform: translate3d(-168.92px,-189.93px,0.00px) rotate(0.00deg) scale(1.45763636363636,1.45763636363636);opacity: 1.00;}
		6.44% {transform: translate3d(-175.17px,-196.96px,0.00px) rotate(0.00deg) scale(1.45236363636364,1.45236363636364);opacity: 1.00;}
		6.67% {transform: translate3d(-181.43px,-204.00px,0.00px) rotate(0.00deg) scale(1.44709090909091,1.44709090909091);opacity: 1.00;}
		6.90% {transform: translate3d(-187.69px,-211.03px,0.00px) rotate(0.00deg) scale(1.44181818181818,1.44181818181818);opacity: 1.00;}
		7.13% {transform: translate3d(-193.95px,-218.06px,0.00px) rotate(0.00deg) scale(1.43654545454546,1.43654545454545);opacity: 1.00;}
		7.36% {transform: translate3d(-200.21px,-225.09px,0.00px) rotate(0.00deg) scale(1.43127272727273,1.43127272727273);opacity: 1.00;}
		7.59% {transform: translate3d(-206.47px,-232.12px,0.00px) rotate(0.00deg) scale(1.426,1.426);opacity: 1.00;}
		7.82% {transform: translate3d(-212.73px,-239.15px,0.00px) rotate(0.00deg) scale(1.42072727272727,1.42072727272727);opacity: 1.00;}
		8.05% {transform: translate3d(-219.00px,-246.18px,0.00px) rotate(0.00deg) scale(1.41545454545455,1.41545454545455);opacity: 1.00;}
		8.28% {transform: translate3d(-225.26px,-253.20px,0.00px) rotate(0.00deg) scale(1.41018181818182,1.41018181818182);opacity: 1.00;}
		8.51% {transform: translate3d(-231.53px,-260.23px,0.00px) rotate(0.00deg) scale(1.40490909090909,1.40490909090909);opacity: 1.00;}
		8.74% {transform: translate3d(-237.80px,-267.25px,0.00px) rotate(0.00deg) scale(1.39963636363636,1.39963636363636);opacity: 1.00;}
		8.97% {transform: translate3d(-244.07px,-274.28px,0.00px) rotate(0.00deg) scale(1.39436363636364,1.39436363636364);opacity: 1.00;}
		9.20% {transform: translate3d(-250.34px,-281.30px,0.00px) rotate(0.00deg) scale(1.38909090909091,1.38909090909091);opacity: 1.00;}
		9.43% {transform: translate3d(-256.61px,-288.32px,0.00px) rotate(0.00deg) scale(1.38381818181818,1.38381818181818);opacity: 1.00;}
		9.66% {transform: translate3d(-262.89px,-295.33px,0.00px) rotate(0.00deg) scale(1.37854545454546,1.37854545454545);opacity: 1.00;}
		9.89% {transform: translate3d(-269.16px,-302.34px,0.00px) rotate(0.00deg) scale(1.37327272727273,1.37327272727273);opacity: 1.00;}
		10.11% {transform: translate3d(-275.44px,-309.36px,0.00px) rotate(0.00deg) scale(1.368,1.368);opacity: 1.00;}
		10.34% {transform: translate3d(-281.72px,-316.37px,0.00px) rotate(0.00deg) scale(1.36272727272727,1.36272727272727);opacity: 1.00;}
		10.57% {transform: translate3d(-288.01px,-323.37px,0.00px) rotate(0.00deg) scale(1.35745454545455,1.35745454545455);opacity: 1.00;}
		10.80% {transform: translate3d(-294.30px,-330.38px,0.00px) rotate(0.00deg) scale(1.35218181818182,1.35218181818182);opacity: 1.00;}
		11.03% {transform: translate3d(-300.59px,-337.39px,0.00px) rotate(0.00deg) scale(1.34690909090909,1.34690909090909);opacity: 1.00;}
		11.26% {transform: translate3d(-306.88px,-344.39px,0.00px) rotate(0.00deg) scale(1.34163636363636,1.34163636363636);opacity: 1.00;}
		11.49% {transform: translate3d(-313.18px,-351.39px,0.00px) rotate(0.00deg) scale(1.33636363636364,1.33636363636364);opacity: 1.00;}
		11.72% {transform: translate3d(-319.48px,-358.39px,0.00px) rotate(0.00deg) scale(1.33109090909091,1.33109090909091);opacity: 1.00;}
		11.95% {transform: translate3d(-325.79px,-365.38px,0.00px) rotate(0.00deg) scale(1.32581818181818,1.32581818181818);opacity: 1.00;}
		12.18% {transform: translate3d(-332.09px,-372.36px,0.00px) rotate(0.00deg) scale(1.32054545454545,1.32054545454545);opacity: 1.00;}
		12.41% {transform: translate3d(-338.41px,-379.34px,0.00px) rotate(0.00deg) scale(1.31527272727273,1.31527272727273);opacity: 1.00;}
		12.64% {transform: translate3d(-344.73px,-386.32px,0.00px) rotate(0.00deg) scale(1.31,1.31);opacity: 1.00;}
		12.87% {transform: translate3d(-351.05px,-393.30px,0.00px) rotate(0.00deg) scale(1.30472727272727,1.30472727272727);opacity: 1.00;}
		13.10% {transform: translate3d(-357.38px,-400.27px,0.00px) rotate(0.00deg) scale(1.29945454545455,1.29945454545455);opacity: 1.00;}
		13.33% {transform: translate3d(-363.72px,-407.22px,0.00px) rotate(0.00deg) scale(1.29418181818182,1.29418181818182);opacity: 1.00;}
		13.56% {transform: translate3d(-370.07px,-414.18px,0.00px) rotate(0.00deg) scale(1.28890909090909,1.28890909090909);opacity: 1.00;}
		13.79% {transform: translate3d(-376.42px,-421.13px,0.00px) rotate(0.00deg) scale(1.28363636363636,1.28363636363636);opacity: 1.00;}
		14.02% {transform: translate3d(-382.79px,-428.06px,0.00px) rotate(0.00deg) scale(1.27836363636364,1.27836363636364);opacity: 1.00;}
		14.25% {transform: translate3d(-389.17px,-434.98px,0.00px) rotate(0.00deg) scale(1.27309090909091,1.27309090909091);opacity: 1.00;}
		14.48% {transform: translate3d(-395.56px,-441.89px,0.00px) rotate(0.00deg) scale(1.26781818181818,1.26781818181818);opacity: 1.00;}
		14.71% {transform: translate3d(-401.98px,-448.79px,0.00px) rotate(0.00deg) scale(1.26254545454545,1.26254545454545);opacity: 1.00;}
		14.94% {transform: translate3d(-408.41px,-455.65px,0.00px) rotate(0.00deg) scale(1.25727272727273,1.25727272727273);opacity: 1.00;}
		15.17% {transform: translate3d(-414.88px,-462.49px,0.00px) rotate(0.00deg) scale(1.252,1.252);opacity: 1.00;}
		15.40% {transform: translate3d(-421.40px,-469.28px,0.00px) rotate(0.00deg) scale(1.24672727272727,1.24672727272727);opacity: 1.00;}
		15.63% {transform: translate3d(-428.00px,-476.00px,0.00px) rotate(0.00deg) scale(1.24145454545455,1.24145454545455);opacity: 1.00;}
		15.86% {transform: translate3d(-430.80px,-478.77px,0.00px) rotate(0.00deg) scale(1.23618181818182,1.23618181818182);opacity: 1.00;}
		16.09% {transform: translate3d(-433.63px,-481.50px,0.00px) rotate(0.00deg) scale(1.23090909090909,1.23090909090909);opacity: 1.00;}
		16.32% {transform: translate3d(-436.52px,-484.18px,0.00px) rotate(0.00deg) scale(1.22563636363636,1.22563636363636);opacity: 1.00;}
		16.55% {transform: translate3d(-439.47px,-486.79px,0.00px) rotate(0.00deg) scale(1.22036363636364,1.22036363636364);opacity: 1.00;}
		16.78% {transform: translate3d(-442.56px,-489.23px,0.00px) rotate(0.00deg) scale(1.21509090909091,1.21509090909091);opacity: 1.00;}
		17.01% {transform: translate3d(-446.03px,-491.03px,0.00px) rotate(0.00deg) scale(1.20981818181818,1.20981818181818);opacity: 1.00;}
		17.24% {transform: translate3d(-446.54px,-487.79px,0.00px) rotate(0.00deg) scale(1.20454545454546,1.20454545454545);opacity: 1.00;}
		17.47% {transform: translate3d(-445.01px,-484.16px,0.00px) rotate(0.00deg) scale(1.19927272727273,1.19927272727273);opacity: 1.00;}
		17.70% {transform: translate3d(-443.22px,-480.65px,0.00px) rotate(0.00deg) scale(1.194,1.194);opacity: 1.00;}
		17.93% {transform: translate3d(-441.31px,-477.20px,0.00px) rotate(0.00deg) scale(1.18872727272727,1.18872727272727);opacity: 1.00;}
		18.16% {transform: translate3d(-439.34px,-473.80px,0.00px) rotate(0.00deg) scale(1.18345454545455,1.18345454545455);opacity: 1.00;}
		18.39% {transform: translate3d(-437.31px,-470.42px,0.00px) rotate(0.00deg) scale(1.17818181818182,1.17818181818182);opacity: 1.00;}
		18.62% {transform: translate3d(-435.25px,-467.07px,0.00px) rotate(0.00deg) scale(1.17290909090909,1.17290909090909);opacity: 1.00;}
		18.85% {transform: translate3d(-433.16px,-463.73px,0.00px) rotate(0.00deg) scale(1.16763636363636,1.16763636363636);opacity: 1.00;}
		19.08% {transform: translate3d(-431.05px,-460.41px,0.00px) rotate(0.00deg) scale(1.16236363636364,1.16236363636364);opacity: 1.00;}
		19.31% {transform: translate3d(-428.92px,-457.09px,0.00px) rotate(0.00deg) scale(1.15709090909091,1.15709090909091);opacity: 1.00;}
		19.54% {transform: translate3d(-426.78px,-453.79px,0.00px) rotate(0.00deg) scale(1.15181818181818,1.15181818181818);opacity: 1.00;}
		19.77% {transform: translate3d(-424.62px,-450.49px,0.00px) rotate(0.00deg) scale(1.14654545454545,1.14654545454545);opacity: 1.00;}
		20.00% {transform: translate3d(-422.45px,-447.21px,0.00px) rotate(0.00deg) scale(1.14127272727273,1.14127272727273);opacity: 1.00;}
		20.23% {transform: translate3d(-420.28px,-443.92px,0.00px) rotate(0.00deg) scale(1.136,1.136);opacity: 1.00;}
		20.46% {transform: translate3d(-418.09px,-440.65px,0.00px) rotate(0.00deg) scale(1.13072727272727,1.13072727272727);opacity: 1.00;}
		20.69% {transform: translate3d(-415.90px,-437.37px,0.00px) rotate(0.00deg) scale(1.12545454545455,1.12545454545455);opacity: 1.00;}
		20.92% {transform: translate3d(-413.70px,-434.11px,0.00px) rotate(0.00deg) scale(1.12018181818182,1.12018181818182);opacity: 1.00;}
		21.15% {transform: translate3d(-411.50px,-430.84px,0.00px) rotate(0.00deg) scale(1.11490909090909,1.11490909090909);opacity: 1.00;}
		21.38% {transform: translate3d(-409.29px,-427.58px,0.00px) rotate(0.00deg) scale(1.10963636363636,1.10963636363636);opacity: 1.00;}
		21.61% {transform: translate3d(-407.08px,-424.32px,0.00px) rotate(0.00deg) scale(1.10436363636364,1.10436363636364);opacity: 1.00;}
		21.84% {transform: translate3d(-404.87px,-421.07px,0.00px) rotate(0.00deg) scale(1.09909090909091,1.09909090909091);opacity: 1.00;}
		22.07% {transform: translate3d(-402.65px,-417.81px,0.00px) rotate(0.00deg) scale(1.09381818181818,1.09381818181818);opacity: 1.00;}
		22.30% {transform: translate3d(-400.43px,-414.56px,0.00px) rotate(0.00deg) scale(1.08854545454545,1.08854545454545);opacity: 1.00;}
		22.53% {transform: translate3d(-398.21px,-411.31px,0.00px) rotate(0.00deg) scale(1.08327272727273,1.08327272727273);opacity: 1.00;}
		22.76% {transform: translate3d(-395.99px,-408.06px,0.00px) rotate(0.00deg) scale(1.078,1.078);opacity: 1.00;}
		22.99% {transform: translate3d(-393.77px,-404.81px,0.00px) rotate(0.00deg) scale(1.07272727272727,1.07272727272727);opacity: 1.00;}
		23.22% {transform: translate3d(-391.54px,-401.56px,0.00px) rotate(0.00deg) scale(1.06745454545455,1.06745454545455);opacity: 1.00;}
		23.45% {transform: translate3d(-389.32px,-398.31px,0.00px) rotate(0.00deg) scale(1.06218181818182,1.06218181818182);opacity: 1.00;}
		23.68% {transform: translate3d(-387.11px,-395.05px,0.00px) rotate(0.00deg) scale(1.05690909090909,1.05690909090909);opacity: 1.00;}
		23.91% {transform: translate3d(-384.89px,-391.79px,0.00px) rotate(0.00deg) scale(1.05163636363636,1.05163636363636);opacity: 1.00;}
		24.14% {transform: translate3d(-382.68px,-388.53px,0.00px) rotate(0.00deg) scale(1.04636363636364,1.04636363636364);opacity: 1.00;}
		24.37% {transform: translate3d(-380.49px,-385.27px,0.00px) rotate(0.00deg) scale(1.04109090909091,1.04109090909091);opacity: 1.00;}
		24.60% {transform: translate3d(-378.30px,-381.99px,0.00px) rotate(0.00deg) scale(1.03581818181818,1.03581818181818);opacity: 1.00;}
		24.83% {transform: translate3d(-376.14px,-378.70px,0.00px) rotate(0.00deg) scale(1.03054545454545,1.03054545454545);opacity: 1.00;}
		25.06% {transform: translate3d(-374.01px,-375.39px,0.00px) rotate(0.00deg) scale(1.02527272727273,1.02527272727273);opacity: 1.00;}
		31.10% {transform: translate3d(-372.00px,-372.00px,0.00px) rotate(0.00deg) scale(1.02,1.02);opacity: 1.00;}
		31.26% {transform: translate3d(-372.00px,-372.00px,0.00px) rotate(0.00deg) scale(1.02,1.02);opacity: 0.50;}
		31.49% {transform: translate3d(-372.00px,-372.00px,0.00px) rotate(0.00deg) scale(1.02,1.02);opacity: 0.00;}
		31.95% {transform: translate3d(-372.00px,-372.00px,0.00px) rotate(0.00deg) scale(1.02,1.02);opacity: 0;}
		99.54% {transform: translate3d(-372.00px,-372.00px,0.00px) rotate(0.00deg) scale(1.02,1.02);opacity: 0;}
		99.77% {transform: translate3d(-372.00px,-372.00px,0.00px) rotate(0.00deg) scale(1.02,1.02);opacity: 0;}
		}
	.BX_Class13{
	    left: -927.50px;
	    top: 316.00px;
	    width: 1167px;
	    height: 856px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.68,1.68);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG13{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/7b46b35d5da601dc95b876a3e742fcda.png");
	}
	.BX_Ani13{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey13 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey13{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		0.23% {transform: translate3d(9.25px,-5.33px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		0.46% {transform: translate3d(18.51px,-10.64px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		0.69% {transform: translate3d(27.78px,-15.95px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		0.92% {transform: translate3d(37.05px,-21.24px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		1.15% {transform: translate3d(46.33px,-26.53px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		1.38% {transform: translate3d(55.60px,-31.82px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		1.61% {transform: translate3d(64.87px,-37.10px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		1.84% {transform: translate3d(74.15px,-42.38px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		2.07% {transform: translate3d(83.44px,-47.65px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		2.30% {transform: translate3d(92.73px,-52.93px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		2.53% {transform: translate3d(102.01px,-58.20px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		2.76% {transform: translate3d(111.29px,-63.46px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		2.99% {transform: translate3d(120.58px,-68.73px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		3.22% {transform: translate3d(129.86px,-73.99px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		3.45% {transform: translate3d(139.15px,-79.25px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		3.68% {transform: translate3d(148.45px,-84.52px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		3.91% {transform: translate3d(157.74px,-89.78px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		4.14% {transform: translate3d(167.03px,-95.04px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		4.37% {transform: translate3d(176.32px,-100.30px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		4.60% {transform: translate3d(185.61px,-105.56px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		4.83% {transform: translate3d(194.90px,-110.81px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		5.06% {transform: translate3d(204.19px,-116.07px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		5.29% {transform: translate3d(213.48px,-121.33px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		5.52% {transform: translate3d(222.77px,-126.58px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		5.75% {transform: translate3d(232.07px,-131.84px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		5.98% {transform: translate3d(241.36px,-137.10px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		6.21% {transform: translate3d(250.66px,-142.35px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		6.44% {transform: translate3d(259.95px,-147.61px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		6.67% {transform: translate3d(269.25px,-152.86px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		6.90% {transform: translate3d(278.54px,-158.12px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		7.13% {transform: translate3d(287.83px,-163.37px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		7.36% {transform: translate3d(297.12px,-168.63px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		7.59% {transform: translate3d(306.41px,-173.88px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		7.82% {transform: translate3d(315.70px,-179.13px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		8.05% {transform: translate3d(325.00px,-184.39px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		8.28% {transform: translate3d(334.29px,-189.64px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		8.51% {transform: translate3d(343.59px,-194.90px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		8.74% {transform: translate3d(352.88px,-200.15px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		8.97% {transform: translate3d(362.17px,-205.41px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		9.20% {transform: translate3d(371.47px,-210.66px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		9.43% {transform: translate3d(380.76px,-215.92px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		9.66% {transform: translate3d(390.05px,-221.17px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		9.89% {transform: translate3d(399.34px,-226.43px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		10.11% {transform: translate3d(408.63px,-231.69px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		10.34% {transform: translate3d(417.93px,-236.94px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		10.57% {transform: translate3d(427.22px,-242.20px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		10.80% {transform: translate3d(436.51px,-247.46px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		11.03% {transform: translate3d(445.80px,-252.71px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		11.26% {transform: translate3d(455.10px,-257.97px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		11.49% {transform: translate3d(464.39px,-263.23px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		11.72% {transform: translate3d(473.68px,-268.49px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		11.95% {transform: translate3d(482.97px,-273.75px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		12.18% {transform: translate3d(492.26px,-279.01px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		12.41% {transform: translate3d(501.55px,-284.27px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		12.64% {transform: translate3d(510.84px,-289.53px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		12.87% {transform: translate3d(520.13px,-294.79px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		13.10% {transform: translate3d(529.42px,-300.05px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		13.33% {transform: translate3d(538.71px,-305.32px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		13.56% {transform: translate3d(547.99px,-310.58px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		13.79% {transform: translate3d(557.28px,-315.84px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		14.02% {transform: translate3d(566.57px,-321.11px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		14.25% {transform: translate3d(575.86px,-326.37px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		14.48% {transform: translate3d(585.14px,-331.64px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		14.71% {transform: translate3d(594.43px,-336.91px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		14.94% {transform: translate3d(603.71px,-342.17px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		15.17% {transform: translate3d(613.00px,-347.44px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		15.40% {transform: translate3d(622.28px,-352.71px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		15.63% {transform: translate3d(631.57px,-357.98px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		15.86% {transform: translate3d(640.85px,-363.25px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		16.09% {transform: translate3d(650.13px,-368.52px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		16.32% {transform: translate3d(659.42px,-373.79px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		16.55% {transform: translate3d(668.70px,-379.07px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		16.78% {transform: translate3d(677.98px,-384.34px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		17.01% {transform: translate3d(687.26px,-389.62px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		17.24% {transform: translate3d(696.54px,-394.90px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		17.47% {transform: translate3d(705.82px,-400.18px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		17.70% {transform: translate3d(715.11px,-405.46px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		17.93% {transform: translate3d(724.38px,-410.74px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		18.16% {transform: translate3d(733.66px,-416.02px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		18.39% {transform: translate3d(742.94px,-421.30px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		18.62% {transform: translate3d(752.22px,-426.59px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		18.85% {transform: translate3d(761.49px,-431.87px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		19.08% {transform: translate3d(770.77px,-437.16px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		19.31% {transform: translate3d(780.04px,-442.44px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		19.54% {transform: translate3d(789.31px,-447.73px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		19.77% {transform: translate3d(798.59px,-453.03px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		20.00% {transform: translate3d(807.86px,-458.32px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		20.23% {transform: translate3d(817.13px,-463.61px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		20.46% {transform: translate3d(826.40px,-468.91px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		20.69% {transform: translate3d(835.67px,-474.21px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		20.92% {transform: translate3d(844.94px,-479.51px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		21.15% {transform: translate3d(854.20px,-484.81px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		21.38% {transform: translate3d(863.47px,-490.11px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		21.61% {transform: translate3d(872.73px,-495.42px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		21.84% {transform: translate3d(881.99px,-500.73px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		22.07% {transform: translate3d(891.26px,-506.04px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		22.30% {transform: translate3d(900.52px,-511.35px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		22.53% {transform: translate3d(909.78px,-516.66px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		22.76% {transform: translate3d(919.04px,-521.98px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		22.99% {transform: translate3d(928.29px,-527.30px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		23.22% {transform: translate3d(937.55px,-532.62px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		23.45% {transform: translate3d(946.80px,-537.95px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		23.68% {transform: translate3d(956.05px,-543.28px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		23.91% {transform: translate3d(965.30px,-548.61px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		24.14% {transform: translate3d(974.55px,-553.94px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		24.37% {transform: translate3d(983.79px,-559.28px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		24.60% {transform: translate3d(993.04px,-564.62px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		24.83% {transform: translate3d(1002.28px,-569.97px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		25.06% {transform: translate3d(1011.52px,-575.32px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		25.29% {transform: translate3d(1020.75px,-580.67px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		25.52% {transform: translate3d(1029.99px,-586.03px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		25.75% {transform: translate3d(1039.22px,-591.39px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		25.98% {transform: translate3d(1048.45px,-596.76px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		26.21% {transform: translate3d(1057.67px,-602.14px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		26.44% {transform: translate3d(1066.89px,-607.52px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		26.67% {transform: translate3d(1076.11px,-612.91px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		26.90% {transform: translate3d(1085.32px,-618.30px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		27.13% {transform: translate3d(1094.53px,-623.70px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		27.36% {transform: translate3d(1103.73px,-629.11px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		27.59% {transform: translate3d(1112.93px,-634.53px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		27.82% {transform: translate3d(1122.13px,-639.96px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		28.05% {transform: translate3d(1131.31px,-645.40px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		28.28% {transform: translate3d(1140.49px,-650.85px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		28.51% {transform: translate3d(1149.66px,-656.31px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		28.74% {transform: translate3d(1158.82px,-661.80px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		28.97% {transform: translate3d(1167.97px,-667.30px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		29.20% {transform: translate3d(1177.11px,-672.82px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		29.43% {transform: translate3d(1186.23px,-678.36px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		29.66% {transform: translate3d(1195.33px,-683.94px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		29.89% {transform: translate3d(1204.41px,-689.56px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		30.11% {transform: translate3d(1213.46px,-695.23px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		30.34% {transform: translate3d(1222.46px,-700.98px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		30.57% {transform: translate3d(1231.39px,-706.83px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		30.80% {transform: translate3d(1240.16px,-712.91px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		31.03% {transform: translate3d(1248.00px,-720.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		31.26% {transform: translate3d(0.00px,-112.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		31.49% {transform: translate3d(-4.32px,-109.32px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		31.72% {transform: translate3d(-8.53px,-106.47px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		31.95% {transform: translate3d(-12.47px,-103.27px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		32.18% {transform: translate3d(-11.81px,-100.05px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		32.41% {transform: translate3d(-6.76px,-100.51px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		32.64% {transform: translate3d(-1.77px,-101.51px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		32.87% {transform: translate3d(3.17px,-102.72px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		33.10% {transform: translate3d(8.07px,-104.04px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		33.33% {transform: translate3d(12.96px,-105.43px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		33.56% {transform: translate3d(17.84px,-106.88px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		33.79% {transform: translate3d(22.70px,-108.37px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		34.02% {transform: translate3d(27.55px,-109.89px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		34.25% {transform: translate3d(32.39px,-111.44px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		34.48% {transform: translate3d(37.23px,-113.01px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		34.71% {transform: translate3d(42.06px,-114.60px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		34.94% {transform: translate3d(46.88px,-116.21px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		35.17% {transform: translate3d(51.69px,-117.83px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		35.40% {transform: translate3d(56.51px,-119.46px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		35.63% {transform: translate3d(61.32px,-121.11px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		35.86% {transform: translate3d(66.12px,-122.77px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		36.09% {transform: translate3d(70.93px,-124.44px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		36.32% {transform: translate3d(75.73px,-126.12px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		36.55% {transform: translate3d(80.52px,-127.80px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		36.78% {transform: translate3d(85.31px,-129.49px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		37.01% {transform: translate3d(90.10px,-131.19px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		37.24% {transform: translate3d(94.89px,-132.89px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		37.47% {transform: translate3d(99.68px,-134.61px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		37.70% {transform: translate3d(104.47px,-136.32px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		37.93% {transform: translate3d(109.25px,-138.05px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		38.16% {transform: translate3d(114.04px,-139.77px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		38.39% {transform: translate3d(118.82px,-141.51px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		38.62% {transform: translate3d(123.60px,-143.24px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		38.85% {transform: translate3d(128.37px,-144.98px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		39.08% {transform: translate3d(133.15px,-146.73px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		39.31% {transform: translate3d(137.92px,-148.47px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		39.54% {transform: translate3d(142.69px,-150.22px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		39.77% {transform: translate3d(147.46px,-151.98px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		40.00% {transform: translate3d(152.23px,-153.73px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		40.23% {transform: translate3d(157.00px,-155.49px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		40.46% {transform: translate3d(161.76px,-157.26px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		40.69% {transform: translate3d(166.53px,-159.02px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		40.92% {transform: translate3d(171.30px,-160.79px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		41.15% {transform: translate3d(176.07px,-162.56px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		41.38% {transform: translate3d(180.83px,-164.34px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		41.61% {transform: translate3d(185.59px,-166.11px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		41.84% {transform: translate3d(190.36px,-167.89px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		42.07% {transform: translate3d(195.12px,-169.67px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		42.30% {transform: translate3d(199.88px,-171.45px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		42.53% {transform: translate3d(204.64px,-173.24px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		42.76% {transform: translate3d(209.39px,-175.02px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		42.99% {transform: translate3d(214.15px,-176.81px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		43.22% {transform: translate3d(218.91px,-178.60px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		43.45% {transform: translate3d(223.67px,-180.39px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		43.68% {transform: translate3d(228.43px,-182.18px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		43.91% {transform: translate3d(233.18px,-183.98px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		44.14% {transform: translate3d(237.94px,-185.77px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		44.37% {transform: translate3d(242.70px,-187.57px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		44.60% {transform: translate3d(247.45px,-189.37px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		44.83% {transform: translate3d(252.21px,-191.17px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		45.06% {transform: translate3d(256.96px,-192.97px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		45.29% {transform: translate3d(261.71px,-194.78px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		45.52% {transform: translate3d(266.47px,-196.58px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		45.75% {transform: translate3d(271.22px,-198.38px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		45.98% {transform: translate3d(275.97px,-200.19px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		46.21% {transform: translate3d(280.72px,-202.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		46.44% {transform: translate3d(285.47px,-203.81px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		46.67% {transform: translate3d(290.22px,-205.61px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		46.90% {transform: translate3d(294.97px,-207.42px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		47.13% {transform: translate3d(299.72px,-209.24px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		47.36% {transform: translate3d(304.47px,-211.05px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		47.59% {transform: translate3d(309.22px,-212.86px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		47.82% {transform: translate3d(313.97px,-214.67px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		48.05% {transform: translate3d(318.71px,-216.49px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		48.28% {transform: translate3d(323.46px,-218.30px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		48.51% {transform: translate3d(328.21px,-220.12px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		48.74% {transform: translate3d(332.96px,-221.94px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		48.97% {transform: translate3d(337.71px,-223.76px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		49.20% {transform: translate3d(342.45px,-225.57px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		49.43% {transform: translate3d(347.20px,-227.39px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		49.66% {transform: translate3d(351.95px,-229.21px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		49.89% {transform: translate3d(356.69px,-231.03px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		50.11% {transform: translate3d(361.44px,-232.85px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		50.34% {transform: translate3d(366.18px,-234.67px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		50.57% {transform: translate3d(370.93px,-236.49px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		50.80% {transform: translate3d(375.67px,-238.31px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		51.03% {transform: translate3d(380.42px,-240.13px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		51.26% {transform: translate3d(385.16px,-241.96px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		51.49% {transform: translate3d(389.91px,-243.78px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		51.72% {transform: translate3d(394.66px,-245.60px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		51.95% {transform: translate3d(399.40px,-247.43px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		52.18% {transform: translate3d(404.15px,-249.25px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		52.41% {transform: translate3d(408.90px,-251.07px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		52.64% {transform: translate3d(413.64px,-252.90px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		52.87% {transform: translate3d(418.39px,-254.72px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		53.10% {transform: translate3d(423.13px,-256.55px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		53.33% {transform: translate3d(427.88px,-258.37px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		53.56% {transform: translate3d(432.62px,-260.19px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		53.79% {transform: translate3d(437.37px,-262.01px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		54.02% {transform: translate3d(442.11px,-263.84px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		54.25% {transform: translate3d(446.85px,-265.66px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		54.48% {transform: translate3d(451.60px,-267.48px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		54.71% {transform: translate3d(456.35px,-269.31px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		54.94% {transform: translate3d(461.09px,-271.13px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		55.17% {transform: translate3d(465.84px,-272.95px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		55.40% {transform: translate3d(470.59px,-274.77px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		55.63% {transform: translate3d(475.34px,-276.59px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		55.86% {transform: translate3d(480.08px,-278.41px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		56.09% {transform: translate3d(484.83px,-280.23px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		56.32% {transform: translate3d(489.57px,-282.05px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		56.55% {transform: translate3d(494.32px,-283.86px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		56.78% {transform: translate3d(499.07px,-285.68px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		57.01% {transform: translate3d(503.82px,-287.49px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		57.24% {transform: translate3d(508.57px,-289.31px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		57.47% {transform: translate3d(513.32px,-291.12px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		57.70% {transform: translate3d(518.07px,-292.93px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		57.93% {transform: translate3d(522.82px,-294.74px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		58.16% {transform: translate3d(527.57px,-296.54px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		58.39% {transform: translate3d(532.33px,-298.34px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		58.62% {transform: translate3d(537.08px,-300.14px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		58.85% {transform: translate3d(541.84px,-301.93px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		59.08% {transform: translate3d(546.60px,-303.72px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		59.31% {transform: translate3d(551.36px,-305.51px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		59.54% {transform: translate3d(556.12px,-307.29px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		59.77% {transform: translate3d(560.88px,-309.06px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		60.00% {transform: translate3d(565.66px,-310.82px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		60.23% {transform: translate3d(570.43px,-312.57px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		60.46% {transform: translate3d(575.21px,-314.30px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		60.69% {transform: translate3d(580.00px,-316.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		61.15% {transform: translate3d(536.00px,1228.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 1.00;}
		68.97% {transform: translate3d(536.00px,1228.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 0;}
		99.54% {transform: translate3d(536.00px,1228.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 0;}
		99.77% {transform: translate3d(536.00px,1228.00px,0.00px) rotate(0.00deg) scale(1.68,1.68);opacity: 0;}
	}
	.BX_Class12{
		left: 200px;
		top: -600px;
		width: 400px;
		height: 606px;
		transform-style: preserve-3d;
		transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.8,1.6);
		opacity: 1.00;
		transform-origin:50.00% 50.00%;
		display: block;
		position: absolute; 
	}
	.BX_BG12{
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	    /*background: url("http://litiresource.xcgogo.site/user/5980171/images/070188cdde742a785ecdb6abf0a76a94.png");*/
	}
	.BX_Ani12{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey12 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey12{
		0.00% {opacity: 1.00;}
		30.00% {opacity: 1.00;}
		30.11% {opacity: 0.75;}
		30.34% {opacity: 0.50;}
		30.57% {opacity: 0.25;}
		30.80% {opacity: 0.00;}
		31.03% {opacity: 0.13;}
		31.26% {opacity: 0.25;}
		31.49% {opacity: 0.38;}
		31.72% {opacity: 0.50;}
		31.95% {opacity: 0.63;}
		32.18% {opacity: 0.75;}
		32.41% {opacity: 0.88;}
		32.64% {opacity: 1.00;}
		99.54% {opacity: 1.00;}
		99.77% {opacity: 0.00;}
		}

	.BX_Class9{
	    left: -304.50px;
	    top: 64.50px;
	    width: 865px;
	    height: 399px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(0.23,0.27);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG9{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/eea8e17d7d59c3a3ab74d7c3650a8582.png");
	}
	.BX_Ani9{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey9 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey9{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.22772761981955,0.26919799498747);opacity: 1.00;}
		15.40% {transform: translate3d(54.85px,831.61px,0.00px) rotate(0.00deg) scale(0.66115178335054,0.78155093616394);opacity: 1.00;}
		15.63% {transform: translate3d(56.00px,844.00px,0.00px) rotate(0.00deg) scale(0.66762080071668,0.78919799498747);opacity: 1.00;}
		15.86% {transform: translate3d(56.42px,848.78px,0.00px) rotate(0.00deg) scale(0.67164912654907,0.79395989974937);opacity: 1.00;}
		16.09% {transform: translate3d(56.75px,853.52px,0.00px) rotate(0.00deg) scale(0.67567745238146,0.79872180451128);opacity: 1.00;}
		16.32% {transform: translate3d(56.98px,858.28px,0.00px) rotate(0.00deg) scale(0.67970577821385,0.80348370927318);opacity: 1.00;}
		24.83% {transform: translate3d(78.56px,913.37px,0.00px) rotate(0.00deg) scale(0.82875383401233,0.97967418546366);opacity: 1.00;}
		25.06% {transform: translate3d(83.27px,912.65px,0.00px) rotate(0.00deg) scale(0.83278215984472,0.98443609022556);opacity: 1.00;}
		25.29% {transform: translate3d(88.00px,912.00px,0.00px) rotate(0.00deg) scale(0.83681048567711,0.98919799498747);opacity: 1.00;}
		25.52% {transform: translate3d(132.93px,905.46px,0.00px) rotate(0.00deg) scale(0.82464997707058,0.97482299498747);opacity: 1.00;}
		25.75% {transform: translate3d(177.77px,898.39px,0.00px) rotate(0.00deg) scale(0.81248946846405,0.96044799498747);opacity: 1.00;}
		25.98% {transform: translate3d(222.58px,891.05px,0.00px) rotate(0.00deg) scale(0.80032895985752,0.94607299498747);opacity: 1.00;}
		26.21% {transform: translate3d(267.36px,883.52px,0.00px) rotate(0.00deg) scale(0.78816845125099,0.93169799498747);opacity: 1.00;}
		29.20% {transform: translate3d(846.55px,770.48px,0.00px) rotate(0.00deg) scale(0.63008183936608,0.74482299498747);opacity: 1.00;}
		29.43% {transform: translate3d(890.53px,759.24px,0.00px) rotate(0.00deg) scale(0.61792133075955,0.73044799498747);opacity: 1.00;}
		29.66% {transform: translate3d(934.02px,746.22px,0.00px) rotate(0.00deg) scale(0.60576082215302,0.71607299498747);opacity: 1.00;}
		29.89% {transform: translate3d(960.00px,724.00px,0.00px) rotate(0.00deg) scale(0.59360031354649,0.70169799498747);opacity: 1.00;}
		30.11% {transform: translate3d(846.40px,715.78px,0.00px) rotate(0.00deg) scale(0.58143980493996,0.68732299498747);opacity: 0.75;}
		32.41% {transform: translate3d(-294.06px,742.22px,0.00px) rotate(0.00deg) scale(0.45983471887465,0.54357299498747);opacity: 0.88;}
		32.64% {transform: translate3d(-408.00px,748.00px,0.00px) rotate(0.00deg) scale(0.44767421026811,0.52919799498747);opacity: 1.00;}
		32.87% {transform: translate3d(-418.97px,752.23px,0.00px) rotate(0.00deg) scale(0.46287421026811,0.54716599345446);opacity: 1.00;}
		33.10% {transform: translate3d(-393.94px,754.24px,0.00px) rotate(0.00deg) scale(0.47807421026811,0.56513399192145);opacity: 1.00;}
		38.16% {transform: translate3d(158.05px,777.19px,0.00px) rotate(0.00deg) scale(0.81247421026811,0.96042995819529);opacity: 1.00;}
		38.39% {transform: translate3d(183.00px,780.00px,0.00px) rotate(0.00deg) scale(0.82767421026811,0.97839795666228);opacity: 1.00;}
		38.62% {transform: translate3d(188.14px,780.84px,0.00px) rotate(0.00deg) scale(0.82688075214961,0.97746000572433);opacity: 1.00;}
		38.85% {transform: translate3d(193.24px,781.80px,0.00px) rotate(0.00deg) scale(0.82608729403111,0.97652205478638);opacity: 1.00;}
		39.08% {transform: translate3d(198.32px,782.90px,0.00px) rotate(0.00deg) scale(0.82529383591261,0.97558410384843);opacity: 1.00;}
		43.22% {transform: translate3d(195.00px,840.00px,0.00px) rotate(0.00deg) scale(0.81101158977959,0.95870098696531);opacity: 1.00;}
		43.45% {transform: translate3d(195.60px,836.18px,0.00px) rotate(0.00deg) scale(0.81021813166109,0.95776303602736);opacity: 1.00;}
		43.68% {transform: translate3d(195.76px,832.28px,0.00px) rotate(0.00deg) scale(0.80942467354258,0.95682508508941);opacity: 1.00;}
		43.91% {transform: translate3d(195.84px,828.39px,0.00px) rotate(0.00deg) scale(0.80863121542408,0.95588713415146);opacity: 1.00;}
		47.13% {transform: translate3d(195.11px,773.89px,0.00px) rotate(0.00deg) scale(0.79752280176506,0.94275582102014);opacity: 1.00;}
		47.36% {transform: translate3d(195.00px,770.00px,0.00px) rotate(0.00deg) scale(0.79672934364656,0.94181787008219);opacity: 1.00;}
		47.59% {transform: translate3d(195.00px,774.12px,0.00px) rotate(0.00deg) scale(0.79593588552806,0.94087991914424);opacity: 1.00;}
		47.82% {transform: translate3d(195.00px,778.24px,0.00px) rotate(0.00deg) scale(0.79514242740956,0.93994196820629);opacity: 1.00;}
		51.03% {transform: translate3d(195.00px,835.88px,0.00px) rotate(0.00deg) scale(0.78403401375054,0.92681065507498);opacity: 1.00;}
		51.26% {transform: translate3d(195.00px,840.00px,0.00px) rotate(0.00deg) scale(0.78324055563204,0.92587270413703);opacity: 1.00;}
		51.49% {transform: translate3d(187.14px,837.69px,0.00px) rotate(0.00deg) scale(0.78244709751354,0.92493475319908);opacity: 1.00;}
		51.72% {transform: translate3d(179.38px,835.04px,0.00px) rotate(0.00deg) scale(0.78165363939504,0.92399680226113);opacity: 1.00;}
		51.95% {transform: translate3d(171.74px,832.10px,0.00px) rotate(0.00deg) scale(0.78086018127654,0.92305885132317);opacity: 1.00;}
		55.17% {transform: translate3d(195.00px,771.00px,0.00px) rotate(0.00deg) scale(0.76975176761752,0.90992753819186);opacity: 1.00;}
		55.40% {transform: translate3d(245.63px,758.99px,0.00px) rotate(0.00deg) scale(0.76895830949902,0.90898958725391);opacity: 1.00;}
		55.63% {transform: translate3d(296.59px,748.44px,0.00px) rotate(0.00deg) scale(0.76816485138052,0.90805163631596);opacity: 1.00;}
		55.86% {transform: translate3d(347.68px,738.54px,0.00px) rotate(0.00deg) scale(0.76737139326201,0.90711368537801);opacity: 1.00;}
		58.16% {transform: translate3d(860.42px,649.64px,0.00px) rotate(0.00deg) scale(0.759436812077,0.8977341759985);opacity: 1.00;}
		58.39% {transform: translate3d(911.73px,640.95px,0.00px) rotate(0.00deg) scale(0.7586433539585,0.89679622506055);opacity: 1.00;}
		58.62% {transform: translate3d(963.00px,632.00px,0.00px) rotate(0.00deg) scale(0.75784989584,0.8958582741226);opacity: 1.00;}
		58.85% {transform: translate3d(947.35px,627.00px,0.00px) rotate(0.00deg) scale(0.75870118808286,0.89686459107752);opacity: 1.00;}
		61.15% {transform: translate3d(790.91px,577.05px,0.00px) rotate(0.00deg) scale(0.06897283528026,0.08153314464341);opacity: 1.00;}
		61.38% {transform: translate3d(775.27px,572.05px,0.00px) rotate(0.00deg) scale(0.01);opacity: 1.00;}
		61.61% {transform: translate3d(759.62px,567.06px,0.00px) rotate(0.00deg) scale(0.01);opacity: 1.00;}
		61.84% {transform: translate3d(743.98px,562.06px,0.00px) rotate(0.00deg) scale(0.01);opacity: 1.00;}
		65.06% {transform: translate3d(524.96px,492.12px,0.00px) rotate(0.00deg) scale(0.01);opacity: 1.00;}
		65.29% {transform: translate3d(509.31px,487.13px,0.00px) rotate(0.00deg) scale(0.01);opacity: 1.00;}
		65.52% {transform: translate3d(493.67px,482.13px,0.00px) rotate(0.00deg) scale(0.01);opacity: 1.00;}
		65.75% {transform: translate3d(488.55px,494.37px,0.00px) rotate(0.00deg) scale(0.00775508506412,0.00916732608839);opacity: 1.00;}
		65.98% {transform: translate3d(483.44px,506.58px,0.00px) rotate(0.00deg) scale(0.01551017012824,0.01833465217678);opacity: 1.00;}
		69.20% {transform: translate3d(411.89px,677.69px,0.00px) rotate(0.00deg) scale(0.12408136102595,0.14667721741425);opacity: 1.00;}
		69.43% {transform: translate3d(406.78px,689.91px,0.00px) rotate(0.00deg) scale(0.13183644609007,0.15584454350264);opacity: 1.00;}
		69.66% {transform: translate3d(401.67px,702.13px,0.00px) rotate(0.00deg) scale(0.13959153115419,0.16501186959103);opacity: 1.00;}
		69.89% {transform: translate3d(395.06px,702.73px,0.00px) rotate(0.00deg) scale(0.14734661621832,0.17417919567942);opacity: 1.00;}
		74.02% {transform: translate3d(276.27px,713.53px,0.00px) rotate(0.00deg) scale(0.28693814737251,0.33919106527045);opacity: 1.00;}
		74.25% {transform: translate3d(269.67px,714.13px,0.00px) rotate(0.00deg) scale(0.29469323243663,0.34835839135884);opacity: 1.00;}
		74.48% {transform: translate3d(277.27px,717.14px,0.00px) rotate(0.00deg) scale(0.30244831750075,0.35752571744723);opacity: 1.00;}
		74.71% {transform: translate3d(284.87px,720.13px,0.00px) rotate(0.00deg) scale(0.31020340256487,0.36669304353562);opacity: 1.00;}
		74.94% {transform: translate3d(292.47px,723.13px,0.00px) rotate(0.00deg) scale(0.317958487629,0.37586036962402);opacity: 1.00;}
		79.08% {transform: translate3d(406.61px,774.13px,0.00px) rotate(0.00deg) scale(0.45755001878319,0.54087223921505);opacity: 1.00;}
		79.31% {transform: translate3d(391.55px,774.13px,0.00px) rotate(0.00deg) scale(0.46530510384731,0.55003956530344);opacity: 1.00;}
		79.54% {transform: translate3d(376.49px,774.13px,0.00px) rotate(0.00deg) scale(0.47306018891143,0.55920689139183);opacity: 1.00;}
		79.77% {transform: translate3d(361.43px,774.13px,0.00px) rotate(0.00deg) scale(0.48081527397555,0.56837421748022);opacity: 1.00;}
		82.07% {transform: translate3d(210.84px,774.13px,0.00px) rotate(0.00deg) scale(0.55836612461677,0.66004747836412);opacity: 1.00;}
		82.30% {transform: translate3d(195.78px,774.13px,0.00px) rotate(0.00deg) scale(0.56612120968089,0.66921480445251);opacity: 1.00;}
		82.53% {transform: translate3d(180.73px,774.13px,0.00px) rotate(0.00deg) scale(0.57387629474502,0.67838213054091);opacity: 1.00;}
		82.76% {transform: translate3d(165.67px,774.13px,0.00px) rotate(0.00deg) scale(0.58163137980914,0.6875494566293);opacity: 1.00;}
		82.99% {transform: translate3d(176.28px,775.35px,0.00px) rotate(0.00deg) scale(0.58938646487326,0.69671678271769);opacity: 1.00;}
		88.05% {transform: translate3d(409.67px,802.13px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		88.28% {transform: translate3d(411.32px,796.01px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		88.51% {transform: translate3d(412.96px,789.90px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		88.74% {transform: translate3d(414.61px,783.78px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		88.97% {transform: translate3d(416.26px,777.66px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		92.18% {transform: translate3d(437.67px,700.89px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		92.41% {transform: translate3d(437.67px,703.63px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		92.64% {transform: translate3d(437.67px,706.38px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		92.87% {transform: translate3d(437.67px,709.14px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		95.17% {transform: translate3d(437.67px,736.63px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		95.40% {transform: translate3d(437.67px,739.38px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		95.63% {transform: translate3d(437.67px,742.13px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		95.86% {transform: translate3d(437.67px,740.83px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		96.09% {transform: translate3d(437.67px,739.55px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		96.32% {transform: translate3d(437.67px,738.25px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		96.55% {transform: translate3d(437.67px,736.96px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		96.78% {transform: translate3d(437.67px,735.66px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		97.01% {transform: translate3d(437.67px,734.37px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		97.24% {transform: translate3d(437.67px,733.07px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		97.47% {transform: translate3d(437.67px,731.78px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		97.70% {transform: translate3d(437.67px,730.49px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		97.93% {transform: translate3d(437.67px,729.19px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		98.16% {transform: translate3d(437.67px,727.90px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		98.39% {transform: translate3d(437.67px,726.61px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		98.62% {transform: translate3d(437.67px,725.31px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		98.85% {transform: translate3d(437.67px,724.02px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		99.08% {transform: translate3d(437.67px,722.72px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		99.31% {transform: translate3d(437.67px,721.44px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 1.00;}
		99.54% {transform: translate3d(437.67px,720.13px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 0.00;}
		99.77% {transform: translate3d(437.67px,720.13px,0.00px) rotate(0.00deg) scale(0.75999833628394,0.89839795666228);opacity: 0.00;}
	}
	.BX_Class8{
	    left: -62.50px;
	    top: 1106.50px;
	    width: 865px;
	    height: 399px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.14,0.90);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG8{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/eea8e17d7d59c3a3ab74d7c3650a8582.png");
	}
	.BX_Ani8{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey8 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey8{
		0.00% {opacity: 0;}
		31.50% {opacity: 0;}
		31.72% {opacity: 1.00;}
		60.92% {opacity: 0;}
		99.54% {opacity: 0;}
		99.77% {opacity: 0;}
		}
	.BX_Class7{
	    left: 3.50px;
	    top: 993.50px;
	    width: 487px;
	    height: 347px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(2.00deg) scale(-1.22,1.23);
	    opacity: 0.00;
	    transform-origin:52.26% 91.21%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG7{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/4bb8b49406f8706cbf4ea3a7c1cc2d7c.png");
	}
	.BX_Ani7{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey7 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey7{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 0;}
		31.50% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.83deg) scale(-1.21954102483898,1.23054755043228);opacity: 0.00;}
		31.72% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.83deg) scale(-1.21954102483898,1.23054755043228);opacity: 0.50;}
		31.95% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.65deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		32.18% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.48deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		32.41% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.30deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		32.64% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.13deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		32.87% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.96deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		33.10% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.78deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		33.33% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.61deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		33.56% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.43deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		33.79% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.26deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		34.02% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.09deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		34.25% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.09deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		34.48% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.26deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		34.71% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.43deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		34.94% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.61deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		35.17% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.78deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		35.40% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.96deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		35.63% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.13deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		35.86% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.30deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		36.09% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.48deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		36.32% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.65deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		36.55% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.83deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		36.78% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		37.01% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		37.24% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		37.47% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		37.70% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		37.93% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		38.16% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		38.39% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		38.62% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		38.85% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		39.08% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		39.31% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		39.54% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		39.77% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		40.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		40.23% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		40.46% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		40.69% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		40.92% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		41.15% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		41.38% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		41.61% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		41.84% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		42.07% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		42.30% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		42.53% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		42.76% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		42.99% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		43.22% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		43.45% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		43.68% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		43.91% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		44.14% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		44.37% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		44.60% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		44.83% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		45.06% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		45.29% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		45.52% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		45.75% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		45.98% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		46.21% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		46.44% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		46.67% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		46.90% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		47.13% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		47.36% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		47.59% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		47.82% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		48.05% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		48.28% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		48.51% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		48.74% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		48.97% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		49.20% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		49.43% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		49.66% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		49.89% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		50.11% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		50.34% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		50.57% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		50.80% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		51.03% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		51.26% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		51.49% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		51.72% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		51.95% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		52.18% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		52.41% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		52.64% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		52.87% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		53.10% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		53.33% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		53.56% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		53.79% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		54.02% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		54.25% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		54.48% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		54.71% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		54.94% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		55.17% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		55.40% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		55.63% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		55.86% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		56.09% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		56.32% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		56.55% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		56.78% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		57.01% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		57.24% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		57.47% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		57.70% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		57.93% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		58.16% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		58.39% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		58.62% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		58.85% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		59.08% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		59.31% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		59.54% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		59.77% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 1.00;}
		60.92% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 0;}
		99.54% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 0;}
		99.77% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(-1.21954102483898,1.23054755043228);opacity: 0;}
		}
	.BX_Class6{
	    left: 418.50px;
	    top: 1139.00px;
	    width: 289px;
	    height: 208px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(2.00deg) scale(1.42,1.31);
	    opacity: 0.00;
	    transform-origin:51.04% 97.60%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG6{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/01c7672edf50bd3a509ddab926375f68.png");
	}
	.BX_Ani6{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey6 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey6{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 0;}
		31.72% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.83deg) scale(1.41683766779961,1.30769230769231);opacity: 0.00;}
		31.95% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.65deg) scale(1.41683766779961,1.30769230769231);opacity: 0.50;}
		32.18% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.48deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		32.41% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.30deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		32.64% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.13deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		32.87% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.96deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		33.10% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.78deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		33.33% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.61deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		33.56% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.43deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		33.79% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.26deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		34.02% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.09deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		34.25% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.09deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		34.48% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.26deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		34.71% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.43deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		34.94% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.61deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		35.17% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.78deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		35.40% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.96deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		35.63% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.13deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		35.86% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.30deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		36.09% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.48deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		36.32% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.65deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		36.55% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.83deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		36.78% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		37.01% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		37.24% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		37.47% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		37.70% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		37.93% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		38.16% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		38.39% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		38.62% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		38.85% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		39.08% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		39.31% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		39.54% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		39.77% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		40.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		40.23% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		40.46% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		40.69% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		40.92% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		41.15% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		41.38% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		41.61% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		41.84% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		42.07% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		42.30% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		42.53% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		42.76% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		42.99% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		43.22% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		43.45% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		43.68% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		43.91% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		44.14% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		44.37% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		44.60% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		44.83% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		45.06% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		45.29% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		45.52% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		45.75% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		45.98% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		46.21% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		46.44% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		46.67% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		46.90% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		47.13% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		47.36% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		47.59% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		47.82% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		48.05% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		48.28% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		48.51% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		48.74% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		48.97% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		49.20% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		49.43% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		49.66% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		49.89% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		50.11% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		50.34% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		50.57% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		50.80% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		51.03% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		51.26% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		51.49% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		51.72% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		51.95% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		52.18% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		52.41% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		52.64% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		52.87% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		53.10% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		53.33% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		53.56% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		53.79% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		54.02% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		54.25% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		54.48% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		54.71% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		54.94% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		55.17% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		55.40% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		55.63% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		55.86% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		56.09% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		56.32% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		56.55% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		56.78% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		57.01% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		57.24% {transform: translate3d(0.00px,0.00px,0.00px) rotate(-0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		57.47% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		57.70% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		57.93% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		58.16% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		58.39% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		58.62% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		58.85% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.20deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		59.08% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.40deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		59.31% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.60deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		59.54% {transform: translate3d(0.00px,0.00px,0.00px) rotate(1.80deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		59.77% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 1.00;}
		60.92% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 0;}
		99.54% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 0;}
		99.77% {transform: translate3d(0.00px,0.00px,0.00px) rotate(2.00deg) scale(1.41683766779961,1.30769230769231);opacity: 0;}
		}
	.BX_Class5{
	    left: 351.50px;
	    top: 349.50px;
	    width: 249px;
	    height: 493px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.87,1.58);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG5{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/fcbb1b0705d0a0f4f9f63f2287ff4819.png");
	}
	.BX_Ani5{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey5 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey5{
		0.00% {opacity: 0;}
		61.00% {opacity: 0.00;}
		61.15% {opacity: 1.00;}
		66.67% {opacity: 0.88;}
		66.90% {opacity: 0.75;}
		67.13% {opacity: 0.63;}
		67.36% {opacity: 0.50;}
		67.59% {opacity: 0.38;}
		67.82% {opacity: 0.25;}
		68.05% {opacity: 0.13;}
		68.28% {opacity: 0.00;}
		72.41% {opacity: 0;}
		99.54% {opacity: 0;}
		99.77% {opacity: 0;}
		}
	.BX_Class4{
	    left: -49.00px;
	    top: 56.50px;
	    width: 884px;
	    height: 1175px;
	    transform-style: preserve-3d;
	    transform: translate3d(-78.77px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG4{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png");
	}
	.BX_Ani4{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey4 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey4{
		0.00% {opacity: 1.00;}
		1.38% {opacity: 1.00;}
		3.38% {opacity: 0.00;}
		96.50% {opacity: 0.00;}
		99.77% {opacity: 1.00;}
		}
	.BX_Class4{
	    left: -49.00px;
	    top: 56.50px;
	    width: 884px;
	    height: 1175px;
	    transform-style: preserve-3d;
	    transform: translate3d(-78.77px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG4{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png");
	}
	.BX_Ani4{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey4 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey4{
		0.00% {opacity: 1.00;}
		1.38% {opacity: 1.00;}
		3.38% {opacity: 0.00;}
		96.50% {opacity: 0.00;}
		99.77% {opacity: 1.00;}
		}

	.BX_Class3{
	    left: 870.00px;
	    top: 56.50px;
	    width: 884px;
	    height: 1175px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.27,1.27);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG3{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png");
	}
	.BX_Ani3{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey3 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey3{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0;}
		27.59% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		28.74% {transform: translate3d(-71.74px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		28.97% {transform: translate3d(-143.47px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		29.20% {transform: translate3d(-215.19px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		29.43% {transform: translate3d(-286.93px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		29.66% {transform: translate3d(-358.66px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		29.89% {transform: translate3d(-430.39px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		30.11% {transform: translate3d(-502.12px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		30.34% {transform: translate3d(-573.85px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		30.57% {transform: translate3d(-645.58px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		30.80% {transform: translate3d(-717.31px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		31.03% {transform: translate3d(-789.04px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		31.26% {transform: translate3d(-860.77px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		31.49% {transform: translate3d(-932.50px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		31.72% {transform: translate3d(-1004.23px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		31.95% {transform: translate3d(-1075.96px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		32.18% {transform: translate3d(-1147.69px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		32.41% {transform: translate3d(-1219.42px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		32.64% {transform: translate3d(-1291.15px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		32.87% {transform: translate3d(-1362.88px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		33.10% {transform: translate3d(-1434.61px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		33.33% {transform: translate3d(-1506.35px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		33.56% {transform: translate3d(-1578.07px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		33.79% {transform: translate3d(-1649.81px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		34.02% {transform: translate3d(-1721.53px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		34.25% {transform: translate3d(-1793.26px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		34.48% {transform: translate3d(-1865.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		35.40% {transform: translate3d(-1865.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0;}
		36.40% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0;}
		}
	.BX_Class2{
	    left: 870.00px;
	    top: 56.50px;
	    width: 884px;
	    height: 1175px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.27,1.27);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG2{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png");
	}
	.BX_Ani2{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey2 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey2{
		0.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0;}
		57.01% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		58.39% {transform: translate3d(-71.74px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		58.62% {transform: translate3d(-143.47px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		58.85% {transform: translate3d(-215.19px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		59.08% {transform: translate3d(-286.93px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		59.31% {transform: translate3d(-358.66px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		59.54% {transform: translate3d(-430.39px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		59.77% {transform: translate3d(-502.12px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		60.00% {transform: translate3d(-573.85px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		60.23% {transform: translate3d(-645.58px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		60.46% {transform: translate3d(-717.31px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		60.69% {transform: translate3d(-789.04px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		60.92% {transform: translate3d(-860.77px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		61.15% {transform: translate3d(-932.50px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		61.38% {transform: translate3d(-1004.23px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		61.61% {transform: translate3d(-1075.96px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		61.84% {transform: translate3d(-1147.69px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		62.07% {transform: translate3d(-1219.42px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		62.30% {transform: translate3d(-1291.15px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		62.53% {transform: translate3d(-1362.88px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		62.76% {transform: translate3d(-1434.61px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		62.99% {transform: translate3d(-1506.35px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		63.22% {transform: translate3d(-1578.07px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		63.45% {transform: translate3d(-1649.81px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		63.68% {transform: translate3d(-1721.53px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		63.91% {transform: translate3d(-1793.26px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		64.14% {transform: translate3d(-1865.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		64.83% {transform: translate3d(-1865.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0;}
		65.83% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0;}
		
		}
	.BX_Class1{
	    left: 870.00px;
	    top: 56.50px;
	    width: 884px;
	    height: 1175px;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,0.00px) rotate(0.00deg) scale(1.27,1.27);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG1{
	    background: url("http://litiresource.xcgogo.site/user/5980171/images/f54dc9e2a8f858957fae67c735d3d9e5.png");
	}
	.BX_Ani1{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey1 43.40s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey1{
		0.00% {transform: translate3d(-932.5px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		1.00% {transform: translate3d(-932.5px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0.00;}
		2.00% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 0.00;}
		96.32% {transform: translate3d(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		97.70% {transform: translate3d(-84.77px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		97.93% {transform: translate3d(-169.54px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		98.16% {transform: translate3d(-254.31px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		98.39% {transform: translate3d(-339.09px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		98.62% {transform: translate3d(-423.86px,-0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		98.85% {transform: translate3d(-508.63px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		99.08% {transform: translate3d(-593.40x,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		99.31% {transform: translate3d(-678.18px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		99.54% {transform: translate3d(-762.95px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		99.77% {transform: translate3d(-847.72px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
		99.99% {transform: translate3d(-932.5px,0.00px,0.00px) rotate(0.00deg) scale(1.27,1.27);opacity: 1.00;}
	}
</style>