import dialogVue from './Dialog.vue'

const Dialog = {};

Dialog.install = function(Vue, options) {
	const DialogInstance = Vue.extend(dialogVue);
	let currentDialog;
	const initInstance = () => {
		// 实例化
		currentDialog = new DialogInstance();
		let dialogEl = currentDialog.$mount().$el;
		document.body.appendChild(dialogEl);
	}

	// 原型上添加实例方法
	Vue.prototype.$dialog = {
		alert (options) {
			if (!currentDialog) {
				initInstance();
			}
			// currentDialog.alertTitle = options['title'];
			if (typeof options == 'object') {
				Object.assign(currentDialog.alertOptions, options);
			}
			return currentDialog.alert();
		},
		confirm (options) {
			if (!currentDialog) {
				initInstance();
			}
			if (typeof options == 'object') {
				Object.assign(currentDialog.confirmOptions, options);
			}
			return currentDialog.confirm();
		}
	}
}

export default Dialog