<template>
    <div class="zimu4_con po container" :style="getStyle">
        <template v-for="(txitem, index) in tx">
            <span
                v-if="current_idx == index"
                :key="'tx_item_' + index"
                class="zimu4-zimu"
                :style="getItemStyles">{{txitem}}</span>
        </template>
    </div>
</template>

<script>
	import BaseElem from "@/components/st_comps/st_comp/comps/StElem/BaseElem"
	export default {
        name: 'zimu4',
        extends: BaseElem,
		data () {
			return {
                current_idx: "",
                tx: [],
            }
		},
        mounted () {
            let sec = 0,
                idx = 0,
                _this = this,
                gap = _this.mode['itemStyle'] && _this.mode['itemStyle']['gap']? _this.mode['itemStyle']['gap'] : 4;
            // if (this.mode['tx']) {
            //     let _state = this.$store.state;
            //     for (let i=0; i<this.mode.tx.length; i++) {
            //         let _tx = this.$util.replaceKeyWords(this.mode.tx[i], "author", _state)
            //         this.tx.push(_tx)
            //     }
            // }
            let txs = this.mode && this.mode['tx'] ? this.mode['tx'] : [];
            // 适配组件库
            if (this.comp_config && this.comp_config[0]) {
                if (this.$util.typeOf( this.comp_config[0] ) == "string") {
                    txs = [ this.comp_config[0] ];
                } else if (this.$util.typeOf( this.comp_config[0] ) == "array"){
                    txs = this.comp_config[0];
                }
            }
            let _state = this.$store.state;
            for (let i=0; i<txs.length; i++) {
                let _tx = this.$util.replaceKeyWords(txs[i], "author", _state)
                this.tx.push(_tx)
            }

            setInterval(() => {
                if(sec%gap == 0){
                    _this.current_idx = idx % txs.length;
                    idx = idx + 1;
                }
                sec = sec + 1;
            }, 1000);
        },
        computed: {
            getStyle(){
                let s = {};
                if (this.$store.state.hasUsertext
                    && this.$store.state.hasUsertext != 0
                    && !this.mode['withtext']
                    && (!this.comp_config || !this.comp_config[3])){
                    s = {
                        "display": "none"
                    }
                    return s;
                }
                s = Object.assign(s, this.getStyles)
                s = this.$util.pxTransform(s, "style");
                return s;
            },
            getItemStyles(){
                let s = this.selfGetItemStyle,
                    item = this.mode['itemStyle'];
                if(!item){
                    return s;
                }
                if(item['textShadowColor']){
					s['textShadow'] = "0 0 5px "+ item['textShadowColor'] +", 0 0 10px "+ item['textShadowColor'] +", 0 0 15px "+ item['textShadowColor'] +", 0 0 40px "+ item['textShadowColor'] +", 0 0 70px " + item['textShadowColor'];
                }
                s = this.$util.pxTransform(s, "style");
                return s;
            },
            selfGetItemStyle () {
                let item = this.mode;
                let s = {};
                // 绑定动画
                if (item['itemkeyframe']) {
                    s = Object.assign(s, this.getKeyframeStyle(item['itemkeyframe']));
                }
                if (item['itemStyle']) {
                    s = Object.assign(s, item['itemStyle']);
                }

                // 适配新版
                // 绑定动画
                if (this.comp_config && this.comp_config[1]) {
                    s = Object.assign(s, this.getKeyframeStyle(this.comp_config[1]));
                }
                // 设置style
                if (this.comp_config && this.comp_config[2]) {
                    s = Object.assign(s, this.comp_config[2]);
                }

                return s;
            },
        },
        methods:{
            
        }
	}
</script>

<style>
.zimu4_con{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    justify-content: center;
    overflow: hidden;
}
.zimu4-zimu{
    text-align: center;
    display: block;
}
</style>