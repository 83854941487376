<template>
	<div class="xinbox">
		<div class="xinbox">
			<div class="xinxinbox" :class="{xinxinbox_xuanzhuan: xinboxAniShow}">
				<div class="anidiv anidiv-0" :style="{'transform': anidivTransform_0}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-1" :style="{'transform': anidivTransform_1}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-2" :style="{'transform': anidivTransform_2}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-3" :style="{'transform': anidivTransform_3}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-4" :style="{'transform': anidivTransform_4}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-5" :style="{'transform': anidivTransform_5}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-6" :style="{'transform': anidivTransform_6}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-7" :style="{'transform': anidivTransform_7}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-8" :style="{'transform': anidivTransform_8}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
				<div class="anidiv anidiv-9" :style="{'transform': anidivTransform_9}">
					<img :src="currentImage['im']" @click="wxPreview" />
				</div>
			</div>

		</div>

		<div style="z-index: 1;">
			<div class="aixin_01">
				<div class="rect_1"></div>
				<div class="rect_2"></div>
				<div class="rect_3"></div>
			</div>
			<div class="aixin_02">
				<div class="rect_1"></div>
				<div class="rect_2"></div>
				<div class="rect_3"></div>
			</div>
			<div class="aixin_03">
				<div class="rect_1"></div>
				<div class="rect_2"></div>
				<div class="rect_3"></div>
			</div>
			<div class="aixin_04">
				<div class="rect_1"></div>
				<div class="rect_2"></div>
				<div class="rect_3"></div>
			</div>
			<div class="aixin_05">
				<div class="rect_1"></div>
				<div class="rect_2"></div>
				<div class="rect_3"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'xinbox',
		props: ['mode', 'currentImage'],
		data () {
			return {
				anidivTransform_0 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_1 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_2 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_3 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_4 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_5 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_6 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_7 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_8 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_9 : "rotateY(0deg) translateZ(0px);",
				xinboxAniShow: false
			}
		},
		mounted () {},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			},
			start () {
				setTimeout(() => {
					for(var i=0; i<10; i++) {
						this['anidivTransform_'+i] = 'rotateY('+(i*60)+'deg) translateZ('+(this.$env.bw*.556)+'px)';
					}
					setTimeout(() => {
						this.xinboxAniShow = true;
					}, 300);
				}, 200);
			},
			end () {
				setTimeout(() => {
					for(var i=0; i<10; i++) {
						this['anidivTransform_'+i] = 'rotateY(0deg) translateZ(0px)';
					}
					setTimeout(() => {
						this.xinboxAniShow = false;
					}, 300);
				}, 1000);
			}
		},
	}
</script>

<style scoped lang="less">
	.aixin_01 {
		position: absolute;
		left:@bw * 0.417;
		top: 100%;
		width:@bw * 0.139;
		height:@bw * 0.139;
		opacity: .8;
		transform: translateZ(@bw * 0.278);
		animation: aixin_01 20s infinite -5s linear;
	}
	@-webkit-keyframes aixin_01 {
		100% {
			opacity: 0;
			transform: translateY(-@bw * 2.083) translateX(-@bw * 0.694) scale(.5);
		}
	}
	.aixin_01 .rect_1 {
		top: 0;
		left:@bw * 0.056;
		border-radius: 50%;
	}
	.aixin_01 .rect_2 {
		top: 0;
		right:@bw * 0.056;
		border-radius: 50%;
	}
	.aixin_01 .rect_3 {
		top:@bw * 0.021;
		left:@bw * 0.035;
		transform: rotate(45deg);
	}
	.aixin_01>div {
		position: absolute;
		width: 50%;
		height: 50%;
		background: #fff;
	}

	.aixin_02 {
		position: absolute;
		top: 100%;
		left: 3%;
		width:@bw * 0.083;
		height:@bw * 0.083;
		opacity: .9;
		transform: translateZ(@bw * 2.083);
		animation: aixin_02 20s infinite -5s linear;
	}
	@-webkit-keyframes aixin_02 {
		100% {
			opacity: .5;
			transform: translateY(-@bw * 1.389) translateX(@bw * 0.694) scale(.5);
		}
	}
	.aixin_02 .rect_1 {
		top: 0;
		left:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_02 .rect_2 {
		top: 0;
		right:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_02 .rect_3 {
		top:@bw * 0.014;
		left:@bw * 0.021;
		transform: rotate(45deg);
	}
	.aixin_02>div {
		position: absolute;
		width: 50%;
		height: 50%;
		background: #fff;
	}

	.aixin_03 {
		position: absolute;
		top: 100%;
		left: 40%;
		width:@bw * 0.083;
		height:@bw * 0.083;
		opacity: .9;
		transform: translateZ(@bw * 2.083);
		animation: aixin_03 15s infinite -7s linear;
	}
	@-webkit-keyframes aixin_03 {
		100% {
			opacity: .5;
			transform: translateY(-@bw * 2.083) translateX(@bw * 0.556) scale(1.5);
		}
	}
	.aixin_03 .rect_1 {
		top: 0;
		left:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_03 .rect_2 {
		top: 0;
		right:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_03 .rect_3 {
		top:@bw * 0.014;
		left:@bw * 0.021;
		transform: rotate(45deg);
	}
	.aixin_03>div {
		position: absolute;
		width:@bw * 0.042;
		height:@bw * 0.042;
		background: #fff;
	}

	.aixin_04 {
		position: absolute;
		top: 20%;
		left: 40%;
		width:@bw * 0.083;
		height:@bw * 0.083;
		opacity: .9;
		transform: translateZ(@bw * 2.083);
		animation: aixin_04 10s infinite linear;
	}
	@-webkit-keyframes aixin_04 {
		0% {
			opacity: 0;
		}
		10% {
			opacity: .8;
		}
		100% {
			opacity: .5;
			transform: translateY(-@bw * 0.694) translateX(-@bw * 0.139) scale(.8);
		}
	}
	.aixin_04 .rect_1 {
		top: 0;
		left:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_04 .rect_2 {
		top: 0;
		right:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_04 .rect_3 {
		top:@bw * 0.014;
		left:@bw * 0.021;
		transform: rotate(45deg);
	}
	.aixin_04>div {
		position: absolute;
		width:@bw * 0.042;
		height:@bw * 0.042;
		background: #fff;
	}

	.aixin_05 {
		position: absolute;
		top: 54%;
		left: 30%;
		width:@bw * 0.083;
		height:@bw * 0.083;
		opacity: .9;
		transform: translateZ(@bw * 2.083);
		animation: aixin_05 10s infinite linear;
	}
	@-webkit-keyframes aixin_05 {
		0% {
			opacity: 0;
		}
		10% {
			opacity: .8;
		}
		100% {
			opacity: .5;
			transform: translateY(-@bw * 0.417) translateX(@bw * 0.139) scale(.4);
		}
	}
	.aixin_05 .rect_1 {
		top: 0;
		left:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_05 .rect_2 {
		top: 0;
		right:@bw * 0.007;
		border-radius: 50%;
	}
	.aixin_05 .rect_3 {
		top:@bw * 0.014;
		left:@bw * 0.021;
		transform: rotate(45deg);
	}
	.aixin_05>div {
		position: absolute;
		width:@bw * 0.042;
		height:@bw * 0.042;
		background: #fff;
	}

	.xinbox-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center 20%;
		background-size: cover;
		transform: translateZ(-@bw * 13.889);
	}
	.xinbox {
		position: absolute;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transform: rotateX(0deg) rotateY(0deg) translateZ(-@bw * 1.389);
	}
	.xinxinbox {
		position: absolute;
		left: 26%;
		top:@bw * 0.035;
		width:@bw * 0.417;
		height:@bw * 0.417;
		transform-style: preserve-3d;
		transform: rotateX(0deg) rotateY(0deg);
	}
	.xinxinbox_xuanzhuan {
		animation: xinbox_xuanzhuan 20s infinite linear;
	}
	.xinxinbox>div {
		position: absolute;
		left: 0;
		top: 0;
		width:@bw * 0.625;
		height:@bw * 0.625;
		opacity: .98;
		background-color: hsla(0,0%,100%,.8);
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: 100% 100%;
		-webkit-mask-position: 50% 50%;
	}
	.xinxinbox>div>img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
	}
	.anidiv {
		-webkit-mask-image: url(http://litiresource.xcgogo.site/user/5980171/images/bcbc95daa866f90a00c971f4e2f71c59.png);
	}
	@-webkit-keyframes xinbox_xuanzhuan {
		from {
			transform: rotateY(0deg);
		}
		to {
			transform: rotateY(-1turn);
		}
	}
	.anidiv-0 {
		transition: transform 1s 1s; margin-top:@bw * 0.0;
	}
	.anidiv-1 {
		transition: transform 1s .9s; margin-top:@bw * 0.111;
	}
	.anidiv-2 {
		transition: transform 1s .8s; margin-top:@bw * 0.222;
	}
	.anidiv-3 {
		transition: transform 1s .7s; margin-top:@bw * 0.333;
	}
	.anidiv-4 {
		transition: transform 1s .6s; margin-top:@bw * 0.444;
	}
	.anidiv-5 {
		transition: transform 1s .5s; margin-top:@bw * 0.556;
	}
	.anidiv-6 {
		transition: transform 1s .4s; margin-top:@bw * 0.667;
	}
	.anidiv-7 {
		transition: transform 1s .3s; margin-top:@bw * 0.778;
	}
	.anidiv-8 {
		transition: transform 1s .2s; margin-top:@bw * 0.889;
	}
	.anidiv-9 {
		transition: transform 1s .1s; margin-top:@bw * 1.0;
	}
</style>