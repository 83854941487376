import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import store from './st_comps.store'
import util from './util'
import wxsdk from './wx'
import mta from './mta'
import config from './config'

import Dialog from './components/dialog'
import Toast from './components/toast'
import Audio from './components/vaudio'
import MainLoading from './components/mainloading'
// import Components from './components/themes/components'

// 组件引入
import StComps from "./st_comps.index"
Vue.use(StComps)// 730组件库

// // vconsole
// import vConsole from 'vconsole'
// Vue.prototype.$vConsole= new vConsole()

// ui组件初始化
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Audio)
Vue.use(MainLoading)
// Vue.use(Components)

/* Sentry */
// import * as Sentry from "@sentry/browser";
// import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from '@sentry/tracing';
// if (Math.random() > 0.7) {

// 	Sentry.init({
// 	  	dsn: "https://dd9724840b16418aaee8e25e9d25846c@o441498.ingest.sentry.io/5411737",
// 	  	integrations: [
// 	    	new VueIntegration({
// 	      		Vue,
// 	      		tracing: true
// 			}),
// 	    	new Integrations.BrowserTracing()
// 	  	],
// 	  	tracesSampleRate: 1
// 	});
// }
/* Sentry */

// 测试环境下强制刷新keyframes资源文件
// var kj = document.getElementById('keyframes_js'),
// 	kc = document.getElementById('keyframes_css');

// // if (config.debugHost.indexOf(location.host) != -1) {
// if (['localhost:8081', 'localhost:8080', 'debugstatic.litiskr.cn'].indexOf(location.host) != -1) {
// 	var v = new Date().getTime().toString();

// 	kj.src = 'https://static.litiskr.cn/keyframes.js?v=' + v;
// 	kc.href ='https://static.litiskr.cn/keyframes.css?v=' + v;
// }
//  else {
	
// 	kj.src = 'https://static.litiskr.cn/keyframes.js?v=' + __VERSION__;
// 	kc.href ='https://static.litiskr.cn/keyframes.css?v=' + __VERSION__;
// }

Vue.config.ignoredElements = ['wx-open-launch-weapp','wx-open-audio','wx-open-subscribe']
// 错误收集
Vue.config.errorHandler = function (err, vm, info) {
	try {
		var json = {
	        msg: err.toString(),
	        fileUrl: null,
	        lineNo: null,
	        columnNo: null,
	        error: null,
	        app: 'vliti',
	        // other: {}
	        other: {'url':window.location.href}
	    }
	    if (config.debugHost.indexOf(location.host) != -1) {
	    	console.error(err);
	    }
	    util.rpost('/api/logerror', {
	    	'errormsg': JSON.stringify(json),
	    	'useragent': 'null'
	    }).then(() => {})
	} catch(e) {
		console.error(e);
	}
}

var oldError = window.onerror;
window.onerror = function(msg, fileUrl, lineNo, columnNo, error) {
    var args = Array.prototype.slice.call(arguments);
    if (oldError) {
        oldError.apply(window, args)
    }

    var stack = null;

    if(error && error.stack) stack = error.stack;

    var json = {
        msg: msg || null,
        fileUrl: fileUrl || null,
        lineNo: lineNo || null,
        columnNo: columnNo || null,
        error: stack,
        app: 'vliti',
        // other: {}
        other: {'url':window.location.href}
    }

    if (config.debugHost.indexOf(location.host) != -1) {
    	console.error(msg);
    }

    var userAgent = navigator.userAgent;
    util.rpost('/api/logerror', {
    	'errormsg': JSON.stringify(json),
    	'useragent': userAgent
    }).then(() => {})
}

// 微信sdk初始化
Vue.prototype.$wx = wxsdk;

// 分析初始化
Vue.prototype.$mta = mta;

// 工具函数初始化
Vue.prototype.$o_util = util;

// 全局静态配置
Vue.prototype.$config = config;

// 酷狗音乐对象
try {
	Vue.prototype.$kgPlayer = window.kgPlayer;
	Vue.prototype.$kgPlayer.init2 = function(music) {

		this.__vliti_music__ = music;

		this.player.destroy();

		setTimeout(() => {
	    	this.player.initPlayer({
	            businessId: config.kgPlayer.businessId,
	            loadTime: 5000,//加载超时时长
	            playTime: 5000//播放超时时长
	        });

	        setTimeout(() => {
	            this.player.playSong({
	            	hash: music['hash'],
	            	album_audio_id: parseInt(music['album_audio_id'])
	            });
	            if (util.getMobEnv() == 'ios') {
	                setTimeout(() => {
	                	wx.checkJsApi({
							jsApiList: ["checkJsApi"],
							success: function() {
								this.player.play();
							}
						});
	                }, 500);
	            }
	        }, 300);
		}, 500);

	}
	Vue.prototype.$kgPlayer.getInfo = function() {
		return this.__vliti_music__;
	}
} catch(e) {
	console.warn(e);
}

mta.init();

// 初始化相关配置
Vue.prototype.$env = {};

// 是否是iphonex
Vue.prototype.$env['iphonex'] = /iphone/gi.test(navigator.userAgent) && (screen.height == 812 && screen.width == 375);

// 生产环境
Vue.prototype.$env['env'] = process.env.NODE_ENV;

// 视图基础宽度
Vue.prototype.$env['bw'] = 500;

// 浏览器环境
Vue.prototype.$env['web'] = util.isWebview();

// 获取服务端配置
// util.rget('/config_center/get_app_config', {
// 	"app_name": "vliti"
// }).then((config) => {
// 更改统一到get_config
util.rget("/config_center/get_config", {
	"cid": "app_vliti_config",
	"__app__": "vliti"
}).then(config => {
// /config_center/get_config?cid=app_vliti_config&is_debug=1&_type=ajax&__debug__=true&__app__=vliti
	store.commit('setAppConfig', {'appConfig': config});
	// 初始化界面
	new Vue({
		created () {
			this.$util.$preload.stInit();
			// old util 挂载 $util
			this.$o_util.$util = this.$util;
		},
		router,
		store,
		render: h => h(App)
	}).$mount('#app');
}).catch((err) => {
	util.log('get_td_config_error');
})

// 加载微信sdk环境
function onWXReady () {
	return new Promise((resolve, reject) => {
		if (typeof(wx) == 'undefined') {
			let wx2url = "http://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
		    if (window.location.protocol == 'https:') {
		        wx2url = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
		    }
		    util.loadScript(wx2url).then(() => {
		        resolve();
		    });
		} else {
			resolve();
		}
	})
}

onWXReady().then(() => {
	// 静态页面访问逻辑，ajax请求配置
	if (wxConfigMsg.appId == '{{ wxconfig["appid"] }}') {
		wxsdk.jsload().then(() => {
			wxReadyListener.run();
		}).catch(() => {
			util.log('wxerr_3');
		})
	} else {
	// 动态页面访问逻辑，服务端下发配置
		wxsdk.config();
		wxsdk.ready().then(() => {
			wxReadyListener.run();
		}).catch(() => {
			util.log('wxerr_2');
		})
	}
})



// // 加载ad
// window.TencentGDT = window.TencentGDT || [];

// var ad_config = config.adAble[util.getTopDomain()];
// if (ad_config && window.location.href.indexOf('share') != -1) {

// 	var app_id = ad_config['app_id'];
    
//     window.addEventListener('load', function() {
//         // 等待页面加载完成后执行
//         setTimeout(function() {
//             // 广告示例声明
//             TencentGDT.push({
//                 placement_id: ad_config['placement_ids'][0], // 广告位id，请从联盟平台广告位获取
//                 app_id: app_id, 
//                 type: 'native', // 广告类型
//                 display_type: 'banner', // 广告播放类型: banner广告
//                 carousel: 3000, // 轮播时间，> 3000数字类型，否则轮播会被取消
//                 containerid: 'banner_2_0', // 广告容器
//                 onComplete: function(res) {
//                     if (res.ret == 0) {
//                         console.log('广告播放成功');
//                     } else {
//                         console.log('广告播放失败')
//                     }
//                 }
//             });

//             if ((util.getCookie('isInterShow') === null || util.getCookie('isInterShow') == 1) && window.location.href.indexOf('is_timeline=1') == -1) {
//             	util.setCookie('isInterShow', 0, 1 * 24 * 60 * 60 * 1000);
// 	            // 分享页插屏广告初始化
// 	            TencentGDT.push({
// 	                placement_id: ad_config['placement_ids'][1],
// 	                app_id: app_id,
// 	                type: 'native',
// 	                display_type: 'interstitial', // 播放类型：插屏
// 	                count: 1, //拉取广告的数量，必填，默认是1，最高支持10
// 	                onComplete: function(res) {
// 	                    if (res && res.ret === 0) {
// 	                    	// 插屏生成推迟2s
// 	                    	setTimeout(function() {
// 	                    		 // 调用渲染接口
// 	                    		TencentGDT.NATIVE.renderAd(res.data[0])
// 	                    		// 绑定点击蒙层关闭事件
// 	                    		document.getElementById('gdt_template_interstitial_wrap').children[0].onclick = function() {
// 	                    			if (this.tagName.toLowerCase() == 'div' && this.getAttribute('id').indexOf('gdt_template_native_wrap') != -1) {
// 	                    				document.body.removeChild(document.getElementById('gdt_template_interstitial_wrap'));
// 	                    			}
// 	                    		}
// 	                    	}, 1000);
// 	                    } else {
// 	                        console.log(res.ret);
// 	                    }
// 	                }
// 	            });
//             }

//             // H5 SDK接入全局只需运行一次
//             (function () {
//                 var doc = document,
//                     h = doc.getElementsByTagName('head')[0],
//                     s = doc.createElement('script');
//                 s.async = true;
//                 s.src = '//qzs.qq.com/qzone/biz/res/i.js';
//                 h && h.insertBefore(s, h.firstChild);
//             })();
//         }, 1000);
//     }, false);
// }
