import { render, staticRenderFns } from "./qingming3d.vue?vue&type=template&id=5f7c2f72&scoped=true&"
import script from "./qingming3d.vue?vue&type=script&lang=js&"
export * from "./qingming3d.vue?vue&type=script&lang=js&"
import style0 from "./qingming3d.vue?vue&type=style&index=0&id=5f7c2f72&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7c2f72",
  null
  
)

export default component.exports