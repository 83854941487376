<template>
	<div class="dongxin bg" :style="{backgroundImage: 'url('+ mode[0] +')'}">
	    <!-- <canvas id="bg3_canvas" class="fullScreen po" width="500" height="815"></canvas> -->
	    <div class="dongxin-box po">
	        <div class="dongxin-elastic poc">
	            <div class="dongxin-con poc">
	                <div class="dongxin-div poc">
	                    <div class="dongxin-circular poc" @click="wxPreview">
	                        <div class="dongxin-c_1 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                        <div class="dongxin-c_2 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                        <div class="dongxin-c_3 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                        <div class="dongxin-c_4 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                        <div class="dongxin-c_5 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                        <div class="dongxin-c_6 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                        <div class="dongxin-c_7 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                        <div class="dongxin-c_8 dongxin-c po">
	                            <div class="dongxin-xxin dongxin-div3 poc"></div>
	                            <div class="dongxin-xxin dongxin-div2 poc"></div>
	                            <div class="dongxin-xxin dongxin-div1 poc" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
	                            <!-- <img src="" class="dongxin-border po"> -->
	                        </div>
	                    </div>
	                </div>
	                <div class="dongxin-div_2 poc" @click="wxPreview">
	                    <div class="dongxin-yuan dongxin-yuan1 poc">
	                        <div class="dongxin-yuan_b poc">
	                            <img class="po">
	                        </div>
	                    </div>
	                    <div class="dongxin-yuan dongxin-yuan2 poc">
	                        <div class="dongxin-yuan_b poc">
	                            <img class="po">
	                        </div>
	                    </div>
	                    <div class="dongxin-yuan dongxin-yuan3 poc">
	                        <div class="dongxin-yuan_b bg" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}">
	                            <!-- <img class="po dongxin-yuan_im" :src="currentImage['im']"> -->
	                        </div>
	                    </div>
	                    <img :src="mode[1]" class="dongxin-border_2 po">
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'dongxin',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style scoped lang="less">
	.dongxin {}
	.dongxin-box {
		margin: auto;
		width: 500px;
		height: 500px;
		top: 0;
		bottom: 0;
		-webkit-perspective: 800px;
	}
	.dongxin-elastic {
		-webkit-animation: page3_scl_s 0.3s ease-in-out infinite alternate both;
		-webkit-transform-style: preserve-3d;
	}
	@keyframes page3_scl_s {
		0% {
		    -webkit-transform: translateZ(0px);
		}
		100% {
		    -webkit-transform: translateZ(20px);
		}
	}
	.dongxin-con {
		-webkit-transform: translateZ(50px);
	}
	@keyframes page3_rotZ {
		0% {
		    -webkit-transform: rotateZ(0);
		}
		100% {
		    -webkit-transform: rotateZ(360deg);
		}
	}
	.dongxin-div {
	    animation: 15000ms linear 0s infinite normal none running page3_rotZ;
	}
	.dongxin-xxin {
		-webkit-mask-image: url('http://resali.lititutu.cn/icons/mask/deamon.png');
		-webkit-mask-size: cover;
		overflow: hidden;
		background-size: cover;
		background-position: 50% 10%;
	}
	.dongxin-div1 {}
	.dongxin-border {
		top: -3.5px;
		left: -4px;
	}
	.dongxin-div_2 {}
	.dongxin-yuan {
		margin: auto;
		width: 285px;
		height: 285px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50%;
		-webkit-transform: translateZ(0);
		overflow: hidden;
		background-size: cover;
		background-position: 50% 10%;
	}
	.dongxin-border_2 {
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 340px;
		height: 340px;
	}
	.dongxin-yuan_b {
		border-radius: 50%;
		overflow: hidden;
	}
	.dongxin-yuan_im {
		width: 306.04px;
		height: 285px; 
		animation: 5000ms linear 0s 1 normal both running page3_div1;
	}
	@keyframes page3_div1 {
		0% {
		    -webkit-transform: translate(0, -0px);
		}
		100% {
		    -webkit-transform: translate(-21.04px, 0);
		}
	}
	.dongxin-c {
		margin: auto;
		width: 101px;
		height: 104px;
		top: 146px;
		left: 0;
		right: 0;
		-webkit-transform-origin: 50% 100%;
	}
	.dongxin-c_1 {
		transform: rotate(0deg) translateY(-165px);
	}
	.dongxin-c_2 {
		transform: rotate(45deg) translateY(-165px);
	}
	.dongxin-c_3 {
		transform: rotate(90deg) translateY(-165px);
	}
	.dongxin-c_4 {
		transform: rotate(135deg) translateY(-165px);
	}
	.dongxin-c_5 {
		transform: rotate(180deg) translateY(-165px);
	}
	.dongxin-c_6 {
		transform: rotate(225deg) translateY(-165px);
	}
	.dongxin-c_7 {
		transform: rotate(270deg) translateY(-165px);
	}
	.dongxin-c_8 {
		transform: rotate(315deg) translateY(-165px);
	}
</style>










