<template>
    <component :is="canvasTag" class="gif-canvas po" 
        v-if="isGifShow"
        :type="getCanvasType"
        ref="gifCanvas"
        :canvasId="canvasId" 
        :canvas-id="canvasId"
        :style="mpGifStyle"
        :id="canvasId"></component>
        <!-- :style="mpGifStyle" -->
</template>

<script>
	import BaseComponent from '@/components/st_comps/st_comp/comps/StElem/BaseComponent'
    export default {
        name: 'gif',
        extends: BaseComponent,
        props: ['mode', 'canIShow'],
        data () {
            return {
                defaultWidth: 500,
                defaultHeight: 815,
                aniFrameObj: null,
                isGifShow: true,
                canvasId: ''
            }
        },
        watch: {
            canIShow: {
                handler () {
                    if (this.canIShow === true) {
                        this.isGifShow = true;
                        this.$nextTick(() => {
                            this.initGif();
                        })
                    } else if (this.canIShow === false) {
                        this.isGifShow = false;
                    }
                },
                immediate: true
            }
        },
        computed: {
            getCanvasType () {
                let systemInfo = this.$util.getSystemInfoSync();
                return systemInfo.brand  && systemInfo.brand == "devtools" ? "" : "2d";
            },
            canvasTag () {
                return this.$util.isEnv("wxa") ? "canvas" : "Canvas";
            },
            currentMode () {
                // 处理用style设置百分比的情况
                let width = this.mode['size'] && this.mode['size'][0] || this.mode['style'] && this.mode['style']['width'] || undefined,
                    height = this.mode['size'] && this.mode['size'][1] || this.mode['style'] && this.mode['style']['height'] || undefined;
                
                if (width && height) {
                    if (width.toString().indexOf('%') != -1 && height.toString().indexOf('%') != -1) {
                        let systemInfo = this.$util.getSystemInfoSync();
                        let standWidth = 500,
                            standHeight = (systemInfo['screenHeight'] - systemInfo['navHeight']) / systemInfo['screenWidth'] * standWidth;
                        this.mode['width'] = standWidth * parseInt(width) / 100;
                        this.mode['height'] = standHeight * parseInt(height) / 100;
                    }
                }
                return this.mode;
            },
            mpGifStyle () {
                let s = this.getStyles;
                
                // gif宽高未设置时，使用帧宽高来替代
                if (!s['width']) {
                    s['width'] = this.currentMode['width'] + "px";
                }
                if (!s['height']) {
                    s['height'] = this.currentMode['height'] + "px";
                }
                s = this.$util.pxTransform(s, 'style');

                return s;
            },
        },
        mounted () {
            // 非elem调用则直接执行initGif
            if (this.canIShow === undefined) {
                this.initGif();
            }
        },
        destroyed () {
            window.cancelAnimationFrame(this.aniFrameObj);
        },
        methods: {
            async initGif () {
                if (this.currentMode['sprite_image']) {

                    let tw = this.currentMode['width'],
                        th = this.currentMode['height'],
                        len = this.currentMode['sprite_image']['steps'];

                    // 帧图片
                    if (this.currentMode['sprite_image']['path']) {
                        // 加入style生成canvasId应对同一张图用在不同位置的情况
                        this.canvasId = "gif_" + this._uid;

                        let frames = [], 
                            frame, im, imagekey;
                        for (let i=0; i<len; i++) {
                            
                            im = this.currentMode['sprite_image']['path'].replace('{index}', (i+1).toString());
                            // if (this.$util.ISWXA) {
                            //     im = im.replace("http://", "https://");
                            // }
                            // console.log("帧图片 im > ", im);
                            imagekey = this.$util.md5(im);
                            // console.log("帧图片 md5 > ", imagekey);
                            // console.log("帧图片 imagesObj > ", this.$store.state.imagesObj);
                            frame = this.$store.state.imagesObj[imagekey];
                            // console.log("帧图片 frame > ", frame);

                            frames.push(frame);
                        }
                        this.__painting(frames, tw, th, len, 'frame')
                        
                    // 雪碧图
                    } else {
                        let sp_image = this.currentMode['sprite_image']['im'];
                        // 加入style生成canvasId应对同一张图用在不同位置的情况
                        this.canvasId = "gif_" + this._uid;

                        // 缩放原图至指定大小
                        // if (this.$util.ISWXA) {
                        //     sp_image = sp_image.replace("http://", "https://");
                        // }
                        let image = this.$store.state.imagesObj[ this.$util.md5(sp_image) ];
                        // 部分预加载失败的情况补充处理
                        if (!image) {
                            this.__backup(tw, th, len);
                            return;
                        }

                        this.__painting(image, tw, th, len, 'image')
                    }
                }
            },
            // canvas或者sprite统一的style设置
            __gifStyle (style) {
                // zIndex
                if (this.currentMode['zIndex']) {
                    style['zIndex'] = this.currentMode['zIndex'];
                } else {
                    style['zIndex'] = 'auto';
                }

                if (!this.$util.ISWEB) {

                    // width
                    if (this.currentMode['width']) {
                        style['width'] = this.currentMode['width']+'px';
                    } else {
                        style['width'] = this.defaultWidth + 'px';
                    }
                    // height
                    if (this.currentMode['height']) {
                        style['height'] = this.currentMode['height']+'px';
                    } else {
                        style['height'] = this.defaultHeight + 'px';
                    }
                }

                // size
                if (this.currentMode['size']) {
                    style['width'] = this.$util.getPx(this.currentMode['size'][0]);
                    style['height'] = this.$util.getPx(this.currentMode['size'][1]);
                }

                if (this.currentMode['pos']) {
                    if (this.currentMode['pos'] == "center") {
                        style['top'] = '50%';
                        style['left'] = '50%';
                        style['marginLeft'] = '-' + (parseInt(this.currentMode['width']) / 2) + 'px';
                        style['marginTop'] = '-' + (parseInt(this.currentMode['height']) / 2) + 'px';
                    } else {
                        // top
                        if ('string' == typeof this.currentMode['pos'][0] && this.currentMode['pos'][0].indexOf('%') != -1) {
                            style['top'] = this.currentMode['pos'][0];
                        } else if (this.currentMode['pos'][0] == null) {
                            style['top'] = 'initial';
                        } else {
                            style['top'] = this.currentMode['pos'][0] + 'px';
                        }
                        // right
                        if ('string' == typeof this.currentMode['pos'][1] && this.currentMode['pos'][1].indexOf('%') != -1) {
                            style['right'] = this.currentMode['pos'][1];
                        } else if (this.currentMode['pos'][1] == null) {
                            style['right'] = 'initial';
                        } else {
                            style['right'] = this.currentMode['pos'][1] + 'px';
                        }
                        // bottom
                        if ('string' == typeof this.currentMode['pos'][2] && this.currentMode['pos'][2].indexOf('%') != -1) {
                            style['bottom'] = this.currentMode['pos'][2];
                        } else if (this.currentMode['pos'][2] == null) {
                            style['bottom'] = 'initial';
                        } else {
                            style['bottom'] = this.currentMode['pos'][2] + 'px';
                        }
                        // left
                        if ('string' == typeof this.currentMode['pos'][3] && this.currentMode['pos'][3].indexOf('%') != -1) {
                            style['left'] = this.currentMode['pos'][3];
                        } else if (this.currentMode['pos'][3] == null) {
                            style['left'] = 'initial';
                        } else {
                            style['left'] = this.currentMode['pos'][3] + 'px';
                        }
                    }
                } else {
                    style['top'] = '0px';
                    style['right'] = 'initial';
                    style['bottom'] = 'initial';
                    style['left'] = '0px';
                }

                // 绑定动画
                if (this.currentMode['keyframe']) {
                    style = Object.assign(style, this.$util.getKeyframeStyle(this.currentMode['keyframe']));
                }

                if (this.currentMode['style']) {
                    style = Object.assign(style, this.currentMode['style']);
                }
            },
            __backup (tw, th, len) {
                let that = this;
                let im = new Image();
                im.onload = function() {
                    that.$store.commit('setImagesObj', {'imagesrc': im.src, 'imageobj': im});
                    that.__painting(im, tw, th, len, 'image');
                }
                im.src = this.currentMode['sprite_image']['im'];
            },
            __painting (frames, tw, th, len, mode) {

                // if (this.$util.ISWEB) {
                //     this.__paintingWEB(frames, tw, th, len, mode);
                // } else {
                //     this.__paintingWEAPP(frames, tw, th, len, mode);
                // }
                this.__paintingWEAPP(frames, tw, th, len, mode);
                
            },
            // __paintingWEB (frames, tw, th, len, mode) {
            //     tw = this.$util.cpxTransform(tw);
            //     th = this.$util.cpxTransform(th);
                
            //     // 将帧图片绘制到画布展示
            //     let canvas = this.$refs.gifCanvas;
            //     canvas.width = tw;
            //     canvas.height = th;
            //     let ctx = canvas.getContext("2d");

            //     let bol = true,
            //         stime = new Date().getTime(),
            //         time = this.currentMode['duration'] * 1000,

            //         runtime = this.currentMode['animationIterationCount'] || 0,
            //         runidx = 0,
            //         that = this;

            //     let sw, sh;
            //     if (mode == 'image') {
            //         sw = frames.width;
            //         sh = frames.height / len;
            //     }

            //     let sIndex = this.currentMode['sprite_image'] && this.currentMode['sprite_image']['sIndex'] ? this.currentMode['sprite_image']['sIndex'] : 0;

            //     function animationSet() {
            //         if (bol) {
            //             let c = new Date().getTime() - stime;
            //             let b = parseInt(c / time * len);
            //             b = (b + sIndex) % len // 可以指定从第几帧开始
            //             if (b >= len) {
            //                 runidx ++;
            //                 bol = false;
            //                 // 设置播放次数
            //                 if (runidx == runtime) {
            //                     that.isGifShow = false;
            //                     return;
            //                 }
            //                 stime = new Date().getTime();
            //                 bol = true;
            //             } else {
            //                 if (mode == 'frame') {
            //                     if (frames[b]) {
            //                         ctx.clearRect(0, 0, tw, th);
            //                         try {
            //                             ctx.drawImage(frames[b], 0, 0, Math.round(tw), Math.round(th));
            //                         } catch (e) {
            //                             ctx.drawImage(frames[ (b-1)<0 ? frames.length-1 : b-1 ], 0, 0, Math.round(tw), Math.round(th));
            //                         }
            //                     }
            //                 } else if (mode == 'image') {
            //                     ctx.clearRect(0, 0, Math.round(tw), Math.round(th))    
            //                     ctx.drawImage(frames, 0, b*sh, sw, sh, 0, 0, Math.round(tw), Math.round(th));
            //                 }
            //             }
            //         }
            //         that.aniFrameObj = requestAnimationFrame(animationSet);
            //     }
            //     animationSet();
            // },
            __paintingWEAPP (frames, tw, th, len, mode) {
                // console.log("__paintingWEAPP > ", frames);
                if (this.getCanvasType != "2d") {
                    this.__paintingDEV(frames, tw, th, len, mode);
                    return;
                }

                tw = Math.round(this.$util.cpxTransform(tw));
                th = Math.round(this.$util.cpxTransform(th));

                let that = this;
                // 2d - 获取
                setTimeout(() => {
                // that.$nextTick(() => {
                    that.$util.$taro.createSelectorQuery().select('#' + that.canvasId).node(function(res){
                        let THE_NODE = res.node;
                        let ctx = THE_NODE.getContext('2d');

                        // 恢复绘画比例
                        let dpr = that.$util.getSystemInfoSync()['pixelRatio'];
                        THE_NODE.width = tw * dpr;
                        THE_NODE.height = th * dpr;
                        ctx.scale(dpr, dpr)

                        let bol = true,
                            stime = new Date().getTime(),
                            time = that.currentMode['duration'] * 1000,
                            runtime = that.currentMode['animationIterationCount'] || 'infinite',
                            runidx = 0;
                            
                        let sw, sh;
                        if (mode == 'image') {
                            sw = frames.width;
                            sh = frames.height / len;
                        }

                        let sIndex = that.currentMode['sprite_image'] && that.currentMode['sprite_image']['sIndex'] ? that.currentMode['sprite_image']['sIndex'] : 0;

                        function animationSet() {
                            if (bol) {
                                let c = new Date().getTime() - stime;
                                let b = parseInt(c / time * len);
                                b = (b + sIndex) % len // 可以指定从第几帧开始
                                if (b + 1 >= len) {
                                    runidx ++;
                                    bol = false;
                                    // 设置播放次数
                                    if (runidx == runtime) {
                                        if (that.mode['fillMode'] != 'both') {
                                            that.isGifShow = false;
                                        }
                                        return;
                                    }
                                    stime = new Date().getTime();
                                    bol = true;
                                } else {
                                    const imgObj = THE_NODE.createImage();
                                    // console.log("__paintingWEAPP mode > ", mode);
                                    // console.log("__paintingWEAPP frames > ", frames);
                                    // if (mode == 'image') {
                                        // console.log("__paintingWEAPP image > ", frames.path);
                                    // } else {
                                        // console.log("__paintingWEAPP frame > ", frames[b]);
                                    // }
                                    imgObj.src = mode == 'image' ? frames.path : ( frames[b] ? frames[b].path : "" );
                                    // console.log("__paintingWEAPP src > ", imgObj.src);
                                    // if (!imgObj.src) {
                                    //     console.error("painting WEAPP Frame Error > 请检查图片预加载是否成功！", that.currentMode);
                                    //     return;
                                    // }
                                    imgObj.onload = function (options) {
                                        // console.log("__paintingWEAPP onload > ", options);
                                        ctx.clearRect(0, 0, tw, th)
                                        if (mode == 'image'){
                                            ctx.drawImage(imgObj, 0, Math.round(b*sh), Math.round(sw), Math.round(sh), 0, 0, tw, th);
                                        } else {
                                            ctx.drawImage(imgObj, 0, 0, parseInt(imgObj.width), parseInt(imgObj.height), 0, 0, tw, th)
                                        }
                                    }
                                    imgObj.onerror = function (err) {
                                        console.error("__paintingWEAPP painting WEAPP Image Onload Error > ", this.src, err);
                                    }
                                }
                            }
                            that.aniFrameObj = requestAnimationFrame(animationSet);
                        }
                        animationSet();
                    }).exec()
                // })
                }, 10);
            },
            __paintingDEV (frames, tw, th, len, mode) {
                tw = this.$util.cpxTransform(tw);
                th = this.$util.cpxTransform(th);

                let that = this;
                const ctx = this.$util.$taro.createCanvasContext(this.canvasId);

                let bol = true,
                stime = new Date().getTime(),
                time = that.currentMode['duration'] * 1000,

                runtime = that.currentMode['animationIterationCount'] || 0,
                runidx = 0;

                let sw, sh;
                if (mode == 'image') {
                    sw = frames.width;
                    sh = frames.height / len;
                }

                function animationSet() {
                    if (bol) {
                        let c = new Date().getTime() - stime;
                        let b = parseInt(c / time * len);
                        if (b + 1 >= len) {
                            runidx ++;
                            bol = false;
                            // 设置播放次数
                            if (runidx == runtime) {
                                if (that.mode['fillMode'] != 'both') {
                                    that.isGifShow = false;
                                }
                                return;
                            }
                            stime = new Date().getTime();
                            bol = true;
                        } else {
                            if (mode == 'frame') {
                                if (frames[b]) {
                                    try {
                                        ctx.drawImage(frames[b].path, 0, 0, Math.round(tw), Math.round(th));
                                        ctx.draw(false)
                                    } catch (e) {
                                        ctx.drawImage(frames[ (b-1)<0 ? frames.length-1 : b-1 ].path, 0, 0, Math.round(tw), Math.round(th));
                                        ctx.draw(false)
                                    }
                                }
                            } else if (mode == 'image') {
                                ctx.drawImage(frames.path, 0, Math.round(b*sh), Math.round(sw), Math.round(sh), 0, 0, Math.round(tw), Math.round(th));
                                ctx.draw(false)
                            }
                        }
                    }
                    that.aniFrameObj = requestAnimationFrame(animationSet);
                }
                animationSet();
            },
        }
    }
</script>

<style>
    .gif-canvas {
        pointer-events: none;
    }
    .full-canvas {
        width: 100%!important;
        height: 100%!important;
    }
</style>