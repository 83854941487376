<template>
	<div class="enter poc perspective" :style="enterStyle">
		<template v-for="item in items">
			<component :is="item['name']" :mode="enter[item['id']]" v-if="show[item['id']]"></component>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'enter',
        props: ['mode'],
		data () {
			return {
				items: [],
				show: {},
				second: 0,
				isEnterOut: false
			}
		},
		computed: {
			enter () {
				return this.mode['mode'] && (this.mode['mode']['enter'] || {'timeline': []});
			},
			// 时间轴
			lines () {
				let lines = {}, // 元素时间轴
					items = [], // 元素集合
					show = {}, // 元素显示配置
					tmp, second, item;
				for (let i=0; i<this.enter.timeline.length; i++) {
					tmp = this.enter.timeline[i].split(',');
					second = tmp[0];
					if (!lines[second]) {
						lines[second] = [];
					}

					item = {
						'name': tmp[1].split('_')[0],
						'id': tmp[1]
					};
					show[tmp[1]] = false;
					items.push(item);

					lines[second].push(item);
				}
				this.items = items;
				this.show = show;
				return lines;
			},
			duration () {
				return parseInt(this.enter['duration']);
			},
			enterStyle () {
				let s = {};
				if (this.enter['keyframe'] && this.isEnterOut) {
					s = Object.assign(s, this.$o_util.getKeyframeStyle(this.enter['keyframe']));
				}
				return s;
			}
		},
		created () {
			this.run();
			this.timeEvent = setInterval(() => {
				this.run();
			}, 500);
		},
		destroyed () {
			clearInterval(this.timeEvent);
		},
		methods: {
			run () {
				let line = this.lines[this.second];
				if (line) {
					for (let i=0; i<line.length; i++) {
						this.show[line[i]['id']] = true;
					}
				}

				// 渐隐淡出
				if (this.second == this.duration - this.__getEnterKeyframeDuration()) {
					this.$emit('showCanvas');
					if (this.enter['keyframe']) {
						this.isEnterOut = true;
					}
				}

				if (this.second == this.duration) {
					clearInterval(this.timeEvent);
					this.$emit('endEnter');
					return;
				}

				this.second += 0.5;
			},
			__getEnterKeyframeDuration () {
				let duration = 1;
				if (this.enter['keyframe'] && this.enter['keyframe']['animationDuration']) {
					duration = parseFloat(this.enter['keyframe']['animationDuration']);
				}
				return duration;
			}
		}
	}
</script>

<style>
	.enter {
		z-index: 2;
	}
</style>