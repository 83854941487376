import Babianxing from './Babianxing'
import Huaquanzhuan from './Huaquanzhuan'
import Duolifangzhuan from './Duolifangzhuan/Duolifangzhuan'
import LitiZhuan from './LitiZhuan'
import Blink from './Blink'
import Daoshu from './Daoshu'
import Daoshu2 from './Daoshu2'
import Firefly from './Firefly'
import Firework from './Firework'
import Fish from './Fish'
import Fish2 from './Fish2'
import Flow from './Flow'
import Flower from './Flower'
import Huojian from './Huojian'
import Huojianslow from './Huojianslow'
import Particle from './Particle'
import Sheng from './Sheng'
import Wutai from './Wutai'
import Huaban from './Huaban'
import Snow from './Snow'
import Snow2 from './Snow2'
import Hudie from './Hudie'
import Hudie2 from './Hudie2'
import Hudie3 from './Hudie3'
import Hudie5 from './Hudie5'
import Light from './Light'
import Light2 from './Light2'
import Luopic from './Luopic'
import Luo from './Luo'
import Luo2 from './Luo2'
import Luo3 from './Luo3'
import Luo4 from './Luo4'
import Luo5 from './Luo5'
import Luo6 from './Luo6'
import Luo7 from './Luo7'
import Luo8 from './Luo8'
import Shan from './Shan'
import Shan2 from './Shan2'
import Shan3 from './Shan3'
import Piao from './Piao'
// import Piao2 from './Piao2' // piao2与piao重复
import Piao3 from './Piao3'
import Piao4 from './Piao4'
import Piao5 from './Piao5'
import Piao6 from './Piao6'
import Piao7 from './Piao7'
import Piao8 from './Piao8'
import Zimu from './Zimu'
import Zimu2 from './Zimu2'
import Zimu3 from './Zimu3'
import Zimu4 from './Zimu4'
import Zimu5 from './Zimu5'
import Quanzhuan from './Quanzhuan'
import Litiquanzhuan from './Litiquanzhuan'
import Fangkuai from './Fangkuai/Fangkuai'
import Changlang from './Changlang'
import Denglong from './Denglong'
import Baozha from './Baozha'
import Litihengfei from './Litihengfei'
import Yuanzhuan from './Yuanzhuan'
import Luoxuan from './Luoxuan'
import Dongxin from './Dongxin'
import Xinbox from './Xinbox'
import Lifangti from './Lifangti'
import Fanpai from './Fanpai'
import Guandao from './Guandao'
import Fangzhuan from './Fangzhuan'
import Fanzhuan from './Fanzhuan'
import Manping from './Manping'
import Shuibo from './Shuibo'
import Zhuanyuan from './Zhuanyuan'
import Zhuanta from './Zhuanta'
import Hengfengche from './Hengfengche'
import Zhuan from './Zhuan'
import Fangzoomin from './Fangzoomin'

import Saluo from './Saluo'

export default {
    install: function(Vue){
        Vue.component('Babianxing', Babianxing)
        Vue.component('Huaquanzhuan', Huaquanzhuan)
        Vue.component('Duolifangzhuan', Duolifangzhuan)
        Vue.component('LitiZhuan', LitiZhuan)
        Vue.component('Blink', Blink)
        Vue.component('Daoshu', Daoshu)
        Vue.component('Daoshu2', Daoshu2)
        Vue.component('Firefly', Firefly)
        Vue.component('Firework', Firework)
        Vue.component('Fish', Fish)
        Vue.component('Fish2', Fish2)
        Vue.component('Flow', Flow)
        Vue.component('Flower', Flower)
        Vue.component('Huojian', Huojian)
        Vue.component('Huojianslow', Huojianslow)
        Vue.component('Particle', Particle)
        Vue.component('Sheng', Sheng)
        Vue.component('Wutai', Wutai)
        Vue.component('Huaban', Huaban)

        Vue.component('Snow', Snow)
        Vue.component('Snow2', Snow2)

        Vue.component('Hudie', Hudie)
        Vue.component('Hudie2', Hudie2)
        Vue.component('Hudie3', Hudie3)
        Vue.component('Hudie5', Hudie5)

        Vue.component('Light', Light)
        Vue.component('Light2', Light2)

        Vue.component('Luopic', Luopic)
        Vue.component('Luo', Luo)
        Vue.component('Luo2', Luo2)
        Vue.component('Luo3', Luo3)
        Vue.component('Luo4', Luo4)
        Vue.component('Luo5', Luo5)
        Vue.component('Luo6', Luo6)
        Vue.component('Luo7', Luo7)
        Vue.component('Luo8', Luo8)

        Vue.component('Shan', Shan)
        Vue.component('Shan2', Shan2)
        Vue.component('Shan3', Shan3)

        Vue.component('Piao', Piao)
        // Vue.component('Piao2', Piao2)
        Vue.component('Piao3', Piao3)
        Vue.component('Piao4', Piao4)
        Vue.component('Piao5', Piao5)
        Vue.component('Piao6', Piao6)
        Vue.component('Piao7', Piao7)
        Vue.component('Piao8', Piao8)

        Vue.component('Zimu', Zimu)
        Vue.component('Zimu2', Zimu2)
        Vue.component('Zimu3', Zimu3)
        Vue.component('Zimu4', Zimu4)
        Vue.component('Zimu5', Zimu5)

        Vue.component('Quanzhuan', Quanzhuan)
        Vue.component('Litiquanzhuan', Litiquanzhuan)
        Vue.component('Fangkuai', Fangkuai)
        Vue.component('Changlang', Changlang)
        Vue.component('Denglong', Denglong)
        Vue.component('Baozha', Baozha)
        Vue.component('Litihengfei', Litihengfei)
        Vue.component('Yuanzhuan', Yuanzhuan)
        Vue.component('Luoxuan', Luoxuan)
        Vue.component('Dongxin', Dongxin)
        Vue.component('Xinbox', Xinbox)
        Vue.component('Lifangti', Lifangti)
        Vue.component('Fanpai', Fanpai)
        Vue.component('Guandao', Guandao)
        Vue.component('Fangzhuan', Fangzhuan)
        Vue.component('Fanzhuan', Fanzhuan)
        Vue.component('Manping', Manping)
        Vue.component('Shuibo', Shuibo)
        Vue.component('Zhuanyuan', Zhuanyuan)
        Vue.component('Zhuanta', Zhuanta)
        Vue.component('Hengfengche', Hengfengche)
        Vue.component('Zhuan', Zhuan)
        Vue.component('Fangzoomin', Fangzoomin)

        Vue.component('Saluo', Saluo)
    }
}