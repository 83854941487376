<template>
	<div class="baobao2 page po bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
	    <div class="page po baobao2-car">
	        <img src="http://resali.lititutu.cn/scenes/kuailebaobao/cars.png" class="baobao2-car_im po">
	        <div class="baobao2-div5 po">
	            <div class="baobao2-tremble page po">
	                <div class="baobao2-div page po" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                </div>
	                <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_1_h.png" class="baobao2-border_v po">
	                <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_1_v.png" class="border_h po">  -->
                </div>
	        </div>
	        <div class="baobao2-div6 po">
	            <div class="baobao2-tremble page po">
	                <div class="baobao2-div page po" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                </div>
	                <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_1_h.png" class="baobao2-border_v po">
	                <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_1_v.png" class="border_h po">  -->
                </div>
	        </div>
	        <div class="baobao2-div7 po">
	            <div class="baobao2-tremble page po">
	                <div class="baobao2-div page po" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
	                </div>
	                <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_1_h.png" class="baobao2-border_v po">
	                <!-- <img src="http://resali.lititutu.cn/scenes/kuailebaobao/frame_1_v.png" class="border_h po">  -->
                </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
        name: 'baobao2',
        props: ['mode', 'currentImage', 'showStatus'],
        watch: {
        	showStatus: {
        		handler () {},
        		immediate: true
        	}
        },
        data () {
            return {}
        },
        methods: {
        	wxPreview () {
                this.$emit('wxPreview', this.currentImage);
            }
        }
    }
</script>

<style>
	.baobao2-car {
		-webkit-transform: translateX(-40px);
	    animation: 7s linear 3s 1 normal both running baobao2_car1;
	}
	@keyframes baobao2_car1 {
		0% {
		    transform: translateX(-40px);
		}
		40% {
			transform: translateX(-623px);
		}
		60% {
			transform: translateX(-623px);
		}
		100% {
		    transform: translateX(-1265px);
		}
	}
	.baobao2-car_im {
		bottom: 50px;
	}
	.baobao2-div5 {
		-webkit-transform-origin: 50% 100%;
		-webkit-transform: scale(0.9);
		left: 90px;
		bottom: 210px;
		width: 402px;
		height: 502px;
	}
	.baobao2-tremble {
		-webkit-transform-origin: 50% 100%;
		-webkit-animation: baobao2_tremble 1.5s ease-in-out infinite both;
	}
	@keyframes baobao2_tremble {
		0% {
		    -webkit-transform: scaleY(0.97);
		}
		13.333% {
		    -webkit-transform: scaleY(1.05);
		}
		26.666% {
		    -webkit-transform: scaleY(0.985);
		}
		40% {
		    -webkit-transform: scaleY(1);
		}
		86.666% {
		    -webkit-transform: scaleY(1);
		}
		100% {
		    -webkit-transform: scaleY(0.97);
		}
	}
	.baobao2-div {
		background-size: cover;
		background-position: 50% 10%;
		border-radius: 30px;
		overflow: hidden;
	}
	.baobao2-border_v {
		top: -132px;
		left: -36px;
	}

	.baobao2-div6 {
		-webkit-transform-origin: 50% 100%;
		-webkit-transform: scale(0.9);
		left: 670px;
		bottom: 210px;
		width: 402px;
		height: 502px;
	}

	.baobao2-div7 {
		 -webkit-transform-origin: 50% 100%; 
		-webkit-transform: scale(0.9);
		left: 1320px;
		bottom: 210px;
		width: 402px;
		height: 502px;
	}
</style>