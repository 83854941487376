<template>
	<div class="yuanzhuan poc" :style="elemStyles">
	    <div class="perspective poc">
	        <div class="yuanzhuan_1 preserve3d poc">
                <elem v-if="comp['elems'] && comp['elems'][0]" :mode="comp['elems'][0]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
				<elem v-if="config[6]" :mode="config[6]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
	            <div class="yuanzhuan_1_1 preserve3d poc" :style="getYuanZhuanStyle">
	                <div class="preserve3d poc"
                        v-for="idx of 4"
                        :key="'yuanzhuan_item' + idx"
                        :class="'yuanzhuan_item' + idx">
	                    <div class="yuanzhuan_item preserve3d po" :style="getYuanZhuanPhotoStyle">
	                        <div class="yuanzhuan_A yuanzhuan_div poc bg" :style="getCss">
                                <template v-if="config[3]">
                                    <img class="po"
                                        v-for="index of 4"
                                        :key="'yuanzhuan_flower' + index"
                                        :class="'yuanzhuan_flower' + index"
                                        :src="config[3]" />
                                </template>
	                        </div>
	                        <div class="yuanzhuan_F yuanzhuan_div poc bg" :style="getCss">
	                            <template v-if="config[3]">
                                    <img class="po"
                                        v-for="index of 4"
                                        :key="'yuanzhuan_flower' + index"
                                        :class="'yuanzhuan_flower' + index"
                                        :src="config[3]" />
                                </template>
	                        </div>
	                        <div class="yuanzhuan_L yuanzhuan_l po" :style="getCss2"></div>
	                        <div class="yuanzhuan_R yuanzhuan_r po" :style="getCss3"></div>
							<elem v-if="comp['elems'] && comp['elems'][1]" :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
							<div class="yuanzhuan_elem_item">
								<elem v-if="config[7]" :mode="config[7]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
							</div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
		name: 'yuanzhuan',
        extends: BaseElem,
		computed: {
			config () {
				return this.comp['config'] || [];
			},
            getYuanZhuanStyle () {
				let css = {};
                css['animationDuration'] = (this.config[4]||12) + "s";
                css['animationDirection'] = this.config[5] ? 'reverse' : "normal";
				return css;
			},
			getYuanZhuanPhotoStyle () {
				if (this.currentPhoto && this.currentPhoto['orisize']) {
					let tsize = this.$util.getSizeInArea(this.currentPhoto['orisize'], [500, 800], 'widthFix');

					return {
						width: tsize[0]+'px',
						height: tsize[1]+'px',
						marginTop: (0-(tsize[1]/2))+'px'
					}
				} else {
					return {
						width: '500px',
						height: '375px',
						marginTop: '-187.5px'
					}
				}
			},
			getCss () {
				let css = {};
				css['backgroundImage'] = 'url('+this.myCurrentPhoto+')';
				if (this.comp['config'][0]) {
					css['borderImageSource'] = 'url('+this.comp['config'][0]+')';
				} else {
					css['borderImageSource'] = 'url(http://resali.lititutu.cn/icons/other/border.jpg)';
				}
				return css;
			},
			getCss2 () {
				let css = {};
				if (this.comp['config'][1]) {
					css['backgroundImage'] = 'url('+this.comp['config'][1]+')';
				} else {
					css['backgroundImage'] = 'url(http://resali.lititutu.cn/icons/other/borderL.png)';
				}
				return css;
			},
			getCss3 () {
				let css = {};
				if (this.comp['config'][2]) {
					css['backgroundImage'] = 'url('+this.comp['config'][2]+')';
				} else {
					css['backgroundImage'] = 'url(http://resali.lititutu.cn/icons/other/borderR.png)';
				}
				return css;
			},
		},
		methods: {
		}
	}
</script>

<style>
	.yuanzhuan_1 {
	    -webkit-transform: translateZ(-900px) translateY(0px);
	}
    .yuanzhuan_1_1 {
        -webkit-animation: rotate_360 linear infinite both;
    }
	.yuanzhuan_item1, .yuanzhuan_item2, .yuanzhuan_item3, .yuanzhuan_item4 {
	    -webkit-transform-origin: right;
	}
	.yuanzhuan_item1 {
	    -webkit-transform: translateX(-260px) rotateY(0deg);
	}
	.yuanzhuan_item {
	    margin: auto;
	    top: 50%;
	    left: 0;
	    right: 0;
	    -webkit-transform: rotateY(90deg) translateZ(680px);
	    background-color: #eee;
	}
	.yuanzhuan_div {
	    border: 6px solid;
	    border-image-slice: 6;
	    background-size: cover;
	    background-clip: content-box;
	    transition: all .5s both;
	    background-color: #eee;
	}
	.yuanzhuan_A {
	    -webkit-transform: translateZ(-10px);
	}
	.yuanzhuan_flower1, .yuanzhuan_flower2, .yuanzhuan_flower3, .yuanzhuan_flower4 {
		width: 150px;
	}
	.yuanzhuan_flower1 {
	    -webkit-transform: rotateX(180deg);
	    top: -50px;
	    left: -50px;
	}
	.yuanzhuan_flower2 {
	    -webkit-transform: rotateZ(180deg);
	    top: -50px;
	    right: -50px;
	}
	.yuanzhuan_flower3 {
	    bottom: -50px;
	    left: -50px;
	}
	.yuanzhuan_flower4 {
	    -webkit-transform: rotateY(180deg);
	    bottom: -50px;
	    right: -50px;
	}
	.yuanzhuan_F {
	    -webkit-transform: translateZ(10px);
	}
	.yuanzhuan_L {
	    -webkit-transform: rotateY(90deg);
	}
	.yuanzhuan_l {
	    left: -10px;
	}
	.yuanzhuan_l, .yuanzhuan_r {
	    width: 22px;
	    height: 102%;
	    background-size: auto 100%;
	}
	.yuanzhuan_R {
	    -webkit-transform: rotateY(-90deg);
	}
	.yuanzhuan_r {
	    right: -22px;
	}
	.yuanzhuan_item2 {
	    -webkit-transform: translateX(-260px) rotateY(90deg);
	}
	.yuanzhuan_item3 {
	    -webkit-transform: translateX(-260px) rotateY(180deg);
	}
	.yuanzhuan_item4 {
	    -webkit-transform: translateX(-260px) rotateY(270deg);
	}
	.yuanzhuan .perspective {
	    -webkit-perspective: 1000px!important;
	}
	.yuanzhuan_elem_item {
		transform: translateZ(10px);
	}
</style>