import axios from 'axios'
import weui from 'weui.js'
import 'weui'

class stTaro {
    constructor (util) {
        this.$util = util;
        
        // 加载弹窗单例对象
        this.loadingEvent = null;

        // 消息中心
        this.eventCenter = {
            on (eventName, cb) {
                // console.log("1 on > ", eventName, cb);
                let line = util.$global.get("h5EventCenterLine", {});
                let eventline = line[eventName] || [];
                eventline.push(cb);
                line[eventName] = eventline;
                util.$global.set("h5EventCenterLine", line);
                // console.log("2 on > ", line);
            },
            trigger (eventName, arg) {
                let line = util.$global.get("h5EventCenterLine", {});
                let eventline = line[eventName] || [];
                for (let i=0; i<eventline.length; i++) {
                    eventline[i](); 
                }
                // console.log("trigger > ", eventName, arg, eventline);
            },
            off (eventName, cb) {
                // console.log("off > ", eventName, cb)
            }
        }
    }
    // 获取当前环境，h5中直接返回WEB
    getEnv (mode) {
        // 这个方法只有vue版本会读取，因此getEnv的mode参数为isVue时直接返回true
        if (mode == "isVue") {
            return true;
        } else if (mode == "isTaro") {
            return false;
        }
        return "WEB";
    }
    // 本地存储系列方法
    getStorage (option) {
        let value = this.getStorageSync(option['key']);
        option['success'] && option['success']({'data': value});    
    }
    getStorageSync (key) {
        if (!key) {
            return '';
        }
        let value = localStorage.getItem(key);
        value = value ? JSON.parse(value)['data'] : '';
        return value;
    }
    setStorage (option) {
        option = option || {};
        let key = option['key'],
            data = option['data'];
        if (!(key && data)) {
            console.warn("【st_taro错误】setStorage参数异常", option);
            return;
        }
        this.setStorageSync(key, data);
    }
    setStorageSync (key, data) {
        let value = localStorage.getItem(key);
        value = value ? JSON.parse(value) : {};
        value['data'] = data;
        localStorage.setItem(key, JSON.stringify(value));
    }
    getStorageInfoSync () {
        return {
            keys: [],
            currentSize: 5,
            limitSize: 5 * 1024
        }
    }
    pxTransform (size, stand_size) {
        size = size.indexOf("px") == -1 ? size + "px" : size;
        return size;
    }
    getCurrentInstance () { // 获取浏览器参数
        return {
            'router': {
                'path': window.location.pathname + window.location.search,
                'params': this.$util.getUrlQuerys()
            },
        }
    }
    uploadFile (options) {
        options = options || {};
        let {
            url,
            filePath,
            name,
            formData,
            success,
            fail
        } = options;
        // base64 转为二进制字节流 => 会有文件破损的问题
        // => 传入文件base64 和文件名合成 file 对象
        function base64ToFile (base64Image, fileName) {
            const arr = base64Image.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            if (!fileName){
                let sub_fix = mime.toLowerCase().replace("image/","")
                fileName = ('img' + Date.now() + '.' + sub_fix);
            }
            return new File([u8arr], fileName, { type: mime })
        }
        function createFormData (file, body, name) {
            let _data = new FormData()
            Object.keys(body).forEach(key => {
                _data.append(key, body[key])
            })
            _data.append(name, base64ToFile(file))
            return _data
        }
        axios.post(url, 
            createFormData(filePath, formData, name),
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        ).then(res => {
            if (res && res.status && res.status == 200){
                success && success(res)
            } else {
                fail && fail()    
            }
        }).catch(err => {
            fail && fail(err)
        });
    }
    showLoading (options) {
        let obj = document.querySelector('.weui-toast__content');
        // 避免重复生成对象，仅修改内容
        if (obj) {
            obj.innerText = options['title'];
        } else {
            this.loadingEvent = weui.loading(options['title'], {
                // className: 'custom-classname'
            })
        }
    }
    hideLoading () {
        this.loadingEvent.hide();
    }
    showToast (options) {
        let title = options['title'],
            duration = options['duration'] || 3000;
        let className = '';
        if (options['icon'] != 'success') {
            className = 'my-weui-toast_noicon';
        }
        weui.toast(title, {
            duration: duration,
            className: className
        });
    }
    showModal (options) {
        weui.confirm(options['content'], {
            title: options['title'],
            buttons: [{
                label: "取消",
                type: 'default',
                onClick () {
                    options['success'] && options['success']({
                        confirm: 0,
                        cancel: 1
                    })
                }
            },{
                label: '确定',
                type: 'primary',
                onClick () {
                    options['success'] && options['success']({
                        confirm: 1,
                        cancel: 0
                    })
                }
            }]
        })
    }
    getSystemInfoSync () {
        let screenHeight = parseInt(document.body.clientWidth / window.screen.width * window.screen.height);
        return {
            windowWidth: document.body.clientWidth,
            windowHeight: document.body.clientHeight || screenHeight,
            screenWidth: document.body.clientWidth,
            screenHeight: screenHeight,
            safeArea: {
                width: 0,
                height: 0,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            }
        }
    }
    // 获取音频对象
    createInnerAudioContext () {
        function myAudio () {
            this.audio = new Audio();
            this.loop = true;
            this.autoplay = true;
            this.src = "";
            this.currentTime = 0;
            
            let that = this;
            
            this.onTimeUpdate = (cb) => {
                this.audio.addEventListener("timeupdate", function() {
                    that.currentTime = this.currentTime;
                    cb && cb();
                });
            }

            this.onCanplay = (cb) => {
                this.audio.addEventListener("canplay", cb);
            }

            this.pause = () => {
                this.audio.pause();
            }

            this.play = () => {
                this.initOptions();
                this.audio.play();
            }

            this.stop = () => {
                this.audio.pause();
                this.audio.currentTime = 0;
            }

            this.setCurrentTime = (time) => {
                this.audio.currentTime = time;
            }
            this.seek = (time) => {
                this.audio.currentTime = time;
            }

            this.isPaused = () => {
                return this.audio.paused;
            }

            this.initOptions = () => {
                if (this.audio.src != this.src)
                    this.audio.src = this.src;
                if (this.audio.loop != this.loop)
                    this.audio.loop = this.loop;
                if (this.audio.autoplay != this.autoplay)
                    this.audio.autoplay = this.autoplay;
            }
        }
        return new myAudio();
    }
    // 设置页面标题
    setNavigationBarTitle (option) {
        document.title = option['title'];
    }
    getMiniProgramInfoSync () {
        return {};
    }
    onPageNotFound (cb) {
        // h5无需调用此方法，跳过
    }
    getImageInfo (options) {
        let {
            src,
            success,
            fail
        } = options;
        let im = new Image();
        im.onload = function () {
            success && success(im);
        };
        im.onerror = function (err) {
            fail && fail(err);
        };
        im.src = src;
    }
}

export default {
    stTaro
}