<template>
    <div class="st-button-group" :id="_getStid" v-show="configLoop < 2">
        <slot name="default"></slot>
        <template v-for="(_config, idx) in config">
            <self-button class="st-button-group-btn"
                v-show="canShowIndex == idx"
                :key="_getStid + '_st_button_' + idx"
                :config="_config"
                :idx="idx"
                :envDatas="envDatas"
                :onFunc="onFunc"
                @onCallBack="onCallBack"
                @onShowNextButton="onShowNextButton"
                @onBtnShow="onBtnShow">
                <slot name="toast"></slot>
            </self-button>
        </template>
    </div>
</template>

<script>
    import StBaseComp from "../StBaseComp";
    import SelfButton from "./SelfButton"
    export default {
        name: "st_button_group",
        extends: StBaseComp,
        components: {
            SelfButton
        },
        props: {
            config: {
                type: Array,
                default: []
            },
            envDatas: {
                type: Object,
                default: ()=>{
                    return {}
                }
            },
            onFunc: {
                type: Function
            },
        },
        data () {
            return {
                canShowIndex: 0,
                configLoop: 0,
                slotNum: 0
            }
        },
        mounted () {
            this.slotNum = !this.$util.isEnv('wxa') && this.$slots && this.$slots.default ? 1 : 0;
            this.setSize();
        },
        methods: {
            onCallBack (options) {
                let _this = this;
                if (options && options.idx) {
                    _this.canShowIndex = options.idx;
                }
                this.showNextBtn().then(()=>{
                    if( _this.configLoop > 0 || options.idx >= (_this.config.length - 1) ) { // 最后一个button响应 or 按钮已经经过循环 callback
                        _this.$emit("onCallBack", options);
                        _this.configLoop = 0;
                    } else { // 传递到下一个btn点击事件
                        console.log("=>// 传递到下一个btn点击事件",_this.canShowIndex)
                        let next_btn = _this._child[_this.canShowIndex + _this.slotNum];
                        next_btn && next_btn.onClick({
                            "auto": 1
                        });
                    }
                }).catch(err=>{
                    console.error("err=>",err)
                })
                
            },
            async showNextBtn (options) {
                let {
                    action
                } = options || {};

                if (this.configLoop > 2){ // 防止 death loop
                    this.$util.$loger.warn("防止 death loop=>")
                    return;
                }
                this.setSize();
                this.canShowIndex = (this.canShowIndex + 1) % this.config.length;

                if (this.canShowIndex == 0 && action == "auto") {
                    this.configLoop = this.configLoop + 1; // 经过了一轮循环
                }
                let current_child = this._child[this.canShowIndex + this.slotNum];
                if (current_child && current_child['condition']) {
                    current_child.btnDidShow(); //触发显示
                } else {
                    if (current_child && current_child.getCondition) {
                        await current_child.getCondition().catch(err=>{
                            debugger;
                        });
                    }
                    if (current_child && current_child['condition']) {
                        current_child.btnDidShow(); //触发显示
                    } else {
                        // 递归 - 自动显示下一个按钮
                        await this.showNextBtn({
                            "action": "auto"
                        }).catch(err=>{
                            console.error("err=>",err)
                            debugger;
                        })
                    }
                }
                return Promise.resolve();
            },
            onShowNextButton (idx) {
                if (idx == this.canShowIndex){
                    this.showNextBtn({
                        "action": "auto"
                    }).catch(err=>{
                        console.error("err=>",err)
                        // debugger;
                    })
                }
            },
            onBtnShow (options) {
                this.$emit("onBtnShow", options);
            },
            setSize () {
                if (this.$util.isEnv("wxa")){
                    return;
                }
                let _dom = document.getElementById(this._getStid);
                // 可能存在_dom为null的情况，是否需要处理？byhz 2022.07.16
                let width = _dom.offsetWidth;
                let height = _dom.offsetHeight;
                let child = this._child[this.canShowIndex + this.slotNum];
                child && child.setElSize && child.setElSize([width, height]);
            }
        },
    }
</script>

<style>
/* ,.st-button-group */
.st-button-group-btn{
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    border: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
</style>