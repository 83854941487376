<template>
    <div class="luoxuan_box">
        <div class="luoxuan_bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
            <!-- xinnianyouyu装饰 -->
            <template v-if="'xinnianyouyu' == currentTid">
	            <img class="wenzituan" alt="" src="http://resali.lititutu.cn/scenes/xinnianyouyu/21.png">
	            <div class="aniBox">
	                <img class="po ani1 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani2 piao2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/23.png">
	                <img class="po ani3 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani4 piao2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/23.png">
	                <img class="po ani5 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani6 piao2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/23.png">
	                <img class="po ani7 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani8 piao2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/23.png">
	                <img class="po ani9 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani10 piao2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/23.png">
	                <img class="po ani11 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani12 piao2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/23.png">
	                <img class="po ani13 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani14 piao2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/23.png">
	                <img class="po ani15 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	                <img class="po ani16 piao1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/22.png">
	            </div>
	            <img class="denglong1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/24.png">
	            <img class="denglong2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/25.png">
	            <img class="denglong3" src="http://resali.lititutu.cn/scenes/xinnianyouyu/26.png">
	            <img class="denglong4" src="http://resali.lititutu.cn/scenes/xinnianyouyu/27.png">
	            <img class="denglong_left" src="http://resali.lititutu.cn/scenes/xinnianyouyu/28.png">
	            <div class="fish_div">
	                <img class="fishxu2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/29.png">
	                <img class="fish" src="http://resali.lititutu.cn/scenes/xinnianyouyu/30.png">
	                <img class="fishxu1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/31.png">
	                <img class="yulin" src="http://resali.lititutu.cn/scenes/xinnianyouyu/32.png">
	            </div>
	            <img class="yun2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/33.png">
	            <img class="yun1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/34.png">
	            <img class="hehua1" src="http://resali.lititutu.cn/scenes/xinnianyouyu/35.png">
	            <img class="hehua2" src="http://resali.lititutu.cn/scenes/xinnianyouyu/36.png">
	            <img class="lefttext" src="http://resali.lititutu.cn/scenes/xinnianyouyu/37.png">
            </template>
            <!-- zuixuanguangchangwu -->
            <template v-if="'zuixuanguangchangwu' == currentTid">
            	<div class="luoxuan_bot" style="background-image: url(http://resali.lititutu.cn/scenes/zuixuanguangchangwu/02.png)"></div>
            </template>
            <div class="userImg userImgani1 bg bg_photo" :style="getStyle" @click="wxPreview">
            	<img class="po page" :src="mode[2]" v-if="mode.length > 2">
            </div>
        	
        	<div class="luoxuan_bot" v-if="mode.length > 1" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'luoxuan',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
		computed: {
			currentTid () {
				return this.$store.state.config.data['ttid'];
			},
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [450, 600], 'both');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px',
						marginLeft: (0-(tsize[0]/2)) + 'px',
						marginTop: (0-(tsize[1]/2)) + 'px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				} else {
					return {
						width: '348px',
						height: '260px',
						marginLeft: '-174px',
						marginTop: '-130px',
						backgroundImage: 'url('+ this.currentImage['im'] +')'
					}
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.ani1 {
	    width:@bw * 0.075;
	    left:@bw * 0.418;
	    animation: luo4 13.2s linear -9.2s infinite both;
	}
	.ani2 {
	    width:@bw * 0.069;
	    left:@bw * 0.456;
	    animation: luo4 13.6s linear -7.7s infinite both;
	}
	.ani3 {
	    width:@bw * 0.115;
	    left:@bw * 0.388;
	    animation: luo4 13.5s linear -5.7s infinite both;
	}
	.ani4 {
	    width:@bw * 0.068;
	    left:@bw * 0.388;
	    animation: luo3 12.8s linear -8.8s infinite both;
	}
	.ani5 {
	    width:@bw * 0.086;
	    left:@bw * 0.057;
	    animation: luo3 12.9s linear -4.5s infinite both;
	}
	.ani6 {
	    width:@bw * 0.065;
	    left:@bw * 0.089;
	    animation: luo4 13.3s linear -11.5s infinite both;
	}
	.ani7 {
	    width:@bw * 0.071;
	    left:@bw * 0.535;
	    animation: luo3 12.4s linear -9.3s infinite both;
	}
	.ani8 {
	    width:@bw * 0.083;
	    left:@bw * 0.179;
	    animation: luo4 13.3s linear -8.9s infinite both;
	}
	.ani9 {
	    width:@bw * 0.078;
	    left:@bw * 0.208;
	    animation: luo2 10.3s linear -8.9s infinite both;
	}
	.ani10 {
	    width:@bw * 0.075;
	    left:@bw * 0.232;
	    animation: luo1 14.3s linear -8.9s infinite both;
	}
	.ani11 {
	    width:@bw * 0.071;
	    left:@bw * 0.26;
	    animation: luo1 11.3s linear -11.9s infinite both;
	}
	.ani12 {
	    width:@bw * 0.083;
	    left:@bw * 0.278;
	    animation: luo5 8.3s linear -11.9s infinite both;
	}
	.ani13 {
	    width:@bw * 0.074;
	    left:@bw * 0.208;
	    animation: luo5 10.3s linear -11.9s infinite both;
	}
	.ani14 {
	    width:@bw * 0.078;
	    left:@bw * 0.181;
	    animation: luo5 8.3s linear -10.9s infinite both;
	}
	.ani15 {
	    width:@bw * 0.088;
	    left:@bw * 0.212;
	    animation: luo5 12.3s linear -8.9s infinite both;
	}
	.ani16 {
	    width:@bw * 0.067;
	    left:@bw * 0.236;
	    animation: luo5 9.3s linear -13.9s infinite both;
	}
	.luo {
	    width: 100%;
	    height: 100%;
	}
	@keyframes luo1 {
	    0% {
	        transform:translate(0, -100%) rotateZ(0);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(@bw * 0.347,@bw * 1.403) rotateZ(360deg);
	        opacity:0;
	    }
	}
	@keyframes luo5 {
	    0% {
	        transform:translate(0, -100%) rotateZ(0);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(@bw * 0.347,@bw * 1.25) rotateZ(360deg);
	        opacity:0;
	    }
	}
	@keyframes luo2 {
	    0% {
	        transform:translate(0, -100%) rotateZ(60deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(@bw * 0.528,@bw * 1.375) rotateZ(420deg);
	        opacity:0;
	    }
	}
	@keyframes luo3 {
	    0% {
	        transform:translate(0, -100%) rotateZ(-60deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(@bw * 0.264,@bw * 1.403) rotateZ(300deg);
	        opacity:0;
	    }
	}
	@keyframes luo4 {
	    0% {
	        transform:translate(0, -100%) rotateZ(20deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(-@bw * 0.347,@bw * 1.403) rotateZ(-340deg);
	        opacity:0;
	    }
	}
	.luoxuan_box, .luoxuan_bg {
	    position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    top: 0;
	}
	.luoxuan_bg {
	    background: no-repeat top center;
	    background-size: cover;
	}
	.userImg {
	    position: absolute;
	    left: 50%;
	    top: 50%;
	}
	.userImgani1 {
	    animation: userImgani1 3s linear infinite
	}
	@keyframes userImgani1 {
	    0% {
	        transform: translate3d(0, 0,@bw * 0.0) scale(0.1) rotateZ(0deg)
	    }
	    10% {
	        transform: translate3d(0, 0,@bw * 0.0) scale(0.6) rotateZ(360deg)
	    }
	    20% {
	        transform: translate3d(0, 0,@bw * 0.0) scale(1) rotateZ(720deg)
	    }
	    30% {
	        transform: translate3d(0, 0,@bw * 0.0) scale(1) rotateZ(1030deg)
	    }
	    100% {
	        transform: translate3d(0, 0,@bw * 0.0) scale(1) rotateZ(1110deg)
	    }
	}
	.wenzituan {
	    /* width:@bw * 0.597; */
	    width:@bw * 0.597;
	    /* height:@bw * 0.597; */
	    position: absolute;
	    top: 6%;
	    left: 24%;
	    animation: wenzituan 4s linear infinite;
	    background: no-repeat center;
	    background-size: cover;
	    box-shadow:@bw * 0.007@bw * 0.007@bw * 0.021@bw * 0.035 #00000028;
	    border-radius: 50%;
	}
	@keyframes wenzituan {
	    0% {
	        transform: rotate(0deg)
	    }
	    100% {
	        transform: rotate(-360deg)
	    }
	}
	.denglong1 {
	    position: absolute;
	    top: -@bw * 0.014;
	    left: -@bw * 0.015;
	}
	.denglong2 {
	    position: absolute;
	    top: -@bw * 0.014;
		left: -50px;
		width: 600px;
	    animation: denglong2 1.5s ease-in-out infinite alternate;
	    transform-origin: 50% 0%;
	}
	.denglong3 {
	    position: absolute;
	    top: -7%;
	    left:@bw * 0.292;
	    animation: denglong3 1.45s ease-in-out infinite alternate;
	    transform-origin: 50% 0%;
	}
	@keyframes denglong2 {
	    0% {
	        transform: rotate(-5deg);
	    }
	    100% {
	        transform: rotate(4deg);
	    }
	}
	@keyframes denglong3 {
	    0% {
	        transform: rotate(-5deg);
	    }
	    100% {
	        transform: rotate(3deg);
	    }
	}
	.denglong4 {
	    position: absolute;
	    top: -24%;
	    left: -@bw * 0.056;
	    animation: denglong4 1.45s ease-in-out infinite alternate;
	    transform-origin: 50% 0%;
	}
	@keyframes denglong4 {
	    0% {
	        transform: rotate(-5deg);
	    }
	    100% {
	        transform: rotate(3deg);
	    }
	}
	.lefttext {
	    position: absolute;
	    top: 21%;
		width: 140px;
		left: 12px;
	    animation: tranforLeft 1.45s ease-in-out infinite alternate;
	    transform-origin: 50% 0%;
	}
	.denglong_left {
	    position: absolute;
	    top: 23%;
	    left:@bw * 0.042;
	}
	.hehua1 {
	    position: absolute;
	    bottom:@bw * 0.25;
	    right: -@bw * 0.097;
	    transform-origin: 100% 100%;
	    animation: hehua1 1.45s ease-in-out infinite alternate;
	}
	.hehua2 {
	    position: absolute;
	    bottom: -@bw * 0.019;
	    right: -82.5px;
	}
	.yun1 {
	    position: absolute;
	    bottom:@bw * 0.0;
	    right:@bw * 0.208;
	    background-repeat: repeat-x;
	    background-position: bottom;
	    animation: yun1 1.5s linear infinite alternate;
	}
	.yun2 {
	    position: absolute;
	    bottom:@bw * 0.0;
	    left: -@bw * 0.049;
	    background-repeat: repeat-x;
	    background-position: bottom;
	    animation: yun1 1.65s -234ms linear infinite alternate;
	}
	.fish {
	    position: absolute;
	    bottom:@bw * 0.0;
	    left: -@bw * 0.014;
	}
	.fishxu1 {
	    position: absolute;
	    bottom:@bw * 0.258;
	    left:@bw * 0.417;
	    transform-origin: 0% 100%;
	    animation: fishxu1 1.45s ease-in-out infinite alternate;
	}
	.fishxu2 {
	    position: absolute;
	    bottom:@bw * 0.339;
	    left:@bw * 0.326;
	    animation: fishxu2 1.45s ease-in-out infinite alternate;
	}
	.yulin {
	    position: absolute;
	    bottom:@bw * 0.008;
	    left:@bw * 0.125;
	    transform-origin: 100% 0%;
	    animation: yulin 1.25s ease-in-out infinite alternate;
	}
	@keyframes tranforLeft {
	    0% {
	        transform: translateY(-@bw * 0.014);
	    }
	    100% {
	        transform: translateY(@bw * 0.028);
	    }
	}
	@keyframes yun1 {
	    0% {
	        transform: translateX(@bw * 0.0);
	    }
	    100% {
	        transform: translateX(@bw * 0.056);
	    }
	}
	@keyframes fishxu2 {
	    0% {
	        transform: translateY(@bw * 0.0);
	    }
	    100% {
	        transform: translateY(@bw * 0.014);
	    }
	}
	@keyframes fishxu1 {
	    0% {
	        transform: rotate(0deg);
	    }
	    100% {
	        transform: rotate(-10deg);
	    }
	}
	@keyframes yulin {
	    0% {
	        transform: rotate(0deg);
	    }
	    100% {
	        transform: rotate(10deg);
	    }
	}
	@keyframes hehua1 {
	    0% {
	        transform: rotate(0deg);
	    }
	    100% {
	        transform: rotate(-10deg);
	    }
	}
	@keyframes backgpox {
        100% {
           background-position-x: 2000px
        }
    }
    .luoxuan_bot{
        width: 100%;
        height: 347px;
        position: absolute;
        bottom: 0;
        background: repeat-x bottom;
        animation: backgpox 15s linear infinite;
        background-size: cover;
    }
</style>