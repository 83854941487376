<template>
	<div class="guoduDiv">
	    <div class="guochangani4">
	        <img class="ani guochang1_guodu" :src="mode[0]">
	    </div>
    </div>
</template>

<script>
	export default {
        name: 'zhuanyouzuo',
        props: ['mode'],
        data () {
            return {}
        }
    }
</script>

<style scoped lang="less">
	.guoduDiv {
	    position: absolute;
	    height: 100%;
	    width: 100%;
	    top: 0;
	    pointer-events: none;
	    z-index: 8;
	}
	.guochang1_guodu{
        width: 800px;
    }
    .guochangani4 {
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 11
    }
    .guochangani4>.ani {
       transform-origin: 50% 55%;
       animation: tab4 5s 0s linear both;
       width: 1800px;
    }
    @-webkit-keyframes tab4 {
        0% {
            transform: translateX(1130px) rotateZ(210deg);
        }
        100% {
            transform: translateX(-1330px) rotateZ(0deg);
        }

        
    }

</style>