<template>
    <div class="piao6 po page" :style="getStyles">
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
			<!-- elem模式 -->
			<div v-for="idx of 5"
				:class="'piao6_aixin_0' + idx"
				:key="'piao6_item_' + idx">
				<elem 
					:mode="getLuoItem(idx)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
			</div>
		</template>
		<template v-else>
			<div v-for="idx of 5"
				mode="widthFix"
				:class="'piao6_aixin_0' + idx"
				:key="'piao6_aixin_0' + idx">
				<div class="piao6_rect_1"></div>
				<div class="piao6_rect_2"></div>
				<div class="piao6_rect_3"></div>
			</div>
		</template>
    </div>
</template>

<script>
    // import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    import BaseLuo from './BaseLuo.vue'
    export default {
        name: 'Piao6',
        extends: BaseLuo
        // extends: BaseElem
    }
</script>

<style>
    .piao6_aixin_01 {
		position: absolute;
		left:208.5px;
		top: 100%;
		width:69.5px;
		height:69.5px;
		opacity: .8;
		transform: translateZ(139px);
		animation: piao6_aixin_01 20s infinite -5s linear;
	}
	@-webkit-keyframes piao6_aixin_01 {
		100% {
			opacity: 0;
			transform: translateY(-1041.5px) translateX(-347px) scale(.5);
		}
	}
	.piao6_aixin_01 .piao6_rect_1 {
		top: 0;
		left:28px;
		border-radius: 50%;
	}
	.piao6_aixin_01 .piao6_rect_2 {
		top: 0;
		right:28px;
		border-radius: 50%;
	}
	.piao6_aixin_01 .piao6_rect_3 {
		top:10.5px;
		left:17.5px;
		transform: rotate(45deg);
	}
	.piao6_aixin_01>div {
		/* position: absolute;
		width: 50%;
		height: 50%;
		background: #fff; */
	}

	.piao6_aixin_02 {
		position: absolute;
		top: 100%;
		left: 3%;
		width:41.5px;
		height:41.5px;
		opacity: .9;
		transform: translateZ(1041.5px);
		animation: piao6_aixin_02 20s infinite -5s linear;
	}
	@-webkit-keyframes piao6_aixin_02 {
		100% {
			opacity: .5;
			transform: translateY(-694.5px) translateX(347px) scale(.5);
		}
	}
	.piao6_aixin_02 .piao6_rect_1 {
		top: 0;
		left:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_02 .piao6_rect_2 {
		top: 0;
		right:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_02 .piao6_rect_3 {
		top:7px;
		left:10.5px;
		transform: rotate(45deg);
	}
	.piao6_aixin_02>div {
		/* position: absolute;
		width: 50%;
		height: 50%;
		background: #fff; */
	}

	.piao6_aixin_03 {
		position: absolute;
		top: 100%;
		left: 40%;
		width:41.5px;
		height:41.5px;
		opacity: .9;
		transform: translateZ(1041.5px);
		animation: piao6_aixin_03 15s infinite -7s linear;
	}
	@-webkit-keyframes piao6_aixin_03 {
		100% {
			opacity: .5;
			transform: translateY(-1041.5px) translateX(278px) scale(1.5);
		}
	}
	.piao6_aixin_03 .piao6_rect_1 {
		top: 0;
		left:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_03 .piao6_rect_2 {
		top: 0;
		right:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_03 .piao6_rect_3 {
		top:7px;
		left:10.5px;
		transform: rotate(45deg);
	}
	.piao6_aixin_03>div {
		/* position: absolute;
		width:21px;
		height:21px;
		background: #fff; */
	}

	.piao6_aixin_04 {
		position: absolute;
		top: 20%;
		left: 40%;
		width:41.5px;
		height:41.5px;
		opacity: .9;
		transform: translateZ(1041.5px);
		animation: piao6_aixin_04 10s infinite linear;
	}
	@-webkit-keyframes piao6_aixin_04 {
		0% {
			opacity: 0;
		}
		10% {
			opacity: .8;
		}
		100% {
			opacity: .5;
			transform: translateY(-347px) translateX(-69.5px) scale(.8);
		}
	}
	.piao6_aixin_04 .piao6_rect_1 {
		top: 0;
		left:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_04 .piao6_rect_2 {
		top: 0;
		right:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_04 .piao6_rect_3 {
		top:7px;
		left:10.5px;
		transform: rotate(45deg);
	}
	.piao6_aixin_04>div {
		/* position: absolute;
		width:21px;
		height:21px;
		background: #fff; */
	}

	.piao6_aixin_05 {
		position: absolute;
		top: 54%;
		left: 30%;
		width:41.5px;
		height:41.5px;
		opacity: .9;
		transform: translateZ(1041.5px);
		animation: piao6_aixin_05 10s infinite linear;
	}
	@-webkit-keyframes piao6_aixin_05 {
		0% {
			opacity: 0;
		}
		10% {
			opacity: .8;
		}
		100% {
			opacity: .5;
			transform: translateY(-208.5px) translateX(69.5px) scale(.4);
		}
	}
	.piao6_aixin_05 .piao6_rect_1 {
		top: 0;
		left:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_05 .piao6_rect_2 {
		top: 0;
		right:3.5px;
		border-radius: 50%;
	}
	.piao6_aixin_05 .piao6_rect_3 {
		top:7px;
		left:10.5px;
		transform: rotate(45deg);
	}
	.piao6_aixin_05>div {
		/* position: absolute;
		width:21px;
		height:21px;
		background: #fff; */
	}
</style>