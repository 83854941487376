<template>
    <div class="po lifangti-container" :style="containStyle">
        <div class="lifangti-container_rot page preserve3d">
            <!-- 立方体 -->
            <div class="po lifangti-box preserve3d lifangti-center" :style="boxStyle">
                <div class="po bg bg_photo lifangti-page lifangti-border lifangti-page_0" :style="getPageStyle(0)">
                    <elem v-if="comp_config[2]" :mode="comp_config[2]"></elem>
                </div>
                <div class="po bg bg_photo lifangti-page lifangti-border lifangti-page_1" :style="getPageStyle(1)">
                    <elem v-if="comp_config[2]" :mode="comp_config[2]"></elem>
                </div>
                <div class="po bg bg_photo lifangti-page lifangti-border lifangti-page_2" :style="getPageStyle(2)">
                    <elem v-if="comp_config[2]" :mode="comp_config[2]"></elem>
                </div>
                <div class="po bg bg_photo lifangti-page lifangti-border lifangti-page_3" :style="getPageStyle(3)">
                    <elem v-if="comp_config[2]" :mode="comp_config[2]"></elem>
                </div>
            </div>
            <!-- 嵌套 -->
            <elem v-for="(elem, index) in elems" 
                :key="'cover_sub_elems_' + index"
                :mode="elem"
                :currentPhoto="currentPhoto"
                :currentTime="currentTime"
                :canIShow="canIShow"
                :currentImage="currentImage"
                :scene="scene"
                :line="line"></elem>
        </div>
    </div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: 'Lifangti',
        extends: BaseElem,
        data () {
            return {
                
            }
        },
        computed: {
            myStyle () {
                let mode = this.$util.copy(this.mode);
                if (this.comp_config[0] == 'contain' && this.currentPhoto['im']) {
                    let containIm = this.$store.state.imagesObj[ this.$util.md5(this.currentPhoto['im']) ];
				    let containSize = this.$util.getContainSize( [containIm.width, containIm.height], [this.mode['size'][0], this.mode['size'][1]] )
                    mode['size'] = [containSize['width'], containSize['height']];
                }
                return this.getItemStyle(mode);
            },
            boxStyle () {
                let s = {};

                s['width'] = this.myStyle['width'] || this.$util.getPx( this.$util.pxTransform("396px") );
                s['height'] = this.myStyle['height'] || this.$util.getPx( this.$util.pxTransform("543px") );
                return s;
            },
            containStyle () {
                return Object.assign(this.myStyle, this.boxStyle);
            }
        },
        methods: {
            getPageStyle (idx) {
                
                let reg_s = /\d+\.?\d+/;
                let w_num = this.boxStyle['width'].match(reg_s)[0],
                    trans_z = this.boxStyle['width'].replace(reg_s, (parseFloat(w_num) / 2).toString());

                let s = {
                    "backgroundImage": 'url(' + this.currentPhoto['im'] +')',
                    "transform": "rotateY("+ (idx * 90) +"deg) translateZ("+ trans_z +")"
                };

                s['border-color'] = "#000";
                s['border-image'] = "-webkit-linear-gradient(-45deg,#ff1936,#ff0,#ff00fc,#fe0000) 8 8";

                // 设置字体颜色
                if (this.comp_config[1]) {
                    if (this.comp_config[1].indexOf("gradient") != -1) {
                        // 渐变色
                        s['border-color'] = "#000";
                        s['border-image'] = this.comp_config[1] + " 8 8";
                    } else {
                        s['border-color'] = this.comp_config[1];
                        delete s['border-image'];
                    }
                } else {
                    
                }

                return s;
            }
        }
    }
</script>

<style>
    .lifangti-container {
        -webkit-perspective: 720px;
    }
    .lifangti-center {
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .lifangti-container_rot {
        animation: 12s linear 0s infinite normal none running lifangti_roY;
    }
    @keyframes lifangti_roY {
        0% {
            -webkit-transform: translateZ(-298px)rotateY(0deg);
        }
        100% {
            -webkit-transform: translateZ(-298px)rotateY(-360deg);
        }
    }
    .lifangti-box {
        /* width: 396px;
        height: 543.778px; */
    }
    .lifangti-page {
        backface-visibility: hidden;
    }
    .lifangti-border {
        border-width: 8px;
        border-style: solid;
        /* border-color: rgb(235,92,129); */
        /* border-color: #000; */
        /* border-image: -webkit-linear-gradient(-45deg,#ff1936,#ff0,#ff00fc,#fe0000) 8 8; */
    }
    .lifangti-page_0 {
        /* transform: rotateY(0deg) translateZ(198px); */
    }
    .lifangti-page_1 {
        /* transform: rotateY(90deg) translateZ(198px); */
    }
    .lifangti-page_2 {
        /* transform: rotateY(180deg) translateZ(198px); */
    }
    .lifangti-page_3 {
        /* transform: rotateY(270deg) translateZ(198px); */
    }
</style>