<template>
	<div id="model" ref="model">
		<div class="w-wrap" id="w-wrap" :style="{backgroundImage: 'url('+ mode['scene']['bgimg'] +')'}">
			<div id="w-square" @touchstart="down" @touchmove="move" @touchend="up">
				<div class="w-bigbox">
					<div id="w-bganim">
						<component :is="currentTid"></component>
					</div>
					<div id="w-panorama" :style="{ transform: 'rotateY('+rotateDeg+'deg)' }">
						<template v-for="idx in itemsLen">
							<div :class="'w-item'+idx" class="w-item">
								
								<img class="w-midimg" :src="mode['scene']['midimg']" />
								
								<template v-if="photoPlace.indexOf(idx) != -1">
									<transition name="wscene-images">
										<div class="tupian" :style="{backgroundImage: 'url('+ timages[ photoPlace.indexOf(idx) ]['im'] +')'}" @click="wxPreview(timages[ photoPlace.indexOf(idx) ])" v-show="imageShow">
											<span class="tupian-text" v-if="timages[ photoPlace.indexOf(idx) ]['tx']">{{timages[ photoPlace.indexOf(idx) ]['tx']}}</span>
										</div>
									</transition>
								</template>

								<template v-for="elem in mode['scene']['elems']">
									<img v-if="idx == elem[0]" :class="elem[1]" :src="elem[2]">
								</template>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>

		<!-- 开场动画 -->
		<Prelude :mode="prelude" @preluded="preluded"></Prelude>

		<!-- 场内动画 -->
		<template v-if="mode['decorate'] && mode['decorate']['luo']">
			<luo :mode="mode['decorate']['luo']"></luo>
		</template>
	</div>
</template>

<script>
	/* eslint-disable */

	// import U from '@/util'
	// import C from '@/config'

	import gongtingmeijing from './scene/gongtingmeijing'
	import xingfuxuege from './scene/xingfuxuege'
	import huayudiemeng from './scene/huayudiemeng'
	import shuguangdengta from './scene/shuguangdengta'
	import xinniandaji from './scene/xinniandaji'

	// import Luo from '@/components/themes/components/Luo'

	import Prelude from '@/components/Prelude'

	const prelude_duration = 3; // 默认开场

	let G = {
		imagesIndent: 4,
		images: [],
	}

	export default {
		name: 'wscene',
		components: {
			gongtingmeijing,
			xingfuxuege,
			huayudiemeng,
			shuguangdengta,
			xinniandaji,
			Prelude,
		},
		data () {
			return {
				rotateDeg: 0,
				timages: [],
				imageShow: true,
				itemsLen: 24,
				photoPlace: [1, 7, 13, 19],

				drag: false,
				r: 200,
				speed: .5,
				brake: 1,
				o: null,
				x: null,
				time: null,
				
				imageIdx: 0,
			}
		},
		props: ['mode'],
		mounted () {
			this.$refs.model.addEventListener('touchmove', function(e) {
				e.preventDefault();
			})

			this.spin();
		},
		created () {
			G.images = this.$o_util.getImagesArray(this.$store.state.config.images, G.imagesIndent);
			
			this.imageIdx = G.images.length * 1000000000;

			let next_timages;

			[this.timages, next_timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);

			// 加载下一组照片
			this._preloadNextPhotos(next_timages);
		},
		computed: {
			currentTid () {
				let ttid = this.$store.state.config['data']['ttid'];
				return ttid;
			},
			prelude () {
				let prelude = Boolean(this.mode['prelude']) ? this.mode['prelude'] : false;
				if (!prelude) {
					// prelude = {
					// 	'type': 'default',
					// 	'duration': prelude_duration
					// }
					prelude = {}
				}
				if (this.mode['is_prelude'] === false) {
					prelude = {
						'type': 'none',
						'duration': 0
					}
				}
				prelude['title'] = this.$store.state.config['data']['ti'] || this.mode['name'];
				if (!prelude['textcolor']) {
					prelude['textcolor'] = '#066f06';
				}
				if (!prelude['type']) {
					prelude['type'] = 'default';
				}
				if (!prelude['duration']) {
					prelude['duration'] = prelude_duration;
				}
				return prelude;
			},
		},
		methods: {
			wxPreview (image) {
				this.$emit('wxPreview', image);
			},
			spin () {
				if ( !this.drag ) {
					if ( this.r * 180 / 400 < -360 || this.r * 180 / 400 > 360 ) {
						this.r = 200;
						if (G.images.length > 1) {
							this.imageShow = false;
							setTimeout(() => {

								let timages = [];
								let next_timages;

								[timages, next_timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);

								// 加载下一组照片
								this._preloadNextPhotos(next_timages);

								for (let j=0,jlens=timages.length; j<jlens; j++) {
									this.$set(this.timages, j, timages[j]);
								}
								setTimeout(() => {
									this.imageShow = true;
								}, 200)
							}, 500);
						}
					}

					if ( this.speed < .5 && this.speed > 0 ) {
						this.speed = .5;
					} else if ( this.speed > -.5 && this.speed < 0 ) {
						this.speed = -.5;
					}

					this.r += this.speed;
					this.speed /= this.brake;

					let ry = (this.r * 180 / 400);
					this.rotateDeg = (0-ry);
				}
				requestAnimationFrame(this.spin);
			},
			down (event) {
				this.o = this.r;
				this.x = this.r + event.touches[0].clientX;
				this.drag = true;
				this.time = new Date();
			},
			move (event) {
				if (this.drag) {
					let ry = (this.r * 180 / 400);
					this.r = this.x - event.touches[0].clientX;
					this.rotateDeg = (0-ry);
				}
			},
			up (event) {
				if (this.drag) {
					let time = new Date() - this.time;
					let path = this.r - this.o;
					this.speed = path / time * 5;
					this.brake = 1.01;
					this.drag = false;
				}
			},
			// 预加载下一张用户图片
			_preloadNextPhotos (photos) {

				let _this = this;

				for (let i=0; i<photos.length; i++) {

					let photo = photos[i];

					let im = new Image();
					
					im.onload = (function(_photo) {
						return function() {
							_this.$store.commit('setImageOriSize', {'imageid': _photo['id'], 'orisize': [this.width, this.height]});
						}
					})(photo);
					im.onerror = (function(_photo) {
						return function() {
							_this.$store.commit('setImageOriSize', {'imageid': _photo['id'], 'orisize': [500, 500]});
						}
					})(photo);

					im.src = photo['im'];
				}
			},
			preluded () {}
		}
	}	
</script>

<style lang="less">
	/*.w-item {
		top: 0!important;
		bottom: 0!important;
		margin: auto 0!important;
	}*/
	.w-wrap {
		position:fixed;
		overflow:hidden;
		margin:0 auto;
		top:0;
		bottom:0;
		height:100%;
		width:@bw * 1.0;
		border-radius:@bw * 0.004;
		-webkit-transition:opacity 0.5s ease;
		-moz-transition:opacity 0.5s ease;
		transition:opacity 0.5s ease;
		background-size: cover;
		background-repeat: no-repeat;
	}
	#w-square {
		height: 100%;
		width:100%;
		margin-left:50%;
		margin-top:@bw * 0.069;
		position:relative;
		perspective:@bw * 1.0;
		user-select:none;
	}
	#w-panorama {
		-webkit-transition:opacity 0.5s ease;
		-moz-transition:opacity 0.5s ease;
		transition:opacity 0.5s ease;
	}
	#w-panorama ,#w-panorama .face, #w-panorama .w-item {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
	}
	#w-panorama .w-item img:nth-child(1) {
		height:100%;
		width:100%;
	}
	#w-panorama .w-item .tupian,#w-panorama .w-item .tupianbg {
		position:absolute;
		bottom:@bw * 0.375;
		left:@bw * 0.188;
		width:@bw * 0.431;
		height:@bw * 0.694;
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		/*opacity:0.8;
		*/ border:@bw * 0.001 solid #fff;
		font-size:@bw * 0.139;
		color:#ffffff;
		text-align:center;
		background-color:#ffffff;
	}
	#w-panorama .w-item .tupian {
		/*display:none;*/
	}
	#w-panorama .face,.label {
		-webkit-backface-visibility:hidden;
		-moz-backface-visibility:hidden;
		backface-visibility:hidden;
	}
	#w-panorama .w-item1,#w-panorama .w-item2,#w-panorama .w-item3,#w-panorama .w-item4,#w-panorama .w-item5,#w-panorama .w-item6,#w-panorama .w-item7,#w-panorama .w-item8,#w-panorama .w-item9,#w-panorama .w-item10,#w-panorama .w-item11,#w-panorama .w-item12,#w-panorama .w-item13,#w-panorama .w-item14,#w-panorama .w-item15,#w-panorama .w-item16,#w-panorama .w-item17,#w-panorama .w-item18,#w-panorama .w-item19,#w-panorama .w-item20,#w-panorama .w-item21,#w-panorama .w-item22,#w-panorama .w-item23,#w-panorama
	      .w-item24 {
		width:@bw * 0.718;
		height:@bw * 1.451;
		position:absolute;
		/*top:@bw * 0.0;
		bottom:@bw * 0.0;
		margin: auto;*/
		top: 10%;
		transform-origin:100% 50%;
		pointer-events:none;
	}
	#w-panorama .w-item1,#w-panorama .w-item7,#w-panorama .w-item13,#w-panorama .w-item19 {
		pointer-events:auto;
	}
	#w-panorama .w-item1 {
		transform:translateX(-@bw * 0.218) rotateY(0deg);
	}
	#w-panorama .w-item2 {
		transform:translateX(-@bw * 0.218) rotateY(15deg);
	}
	#w-panorama .w-item3 {
		transform:translateX(-@bw * 0.218) rotateY(30deg);
	}
	#w-panorama .w-item4 {
		transform:translateX(-@bw * 0.218) rotateY(45deg);
	}
	#w-panorama .w-item5 {
		transform:translateX(-@bw * 0.218) rotateY(60deg);
	}
	#w-panorama .w-item6 {
		transform:translateX(-@bw * 0.218) rotateY(75deg);
	}
	#w-panorama .w-item7 {
		transform:translateX(-@bw * 0.218) rotateY(90deg);
	}
	#w-panorama .w-item8 {
		transform:translateX(-@bw * 0.218) rotateY(105deg);
	}
	#w-panorama .w-item9 {
		transform:translateX(-@bw * 0.218) rotateY(120deg);
	}
	#w-panorama .w-item10 {
		transform:translateX(-@bw * 0.218) rotateY(135deg);
	}
	#w-panorama .w-item11 {
		transform:translateX(-@bw * 0.218) rotateY(150deg);
	}
	#w-panorama .w-item12 {
		transform:translateX(-@bw * 0.218) rotateY(165deg);
	}
	#w-panorama .w-item13 {
		transform:translateX(-@bw * 0.218) rotateY(180deg);
	}
	#w-panorama .w-item14 {
		transform:translateX(-@bw * 0.218) rotateY(195deg);
	}
	#w-panorama .w-item15 {
		transform:translateX(-@bw * 0.218) rotateY(210deg);
	}
	#w-panorama .w-item16 {
		transform:translateX(-@bw * 0.218) rotateY(225deg);
	}
	#w-panorama .w-item17 {
		transform:translateX(-@bw * 0.218) rotateY(240deg);
	}
	#w-panorama .w-item18 {
		transform:translateX(-@bw * 0.218) rotateY(255deg);
	}
	#w-panorama .w-item19 {
		transform:translateX(-@bw * 0.218) rotateY(270deg);
	}
	#w-panorama .w-item20 {
		transform:translateX(-@bw * 0.218) rotateY(285deg);
	}
	#w-panorama .w-item21 {
		transform:translateX(-@bw * 0.218) rotateY(300deg);
	}
	#w-panorama .w-item22 {
		transform:translateX(-@bw * 0.218) rotateY(315deg);
	}
	#w-panorama .w-item23 {
		transform:translateX(-@bw * 0.218) rotateY(330deg);
	}
	#w-panorama .w-item24 {
		transform:translateX(-@bw * 0.218) rotateY(345deg);
	}
	.w-bigbox {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
	}
	#w-bganim {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		transform-style:preserve-3d;
		background-size:cover;
	}
	.w-midimg {
		pointer-events:none;
	}

	.tupian {
		transition: opacity .5s linear;
	}
	.tupian-text {
		position: absolute;
		width:@bw * 0.389;
		left: 0;
		right: 0;
		text-align: center;
		margin: auto;
		bottom:@bw * 0.021;
		color: white;
		font-size:@bw * 0.047;
		text-shadow:@bw * 0.003@bw * 0.003@bw * 0.014 black;
		font-family: '\9ED1\4F53';
		font-weight: bold;
	}

	.wscene-images-leave {
		opacity: 1;
	}
	.wscene-images-leave-to {
		opacity: 0;
	}

	.wscene-images-enter {
		opacity: 0;
	}
	.wscene-images-enter-to {
		opacity: 1;
	}
</style>










