<template>
    <div class="f_zoomin_userbox">
		<div class="f_zoomin_userhezileft">
			<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
			<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
		</div>
		<div class="f_zoomin_userleft">
			<div class="f_zoomin_userhezi2">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi22">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi3">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi33">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi4">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi5">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi55">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi555">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
		</div>
		<div class="f_zoomin_userheziright">
			<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
			<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
		</div>
		<div class="f_zoomin_userright">
			<div class="f_zoomin_userhezi6">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi66">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi7">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi77">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi8">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi88">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
			<div class="f_zoomin_userhezi9">
				<div class="f_zoomin_userpic bg bg_photo" :style="{backgroundImage: 'url('+ currentPhoto['im'] +')'}"></div>
				<elem v-if="comp_config[0]" :mode="comp_config[0]"></elem>
			</div>
		</div>
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'baibianxing',
		extends: BaseElem,
		data () {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style>
	div[class^="f_zoomin_userhezi"] {
		position:absolute;
		width:208px;
		height:348px;
		transform-style:preserve-3d
	}

	.f_zoomin_userbox {
		position:absolute;
		top:0;
		left:0;
		width:500px;
		height:100%;
		perspective:694px;
		transform-style:preserve-3d;
	}
	.f_zoomin_userhezileft {
		top:312px;
		left:10px;
		animation:f_zoomin_userhezileft 3s ease-in both
	}
	@keyframes f_zoomin_userhezileft {
		0% {
			transform:scale(1) translateZ(0px) translateX(0px)
		}
		100% {
			transform:scale(2) translateZ(348px) translateX(-112px)
		}
	}
	.f_zoomin_userleft {
		position:absolute;
		top:-139px;
		left:0;
		width:500px;
		height:100%;
		perspective:694px;
		transform-style:preserve-3d
	}
	@keyframes f_zoomin_userleft0 {
		0% {
			transform:translateZ(-1389px) translateX(35px)
		}
		100% {
			transform:translateZ(-694px) translateX(0px)
		}
	}
	@keyframes f_zoomin_userleft {
		0% {
			transform:translateZ(-694px) translateX(0px)
		}
		100% {
			transform:translateZ(347px) translateX(-69px)
		}
	}
	.f_zoomin_userhezi2 {
		top:696px;
		left:-167px;
		animation:f_zoomin_userleft0 3.5s linear both,f_zoomin_userleft 5s 3.5s linear forwards;
	}
	.f_zoomin_userhezi22 {
		top:210px;
		left:-444px;
		animation:f_zoomin_userleft0 3.5s linear both,f_zoomin_userleft 5s 3.5s linear forwards
	}
	.f_zoomin_userhezi3 {
		top:240px;
		left:74px;
		animation:f_zoomin_userleft0 3.5s linear both,f_zoomin_userleft 5s 3.5s linear forwards
	}
	.f_zoomin_userhezi33 {
		top:893px;
		left:143px;
		animation:f_zoomin_userleft0 3.5s linear both,f_zoomin_userleft 5s 3.5s linear forwards
	}
	.f_zoomin_userhezi4 {
		top:-312px;
		left:-113px;
		animation:f_zoomin_userleft0 3.5s linear both,f_zoomin_userleft 5s 3.5s linear forwards
	}
	.f_zoomin_userhezi5 {
		top:162px;
		left:-208px;
		animation:f_zoomin_userleft1 3.5s linear both,f_zoomin_userleft2 5s 3.5s linear forwards,f_zoomin_userleft3 4s 8.5s linear forwards
	}
	.f_zoomin_userhezi55 {
		top:703px;
		left:-104px;
		animation:f_zoomin_userleft1 3.5s linear both,f_zoomin_userleft2 5s 3.5s linear forwards,f_zoomin_userleft3 4s 8.5s linear forwards
	}
	.f_zoomin_userhezi555 {
		top:1051px;
		left:-396px;
		animation:f_zoomin_userleft1 3.5s linear both,f_zoomin_userleft2 5s 3.5s linear forwards,f_zoomin_userleft3 4s 8.5s linear forwards
	}
	@keyframes f_zoomin_userleft2 {
		0% {
			transform:translateZ(-1042px) translateX(0px)
		}
		100% {
			transform:translateZ(-347px) translateX(0px)
		}
	}
	@keyframes f_zoomin_userleft3 {
		0% {
			transform:translateZ(-347px) translateX(0px)
		}
		100% {
			transform:translateZ(347px) translateX(-21px)
		}
	}
	.f_zoomin_userpic {
		position: absolute;
		top: 0;
		left: -11px;
		/* width: 243px;
		height: 312px; */
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	@keyframes f_zoomin_userleft1 {
		0% {
			transform:translateZ(-1736px) translateX(35px)
		}
		100% {
			transform:translateZ(-1041px) translateX(0px)
		}
	}
	@keyframes f_zoomin_userleft2 {
		0% {
			transform:translateZ(-1041px) translateX(0px)
		}
		100% {
			transform:translateZ(-347px) translateX(0px)
		}
	}
	@keyframes f_zoomin_userleft3 {
		0% {
			transform:translateZ(-347px) translateX(0px)
		}
		100% {
			transform:translateZ(347px) translateX(-14px)
		}
	}
	.f_zoomin_userheziright {
		top:410px;
		right:-14px;
		animation:f_zoomin_userheziright 3.5s ease-in both
	}
	@keyframes f_zoomin_userheziright {
		0% {
			transform:scale(1) translateZ(-69px) translateX(0px)
		}
		100% {
			transform:scale(1.5) translateZ(328px) translateX(97px)
		}
	}

	.f_zoomin_userright {
		position:absolute;
		top:0;
		right:0;
		width:500px;
		height:100%;
		perspective:694px;
		transform-style:preserve-3d
	}
	.f_zoomin_userhezi6 {
		top:-224px;
		right:-28px;
		animation:f_zoomin_userright0 3.5s linear both,f_zoomin_userright1 5s 3.5s linear forwards
	}
	.f_zoomin_userhezi66 {
		top:-500px;
		right:-306px;
		animation:f_zoomin_userright0 3.5s linear both,f_zoomin_userright1 5s 3.5s linear forwards
	}
	@keyframes f_zoomin_userright0 {
		0% {
		transform:translateZ(-1389px) translateX(-21px)
	}
	100% {
		transform:translateZ(-694px) translateX(0px)
	}
	}@keyframes f_zoomin_userright1 {
		0% {
		transform:translateZ(-694px) translateX(0px)
	}
	100% {
		transform:translateZ(208px) translateX(21px)
	}
	}.f_zoomin_userhezi7 {
		top:347px;
		right:-283px;
		animation:f_zoomin_userright2 3.5s linear both,f_zoomin_userright3 5s 3.5s linear forwards,f_zoomin_userright4 4s 8.5s linear forwards
	}
	.f_zoomin_userhezi77 {
		top:-243px;
		right:-182px;
		animation:f_zoomin_userright2 3.5s linear both,f_zoomin_userright3 5s 3.5s linear forwards,f_zoomin_userright4 4s 8.5s linear forwards
	}
	.f_zoomin_userhezi8 {
		top:547px;
		right:-373px;
		animation:f_zoomin_userright0 3.5s linear both,f_zoomin_userright1 5s 3.5s linear forwards
	}
	.f_zoomin_userhezi88 {
		top:60px;
		right:-581px;
		animation:f_zoomin_userright0 3.5s linear both,f_zoomin_userright1 5s 3.5s linear forwards
	}
	.f_zoomin_userhezi9 {
		top:856px;
		right:-556px;
		animation:f_zoomin_userright2 3.5s linear both,f_zoomin_userright3 5s 3.5s linear forwards,f_zoomin_userright4 4s 8.5s linear forwards
	}
	@keyframes f_zoomin_userright2 {
		0% {
		transform:translateZ(-2083px) translateX(-21px)
	}
	100% {
		transform:translateZ(-1389px) translateX(0px)
	}
	}@keyframes f_zoomin_userright3 {
		0% {
		transform:translateZ(-1389px) translateX(0px)
	}
	100% {
		transform:translateZ(-694px) translateX(21px)
	}
	}@keyframes f_zoomin_userright4 {
		0% {
		transform:translateZ(-694px) translateX(21px)
	}
	100% {
		transform:translateZ(347px) translateX(21px)
	}
	}
</style>