<template>
	<div id="model" ref="model">
		<component :is="scene" :currentImage="currentImage"></component>
		<!-- <component :is="scene" :currentImage="currentImage" @letAniEndPageIn="letAniEndPageIn" v-if="isThemeShow"></component> -->

		<!-- <div class="ani_end_page" :class="{ani_end_page_in: aniEndPageIn}">
	    	<div class="ani_end_mask"></div>

	    	<div class="ani_end_t_0">你是第<strong>{{playnum}}</strong>位登上祝福祖国列车的人</div>

	    	<div class="ani_end_t_1" @click="goshare">
	    		<div class="ani_end_t_1_btn">
		    		<img style="width: 64px;height: 64px;" src="http://litiresource.xcgogo.site/user/5980171/images/4137b9e11c4ea8de1c98bf58d56b72f6.png">
		    		<span>邀请好友接力</span>
	    		</div>
	    	</div>

	    	<div class="ani_end_t_2" @click="replay">
	    		<img style="width: 64px;height: 64px;" src="http://litiresource.xcgogo.site/user/5980171/images/f30ee22ea3f78678a927d4f94fbddb54.png">
	    		<span>重播</span>
	    	</div>
	    </div> -->
	</div>
</template>

<script>
	import zhongqiubianlian from './scene/zhongqiubianlian'
	import guoqingbianlian from './scene/guoqingbianlian'
	import guoqingheying from './scene/guoqingheying'
	import chongyangheying from './scene/chongyangheying'
	import dongtai_0 from './scene/dongtai_0'

	export default {
		name: 'Other',
		components: {
			zhongqiubianlian,
			guoqingbianlian,
			guoqingheying,
			chongyangheying,
			dongtai_0,
		},
		data () {
			return {
				// aniEndPageIn: false,
				// isThemeShow: true,
				// playnum: 10000
			}
		},
		computed: {
			scene () {
				let ttid = this.$store.state.config.data['ttid'];
				return ttid;
			},
			currentImage () {
				let images = this.$store.state.config.images;
				return images;
			}
		},
		created () {
			// this.$util.rget('get_merge_face_config', {}).then((response) => {
			// 	this.playnum = response['merge_themes']['guoqingbianlian']['count'];
			// })
		},
		methods: {
			// letAniEndPageIn () {
			// 	this.aniEndPageIn = true;
			// },
			// letAniEndPageOut () {
			// 	this.aniEndPageIn = false;	
			// },
			// replay () {
			// 	this.letAniEndPageOut();
			// 	this.refresh();
			// },
			// refresh () {
			// 	this.isThemeShow = false;
			// 	this.$nextTick(() => {
			// 		this.isThemeShow = true;
			// 	});
			// },
			// goshare () {
			// 	wx.miniProgram.navigateTo({
			//       	url: '/pages/savePage/savePage?key='+this.$route.query.key+'&share=1'
			//     });
			// }
		}
	}
</script>

<style scoped>
	.ani_end_page, .ani_end_mask {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.ani_end_page {
		position: fixed;
		z-index: 11;
		transform: translateX(100%);
	}
	.ani_end_page_in {
		animation: ani_end_page_in 1s both;
	}
	@keyframes ani_end_page_in {
		from {
			transform: translateX(100%);
		} to {
			transform: none;
		}
	}
	.ani_end_mask {
		position: absolute;
		background-color: rgba(0,0,0,.8);
	}
	.ani_end_t_0 {
		position: relative;
		margin: 200px 0;
		color: white;
		font-size: 60px;
		font-family: 'Heiti';
		text-align: center;
		padding: 0 44px;
	}
	.ani_end_t_0>strong {
		font-size: 64px;
		font-weight: bold;
		color: rgb(234,134,57);
	}
	.ani_end_t_1, .ani_end_t_2 {
		position: relative;
		font-size: 60px;
		color: white;
		width: 100%;
		display: block;
	}
	.ani_end_t_1_btn {
		position: relative;
		background-color: #1BAE38;
		margin: auto;
		display: block;
		padding: 25px 0;
		border-radius: 80px;
		width: 512px;
		margin: auto;
		left: 0; right: 0;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.ani_end_t_2 {
		position: relative;
		margin: 60px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ani_end_t_2>span {
		margin-left: 10px;
	}
</style>