<template>
    <div class="po zimu5-contain" :style="getMyStyles" :class="elemClass" v-show="canZimuShow">
        <template v-if="texts">
            <div class="zimu5-texts page"
                :style="getTextsStyle">

                <template v-if="canTextShow">
                    <div class="zimu5-texts_row zimu5-line" 
                        v-for="(ts, index) in texts"
                        :key="'zimu5_texts_'+index"
                        :style="getTextsLineStyle">

                        <div class="zimu5-text"
                            :style="getTextStyle( getTextIndex(index, j_index) )" 
                            v-for="(t, j_index) in ts"
                            :key="'zimu5_text_'+j_index">
                            <span class="page zimu5-text_span"
                                :style="getTextSpanStyle( getTextIndex(index, j_index) )">{{t}}</span>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else-if="ims.length">
            <div class="zimu5-line page">

                <template v-if="canTextShow">
                    <div class="zimu5-texts_row zimu5-line"
                        v-for="(im, index) in ims"
                        :key="'zimu5_texts_'+index"
                        :style="getTextsLineStyle">
                        
                        <!-- :class="getTextClass(index)"  -->
                        <!-- :style="getImStyle"  -->
                        <div class="zimu5-text" 
                            :class="'zimu5-text_' + config[13] || 'relative'"
                            v-for="(_im, j_index) in im"
                            :key="'zimu5_text_'+j_index"
                            :style="getImStyle( getImsIndex(index, j_index), _im )" >
                            <div class="page zimu5-text_span"
                                :style="getTextSpanStyle( getImsIndex(index, j_index) )">
                                <elem :mode="getImElem( _im )" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>

        <div class="none" v-html="cssHtml"></div>

    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
    export default {
        name: 'zimu5',
        extends: BaseElem,
        data () {
            return {
                // pindex: 0,
                lrc: '',
                canTextShow: false,
                wordStatusCache: 0,
                hasLrc: false
            }
        },
        watch: {
            canIShow: {
                handler () {
                    if (this.canIShow) {
                        this.start()
                    } else {
                        this.end()
                    }
                },
                immediate: true
            }
        },
        computed: {
            getMyStyles () {
                let s = this.getStyles;
                if (!s['width']) {
                    let w = this.textLength * this.myTextWidth + (this.textLength - 1) * this.myConfigSpan;
                    w = w >= 500 ? 500 : w;
                    s['width'] = w + "px";
                }
                s = this.$util.pxTransform(s, "style");
                return s;
            },
            canZimuShow () {
                let can_show;
                if (this.myWithText) {
                    // 可与用户文字同时显示
                    can_show = true;
                } else {
                    // 不可与用户文字同时显示
                    if (this.$store.state.wordStatus == 1) {
                        can_show = this.myWithText;
                    } else {
                        can_show = true;
                    }
                }
                return can_show;
            },
            ims () {
                let ims = this.comp['im'] || this.config[11] || [];
                if (ims.length === 0) {
                    return [];
                }
                if (this.$util.typeOf(ims[0]) == 'object') {
                    ims = [ims];
                }
                return ims;
            },
            myConfigTexts () {
                return this.config[6];
            },
            myConfigSpan () {
                return this.config[3] ? parseFloat(this.config[3]) : .1;
            },
            myConfigDelay () {
                return this.config[5] || 0;
            },
            myConfigAnimation () {
                return this.config[4];
            },
            myWithText () {
                let with_text = this.config[10];
                if (this.config[10] === null || this.config[10] === undefined) {
                    with_text = 1;
                }
                if (this.config[10] == "0") {
                    with_text = 0;
                }
                return with_text;
            },
            texts () {
                let texts = null;
                if (this.myConfigTexts) {
                    if (this.$util.typeOf(this.myConfigTexts) == 'string') {
                        if (this.myConfigTexts == '#lrc#') {
                            if (this.$store.state.currentLrc != this.lrc) {
                                this.lrc = this.$store.state.currentLrc;

                                this.canTextShow = false;
                                this.$nextTick(() => {
                                    this.canTextShow = true;
                                })
                            }
                            this.$store.commit("setWordStatus", {
                                status: 3
                            });
                            this.hasLrc = true;
                            texts = [this.lrc];
                        } else {
                            texts = [this.myConfigTexts];
                        }
                    } else if (this.$util.typeOf(this.myConfigTexts) == 'array') {
                        texts = this.myConfigTexts;
                    }
                }
                return texts;
            },
            textLength () {
                let length = 0;
                if (this.texts) {
                    for (let i=0; i<this.texts.length; i++) {
                        length += this.texts[i].length;
                    }
                } else {
                    for (let i=0; i<this.ims.length; i++) {
                        length += this.ims[i].length;
                    }
                }

                return length;
            },
            config () {
                return this.comp['config'] || [];
            },
            getTextsStyle () {
                let s  = this.config[7] || {};

                return this.$util.pxTransform(s, 'style');
            },
            getTextsLineStyle () {
                let row = this.config[8] || 'start',
                    col = this.config[9] || 'center',
                    line_height = this.config[12] || 12;
                let map = {
                    'start': 'flex-start',
                    'center': 'center',
                    'end': 'flex-end'
                };
                let s = {};
                s['justify-content'] = map[row];
                s['align-items'] = map[col];
                s['margin-top'] = line_height + "px";
                return s;
            },
            myTextWidth () {
                return this.config[0] ? parseFloat(this.config[0]) : 100;
            },
            myTextHeight () {
                return this.config[1] ? parseFloat(this.config[1]) : 100;
            }
        },
        created () {
            this.$store.commit("setCompStatus", {
                comp: "zimu5",
                status: 1
            });
            // 设置wordStatus = 3
            // this.wordStatusCache = this.$store.state.wordStatus;
            // if (this.$store.state.wordStatus != 1) {
            //     setTimeout(() => {
            //         this.$util.tdCall({
            //             action: 'word_status',
            //             data: 3
            //         })
            //         this.$store.commit('setWordStatus', {
            //             status: 3,
            //             from: 1
            //         })
            //     }, 500);
            // }
        },
        destroyed () {
            this.$store.commit("setCompStatus", {
                comp: "zimu5",
                status: 0
            });
            if (this.hasLrc) {
                this.hasLrc = false;
                this.$store.commit("setWordStatus", {
                    status: 2
                });
            }
        //     // 恢复文字状态
        //     this.$util.tdCall({
        //         action: 'word_status',
        //         data: this.wordStatusCache
        //     })
        //     this.$store.commit('setWordStatus', {
        //         status: this.wordStatusCache,
        //         from: 2
        //     })
        },
        mounted () {
            
        },
        methods: {
            start () {
                // 设置播放delay
                setTimeout(() => {
                    this.canTextShow = true;
                }, this.myConfigDelay * 1000)
            },
            end () {
                this.canTextShow = false;
            },
            getTextClass (index) {
                return {
                    'none': index >= this.pindex
                }
            },
            getElem (elem, index) {
                if (!elem['keyframe'] && this.myConfigAnimation) {
                    elem['keyframe'] = this.myConfigAnimation;
                    if (!elem['style']) elem['style'] = {};
                    elem['style']['animationDelay'] = (this.myConfigSpan * index).toFixed(2) + 's';
                }
                return elem;
            },
            // 获取文字下标
            getTextIndex (i, j) {
                if (i == 0) {
                    return j;
                } else {
                    return j + this.texts[i-1].length;
                }
            },
            getImsIndex (i, j) {
                if (i == 0) {
                    return j;
                } else {
                    return j + this.ims[i-1].length;
                }
            },
            // 根据下标动态获取style
            getTextSpanStyle (index) {
                return {
                    'animationDelay': (this.myConfigSpan * index).toFixed(2) + 's'
                }
            },
            getTextStyle (index) {
                let width = this.myTextWidth,
                    height = this.myTextHeight,
                    spacing = this.config[2] || 0;

                let s = {};

                // 获取动画style
                if (this.myConfigAnimation) {
                    s = this.getElemStyles({
                        "keyframe": this.myConfigAnimation
                    })
                    s['animationDelay'] = (this.myConfigSpan * index).toFixed(2) + "s";
                }

                s = Object.assign(s, {
                    'width': width + 'px',
                    'height': height + 'px',
                    'marginRight': spacing + 'px',
                    'fontSize': (width > height ? height : width) + 'px'
                });

                return this.$util.pxTransform(s, 'style');
            },
            getImStyle (index, im) {
                let s = this.getTextStyle(index);
                let im_s = this.getItemStyle(im);
                if (im_s['left']) s['left'] = im_s['left'];
                if (im_s['right']) s['right'] = im_s['right'];
                if (im_s['top']) s['top'] = im_s['top'];
                if (im_s['bottom']) s['bottom'] = im_s['bottom'];
                return s;
            },
            getImElem (im) {
                let mode = this.$util.copy(im);
                delete mode['pos'];
                return mode;
            },
            // getImStyle () {
            //     let width = this.myTextWidth,
            //         height = this.myTextHeight,
            //         spacing = this.config[2] || 0,
            //         style = this.config[7] || {};

            //     let s = Object.assign({
            //         'width': width + 'px',
            //         'height': height + 'px',
            //         'marginRight': spacing + 'px'
            //     }, style);

            //     return this.$util.pxTransform(s, 'style');
            // },
        }
    }
</script>

<style>
    .zimu5-line {
        display: flex;
        flex-wrap: wrap;
    }
    .zimu5-text_relative {
        position: relative;
    }
    .zimu5-text_absolute {
        position: absolute;
    }
    .zimu5-texts_row {
        /* background-color: red; */
        position: relative;
        width: 100%;
    }
    .zimu5-text_span {
        animation-name: fadeIn;
        animation-duration: .5s;
        animation-fill-mode: both;
    }
</style>
