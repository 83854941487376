<template>
	<div class="BX_AEComposition" AELayerName="guoqiheying">

		<div class="BX_Class12 BX_BG12 BX_Ani12" id="BX_Layer12" AELayerName="12.bg.jpg"></div>
		<div class="BX_Class11 BX_BG11 BX_Ani11" id="BX_Layer11" AELayerName="11.flag.png"></div>
		<div :style="{backgroundImage: 'url('+currentImage[0]['im']+')'}" class="BX_Class10 BX_BG10 BX_Ani10" id="BX_Layer10" AELayerName="10.user.png"></div>
		<div class="BX_Class9 BX_BG9 BX_Ani9" id="BX_Layer9" AELayerName="9.choudai.png"></div>
		<div class="BX_Class8 BX_BG8 BX_Ani8" id="BX_Layer8" AELayerName="8.tiananmen.png"></div>
		<div class="BX_Class7 BX_BG7 BX_Ani7" id="BX_Layer7" AELayerName="7.ying.jpg"></div>
		<div class="BX_Class6 BX_BG6 BX_Ani6" id="BX_Layer6" AELayerName="6.he.jpg"></div>
		<div class="BX_Class5 BX_BG5 BX_Ani5" id="BX_Layer5" AELayerName="5.qi.jpg"></div>
		<div class="BX_Class4 BX_BG4 BX_Ani4" id="BX_Layer4" AELayerName="4.guo.jpg"></div>
		<div class="BX_Class3 BX_BG3 BX_Ani3" id="BX_Layer3" AELayerName="3.yu.jpg"></div>
		<div class="BX_Class2 BX_BG2 BX_Ani2" id="BX_Layer2" AELayerName="2.wo.jpg"></div>
		<div class="BX_Class15 BX_BG15 BX_Ani15" id="BX_Layer15" AELayerName="3.gezimove">
			<div class="BX_Class16 BX_Ani16" id="BX_Layer16" AELayerName="1.预合成_1">
				<div class="BX_Sprite16"></div>
			</div>
		</div>
		<div class="BX_Class14 BX_BG14 BX_Ani14" id="BX_Layer14" AELayerName="2.gezimove_2">
			<div class="BX_Class16 BX_Ani16" id="BX_Layer16" AELayerName="1.预合成_1">
				<div class="BX_Sprite16"></div>
			</div>
		</div>
		<div class="BX_Class13 BX_BG13 BX_Ani13" id="BX_Layer13" AELayerName="1.gezimove_3">
			<div class="BX_Class16 BX_Ani16" id="BX_Layer16" AELayerName="1.预合成_1">
				<div class="BX_Sprite16"></div>
			</div>
		</div>
		<div class="BX_Class1 BX_BG1 BX_Ani1" id="BX_Layer1" AELayerName="1.cover.jpg"></div>
	</div>
</template>

<script>
	export default {
		name: 'guoqiheying',
		props: ['currentImage'],
		data () {
			return {}
		}
	}
</script>

<style scoped>
	.BX_AEComposition {
	    background: transparent;
	    width: 720px;
	    height: 1280px;
	    display: block;
	    overflow: hidden;
	    position: relative;
	    transform: translate3d(0, 0, 0) scale(1, 1);
	    transform-origin:50% 0%;
	    perspective: 600px;
	    perspective-origin: 50% 50%;
	}
	.BX_Class16 {
	    left: 0.00px;
	    top: 0.00px;
	    width: 250px;
	    height: 250px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG16 {
	    background: transparent;
	}
	.BX_Ani16 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey16 0.60s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey16 {
	    /* 		This layer has no animations to export */
	}
	.BX_Sprite16 {
	    background-image: url("http://resali.lititutu.cn/scenes/guoqingheying/gezi.png");
	    width: 250px;
	    height: 250px;
	    animation: BX_SprAniKey16 0.60s steps(1) 0s infinite normal none;
	}
	@keyframes BX_SprAniKey16 {
	    0% {
	        background-position: 0px 0px;
	    }
	    16.67% {
	        background-position: -250px 0px;
	    }
	    33.33% {
	        background-position: -500px 0px;
	    }
	    50.00% {
	        background-position: -750px 0px;
	    }
	    66.67% {
	        background-position: -1000px 0px;
	    }
	    83.33% {
	        background-position: -1250px 0px;
	    }
	}
	.BX_Class15 {
	    left: -230.00px;
	    top: 756.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG15 {
	}
	.BX_Ani15 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey15 7.50s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey15 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    0.67% {
	        transform: translate3d(18.36px, -6.88px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    33.33% {
	        transform: translate3d(918.00px, -344.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    34.00% {
	        transform: translate3d(918.00px, -344.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 0;
	    }
	    100.00% {
	        transform: translate3d(918.00px, -344.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 0;
	    }
	}
	.BX_Class14 {
	    left: -266.00px;
	    top: 414.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG14 {
	}
	.BX_Ani14 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey14 7.50s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey14 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    34.00% {
	        transform: translate3d(18.68px, -6.04px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    66.67% {
	        transform: translate3d(934.00px, -302.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    67.33% {
	        transform: translate3d(934.00px, -302.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 0;
	    }
	    100.00% {
	        transform: translate3d(934.00px, -302.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 0;
	    }
	}
	.BX_Class13 {
	    left: -276.00px;
	    top: 1072.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG13 {
	}
	.BX_Ani13 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey13 7.50s linear 0.00s infinite normal none;
	}
	@keyframes BX_AniKey13 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    67.33% {
	        transform: translate3d(16.04px, -5.60px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(802.00px, -280.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class12 {
	    left: 0.00px;
	    top: 0.00px;
	    width: 720px;
	    height: 1280px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG12 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/bg.jpg");
	}
	.BX_Ani12 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey12 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey12 {
	    /* 		This layer has no animations to export */
	}
	.BX_Class11 {
	    left: -295.76px;
	    top: 1031.90px;
	    width: 768px;
	    height: 1024px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(3.23, 3.23);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG11 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/flag.png");
	}
	.BX_Ani11 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey11 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey11 {
	    /* 		This layer has no animations to export */
	}
	.BX_Class10 {
	    left: 62.50px;
	    /*top: 294.00px;*/
	    bottom: 180px;
	    width: 681px;
	    /*height: 1024px;*/
        height: 803px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG10 {
		background-repeat: no-repeat;
		background-size: contain;
		background-position: bottom center;
	}
	.BX_Ani10 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey10 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey10 {
		from {
			opacity: 0;
		}
		20% {
			opacity: 1;
		}
		to {
			opacity: 1;
		}
	    /* 		This layer has no animations to export */
	}
	.BX_Class9 {
	    left: -92.50px;
	    top: -4.50px;
	    width: 569px;
	    height: 215px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG9 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/choudai.png");
	}
	.BX_Ani9 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey9 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey9 {
	    /* 		This layer has no animations to export */
	}
	.BX_Class8 {
	    left: 0.00px;
	    top: 872.00px;
	    width: 720px;
	    height: 408px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG8 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/tiananmen.png");
	}
	.BX_Ani8 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey8 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey8 {
	    /* 		This layer has no animations to export */
	}
	.BX_Class7 {
	    left: 728.00px;
	    top: 860.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(117.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG7 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/ying.jpg");
	}
	.BX_Ani7 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey7 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey7 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(117.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    71.83% {
	        transform: translate3d(-7.41px, 0.00px, 0.00px) rotate(111.15deg) scale(1, 1);
	        opacity: 0.05;
	    }
	    73.24% {
	        transform: translate3d(-14.80px, 0.00px, 0.00px) rotate(105.30deg) scale(1, 1);
	        opacity: 0.10;
	    }
	    74.65% {
	        transform: translate3d(-22.21px, -0.00px, 0.00px) rotate(99.45deg) scale(1, 1);
	        opacity: 0.15;
	    }
	    76.06% {
	        transform: translate3d(-29.61px, 0.00px, 0.00px) rotate(93.60deg) scale(1, 1);
	        opacity: 0.20;
	    }
	    77.46% {
	        transform: translate3d(-37.00px, -0.00px, 0.00px) rotate(87.75deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    78.87% {
	        transform: translate3d(-44.40px, 0.00px, 0.00px) rotate(81.90deg) scale(1, 1);
	        opacity: 0.30;
	    }
	    80.28% {
	        transform: translate3d(-51.80px, 0.00px, 0.00px) rotate(76.05deg) scale(1, 1);
	        opacity: 0.35;
	    }
	    81.69% {
	        transform: translate3d(-59.20px, 0.00px, 0.00px) rotate(70.20deg) scale(1, 1);
	        opacity: 0.40;
	    }
	    83.10% {
	        transform: translate3d(-66.60px, -0.00px, 0.00px) rotate(64.35deg) scale(1, 1);
	        opacity: 0.45;
	    }
	    84.51% {
	        transform: translate3d(-74.00px, 0.00px, 0.00px) rotate(58.50deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    85.92% {
	        transform: translate3d(-81.40px, 0.00px, 0.00px) rotate(52.65deg) scale(1, 1);
	        opacity: 0.55;
	    }
	    87.32% {
	        transform: translate3d(-88.80px, 0.00px, 0.00px) rotate(46.80deg) scale(1, 1);
	        opacity: 0.60;
	    }
	    88.73% {
	        transform: translate3d(-96.20px, 0.00px, 0.00px) rotate(40.95deg) scale(1, 1);
	        opacity: 0.65;
	    }
	    90.14% {
	        transform: translate3d(-103.60px, 0.00px, 0.00px) rotate(35.10deg) scale(1, 1);
	        opacity: 0.70;
	    }
	    91.55% {
	        transform: translate3d(-111.00px, 0.00px, 0.00px) rotate(29.25deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    92.96% {
	        transform: translate3d(-118.39px, 0.00px, 0.00px) rotate(23.40deg) scale(1, 1);
	        opacity: 0.80;
	    }
	    94.37% {
	        transform: translate3d(-125.80px, 0.00px, 0.00px) rotate(17.55deg) scale(1, 1);
	        opacity: 0.85;
	    }
	    95.77% {
	        transform: translate3d(-133.20px, 0.00px, 0.00px) rotate(11.70deg) scale(1, 1);
	        opacity: 0.90;
	    }
	    97.18% {
	        transform: translate3d(-140.59px, 0.00px, 0.00px) rotate(5.85deg) scale(1, 1);
	        opacity: 0.95;
	    }
	    98.59% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class6 {
	    left: 728.00px;
	    top: 760.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(117.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG6 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/he.jpg");
	}
	.BX_Ani6 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey6 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey6 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(117.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    71.83% {
	        transform: translate3d(-7.41px, 0.00px, 0.00px) rotate(111.15deg) scale(1, 1);
	        opacity: 0.05;
	    }
	    73.24% {
	        transform: translate3d(-14.80px, 0.00px, 0.00px) rotate(105.30deg) scale(1, 1);
	        opacity: 0.10;
	    }
	    74.65% {
	        transform: translate3d(-22.21px, -0.00px, 0.00px) rotate(99.45deg) scale(1, 1);
	        opacity: 0.15;
	    }
	    76.06% {
	        transform: translate3d(-29.61px, 0.00px, 0.00px) rotate(93.60deg) scale(1, 1);
	        opacity: 0.20;
	    }
	    77.46% {
	        transform: translate3d(-37.00px, 0.00px, 0.00px) rotate(87.75deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    78.87% {
	        transform: translate3d(-44.40px, 0.00px, 0.00px) rotate(81.90deg) scale(1, 1);
	        opacity: 0.30;
	    }
	    80.28% {
	        transform: translate3d(-51.80px, 0.00px, 0.00px) rotate(76.05deg) scale(1, 1);
	        opacity: 0.35;
	    }
	    81.69% {
	        transform: translate3d(-59.20px, 0.00px, 0.00px) rotate(70.20deg) scale(1, 1);
	        opacity: 0.40;
	    }
	    83.10% {
	        transform: translate3d(-66.60px, 0.00px, 0.00px) rotate(64.35deg) scale(1, 1);
	        opacity: 0.45;
	    }
	    84.51% {
	        transform: translate3d(-74.00px, 0.00px, 0.00px) rotate(58.50deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    85.92% {
	        transform: translate3d(-81.40px, 0.00px, 0.00px) rotate(52.65deg) scale(1, 1);
	        opacity: 0.55;
	    }
	    87.32% {
	        transform: translate3d(-88.80px, 0.00px, 0.00px) rotate(46.80deg) scale(1, 1);
	        opacity: 0.60;
	    }
	    88.73% {
	        transform: translate3d(-96.20px, 0.00px, 0.00px) rotate(40.95deg) scale(1, 1);
	        opacity: 0.65;
	    }
	    90.14% {
	        transform: translate3d(-103.60px, 0.00px, 0.00px) rotate(35.10deg) scale(1, 1);
	        opacity: 0.70;
	    }
	    91.55% {
	        transform: translate3d(-111.00px, 0.00px, 0.00px) rotate(29.25deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    92.96% {
	        transform: translate3d(-118.39px, 0.00px, 0.00px) rotate(23.40deg) scale(1, 1);
	        opacity: 0.80;
	    }
	    94.37% {
	        transform: translate3d(-125.80px, 0.00px, 0.00px) rotate(17.55deg) scale(1, 1);
	        opacity: 0.85;
	    }
	    95.77% {
	        transform: translate3d(-133.20px, 0.00px, 0.00px) rotate(11.70deg) scale(1, 1);
	        opacity: 0.90;
	    }
	    97.18% {
	        transform: translate3d(-140.59px, 0.00px, 0.00px) rotate(5.85deg) scale(1, 1);
	        opacity: 0.95;
	    }
	    98.59% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class5 {
	    left: 728.00px;
	    top: 660.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(117.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG5 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/qi.jpg");
	}
	.BX_Ani5 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey5 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey5 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(117.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    71.83% {
	        transform: translate3d(-7.41px, 0.00px, 0.00px) rotate(111.15deg) scale(1, 1);
	        opacity: 0.05;
	    }
	    73.24% {
	        transform: translate3d(-14.80px, 0.00px, 0.00px) rotate(105.30deg) scale(1, 1);
	        opacity: 0.10;
	    }
	    74.65% {
	        transform: translate3d(-22.21px, 0.00px, 0.00px) rotate(99.45deg) scale(1, 1);
	        opacity: 0.15;
	    }
	    76.06% {
	        transform: translate3d(-29.61px, 0.00px, 0.00px) rotate(93.60deg) scale(1, 1);
	        opacity: 0.20;
	    }
	    77.46% {
	        transform: translate3d(-37.00px, -0.00px, 0.00px) rotate(87.75deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    78.87% {
	        transform: translate3d(-44.40px, 0.00px, 0.00px) rotate(81.90deg) scale(1, 1);
	        opacity: 0.30;
	    }
	    80.28% {
	        transform: translate3d(-51.80px, 0.00px, 0.00px) rotate(76.05deg) scale(1, 1);
	        opacity: 0.35;
	    }
	    81.69% {
	        transform: translate3d(-59.20px, 0.00px, 0.00px) rotate(70.20deg) scale(1, 1);
	        opacity: 0.40;
	    }
	    83.10% {
	        transform: translate3d(-66.60px, -0.00px, 0.00px) rotate(64.35deg) scale(1, 1);
	        opacity: 0.45;
	    }
	    84.51% {
	        transform: translate3d(-74.00px, 0.00px, 0.00px) rotate(58.50deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    85.92% {
	        transform: translate3d(-81.40px, 0.00px, 0.00px) rotate(52.65deg) scale(1, 1);
	        opacity: 0.55;
	    }
	    87.32% {
	        transform: translate3d(-88.80px, 0.00px, 0.00px) rotate(46.80deg) scale(1, 1);
	        opacity: 0.60;
	    }
	    88.73% {
	        transform: translate3d(-96.20px, 0.00px, 0.00px) rotate(40.95deg) scale(1, 1);
	        opacity: 0.65;
	    }
	    90.14% {
	        transform: translate3d(-103.60px, 0.00px, 0.00px) rotate(35.10deg) scale(1, 1);
	        opacity: 0.70;
	    }
	    91.55% {
	        transform: translate3d(-111.00px, 0.00px, 0.00px) rotate(29.25deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    92.96% {
	        transform: translate3d(-118.39px, 0.00px, 0.00px) rotate(23.40deg) scale(1, 1);
	        opacity: 0.80;
	    }
	    94.37% {
	        transform: translate3d(-125.80px, 0.00px, 0.00px) rotate(17.55deg) scale(1, 1);
	        opacity: 0.85;
	    }
	    95.77% {
	        transform: translate3d(-133.20px, 0.00px, 0.00px) rotate(11.70deg) scale(1, 1);
	        opacity: 0.90;
	    }
	    97.18% {
	        transform: translate3d(-140.59px, -0.00px, 0.00px) rotate(5.85deg) scale(1, 1);
	        opacity: 0.95;
	    }
	    98.59% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class4 {
	    left: 728.00px;
	    top: 560.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(117.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG4 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/guo.jpg");
	}
	.BX_Ani4 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey4 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey4 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(117.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    71.83% {
	        transform: translate3d(-7.41px, 0.00px, 0.00px) rotate(111.15deg) scale(1, 1);
	        opacity: 0.05;
	    }
	    73.24% {
	        transform: translate3d(-14.80px, 0.00px, 0.00px) rotate(105.30deg) scale(1, 1);
	        opacity: 0.10;
	    }
	    74.65% {
	        transform: translate3d(-22.21px, -0.00px, 0.00px) rotate(99.45deg) scale(1, 1);
	        opacity: 0.15;
	    }
	    76.06% {
	        transform: translate3d(-29.61px, 0.00px, 0.00px) rotate(93.60deg) scale(1, 1);
	        opacity: 0.20;
	    }
	    77.46% {
	        transform: translate3d(-37.00px, 0.00px, 0.00px) rotate(87.75deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    78.87% {
	        transform: translate3d(-44.40px, 0.00px, 0.00px) rotate(81.90deg) scale(1, 1);
	        opacity: 0.30;
	    }
	    80.28% {
	        transform: translate3d(-51.80px, 0.00px, 0.00px) rotate(76.05deg) scale(1, 1);
	        opacity: 0.35;
	    }
	    81.69% {
	        transform: translate3d(-59.20px, 0.00px, 0.00px) rotate(70.20deg) scale(1, 1);
	        opacity: 0.40;
	    }
	    83.10% {
	        transform: translate3d(-66.60px, 0.00px, 0.00px) rotate(64.35deg) scale(1, 1);
	        opacity: 0.45;
	    }
	    84.51% {
	        transform: translate3d(-74.00px, 0.00px, 0.00px) rotate(58.50deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    85.92% {
	        transform: translate3d(-81.40px, 0.00px, 0.00px) rotate(52.65deg) scale(1, 1);
	        opacity: 0.55;
	    }
	    87.32% {
	        transform: translate3d(-88.80px, 0.00px, 0.00px) rotate(46.80deg) scale(1, 1);
	        opacity: 0.60;
	    }
	    88.73% {
	        transform: translate3d(-96.20px, 0.00px, 0.00px) rotate(40.95deg) scale(1, 1);
	        opacity: 0.65;
	    }
	    90.14% {
	        transform: translate3d(-103.60px, 0.00px, 0.00px) rotate(35.10deg) scale(1, 1);
	        opacity: 0.70;
	    }
	    91.55% {
	        transform: translate3d(-111.00px, 0.00px, 0.00px) rotate(29.25deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    92.96% {
	        transform: translate3d(-118.39px, 0.00px, 0.00px) rotate(23.40deg) scale(1, 1);
	        opacity: 0.80;
	    }
	    94.37% {
	        transform: translate3d(-125.80px, 0.00px, 0.00px) rotate(17.55deg) scale(1, 1);
	        opacity: 0.85;
	    }
	    95.77% {
	        transform: translate3d(-133.20px, 0.00px, 0.00px) rotate(11.70deg) scale(1, 1);
	        opacity: 0.90;
	    }
	    97.18% {
	        transform: translate3d(-140.59px, 0.00px, 0.00px) rotate(5.85deg) scale(1, 1);
	        opacity: 0.95;
	    }
	    98.59% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class3 {
	    left: 728.00px;
	    top: 460.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(117.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG3 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/yu.jpg");
	}
	.BX_Ani3 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey3 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey3 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(117.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    71.83% {
	        transform: translate3d(-7.41px, 0.00px, 0.00px) rotate(111.15deg) scale(1, 1);
	        opacity: 0.05;
	    }
	    73.24% {
	        transform: translate3d(-14.80px, 0.00px, 0.00px) rotate(105.30deg) scale(1, 1);
	        opacity: 0.10;
	    }
	    74.65% {
	        transform: translate3d(-22.21px, -0.00px, 0.00px) rotate(99.45deg) scale(1, 1);
	        opacity: 0.15;
	    }
	    76.06% {
	        transform: translate3d(-29.61px, 0.00px, 0.00px) rotate(93.60deg) scale(1, 1);
	        opacity: 0.20;
	    }
	    77.46% {
	        transform: translate3d(-37.00px, -0.00px, 0.00px) rotate(87.75deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    78.87% {
	        transform: translate3d(-44.40px, 0.00px, 0.00px) rotate(81.90deg) scale(1, 1);
	        opacity: 0.30;
	    }
	    80.28% {
	        transform: translate3d(-51.80px, 0.00px, 0.00px) rotate(76.05deg) scale(1, 1);
	        opacity: 0.35;
	    }
	    81.69% {
	        transform: translate3d(-59.20px, 0.00px, 0.00px) rotate(70.20deg) scale(1, 1);
	        opacity: 0.40;
	    }
	    83.10% {
	        transform: translate3d(-66.60px, -0.00px, 0.00px) rotate(64.35deg) scale(1, 1);
	        opacity: 0.45;
	    }
	    84.51% {
	        transform: translate3d(-74.00px, 0.00px, 0.00px) rotate(58.50deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    85.92% {
	        transform: translate3d(-81.40px, -0.00px, 0.00px) rotate(52.65deg) scale(1, 1);
	        opacity: 0.55;
	    }
	    87.32% {
	        transform: translate3d(-88.80px, 0.00px, 0.00px) rotate(46.80deg) scale(1, 1);
	        opacity: 0.60;
	    }
	    88.73% {
	        transform: translate3d(-96.20px, 0.00px, 0.00px) rotate(40.95deg) scale(1, 1);
	        opacity: 0.65;
	    }
	    90.14% {
	        transform: translate3d(-103.60px, 0.00px, 0.00px) rotate(35.10deg) scale(1, 1);
	        opacity: 0.70;
	    }
	    91.55% {
	        transform: translate3d(-111.00px, 0.00px, 0.00px) rotate(29.25deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    92.96% {
	        transform: translate3d(-118.39px, 0.00px, 0.00px) rotate(23.40deg) scale(1, 1);
	        opacity: 0.80;
	    }
	    94.37% {
	        transform: translate3d(-125.80px, 0.00px, 0.00px) rotate(17.55deg) scale(1, 1);
	        opacity: 0.85;
	    }
	    95.77% {
	        transform: translate3d(-133.20px, 0.00px, 0.00px) rotate(11.70deg) scale(1, 1);
	        opacity: 0.90;
	    }
	    97.18% {
	        transform: translate3d(-140.59px, 0.00px, 0.00px) rotate(5.85deg) scale(1, 1);
	        opacity: 0.95;
	    }
	    98.59% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class2 {
	    left: 728.00px;
	    top: 360.00px;
	    width: 80px;
	    height: 80px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(117.00deg) scale(1.00, 1.00);
	    opacity: 0.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG2 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/wo.jpg");
	}
	.BX_Ani2 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey2 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey2 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(117.00deg) scale(1, 1);
	        opacity: 0.00;
	    }
	    71.83% {
	        transform: translate3d(-7.41px, 0.00px, 0.00px) rotate(111.15deg) scale(1, 1);
	        opacity: 0.05;
	    }
	    73.24% {
	        transform: translate3d(-14.80px, 0.00px, 0.00px) rotate(105.30deg) scale(1, 1);
	        opacity: 0.10;
	    }
	    74.65% {
	        transform: translate3d(-22.21px, -0.00px, 0.00px) rotate(99.45deg) scale(1, 1);
	        opacity: 0.15;
	    }
	    76.06% {
	        transform: translate3d(-29.61px, 0.00px, 0.00px) rotate(93.60deg) scale(1, 1);
	        opacity: 0.20;
	    }
	    77.46% {
	        transform: translate3d(-37.00px, 0.00px, 0.00px) rotate(87.75deg) scale(1, 1);
	        opacity: 0.25;
	    }
	    78.87% {
	        transform: translate3d(-44.40px, 0.00px, 0.00px) rotate(81.90deg) scale(1, 1);
	        opacity: 0.30;
	    }
	    80.28% {
	        transform: translate3d(-51.80px, 0.00px, 0.00px) rotate(76.05deg) scale(1, 1);
	        opacity: 0.35;
	    }
	    81.69% {
	        transform: translate3d(-59.20px, 0.00px, 0.00px) rotate(70.20deg) scale(1, 1);
	        opacity: 0.40;
	    }
	    83.10% {
	        transform: translate3d(-66.60px, 0.00px, 0.00px) rotate(64.35deg) scale(1, 1);
	        opacity: 0.45;
	    }
	    84.51% {
	        transform: translate3d(-74.00px, 0.00px, 0.00px) rotate(58.50deg) scale(1, 1);
	        opacity: 0.50;
	    }
	    85.92% {
	        transform: translate3d(-81.40px, 0.00px, 0.00px) rotate(52.65deg) scale(1, 1);
	        opacity: 0.55;
	    }
	    87.32% {
	        transform: translate3d(-88.80px, 0.00px, 0.00px) rotate(46.80deg) scale(1, 1);
	        opacity: 0.60;
	    }
	    88.73% {
	        transform: translate3d(-96.20px, 0.00px, 0.00px) rotate(40.95deg) scale(1, 1);
	        opacity: 0.65;
	    }
	    90.14% {
	        transform: translate3d(-103.60px, 0.00px, 0.00px) rotate(35.10deg) scale(1, 1);
	        opacity: 0.70;
	    }
	    91.55% {
	        transform: translate3d(-111.00px, 0.00px, 0.00px) rotate(29.25deg) scale(1, 1);
	        opacity: 0.75;
	    }
	    92.96% {
	        transform: translate3d(-118.39px, 0.00px, 0.00px) rotate(23.40deg) scale(1, 1);
	        opacity: 0.80;
	    }
	    94.37% {
	        transform: translate3d(-125.80px, 0.00px, 0.00px) rotate(17.55deg) scale(1, 1);
	        opacity: 0.85;
	    }
	    95.77% {
	        transform: translate3d(-133.20px, 0.00px, 0.00px) rotate(11.70deg) scale(1, 1);
	        opacity: 0.90;
	    }
	    97.18% {
	        transform: translate3d(-140.59px, 0.00px, 0.00px) rotate(5.85deg) scale(1, 1);
	        opacity: 0.95;
	    }
	    98.59% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	    100.00% {
	        transform: translate3d(-148.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1, 1);
	        opacity: 1.00;
	    }
	}
	.BX_Class1 {
	    left: 0.00px;
	    top: 320.00px;
	    width: 360px;
	    height: 640px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(2.00, 2.00, 2.00);
	    opacity: 1.00;
	    transform-origin:0.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG1 {
	    background: url("http://resali.lititutu.cn/scenes/guoqingheying/cover.jpg");
	}
	.BX_Ani1 {
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation:BX_AniKey1 3.55s linear 0.00s 1 normal forwards;
	}
	@keyframes BX_AniKey1 {
	    0.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(2, 2, 2);
	    }
	    43.65% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(2, 2, 2);
	    }
	    80.42% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotateX(-0.00deg) rotateY(-90.00deg) rotateZ(0.00deg) scale3d(2, 2, 2);
	    }
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotateX(-0.00deg) rotateY(-90.00deg) rotateZ(0.00deg) scale3d(2, 2, 2);
	    }
	}
</style>