<template>
	<div id="bganim2"></div>	
</template>

<script>
	/* eslint-disable */

	export default {
		name: 'shuguangdengta',
		data () {
			return {}
		}
	}
</script>

<style>
	
</style>