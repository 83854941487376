<template>
	<div class="poc hengfengche">
	    <div class="po hengfengche_per" style="width: 480px; height: 430px;">
	        <div class="preserve3d poc">
	            <div class="hengfengche_rotY preserve3d poc">
	                <div class="hengfengche_1_1 preserve3d poc">
	                    <div class="hengfengche_1_F poc">
	                        <div class="hengfengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url(' + getBorder + ')'}"></div>
	                        <div class="hengfengche_left bg_photo hengfengche_div bg po" :style="getStyle">
								<div class="poc" style="transform: scaleX(-1);">
									<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
								</div>
							</div>
	                        <div class="hengfengche_right bg_photo hengfengche_div bg po" :style="getStyle">
								<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
							</div>
	                    </div>
	                    <div class="hengfengche_border_l hengfengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ getBorder +')'}"></div>
	                    <div class="hengfengche_border_r hengfengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ getBorder +')'}"></div>
	                    <div class="hengfengche_1_B page border_bg po">
	                        <div class="hengfengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url(' + getBorder + ')'}"></div>
	                        <div class="hengfengche_left bg_photo hengfengche_div bg po" :style="getStyle">
								<div class="poc" style="transform: scaleX(-1);">
									<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
								</div>
							</div>
	                        <div class="hengfengche_right bg_photo hengfengche_div bg po" :style="getStyle">
								<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
							</div>
	                    </div>
	                </div>
	                <div class="hengfengche_1_2 page preserve3d po">
	                    <div class="hengfengche_1_F page border_bg po">
	                        <div class="hengfengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url(' + getBorder + ')'}"></div>
	                        <div class="hengfengche_left bg_photo hengfengche_div bg po" :style="getStyle">
								<div class="poc" style="transform: scaleX(-1);">
									<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
								</div>
							</div>
	                        <div class="hengfengche_right bg_photo hengfengche_div bg po" :style="getStyle">
								<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
							</div>
	                    </div>
	                    <div class="hengfengche_border_l hengfengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ getBorder +')'}"></div>
	                    <div class="hengfengche_border_r hengfengche_kuang_border po" :style="{height: borderHeight, backgroundImage: 'url('+ getBorder +')'}"></div>
	                    <div class="hengfengche_1_B page border_bg po">
	                        <div class="hengfengche_kuang_bg poc" :style="{height: borderHeight, backgroundImage: 'url(' + getBorder + ')'}"></div>
	                        <div class="hengfengche_left bg_photo hengfengche_div bg po" :style="getStyle">
								<div class="poc" style="transform: scaleX(-1);">
									<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
								</div>
							</div>
	                        <div class="hengfengche_right bg_photo hengfengche_div bg po" :style="getStyle">
								<elem v-if="comp_config[1]" :mode="comp_config[1]"></elem>
							</div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'hengfengche',
        extends: BaseElem,
		data () {
			return {
				borderHeight: '100%'
			}
		},
		computed: {
            getBorder () {
                return this.comp_config[0] || "http://resliti.lititutu.cn/icons/tuku/DD3F288D79A39F22D2C8F718A7CAD588.jpeg";
            },
			getStyle () {
                let tsize = this.$util.getphotoboxsize(this.currentPhoto['orisize'], [240, 430], 'widthFix');

                this.borderHeight = (tsize[1] + 12) + 'px';

                return {
                    width: tsize[0] + 'px',
                    height: tsize[1] + 'px',
                    backgroundImage: 'url('+ this.currentPhoto['im'] +')'
                }
			}
		},
		methods: {
		
		}
	}
</script>

<style>
	.hengfengche_per {
	    -webkit-perspective: 800px;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 30px;
	}
	.hengfengche_rotY {
	    -webkit-animation: hengfengche_rotY 9s linear infinite both;
	}
	@keyframes hengfengche_rotY {
		0% {
		    -webkit-transform: rotateY(-90deg);
		}
		100% {
		    -webkit-transform: rotateY(270deg);
		}
	}
	.hengfengche_1_1 {}
	.hengfengche_1_2 {
		-webkit-transform: rotateY(90deg);
	}
	.hengfengche_1_F {
	    -webkit-transform: translateZ(-5px) rotateY(180deg);
	}
	.hengfengche_1_B {
		-webkit-transform: translateZ(5px);
	}
	.hengfengche_kuang_bg {
	    background-size: 100% 100%;
	    top: 0;
	    bottom: 0;
	    margin: auto;
	}
	.hengfengche_left {
		margin: auto;
		left: 5px;
		top: 0;
		bottom: 0;
	}
	.hengfengche_right {
		margin: auto;
		right: 5px;
		top: 0;
		bottom: 0;
	}
	.hengfengche_div {
		background-color: #FFFFFF;
		width: 47%;
		height: 98%;
	}
	.hengfengche_border_l {
		left: 0;
    	-webkit-transform: translateX(-5px) translateZ(0) rotateY(-90deg);
	}
	.hengfengche_kuang_border {
		width: 10px;
		background-size: auto 100%;
		background-position: 0 0;
		backface-visibility: hidden;
		top: 0;
	    bottom: 0;
	    margin: auto;
	}
	.hengfengche_border_r {
		right: 0;
    	-webkit-transform: translateX(5px) translateZ(0) rotateY(90deg);
	}
</style>

























