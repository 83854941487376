<!-- [
    "http://resali.lititutu.cn/scenes/shoubinanshan3d/gif_1.gif",
    {
        "middle": {
            "width": 403.5,
            "pos": [
                120,
                null,
                null,
                48.25
            ],
            "height": 564,
            "sprite_image": {
                "im": "http://resali.lititutu.cn/scenes/shoubinanshan3d/sprite_2_18f.png",
                "steps": 18
            },
            "duration": 2.5,
            "backgroundWidth": 403.5,
            "backgroundHeight": 10152
        },
        "border": {
            "l_im": "http://resali.lititutu.cn/icons/tuku/44B952C5578E7C5DF56EDE53BA5CCAD8.jpeg",
            "im": "http://resali.lititutu.cn/icons/tuku/1865A9F0F9A7D59C910835C7713EC6F2.jpeg",
            "r_im": "http://resali.lititutu.cn/icons/tuku/B0DCCD2D3EF495438940293534C11A6F.jpeg"
        },
        "rotateDuration": "18"
    }
] -->
<template>
	<div class="poc yuanzhuan">
	    <!-- <canvas id="yuanzhuan_canvas" width="500" height="815" class="fullScreen po"></canvas> -->
	    <div class="perspective poc">
	        <div class="yuanzhuan_1 preserve3d poc">
	        	<gif v-if="mode.length > 1 && mode[1]['middle']" :mode="mode[1]['middle']"></gif>
	            <!-- <canvas id="yuanzhuan_trun_canvas" width="535" height="594" class="po"></canvas> -->
	            <div class="preserve3d poc" :class="getDir" :style="getCss4">
	                <div class="yuanzhuan_item1 preserve3d poc">
	                    <div class="yuanzhuan_item preserve3d po" :style="getStyle">
	                        <div class="yuanzhuan_A yuanzhuan_div poc bg" :style="getCss" @click="wxPreview">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower1 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower2 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower3 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower4 po">
	                        </div>
	                        <div class="yuanzhuan_F yuanzhuan_div poc bg" :style="getCss" @click="wxPreview">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower1 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower2 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower3 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower4 po">
	                        </div>
	                        <div class="yuanzhuan_L yuanzhuan_l po" :style="getCss2"></div>
	                        <div class="yuanzhuan_R yuanzhuan_r po" :style="getCss3"></div>
	                    </div>
	                </div>
	                <div class="yuanzhuan_item2 preserve3d poc">
	                    <div class="yuanzhuan_item preserve3d po" :style="getStyle">
	                        <div class="yuanzhuan_A yuanzhuan_div poc bg" :style="getCss" @click="wxPreview">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower1 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower2 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower3 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower4 po">
	                        </div>
	                        <div class="yuanzhuan_F yuanzhuan_div poc bg" :style="getCss" @click="wxPreview">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower1 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower2 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower3 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower4 po">
	                        </div>
	                        <div class="yuanzhuan_L yuanzhuan_l po" :style="getCss2"></div>
	                        <div class="yuanzhuan_R yuanzhuan_r po" :style="getCss3"></div>
	                    </div>
	                </div>
	                <div class="yuanzhuan_item3 preserve3d poc">
	                    <div class="yuanzhuan_item preserve3d po" :style="getStyle">
	                        <div class="yuanzhuan_A yuanzhuan_div poc bg" :style="getCss" @click="wxPreview">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower1 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower2 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower3 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower4 po">
	                        </div>
	                        <div class="yuanzhuan_F yuanzhuan_div poc bg" :style="getCss" @click="wxPreview">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower1 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower2 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower3 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower4 po">
	                        </div>
	                        <div class="yuanzhuan_L yuanzhuan_l po" :style="getCss2"></div>
	                        <div class="yuanzhuan_R yuanzhuan_r po" :style="getCss3"></div>
	                    </div>
	                </div>
	                <div class="yuanzhuan_item4 preserve3d poc">
	                    <div class="yuanzhuan_item preserve3d po" :style="getStyle">
	                        <div class="yuanzhuan_A yuanzhuan_div poc bg" :style="getCss" @click="wxPreview"></div>
	                        <div class="yuanzhuan_F yuanzhuan_div poc bg" :style="getCss" @click="wxPreview">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower1 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower2 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower3 po">
	                            <img v-if="mode.length > 1 && mode[1]['flower']" :src="mode[1]['flower']" class="yuanzhuan_flower4 po">
	                        </div>
	                        <div class="yuanzhuan_L yuanzhuan_l po" :style="getCss2"></div>
	                        <div class="yuanzhuan_R yuanzhuan_r po" :style="getCss3"></div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>export default {
		name: 'yuanzhuan',
		props: ['mode', 'currentImage','showStatus'],
		components: {},
		data () {
			return {
			}
		},
		computed: {
			getDir () {
				if (this.mode.length == 1 || !this.mode[1]['dir']) {
					return {'yuanzhuan_1_1': true}
				}
				if (this.mode[1]['dir'] == 1) {
					return {'yuanzhuan_1_1': true}
				}
				if (this.mode[1]['dir'] == 2) {
					return {'yuanzhuan_1_2': true}
				}
			},
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [500, 800], 'widthFix');

					return {
						width: tsize[0]+'px',
						height: tsize[1]+'px',
						marginTop: (0-(tsize[1]/2))+'px'
					}
				} else {
					return {
						width: '500px',
						height: '375px',
						marginTop: '-187.5px'
					}
				}
			},
			getCss () {
				let css = {};
				css['backgroundImage'] = 'url('+this.currentImage['im']+')';
				if (this.mode.length > 1 && this.mode[1]['border'] && this.mode[1]['border']['im']) {
					css['borderImageSource'] = 'url('+this.mode[1]['border']['im']+')';
				} else {
					css['borderImageSource'] = 'url(http://resali.lititutu.cn/icons/other/border.jpg)';
				}
				return css;
			},
			getCss2 () {
				let css = {};
				if (this.mode.length > 1 && this.mode[1]['border'] && this.mode[1]['border']['l_im']) {
					css['backgroundImage'] = 'url('+this.mode[1]['border']['l_im']+')';
				} else {
					css['backgroundImage'] = 'url(http://resali.lititutu.cn/icons/other/borderL.png)';
				}
				return css;
			},
			getCss3 () {
				let css = {};
				if (this.mode.length > 1 && this.mode[1]['border'] && this.mode[1]['border']['r_im']) {
					css['backgroundImage'] = 'url('+this.mode[1]['border']['r_im']+')';
				} else {
					css['backgroundImage'] = 'url(http://resali.lititutu.cn/icons/other/borderR.png)';
				}
				return css;
			},
			getCss4 () {
				let css = {};
				css['animationDuration'] = this.mode.length>1 && this.mode[1]['rotateDuration'] ? this.mode[1]['rotateDuration'] + 's' : '7s';
				return css;
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style>
	.yuanzhuan_1 {
	    -webkit-transform: translateZ(-900px) translateY(0px);
	}
	.yuanzhuan_1_1 {
	    -webkit-animation: yuanzhuan_1_1 linear infinite both;
	}
	.yuanzhuan_1_2 {
	    -webkit-animation: yuanzhuan_1_2 linear infinite both;
	}
	@keyframes yuanzhuan_1_2 {
		0% {
		    -webkit-transform: rotateY(0);
		}
		100% {
		    -webkit-transform: rotateY(360deg);
		}
	}
	@keyframes yuanzhuan_1_1 {
		0% {
		    -webkit-transform: rotateY(360deg);
		}
		100% {
		    -webkit-transform: rotateY(0);
		}
	}
	.yuanzhuan_item1, .yuanzhuan_item2, .yuanzhuan_item3, .yuanzhuan_item4 {
	    -webkit-transform-origin: right;
	}
	.yuanzhuan_item1 {
	    -webkit-transform: translateX(-260px) rotateY(0deg);
	}
	.yuanzhuan_item {
	    margin: auto;
	    top: 50%;
	    left: 0;
	    right: 0;
	    -webkit-transform: rotateY(90deg) translateZ(680px);
	    background-color: #eee;
	}
	.yuanzhuan_div {
	    border: 6px solid;
	    /*border-image: url(http://resali.lititutu.cn/icons/other/border.jpg);*/
	    border-image-slice: 6;
	    background-size: cover;
	    background-clip: content-box;
	    transition: all .5s both;
	    background-color: #eee;
	}
	.yuanzhuan_A {
	    -webkit-transform: translateZ(-10px);
	}
	.yuanzhuan_flower1, .yuanzhuan_flower2, .yuanzhuan_flower3, .yuanzhuan_flower4 {
		width: 150px;
	}
	.yuanzhuan_flower1 {
	    -webkit-transform: rotateX(180deg);
	    top: -50px;
	    left: -50px;
	}
	.yuanzhuan_flower2 {
	    -webkit-transform: rotateZ(180deg);
	    top: -50px;
	    right: -50px;
	}
	.yuanzhuan_flower3 {
	    bottom: -50px;
	    left: -50px;
	}
	.yuanzhuan_flower4 {
	    -webkit-transform: rotateY(180deg);
	    bottom: -50px;
	    right: -50px;
	}
	.yuanzhuan_F {
	    -webkit-transform: translateZ(10px);
	}
	.yuanzhuan_L {
	    -webkit-transform: rotateY(90deg);
	}
	.yuanzhuan_l {
	    /*background-image: url(http://resali.lititutu.cn/icons/other/borderL.png);*/
	    left: -10px;
	}
	.yuanzhuan_l, .yuanzhuan_r {
	    width: 22px;
	    height: 102%;
	    background-size: auto 100%;
	}
	.yuanzhuan_R {
	    -webkit-transform: rotateY(-90deg);
	}
	.yuanzhuan_r {
	    /*background-image: url(http://resali.lititutu.cn/icons/other/borderR.png);*/
	    right: -22px;
	}
	.yuanzhuan_item2 {
	    -webkit-transform: translateX(-260px) rotateY(90deg);
	}
	.yuanzhuan_item3 {
	    -webkit-transform: translateX(-260px) rotateY(180deg);
	}
	.yuanzhuan_item4 {
	    -webkit-transform: translateX(-260px) rotateY(270deg);
	}
	.yuanzhuan .perspective {
	    -webkit-perspective: 1000px!important;
	}
</style>








