<template>
	<div class="st-denglong-container page bg">
		<div class="st-denglong_contain">
			<div class="st-denglong_box st-denglong_box4">
				<div class="st-denglong_box float-x">
					<div class="st-denglong_box float-y">
				        <div class="st-denglong_front user" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				            <div class="st_user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
				            <div class="st_user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
				            <div class="st_user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
				            <div class="st_user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
				            <div class="st_user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
				        </div>
				        <div class="st-denglong_back user" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				            <div class="st_user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
				            <div class="st_user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
				            <div class="st_user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
				            <div class="st_user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
				            <div class="st_user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
				        </div>
				        <div class="st-denglong_left user" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				            <div class="st_user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
				            <div class="st_user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
				            <div class="st_user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
				            <div class="st_user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
				            <div class="st_user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
				        </div>
				        <div class="st-denglong_right user" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}">
				            <div class="st_user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
				            <div class="st_user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
				            <div class="st_user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
				            <div class="st_user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
				            <div class="st_user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
				        </div>
					</div>
				</div>
		    </div>	
	    </div>	
	</div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

	export default {
		name: 'st-Denglong',
		extends: BaseElem,
		data () {
			return {}
		}
	}
</script>

<style>
	.st-denglong-container {
	    -webkit-perspective: 694.5px;
	}
	.st-denglong-bg {
		opacity: 0;
	}
	.st-denglong-bg_in {
		animation: fadeIn .6s both;
	}
	.st-denglong-bg_out {
		animation: fadeOut .6s both;
	}

	.st-denglong_bgGif {
	    position: absolute;
	    top: 0;
	}
	.st-denglong_bg, .st-denglong_bgGif {
	    height: 100%;
	    width: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    background: no-repeat center;
	    background-size: cover;
	}
	.perspe {
	    perspective: 694.5px;
	    width: 100%;
	    height: 100%;
	    position: absolute
	}
	.st-denglong_box {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    top: 0; left: 0;
	    transform-style: preserve-3d;
	}
	.st-denglong_contain {
		width: 278.0px;
	    height: 278.0px;
	    position: absolute;
	    transform-style: preserve-3d;
	    left: 50%;
	    top: 50%;
	    margin-left: -139.0px;
	    margin-top: -139.0px;
	    transform: translateZ(-3472.0px) scale(.2);
		animation: st-denglong_in 12s ease-in-out both;
		z-index: 2;
	}
	@keyframes st-denglong_in {
		0% {
			transform: translate3d(-500px, 500px, 0);
		}
		25% {
			transform: translate3d(0, 0, 0);
		}
		50% {
			transform: translate3d(0, 0, 0);
		}
		75% {
			transform: translate3d(0, 0, 0);
		}
		100% {
			transform: translate3d(500px, -500px, 0);
		}
	}
	.st-denglong_box4 {
	    animation: st-denglong_rotateBox 12s linear infinite
	}
	@keyframes st-denglong_rotateBox {
		0% {
	        transform: rotateY(0deg);
	    }
	    100% {
	        transform: rotateY(360deg)
	    }
	}
	.st-denglong_box3 {
	    transform: translateZ(-3472.0px);
	    left: -169%;
	    top: 181%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.st-denglong_box2 {
	    transform: translateZ(-3472.0px);
	    right: -200%;
	    top: -11%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.st-denglong_box1 {
	    transform: translateZ(-3472.0px);
	    left: -160%;
	    top: -109%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.st-denglong_front {
	    transform: rotateY(0deg) translateZ(139.0px);
	}
	.st-denglong_back {
	    transform: translateZ(-139.0px) rotateY(180deg);
	}
	.st-denglong_left {
	    transform: rotateY(90deg) translateZ(139.0px);
	}
	.st-denglong_right {
	    transform: rotateY(-90deg) translateZ(139.0px);
	}
	.st-denglong_top {
	    transform: rotateX(90deg) translateZ(139.0px);
	}
	.st-denglong_bot {
	    transform: rotateX(-90deg) translateZ(139.0px);
	}
	.user {
	    background: no-repeat center;
	    position: absolute;
	    width: 278.0px;
	    height: 278.0px;
		background-size: cover;
	}
	.st_user_border {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    background: no-repeat center;
	    background-size: cover
	}
	.st_user_border_left {
	    position: absolute;
	    width: 139.0px;
	    height: 278.0px;
	    left: -139.0px;
	    background: no-repeat right center;
	    background-size: auto 100%;
	}
	.st_user_border_right {
	    position: absolute;
	    width: 139.0px;
	    height: 278.0px;
	    right: -139.0px;
	    background: no-repeat left center;
	    background-size: auto 100%;
	}
	.st_user_border_top {
	    position: absolute;
	    width: 414.5px;
	    height: 208.5px;
	    left: 50%;
	    top: -208.5px;
	    transform: translate(-50%, 0%);
	    background: no-repeat bottom center;
	    background-size: 100% auto;
	}
	.st_user_border_bot {
	    position: absolute;
	    width: 414.5px;
	    height: 208.5px;
	    left: 50%;
	    bottom: -208.5px;
	    transform: translate(-50%, 0%);
	    background: no-repeat top center;
	    background-size: 100% auto;
	}
</style>