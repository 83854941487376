<template>
  <div
    class="flow-guide-container re-container "
    :class="show_flow_guide?'flowfadeindown':'flowfadeoutup'"
    :style="'right: ' + ($env.web == 'wxa'? '65':'10') + 'px;'">
    <img
      class="close-sub-btn"
      @click.stop="closeGuideBtn"
      src="http://resali.lititutu.cn/wxa_icon/btn/close2.png" />
    <span class="sub-text">点击</span>
    <img
      class="close-sub-btn"
      src="http://resali.lititutu.cn/wxa_icon/btn/more.png" />
    <span class="sub-text">收藏此相册</span>
    <div class="triangle"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "FlowGuide",
  props: ["show_flow_guide"],
  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    closeGuideBtn() {
      this.$emit("closeGuideBtn", {});
    },
  },
};
</script>

<style scoped lang="less">
.flowfadeindown{
  animation: flowfadeindown .2s linear 0s 1 normal both running
}
.flowfadeoutup{
  animation: flowfadeoutup .2s linear 0s 1 normal both running
}

.flow-guide-container {
  position: absolute;
  z-index: 9;
  top: 10px;
  background: rgba(24, 24, 24, .6);
  border-radius: @bw*0.052;
  padding: 6px 12px 6px 18px;
  justify-content: space-evenly;
  opacity: 0;

  .close-sub-btn {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  .sub-text {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0 6px;
  }
  .triangle {
    position: absolute;
    right: 30px;
    top: -15px;
    transform: rotate(0deg);
  }

  .triangle:before,
  .triangle:after {
    position: absolute;
    content: "";
    border-top: 8px transparent dashed;
    border-left: 8px transparent dashed;
    border-right: 8px transparent dashed;
    border-bottom: 8px rgba(24, 24, 24, .6) solid;
  }

  .triangle:before {
    border-bottom: 8px rgba(24, 24, 24, .6) solid;
  }

  .triangle:after {
    top: 1px;
    border-bottom: 8px rgba(24, 24, 24, .6) solid;
  }
}
@keyframes flowfadeindown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes flowfadeoutup {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>