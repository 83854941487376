<template>
	<div class="dongxin-box po">
        <div class="dongxin-elastic poc">
            <div class="dongxin-con poc">
                <!-- 边上 -->
                <div class="dongxin-div poc">
                    <div class="dongxin-circular poc">
                        <div class="dongxin-c po"
                            v-for="idx of 8"
                            :class="'dongxin-c_'+idx"
                            :key="'dongxin-c_'+idx">
                            <elem v-if="comp['elems'] && comp['elems'][1]" :canIShow="canIShow" :mode="comp['elems'][1]" :currentPhoto="currentPhoto"></elem>
                            <div v-else class="dongxin-xxin poc" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                        </div>
                    </div>
                </div>
                <!-- 中心区域 -->
                <div class="dongxin-div_2 poc">
                    <elem v-if="comp['elems'] && comp['elems'][0]" :canIShow="canIShow" :mode="comp['elems'][0]" :currentPhoto="currentPhoto"></elem>
                    <template v-else>
                        <div class="dongxin-yuan poc">
                            <div class="dongxin-yuan_b bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                        </div>
                        <img v-if="comp['config'] && comp['config'][0]" :src="comp['config'][0]" class="dongxin-border_2 po">
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'Dongxin',
        extends: BaseElem,
	}
</script>

<style>
	.dongxin-box {
		margin: auto;
		width: 500px;
		height: 500px;
		top: 0;
		bottom: 0;
		-webkit-perspective: 800px;
	}
	.dongxin-elastic {
		-webkit-animation: page3_scl_s 0.3s ease-in-out infinite alternate both;
		-webkit-transform-style: preserve-3d;
	}
	@keyframes page3_scl_s {
		0% {
		    -webkit-transform: translateZ(0px);
		}
		100% {
		    -webkit-transform: translateZ(20px);
		}
	}
	.dongxin-con {
		-webkit-transform: translateZ(50px);
	}
	@keyframes page3_rotZ {
		0% {
		    -webkit-transform: rotateZ(0);
		}
		100% {
		    -webkit-transform: rotateZ(360deg);
		}
	}
	.dongxin-div {
	    animation: 15000ms linear 0s infinite normal none running page3_rotZ;
	}
	.dongxin-xxin {
		-webkit-mask-image: url('http://resali.lititutu.cn/icons/mask/deamon.png');
		-webkit-mask-size: cover;
		overflow: hidden;
		background-size: cover;
		background-position: 50% 10%;
	}
	.dongxin-border {
		top: -3.5px;
		left: -4px;
	}
	.dongxin-div_2 {}
	.dongxin-yuan {
		margin: auto;
		width: 285px;
		height: 285px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50%;
		-webkit-transform: translateZ(0);
		overflow: hidden;
		background-size: cover;
		background-position: 50% 10%;
	}
	.dongxin-border_2 {
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 340px;
		height: 340px;
	}
	.dongxin-yuan_b {
		border-radius: 50%;
		overflow: hidden;
	}
	.dongxin-yuan_im {
		width: 306.04px;
		height: 285px; 
		animation: 5000ms linear 0s 1 normal both running page3_div1;
	}
	@keyframes page3_div1 {
		0% {
		    -webkit-transform: translate(0, -0px);
		}
		100% {
		    -webkit-transform: translate(-21.04px, 0);
		}
	}
	.dongxin-c {
		margin: auto;
		width: 101px;
		height: 104px;
		top: 146px;
		left: 0;
		right: 0;
		-webkit-transform-origin: 50% 100%;
	}
	.dongxin-c_1 {
		transform: rotate(0deg) translateY(-165px);
	}
	.dongxin-c_2 {
		transform: rotate(45deg) translateY(-165px);
	}
	.dongxin-c_3 {
		transform: rotate(90deg) translateY(-165px);
	}
	.dongxin-c_4 {
		transform: rotate(135deg) translateY(-165px);
	}
	.dongxin-c_5 {
		transform: rotate(180deg) translateY(-165px);
	}
	.dongxin-c_6 {
		transform: rotate(225deg) translateY(-165px);
	}
	.dongxin-c_7 {
		transform: rotate(270deg) translateY(-165px);
	}
	.dongxin-c_8 {
		transform: rotate(315deg) translateY(-165px);
	}
</style>










