<template>
	<div class="tabbar" :class="{'iphonex': this.$env.iphonex}">
		<div class="tabbar-item" @click="showTab('menu')">
			<!-- <span class="tabbar-tips">新模板</span> -->
			<img src="http://litiresource.xcgogo.site/user/5980171/images/4cee31460bc14d14c53d5c2d65f5c1fb.png" />
		</div>
		<div class="tabbar-item" @click="showTab('info')">
			<img src="http://litiresource.xcgogo.site/user/5980171/images/d27c328a972467be44c094ce742033f5.png" />
		</div>
		<div class="tabbar-item" @click="showTab('music')">
			<img src="http://litiresource.xcgogo.site/user/5980171/images/5f2c8ba30c5d7b8d647313e5ceda760e.png" />
		</div>
		<div class="tabbar-item" @click="showTab('text')">
			<img src="http://litiresource.xcgogo.site/user/5980171/images/089349b7cdef133baca7fd6227aa48a9.png">
		</div>
		<div class="tabbar-item" @click="showTab('save')" style="background-color: rgb(255, 97, 39);">
			<img src="http://litiresource.xcgogo.site/user/5980171/images/6e1ef5da2fc510b24cc0c8f8f327e6a0.png" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tabbar',
		data () {
			return {}
		},
		methods: {
			showTab (target) {
				this.$emit('showTab', target);
			}
		}
	}
</script>

<style lang="less" scoped>
	.tabbar {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 500px;
		height: 69px;
		z-index: 11;
		font-size: 0;
		background-color: white;
		opacity: .94;
	}
	.tabbar-item {
		position: relative;
		display: inline-block;
		width: 20%;
		height: 100%;
		img {
			position: absolute;
			width: 90px;
			height: 60px;
			left: 50%;
			top: 50%;
			margin-left: -45px;
			margin-top: -30px;

			// width: 100%;
			// height: 100%;

			// width: 144px;
			// height: 100px;
			// left: 0; right: 0;
			// margin: auto;
		}
	}
	.tabbar-tips {
		position: absolute;
		z-index: 1;
		font-size: 18px;
		top: -32px;
		right: -17px;
		background-color: white;
		border: solid 3px red;
		padding: 5px 8px;
		border-radius: 30px;
		color: #f7110f;
		font-weight: bold;
	}
</style>