<template>
	<div class="fangzhuan poc bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
       <div class="perspective poc">
			<div class="fangzhuan_1 fangzhuan_r_y preserve3d po fangzhuan_h" :style="getStyle">
				<div class="fangzhuan_r_x preserve3d poc">
					<div class="fangzhuan_1_f fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}"></div>
					<div class="fangzhuan_1_a fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}">
						<img :src="mode[1][0]" class="fangzhuan_flower1 fangzhuan_flower po">
						<img :src="mode[1][1]" class="fangzhuan_flower2 fangzhuan_flower po">
						<img :src="mode[1][2]" class="fangzhuan_flower3 fangzhuan_flower po">
						<img :src="mode[1][3]" class="fangzhuan_flower4 fangzhuan_flower po">
						<img :src="mode[1][0]" class="fangzhuan_flower5 fangzhuan_flower po">
						<img :src="mode[1][1]" class="fangzhuan_flower6 fangzhuan_flower po">
						<img :src="mode[1][2]" class="fangzhuan_flower7 fangzhuan_flower po">
						<img :src="mode[1][3]" class="fangzhuan_flower8 fangzhuan_flower po">
					</div>
					<div class="fangzhuan_1_t fangzhuan_t po" :style="{backgroundImage: 'url('+ mode[2][1] +')'}"></div>
					<div class="fangzhuan_1_b fangzhuan_b po" :style="{backgroundImage: 'url('+ mode[2][2] +')'}"></div>
					<div class="fangzhuan_1_l fangzhuan_l po" :style="{backgroundImage: 'url('+ mode[2][3] +')'}"></div>
					<div class="fangzhuan_1_r fangzhuan_r po" :style="{backgroundImage: 'url('+ mode[2][4] +')'}"></div>
					<div class="fangzhuan_2_f fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}"></div>
					<div class="fangzhuan_2_a fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}"></div>
					<div class="fangzhuan_2_t fangzhuan_t po" :style="{backgroundImage: 'url('+ mode[2][1] +')'}"></div>
					<div class="fangzhuan_2_b fangzhuan_b po" :style="{backgroundImage: 'url('+ mode[2][2] +')'}"></div>
					<div class="fangzhuan_2_l fangzhuan_l po" :style="{backgroundImage: 'url('+ mode[2][3] +')'}"></div>
					<div class="fangzhuan_2_r fangzhuan_r po" :style="{backgroundImage: 'url('+ mode[2][4] +')'}"></div>
					<div class="fangzhuan_3_f fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}"></div>
					<div class="fangzhuan_3_a fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}"></div>
					<div class="fangzhuan_3_t fangzhuan_t po" :style="{backgroundImage: 'url('+ mode[2][1] +')'}"></div>
					<div class="fangzhuan_3_b fangzhuan_b po" :style="{backgroundImage: 'url('+ mode[2][2] +')'}"></div>
					<div class="fangzhuan_3_l fangzhuan_l po" :style="{backgroundImage: 'url('+ mode[2][3] +')'}"></div>
					<div class="fangzhuan_3_r fangzhuan_r po" :style="{backgroundImage: 'url('+ mode[2][4] +')'}"></div>
					<div class="fangzhuan_4_f fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}">
			               <img :src="mode[1][0]" class="fangzhuan_flower1 fangzhuan_flower po">
			               <img :src="mode[1][1]" class="fangzhuan_flower2 fangzhuan_flower po">
			               <img :src="mode[1][2]" class="fangzhuan_flower3 fangzhuan_flower po">
			               <img :src="mode[1][3]" class="fangzhuan_flower4 fangzhuan_flower po">
			               <img :src="mode[1][0]" class="fangzhuan_flower5 fangzhuan_flower po">
			               <img :src="mode[1][1]" class="fangzhuan_flower6 fangzhuan_flower po">
			               <img :src="mode[1][2]" class="fangzhuan_flower7 fangzhuan_flower po">
			               <img :src="mode[1][3]" class="fangzhuan_flower8 fangzhuan_flower po">
					</div>
					<div class="fangzhuan_4_a fangzhuan_div poc bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')', borderImage: 'url('+ mode[2][0] +')'}"></div>
					<div class="fangzhuan_4_t fangzhuan_t po" :style="{backgroundImage: 'url('+ mode[2][1] +')'}"></div>
					<div class="fangzhuan_4_b fangzhuan_b po" :style="{backgroundImage: 'url('+ mode[2][2] +')'}"></div>
					<div class="fangzhuan_4_l fangzhuan_l po" :style="{backgroundImage: 'url('+ mode[2][3] +')'}"></div>
					<div class="fangzhuan_4_r fangzhuan_r po" :style="{backgroundImage: 'url('+ mode[2][4] +')'}"></div>
				</div>
			</div>
       </div>
	</div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name:'fangzhuan',
        extends: BaseElem,
		data () {
			return {}
		},
		computed: {
			getStyle () {
				if (this.showStatus) {

					let tsize = this.$util.getphotoboxsize(this.currentPhoto['orisize'], [300, 400], 'widthFix');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px'
					}	
				} else {
					return {
						width: '300px',
						height: '260px'
					}
				}
			}
		}
	}
</script>

<style>
	.fangzhuan_r_y {
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		animation: fangzhuan_r_y 4.5s 0.5s cubic-bezier(0.2,0,0.8,1) both;
	}
	@keyframes fangzhuan_r_y {
		0% {
		    transform: rotateY(360deg);
		}
		100% {
		    transform: rotateY(0deg);
		}
	}
	.fangzhuan_r_x {
	    animation: fangzhuan_r_x 4.5s 5s cubic-bezier(0.2,0,0.8,1) both;
	}
	@keyframes fangzhuan_r_x {
		0% {
		    transform: rotateX(360deg);
		}
		100% {
		    transform: rotateX(0deg);
		}
	}
	.fangzhuan_div {
	    border: 5px solid;
	    border-image-slice: 5;
	    background-size: cover;
	    background-clip: content-box;
	}
	.fangzhuan_1_f {
	    transform: translateZ(-125px);
	}
	.fangzhuan_1_a {
	    transform: translateZ(-145px);
	}
	.fangzhuan_h .fangzhuan_flower1, .fangzhuan_h .fangzhuan_flower2, .fangzhuan_h .fangzhuan_flower3, .fangzhuan_h .fangzhuan_flower4 {
	    width: 18%;
	    top: 0;
	    transform: translateY(-50%);
	}
	.fangzhuan_h .fangzhuan_flower1 {
	    top: 5px;
	}
	.fangzhuan_h .fangzhuan_flower1, .fangzhuan_h .fangzhuan_flower5 {
	    left: 0;
	}
	.fangzhuan_h .fangzhuan_flower2 {
	    top: -8px;
	}
	.fangzhuan_h .fangzhuan_flower2, .fangzhuan_h .fangzhuan_flower6 {
	    left: 27%;
	}
	.fangzhuan_h .fangzhuan_flower3 {
	    top: -5px;
	}
	.fangzhuan_h .fangzhuan_flower3, .fangzhuan_h .fangzhuan_flower7 {
	    left: 54%;
	}
	.fangzhuan_h .fangzhuan_flower4, .fangzhuan_h .fangzhuan_flower8 {
	    left: 81%;
	}
	.fangzhuan_h .fangzhuan_flower5 {
	    bottom: 5px;
	}
	.fangzhuan_h .fangzhuan_flower2, .fangzhuan_h .fangzhuan_flower6 {
	    left: 27%;
	}
	.fangzhuan_h .fangzhuan_flower7 {
	    bottom: -5px;
	}
	.fangzhuan_h .fangzhuan_flower3, .fangzhuan_h .fangzhuan_flower7 {
	    left: 54%;
	}
	.fangzhuan_h .fangzhuan_flower4, .fangzhuan_h .fangzhuan_flower8 {
	    left: 81%;
	}
	.fangzhuan_h .fangzhuan_flower5, .fangzhuan_h .fangzhuan_flower6, .fangzhuan_h .fangzhuan_flower7, .fangzhuan_h .fangzhuan_flower8 {
	    width: 24%;
	    bottom: 0;
	    transform: translateY(50%);
	}
	.fangzhuan_h .fangzhuan_flower6 {
	    bottom: -10px;
	}
	.fangzhuan_1_t {
	    transform: translateZ(-135px) rotateX(90deg);
	}
	.fangzhuan_t {
	    top: -10px;
	}
	.fangzhuan_t, .fangzhuan_b {
	    height: 20px;
	    width: 103%;
	    background-size: 100% auto;
	}
	.fangzhuan_1_b {
	    transform: translateZ(-135px) rotateX(-90deg);
	}
	.fangzhuan_b {
	    bottom: -20px;
	}
	.fangzhuan_1_l {
	    transform: translateZ(-135px) rotateY(90deg);
	}
	.fangzhuan_l {
	    left: -10px;
	}
	.fangzhuan_l, .fangzhuan_r {
	    width: 20px;
	    height: 106%;
	    background-size: auto 100%;
	}
	.fangzhuan_1_r {
	    transform: translateZ(-135px) rotateY(-90deg);
	}
	.fangzhuan_r {
	    right: -20px;
	}
	.fangzhuan_2_f {
	    transform: translateZ(-35px);
	}
	.fangzhuan_2_a {
	    transform: translateZ(-55px);
	}
	.fangzhuan_2_t {
	    transform: translateZ(-45px) rotateX(90deg);
	}
	.fangzhuan_2_b {
	    transform: translateZ(-45px) rotateX(-90deg);
	}
	.fangzhuan_2_l {
	    transform: translateZ(-45px) rotateY(90deg);
	}
	.fangzhuan_2_r {
	    transform: translateZ(-45px) rotateY(-90deg);
	}
	.fangzhuan_3_f {
	    transform: translateZ(55px);
	}
	.fangzhuan_3_a {
	    transform: translateZ(35px);
	}
	.fangzhuan_4_r {
	    transform: translateZ(135px) rotateY(-90deg);
	}
	.fangzhuan_4_b {
	    transform: translateZ(135px) rotateX(-90deg);
	}
	.fangzhuan_4_t {
	    transform: translateZ(135px) rotateX(90deg);
	}
	.fangzhuan_4_f {
	    transform: translateZ(145px);
	}
	.fangzhuan_3_r {
	    transform: translateZ(45px) rotateY(-90deg);
	}
	.fangzhuan_3_l {
	    transform: translateZ(45px) rotateY(90deg);
	}
	.fangzhuan_3_b {
	    transform: translateZ(45px) rotateX(-90deg);
	}
	.fangzhuan_3_t {
	    transform: translateZ(45px) rotateX(90deg);
	}
	.fangzhuan_4_a {
	    transform: translateZ(125px);
	}
	.fangzhuan_4_l {
	    transform: translateZ(135px) rotateY(90deg);
	}
</style>