<template>
  <div
    class="sub-mp-container re-container">
    <img
      class="close-sub-btn"
      @click.stop="closeSubBtn"
      src="http://resali.lititutu.cn/wxa_icon/btn/close2.png"/>
    <span class="sub-text" @click.stop="naviToMpDetail">
      订阅获取
      <br />最新模板
    </span>
    <div
      class="sub-btn"
      @click="naviToMpDetail">去关注</div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "FlowSubMp",
  props: [],
  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {
    let _this = this;
  },
  methods: {
    naviToMpDetail: function(){
      let _url = this.$store.state.appConfig.flow_sub;
      window.location.href = _url;
    },
    closeSubBtn(){
      this.$emit('closeSubBtn', {});
    },
  }
};
</script>

<style lang="less">
.sub-mp-container {
  position: absolute;
  z-index: 9;
  left: 0;
  // top: 80px; // 与音乐播放icon等距
  top: 52px;
  background-color: rgba(0, 0, 0, 0.75);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: @bw*0.052;
  border-bottom-right-radius: @bw*0.052;
  padding: 8px 8px 8px 6px;
  justify-content: space-evenly;
  animation: leftfadein 2s ease-out 0s 1 normal both running;
  .close-sub-btn {
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
  .sub-text {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    margin: 0 8px;
  }
  .sub-btn {
    background: #EE4B4B;
    padding: 6px 8px;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    border-radius: @bw * 0.04;
  }
}
</style>