<template>
	<div class="now_page">
	    <div class="container_babianxing">
	        <div class="rect div_1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_3" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_4" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_5" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_6" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_7" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_8" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_9" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	        <div class="rect div_10" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'baibianxing',
		props: ['mode', 'currentImage'],
		data () {
			return {

			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.now_page {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    background-position: center;
	    background-repeat: no-repeat;
	    background-size: cover;
	    perspective:@bw * 3.647;
	}
	.container_babianxing {
	    position: absolute;
	    transform-style: preserve-3d;
	    width:@bw * 0.444;
	    height:@bw * 0.444;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    margin: auto;
	    animation: xuanzhuan 8s infinite linear;
	}
	@keyframes xuanzhuan {
		0% {
			transform: rotateY(0deg);
		}
		100% {
			transform: rotateY(360deg);
		}
	}
	.div_1 {
	    transform: translateZ(@bw * 0.444);
	}
	.div_2 {
	    transform: rotateY(60deg) translateZ(@bw * 0.444);
	}
	.div_3 {
	    transform: rotateY(120deg) translateZ(@bw * 0.444);
	}
	.div_4 {
	    transform: rotateY(180deg) translateZ(@bw * 0.444);
	}
	.div_5 {
	    transform: rotateY(240deg) translateZ(@bw * 0.444);
	}
	.div_6 {
	    transform: rotateY(300deg) translateZ(@bw * 0.444);
	}
	.div_7 {
	    transform: rotateX(60deg) translateZ(@bw * 0.444);
	}
	.div_8 {
	    transform: rotateY(180deg) rotateX(-60deg) translateZ(@bw * 0.444);
	}
	.div_9 {
	    transform: rotateX(-60deg) translateZ(@bw * 0.444);
	}
	.div_10 {
	    transform: rotateY(180deg) rotateX(60deg) translateZ(@bw * 0.444);
	}
	.rect {
	    position: absolute;
	    width:@bw * 0.444;
	    height:@bw * 0.444;
	    overflow: hidden;
	    border: solid@bw * 0.014 #fff;
	    border-radius:@bw * 0.028;
	    background: no-repeat center;
	    background-size: cover;
	}
</style>