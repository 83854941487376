class SubMessageManager {
    constructor(util) {
        this.$util = util;
        this.show_func = {};
        this.hide_func = {};
        this.next_ids = [];
    }
    // bindTipsController (options) {
    //     let {
    //         show_func,
    //         hide_func
    //     } = options || {};
    //     let current_page = this.$util.$router.instance_router && this.$util.$router.instance_router['path'];
    //     this.show_func[current_page] = show_func;
    //     this.hide_func[current_page] = hide_func;
    // }
    showTips() {
        // let current_page = this.$util.$router.instance_router && this.$util.$router.instance_router['path'];
        // this.show_func[current_page] && this.show_func[current_page]();
        this.$util.$eventCenter.broadcast("ST_SUBMSG_TOAST", {
            "mode": "show"
        })
    }
    hideTips() {
        // let current_page = this.$util.$router.instance_router && this.$util.$router.instance_router['path'];
        // this.hide_func[current_page] && this.hide_func[current_page]();
        this.$util.$eventCenter.broadcast("ST_SUBMSG_TOAST", {
            "mode": "hide"
        })
    }
    pushNextSubIds (ids) {
        this.next_ids = this.next_ids.concat(ids);
    }
}

const _util = {
    SubMessageManager,
    // 订阅消息
    requareTemplate (options) {
        options = options || {};
        let {
            ids, // 优先订阅渠道
            scene_id, // 场景
            scene_count, // 场景-限制出现次数
            sub_msg_tips, // 是否展示提示背景
        } = options;
        ids = ids ? ids : [];
        sub_msg_tips = sub_msg_tips !== undefined ? sub_msg_tips :  1;

        // 插入优先渠道
        if (this.next_ids && this.next_ids.length > 0 && ids.length < 1) {
            ids = ids.concat(this.next_ids)
            this.next_ids = [];
        }

        // 如果传入了场景id则此场景内生命周期内只出现 X 次
        if (scene_id) {
            let user_scene_count = this.$global.get(scene_id, 0);
            scene_count = scene_count ? scene_count : 1;
            if (scene_count <= user_scene_count) {
                return Promise.resolve();
            } else {
                this.$global.set(scene_id, user_scene_count + 1);
            }
        }

        let _this = this,
            template_config = this.$config.app_config['template_config'],
            template_ids = this.$config.app_config['template_id_lists'] && this.$config.app_config['template_id_lists'][ this.$wx.appid ],
            tmpl_ids = [],
            tmpl_id_map = {},
            local_sub_log = this.$global.get("st_template_log", []);
            // storage_sub_log = this.getStorageSync("st_storage_template_log", {"logs": [],"time": now});
        if (!template_ids || !template_config) {
            this.$loger.warn("⚠️ 尚未配置订阅消息 ");
            return this.isEnv('wxa') ? Promise.reject() : [];
        }
        const LIMIT = (this.isEnv('wxa') ? 3 : 5);

        // 优先订阅一个节日类型 (每次都获取一个)
        if (ids.length < LIMIT) { // 限制生命周期只获取一次 && !(this.arrayIntersection(local_sub_log.concat(ids), template_config['festival'])).length
            // let random_festival = (this.arrayDifferenceFrom( local_sub_log, template_config['festival'] )).randomChoice();
            let random_festival = this.randomMin(local_sub_log, template_config['festival']); // 取获取次数最少的一个
            if (random_festival) {
                ids.push( random_festival );
                // 成功了再记录 local_sub_log.push( random_festival );
            }
        }

        // 订阅一个周期性订阅消息
        if (ids.length < LIMIT && !(this.arrayIntersection(local_sub_log.concat(ids), template_config['cyclicity'])).length) {
            let random_cyclicity = (this.arrayDifferenceFrom( local_sub_log, template_config['cyclicity'] )).randomChoice();
            if (random_cyclicity) {
                ids.push( random_cyclicity );
                // 成功了再记录 local_sub_log.push( random_cyclicity );
            }
        }

        // 去重
        ids = ids.uniq();

        // 补满订阅消息渠道
        // 补全3.0
        let require_num = LIMIT - ids.length,
            backup = [],
            backup_templates = template_config['daily']; // 优先补全daily
        if (backup_templates.length < LIMIT - ids.length) { // daily不足则把festival和cyclicity也补全
            backup_templates = backup_templates.concat(template_config['festival']).concat(template_config['cyclicity'])
        }
        // 去除现已获取的渠道
        backup_templates = this.arrayDifferenceFrom(ids, backup_templates);

        // 临时全局已授权渠道数组
        let temp_local_sub_log = this.copy(local_sub_log);
        temp_local_sub_log = temp_local_sub_log.concat(ids);
        // console.log("当前生命周期已获取渠道==>",temp_local_sub_log);
        for (let i = 0 ; i < require_num ; i++) {
            // 全局与backup取最少的random渠道
            let random_min = this.randomMin(temp_local_sub_log, backup_templates);
            // console.log("随机获取最少渠道==>",random_min)
            if (random_min) {
                temp_local_sub_log.push(random_min);
                backup.push(random_min);
                backup_templates = this.arrayDifferenceFrom(backup, backup_templates);
            }
        }
        // console.log("进入补全逻辑的渠道==>",backup)

        // 乱序
        backup && backup.shuffle();
        for (let i = 0 ; i < backup.length && ids.length < LIMIT ; i++){
            ids.push(backup[i]);
        }

        // console.log("最终此次获取渠道==>",ids);

        // 记录最终获取订阅消息
        local_sub_log = local_sub_log.concat(ids);
        this.$global.set("st_template_log", local_sub_log);

        // console.log("===========")
        // console.log("最终现在已获取过的渠道==>",local_sub_log)
        // let _all_ids_ = template_config['daily'].concat(template_config['festival']).concat(template_config['cyclicity']);
        // for (let i = 0 ; i < _all_ids_.length ; i++) {
        //     let temp_ary_item = _all_ids_[i],
        //       num = local_sub_log.numOf(temp_ary_item);
        //     console.log("numof==>" + temp_ary_item + ":" + num);
        // }
        // console.log("===========")

        let success_channels = [];
        // 获取对应的tmpid
        for (let i = 0 ; i < ids.length ; i++) {
            tmpl_ids.push(template_ids[ids[i]]);
            tmpl_id_map[template_ids[ids[i]]] = ids[i];
        }

        if (this.isEnv("h5")) {return tmpl_ids};

        let querys = this.getUrlQuerys() || {};
        
        if (sub_msg_tips && tmpl_ids && tmpl_ids.length > 0) {
            this.$submsg.showTips();
        }
        return new Promise((resolve, reject)=>{
            wx.requestSubscribeMessage({
              tmplIds: tmpl_ids,
              success: res => {
                _this.$submsg.hideTips();
                for (let i = 0 ; i < tmpl_ids.length ; i++) {
                  let tpl_key = tmpl_ids[i];
                  if (res[tpl_key] == "accept") {
                    _this.$loger.log("uni_submessage",{
                        "channel_id": tmpl_id_map[tpl_key],
                        "page": querys['st_page_route'] || ""
                    })
                    success_channels.push(tmpl_id_map[tpl_key]);
                  }
                }
                if (success_channels && success_channels.length > 0) {
                    _this.rpost(_this.$config.FC_HOST + "stpush/sub_template", {
                      "channels": success_channels,
                      "app": _this.$config.__APP__,
                      "appid": _this.$login.appid,
                      'openid': _this.$login.openid,
                    })
                    // _this.$global.set("st_template_log", local_sub_log.concat(success_channels));
                    // _this.$taro.showToast({
                    //     title: '订阅成功',
                    //     duration: 2000,
                    // })
                    resolve(success_channels)
                } else {
                    reject()
                }
              },
              fail: err=>{
                _this.$submsg.hideTips();
                reject()
              }
            })
        }).catch(err=>{
            _this.$submsg.hideTips();
        })
    },
}

export default _util;