<template>
	<div class="menu-container">
		<!-- <div class="menu-make-container">
			<div class="menu-make-btn">
				<img src="http://litiresource.xcgogo.site/user/5980171/images/30e57783a6d4121ebea20746fb52382a.png">
				<span>做相册</span>
			</div>
		</div> -->
		<!-- <CollectionTips :currentPage="'menu'"></CollectionTips> -->
		<ThemeMenu from="menu" @onThemeSelect="onThemeSelect"></ThemeMenu>

		<!-- <TabBar from="menu"></TabBar> -->
	</div>
</template>

<script>
	import ThemeMenu from '@/components/ThemeMenu'
	import CollectionTips from '@/views/tips/CollectionTips.vue'	

	// import C from '@/config'

	export default {
		name: 'Menu',
		data () {
			return {
				firstLoad: true
			}
		},
		components: {
			ThemeMenu,
			CollectionTips
		},
		props: ['currentPage'],
		watch: {
			currentPage: {
				handler () {
					if (this.currentPage == 'menu') {

						let share_link = window.location.protocol + '//' + window.location.host + '/vliti/main/' + this.$store.state.appConfig.menu_path;

						if (this.firstLoad) {
							this.firstLoad = false;
							
							// 微信分享设置
							this.$wx.share({
								title: '邀请你制作甜豆相册',
								link: share_link,
								imgUrl: 'http://resali.lititutu.cn/tuku/prod/e3124a15b00bb83e2ec4ff42e0a2f60e.jpg',
								success: () => {},
								from: 'menu'
							});
							
							// 记录pv
							this.$o_util.log('menu');
							this.$mta.click('pv_menu');
						}

						// 适配明哥账号制作入口流出的bug
						if (this.$route.query['_source'] == 'wx7d8d082b9cc46a28' && this.$route.query['openid'] == 'or11E1I-cXXF8vu8Px29DDjrrFOk') {
							window.location.replace( share_link );
						}
					}
				},
				immediate: true
			}
		},
		created () {

			// 一键制作
			if (this.$route.query['make']) {
				let tid = this.$route.query['make'];
				if (tid == 'guoqingheying') {
					setTimeout(() => {
						window.location.href='/vliti/main/share?key=18d74c0509968eeecec93aff79f3f0bb&_source=wx552e3843819927e5&visitor='+this.$route.query.openid;
					}, 500);
				} else if (tid == 'chongyangheying') {
					setTimeout(() => {
						window.location.href='/vliti/main/share?key=7ee653c80af12c061d1da0d7585ace6a&_source=wx552e3843819927e5&visitor='+this.$route.query.openid;
					}, 500);
				}
			}

			// 处理action
			switch(this.$route.query['action']) {
				case 'make':
					setTimeout(() => {
						this.onThemeSelect();
					}, 1000);
					break;
			}
		},
		methods: {
			onThemeSelect: function(tid) {
				if (!this.$util.isEnv("wx")) {
					alert("需使用微信选图功能，请在微信环境中打开")
					return;
				}
				
				let _this = this;

				var appid = _this.$route.query['_source'],
					openid = _this.$route.query['openid'];

				_this.$o_util.log(['select_image_4003', 'select_image_h5']);
				
				_this.$o_util.uploadMedia({
					violate: async function () {
						return new Promise((resolve, reject)=>{
							resolve("C9130828AE2347CD2AD3F8D4C98EDC89")
						})
					},
					process (index, len) {
						if (index == 0) {
							_this.$toast.loading({
								message: '加载中...',
								duration: 'infinite'
							})
						} else if(index == len) {
							_this.$toast.loading('创建中...');
						} else {
							_this.$toast.loading(index+'/'+len+' 上传中');
						}
					},
				}).then(image_infos=>{//images
					// 制作相册
					if (appid && openid && loginManager._isStorageAuthor(appid, openid)) {
						let param = {
							'appid': appid,
							'openid': openid,
							// 'urls': images.join(','),
							'imgInfos': JSON.stringify(image_infos),
							'vscene': '4003'
						}
						if(tid && tid != "undefined"){
							param['tid'] = tid;
						}
						_this.$util.rpost('/vliti/new_book', param)
						.then((response) => {
							_this.$toast.loading('制作成功');
							setTimeout(() => {
								_this.$toast.clear();
								window.location.href = "/vliti/main/"+(_this.$store.state.appConfig.editor_path)+"?key="+response+'&_source='+appid+'&vscene=2007';
							}, 1000);
						})
						.catch((err) => {
							_this.$toast.clear();
							setTimeout(() => {
								_this.$toast.text({
									'message': err,
									'duration': 10
								})
							}, 500);
						});
					} else {
						this.$util.rpost('set_sharemake_images', {
							images: JSON.stringify(image_infos)//images.join(',')
						}).then(imagekeys => {

							let jumpurl = '//' + window.location.host + '/vliti/sharemake?v=1&vscene=2007&imagekeys=' + imagekeys;
							window.location.href = jumpurl+'&tid='+tid;
						})
					}
				}).catch(err=>{
					_this.$toast.clear();
					_this.$o_util.log('upload_media_fail_0');
				})
				this.$mta.click('menu_btn_newbook');
			},

			// isFresh: function(){
			// 	this.$emit('isFresh');
			// },
		}
	}
</script>

<style scoped lang="less">
	@menuBgColor: #f1f1f1;
	@makeWidth:@bw * 0.944;
	@makeHeight:@bw * 0.528;
	@makeRadius:@bw * 0.011;

	.menu-container {
		position: fixed;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
		background-color: @menuBgColor;
	}
	.menu-make-container {
		position: relative;
		width: @makeWidth;
		height: @makeHeight;
		display: block;
		margin:@bw * 0.028 auto@bw * 0.011;
		border-radius: @makeRadius;
		background: linear-gradient(to top right, #ff9761 , #E72047);
	}
	.menu-make-btn {
		position: relative;
		width: @makeWidth - (@bw * 0.039);
		height: @makeHeight - (@bw * 0.039);
		border-radius: @makeRadius;
		background-color: #fff;
		margin: auto;
		top: @bw * 0.019;
		left: -@bw * 0.019;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		img {
			position: relative;
			display: block;
			width:@bw * 0.139;
			height:@bw * 0.139;
			box-shadow: 0 0 (@bw * 0.017) #aaa;
			border-radius: 50%;
		}
		span {
			position: relative;
			display: block;
			font-size:@bw * 0.061;
			letter-spacing:@bw * 0.006;
			font-weight: bold;
			line-height:@bw * 0.078;
			font-family: 'Heiti';
			color: #505050;
		}
	}
</style>