<template>
	<div class="model-full">
		<!-- 背景 -->
		<full-bg :mode="mode" :currentScene="currentSceneName"></full-bg>
		<!-- 模板 -->
		<template v-for="(idx, index) in scenes">
			<transition name='full-model'>
					<component 
						:ref="lines[idx]['id']"
						v-if="lines[idx]['id'] == currentSceneId" 
						:showStatus="lines[idx]['id'] == currentSceneId" 
						@wxPreview="wxPreview" 
						:mode='getSceneMode(lines[idx]["mode"])' 
						:currentImage='currentImage(index)' 
						:is='"o_"+lines[idx]["name"]'>
					</component>
			</transition>
		</template>
		<template v-for="(idx, index) in inters">
			<!-- 将elems兼容为elem byhz 2020/10/10 -->
			<template v-if="lines[idx]['name'] == 'elems'">
				<template v-for="elem in getCurrentInterConf(lines[idx]['mode'])">
					<component
						:mode="elem"
						@endInter="_endInter"
						v-if="lines[idx]['id'] == currentInterId"
						:showStatus="lines[idx]['id'] == currentInterId"
						is="elem"></component>
				</template>
			</template>
			<template v-else>
				<component
					:mode="getCurrentInterConf(lines[idx]['mode'])"
					@endInter="_endInter"
					v-if="lines[idx]['id'] == currentInterId"
					:showStatus="lines[idx]['id'] == currentInterId"
					:is="'o_'+lines[idx]['name']"></component>
			</template>
		</template>

		<!-- 字幕 -->
		<UserText :mode="mode['mode']" :currentText="currentText"></UserText>
		<!-- <span class="full-usertext">{{currentText}}</span> -->
		<span class="full-musictext" v-if="currentMusicText && !currentText">{{currentMusicText}}</span>

		<!-- 场内动画 -->
		<template v-for="(decorate, decorate_id) in decorates">
			<template v-if="isDecorateShow">
				<template v-if="decorate_id == 'gif'">
					<template v-for="mode in decorate">
						<gif :mode="mode"></gif>
					</template>
				</template>
				<template v-else-if="decorate_id == 'elems'">
					<template v-for="mode in decorate">
						<elem :mode="mode"></elem>
					</template>
				</template>
				<template v-else-if="decorate_id == 'flowers'">
					<template v-for="mode in decorate">
						<flower :mode="mode"></flower>
					</template>
				</template>
				<template v-else>
					<component :is="decorate_id" :mode="decorate"></component>
				</template>
			</template>
		</template>

		<!-- 开场动画 -->
		<Prelude :mode="prelude" @preluded="preluded"></Prelude>
	</div>
</template>

<script>

	import fullBg from './FullBg'

	import o_gif from '@/components/st_comps/st_comp/comps/StElem/Gif'

	import o_jiaban from './scene/jiaban'
	import o_fangkuai from './scene/fangkuai'
	import o_xuanzhuan from './scene/xuanzhuan'
	import o_guandao from './scene/guandao'
	import o_pingbox from './scene/pingbox'
	import o_xinbox from './scene/xinbox'
	import o_lifangzhuan from './scene/lifangzhuan'
	import o_fanzhuan from './scene/fanzhuan'
	import o_xuandong from './scene/xuandong'
	import o_shuibo from './scene/shuibo'
	import o_baozha from './scene/baozha'
	import o_tiandi from './scene/tiandi'
	import o_lingxing from './scene/lingxing'
	import o_zhuanxin from './scene/zhuanxin'
	import o_chuang from './scene/chuang'
	import o_dangao from './scene/dangao'
	import o_fanshu from './scene/fanshu'
	import o_babianxing from './scene/babianxing'
	import o_tuoyuan from './scene/tuoyuan'
	import o_sea from './scene/sea'
	import o_aixinyuan from './scene/aixinyuan'
	import o_nianlun from './scene/nianlun'
	
	import o_tongdao from './scene/tongdao'
	import o_duolingxing from './scene/duolingxing'

	import o_xiayu from './scene/xiayu'
	import o_changlang from './scene/changlang'
	import o_zhuan2 from './scene/zhuan2'
	import o_zhuan3 from './scene/zhuan3'
	import o_litifei from './scene/litifei'
	import o_manping from './scene/manping'
	import o_dong1 from './scene/dong1'
	import o_dong2 from './scene/dong2'
	import o_dong3 from './scene/dong3'
	import o_dong4 from './scene/dong4'
	import o_dong5 from './scene/dong5'
	import o_dong6 from './scene/dong6'
	import o_dong7 from './scene/dong7'
	import o_dong8 from './scene/dong8'
	import o_dong9 from './scene/dong9'
	import o_dong10 from './scene/dong10'
	import o_dong11 from './scene/dong11'
	import o_dong12 from './scene/dong12'
	import o_dong13 from './scene/dong13'
	import o_huaquanzhuan from './scene/huaquanzhuan'
	import o_yuanup from './scene/yuanup'
	import o_aixintiao from './scene/aixintiao'
	import o_zhuanyuan from './scene/zhuanyuan'
	import o_qianyidong from './scene/qianyidong'
	import o_fenshen from './scene/fenshen'
	import o_zuoyidong from './scene/zuoyidong'
	import o_zhuanbox from './scene/zhuanbox'
	import o_luoxuan from './scene/luoxuan'
	import o_denglong from './scene/denglong'
	import o_dongxin from './scene/dongxin'
	import o_fanpai from './scene/fanpai'
	import o_zhuanta from './scene/zhuanta'
	import o_yuanzhuan from './scene/yuanzhuan'
	import o_fangzhuan from './scene/fangzhuan'
	import o_zhuan from './scene/zhuan'
	import o_dangaotai from './scene/dangaotai'
	import o_yuanluo from './scene/yuanluo'
	import o_duolifangzhuan from './scene/duolifangzhuan'
	import o_baobao1 from './scene/baobao1'
	import o_baobao2 from './scene/baobao2'
	import o_baobao3 from './scene/baobao3'
	import o_gundong from './scene/gundong'
	import o_yuanzhuanshu from './scene/yuanzhuanshu'
	import o_lifangti from './scene/lifangti'
	import o_hengfengche  from './scene/hengfengche'
	import o_zuofengche from './scene/zuofengche'
	import o_yuanzhuanting from './scene/yuanzhuanting'
	import o_chuxian from './scene/chuxian'
	import o_fengche from './scene/fengche'
	import o_chongdie from './scene/chongdie'
	import o_quantu1 from './scene/quantu1'
	import o_quantu2 from './scene/quantu2'
	import o_litihengfei from './scene/litihengfei'

	import o_guoqi from '@/components/themes/full/inter/guoqi'
	import o_dahuadown from '@/components/themes/full/inter/dahuadown'
	import o_dahuaup from '@/components/themes/full/inter/dahuaup'
	import o_dahuascale from '@/components/themes/full/inter/dahuascale'
	import o_denglongheng from '@/components/themes/full/inter/denglongheng'
	import o_denglongshu from '@/components/themes/full/inter/denglongshu'
	import o_zhuanzuoxiayoushang from '@/components/themes/full/inter/zhuanzuoxiayoushang'
	import o_zhuanyoushangzuoxia from '@/components/themes/full/inter/zhuanyoushangzuoxia'
	import o_zhuanzuoshangyouxia from '@/components/themes/full/inter/zhuanzuoshangyouxia'
	import o_zhuanyouxiazuoshang from '@/components/themes/full/inter/zhuanyouxiazuoshang'
	import o_zhuanzuoyou from '@/components/themes/full/inter/zhuanzuoyou'
	import o_zhuanyouzuo from '@/components/themes/full/inter/zhuanyouzuo'
	import o_zhuanzhong from '@/components/themes/full/inter/zhuanzhong'
	import o_piaozuoxiayoushang from '@/components/themes/full/inter/piaozuoxiayoushang'
	import o_piaoyouxiazuoshang from '@/components/themes/full/inter/piaoyouxiazuoshang'

	import Prelude from '@/components/Prelude'
	import UserText from '@/components/UserText'

	const scene_duration = 10; // 默认动态模板scene切换间隔
	const prelude_duration = 3; // 默认开场

	export default {
		name: 'Full',
		components: {
			fullBg,

			o_gif,
			
			o_jiaban,
			o_fangkuai,
			o_xuanzhuan,
			o_guandao,
			o_pingbox,
			// o_yuantong,
			o_xinbox,
			o_lifangzhuan,
			o_fanzhuan,
			o_xuandong,
			o_shuibo,
			o_baozha,
			o_tiandi,
			o_lingxing,
			o_zhuanxin,
			o_chuang,
			o_dangao,
			o_fanshu,
			o_babianxing,
			o_tuoyuan,
			o_sea,
			o_aixinyuan,
			o_nianlun,
			o_tongdao,
			o_duolingxing,
			o_xiayu,
			o_changlang,
			o_zhuan2,
			o_zhuan3,
			o_litifei,
			o_manping,
			o_dong1,
			o_dong2,
			o_dong3,
			o_dong4,
			o_dong5,
			o_dong6,
			o_dong7,
			o_dong8,
			o_dong9,
			o_dong10,
			o_dong11,
			o_dong12,
			o_dong13,
			o_huaquanzhuan,
			o_yuanup,
			o_aixintiao,
			o_zhuanyuan,
			o_qianyidong,
			o_fenshen,
			o_zuoyidong,
			o_zhuanbox,
			o_luoxuan,
			o_denglong,
			o_dongxin,
			o_fanpai,
			o_zhuanta,
			o_yuanzhuan,
			o_fangzhuan,
			o_zhuan,
			o_dangaotai,
			o_yuanluo,
			o_duolifangzhuan,
			o_baobao1,
			o_baobao2,
			o_baobao3,
			o_gundong,
			o_yuanzhuanshu,
			o_lifangti,
			o_hengfengche,
			o_zuofengche,
			o_yuanzhuanting,
			o_chuxian,
			o_fengche,
			o_chongdie,
			o_quantu1,
			o_quantu2,
			o_litihengfei,

			o_guoqi,
			o_dahuadown,
			o_dahuaup,
			o_dahuascale,
			o_denglongheng,
			o_denglongshu,
			o_zhuanzuoxiayoushang,
			o_zhuanyoushangzuoxia,
			o_zhuanzuoshangyouxia,
			o_zhuanyouxiazuoshang,
			o_zhuanzuoyou,
			o_zhuanyouzuo,
			o_zhuanzhong,
			o_piaozuoxiayoushang,
			o_piaoyouxiazuoshang,

			Prelude,
			UserText,
		},
		data () {
			return {
				// 过场
				inters: [],
				// 场景
				scenes: [],
				// 当前运行秒数
				second: 0,
				// 当前运行第几轮
				round: 0,
				// 当前场景名称+(下标)
				currentScene: '',
				// 当前场景名称
				currentSceneName: '',
				// 当前场景Id 用于判断显示隐藏
				currentSceneId: '',
				// 当前场景Idx 表示当前是第几个场景
				currentSceneIdx: -1,

				// 当前过场Id 用于判断显示隐藏
				currentInterId: '',

				timeEvent: null,
				currentMusicText: '',

				isDecorateShow: true
			}
		},
		props: ['mode'],
		mounted () {
			this.run();
		},
		destroyed () {
			clearInterval(this.timeEvent);
		},
		computed: {
			// 当前时间对应第几个line
			times () {
				return this._getTimes(this.mode['mode']);
			},
			// 每一个line的数据结构
			lines () {
				return this._getLines(this.mode['mode']);
			},
			decorates () {
				this.isDecorateShow = false;

				if (!this.mode['mode']['decorate']) {
					return false;
				}
				if (!this.mode['mode']['decorate'][this.currentScene]) {
					return false;
				}
				this.$nextTick(() => {
					this.isDecorateShow = true;
				});
				return this.mode['mode']['decorate'][this.currentScene];
			},
			prelude () {
				let prelude = Boolean(this.mode['prelude']) ? this.mode['prelude'] : false;

				if (!prelude) {
				// 如果设置无开场或无用户设置标题，则不显示 
					if (this.mode['is_prelude'] === false || !this.$store.state.config['data']['ti']) {
						prelude = {
							'type': 'none',
							'duration': 0
						}
					}
					// 其他情况则显示默认开场样式
					else {
						prelude = {}
						prelude['title'] = this.$store.state.config['data']['ti'];
						if (!prelude['textcolor']) {
							prelude['textcolor'] = '#066f06';
						}
						if (!prelude['type']) {
							prelude['type'] = 'default';
						}
					}
				}

				if (!prelude['duration']) {
					prelude['duration'] = prelude_duration;
				}
				prelude['title'] = this.$store.state.config['data']['ti'] || false;

				if (!prelude['title']) {
					prelude = {
						'type': 'none',
						'duration': 0
					}
				}

				return prelude;
			},
			currentText () {
				if (this.currentSceneIdx == -1) {
					return '';
				}
				let lens = this.$store.state.config.images.length;
				return this.$store.state.config.images[ this.currentSceneIdx % lens ]['tx'];
			}
		},
		methods: {
			_getLines (mode) {
				let lines = [];

				if (mode['scene']) {

					let timeline = mode['scene'];
					for (let i=0, lens=timeline.length; i<lens; i++) {
						lines.push({
							'type': 'scene',
							'name': timeline[i].split('_')[0],
							'mode': timeline[i],
							'id': timeline[i] + '_' + i.toString()
						})
						this.scenes.push(i);
					}
					lines.push({
						'type': 'end'
					})

				} else if (mode['timeline']) {

					let timeline = mode['timeline'], items;
					for (let i=0, lens=timeline.length; i<lens; i++) {
						items = timeline[i].split(',');
						if (items[1] == 'end') {
							lines.push({
								'type': 'end'
							})
						} else {
							lines.push({
								'type': items[1],
								'name': items[2].split('_')[0],
								'mode': items[2],
								'id': items[2] + '_' + i.toString()
							})
							if (items[1] == 'scene') {
								this.scenes.push(i);
							} else if (items[1] == 'inter') {
								this.inters.push(i);
							}
						}
					}
				}
				return lines;
			},
			_getTimes (mode) {
				let times = {}

				if (mode['scene']) {

					let timeline = mode['scene'], time;
					for (var i=0, lens=timeline.length; i<lens; i++) {
						time = i*scene_duration;
						if (i>0) {
							time = time + prelude_duration;
						}
						times[ time ] = i;
					}
					time = i * scene_duration + prelude_duration;
					times[ time ] = i;

				} else if (mode['timeline']) {
					let timeline = mode['timeline'];
					for (var i=0, lens=timeline.length; i<lens; i++) {
						times[ parseInt(timeline[i].split(',')[0]) ] = i;
					}
				}
				return times;
			},
			run () {
				let current = this.lines[this.times[this.second]];

				if (current && current['type'] == 'scene') {
					this._runScene(current);
					this._preloadNextImages(this.times[this.second]);
				}

				this.timeEvent = setInterval(() => {

					this.second += 0.5;
					current = this.lines[this.times[this.second]];

					if (current && current['type'] == 'end') {
						this.second = 0;
						this.round ++;
						current = this.lines[this.times[this.second]];
					}

					if (current && current['type'] == 'scene') {
						this._runScene(current);
						this._preloadNextImages(this.times[this.second]);
					} else if (current && current['type'] == 'inter') {
						this._runInter(current);
					}

				}, 500);

				// 疫情提醒模板 音乐字幕
				let musictext = [];
				let textDuration = 3000;
				let textTimer = null;
				clearInterval(textTimer);
				if (['kangyitixing', 'yuanxiaotuanyuan', 'qingrenjie'].indexOf(this.$store.state.config.data['ttid']) != -1) {
					if ('kangyitixing' == this.$store.state.config.data['ttid']) {
						textDuration = 3000;
						musictext = ["口罩一戴，福气永在","注意洗手，钱财就有","不吃野味，运势翻倍","锻炼身体，开门见喜","经常消毒，坏运消除","老实宅家，来年必发","安全放第一","亲人不分离","过年不出门","情谊永不断","你不聚，我不聚","人人齐把病毒拒","鼠年平安"];
					} else if ('yuanxiaotuanyuan' == this.$store.state.config.data['ttid']) {
						textDuration = 5000;
						musictext = ["抗疫继续努力来","元宵到了胜利快","少出门来不聚会","勤洗手将口罩戴","微信连线聚团圆","情谊满满祝福甜","正月十五月儿圆","幸福日子在眼前","正月十五元宵甜","祝你今年更有钱","正月十五汤圆香","祝你身体更健康","元宵快乐"];
					} else if ('qingrenjie' == this.$store.state.config.data['ttid']) {
						textDuration = 5000;
						musictext = ["我最爱的你","我把成串的往昔","多情的眷恋","编织成相思的花环","献给你","我爱你！"];
					}
					let textIndex = 0;
					this.currentMusicText = musictext[textIndex];
					textTimer = setInterval(() => {
						textIndex ++;
						if (textIndex == musictext.length) {
			                textIndex = 0;
			            }
			            this.currentMusicText = musictext[textIndex];
					}, textDuration);
				} else {
					this.currentMusicText = '';
				}
			},
			_runScene (current) {
				this.currentSceneId = current['id'];
				this.currentScene = current['mode'];
				this.currentSceneName = current['name'];
				this.currentSceneIdx ++;

				// 触发结束页面开始
				let theme_num = this.$o_util.getSceneNum(this.mode),
					images_num = this.$store.state.config.images.length;
				theme_num = images_num > theme_num ? images_num : theme_num;
				if (this.currentSceneIdx == theme_num) {
					this.$emit('showEndPage');
				}
				// 触发结束页面结束


				// 生命周期：模板开始及结束播放
				this.$nextTick(() => {
					try {
						let current_idx = this.currentSceneIdx%this.scenes.length;
						let current_t = this.$refs[this.lines[this.scenes[ current_idx ]]['id']][0];
						current_t['start'] && current_t['start'](this.currentSceneIdx%this.scenes.length, this.currentSceneIdx);

						if (this.currentSceneIdx != 0) {
							let last_idx = (this.currentSceneIdx-1)%this.scenes.length;
							let current_t = this.$refs[this.lines[this.scenes[ last_idx ]]['id']][0];
							current_t['end'] && current_t['end']();
						}
					} catch(e) {
						console.warn('模板切换报错：', e);
					}
				})
			},
			// 预加载下一场景用图
			_preloadNextImages (current_line_idx) {
				// 只在第一轮预加载图片素材
				if (this.round > 0) {
					return;
				}
				let preload_images = [];

				let next_line_idx = (current_line_idx + 1) % this.lines.length;

				let next_line = this.lines[next_line_idx];

				if (next_line['type'] == 'inter') {
					// 部分过场动画并无图片配置
					if (this.mode['mode']['inter']) {
						preload_images = preload_images.concat( this.$o_util.getImageUrlsFromString(JSON.stringify( this.mode['mode']['inter'][next_line['mode']] )) );
					}

					next_line_idx = (next_line_idx + 1) % this.lines.length;
					next_line = this.lines[next_line_idx];

					if (next_line['type'] == 'end') {
						return;
					}
					preload_images = preload_images.concat( this.$o_util.getImageUrlsFromString(JSON.stringify( this.mode['mode'][next_line['mode']] )) );


				}

				if (next_line['type'] == 'scene') {

					if (next_line['type'] == 'end') {
						return;
					}

					preload_images = preload_images.concat( this.$o_util.getImageUrlsFromString(JSON.stringify( this.mode['mode'][next_line['mode']] )) );

					if (this.mode['mode']['decorate'] && this.mode['mode']['decorate'][next_line['mode']]) {
						let decorate = this.mode['mode']['decorate'][next_line['mode']];
						preload_images = preload_images.concat( this.$o_util.getImageUrlsFromString(JSON.stringify( decorate )) );
					}
				}

				let that = this;

				for (let i=0; i<preload_images.length; i++) {
					let im = new Image();
					im.onload = function() {
						that.$store.commit('setImageObj', {
							imagesrc: this.src,
							imageobj: this
						});
					};
					im.onerror = function() {
						that.$store.commit('setImageObj', {
							imagesrc: this.src,
							imageobj: this
						});
					};
					im.src = preload_images[i];
				}
			},
			// 预加载下一张用户图片
			_preloadNextPhotos (photo) {
				if (photo['orisize']) {
					return;
				}

				let _this = this;

				let im = new Image();
				im.onload = function() {
					_this.$store.commit('setImageOriSize', {'imageid': photo['id'], 'orisize': [this.width, this.height]});
				};
				im.onerror = function() {
					_this.$store.commit('setImageOriSize', {'imageid': photo['id'], 'orisize': [500, 500]});
				};
				im.src = photo['im'];
			},
			_runInter (current) {
				this.currentInterId = current['id'];
			},
			_endInter () {
				this.currentInterId = '';
			},
			currentImage (index) {

				let lens = this.$store.state.config.images.length,
					im = {'im': ''};

				// 单场景
				if (this.scenes.length == 1) {
					let im = this.$store.state.config.images[ (this.currentSceneIdx) % lens ];
					
					let next_im = this.$store.state.config.images[ (this.currentSceneIdx + 1) % lens ];
					this._preloadNextPhotos(next_im);
					
					return im;
				}

				// 多场景 2021.08.16 byhz 发现仅会获取当前的照片，应该和full场景显隐的v-if有关 暂时增加一个单独逻辑加载下张图
				let currentIdx = this.currentSceneIdx % this.scenes.length,
					lastIdx = (this.currentSceneIdx-1) % this.scenes.length,
					nextIdx = (this.currentSceneIdx+1) % this.scenes.length;


				if (index == lastIdx) {
					im = this.$store.state.config.images[ (this.currentSceneIdx-1) % lens ];

				} else if (index == currentIdx) {
					im = this.$store.state.config.images[ (this.currentSceneIdx) % lens ];

				} else if (index == nextIdx) {
					im = this.$store.state.config.images[ (this.currentSceneIdx+1) % lens ];
					this._preloadNextPhotos(im);
				}

				// 预加载下一个场景的图片
				let next_im = this.$store.state.config.images[ (this.currentSceneIdx+1) % lens ];
				this._preloadNextPhotos(next_im);

				return im;
			},
			getSceneMode (scene) {
				return this.mode['mode'][scene];
			},
			wxPreview (image) {
				this.$emit('wxPreview', image);
			},
			// 生命周期：进场动画播放完毕
			preluded () {
				// 延迟执行逻辑保证refs绑定成功
				setTimeout(() => {
					let scene = this.$refs[this.lines[this.scenes[0]]['id']];
					if (scene && scene.length) {
						let t = scene[0];
						t['preluded'] && t['preluded']();
					}
				}, 500);
			},
			getCurrentInterConf (inter) {
				return this.mode['mode']['inter'] && this.mode['mode']['inter'][inter];
			}
		}
	}
</script>

<style scoped lang="less">
	.model-full {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.full-model-enter-active, .full-model-leave-active {
		transition: opacity .3s;
	}
	.full-model-enter, .full-model-leave-to {
		opacity: 0;
	}
	.full-musictext {
		position: absolute;
		font-size: 48px;
		line-height: 48px;
		bottom: 200px;
		font-weight: bolder;
		text-align: center;
		width: 100%;
		color: #ffff64;
		text-shadow: 5px 1px #f70707;
		z-index: 9;
	}
</style>