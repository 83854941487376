Date.prototype.format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

Date.prototype.getSec = function (unit) {
	unit = unit || "s";
	let sec = this.getTime()
	if (unit == "s") {
		sec = parseInt( sec / 1000 )
	}
	return sec;
}

export default {
	dateStr2Date (_date) {
		let temp_date = _date.replace(/:/g, "-");
		temp_date = temp_date.replace(/ /g, "-");

		let arr = temp_date.split("-");
		return new Date( Date.UTC(arr[0], arr[1]-1, arr[2], arr[3]-8, arr[4], arr[5]) );
	},
	// 时间戳补全为13位
	__fixTimestamp (timestamp) {
		var arrTimestamp = (timestamp + '').split('');
			for (var start = 0; start < 13; start++) {
			if (!arrTimestamp[start]) {
				arrTimestamp[start] = '0';
			}
		}
		timestamp = arrTimestamp.join('') * 1;
	},
    // 获取日期差距
    dateDiff(timestamp) {
		if (timestamp == 0 || !timestamp) {
			return '以前';
		}
		timestamp = this.__fixTimestamp(timestamp);
		var minute = 1000 * 60;
		var hour = minute * 60;
		var day = hour * 24;
		var halfamonth = day * 15;
		var month = day * 30;
		var now = new Date().getTime();
		var diffValue = now - timestamp;
		// 如果本地时间反而小于变量时间
		if (diffValue < 0) {
			return '不久前';
		}
		// 计算差异时间的量级
		var monthC = diffValue / month;
		var weekC = diffValue / (7 * day);
		var dayC = diffValue / day;
		var hourC = diffValue / hour;
		var minC = diffValue / minute;
		// 数值补0方法
		var zero = function (value) {
			if (value < 10) {
				return '0' + value;
			}
			return value;
		};
		// 使用
		if (monthC > 12) {
		// 超过1年，直接显示年月日
		return (function () {
			var date = new Date(timestamp);
			return date.getFullYear() + '年' + zero(date.getMonth() + 1) + '月' + zero(date.getDate()) + '日';
		})();
		} else if (monthC >= 1) {
			return parseInt(monthC) + "月前";
		} else if (weekC >= 1) {
			return parseInt(weekC) + "周前";
		} else if (dayC >= 1) {
			return parseInt(dayC) + "天前";
		} else if (hourC >= 1) {
			return parseInt(hourC) + "小时前";
		} else if (minC >= 1) {
			return parseInt(minC) + "分钟前";
		}
		return '刚刚';
	},
    // 获取两个时间中间隔天数
    daysDistance (dateString1, dateString2){
		var  startDate = Date.parse(dateString1);
		var  endDate = Date.parse(dateString2);
		var days=(endDate - startDate)/(1*24*60*60*1000);
		return  days;
	},
    // 时间格式化
    getFormateTime (time, format) {
		format = format || "yyyy-MM-dd hh:mm:ss";
        // 传入为date类型
        if (this.typeOf(time) === "date") {
            return time.format(format);
        // 传入类型为时间戳
        } else if (this.typeOf(time) === "number") {
            // 补全为13位
            var arrTimestamp = (time + '').split('');
            for (var start = 0; start < 13; start++) {
                if (!arrTimestamp[start]) {
                    arrTimestamp[start] = '0';
                }
            }
            time = arrTimestamp.join('') * 1;
            return new Date(time).format(format)
        }
    },
	// 获取年月日
	getTimeDetail(date) {
		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		let day = date.getDate();
		let hour = date.getHours();
		let minute = date.getMinutes();
		let second = date.getSeconds();
		return {year, month, day, hour, minute, second};
    },
	// 判断特殊时间段
	isTime (time, timing) {
		if (this.typeOf(time) == "number") { //传入的为时间戳则转化为Date
			time = new Date( this.__fixTimestamp(time) );
		}
		let {year, month, day, hour, minute} = this.getTimeDetail(time);
		if (timing == "morning") { // [03:00-10:30)
			return hour >= 3 && (hour < 10 || (hour == 10 && minute <= 30));
		} else if (timing == "noon") { // [10:30~13:30)
			return (hour >10 || (hour == 10 && minute > 30)) && (hour < 14 || (hour == 14 && minute < 30));
		} else if (timing == "afternoon") { // [13:30~17:30)
			return hour > 2 && (hour < 19 || (hour == 19 && minute <= 30));
		} else if (timing == "night") { // [07:30~23:59 && 00:00~03:00)
			return hour < 3 || hour > 19 || (hour == 19 && hour > 30)
		} else if (timing == "weekend") { // 周六、周日
			return [0,6].indexOf(6) != -1;
		}
	},
	// 判断是否是当前天的方法
	isCurrentDay (date) {
		let {year: currentYear, month: currentMonth, day: currentDay} = this.getYearMonthDay(new Date());
		let {year, month, day} = this.getYearMonthDay(date);
		return currentYear == year && currentMonth == month && currentDay == day;
	},
	// 时间计算
	timelog (options) {
		let {
			scene,
			step
		} = options || {};
		// 测试环境生效
		// || !this.$config.ISDEBUG
		if (!(scene && step)) {
			return;
		}
		scene = "timelog_" + scene;
		let __now__ = (new Date()).getSec("ms"),
			last = this.$global.get(scene) || __now__;

		console.log(scene, " >> ", step, " : ", __now__ - last);

		this.$global.set(scene, __now__);
	}
}