<template>
	<div class="huaban">
		<img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	    <img :src="getHuaban()">
	</div>
</template>

<script>
	export default {
		name: 'huaban',
		props: ['mode'],
		data () {
			return {}
		},
		methods: {
			getHuaban () {
				let len = this.mode.length;
				return this.mode[parseInt( Math.random() * 1000000 ) % len];
			}
		}
	}
</script>

<style>
	.huaban img {
		opacity: 0;
	}
	.huaban img:nth-child(1) {
		position: absolute;
		width: 37px;
		top: -100px;
		left: -47px;
		animation: falling0 11s linear 2s infinite;
	}
	.huaban img:nth-child(2) {
		position: absolute;
		width: 31px;
		top: -100px;
		left: 60px;
		animation: falling1 8s linear 6s infinite;
	}
	.huaban img:nth-child(3) {
		position: absolute;
		width: 44px;
		top: -100px;
		left: 5px;
		animation: falling2 12s linear 3s infinite;
	}
	.huaban img:nth-child(4) {
		position: absolute;
		width: 27px;
		top: -100px;
		left: 244px;
		animation: falling0 11s linear 4s infinite;
	}
	.huaban img:nth-child(5) {
		position: absolute;
		width: 15px;
		top: -100px;
		left: 222px;
		animation: falling1 10s linear 3s infinite;
	}
	.huaban img:nth-child(6) {
		position: absolute;
		width: 24px;
		top: -100px;
		left: -33px;
		animation: falling2 8s linear 1s infinite;
	}
	.huaban img:nth-child(7) {
		position: absolute;
		width: 50px;
		top: -100px;
		left: 5px;
		animation: falling0 9s linear 1s infinite;
	}
	.huaban img:nth-child(8) {
		position: absolute;
		width: 21px;
		top: -100px;
		left: 82px;
		animation: falling1 10s linear 0s infinite;
	}
	.huaban img:nth-child(9) {
		position: absolute;
		width: 18px;
		top: -100px;
		left: -44px;
		animation: falling2 9s linear 6s infinite;
	}
	.huaban img:nth-child(10) {
		position: absolute;
		width: 38px;
		top: -100px;
		left: -92px;
		animation: falling0 12s linear 4s infinite;
	}
	.huaban img:nth-child(11) {
		position: absolute;
		width: 35px;
		top: -100px;
		left: 4px;
		animation: falling1 10s linear 5s infinite;
	}
	.huaban img:nth-child(12) {
		position: absolute;
		width: 27px;
		top: -100px;
		left: 198px;
		animation: falling2 12s linear 1s infinite;
	}
	.huaban img:nth-child(13) {
		position: absolute;
		width: 35px;
		top: -100px;
		left: 162px;
		animation: falling0 8s linear 8s infinite;
	}
	.huaban img:nth-child(14) {
		position: absolute;
		width: 22px;
		top: -100px;
		left: 55px;
		animation: falling1 10s linear 5s infinite;
	}
	.huaban img:nth-child(15) {
		position: absolute;
		width: 36px;
		top: -100px;
		left: 69px;
		animation: falling2 11s linear 2s infinite;
	}
    @keyframes falling0 {
        0% {
        	opacity: 0;
            transform: translate3d(0px,0px,0px) rotateX(0deg) rotateY(0deg);
        }
        5% {
        	opacity: 1;
        }
        100% {
        	opacity: 1;
            transform: translate3d(100px,1000px,200px) rotateX(500deg) rotateY(500deg);
        }
    }
    @keyframes falling1 {
        0% {
        	opacity: 0;
            transform: translate3d(0px,0px,0px) rotateX(0deg) rotateY(0deg);
        }
        8% {
        	opacity: 1;
        }
        100% {
        	opacity: 1;
            transform: translate3d(300px,1000px,-120px) rotateX(420deg) rotateY(420deg);
        }
    }
    @keyframes falling2 {
        0% {
        	opacity: 0;
            transform: translate3d(0px,0px,0px) rotateX(0deg) rotateY(0deg);
        }
        2% {
        	opacity: 1;
        }
        100% {
        	opacity: 1;
            transform: translate3d(500px,1000px,-322px) rotateX(520deg) rotateY(520deg);
        }
    }
</style>