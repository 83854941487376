<template>
	<div class="dong9-page">
		<div class="dong9-1 blurImage" :style="{backgroundImage: imageStyle['backgroundImage']}"></div>
		<div class="dong9" :class="{animated: animated}" :style="imageStyle" @click="wxPreview"></div>
	</div>
</template>

<script>
	export default {
		name: 'dong9',
		props: ['mode', 'currentImage'],
		data () {
			return {
				imageStyle: {
					top: '50%',
					left: '0px',
					width: '0px',
					height: '0px',
					marginTop: '0px',
					backgroundImage: 'url()'
				},
				animated: false
			}
		},
		watch: {
			currentImage () {
				this.analyseImage();
			},
		},
		mounted () {
			this.analyseImage();
		},
		methods: {
			start (scenedx, idx) {
				if (idx) {
					this.animated = true;
				}
			},
			end () {
				console.log("end");
				setTimeout(() => {
					this.animated = false;
				}, 1000);
			},
			preluded () {
				console.log("preluded");
				this.animated = true;
			},
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			},
			analyseImage () {
				let twidth = this.$env.bw,
					theight = this.$env.bw * 1.25;
				this.imageStyle['width'] = twidth + 'px';
				this.imageStyle['height'] = theight.toString() + 'px';
				this.imageStyle['marginTop'] = -(theight/2) + 'px';
				this.imageStyle['backgroundImage'] = 'url('+ this.currentImage['im'] +')';
			}
		}
	}
</script>

<style scoped>
	.animated {
		animation: 10s linear 0s 1 normal both running div1-in;
	}
	.dong9-1 {
		position: absolute;
		background-position: center center;
	    background-size: cover;
		animation: 10s linear 0s 1 normal both running div11h-in;
	}
	@keyframes div11h-in {
		0% {
		    transform: scale(2);
		    opacity: 0;
		}
		5% {
			opacity: 0.8;
		}
		20% {
		    transform: scale(2.04);
		    opacity: 1;
		}
		80% {
		    transform: scale(2.16);
		    opacity: 1;
		}
		100% {
		    transform: scale(2.2);
		    opacity: 0.8;
		}
	}
	.dong9 {
		position: absolute;
		background-position: center center;
	    background-size: contain;
		background-repeat: no-repeat;
		opacity: 0;
	}
	@keyframes div1-in {
		0% {
		    transform: scale(1);
		    opacity: 0;
		}
		20% {
		    transform: scale(1.04);
		    opacity: 1;
		}
		80% {
		    transform: scale(1.16);
		    opacity: 1;
		}
		100% {
		    transform: scale(1.2);
		    opacity: 0;
		}
	}
</style>