import st_upload from './st_upload.base'

class stUpload extends st_upload.stUpload {
    constructor (util) {
        super(util)

        this.can_i_use_b64 = null;
        // this.can_i_use_b64 = true;
    }

    /**
     * 判断是否可以直接使用base64
     */
    async canIUseB64() {
        if (this.can_i_use_b64 !== null) {
            return this.can_i_use_b64;
        }
        let _this = this;
        this.can_i_use_b64 = await new Promise((resolve, reject)=>{
            _this.$util.$wx.wxsdk.checkJsApi({
                jsApiList: ['getLocalImgData'],
                success: function(res) {
                    if (res && res["checkResult"] && res["checkResult"]["getLocalImgData"]) {
                        resolve(true)
                    } else {
                        resolve(false);
                    }
                }
            })
        })
        // api不可用直接返回
        if (!this.can_i_use_b64){
            return false;
        }

        let userAgent = navigator.userAgent.toLowerCase();
        // 测试并已适配的机型
        // let isIphone = userAgent.match(/iphone|ipad/i) == "iphone";
        // let isHuawei = userAgent.match(/huawei/i) == "huawei";
        // let isHonor = userAgent.match(/honor/i) == "honor";
        // let isOppo = userAgent.match(/oppo/i) == "oppo";
        // let isOppoR15 = userAgent.match(/pacm/i) == "pacm";
        // let isOppoV = userAgent.match(/pbcm/i) == "pbcm";
        // let isVivo = userAgent.match(/vivo/i) == "vivo";
        // let isZte = userAgent.match(/zte/i) == "zte";
        let onePlus = userAgent.indexOf("gm1910") > 0;
        let isXiaomi = userAgent.match(/mi\s/i) == "mi " || userAgent.match(/mix\s/i) == "mix " || userAgent.match(/redmi/i) == "redmi";
        let isSamsung = userAgent.match(/sm-/i) == "sm-" || userAgent.match(/gt-/i) == "gt-" || userAgent.match(/sch-/i) == "sch-";
        if (isXiaomi || isSamsung || onePlus){// 三星，小米，一加 使用base64的api 图片会旋转 暂时转用七牛上传
            this.can_i_use_b64 = false;
            return false;
        } else {
            return true;
        }
    }
    
    async chooseImage (options) {
        let _this = this;
        
        // 选择图片
        await new Promise((resolve, reject)=>{
            // 基础参数
            let params = {
                count: this.count,
                sizeType: this.sizeType,
                sourceType: this.sourceType,
            }
            let success_func = function (res) {
                _this.log("upload_media",{
                    "step": "chosed",
                    "method": _this.can_i_use_b64 == true ? "oss" : "qiniu",
                })
                // _this.$util.$taro.showLoading
                debugger;
                _this.showLoading({
                    title: '加载中...',
                    mask: true
                })
                let localIds = res["localIds"];
                
                // 确保上传顺序
                _this.chosed_count = localIds.length;
                for (let i = 0 ; i < localIds.length ; i ++ ){
                    _this.media_infos.push({});
                }

                // 必须用递归实现逐张图片获取base64 || 微信上传图片也需要逐
                const getResults = ids => {
                    
                    const localId = ids.shift()

                    if (_this.can_i_use_b64 == true) { // 获取base64
                        _this.$util.$wx.wxsdk.getLocalImgData({
                            localId,
                            success: response => {
                                let { localData } = response
                                // 适配安卓base64
                                if (localData.indexOf('data:image') != 0) {
                                    //判断是否有这样的头部
                                    localData = 'data:image/jpeg;base64,' +  localData
                                }
                                localData = localData.replace(/\r|\n/g, '');
                                // 确保上传顺序
                                let idx = localIds.indexOf(localId);
                                _this.pushUploadPipe({
                                    "localId": localId,
                                    "index": idx,
                                    "file": localData
                                }).then(()=>{
                                    resolve()
                                }).catch(err=>{
                                    if (err.mode == "break") {
                                        reject(err);
                                    } else if (err && err.localId){
                                        // 调用七牛重试
                                        if (_this.can_i_use_b64){
                                            _this.can_i_use_b64 = false;
                                            _this.log("upload_media",{
                                                "step": "qiniu_retry",
                                            })
                                            getResults( _this.$util.copy(localIds) )
                                        }
                                    }
                                })
                                if (ids.length > 0) { // 递归调用
                                    getResults(ids)
                                }
                            },
                        })
                    } else { // 微信api上传 必须用递归实现逐张上传
                        _this.$util.$wx.wxsdk.uploadImage({
                            localId: localId,
                            isShowProgressTips: 1,// 2021-07-09 临时规避8.07版本微信bug // 2022-02-22 隐藏loading
                            success (upload_img_res) {
                                // 确保上传顺序
                                let idx = localIds.indexOf(localId);
                                _this.pushUploadPipe({
                                    "localId": localId,
                                    "index": idx,
                                    // "file": _this.$util.$store.state.appConfig.image_map.host_map['qiniu'] + upload_img_res.serverId,
                                    "file": _this.upload_config['qiniu']['host'] + upload_img_res.serverId,
                                    "serverId": upload_img_res.serverId
                                }).then(()=>{
                                    resolve()
                                }).catch(err=>{
                                    console.log("pushUploadPipe==>",err)
                                    if (err.mode == "break") {
                                        reject(err);
                                    }
                                })

                                if (ids.length > 0) { // 递归调用
                                    getResults(ids)
                                }
                            },
                            fail (err) {
                                this.finsh_count = this.finsh_count + 1;
                                if (ids.length > 0) { // 递归调用
                                    getResults(ids)
                                }
                            }
                        })
                    }
                }
                getResults( _this.$util.copy(localIds) )
            },
            fail_func = function (err){
                console.error("upload err=>",err)
                _this.fail && _this.fail(err)
                _this.log("upload_media",{
                    "step": "chose_fail",
                    "err": err
                })
                reject();
                _this.showToast({
                    title: '拉起相册失败',
                    icon: 'none',
                    duration: 5000
                })
            },
            cancel_func = function (res){
                _this.cancel && _this.cancel(res)
                reject();
                _this.log("upload_media",{
                    "step": "chose_cancel"
                })
            };
            Object.assign(params, {
                success: success_func,
                fail: fail_func,
                cancel: cancel_func
            })

            _this.$util.$wx.wxsdk.chooseImage(params)
            
        }).catch(err=>{
            console.error("upload err=>",err)
            if (err){
                _this.fail && _this.fail(err)
                _this.log("upload_media",{
                    "step": "chose_fail"
                })
            }
            return Promise.reject(err);
        })
        return Promise.resolve()
    }

    async pushUploadPipe (options) {
        let {index, file, serverId, localId} = options,
            // host = this.$util.$store.state.appConfig.image_map.host_map['oss'],
            host = this.upload_config['ali']['host'],
            path = "",
            width = 0,
            height = 0,
            fileName = "",
            _this = this;

        // base64压缩 + oss上传
        if (!serverId) {

            // 压缩
            let compress_res = await this.compressImg(file).catch(err=>{
                    _this.log("upload_media",{
                        "step": "compress_fail"
                    })
                });

            if (compress_res) {
                file = compress_res['file'];
                
                const { width: originWidth, height: originHeight, size: originSize } = compress_res['params'];

                if (originWidth && originHeight && originSize) {
                    fileName = originWidth + "," + originHeight + "," + originSize + "," + (this.$util.$login.openid ? this.$util.$login.openid : localId )
                    width = originWidth;
                    height = originHeight;
                }
            }

            if (!fileName) {
                fileName = localId + file;
            }

            fileName = this.$util.md5(fileName);
            
            // 上传到oss
            let oss_success = await this.uploadFile({
                "filePath": file,
                "fileName": fileName
            }).catch(err=>{})

            if (!oss_success) {
                return Promise.reject({
                    "localId": localId,
                })
            }
            
            path = this.upload_config['ali']['sub_path'] + fileName
        }
        
        // 七牛fetch
        if (serverId) {
            // host = this.$util.$store.state.appConfig.image_map.host_map['qiniu'];
            host = this.upload_config['qiniu']['host'];
            path = serverId;
            await this.$util.rpost('https://alifun.litiskr.cn/script/wx_qiniu_fetch', {
                'appid': this.$util.$wx.appid,
                'serverids': serverId
            }).catch(err=>{
                _this.log("upload_media",{
                    "step": "qiniu_fetch_err"
                })
            });
            // 七牛图片获取宽高
            let imageInfo = await this.$util.getImageInfo({
                "src": host + path
            }).catch(err=>{
                console.log(err)
            });
            width = imageInfo && imageInfo['width'];
            height = imageInfo && imageInfo['height'];
        }

        // 鉴黄
        let issave = await this.$util.safetyCheck({
            "mode": this.checkMode || 4,
            "imgs": [{
                "host": host,
                "path": path
            }]
        }).catch(err=>{
            console.error("safetyCheck error: ", err);
        });
        if (issave == "block") {
            // 替换为统一的违规图片
            // host = this.$util.$store.state.appConfig.image_map.host_map['oss'];
            host = this.upload_config['ali']['host'];
            path = "mmbiz_jpg/LhYAiaRvYxIic7OavJoa2bIelsM6aH0U5TkiauwKEo4t1fhaAYxjvfcxPOngXnKLIiaHUxnHXlJ8gyvkWtCfmdSoxA/0";
            width = 100;
            height = 100;

            let violate_res = await this.violate(file).catch(err=>{
                console.log("violate callback error", err);
            });
            console.error("violate > ", violate_res);
            // 中断上传模式
            if (violate_res && violate_res['mode'] == "break") {
                this.hideLoading();
                
                this.showToast({
                    title: '请勿上传违规照片!',
                    icon: 'none',
                    duration: 3000
                })
                return Promise.reject({
                    errcode: 40003,
                    mode: "break"
                })
            }
        }

        this.media_infos[index] = {
            "url": host + path,
            "width": width,
            "height": height,
            "sign": fileName
        };

        // 进度展示
        this.finsh_count = this.finsh_count + 1;
        // 加载第n张
        // this.$util.$taro.showLoading
        this.showLoading({
            title: this.finsh_count + "/"+ this.chosed_count +" 上传中",
            mask: true
        })
        if (this.finsh_count == this.chosed_count) {
            // this.$util.$taro.hideLoading();
            this.hideLoading();
            return Promise.resolve()
        } else {
            this.process && this.process();
            return Promise.reject()
        }
    }

    /**
     * 压缩图片
     * @param file base64图片
     */
    // 图片最大尺寸限制
    compressImg(file) {
        let _this = this,
            params = {};
        return new Promise((resolve, reject) => {
            params['size'] = file.length;
            new Promise((resol, rejc)=>{ 
                if (file.length * 0.75 > _this.maxsize && _this.oversize) { // 外部函数决定如何处理
                    _this.oversize()
                    .then(()=>{resol()})
                    .catch(()=>{reject()})
                } else {
                    resol()
                }
            }).then(()=>{
                let img = new Image();
                
                img.onload = function() {
                    const { width: originWidth, height: originHeight } = this; // img
                    params['width'] = originWidth;
                    params['height'] = originHeight;

                    // 500kb以下图片不压缩
                    if (file.length * 0.75 < _this.maxsize/4) {
                        resolve( {
                            "file" : file,
                            "params": params
                        } )
                    } else {
                        _this.log("upload_media", {
                            "step": "compress",
                        })
                        // 目标尺寸
                        let targetWidth = originWidth;
                        let targetHeight = originHeight;
                        if (originWidth > _this.maxWidth || originHeight > _this.maxHeight) {
                            if (originWidth / originHeight > 1) {
                                // 宽图片
                                targetWidth = _this.maxWidth;
                                targetHeight = Math.round(_this.maxWidth * (originHeight / originWidth));
                            } else {
                                // 高图片
                                targetHeight = _this.maxHeight;
                                targetWidth = Math.round(_this.maxHeight * (originWidth / originHeight));
                            }
                        }
                        params['width'] = targetWidth;
                        params['height'] = targetHeight;
                        
                        // 普通canvas
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        // 设置 canvas 画布大小
                        canvas.width = targetWidth;
                        canvas.height = targetHeight;
                        context.clearRect(0, 0, targetWidth, targetHeight);

                        // 离屏canvas
                        try {
                            const offscreen = new OffscreenCanvas(targetWidth, targetHeight);
                            const ctx = offscreen.getContext("2d");
                            // 离屏canvas绘制
                            ctx.drawImage(this, 0, 0, originWidth, originHeight, 0, 0, targetWidth, targetHeight);
                            const imageBitmap = offscreen.transferToImageBitmap();
                            // 离屏canvas绘制的图像 转绘画到dom的canvas中
                            context.drawImage(imageBitmap, 0, 0);
                            // console.error("useing OffscreenCanvas")
                        } catch (error) {
                            // 图像直接绘制到dom结构中的canvas
                            context.drawImage(this, 0, 0, originWidth, originHeight, 0, 0, targetWidth, targetHeight);
                        }


                        // 压缩并返回图片
                        resolve( {
                            "file" : canvas.toDataURL('image/jpeg', 0.9),
                            "params": params
                        } ); //mime || 'image/jpeg'
                    }
                }
                img.onerror = function() {
                    reject()
                }
                img.src = file;
            }).catch(err=>{
                resolve( file )
                _this.log("upload_media",{
                    "step": "compress_fail"
                })
            })
        })
    }

    /**
     * 上传文件至阿里云
     */
    async uploadFile (params) {
        let _this = this,
            aliyunServerURL = this.upload_config['ali']['upload_host'];
        const aliyunFileKey = params.fileName;
        if (params['fileHost']) {
            aliyunServerURL = params['fileHost'];
        }

        await this.getAliyunAccessData();

        const accessid = this.aliyun_access_data.access_id;
        const policyBase64 = this.aliyun_access_data.policy;
        const signature = this.aliyun_access_data.sign;

        return await new Promise ((resolve, reject)=>{
            _this.$util.$taro.uploadFile({
                url: aliyunServerURL,
                filePath: params.filePath,
                name: 'file',
                formData: {
                    'key': _this.upload_config['ali']['sub_path'] + aliyunFileKey,
                    'policy': policyBase64,
                    'OSSAccessKeyId': accessid,
                    'signature': signature,
                    'success_action_status': '200',
                },
                success: res=> {
                    resolve(true)
                },
                fail: err=> {
                    debugger;
                    _this.log("upload_media",{
                        "step": "oss_upload_fail"
                    })
                    resolve(false)
                },
            })
        })
    }
}

export default {
    stUpload
};