<template>
	<div class="light2 page">
		<img class="light2_1 po" src="http://resali.lititutu.cn/tuku/81f81bb64069f031a2f516234a138aa3.png">
		<img class="light2_2 po" src="http://resali.lititutu.cn/tuku/83cfd0fd0244fc3981c3aeff9ae56fea.png" >
	</div>
</template>

<script>
	export default {
		name: 'light2',
		data () {
			return {}
		}
	}
</script>

<style>
	.light2 {
		z-index: 7;
		opacity: .5;
		pointer-events: none;
	}
	.light2_1 {
	    top: -290px;
	    left: -315px;
	    animation: sun_light 18s ease-in-out infinite alternate both;
	    transform-origin: 32.5% 27.5%;
	}
	@keyframes sun_light {
		0% {
		    transform: rotateZ(-3deg);
		}
		100% {
		    transform: rotateZ(11deg);
		}
	}
	.light2_2 {
		transform-origin: -50px -100px;
		animation: sun_light 18s ease-in-out infinite alternate both;
	}
</style>