<template>
	<div class="pingianBox">
		<div id="pingianBox-qiandiv" class="po pingianBox-qiandiv">
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(55deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(111deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(326deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(314deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(316deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(36deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(26deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(313deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(329deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(307deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(278deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(250deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(104deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(205deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(249deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(309deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(166deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(290deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(204deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(94deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(32deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(10deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(339deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(39deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(11deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(221deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(266deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(29deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(103deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(30deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(154deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(221deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(86deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(351deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(52deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(151deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(257deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(258deg);"></div>
		    <div>
		        <img class='pingianBox-hua' :src="pingHua()" style="position: absolute; transform: rotate(317deg);"></div>
		</div>
		<img id="pingianBox-qian1" class="po pingianBox-hua" :src="pingHua()" />
		<img id="pingianBox-qian2" class="po pingianBox-hua" :src="pingHua()" />
		<img id="pingianBox-qian3" class="po pingianBox-hua" :src="pingHua()" />
		<img id="pingianBox-qian4" class="po pingianBox-hua" :src="pingHua()" />
		<img class="po pingianBox-above" :src="mode[1]"></img>
		<div class="pingianBox-contain" :style="getStyle">
			<div class="pingianBox-userImg1 pingianBox-userImg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<div class="pingianBox-userImg2 pingianBox-userImg" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')'}"></div>
			<img class="pingianBox-cube pingianBox-leftdiv">
			<img class="pingianBox-cube pingianBox-rightdiv">
		</div>
		<!-- xinniancaiyuangungun装饰 -->
		<template v-if="'xinniancaiyuangungun' == currentTid">
			<div class="luohua_zhuangshi">
	            <img class="shanzi shanziright" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/07.png">
	            <img class="shanzi shanzileft" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/07.png">

	            <div class="guashi">
	                <img class="guashi_right" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/08.png">    
	                <img class="guashi_left" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/12.png">    
	            </div>
	            <div class="guashi guashi2">
	                <img class="guashi_right" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/08.png">    
	                <img class="guashi_left" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/12.png">    
	            </div>
	            <img class="zhuti_top" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/09.png"> 

	            <img class="shuleft" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/10.png">
	            <img class="shuright" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/11.png">  
	            
	            <img class="lit lit1" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/13.png">
	            <img class="lit lit2" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/14.png">
	            <img class="lit lit3" alt="" src="http://resali.lititutu.cn/scenes/xinniancaiyuangungun/15.png">
	        </div>
		</template>

		<!-- xinnianyouyu装饰 -->
		<template v-if="'xinnianyouyu' == currentTid">
			<div class="luohua_zhuangshi1">
	            <img class="huatop" src="http://resali.lititutu.cn/scenes/xinnianyouyu/09.png">
	            <img class="denglong_left" src="http://resali.lititutu.cn/scenes/xinnianyouyu/10.png">
	            <img class="qiandai" src="http://resali.lititutu.cn/scenes/xinnianyouyu/11.png">
	            <img class="dibu" src="http://resali.lititutu.cn/scenes/xinnianyouyu/12.png">
	            <img class="bianbao" src="http://resali.lititutu.cn/scenes/xinnianyouyu/13.png">
	            <img class="fish" src="http://resali.lititutu.cn/scenes/xinnianyouyu/14.png">
	            <img class="huabot" src="http://resali.lititutu.cn/scenes/xinnianyouyu/15.png">
	        </div>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'pingbox',
		props: ['mode', 'currentImage', 'showStatus'],
		methods: {
			pingHua () {
				let huas = this.mode[2];
				if ( typeof huas == 'object' ) {
					return huas[ parseInt(Math.random()*100000)%huas.length ];
				} else {
					return huas;
				}
			},
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		},
		computed: {
			getStyle () {
				let twidth, theight, standsize = [430, 530];

				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], standsize, 'both');
					twidth = tsize[0];
					theight = tsize[1];
				} else {
					twidth = standsize[0];
					theight = standsize[1];
				}
				
				return {
					width: twidth + 'px',
					height: theight + 'px'
				}
			},
			currentTid () {
				return this.$store.state.config.data['ttid'];
			}
		}
	}	
</script>

<style lang="less" scoped>
	@keyframes div1_left {
	    0% {
	        transform: translate(-50%, -50%) rotateY(0deg) scale(1);
	    }
	    50% {
	        transform: translate(-50%, -50%) rotateY(180deg) scale(.5);
	    }
	    100% {
	        transform: translate(-50%, -50%) rotateY(1turn) scale(1);
	    }
	}
	.pingianBox-contain {
	    // width:@bw * 0.847;
	    animation: div1_left 8s linear 0s infinite normal both running;
	    display: block;
	    background: no-repeat 50%;
	    background-size: cover;
	    top: 50%;
	    transform-style: preserve-3d;
	    left: 50%;
	    position: absolute;
	    transform: translate(-50%, -50%);
	    z-index: 2;
	}
	.pingianBox-userImg {
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    position: absolute;
	    background: no-repeat 50%;
	    background-size: cover;
	    background-position: center 20%;
	    display: block;
	}
	.pingianBox-userImg1 {
	    position: absolute;
	    transform: translateZ(-@bw * 0.028);
	}
	.pingianBox-userImg2 {
	    transform: translateZ(@bw * 0.028);
	}
	.pingianBox-leftdiv {
	    transform: translateX(-@bw * 0.028) rotateY(-90deg);
	    left: 0;
	}
	.pingianBox-rightdiv {
	    transform: translateX(@bw * 0.028) rotateY(90deg);
	    right: 0;
	}
	.pingianBox-cube {
	    width:@bw * 0.056;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    opacity: .6;
	    background: linear-gradient(#0047ff, red);
	    animation: colorr 11s linear 0s infinite normal both running;
	}
	@keyframes colorr {
	    0% {
	        background: linear-gradient(#0047ff, red);
	    }
	    50% {
	        background: linear-gradient(#ff4200, #003bff);
	    }
	    100% {
	        background: linear-gradient(#ff9800, #f43703);
	    }
	}
	.pingianBox, .pingianBox-above {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
		z-index: 0;
	}
	.pingianBox-above {
	    z-index: 1;
	    animation: above_in .8s both linear;
	}
	.pingianBox-bg {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    background-repeat: no-repeat;
	    background-position: center 20%;
	    background-size: cover;
	}
	@keyframes rotating {
	    0% {
	        transform: translate(@bw * 0.0, @bw * 0.0) rotate(0deg);
	        opacity: 1;
	    }
	    90% {
	        opacity: 1;
	    }
	    100% {
	        transform: translate(@bw * 0.0, @bw * 1.528) rotate(40deg);
	        opacity: 0;
	    }
	}
	img {
	    border: 0;
	    vertical-align: middle;
	}
	#pingianBox-qiandiv {
	    top:@bw * 0.208;
	    position: absolute;
	    left:@bw * 0.153;
	}
	#pingianBox-qiandiv img {
	    // width:@bw * 0.139;
	}
	@keyframes above_in {
	    from {
	        transform: scale(1.1);
	    }
	    to {
	        transform: none;
	    }
	}
	#pingianBox-qian1 {
	    left:@bw * 0.132;
	    bottom: -@bw * 0.356;
	    -webkit-animation: pingianBox-qian1 2s linear 1s 6 both;
	}
	@-webkit-keyframes pingianBox-qian1 {
	    from {
	        -webkit-transform: rotate(90deg);
	    }
	    to {
	        -webkit-transform: translate(-@bw * 0.619-@bw * 1.585) rotate(65deg);
	    }
	}
	#pingianBox-qian2 {
	    width:@bw * 0.222;
	    left:@bw * 0.944;
	    bottom:@bw * 0.347;
	    -webkit-transform-origin: 74% 250%;
	    -webkit-animation: pingianBox-qian2 2s linear 1s 6 both;
	}
	@-webkit-keyframes pingianBox-qian2 {
	    from {
	        -webkit-transform: rotate(-100deg);
	    }
	    to {
	        -webkit-transform: rotate(0deg);
	    }
	}
	#pingianBox-qian3 {
	    left: -@bw * 0.354;
	    bottom:@bw * 0.565;
	    width:@bw * 0.208;
	    -webkit-transform-origin: 63% 400%;
	    -webkit-animation: pingianBox-qian3 1.5s linear 1s 6 both;
	}
	@-webkit-keyframes pingianBox-qian3 {
	    from {
	        -webkit-transform: rotate(116deg);
	    }
	    to {
	        -webkit-transform: rotate(0deg);
	    }
	}
	#pingianBox-qian4 {
	    left: -@bw * 0.34;
	    bottom:@bw * 0.519;
	    width:@bw * 0.208;
	    -webkit-transform-origin: 29% 433%;
	    -webkit-animation: pingianBox-qian4 2s linear 0.3s both;
	}
	@-webkit-keyframes pingianBox-qian4 {
	    from {
	        -webkit-transform: rotate(92deg);
	    }
	    to {
	        -webkit-transform: rotate(0deg);
	    }
	}
	.pingianBox-qiandiv>div:nth-child(0) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.185;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 0s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(1) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.075;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 0.28s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(2) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.526;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 0.56s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(3) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.021;
	    top: -@bw * 0.278;
	    animation: rotating 11s linear 0.84s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(4) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.346;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 1.12s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(5) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.126;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 1.4s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(6) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.122;
	    top: -@bw * 0.278;
	    animation: rotating 15s linear 1.68s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(7) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.189;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 1.96s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(8) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.494;
	    top: -@bw * 0.278;
	    animation: rotating 11s linear 2.24s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(9) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.557;
	    top: -@bw * 0.278;
	    animation: rotating 11s linear 2.52s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(10) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.421;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 2.8s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(11) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.017;
	    top: -@bw * 0.278;
	    animation: rotating 13s linear 3.08s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(12) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.347;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 3.36s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(13) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.107;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 3.64s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(14) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.094;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 3.92s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(15) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.274;
	    top: -@bw * 0.278;
	    animation: rotating 11s linear 4.2s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(16) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.564;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 4.48s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(17) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.41;
	    top: -@bw * 0.278;
	    animation: rotating 15s linear 4.76s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(18) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.004;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 5.04s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(19) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.299;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 5.32s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(20) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.008;
	    top: -@bw * 0.278;
	    animation: rotating 13s linear 5.6s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(21) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.032;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 5.88s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(22) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.407;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 6.16s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(23) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.465;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 6.44s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(24) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.361;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 6.72s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(25) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.181;
	    top: -@bw * 0.278;
	    animation: rotating 13s linear 7s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(26) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.065;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 7.28s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(27) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.065;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 7.56s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(28) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.006;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 7.84s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(29) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.293;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 8.12s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(30) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.131;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 8.4s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(31) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.06;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 8.68s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(32) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.557;
	    top: -@bw * 0.278;
	    animation: rotating 11s linear 8.96s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(33) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.025;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 9.24s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(34) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.231;
	    top: -@bw * 0.278;
	    animation: rotating 15s linear 9.52s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(35) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.367;
	    top: -@bw * 0.278;
	    animation: rotating 14s linear 9.8s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(36) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left: -@bw * 0.021;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 10.08s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(37) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.075;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 10.36s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(38) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.571;
	    top: -@bw * 0.278;
	    animation: rotating 10s linear 10.64s infinite;
	}
	.pingianBox-qiandiv>div:nth-child(39) {
	    // width:@bw * 0.406;
	    // height:@bw * 0.201;
	    position: absolute;
	    left:@bw * 0.003;
	    top: -@bw * 0.278;
	    animation: rotating 12s linear 10.92s infinite;
	}
	// xinniancaiyuangungun装饰 
	.shuleft {
	    position: absolute;
	    // width:@bw * 0.278;
	    left: -@bw * 0.028;
	    bottom:@bw * 0.486;
	    transform-origin: bottom center;
	    animation: shu_left 1.5s linear infinite alternate;
	    width:@bw * 0.278;
	}
	.shuright {
	    position: absolute;
	    // width:@bw * 0.278;
	    right: -@bw * 0.028;
	    bottom:@bw * 0.486;
	    transform-origin: bottom center;
	    animation: shu_right 1.5s linear infinite alternate;
	    width:@bw * 0.278;
	}
	.luohua_zhuangshi {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    z-index: 1;
	}
	.shanzileft {
	    position: absolute;
	    left: -@bw * 0.333;
	    top: -@bw * 0.361;
	    transform: rotate(-30deg);
	    animation: shanzi_left 8s linear infinite alternate;
	}
	.shanziright {
	    position: absolute;
	    right: -@bw * 0.347;
	    top: -@bw * 0.361;
	    transform: rotate(130deg);
	    animation: shanzi_right 7.5s linear infinite alternate;
	}
	.guashi {
	    position: absolute
	}
	.guashi2 {
	    right:0;
	}
	.guashi_left {
	    position: absolute;
	    transform-origin: 50% 0%;
	    animation: denglong 1.5s 0.5s ease-in-out infinite alternate;
	}
	.guashi_right {
	    position: absolute;
	    left:@bw * 0.097;
	    transform-origin: 50% 0%;
	    animation: denglong 1.5s ease-in-out infinite alternate;
	}
	.guashi2 .guashi_left {
	    left: -@bw * 0.181;
	}
	.guashi2 .guashi_right {
	    left: -@bw * 0.278;
	}
	.zhuti_top {
	    position: absolute;
	    left: 50%;
	    /* transform: translate(-50%, 0); */
	    width:@bw * 0.507;
	    margin-left: -@bw * 0.26;
	    top:@bw * 0.028;
	    animation: swing 1.5s linear 0s infinite alternate;
	    transform-origin: 15% 50%
	}
	@keyframes denglong {
	    0% {
	        transform: rotate(0deg);
	    }
	    100% {
	        transform: rotate(5deg);
	    }
	}
	@keyframes shanzi_left {
	    0% {
	        transform: rotate(-30deg);
	    }
	    100% {
	        transform: rotate(50deg);
	    }
	}
	@keyframes shanzi_right {
	    0% {
	        transform: rotate(-220deg);
	    }
	    100% {
	        transform: rotate(-260deg);
	    }
	}
	@keyframes shu_left {
	    0% {
	        transform: rotate(0);
	    }
	    100% {
	        transform: rotate(5deg);
	    }
	}
	@keyframes shu_right {
	    0% {
	        transform: rotate(0);
	    }
	    100% {
	        transform: rotate(-5deg);
	    }
	}
	@keyframes swing {
	    0% {
	        transform: rotate(10deg)
	    }
	    50% {
	        transform: rotate(0deg)
	    }
	    100% {
	        transform: rotate(15deg)
	    }
	}
	.lit {
	    position: absolute;
	    width: 101%;
	    bottom: 0;
	}
	.lit1 {
	    bottom:@bw * 0.111;
	    animation: tranTop 2s cubic-bezier(0.13, 0.29, 0.65, 0.81) infinite forwards alternate;
	}
	.lit2 {
	    bottom: -@bw * 0.028;
	    animation: tranTop 2s -2323ms cubic-bezier(0.13, 0.29, 0.65, 0.81) infinite forwards alternate;
	}
	.lit3 {
	    bottom: -@bw * 0.028;
	    animation: tranTop 2s -4323ms cubic-bezier(0.13, 0.29, 0.65, 0.81) infinite forwards alternate;
	}
	@keyframes tranTop {
	    100% {
	        transform: translateY(@bw * 0.028)
	    }
	}

	// xinnianyouyu装饰
	.luohua_zhuangshi1 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .huatop{
        position: absolute;
        top: -@bw * 0.167;
        left: -@bw * 0.111;
        animation: rotateImg 3s linear  infinite normal none running;
    }

    .denglong_left{
        position: absolute;
        top:@bw * 0.556;
        left: -@bw * 0.139;
        animation: tranleftImg 1.5s linear  infinite alternate;
    }

    .qiandai{
        position: absolute;
        bottom: 0;
        left: -@bw * 0.014;
        animation: qiandai 1.2s linear  infinite alternate;

    }

    .dibu{
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .huabot{
        position: fixed;
        bottom: -@bw * 0.139;
        right: -@bw * 0.25;
        transform: rotate(0deg);
        animation: rotateImg 3s linear infinite normal none running;

    }
    .bianbao{
        position: absolute;
        top:@bw * 0.278;
        right: -@bw * 0.069;
        animation: bianbao 1.24s linear infinite alternate;

    }
    .fish{
        position: absolute;
        top:@bw * 0.017;
        right:@bw * 0.014;
    }
    @keyframes rotateImg {
        0%{
            transform: rotate(0)
        }
        
        100%{
            transform: rotate(360deg)
        } 
    }

    @keyframes tranleftImg {
        0%{
            transform: translateX(@bw * 0.0)
        }
        
        100%{
            transform: translateX(@bw * 0.042)
        } 
    }
    @keyframes bianbao {
        0%{
            transform: translateX(@bw * 0.0)
        }
        
        100%{
            transform: translateX(@bw * 0.028)
        } 
    }

    @keyframes qiandai {
        0%{
            transform: translateY(@bw * 0.0)
        }
        
        100%{
            transform: translateY(@bw * 0.042)
        } 
    }
</style>
