import st_observer_base from './st_observer.base'
class stObserver extends st_observer_base.stObserver{
    constructor (util) {
        super(util)
    }

    getObserveElement(name) {
        let _name = name.split(name[0])[1];
        if (name.startsWith("#")) {
            return document.getElementById(_name);
        } else {
            return document.getElementsByClassName(_name);
        }
    }

    initObserver (options) {
        let {
            self,
            arvgs,
            parent,
            target,
            margins,
            threshold,
            cb
        } = options;
        parent = parent || "__viewport__";
        let observer_id = this.getObserverId(parent, target);
        if (!this.observer_map[observer_id]) {
            // 初始化参数拼接
            let params = {};
            if (parent != "__viewport__") {
                params["root"] = this.getObserveElement(parent);
            }
            if (margins) {
                params["rootMargin"] = margins;
            }
            if (threshold) {
                params["threshold"] = threshold;
            }
            let _observer = new IntersectionObserver(cb, params);
            this.observer_map[observer_id] = _observer;
        }
        return this.observer_map[observer_id];
    }

    async observe(options) {
        debugger;
        return Promise.resolve();
        // let {
        //     parent,
        //     target,
        //     timeout,
        //     cb
        // } = options;
        // let observer_id = this.getObserverId(parent, target);
        // if (!this.observer_map[observer_id]) {
        //     this.initObserver(options);
        // }
        // await new Promise((resolve, reject)=>{
        //     setTimeout(() => {
        //         resolve();
        //     }, 500);
        // })
        // // 同一id监控只能调用一次
        // if (this.observe_status_map[observer_id]) {
        //     console.warn("⚠️ 同一id监控只能调用一次")
        //     return Promise.reject()
        // }

        // this.observer_map[observer_id]
        //     .observe(target, res=>{
        //         cb && cb(res);
        //     })
        // this.observe_status_map[observer_id] = true;
        // return Promise.resolve({
        //     "observer": this.observer_map[observer_id],
        //     "observer_id": observer_id
        // })
    }

    async observeShow (options) {
        let _this = this,
            {
                parent,
                target
            } = options;
        let observer_id = this.getObserverId(parent, target);
        if (!this.observer_map[observer_id]) {
            // 等待0.5s才能生效
            await new Promise(resolve=>{
                setTimeout(() => {
                    resolve();
                }, 500);
            })
            return new Promise((resolve, reject)=>{
                options["cb"] = res=>{
                    res = res && res.length > 0 ? res : [];
                    res = res.pop();
                    if (res && res.intersectionRatio) {
                        console.log("show ==>")
                        _this.observer_map[observer_id].unobserve(target_elem);
                        _this.destroy(observer_id);
                        resolve(res);
                    } else {
                        // 元素不在viewport内
                    }
                }
                let target_elem = _this.getObserveElement(target);
                if (target_elem) {
                    _this.initObserver(options);
                    _this.observer_map[observer_id].observe(target_elem);
                }
            })
        } else {
            return Promise.resolve();
        }
    }

}

export default {
    stObserver
}