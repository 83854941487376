<template> 
    <!-- 遮罩层 -->
    <div class="elem-mask po" :class="maskClass" :style="maskStyle" v-if="canIShow">
        <!-- 显示层 -->
        <div class="poc" :class="{
            'none': isMaskIn === -1,
            'elem-mask_in': isMaskIn === 1,
            'elem-mask_out': isMaskIn === 0
        }">
            <!-- 动画层 -->
            <div class="poc" :style="animationStyle">
                <!-- 照片层 -->
                <div class="elem elem-image_div poc" 
                    :style="imStyle"
                    :class="imClass">
                    <!-- elem-mask_im poc bg bg_photo -->
                    <!-- 处理子elems嵌套 -->
                    <elem v-for="(elem, index) in elems" 
                        :key="'elem-mask_sub_elems_' + index"
                        :mode="elem"
                        :currentPhoto="currentPhoto"
                        :currentTime="currentTime"
                        :canIShow="canIShow"
                        :currentImage="currentImage"
                        :scene="scene"
                        :line="line"></elem>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ElemIm from "./ElemIm"
    export default {
        extends: ElemIm,
        data () {
            return {
                isMaskIn: -1,
                maskClass: ""
            }
        },
        watch: {
			canIShow: {
				handler () {
                    if (this.needFade) {
                        if (this.canIShow === false) {
                            this.isMaskIn = 1;
                        } else if (this.canIShow === true) {
                            this.isMaskIn = 1;
                            if (this.scene) {
                                let out_time = Math.abs(this.scene['timing'][0][1] - this.scene['timing'][0][0] - .5);
                                setTimeout(() => {
                                    this.isMaskIn = 0;
                                }, out_time * 1000)
                            }
                        }
                    } else {
                        this.isMaskIn = 99;
                    }
				},
				immediate: true
			}
		},
        computed: {
            mask () {
                return this.mode['mask'] || {};
            },
            needFade () {
                return this.mask['fade'] === undefined ? true : this.mask['fade'];
            },
            needMove () {
                return this.mask['move'] === undefined ? true : this.mask['move'];
            },
            otherStyle () {
                let s = this.$util.copy( this.getElemStyles() );
                delete s['backgroundImage'];
                delete s['animation'];
                return s;
            },
            imStyle () {
                let s = {};
                s["backgroundImage"] = this.$util.copy( this.elemStyles )['backgroundImage'];

                if (this.elemStyles['animation'] && this.elemStyles['animation'].indexOf('fullscreen_bg') != -1) {
                    s['animation'] = this.elemStyles['animation'];
                } else {
                    if (this.needMove) {
                        s["animation"] = "5s ease-in-out 0s 1 reverse both running fullscreen_bg_20_80";
                    }
                }
                return s;
            },
            imClass () {
                let c = this.myElemClass;
                return c;
            },
            animationStyle () {
                let s = {};
                if (this.elemStyles['animation']) {
                    if (this.elemStyles['animation'].indexOf('fullscreen_bg') == -1) {
                        s['animation'] = this.elemStyles['animation'];
                    }
                } else {
                    if (this.needMove) {
                        s['animation'] = "scale_4 8s ease-in-out 0s 1 "+ (Math.random() > .5 ? 'reverse' : 'normal') +" both running"
                    }
                }
                return s;
            },
            rowMaskStyle () {
                let im = this.mask['im_row'],
                    sprite_steps = parseInt(this.mask['sprite_steps_row'] || 0),
                    sprite_duration = this.mask['sprite_duration_row'],
                    sprite_width = parseInt(this.mask['sprite_width_row'] || 0),
                    sprite_height = parseInt(this.mask['sprite_height_row'] || 0);

                let {
                    mask_style,
                    mask_class
                } = this.$util.getElemMaskStyle(im, sprite_steps, sprite_duration, sprite_width, sprite_height);

                if (this.$util.isEnv("wxa")) this.maskClass = mask_class;

                return mask_style;
                // return this.getMaskStyle(im, sprite_steps, sprite_duration, sprite_width, sprite_height);
            },
            colMaskStyle () {
                let im = this.mask['im_col'] || this.mask['im'],
                    sprite_steps = parseInt(this.mask['sprite_steps_col'] || this.mask['sprite_steps'] || 0),
                    sprite_duration = this.mask['sprite_duration_col'] || this.mask['sprite_duration'],
                    sprite_width = parseInt(this.mask['sprite_width_col'] || this.mask['sprite_width'] || 0),
                    sprite_height = parseInt(this.mask['sprite_height_col'] || this.mask['sprite_height'] || 0);

                let {
                    mask_style,
                    mask_class
                } = this.$util.getElemMaskStyle(im, sprite_steps, sprite_duration, sprite_width, sprite_height);

                if (this.$util.isEnv("wxa")) this.maskClass = mask_class;

                return mask_style;
                // return this.getMaskStyle(im, sprite_steps, sprite_duration, sprite_width, sprite_height);
            },
            maskStyle () {
                
                let s = this.colMaskStyle;

                // 根绝用户图片的长宽，判断使用哪种遮罩
                // 横图且存在横遮罩时，改为横遮罩
                if (this.containIm.width > this.containIm.height && this.hasRow) {
                    s = this.rowMaskStyle;
                }

                Object.assign(s, this.otherStyle);

                s = this.$util.pxTransform(s, 'style');
                
                return s;
            },
            hasRow () {
                return Boolean(this.mask['im_row']);
            }
        },
        methods: {
            // getMaskStyle (im, sprite_steps, sprite_duration, sprite_width, sprite_height) {
            //     let s = {
            //         webkitMaskImage: `url(${im})`
            //     };
            //     if (sprite_steps) {
            //         s['animation'] = `dynamic_mask ${sprite_duration}s steps(${sprite_steps - 1}) forwards`
            //     }
            //     let width = sprite_width ? parseInt(this.containIm['width']) + "px" : "101%",
            //         height = sprite_height ? ( parseInt(this.containIm['height']) * sprite_steps ) + "px" : "101%";
            //     s['webkitMaskSize'] = `${width} ${height}`;

            //     return s;
            // }
        }
    }
</script>

<style>
    .elem-mask {
        transform-origin: 50% 50%;
        -webkit-mask-repeat: no-repeat;
        overflow: hidden;
        /* overflow: "hidden"; */
    }
    .elem-mask_im {
        /* width: 110%;
        height: 110%;
        top: -5%;
        left: -5%; */
    }
    .elem-mask_in {
        animation: fadeIn .5s ease-out both;
    }
    .elem-mask_out {
        animation: fadeOut .5s ease-out both;
    }
</style>