<template>
	<div class="anitoptobot pointer poc" :style="getStyles">
        <div class="anitoptobot1_box1">
			<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
				<div v-for="idx in 13"
					:class="'anitoptobot1_ani'+idx"
					:key="'anitoptobot1_ani'+idx">
					<elem 
						:mode="getLuoItem(idx)"
						:currentPhoto="currentPhoto"
						:canIShow="canIShow"></elem>
				</div>
			</template>
			<template v-else>
				<img v-for="idx in 13"
					mode="widthFix"
					:class="'anitoptobot1_ani'+idx"
					:key="'anitoptobot1_ani'+idx"
					:src="getLuoItem(idx)" />
			</template>
        </div> 
    </div>
</template>

<script>
import BaseLuo from './BaseLuo'
	export default {
		name: 'luo2',
        extends: BaseLuo,
	}
</script>

<style>
	.anitoptobot_luo {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    right: 0;
		left: 0;
	}
	.anitoptobot_ani1 {
	    width:37.50px;
	    left:209.00px;
	    animation: anitoptobot_luo4 13.2s linear -9.2s infinite both;
	}
	.anitoptobot_ani2 {
	    width:34.50px;
	    left:228.00px;
	    animation: anitoptobot_luo4 13.6s linear -7.7s infinite both;
	}
	.anitoptobot_ani3 {
	    width:57.50px;
	    left:194.00px;
	    animation: anitoptobot_luo4 13.5s linear -5.7s infinite both;
	}
	.anitoptobot_ani4 {
	    width:34.00px;
	    left:194.00px;
	    animation: anitoptobot_luo3 12.8s linear -8.8s infinite both;
	}
	.anitoptobot_ani5 {
	    width:43.00px;
	    left:28.50px;
	    animation: anitoptobot_luo3 12.9s linear -4.5s infinite both;
	}
	.anitoptobot_ani6 {
	    width:32.50px;
	    left:44.50px;
	    animation: anitoptobot_luo4 13.3s linear -11.5s infinite both;
	}
	.anitoptobot_ani7 {
	    width:35.50px;
	    left:267.50px;
	    animation: anitoptobot_luo3 12.4s linear -9.3s infinite both;
	}
	.anitoptobot_ani8 {
	    width:41.50px;
	    left:89.50px;
	    animation: anitoptobot_luo4 13.3s linear -8.9s infinite both;
	}
	.anitoptobot_ani9 {
	    width:39.00px;
	    left:104.00px;
	    animation: anitoptobot_luo2 10.3s linear -8.9s infinite both;
	}
	.anitoptobot_ani10 {
	    width:37.50px;
	    left:116.00px;
	    animation: anitoptobot_luo1 14.3s linear -8.9s infinite both;
	}
	.anitoptobot1_ani11 {
	    width:35.50px;
	    left:130.00px;
	    animation: anitoptobot_luo1 11.3s linear -11.9s infinite both;
	}
	.anitoptobot_ani12 {
	    width:41.50px;
	    left:139.00px;
	    animation: anitoptobot_luo5 8.3s linear -11.9s infinite both;
	}
	.anitoptobot1_ani12 {
	    width:37.00px;
	    left:104.00px;
	    animation: anitoptobot_luo5 10.3s linear -11.9s infinite both;
	}
	.anitoptobot_ani14 {
	    width:39.00px;
	    left:90.50px;
	    animation: anitoptobot_luo5 8.3s linear -10.9s infinite both;
	}
	.anitoptobot1_ani13 {
	    width:44.00px;
	    left:106.00px;
	    animation: anitoptobot_luo5 12.3s linear -8.9s infinite both;
	}
	.anitoptobot_ani16 {
	    width:33.50px;
	    left:118.00px;
	    animation: anitoptobot_luo5 9.3s linear -13.9s infinite both;
	}
	.anitoptobot_luo {
	    width: 100%;
	    height: 100%;
	}
	@keyframes anitoptobot_luo1 {
	    0% {
	        transform:translate(0, -100%) rotateZ(0);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(173.50px,701.50px) rotateZ(360deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot_luo5 {
	    0% {
	        transform:translate(0, -100%) rotateZ(0);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(173.50px,625.00px) rotateZ(360deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot_luo2 {
	    0% {
	        transform:translate(0, -100%) rotateZ(60deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(264.00px,687.50px) rotateZ(420deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot_luo3 {
	    0% {
	        transform:translate(0, -100%) rotateZ(-60deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(132.00px,701.50px) rotateZ(300deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot_luo4 {
	    0% {
	        transform:translate(0, -100%) rotateZ(20deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(-173.50px,701.50px) rotateZ(-340deg);
	        opacity:0;
	    }
	}
	.anitoptobot1_box1 {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    left: 0;
		pointer-events: none;
		transform: rotateY(180deg);
	}
	.anitoptobot1_box1 img {
	    position: absolute;
	}
	.anitoptobot1_ani1 {
	    width:45.00px;
	    left:70.00px;
	    -webkit-animation: anitoptobot1_luo3 10143ms -11446ms linear infinite both;
	}
	.anitoptobot1_ani2 {
	    width:41.50px;
	    left:100.50px;
	    -webkit-animation: anitoptobot1_luo4 8236ms -11554ms linear infinite both;
	}
	.anitoptobot1_ani3 {
	    width:48.50px;
	    left:-82.00px;
	    -webkit-animation: anitoptobot1_luo2 8569ms -4069ms linear infinite both;
	}
	.anitoptobot1_ani4 {
	    width:39.00px;
	    left:60.50px;
	    -webkit-animation: anitoptobot1_luo5 8664ms -2506ms linear infinite both;
	}
	.anitoptobot1_ani5 {
	    width:52.00px;
	    left:54.00px;
	    -webkit-animation: anitoptobot1_luo2 8636ms -857ms linear infinite both;
	}
	.anitoptobot1_ani6 {
	    width:21.00px;
	    left:97.00px;
	    -webkit-animation: anitoptobot1_luo2 8196ms -2023ms linear infinite both;
	}
	.anitoptobot1_ani7 {
	    width:34.50px;
	    left:93.00px;
	    -webkit-animation: anitoptobot1_luo1 8924ms -5685ms linear infinite both;
	}
	.anitoptobot1_ani8 {
	    width:47.00px;
	    left:315.50px;
	    -webkit-animation: anitoptobot1_luo3 8724ms -6645ms linear infinite both;
	}
	.anitoptobot1_ani9 {
	    width:51.50px;
	    left:239.00px;
	    -webkit-animation: anitoptobot1_luo4 9124ms -7695ms linear infinite both;
	}
	.anitoptobot1_ani10 {
	    width:39.00px;
	    left:134.50px;
	    -webkit-animation: anitoptobot1_luo5 9254ms -1346ms linear infinite both;
	}
	@keyframes anitoptobot1_luo1 {
	    0% {
	        transform:translate(0, -100%) rotateZ(0);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(173.50px,701.50px) rotateZ(360deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot1_luo5 {
	    0% {
	        transform:translate(0, -100%) rotateZ(0);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(173.50px,625.00px) rotateZ(360deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot1_luo2 {
	    0% {
	        transform:translate(0, -100%) rotateZ(60deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(264.00px,687.50px) rotateZ(420deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot1_luo3 {
	    0% {
	        transform:translate(0, -100%) rotateZ(-60deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(132.00px,701.50px) rotateZ(300deg);
	        opacity:0;
	    }
	}
	@keyframes anitoptobot1_luo4 {
	    0% {
	        transform:translate(0, -100%) rotateZ(20deg);
	        opacity:0;
	    }
	    2% {
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        transform:translate(-173.50px,701.50px) rotateZ(-340deg);
	        opacity:0;
	    }
	}
</style>