<template>
	<div class="tab-btns tab-btns-1 flex_r">
		<img v-show="secondAbleTheme && !is_make_detail" class="tab-uparrow" src="http://resali.lititutu.cn/icons/app/uparrow.png">
		<template v-if="is_author">
			<!-- 小程序作者页 -->
			<template v-if="$env.web == 'wxa'">
				<div class="tab-item tab-edit_wxa" @click="onBtnClick('edit')">
					<img class="poc" src="http://resali.lititutu.cn/icons/app/edit_3.png">
				</div>
				<div class="tab-item tab-makemore_wxa" @click="onBtnClick('makemore')">
					<img class="poc" src="http://resali.lititutu.cn/icons/app/makemore_1.png">
				</div>
				<div class="tab-item tab-share_wxa" @click="onBtnClick('share')">
					<img class="poc" src="http://resali.lititutu.cn/icons/app/share_2.png">
				</div>
			</template>
			<!-- H5作者页 -->
			<template v-else>
				<!-- share_mode -->
				<template v-if="showShareBtn">
					<div class="tab-item tab-edit_wxa" @click="onBtnClick('edit')">
						<img class="poc" src="http://resali.lititutu.cn/icons/app/edit_3.png">
					</div>
					<div class="tab-item tab-makemore_wxa" @click="onBtnClick('makemore')">
						<st-open-tag
							:originid="getWxaOriginId"
							:path="getWxaPath"
							html='<img class="poc" src="http://resali.lititutu.cn/icons/app/makemore_1.png">'>
						</st-open-tag>
					</div>
					<div class="tab-item tab-share_wxa" @click="onBtnClick('share')">
						<img class="poc" src="http://resali.lititutu.cn/icons/app/share_2.png">
					</div>
				</template>
				<template v-else>
					<div class="tab-item tab-edit" @click="onBtnClick('edit')">
						<img class="poc" src="http://resali.lititutu.cn/icons/app/edit_2.png">
					</div>
					<div class="tab-item tab-makemore" @click="onBtnClick('makemore')">
						<st-open-tag
							:originid="getWxaOriginId"
							:path="getWxaPath"
							html='<img style="width: 200px; height: 63px;" class="img-btn" src="http://resali.lititutu.cn/icons/app/makemore.png">'>
						</st-open-tag>
					</div>
				</template>
			</template>
		</template>
		<template v-else>
			<!-- 分享 -->
			<div class="tab-item tab-share" v-if="showShareBtn" @click="onBtnClick('share')">
				<img class="poc" src="http://resali.lititutu.cn/icons/app/share.png">
			</div>
			<!-- 我要制作 -->
			<div class="tab-item tab-make" @click="onBtnClick('make')">
				<!-- <div v-if="isFresh.show" class="fresh-guide fadeInUp animated">
					<div class="fresh-guide-tx" v-html="getGuideText"></div>
					<div class="triangle"></div>
				</div> -->
				<st-open-tag
					:originid="getWxaOriginId"
					:path="getWxaPath"
					tagstyle="@keyframes btns-bounceup {from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);}from {transform: translate3d(0, 0, 0);}58% {transform: translate3d(0, 0, 0);}60% {transform: translate3d(0, -5px, 0);}62% {transform: translate3d(0, 5px, 0);}64% {transform: translate3d(0, -5px, 0);}66% {transform: translate3d(0, 0, 0);} to {transform: translate3d(0, 0, 0);}}"
					html='<img style="width: 286px;height: 66px; margin: auto; animation: btns-bounceup 6s 1s both infinite;" src="http://resali.lititutu.cn/wxa_icon/btn/makebtn.png">'
					@error="onBtnClick('make')">
				</st-open-tag>
			</div>
		</template>
	</div>
</template>

<script>
	import StOpenTag from '@/components/st_comp/StOpenTag'
	export default {
		name: 'ShareBtns',
		props:["is_author","is_make_detail"],
		components: { StOpenTag },
		data () {
			return {
				btnTada: false,
				isFresh: {
					show: false,
					tx: "这样的相册\n我也能做"
				}
			}
		},
		computed: {
			// isAuthor () {

			// 	let appid = this.$store.state.config.data['appid'],
			// 		openid = this.$store.state.config.data['openid'];
				
			// 	// 兼容2020.06.19发文事故处理
			// 	if (appid == 'wxa14f0346ddc3bd37' && openid == 'oUvY81vTLkVYouuIjyUf8vzzJtBc') {
			// 		return false;
			// 	}

			// 	// 在这里判断is_timeline是为了适配因朋友圈下发链接误带is_author导致编辑相册入口流出的bug  && !this.$util.isWxapp(this.$route.query['_source'])
			// 	return (this.$util.inSlog('riti_author3', appid+','+openid)) || loginManager._isStorageAuthor(appid, openid) || (this.$route.query['is_author'] == 1 && this.$route.query['is_timeline'] != 1);
			// },
			secondAbleTheme () {
				// return ['full', 'normal', 'simple'].indexOf( this.$store.state.themes[ this.$store.state.config['data']['ttid'] ]['type'] ) != -1;
				return ['xc', 'exhi', 'wscene'].indexOf( this.$store.state.themes[ this.$store.state.config['data']['ttid'] ]['type'] ) == -1;
			},
			getGuideText() {
				return this.isFresh.tx.replace("\n", "<br \>")
			},
			getWxaPath () {
				return '/pages/make/make.html?tab=make&detail_tid=' + this.$store.state.config.data['ttid']
			},
			getWxaOriginId () {
				// return "gh_37a75119d615"
				return this.$o_util.getWxaOriginId();
			},
			showShareBtn () {
				// make_detail页面不出
				if (this.is_make_detail) {
					return false;
				}
				// share_mode出
				if (this.$env.web != 'wxa' && this.$store.state.appConfig.share_mode == 2) {
					return true;
				}
				// 小程序出
				if (this.$env.web == 'wxa') {
					return true;
				}
				// 剩余情况不出
				return false;
			}
		},
		methods: {
			onBtnClick (t) {
				// 立即制作
				if ('make' == t) {
					this.$o_util.log('theme_share_make_'+this.$store.state.config.data['ttid']);
					// 改为直接制作
					if (this.$env.web == 'wxa') { //小程序直接制作
						this.share_make_log('wxa_make');
						this.$emit('onMakeTap', this.$store.state.config.data.ttid, '5001');
						// if(this.$route.query && this.$route.query["wxa_appid"] && this.$route.query['visitor']){
							
						// } else if (this.is_make_detail) {
						// 	wx.miniProgram.postMessage({ data: {tid: this.$store.state.config.data['ttid']} });
						// 	wx.miniProgram.navigateBack()
						// } else {
						// 	wx.miniProgram.reLaunch({
						// 		url: '/pages/make/make?tab=make&detail_tid=' + this.$store.state.config.data['ttid']
						// 	})
						// }
						
					} else {
						// if (this.$store.state.config['follow_url'] == 'make') {
						if (!this.$o_util.goFollow()) {

							this.share_make_log('make');

							// 制作当前模板
							this.$emit('onMakeTap', this.$store.state.config.data.ttid, '2008');
						} else {
							this.share_make_log('follow');

							window.location.href = this.$o_util.getFollowUrl(this.$store.state.config['follow_url'], 'make');
						}
					}
				}
				// 作者编辑
				else if ('edit' == t) {
					if(this.$env.web == 'wxa'){
						wx.miniProgram.postMessage({ data: {refresh_list: 1} });
					}
					this.$mta.click('share_btn_edit');
					window.location.href = window.location.protocol + '//' + window.location.host + '/vliti/main/'+(this.$store.state.appConfig.editor_path)+'?key=' + this.$route.query['key'] + '&_source=' + this.$route.query['_source'] + '&pfrom=share&vscene=2010';
				}
				// 作者再做一个
				else if ('makemore' == t) {
					this.$o_util.log('theme_share_make_'+this.$store.state.config.data['ttid']);
					// if (this.$env.web == 'wxa') {
					// 	this.share_make_log('wxa_makemore');
					// 	// wx.miniProgram.postMessage({ data: {tid: this.$store.state.config.data['ttid']} });
					// 	wx.miniProgram.reLaunch({
					// 		// url: '/pages/make/make?tab=make&from_detail=1'
					// 		url: '/pages/make/make?tab=make&detail_tid=' + this.$store.state.config.data['ttid']
					//     })
					// } else {
					// 	this.share_make_log('makemore');
					// 	this.$emit('onMakeTap', false, '2011');
					// }
					this.$emit('onMakeTap', false, '2011');
				}
				// 分享
				else if ('share' == t) {
					// this.$mta.click('wxa_share_btn_share');
					// this.$mta.click('wxa_share_btn_share_'+this.$route.query._source);
					if (this.$env.web == 'wxa') {
						wx.miniProgram.navigateTo({
							  url: '/pages/savePage/savePage?key='+this.$route.query.key+'&share=1'
						})
					} else {
						this.$emit('showPreShare');
					}
				}
			},
			share_make_log (mode) {
				let mta_params = {};
				mta_params[mode] = 'true';
				this.$mta.click('share_btn_make', mta_params);
			},
			tryShowFlowGuide(){
				let guideLog = localStorage.getItem("guide_make_xc");
				if(!guideLog){
					this.isFresh.show = true;
					localStorage.setItem("guide_make_xc", true)
				}
			}
		},
		created () {
			let _this = this,
				s = 0
			// 控制按钮动画
			// setInterval(() => {
			// 	_this.btnTada = true;
			// 	setTimeout(() => {
			// 		_this.btnTada = false;
			// 	}, 1000);
			// 	if (s > 18 && _this.isFresh.show){
			// 		_this.isFresh.show = false;
			// 	} else if (s > 9 && s < 18 && !_this.isFresh.show) {
			// 		_this.tryShowFlowGuide()
			// 	}
			// 	s = s + 1.5;
			// }, 1500);
		}
	}
</script>

<style scoped lang="less">
	// 底部按钮
	.tab-btns {
		position: fixed;
		left: 0;
		bottom: 0;
		width: @bw;
		z-index: 10;
		animation: btns-in .6s ease-in-out both;
		z-index: 9;
	}
	// .tab-btns::before {
		
	// }
	// .tab-btns::after {
	// 	content: '';
	// 	display: block;
 //    }
	.tab-btns-1 {
		position: absolute;
		background: linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
		height: 100px;
	}
	// .tab-btns-2 {
	// 	position: fixed;
	// 	box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
	// 	height: 82px;
	// }
	.tab-share-l1 {
		left: 8.5px;
	}
	.tab-share-l2 {
		right: 128.5px;
	}
	.tab-share-l3 {
		right: 8.5px;
	}
	// .tab-btns-2 .tab-share-btn {
	// 	position: absolute;
	// 	top: 50%;
	// 	margin-top: -30px;
	// }
	.tab-flex-end {
		justify-content: flex-end;
	}
	.tab-flex-center {
		justify-content: center;
	}
	.tab-item {
		// position: relative;
	}
	.tab-make {
		position: relative;
		width: 286px;
		height: 68px;
		overflow: visible;
		// top: 50%;
		// left: 50%;
		// margin-top: -33px;
		// margin-left: -126px;
	}
	.tab-share {
		position: relative;
		width: 153px;
		height: 66px;
	}
	.tab-edit, .tab-makemore {
		position: relative;
		width: 200px;
		height: 63px;
		// top: 50%;
		// margin-top: -36px;
	}
	.tab-edit_wxa, .tab-share_wxa, .tab-makemore_wxa {
		position: relative;
		width: 147px;
		height: 63px;
	}
	// .tab-edit {
	// 	left: 30px;
	// }
	// .tab-makemore {
	// 	right: 30px;
	// }
	.tab-make_img {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0; top: 0;
	}
	.tab-share-btn {
		margin-right: @bw * 0.017;
		width: 115px;
		height: 60px;
	}
	.tab-item-image {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	// 按钮css样式
	.make-btn{
		background: linear-gradient(180deg, #FC6326 0%, #FF3672 100%);
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 34px;
		padding: 10px 4px;
	}
	.make-btn-img{
		width: 42px;
		height: 42px;
		border-radius: 50%;
		margin-left: auto;
		margin-right: 8px;
	}
	.btn-text{
		font-size: 34px;
		font-weight: bold;
		line-height: 34px;
		color: #fff;
		text-align: center;
		letter-spacing: 4px;
		margin-right: auto;
	}


	@keyframes btns-in {
		from {
			transform: translateY(100%);
		}
		to {
			transform: none;
		}
	}

	.h5-share-for-editor {
		position: absolute;
		left:@bw * 0.017;
	}

	.btns-bounceup {
		animation: btns-bounceup 6s 1s both infinite;
	}
	@keyframes btns-bounceup {
		from, 60%, 75%, 90%, to {
			-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}
		from {
			transform: translate3d(0, 0, 0);
		}
		58% {
			transform: translate3d(0, 0, 0);
		}
		60% {
			transform: translate3d(0, -5px, 0);
		}
		62% {
			transform: translate3d(0, 5px, 0);
		}
		64% {
			transform: translate3d(0, -5px, 0);
		}
		66% {
			transform: translate3d(0, 0, 0);
		}
		to {
			transform: translate3d(0, 0, 0);
		}
	}

	.tab-uparrow {
		position: absolute;
		width: 43px;
		height: 33px;
		left: 50%;
		margin-left: -21px;
		top: 0px;
		animation: arrow-up 4s 1s both infinite;
	}
	@keyframes arrow-up {
		from {
			opacity: 0;
		}
		30% {
			transform: translateY(10px);
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		70% {
			transform: translateY(-10px);
			opacity: 0;
		}
		to {
			opacity: 0;
		}
	}

	// @keyframes fadeinout {
	// 	from{
	// 		opacity: 0.1;
	// 	}
	// 	50% {
	// 		opacity: 0.9;
	// 	}
	// 	to {
	// 		opacity: 0.1;
	// 	}
	// }

	.fresh-guide{
		// animation: fadeinout 3.4s ease-in both infinite;
		animation: btns-in .6s ease-in-out both;
		position: absolute;
		right: 50px;
		top: calc(-100% - 36px);
		border-radius: 28px;
		background-color: #FFF566;
		// border: #484848 2rpx solid;
		padding: 8px 28px;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.75);
		.triangle {
			position: absolute;
			right: 60px;
			bottom: 1px;
		}

		.triangle:before,
		.triangle:after {
			position: absolute;
			content: '';
			border-bottom: 9px transparent dashed;
			border-left: 9px transparent dashed;
			border-right: 9px transparent dashed;
			border-top: 9px #FFF566 solid;
		}

		.triangle:before {
			border-top: 9px #FFF566 solid;
		}

		.triangle:after {
			top: 1px;
			border-top: 9px #FFF566 solid;
		}
	}
	.fresh-guide-tx{
		color: #333;
		line-height: 28px;
		font-size: 24px;
		text-align: justify;
		text-align-last: justify;
		width: 130px;
	}
</style>





