<template>
    <div class="zuoyidong_box">
        <div class="img_box">
            <div class="photos_box1">
                <div class="userImg userImg1" @click="wxPreview">
                    <div :style="getStyle" class="bg">
	                    <div class="userborder" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    </div>
                </div>
                <div class="userImg userImg2" @click="wxPreview">
                    <div :style="getStyle" class="bg">
	                    <div class="userborder" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    </div>
                </div>
                <div class="userImg userImg3" @click="wxPreview">
                    <div :style="getStyle" class="bg">
	                    <div class="userborder" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    </div>
                </div>
                <div class="userImg userImg4" @click="wxPreview">
                    <div :style="getStyle" class="bg">
	                    <div class="userborder" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
                    </div>
                </div>
            </div>
        </div>
    	<!-- <img class="bottom_flower" :src="mode[2]"> -->
        <!-- <canvas height="340" width="720" class="bottom_flower"></canvas> -->
    </div>
</template>

<script>
	export default {
		name: 'zuoyidong',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		computed: {
			getStyle () {
				let twidth, theight;
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [389, 521], 'both');
					twidth = tsize[0];
					theight = tsize[1];
				} else {
					twidth = 389;
					theight = 521;
				}
				return {
					width: twidth + 'px',
					height: theight + 'px',
					backgroundImage: 'url('+ this.currentImage['im'] +')'
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.zuoyidong_box {
		position: relative;
	    height: 100%;
	    width: 100%;
	    background: no-repeat center;
	    background-size: cover;
	}
	.zuoyuyi_canvasbg {
	    position: absolute;
	    top: 0;
	    height: 100%;
	    width: 100%
	}
	.bottom_flower {
	    position: absolute;
	    left: 0;
	    right: 0;
	    width: 100%;
	    /* height:@bw * 0.556; */
	    bottom: 0;
	    background: no-repeat center;
	    background-position: bottom;
	    background-size: contain;
	    z-index: 2;
	}
	.photos_box1 {
	    height: 100%;
	    left: 0;
	    /* animation: tran1 25s linear 1 both; */
	    width:@bw * 3.611;
	}
	.userImg {
	    // width:@bw * 0.778;
	    // height:@bw * 1.042;
	    background: no-repeat center;
	    background-size: cover;
	    position: absolute;
	    bottom: @bw * 0.417;
	    width: 521px;
	    height: 521px;
	    display: flex;
	    align-items: center;
        justify-content: center;
	}
	.userImg1 {
	    animation: tran2 5s linear 1 both;
	    transform: translateX(@bw * 0.944)
	}
	@keyframes tran2 {
	    // 0% {
	    //     transform: translateX(@bw * 1.361);
	    // }
	    // 30% {
	    //     transform: translateX(@bw * 0.083) rotate(-8deg)
	    // }
	    // 50% {
	    //     transform: translateX(@bw * 0.165) rotate(1deg)
	    // }
	    // 70% {
	    //     transform: translateX(@bw * 0.165) rotate(0deg)
	    // }
	    // 80% {
	    //     transform: translateX(-100%) rotate(1deg)
	    // }
	    // 100% {
	    //     transform: translateX(-124%);
	    // }
	 //    0% {
		//     transform: translateX(124%);
		// }
		// 30% {
		//     transform: translateX(-20px) rotate(-8deg);
		// }
		// 50% {
		//     transform: translateX(-10px) rotate(1deg);
		// }
		// 70% {
		//     transform: translateX(-10px) rotate(0deg);
		// }
		// 80% {
		//     transform: translateX(-70%) rotate(1deg);
		// }
		// 100% {
		//     transform: translateX(-124%);
		// }
		0% {
		    transform: translateX(124%);
		}
		30% {
		    transform: translateX(@bw * -0.04) rotate(-8deg);
		}
		50% {
		    transform: translateX(@bw * -0.02) rotate(1deg);
		}
		70% {
		    transform: translateX(@bw * -0.02) rotate(0deg);
		}
		80% {
		    transform: translateX(-70%) rotate(1deg);
		}
		100% {
		    transform: translateX(-124%);
		}
	}
	.userImg2 {
	    transform: translateX(@bw * 1.25);
	    animation: tran2 5s 3s linear 1 both;
	}
	.userImg3 {
	    transform: translateX(@bw * 2.361);
	    animation: tran2 5s 6.7s linear 1 both;
	}
	.userImg4 {
	    transform: translateX(@bw * 3.472);
	    animation: tran2 5s 10s linear 1 both;
	}
	.userImg5 {
	    transform: translateX(@bw * 4.583);
	    animation: tran2 5s 12s linear 1 both;
	}
	.img_box {
	    height: 100%;
	    width: 100%;
	}
	.userborder {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-repeat: no-repeat;
	    background-size: 100% 100%;
	    background-position: 0 0;
	}
	.zuoyidong_box .luo {
	    height: 0;
	}
	@keyframes tran1 {
	    100% {
	        transform: translateX(-124%);
	    }
	}
</style>