<template>
	<div class="litifei poc">
		<div class="bgCover BX_Class19 BX_BG19 BX_Ani19" :style="{backgroundImage: 'url('+mode[8]+')'}"></div>
		<div class="bgCover BX_Class20 BX_BG20 BX_Ani20" :style="{backgroundImage: 'url('+mode[7]+')'}"></div>
		<div class="bgCover BX_Class3 BX_BG3 BX_Ani3" :style="{backgroundImage: 'url('+mode[5]+')'}"></div>
		<div class="bgCover BX_Class4 BX_BG4 BX_Ani4" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
		
		<div class="bgCover BX_Class16 BX_BG16 BX_Ani16" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
		<div class="bgCover BX_Class10 BX_BG10 BX_Ani10" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
		<div class="BX_AEComposition">
			<div class="bgCover BX_Class18 BX_BG18 BX_Ani18" :style="{backgroundImage: 'url('+mode[6]+')'}"></div>
			<div class="bgCover BX_Class17 BX_BG17 BX_Ani17" :style="{backgroundImage: 'url('+mode[6]+')'}"></div>

			<div class="bgCover BX_Class11 BX_Ani11">
				<div class="bgCover BX_Class15 BX_BG15 BX_Ani15" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="bgCover BX_Class14 BX_BG14 BX_Ani14" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="bgCover BX_Class13 BX_BG13 BX_Ani13 BG_CENTER" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="bgCover BX_Class12 BX_BG12 BX_Ani12" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
			</div>

			<div class="bgCover BX_Class5 BX_Ani5">
			</div>
			<div class="bgCover BX_Class5 BX_Ani5">
			</div>
			<div class="bgCover BX_Class5 BX_Ani5">
			</div>
			<div class="bgCover BX_Class5 BX_Ani5">
			</div>
			<div class="bgCover BX_Class5 BX_Ani5">
				<div class="bgCover BX_Class9 BG_CENTER BX_BG9 BX_Ani9" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="bgCover BX_Class8 BG_CENTER BX_BG8 BX_Ani8" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="bgCover BX_Class7 BG_CENTER BX_BG7 BX_Ani7" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
				<div class="bgCover BX_Class6 BG_CENTER BX_BG6 BX_Ani6" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
			</div>

		</div>
		<div class="bgCover BX_Class2 BX_BG2 BX_Ani2" :style="{backgroundImage: 'url('+mode[4]+')'}"></div>
		<div class="bgCover BX_Class1 BX_BG1 BX_Ani1" :style="{backgroundImage: 'url('+mode[3]+')'}"></div>
	</div>
</template>

<script>
	export default {
		name: 'litifei',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.bgCover {
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
	.BG_CENTER {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
	.BX_AEComposition{
	    background: transparent;
	    width:@bw * 1.0;
	    height:@bw * 1.778;
	    display: block;
	    overflow: hidden;
	    position: relative;
	    transform: translate3d(0,0,0) scale(1,1);
	    transform-origin:50% 0%;
	    perspective:@bw * 0.833;
	    perspective-origin: 50% 50%;
	}
	.BX_Class21{
	    left:@bw * 0.278;
	    top:@bw * 0.539;
	    width:@bw * 0.444;
	    height:@bw * 0.7;
	    /*transform-style: preserve-3d;*/
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(2.25,2.55);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class20{
	    left:@bw * 0.062;
	    top:@bw * 0.169;
	    width:@bw * 0.867;
	    height:@bw * 0.85;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.47,1.46);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class19{
	    left:@bw * 0.167;
	    // top:@bw * 1.099;
	    bottom: 0;
	    width:@bw * 0.672;
	    height:@bw * 0.494;
	    /*transform-style: preserve-3d;*/
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(2.04,1.76);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani19{
	    animation: litifei_BX_AniKey19 13.00s linear 0.00s infinite normal none;
	}
	@keyframes  litifei_BX_AniKey19{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.04059917355372,1.76209315820013);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.08764512060813,1.80271815820013);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.13469106766254,1.84334315820013);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.13183979814409,1.84088103698801);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.0862194858489,1.80148709759407);}
		40.77% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.08271023105697,1.79845679456377);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.04059917355372,1.76209315820013);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.08764512060813,1.80271815820013);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.13469106766254,1.84334315820013);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.13183979814409,1.84088103698801);}
		80.77% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.12833054335215,1.83785073395771);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.08621948584891,1.80148709759407);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(2.04059917355372,1.76209315820013);}
		}
	.BX_Class18{
	    left:@bw * 0.088;
	    top:@bw * 0.886;
	    width:@bw * 0.397;
	    height:@bw * 0.283;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(0.00,0.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani18{
	    animation: litifei_BX_AniKey18 13.00s linear 0.00s infinite normal none;
	}
	@keyframes  litifei_BX_AniKey18{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(0,0);}
		10.00% {transform: translate3d(@bw * 0.006,-@bw * 0.229,@bw * 0.0) rotate(0.00deg) scale(0.18735294117647,0.18735294117647);}
		20.00% {transform: translate3d(@bw * 0.012,-@bw * 0.458,@bw * 0.0) rotate(0.00deg) scale(0.37470588235294,0.37470588235294);}
		30.00% {transform: translate3d(@bw * 0.018,-@bw * 0.688,@bw * 0.0) rotate(0.00deg) scale(0.56205882352941,0.56205882352941);}
		40.00% {transform: translate3d(@bw * 0.025,-@bw * 0.917,@bw * 0.0) rotate(0.00deg) scale(0.74941176470588,0.74941176470588);}
		50.00% {transform: translate3d(@bw * 0.031,-@bw * 1.146,@bw * 0.0) rotate(0.00deg) scale(0.93676470588235,0.93676470588235);}
		100.00% {transform: translate3d(@bw * 0.033,-@bw * 1.2,@bw * 0.0) rotate(0.00deg) scale(0.98,0.98);}
		}
	.BX_Class17{
	    left:@bw * 0.299;
	    top:@bw * 1.058;
	    width:@bw * 0.397;
	    height:@bw * 0.283;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(0.00,0.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani17{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation: litifei_BX_AniKey17 13.00s linear 0.00s infinite normal none;
	}
	@keyframes  litifei_BX_AniKey17{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(0,0);}
		80.00% {transform: translate3d(@bw * 0.025,-@bw * 0.336,@bw * 0.0) rotate(0.00deg) scale(0.24,0.24);}
		90.00% {transform: translate3d(@bw * 0.067,-@bw * 0.882,@bw * 0.0) rotate(0.00deg) scale(0.63,0.63);}
		100.00% {transform: translate3d(@bw * 0.1,-@bw * 1.303,@bw * 0.0) rotate(0.00deg) scale(0.93,0.93);}
		}
	.BX_Class16{
	    left:@bw * 0.751;
	    top:@bw * 0.697;
	    width:@bw * 1.174;
	    height:@bw * 0.96;
	    /*transform-style: preserve-3d;*/
	    transform: translate3d(0,0,@bw * 0.0) rotate(5.00deg) scale(0.38,0.42);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani16{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation: litifei_BX_AniKey16 13.00s linear 0.00s infinite normal none;
	}
	@keyframes  litifei_BX_AniKey16{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(5.00deg) scale(0.37687428291201,0.41539082711307);}
		10.00% {transform: translate3d(-@bw * 0.264,-@bw * 0.0,@bw * 0.0) rotate(-1.50deg) scale(0.37687428291201,0.41539082711307);}
		20.00% {transform: translate3d(-@bw * 0.529,-@bw * 0.001,@bw * 0.0) rotate(-2.00deg) scale(0.37687428291201,0.41539082711307);}
		30.00% {transform: translate3d(-@bw * 0.793,-@bw * 0.003,@bw * 0.0) rotate(4.50deg) scale(0.37687428291201,0.41539082711307);}
		40.00% {transform: translate3d(-@bw * 1.029,@bw * 0.0,@bw * 0.0) rotate(-1.00deg) scale(0.37687428291201,0.41539082711307);}
		50.00% {transform: translate3d(-@bw * 1.101,@bw * 0.0,@bw * 0.0) rotate(-2.50deg) scale(0.37687428291201,0.41539082711307);}
		60.00% {transform: translate3d(-@bw * 1.174,-@bw * 0.0,@bw * 0.0) rotate(4.00deg) scale(0.37687428291201,0.41539082711307);}
		70.00% {transform: translate3d(-@bw * 1.253,@bw * 0.0,@bw * 0.0) rotate(-0.50deg) scale(0.37687428291201,0.41539082711307);}
		80.00% {transform: translate3d(-@bw * 1.417,@bw * 0.006,@bw * 0.0) rotate(-3.00deg) scale(0.37687428291201,0.41539082711307);}
		90.00% {transform: translate3d(-@bw * 1.581,@bw * 0.012,@bw * 0.0) rotate(3.50deg) scale(0.37687428291201,0.41539082711307);}
		100.00% {transform: translate3d(-@bw * 1.746,@bw * 0.019,@bw * 0.0) rotate(5.00deg) scale(0.37687428291201,0.41539082711307);}
		}
	.BX_Class15{
	    left:@bw * 0.0;
	    top:@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.111) rotateX(-0.00deg) rotateY(180deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class14{
	    left:@bw * 0.111;
	    top: -@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(90.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class13{
	    left:@bw * 0.0;
	    top:@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.111) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class12{
	    left: -@bw * 0.111;
	    top:@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(-90.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class11{
	    left:@bw * 1.084;
	    top:@bw * 0.788;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG11{
	    background: rgb(16, 255, 0);
	}
	.BX_Ani11{
	    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
	    animation: litifei_BX_AniKey11 13.00s linear 0.00s infinite normal none;
	}
	@keyframes  litifei_BX_AniKey11{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		10.00% {transform: translate3d(-@bw * 0.275,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(72.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		20.00% {transform: translate3d(-@bw * 0.551,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(144.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		30.00% {transform: translate3d(-@bw * 0.828,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(216.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		40.00% {transform: translate3d(-@bw * 1.074,@bw * 0.001,@bw * 0.0) rotateX(-0.00deg) rotateY(288.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		50.00% {transform: translate3d(-@bw * 1.146,@bw * 0.001,@bw * 0.0) rotateX(-0.00deg) rotateY(360.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		60.00% {transform: translate3d(-@bw * 1.218,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(432.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		70.00% {transform: translate3d(-@bw * 1.299,@bw * 0.001,@bw * 0.0) rotateX(-0.00deg) rotateY(504.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		80.00% {transform: translate3d(-@bw * 1.462,@bw * 0.001,@bw * 0.0) rotateX(-0.00deg) rotateY(576.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		90.00% {transform: translate3d(-@bw * 1.626,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(648.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		
		100.00% {transform: translate3d(-@bw * 1.792,@bw * 0.001,@bw * 0.0) rotateX(-0.00deg) rotateY(720.00deg) rotateZ(0.00deg) scale3d(0.74,0.74,0.74);}
		}
	.BX_Class10{
	    left:@bw * 1.39;
	    top:@bw * 0.785;
	    width:@bw * 1.314;
	    height:@bw * 0.88;
	    /*transform-style: preserve-3d;*/
	    transform: translate3d(0,0,@bw * 0.0) rotate(5.00deg) scale(0.94,0.99);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani10{
	    animation: litifei_BX_AniKey10 13.00s linear 0.00s infinite normal none;
	}
	@keyframes  litifei_BX_AniKey10{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(5.00deg) scale(0.94027833534056,0.98897959183673);}
		10.00% {transform: translate3d(-@bw * 0.301,-@bw * 0.006,@bw * 0.0) rotate(-1.50deg) scale(0.94027833534056,0.98897959183673);}
		20.00% {transform: translate3d(-@bw * 0.604,-@bw * 0.014,@bw * 0.0) rotate(-2.00deg) scale(0.94027833534056,0.98897959183673);}
		30.00% {transform: translate3d(-@bw * 0.907,-@bw * 0.021,@bw * 0.0) rotate(4.50deg) scale(0.94027833534056,0.98897959183673);}
		40.00% {transform: translate3d(-@bw * 1.178,-@bw * 0.024,@bw * 0.0) rotate(-1.00deg) scale(0.94027833534056,0.98897959183673);}
		50.00% {transform: translate3d(-@bw * 1.272,-@bw * 0.022,@bw * 0.0) rotate(-2.50deg) scale(0.94027833534056,0.98897959183673);}
		60.00% {transform: translate3d(-@bw * 1.368,-@bw * 0.018,@bw * 0.0) rotate(4.00deg) scale(0.94027833534056,0.98897959183673);}
		70.00% {transform: translate3d(-@bw * 1.478,-@bw * 0.017,@bw * 0.0) rotate(-0.50deg) scale(0.94027833534056,0.98897959183673);}
		80.00% {transform: translate3d(-@bw * 1.757,-@bw * 0.017,@bw * 0.0) rotate(-3.00deg) scale(0.94027833534056,0.98897959183673);}
		90.00% {transform: translate3d(-@bw * 2.036,-@bw * 0.017,@bw * 0.0) rotate(3.50deg) scale(0.94027833534056,0.98897959183673);}
		100.00% {transform: translate3d(-@bw * 2.315,-@bw * 0.017,@bw * 0.0) rotate(5.00deg) scale(0.94027833534056,0.98897959183673);}
		}
	.BX_Class9{
	    left:@bw * 0.0;
	    top:@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,-@bw * 0.111) rotateX(-0.00deg) rotateY(180deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class8{
	    left:@bw * 0.111;
	    top: -@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(90.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class7{
	    left:@bw * 0.0;
	    top:@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.111) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class6{
	    left: -@bw * 0.111;
	    top:@bw * 0.0;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(-90.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Class5{
	    left:@bw * 1.657;
	    top:@bw * 0.68;
	    width:@bw * 0.417;
	    height:@bw * 0.625;
	    transform-style: preserve-3d;
	    transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(1.80,1.80,1.80);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_BG5{
	    background: rgb(16, 255, 0);
	}
	.BX_Ani5{
	    animation:litifei_BX_AniKey5 13.00s linear 0.00s infinite normal none;
	}
	@keyframes litifei_BX_AniKey5{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		10.00% {transform: translate3d(-@bw * 0.314,@bw * 0.001,@bw * 0.0) rotateX(-0.00deg) rotateY(72.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		20.00% {transform: translate3d(-@bw * 0.629,@bw * 0.003,@bw * 0.0) rotateX(-0.00deg) rotateY(144.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		30.00% {transform: translate3d(-@bw * 0.943,@bw * 0.004,@bw * 0.0) rotateX(-0.00deg) rotateY(216.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		40.00% {transform: translate3d(-@bw * 1.222,@bw * 0.006,@bw * 0.0) rotateX(-0.00deg) rotateY(288.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		50.00% {transform: translate3d(-@bw * 1.3,@bw * 0.006,@bw * 0.0) rotateX(-0.00deg) rotateY(360.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		60.00% {transform: translate3d(-@bw * 1.378,@bw * 0.006,@bw * 0.0) rotateX(-0.00deg) rotateY(432.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		70.00% {transform: translate3d(-@bw * 1.472,@bw * 0.004,@bw * 0.0) rotateX(-0.00deg) rotateY(504.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		80.00% {transform: translate3d(-@bw * 1.764,@bw * 0.003,@bw * 0.0) rotateX(-0.00deg) rotateY(576.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		90.00% {transform: translate3d(-@bw * 2.056,@bw * 0.001,@bw * 0.0) rotateX(-0.00deg) rotateY(648.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
		100.00% {transform: translate3d(-@bw * 2.349,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(720.00deg) rotateZ(0.00deg) scale3d(1.8,1.8,1.8);}
	}
	.BX_Class4{
	    left:@bw * 0.94;
	    top:@bw * 0.314;
	    width:@bw * 0.54;
	    height:@bw * 0.566;
	    background-repeat: no-repeat;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(0.83,0.71);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani4{
	    animation:litifei_BX_AniKey4 13.00s linear 0.00s infinite normal none;
	}
	@keyframes litifei_BX_AniKey4{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		10.00% {transform: translate3d(-@bw * 0.146,-@bw * 0.088,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		20.00% {transform: translate3d(-@bw * 0.294,-@bw * 0.172,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		30.00% {transform: translate3d(-@bw * 0.438,-@bw * 0.135,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		40.00% {transform: translate3d(-@bw * 0.556,-@bw * 0.04,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		50.00% {transform: translate3d(-@bw * 0.699,-@bw * 0.019,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		60.00% {transform: translate3d(-@bw * 0.831,-@bw * 0.124,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		70.00% {transform: translate3d(-@bw * 0.969,-@bw * 0.21,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		80.00% {transform: translate3d(-@bw * 1.097,-@bw * 0.162,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		90.00% {transform: translate3d(-@bw * 1.207,-@bw * 0.081,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
		100.00% {transform: translate3d(-@bw * 1.322,-@bw * 0.008,@bw * 0.0) rotate(0.00deg) scale(0.83345588235294,0.7084375);}
	}
	.BX_Class3{
	    left:@bw * 0.635;
	    top: -@bw * 0.001;
	    width:@bw * 0.379;
	    height:@bw * 0.782;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.00,1.14);
	    opacity: 1.00;
	    transform-origin:101.65% 1.43%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani3{
	    animation:litifei_BX_AniKey3 13.00s linear 0.00s infinite normal none;
	}
	@keyframes litifei_BX_AniKey3{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.00205869175384,1.13592406283825);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.17deg) scale(1.00205869175384,1.13592406283825);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(4.33deg) scale(1.00205869175384,1.13592406283825);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(5.45deg) scale(1.00205869175384,1.13592406283825);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(3.09deg) scale(1.00205869175384,1.13592406283825);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.73deg) scale(1.00205869175384,1.13592406283825);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(1.69deg) scale(1.00205869175384,1.13592406283825);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(4.13deg) scale(1.00205869175384,1.13592406283825);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(5.38deg) scale(1.00205869175384,1.13592406283825);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(2.69deg) scale(1.00205869175384,1.13592406283825);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.00205869175384,1.13592406283825);}
	}
	.BX_Class2{
	    left:@bw * 0.56;
	    top:@bw * 1.533;
	    width:@bw * 0.401;
	    height:@bw * 0.289;
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.53,1.29);
	    opacity: 1.00;
	    transform-origin:49.31% 98.08%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani2{
	    animation:litifei_BX_AniKey2 13.00s linear 0.00s infinite normal none;
	}
	@keyframes litifei_BX_AniKey2{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.53086075609775,1.29295751117951);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.81deg) scale(1.53086075609775,1.29295751117951);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.61deg) scale(1.53086075609775,1.29295751117951);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-4.55deg) scale(1.53086075609775,1.29295751117951);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-2.58deg) scale(1.53086075609775,1.29295751117951);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-0.61deg) scale(1.53086075609775,1.29295751117951);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.41deg) scale(1.53086075609775,1.29295751117951);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.44deg) scale(1.53086075609775,1.29295751117951);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-4.48deg) scale(1.53086075609775,1.29295751117951);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-2.24deg) scale(1.53086075609775,1.29295751117951);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.53086075609775,1.29295751117951);}
	}
	.BX_Class1{
	    left: -@bw * 0.008;
	    top:@bw * 1.436;
	    width:@bw * 0.417;
	    height:@bw * 0.417;
	    /*transform-style: preserve-3d;*/
	    transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(-1.44,1.14);
	    opacity: 1.00;
	    transform-origin:49.33% 94.00%;
	    display: block;
	    position: absolute; 
	}
	.BX_Ani1{
	    animation: litifei_BX_AniKey1 13.00s linear 0.00s infinite normal none;
	}
	@keyframes  litifei_BX_AniKey1{
		0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(-1.43941237356528,1.13600827572225);}
		10.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.81deg) scale(-1.43941237356528,1.13600827572225);}
		20.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.61deg) scale(-1.43941237356528,1.13600827572225);}
		30.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-4.55deg) scale(-1.43941237356528,1.13600827572225);}
		40.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-2.58deg) scale(-1.43941237356528,1.13600827572225);}
		50.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-0.61deg) scale(-1.43941237356528,1.13600827572225);}
		60.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-1.41deg) scale(-1.43941237356528,1.13600827572225);}
		70.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-3.44deg) scale(-1.43941237356528,1.13600827572225);}
		80.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-4.48deg) scale(-1.43941237356528,1.13600827572225);}
		90.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-2.24deg) scale(-1.43941237356528,1.13600827572225);}
		100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(-1.43941237356528,1.13600827572225);}
	}
</style>