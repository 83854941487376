import axios from 'axios'
import qs from 'qs'
import md5 from 'md5'
import C from '@/config'
// import store from './store'
import store from './st_comps.store'
import upload_image from './upload_image'

let Base64 = require('js-base64').Base64
let onceTimeMap = {}
let secondTimeMap = {}

var APP = getApp();

function fitModuleId (cid, capp) {
	let pre = "module_" + capp + "_";
	return cid.replace(pre, "");
}
function fixModuleId (cid, capp) {
	let pre = "module_" + capp + "_";
	if (cid.indexOf(pre) == -1) {
		cid = pre + cid;
	}
	return cid;
}

const _util = {
	getWebEnv () {
		return new Promise((resolve, reject) => {
			// resolve('wxa');
			// return;
			if (this.isWeiXin()) {
				wx.miniProgram.getEnv((res) => {
					if (res.miniprogram) {
						resolve('wxa')
					} else {
						resolve('wx')
					}
				})
			} else {
				resolve('other')
			}
		});
	},
	// 判断所处的系统环境
	getMobEnv () {
		var browser = {  
            versions: function () {  
                var u = navigator.userAgent, app = navigator.appVersion;  
                return {//移动终端浏览器版本信息   
                    trident: u.indexOf('Trident') > -1, //IE内核  
                    presto: u.indexOf('Presto') > -1, //opera内核  
                    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核  
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核  
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端  
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端  
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器  
                    iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器  
                    iPad: u.indexOf('iPad') > -1, //是否iPad  
                    webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部  
                };  
            }(),  
            language: (navigator.browserLanguage || navigator.language).toLowerCase()  
        }
        if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {  
			return "ios"; 
        } else if (browser.versions.android) {   //安卓系统  
			return "android";
        } else {  
			return "other";
        }
	},
	// 判断是否在微信小程序环境
	isWeiXin () {
		let ua = navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == "micromessenger") {
		// if (ua.match(/MicroMessenger/i) == 'micromessenger' && ua.match(/WindowsWechat/i) != 'windowswechat' && ua.match(/WechatDevTools/i) !='wechatdevtools') {
			return true;
		} else {
			return false;
		}
	},
	// 判断是否在qq环境中
	isQQ () {
		let ua = navigator.userAgent.toLowerCase();
		if (ua.match(/QQ\/[0-9]/i)) {
			return true;
		} else {
			return false;
		}
	},
	// 判断是否来自于微信小程序制作
	isWxapp (appid) {

		if (appid == 'wx2fb7317f6bc5b387') {
			return 'TIANDOU';
		} else if (appid == 'wx552e3843819927e5') {
			return 'TIANDOUMERGE';
		}

		return false;
	},
	// 判断是否webview环境
	isWebview () {
		return ["wxa.litiskr.xyz","debugwxa.litiskr.xyz","debugg3.litiskr.cn","webview.litiskr.cn","debugwebview.litiskr.cn"].indexOf(window.location.host) == -1 ? 'wx' : 'wxa';
	},
	searchImgurl (obs, results) {
		// if (Array.isArray(obs)) {
		// 	for(let i=0, lens=obs.length; i<lens; i++) {
		// 		results.push(searchImgurl(obs[i], results))
		// 	}
		// }
	},
	canWebpAble () {
		let s = this.getSlog("WEBP_ABLE");
		if (!s) {
			return false;
		}
		s = JSON.parse(s);
		return s['data'];
	},
	setSlog (key, value) {
	 	if (!(key && value)) {
	 		return;
	 	}
	    var data = localStorage.getItem(key);

	    if (data) {
	        data = JSON.parse(data);
	    } else {
	        data = [];
	    }
	    if (data.indexOf(value) == -1) {
	        data.push(value);
	    }
	    localStorage.setItem(key, JSON.stringify(data));
	},
	getSlog (key) {
		if (!key) {
			return false;
		};
		var data = localStorage.getItem(key);

		return data;
	},
	inSlog (key, value) {
        var data = localStorage.getItem(key)

        if (!data) {
            return false;
        }

        data = JSON.parse(data);

        if (data.indexOf(value) == -1) {
            return false;
        } else {
            return true;
        }
	},
	im (imageData, resize_w) {
		let from_map = store.state.appConfig.image_map.from_map;
		let host_map = store.state.appConfig.image_map.host_map;

		if ('string' == this.typeOf(imageData)) {
			imageData = imageData.replace("https:","http:");
			let _f = "oss";
			let _p = null;
			for (let _host in from_map) {
				if (imageData.indexOf(_host) > -1) {
					_f = from_map[_host];
					_p = imageData.replace(_host, "");
				}
			}
			imageData = {
				'f': _f,
				'p': _p,
				'pr': null,
			}
			if (!_p) { // 无法匹配host则直接返回
				return imageData;
			}
		}

		let f = imageData['f'];

		let host = host_map[f],
			path = imageData['p'];
			
		if (path.indexOf("http") > -1){
			return path
		}
		
		let url = [host + path];

		// 加入前缀
		if (f.indexOf('oss') != -1) {
            url.push('?x-oss-process=image');
		} else if (f == 'qiniu') {
            url.push('?imageMogr2/auto-orient')
		}

		// 兼容服务端错误产生的string类型的pr数据
		if (typeof(imageData['pr']) == 'string') {
			try {
				imageData['pr'] = JSON.parse(imageData['pr']);
			} catch(e) {
                console.error(e);
            }
		}

		// 加入裁剪
		if (imageData['pr'] && imageData['pr']['ed']) {
			// 清理ed
			for (let j=0; j<imageData['pr']['ed'].length; j++) {
				// 部分情况下的裁剪操作会产生越界的负值，这里将负值统一设置为0
				if (parseInt(imageData['pr']['ed'][j]) < 0) {
					imageData['pr']['ed'][j] = 0;
				}
			}

			if (f.indexOf('oss') != -1) {
                url.push( '/crop,x_'+imageData['pr']['ed'][0]+',y_'+imageData['pr']['ed'][1]+',w_'+imageData['pr']['ed'][2]+',h_'+imageData['pr']['ed'][3] );
			} else if (f == 'qiniu') {
                url.push( '/crop/!'+imageData['pr']['ed'][2]+'x'+imageData['pr']['ed'][3]+'a'+imageData['pr']['ed'][0]+'a'+imageData['pr']['ed'][1] );
			}
		}

		// 加入缩放
		resize_w = resize_w || 600;
        // 若图片宽不足则不进行缩放
        let image_w = imageData['pr'] && imageData['pr']['width'] && imageData['pr']['width'] || resize_w;
        if (parseInt(image_w) >= parseInt(resize_w)) {
            if (f.indexOf('oss') != -1) {
                url.push( '/resize,w_'+resize_w );
            } else if (f == 'qiniu') {
                url.push( '/thumbnail/'+resize_w+'x' );
            }
        }

        // 加入旋转
		if (imageData['pr'] && imageData['pr']['rotate']) {
			let deg = imageData['pr']['rotate'];
			if (f.indexOf('oss') != -1) {
                url.push( '/rotate,'+deg );
			} else if (f == 'qiniu') {
                url.push( '/rotate/'+deg );
			}
		}

        // 检测是否需要加入图片处理
        if (url.length == 2) {
            // 无需要处理的url，取消后缀
            url.pop();
        } else {
            // 有需要处理的后缀，补充oss图片的自适应方向
            if (f.indexOf('oss') != -1) {
                url.push( '/auto-orient,1' );
            }
        }

        // 合并链接
        url = url.join("");

		return url;
	},
	// 根据传入的imagedatas返回imageids
	getImageIdsByDatas (datas) {
		let imageids = [];
		for (let i=0; i<datas.length; i++) {
			imageids.push(datas[i]['id']);
		}
		return imageids;
	},
	copy (o) {
		return JSON.parse(JSON.stringify(o));
	},
	timeout (cb, time) {
		let used = 0;
		let handle = setInterval(() => {
			used += 100;
			if (used == time) {
				cb && cb();
				clearInterval(handle);
			}
		}, 100);
		return handle;
	},
	// 获取url中参数
	getUrlQuery (name) {
		return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
	},
	// 根据url获取querys
	getQuerysByUrl (url) {
		url = url || window.location.href;
		let querys = {};
		let tm = url.split('?');
		if (tm.length == 2) {
			let querys_arr = tm[1];
			querys_arr = querys_arr.split('&');
			for (let i=0; i<querys_arr.length; i++) {
				tm = querys_arr[i].split('=');
				querys[tm[0]] = tm[1];
			}
		}
		return querys;
	},
	// 根据querys获取url
	getUrlByQuerys (querys, url) {
		url = url ? url.split('?')[0] : window.location.href.split('?')[0];
		let querys_arr = [];
		for (let k in querys) {
			querys_arr.push( (k + "=" + querys[k]) );
		}
		return url + "?" + querys_arr.join('&');
	},
	copyArray (arr) {
		return JSON.parse(JSON.stringify(arr))
	},
	getImagesArray (images, indent) {
		images = this.copyArray(images);
		let lens = images.length,
			maxlen = Math.ceil(images.length / indent) * indent;

		// 补充图片组
		// for (let i=0; i<maxlen; i++) {
		// 	if (!images[i]) {
		// 		images.push( images[i%lens] );
		// 	}
		// }

		// 均分图片组
		let slice_images = [];
		for (let i=0; i<images.length; i+=indent) {
			let ims = images.slice( i, i+indent );
			slice_images.push(ims);
		}

		// 补全尾数
		if (slice_images.length == 1) {
			for (let i=0; i<indent; i++) {
				if (!slice_images[0][i]) {
					slice_images[0].push( slice_images[0][i%slice_images[0].length] );
				}
			}
		} else {
			let last_images = slice_images[ slice_images.length - 1 ];
			if (last_images.length != indent) {
				let last_images_2 = slice_images[ slice_images.length - 2 ];
				let temp_images = last_images_2.slice(last_images.length);
				last_images = temp_images.concat(last_images);
				slice_images[slice_images.length-1] = last_images;
			}
		}

		return slice_images;
	},
	getViewImages (slice_images, image_idx, speed) {
		slice_images = JSON.parse(JSON.stringify(slice_images));
		let view_images = slice_images[ image_idx%slice_images.length ];

		let last_view_images, next_view_images, last_idx, next_idx;
		let preload_view_images = [];

		last_idx = image_idx - 1;
		next_idx = image_idx + 1;
		next_view_images = slice_images[ next_idx%slice_images.length ];
		last_view_images = slice_images[ last_idx%slice_images.length ];

		if (speed <= 0) { // 若反向滑动，则数组逆序
			image_idx --;
			if (image_idx <= 0) {
				image_idx = slice_images.length * 1000000000;
			}
		} else {
			image_idx ++;
		}

		try {
			preload_view_images = preload_view_images.concat(next_view_images);
		} catch(e) {}
		try {
			preload_view_images = preload_view_images.concat(last_view_images);
		} catch(e) {}

		return [ view_images, preload_view_images , image_idx ];
	},
	fixImages (arr, indent) {
		var lens = arr.length;
		for (var i=0; i<indent; i++) {
			if (i > (lens-1)) {
				arr.push(arr[i%lens])
			}
		}

		// 按个数分割数组

		// arr = resizeImage(arr);
		
		return arr;
	},
	logRequestError (point) {
		alert(point + ', 请求错误，请添加微信号（litixzs）联系客服');
	},
	rget (api, params) {
		let path,
			url,
			host,
			root = 'vliti';

		if (api.indexOf('http') != -1) {
			url = api;
		} else {
			host = C.staticHost.indexOf(location.host) != -1 ? (
				// 静态资源
				C.debugHost.indexOf(location.host) != -1 ? 'https://debugg3.litiskr.cn' : 'https://g3.litiskr.cn'
			) : (
				// 动态版本
				location.host.indexOf('localhost') != -1 ? 'http://dev2.xcgogo.site' : ''
				// C.debugHost.indexOf(location.host) != -1 ? 'http://dev2.xcgogo.site' : ''
			)
			// host = C.debugHost.indexOf(location.host) != -1 ? 'https://debugg3.litiskr.cn' : 'https://g3.litiskr.cn';
			
			path = api.indexOf('/') == -1 ? '/'+root+'/'+api : api;
			if (path[0] != '/') {
				path = '/' + path;
			}

			url = host + path;
		}
		
		params['_type'] = 'ajax';
		params['__version__'] = __VERSION__;
		params['__debug__'] = C.debugHost.indexOf(location.host) != -1

		return new Promise(( resolve, reject ) => {
			axios.get(url, {
				params: params
			}).then((response) => {
				if ('/asyn/wxa_make_qrcode_image' == api) {
					resolve(response.data);
				} else {
					if (response.data.errcode == 0) {
						resolve(response.data.data);
					} else {
						console.warn('server warn: ', response);
						reject(response.data.data);
					}
				}
			}).catch((err) => {
				reject(err);
			})
		})
	},
	rpost (api, params) {
		let path,
			url,
			host,
			root = 'vliti';

		if (api.indexOf('http') != -1) {
			url = api;
		} else {
			host = C.staticHost.indexOf(location.host) != -1 ? (
				// 静态资源
				C.debugHost.indexOf(location.host) != -1 ? 'https://debugg3.litiskr.cn' : 'https://g3.litiskr.cn'
			) : (
				// 动态版本
				location.host.indexOf('localhost') != -1 ? 'http://dev2.xcgogo.site' : ''
				// C.debugHost.indexOf(location.host) != -1 ? 'http://dev2.xcgogo.site' : ''
			)
			// host = C.debugHost.indexOf(location.host) != -1 ? 'https://debugg3.litiskr.cn' : 'https://g3.litiskr.cn';

			path = api.indexOf('/') == -1 ? '/'+root+'/'+api : api;
			if (path[0] != '/') {
				path = '/' + path;
			}

			url = host + path;
		}
		
		params['_type'] = 'ajax';
		params['__version__'] = __VERSION__;
		params['__debug__'] = C.debugHost.indexOf(location.host) != -1

		return new Promise(( resolve, reject ) => {
			axios.post(url, 
				qs.stringify(params),
				{
					headers: {'Content-Type': "application/x-www-form-urlencoded"}
				}
			)
			.then((response) => {
				if (response.data.errcode == 0) {
					resolve(response.data.data);
				} else {
					console.error('server error');
					reject(response.data.data);
				}
			})
			.catch((err) => {
				reject(err);
			})
		})
	},
	rfetch (url, params) {
		return new Promise(( resolve, reject ) => {
			axios.post(url, 
				params,
				{
					headers: {'Content-Type': "multipart/form-data"},
				},
			)
			.then((response) => {
				resolve(response)
			})
			.catch((err) => {
				reject(err);
			})
		})
	},
	getVideoSrc (vid) {
		// let req_url = "https://vv.video.qq.com/getinfo?vids=" + vid + "&platform=101001&charge=0&otype=json&defn=shd";
		let _this = this;
		return new Promise(( resolve, reject ) => {
			_this.rpost('https://alifun.litiskr.cn/api/repost_request', {
				"host": "https://vv.video.qq.com/getinfo",
				"params": JSON.stringify({
					"vids": vid,
					"platform": 101001,
					"charge": 0,
					"otype": "json",
					"defn": "shd"
				}),
				"method": "GET",
			}).then((res) => {
				var respone = res.split('=')
				var length = respone[1].length;
				var output = respone[1].substr(0, length-1);
				var output_JSON = JSON.parse(output);
				var fn = output_JSON.vl.vi[0].fn;
				var fvkey = output_JSON.vl.vi[0].fvkey;
				resolve('https://ugcws.video.gtimg.com/' + fn + '?vkey=' + fvkey);
			}).catch((err) => {
				reject(err);
			})
		})
	},
	build_inline_script (func_name, func_params, func) {
		let script = document.createElement('script');
		script.innerHTML = "\
			function "+func_name+"("+func_params+") {\
				"+func+"\
			}\
		";
		document.head.appendChild(script);
	},
	build_script (src) {
		let script = document.createElement('script');
		script.setAttribute('src', src);
		document.body.appendChild(script);
	},
	random_n (n) {
		if (n > 21) return null;
		return parseInt((Math.random() + 1) * Math.pow(10,n-1));
	},
	getLocationPath (path) {
		return window.location.protocol + '//' + window.location.host + '/vliti/main/' + path;
	},
	loadScript (url) {
		return new Promise((resolve, reject) => {
			var id = this.md5(url);

			// 已经加载过
			if (document.getElementById(id)) {
				resolve();
				return;
			}

			var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = 'async';
            script.setAttribute('id', id);

            if (script.readyState) {
            	script.onreadystatechange = function() {
            		if (script.readyState == 'loaded' || script.readyState == 'complete') {
            			script.onreadystatechange = null;
            			resolve();
            		}
            	}
            } else {
	            script.onload = function() {
	            	resolve();
	            }
	            script.onerror = function() {
	            	reject();
	            }
            }

            script.src = url;
            document.body.appendChild(script);
		})
	},
	loadCss (url) {
		return new Promise((resolve, reject) => {
			var id = this.md5(url);

			// 已经加载过
			if (document.getElementById(id)) {
				resolve();
				return;
			}

			var link = document.createElement('link');
			link.rel = "stylesheet";
			link.type = "text/css";
			link.setAttribute('id', id);

			if (link.readyState) {
            	link.onreadystatechange = function() {
            		if (link.readyState == 'loaded' || link.readyState == 'complete') {
            			link.onreadystatechange = null;
            			resolve();
            		}
            	}
            } else {
	            link.onload = function() {
	            	resolve();
	            }
	            link.onerror = function() {
	            	reject();
	            }
            }

			link.href = url;
			document.body.appendChild(link);
		})
	},
	classHide (className) {
		let classs = document.getElementsByClassName(className);
		for(let i=0, lens=classs.length; i<lens; i++) {
			let _class = classs[i];
			_class.style.display = 'none';
		}
	},
	classShow (className) {
		let classs = document.getElementsByClassName(className);
		for(let i=0, lens=classs.length; i<lens; i++) {
			let _class = classs[i];
			_class.style.display = 'block';
		}
	},
	oncetime (key, cb) {
		if (!onceMap[key]) {
			onceMap[key] = true;
			cb && cb();
		}
	},
	overSecondTime (key, cb) {
		if (!secondTimeMap[key]) secondTimeMap[key] = 0;
		secondTimeMap[key] ++;
		if (secondTimeMap[key] >= 2) {
			cb && cb()
		}
	},
	secondtime (key, cb) {
		if (!secondTimeMap[key]) secondTimeMap[key] = 0;
		secondTimeMap[key] ++;
		if (secondTimeMap[key] == 2) {
			cb && cb()
		}
	},
	getImageUrlsFromString (s) {
		if (!s) {
			return [];
		}
		let m = s.match(/(https?:\/\/).*?\.(jpg|png|gif|jpeg)/gi);
		// let m = s.match(/(https?:\/\/)([0-9a-z\-.]+)(:[0-9]+)?([/0-9a-z.]+)?(\-[0-9a-z]\-[0-9a-z])?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?(_[0-9a-z.]+)?/gi);
		if (m) {
			let r = this.arrayDistinct(m);
			let i, idx, result = [];
			for (i=0; i<r.length; i++) {
				if (r[i].indexOf('{index}') != -1) {
					// 回避帧图片path预加载
					continue;
				}
				result.push(r[i]);
			}
			return result;
		} else {
			return [];
		}
	},
	arrayDistinct (arr) {
		return Array.from(new Set(arr));
	},
	getWxaQrcode (scene, path, appid) {
		let name = scene;
		if (path) {
			name = this.md5(scene+path);
		}
		path = path || 'pages/make/make';
		appid = appid || 'wx2fb7317f6bc5b387';
		return new Promise((resolve, reject) => {
			let _this = this;
			let im = new Image();

			let qrcode = 'http://alioss.lititutu.cn/qrcode/'+name+'.png';

			im.onload = function() {
				resolve(qrcode);
            }
            im.onerror = function() {
            	_this.rget('https://alifun.litiskr.cn/api/wxa_qrcode_fetcher', {
            		'appid': appid,
            		'scene': scene,
            		'page': path,
            		'name': name,
            		'oss_bucket': 'mp-aliyunpic',
            	})
                .then((response) => {
                	resolve(qrcode);
                })
            }
            im.src = qrcode;
		})
	},
	encodeUserText (text) {
		return Base64.encode(text);
	},
	decodeUserText (text) {
		// 服务端错误存储了None字段
		if (text == 'None' || !text) {
			return '';
		}

		let _b64text = '';

		// 捕捉特殊字符串解析失败的情况，直接返回text
		try {
			_b64text = Base64.decode(text);
		} catch(e) {
			return text;
		}

		if ( _b64text && /^[0-9a-zA-Z]/.test(text) && /[0-9a-zA-Z=+\/]$/.test(text)) {
			return _b64text;
		}

		return text;
	},
	log (field) {
		return new Promise((resolve, reject) => {
			let filter_fields = [];

			if (store.state.config.data['official']) { //官方相册过滤log
				filter_fields = ["theme_share"]
			}

			let params = {
				'key': 'vliti'
			};

			if (this.typeOf(field) == 'array') {
				let _fields = [];
				for (let i = 0 ; i < field.length ; i++ ) {
					let can_log = true;
					for (let j = 0 ; j < filter_fields.length ; j++){
						if (field[i] && field[i].indexOf(filter_fields[j]) > -1) {
							can_log = false;
							break;
						}
					}
					if (can_log) {
						_fields.push(field[i])
					}
				}
				if (!_fields){
					return;
				}
				params['fields'] = JSON.stringify(_fields);
			} else {
				for (let j = 0 ; j < filter_fields.length ; j++){
					if (field && field.indexOf(filter_fields[j]) > -1) {
						return;
					}
				}
				params['field'] = field;
			}

			let key = this.$util.md5( params['field'] || params['fields'] );

			if (onceTimeMap[key]) {
				resolve()
			} else {
				onceTimeMap[ key ] = 1;
				this.rpost('/api/log', params)
				.then((response) => {
					resolve();
				});
			}
		})
	},
	// 制作变脸相册
	authentication (value) {
		var valueArray = [];
		for (var index in value) {
			valueArray.push(value[index])
		}
		var api_secret = '2323dsfadfewrasa3434';
		valueArray.push(api_secret);
		var sign = valueArray.join('#');
		return (md5(sign));
	},
	setCookie (name, value, ttl) {
		let exp = new Date();
		exp.setTime(exp.getTime() + ttl);
		document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
	},
	getCookie (name) {
		let arr,reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
		if(arr = document.cookie.match(reg)) {
			return unescape(arr[2]);
		} else {
			return null;
		}
	},
	delCookie (name) {
		let exp = new Date();
		exp.setTime(exp.getTime() - 1);
		let cval = this.getCookie(name);
		if (cval != null) {
			document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
		}
	},
	// getShareTitle (config) {
	// 	let data = config['data'];
	// 	if (data['ti']) {
	// 		return data['ti'];
	// 	}
	// 	// if (C.shareTitles[data['ttid']]) {
	// 	// 	let ttid = data['ttid'];
	// 	// 	let share_title = ""
	// 	// 	if (Object.prototype.toString.call(C.shareTitles[ttid]).toLowerCase() == '[object array]') {
	// 	// 		share_title = C.shareTitles[ttid][parseInt(Math.random()*10000)%C.shareTitles[ttid].length];
	// 	// 	} else {
	// 	// 		share_title = C.shareTitles[ttid];
	// 	// 	}
	// 	// 	return share_title;
	// 	// }
	// 	return "";
	// },
	getShareTitle () {
		let share_title = '';
		// step 1. 获取用户ti
		share_title = store.state.config['data'] && store.state.config['data']['ti'] || share_title;

		// step 2. 获取主题配置中的标题
		if (!share_title) {
			let share_config_titles = store.state.config['data']['ttid'] 
							&& store.state.themes[ store.state.config['data']['ttid'] ] 
							&& store.state.themes[ store.state.config['data']['ttid'] ]['shareconfig'] 
							&& store.state.themes[ store.state.config['data']['ttid'] ]['shareconfig']['titles'] || [];
			try {
				share_config_titles = JSON.parse(share_config_titles);
			} catch(e) {}
			if (share_config_titles.length) {
				share_title = share_config_titles[ parseInt(Math.random() * 10000) % share_config_titles.length ];
				share_title = this.replaceKeyWords(share_title, "author");
			}
		}

		// step 3. 获取服务端下发默认标题
		if (!share_title) {
			let prefix = this.getCurrentPreFix(store.state.appConfig);
			// share_title = store.state.appConfig.share_title.indexOf(prefix) == -1 ? prefix + store.state.appConfig.share_title : store.state.appConfig.share_title;
			let titles = this.$util.$config.st_config['share_titles'];
			share_title = titles[ parseInt(Math.random() * 10000) % titles.length ];
			if (share_title.indexOf(prefix) < 0){
				share_title = prefix + share_title;
			}
		}

		return share_title;
	},
	// buildShareTitle(_state, _response){
	// 	let _the_title = this.getShareTitle(_state.config),
	// 		_this = this,
	// 		_share_title = _response && _response['def_share_title'] || "";
	// 	try {
	// 		if (_state.config['data'] && _state.config['data']['ti'] && _state.config['data']['ti'].length > 0){
	// 			_share_title = _state.config['data']['ti'];
	// 		} else if (_state.config['data']['ttid']
	// 			&& _state['themes'][ _state.config['data']['ttid'] ]
	// 			&& _state['themes'][ _state.config['data']['ttid'] ]['shareconfig']
	// 			&& _state['themes'][ _state.config['data']['ttid'] ]['shareconfig']['titles']
	// 			&& _state['themes'][ _state.config['data']['ttid'] ]['shareconfig']['titles'].length > 1)
	// 		{
	// 			let titles = JSON.parse( _state['themes'][ _state.config['data']['ttid'] ]['shareconfig']['titles'] );
	// 			if(titles && titles.length > 0){
	// 				_the_title = titles[parseInt(Math.random()*10000)%titles.length];
	// 				// _the_title = _the_title.replace("#nickname#", _state.userInfo && _state.userInfo.nickName && _state.userInfo.nickName != "相册用户"?_state.userInfo.nickName:"我");
	// 				// _the_title = _the_title.replace("#city#", _state.userInfo && _state.userInfo.city ?_state.userInfo.city:"这里");
	// 				// _the_title = _the_title.replace("#today#", __now__.getMonth()+1 + '月' + __now__.getDate()+'日');
	// 				// // 日期判断
	// 				// var reg = /(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)/;
	// 				// if(_the_title.match(reg)){
	// 				// 	let daysDistance = Math.abs(_this.daysDistance(__now__.toLocaleDateString(), _the_title.match(reg)[0].replace(/-/g,"/")));
	// 				// 	_the_title = _the_title.replace("#"+ _the_title.match(reg)[0] + "#", daysDistance.toString())
	// 				// }
	// 				_share_title = _this.replaceKeyWords(_the_title, "author", _state);
	// 			}
	// 		} else {
	// 			let prefix = _this.getCurrentPreFix(_response);
	// 			if(_share_title.indexOf(prefix) < 0){
	// 				_share_title = prefix + _share_title;
	// 			}
	// 		}
	// 	} catch (error) {
	// 		// alert(JSON.stringify(error))
	// 		console.log(error)
	// 	}
	// 	return _share_title;
	// },
	replaceKeyWords (_string, usertype, _state){
		_state = _state || store.state;
		/**
		 * _string 被替换的string
		 * usertype 用户类型"当前用户/相册作者"
		 * state vuex中的state对象
		 */
		let __now__ = new Date(),
			userinfo = {
				"nickname": usertype == "author" ? "我" : "相册用户",
				"city": "这里",
			};
		if (usertype == "author" && _state.userInfo){
			userinfo = _state.userInfo;
		} else if (usertype == "visitor" && _state.config && _state.config.userInfo) {
			userinfo = _state.config.userInfo;
		}
		_string = _string.replace("#nickname#", userinfo && userinfo.nickName && userinfo.nickName != "相册用户"?userinfo.nickName:"我");
		_string = _string.replace("#city#", userinfo && userinfo.city ?userinfo.city:"这里");
		_string = _string.replace("#today#", __now__.getMonth()+1 + '月' + __now__.getDate()+'日');
		// 日期判断
		var reg = /(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)/;
		if (_string.match(reg)) {
			let daysDistance = Math.abs(this.daysDistance(__now__.toLocaleDateString(), _string.match(reg)[0].replace(/-/g,"/")));
			_string = _string.replace("#"+ _string.match(reg)[0] + "#", daysDistance.toString())
		}

		// 新倒数
		let reg2 = /#countdown_.*#/;
		if (_string.match(reg2)) {
			let countdown = _string.match(reg2)[0].replace(/#/g, "").replace("countdown_","");
			let [countdonw_day,day_name] = countdown.split("_");
			let daysDistance = parseInt( this.daysDistance(__now__.toLocaleDateString(), countdonw_day) ); 
			let count_down_temp = "";
			let coundownwords = [
				["今天是#name#","#name#到了"],
				["明天就是#name#了", "#name#马上到了", "马上就是#name#了"],
				["后天就是#name#了"],
				["距离#name#还有#day#天","还有#day#天就是#name#了","#day#天后是#name#"]
			]
			if (daysDistance > -1){
				let words = coundownwords[3]
				if (daysDistance <= 2) {
					words = coundownwords[daysDistance];
				}
				count_down_temp = words[parseInt(Math.random()*10000)%words.length];
				count_down_temp = count_down_temp.replace(/#day#/g, daysDistance).replace(/#name#/g, day_name)
			}
			_string = _string.replace(_string.match(reg2)[0], count_down_temp);
		}
		return _string;
	},
	getShareImgurl (normal_mode){
		let _this = this,
			state = store.state,
			xc_value = state.config,
			usercover = this.im(xc_value.images[0], normal_mode ? 250 : 160),
			simple_cover = this.buildShareCover(usercover
				,'play_video.png?x-oss-process=image/resize,w_120',
            	'cv2.jpg?x-oss-process=image/resize,w_400')
		if (normal_mode){
			console.log("简约封面=>",simple_cover)
			console.log("早安封面=>",this.buildShareCover(usercover
				,'play_video.png?x-oss-process=image/resize,w_120',
            	'cv3.jpg?x-oss-process=image/resize,w_400'))
			console.log("情歌封面=>",this.buildShareCover(usercover
				,'play_video.png?x-oss-process=image/resize,w_120',
            	'cv4.jpg?x-oss-process=image/resize,w_400'))
		}
		try {
			let theme_value = state.themes[ xc_value['data']['ttid'] ];
			if(theme_value && theme_value.shareconfig && theme_value.shareconfig.cover && theme_value.shareconfig.cover.length){
				let host = "",
					hostend = usercover.indexOf(".cn/") > -1 ? ".cn/":".com/";
				host = usercover.split(hostend)[0] + hostend
				let _usercover = usercover.split(hostend)[1].split("?")[0]
				// 七牛图片需触发一次回源到oss中作分享封面
				if (host.indexOf(state.appConfig.image_map.host_map['qiniu']) != -1) {
					host = host.replace(store.state.appConfig.image_map.host_map['qiniu'], 'http://wxaqiniu.lititutu.cn/')
					let im = new Image();
					im.src = host + _usercover + "?x-oss-process=image/resize,w_10";
					im.onload = ()=>{}
					im.onerror = ()=>{}
				}
				let share_image_path = host + _this.getShareCover(_usercover, theme_value.shareconfig.cover);
				return share_image_path;
			} else {
				return simple_cover;
			}
		} catch (error) {
			return simple_cover;
		}
	},
	getCurrentPreFix(app){
		if (app && app.pre_fix){
			return app.pre_fix;
		}
		let TODAY= new Date();
		let HOUR = TODAY.getHours()
		let MONTH = TODAY.getMonth() + 1
		let DATE = TODAY.getDate()
		let WEEKDAY = TODAY.getDay()
		let preFix = ""
		if(DATE == 1){
			return "🗓️" + MONTH + "月你好"
		}
		if(WEEKDAY == 0 || WEEKDAY == 6){
			preFix = "🌈周末" + preFix
		}
		if(3 <= HOUR && HOUR < 11){
			preFix = (preFix ? preFix:"🌅") + "早上好"
		} else if(11 <= HOUR && HOUR < 13){
			preFix = (preFix ? preFix:"🔅") + "中午好"
		} else if(13 <= HOUR && HOUR < 18){
			preFix = (preFix ? preFix:"🌆") + "下午好"
		} else {
			preFix = (preFix ? preFix:"🌃") + "晚上好"
		}
		return preFix;
	},
	daysDistance(dateString1, dateString2){
		var  startDate = Date.parse(dateString1);
		var  endDate = Date.parse(dateString2);
		var days=(endDate - startDate)/(1*24*60*60*1000);
		return  days;
	},
	randInt () {
		return Math.round(Math.random()*1000000);
	},
	randArray (data) {
		//获取数组长度
		var arrLen = data.length;
		//创建数组 存放下标数
		var try1 = new Array();
		for( var i = 0 ; i < arrLen ; i++ ){
		    try1[i] = i;
		}
		//创建数组 生成随机下标数
		var try2 = new Array();
		for( var i = 0 ; i < arrLen ; i++ ){
		    try2[i] = try1.splice(Math.floor(Math.random() * try1.length),1);
		}
		//创建数组，生成对应随机下标数的数组
		var try3 = new Array();
		for( var i = 0 ; i < arrLen ; i++){
		    try3[i] = data[ try2[i] ];
		}
		return try3;
	},
	md5 (s) {
		return md5(s);
	},
	block (appid, openid) {
		let redirect_url = '/vliti/main/mybook?_source='+appid+'&openid='+openid;
		// window.location.href = '/public/msg?msgtype=warn&title='+encodeURIComponent('制作失败')+'&desc='+encodeURIComponent('请勿上传包含不良信息的图片及内容，与立体相册携手共建绿色美好生活')+'&primary_url='+encodeURIComponent('/vliti/login?target=menu')+'&primary_txt='+encodeURIComponent('绿色制作')+'&default_url='+encodeURIComponent('/public/apps_report?__app__=liti&redirect_url='+encodeURIComponent(redirect_url)+"&remark=reblock&__appid__="+appid+"&openid="+openid)+'&default_txt='+encodeURIComponent('申诉解封');
		
		// window.location.href = '/public/msg?msgtype=warn&title='+encodeURIComponent('相册审核中')+'&desc='+encodeURIComponent('根据相关法律法规，当前相册中存在需要审核的内容，工作人员正在加紧审核，请耐心等待！给您带来不便，敬请谅解！')+'&primary_url='+encodeURIComponent('/vliti/login?target=menu')+'&primary_txt='+encodeURIComponent('返回相册')+'&default_url='+encodeURIComponent('/public/apps_report?__app__=liti&redirect_url='+encodeURIComponent(redirect_url)+"&remark=reblock&__appid__="+appid+"&openid="+openid)+'&default_txt='+encodeURIComponent('加快审核');

		// 应对2021.04.14的域名封禁事件、暂时不引流xcpapa.cn byhz
		window.location.href = '/public/msg?msgtype=warn&title='+encodeURIComponent('相册审核中')+'&desc='+encodeURIComponent('根据相关法律法规，当前相册中存在需要审核的内容，工作人员正在加紧审核，请耐心等待！给您带来不便，敬请谅解！')+'&default_url='+encodeURIComponent('/public/apps_report?__app__=liti&redirect_url='+encodeURIComponent(redirect_url)+"&remark=reblock&__appid__="+appid+"&openid="+openid)+'&default_txt='+encodeURIComponent('加快审核');
	},
	getMusicLast (mid) {
		return new Promise((resolve, reject) => {
			this.rget('https://alifun.litiskr.cn/api/music', {
				mid: mid,
				t: 'get',
				fields: 'all'
			}).then((music) => {
				if (music['type'] == 'kugou') {
					resolve(music);
				} else {
					let playsrc = music['source_url'].indexOf('http') != -1 ? music['source_url'] : music['oss_url'];
					playsrc = playsrc.replace('mp-musics-kugou.oss-cn-shenzhen.aliyuncs.com', 'music.lititutu.cn');
					playsrc = playsrc.replace('aac|mp3', 'mp3');
					music['src'] = playsrc;
					resolve(music);
				}
			});
		});
	},
	getMusic (mid) {
		return new Promise((resolve, reject) => {
			// 灰度测试新接口获取音乐数据 100% byhz 04.27
			this.rget('https://alifun.litiskr.cn/stmusic/get_music', {
				mids: mid,
				fields: 'mid,name,uid,src,lrc'
			}).then((music) => {
				resolve(music[0]);
			}).catch((err) => {
				this.getMusicLast(mid).then((music) => {
					resolve(music);
				})
			})
		})
	},
	// 计划废弃
	audioLoader (music) {
		return new Promise((resolve, reject) => {
			let playsrc = music['source_url'].indexOf('http') != -1 ? music['source_url'] : music['oss_url'];
			playsrc = playsrc.replace('mp-musics-kugou.oss-cn-shenzhen.aliyuncs.com', 'music.lititutu.cn');
			resolve(playsrc);
		})
	},
	// 获取关注链接
	getFollowUrl (url, __from__) {
		if (url.indexOf(',') != -1) {
			let url1, url2, url_rt;
			[url1,url2] = url.split(',');
			switch (__from__) {
				case 'make':
					url_rt = url1;
					break;
				case 'recommend':
					url_rt = url2;
					break;
				case 'wxshare':
					url_rt = url1;
					break;
			}
			return url_rt;
		} else {
			return url;
		}
	},
	// 特定用户行为日志收集
	ulog (t) {
		if (window.location.search.indexOf('ulog=1') != -1 || window.location.search.indexOf('ulog=2') != -1) {
			if (typeof t == 'object') {
				t = JSON.stringify(t);
			}
			// t = window.location.href + "_" + t;
			this.rpost('/api/ulog', {msg: t});
		}
	},
	// 获取指定空间内最大显示尺寸、类似background-size: contain
	getContainSize (orisize, boxsize) {
		orisize = orisize || [500, 500];
		
		let box_width = boxsize[0],
			box_height = boxsize[1],
			w = orisize[0],
			h = orisize[1];

		if (box_width / box_height <= w / h) {
			let tw = box_width;
			let th = tw * h / w;
			return {
				width: tw,
				height: th,
				marginTop: ((box_height - box_width * h / w) / 2),
				marginLeft: 0
			}

		} else {
			let th = box_height;
			let tw = th * w / h;
			return {
				height: th,
				width: tw,
				marginTop: 0,
				marginLeft: ((box_width - box_height * w / h) / 2)
			}
		}
	},
	getCoverSize (orisize, boxsize) {
		orisize = orisize || [500, 500];

		let width = parseInt(orisize[0]),
			height = parseInt(orisize[1]);
		let box_width = parseInt(boxsize[0]),
			box_height = parseInt(boxsize[1]);

		let scale = width / height;
		let box_scale = box_width / box_height;
		let size;
		if (box_scale > scale) {
			size = {
				width: box_width,
				height: box_width / scale
			}
			size['marginTop'] = - (size['height'] - box_height)/2;
			size['marginLeft'] = 0;
		} else {
			size = {
				width: box_height * scale,
				height: box_height
			}
			size['marginTop'] = 0;
			size['marginLeft'] = - (size['width'] - box_width)/2;
		}
		return size;
	},
	// 相册数据相关计数 废弃 改到vux
	// setNum (key, field) {
	// 	return new Promise((resolve, reject) => {
	// 		this.rget('set_num', {
	// 			'key': key,
	// 			'field': field || 'default'
	// 		})
	// 		.then((response) => {
	// 			resolve(response);
	// 		})
	// 	})
	// },
	// 判断变量类型
	typeOf (s) {
		let t = 'string';
		switch (Object.prototype.toString.call(s).toLowerCase()) {
			case '[object object]':
				t = 'object';
				break;
			case '[object array]':
				t = 'array';
				break;
			case '[object string]':
				t = 'string';
				break;
			case '[object number]':
				t = 'number';
				break;
		}
		return t;
	},
	// 图片内容检测
	imgchecker (mode, key, images) {
		if (mode == 'CONTENT_CHECK_10') {
			this.log('pic_content_check_10');
			this.__safety_check(key, images, mode);
		} else if (mode == 'CONTENT_CHECK_100') {
			this.log('pic_content_check_100');
			this.__safety_check(key, images, mode);
		} else if (mode == 'TEXT_CHECK_50') {
			this.log('pic_text_check_50');
			this.__safety_check(key, images, mode);
		}
	},
	__safety_check (key, images, mode) {
		let imgs = [];
		for (let i = 0 ; i < images.length ; i++){
			let img = images[i];
			img["host"] = img["im"].split("?")[0].replace(img["p"], "").replace("https","http")
			img["path"] = img["p"]
			imgs.push(img)
		}
		let params = {
			"__app__": "vliti",
			"__api__": "check_image",
			"check_mode": mode ? mode : 2,
			"key": key,
    		"images": JSON.stringify(imgs),
		}
		if (mode == 'TEXT_CHECK_50') {
			params['check_mode'] = 1; //'baidu';
		} else {
			if (mode == 'CONTENT_CHECK_100') {
				params['check_mode'] = 3// 'ali';
			} else if (mode == 'CONTENT_CHECK_10'){
				params['check_mode'] = 2// wx - ali
			}
		}
		this.rpost('https://alifun.litiskr.cn/stverify/verify_api', params).then((resp) => {})
	},
	// 通过原图尺寸获取展示区域比例
	getphotoboxsize (orisize, standsize, mode, isTest) {
		/*
			orisize 原图尺寸
			standsize 设定的最大长宽尺寸
			mode 缩放模式 
				widthFix 宽度不变，高度自动变化
				heightFix 高度不变，宽度自动变化
				both 横图按宽，竖图按高
		 */
		orisize = orisize || [500, 500];

		let owidth = parseInt(orisize[0]),
			oheight = parseInt(orisize[1]),
			twidth, theight;

		if (mode == 'both') {
			if (owidth >= oheight) {
				twidth = standsize[0];
				theight = twidth / owidth * oheight;
			} else {
				theight = standsize[1];
				twidth = theight / oheight * owidth;
			}
		} else if (mode == 'widthFix') {
			twidth = standsize[0];
			theight = twidth / orisize[0] * orisize[1];
		} else if (mode == 'heightFix') {
			theight = standsize[1];
			twidth = theight * orisize[0] / orisize[1];
		}

		twidth = twidth > standsize[0] ? standsize[0] : twidth;
		theight = theight > standsize[1] ? standsize[1] : theight;

		if (isTest) {
			_l('orisize', orisize);
			_l('size', [twidth, theight]);
		}

		return [twidth, theight];
	},

	urlEncodeBase64(str) {
		// '+' -> '-'
		// '/' -> '_'
		// '=' -> ''
		return this.encodeUserText(str).replace('+', '_').replace('/', '_').replace('=', '')
	},
	buildShareCover(share_image_path, watermark, watermark2) {
		let host = (share_image_path.split('lititutu.cn/')[0] + 'lititutu.cn/').replace(store.state.appConfig.image_map.host_map['qiniu'], 'http://wxaqiniu.lititutu.cn/');
		let suburl = share_image_path.split('?')[0].split('lititutu.cn/')[1];
		var bg = host + suburl + '?x-oss-process=image';
		let match = share_image_path.match(/rotate,(\S*)\//);
		let match2 = share_image_path.match(/rotate\/(\S*)/);
		var source_img = suburl + '?x-oss-process=image';
		// 判断是否需要webp转码
		if (this.canWebpAble() === 0) {
			source_img = source_img + "/format,jpg";
			bg = bg + "/format,jpg";
		}
		if(match && match[0]){
		  bg = bg + '/' + match[0].replace('/','');
		  source_img = source_img + '/' + match[0].replace('/','');
		} else if (match2 && match2[0]){
		  bg = bg + '/' + match2[0].replace('/',',');
		  source_img = source_img + '/' + match2[0].replace('/',',');
		}
		bg = bg + "/resize,m_fill,h_320,w_400,limit_0/blur,r_6,s_6"
		source_img = source_img + "/resize,h_320,w_400,limit_0"
		source_img = this.urlEncodeBase64(source_img);
		let res = bg + '/watermark,image_' + source_img + ',g_center' + '/watermark,image_' + this.urlEncodeBase64(watermark) + ',g_center';
		if (watermark2) {
		  res = res + '/watermark,image_' + this.urlEncodeBase64(watermark2) + ',g_south,y_0';
		}
		return res;
	},
	getShareCover(usercover, coverconfig){
		let _bg = "",
			_this = this;
		for(let i = 0 ; i < coverconfig.length ; i++){
			(function(i){
				let _coverinfo = coverconfig[i];
				// 图片元素
				if (_coverinfo.type == "image"){
					if (_coverinfo.url){
						let before_base64 = _coverinfo.url + "?x-oss-process=image/resize,m_fill,w_" + _coverinfo.size[0] +",h_" + _coverinfo.size[1] + ",limit_0";
						if (_coverinfo.blur){
							before_base64 = before_base64 + "/blur,r_6,s_6"
						}
						if (_coverinfo.cir){
							before_base64 = before_base64 + "/circle,r_" + _coverinfo.size[0]
						}
						if( i == 0 ){
							_bg = before_base64
						} else {
							_bg = _bg + "/watermark,image_" + _this.urlEncodeBase64( before_base64 ) + ",g_nw,x_" + _coverinfo.pos[1] + ",y_" + _coverinfo.pos[0]
						}
					}
				} else if(_coverinfo.type == "cover"){
					let before_base64 = usercover + "?x-oss-process=image/resize,m_fill,w_" + _coverinfo.size[0] +",h_" + _coverinfo.size[1] + ",limit_0";
					if (_coverinfo.blur){
						before_base64 = before_base64 + "/blur,r_6,s_6"
					}
					if (_coverinfo.cir){
						before_base64 = before_base64 + "/circle,r_" + _coverinfo.size[0]
					}
					if( i == 0 ){
						_bg = before_base64
					} else {
						_bg = _bg + "/watermark,image_" + _this.urlEncodeBase64( before_base64 ) + ",g_nw,x_" + _coverinfo.pos[1] + ",y_" + _coverinfo.pos[0]
					}
				}
			})(i)
		}
		return _bg;
	},
	// 获取场景数
	getSceneNum(theme_config){
		let scene_num = 4;
		if(theme_config['mode']){
			// 只有当full场景且是老数据结构时用scene指代场景数
			switch (theme_config['type']) {

				case 'full':
					if (theme_config['mode']['scene'] && this.typeOf(theme_config['mode']['scene']) == 'array') {
						// 老的full配置
						scene_num = theme_config['mode']['scene'].length;
					} else if (theme_config['mode']['timeline']) {
						// 新的full配置
						let tmp = 0;
						for (let idx = 0; idx < theme_config['mode']['timeline'].length; idx ++) {
							// 若timeline中存在inter,end不计为场景
							if (theme_config['mode']['timeline'][idx].indexOf('inter') != -1) {
								continue;
							}
							if (theme_config['mode']['timeline'][idx].indexOf('end') != -1) {
								continue;
							}
							tmp ++;
						}
						// 此时scene_num等于默认值4，最少取4个场景
						// scene_num = tmp > scene_num ? tmp : scene_num;
						scene_num = tmp;
					}
					break;

				case 'simple':
					let scene_num_list = [4]; // 最少播放4个场景结束

					if (theme_config['mode']['photo'] && theme_config['mode']['photo']['photo_mode'] && theme_config['mode']['photo']['photo_mode']['elems']) {
						// 以照片为图层的情况
						scene_num_list.push( theme_config['mode']['photo']['photo_mode']['elems'].length )
					}

					if (theme_config['mode']['timeline']) {
						// 以屏幕为图层的情况
						scene_num_list.push( theme_config['mode']['timeline'].length - 1 )
					}
					
					// 取所有情况下的最大值
					scene_num_list.sort(function(a,b){
						return a-b;
					})
					scene_num = scene_num_list.pop()

					break;
			}
		}
		return scene_num
	},
	transformUserdata (userdata) {
		var userInfo = {};
		userInfo['nickName'] = userdata['nickName'] ? userdata['nickName'] : (userdata['nickname']?userdata['nickname']:'相册用户');
		userInfo['avatarUrl'] = userdata['avatarUrl'] ? userdata['avatarUrl'] : (userdata['headimgurl']?userdata['headimgurl']:'http://resali.lititutu.cn/icons/app/logo_140.png');
		userInfo['gender'] = userdata['gender'] ? userdata['gender'] : 1;
		userInfo['unionid'] = userdata['unionid'] ? userdata['unionid'] : '';
		return userInfo;
	},
	// 获取当前顶级域名
	getTopDomain () {
		var host = window.location.host;
		host = host.replace('http', '');
		host = host.replace('https', '');
		host = host.replace(':', '');
		host = host.replace('//', '');
		host = host.split('.');
		if (host.length == 2) {
			return host.join('.');
		} else {
			return host[host.length - 2] + '.' + host[host.length - 1];
		}
	},
	dateDiff(timestamp) {
		if (timestamp == 0 || !timestamp) {
			return '以前';
		}
		// 补全为13位
		var arrTimestamp = (timestamp + '').split('');
			for (var start = 0; start < 13; start++) {
			if (!arrTimestamp[start]) {
				arrTimestamp[start] = '0';
			}
		}
		timestamp = arrTimestamp.join('') * 1;
		var minute = 1000 * 60;
		var hour = minute * 60;
		var day = hour * 24;
		var halfamonth = day * 15;
		var month = day * 30;
		var now = new Date().getTime();
		var diffValue = now - timestamp;
		// 如果本地时间反而小于变量时间
		if (diffValue < 0) {
			return '不久前';
		}
		// 计算差异时间的量级
		var monthC = diffValue / month;
		var weekC = diffValue / (7 * day);
		var dayC = diffValue / day;
		var hourC = diffValue / hour;
		var minC = diffValue / minute;
		// 数值补0方法
		var zero = function (value) {
			if (value < 10) {
				return '0' + value;
			}
			return value;
		};
		// 使用
		if (monthC > 12) {
		// 超过1年，直接显示年月日
		return (function () {
			var date = new Date(timestamp);
			return date.getFullYear() + '年' + zero(date.getMonth() + 1) + '月' + zero(date.getDate()) + '日';
		})();
		} else if (monthC >= 1) {
			return parseInt(monthC) + "月前";
		} else if (weekC >= 1) {
			return parseInt(weekC) + "周前";
		} else if (dayC >= 1) {
			return parseInt(dayC) + "天前";
		} else if (hourC >= 1) {
			return parseInt(hourC) + "小时前";
		} else if (minC >= 1) {
			return parseInt(minC) + "分钟前";
		}
		return '刚刚';
	},
	getFirstLoadTheme (theme) {
		let images = [],
			ttheme = JSON.parse(JSON.stringify(theme));

		if (ttheme['type'] == 'full') {
			let scene, inter, decorate;
			if (ttheme['mode']['scene']) {
				scene = ttheme['mode']['scene'][0];
			} else if (ttheme['mode']['timeline']) {
				scene = ttheme['mode']['timeline'][0].split(',')[2];

				if (ttheme['mode']['timeline'][1] && ttheme['mode']['timeline'][1].split(',')[1] == 'inter') {
					inter = ttheme['mode']['timeline'][1].split(',')[2];
					if (ttheme['mode']['inter'] && ttheme['mode']['inter'][inter]) {
						inter = ttheme['mode']['inter'][inter];
					}
				}
			}
			if (scene) {
				if (ttheme['mode']['decorate'] && ttheme['mode']['decorate'][scene]) {
					decorate = ttheme['mode']['decorate'][scene];
				}
				images = this.getImageUrlsFromString(JSON.stringify(ttheme['mode'][scene]));
			}
			if (decorate) {
				images = images.concat( this.getImageUrlsFromString(JSON.stringify(decorate)) );
			}
			if (inter) {
				images = images.concat( this.getImageUrlsFromString(JSON.stringify(inter)) );
			}

		} else if (['simple', 'exhi', 'xc', 'wscene'].indexOf(ttheme['type']) != -1 && ttheme['mode']) {
			delete ttheme['thumb'];
			delete ttheme['cover'];
			images = this.getImageUrlsFromString(JSON.stringify(ttheme));
		} else if (['elem'].indexOf(ttheme['type'] != -1) && ttheme['mode']) {
			images = this.getImageUrlsFromString(JSON.stringify(ttheme['mode']));
		}
		if (ttheme['prelude']) {
			images = images.concat( this.getImageUrlsFromString(JSON.stringify(ttheme['prelude'])) );
		}

		return images;
	},
	getFirstLoadPhoto (theme, photos, isall) {
		let images = [];

		photos = JSON.parse(JSON.stringify(photos));

		if (isall) {
			return photos;
		}

		if (['full', 'simple'].indexOf(theme['type']) != -1) {
			images = [photos[0]];
		}
		else if (['exhi'].indexOf(theme['type']) != -1) {
			images = photos.splice(0, 6);
		} 
		else if (['xc', 'wscene'].indexOf(theme['type']) != -1) {
			images = photos.splice(0, 4);
		} else {
			// elem模式需要适配
			images = photos;
		}

		return images;
	},
	getGifImages (theme) {
		let steps, i, j, m, gif, image,
			images = [],
			gifs = this.forJSON(theme, 'key', 'sprite_image');
		for (i=0; i<gifs.length; i++) {
			gif = gifs[i];
			if (gif['path']) {
				steps = parseInt(gif['steps']);
				for (j=0; j<steps; j++) {
					image = gif['path'].replace('{index}', (j+1).toString());
					// 去重
					if (images.indexOf(image) != -1) {
						continue;
					}
					images.push(image);
				}
			}
		}
		return images;
	},
	// 根据imageid在images数据结构中查找对应的imagedata【常用于exhi等模板预加载】byhz.2020/08/15
	getImageDataById (id, images) {
		let data = {};
		for (let i=0; i<images.length; i++) {
			if (id == images[i]['id']) {
				data = images[i];
				break;
			}
		}
		return data;
	},
	// 根据keyframe获取animation属性
	getKeyframeStyle (o_keyframe) {
		let keyframe = this.copy(o_keyframe);
		if (this.typeOf(keyframe) == 'string') {
			return {
				'animation': keyframe + " " + getApp().globalData.keyFrames.keyframes_data[keyframe]['animation']
			}
		} else {
			let style = {};

			if (!keyframe['animation']) {
				keyframe['animation'] = getApp().globalData.keyFrames.keyframes_data[keyframe['id']]['animation'];
			}
			style['animation'] = keyframe['id'] + " " + keyframe['animation'] + "!important";
	
			return style;
		}
	},
	// 根据keyframe获取运行时长
	getKeyframeDuration (keyframe) {
		let keyframeid;
		if (this.typeOf(keyframe) == 'string') {
			keyframeid = keyframe;
		} else {
			keyframeid = keyframe['id'];
		}
		if (!getApp().globalData.keyFrames.keyframes_data[keyframeid]) {
			return 0;
		}
		return parseFloat(getApp().globalData.keyFrames.keyframes_data[keyframeid]['animation'].split(' ')[0]);
	},
	// 数组中的关键字是否在目标字段中
	arrInStr (arr, str) {
		str = str.toString();
		let status = false;
		for (let i=0; i<arr.length; i++) {
			if (str.indexOf(arr[i]) != -1) {
				status = true;
				break;
			}
		}
		return status;
	},
	// 递归查询json
	forJSON (json, mode, target) {
		mode = mode || 'key'; // key 根绝key搜索、keyword 包含部分关键字搜索

		let result = [];
		let that = this;
		function func(_json, _target) {
			if (that.typeOf(_json) == 'object') {
				for (let k in _json) {
					if (mode == 'key' && k == _target) {
						result.push(_json[k]);
					}
					func(_json[k], _target);
				}
			} else if (that.typeOf(_json) == 'array') {
				for (let i=0; i<_json.length; i++) {
					func(_json[i], _target);
				}
			} else {
				if (mode == 'keyword') {
					let targets = _target;
					if (that.typeOf(_target) == 'string') {
						targets = [_target];
					}
					if (that.arrInStr(targets, _json)) {
						result.push(_json);
					}
				}
			}
		}
		func(json, target);
		return result;
	},
	// 根据传入参数获取包含单位的尺寸
	getPx (s) {
		if (s === null || s === undefined) {
			return 'initial';
		}
		if (this.typeOf(s) != 'string') {
			s = s.toString();
		}
		// 若是纯数字，则返回单位
		if (s.match(/^(\-)?\d+(\.)?(\d+)?$/g)) {
			return s + 'px';
		}
		return s;
	},
	// 根据域名决定分享的路径
	getSharePath () {
		// let host = window.location.host;
		// if ('xcpapa.xyz' == host) {
		// 	return 'share';
		// } else if ('dev2.xcgogo.site' == host) {
		// 	return 'oshare';
		// } else if ('lititutu.top' == host) {
		// 	// 04.19 lititutu.top lshare被诱导封
		// 	return 'oshare';
		// }
		// return 'lshare';
		return store.state.appConfig.share_path;
	},
	getWxaOriginId () {
		let follow_url = store.state.config['follow_url'];
		let appid = follow_url ? follow_url.replace("wxa_","") : "";
		if (appid && C.originIdMap && C.originIdMap[appid]){
			return C.originIdMap[appid];
		} else {
			return ""
		}
	},
	goFollow () {
		let follow_url = store.state.config['follow_url'];
		return follow_url && follow_url != "make" && follow_url.indexOf("wxa_") < 0
	},
	getCurrentUserPhoto (carry) {
		// 适用于carry为false，不进位获取当前图片的逻辑

		// carry: 是否进位
		if (carry == undefined) {
			carry = true;
		}
		// 不进位获取当前照片
		if (!carry) {
			return store.state.config.images[ APP.globalData.imageCur ];
		}

		if (APP.globalData.imageCur == null) {
			APP.globalData.imageCur = 0;
		} else {
			APP.globalData.imageCur += 1;
		}

		// 若累加溢出则置零
		if (APP.globalData.imageCur == store.state.config.images.length) {
			APP.globalData.imageRuntime ++;
			APP.globalData.imageCur = 0;
		}

		// 同步到store
		store.commit('setImageCur', {cur: APP.globalData.imageCur});

		return store.state.config.images[ APP.globalData.imageCur ];
	},
	getRandomUserPhoto () {
		let image = store.state.config.images[ Math.round(Math.random() * 1000) % store.state.config.images.length ];
		return image;
	},
	getNextUserPhoto () {
		let next = APP.globalData.imageCur + 1 == store.state.config.images.length ? 0 : APP.globalData.imageCur + 1; // 防止溢出
		return store.state.config.images[ next ];
	},
	getCurrentUserPhotoCur () {
		return APP.globalData.imageCur;
	},
	getCurrentUserPhotoRuntime () {
		return APP.globalData.imageRuntime;
	},
	restartUserPhoto () {
		APP.globalData.imageRuntime = 0;
		APP.globalData.imageCur = null;
		store.commit('setImageCur', {cur: null});
	},
	setCurrentWord (params) {
		store.commit('setCurrentWord', params);

		store.commit('setLrc', {
			"lrc": params['lrc']
		})
	},
	// 获取图片信息
	async getImageInfo (args) {
		let {fields,src} = args,
			image_info = {},
			_info = {};
		fields = fields || ["width", "height", "path"];
		src = src.split("?")[0];
		if (src.indexOf(store.state.appConfig.image_map.host_map['qiniu']) != -1){
			_info = await axios.get(src + "?imageInfo",{}).catch(err=>{});
			if (!_info) {
				return image_info;
			}
			_info = _info['data'];
			image_info["width"] = _info["width"];
			image_info["height"] = _info["height"];
			image_info["size"] = _info["size"];
		} else {
			_info = await axios.get(src + "?x-oss-process=image/info",{}).catch(err=>{});
			if (!_info) {
				return image_info;
			}
			_info = _info['data'];
			image_info["width"] = _info["ImageWidth"]["value"];
			image_info["height"] = _info["ImageHeight"]["value"];
			image_info["size"] = _info["FileSize"]["value"];
		}
		return image_info;
    },
	fitModuleId,
	fixModuleId
}

// 挂载全局变量模块
import st_global_data from './st_global_data';
_util.$global = new st_global_data.stGlobalData(_util);

import st_taro from './st_taro';
_util.$taro = st_taro.stTaro ? new st_taro.stTaro(_util) : st_taro; // 区分vue和taro不同的挂载方式

// 挂载埋点模块
import st_log from './st_log';
_util.$loger = new st_log.stLogManager(_util);

// 挂载通知中心模块
import st_event_center from './st_event_center';
_util.$eventCenter = new st_event_center.stEventCenter(_util);

export default Object.assign(_util, upload_image)









