<template>
    <div class="denglong_bg" :style="{backgroundImage: 'url('+mode[0]+')'}">
        <div class="perspe">
            <!-- <div class="denglong_box1">
                <div class="denglong_box denglong_boxAni">
                    <div class="denglong_front user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_back user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_left user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_right user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="denglong_box2">
                <div class="denglong_box denglong_boxAni">
                    <div class="denglong_front user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_back user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_left user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_right user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="denglong_box3">
                <div class="denglong_box denglong_boxAni">
                    <div class="denglong_front user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_back user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_left user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                    <div class="denglong_right user" :style="{backgroundImage: 'url('+currentImage['im']+')'}">
                        <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                        <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                        <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                        <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                        <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                    </div>
                </div>
            </div> -->
            <div class="denglong_box denglong_box4">
                <div class="denglong_front user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                    <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                    <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                    <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                    <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                </div>
                <div class="denglong_back user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                    <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                    <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                    <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                    <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                </div>
                <div class="denglong_left user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                    <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                    <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                    <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                    <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                </div>
                <div class="denglong_right user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
                    <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
                    <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
                    <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
                    <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
                    <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'denglong',
		props: ['mode', 'currentImage'],
		data () {
			return {}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.denglong_bgGif {
	    position: absolute;
	    top: 0;
	}
	.denglong_bg, .denglong_bgGif {
	    height: 100%;
	    width: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    background: no-repeat center;
	    background-size: cover;
	}
	.perspe {
	    perspective: @bw * 1.389;
	    width: 100%;
	    height: 100%;
	    position: absolute
	}
	.denglong_box {
	    width: @bw * 0.556;
	    height: @bw * 0.556;
	    position: absolute;
	    transform-style: preserve-3d;
	    left: 50%;
	    top: 50%;
	    margin-left: -@bw * 0.278;
	    margin-top: -@bw * 0.278;
	    transform: translateZ(-@bw * 6.944);
	}
	.denglong_box4 {
	    animation: denglong_rotateBox 12s linear infinite
	}
	.denglong_boxAni {
	    animation: denglong_rotateBox2 12s linear infinite
	}
	.denglong_box3 {
	    transform: translateZ(-@bw * 6.944);
	    left: -169%;
	    top: 181%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.denglong_box2 {
	    transform: translateZ(-@bw * 6.944);
	    right: -200%;
	    top: -11%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.denglong_box1 {
	    transform: translateZ(-@bw * 6.944);
	    left: -160%;
	    top: -109%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.denglong_front {
	    transform: rotateY(0deg) translateZ(@bw * 0.278);
	}
	.denglong_back {
	    transform: translateZ(-@bw * 0.278) rotateY(180deg);
	}
	.denglong_left {
	    transform: rotateY(90deg) translateZ(@bw * 0.278);
	}
	.denglong_right {
	    transform: rotateY(-90deg) translateZ(@bw * 0.278);
	}
	.denglong_top {
	    transform: rotateX(90deg) translateZ(@bw * 0.278);
	}
	.denglong_bot {
	    transform: rotateX(-90deg) translateZ(@bw * 0.278);
	}
	.user {
	    background: no-repeat center;
	    background-size: cover;
	    position: absolute;
	    width: @bw * 0.556;
	    height: @bw * 0.556
	}
	.user_border {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    background: no-repeat center;
	    background-size: cover
	}
	.user_border_left {
	    position: absolute;
	    width: @bw * 0.278;
	    height: @bw * 0.556;
	    left: -@bw * 0.278;
	    background: no-repeat right center;
	    background-size: auto 100%;
	}
	.user_border_right {
	    position: absolute;
	    width: @bw * 0.278;
	    height: @bw * 0.556;
	    right: -@bw * 0.278;
	    background: no-repeat left center;
	    background-size: auto 100%;
	}
	.user_border_top {
	    position: absolute;
	    width: @bw * 0.829;
	    height: @bw * 0.417;
	    left: 50%;
	    top: -@bw * 0.417;
	    transform: translate(-50%, 0%);
	    background: no-repeat bottom center;
	    background-size: 100% auto;
	}
	.user_border_bot {
	    position: absolute;
	    width: @bw * 0.829;
	    height: @bw * 0.417;
	    left: 50%;
	    bottom: -@bw * 0.417;
	    transform: translate(-50%, 0%);
	    background: no-repeat top center;
	    background-size: 100% auto;
	}
	@keyframes denglong_rotateBox {
	    0% {
	        transform: scale(0.1);
	    }
	    25% {
	        transform: rotateY(0deg) scale(1);
	    }
	    50% {
	        transform: rotateY(90deg);
	    }
	    75% {
	        transform: rotateY(180deg);
	    }
	    100% {
	        transform: rotateY(270deg)
	    }
	}
	@keyframes denglong_rotateBox2 {
	    0% {
	        transform: rotateY(0deg);
	    }
	    25% {
	        transform: rotateY(90deg);
	    }
	    50% {
	        transform: rotateY(180deg);
	    }
	    100% {
	        transform: rotateY(360deg)
	    }
	}
</style>