import axios from 'axios'
import qs from 'qs'
import st_request_base from './st_request.base'

class stRequest extends st_request_base.stRequestBase {
    constructor (util) {
        super(util)
    }
    rlog (field) {
        // let params = {
        //     'key': 'vliti',
        //     'field': field
        // };
        // let host = this.$util.$config.REQ_HOST,
        //     url = host + '/api/log';
        // axios.get(url, {
        //     params: params
        // })
    }
    _request (url, params, method, contentType) {
        return new Promise((resolve, reject) => {
            if ("GET" == method) {
                axios.get(url, {
                    params: params
                }).then(response => {
                    resolve(response.data);
                }).catch(err => {
                    reject(err);
                })
            } else if ("POST" == method) {
                axios.post(url, 
                    qs.stringify(params),
                    {
                        headers: {'Content-Type': contentType || 'application/x-www-form-urlencoded'}
                    }
                ).then(response => {
                    resolve(response.data);
                }).catch(err => {
                    reject(err);
                })
            }
        })
    }
    requestHandler (api, params, method, contentType) {
        // if (api == 'dconfig') this.rlog("taro_load_dconfig_axios_0");
        let url = this._findUrl(api);
        params = this._findParams(params);
        this._log({
            "step": "start",
            "api": api
        });
        let _this = this;
        return new Promise((resolve, reject) => {
            // if (api == 'dconfig') _this.rlog("taro_load_dconfig_axios_1");

            this._request(url, params, method, contentType).then(response => {
                if (response.errcode == 0) {
                    // if (api == 'dconfig') _this.rlog("taro_load_dconfig_axios_2");
                    resolve(response.data);
                    _this._log({
                        "step": "success",
                        "api": api
                    });
                } else {
                    // if (api == 'dconfig') _this.rlog("taro_load_dconfig_axios_3");
                    console.error(api, "请求失败=>", response);
                    reject(response.data);
                    _this._log({
                        "step": "error",
                        "api": api,
                        "err": response.data
                    });
                }
            }).catch(err => {
                // if (api == 'dconfig') _this.rlog("taro_load_dconfig_axios_4");
                console.error(api, "请求失败=>", err);
                reject(err);
                _this._log({
                    "step": "error",
                    "api": api,
                    "err": err
                });
            })
        })
    }
}

export default {
    stRequest   
}

// return new Promise((resolve, reject) => {
//     try {
//         if (api == 'dconfig') _this.rlog("taro_load_dconfig_request_1");
//         let request_params = {
//             url: url,
//             method: method,
//             data: params,
//             success (response) {
//                 if (api == 'dconfig') _this.rlog("taro_load_dconfig_request_1.5");
//                 let res = response.data;
//                 if (res && res['errcode'] == 0) {
//                     if (api == 'dconfig') _this.rlog("taro_load_dconfig_request_2");
//                     resolve(res['data']);
//                 } else {
//                     if (api == 'dconfig') _this.rlog("taro_load_dconfig_request_3");
//                     reject(res['data']);
//                 }
//             },
//             fail (err) {
//                 if (api == 'dconfig') _this.rlog("taro_load_dconfig_request_4");
//                 reject(err);
//             }
//         }
//         if (method == "POST") {
//             request_params['header'] = {
//                 'content-type': contentType || 'application/x-www-form-urlencoded'
//             }
//         }
//         Taro.request(request_params)
//     } catch(e) {
//         _this.rlog("taro_load_dconfig_request_error_1");
//     }
// })