<template>
    <div class="dong12 bg" :style="{backgroundImage: 'url('+ mode[0] +')'}">
        <div class="dong12_mask">
            <div class="dong12_rot">
                <div class="dong12_scl" :style="{animationName: getAniName()}">
                    <div @click="wxPreview" class="dong12_blur" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                    <div @click="wxPreview" class="dong12_div" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'dong12',
        props: ['mode', 'currentImage'],
        data () {
            return {}
        },
        methods: {
            getAniName () {
                let anis = ['dong12_ani', 'dong12_ani_reverse'];
                return anis[parseInt(Math.random()*100) % anis.length];
            },
            wxPreview () {
                this.$emit('wxPreview', this.currentImage);
            }
        }
    }
</script>

<style>
    .dong12 {
        position: absolute;
        width: 600px;
        height: calc(100% + 21px);
        left: -50px;
    }
    .dong12_mask {
        width: 100%;
        height: 100%;
        -webkit-mask-image: url("http://resali.lititutu.cn/tuku/td/4b0d8d0c324f232973311b11a98f821b.png");
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        animation: fadeIn 3s both;
    }
    .dong12_rot {
        animation: 8000ms linear 0s 1 normal both running div1_rot;
        width: 100%;
        height: 100%;
    }
    @keyframes div1_rot {
        0% {
            transform: rotateZ(-2deg);
        }
        100% {
            transform: rotateZ(2deg);
        }
    }
    .dong12_scl {
        position: relative;
        width: 100%;
        height: 100%;
        -webkit-transform-origin: 50% 0%;
        animation: 8000ms linear 0s 1 normal both running;
    }
    @keyframes dong12_ani {
        0% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes dong12_ani_reverse {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
        }
    }
    .dong12_blur {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 10%;
        background-repeat: no-repeat;
        -webkit-transform: translateZ(0) scale(1.1);
        -webkit-filter: blur(10px);
    }
    .dong12_div {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: 100%;
        /*background-position: center center;*/
        background-position: 50% 30%;
        background-repeat: no-repeat;
    }
</style>