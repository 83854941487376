<template>
	<div id="model" ref="model">
		<div class="simple3d-bg" :style="{backgroundImage: 'url('+mode['mode']['bg']['im']+')'}"></div>
		<div id="room" class="room" @touchstart="down" @touchmove="move" @touchend="up">
			<!-- <span style="position: fixed;color: white;font-size:@bw * 0.069;font-weight: bold;z-index: 9999999999;width: 100%;text-align: center;top:@bw * 0.069;">{{imageIdx}}</span> -->
			<div class="stage" :style="{ transform: 'rotateY('+rotateDeg+'deg)' }">
				<div v-for="(image, index) in timages" class="item" :style="{transform: getItemTransform(index)}">
					<div class="photo-box" :style="{width: box[0]+'px', height: box[1]+'px'}" @click="wxPreview(image)">
						<transition name="simple3d-images">
							<!-- <div ref="photo_frame" class="photo-frame" :class="{photo_frame_border: !mode['border']}" :style="getPhotoFrameStyle(index)"> -->
							<div v-show="imageShow" class="photo-frame" :class="{photo_frame_border: !mode['border']}" :style="getPhotoFrameStyle(index)">
								<img class="photo-border" :src="mode['border']" v-if="mode['border']">
								<span v-if="image['tx']">{{image['tx']}}</span>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>

		<!-- decorate -->
		<template v-if="mode['mode']['decorate']">
			<template v-if="mode['mode']['decorate']['gif']">
				<template v-for="item in mode['mode']['decorate']['gif']">
					<Gif :mode="item"></Gif>
				</template>
			</template>
		</template>

		<!-- elems -->
		<template v-if="mode['mode']['elems']">
			<div class="elems" v-for="item in mode['mode']['elems']" :style="getElemsStyle(item)">
				<img :src="item['im']">
			</div>
		</template>

		<!-- 开场动画 -->
		<Prelude :mode="prelude" @preluded="preluded"></Prelude>
	</div>
</template>

<script>
	/* eslint-disable */
	// import U from '@/util'
	// import C from '@/config'
	import Prelude from '@/components/Prelude'

	const prelude_duration = 3; // 默认开场

	let G = {
		// drag: false,
		// speed: .5,
		// r: 0,
		// brake: 1,
		// o: null,
		// x: null,
		// time: null,
		// imageIdx: 0,
		imagesIndent: 6,
		images: []
	}

	export default {
		name: 'simple3d',
		data () {
			return {
				box: [278, 378],
				rotateDeg: 0,
				imageShow: true,
				timages: [],

				drag: false,
				speed: .5,
				r: 0,
				brake: 1,
				o: null,
				x: null,
				time: null,

				imageIdx: 0
			}
		},
		props: ['mode'],
		components: {
			Prelude,
		},
		computed: {
			prelude () {
				let prelude = Boolean(this.mode['prelude']) ? this.mode['prelude'] : false;
				if (!prelude) {
					prelude = {
						'type': 'default',
						'duration': prelude_duration
					}
				}
				if (this.mode['is_prelude'] === false) {
					prelude = {
						'type': 'none',
						'duration': 0
					}
				} else {
					if (!prelude['textcolor']) {
						prelude['textcolor'] = '#066f06';
					}
				}
				prelude['title'] = this.$store.state.config['data']['ti'] || this.mode['name'];
				return prelude;
			},
		},
		created () {
			G.images = this.$o_util.getImagesArray(this.$store.state.config.images, G.imagesIndent);
			
			this.imageIdx = G.images.length * 1000000000;

			[this.timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);
		},
		mounted () {
			this.$refs.model.addEventListener('touchmove', function(e) {
				e.preventDefault();
			})
			
			// this.spin();
		},
		methods: {
			getItemTransform (idx) {
				let angle = idx * ( 360 / G.imagesIndent );
				let distance = 500;
				return 'rotateY(' + angle + 'deg) translateZ(' + (distance) + 'px)';
			},
			getPhotoFrameStyle (idx) {
				let image = this.timages[idx];
				let size = image['orisize'];

				try {
					if (size[0] >= size[1]) {
						return {
							width: this.box[0] + 'px',
							height: (( this.box[0] / size[0] ) * size[1] ) + 'px',
							backgroundImage: 'url('+ image['im'] +')'
						}
					} else {
						return {
							width: (( this.box[0] / size[1] ) * size[0]) + 'px',
							height: this.box[1] + 'px',
							backgroundImage: 'url('+ image['im'] +')'
						}
					}
				} catch (e) {
					console.warn('error in reflash: ', e);
				}
			},
			getElemsStyle (item) {
				let style = {};
				if (item['style']) {
					style = item['style'];
				}
				style['width'] = item['size'][0]+'px';
				style['height']= item['size'][1]+'px';
				style['top'] = (item['pos'][0] == null ? 'initial' : item['pos'][0]+'px');
				style['right'] = (item['pos'][1] == null ? 'initial' : item['pos'][1]+'px');
				style['bottom'] = (item['pos'][2] == null ? 'initial' : item['pos'][2]+'px');
				style['left'] = (item['pos'][3] == null ? 'initial' : item['pos'][3]+'px');
				return style;
			},
			wxPreview (image) {
				this.$emit('wxPreview', image);
			},
			spin () {
				if ( !this.drag ) {

					if ( this.r * 180 / 400 < -360 || this.r * 180 / 400 > 360 ) {
						this.r = 0;
						if (G.images.length > 1) {
							this.imageShow = false;
							setTimeout(() => {
								let timages = [];
								[timages, this.imageIdx] = this.$o_util.getViewImages(G.images, this.imageIdx, this.speed);

								for (let j=0,jlens=timages.length; j<jlens; j++) {
									this.$set(this.timages, j, timages[j]);
								}
								setTimeout(() => {
									this.imageShow = true;
								}, 200)
							}, 500);
						}
					}

					if ( this.speed < .5 && this.speed > 0 ) {
						this.speed = .5;
					} else if ( this.speed > -.5 && this.speed < 0 ) {
						this.speed = -.5;
					}

					this.r += this.speed;
					this.speed /= this.brake;
					
					let ry = 0 - (this.r * 180 / 400);

					this.rotateDeg = ry;
				}
				requestAnimationFrame(this.spin);
			},
			down (event) {
				this.o = this.r;
				this.x = this.r + event.touches[0].clientX;
				this.drag = true;
				this.time = new Date();
			},
			move (event) {
				if (this.drag) {
					let ry = 0 - (this.r * 180 / 400);
					this.r = this.x - event.touches[0].clientX;
					this.rotateDeg = ry;
				}
			},
			up (event) {
				if (this.drag) {
					let time = new Date() - this.time;
					let path = this.r - this.o;
					this.speed = path / time * 5;
					this.brake = 1.01;
					this.drag = false;
				}
			},
			preluded () {
				this.spin();
			}
		}
	}
</script>

<style scoped lang="less">
	.simple3d-bg {
		position: absolute;;
		width: 100%;
		height: 100%;
		top: 0; left: 0;
		z-index: 1;
		background-position: center;
		background-size: cover;
	}
	.room {
		position: absolute;
		width: 100%;
		height: 100%;
		perspective-origin: 50% 50%;
		perspective:@bw * 3.056;
		overflow: hidden;
		/*background-color: #891224;*/
		/*background-color: #3e1219;*/
		// background-color: #575650;
		z-index: 2;
	}
	.stage {
		position: absolute;
		width:@bw * 0.583;
		height: 100vh;
		top: 50%;
		left: 50%;
		margin-top: -50vh;
		margin-left: -@bw * 0.292;
		transform-style: preserve-3d;
	}
	.item {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-size: 100% 100%;
		background-position: center;
		background-repeat: no-repeat;
	}
	.elems {
		position: absolute;
		z-index: 2;
		img {
			width: 100%;
		}
	}
	.photo-box {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		/*border: dashed@bw * 0.004 red;*/
	}
	.photo-frame {
		position: relative;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		transition: opacity .5s linear;
		span {
			position: absolute;
			width: 100%;
			left: 0;
			right: 0;
			text-align: center;
			margin: auto;
			bottom:@bw * 0.021;
			color: white;
			font-size:@bw * 0.042;
			text-shadow:@bw * 0.003@bw * 0.003@bw * 0.014 black;
			font-family: '\9ED1\4F53';
			font-weight: bold;
		}
	}
	.photo-frame.photo_frame_border {
		border: #fff@bw * 0.008 solid;
		box-shadow:@bw * 0.0@bw * 0.014@bw * 0.021 rgba(0,0,0,.7);
	}
	.photo-border {
		position: absolute;
		width: 110%;
		height: 110%;
		left: -5%;
		top: -5%;
	}

	.simple3d-images-leave {
		opacity: 1;
	}
	.simple3d-images-leave-to {
		opacity: 0;
	}

	.simple3d-images-enter {
		opacity: 0;
	}
	.simple3d-images-enter-to {
		opacity: 1;
	}
</style>