<template>
    <div class="zimu3_con po" :style="getStyle">
        <template v-if="getZimuItems && getZimuItems.length > 0">
            <elem
                v-for="(zimu, index) in getZimuItems"
                :key="'zimu3_' + index"
                :class="'zimu'+ index%5"
                :style="getZimuItemStyle(index)"
                class="zimu3-elem-item"
                :mode="zimu"
                :currentImage="currentImage"
                :scene="scene"
                :line="line">
            </elem>
        </template>

        <template v-else>
            <span
                v-for="(zimu, index) in tx"
                :key="'zimu3_' + index"
                :class="'zimu'+ index%5"
                :style="getZimuItemStyle(index)"
                class="zimu3-item">
                {{zimu}}
                <elem v-if="mode.itemElems"
                    :mode="getItemElem(index)"
                    :currentImage="currentImage"
                    :scene="scene"
                    :line="line"></elem>
            </span>
        </template>
        <slot></slot>
    </div>
</template>

<script>
	import BaseElem from "@/components/st_comps/st_comp/comps/StElem/BaseElem"
	export default {
        name: 'zimu3',
        extends: BaseElem,
		data () {
			return {
                tx: [],
            }
        },
        mounted () {
            let txs = this.mode && this.mode['tx'] ? this.mode['tx'] : [];
            
            // 适配组件库
            if (this.comp_config && this.comp_config[0]) {
                if (this.$util.typeOf( this.comp_config[0] ) == "string") {
                    txs = [ this.comp_config[0] ];
                } else if (this.$util.typeOf( this.comp_config[0] ) == "array"){
                    txs = this.comp_config[0];
                }
            }

            let _state = this.$store.state;
            for (let i=0; i<txs.length; i++) {
                let _tx = this.$util.replaceKeyWords(txs[i], "author", _state)
                this.tx.push(_tx)
            }
        },
        computed: {
            getStyle(){
                let s = {};
                if (this.$store.state.hasUsertext
                    && this.$store.state.hasUsertext != 0
                    && !this.mode['withtext']
                    && (!this.comp_config || !this.comp_config[4])){
                    s = {
                        "display": "none"
                    }
                    return s;
                }

                // 适配新版
                // 绑定动画
                if (this.comp_config && this.comp_config[2]) {
                    s = Object.assign(s, this.getKeyframeStyle(this.comp_config[2]));
                }

                s = Object.assign(s, this.getStyles)

                return s;
            },
            getZimuItems () {
                if (this.comp && this.comp['config'] && this.comp['config'][1] && this.$util.typeOf(this.comp['config'][1]) == 'array') {
                    return this.comp['config'][1];
                } else {
                    return [];
                }
            }
        },
        methods:{
            getZimuItemStyle (index) {
                let item = this.mode,
                    s = {};

                // 老版本适配
                if (item) {
                    if (item['bg']){
                        s['background'] = item['bg'][index%item['bg'].length]
                    }
                    if (item['itemkeyframe']) {
                        s = Object.assign(s, this.$util.getKeyframeStyle(item['itemkeyframe']));
                    }
                    if (item['itemStyle']) {
                        s = Object.assign(s, item['itemStyle']);
                    }
                }

                // 设置style
                if (this.comp_config && this.comp_config[3]) {
                    s = Object.assign(s, this.comp_config[3]);
                }
                return s;
            },
            getItemElem (index) {
                if (this.getZimuItems && this.getZimuItems[index]) {
                    return this.getZimuItems[index];
                }
                return this.mode.itemElems[ index %  this.mode.itemElems.length ];
            },
        },
	}
</script>

<style>
.zimu3_con{
    white-space: nowrap;
    height: 300px;
}
.zimu3-item,.zimu3-elem-item {
    position: relative;
    display: inline-block !important;
}
.zimu3_con .zimu3-item{
    color: #fff;
    font-size: 36px;
    line-height: 46px;
    border-radius: 30px;
    padding: 10px 20px;
    text-align: center;
    font-weight: 450;
    text-shadow: #e2e2e2;
}
.zimu3_con .zimu0{
    background: linear-gradient(to right,#ff0844,#ffb199);
    margin: 0px 60px;
    vertical-align: top;
    transform: scale(1.02);
}
.zimu3_con .zimu1{
    background: linear-gradient(to right,#f9d423,#ff4e50);
    margin: 10px 30px;
    vertical-align: bottom;
    transform: scale(.75);
}
.zimu3_con .zimu2{
    background: linear-gradient(to right,#9F38C1,#F86493);
    margin: 40px 50px;
    vertical-align: top;
    transform: scale(.92);
}
.zimu3_con .zimu3{
    background: linear-gradient(to right,#fa709a,#fee140);
    margin: 20px 70px;
    vertical-align: bottom;
    transform: scale(.86);
}
.zimu3_con .zimu4{
    background: linear-gradient(to right,#ff9800,#f94eff);
    margin: 0 40px;
    vertical-align: center;
    transform: scale(.82);
}
</style>