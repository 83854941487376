class stLoginBase {
    constructor (util) {
        this.util = util;
        // 登录的appid openid
        let user = this.util.getStorageSync("userInfo");
        if (user && user['appid'] && user['openid']) {
            this.appid = user['appid'];
            this.openid = user['openid'];
        } else {
            this.appid = util.$config['__APPID__'];
            this.openid = '';
        }
        // 是否已从ucenter检查过用户信息标识符
        this.has_check_ucenter = false;
    }
    get user() {
        return this.util.getStorageSync("userInfo", {});
    }
    get login_sign () {
        if (this.appid && this.openid) {
            return this.appid + "_" + this.openid;
        }
        return "";
    }
    /**
     * 从缓存、页面参数中获取用户信息
     */
    getBaseInfo () {
        // 从缓存中获取用户信息
        let user = this.util.getStorageSync("userInfo", {});
        // 环境参数中有login_sign直接赋值appid与openid
        let login_sign = this.util.getUrlQuery('login_sign');
        if (login_sign) {
            let appid = login_sign.split('_',1)[0],
                openid = login_sign.replace(appid + "_", "");
            if (appid && openid){
                this.appid = appid;
                this.openid = openid;
            }
            // 以login_sign的appid与openid为准
            user = Object.assign(user, {
                "appid": appid,
                "openid": openid
            })
        }
        if (user && user.appid && user.openid) {
            this.setUserInfo(user); // 防止第一次加载时，只从缓存拿，未设置到$store
        }
        return user;
    }
    /**
     * 获取用户信息
     */
    async getUserInfo () {
        let userinfo = this.getBaseInfo()

        // 尝试从服务端拿取
        if (userinfo 
            && this.openid
            && (!userinfo['nickname'] || !userinfo['token'] ) 
            && !this.has_check_ucenter) {
            userinfo = await this.util.rget("ucenter/get_user", {
                "openid": this.openid,
                "appid": this.appid
            }).catch(err=>{})
            userinfo = this.util.transformUserdata(userinfo);
            this.setUserInfo(userinfo);
            this.has_check_ucenter = true;
        }
        return userinfo;
	}
    setUserInfo (userinfo) {
        if (!userinfo['appid']) { // 补全设置appid
            userinfo['appid'] = this.appid;
        }

        // 合并
        let o_userinfo = this.util.getStorageSync("userInfo");
        userinfo = o_userinfo ? Object.assign(o_userinfo, userinfo) : userinfo;

        // $login.openid
        if (this.openid != userinfo.openid) {
            this.appid = userinfo.appid;
            this.openid = userinfo.openid;
            // 记录用户访问应用
            this.util.rpost("/ucenter/log",{
                "app": this.util.$config['__APP__'],
                "appid": this.appid,
                "openid": this.openid,
                "event_type": "visit"
            })
        }

        // 设置埋点中openid
        if (userinfo.openid) { 
            this.util.$loger.setLogId(userinfo.openid)
        }

        // state.userinfo
        this.util.$store.commit('setUserInfo', userinfo);

        // setStorage
        this.util.setStorageSync("userInfo", userinfo);
    }

    // 传入用户的openid/unionid判断openid与当前登录用户是否同一人
    checkIsUser (options) {
        let {
            openid,
            unionid
        } = options || {};
        if (!openid || !this.user) {
            return false;
        }
        // console.log("options==>",options)
        // console.log("this.user==>",this.user)
        // console.log("this.user.unionid==>",this.user.unionid)
        // console.log("unionid==>",unionid)
        // 判断是否同一openid
        if (this.openid == openid) {
            return true;
        }
        // 判断是否同一unionid
        let is_user = Boolean( unionid && this.user.unionid && (this.user.unionid == unionid) );
        // console.log("is_user==>",is_user);
        return is_user;
    }
}

export default {
    stLoginBase
};