<template>
	<div class="page pagehiden">
        <div class="blurImage" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
        <div class="page scaleRound">
            <div class="dong1-im" :class="{animated: animated}" :style="imageStyle" @click="wxPreview"></div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'dong1',
		props: ['mode', 'currentImage'],
		data () {
			return {
				imageStyle: {
					width: '0px',
					height: '0px',
					top: '50%',
					marginTop: '0px',
					left: '0px',
					backgroundImage: 'url()'
				},
				animated: false
			}
		},
		watch: {
			currentImage () {
				this.analyseImage();
			},
		},
		mounted () {
			this.analyseImage();
		},
		methods: {
			start (scenedx, idx) {
				if (idx) {
					this.animated = true;
				}
			},
			end () {
				setTimeout(() => {
					this.animated = false;
				}, 1000);
			},
			preluded () {
				this.animated = true;
			},
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			},
			analyseImage () {
				let twidth = this.$env.bw,
					theight = this.$env.bw * 1.25;
				this.imageStyle['width'] = twidth + 'px';
				this.imageStyle['height'] = theight.toString() + 'px';
				this.imageStyle['marginTop'] = -(theight/2) + 'px';
				this.imageStyle['backgroundImage'] = 'url('+ this.currentImage['im'] +')';
			}
		}
	}
</script>

<style scoped lang="less">
	.page > * {
	    position: absolute;
	    overflow: hidden;
	}
	.page {
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    position: absolute;
	}
	.scaleRound {
	    -webkit-animation: scaleRound 1.5s ease-in-out infinite alternate;
	}
	@keyframes scaleRound {
		0% {
		    transform: scale3d(1,1,1);
		}
		100% {
		    transform: scale3d(1.02,1.02,1.02);
		    /*transform: scale3d(1.02,1.02,1.02);*/
		}
	}
	.animated {
		animation: 1.5s linear .3s 1 normal both running div1_clip;
	}
	.dong1-im {
		opacity: 0;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	@keyframes div1_clip {
		0% {
			opacity: 0;
		    -webkit-clip-path: polygon( 50% 50%, 50% 50%, 50% 50%, 50% 50%);
		}
		5% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		    -webkit-clip-path: polygon( 0% 0%, 0% 100%, 100% 100%, 100% 0%);
		}
	}
</style>