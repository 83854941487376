<template>
	<div class="huaquanzhuan poc">
		<!-- <div class="poc bg huaquanzhuan-bg" :style="{backgroundImage: 'url('+mode[0]+')'}"></div> -->
		<div class="hqz-cont-1">
			<div class="rim-cont">
				<div class="rim-box">
					<div class="rim-photo rim-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div :style="{borderImageSource: 'url('+(mode.length > 1 ? mode[2] : 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png')+')'}" class="rim rim-kuang rim-kuang-1"></div>
		            <div :style="{borderImageSource: 'url('+(mode.length > 1 ? mode[2] : 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png')+')'}" class="rim rim-item rim-2"></div>
		            <div :style="{borderImageSource: 'url('+(mode.length > 1 ? mode[2] : 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png')+')'}" class="rim rim-item rim-3"></div>
		            <div :style="{borderImageSource: 'url('+(mode.length > 1 ? mode[2] : 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png')+')'}" class="rim rim-item rim-4"></div>
		            <div :style="{borderImageSource: 'url('+(mode.length > 1 ? mode[2] : 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png')+')'}" class="rim rim-item rim-5"></div>
		            <div :style="{borderImageSource: 'url('+(mode.length > 1 ? mode[2] : 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png')+')'}" class="rim rim-item rim-6"></div>
		            <div class="rim-photo rim-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div :style="{borderImageSource: 'url('+(mode.length > 1 ? mode[2] : 'http://resali.lititutu.cn/icons/huahuan/fangkuangcheng.png')+')'}" class="rim rim-kuang rim-kuang-2"></div>
				</div>
			</div>
		</div>
		<div class="hqz-cont-2">
			<div class="bot-cont">
				<div class="bot-item bot-item-1">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
				<div class="bot-item bot-item-2">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
				<div class="bot-item bot-item-3">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
				<div class="bot-item bot-item-4">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
		        <div class="bot-item bot-item-5">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
		        <div class="bot-item bot-item-6">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
		        <div class="bot-item bot-item-7">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
		        <div class="bot-item bot-item-8">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
		        <div class="bot-item bot-item-9">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
		        <div class="bot-item bot-item-10">
		            <div class="bot-photo-1" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <div class="bot-photo-2" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview"></div>
		            <img :src="mode.length > 1 ? mode[1] : 'http://resali.lititutu.cn/icons/huahuan/06.png'" :class="{'bot-kuang': mode.length == 1, 'bot-kuang-1': mode.length > 1}">
		        </div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'huaquanzhuan',
		props: ['mode', 'currentImage'],
		data () {
			return {

			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style scoped lang="less">
	.huaquanzhuan-bg {
		animation: mscale 12s both;
		z-index: -1;
	}
	.hqz-cont-1 {
		-webkit-perspective: 1600px;
    	transform: translateY(60px);
	}
	.hqz-cont-2 {
		-webkit-perspective: 720px;
    	transform: translateY(60px);
	}
	.rim-cont {
		transform-style: preserve-3d;
		animation: huaquanzhuan_zhuan 6s linear infinite both;
		margin-top: 70px;
	}
	.bot-cont {
		transform-style: preserve-3d;
		transform-origin: 50% 50%;
		animation: huaquanzhuan_zhuan 12s linear infinite both;
		margin-top: 600px;
		// margin-top: 660px;
	}
	@keyframes huaquanzhuan_zhuan {
		0% {
		    transform: rotateX(-10deg) rotateY(90deg);
		}
		100% {
		    transform: rotateX(-10deg) rotateY(-270deg);
		}
	}
	.bot-item-1 {
	    -webkit-transform: rotateY(0deg) translateZ(250px);
	}
	.bot-item-2 {
	    -webkit-transform: rotateY(36deg) translateZ(250px);
	}
	.bot-item-3 {
	    -webkit-transform: rotateY(72deg) translateZ(250px);
	}
	.bot-item-4 {
	    -webkit-transform: rotateY(108deg) translateZ(250px);
	}
	.bot-item-5 {
		-webkit-transform: rotateY(144deg) translateZ(250px);
	}
	.bot-item-6 {
		-webkit-transform: rotateY(180deg) translateZ(250px);
	}
	.bot-item-7 {
	    -webkit-transform: rotateY(216deg) translateZ(250px);
	}
	.bot-item-8 {
		-webkit-transform: rotateY(252deg) translateZ(250px);
	}
	.bot-item-9 {
		-webkit-transform: rotateY(288deg) translateZ(250px);
	}
	.bot-item-10 {
		-webkit-transform: rotateY(324deg) translateZ(250px);
	}

	.preserve3d {
		transform-style: preserve-3d;
	}
	.bot-container {
		position: absolute;
		width: 500px;
		height: 50%;
		left: 0;
		right: 0;
		top: 40%;
		bottom: 0;
		margin: auto;
		-webkit-perspective: 720px;
		-webkit-perspective-origin: 50% 50%;
	}
	.bot-con {
		position: absolute;
		animation: page1_1 1.5s linear infinite both;
		transform: rotateX(-12deg) rotateY(16deg);
		width: 100%;
		height: 100%;
	}
	.bot-item {
		position: absolute;
		width: 110px;
		height: 110px;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	.bot-photo-1 {
		position: absolute;
		width: 100%;
    	height: 100%;
		-webkit-mask-image: url('http://resali.lititutu.cn/icons/mask/yuan.png');
		-webkit-mask-size: 100% 100%;
		background-size: cover;
		background-position: center;
		animation: 0.8s linear 0s 1 normal both running fadeOut;
	}
	.bot-photo-2 {
		position: absolute;
		width: 100%;
    	height: 100%;
		-webkit-mask-image: url('http://resali.lititutu.cn/icons/mask/yuan.png');
		-webkit-mask-size: 100% 100%;
		background-size: cover;
		background-position: center;
		animation: 0.8s linear 0s 1 normal both running fadeIn;
	}
	.bot-kuang {
		position: absolute;
		width: 120%;
		top: -10%;
		left: -10%;
	}
	.bot-kuang-1 {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0; left: 0;
	}
	@keyframes page1_1 {
		0% {
		    transform: rotateX(-12deg) rotateY(16deg);
		}
		100% {
		    transform: rotateX(-12deg) rotateY(-20deg);
		}
	}
	.rim-container {
	    position: absolute;
		width: 100%;
    	height: 100%;
		-webkit-perspective: 1600px;
		-webkit-perspective-origin: 50% 50%;
	}
	.rim-con {
		position: absolute;
		width: 430px;
		height: 63%;
		margin: auto;
		top: 0;
		bottom: 25%;
		left: 0;
		right: 0;
		-webkit-animation: model_ani_1 6s linear infinite both;
	}
	@keyframes model_ani_1 {
		0% {
		    transform: rotateY(90deg);
		}
		100% {
		    transform: rotateY(-270deg);
		}
	}
	.rim-box {
		position: absolute;
		width: 300px;
		height: 300px;
		// top: 50%;
		left: 50%;
		// margin-top: -200px;
		margin-left: -200px;
	}
	.rim-photo {
		position: absolute;
		width: calc(100% + 50px);
		height: calc(100% + 50px);
		top: 25px;
		left: 25px;
		background-size: cover;
		background-position: center;
	}
	.rim-photo-1 {
		-webkit-transform: translateZ(12px);
	}
	.rim-photo-2 {
		-webkit-transform: translateZ(-12px);
	}
	.rim {
		position: absolute;
		width: 100%;
		height: 100%;
		border-style: solid;
		border-width: 50px;
		// border-image-slice: 45;
		// border-image-repeat: round;
	}
	.rim-item {
		// border-image: url('http://resali.lititutu.cn/icons/border/kuangvh.png');
		border-image-repeat: round;
		border-image-slice: 45;
	}
	.rim-kuang {
		// border-image: url('http://resali.lititutu.cn/icons/border/kuangvh.png');
		border-image-repeat: round;
		border-image-slice: 45;
	}
	.rim-kuang-1 {
		-webkit-transform: translateZ(16px);
	}
	.rim-kuang-2 {
	    -webkit-transform: translateZ(-16px);
	}
	.rim-2 {
		-webkit-transform: translateZ(8px);
	}
	.rim-3 {
		-webkit-transform: translateZ(4px);
	}
	.rim-4 {
		-webkit-transform: translateZ(0px);
	}
	.rim-5 {
		-webkit-transform: translateZ(-4px);
	}
	.rim-6 {
		-webkit-transform: translateZ(-8px);
	}
</style>





