<template>
	<div class="BX_AEComposition">
		<div class="BX_Class1 BX_BG1 BX_Ani2" :style="{backgroundImage: 'url('+mode[0]+')'}"></div>
	</div>
</template>

<script>
	export default {
		name: 'dahonghuadown',
        props: ['mode'],
		data () {
			return {}
		}
	}
</script>

<style scoped lang="less">
	 .BX_AEComposition {
	    position: absolute;
	    top: 0;
	    left: 0;
	    background: transparent;
	    width: 500px;
	    height: 100%;
	    display: block;
	    overflow: hidden;
	    transform: translate3d(0, 0, 0) scale(1, 1);
	    transform-origin:50% 0%;
	    z-index: 9;
	    pointer-events: none;
	}
	.BX_Class1 {
	    left: 100.00px;
	    top: 995.50px;
	    width: 300px;
	    height: 273px;
	    transform-style: preserve-3d;
	    transform: translate3d(0, 0, 0.00px) rotate(0.00deg) scale(2.87, 2.87);
	    opacity: 1.00;
	    transform-origin:50.00% 50.00%;
	    display: block;
	    position: absolute;
	}
	.BX_BG1 {
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	.BX_Ani2 {
	    animation: BX_AniKey2 2s linear 0.00s 1 normal both;
	}
	@keyframes BX_AniKey2 {
	    0.00% {
	    	opacity: 0;
	        transform: translate3d(8.00px, -1520.00px, 0.00px) rotate(90.00deg) scale(2.87, 2.87);
	    }
	    10% {
	    	opacity: 1;
	    }
	    90% {
	    	opacity: 1;
	    }
	    
	    100.00% {
	        transform: translate3d(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(2.87, 2.87);
	        opacity: 0;
	    }
	}
</style>