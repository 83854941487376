<template>
	<div class="dongtai_container">
	    <div class="page pagehiden" id="page1" style="animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div id="page_blurImage1" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_20960356344752?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div1" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_20960356344752?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px;"></div>
	        </div>
	        <div id="word1" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page2" style="animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div id="page_blurImage2" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857593525?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div2" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857593525?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 751.174px; left: 0px; top: 17.9131px; background-size: 500px 751.174px;"></div>
	        </div>
	        <div id="word2" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page3" style="animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div id="page_blurImage3" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857655321?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div3" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857655321?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 541.455px; left: 0px; top: 122.772px; background-size: 500px 541.455px;"></div>
	        </div>
	        <div id="word3" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page4" style="animation: 1s linear 0.7s 1 normal both running fadeIn;">
	        <div id="page_blurImage4" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857745604?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div41" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857745604?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px; animation: 1s linear 1.2s 1 normal both running div41_clip;"></div>
	            <div id="div42" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857745604?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px; animation: 1s linear 1.2s 1 normal both running div42_clip;"></div>
	            <div id="div43" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857745604?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px; animation: 1s linear 1.2s 1 normal both running div43_clip;"></div>
	            <div id="div44" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857745604?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px; animation: 1s linear 1.2s 1 normal both running div44_clip;"></div>
	            <div id="div45" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857745604?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px; animation: 1s linear 1.2s 1 normal both running div45_clip;"></div>
	        </div>
	        <div id="word4" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page5" style="animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div id="page_blurImage5" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857856787?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div51" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857856787?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 611.855px; left: 0px; top: 87.5727px; background-size: 500px 611.855px;"></div>
	            <div id="div52" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857856787?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 611.855px; left: 0px; top: 87.5727px; background-size: 500px 611.855px;"></div>
	        </div>
	        <div id="word5" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page6" style="animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div id="page_blurImage6" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857951631?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div6" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580857951631?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 739.03px; left: 0px; top: 23.985px; background-size: 500px 739.03px;"></div>
	        </div>
	        <div id="word6" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page7" style="animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div id="page_blurImage7" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580858113145?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div7" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580858113145?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px;"></div>
	        </div>
	        <div id="word7" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page8" style="animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div id="page_blurImage8" class="blurImage" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580858198405?x-oss-process=image/resize,w_1000/auto-orient,1/blur,r_40,s_20/quality,q_50/&quot;);"></div>
	        <div class="page scaleRound">
	            <div id="div81" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580858198405?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px;"></div>
	            <div id="div82" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580858198405?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px;"></div>
	            <div id="div83" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580858198405?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px;"></div>
	            <div id="div84" style="background-image: url(&quot;https://aliphoto.kagirl.cn/kawa_21580858198405?x-oss-process=image/resize,w_1000/auto-orient,1/&quot;); width: 500px; height: 375px; left: 0px; top: 206px; background-size: 500px 375px;"></div>
	        </div>
	        <div id="word8" class="wordspan"></div>
	    </div>
	    <div class="page pagehiden" id="page9">
	        <div id="page_blurImage9" class="blurImage"></div>
	        <div class="page scaleRound">
	            <div id="div91"></div>
	            <div id="div92"></div>
	            <div id="div93"></div>
	            <div id="div94"></div>
	            <div id="div95"></div>
	            <div id="div96"></div>
	            <div id="div97"></div>
	            <div id="div98"></div>
	        </div>
	        <div id="word9" class="wordspan"></div>
	    </div>
	    <div class="page" id="pagetitle" style="display: flex; justify-content: center; align-items: center; animation: 1s linear 0s 1 normal both running fadeOut;">
	        <div style="background-image: url(https://qnphoto.kagirl.cn/pic/flashscene/bg.jpg?imageMogr2/blur/40x20)" class="blurImage"></div>
	        <div id="titlediv" style="position:relative;width:500px;height:180px;overflow:hidden;display:table;background-color: rgba(0,0,0,0.5);">
	            <div id="titlecontent" style="width: 288px; height: 150px; vertical-align: middle; display: table-cell; text-align: center; font-size: 26px; line-height: 34px; color: rgb(255, 255, 255); animation: 1s linear 0.1s 1 normal both running titlein;">我的动态相册，送给我的朋友</div></div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'dongtai_0',
		props: ['currentImage'],
		data () {
			return {}
		}
	}
</script>

<style scoped>
	.dongtai_container {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    overflow: hidden;
	    background-color: rgba(0,0,0,0.7);
	}
	.dongtai_container>div, .dongtai_container>img {
		position: absolute;
	}
	@keyframes fadeIn {
		0% {
		    opacity: 1;
		}
		100% {
		    opacity: 0;
		}
	}
	.page > * {
	    position: absolute;
	    overflow: hidden;
	}
	.page {
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	}
	.scaleRound {
	    -webkit-animation: scaleRound 1.5s ease-in-out infinite alternate;
	}
	@keyframes scaleRound {
		0% {
		    transform: scale3d(1,1,1);
		}
		100% {
		    transform: scale3d(1.02,1.02,1.02);
		}
	}
</style>