<template>
	<div class="info">
		<div class="info-box">
			<div class="tab-switch">
			<!-- <div class="tab-switch" v-if="$env.web == 'wxa'"> -->
				<span class="active">照片</span>
				<span @click="switchTab">字幕/标题</span>
			</div>
			<div class="info-bd" ref="info-bd">
				<p class="info-tips">正在操作第 {{activeImageIndex+1}} 张照片</p>
				<div class="info-photos">
					<div class="info-item" @click="onImageSelect(index)" :class="{'active': activeImageIndex == index}" v-for="(image, index) in images">
						<div class="item-photo" :style="{
								backgroundImage: 'url('+(image['im'].split('/rotate')[0])+')',
								transform: getImageTransform(index)
							}"></div>
						<span class="item-index">{{index+1}}</span>
					</div>
				</div>
			</div>
			<div class="info-ft" :class="{iphonex: this.$env.iphonex}">
				<div class="info-obar" @click="onImageMove('last')">
					<span class="info-obar-icon">
						<img src="http://litiresource.xcgogo.site/user/5980171/images/4799e7d9f9c54c1dce7bacaf2062923b.png" style="transform: rotate(180deg);">
						<!-- <img src="http://litiresource.xcgogo.site/user/5980171/images/caa64922958d389af82bfb124c26f588.png"> -->
					</span>
					<p class="info-obar-text">向前移</p>
				</div>
				<div class="info-obar" @click="onImageMove('next')">
					<span class="info-obar-icon">
						<img src="http://litiresource.xcgogo.site/user/5980171/images/4799e7d9f9c54c1dce7bacaf2062923b.png">
						<!-- <img src="http://litiresource.xcgogo.site/user/5980171/images/8fae5e40028bc20e71571e2db9abd2da.png"> -->
					</span>
					<p class="info-obar-text">向后移</p>
				</div>
				<!-- <div class="info-obar">
					<span class="info-obar-icon">
						<img src="http://litiresource.xcgogo.site/user/5980171/images/4799e7d9f9c54c1dce7bacaf2062923b.png">
					</span>
					<p class="info-obar-text">移动到</p>
				</div> -->
				<div class="info-obar" @click="onImageRotate">
					<span class="info-obar-icon">
						<img src="http://litiresource.xcgogo.site/user/5980171/images/b680ef7b8dd60d17569c7f44ea5aeaac.png">
					</span>
					<p class="info-obar-text">旋转</p>
				</div>
				<div class="info-obar" @click="onImageEdit">
					<span class="info-obar-icon">
						<img src="http://litiresource.xcgogo.site/user/5980171/images/8fd7ccb4bae4947d75eedc08377d3fcb.png">
					</span>
					<p class="info-obar-text">调整图片</p>
				</div>
				<div class="info-obar" @click="onImageRemove">
					<span class="info-obar-icon">
						<img src="http://litiresource.xcgogo.site/user/5980171/images/b70541f25ed893f3a70050d0daccfcff.png">
					</span>
					<p class="info-obar-text">删除</p>
				</div>
			</div>
		</div>
		<div class="info-flow-btns" :class="{iphonex: this.$env.iphonex}">
			<div class="info-flow_btn addimage" @click="onImageAdd">
				<span class="info-flow_icon">
					<img src="http://litiresource.xcgogo.site/user/9546085/images/bb3a20708f44e8cc1e253c13f2932cad.png">
				</span>
				<span class="info-flow_text">新增图片</span>
			</div>
		</div>
	</div>
</template>

<script>

	// import U from "@/util"

	import cropper from '@/cropper'
	let CHOSEING = false;

	export default {
		data () {
			return {
				modi: false,
				modiRemove: false,
				modiRotate: false,
				modiRotating: false,
				modiMove: false,
				modiMoving: false,
				modiAdd: false,
				activeImageIndex: 0
			}
		},
		computed: {
			images () {
				return this.$store.state.config.images;
			},
		},
		methods: {
			onImageAdd () {
				// this.$toast.text('新增照片功能正在升级中，稍后将恢复使用，感谢您的支持');
				// return;
				let self = this;
				// self.$o_util.uploadMedia({
				// 	violate: async function () {
				// 		return new Promise((resolve, reject)=>{
				// 			resolve("C9130828AE2347CD2AD3F8D4C98EDC89")
				// 		})
				// 	},
				// 	process (index, len) {
				// 		if (index == 0) {
				// 			self.$toast.loading({
				// 				message: '加载中...',
				// 				duration: 'infinite'
				// 			})
				// 		} else {
				// 			self.$toast.loading(index+'/'+len+' 上传中');
				// 		}
				// 	},
				// })
				if (CHOSEING) {
					return;
				}
				CHOSEING = true;
				this.$util.$upload.uploadMedia()
				.then(image_infos=>{ // images 
					CHOSEING = false;
					self.$util.rpost('new_images', {
						'token': self.$store.state.config.token,
						'key': self.$route.query.key,
						// 'images': JSON.stringify(images)
						'imgInfos': JSON.stringify(image_infos)
					})
					.then((newImageDatas) => {
						// 存储新图片数据
						self.__saveImages(newImageDatas);

						// 设置新图片到相册
						self.$toast.loading('添加成功');
						setTimeout(() => {
							self.$toast.clear();
						}, 1000);

						let imageDatas = self.$util.copy(self.$store.state.config.images);
						imageDatas = imageDatas.concat(newImageDatas);
						self.setImages(imageDatas);
						
						self.__scrollTo('bottom');
						
						self.modi = true;
						self.modiAdd = true;
						// self.$emit('toggleEdited', true);
					})
					.catch((err) => {
						self.$toast.clear();
						// self.$o_util.ulog('image new_images fail');
					});
				}).catch((err) => {
					self.$toast.clear();
					CHOSEING = false;
				})
				
				this.$mta.click('btn_add_image');
			},
			onImageRemove () {
				let index = this.activeImageIndex;

				const CANT_DELETE_MESS = '照片只有一张了，无法继续删除';
				const DELETE_MESS_CONFIRM = '确定要删除这张照片吗？'
				
				if (this.$store.state.config.images.length == 1) {
					this.$dialog.alert({
						title: '注意',
						message: CANT_DELETE_MESS
					});
					return;
				}

				let imageId = this.$store.state.config.images[index]['id'];
				this.$dialog.confirm({
					title: '提示',
					message: DELETE_MESS_CONFIRM,
				})
				.then((action) => {
					// let out = this.$util.timeout(() => {
					// 	this.$toast.loading('删除中...');
					// }, 400)
					this.$toast.loading('删除中...');

					this.$util.rget('delete_image', {
						imageId: imageId,
						index: index,
						key: this.$route.query.key
					}).then((response) => {
						// if (response == 0) {
						// 	this.$dialog.alert({
						// 		title: '注意',
						// 		message: CANT_DELETE_MESS
						// 	});
						// } else {

							// this.setImages(response);

							let imageDatas = this.$util.copy(this.$store.state.config.images);
							imageDatas.splice(index, 1);
							// this.$store.commit('setimages', {'imageDatas': imageDatas});
							this.setImages(imageDatas);
							this.activeImageIndex = 0;
						// }

						// clearInterval(out);
						try {this.$toast.clear()} catch(e) {};
					});
				})

				this.modi = true;
				this.modiRemove = true;
				// this.$emit('toggleEdited', true);

				this.$mta.click('btn_delete_image');
			},
			onImageMove (direction) {
				let index = this.activeImageIndex;
				let imageId = this.$store.state.config.images[index]['id'];
				let self = this;
				
				const CANT_MOVE_MESS = '照片只有一张了，无法进行移动';

				if (this.$store.state.config.images.length == 1) {
					this.$dialog.alert({
						title: '注意',
						message: CANT_MOVE_MESS
					});
					return;
				}

				if (this.modiMoving) {
					return;
				}
				this.modiMoving = true;

				let out = this.$o_util.timeout(() => {
					this.$toast.loading('移动中...');
				}, 300)

				this.$util.rget('move_image', {
					imageId: imageId,
					direction: direction,
					index: index,
					key: this.$route.query.key
				}).then(res => {
					
					// 服务端逻辑成功，移动本地照片数据
					let imageDatas = this.$util.copy( this.$store.state.config.images );
					let removeData = imageDatas.splice( this.activeImageIndex, 1 )[0];
					this.__preloadImages([removeData], images=>{
						removeData['orisize'] = images[removeData['id']];
						if ('last' == direction) {
							this.activeImageIndex -= 1;
							if (this.activeImageIndex < 0) {
								// 暂时不支持移动到队尾
								this.activeImageIndex = this.$store.state.config.images.length - 1;
								this.__scrollTo('bottom');
							}
						} else {
							this.activeImageIndex += 1;
							if (this.activeImageIndex == this.$store.state.config.images.length) {
								// 暂时不支持移动到队头
								this.activeImageIndex = 0;
								this.__scrollTo('top');
							}
						}
						imageDatas.splice( this.activeImageIndex, 0, removeData);
	
						this.setImages(imageDatas);

						clearInterval(out);
						try {this.$toast.clear()} catch(e) {};
						
						// 更新状态
						this.modi = true;
						this.modiMove = true;
						this.modiMoving = false;
					})
				}).catch(err => {
					clearInterval(out);
					try {this.$toast.clear()} catch(e) {};

					// 更新状态
					this.modi = true;
					this.modiMove = true;
					this.modiMoving = false;

					if (this.$store.state.config.images.length == 1) {
						this.$dialog.alert({
							title: '注意',
							message: CANT_MOVE_MESS
						});
						return;
					}
				})

				if (direction == 'last') {
					this.$mta.click('btn_move_image', {'left':'true'});
				} else {
					this.$mta.click('btn_move_image', {'right':'true'});
				}
			},
			onImageRotate () {
				if (this.modiRotating) {
					return;
				}
				this.modiRotating = true;

				let imageDatas = this.$util.copy(this.$store.state.config.images);
				let index = this.activeImageIndex;
				let imageData = imageDatas[index];
				let imageId = imageData['id'];

				let out = this.$o_util.timeout(() => {
					this.$toast.loading('处理中...');
				}, 400)

				this.$util.rget('rotate_image', {
					imageId: imageId,
					key: this.$route.query.key
				})
				.then((response) => {
					let process = imageData['pr'] ? imageData['pr'] : {};
					let deg = process['rotate'] ? parseInt(process['rotate']) : 0;
					deg = deg + 90 == 360 ? 0 : deg + 90;
					process['rotate'] = deg;
					imageData['pr'] = process;
					// imageData['im'] = this.$util.im(imageData);

					if (imageData['orisize']) {
						this.__preloadImages([imageData], image=>{
							imageData['orisize'] = image[ imageData['id'] ];
							this.setImages(imageDatas);
						})
					} else {
						this.setImages(imageDatas);
					}

					clearInterval(out);
					try {this.$toast.clear()} catch(e) {};
					
					// this.$emit('toggleEdited', true);
					this.modi = true;
					this.modiRotate = true;
					this.modiRotating = false;
				})



				this.$mta.click('btn_rotate_image');
			},
			onImageEdit () {
				// create elem
				if (!document.getElementById("cropper_container")) {
					let container = document.createElement("div");
					container.id = "cropper_container";
					container.innerHTML = document.getElementById("cropper_htm").innerHTML;
					document.body.appendChild(container);
				}

				// start
				let index = this.activeImageIndex;
				let imdata = this.$store.state.config.images[index];
				let image = imdata['im'].split('?')[0];
				// 处理webp的情况
				if (this.$util.getStorageSync("WEBP_ABLE") === 0) {
					if (imdata['o']) {
						if (imdata['f'].indexOf('oss') != -1) {
							image += "?x-oss-process=image/format," + imdata['o'];
						} else if (imdata['f'] == 'qiniu') {
							image += "?imageMogr2/auto-orient/format/" + imdata['o'];
						}
					}
				}

				this.$o_util.loadScript('https://webliti.lititutu.cn/vliti/assets/js/cropper.min.js').then(() => {
					this.$o_util.loadCss('https://webliti.lititutu.cn/vliti/assets/css/cropper.min.css').then(() => {
						// 初始化cropper
						let $c = cropper.init();
						
						// 加载编辑界面
						$c.reset().replace(image);

						// 绑定编辑界面相关按钮事件
						document.getElementById('crop-confirm').onclick = () => {
							let ed = $c.getPos();
							this.$util.rpost('edit_image', {
								'ed': JSON.stringify(ed),
								'imageId': this.$store.state.config.images[index]['id'],
								'key': this.$route.query.key
							})
							.then((response) => {
								// this.setImages(response);

								let imageDatas = this.$util.copy(this.$store.state.config.images);
								let imageData = imageDatas[index];
								let process = imageData['pr'] ? imageData['pr'] : {};
								process['ed'] = ed;
								imageData['pr'] = process;
								// imageData['im'] = this.$util.im(imageData);
								// this.$store.commit('setimages', {'imageDatas': imageDatas});
								this.setImages(imageDatas);

								$c.close();

								this.$toast.text({
									message: '调整成功',
									duration: 2
								});
							})
						}

						document.getElementById('crop-cancel').onclick = () => {
							$c.close();
						}

						document.getElementById('crop-downphoto').onclick = () => {
							if (this.$env.web == 'wxa') {

								wx.miniProgram.navigateTo({
									url: '/pages/make/make?scene=' + this.$route.query.key
								});

							} else {
								this.$o_util.getWxaQrcode(this.$route.query.key).then((qrcode) => {
									document.getElementById('cropdownphotocontain').style.display = 'block';
			                		document.getElementById('cropdownphotoqrcode').setAttribute('src', qrcode);
								})
							}
						}

						this.$mta.click('btn_edit_image');
					})
				})
			},
			getImageTransform (index) {
				if (!this.$store.state.config.images[index]['pr']) {
					return 'rotate(0deg)';
				}
				let deg = this.$store.state.config.images[index]['pr']['rotate'];
				return 'rotate('+( deg ? deg : 0 )+'deg)';
			},
			onImageSelect (index) {
				this.activeImageIndex = index;
			},
			switchTab () {
				this.$emit('hideTab');
				this.$emit('showTab', 'text');
			},
			setImages (imageDatas) {
				// for (let i=0, lens=imageDatas.length; i<lens; i++) {
				// 	imageDatas[i]['im'] = this.$util.im( imageDatas[i] );
				// }

				this.$store.commit('setimages', {'imageDatas': imageDatas});

				this.$wx.share({
					imgUrl: this.$store.state.config['images'][0]['im']
				});
				
				// this.$emit('toggleEdited', true);
			},
			// setImages (imageDatas, cb) {
			// 	let self = this;

			// 	for (let i=0, lens=imageDatas.length; i<lens; i++) {
			// 		imageDatas[i]['im'] = self.$util.im( imageDatas[i] );
			// 	}

			// 	self.__preloadImages(imageDatas, function(images) {
			// 		for (let j=0, lens=imageDatas.length; j<lens; j++) {
			// 			imageDatas[j]['orisize'] = images[imageDatas[j]['id']];
			// 		}
			// 		self.$store.commit('setimages', {'imageDatas': imageDatas});

			// 		self.$wx.share({
			// 			imgUrl: self.$store.state.config['images'][0]['im']
			// 		});
			// 		// 当第一张图被修改时，修改封面
			// 		// if ((index == 1 && direction == 'last') || (index == 0 && direction == 'next')) {
			// 		// }
			// 		cb && cb();
			// 	})

			// 	this.$emit('toggleEdited', true);
			// },
			__preloadImages (images, cb) {
		        var n = 0, img, imgs = {}, that = this;

		        for(let i=0, len=images.length; i<len; i++) {
		        	let image = images[i],
						imagesrc = this.$util.im(image);

		            img = new Image();
		            img.onload = (function(_imageid, img) {
		                return function() {
		                    imgs[_imageid] = [img.width, img.height];
							that.$store.commit('setImageObj', {'imagesrc': img.src, 'imageobj': img});
		                    if (++n === images.length) {
		                    	cb && cb(imgs);
		                    }
		                }
		            })(image['id'], img);

		            img.onerror = (function(_imageid, img) {
		            	return function () {
		                	console.log("error");
		            		imgs[_imageid] = [0, 0];
		            		if (++n === images.length) {
		                    	cb && cb(imgs);
		                    }
		            	}
		            })(image['id'], img);

		            img.src = imagesrc;
		        }
			},
			__scrollTo (dirc) {
				if (dirc == 'top') {
					this.$refs['info-bd'].scrollTop = 0;
				} else if (dirc == 'bottom') {
					this.$refs['info-bd'].scrollTop = this.$refs['info-bd'].scrollHeight;
				}
			},
			__saveImages (images) {
				let _this = this;
				for (let i=0; i<images.length; i++) {
					let image = images[i];
					let im = new Image();

					(function(imageEl, imageSrc) {
						imageEl.onload = function() {
							// 存储图片原始尺寸
							_this.$store.commit('setImageOriSize', {'imageid': imageSrc['id'], 'orisize': [this.width, this.height]});

							// 存储图片对象
							_this.$store.commit('setImageObj', {'imagesrc': imageSrc['im'], 'imageobj': this});
						}
						imageEl.src = _this.$util.im( imageSrc );
					})(im, image);
				}
			}
		}
	}
</script>

<style lang="less">
	@oprate-icon-width: @bw*.075;
	@oprate-icon-color: #f06734;
	@orange: #ff6126;
	@orange-light: #ff9761;
	@orange-dark: #ed5100;

	.info-box {
		position: absolute;
		width: 100%;
		height: calc(100% - @tabtop);
		background-color: white;
		display: flex;
		flex-direction: column;
		top: @tabtop;
		background-color: #f3f3f3;
	}
	.info-tips {
		font-size: @bw*.032;
		text-align: center;
		color: #989898;
	}
	.info-bd {
		flex: 1;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	.info-photos {
		position: relative;
		// padding-top: 10px;
		padding-bottom: @bw*.2;
	}
	.info-ft {
		display: flex;
		height: @bw*.16;
		border-top: solid 1px #ccc;
	}
	.info-obar {
		flex: 1;
		font-size: 0;
		text-align: center;
		padding: @bw*.016 0;
	}
	.info-obar-icon {
	    position: relative;
	    display: inline-block;
	}
	.info-obar-icon>img {
		display: inline-block;
		width: @bw*.075px;
		height: @bw*.075px;
		margin-top: 6px;
	}
	.info-obar-text {
		font-size: @bw*.03;
		line-height: 0;
		font-weight: bold;
		margin: @bw*.028;
	}

	.info {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}
	.info-items {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    overflow-x: hidden;
	    top: @tabtop;
	    overflow-y: auto;
	    -webkit-overflow-scrolling: touch;
		background-color: white;
	    .contain {
	    	position: relative;
			padding-top: @bw*.02;
			padding-bottom: @bw*.42;
			font-size: 0;
	    }
	}

	.info-item.active {
		// border-color:  @orange!important;
	    box-shadow: 0px 0px 7px 8px @orange-dark;
	}
	.info-item.active>.item-index {
		color: white!important;
		background-color: @orange;
	}
	.info-item {
		position: relative;
		width: @bw*.224;
		height: @bw*.224;
		margin-left: @bw*.022;
		margin-top: @bw*.022;
		// border: solid 10px;
		border-color: white;
		overflow: hidden;
		display: inline-block;
		border-radius: @bw*.014;

		.item-index {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			font-size: @bw*.042;
			background-color: white;
			width: @bw*.083;
			height: @bw*.045;
			text-align: center;
			line-height: @bw*.045;
			font-family: '\9ED1\4F53';
			border-bottom: solid 3px #ff6126;
			border-right: solid 3px #ff6126;
			color: #ff6126;
			border-bottom-right-radius: 50%;
		}

		.item-photo {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			// border-radius: 5px;
		}
		.item-icon {
			position: absolute;
			width: @oprate-icon-width;
			height: @oprate-icon-width;
			top: 0;
		}
		.rotate {
			left: 0;
			background: @oprate-icon-color;
			border-bottom-right-radius: @bw*.03;
		}
		.remove {
			right: 0;
			background: @oprate-icon-color;
			border-bottom-left-radius: @bw*.03;
		}
		.caret {
			position: absolute;
			width: 100%;
			height: @oprate-icon-width;
			bottom: 0;
			left: 0;
			background: @oprate-icon-color;
			.item-icon-caret {
				position: absolute;
				width: @oprate-icon-width;
				height: 100%;
			}
			.caret-left {
				left: 0;
			}
			.caret-right {
				right: 0;
				transform: rotate(180deg);
			}
		}
		.item-icon>img, .item-icon-caret>img {
			position: absolute;
			width: @bw*.05;
			height: @bw*.05;
			top: 0; left: 0; right: 0; bottom: 0;
			margin: auto;
		}
	}

	.info_images-btns {
	    position: absolute;
	    bottom: 0;
	    display: flex;
	    font-size: @bw*.042;
	    left: 0;
	    width: 100%;
	    height: @bw*.167;
	    line-height: @bw*.167;
	    background-color: #f8f8f8;
	}
	.info_images-btns>div {
	    flex: 1;
	    text-align: center;
	}
	.info_images-btns-addphotos>span {
	    background-color: @oprate-icon-color;
	    color: white;
	    border: solid 3px @oprate-icon-color;
	}
	.info_images-btns>div>span {
	    padding: @bw*.02 @bw*.056;
	    border-radius: @bw*.017;
	}
	.info_images-btns-close>span {
	    color: @oprate-icon-color;
	    border: solid 3px @oprate-icon-color;
	}

	.info-flow-btns {
		position: absolute;
		bottom: @bw*.2;
		width: 100%;
		height: @bw*.167;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.info-flow_btn.addimage {
	    background-color: #ed5100;		
	    box-shadow: 0 0 9px 5px rgba(237, 81, 0, 0.67);
	    .info-flow_text {
	    	color: white;
	    }
	}
	.info-flow_btn.downphoto {
	    // background-color: #ed5100;		
	    box-shadow: 0 0 9px 5px #444;
	    .info-flow_text {
	    	color: #444;
	    }
	}
	.info-flow_btn {
	    position: relative;
	    font-size: 0;
	    text-align: center;
	    width: @bw*.17;
	    height: @bw*.17;
	    border-radius: 50%;
	    border: solid 8px white;
		z-index: 10;
	}
	.info-flow_icon {
	    position: relative;
	    display: inline-block;
        margin: @bw*.02 0;
	    img {
			display: inline-block;
			width: @bw*.067;
			height: @bw*.067;
			margin-bottom: 2px;
	    }
	}
	.info-flow_text {
	    font-size: @bw*.03;
	    line-height: 0.2;
	    font-weight: bold;
	    display: block;
	}

	.tab-switch {
		position: relative;
		display: flex;
		justify-content: center;
		margin: 0 0 @bw*.028;
		border-bottom: solid 2px #ccc;
		span {
			flex: 1;
			height: @bw*0.125;
			line-height: @bw*0.125;
			text-align: center;
			// border: solid 2px #ed5100;
			color: #676767;
			font-size: @bw*.05;
			font-weight: bold;
		}
		span.active {
			color: #ed5100;
			border-bottom: solid 5px #ed5100;
		}
	}
</style>