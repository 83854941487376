<template>
    <div class="page">
        <!--后面-->
        <div class="zy-Fdiv page">
            <div class="zy-Udiv po">
                <div class="zy-Ldiv po" >
                    <div class="zy-div4 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div3 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div2 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div1 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <img class="zy-borderImg zy-bor1 page" v-if="comp_config[0]" :src="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
                <div class="zy-Rdiv po" >
                    <div class="zy-div4 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div3 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div2 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div1 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <img class="zy-borderImg zy-bor1 page" v-if="comp_config[0]" :src="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
                <div class="zy-Zdiv po" >
                    <div class="zy-div4 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div3 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div2 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div1 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <img class="zy-borderImg zy-bor1 page" v-if="comp_config[0]" :src="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
            </div>
            <div class="zy-Ddiv po">
                <div class="zy-Ldiv po" >
                    <div class="zy-div4 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div3 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div2 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div1 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <img class="zy-borderImg zy-bor1 page" :src="comp_config[0]" v-if="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
                <div class="zy-Rdiv po" >
                    <div class="zy-div4 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div3 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div2 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div1 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <img class="zy-borderImg zy-bor1 page" :src="comp_config[0]" v-if="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
                <div class="zy-Zdiv po" >
                    <div class="zy-div4 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div3 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div2 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <div class="zy-div1 zy-div po bg_photo" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                    <img class="zy-borderImg zy-bor1 page" :src="comp_config[0]" v-if="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
            </div>
        </div>
        <!--前面-->
        <div class="zy-Bdiv zy-per po">
            <div class="page preserve3d zy-duoAni" :style="zyAni">
                <div class="zy-Bdiv_div1 zy-Bdiv_div po">
                    <div class="zy-div1 zy-div po">
                        <div class="page">
                            <div class="zy-div_img bg_photo po zy-picAni1 bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                        </div>
                    </div>
                    <img class="zy-borderImg page" :src="comp_config[0]" v-if="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
                <div class="zy-Bdiv_div3 zy-Bdiv_div po">
                    <div class="zy-div3 zy-div po">
                        <div class="page">
                            <div class="zy-div_img bg_photo po zy-picAni3 bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                        </div>
                    </div>
                    <img class="zy-borderImg page" :src="comp_config[0]" v-if="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
                <div class="zy-Bdiv_div5 zy-Bdiv_div po">
                    <div class="zy-div1 zy-div po">
                        <div class="page">
                            <div class="zy-div_img bg_photo po zy-picAni1 bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                        </div>
                    </div>
                    <img class="zy-borderImg page" :src="comp_config[0]" v-if="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
                <div class="zy-Bdiv_div7 zy-Bdiv_div po">
                    <div class="zy-div3 zy-div po">
                        <div class="page">
                            <div class="zy-div_img bg_photo po zy-picAni3 bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
                        </div>
                    </div>
                    <img class="zy-borderImg page" :src="comp_config[0]" v-if="comp_config[0]">
                    <elem :mode="comp_config[1]" v-if="comp_config[1]"></elem>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
export default {
    name: "zhuanyuan",
    extends: BaseElem,
    data () {
        return {
            aniEvent: null,
            zyAni: 'animation: 3s ease-in-out 0s 1 normal both running page1_div1_in'
        }
    },
    destroyed () {
        clearInterval(this.aniEvent);
    },
    created () {
        let aniIdx = 1;
        clearInterval(this.aniEvent);
        this.aniEvent = setInterval(() => {
            aniIdx ++;

            this.zyAni = 'animation: 3s ease-in-out 0s 1 normal both running page1_div'+ aniIdx +'_in';

            if (aniIdx >= 5) {
                aniIdx = 0;
            }
        }, 4000);
    },
}
</script>

<style>
	.zy-div {
	    margin: auto;
	    width: 78%;
	    height: 78%;
	    border-radius: 50%;
	    background-size: cover;
	    background-position: 50% 10%;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    overflow: hidden;
	}
	.zy-div>div {
	    border-radius: 50%;
	    overflow: hidden;
	}
	.zy-Fdiv .zy-div {
	    transform: translateZ(0);
	}
	.zy-Ldiv, .zy-Rdiv, .zy-Zdiv {
	    width: 180.50px;
	    height: 180.50px;
	}
	.zy-Ldiv {
	    left: -24.50px;
	}
	.zy-Rdiv {
	    right: -24.50px;
	}
	.zy-Zdiv {
	    left: 50%;
	    margin-left: -90px;
	}
	.zy-Mdiv {
	    margin: auto;
	    width: 139.00px;
	    height: 139.00px;
	    left: 14.00px;
	    right: 0;
	    bottom: -169.50px;
	}
	.zy-Udiv {
	    width: 500.00px;
	}
	.zy-Udiv .zy-Ldiv, .zy-Udiv .zy-Rdiv {
	    top: 31.00px;
	}
	.zy-Udiv .zy-Mdiv {
	    top: -21.00px;
	}
	.zy-Ddiv {
	    width: 500.00px;
	    bottom: 0;
	}
	.zy-Ddiv .zy-Ldiv, .zy-Ddiv .zy-Rdiv {
	    bottom: 60.00px;
	}
	.zy-Ddiv .zy-Zdiv {
		bottom: 31.00px;
	}
	.zy-Ddiv .zy-Mdiv {
	    bottom: -7.00px;
	    left: 0;
	}
	.zy-Bdiv {
	    margin: auto;
	    width: 500.00px;
	    height: 500.00px;
	    top: 0;
	    bottom: 0;
	}
	.zy-Bdiv>div {
	    transform: translateZ(-451.50px) rotateY(-22.5deg);
	}
	.zy-per {
	    perspective: 1500.00px;
	}
	.zy-Bdiv_div {
	    margin: auto;
	    width: 528.00px;
	    height: 528.00px;
	    top: 0;
	    left: -4.5px;
	    bottom: 0;
	}
	.zy-Bdiv_div1 {
	    transform: rotateY(0deg) translateZ( 625.00px);
	    animation:3s ease-in-out .1s 1 normal both running zy-aniscal1
	}
	.zy-Bdiv_div3 {
	    transform: rotateY(-90deg) translateZ( 625.00px);
	}
	.zy-Bdiv_div5 {
	    transform: rotateY(-180deg) translateZ( 625.00px);
	}
	.zy-Bdiv_div7 {
	    transform: rotateY(-270deg) translateZ( 625.00px);
	}
	.divlitton {
	    width: 416.50px;
	    height: 416.50px;
	    left: 83.50px;
	}
	@keyframes rot_f {
	    0% {
	        transform: rotate(360deg);
	    }
	    100% {
	        transform: rotate(0);
	    }
	}
	@keyframes page1_div1_in {
	    0% {
	        transform: translateZ(-451.50px) rotateY(0deg);
	    }
	    100% {
	        transform: translateZ(-451.50px) rotateY(0deg);
	    }
	}
	@keyframes page1_div2_in {
	    0% {
	        transform: translateZ(-451.50px) rotateY(0deg);
	    }
	    100% {
	        transform: translateZ(-451.50px) rotateY(90deg);
	    }
	}
	@keyframes page1_div3_in {
	    0% {
	        transform: translateZ(-451.50px) rotateY(90deg);
	    }
	    100% {
	        transform: translateZ(-451.50px) rotateY(180deg);
	    }
	}
	@keyframes page1_div4_in {
	    0% {
	        transform: translateZ(-451.50px) rotateY(180deg);
	    }
	    100% {
	        transform: translateZ(-451.50px) rotateY(270deg);
	    }
	}
	@keyframes page1_div5_in {
	    0% {
	        transform: translateZ(-451.50px) rotateY(270deg);
	    }
	    100% {
	        transform: translateZ(-451.50px) rotateY(360deg);
	    }
	}
	.zy-borderImg {
	    animation: 10s linear 0s infinite normal both running rot_f;
	}
	.zy-picAni1 {
	    width: 100%;
	    height: 100%;
	    transform: translate( 0.00px, 0.00px);
	    animation: 6000ms linear 2000ms 1 normal both running page1_div1;
	}
	.zy-picAni2 {
	    width: 100%;
	    height: 100%;
	    transform: translate( 0.00px, 0.00px);
	    animation: 6000ms linear 2000ms 1 normal both running page1_div2;
	}
	.zy-picAni3 {
	    width:100%;
	    height:100%;
	    transform: translate(0.00px,0.00px);
	    animation: 6000ms linear 2000ms 1 normal both running page1_div3;
	}
	@keyframes zy-aniscal1 {
	    0% {
	        transform:rotateY(0deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform:rotateY(0deg) translateZ(625.00px) scale(1);
	    }
	}
	@keyframes zy-aniscal2 {
	    0% {
	        transform:rotateY(-45deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform: rotateY(-45deg) translateZ(625.00px)scale(1);
	    }
	}
	@keyframes zy-aniscal3 {
	    0% {
	        transform:rotateY(-90deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-90deg) translateZ(625.00px) scale(1);
	    }
	}
	@keyframes zy-aniscal4 {
	    0% {
	        transform:rotateY(-135deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-135deg) translateZ(625.00px) scale(1);
	    }
	}
	@keyframes zy-aniscal5 {
	    0% {
	        transform:rotateY(-180deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-180deg) translateZ(625.00px) scale(1);
	    }
	}
	@keyframes zy-aniscal6 {
	    0% {
	        transform:rotateY(-225deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-225deg) translateZ(625.00px) scale(1);
	    }
	}
	@keyframes zy-aniscal7 {
	    0% {
	        transform:rotateY(-270deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-270deg) translateZ(625.00px) scale(1);
	    }
	}
	@keyframes zy-aniscal8 {
	    0% {
	        transform:rotateY(-315deg) translateZ(625.00px) scale(0.3);
	    }
	    100% {
	        transform:rotateY(-315deg) translateZ(625.00px) scale(1);
	    }
	}
	.zy-duoAni {
	    transition: all .5s;
	}
</style>