<template>
	<div class="tab-menu" :class="menuClass">
        <template v-if="['sharemore', 'endpage'].indexOf(from) != -1">
            <div class="items" :class="{'items-sharemore': from == 'sharemore', 'items-endpage': from == 'endpage'}">
                <div class="scroll">
                	<div class="scroll-content">
                		<div class="item" v-for="item in themeHot" @click="onThemeSelect(item[0])">
                			<div class="box">
                				<div class="bd" :style="{backgroundImage: 'url('+getItemCover({'tid': item[0]})+')'}">
                					<!-- <span class="playing">{{item[2]}}人在玩</span> -->
                					<div class="make-tips">立即制作</div>
                				</div>
                				<div class="ft">{{ themes[(item[0].indexOf('3D') != -1 ? item[0].toLowerCase() : item[0])] ? themes[(item[0].indexOf('3D') != -1 ? item[0].toLowerCase() : item[0])]['name'] : '相册模板' }}</div>
                			</div>
                		</div>
                	</div>
                	<!-- <span class="backtip">返回相册</span> -->
                </div>
            </div>
        </template>
        <template v-else>
            <div class="types">
                <div
					class="type"
					v-for="(type,index) in themeTypes" :class="{ active: type['tyid'] == themeTypesActive}"
					:key="type['tyid'] + index"
					:id="type['tyid']"
					@click="onThemeTypesClick(type['tyid'])">
						<span class="type-text" :class="{ activetext: type['tyid'] == themeTypesActive}" v-html="type['name']"></span>
					</div>
            </div>
            
			<div class="items item-scorll-container" ref="tabMenuItems">
                <div class="scroll" v-show="menuShow">
                    <template v-for="type in themeTypes">
                        <div class="scroll-content" v-show="type['tyid'] == themeTypesActive" :key="type['tyid']">
							<!-- 随机模板 -->
							<div class="item random-btn-bg" @click="onThemeSelect()" v-show="type['tyid'] == 'hot_v2' && from != 'editor'">
								<div class="random-btn">
									<img class="random-btn-image" src="http://resali.lititutu.cn/wxa_icon/tabbar/make_selected.png" />
									<span class="random-btn-text">随机模板</span>
								</div>
							</div>
                            <template v-for="(theme,idx) in type['themes']">
                                <div class="item" v-if="theme" @click="onThemeSelect(theme['tid'])" :key="theme['tid']  + '_' + idx">
                                    <div class="box">
                                        <div class="bd" :style="{backgroundImage: 'url('+getItemCover(theme)+')'}">
                                            <template v-if="theme['tips']">
                                                <span v-if="theme['tips']['name']" class="tips" :style="{backgroundColor: theme['tips']['bgcolor']}">{{theme['tips']['name']}}</span>
                                                <img v-if="theme['tips']['im']" class="mark" :src="theme['tips']['im']">
                                            </template>
											<template v-if="themeTypesActive!='used' && themeUsed['tids'].indexOf(theme['tid']) > -1">
												<div class="logo used-logo">最近使用</div>
											</template>
                                            <template v-else-if="theme['type'] == 'exhi' || ( theme['pcode'] && themeLogos[theme['pcode']] && (themeTypesActive == 'douyin' || themeTypesActive == 'hot_v2' || themeTypesActive == 'used') )">
												<div v-if="themeTypesActive!='exhi' && theme['type'] == 'exhi'" class="logo">立体画廊</div>
                                                <div v-else class="logo" :style="{background: (themeLogoBg[theme['pcode']] ? themeLogoBg[theme['pcode']] : 'linear-gradient(to top right, #36A9D9, #F94EFF)')}">{{themeLogos[theme['pcode']]}}</div>
                                            </template>
                                            <div class="make-tips" v-if="from == 'menu'">立即制作</div>
                                        </div>
                                        <div class="ft">{{theme['name']}}</div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </template>
	</div>
</template>

<script>
	// import U from "@/util"
	export default {
		data () {
			return {
				themeTypes: [],
				themeLogos: [],
				themeTypesActive: '',
				themes: {},
				menuShow: true,
				themeHot: [],
				themeUsed: {
					tids: []
				},
			}
		},
		components: {},
		props: {
			from: {
				default: 'editor'
			}
		},
		created () {
			let params = {};

			// 从缓存中获取主题数据
			this._getThemeData().then((data) => {
				this.setMenu(data);

				// 从缓存中获取热门主题数据
				this._getThemeHot().then((hotdata) => {
					hotdata.sort(function(m, n) {
						return Math.random() > .5 ? 1 : -1;
					});
					this.themeHot = hotdata;
				})
			});
		},
		computed: {
			menuClass () {
				if (['sharemore', 'endpage'].indexOf(this.from) != -1) {
					return 'tab-menu-2';
				} else if (this.from == 'menu') {
					return 'tab-menu-3';
				} else {
					return 'tab-menu-1';
				}
			},
		},
		methods: {
			onThemeTypesClick: function(type) {
				this.menuShow = false;
				this.themeTypesActive = type;
				try {
					this.$refs.tabMenuItems.scrollTo(0, 0);
				} catch(e) {
					this.$refs.tabMenuItems.scrollTop = 0;
				}
				setTimeout(() => {
					this.menuShow = true;
				}, 100)

				// let active_type = document.getElementById(type)
				// active_type.scrollIntoView({block: "end", behavior: "smooth", inline: "center"})				
				
				let active_type = document.getElementById(type);
				active_type.scrollIntoView({
					block: "end",
					behavior: "smooth",
					inline: "center"
				})
				let param = {};
				param[type] = 'true';
				this.$mta.click('btn_theme_type', param);
			},
			onThemeSelect: function(tid) {
				// model相册固定模板
				if (this.$store.state.theme.model && this.$route.query.key && this.$store.state.theme.model == this.$route.query.key && tid != this.$store.state.theme.tid) {
					tid = this.$store.state.theme.tid;
					this.$util.$taro.showToast({
						title: '无法修改详情页模板'
					});
				}
				// 相同的tid不操作
				if (tid == this.$store.state.config.data['ttid']) {
					this.$emit('onThemeSelect', tid, false);
					return;
				}
				let _this = this;
				this.$util.checkThemeLock({
					"tid": tid
				}).then(res=>{
					if (res) {
						_this.$util.$wx.wxsdk.miniProgram.navigateTo({
							"url": "/subpackages/page/page?pid=stpage_vliti_theme_unlock&tid=" + tid + "&key=" + this.$route.query.key
						})
					} else {
						_this.$emit('onThemeSelect', tid, false);
					}
				}).catch(err=>{
					_this.$emit('onThemeSelect', tid, false);
				})
			},
			getItemCover (theme) {
				let r = 'http://resali.lititutu.cn/thumbs/'+ theme['tid'] + '.jpg';	
				if (this.from == 'menu' && theme['cover']) {
					r = 'http://resali.lititutu.cn/covers/'+ theme['tid'] + '.gif';
				}
				if (theme['cv']) {
					r += '?v=' + theme['cv'];
				}
				return r;
			},
			onHeYingClick (url) {
				window.location.href = url+'&visitor='+this.$route.query.openid;
			},
			onGuoQingHeYingClick () {
				window.location.href='/vliti/main/share?key=18d74c0509968eeecec93aff79f3f0bb&_source=wx552e3843819927e5&visitor='+this.$route.query.openid;
			},
			setMenu (response) {
				if (response['theme_type_data'] && response['theme_types']) {

					for (var i = 0; i < response['theme_types'].length ; i++) {
						var type_id = response['theme_types'][i],
						  	themes = [];
			          	for (var j = 0; j < response["theme_type_data"][type_id]["tids"].length ; j++){
			            	themes.push(response["theme"][response["theme_type_data"][type_id]["tids"][j] ])
			          	}
			          	response["theme_type_data"][type_id]["themes"] = themes;
			        }

					let datas = [], typeid;
					for (let i=0, lens=response['theme_types'].length; i<lens; i++) {
						typeid = response['theme_types'][i];
						datas.push(response['theme_type_data'][typeid]);
					}
					this.themes = response['theme'];
					this.themeTypes = datas;
					this.themeLogos = response['logo_names'];
					this.themeLogoBg = response['logo_bg'];
					this.themeTypesActive = "hot_v2";

					// 获取用户最近使用过的模板
					this.setUsed(response);
				}
			},
			setUsed (response) {
				let used = {
					"tyid": "used",
					"name": "最近<br />使用",
					"tids": [],
					"themes": [],
				},
				_this = this;
				this.$store.dispatch('setUserMsg', {
					"appid": this.from == "editor" ? this.$store.state.config.data.appid :this.$route.query._source,
					"openid": this.from == "editor" ? this.$store.state.config.data.openid:this.$route.query.openid
				}).then((usermsg) => {
					if(usermsg.tids && usermsg.tids.length > 0){
						let used_tids = usermsg.tids;
						for(let j = 0 ; j < used_tids.length ; j++){
							if(response.theme[used_tids[j]]){
								used["tids"].push(used_tids[j])
								used["themes"].push(response.theme[used_tids[j]])
							}
						}
						_this.themeUsed = used;
						_this.themeTypes.splice(2, 0, used);
					}
					// else {
					// 	let guideLog = localStorage.getItem("menu_guide_make_xc");
					// 	if(!guideLog){
					// 		_this.$emit('isFresh');
					// 		localStorage.setItem("menu_guide_make_xc", true)
					// 	}
					// }
				})
			},
			_getThemeData () {
				return new Promise((resolve, reject) => {
					if (this.$store.state['themeData']) {
						resolve(this.$store.state.themeData);
					} else {
						this.$store.dispatch('setThemeData', {}).then((response) => {
							resolve(response);
						});
					}
				})
			},
			_getThemeHot () {
				return new Promise((resolve, reject) => {
					// 从缓存中获取热门主题数据
					if (this.$store.state['themeHot']) {
						resolve(this.$store.state['themeHot']);
					} else {
						this.$store.dispatch('setThemeHot', {}).then((response) => {
							response = JSON.parse(JSON.stringify(response)).splice(0, 6);
							resolve(response);
						});
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	@menuBgColor: #f1f1f1;

	// .menu-container .tab-menu {
	// 	top: 44px!important;
	// }

	.tab-menu {
		width: 100%;
		height: 100%;
		left: 0;
	}
	.tab-menu-1 {
		position: fixed;
		top: @tabtop;
		z-index: 999;
	}
	.tab-menu-2 {
		position: relative;
	}
	.tab-menu-3 {
		position: relative;
	}

	.types {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		overflow-x: scroll;
		padding: @bw*.016 12px;
		// width: 100%;
		width: calc(100% - 24px);
		background-color: @menuBgColor;
	}

	// 隐藏横向滚动条
	::-webkit-scrollbar {
		display: none;
		width: 0px;
		height: 0px;
	}

	.type {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: white;
		padding: 0 @bw*0.03;
		margin: 0 @bw*.01;
		border-radius: @bw*.02;
		height: @bw*.15;
	}

	.type-text{
		line-height: @bw*.052;
		font-size: @bw*.044;
		font-weight: bold;
		text-align: center;
		color: #5f5f5f;
	}

	.type.active {
		background: linear-gradient(to top right,#ff9761 , #E72047);
	}
	.activetext{
		color: white;
	}
    .item-scorll-container {
        height: 100%;
    }
    .tab-menu-2 .items {
    	overflow-x: auto!important;
    	overflow-y: auto!important;
    }
	.items {
		position: relative;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		background-color: @menuBgColor;
		.scroll {
			position: relative;
			font-size: 0;
		}
	}
	.items-endpage {
	    background-color: initial!important;
	}
	.tab-menu-1 .scroll, .tab-menu-3 .scroll {
		padding-bottom: @tabtop+100;
	}
	.item:active {
		background-color: rgba(240, 79, 81, 0.44);
	}
	.tab-menu-1 .item, .tab-menu-3 .item {
	    // width: @bw*.46;
		// height: @bw*.6;
		// margin-left: @bw*.028;
		width: 156px;
		height: 222px;
        margin-left: @bw*.016;
        margin-bottom: @bw*.02;
	}
	.random-btn-bg{
		border-radius: @bw * .16;
		background: linear-gradient(to top right, #ff9761, #e72047);
		.random-btn{
			position: relative;
			margin: 5px;
			background-color: #fff;
			width: 146px;
			height: 212px;
		}
		.random-btn-image{
			position: absolute;
			left: 0;
			right: 0;
			top: 25%;
			margin: 0 auto;
			border-radius: 50%;
			width: @bw * .12;
			height: @bw * .12;
			box-shadow: 0 0 4px #aaa;
		}
		.random-btn-text{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 25%;
			margin: 0 auto;
			font-size: 24px;
			letter-spacing: 1px;
			font-weight: bold;
			line-height: 28px;
			color: #000;
			text-align: center;
		}
	}
	.tab-menu-2 .item {
		width: 132px;
		height: 222px;
		margin-left: 23px;
	}
	.tab-menu-1 .bd, .tab-menu-3 .bd {
		height: @bw*.54;
	}
	.tab-menu-2 .bd {
		height: 141.5px;
	}
	.item {
	    position: relative;
        display: inline-block;
        border-radius: 5px;
	    .box {
	    	position: absolute;
		    background-color: white;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    // width: 97%;
		    width: 148px;
		    height: 222px;
		    margin: auto;
		    border-radius: 5px;
		    overflow: hidden;
		    // display: flex;
		    // flex-direction: column;
		    .bd {
				position: relative;
				width: 148px;
				height: 182px;
				background-position: center center;
				background-size: cover;
				.tips {
					position: absolute;
					z-index: 9;
					font-size: @bw*.04;
					color: white;
					padding: @bw*.01;
			    }
				.mark {
					position: absolute;
					width: @bw*.1;
					// height: 60px;
				}
		    }
		    .ft {
				position: relative;
				background-color: white;
				display: block;
				height: 40px;
				line-height: 40px;
				font-size: 20px;
				// height: 60px;
				// line-height: 60px;
				// font-size: 29px;
				font-weight: bold;
				text-align: center;
		    }
	    }
	}

	.tab-menu-1 .scroll-content, .tab-menu-3 .scroll-content {
		padding-bottom: 50px;
	}
	.scroll-content {
		position: relative;
	}
	.tab-menu-2 .scroll-content {
		white-space: nowrap!important;
	}
	.tab-menu-2 .ft {
		font-size: 18px!important;
	}

	.wxa-items {
		position: absolute;
		width: 100%; height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		background-color: #f1f1f1;
		.scroll {
			position: relative;
			padding-bottom: @bw;
			font-size: 0;
		}
	}
	.wxa-item {
	    position: relative;
	    width: @bw*.5;
	    height: @bw*.75;
	    display: inline-block;
	    .box {
	    	position: absolute;
		    background-color: white;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    width: 95%;
		    height: 95%;
		    margin: auto;
		    border-radius: 5px;
		    overflow: hidden;
		    display: flex;
		    flex-direction: column;
		    .tips {
				position: absolute;
				z-index: 9;
				font-size: @bw*.042;
				color: white;
				padding: @bw*.014;
		    }
		    .bd {
				position: relative;
				width: 100%;
				height: @bw*.67;
				background-position: center center;
				background-size: cover;
				.mark {
					position: absolute;
					width: @bw*.14;
					height: @bw*.08;
					// display: none;
				}
		    }
		    .ft {
				position: relative;
				background-color: white;
				display: block;
				height: @bw*.08;
				line-height: @bw*.08;
				font-size: @bw*.046;
				font-weight: bold;
				text-align: center;
		    }
	    }
	}
	.tab-menu-1 .make-tips {
		font-size: @bw*.039;
		width: @bw*.244;
		height: @bw*.084;
		line-height: @bw*.084;
		margin-left: -(@bw*.122);
		bottom: @bw*.042;
	}
	.tab-menu-2 .make-tips {
		font-size: 16px;
		width: 108px;
		height: 38px;
		line-height: 38px;
		margin-left: -54px;
		bottom: 6px;
	}
	.make-tips {
		position: absolute;
		z-index: 1;
		color: white;
		background: linear-gradient(to top right,#ff9761 , #E72047);
		text-align: center;
		border-radius: @bw*.139;
		left: 50%;
	}
	.logo {
		position: absolute;
		color: #fff;
		font-size: @bw*.036;
		left: 0;
		top: 0;
		padding: @bw*.008 @bw*.016;
		line-height: @bw*.044;
		border-radius: @bw*.01;
		background: linear-gradient(to top right, #36A9D9, #F94EFF);
	}
	.used-logo{
		background: linear-gradient(to top right, #f78ca0, #fe9a8b);
	}
	.playing {
		position: absolute;
		font-size: 18px;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		padding: 8px 11px;
		right: 0;
	}
	.backtip {
		position: relative;
		display: block;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		margin-top: 80px;
		color: #878787;
		margin-bottom: 120px;
	}
</style>