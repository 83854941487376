<template>
	<div class="shan2_box po" :style="getStyles">
       <img v-for="(idx, index) in 12" 
	   		class="shan2_image" 
			:class="'shan2_'+idx" 
			:src="getLuoItem(index)" 
			:style="getLuoItemStyle(index)"
			:key="'shan2_'+idx">
	</div>
</template>

<script>
	import BaseLuo from './BaseLuo'
	export default {
		name: 'Shan2',
		extends: BaseLuo,
	}
</script>

<style>
	.shan2_box {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
	.shan2_image {
		position: absolute;
		opacity: 0;
	}
	.shan2_1 {
	    width: 40px;
	    left: 28px;
	    top: 361px;
	    animation: fadein 1s linear 0.5s infinite alternate;
	}
	.shan2_2 {
	    width: 42px;
	    left: -11px;
	    top: 387px;
	    animation: fadein 1s linear 0.3s infinite alternate;
	}
	.shan2_3 {
	    width: 43px;
	    left: 141px;
	    top: 417px;
	    animation: fadein 1s linear infinite alternate;
	}
	.shan2_4 {
	    width: 48px;
	    left: 259px;
	    top: 422px;
	    animation: fadein 1s linear infinite alternate;
	}
	.shan2_5 {
	    width: 45px;
	    left: 31px;
	    top: 467px;
	    animation: fadein 1s linear 0.2s infinite alternate;
	}
	.shan2_6 {
	    left: 87px;
	    top: 451px;
	    animation: fadein 1s linear 0.6s infinite alternate;
	}
	.shan2_7 {
	    left: 70px;
	    top: 490px;
	    animation: fadein 1s linear 0.5s infinite alternate;
	}
	.shan2_8 {
	    left: 156px;
	    top: 488px;
	    animation: fadein 1s linear 0.5s infinite alternate;
	}
	.shan2_9 {
	    left: 9px;
	    top: 521px;
	    animation: fadein 1s linear 0.2s infinite alternate;
	}
	.shan2_10 {
	    width: 50px;
	    left: 232px;
	    top: 519px;
	    animation: fadein 1s linear 0.6s infinite alternate;
	}
	.shan2_11 {
	    width: 45px;
	    left: 108px;
	    top: 538px;
	    animation: fadein 1s linear 0.7s infinite alternate;
	}
	.shan2_12 {
	    width: 50px;
	    left: -20px;
	    top: 592px;
	    animation: fadein 1s linear 0.1s infinite alternate;
	}
</style>