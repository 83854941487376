<template>
	<div class="po" 
		:style="elemStyles"
		v-if="canIshow"
		:data-id="mode['id']">

		<!-- 处理子elems嵌套 -->
		<elem v-for="(elem, index) in elems" 
			:key="'line_sub_elems_' + index"
			:currentTime="currenttime"
			:mode="elem"
			:line="mode"></elem>

		<!-- 字幕 -->
		<!-- <user-text :currentText="currentText" style="z-index: 11;"></user-text> -->
		<word
			v-if="isMain"
			:currentPhoto="currentPhoto"
			wordMode="usertext"
			id="elem-line-word" :mode='{
				"text": "#usertext#",
				"size": ["100%", 76],
				"pos": [null, null, 100, null],
				"zIndex": 11
		}'></word>

		<!-- 开场时间线事件点击区域 -->
		<!-- 显示条件：
			带有点击事件配置
			必须是开场时间线
			必须是可点击状态
			开场时间线播放未结束
		-->
		<div v-if="hasClick && !isMain && clickable && currenttime != -1" 
			class="page line-clicker" @click="onClick"></div>
	</div>
</template>

<script>
	import BaseElem from './BaseElem'

	export default {
		name: 'ElemLine',
		extends: BaseElem,
		components: {
		},
		data () {
			return {
				currenttime: 0.5, 
				runtime: 0, // 运行次数
				timer: null, // 计时器
				clickable: true, // 点击区域显示状态
			}
		},
		watch: {
			canIshow: {
				handler () {
					if (this.canIshow) {
						if (this.hasClick && this.hasClick['function'] == 'runLine') {
							setTimeout(() => {
								if (this.clickable && this.currenttime != -1) {
									this.onClick();
								}
							}, this.hasClick['params'] && this.hasClick['params']['timeout'] ? this.hasClick['params']['timeout'] : 5000);
							return;
						}
						this.runLine()
					} else {
						this.destroyLine();
					}
				},
				immediate: true
			}
		},
		created () {
			this.elemStyles = this.getElemStyles();

			// 若是开场时间线，发送关闭preview的指令
			if (!this.isMain) {
				this.$util.tdCall({
					action: "preview",
					data: "hide"
				})
			}
		},
		mounted () {},
		destroyed () {
			this.destroyLine();
		},
		computed: {
			// 是否根时间线
			isRoot () {
				if (this.mode['timing'] && !this.$store.state.theme['enter_backup']) {
					return false;
				}
				return true;
			},
			// 是否主场景时间线
			isMain () {
				let is = true;
				for (let i=0; i<this.elems.length; i++) {
					if (this.elems[i]['type'] && this.elems[i]['type'] == 'line') {
						is = false;
					}
				}
				return is;
			},
			// 是否包含点击事件
			hasClick () {
				if (!this.mode['click']) {
					return false;
				}
				return this.mode['click'];
			},
			canIshow () {
				// 根line
				if (this.isRoot) {
					return true;
				}
				// 其他line
				return this.currentTime >= this.mode['timing'] || this.currentTime == -1;
			},
		},
		methods: {
			runLine () {
				this.timer = setInterval(() => {

					// 循环节点
					if (this.currenttime == this.mode['duration']) {

						this.runtime ++;

						// 若存在循环次数，则判断并结束
						if (this.mode['count'] && this.mode['count'] == this.runtime) {
							clearInterval(this.timer);
							this.currenttime = -1;
							// 开场时间线结束，发送打开preview的指令
							this.$util.tdCall({
								action: "preview",
								data: "show"
							})
							return;
						} else {
							// 非开场时间线才记录模板runtime
							this.$store.commit("incrThemeRuntime");
							this.currenttime = 0;
						}
					}

					this.currenttime += .5;
				}, 500);
			},
			destroyLine () {
				clearInterval(this.timer);
			},
			onClick () {
				if (this.mode['click']) {
					switch(this.mode['click']['function']) {
						case 'runLine':
							this.clickable = false;
							this.runLine();
							break
					}
				}
			}
		}
	}
</script>

<style scoped>
	.line-clicker {
		z-index: 1;
	}
</style>