<template>
    <div class="zimu2_con po oh" :style="__getStyle">
        <div class="subtitle-animate" :style="getZimuStyle">
            <!-- 2022.11.21 由于微信登陆逻辑改动，取消zimu组件中用户头像的使用 -->
            <!-- <div class="avatarUrl-container" v-if="$store.state.userInfo && $store.state.userInfo.headimgurl">
                <img
                    v-if="$store.state['userInfo']"
                    mode="widthFix"
                    class="avatarUrl" :src="headimgurl" />
            </div> -->
            <span class="subtitle-text" :style="getTextStyle" v-html="getZimu"></span>
        </div>
    </div>
</template>

<script>
	import BaseElem from "@/components/st_comps/st_comp/comps/StElem/BaseElem"
    export default {
        name: 'zimu2',
        extends: BaseElem,
        computed: {
            headimgurl () {
                return this.$store.state.userInfo.headimgurl;
            },
            __getStyle(){
                let s = {};
                if (this.$store.state.hasUsertext
                    && this.$store.state.hasUsertext != 0
                    && !this.mode['withtext']
                    && (!this.comp_config || !this.comp_config[4])){
                    s = {
                        "display": "none"
                    }
                    return s;
                }
                s = Object.assign(s, this.getStyles)

                s = this.$util.pxTransform(s, "style");

                return s;
            },
            getZimuStyle () {
                let item = this.mode;
                
                let s = {
                    "justifyContent": "center",
                    "flexDirection": "column",
                    "alignItems": "center",
                    "alignContent": "center",
                    "display": "flex"
                };

                // 绑定动画
                if (item['itemkeyframe']) {
                    s = Object.assign(s, this.getKeyframeStyle(item['itemkeyframe']));
                }

                if (item['itemStyle']) {
                    s = Object.assign(s, item['itemStyle']);
                }

                // 适配组件库
                // 绑定动画
                if (this.comp_config && this.comp_config[2]) {
                    s = Object.assign(s, this.getKeyframeStyle(this.comp_config[2]));
                }
                // 设置style
                if (this.comp_config && this.comp_config[1]) {
                    s = Object.assign(s, this.comp_config[1]);
                }

                s = this.$util.pxTransform(s, "style");

                return s;
            },
            getTextStyle() {
                let s = {};

                if (this.mode['textStyle']){
                    s = this.mode['textStyle'];
                }

                // 设置style
                if (this.comp_config && this.comp_config[3]) {
                    s = Object.assign(s, this.comp_config[3]);
                }

                s = this.$util.pxTransform(s, "style");

                return s;
            },
            getZimu() {
                let zimu = "",
                    mode_tx = this.mode['tx'];
                if(mode_tx){
                    if (this.mode['tx'] && this.$util.typeOf(mode_tx) == 'array') {
                        for (let i=0; i<this.mode.tx.length; i++) {
                            zimu = zimu + this.mode.tx[i].split('').join('<br>');
                        }
                    } else {
                        zimu = mode_tx;
                    }
                    zimu = zimu.replace(/\n/g,"<br>");
                }
                
                // 适配组件库
                if (this.comp_config && this.comp_config[0]) {
                    let temp_txs = [];
                    if (this.$util.typeOf( this.comp_config[0] ) == "string") {
                        temp_txs = [ this.comp_config[0] ];
                    } else if (this.$util.typeOf( this.comp_config[0] ) == "array"){
                        temp_txs = this.comp_config[0];
                    }
                    for (let i = 0; i < temp_txs.length ; i ++) {
                        if (i != (temp_txs.length -1)) {
                            zimu = zimu + "<br>" + temp_txs[i];
                        } else {
                            zimu = zimu + temp_txs[i];
                        }
                    }
                }

                if (this.$util.isEnv("wxa")) {
                    zimu = zimu.replace(/<br>/g,"\n");
                }

                zimu = this.$util.replaceKeyWords(zimu);
                return zimu;
            },
        }
    }
</script>

<style>

.zimu2_con .subtitle-text {
    opacity: 1;
    font-size: 32px;
    display: inline-block;
    white-space: pre-line;
    animation-delay: 2s;
    line-height: 60px;
    font-family: "arial", "微软雅黑";
    font-weight: bold;
    text-shadow: #fff 1px -1px 0,
                    #fff 0 -1px 0,
                    #fff 1px 1px 0,
                    #fff 1px 0 0,
                    #fff 1px 1px 0,
                    #fff 0 1px 0,
                    #fff -1px 1px 0,
                    #fff -1px 0 0,
                    #0000 1px 1px 0,
                    #0000 1px 1px 0,
                    #0000 2px 2px 0,
                    #000 1px 1px 3px;
}
.zimu2_con .avatarUrl, .zimu2_con .avatarUrl-container{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    margin-bottom: 10px;
}
.zimu2_con .avatarUrl-container{
    background-color: rgba(0, 0, 0, 0);
}
</style>