<template>
	<div class="tc-container">
		<div class="tc-mask" @click="hideTab('mask')"></div>
		<div class="tcsave">
			<div class="tcsave-1">
				<img class="success-icon" src="http://resali.lititutu.cn/icons/other/a23faa0ee464d4ae55d3ed03c0708249.png">
				<img class="success-tip" src="http://litiresource.xcgogo.site/user/11812637/images/c22ce3e5048803d8307743714bfd646b.png">
				<!-- <span class="to-share-tips">快发给朋友们看看吧</span> -->
			</div>
			<div class="tcsave-2">
				<span class="to-other-tips" style="color: rgba(238,141,43,1);">您还可以</span>
				<div class="save-other" @click="onMybookClick">
					<div class="save-other-cont">
						<img class="save-other-1" src="http://litiresource.xcgogo.site/user/11812637/images/41b5c3933e7928f7b7cec00635be1338.png">
						<img class="save-other-2" src="http://litiresource.xcgogo.site/user/11812637/images/cfebbea8726707aec1054ee3b77959f9.png">
					</div>
				</div>

				<div class="save-other" @click="hideTab('edit')" v-if="showBackEdit">
					<div class="save-other-cont">
						<img class="save-other-1" src="http://litiresource.xcgogo.site/user/11812637/images/89d335e32cdf7803a690021647a7a5b2.png">
						<img class="save-other-2" src="http://litiresource.xcgogo.site/user/11812637/images/af1c00cc8d9be7917632a9b13767d6d7.png">
					</div>
				</div>
				<div class="save-other" @click="onDownloadClick" v-else>
					<div class="save-other-cont">
						<img class="save-other-1" src="http://resali.lititutu.cn/icons/app/download_icon.png">
						<img class="save-other-2" src="http://resali.lititutu.cn/icons/app/download_btn.png">
					</div>
				</div>
			</div>
			
			<img class="save-close" src="http://resali.lititutu.cn/icons/app/close2.png" @click="hideTab('close')">
		</div>
	</div>	
</template>

<script>
	export default {
		name: 'TabSave',
		data () {
			return {
				items: []
			}
		},
		computed: {
			showBackEdit () {
				try {
					if (this.$store.state.themes[ this.$store.state.config['data']['ttid'] ]['scene']) {
						return false;
					} else {
						return true;
					}
				} catch (e) {
					return true;
				}
			}
		},
		created () {},
		mounted () {},
		methods: {
			hideTab (from) {
				let p = {};
				p[from] = 'true';
				this.$mta.click("btn_to_edit", p);

				this.$emit('hideTab');
			},
			onMybookClick () {
				this.$mta.click("btn_to_mybook");

				// 2021.12.17下线所有引流服务号入口
				window.location.href = "/vliti/main/mybook?openid="+this.$store.state.config.data['openid']+"&_source="+this.$store.state.config.data['appid'];
				// this.$store.dispatch('getUserInfo').then(userinfo => {
				// 	if (userinfo['unionid'] && this.$config.debugHost.indexOf(location.host) == -1) {
				// 		// 跳转落地页
				// 		window.location.href = "https://mp.weixin.qq.com/s/JWvtKO_Y9riREJxJvdGXtw";
				// 	} else {
				// 		// 跳转管理页
				// 		window.location.href = "/vliti/main/mybook?openid="+this.$store.state.config.data['openid']+"&_source="+this.$store.state.config.data['appid'];
				// 	}
				// })
			},
			onDownloadClick () {
				this.$mta.click("btn_to_downloadbook");

				this.$toast.loading({
					message: '加载中...',
					duration: 'infinite'
				});

				this.$o_util.getWxaQrcode(this.$route.query.key, 'pages/savePage/savePage').then((qrcode) => {
					this.$toast.clear();

					this.hideTab('download_qrcode');

					this.$toast.qrcode({
						isMask: true,
						bgSrc: 'http://resali.lititutu.cn/icons/app/download_bg.png',
						qrcodeSrc: qrcode
					})
				});
			}
		}
	}
</script>

<style scoped>
	.tc-container {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 9999;
		background-color: rgba(0, 0, 0, 0.6);
	}
	.tc-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		background-color: rgba(0,0,0,.72);
	}
	.tcsave {
		position: absolute;
		width: 430px;
		height: 430px;
		top: 50%; left: 50%;
		margin-top: -215px;
		margin-left: -215px;
		z-index: 2;
	}
	.tcsave>div {
		position: relative;
		width: 100%;
	}
	.tcsave-1 {
		height: 145px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background-color: #FFE2C5;
	}
	.tcsave-2 {
		height: 215px;
		background-color: #FFFFFF;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.success-tip {
		position: absolute;
		/* top: 68px; */
		top: 50%;
		left: 50%;
		margin-left: -80px;
		width: 160px;
		height: 42px;
	}
	.success-icon {
		position: absolute;
		width: 140px;
		height: 140px;
		top: -70px;
		left: 50%;
		margin-left: -70px;
	}
	.to-share-tips {
		position: absolute;
		width:430px;
		text-align: center;
		font-size:25px;
		font-weight:bold;
		color:#c78644;
		opacity:1;
		bottom: 10px;
	}
	.to-other-tips {
		position: absolute;
		top: 0px;
		width: 430px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 20px;
		font-weight: 500;
		opacity: 1;
	}
	.save-other {
		position: relative;
		display: inline-block;
		width: 50%;
		height: 100%;
	}
	.save-other-cont {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.save-other-1 {
		height: 57px;
		margin-bottom: 10px;
	}
	.save-other-2 {
		width: 160px;
		height: 46px;
		margin-top: 10px;
	}
	.save-close {
		position: absolute;
		width: 50px;
		height: 50px;
		color: #cacaca;
		font-size: 25px;
		font-weight: bold;
		text-align: center;
		line-height: 34.5px;
		border-radius: 50%;
		left: 50%;
		margin-left: -25px;
		bottom: 0px;
	}
</style>