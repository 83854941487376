<template>
	<div>

		<div class="promo">
			<template v-for="item in promoWxaItems">
				<div class="promo-item" :style="{backgroundImage: 'url('+item['cover']+')'}" @click="onPromoClick(item['url'])">
					<!-- <img class="promo-item-icon" src="http://litiresource.xcgogo.site/user/6403275/images/90bf88de070edba644c8f15e7147e3ce.png"> -->
					<span class="promo-item-title">
						{{item['name']}}
					</span>
				</div>
			</template>

			<div class="promo-item" :data-appid="getZhufuAppid" :data-scene="getZhufuScene" @click="onPromoClick('zhufu')" ref="promo-zhufu">
				<img class="promo-item-icon" src="http://litiresource.xcgogo.site/user/6403275/images/90bf88de070edba644c8f15e7147e3ce.png">
				<span class="promo-item-title">
					每日祝福
				</span>
			</div>
			<div class="promo-item" data-appid="wxba7cee6e3846b66e" data-scene="0" @click="onPromoClick('book')" ref="promo-book">
				<img class="promo-item-icon" src="http://litiresource.xcgogo.site/user/6403275/images/90bf88de070edba644c8f15e7147e3ce.png">
				<span class="promo-item-title">
					精选美文
				</span>
			</div>
		</div>

		<PromoScan v-show="isPromoScanShow" :promoFingerImage="promoFingerImage" @onPromoScanHide="onPromoScanHide" :promoQrcode="promoQrcode" :promoFrom="promoFrom"></PromoScan>
	</div>
</template>

<script>

	import PromoScan from '@/components/promo/PromoScan.vue'
	import U from '@/util'

	let G = {
		promo: {
			'wxba7cee6e3846b66e': {
				'name': '甜豆图文',
				't': 'book',
				'scene': [
					{
						'name': '首页',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wxba7cee6e3846b66e_read.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/6403275/images/6d10322ac83654dec4fbdbebfa227f45.png',
						'cover': [
							'http://litiresource.xcgogo.site/user/6403275/images/f51ac11bffefde3d82285d342030f4c7.gif',
							'http://litiresource.xcgogo.site/user/6403275/images/678a368c8fbbe65e84a61a1a4fb8facb.gif',
							'http://litiresource.xcgogo.site/user/6403275/images/9c2002989c12b5562727f07882981e46.gif',
							'http://litiresource.xcgogo.site/user/6403275/images/9c2002989c12b5562727f07882981e46.gif',
							'http://litiresource.xcgogo.site/user/6403275/images/1a276c6e1a51056124bb749bf3c2d0a0.gif',
							'http://litiresource.xcgogo.site/user/6403275/images/78e88a68090298a30f527a3bc47764b9.gif'
						]
						// 'cover': 'http://litiresource.xcgogo.site/user/6403275/images/e7afb3c584b41f157c106dda38e8bc0d.jpg'
					}
				]
			},
			'wx4cf27a2def3b0d52': {
				'name': '开心节日祝福',
				't': 'zhufu',
				'scene': [
					{
						'name': '早上好',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wx4cf27a2def3b0d52_id_98.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/057320128e81123fc534175b64c3d4b9.png',
						'cover': 'http://litiresource.xcgogo.site/user/6403275/images/8e25ffc29030c9cdbd0be04f6af7bfe9.gif'
					},
					{
						'name': '晚安问候',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wx4cf27a2def3b0d52_id_99.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/904c6cb251a3b9d37b68a0a1e6df3931.png',
						'cover': 'http://litiresource.xcgogo.site/user/6403275/images/0555bdee329b8698fb1832f1451b5226.gif'
					},
					{
						'name': '友谊天长地久',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wx4cf27a2def3b0d52_id_101.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/e9b019f416299035a0f76f464b8bddee.png',
						'cover': 'http://resali.lititutu.cn/tuku/prod/dc5ed0d80749fd3e5d3faccfa5887993.gif'
					},
					{
						'name': '群相册',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wx4cf27a2def3b0d52_id_120.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/2653ee18407c0014c6de289981463790.png',
						'cover': 'http://litiresource.xcgogo.site/user/6403275/images/69648211ee0584f9bc6c0b87baa2aee7.gif'
					},
					{
						'name': '祝福群友',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wx4cf27a2def3b0d52_id_100.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/9051315d30304dc690be547d32566a05.png',
						'cover': 'http://litiresource.xcgogo.site/user/9546085/images/ad38b958147d78602b9a77999ce5e0f8.gif'
					},
					{
						'name': '祝福送朋友',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wx4cf27a2def3b0d52_id_104.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/4511b73c58a2643612e1154ffc06d66f.png',
						'cover': 'http://litiresource.xcgogo.site/user/9546085/images/ad38b958147d78602b9a77999ce5e0f8.gif'
					}
				]
			},
			'wxedb5b600afb45f1b': {
				'name': '心语祝福集',
				't': 'zhufu',
				'scene': [
					{
						'name': '早上好',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wxedb5b600afb45f1b_id_127.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/6403275/images/8c9a2f2cb7ba94f7b432c8ef19391bd3.png',
						'cover': 'http://litiresource.xcgogo.site/user/6403275/images/8e25ffc29030c9cdbd0be04f6af7bfe9.gif'
					},
					{
						'name': '晚安问候',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wxedb5b600afb45f1b_id_128.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/6403275/images/76040489795f3ac9fb58128560be4361.png',
						'cover': 'http://litiresource.xcgogo.site/user/6403275/images/0555bdee329b8698fb1832f1451b5226.gif'
					},
					{
						'name': '友谊天长地久',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wxedb5b600afb45f1b_id_129.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/6403275/images/8063e0fbb53e3c1d3a351cb43c904e5f.png',
						'cover': 'hhttp://resali.lititutu.cn/tuku/prod/dc5ed0d80749fd3e5d3faccfa5887993.gif'
					},
					{
						'name': '群相册',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wxedb5b600afb45f1b_id_150.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/6403275/images/712217341a31056ea4248565bbbb4001.png',
						'cover': 'http://litiresource.xcgogo.site/user/6403275/images/69648211ee0584f9bc6c0b87baa2aee7.gif'
					},
					{
						'name': '祝福群友',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wxedb5b600afb45f1b_id_130.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/9072a5beea3c34f2db1f3fb34ccb4201.png',
						'cover': 'http://litiresource.xcgogo.site/user/9546085/images/ad38b958147d78602b9a77999ce5e0f8.gif'
					},
					{
						'name': '祝福送朋友',
						'ori_qrcode': 'http://alioss.lititutu.cn/qrcode/wxedb5b600afb45f1b_id_131.png',
						'qrcode': 'http://litiresource.xcgogo.site/user/9546085/images/ef31eee4a23a52716857ccba7f9441b0.png',
						'cover': 'http://litiresource.xcgogo.site/user/9546085/images/ad38b958147d78602b9a77999ce5e0f8.gif'
					}
				]
			}
		},
		promo_wxa: [
			{
				'name': '甜豆相册',
				'cover': 'http://litiresource.xcgogo.site/user/5980171/images/74b77f75cc0ddb05e938611036c99942.png',
				// 'url': 'https://mp.weixin.qq.com/s/42G0vFrsaBbob-K_bwthTw',
				'url': 'https://mp.weixin.qq.com/s/6Qj_Qu8fXZPqD8ebU_ilgA',
			},
			{
				'name': '神笔特效',
				'cover': 'http://litiresource.xcgogo.site/user/5980171/images/820eb5cb5339b9cdf043e2b235d8dc20.jpg',
				'url': 'https://mp.weixin.qq.com/s/32Qdv3X32Bypau4ZZBG93g'
			},
			{
				'name': '变脸换装',
				'cover': 'http://litiresource.xcgogo.site/user/5980171/images/b7c0e2d7cf4adb0005d1add4216f6da3.jpg',
				'url': 'https://mp.weixin.qq.com/s/MrKF1g-53QsIskkUbAKlIw'
			},
			{
				'name': '时代列车',
				'cover': 'http://litiresource.xcgogo.site/user/5980171/images/b7f635259c4964cc0281c8d9dae4000d.png',
				'url': 'https://mp.weixin.qq.com/s/zx6AevWQp30Hx7apx6JlKA'
			}
		]
	}

	export default {
		name: 'PromoItem',
		props: {
			num: {
				default: 0
			}
		},
		data () {
			return {
				isPromoScanShow: false,
				photoDownQrcode: '',
				promoQrcode: '',
				promoFingerImage: '',
				promoFrom: ''
			}
		},
		computed: {
			getZhufuAppid () {
				return 'wx4cf27a2def3b0d52';
				// if (Math.random() > .5) {
				// 	return 'wxedb5b600afb45f1b';
				// } else {
				// 	return 'wx4cf27a2def3b0d52';
				// }
			},
			getZhufuScene () {
				let hour = new Date().getHours();
				if (hour < 12 && hour > 5) {
					return '0';
				}
				else if (hour >= 12 && hour < 18) {
					if (Math.random() > .5) {
						return '2';
					} else {
						return '3';
					}
				} else {
					return '1';
				}
			},
			promoWxaItems () {
				let items = this.$o_util.randArray(JSON.parse(JSON.stringify(G.promo_wxa)));
				let r = [];
				for (let i=0; i<parseInt(this.num); i++) {
					r.push(items[i])
				}
				return r;
			}
		},
		mounted () {
			// 设置promo封面
			// 祝福
			let zhufu_obj = this.$refs['promo-zhufu'];
			let appid = zhufu_obj.getAttribute('data-appid'),
				scene = zhufu_obj.getAttribute('data-scene');

			// this.$util.rget('https://g0.litiskr.cn/blessarti/get_hottest', {
			// console.log(hottest_covers['bless']);
			// if (!hottest_covers['bless']) {
				// hottest_covers['bless'] = true;

				// this.$util.rget('https://g5.litiskr.cn/blessarti/get_hottest', {
				// 	'_type': 'ajax',
				// 	't': 'bless'
				// }).then((res) => {
				// 	hottest_covers['bless'] = res['coverImg'];
				// 	zhufu_obj.style.backgroundImage = 'url('+ hottest_covers['bless'] +')';
				// }).catch((res) => {
				// 	hottest_covers['bless'] = G.promo[appid]['scene'][parseInt(scene)]['cover'];
				// 	zhufu_obj.style.backgroundImage = 'url('+ hottest_covers['bless'] +')';
				// })
			// }
			// this.$store.dispatch('getPromoCover', {
			// 	't': 'bless'
			// }).then((res) => {
			// 	zhufu_obj.style.backgroundImage = 'url('+ res +')';
			// }).catch((err) => {
			// 	zhufu_obj.style.backgroundImage = 'url('+ G.promo[appid]['scene'][parseInt(scene)]['cover'] +')';
			// })
			zhufu_obj.style.backgroundImage = 'url('+ G.promo[appid]['scene'][parseInt(scene)]['cover'] +')';
			
			// 中秋
			// zhufu_obj.style.backgroundImage = 'url(http://litiresource.xcgogo.site/user/5980171/images/feb6cee8dfbfaf70d093ce45fe166efa.gif)';

			// 国庆
			// let bgs = [
			// 	'http://litiresource.xcgogo.site/user/5980171/images/54697dcdee129541d8a391b5941fea17.gif',
			// 	'http://litiresource.xcgogo.site/user/5980171/images/00b5ee535cc8ff06153ee94c7f3ae96d.gif',
			// 	'http://litiresource.xcgogo.site/user/5980171/images/5753c0e01c4cb7651b44190c87ca18d4.gif',
			// 	'http://litiresource.xcgogo.site/user/5980171/images/e9b16b6df6bde665ae68442d8e0af22d.gif',
			// ];
			// zhufu_obj.style.backgroundImage = 'url('+bgs[this.$o_util.randInt()%bgs.length]+')';
			
			// 图文
			let book_obj = this.$refs['promo-book'];
			let bappid = book_obj.getAttribute('data-appid'),
				bscene = book_obj.getAttribute('data-scene');
			
			if (Object.prototype.toString.call(G.promo[bappid]['scene'][parseInt(bscene)]['cover']).toLowerCase() == '[object array]') {
				book_obj.style.backgroundImage = 'url('+ G.promo[bappid]['scene'][parseInt(bscene)]['cover'][ parseInt(Math.random()*100000) % G.promo[bappid]['scene'][parseInt(bscene)]['cover'].length ] +')';
			} else {
				book_obj.style.backgroundImage = 'url('+ G.promo[bappid]['scene'][parseInt(bscene)]['cover'] +')';
			}
			// this.$util.rget('https://g0.litiskr.cn/blessarti/get_hottest', {
			// if (!hottest_covers['arti']) {
			// 	hottest_covers['arti'] = true;
			// 	this.$util.rget('https://g5.litiskr.cn/blessarti/get_hottest', {
			// 		'_type': 'ajax',
			// 		't': 'arti'
			// 	}).then((res) => {
			// 		hottest_covers['arti'] = res['coverImg'];
			// 		book_obj.style.backgroundImage = 'url('+ hottest_covers['arti'] +')';
			// 	}).catch((err) => {
			// 		hottest_covers['arti'] = G.promo[bappid]['scene'][parseInt(bscene)]['cover'];
			// 		book_obj.style.backgroundImage = 'url('+ hottest_covers['arti'] +')';
			// 	})
			// }
			// this.$store.dispatch('getPromoCover', {
			// 	't': 'arti'
			// }).then((res) => {
			// 	console.log('-1', res);
			// 	book_obj.style.backgroundImage = 'url('+ res +')';
			// }).catch((err) => {
			// 	console.log('-2');
			// 	book_obj.style.backgroundImage = 'url('+ G.promo[bappid]['scene'][parseInt(bscene)]['cover'] +')';
			// })
			
			// 中秋
			// book_obj.style.backgroundImage = 'url(http://litiresource.xcgogo.site/user/5980171/images/280a71bcba198f70c422c67b2d898b29.gif)';

			// 国庆
			// book_obj.style.backgroundImage = 'url(http://litiresource.xcgogo.site/user/5980171/images/dd626edf01f7a0a4158449f6d475c86c.gif)';

		},
		methods: {
			onPromoScanShow (e) {
				
				let node = e.target;
				if (['promo-item-title', 'promo-item-icon'].indexOf(e.target.className) != -1) {
					node = e.target.parentNode;
				}

				this.isPromoScanShow = true;
				this.promoFingerImage = "http://litiresource.xcgogo.site/user/6403275/images/ce8a786ca2338b0743e123d89c411013.gif";

				let _this = this;

				let appid = node.getAttribute('data-appid'),
					scene = node.getAttribute('data-scene');

				let t = G.promo[appid]['t'];

				let qrcode = G.promo[appid]['scene'][parseInt(scene)]['qrcode'];
				this.promoQrcode = qrcode;

				this.$mta.click('mybook_promo_'+t);

				this.promoFrom = t;

			},
			onPromoScanHide () {
				this.isPromoScanShow = false;
			},
			onPromoClick (f) {
				if (f.indexOf('http') != -1) {
					window.location.href = f;
					return;
				}

				let url = '';
				if ('zhufu' == f) {
					let zhufu_urls = [
						'https://mp.weixin.qq.com/s/_GepJy9uJmfimK7TgqoJaQ', // 开心节日祝福
						'https://mp.weixin.qq.com/s/NwVdjPr97MilSIzCFQetwQ', // 心语祝福集
					];
					url = zhufu_urls[Math.round(Math.random()*10000)%zhufu_urls.length];
				} else if ('book' == f) {
					// let book_urls = [
					// 	'https://mp.weixin.qq.com/s/oIxZBL2ubBHDphJiiYAfzw', // 群友趣闻 1
					// 	'https://mp.weixin.qq.com/s/unr-Oqn3JL3kAKKGg82vrw', // 群友趣闻 2
					// 	'https://mp.weixin.qq.com/s/ziliGOJP3sp-VdoUe1zJag', // 甜豆图文 1
					// 	'https://mp.weixin.qq.com/s/-N5oIA3Tve_Egvt_kw5jOg', // 甜豆图文 2
					// ];
					// url = book_urls[Math.round(Math.random()*10000)%book_urls.length];
					// url = 'https://mp.weixin.qq.com/s/ZzwbLS7fdagkr4Vf9oRY9w';

					// 日常
					// url = 'https://mp.weixin.qq.com/s/ziliGOJP3sp-VdoUe1zJag';
					// url = 'https://mp.weixin.qq.com/s/6Qj_Qu8fXZPqD8ebU_ilgA';
					url = 'https://mp.weixin.qq.com/s/bruCcZs3E3nhmfnCgBZ5VA';

					// 国庆
					// url = 'https://mp.weixin.qq.com/s/fvlJVqOjiLBzdxSLnhGvpw'; // 甜豆图文
				}

				this.$mta.click('mybook_promo_'+f);

				setTimeout(() => {
					window.location.href = url;
				}, 300);
			}
		},
		components: {PromoScan}
	}
</script>

<style lang="less" scoped>
	.promo-item-icon {
		position: absolute;
		width:@bw * 0.111;
		height:@bw * 0.111;
		left: 50%;
		top: 50%;
		margin-left: -@bw * 0.056;
		margin-top: -@bw * 0.056;
		z-index: 3;
	}
	.promo-item-title {
		position: absolute;
		width: 100%;
		bottom: 0;
		color: rgba(255, 255, 255, 0.78);
		text-align: center;
		bottom: -@bw * 0.062;
		font-size:@bw * 0.035;
		background-color: rgba(0, 0, 0, 0.65);
		height:@bw * 0.062;
		font-weight: bold;
		line-height:@bw * 0.062;
	}
	.promo {
		position: relative;
		// height:@bw * 0.292;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-wrap: wrap;
	}
	.promo-item {
		position: relative;
		width: 47%;
		height:@bw * 0.292;
		background-size: cover;
		background-position: center;
	    margin-bottom:@bw * 0.083;
	    background-color: black;
	}
</style>