<template>
	<div class="lifangti poc">
	    <div class="lifangti_per poc" :style="getStyle">
	        <div class="lifangti_div4 preserve3d poc">
	            <div class="preserve3d poc">
	                <div class="lifangti_div4_1 preserve3d poc">
	                    <div class="lifangti_div4_trz lifangti_div bg bg_photo poc" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')', borderImageSource: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="lifangti_div4_2 preserve3d poc">
	                    <div class="lifangti_div4_trz lifangti_div bg bg_photo poc" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')', borderImageSource: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="lifangti_div4_3 preserve3d poc">
	                    <div class="lifangti_div4_trz lifangti_div bg bg_photo poc" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')', borderImageSource: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="lifangti_div4_4 preserve3d poc">
	                    <div class="lifangti_div4_trz lifangti_div bg bg_photo poc" @click="wxPreview" :style="{backgroundImage: 'url('+ currentImage['im'] +')', borderImageSource: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <div class="lifangti_div4_5 preserve3d poc" style="width: 436px; height: 436px;">
	                    <div class="lifangti_top lifangti_div4_trz poc" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                </div>
	                <!-- <div class="lifangti_div4_6 preserve3d poc" style="width: 436px; height: 436px;">
	                    <div class="lifangti_div4_trz poc">
	                        <div class="lifangti_bottom po" :style="{backgroundImage: 'url('+ mode[1] +')'}"></div>
	                    </div>
	                </div> -->
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
	export default {
		name: 'lifangti',
		props: ['mode', 'currentImage', 'showStatus'],
		data () {
			return {}
		},
		computed: {
			getStyle () {
				if (this.showStatus) {
					let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [436, 600], 'widthFix');

					return {
						width: tsize[0] + 'px',
						height: tsize[1] + 'px'
					}
				} else {
					return {
						width: '436px',
						height: '330px'
					}
				}
			}
		},
		methods: {
			wxPreview () {
				this.$emit('wxPreview', this.currentImage)
			}
		}
	}
</script>

<style>
	.lifangti_per {
	    -webkit-perspective: 1500px;
	    -webkit-perspective-origin: 50% -100px;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-animation: lifangti_per 2s ease-out both;
	}
	@keyframes lifangti_per {
		0% {
		    -webkit-transform: translateX(500px);
		}
		100% {
		    -webkit-transform: translateX(0);
		}
	}
	.lifangti_div4 {
		-webkit-animation: lifangti_div4 12s linear infinite;
	}
	@keyframes lifangti_div4 {
		0% {
		    -webkit-transform: translateZ(-218px) rotateY(0deg);
		}
		100% {
		    -webkit-transform: translateZ(-218px) rotateY(360deg);
		}
	}
	.lifangti_div {
		border-width: 8px;
		border-style: solid;
		border-color: #FFFFFF;
		/*border-image-source: url(http://image1.17ll.com/gcard/857/016.jpg);*/
		border-image-slice: 8;
		/*background-size: cover;
		background-position: 50% 10%;
		background-clip: content-box;*/
		background-color: #FFFFFF;
		width: calc(100% - 16px)!important;
	}
	.lifangti_div4_1 {
		-webkit-transform: rotateY(90deg);
	}
	.lifangti_div4_2 {
		-webkit-transform: rotateY(180deg);
	}
	.lifangti_div4_3 {
		-webkit-transform: rotateY(270deg);
	}
	.lifangti_div4_4 {}
	.lifangti_div4_5 {
		-webkit-transform: rotateX(90deg);
	}
	.lifangti_div4_6 {
		-webkit-transform: rotateX(-90deg);
	}
	.lifangti_div4_trz {
		-webkit-transform: translateZ(218px);
	}
	.lifangti_bottom {
		width: 100%;
		height: 100%;
		/*background-image: url(http://image1.17ll.com/gcard/857/015.jpg);*/
		background-size: cover;
	}
	.lifangti_top {
		/*background-image: url(http://image1.17ll.com/gcard/857/015.jpg);*/
		background-size: cover;
	}
</style>