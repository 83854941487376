<template>
    <div class="daoshu-container po fadeIn pointer" :style="__getStyle" v-show="leftd.length > 1 || lefth.length > 1 || leftm.length > 1 || lefts.length > 1">
        <div class='date-container re-container' v-show="leftd.length > 1 || leftd[0] != 0">
            <img
                v-for="(date, index) in leftd"
                class="date-items"
                mode="widthFix"
                :key="'date_' + index"
                :src="'http://resali.lititutu.cn/tuku/date1_' + date + '.png'" />
            <img
                class="date-end"
                mode="widthFix"
                src="http://resali.lititutu.cn/tuku/8c2fcf733c0b7451dcdcf4d6d836095d.png" />
        </div>
        <div class='countdown-container re-container'>
            <div class="countdown-bg re-container">
                <img
                    v-for="(hour, index) in lefth"
                    class="countdown-items"
                    mode="widthFix"
                    :key="'hour_' + index"
                    :src="'http://resali.lititutu.cn/tuku/date2_' + hour + '.png'" />
                <img
                    class="countdown-end"
                    mode="widthFix"
                    src="http://resali.lititutu.cn/tuku/9b6912f4d32ce85cdc0e7a907c93a510.png" />
            </div>
            <div class="countdown-bg re-container">
                <img
                    v-for="(min, index) in leftm"
                    class="countdown-items"
                    mode="widthFix"
                    :key="'min_' + index"
                    :src="'http://resali.lititutu.cn/tuku/date2_' + min + '.png'" />
                <img
                    class="countdown-end"
                    mode="widthFix"
                    src="http://resali.lititutu.cn/tuku/27473cba1ba6e4210d2ac4c55d239308.png" />
            </div>
            <div class="countdown-bg re-container">
                <img
                    v-for="(sec, index) in lefts"
                    class="countdown-items"
                    mode="widthFix"
                    :key="'sec_' + index"
                    :src="'http://resali.lititutu.cn/tuku/date2_' + sec + '.png'" />
                <img
                    class="countdown-end"
                    mode="widthFix"
                    src="http://resali.lititutu.cn/tuku/06460e05175191469e0f9f8bb9b9a393.png" />
            </div>
        </div>
    </div>
</template>

<script>
	import BaseElem from "@/components/st_comps/st_comp/comps/StElem/BaseElem"
	export default {
        name: 'daoshu',
        extends: BaseElem,
		data () {
			return {
                count_down_started: false,
                leftd: ['0'],
                lefth: ['0'],
                leftm: ['0'],
                lefts: ['0'],
            }
        },
        countDownInterval: null,
        destroyed () {
            if (this.countDownInterval) {
                clearInterval(countDownInterval);
            }
        },
        mounted () {
            let _this = this;
            let date = this.mode && this.mode.date ? this.mode.date : ( this.comp_config && this.comp_config[0]? this.comp_config[0] : this.$util.getFormateTime(new Date(), "yyyy-MM-dd"));
            this.countDownInterval = setInterval(() => {
                _this.startCountDown(new Date(date));
            }, 1000);
            setTimeout(() => {
                _this.count_down_started = true;
            }, 1000);
        },
        computed: {
            __getStyle(){
                let s = {};
                if (this.$store.state.hasUsertext
                    && this.$store.state.hasUsertext != 0
                    && !this.mode['withtext']
                    && (!this.comp_config || !this.comp_config[1])){
                    s = {
                        "display": "none"
                    }
                    return s;
                }
                s = Object.assign(s, this.getStyles)
                return s;
            },
        },
        methods:{
            startCountDown (endtime) {
                var nowtime = new Date();
                var lefttime = endtime.getTime() - nowtime.getTime(),  //距离结束时间的毫秒数
                    leftd = Math.floor(lefttime/(1000*60*60*24)),  //计算天数
                    lefth = Math.floor(lefttime/(1000*60*60)%24),  //计算小时数
                    leftm = Math.floor(lefttime/(1000*60)%60),  //计算分钟数
                    lefts = Math.floor(lefttime/1000%60);  //计算秒数
                this.leftd = this.getSplitNum(leftd);
                this.lefth = this.getSplitNum(lefth);
                this.leftm = this.getSplitNum(leftm);
                this.lefts = this.getSplitNum(lefts);
            },
            getSplitNum(num){
                let ge = 0;
                let shi = 0;
                let bai = 0;
                if(num < 10){
                    return [0,num];
                } else if(num >= 10 && num <100){
                    ge = num % 10;
                    shi = parseInt(num/10) % 10;
                    return [shi, ge];
                } else {
                    ge = num % 10;
                    shi = parseInt(num/10) % 10;
                    bai = parseInt(num/100) % 10;
                    return [bai, shi, ge]
                }
            },
        },
	}
</script>

<style>
.daoshu-container{
    pointer-events: none;
}
.date-container,.countdown-container{
    width: 100%;
    justify-content: flex-end;
}
.date-items,.date-end{
    display: block;
    margin: 0 -1.2%;
}
.date-items{
    width: 12%;
}
.date-end{
    width: 15%;
}
.countdown-bg{
    background-image: url("http://resali.lititutu.cn/tuku/099af58168f37375c3b9f6446df05708.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    float: left;
    padding: 4% 6% 5.6% 6%;
    align-items: flex-end;
}
.countdown-items{
    width: 40% !important;
    margin: 0 -9%;
}
.countdown-end{
    width: 38%;
    margin: 0 0 -4% -6%;
}
</style>