<template>
	<div class="firefly">
        <img :src="getItem()" class="firefly1" width="47">
        <img :src="getItem()" class="firefly2" width="42">
        <img :src="getItem()" class="firefly3" width="47">
        <img :src="getItem()" class="firefly4" width="42">
        <img :src="getItem()" class="firefly5" width="47">
    </div>
</template>

<script>
	export default {
		name: 'firefly',
		props: ["mode"],
		data () {
			return {}
		},
		methods: {
			getItem () {
				return this.mode[ parseInt( 100000 * Math.random() ) % this.mode.length ];
			}
		}
	}
</script>

<style>
    .firefly {
        bottom: 0;
        left:139.00px;
        position: absolute
    }
    .firefly img{
    	top: -142.50px;
    	left:156.00px;
    }
    .firefly1 {
        animation: chongmove1 9s ease infinite 1s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly1 {
        animation: chongmove1 9s ease infinite 1s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }

    .firefly2 {
        animation: chongmove3 9s ease infinite 1.8s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly3 {
        animation: chongmove2 9s ease infinite;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly4 {
        animation: chongmove1 9s ease infinite 5s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly5 {
        animation: chongmove4 9s ease infinite 5.8s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly6 {
        animation: chongmove5 9s ease infinite;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly7 {
        animation: chongmove6 9s ease infinite 1.2s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly8 {
        animation: chongmove7 9s ease infinite .8s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly9 {
        animation: chongmove5 9s ease infinite 3s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    .firefly10 {
        animation: chongmove7 9s ease infinite 5s;
        top: -97.00px;
        left:257.00px;
        opacity: 0;
    }
    
    @keyframes chongmove1 {
        0% {
            transform: translate3d(0,0,0)
        }

        10.4807% {
            transform: translate3d(0,-55.50px,0);
            opacity: 1
        }

        20.499% {
            transform: translate3d(-173.50px,-104.00px,0);
            opacity: 1
        }

        47.3909% {
            transform: translate3d(-194.50px,-291.50px,0);
            opacity: 1
        }

        73.1831% {
            transform: translate3d(-173.50px,-361.00px,0);
            opacity: 1
        }

        90% {
            opacity: 1
        }

        100% {
            transform: translate3d(-125.00px,-590.50px,291.50px);
            opacity: 0
        }
    }

    @keyframes chongmove2 {
        0% {
            transform: translate3d(0,0,0)
        }

        10.4807% {
            transform: translate3d(0,-69.50px,0);
            opacity: 1
        }

        20.499% {
            transform: translate3d(-139.00px,-90.50px,0);
            opacity: 1
        }

        47.3909% {
            transform: translate3d(-125.00px,-271.00px,0);
            opacity: 1
        }

        73.1831% {
            transform: translate3d(-201.50px,-333.50px,0);
            opacity: 1
        }

        90% {
            opacity: 1
        }

        100% {
            transform: translate3d(-125.00px,-659.50px,291.50px);
            opacity: 0
        }
    }

    @keyframes chongmove3 {
        0% {
            transform: translate3d(0,0,0)
        }

        10.4807% {
            transform: translate3d(0,-69.50px,0);
            opacity: 1
        }

        20.499% {
            transform: translate3d(-34.50px,-125.00px,0);
            opacity: 1
        }

        47.3909% {
            transform: translate3d(-66.50px,-271.00px,0);
            opacity: 1
        }

        73.1831% {
            transform: translate3d(-48.50px,-333.50px,0);
            opacity: 1
        }

        90% {
            opacity: 1
        }

        100% {
            transform: translate3d(-125.00px,-590.50px,291.50px);
            opacity: 0
        }
    }

    @keyframes chongmove4 {
        0% {
            transform: translate3d(0,0,0)
        }

        10.4807% {
            transform: translate3d(0,-34.50px,0);
            opacity: 1
        }

        20.499% {
            transform: translate3d(-90.50px,-69.50px,0);
            opacity: 1
        }

        47.3909% {
            transform: translate3d(-52.00px,-139.00px,0);
            opacity: 1
        }

        73.1831% {
            transform: translate3d(-83.50px,-312.50px,0);
            opacity: 1
        }

        90% {
            opacity: 1
        }

        100% {
            transform: translate3d(-125.00px,-590.50px,291.50px);
            opacity: 0
        }
    }

    @keyframes chongmove5 {
        0% {
            transform: translate3d(0,0,0)
        }

        10% {
            transform: translate3d(0,-34.50px,0);
            opacity: 1
        }

        25% {
            transform: translate3d(90.50px,-125.00px,0);
            opacity: 1
        }

        50% {
            transform: translate3d(52.00px,-222.00px,0);
            opacity: 1
        }

        75% {
            transform: translate3d(83.50px,-361.00px,0);
            opacity: 1
        }

        90% {
            opacity: 1
        }

        100% {
            transform: translate3d(125.00px,-590.50px,291.50px);
            opacity: 0
        }
    }

    @keyframes chongmove6 {
        0% {
            transform: translate3d(0,0,0)
        }

        10% {
            transform: translate3d(0,-55.50px,0);
            opacity: 1
        }

        30% {
            transform: translate3d(-7.00px,-139.00px,0);
            opacity: 1
        }

        60% {
            transform: translate3d(34.50px,-264.00px,0);
            opacity: 1
        }

        90% {
            transform: translate3d(-21.00px,-416.50px,0);
            opacity: 1
        }

        90% {
            opacity: 1
        }

        100% {
            transform: translate3d(28.00px,-590.50px,291.50px);
            opacity: 0
        }
    }

    @keyframes chongmove7 {
        0% {
            transform: translate3d(0,0,0)
        }

        10.4807% {
            transform: translate3d(14.00px,-41.50px,0);
            opacity: 1
        }

        20.499% {
            transform: translate3d(55.50px,-194.50px,0);
            opacity: 1
        }

        47.3909% {
            transform: translate3d(-52.00px,-291.50px,0);
            opacity: 1
        }

        73.1831% {
            transform: translate3d(-83.50px,-437.50px,0);
            opacity: 1
        }

        90% {
            opacity: 1
        }

        100% {
            transform: translate3d(-125.00px,-590.50px,291.50px);
            opacity: 0
        }
    }
</style>