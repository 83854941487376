<template>
	<div>
		<template v-if="$util.typeOf(mode['mode']['bg']) == 'array'">
			<!-- runtime在这里的作用是防止第一次背景轮播的时候出现闪现 -->
			<div v-for="(bgitem, index) in mode['mode']['bg']"
				:key="bgitem.toString() + index"
				class="simple-bg page bg"
				:class="{
					'simple-bg_in':  currentBgIdx == index && runtime != 0,
					'simple-bg_out': currentBgIdx != index,
					'hide': runtime != 0
				}"
				:style="{
					animationDuration: (index == 0 ? 0.6 : 1.2) + 's',
					zIndex: (currentBgIdx == index ? -1 : -2)
				}">

				<div v-if="$util.typeOf(bgitem) == 'object'" class="page bg">
					<gif :mode="bgitem"></gif>
				</div>
				<div v-else class="page bg" :style="{backgroundImage: 'url('+bgitem+')'}"></div>
			</div>
		</template>
		<template v-else-if="$util.typeOf(mode['mode']['bg']) == 'object'">
			<template v-if="mode['mode']['bg']['im']">
				<div 
					v-if="mode['mode']['photo'] 
						&& mode['mode']['photo']['photo_mode'] 
						&& mode['mode']['photo']['photo_mode']['mode'] == 'frame'" 
					class="simple-bg page bg"
					style="z-index: -1;"
					:style="getFrameStyle"
					:class="mode['mode']['bg']['className']"></div>
				<div v-else class="simple-bg page bg" style="z-index: -1;" :style="getImStyle" :class="mode['mode']['bg']['className']"></div>
			</template>
			<template v-else>
				<gif :mode="mode['mode']['bg']"></gif>
			</template>
		</template>
		<template v-else>
			<div class="simple-bg page bg" style="z-index: -1;" :style="{backgroundImage: 'url('+mode['mode']['bg']+')'}"></div>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'SimpleBg',
		props: ['mode', 'currentBgIdx', 'currentImage', 'currentIdx'],
		watch: {
			currentBgIdx () {
				this.runtime ++;
			}
		},
		components: {},
		computed: {
			getImStyle () {
				let style = {};
				if (this.mode['mode']['bg']['im'] == 'userphoto' || this.mode['mode']['bg']['im'] == '#userphoto#') {
					style['backgroundImage'] = 'url('+ this.currentImage +')';
				} else {
					style['backgroundImage'] = 'url('+ this.mode['mode']['bg']['im'] +')';
				}
				return style;
			},
			getFrameStyle () {
				let currentIdx = this.currentIdx - 1;
				currentIdx = currentIdx < 0 ? 0 : currentIdx; 
				let elems = this.mode['mode']['photo']['photo_mode']['elems'];
				let idx = currentIdx % elems.length;
				let im = this.$util.typeOf(elems[idx]) == 'object' ? elems[idx]['im'] : elems[idx][0]['im'];
				return {
					backgroundImage: 'url('+ im +')'
				}
			}
		},
		data () {
			return {
				runtime: 0
			}
		}
	}
</script>

<style lang="less" scoped>
	.simple-bg .bg {
		background-position: center top!important;
	}
	.simple-bg.hide {
		opacity: 0;
	}
	.simple-bg_in {
		animation: fadeIn .6s both;
		z-index: 1;
	}
	.simple-bg_out {
		animation: fadeOut .6s both;
		z-index: 2;
	}
</style>