<template>
	<div class="denglong-page page bg">
		<div class="denglong-container page bg">
			<div v-for="(bgsrc, index) in mode['mode']['bg']" class="denglong-bg page bg" style="z-index: -1;" :class="{
					'denglong-bg_in':  currentBgIdx == index,
					'denglong-bg_out': currentBgIdx != index
				}" :style="{backgroundImage: 'url('+bgsrc+')'}"
				:key="'qingming3d_'+index">
			</div>
			<div class="denglong_contain" v-if="isShow">
				<div class="denglong_box denglong_box4">
					<div class="denglong_box float-x">
						<div class="denglong_box float-y">
					        <div class="denglong_front user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
					            <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
					            <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
					            <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
					            <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
					            <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
					        </div>
					        <div class="denglong_back user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
					            <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
					            <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
					            <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
					            <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
					            <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
					        </div>
					        <div class="denglong_left user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
					            <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
					            <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
					            <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
					            <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
					            <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
					        </div>
					        <div class="denglong_right user" :style="{backgroundImage: 'url('+currentImage['im']+')'}" @click="wxPreview">
					            <div class="user_border" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2.png)"></div>
					            <div class="user_border_top" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2t.png)"></div>
					            <div class="user_border_bot" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2b.png)"></div>
					            <div class="user_border_right" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2r.png)"></div>
					            <div class="user_border_left" style="background-image: url(http://resali.lititutu.cn/scenes/wuhanjiayou/b2l.png)"></div>
					        </div>
						</div>
					</div>
			    </div>	
			</div>
			<!-- 场内元素 -->
			<template v-for="(decorate, decorate_id) in mode['mode']['decorate']">
				<template v-if="decorate_id == 'gif'">
					<template v-for="mode in decorate">
						<gif :mode="mode"></gif>
					</template>
				</template>
				<component :is="decorate_id" :mode="decorate"></component>
			</template>
		</div>

		<!-- 字幕 -->
		<span class="denglong-usertext">{{currentText}}</span>

		<!-- 开场动画 -->
		<Prelude :mode="prelude" @preluded="preluded"></Prelude>
    </div>
</template>

<script>
	
	import Prelude from '@/components/Prelude'

	export default {
		name: 'denglong',
		props: ['mode'],
		components: {

			Prelude,
		},
		data () {
			return {
				// currentImage: 'http://hdalioss.lititutu.cn/sz_mmbiz_jpg/0QicI7yQXhWZibOibDibSl2XoeFib9EsFQR6KYKWAsUibMXLVum1gXQWr8tqnGAiajfbAuicCVbZYZKjsZfw0Qwm62Mx2w/0?x-oss-process=image/resize,w_500/auto-orient,1',
				currentIdx: 0,
				timeEvent: null,
				isShow: true,
				currentText: ''
			}
		},
		computed: {
			currentBgIdx () {
				let bgidx = this.currentIdx % this.mode['mode']['bg'].length;
				return bgidx;
			},
			currentImage () {
				let photoidx = this.currentIdx % this.$store.state.config.images.length;
				let currentim = this.$store.state.config.images[photoidx];
				setTimeout(() => {
					this.currentText = currentim['tx'] ? currentim['tx'] : '';
					setTimeout(() => {
						this.currentText = '';
					}, 6000);
				}, 3000);
				return currentim;
			},
			prelude () {
				return {
					'type': 'default',
					'duration': 3,
					'title': this.$store.state.config['data']['ti'] || this.mode['name'],
					'textcolor': '#d56719'
				}
			}
		},
		created () {

			clearInterval(this.timeEvent);

			this.timeEvent = setInterval(() => {
				this.isShow = false;
				this.$nextTick(() => {
					this.isShow = true;
				});

				this.currentIdx ++;
			}, 12000);
		},
		run () {

		},
		methods: {
			preluded () {},
			wxPreview () {
				this.$emit('wxPreview', this.currentImage);
			}
		}
	}
</script>

<style scoped lang="less">
	.denglong-usertext {
		position: absolute;
		width: @bw*.93;
		left: 0;
		right: 0;
		text-align: center;
		margin: auto;
		bottom: @bw*.2;
		color: white;
		font-size: @bw*.056;
		text-shadow: 2px 2px 10px black;
		font-family: '\9ED1\4F53';
		font-weight: bold;
		z-index: 9;
	}
	.denglong-container {
	    -webkit-perspective: 694.5px;
	}
	.denglong-bg {
		opacity: 0;
	}
	.denglong-bg_in {
		animation: fadeIn .6s both;
	}
	.denglong-bg_out {
		animation: fadeOut .6s both;
	}

	.denglong_bgGif {
	    position: absolute;
	    top: 0;
	}
	.denglong_bg, .denglong_bgGif {
	    height: 100%;
	    width: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    background: no-repeat center;
	    background-size: cover;
	}
	.perspe {
	    perspective: @bw * 1.389;
	    width: 100%;
	    height: 100%;
	    position: absolute
	}
	.denglong_box {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    top: 0; left: 0;
	    transform-style: preserve-3d;
	}
	.denglong_contain {
		width: @bw * 0.556;
	    height: @bw * 0.556;
	    position: absolute;
	    transform-style: preserve-3d;
	    left: 50%;
	    top: 50%;
	    margin-left: -@bw * 0.278;
	    margin-top: -@bw * 0.278;
	    transform: translateZ(-@bw * 6.944) scale(.2);
		animation: denglong_in 12s ease-in-out both;
		z-index: 2;
	}
	@keyframes denglong_in {
		0% {
			transform: translate3d(-500px, 500px, 0);
		}
		25% {
			transform: translate3d(0, 0, 0);
		}
		50% {
			transform: translate3d(0, 0, 0);
		}
		75% {
			transform: translate3d(0, 0, 0);
		}
		100% {
			transform: translate3d(500px, -500px, 0);
		}
	}
	.denglong_box4 {
	    animation: denglong_rotateBox 12s linear infinite
	}
	@keyframes denglong_rotateBox {
		0% {
	        transform: rotateY(0deg);
	    }
	    100% {
	        transform: rotateY(360deg)
	    }
	    // 0% {
	    //     transform: translate3d(-500px, 500px, 0px) scale(.9);
	    // }
	    // 25% {
	    //     transform: rotateY(0deg) translate3d(0px, 0px, 0px) scale(1);
	    // }
	    // 50% {
	    //     transform: rotateY(90deg);
	    // }
	    // 75% {
	    //     transform: rotateY(180deg);
	    // }
	    // 100% {
	    //     transform: rotateY(270deg)
	    // }
	}
	.denglong_box3 {
	    transform: translateZ(-@bw * 6.944);
	    left: -169%;
	    top: 181%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.denglong_box2 {
	    transform: translateZ(-@bw * 6.944);
	    right: -200%;
	    top: -11%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.denglong_box1 {
	    transform: translateZ(-@bw * 6.944);
	    left: -160%;
	    top: -109%;
	    position: absolute;
	    transform-style: preserve-3d;
	}
	.denglong_front {
	    transform: rotateY(0deg) translateZ(@bw * 0.278);
	}
	.denglong_back {
	    transform: translateZ(-@bw * 0.278) rotateY(180deg);
	}
	.denglong_left {
	    transform: rotateY(90deg) translateZ(@bw * 0.278);
	}
	.denglong_right {
	    transform: rotateY(-90deg) translateZ(@bw * 0.278);
	}
	.denglong_top {
	    transform: rotateX(90deg) translateZ(@bw * 0.278);
	}
	.denglong_bot {
	    transform: rotateX(-90deg) translateZ(@bw * 0.278);
	}
	.user {
	    background: no-repeat center;
	    position: absolute;
	    width: @bw * 0.556;
	    height: @bw * 0.556;
		background-size: cover;
	}
	.user_border {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    background: no-repeat center;
	    background-size: cover
	}
	.user_border_left {
	    position: absolute;
	    width: @bw * 0.278;
	    height: @bw * 0.556;
	    left: -@bw * 0.278;
	    background: no-repeat right center;
	    background-size: auto 100%;
	}
	.user_border_right {
	    position: absolute;
	    width: @bw * 0.278;
	    height: @bw * 0.556;
	    right: -@bw * 0.278;
	    background: no-repeat left center;
	    background-size: auto 100%;
	}
	.user_border_top {
	    position: absolute;
	    width: @bw * 0.829;
	    height: @bw * 0.417;
	    left: 50%;
	    top: -@bw * 0.417;
	    transform: translate(-50%, 0%);
	    background: no-repeat bottom center;
	    background-size: 100% auto;
	}
	.user_border_bot {
	    position: absolute;
	    width: @bw * 0.829;
	    height: @bw * 0.417;
	    left: 50%;
	    bottom: -@bw * 0.417;
	    transform: translate(-50%, 0%);
	    background: no-repeat top center;
	    background-size: 100% auto;
	}
</style>