
class stAd {
    constructor(util) {
        // this.$util = util;
        // console.warn("stAd==>")
        // console.warn("优量汇初始化==>")
        // // H5 优量汇 SDK接入全局只需运行一次
        // window.TencentGDT = window.TencentGDT || [];
        // window.addEventListener('load', function() {
        //     (function () {
        //         var doc = document,
        //             h = doc.getElementsByTagName('head')[0],
        //             s = doc.createElement('script');
        //         s.async = true;
        //         s.src = '//qzs.gdtimg.com/union/res/union_sdk/page/h5_sdk/i.js'; // 2022-05-30 替换 jssdk即将过期 '//qzs.qq.com/qzone/biz/res/i.js';
        //         h && h.insertBefore(s, h.firstChild);
        //     })();
        // })
        // this.ads = null;
    }
    getAdvConfig (advkey) {
        let key = this.$util.getTopDomain(),
            ad_config = this.$util.$config.st_config['ad'],
            map = ad_config[key];
        if (map) {
            map["re_card"] = map["banner"];
            map["video"] = map["banner"];
            map["video2"] = map["banner"];
            map["text_banner"] = map["banner"];
            ad_config[key] = map;
        }
        if (advkey) {
            return ad_config[key] && ad_config[key][advkey] ? ad_config[key][advkey] : "";
        } else {
            return ad_config[key]
        }
    }
    async initAd (type, advid) {
        return Promise.resolve();
        if (this.ads && this.ads[type]) {
            return Promise.resolve(this.ads)
        }
        let adv_config = this.getAdvConfig();
        if (!adv_config) {
            return Promise.resolve();
        }
        let app_id = adv_config['app_id'],
            _this = this;
        return new Promise((resolve, reject)=>{
            window.TencentGDT.push({
                placement_id: adv_config[type],//ad_config['placement_ids'][1],
                app_id: app_id,
                type: 'native',
                display_type: 'interstitial', // 播放类型：插屏
                count: 1, //拉取广告的数量，必填，默认是1，最高支持10
                onComplete: function(res) {
                    if (res && res.ret === 0 && res.data) {
                        if (!_this.ads) {
                            _this.ads = {}
                        }
                        _this.ads[type] = res.data;
                        resolve(res.data)
                    } else {
                        console.log("ad ",res.ret,res.message);
                    }
                }
            });
        })
    }
    async show (options) {
        return Promise.resolve();
        let _this = this,
            {type, delay} = options,
            _now = Date.parse(new Date())/1000,
            storage_key = 'st_' + type + 'showed',
            last_time = this.$util.getStorageSync(storage_key);
        if (last_time && _now - last_time < delay) {
            return Promise.resolve("delaying")
        }
        if (!this.ads || !this.ads[type]) {
            await this.initAd(type);
        }
        if (!this.ads) {
            return Promise.resolve()
        }
        await new Promise((resolve, reject)=>{
            // 插屏生成推迟2s
            setTimeout( ()=> {
                // 调用渲染接口
                window.TencentGDT.NATIVE.renderAd(_this.ads[type][0])
                // 绑定点击蒙层关闭事件
                document.getElementById('gdt_template_interstitial_wrap').children[0].onclick = function() {
                    if (this.tagName.toLowerCase() == 'div' && this.getAttribute('id').indexOf('gdt_template_native_wrap') != -1) {
                        document.body.removeChild(document.getElementById('gdt_template_interstitial_wrap'));
                    }
                }
                if (delay) {
                    _this.$util.setStorageSync(storage_key, _now);
                }
                resolve("success")
            }, 1000);
        }).catch(err=>{
            return Promise.reject(err)
        })
        return Promise.resolve()
    }
    destroy () {
        //
    }
}

export default {
    stAd
}