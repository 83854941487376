
<template>
    <div class="quantu2_con">
    <!--     <div class="feiHeBox">
            <div class="feiHe"><img :src="mode[2]" class="he"/></div>
            <div class="feiHe feiHe2"><img class="he"/></div>
            <div class="feiHe feiHe3"><img class="he"/></div>
        </div> -->
        <div class="quantu2_userbox">
            <div class="quantu2_user1" :style="getStyle">
                <div class="quantu2_pic" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                <div class="quantu2_border" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            </div>
            <div class="quantu2_user2" :style="getStyle">
                <div class="quantu2_pic" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                <div class="quantu2_border" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            </div>
            <div class="quantu2_user3" :style="getStyle">
                <div class="quantu2_pic" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                <div class="quantu2_border" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'quantu2',
        props: ['mode', 'currentImage', 'showStatus'],
        data () {
            return {}
        },
        computed: {
            getStyle () {
                let twidth, theight;
                if (this.showStatus) {
                    let tsize = this.$o_util.getphotoboxsize(this.currentImage['orisize'], [347, 556], 'both');
                    twidth = tsize[0];
                    theight = tsize[1];
                } else {
                    twidth = 347;
                    theight = 556;
                }
                return {
                    width: twidth + 'px',
                    height: theight + 'px'
                }
            }
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage)
            }
        }
    }
</script>

<style scoped lang="less">
    .quantu2_con{
        width: 100vw;
        height: 100vh;
        position: relative;
        perspective:@bw * 1.111;
    }
    .quantu2_bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: -@bw * 1.389 @bw * 0.0;
        animation: quantu2_yidong 10s linear both;
    }
    @keyframes quantu2_yidong{
        0%{background-position:@bw * 0.0 @bw * 0.0;}
        100%{background-position: -@bw * 1.111 @bw * 0.0;}
    }
    .quantu2_userbox{
        position: absolute;
        top:@bw * 0.0;
        left:@bw * 0.0;
        width: 100vw;
        height: 100vh;
        transform-style: preserve-3d;
        animation: art1 10s linear both;
    }
    @keyframes art1{
        0%{transform: rotateY(20deg) translateX(@bw * 0.0);}
        25%{transform: rotateY(0deg) translateX(@bw * 0.468);}
        50%{transform: rotateY(-20deg) translateX(@bw * 0.936);}
        75%{transform: rotateY(0deg) translateX(@bw * 1.404);}
        100%{transform: rotateY(20deg) translateX(@bw * 1.872);}
    }
    .quantu2_user1{
        position: absolute;
        top: 20vh;
        left:@bw * 0.197;
        // width:@bw * 0.694;
        // height:@bw * 1.111;
    }
    .quantu2_user2{
        position: absolute;
        top: 20vh;
        left: -@bw * 0.757;
        // width:@bw * 0.694;
        // height:@bw * 1.111;
    }
    .quantu2_user3{
        position: absolute;
        top: 20vh;
        left: -@bw * 1.715;
        // width:@bw * 0.694;
        // height:@bw * 1.111;
    }
    .quantu2_pic{
        position: absolute;
        top:@bw * 0.0;
        left:@bw * 0.0;
        // width:@bw * 0.694;
        // height:@bw * 1.111;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .quantu2_border{
        position: absolute;
        top:@bw * 0.0;
        left:@bw * 0.0;
        // width:@bw * 0.694;
        // height:@bw * 1.111;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
    }
</style>