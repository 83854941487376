<template>
    <component :is="is"
        :app="app"
        :config="config"
        :idx="idx"
        :envDatas="selfEnvDatas"
        :openType="openType"
        :showMessageCard="showMessageCard"
        :sendMessagePath="sendMessagePath"
        :sendMessageImg="sendMessageImg"
        :onFunc="onFunc"
        @onBtnShow="onBtnShow"
        @onCallBack="onCallBack">
        <slot name="default"></slot>
    </component>
</template>

<script>
    import StBaseComp from "../StBaseComp";
    import SelfButton from "./SelfButton"
    import SelfButtonGroup from "./SelfButtonGroup"

    export default {
        name: 'StButton',
        extends: StBaseComp,
        components: {
            SelfButton,
            SelfButtonGroup
        },
        props: {
            app: { // 已废弃
                type: String,
                default: 'vliti'
            },
            rid: {
                type: String,
                default: ""
            },
            rconfig: {},
            idx: {
                type: Number,
                default: -1
            },
            envDatas: {
                type: [Object, Function]
            },
            openType: {
                type: String,
            },
            showMessageCard: {
                type: Boolean,
            },
            sendMessagePath: {
                type: String,
            },
            sendMessageImg: {
                type: String,
            },
            onFunc: {
                type: Function
            }
        },
        data () {
            return {
                config: null,
                selfEnvDatas: {},
            }
        },
        computed: {
            is () {
                let is;
                if (this.config && this.$util.typeOf(this.config) == 'object') {
                    is = 'SelfButton';
                } else if (this.config && this.$util.typeOf(this.config) == 'array') {
                    is = 'SelfButtonGroup';
                }
                return is;
            }
        },
        created () {
            this.getConfig().then(config => {
                this.config = config;
            })
        },
        methods: {
            async getConfig () {
                let config = await new Promise (resolve => {
                    if (this.rid) {
                        // 防止多个相同的配置的按钮重复请求 byhz 2021.11.05
                        let recommends = this.$util.$global.get('recommends', {});
                        if (recommends[this.rid]) {
                            resolve( recommends[this.rid] )
                        } else {
                            this.$util.rget('/api/get_recommend', {
                                rid: this.rid
                            }).then((config) => {
                                recommends[this.rid] = config;
                                this.$util.$global.set('recommends', recommends);
                                resolve( config );
                            })
                        }
                    } else {
                        resolve()
                    }
                }) 

                if (this.rconfig) {
                    // 两者都为object的时候，以rid中结构优先、合并config
                    if (!config || !this.rid){
                        config = this.rconfig;
                    } else if (this.$util.typeOf(this.rconfig) == 'object' && this.$util.typeOf(config) == 'object') {
                        config = Object.assign(this.rconfig, config);
                    } else { // 否则警告
                        this.$util.$loger.warn((this.rid ? this.rid : JSON.stringify(this.rconfig) ) + "st-button rid 获取的结构与rconfig无法结合，将以rid中结构优先，请留意")
                    }
                }

                if (!config) {
                    config = {
                        "condition": [
                            {
                                "if": "else",
                                "do": "make"
                            }
                        ]
                    }
                }
                
                // 当condition数组只有一个时无需使用buttongroup
                if (config && this.$util.typeOf(config) == "array" && config.length == 1) {
                    config = config[0];
                }
                
                // 根据环境变量赋值替换config中关键字
                if (this.envDatas) {
                    if (this.$util.typeOf(this.envDatas) == "function") {
                        this.selfEnvDatas = await this.envDatas()
                    } else {
                        this.selfEnvDatas = this.envDatas
                    }
                    config = JSON.parse( this.$util.replaceReg( JSON.stringify(config), this.selfEnvDatas ) )
                }

                return Promise.resolve(config);
            },
            onCallBack (e) {
                this.$emit("onCallBack", e);
            },
            onBtnShow (e) {
                this.$emit("onBtnShow", e);
            }
        },
    }
</script>