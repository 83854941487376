<template>
    <div class="po compluo6 pointer" :style="getStyles">
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
            <div v-for="index of 6"
                :class="'luo6_item_' + index"
                :key="'luo6_item_' + index"
                class="po">
                <elem 
					:mode="getLuoItem(index)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
            </div>
		</template>
		<template v-else>
            <img v-for="index of 6"
                mode="widthFix"
                :class="'luo6_item_' + index"
                :key="'luo6_item_' + index"
                class="po"
                :src="getRandomLuo()" />
		</template>
    </div>
</template>

<script>
    import BaseLuo from './BaseLuo'
    export default {
        name: 'luo6',
        extends: BaseLuo,
    }
</script>

<style>
.compluo6{
    top: 0;
    left: 0;
    transform: rotateX(180deg);
}
.luo6_item_1 {
    width: 30px;
    right: 0px;
    animation: luo6_down4 12s linear 3.5s infinite both;
}
@keyframes luo6_down4 {
    0% {
        transform: translate(-223px,0px) rotateZ(0deg) rotateX(0deg);
    }
    95% {
        opacity: 1;
    }
    100% {
        transform: translate(500px,-880px) rotateZ(360deg) rotateX(1080deg);
        opacity: 0;
    }
}

.luo6_item_2 {
    width: 35px;
    right: 0px;
    top: -640px;
    animation: luo6_down5 12s linear 2s infinite both;
}
@keyframes luo6_down5 {
    0% {
        transform: translate(-30px,0px) rotateZ(0deg) rotateY(0deg);
    }
    95% {
        opacity: 1;
    }
    100% {
        transform: translate(443px,-306px) rotateZ(360deg) rotateY(-1080deg);
        opacity: 0;
    }
}

.luo6_item_3 {
    width: 35px;
    right: 0px;
    animation: luo6_down7 10s linear 4s infinite both;
}
@keyframes luo6_down7 {
    0% {
        transform: translate(-222px,0px) rotateZ(0deg) rotateX(0deg);
    }
    95% {
        opacity: 1;
    }
    100% {
        transform: translate(643px,-830px) rotateZ(350deg) rotateX(1080deg);
        opacity: 0;
    }
}

.luo6_item_4 {
    width: 24px;
    right: 356px;
    animation: luo6_down10 10s linear 11s infinite both;
}
@keyframes luo6_down10 {
    0% {
        transform: translate(-256px,0px) rotateZ(0deg) rotateX(0deg);
    }
    95% {
        opacity: 1;
    }
    100% {
        transform: translate(480px,-830px)rotateZ(350deg) rotateX(720deg);
        opacity: 0;
    }
}

.luo6_item_5 {
    width: 30px;
    right: 0px;
    animation: luo6_down11 6s linear 2s infinite both;
}
@keyframes luo6_down11 {
    0% {
        transform: translate(-49px,0px) rotateZ(0deg) rotateX(0deg) scale(1.5);
    }
    95% {
        opacity: 1;
    }
    100% {
        transform: translate(645px,-565px) rotateZ(350deg) rotateX(720deg) scale(0.5);
        opacity: 0;
    }
}

.luo6_item_6 {
    width: 38px;
    right: 0px;
    top: -230px;
    animation: luo6_down14 7s linear 8s infinite both;
}
@keyframes luo6_down14 {
    0% {
        transform: translate(-65px,0px) rotateZ(0deg) rotateX(0deg);
    }
    95% {
        opacity: 1;
    }
    100% {
        transform: translate(545px,-565px) rotateZ(-350deg) rotateX(720deg) scale(0.5);
        opacity: 0;
    }
}
</style>