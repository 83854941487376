<template>
    <div class="zhuan2">
        <div class="zhuan2bgCover BX_Class17 BX_BG17 zhuan2BX_Ani17"></div>
        <div class="BX_AEComposition">
            <div class="zhuan2bgCover BX_Class16 BX_BG16 zhuan2BX_Ani16" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            <div class="zhuan2bgCover BX_Class15 BX_BG15 zhuan2BX_Ani15" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            <div class="zhuan2bgCover BX_Class14 BX_BG14 zhuan2BX_Ani14" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>
            <div class="zhuan2bgCover BX_Class13 BX_BG13 zhuan2BX_Ani13" :style="{backgroundImage: 'url('+mode[1]+')'}"></div>

            <div class="zhuan2bgCover BX_Class8 zhuan2BX_Ani8">
                <div class="zhuan2bgCover BX_Class12 BG_CENTER BX_BG12 zhuan2BX_Ani12 BX_USER" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                <div class="zhuan2bgCover BX_Class11 BG_CENTER BX_BG11 zhuan2BX_Ani11 BX_USER" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                <div class="zhuan2bgCover BX_Class10 BG_CENTER BX_BG10 zhuan2BX_Ani10 BX_USER" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
                <div class="zhuan2bgCover BX_Class9 BG_CENTER BX_BG9 zhuan2BX_Ani9 BX_USER" @click="wxPreview" :style="{backgroundImage: 'url('+currentImage['im']+')'}"></div>
            </div>

            <div class="zhuan2bgCover BX_Class7 BX_BG7 zhuan2BX_Ani7" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
            <div class="zhuan2bgCover BX_Class6 BX_BG6 zhuan2BX_Ani6" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
            <div class="zhuan2bgCover BX_Class5 BX_BG5 zhuan2BX_Ani5" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
            <div class="zhuan2bgCover BX_Class4 BX_BG4 zhuan2BX_Ani4" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
            <div class="zhuan2bgCover BX_Class3 BX_BG3 zhuan2BX_Ani3" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
            <div class="zhuan2bgCover BX_Class2 BX_BG2 zhuan2BX_Ani2" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
            <div class="zhuan2bgCover BX_Class1 BX_BG1 zhuan2BX_Ani1" :style="{backgroundImage: 'url('+mode[2]+')'}"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'zhuan2',
        props: ['mode', 'currentImage'],
        data () {
            return {
            }
        },
        mounted () {
            
        },
        methods: {
            wxPreview () {
                this.$emit('wxPreview', this.currentImage)
            }
        }
    }
</script>

<style scoped lang="less">
    .zhuan2bgCover {
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .BG_CENTER {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .BX_AEComposition{
        background: transparent;
        width:@bw * 1.0;
        height:@bw * 1.778;
        display: block;
        overflow: hidden;
        position: relative;
        transform: translate3d(0,0,0) scale(1,1);
        transform-origin:50% 0%;
        perspective:@bw * 0.833;
        perspective-origin: 50% 50%;
    }
    .BX_Class17{
        left:@bw * 0.044;
        top:@bw * 0.189;
        width:@bw * 0.889;
        height:@bw * 1.4;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.14,1.27);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .BX_Class16{
        left:@bw * 0.665;
        top:@bw * 1.835;
        width:@bw * 0.139;
        height:@bw * 0.096;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.30,1.32);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani16{
        animation:zhuan2BX_AniKey16 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey16{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.3,1.31884057971014);opacity: 1.00;}
        31.01% {transform: translate3d(@bw * 0.004,-@bw * 0.006,@bw * 0.0) rotate(0.00deg) scale(1.28785134947865,1.30651586178994);opacity: 0.99;}
        40.31% {transform: translate3d(-@bw * 0.001,-@bw * 0.101,@bw * 0.0) rotate(0.00deg) scale(1.14206754322247,1.15861924674744);opacity: 0.83;}
        50.39% {transform: translate3d(-@bw * 0.022,-@bw * 0.204,@bw * 0.0) rotate(0.00deg) scale(0.98414101840321,0.9984039317134);opacity: 0.65;}
        60.47% {transform: translate3d(-@bw * 0.042,-@bw * 0.308,@bw * 0.0) rotate(0.00deg) scale(0.82620856162568,0.83818259875069);opacity: 0.48;}
        70.54% {transform: translate3d(-@bw * 0.056,-@bw * 0.412,@bw * 0.0) rotate(0.00deg) scale(0.66827610484815,0.67796126578798);opacity: 0.31;}
        80.62% {transform: translate3d(-@bw * 0.054,-@bw * 0.517,@bw * 0.0) rotate(0.00deg) scale(0.51034364807063,0.51773993282527);opacity: 0.13;}
        88.37% {transform: translate3d(-@bw * 0.018,-@bw * 0.585,@bw * 0.0) rotate(0.00deg) scale(0.38885714285714,0.39449275362319);opacity: 0.00;}
        100.00% {transform: translate3d(-@bw * 0.018,-@bw * 0.585,@bw * 0.0) rotate(0.00deg) scale(0.38885714285714,0.39449275362319);opacity: 0.00;}
    }
    .BX_Class15{
        left: -@bw * 0.254;
        top:@bw * 1.715;
        width:@bw * 0.139;
        height:@bw * 0.096;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.30,1.32);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani15{
        animation:zhuan2BX_AniKey15 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey15{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.3,1.31884057971014);opacity: 1.00;}
        10.08% {transform: translate3d(@bw * 0.104,-@bw * 0.064,@bw * 0.0) rotate(0.00deg) scale(1.15175668647171,1.1684488123626);opacity: 0.86;}
        20.16% {transform: translate3d(@bw * 0.21,-@bw * 0.128,@bw * 0.0) rotate(0.00deg) scale(1.00351337294341,1.01805704501506);opacity: 0.73;}
        30.23% {transform: translate3d(@bw * 0.314,-@bw * 0.192,@bw * 0.0) rotate(0.00deg) scale(0.85527005941512,0.86766527766751);opacity: 0.59;}
        40.31% {transform: translate3d(@bw * 0.419,-@bw * 0.257,@bw * 0.0) rotate(0.00deg) scale(0.70702674588683,0.71727351031997);opacity: 0.46;}
        50.39% {transform: translate3d(@bw * 0.524,-@bw * 0.321,@bw * 0.0) rotate(0.00deg) scale(0.55878900039164,0.56688739170167);opacity: 0.32;}
        60.47% {transform: translate3d(@bw * 0.629,-@bw * 0.385,@bw * 0.0) rotate(0.00deg) scale(0.41054568686335,0.41649562435412);opacity: 0.19;}
        70.54% {transform: translate3d(@bw * 0.733,-@bw * 0.45,@bw * 0.0) rotate(0.00deg) scale(0.26230237333506,0.26610385700658);opacity: 0.05;}
        74.42% {transform: translate3d(@bw * 0.775,-@bw * 0.475,@bw * 0.0) rotate(0.00deg) scale(0.20528571428571,0.20826086956522);opacity: 0.00;}
        100.00% {transform: translate3d(@bw * 0.775,-@bw * 0.475,@bw * 0.0) rotate(0.00deg) scale(0.20528571428571,0.20826086956522);opacity: 0.00;}
        }
    .BX_Class14{
        left:@bw * 0.129;
        top:@bw * 1.815;
        width:@bw * 0.139;
        height:@bw * 0.096;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.03,1.05);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani14{
        animation:zhuan2BX_AniKey14 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey14{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.03385714285714,1.04884057971014);opacity: 1.00;}
        44.19% {transform: translate3d(@bw * 0.008,-@bw * 0.007,@bw * 0.0) rotate(0.00deg) scale(1.02332648114309,1.03815729971038);opacity: 0.98;}
        50.39% {transform: translate3d(@bw * 0.072,-@bw * 0.085,@bw * 0.0) rotate(0.00deg) scale(0.93904003193786,0.95264930776304);opacity: 0.84;}
        60.47% {transform: translate3d(@bw * 0.168,-@bw * 0.215,@bw * 0.0) rotate(0.00deg) scale(0.80207455197936,0.81369882084863);opacity: 0.61;}
        70.54% {transform: translate3d(@bw * 0.262,-@bw * 0.346,@bw * 0.0) rotate(0.00deg) scale(0.66510907202087,0.67474833393421);opacity: 0.38;}
        80.62% {transform: translate3d(@bw * 0.362,-@bw * 0.472,@bw * 0.0) rotate(0.00deg) scale(0.52814359206237,0.5357978470198);opacity: 0.14;}
        86.82% {transform: translate3d(@bw * 0.432,-@bw * 0.543,@bw * 0.0) rotate(0.00deg) scale(0.44385714285714,0.45028985507246);opacity: 0.00;}
        100.00% {transform: translate3d(@bw * 0.432,-@bw * 0.543,@bw * 0.0) rotate(0.00deg) scale(0.44385714285714,0.45028985507246);opacity: 0.00;}
        }
    .BX_Class13{
        left:@bw * 0.396;
        top:@bw * 1.971;
        width:@bw * 0.139;
        height:@bw * 0.096;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.30,1.32);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani13{
        animation:zhuan2BX_AniKey13 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey13{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.3,1.31884057971014);opacity: 1.00;}
        57.36% {transform: translate3d(@bw * 0.0,-@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1.29999199802002,1.31883246175944);opacity: 1.00;}
        60.47% {transform: translate3d(@bw * 0.007,-@bw * 0.056,@bw * 0.0) rotate(0.00deg) scale(1.23444778002762,1.25233832756425);opacity: 0.93;}
        70.54% {transform: translate3d(@bw * 0.031,-@bw * 0.239,@bw * 0.0) rotate(0.00deg) scale(1.02140306511739,1.03620600809011);opacity: 0.69;}
        80.62% {transform: translate3d(@bw * 0.054,-@bw * 0.422,@bw * 0.0) rotate(0.00deg) scale(0.80835835020717,0.82007368861597);opacity: 0.44;}
        90.70% {transform: translate3d(@bw * 0.078,-@bw * 0.606,@bw * 0.0) rotate(0.00deg) scale(0.59531363529694,0.60394136914182);opacity: 0.20;}
        100.00% {transform: translate3d(@bw * 0.099,-@bw * 0.767,@bw * 0.0) rotate(0.00deg) scale(0.40857142857143,0.41449275362319);opacity: 0.00;}
    }

    .BX_USER {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .BX_Class12{
        left:@bw * 0.0;
        top:@bw * 0.0;
        width:@bw * 0.417;
        height:@bw * 0.625;
        transform-style: preserve-3d;
        transform: translate3d(0,0,-@bw * 0.139) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .BX_Class11{
        left:@bw * 0.139;
        top: -@bw * 0.0;
        width:@bw * 0.417;
        height:@bw * 0.625;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(-90.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .BX_Class10{
        left:@bw * 0.0;
        top:@bw * 0.0;
        width:@bw * 0.417;
        height:@bw * 0.625;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.139) rotateX(-0.00deg) rotateY(180.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .BX_Class9{
        left: -@bw * 0.139;
        top:@bw * 0.0;
        width:@bw * 0.417;
        height:@bw * 0.625;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(90.00deg) rotateZ(0.00deg) scale3d(0.53,0.53,1.00);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .BX_Class8{
        left:@bw * 0.304;
        top:@bw * 0.565;
        width:@bw * 0.417;
        height:@bw * 0.625;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(2.00,2.00,2.00);
        opacity: 1.00;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .BX_BG8{
        background: rgb(16, 255, 0);
    }
    .zhuan2BX_Ani8{
        animation:zhuan2BX_AniKey8 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey8{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(0.00deg) rotateZ(0.00deg) scale3d(2,2,2);}
        10.08% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(36.28deg) rotateZ(0.00deg) scale3d(2,2,2);}
        20.16% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(72.56deg) rotateZ(0.00deg) scale3d(2,2,2);}
        30.23% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(108.84deg) rotateZ(0.00deg) scale3d(2,2,2);}
        40.31% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(145.12deg) rotateZ(0.00deg) scale3d(2,2,2);}
        50.39% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(181.39deg) rotateZ(0.00deg) scale3d(2,2,2);}
        60.47% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(217.67deg) rotateZ(0.00deg) scale3d(2,2,2);}
        70.54% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(253.95deg) rotateZ(0.00deg) scale3d(2,2,2);}
        80.62% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(290.23deg) rotateZ(0.00deg) scale3d(2,2,2);}
        90.70% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(326.51deg) rotateZ(0.00deg) scale3d(2,2,2);}
        100.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotateX(-0.00deg) rotateY(360.00deg) rotateZ(0.00deg) scale3d(2,2,2);}
        }
    .BX_Class7{
        left: -@bw * 0.165;
        top:@bw * 0.089;
        width:@bw * 0.126;
        height:@bw * 0.11;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.00,1.00);
        opacity: 0.77;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    @keyframes zhuan2BX_AniKey7{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1,1);}
        50.39% {transform: translate3d(@bw * 0.015,@bw * 0.007,@bw * 0.0) rotate(5.71deg) scale(1,1);}
        60.47% {transform: translate3d(@bw * 0.254,@bw * 0.132,@bw * 0.0) rotate(80.00deg) scale(1,1);}
        70.54% {transform: translate3d(@bw * 0.493,@bw * 0.257,@bw * 0.0) rotate(154.29deg) scale(1,1);}
        80.62% {transform: translate3d(@bw * 0.733,@bw * 0.381,@bw * 0.0) rotate(228.57deg) scale(1,1);}
        90.70% {transform: translate3d(@bw * 0.972,@bw * 0.506,@bw * 0.0) rotate(302.86deg) scale(1,1);}
        100.00% {transform: translate3d(@bw * 1.153,@bw * 0.6,@bw * 0.0) rotate(360.00deg) scale(1,1);}
    }
    .BX_Class6{
        left: -@bw * 0.293;
        top:@bw * 0.825;
        width:@bw * 0.126;
        height:@bw * 0.11;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(-35.00deg) scale(1.26,1.26);
        opacity: 0.77;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    @keyframes zhuan2BX_AniKey6{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-35.00deg) scale(1.26,1.26);}
        10.08% {transform: translate3d(@bw * 0.079,@bw * 0.042,@bw * 0.0) rotate(-3.24deg) scale(1.26,1.26);}
        20.16% {transform: translate3d(@bw * 0.288,@bw * 0.15,@bw * 0.0) rotate(79.35deg) scale(1.26,1.26);}
        30.23% {transform: translate3d(@bw * 0.494,@bw * 0.26,@bw * 0.0) rotate(161.94deg) scale(1.26,1.26);}
        40.31% {transform: translate3d(@bw * 0.703,@bw * 0.369,@bw * 0.0) rotate(244.53deg) scale(1.26,1.26);}
        50.39% {transform: translate3d(@bw * 0.91,@bw * 0.478,@bw * 0.0) rotate(327.12deg) scale(1.26,1.26);}
        60.47% {transform: translate3d(@bw * 1.118,@bw * 0.588,@bw * 0.0) rotate(409.71deg) scale(1.26,1.26);}
        70.54% {transform: translate3d(@bw * 1.325,@bw * 0.696,@bw * 0.0) rotate(492.29deg) scale(1.26,1.26);}
        72.09% {transform: translate3d(@bw * 1.358,@bw * 0.714,@bw * 0.0) rotate(505.00deg) scale(1.26,1.26);}
        100.00% {transform: translate3d(@bw * 1.358,@bw * 0.714,@bw * 0.0) rotate(505.00deg) scale(1.26,1.26);}
        }
    .BX_Class5{
        left:@bw * 0.153;
        top: -@bw * 0.288;
        width:@bw * 0.126;
        height:@bw * 0.11;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(38.00deg) scale(2.13,2.13);
        opacity: 0.77;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani5{
        animation:zhuan2BX_AniKey5 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey5{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(38.00deg) scale(2.13,2.13);}
        10.08% {transform: translate3d(@bw * 0.132,@bw * 0.135,@bw * 0.0) rotate(92.42deg) scale(2.13,2.13);}
        20.16% {transform: translate3d(@bw * 0.264,@bw * 0.269,@bw * 0.0) rotate(146.84deg) scale(2.13,2.13);}
        30.23% {transform: translate3d(@bw * 0.396,@bw * 0.404,@bw * 0.0) rotate(201.26deg) scale(2.13,2.13);}
        40.31% {transform: translate3d(@bw * 0.528,@bw * 0.539,@bw * 0.0) rotate(255.68deg) scale(2.13,2.13);}
        50.39% {transform: translate3d(@bw * 0.661,@bw * 0.674,@bw * 0.0) rotate(310.09deg) scale(2.13,2.13);}
        60.47% {transform: translate3d(@bw * 0.793,@bw * 0.808,@bw * 0.0) rotate(364.51deg) scale(2.13,2.13);}
        66.67% {transform: translate3d(@bw * 0.875,@bw * 0.892,@bw * 0.0) rotate(398.00deg) scale(2.13,2.13);}
        100.00% {transform: translate3d(@bw * 0.875,@bw * 0.892,@bw * 0.0) rotate(398.00deg) scale(2.13,2.13);}
        }
    .BX_Class4{
        left: -@bw * 0.154;
        top:@bw * 0.731;
        width:@bw * 0.126;
        height:@bw * 0.11;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(66.00deg) scale(1.46,1.46);
        opacity: 0.77;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani4{
        animation:zhuan2BX_AniKey4 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey4{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(66.00deg) scale(1.46,1.46);}
        10.08% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(102.45deg) scale(1.46,1.46);}
        20.16% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(138.90deg) scale(1.46,1.46);}
        30.23% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(175.35deg) scale(1.46,1.46);}
        40.31% {transform: translate3d(@bw * 0.136,@bw * 0.061,@bw * 0.0) rotate(211.80deg) scale(1.46,1.46);}
        41.09% {transform: translate3d(@bw * 0.156,@bw * 0.071,@bw * 0.0) rotate(214.60deg) scale(1.46,1.46);}
        50.39% {transform: translate3d(@bw * 0.389,@bw * 0.176,@bw * 0.0) rotate(248.25deg) scale(1.46,1.46);}
        60.47% {transform: translate3d(@bw * 0.642,@bw * 0.292,@bw * 0.0) rotate(284.70deg) scale(1.46,1.46);}
        70.54% {transform: translate3d(@bw * 0.894,@bw * 0.407,@bw * 0.0) rotate(321.15deg) scale(1.46,1.46);}
        80.62% {transform: translate3d(@bw * 1.149,@bw * 0.521,@bw * 0.0) rotate(357.60deg) scale(1.46,1.46);}
        90.70% {transform: translate3d(@bw * 1.186,@bw * 0.539,@bw * 0.0) rotate(394.05deg) scale(1.46,1.46);}
        100.00% {transform: translate3d(@bw * 1.186,@bw * 0.539,@bw * 0.0) rotate(426.00deg) scale(1.46,1.46);}
    }
    .BX_Class3{
        left: -@bw * 0.243;
        top:@bw * 0.178;
        width:@bw * 0.126;
        height:@bw * 0.11;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(-29.00deg) scale(1.26,1.26);
        opacity: 0.77;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani3{
        animation:zhuan2BX_AniKey3 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey3{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(-29.00deg) scale(1.26,1.26);}
        24.81% {transform: translate3d(@bw * 0.012,@bw * 0.008,@bw * 0.0) rotate(-24.56deg) scale(1.26,1.26);}
        30.23% {transform: translate3d(@bw * 0.103,@bw * 0.074,@bw * 0.0) rotate(6.48deg) scale(1.26,1.26);}
        40.31% {transform: translate3d(@bw * 0.271,@bw * 0.193,@bw * 0.0) rotate(64.15deg) scale(1.26,1.26);}
        50.39% {transform: translate3d(@bw * 0.439,@bw * 0.312,@bw * 0.0) rotate(121.81deg) scale(1.26,1.26);}
        60.47% {transform: translate3d(@bw * 0.608,@bw * 0.432,@bw * 0.0) rotate(179.47deg) scale(1.26,1.26);}
        70.54% {transform: translate3d(@bw * 0.776,@bw * 0.551,@bw * 0.0) rotate(237.13deg) scale(1.26,1.26);}
        80.62% {transform: translate3d(@bw * 0.944,@bw * 0.672,@bw * 0.0) rotate(294.79deg) scale(1.26,1.26);}
        90.70% {transform: translate3d(@bw * 1.112,@bw * 0.792,@bw * 0.0) rotate(352.46deg) scale(1.26,1.26);}
        100.00% {transform: translate3d(@bw * 1.261,@bw * 0.897,@bw * 0.0) rotate(403.00deg) scale(1.26,1.26);}
    }
    .BX_Class2{
        left: -@bw * 0.185;
        top:@bw * 1.039;
        width:@bw * 0.126;
        height:@bw * 0.11;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(40.00deg) scale(1.00,1.00);
        opacity: 0.77;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani2{
        animation:zhuan2BX_AniKey2 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey2{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(40.00deg) scale(1,1);}
        10.08% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(76.45deg) scale(1,1);}
        20.16% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(112.90deg) scale(1,1);}
        30.23% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(149.35deg) scale(1,1);}
        40.31% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(185.80deg) scale(1,1);}
        50.39% {transform: translate3d(@bw * 0.132,@bw * 0.062,@bw * 0.0) rotate(222.25deg) scale(1,1);}
        60.47% {transform: translate3d(@bw * 0.372,@bw * 0.175,@bw * 0.0) rotate(258.70deg) scale(1,1);}
        70.54% {transform: translate3d(@bw * 0.611,@bw * 0.288,@bw * 0.0) rotate(295.15deg) scale(1,1);}
        80.62% {transform: translate3d(@bw * 0.85,@bw * 0.401,@bw * 0.0) rotate(331.60deg) scale(1,1);}
        90.70% {transform: translate3d(@bw * 1.089,@bw * 0.514,@bw * 0.0) rotate(368.05deg) scale(1,1);}
        100.00% {transform: translate3d(@bw * 1.3,@bw * 0.614,@bw * 0.0) rotate(400.00deg) scale(1,1);}
    }
    .BX_Class1{
        left: -@bw * 0.254;
        top:@bw * 0.364;
        width:@bw * 0.126;
        height:@bw * 0.11;
        transform-style: preserve-3d;
        transform: translate3d(0,0,@bw * 0.0) rotate(0.00deg) scale(1.00,1.00);
        opacity: 0.77;
        transform-origin:50.00% 50.00%;
        display: block;
        position: absolute; 
    }
    .zhuan2BX_Ani1{
        animation:zhuan2BX_AniKey1 12.90s linear 0.00s infinite normal none;
    }
    @keyframes zhuan2BX_AniKey1{
        0.00% {transform: translate3d(@bw * 0.0,@bw * 0.0,@bw * 0.0) rotate(0.00deg) scale(1,1);}
        10.08% {transform: translate3d(@bw * 0.203,@bw * 0.126,@bw * 0.0) rotate(53.79deg) scale(1,1);}
        20.16% {transform: translate3d(@bw * 0.407,@bw * 0.254,@bw * 0.0) rotate(107.59deg) scale(1,1);}
        30.23% {transform: translate3d(@bw * 0.611,@bw * 0.382,@bw * 0.0) rotate(161.38deg) scale(1,1);}
        40.31% {transform: translate3d(@bw * 0.814,@bw * 0.508,@bw * 0.0) rotate(215.17deg) scale(1,1);}
        50.39% {transform: translate3d(@bw * 1.018,@bw * 0.636,@bw * 0.0) rotate(268.97deg) scale(1,1);}
        60.47% {transform: translate3d(@bw * 1.222,@bw * 0.764,@bw * 0.0) rotate(322.76deg) scale(1,1);}
        67.44% {transform: translate3d(@bw * 1.364,@bw * 0.853,@bw * 0.0) rotate(360.00deg) scale(1,1);}
        100.00% {transform: translate3d(@bw * 1.364,@bw * 0.853,@bw * 0.0) rotate(360.00deg) scale(1,1);}
    }
</style>