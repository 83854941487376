<template>
	<div class="luo7_box po pointer" :style="getStyles">
		<template v-if="luoItems.length && $util.typeOf(luoItems[0]) == 'object'">
			<div v-for="idx in 6"
				:class="'luo7_item luo7_' + idx"
				:key="'luo7_' + idx">
				<elem 
					:mode="getLuoItem(idx)"
					:currentPhoto="currentPhoto"
					:canIShow="canIShow"></elem>
			</div>
		</template>
		<template v-else>
			<img v-for="idx in 6"
				mode="widthFix"
				:class="'luo7_item luo7_' + idx"
				:key="'luo7_' + idx"
				:src="getRandomLuo()">
		</template>
	</div>
</template>

<script>
import BaseLuo from './BaseLuo'
export default {
	name: 'luo7',
	extends: BaseLuo,
}
</script>

<style>
	.luo7_box {
	    width: 500px;
	    height: 100%;
	    min-height: 815px;
	    overflow: hidden;
	    pointer-events: none;
	}
	.luo7_item {
		right: 0!important;
		left: initial!important;
	}
	.luo7_1 {
	    position:absolute;
	    top:142px;
	    left:519px;
	    -webkit-animation: luo7_1 7.8s 1s linear infinite;
	}
	/*@keyframes luo7_1 {
		0% {
		    -webkit-transform: translate(0px,0px) rotate(0deg);
		}
		100% {
		    -webkit-transform: translate(-595px,365px) rotate(350deg);
		}
	}*/
	.luo7_2 {
	    position:absolute;
	    top:50px;
	    left:517px;
	    -webkit-animation: luo7_2 7.5s 3s linear infinite;
	}
	.luo7_3 {
	    position:absolute;
	    top:-12px;
	    left:440px;
	    -webkit-animation: luo7_3 9.5s 4s linear infinite;
	}
	.luo7_4 {
	    position:absolute;
	    top:-19px;
	    left:375px;
	    -webkit-animation: luo7_4 6.3s 5s linear infinite;
	}
	.luo7_5 {
	    position:absolute;
	    top:-50px;
	    left:250px;
	    -webkit-animation: luo7_5 10.3s 2s linear infinite;
	}
	.luo7_6 {
	    position:absolute;
	    top:-40px;
	    left:375px;
	    -webkit-animation: luo7_6 6.3s linear 0.5s infinite;
	}
	@-webkit-keyframes luo7_2 {
	    0% {
			opacity: 0;
	        -webkit-transform:translate(0px, 0px) rotateX(0deg) rotateY(0deg);
	    }
		5% {
			opacity: 1;
		}
	    15% {
	        -webkit-transform:translate(-102px, 40px) rotateX(75deg) rotateY(75deg);
	    }
	    34.67% {
	        -webkit-transform:translate(-200px, 127px) rotateX(180deg) rotateY(180deg);
	    }
	    100% {
	        -webkit-transform:translate(-595px, 365px) rotateX(720deg) rotateY(180deg);
	    }
	}
	@-webkit-keyframes luo7_1 {
	    from {
			opacity: 0;
	        -webkit-transform:translate(0px, 0px) rotate(0deg);
	    }
		5% {
			opacity: 1;
		}
	    to {
	        -webkit-transform:translate(-595px, 365px) rotate(350deg);
	    }
	}
	@-webkit-keyframes luo7_3 {
	    from {
			opacity: 0;
	        -webkit-transform:translate(0px, 0px) rotate(0deg);
	    }
		5% {
			opacity: 1;
		}
	    to {
	        -webkit-transform:translate(-500px, 665px) rotate(350deg);
	    }
	}
	@-webkit-keyframes luo7_4 {
	    0% {
			opacity: 0;
	        -webkit-transform:translate(0px, 0px) rotateX(0deg) rotateY(0deg);
	    }
		5% {
			opacity: 1;
		}
	    15% {
	        -webkit-transform:translate(-60px, 40px) rotateX(75deg) rotateY(75deg);
	    }
	    34.67% {
	        -webkit-transform:translate(-140px, 207px) rotateX(180deg) rotateY(180deg);
	    }
	    100% {
	        -webkit-transform:translate(-400px, 506px) rotateX(720deg) rotateY(450deg);
	    }
	}
	@-webkit-keyframes luo7_5 {
	    0% {
	        -webkit-transform:translate(0px, 0px) rotateX(0deg) rotateY(0deg);
	        opacity:0;
	    }
		5% {
			opacity: 1;
		}
	    34.67% {
	        -webkit-transform:translate(-60px, 292px) rotateX(180deg) rotateY(180deg);
	        opacity:1;
	    }
	    90% {
	        opacity:1;
	    }
	    100% {
	        -webkit-transform:translate(-150px, 980px) rotateX(510deg) rotateY(510deg);
	        opacity:0;
	    }
	}
	@-webkit-keyframes luo7_6 {
	    0% {
			opacity: 0;
	        -webkit-transform:translate(0px, 0px) rotateX(0deg) rotateY(0deg);
	    }
		5% {
			opacity: 1;
		}
	    15% {
	        -webkit-transform:translate(-45px, 40px) rotateX(75deg) rotateY(75deg);
	    }
	    34.67% {
	        -webkit-transform:translate(-105px, 207px) rotateX(180deg) rotateY(180deg);
	    }
	    100% {
	        -webkit-transform:translate(-300px, 506px) rotateX(720deg) rotateY(450deg);
	    }
	}
</style>