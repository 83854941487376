<template>
    <st-button class="st-button_in_elem"
        v-if="canStButtonShow"
        ref="st_button"
        :rid="rid"
        :rconfig="rconfig"
        @onCallBack="onCallBack"
        :style="getItemStyle(mode)">
        <!-- 处理子elems嵌套 -->
		<elem v-for="(elem, index) in elems"
			:key="'button_sub_elems_' + index"
			:mode="elem"
			:currentPhoto="currentPhoto"
			:currentTime="currentTime"
			:canIShow="canIShow"
			:currentImage="currentImage"
			:scene="scene"
            :line="line"></elem>
    </st-button>
</template>

<script>
	import BaseElem from '../BaseElem'

    export default {
        name: 'StButtonTrans',
		extends: BaseElem,
        data () {
            return {
                rid: '',
                rconfig: {}
            }
        },
        computed: {
            config () {
                return this.comp['config'] || {};
            },
            canStButtonShow () {
                return !(this.rid == '' && JSON.stringify(this.rconfig) == '{}');
            }
        },
        watch: {
            pageDatas: {
                handler () {
                    this.rid = this.config['rid'] || '';
                    
                    let rconfig = this.config['rconfig'] || {};
                    if (JSON.stringify(rconfig) != '{}') {
                        rconfig = JSON.parse( this.replaceReg( JSON.stringify(rconfig) ));
                    }
                    
                    this.rconfig = rconfig;
                },
                immediate: true
            }
        },
        created () {
        },
        methods: {
            onCallBack (options) {
                if (this.config['callback']) {
                    // 调用stbutton内部的runFunc来完成对应函数的执行
                    let argvs = options.params ? Object.assign(options.params, this.config['callback']['argvs']) : this.config['callback']['argvs'];
                    this.$refs.st_button.$children[0].runFunc(this.config['callback']['func'], argvs);
                }
                this.$emit("onEvent", options);
            },
        }
    }
</script>

<style>
.st-button_in_elem{
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    border: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
</style>