<template>
	<div class="light" :style="getStyles">
		<img src="http://resali.lititutu.cn/tuku/7c60c228cde3383c404175e9e7a3ac3c.png">
	</div>
</template>

<script>
	export default {
		name: 'light',
		props: ["mode"],
		data () {
			return {}
		},
		computed: {
			getStyles () {
				let item = this.mode;
				
				let s = {};
				
				if (item['zIndex']) {
					s['zIndex'] = item['zIndex'];
				}

				if (item['style']) {
					s = Object.assign(s, item['style']);
				}

				return s;
			}
		}
	}
</script>

<style>
	.light {
		position: absolute;
		animation: 0s ease 0s 1 normal none running none;
		top: -20px;
		right: -20px;
		opacity: 0.8;
		transform: rotateY(180deg);
		pointer-events: none;
	}
	.light img {
		transform-origin: left top;
		animation: light 7s ease-in-out infinite alternate both;
	}
	@keyframes light {
		0% {
		    transform: rotateZ(0);
		}
		100% {
		    transform: rotateZ(-15deg);
		}
	}
</style>