<template>
    <div class="base-component-container po"></div>
</template>

<script>
	// import my_keyframes_data from './keyframes.js'
	// import './keyframes.css'
	import StBaseComp from "../StBaseComp";

	export default {
		name: 'BaseComponent',
		extends: StBaseComp,
        props: ['mode', 'currentImage'],
		data () {
			return {
				cssHtml: '' // 用于装载css的html代码
			}
		},
        computed: {
            getStyles () {
                let s = this.getItemStyle(this.mode);
				return s;
			},
			comp () {
				let comp = {};
				if (this.mode && this.mode['comp']) {
					comp = this.mode.comp;
				}
				return comp;
			},
			comp_config () {
				return this.comp['config'] || [];
			},
			elems () {
				let elems = [];
				if (this.mode['elems']) {
					elems = this.mode['elems'];
				}
				return elems;
			}
        },
        methods:{
			getItemStyle(item){
				let s = {};

				if(this.$util.typeOf(item) != 'object'){
					return s;
				}

				if (item['size']) {
					if (item['size'][0]) {
						s['width'] = this.$util.getPx(item['size'][0]);
					}
					if (item['size'][1]) {
						if (this.$util.typeOf(item['size'][1]) == 'string' && item['size'][1].indexOf('navFullHeight') != -1) {
							let navFullHeight = this.$util.getSystemInfoSync()['navFullHeight'] || 20;
							item['size'][1] = item['size'][1].replace('navFullHeight', navFullHeight + 'px');
						}
						s['height'] = this.$util.getPx(item['size'][1]);
					}
				} else if ((!item['is'] || item['is'] == 'elem') && !item['sprite_image']) { // 结构无指定is，或is为elem，且不是gif的时候默认铺满大小
					if (!item['pos_type']) {
						s['width'] = '100%';
						s['height'] = '100%';
					}
				}

				if (item['pos_type'] == 1) {
					// 相对定位
					s['position'] = 'relative!important';
					if (item['pos'] || item['pos_mode']) {
						let padding_type = item['pos_padding'] ? 'padding' : 'margin';
						// 情况一 pos为正常的坐标数组
						if (this.$util.typeOf(item['pos']) == 'array') {
							s[padding_type+'-top'] = this.$util.getPx(item['pos'][0]);
							s[padding_type+'-right'] = this.$util.getPx(item['pos'][1]);
							s[padding_type+'-bottom'] = this.$util.getPx(item['pos'][2]);
							s[padding_type+'-left'] = this.$util.getPx(item['pos'][3]);
						}
						// 情况二 有pos_mode
						if (item['pos_mode']) {
							if (item['pos_mode'] == 'center_x') {
								s[padding_type+'-left'] = 'auto';
								s[padding_type+'-right'] = 'auto';
							}
						}
					} else {
						s['top'] = '0px';
						s['left'] = '0px';
						s['right'] = 'inital';
						s['bottom'] = 'inital';
					}
				} else {
					if (item['pos_type'] == 2) {
						// fixed定位
						s['position'] = 'fixed!important';
					}
					// 绝对定位
					if (item['pos'] || item['pos_mode']) {
						// 情况一 pos为正常的坐标数组
						if (this.$util.typeOf(item['pos']) == 'array') {
							if (item['pos'].length == 2) {
								s['transform'] = 'translate('+ this.$util.getPx(item['pos'][0]) +', '+ this.$util.getPx(item['pos'][1]) +')';
							} else if (item['pos'].length == 4) {
								s['top'] = this.$util.getPx(item['pos'][0]);
								s['right'] = this.$util.getPx(item['pos'][1]);
								s['bottom'] = this.$util.getPx(item['pos'][2]);
								s['left'] = this.$util.getPx(item['pos'][3]);
							}
						}

						// 获取用于定位计算的长宽值 优先填写style中的长宽，其次是gif中帧图片的长宽，否则为 0
						if (item['size']) {
							let unit_width = s['width'].replace(/(\-)?\d+(\.)?(\d+)?/g, ''),
								unit_height = s['height'].replace(/(\-)?\d+(\.)?(\d+)?/g, '');
							let pos_width = parseInt(s['width']) || parseInt(item['width']) || 0,
								pos_height = parseInt(s['height']) || parseInt(item['height']) || 0;

							// 情况二 pos直接是center
							if (this.$util.typeOf(item['pos']) == 'string') {
								if (item['pos'] == 'center') {
									s['top'] = '50%';
									s['left'] = '50%';
									s['marginLeft'] = '-' + (pos_width / 2) + unit_width;
									s['marginTop'] = '-' + (pos_height / 2) + unit_height;
								}
							}
							// 情况三 有pos_mode
							if (item['pos_mode']) {
								if (item['pos_mode'] == 'center_x') {
									s['top'] = item['pos'] ? this.$util.getPx(item['pos'][0]) : 'initial';
									s['bottom'] = item['pos'] ? this.$util.getPx(item['pos'][2]) : 'initial';
									s['left'] = '50%';
									s['marginLeft'] = '-' + (pos_width / 2) + unit_width;
								} else if (item['pos_mode'] == 'center_y') {
									s['left'] = item['pos'] ? this.$util.getPx(item['pos'][3]) : 'initial';
									s['right'] = item['pos'] ? this.$util.getPx(item['pos'][1]) : 'initial';
									s['top'] = '50%';
									s['marginTop'] = '-' + (pos_height / 2) + unit_height;
								} else if (item['pos_mode'] == 'center') {
									s['top'] = '50%';
									s['left'] = '50%';
									s['marginLeft'] = '-' + (pos_width / 2) + unit_width;
									s['marginTop'] = '-' + (pos_height / 2) + unit_height;	
								}
							}
						}
					} else {
						s['top'] = '0px';
						s['left'] = '0px';
						s['right'] = 'inital';
						s['bottom'] = 'inital';
					}
				}
				
				// 层级
				if (item['zIndex']) {
					s['zIndex'] = item['zIndex'];
				} else {
					s['zIndex'] = 0;
				}
				// 由于taro小程序中zIndex为0无法显式设置，导致原生小程序层级出现问题，故所有zIndex默认+1，最低为1
				if (this.$util.isEnv("wxa")) {
					s['zIndex'] = parseInt(s['zIndex']) + 1;
				}

				// 老遮罩逻辑、已更新至ElemMask
				if (item['mask']) {
					if (this.$util.typeOf(item['mask']) == 'string') {
						s['WebkitMaskImage'] = "url("+item['mask']+")";
						s['WebkitMaskRepeat'] = "no-repeat";
						s['WebkitMaskPosition'] = "0 0";
						s['WebkitMaskSize'] = "100% 100%";
					} else if (this.$util.typeOf(item['mask']) == 'object' && item['mask']['maskImage']) {
						s['WebkitMaskImage'] = "url("+item['mask']['maskImage']+")";
						s['WebkitMaskRepeat'] = "no-repeat";
						s['WebkitMaskPosition'] = item['mask']['maskPosition'];
						s['WebkitMaskSize'] = item['mask']['maskSize'];
					}
				}

				// 绑定动画
				if (item['keyframe']) {
					let cssHtml = "";
					let build_keyframes = (_keyframe) => {
						if (_keyframe['html']) {
							let {
								keyframe_id,
								keyframe_html
							} = this.$util.getElemKeyframeStyle(_keyframe);
							_keyframe['id'] = keyframe_id;
							cssHtml += "<style>"+ keyframe_html +"</style>";
						}
						return this.getKeyframeStyle(_keyframe);
					}
					if (this.$util.typeOf(item['keyframe']) == "array") {
						let keyframe_styles = [];
						for (let i=0; i<item.keyframe.length; i++) {
							keyframe_styles.push( build_keyframes(item.keyframe[i])['animation'] );
						}
						s = Object.assign(s, {
							"animation": keyframe_styles.join(",")
						})
					} else {
						let keyframe_style = build_keyframes(item.keyframe);
						s = Object.assign(s, keyframe_style);
					}
					this.cssHtml = cssHtml;
				}

				// 字号
                if (item['font_size']) {
                    s['font-size'] = this.$util.getPx(item['font_size']);
                }

				// 文字颜色
                if (item['font_color']) {
                    s['color'] = item['font_color'];
                }

				// 文字粗细
                if (item['font_weight']) {
                    s['font-weight'] = item['font_weight'];
                }

				// 背景颜色
				if (item['border_radius']) {
					s['border-radius'] = this.$util.getPx(item['border_radius']);
				}

				// 背景颜色
				if (item['bg_color']) {
					s['background-color'] = item['bg_color'];
				}

				if (item['style']) {
					s = Object.assign(s, item['style']);
				}

				// 做单位转换
				s = this.$util.pxTransform(s, 'style');

				return s;
			},
			getItemIm (item) {
				let im = "";
				if (this.$util.typeOf(item) == 'object') {
					im = item['im'];
				} else if (this.$util.typeOf(item) == 'string') {
					im = item;
				}
				return im;
			},
			// 根据keyframe获取animation属性
			getKeyframeStyle (o_keyframe) {
				let keyframe = this.$util.copy(o_keyframe);
				let keyframe_handler = typeof keyframes_data == 'undefined' ? this.$util.$keyframes && this.$util.$keyframes['keyframes_data'] : keyframes_data;
				if (this.$util.typeOf(keyframe) == 'string') {
					return {
						'animation': keyframe + " " + keyframe_handler[keyframe]['animation']
					}
				} else {
					let style = {};

					if (!keyframe['animation']) {
						keyframe['animation'] = keyframe_handler && keyframe_handler[keyframe['id']] ? keyframe_handler[keyframe['id']]['animation'] : "";
					}

					if (keyframe['animation']) {
						// style['animation'] = keyframe['id'] + " " + keyframe['animation'] + "!important";
						style['animation'] = keyframe['id'] + " " + keyframe['animation'];
					}
			
					return style;
				}
			}
        }
	}
</script>

<style>
    
</style>