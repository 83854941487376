<template>
	<div class="promo-box">
		<div class="promo-mask" @click="onPromoScanHide"></div>
		<div class="promo-contain" @touchstart="onPromoTouchStart" @touchend="onPromoTouchEnd">
			<img class="promo-close" src="http://litiresource.xcgogo.site/user/7175946/images/232f1de590d8e035b16f6784ebfe4ffc.png" @click="onPromoScanHide">
			<img class="promo-finger" :src="promoFingerImage">
			<img class="promo-qrcode" :src="promoQrcode">
		</div>
	</div>
</template>

<script>

	let G = {
		longTouchEvent: null,
		promo_t: '',
	}

	export default {
		name: 'Promo',
		data () {
			return {
				// isPromoScanShow: true,
				// promoFingerImage: ''
				// photoDownQrcode: '',
				// promoQrcode: '',
			}
		},
		props: ['isPromoScanShow', 'promoFingerImage', 'promoQrcode', 'promoFrom'],
		methods: {
			onPromoTouchStart () {
				let _this = this;
				clearTimeout(G.longTouchEvent);
				G.longTouchEvent = setTimeout(() => {
					let mta_param = {};

					mta_param[_this.promoFrom] = 'true';
					
					_this.$mta.click('mybook_promo_longtouch', mta_param);

					// setTimeout(() => {
					// 	_this.onPromoScanHide();
					// }, 3000);
				}, 800);
			},
			onPromoTouchEnd () {
				clearTimeout(G.longTouchEvent);
			},
			onPromoScanHide () {
				this.$emit('onPromoScanHide');
			}
		}
	}
</script>

<style lang="less" scoped>
	.promo-box {
		position: fixed;
		z-index: 9999;
	}
	.promo-box, .promo-mask, .promo-item-mask {
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.promo-mask, .promo-item-mask {
		position: absolute;
		z-index: 1;
		background-color: rgba(0,0,0,.5);
	}
	.promo-contain {
		position: absolute;
		z-index: 2;
		width:@bw * 0.639;
		height:@bw * 0.764;
		left: 50%; top: 50%;
		margin-left: -@bw * 0.319;
		margin-top: -@bw * 0.382;
		border-radius:@bw * 0.021;
	}
	.promo-close {
		position: absolute;
		width:@bw * 0.083;
		height:@bw * 0.083;
		right: -@bw * 0.083;
		top: -@bw * 0.083;
	}
	.promo-finger {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		border-radius:@bw * 0.021;
	}
	.promo-qrcode {
		position: absolute;
		bottom:@bw * 0.028;
		right:@bw * 0.028;
		width:@bw * 0.139;
		// height:@bw * 0.139;
	}
</style>