<!-- 圆圈绕中间转效果 -->

<!-- 
{
    "id": "quanzhuan",
    "name": "3D圆圈转",
    "example": "https://internal-api-drive-stream.feishu.cn/space/api/box/stream/download/video/boxcnnKlPDbNu9Gpe3MXPf2a8Yc/?quality=360p&data_version=6977624445334618115",
    "comp": {
        "border": "上方框边框",
        "border1": "下圆形边框"
    },
    "elems": [
        "上方框区域",
        "下圆形区域"
    ]
}
-->

<template>
	<div class="page po">
        <div class="quanzhuan7_1 po">
            <div class="quanzhuan7_1_con preserve3d page po">
                <div class="quanzhuan7_1_item po"
                    v-for="idx of 10"
                    :key="'quanzhuan7_1_' + idx"
                    :class="'quanzhuan7_1_' + idx">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <!-- <div class="quanzhuan7_1_item quanzhuan7_1_1 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_2 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_3 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_4 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_5 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_6 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_7 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_8 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_9 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="quanzhuan7_1_item quanzhuan7_1_10 po">
                    <div class="quanzhuan7_1_div7 page po">
                        <template v-if="comp['elems'] && comp['elems'][1]">
                            <elem :mode="comp['elems'][1]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                        </template>
                        <template v-else>
                            <div class="page po bg bg_photo circle oh" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                                <img :src="(comp['config'] && comp['config'][2]) || 'http://resali.lititutu.cn/tuku/td/1defdeb9e3949cb576a7c600da51483f.png'" class="quanzhuan7_kuang po">
                            </div>
                        </template>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="quanzhuan7_2 page po">
            <div class="quanzhuan7_2_1 preserve3d po">
                <template v-if="comp['elems'] && comp['elems'][0]">
                    <elem :mode="comp['elems'][0]" :canIShow="canIShow" :currentPhoto="currentPhoto"></elem>
                </template>
                <template v-else>
                    <div class="quanzhuan7_2_1_zhuan page preserve3d po">
                        <div class="quanzhuan7_2_rim1 page po">
                            <div class="bg bg_photo po" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                            </div>
                            <div class="quanzhuan7_2_kuang page po" :style="{'borderImageSource': 'url('+ ((comp['config'] && comp['config'][0]) || 'http://resali.lititutu.cn/tuku/td/d0b53faad1a9ff956785459dcfdb272f.png') +')'}">
                            </div>
                        </div>
                        <div class="quanzhuan7_2_border1 quanzhuan7_2_border po" :style="{'backgroundImage': 'url('+ (comp['config'] && comp['config'][1] || 'http://resali.lititutu.cn/tuku/td/784285113cca9fb302d74f8df1b9e9e8.jpg') +')'}">
                        </div>
                        <div class="page7_border2 quanzhuan7_2_border po" :style="{'backgroundImage': 'url('+ (comp['config'] && comp['config'][1] || 'http://resali.lititutu.cn/tuku/td/784285113cca9fb302d74f8df1b9e9e8.jpg') +')'}">
                        </div>
                        <div class="quanzhuan7_2_rim2 page po">
                            <div class="bg bg_photo po" :style="{'backgroundImage': 'url('+currentPhoto['im']+')'}">
                            </div>
                            <div class="quanzhuan7_2_kuang page po" :style="{'borderImageSource': 'url('+ ((comp['config'] && comp['config'][0]) || 'http://resali.lititutu.cn/tuku/td/d0b53faad1a9ff956785459dcfdb272f.png') +')'}">
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'

	export default {
		name: 'Quanzhuan',
        extends: BaseElem,
		data () {
			return {}
		},
        created () {
        }
	}
</script>

<style>
	.quanzhuan7_1 {
        width: 500px;
        height: 50%;
        left: 0;
        right: 0;
        top: 40%;
        bottom: 0;
        margin: auto;
        -webkit-perspective: 720px;
    }
    .quanzhuan7_1_con {
        -webkit-animation: quanzhuan7_1_rotate1 14s linear infinite both;
    }
    @keyframes quanzhuan7_1_rotate1 {
        0% {
            -webkit-transform: rotateX(-12deg) rotateY(0deg);
        }
        100% {
            -webkit-transform: rotateX(-12deg) rotateY(-360deg);
        }
    }
    .quanzhuan7_1_item {
        width: 120px;
        height: 120px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .quanzhuan7_1_1 {
        -webkit-transform: rotateY(0deg) translateZ(250px);
    }
    .quanzhuan7_1_div7 {
    }
    .quanzhuan7_kuang {
        width: 100%;
        height: 100%;
    }
    .quanzhuan7_1_2 {
        -webkit-transform: rotateY(36deg) translateZ(250px);
    }
    .quanzhuan7_1_3 {
        -webkit-transform: rotateY(72deg) translateZ(250px);
    }
    .quanzhuan7_1_4 {
        -webkit-transform: rotateY(108deg) translateZ(250px);
    }
    .quanzhuan7_1_5 {
        -webkit-transform: rotateY(144deg) translateZ(250px);
    }
    .quanzhuan7_1_6 {
        -webkit-transform: rotateY(180deg) translateZ(250px);
    }
    .quanzhuan7_1_7 {
        -webkit-transform: rotateY(216deg) translateZ(250px);
    }
    .quanzhuan7_1_8 {
        -webkit-transform: rotateY(252deg) translateZ(250px);
    }
    .quanzhuan7_1_9 {
        -webkit-transform: rotateY(288deg) translateZ(250px);
    }
    .quanzhuan7_1_10 {
        -webkit-transform: rotateY(324deg) translateZ(250px);
    }
    .quanzhuan7_2 {
        -webkit-perspective: 1600px;
    }
    .quanzhuan7_2_1 {
        width: 380px; 
        height: 563px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 28%;
        margin: auto;
    }
    .quanzhuan7_2_1_zhuan {
        animation: quanzhuan7_1_rotate2 6s linear infinite both;
    }
    @keyframes quanzhuan7_1_rotate2 {
        0% {
            -webkit-transform: rotateY(0deg);
        }
        100% {
            -webkit-transform: rotateY(-360deg);
        }
    }
    .quanzhuan7_2_rim1 {
        -webkit-transform: translateZ(-14px);
    }
    .quanzhuan7_2_kuang {
        border-style: solid;
        border-width: 18px;
        border-image-slice: 14;
        box-sizing: border-box;
    }
    .quanzhuan7_2_border1 {
        left: 0;
        -webkit-transform: translateX(-14px) translateZ(0) rotateY(-90deg);
    }
    .quanzhuan7_2_border {
        width: 28px;
        height: 100%;
        background-size: auto 100%;
        background-position: 0 0;
        backface-visibility: hidden;
    }
    .page7_border2 {
        right: 0;
        -webkit-transform: translateX(14px) translateZ(0) rotateY(90deg);
    }
    .quanzhuan7_2_rim2 {
        -webkit-transform: translateZ(14px);
    }
</style>