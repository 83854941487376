<template>
	<div class="empty">
		<div class="empty-row empty-title">
			<img class="empty-title_img" src="http://resali.lititutu.cn/icons/logo/logo_100.png">
			<span>该相册为空</span>
		</div>
		<div class="empty-row empty-content">
			<div class="empty-content_title">更多精彩推荐</div>
			<!-- <div class="empty-content_box" @click="jump('https://mp.weixin.qq.com/s/T7yZOLYX5I4ujMgtZDmozQ')" style="background-image: url(http://resali.lititutu.cn/icons/other/2317359996d697f2a8fd5b08f3b49606.gif);"></div> -->
			<template v-for="(book, index) in books_data.list">
				<!-- <template v-if="index == 1">
					<div class="empty-content_box" @click="jump(members[0][1])" :style="{backgroundImage: 'url('+ members[0][0] +')'}"></div>
				</template>
				<template v-else-if="index == 3">
					<div class="empty-content_box" @click="jump(members[1][1])" :style="{backgroundImage: 'url('+ members[1][0] +')'}"></div>
				</template> -->
				<SquareCell :book="book" :key="book.key" v-on:goDetail="goDetail"></SquareCell>
			</template>
		</div>
		<span class="empty-ft">更多精彩 敬请期待</span>
	</div>
</template>

<script>
	import SquareCell from '@/views/square/SquareCell'

	export default {
		name: 'Empty',
		data () {
			return {
				books_data: {
					list: [],
				},
				members: []
			}
		},
		components: {
            SquareCell
        },
		created () {
			let members = [
				['http://resali.lititutu.cn/icons/other/dc8c15ec0eca14bcfd2ddf16ae46647a.gif', 'https://mp.weixin.qq.com/s/_GepJy9uJmfimK7TgqoJaQ'], // 祝福
				['http://resali.lititutu.cn/icons/other/a96674345273e9d6cb479cbda16ba473.gif', 'https://mp.weixin.qq.com/s/yntnOhYaWu-VFZ9W2SvsHg'], // 美文
				// [this.$config['qun']['coverImgs'][parseInt(Math.random() * 10000) % this.$config['qun']['coverImgs'].length], 'https://mp.weixin.qq.com/s/lQJCQEuwDHGphuuViqSbGA'], // 返利群
			];
			members.sort(function(m, n) {
				return Math.random() > .5 ? -1 : 1;
			});
			this.members = members;

			this.$util.rget("get_square_data", {
                "type": "random"
            }).then(res => {
                if (!res["books"]) {
                    return;
                }
              
                var list = [];
                for (var i = 0; i < res.books.length; i++) {
                    var temp_book = res.books[i];
                    temp_book["userinfo"] = this.$o_util.transformUserdata(temp_book["userinfo"]);
                    temp_book["s_type"] = "m_liti";
                    temp_book['cover'] = this.$util.im(temp_book['cover'], 450);
                    list.push(temp_book);
                }
                this.books_data.list = this.books_data.list.concat(list);
            });
		},
		methods: {
			jump (url) {
				window.location.href = url;
			},
			goDetail(e){
                //转跳详情
                window.location.href = this.$o_util.getSharePath() + "?key=" + e.key + "&_source=" + e.value.appid;
                this.$o_util.log("square_pv")
				this.$o_util.log("square_pv_h5_empty")
            }
		}
	}
</script>

<style scoped>
	.empty {
		position: relative;
		background-color: rgb(39, 40, 44);
	}
	.empty-row {
		position: relative;
		top: 0;
		left: 0;
	}
	.empty-title {
		display: flex;
		justify-content: center;
		align-items: center;
	    text-align: center;
	    font-size: 36px;
	    font-weight: bold;
	    color: #a5a5a5;
	    height: 200px;
	}
	.empty-content {
		background-color: white;
		padding-bottom: 10px;
		border-radius: 20px;
	}
	.empty-title_img {
		position: relative;
		width: 80px;
		height: 80px;
		margin-top: -15px;
		margin-right: 12px;
	}
	.empty-content_box {
		position: relative;
        width: 480px;
        height: 258px;
        margin: 10px auto 0;
        /*border-radius: 10px;*/
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
	}
	.empty-content_box:active {
		background-color: #ccc;
	}
	.empty-content_title {
		font-size: 24px;
		font-weight: bold;
		padding: 15px;
		border-bottom: solid 1px rgba(224, 224, 224, 0.45);
		color: #383838;
	}
	.empty-ft {
		position: relative;
		text-align: center;
		padding: 20px 0;
		width: 100%;
		display: block;
		color: #999;
	}

</style>