import md5 from 'md5'
import { Base64 } from 'js-base64'

const _util = {
    // 环境判断
    isEnv (env) { // wxa \ h5 \ webview \ qq \ wx \ local \ vue \ iphonex|ipx
        if (this.typeOf(env) == "string") {
            let ua = navigator.userAgent.toLowerCase();

            if (env == "webview"){
                return this.$taro.getEnv() == "WEB" 
                    && (ua.indexOf('miniprogram') != -1 || ["localhost:8080", "wxa.litiskr.xyz","debugwxa.litiskr.xyz","debugg3.litiskr.cn","debugwebview.litiskr.cn","webview.litiskr.cn"].indexOf(window.location.host) > -1);
            }
            if (env == "qq") {
                return this.$taro.getEnv() == "WEB" && ua.match(/QQ\/[0-9]/i);
            }
            if (env == "wx") {
                return this.$taro.getEnv() == "WEB" && ua.match(/MicroMessenger/i) == "micromessenger";
            }
            if (env == "h5") {
                return this.$taro.getEnv() == "WEB";
            }
            if (env == "wxa") {
                return this.$taro.getEnv() == "WEAPP";
            }
            if (env == "local") {
                return window.location.host.indexOf("localhost") != -1 || window.location.host.indexOf('1008') != -1;
            }
            if (env == "android") {
                if (this.isEnv('wxa')) {
                    let systeminfo = this.getSystemInfoSync(),
                        platform = systeminfo['stPlatform'];
                    return platform == env;
                } else {
                    return ua.indexOf("android") > -1 || ua.indexOf("linux") > -1;
                }
            }
            if (env == "ios") {
                if (this.isEnv('wxa')) {
                    let systeminfo = this.getSystemInfoSync(),
                        platform = systeminfo['stPlatform'];
                    return platform == env;
                } else {
                    let is_ios = !!ua.match(/\(i[^;]+;( U;)? CPua.+Mac OS X/),
                        is_iphone = ua.indexOf('iphone') > -1 || ua.indexOf('mac') > -1,
                        is_ipad = ua.indexOf('ipad') > -1;
                    return is_ios || is_iphone || is_ipad;
                }
            }
            if (env == "vue") {
                return this.$taro.getEnv("isVue") === true;
            }
            if (env == "taro") {
                return Boolean( this.$taro.getEnv("isTaro") );
            }
            if (env == "iphonex" || env == "ipx") {
                let systeminfo = this.getSystemInfoSync();
                return systeminfo['isIpx'];
            }

        } else if (this.typeOf(env) == "array") { // 数组 match其中之一即可
            for(let i=0 ; i < env.length ; i++){
                if ( this.isEnv(env[i]) ) {
                    return true;
                }
            }
        } else {
            return false;
        }
    },
    // 判断变量类型
    typeOf (s) {
		let t = 'string';
		switch (Object.prototype.toString.call(s).toLowerCase()) {
			case '[object object]':
				t = 'object';
				break;
			case '[object array]':
				t = 'array';
				break;
			case '[object string]':
				t = 'string';
				break;
			case '[object number]':
				t = 'number';
				break;
            case '[object function]':
                t = 'function';
                break;
            case '[object boolean]':
                t = 'boolean';
                break;
            case '[object htmldivelement]':
                t = 'htmldivelement';
                break;
            case '[object date]':
                t = 'date';
                break;
		}
		return t;
	},
    // 深拷贝
    copy (o) {
        return JSON.parse(JSON.stringify(o));
    },
    // canvas用单位转换
    cpxTransform (size) {
        let clientWidth = this.getSystemInfoSync().screenWidth;
        return size * clientWidth / this.$config.__DESIGN_WIDTH__;
    },
    // 反向单位转换
    cpxReTransform (size) {
        let clientWidth = this.getSystemInfoSync().screenWidth;
        return size * this.$config.__DESIGN_WIDTH__ / clientWidth;
    },
    // 覆盖官方pxTransform 默认接受一个单位，mode为style则转化样式字典
    pxTransform (size, mode) {
        mode = mode || 'num';
        if ('num' == mode) {
            if (this.isEnv("h5")) {
                return this.$taro.pxTransform(size, this.$config.__DESIGN_WIDTH__);
            } else {
                return parseInt(size, 10) * this.$config.__DESIGN_RATIO__[ this.$config.__DESIGN_WIDTH__ ] + 'rpx';
            }
        } else if ('style' == mode) {
            if (this.isEnv("vue")) { // vue环境无需进行转换
                return size;
            }
            let style = this.copy(size);
            for (let key in style) {
                try {
                    if (this.typeOf(style[key]) == 'string' && style[key].indexOf('px') != -1) {
                        // 对多个px进行替换
                        style[key] = this.__pxExchange(style[key]);
                    }
                } catch(e) {
                    console.error(e);
                }
            }
            return style;
        }
    },
    __pxExchange (style) {
        let matches = style.match(/\d+(\.\d+)?px/g);
        for (let i=0;matches && i<matches.length; i++) {
            let m = matches[i];
            style = style.replace(m, this.pxTransform(parseFloat(m)));
        }
        return style;
    },
    rget (api, params) {
        return this.$request.requestHandler(api, params, "GET");
    },
    rpost (api, params, contentType) {
        return this.$request.requestHandler(api, params, "POST", contentType)
    },
    // base64编码文字
    encodeBase64 (text) {
		return Base64.encode(text);
	},
    // base64解码文字
    decodeBase64 (text) {
        try {
            // 判断是base64字符串才进行解码
            if (text.match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/)) {
            // // 适配urlsafe的base64
            // if (text.match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}|[A-Za-z0-9+/]{2})$/)) {
                text = Base64.decode(text);
            }
        } catch(e) {
            console.error("base64 fail: ", text, e);
        }
        return text
    },
    md5 (s) {
        if (['object', 'array'].indexOf( this.typeOf(s) ) != -1) {
            s = JSON.stringify(s);
        }
		return md5(s);
    },
    // 格式化数字
    transNum (num, unit) {
        if (this.typeOf(num) == "string") {
            num = parseInt(num);
        }
        let t = num;
        unit = unit ? unit : "w";
        if (num > 100000 && (unit == "sw")) {
            t = '10w+';
        } else if (num > 10000 && (unit == "sw" || unit == "w")) {
            t = '1w+';
        } else if (num > 1000 && (unit == "sw" || unit == "w" || unit == "k")) {
            t = '1千+';
        }
		return t;
    },
    toFixed (num, size) {
        size = size || 2;
        return parseFloat(num.toFixed(size));
    },
    // 从字符串中获取图片链接数组
    getImageUrlsFromString (s) {
		if (!s) {
			return [];
		}
		let m = s.match(/(https?:\/\/).*?\.(jpg|png|gif|jpeg)(\?v=\w+)?/gi);
		// let m = s.match(/(https?:\/\/)([0-9a-z\-.]+)(:[0-9]+)?([/0-9a-z.]+)?(\-[0-9a-z]\-[0-9a-z])?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?(_[0-9a-z.]+)?/gi);
		if (m) {
			let r = this.arrayDistinct(m);
			let i, idx, result = [];
			for (i=0; i<r.length; i++) {
				if (r[i].indexOf('{index}') != -1) {
					// 回避帧图片path预加载
					continue;
				}
				result.push(r[i]);
			}
			return result;
		} else {
			return [];
		}
    },
    // 递归查询json
	forJSON (json, mode, target) {
		mode = mode || 'key'; // key 根绝key搜索、keyword 包含部分关键字搜索

		let result = [];
		let that = this;
		function func(_json, _target) {
			if (that.typeOf(_json) == 'object') {
				for (let k in _json) {
					if (mode == 'key' && k == _target) {
						result.push(_json[k]);
					}
					func(_json[k], _target);
				}
			} else if (that.typeOf(_json) == 'array') {
				for (let i=0; i<_json.length; i++) {
					func(_json[i], _target);
				}
			} else {
				if (mode == 'keyword') {
					let targets = _target;
					if (that.typeOf(_target) == 'string') {
						targets = [_target];
					}
					if (that.arrInStr(targets, _json)) {
						result.push(_json);
					}
				}
			}
		}
		func(json, target);
		return result;
    },
    getComponentHeight (component, _id){
        let _this = this;
        return new Promise((resolve, reject)=>{
            setTimeout(() => {
                if(_this.isEnv("h5")){
                    if (_this.typeOf(_id) == 'string') {
                        resolve( document.getElementById(_id).offsetHeight )
                    } else {
                        resolve( _id.offsetHeight )
                    }
                } else {
                    if (_this.typeOf(_id) != 'string') {
                        _id = _id.id;
                    }
                    let query = _this.$taro.createSelectorQuery();
                    query.select('#' + _id).boundingClientRect(rect=>{
                        resolve( rect && rect.height ? rect.height : 0 )
                    }).exec();
                }
            }, 100);
        })
    },
    // 获取当前页面    
    getPageInstance() {
        var pages = this.$taro.getCurrentPages();
        return pages[pages.length - 1];
    },
    // 检测小程序更新
    checkUpdate () {
        if (this.isEnv("wxa")) { //小程序检测是否需要强制更新
            let that = this;
            if (wx.canIUse('getUpdateManager')) {
                const updateManager = wx.getUpdateManager()
                if(!updateManager){
                  return;
                }
                updateManager.onCheckForUpdate((res)=>{
                  if (res.hasUpdate) {
                    updateManager.onUpdateReady(function() {
                      wx.showModal({
                        title: '更新提示',
                        content: '新版本已经准备好了~',
                        showCancel: false,
                        success: function(res) {
                          if (res.confirm) {
                            that.$taro.removeStorage({
                                key: 'st_systeminfo'
                            })
                            updateManager.applyUpdate();
                          }
                        }
                      })
      
                    })
                    updateManager.onUpdateFailed(function() {
                      wx.showToast({
                        title: 'π__π 新的版本下载失败',
                      })
                    })
                  }
                })
            } else {
                wx.showModal({
                    title: '提示',
                    content: '当前微信版本过低，无法自动更新小程序，请升级到最新微信版本后重试。'
                })
            }
        }
    },
    // 从缓存中获取数据
    // 增加默认值的选项 byhz 2021.11.04
    getStorageSync (key, defval) {
        let data = this.$global.get(key);
        if ( !data ) {
            data = this.$taro.getStorageSync(key);
        }
        if ( defval ) {
            data = data || defval;
        }
        // 若为object类型，则硬拷贝
        if ( this.typeOf(data) == 'object' ) {
            data = this.copy(data);
        }
        return data;
    },
    // 向缓存中设置数据
    setStorageSync (key, value) {
        this.$global.set(key, value)
        // 获取当前缓存空间大小
        new Promise((resolve, reject)=>{
            if (value) {
                let {currentSize,keys,limitSize} = this.$taro.getStorageInfoSync(),
                    value_size = parseInt(JSON.stringify(value).length / 1024);
                if (!currentSize || !limitSize){
                    resolve()
                }
                else if (currentSize + value_size < limitSize){
                    resolve();
                } else {
                    this.$taro.removeStorage({
                        key: 'IMAGEINFOS',
                        success: function (res) {
                            this.$loger.info("清除缓存图片数据IMAGEINFOS=>", res)
                        }
                    })
                    this.$loger.info("currentSize=>",currentSize,"limitSize=>",limitSize,"keys=>",keys,"value_size=>",value_size)
                    // logerror
                }
            } else {
                resolve()
            }
        }).then(()=>{
            this.$taro.setStorage({
                key: key,
                data: value
            })
        })
    },
    // 获取设备信息
    getSystemInfoSync () {
        let systeminfo = this.isEnv('h5') ? null : this.getStorageSync("st_systeminfo"); // h5环境不走缓存逻辑
        if (!systeminfo){
            systeminfo = this.$taro.getSystemInfoSync();
            let isiPhone = false;
            let isIpx = false;
            if (systeminfo.model && systeminfo.model.indexOf('iPhone') > -1) {
                isiPhone = true;
                if (systeminfo.screenHeight >= 740) {
                    isIpx = true;
                }
                if (systeminfo.statusBarHeight > 20) {
                    isIpx = true;
                }
            }
            let navHeight = systeminfo.statusBarHeight + 46;
            if (this.isEnv("h5")){
                navHeight = 0; //66;
            } else if (this.isEnv("wxa")) {
                let menuInfo = wx.getMenuButtonBoundingClientRect() || {
                    bottom: 60,
                    height: 32,
                    left: 318,
                    right: 405,
                    top: 28,
                    width: 87,
                }
                systeminfo['menuInfo'] = menuInfo;
            }
            let aspectRatio = Math.round( (systeminfo.screenWidth/systeminfo.screenHeight) * 1000 ) / 1000;
            systeminfo['isIpx'] = isIpx;
            systeminfo['isiPhone'] = isiPhone;
            systeminfo['textwidth'] = systeminfo.windowWidth - 20;
            systeminfo['tabbarHeight'] = isIpx ? 80 : 62;
            systeminfo['aspectRatio'] = aspectRatio;
            systeminfo['isShortDivice'] = aspectRatio > 0.56;
            systeminfo['statusBarHeight'] = systeminfo.statusBarHeight || (systeminfo['isShortDivice'] ? 20 : 44);
            systeminfo['windowHeight'] = systeminfo.windowHeight || systeminfo.screenHeight;
            systeminfo['windowWidth'] = systeminfo.windowWidth || systeminfo.screenWidth;
            systeminfo['navHeight'] = navHeight || 44;
            systeminfo['navFullHeight'] = systeminfo['navHeight'] + systeminfo['statusBarHeight'];
            if (!this.isEnv('h5')) this.setStorageSync("st_systeminfo", systeminfo)
        } else {
            // 后期加的字段，因为部分用户已经存在缓存，增量字段逻辑可能会无效，所以移到缓存逻辑外
            systeminfo['stPlatform'] = systeminfo['platform'] == 'devtools' ? ( systeminfo['system'].indexOf('iOS') == -1 ? 'android' : 'ios' ) : systeminfo['platform'];
            if (systeminfo['isIpx'] && systeminfo['stPlatform'] == 'android') {
                systeminfo['isIpx'] = false;
                if (!this.isEnv('h5')) this.setStorageSync("st_systeminfo", systeminfo)
            }
        }
        return systeminfo;
    },
    // 制定时间后才进行回调中的动作
    timeout (cb, time) {
		let used = 0;
		let handle = setInterval(() => {
			used += 100;
			if (used == time) {
				cb && cb();
				clearInterval(handle);
			}
		}, 100);
		return handle;
	},
    async sleep(numberMillis) {
        await new Promise((resolve, reject)=>{
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve();
            }, numberMillis);
        })
        return Promise.resolve();
    },
    // 内容审核
    safetyCheck(options) {
        let {key, imgs, images, mode, content, openid, appid} = options,
            _this = this;

        if (images){
            imgs = [];
            for (let i = 0 ; i < images.length ; i++){
                let img = images[i];
                img["host"] = img["im"].split("?")[0].replace(img["p"], "").replace("https","http")
                img["path"] = img["p"]
                imgs.push(img)
            }
        }
		let params = {
            "__app__": _this.$config.__APP__,
			"__api__": "check_image",
			"check_mode": mode ? mode : 2,
    		"images": JSON.stringify(imgs),
		}
        if (this.isEnv("wxa")) {
            params["__appid__"] = appid ? appid : this.$login.appid;
            params["openid"] = openid ? openid : this.$login.openid;
        }

        // 文字检测
        if (!images && content) {
            params = {
                "__app__": _this.$config.__APP__,
                "__api__": "check_text",
                "check_mode": mode ? mode : 2,
                "content": content
            }
            return new Promise((resolve, reject)=>{
                _this.rpost(_this.$config.FC_HOST + _this.$config.SAFETY_CHECK_API, params).then(res=>{
                    resolve(res && res.suggestion ? res.suggestion : "pass");
                }).catch(err=>{
                    debugger;
                    resolve("pass");
                })
            })
        }

        if (key){
            params["key"] = key;
        }
        return new Promise((resolve, reject)=>{
            _this.rpost(_this.$config.FC_HOST + _this.$config.SAFETY_CHECK_API, params).then(res=>{
                resolve(res && res.suggestion ? res.suggestion : "pass");
            }).catch(err=>{
                debugger;
                resolve("pass");
            })
        })
    },
    // 长边完整显示、类似background-size: contain
	getContainSize (orisize, boxsize) {
		orisize = orisize || [500, 500];
		
		let box_width = boxsize[0],
			box_height = boxsize[1],
			w = orisize[0],
			h = orisize[1];

		if (box_width / box_height <= w / h) {
			let tw = box_width;
			let th = tw * h / w;
			return {
				width: tw,
				height: th,
				marginTop: ((box_height - box_width * h / w) / 2),
				marginLeft: 0
			}

		} else {
			let th = box_height;
			let tw = th * w / h;
			return {
				height: th,
				width: tw,
				marginTop: 0,
				marginLeft: ((box_width - box_height * w / h) / 2)
			}
		}
    },
    // 短边完整显示、类似background-size: cover
    getCoverSize (orisize, boxsize) {
        orisize = orisize || [500, 500];

        let width = parseInt(orisize[0]),
            height = parseInt(orisize[1]);
        let box_width = parseInt(boxsize[0]),
            box_height = parseInt(boxsize[1]);

        let scale = width / height;
        let box_scale = box_width / box_height;
        let size;
        if (box_scale > scale) {
            size = {
                width: box_width,
                height: box_width / scale
            }
            size['marginTop'] = - (size['height'] - box_height)/2;
            size['marginLeft'] = 0;
        } else {
            size = {
                width: box_height * scale,
                height: box_height
            }
            size['marginTop'] = 0;
            size['marginLeft'] = - (size['width'] - box_width)/2;
        }
        return size;
    },
    // 获取当前顶级域名
	getTopDomain () {
        if (this.$config.ISDEBUG) {
            return "xcpapa.cn";
        }
		var host = window.location.host;
		host = host.replace('http', '');
		host = host.replace('https', '');
		host = host.replace(':', '');
		host = host.replace('//', '');
		host = host.split('.');
		if (host.length == 2) {
			return host.join('.');
		} else {
			return host[host.length - 2] + '.' + host[host.length - 1];
		}
	},
    // 获取元素计算后的属性
    getComputedStyle (element) {
        let style = {};
        if (this.isEnv("h5")) {
            if (getComputedStyle) {
                style = getComputedStyle(element, null);
            } else { 
                style = element.currentStyle;
            }
        }
        return style;
    },
    parseParams (data) {
        let tempArr = [],
            urlParamStr = "";
        for (let i in data){
            let key = encodeURIComponent(i),
                value = encodeURIComponent(data[i]);
            tempArr.push(key + "=" + value);
        }
        urlParamStr = tempArr ? tempArr.join("&") : "";
        return urlParamStr;
    },
    stPushReport (push_id, action) {
        console.log("inside stPushReport==>")
        this.rpost(this.$config.FC_HOST + "stpush/manager", {
            "__api__": "report",
            "app": this.$config.__APP__,
            "log_info": JSON.stringify({
                "push_id": push_id,
                "action": action ? action : "open"
            })
        })
    },
    sourceFilter (host) {
        if (!host || this.isEnv("wxa")) {
            return host;
        }
		let map = {
			"mmbiz.qlogo.cn": "qlogo.lititutu.cn",
			"mmbiz.qpic.cn": "qpic.lititutu.cn"
		}
		for (let item in map){
			if (host.indexOf(item) > -1){
                host = host.replace(item, map[item])
            }
		}
        return host;
    },
    urlEncodeBase64(str) {
		// '+' -> '-'
		// '/' -> '_'
		// '=' -> ''
		return this.encodeBase64(str).replace(/\+/g, '_').replace(/\//g, '_').replace(/=/g, '');
	},
    // 通过mid获取音乐数据
    getMusic (options) {
        let _getNeedReqMid = (mid, fields) => {
			let exsit = true,
                g_musics = this.$global.get("musics", {});
			if (!g_musics[mid]) {
				exsit = false;
			}
			for (let i=0; i<fields.length; i++) {
				let field = fields[i];
				if (g_musics[mid] && !g_musics[mid][field]) {
					exsit = false;
					break;
				}
			}
			if (!exsit) {
				return mid;
			}
		}
		let _getMusicsByMids = (mids) => {
			let musics = [],
                g_musics = this.$global.get("musics", {});
			for (let i=0; i<mids.length; i++) {
				musics.push( g_musics[mids[i]] );
			}
			return musics;
		}
        let _setGlobalMusics = (musics) => {
            let g_musics = this.$global.get("musics", {});
            for (let i=0; i<musics.length; i++) {
                let music = musics[i];
                g_musics[music.mid] = g_musics[music.mid] ? Object.assign(g_musics[music.mid], music) : music;
            }
            this.$global.set("musics", g_musics);
        }

        return new Promise(resolve => {
			let mids = this.typeOf(options.mids) == 'string' ? options.mids.split(",") : options.mids,
				fields = options.fields && this.typeOf(options.fields) == 'string' ? options.fields.split(",") : options.fields || ['mid','src','uid','name'], // 默认不包含“lrc”字段
				need_req_mids = [];
			for (let i=0; i<mids.length; i++) {
				let need_req_mid = _getNeedReqMid(mids[i], fields);
				if (need_req_mid)
					need_req_mids.push( need_req_mid );
			}
	
			if (need_req_mids.length) {
				this.rpost('https://alifun.litiskr.cn/stmusic/get_music', {
					mids: need_req_mids.join(","),
					fields: fields.join(",")
				}).then((musics) => {
                    _setGlobalMusics(musics);
					resolve( _getMusicsByMids(mids) );
				})
			} else {
				resolve( _getMusicsByMids(mids) );
			}
		})
    },
    getQueryFromUrl (url, name, defval) {
        let value = decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec( url )||[,""])[1].replace(/\+/g,'%20'))||null;
        return value || defval;
    },
    // 获取url中参数
	getUrlQuery (name, defval) {
        if ( this.isEnv('h5') ) {
            return this.getQueryFromUrl(location.href, name, defval);
        } else {
            let pages = getCurrentPages();
            let options = {};
            if (!pages || pages.length <= 1) {
                options = wx.getLaunchOptionsSync();
            } else {
                let page = pages.pop();
                options = page.options;
            }
            return (options && options[name]) || defval;
        }
	},
    // 获取url中所有参数并返回obj
    getUrlQuerys () {
        if ( this.isEnv('h5') ) {
            let querys = window.location.search.replace('?', '').split('&');
            let objs = {};
            for (let i=0; i<querys.length; i++) {
                if (querys[i].indexOf('=') == -1) {
                    continue;
                }
                try {
                    let [key, value] = querys[i].split('=');
                    objs[key] = value;
                } catch(e) {
                    console.error(e);
                }
            }
            return objs;
        } else {
            let pages = getCurrentPages();
            let options = {};
            if (!pages || pages.length <= 1){
                let {
                    path,
                    query
                } = ( wx.getEnterOptionsSync && wx.getEnterOptionsSync() ) || (wx.getLaunchOptionsSync && wx.getLaunchOptionsSync()) || {};
                // wx.getLaunchOptionsSync(); 
                /* 
                    wx.getLaunchOptionsSync只获取小程序启动时的参数，
                    在统一生命周期内，
                    永远会返回启动时的参数，
                    应该使用wx.getEnterOptionsSync
                */
                options = query || {};
                options['st_page_route'] = path || "";
            }
            if (pages) {
                let page = pages.pop();
                if (page) {
                    options = Object.assign(options, page.options);
                    options['st_page_route'] = page.route;
                }
            }
            return options;
        }
    },
    // 根据obj拼接url中的参数
    setUrlQuery (params) {
        let querys = [];
        for (let k in params) {
            if (!params[k]) {
                continue
            }
            querys.push( k + "=" + params[k] );
        }
        return querys.join("&");
    },
    // 获取hash中的参数
    getUrlHash (name) {
        let query = {},
            hash = window.location.hash;
        if (!hash) {
            return query[name];
        }
        hash = hash.replace("#", "");
        hash = hash.split("&");
        for (let i=0; i<hash.length; i++) {
            let t = hash[i].split("=");
            query[t[0]] = t[1];
        }
        return query[name];
    },
    transformUserdata (userdata) {
		let userInfo = {},
            default_headimgurl = ( this.$config.st_config['default_user_info'] && this.$config.st_config['default_user_info']['headimgurl'] ) || 'http://resali.lititutu.cn/icons/app/logo_140.png';
        userdata = userdata ? userdata : {};
        userInfo['nickname'] = userdata['nickName'] ? userdata['nickName'] : (userdata['nickname'] ? userdata['nickname'] : '微信用户');
        userInfo['headimgurl'] = userdata['avatarUrl'] ? userdata['avatarUrl'] : (userdata['headimgurl'] ? userdata['headimgurl'] : default_headimgurl);
        userInfo['sex'] = userdata['gender'] ? userdata['gender'] : userdata['sex'] ? userdata['sex'] : 0;
        userInfo['city'] = userdata['city'] ? userdata['city'] : "这里";
        userInfo = Object.assign(userdata, userInfo);
        if (userInfo['nickName']){
            delete userInfo['nickName']
        }
        if (userInfo['avatarUrl']){
            delete userInfo['avatarUrl']
        }
        if (userInfo['gender']){
            delete userInfo['gender']
        }
        userInfo['city'] = "shenzhen";
        return userInfo;
	},
    setCookie (name, value, ttl) {
		let exp = new Date();
		exp.setTime(exp.getTime() + ttl);
		document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
	},
	getCookie (name) {
		let arr,reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
		if(arr = document.cookie.match(reg)) {
			return unescape(arr[2]);
		} else {
			return null;
		}
	},
	delCookie (name) {
		let exp = new Date();
		exp.setTime(exp.getTime() - 1);
		let cval = this.getCookie(name);
		if (cval != null) {
			document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
		}
	},
    // 这个命名更优雅
    packageIm (imageData, resize_w) {
        return this.imPackage(imageData, resize_w);
    },
    imPackage (imageData, resize_w) {
		let from_map = this.$store.state.appConfig.image_map.from_map;
		let host_map = this.$store.state.appConfig.image_map.host_map;

		if ('string' == this.typeOf(imageData)) {
			imageData = imageData.replace("https:","http:");
			let _f = "oss";
			let _p = null;
			for (let _host in from_map) {
				if (imageData.indexOf(_host) > -1) {
					_f = from_map[_host];
					_p = imageData.replace(_host, "");
				}
			}
			imageData = {
				'f': _f,
				'p': _p,
				'pr': null,
			}
			if (!_p) { // 无法匹配host则直接返回
				return imageData;
			}
		}

		let f = imageData['f'];

		let host = host_map[f],
			path = imageData['p'];
			
		if (path.indexOf("http") > -1){
			return path;
		}
		
		let url = [host + path];

		// 加入前缀
		if (f.indexOf('oss') != -1) {
            url.push('?x-oss-process=image');
		} else if (f == 'qiniu') {
            url.push('?imageMogr2/auto-orient')
		}

        // 处理webp兼容
        if (this.getStorageSync("WEBP_ABLE") === 0) {
            if (imageData['o']) {
                if (f.indexOf('oss') != -1) {
                    url.push("/format," + imageData['o']);
                } else if (f == 'qiniu') {
                    url.push("/format/" + imageData['o']);
                }
            }
        }

		// 兼容服务端错误产生的string类型的pr数据
		if (typeof(imageData['pr']) == 'string') {
			try {
				imageData['pr'] = JSON.parse(imageData['pr']);
			} catch(e) {
                console.error(e);
            }
		}

		// 加入裁剪
		if (imageData['pr'] && imageData['pr']['ed']) {
			// 清理ed
			for (let j=0; j<imageData['pr']['ed'].length; j++) {
				// 部分情况下的裁剪操作会产生越界的负值，这里将负值统一设置为0
				if (parseInt(imageData['pr']['ed'][j]) < 0) {
					imageData['pr']['ed'][j] = 0;
				}
			}

			if (f.indexOf('oss') != -1) {
                url.push( '/crop,x_'+imageData['pr']['ed'][0]+',y_'+imageData['pr']['ed'][1]+',w_'+imageData['pr']['ed'][2]+',h_'+imageData['pr']['ed'][3] );
			} else if (f == 'qiniu') {
                url.push( '/crop/!'+imageData['pr']['ed'][2]+'x'+imageData['pr']['ed'][3]+'a'+imageData['pr']['ed'][0]+'a'+imageData['pr']['ed'][1] );
			}
		}

		// 加入缩放
		resize_w = resize_w || 600;
        // 若图片宽不足则不进行缩放
        let image_w = imageData['pr'] && imageData['pr']['width'] && imageData['pr']['width'] || resize_w;
        if (parseInt(image_w) >= parseInt(resize_w)) {
            if (f.indexOf('oss') != -1) {
                url.push( '/resize,w_'+resize_w );
            } else if (f == 'qiniu') {
                url.push( '/thumbnail/'+resize_w+'x' );
            }
        }

        // 加入旋转
		if (imageData['pr'] && imageData['pr']['rotate']) {
			let deg = imageData['pr']['rotate'];
			if (f.indexOf('oss') != -1) {
                url.push( '/rotate,'+deg );
			} else if (f == 'qiniu') {
                url.push( '/rotate/'+deg );
			}
		}

        // 检测是否需要加入图片处理
        if (url.length == 2) {
            // 无需要处理的url，取消后缀
            url.pop();
        } else {
            // 有需要处理的后缀，补充oss图片的自适应方向
            if (f.indexOf('oss') != -1) {
                url.push( '/auto-orient,1' );
            }
        }

        // 合并链接
        url = url.join("");

        console.log("package im > ", url);

		return url;
	},
    // 字符串替换变量相关函数
    fitRegKey (datas) {
        let r_datas = {};
        for (let key in datas) {
            r_datas['page_data_' + key] = datas[key]; // 将请求下来的字段和本地全局好字段分开
        }
        return r_datas;
    },
    replaceReg (tx, _datas) {
        if (this.typeOf(tx) != 'string') {
            tx = JSON.stringify(tx);
        }
        _datas = _datas || {};
        let datas = Object.assign(
            this.copy(_datas),
            this.fitRegKey( this.copy(this.$router.params) ),
            this.copy(this.$store.state.pageDatas),
            this.copy(this.$store.state),
            // this.$global.storeState,
            this.copy({
                'is_h5': this.isEnv('h5'),
                'is_webview': this.isEnv('webview'),
                'is_wxa': this.isEnv('wxa'),
                'env_version': this.$config.env_version,
                'version': this.$config.version,
                'appid': this.$login.appid,
                'openid': this.$login.openid
            })
        )
        
        let fitRegData = (_data) => {
            if (this.typeOf(_data) == 'object') {
                if (_data['f'] && _data['id']) { // im类型数据
                    _data = this.imPackage(_data);
                }
            }
            return _data;
        }
        let getFromData = (_key, _datas) => {
            let _defval = "",
                _val = undefined;
            if (_key.indexOf("||") != -1) { // 带默认值
                let t = _key.split("||");
                _key = t[0].replace(/(^\s*)|(\s*$)/g, "");
                _defval = t[1].replace(/(^\s*)|(\s*$)/g, "");
            }
            // _val = _datas['page_data_'+_key] != undefined ? _datas['page_data_'+_key] : _datas[_key];
            _val = _datas[_key] || _datas['page_data_'+_key] || undefined; // 降低本地字段优先级
            
            return _val || _defval;
        }
        // debugger;
        let regs = tx.match(/\{\{(.*?)\}\}/g) || tx.match(/\#(.*?)\#/g);
        if (regs) {
            for (let i=0; i<regs.length; i++) {
                let reg_replace = regs[i],
                    reg_key = reg_replace.replace("{{", "").replace("}}", "").replace(/#/g, "");

                let reg_idx = null;
                // 处理array、object数据，获取数据下标
                if (reg_key.indexOf(".") != -1) {
                    let t = reg_key.split(".");
                    reg_key = t[0];
                    reg_idx = t[1];
                }
                let reg_rep = new RegExp(reg_replace.replace(/\|/g, "\\|"), "g"),
                    reg_datas = getFromData(reg_key, datas);
                
                if (['array', 'object'].indexOf( this.typeOf(reg_datas) ) != -1) {
                    if (reg_idx === null) {
                        tx = tx.replace(reg_rep, "你没填下标");
                    } else {
                        tx = tx.replace(reg_rep, fitRegData(reg_datas[reg_idx]));
                    }
                } else {
                    tx = tx.replace(reg_rep, fitRegData(reg_datas));
                }
            }
        }
        return tx;
    },
    // 覆盖官方的getImageInfo
    async getImageInfo (args) {
        let {
                fields,
                src
            } = args || {},
            image_info = {},
            _info = {};
        fields = fields || ["width", "height", "path"];
        if (!src) {return image_info};
        if (this.isEnv("wxa")){
            _info = await new Promise((resolve, reject)=>{
                wx.getImageInfo({
                    src: src,
                    success (res) {
                        // console.log("wx.getImageInfo res==>",res)
                        let _info = {}
                        for (let i = 0 ; i < fields.length ; i++) {
                            _info[fields[i]] = res[fields[i]]
                        }
                        let {
                            orientation,
                            width,
                            height
                        } = res;
                        // 如果图片旋转了则宽高互换
                        if (orientation.indexOf("left") != -1 || orientation.indexOf("right") != -1) {
                            let temp_witdh = width;
                            width = height;
                            height = temp_witdh;
                            if (_info["width"] && _info["height"]) {
                                _info["width"] = width;
                                _info["height"] = height;
                            }
                        }
                        resolve(_info)
                    },
                    fail (err) {
                        reject(err);
                    }
                })
            }).catch(err=>{
                console.log("src==>",src,"err==>",err)
            });
            if (fields.indexOf("size") != -1) {
                let fm = wx.getFileSystemManager();
                let file_info = await new Promise((resolve, reject)=>{
                    fm.getFileInfo({
                        filePath: src,
                        success: res=>{
                            resolve(res);
                        }, fail: err=>{reject()}
                    });
                })
                _info["size"] = file_info["size"];
            }
            image_info = _info || {};
        } else {
            src = src.split("?")[0];
            if (src.indexOf(this.$store.state.appConfig.image_map.host_map['qiniu']) != -1){
                _info = await this.$request._request(src + "?imageInfo",{},"GET","").catch(err=>{ });
                if (!_info) {
                    return image_info;
                }
                image_info["width"] = _info["width"];
                image_info["height"] = _info["height"];
                image_info["size"] = _info["size"];
            } else {
                _info = await this.$request._request(src + "?x-oss-process=image/info",{},"GET","").catch(err=>{
                });
                if (!_info) {
                    return image_info;
                }
                image_info["width"] = _info["ImageWidth"]["value"];
                image_info["height"] = _info["ImageHeight"]["value"];
                image_info["size"] = _info["FileSize"]["value"];
            }
        }
        return image_info;
    },
    // 锁止函数(防暴力调用)
    __locker(fn, delay) {
        let _this = this,
            lock_id = "locker_" + fn.name,
            lock = this.$global.get(lock_id) || 0;
        return (...args) => {
            if (lock){
                // console.log(lock_id," + 执行过快被locker中断==>")
                return;
            }
            // 进入加锁
            _this.$global.set(lock_id, 1);
            fn.apply(this, args);

            setTimeout(() => {
                // delay过后解锁
                _this.$global.set(lock_id, 0);
            }, delay);
        }
    },
    locker (fn, delay) {
        delay = delay || 800;
        let func = this.__locker(fn, delay);
        func && func();
    },
    // 通过原图尺寸获取展示区域比例
	getSizeInArea (orisize, areasize, mode) {
		/*
			orisize 原图尺寸
			areasize 设定的最大长宽尺寸
			mode 缩放模式 
				widthFix 宽度不变，高度自动变化
				heightFix 高度不变，宽度自动变化
				both 横图按宽，竖图按高
		 */
		orisize = orisize || [500, 500];

		let owidth = parseInt(orisize[0]),
			oheight = parseInt(orisize[1]),
			twidth, theight;

        mode = mode || 'widthFix';

		if (mode == 'both') {
			if (owidth >= oheight) {
				twidth = areasize[0];
				theight = twidth / owidth * oheight;
			} else {
				theight = areasize[1];
				twidth = theight / oheight * owidth;
			}
		} else if (mode == 'widthFix') {
			twidth = areasize[0];
			theight = twidth / orisize[0] * orisize[1];
		} else if (mode == 'heightFix') {
			theight = areasize[1];
			twidth = theight * orisize[0] / orisize[1];
		}

		twidth = twidth > areasize[0] ? areasize[0] : twidth;
		theight = theight > areasize[1] ? areasize[1] : theight;

		return [twidth, theight];
	},
    /* 根据传入参数获取包含单位的尺寸
        null、undefined => initial
        30 => 30px
        50% => 50%
        navFullHeight => {{navFullHeight}}px
    */
    getPx (s) {
        if (s === null || s === undefined) {
            return 'initial';
        }
        if (this.typeOf(s) != 'string') {
            s = s.toString();
        }
        // 若是纯数字，则返回单位
        if (s.match(/^(\-)?\d+(\.)?(\d+)?$/g)) {
            return s + 'px';
        }
        // 特殊尺寸处理
        if (s == 'navFullHeight') { 
            return this.getSystemInfoSync()['navFullHeight'] + 'px';
        }
        return s;				
    },
    // 加载图片链接数组并返回图片信息
    async preloadImages (options) {
        let {
            images,
            onUpdate
        } = options;

        let that = this;
            // lens = images.length;

        let missions = [],
            // missions_lens = Math.round(images.length/3);
            missions_lens = 3;

        // that.$taro.getImageInfo({
        //     src: images[0].replace("http://", "https://"),
        //     success: function(res) {
        //         console.log('getImageInfo success: ', res)
        //     },
        //     fail: function(err) {
        //         console.error('getImageInfo error: ', err);
        //     }
        // })
        
        for (let i=0; i<images.length;) {
            // missions.push()
            let mission_images = images.slice(i, i += missions_lens);
            await new Promise(resolve => {
                let n = 0,
                    mission_images_lens = mission_images.length;
                for (let j=0; j<mission_images_lens; j++) {
                    (function(image) {
                        let src = that.typeOf(image) == 'object' ? image['im'] : image;
                        if (that.isEnv("wxa")) {
                            src = src.replace("http://", "https://");
                        }
                        that.$taro.getImageInfo({
                            src: src,
                            success: function(res) {
                                // console.log("getImageInfo success: ", res);
                                if (that.typeOf(image) == 'object') {
                                    image['orisize'] = [res.width, res.height];
                                }
                                onUpdate && onUpdate(image, res);

                                // 默认存入store.imagesObj对象
                                that.$store.commit('setImagesObj', {'imagesrc': image, 'imageobj': res})
        
                                if (++n == mission_images_lens) {
                                    resolve();
                                }
                            },
                            fail: function(err) {
                                console.error("getImageInfo error: ", err);
                                if (that.typeOf(image) == 'object') {
                                    image['orisize'] = [300, 300];
                                }
                                onUpdate && onUpdate(image, err);
        
                                if (++n == mission_images_lens) {
                                    resolve();
                                }
                            }
                        })
                    })(mission_images[j]);
                }
            })
        }
        return Promise.resolve();
        // return Promise.all(missions);
        // return new Promise(resolve => {
        //     for (let i=0; i<lens; i++) {
        //         (function(image) {
        //             let src = that.typeOf(image) == 'object' ? image['im'] : image;
        //             that.$taro.getImageInfo({
        //                 src: src,
        //                 success: function(res) {
        //                     if (that.typeOf(image) == 'object') {
        //                         image['orisize'] = [res.width, res.height];
        //                     }
        //                     onUpdate && onUpdate(image, res);
    
        //                     // 默认存入store.imagesObj对象
        //                     debugger;
        //                     that.$store.commit('setImagesObj', {'imagesrc': image, 'imageobj': res})
    
        //                     if (++n == lens) {
        //                         resolve();
        //                     }
        //                 },
        //                 fail: function(err) {
        //                     if (that.typeOf(image) == 'object') {
        //                         image['orisize'] = [300, 300];
        //                     }
        //                     onUpdate && onUpdate(image, err);
    
        //                     if (++n == lens) {
        //                         resolve();
        //                     }
        //                 }
        //             })
        //         })(images[i])
    
        //     }
        // })
    },
    // 计算两个日期之间的时间间隔
    getDiffDays (last_day, now_day) {
        // 两个日期对象
        var date1 = new Date(last_day);
        var date2 = new Date(now_day);

        // 将日期对象转换为时间戳
        var timestamp1 = date1.getTime();
        var timestamp2 = date2.getTime();

        // 计算时间戳之间的差异
        var timeDiff = Math.abs(timestamp2 - timestamp1);

        // 将时间差转换为天数
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return diffDays;
    }
}

/**
 * 模块挂载
 */
// 挂载全局变量模块
import st_global_data from './st_global_data';
_util.$global = new st_global_data.stGlobalData(_util);

// 挂载taro模块
import st_taro from './st_taro';
_util.$taro = st_taro.stTaro ? new st_taro.stTaro(_util) : st_taro; // 区分vue和taro不同的挂载方式

// 挂载config模块
import st_config from './st_config';
_util.$config = new st_config.stConfig(_util);

// 挂载request模块
import st_request from './st_request';
_util.$request = new st_request.stRequest(_util);

// 挂载埋点模块
import st_log from './st_log';
_util.$loger = new st_log.stLogManager(_util);

// 挂载通知中心模块
import st_event_center from './st_event_center';
_util.$eventCenter = new st_event_center.stEventCenter(_util);

// 挂载login模块
import st_login from './st_login';
_util.$login = new st_login.stLogin(_util);

// 挂载wx模块
import st_wx from './st_wx';
_util.$wx = new st_wx.stWx(_util);

// 挂载路由模块
import st_router from './st_router';
_util.$router = new st_router.stRouter(_util);

// 挂载预加载模块
import st_preload from './st_preload';
_util.$preload = new st_preload.stPreload(_util);

// 挂载上传图片模块
import st_upload from './st_upload';
_util.$upload = new st_upload.stUpload(_util);

// condition处理相关
import st_condition from './st_condition'
_util.$condition = new st_condition.install(_util);

// do处理相关
import st_action from './st_action'
_util.$action = new st_action.install(_util);

// 挂载广告模块
import st_ad from "./st_ad";
_util.$ad = new st_ad.stAd(_util);

// 分享相关功能
import st_share from "./st_share"
Object.assign(_util, st_share);

// 数组相关处理
import st_array from "./st_array"
Object.assign(_util, st_array);

// 时间相关处理
import st_time from "./st_time"
Object.assign(_util, st_time);

// canvas处理功能
import st_canvas from './st_canvas';
// Object.assign(_util, st_canvas);
_util.$canvas = new st_canvas.stCanvas(_util);

// Observer相关
import st_observer from "./st_observer";
_util.$observer = new st_observer.stObserver(_util);

// 订阅消息相关
import st_sub_msg from "./st_sub_msg"
Object.assign(_util, st_sub_msg);
_util.$submsg = new st_sub_msg.SubMessageManager(_util);

export default _util;
