<template>
    <div class="po page flex_c" :style="elemStyles">
        <div class="po saluo_item" 
            v-for="(idx, index) in myLens" 
            :key="'saluo_item_'+idx"
            :style="getSizeStyle_0(index)">
            <img class="page" :style="getSizeStyle_1(index)" :src="getRandomLuo()" />
        </div>
    </div>
</template>

<script>
	import BaseLuo from './BaseLuo'
    
    export default {
		extends: BaseLuo,
        data () {
            return {
                delays: [],
                animation_lens_0: 24,
                animation_lens_1: 5
            }
        },
        computed: {
            myWidth () {
                return this.comp_config[1] ? parseInt(this.comp_config[1]) : 100;
            },
            myLens () {
                return this.comp_config[2] ? parseInt(this.comp_config[2]) : 20;
            },
            myTimes () {
                return this.comp_config[3] || 'infinite';
            },
            myDuration () {
                return this.comp_config[4] ? parseInt(this.comp_config[4]) : 2.5;
            }
        },
        created () {
            for (let i=0; i<this.myLens; i++) {
                this.delays.push( ((Math.random() * this.animation_lens_1) + 1).toFixed(2) );
            }
            this.setElemStyles();
        },
        methods: {
            getWidth () {
                let range = 10,
                    num = parseInt(Math.random() * 1000) % range;
                if (Math.random() > .5) {
                    return this.myWidth + num;
                } else {
                    return this.myWidth - num;
                }
            },
            getSizeStyle_0 (idx) {
                // let width = this.getWidth();
                let width = this.myWidth;
                let s = {
                    width: width + "px",
                    height: width + "px",
                    animation: "saluo_0_" + parseInt(idx * Math.random() * 1000) % this.animation_lens_0 + " "+ this.myDuration +"s linear " + this.delays[idx] + "s "+ this.myTimes +" normal both running"
                }
                return s;
            },
            getSizeStyle_1 (idx) {
                let s = {
                    animation: "saluo_1_" + parseInt(idx * Math.random() * 1000) % this.animation_lens_1 + " "+ this.myDuration +"s ease "+ this.delays[idx] +"s "+ this.myTimes +" normal both running"
                }
                return s;
            },
        }
    }
</script>

<style>
    .saluo_item {}

    @keyframes saluo_0_0{0%{transform:translate(0px,0px);}100%{transform:translate(200px,0px)}}
    @keyframes saluo_0_1{0%{transform:translate(0px,0px);}100%{transform:translate(180px,0px)}}
    @keyframes saluo_0_2{0%{transform:translate(0px,0px);}100%{transform:translate(160px,0px)}}
    @keyframes saluo_0_3{0%{transform:translate(0px,0px);}100%{transform:translate(140px,0px)}}
    @keyframes saluo_0_4{0%{transform:translate(0px,0px);}100%{transform:translate(120px,0px)}}
    @keyframes saluo_0_5{0%{transform:translate(0px,0px);}100%{transform:translate(100px,0px)}}
    @keyframes saluo_0_6{0%{transform:translate(0px,0px);}100%{transform:translate(80px,0px)}}
    @keyframes saluo_0_7{0%{transform:translate(0px,0px);}100%{transform:translate(60px,0px)}}
    @keyframes saluo_0_8{0%{transform:translate(0px,0px);}100%{transform:translate(280px,0px)}}
    @keyframes saluo_0_9{0%{transform:translate(0px,0px);}100%{transform:translate(260px,0px)}}

    @keyframes saluo_0_11{0%{transform:translate(0px,0px);}100%{transform:translate(-180px,0px)}}
    @keyframes saluo_0_12{0%{transform:translate(0px,0px);}100%{transform:translate(-160px,0px)}}
    @keyframes saluo_0_13{0%{transform:translate(0px,0px);}100%{transform:translate(-140px,0px)}}
    @keyframes saluo_0_14{0%{transform:translate(0px,0px);}100%{transform:translate(-120px,0px)}}
    @keyframes saluo_0_15{0%{transform:translate(0px,0px);}100%{transform:translate(-100px,0px)}}
    @keyframes saluo_0_16{0%{transform:translate(0px,0px);}100%{transform:translate(-80px,0px)}}
    @keyframes saluo_0_17{0%{transform:translate(0px,0px);}100%{transform:translate(-60px,0px)}}
    @keyframes saluo_0_18{0%{transform:translate(0px,0px);}100%{transform:translate(-280px,0px)}}
    @keyframes saluo_0_19{0%{transform:translate(0px,0px);}100%{transform:translate(-260px,0px)}}
    @keyframes saluo_0_10{0%{transform:translate(0px,0px);}100%{transform:translate(-200px,0px)}}

    @keyframes saluo_0_20{0%{transform:translate(0px,0px);}100%{transform:translate(-220px,0px)}}
    @keyframes saluo_0_21{0%{transform:translate(0px,0px);}100%{transform:translate(220px,0px)}}
    @keyframes saluo_0_22{0%{transform:translate(0px,0px);}100%{transform:translate(-240px,0px)}}
    @keyframes saluo_0_23{0%{transform:translate(0px,0px);}100%{transform:translate(240px,0px)}}

    @keyframes saluo_1_0{0%{opacity:0;transform:translate(0px,0px);animation-timing-function:ease-out}25%{opacity:1;transform:translate(0px,-120px);animation-timing-function:ease-in}100%{transform:translate(0px,880px);}}
    @keyframes saluo_1_1{0%{opacity:0;transform:translate(0px,0px);animation-timing-function:ease-out}25%{opacity:1;transform:translate(0px,-40px);animation-timing-function:ease-in}100%{transform:translate(0px,880px);}}
    @keyframes saluo_1_2{0%{opacity:0;transform:translate(0px,0px);animation-timing-function:ease-out}25%{opacity:1;transform:translate(0px,-60px);animation-timing-function:ease-in}100%{transform:translate(0px,880px);}}
    @keyframes saluo_1_3{0%{opacity:0;transform:translate(0px,0px);animation-timing-function:ease-out}25%{opacity:1;transform:translate(0px,-80px);animation-timing-function:ease-in}100%{transform:translate(0px,880px);}}
    @keyframes saluo_1_4{0%{opacity:0;transform:translate(0px,0px);animation-timing-function:ease-out}25%{opacity:1;transform:translate(0px,-100px);animation-timing-function:ease-in}100%{transform:translate(0px,880px);}}
    
</style>