<template>
	<div class="usertext-container" :style="textStyle" :class="textClass" v-show="showAble">
		<template v-if="lineType == 2">
			<div class="usertext" v-for="(text, index) in userText" :key="'usertext-'+index">{{text}}</div>
		</template>
		<template v-else-if="lineType == 1">
			<span style="width: 90%;">{{currentText}}</span>
		</template>
	</div>
</template>

<script>
	const styleDefault = {
		'full': {
			'bottom': '100px',
			'fontSize': '28px'
		},
		'xc': {
			'bottom': '10px',
			'fontSize': '28px'
		},
		'exhi': {
			'bottom': '-80px',
			'fontSize': '18px'
		}
	};

	export default {
		name: 'usertext',
		props: {
			mode: {
				type: Object,
				default () {
					let currentMode = this.$store.state.themes[ this.$store.state.config['data']['ttid'] ];
					return currentMode['mode'];
				}
			},
			currentText: {
				type: String,
				default: ''
			},
			from: {
				default: 'full'
			},
			lineType: {
				type: Number,
				default: 2
			},
			positionType: {
				type: String,
				default: 'absolute'
			}
		},
		watch: {
			currentText: {
				handler () {
					// 若输入文字为空，则不进行裁切操作
					if (this.currentText == "") {
						this.userText = [];
						return;
					}

					let userText = [
						this.currentText
					];

					if (this.currentText.length > 13) {
						let split_num = Math.round(this.currentText.length/2);
					
						userText = [
							this.currentText.slice(0, split_num),
							this.currentText.slice(split_num)
						];
					}
					// 切换文字时控制显隐
					this.canTextShow = false;
					setTimeout(() => {
						this.userText = userText;
						this.canTextShow = true;
					}, 300);
				},
				immediate: true
			}
		},
		data () {
			return {
				userText: [],
				canTextShow: false,
				isTextIn: false // 用于判断当fadein过一次后，才开始执行fadeout的逻辑
			}
		},
		computed: {
			moreBottom () {
				// 部分情况下，用户文字的底边距要增加额外的距离
				return this.$route.query['fast_make'] ? 40 : 0;
			},
			showAble () {
				let able = true;
				if (this.lineType == 1 && !this.currentText) {
					able = false;
				} else if (this.lineType == 2 && this.userText.length == 0) {
					able = false;
				}
				if (this.isCoverMaker) {
					able = false;
				}
				return able;
			},
			isCoverMaker () {
				let bool = Boolean(this.$route.query['action'] == 'covermaker');
				return bool;
			},
			textMode () {
				let text = {};
				if (this.mode['text']) {
					text = this.mode['text'];
				}
				return text;
			},
			textClass () {
				if (this.from == 'exhi' || this.from == 'xc') {
					if (this.from == 'exhi') {
						return {'usertext-fadein': true, 'usertext-container_exhi': true};
					}
					return {'usertext-fadein': true};
				}

				if (this.canTextShow && this.currentText) {
					this.isTextIn = true;
					return {'usertext-fadein': true};
				} else if (this.isTextIn) {
					return {'usertext-fadeout': true};
				} else {
					return {}
				}
			},
			textStyle () {
				let style = {
					'fontSize': styleDefault[this.from] ? styleDefault[this.from]['fontSize'] : '28px',
					'textShadow': "0 0 5px rgba(39, 36, 36, .6), 0 0 10px rgba(39, 36, 36, .6), 0 0 15px rgba(39, 36, 36, .6), 0 0 40px rgba(39, 36, 36, .6), 0 0 70px rgba(39, 36, 36, .6)",
					'color': '#ffffff'
				};
				if (this.textMode['textShadowColor']) {
					style['textShadow'] = "0 0 5px "+ this.textMode['textShadowColor'] +", 0 0 10px "+ this.textMode['textShadowColor'] +", 0 0 15px "+ this.textMode['textShadowColor'] +", 0 0 40px "+ this.textMode['textShadowColor'] +", 0 0 70px " + this.textMode['textShadowColor'];
				}
				
				// 文字阴影会导致模板卡顿 相关模板暂时屏蔽 byhz 2021.04.12
                if (this.from == 'xc' || this.from == 'exhi') {
                    delete style['textShadow'];
					style['display'] = 'flex';
					style['align-item'] = 'center';
					style['justify-content'] = 'center';
					style['background-color'] = this.textMode['backgroundColor'] || 'initial'; // rgba(115, 115, 115, 0.45)
					style['border-radius'] = '10px';
					style['text-align'] = 'center';
                }

				if (this.textMode['bottom']) {
					if (this.positionType == 'relative') {
						style['marginTop'] = this.$util.getPx(this.textMode['bottom']);
					} else {
						style['bottom'] = this.$util.getPx(this.textMode['bottom']);
					}
				} else {
					if (this.positionType == 'relative') {
						style['marginTop'] = styleDefault[this.from] ? styleDefault[this.from]['bottom'] : '100px';
					} else {
						style['bottom'] = styleDefault[this.from] ? styleDefault[this.from]['bottom'] : '100px';
					}
				}
				style['bottom'] = this.$util.getPx( parseInt(style['bottom']) + this.moreBottom );
				if (this.textMode['fontSize']) {
					style['fontSize'] = this.$util.getPx(this.textMode['fontSize']);
				}
				if (this.textMode['color']) {
					style['color'] = this.textMode['color'];
				}
				if (this.textMode['style']) {
					style = Object.assign(style, this.textMode['style'])
				}
				style['position'] = this.positionType;
				return style;
			}
		}
	}
</script>

<style scoped lang="less">
	.usertext-container_exhi {
		width: 260px!important;
		height: 76px!important;
		left: 50%!important;
		margin-left: -130px!important;
	}
	.usertext-container {
		width: 93%;
		height: 76px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		z-index: 9;
		pointer-events: none;
		opacity: 0;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.usertext {
		position: relative;
		width: 100%;
		height: 38px;
		line-height: 38px;
		margin: auto 0;
		text-align: center;
		font-family: "arial", "微软雅黑";
		font-weight: bold;
		letter-spacing: 3px;
	}
	.usertext-fadein {
		animation: fadein 1s ease-out both;
	}
	.usertext-fadeout {
		animation: fadeout 1s ease-out both;
	}
</style>