import toastVue from './Toast.vue'

const Toast = {};

Toast.install = function(Vue, options) {
	const ToastInstance = Vue.extend(toastVue);
	let currentToast;
	const initInstance = () => {
		currentToast = new ToastInstance();
		let toastEl = currentToast.$mount().$el;
		document.body.appendChild(toastEl);
	}

	Vue.prototype.$toast = {
		loading (options) {
			if (!currentToast) {
				initInstance();
			}
			if (typeof options == 'object') {
				Object.assign(currentToast.loadingOptions, options);
			}
			if (typeof options == 'string') {
				currentToast.loadingOptions.message = options;
			}
			return currentToast.loading();
		},
		text (options) {
			if (!currentToast) {
				initInstance();
			} 
			if (typeof options == 'object') {
				Object.assign(currentToast.textOptions, options);
			}
			if (typeof options == 'string') {
				currentToast.textOptions.message = options;
			}
			return currentToast.text();
		},
		clear () {
			if (!currentToast) {
				initInstance();
			}
			return currentToast.clear();
		},
		qrcode (options) {
			if (!currentToast) {
				initInstance();
			}
			Object.assign(currentToast.qrcodeOptions, options);
			return currentToast.vqrcode();
		}
	}
}

export default Toast