import vaudioVue from './vAudio.vue'

const vAudio = {};

vAudio.install = function (Vue, options) {
	const vAudioInstance = Vue.extend(vaudioVue);
	let currentvAudio;
	const initInstance = () => {
		// 实例化
		currentvAudio = new vAudioInstance();
		let vaudioEl = currentvAudio.$mount().$el;
		document.body.appendChild(vaudioEl);
		// document.querySelector('#capp').appendChild(vaudioEl);
	}

	// 原型上添加实例方法
	Vue.prototype.$audio = {
		// 创建
		init (options) {

			if (!currentvAudio) {
				initInstance();
			}

			return currentvAudio.init(options);
		},
		// 播放
		play (options) {
			return currentvAudio.play();
		},
		// 暂停
		pause (options) {
			return currentvAudio.pause();
		},
		// 重播
		replay (options) {
			return currentvAudio.replay();
		},
		// 替换音乐
		change (options) {
			return currentvAudio.change(options);
		},
		// 销毁
		destroy (options) {
			let cb = currentvAudio.destroy();
			currentvAudio = null;
			return cb;
		},
		// 隐藏
		hide (options) {
			return currentvAudio.hide();
		},
		// 显示
		show () {
			return currentvAudio.show();	
		},
		// 是否存在
		exist () {
			if (!currentvAudio) {
				initInstance();
			}

			return currentvAudio.exist();
		},
		// 获取当前音乐信息
		getInfo () {
			return currentvAudio.getInfo();
		},
		// 关闭歌词
		closeLrc () {
			return currentvAudio.closeLrc();
		},
		// 打开歌词
		openLrc () {
			return currentvAudio.openLrc();
		},
		// 隐藏歌词
		hideLrc () {
			return currentvAudio.hideLrc();
		},
		// 修改歌词颜色
		changeLrcColor (color) {
			return currentvAudio.changeLrcColor(color);
		}
	}
}

export default vAudio

