<template>
	<div class="xinbox">
		<div class="xinbox">
			<div class="xinxinbox" :class="{xinxinbox_xuanzhuan: xinboxAniShow}">
				<div class="anidiv"
                    v-for="idx of 10"
                    :key="'anidiv-' + (idx - 1)"
                    :class="'anidiv-' + (idx - 1)"
                    :style="{'transform': getAnidivTransform(idx - 1)}">
                    <elem v-if="comp['elems'] && comp['elems'][0]" :canIShow="canIShow" :mode="comp['elems'][0]" :currentPhoto="currentPhoto"></elem>
					<div v-else class="xinxinbox-item bg" :style="{backgroundImage: 'url('+currentPhoto['im']+')'}"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BaseElem from '@/components/st_comps/st_comp/comps/StElem/BaseElem'
	export default {
		name: 'xinbox',
        extends: BaseElem,
		data () {
			return {
				anidivTransform_0 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_1 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_2 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_3 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_4 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_5 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_6 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_7 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_8 : "rotateY(0deg) translateZ(0px);",
				anidivTransform_9 : "rotateY(0deg) translateZ(0px);",
				xinboxAniShow: false
			}
		},
        watch: {
            canIShow: {
                handler () {
                    if (this.canIShow) {
                        this.start()
                    } else {
                        this.end()
                    }
                },
                immediate: true
            }
        },
		mounted () {},
		methods: {
            getAnidivTransform (idx) {
                return this['anidivTransform_'+idx];
            },
			start () {
				setTimeout(() => {
					for(var i=0; i<10; i++) {
						this['anidivTransform_'+i] = 'rotateY('+(i*60)+'deg) translateZ('+(500*.556)+'px)';
					}
					setTimeout(() => {
						this.xinboxAniShow = true;
					}, 300);
				}, 200);
			},
			end () {
				setTimeout(() => {
					for(var i=0; i<10; i++) {
						this['anidivTransform_'+i] = 'rotateY(0deg) translateZ(0px)';
					}
					setTimeout(() => {
						this.xinboxAniShow = false;
					}, 300);
				}, 1000);
			}
		},
	}
</script>

<style>
	.xinxinbox-item{
		animation: fullscreen_bg_20_80 6s ease-out 0s infinite both alternate-reverse;
	}
	.xinbox-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center 20%;
		background-size: cover;
		transform: translateZ(-6944.5px);
	}
	.xinbox {
		position: absolute;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transform: rotateX(0deg) rotateY(0deg) translateZ(-694.5px);
	}
	.xinxinbox {
		position: absolute;
		left: 26%;
		top:17.5px;
		width:208.5px;
		height:208.5px;
		transform-style: preserve-3d;
		transform: rotateX(0deg) rotateY(0deg);
	}
	.xinxinbox_xuanzhuan {
		animation: xinbox_xuanzhuan 20s infinite linear;
	}
	.xinxinbox>div {
		position: absolute;
		left: 0;
		top: 0;
		width:312.5px;
		height:312.5px;
		opacity: .98;
		background-color: hsla(0,0%,100%,.8);
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: 100% 100%;
		-webkit-mask-position: 50% 50%;
	}
	.xinxinbox>div>img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
	}
	.anidiv {
		-webkit-mask-image: url(http://litiresource.xcgogo.site/user/5980171/images/bcbc95daa866f90a00c971f4e2f71c59.png);
	}
	@-webkit-keyframes xinbox_xuanzhuan {
		from {
			transform: rotateY(0deg);
		}
		to {
			transform: rotateY(-1turn);
		}
	}
	.anidiv-0 {
		transition: transform 1s 1s;
        margin-top:0;
	}
	.anidiv-1 {
		transition: transform 1s .9s;
        margin-top:55.5px;
	}
	.anidiv-2 {
		transition: transform 1s .8s;
        margin-top:111px;
	}
	.anidiv-3 {
		transition: transform 1s .7s;
        margin-top:166.5px;
	}
	.anidiv-4 {
		transition: transform 1s .6s;
        margin-top:222px;
	}
	.anidiv-5 {
		transition: transform 1s .5s;
        margin-top:278px;
	}
	.anidiv-6 {
		transition: transform 1s .4s;
        margin-top:333.5px;
	}
	.anidiv-7 {
		transition: transform 1s .3s;
        margin-top:389px;
	}
	.anidiv-8 {
		transition: transform 1s .2s;
        margin-top:444.5px;
	}
	.anidiv-9 {
		transition: transform 1s .1s;
        margin-top:500px;
	}
</style>