<template>
    <div class="poc piao8-cont">
        <div class="po piao8-cont_item" 
            :class="'piao8-cont_item_' + jdx" 
            v-for="(idx, jdx) in 26" 
            :key="'piao8_item_'+idx">
            <elem 
                v-if="[13,14,9,7].indexOf(jdx) == -1"
                :mode="getLuoItem(jdx)"
                :currentPhoto="currentPhoto"
                :canIShow="canIShow"></elem>
        </div>
    </div>    
</template>

<script>
	import BaseLuo from './BaseLuo'
    export default {
        name: 'piao8',
		extends: BaseLuo,
    }
</script>

<style scoped>
    .piao8-cont {}
    @keyframes piao8_cont {
        0% {
            transform: scale(0.9);
        }
        100% {
            transform: scale(1.5);
        }
    }
    .piao8-cont_item {
        top: 11vh;
        left: 0;
    }
    .piao8-cont_item_0 {
        animation: piao8_cont_item_0 2s ease-in infinite;
    }
    @keyframes piao8_cont_item_0 {
        from {
            opacity:1;
            transform:translateX(21vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(500px) translateY(17vh)
        }
    }
    .piao8-cont_item_1 {
        animation: piao8_cont_item_1 2s ease-in infinite;
    }
    @keyframes piao8_cont_item_1 {
        from {
            opacity:1;
            transform:translateX(60vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(500px) translateY(0vh)
        }
    }
    .piao8-cont_item_2 {
        animation: piao8_cont_item_2 1.8s ease-in infinite;
    }
    @keyframes piao8_cont_item_2 {
        from {
            opacity:1;
            transform:translateX(29vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(500px) translateY(1vh)
        }
    }
    .piao8-cont_item_3 {
        animation: piao8_cont_item_3 1.8s ease-in infinite;
    }
    @keyframes piao8_cont_item_3 {
        from {
            opacity:1;
            transform:translateX(30vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(500px) translateY(-20vh)
        }
    }
    .piao8-cont_item_4 {
        animation: piao8_cont_item_4 3s ease-in infinite;
    }
    @keyframes piao8_cont_item_4 {
        from {
            opacity:1;
            transform:translateX(15vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(500px) translateY(0vh)
        }
    }
    .piao8-cont_item_5 {
        animation: piao8_cont_item_5 3s ease-in infinite;
    }
    @keyframes piao8_cont_item_5 {
        from {
            opacity:1;
            transform:translateX(10vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(500px) translateY(-18vh)
        }
    }
    .piao8-cont_item_6 {
        animation: piao8_cont_item_6 2.5s ease-in infinite;
    }
    @keyframes piao8_cont_item_6 {
        from {
            opacity:1;
            transform:translateX(8vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(500px) translateY(-20vh)
        }
    }
    .piao8-cont_item_8 {
        animation: piao8_cont_item_8 2.8s ease-in infinite;
    }
    @keyframes piao8_cont_item_8 {
        from {
            opacity:1;
            transform:translateX(50vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(-5vh)
        }
    }
    .piao8-cont_item_10 {
        animation: piao8_cont_item_10 3s ease-in infinite;
    }
    @keyframes piao8_cont_item_10 {
        from {
            opacity:1;
            transform:translateX(30vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(10vh)
        }
    }
    .piao8-cont_item_11 {
        animation: piao8_cont_item_11 3s ease-in infinite;
    }
    @keyframes piao8_cont_item_11 {
        from {
            opacity:1;
            transform:translateX(20vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(-13vh)
        }
    }
    .piao8-cont_item_12 {
        animation: piao8_cont_item_12 3.3s ease-in infinite;
    }
    @keyframes piao8_cont_item_12 {
        from {
            opacity:1;
            transform:translateX(10vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(25vh)
        }
    }
    .piao8-cont_item_15 {
        animation: piao8_cont_item_15 2.5s ease-in infinite;
    }
    @keyframes piao8_cont_item_15 {
        from {
            opacity:1;
            transform:translateX(18vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(20vh)
        }
    }
    .piao8-cont_item_16 {
        animation: piao8_cont_item_16 2.6s ease-in infinite;
    }
    @keyframes piao8_cont_item_16 {
        from {
            opacity:1;
            transform:translateX(45vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(-10vh)
        }
    }
    .piao8-cont_item_17 {
        animation: piao8_cont_item_17 2.6s ease-in infinite;
    }
    @keyframes piao8_cont_item_17 {
        from {
            opacity:1;
            transform:translateX(10vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(-13vh)
        }
    }
    .piao8-cont_item_18 {
        animation: piao8_cont_item_18 3.2s ease-in infinite;
    }
    @keyframes piao8_cont_item_18 {
        from {
            opacity:1;
            transform:translateX(25vw) translateY(40vh)
        }
        to {
            opacity:.7;
            transform:translateX(-10vw) translateY(-8vh)
        }
    }
    .piao8-cont_item_19 {
        animation: piao8_cont_item_19 3.2s ease-in infinite;
    }
    @keyframes piao8_cont_item_19 {
        from {
            opacity:1;
            transform:translateX(25vw) translateY(40vh)
        }
        to {
            opacity:.8;
            transform:translateX(500px) translateY(-5vh)
        }
    }
    .piao8-cont_item_20 {
        animation: piao8_cont_item_20 2.3s ease-in infinite;
    }
    @keyframes piao8_cont_item_20 {
        from {
            opacity:1;
            transform:translateX(80vw) translateY(40vh)
        }
        to {
            opacity:.8;
            transform:translateX(500px) translateY(15vh)
        }
    }
    .piao8-cont_item_21 {
        animation: piao8_cont_item_21 2.3s ease-in infinite;
    }
    @keyframes piao8_cont_item_21 {
        from {
            opacity:1;
            transform:translateX(35vw) translateY(40vh)
        }
        to {
            opacity:.8;
            transform:translateX(-10vw) translateY(14vh)
        }
    }
    .piao8-cont_item_22 {
        animation: piao8_cont_item_22 2.7s ease-in infinite;
    }
    @keyframes piao8_cont_item_22 {
        from {
            opacity:1;
            transform:translateX(90vw) translateY(40vh)
        }
        to {
            opacity:.8;
            transform:translateX(500px) translateY(25vh)
        }
    }
    .piao8-cont_item_23 {
        animation: piao8_cont_item_23 2.7s ease-in infinite;
    }
    @keyframes piao8_cont_item_23 {
        from {
            opacity:1;
            transform:translateX(60vw) translateY(40vh)
        }
        to {
            opacity:.8;
            transform:translateX(-10vw) translateY(18vh)
        }
    }
    .piao8-cont_item_24 {
        animation: piao8_cont_item_24 2.2s ease-in infinite;
    }
    @keyframes piao8_cont_item_24 {
        from {
            opacity:1;
            transform:translateX(35vw) translateY(40vh)
        }
        to {
            opacity:.8;
            transform:translateX(-10vw) translateY(7vh)
        }
    }
    .piao8-cont_item_25 {
        animation: piao8_cont_item_25 2.2s ease-in infinite;
    }
    @keyframes piao8_cont_item_25 {
        from {
            opacity:1;
            transform:translateX(22vw) translateY(40vh);
        }
        to {
            opacity:.8;
            transform:translateX(-10vw) translateY(30vh);
        }
    }
</style>